import { MenuItem } from '@mui/material';
import EyeOffIcon from 'components/icons/EyeOffIcon';
import React from 'react';

interface Props {
  onColumnHide: () => void;
  setMenuState: (menuState: 'MENU' | 'FILTER' | 'HIDE') => void;
}

function HideColumnMenu({ onColumnHide, setMenuState }: Props) {
  return (
    <MenuItem
      onClick={() => {
        onColumnHide();
        setMenuState('HIDE');
      }}
    >
      <div className="flex items-center text-sm">
        <EyeOffIcon className="w-4 h-4 mr-2" />
        Hide column
      </div>
    </MenuItem>
  );
}

export default HideColumnMenu;
