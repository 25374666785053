import React, { useEffect, useRef } from 'react';
import { Document } from 'types/document';
import styles from './MultiDocsView.module.css';
import DocAccordion from './DocAccordion';
import { concat } from 'utils/styling';
import ResponseView from 'components/ResponseView';
import { EntityDocument } from 'types/entity';
import EmptyResponseIcon from 'components/icons/EmptyResponseIcon';

interface Props {
  className?: string;
  docs: Document[];
  currentDoc: string;
  setCurrentDoc: (id: string) => void;
  parentDoc?: EntityDocument;
  scrollContainerRef: React.RefObject<HTMLDivElement>;
}

function MultiDocsView({ className, docs, currentDoc, setCurrentDoc, parentDoc, scrollContainerRef }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  // auto scroll into view for current doc
  useEffect(() => {
    setTimeout(() => {
      if (currentDoc) {
        document.getElementById(currentDoc)?.scrollIntoView({ behavior: 'smooth' });
      }
    }, 300);
  }, [currentDoc]);

  return (
    <div className={concat(styles.container, className)} ref={ref}>
      {docs.length <= 0 && (
        <ResponseView
          className="m-auto px-4"
          noData
          noDataIcon={<EmptyResponseIcon />}
          noDataMsg={
            parentDoc ? (
              <span className="font-normal">
                You do not have any meeting
                <span>
                  &nbsp;in the {parentDoc.entity.name} <strong className="font-bold">{parentDoc.name}</strong>
                </span>
                ...
              </span>
            ) : (
              <span className="font-normal">You do not have any meeting!</span>
            )
          }
        />
      )}
      {docs.map(doc => (
        <DocAccordion
          key={doc.id}
          doc={doc}
          currentDoc={currentDoc}
          setCurrentDoc={setCurrentDoc}
          scrollContainerRef={scrollContainerRef}
        />
      ))}
    </div>
  );
}

MultiDocsView.defaultProps = {
  className: '',
  parentDoc: undefined,
};

export default MultiDocsView;
