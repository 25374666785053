import React, { useState } from 'react';
import { Category, ColumnConfig, RowData } from './types';
import CaretIcon from '../icons/CaretIcon';
import SimpleTable from './SimpleTable';
import orderObjectKeys from '../../utils/order-object-keys';

interface CategoryViewProps {
  categoryTitle: string;
  columnConfigs: ColumnConfig[];
  setColumnConfigs: (columnDefinitions: ColumnConfig[]) => void;
  rows: RowData[];
  title: JSX.Element;
  showSequence?: boolean;
  sequenceOffset?: number;
}

function CategoryView({
  categoryTitle,
  columnConfigs,
  setColumnConfigs,
  rows,
  title,
  showSequence,
  sequenceOffset,
}: CategoryViewProps) {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  return (
    <div className="border rounded mt-8">
      <div className="bg-slate-200 py-2 px-1">
        <div className="pl-8 text-xs text-gray-500">{categoryTitle}</div>
        <div className="flex mt-1">
          <button
            type="button"
            className="w-6 h-6 hover:bg-gray-100 rounded mr-2"
            onClick={() => {
              setIsOpen(prev => !prev);
            }}
          >
            <CaretIcon className={`w-6 h-6 ${isOpen ? 'rotate' : 'rotate-180'}`} />
          </button>
          {title}
        </div>
      </div>
      {isOpen && (
        <SimpleTable
          columnConfigs={columnConfigs}
          setColumnConfigs={setColumnConfigs}
          rows={rows}
          showSequence={showSequence}
          sequenceOffset={sequenceOffset}
        />
      )}
    </div>
  );
}

CategoryView.defaultProps = {
  showSequence: false,
  sequenceOffset: 0,
};

interface Props {
  columnConfigs: ColumnConfig[];
  setColumnConfigs: (columnDefinitions: ColumnConfig[]) => void;
  rows: RowData[];
  category: Category;
  showSequence?: boolean;
  sequenceOffset?: number;
}

interface CategorizedData {
  [uniqueId: string]: {
    groupData: any;
    rowData: RowData[];
  };
}
function CategorizedTable({ columnConfigs, setColumnConfigs, rows, category, showSequence, sequenceOffset }: Props) {
  const categorized: CategorizedData = {};

  rows.forEach(row => {
    const categoryUniques = category.getUniques(row);
    categoryUniques.forEach(({ uniqueId, groupData }) => {
      if (!categorized[uniqueId]) {
        categorized[uniqueId] = {
          groupData,
          rowData: [row],
        };
      } else {
        categorized[uniqueId].rowData.push(row);
      }
    });
  });

  return (
    <>
      {Object.entries(orderObjectKeys(categorized) as CategorizedData).map(([uniqueId, { groupData, rowData }]) => {
        return (
          <CategoryView
            key={uniqueId}
            categoryTitle={category.title}
            columnConfigs={columnConfigs}
            setColumnConfigs={setColumnConfigs}
            rows={rowData}
            title={category.render(groupData, rowData)}
            showSequence={showSequence}
            sequenceOffset={sequenceOffset}
          />
        );
      })}
    </>
  );
}

CategorizedTable.defaultProps = {
  showSequence: false,
  sequenceOffset: 0,
};

export default CategorizedTable;
