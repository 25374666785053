import { Document } from 'types/document';
import httpClient from '../http-client';

// assigning main document (mainId) + other related documents (ids) to an entity
// if entityId exists, meaning it's a non-SF integrated user
// sfEntityId is the SF id of the entity, we probably need to create the entity in backend first
export default async (mainId: string, ids: string[], entityId: string | null): Promise<Document[]> => {
  const { data } = await httpClient.post<Document[]>(`/documents/${mainId}/set-entity`, {
    entityId,
    docIds: ids,
  });
  return data;
};
