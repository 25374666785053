/* eslint-disable no-plusplus, import/no-cycle */
import { cloneDeep, isEqual } from 'lodash';
import {
  BaseOperation,
  Editor,
  Element,
  Location,
  Node,
  NodeOperation,
  Path,
  Point,
  Range,
  SelectionOperation,
  Transforms,
} from 'slate';
import { emitDocumentEvent } from 'utils/event';
import NoteEditor from '../NoteEditor';
import { BlockTypeInline, CustomText, DocSource, LinkElement, MentionElement } from '../types';

export const isLinkActive = (editor: Editor) => {
  const [link] = Editor.nodes(editor, {
    match: n => !Editor.isEditor(n) && Element.isElement(n) && n.type === 'link',
  });
  return !!link;
};

export const unwrapEmptyLink = (editor: Editor) => {
  Transforms.unwrapNodes(editor, {
    match: n => !Editor.isEditor(n) && Element.isElement(n) && n.type === 'link' && (n as LinkElement).link === '',
  });
};

export const unwrapLink = (editor: Editor, at: Location | null) => {
  if (at) {
    Transforms.unwrapNodes(editor, {
      match: n => !Editor.isEditor(n) && Element.isElement(n) && n.type === 'link',
      at,
    });
  } else {
    Transforms.unwrapNodes(editor, {
      match: n => !Editor.isEditor(n) && Element.isElement(n) && n.type === 'link',
    });
  }
};

export const wrapLink = (editor: Editor, link: string, beingEditedBy: null | string = null) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor, null);
  }
  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const linkEle: LinkElement = {
    type: BlockTypeInline.Link,
    link,
    beingEditedBy,
    children: isCollapsed ? [{ text: link }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, [linkEle, { text: '' }]);
  } else {
    Transforms.wrapNodes(editor, linkEle, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
    Transforms.move(editor, { distance: 1, unit: 'offset' });
  }
};

const withInlines = (
  editor: Editor,
  docSource?: DocSource,
  documentId?: string,
  opportunityId?: string,
  disableMention = false,
) => {
  const { insertText, isInline, isVoid, markableVoid, apply } = editor;

  // eslint-disable-next-line no-param-reassign
  editor.isInline = element => {
    return element.type === BlockTypeInline.Link || element.type === BlockTypeInline.Mention || isInline(element);
  };

  // eslint-disable-next-line no-param-reassign
  editor.isVoid = element => {
    if (element.type === BlockTypeInline.Mention) {
      return true;
    }
    return isVoid(element);
  };

  // eslint-disable-next-line no-param-reassign
  editor.markableVoid = element => {
    if (element.type === BlockTypeInline.Mention) {
      return true;
    }
    return markableVoid(element);
  };

  // eslint-disable-next-line no-param-reassign
  editor.insertText = text => {
    const { selection } = editor;
    // if select multi cell in table
    if (
      selection &&
      Range.isExpanded(selection) &&
      (selection.anchor.path.length >= 4 || selection.focus.path.length >= 4) &&
      !isEqual(selection.anchor.path.slice(0, 3), selection.focus.path.slice(0, 3))
    ) {
      const insertPoint = Point.isBefore(selection.anchor, selection.focus) ? selection.anchor : selection.focus;
      Transforms.select(editor, { focus: insertPoint, anchor: insertPoint });
    }
    if (text === '@' && !disableMention) {
      NoteEditor.addNewMentionAfterAt(editor);
      return;
    }
    insertText(text);
  };

  // eslint-disable-next-line no-param-reassign
  editor.apply = (op: BaseOperation) => {
    let newOp = op as SelectionOperation | NodeOperation;
    // move cursor to next element when focus at end of inline block(link/comment)
    if (op.type === 'set_selection') {
      if (Range.isRange(op.newProperties) && Range.isCollapsed(op.newProperties)) {
        const currentNode = Node.has(editor, op.newProperties.anchor.path)
          ? Node.get(editor, op.newProperties.anchor.path)
          : undefined;
        const inlineBlockPath = (currentNode as CustomText)?.comment
          ? op.newProperties.anchor.path
          : NoteEditor.getParentPathByType(editor, op.newProperties.anchor.path, BlockTypeInline.Link);
        if (inlineBlockPath && Point.equals(Editor.end(editor, inlineBlockPath), op.newProperties.anchor)) {
          const inlineBlockParentPath = inlineBlockPath.slice(0, -1);
          const nextEntry = Editor.next(editor, { at: inlineBlockPath });
          if (nextEntry) {
            const [, nextEntryPath] = nextEntry;
            if (Path.isAncestor(inlineBlockParentPath, nextEntryPath)) {
              const start = Editor.start(editor, nextEntryPath);
              newOp = {
                ...newOp,
                newProperties: {
                  anchor: start,
                  focus: start,
                },
              } as SelectionOperation;
            }
          }
        }
      }
    } else if (op.type === 'remove_node') {
      if (documentId) {
        const mentionNodeInfos = NoteEditor.nodes(editor, {
          at: op.path,
          match: n => {
            return Element.isElement(n) && n.type === BlockTypeInline.Mention;
          },
          mode: 'all',
        });
        let nodeInfo = mentionNodeInfos.next();
        const mentionNodes: MentionElement[] = [];
        while (!nodeInfo.done) {
          if (nodeInfo.value) {
            const node = nodeInfo.value[0] as MentionElement;
            if (node.mentionId) {
              mentionNodes.push(cloneDeep(node));
            }
          }
          nodeInfo = mentionNodeInfos.next();
        }
        if (mentionNodes.length && docSource) {
          emitDocumentEvent({
            opportunityId: opportunityId || '',
            documentId: documentId || '',
            type: 'MENTION_DELETE',
            sourceElement: mentionNodes,
            commentContent: null,
            docSource,
          });
        }
      }
    } else if (op.type === 'set_node') {
      const newProperties = op.newProperties as Partial<CustomText>;
      // when undo add new comment, unset comment init status
      if (newProperties.commentInit === true && newProperties.comment === null) {
        newOp = {
          ...newOp,
          newProperties: {
            ...newProperties,
            commentInit: undefined,
          },
        } as NodeOperation;
      } else {
        const newMentionProperties = op.newProperties as Partial<MentionElement>;
        if (newMentionProperties.mentionByUserId && newMentionProperties.mentionUserId && docSource) {
          const { path } = op;
          const node = Node.has(editor, path) && Node.get(editor, path);
          if (node) {
            const nodeInfo = cloneDeep(node) as MentionElement;
            nodeInfo.mentionUserId = newMentionProperties.mentionUserId;
            nodeInfo.mentionByUserId = newMentionProperties.mentionByUserId;
            emitDocumentEvent({
              opportunityId: opportunityId || '',
              documentId: documentId || '',
              type: 'MENTION_CREATE',
              sourceElement: nodeInfo,
              commentContent: null,
              docSource,
            });
          }
        }
      }
    }
    apply(newOp);
  };

  return editor;
};

export default withInlines;
