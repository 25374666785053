import React from 'react';

interface Props {
  className?: string;
}

function NotEditableIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.00065 9.59234L5.92665 12.6663H3.33398V10.0737L6.40798 6.99967M7.74132 5.66634L10.0747 3.33301L12.6673 5.92567L10.334 8.25901"
        stroke="#AFB9CC"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M4 4L12 12" stroke="#AFB9CC" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

NotEditableIcon.defaultProps = {
  className: undefined,
};

export default NotEditableIcon;
