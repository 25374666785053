import React, { useRef, useState } from 'react';
import SortIcon from '../../components/icons/SortIcon';
import { ColumnConfig, GroupColumnDefinition } from '../../components/Table/types';
import Popover from '../../components/Popover';
import SortFieldMenu from '../../components/Table/SortFieldMenu';

interface Props {
  tableConfig: ColumnConfig[];
  updateTableConfig: (config: ColumnConfig[]) => void;
}

function SortButton({ tableConfig, updateTableConfig }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);
  let numOfFiltersApplied = 0;
  tableConfig.forEach(c => {
    if ('group' in c) {
      c.columns.forEach(col => {
        if (col.sort) numOfFiltersApplied += 1;
      });
    } else if (c.sort) {
      numOfFiltersApplied += 1;
    }
  });

  return (
    <>
      <button
        ref={ref}
        type="button"
        className={`flex items-center gap-1 hover:bg-gray-100 rounded p-1 ${
          numOfFiltersApplied > 0 ? 'text-green-500' : 'text-gray-400'
        }`}
        onClick={() => setIsOpen(true)}
      >
        <SortIcon />
        Sort{' '}
        {numOfFiltersApplied > 0 && (
          <span className="w-4 h-4 text-xs bg-green-200 rounded-full text-center leading-4">{numOfFiltersApplied}</span>
        )}
      </button>
      <Popover
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        anchorEl={ref.current}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <div className="p-3 pt-11 w-64 max-h-[20rem] overflow-y-auto">
          <SortFieldMenu
            columnConfigs={tableConfig}
            updateColumnConfigs={(config, closePopover) => {
              updateTableConfig(config as GroupColumnDefinition[]);
              if (closePopover) {
                setIsOpen(false);
              }
            }}
          />
        </div>
      </Popover>
    </>
  );
}

export default SortButton;
