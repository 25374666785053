import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { NumericFormat } from 'react-number-format';

import SpinLoader from 'components/icons/SpinLoader';
import { SalesforceFieldType } from 'types/salesforce';
import isHotkey from 'is-hotkey';

interface Props {
  isEditable: boolean;
  isUpdating: boolean;
  isEdited: boolean;
  format:
    | SalesforceFieldType.INT
    | SalesforceFieldType.DOUBLE
    | SalesforceFieldType.PERCENT
    | SalesforceFieldType.PHONE
    | SalesforceFieldType.CURRENCY;
  answer: number;
  onAnswerChange: (answer: number) => void;
  onCancel: () => void;
  onSubmit: () => Promise<void>;
  autoFocus?: boolean;
}

function NumberField({
  isEditable,
  isUpdating,
  isEdited,
  format,
  answer,
  onAnswerChange,
  onCancel,
  onSubmit,
  autoFocus,
}: Props) {
  return (
    <div className="w-full">
      <NumericFormat
        className="text-sm p-2 bg-transparent text-gray-500 w-full"
        value={answer}
        onValueChange={values => {
          const newAnswer = values.floatValue || 0;
          if (newAnswer !== answer) onAnswerChange(values.floatValue || 0);
        }}
        allowLeadingZeros={false}
        thousandSeparator={format !== SalesforceFieldType.PHONE ? ',' : ''}
        suffix={format === SalesforceFieldType.PERCENT ? '%' : undefined}
        prefix={format === SalesforceFieldType.CURRENCY ? '$' : undefined}
        disabled={!isEditable || isUpdating}
        onCopy={(e: any) => e?.stopPropagation?.()}
        onPaste={(e: any) => e?.stopPropagation?.()}
        onKeyDown={(e: React.KeyboardEvent) => {
          if (e.key === 'Enter' && isEdited) {
            e.preventDefault();
            onSubmit();
          }
          if (isHotkey('mod+a', e)) {
            e.stopPropagation();
          }
        }}
        autoFocus={autoFocus}
      />
      {isEditable && (
        <div className="w-full flex justify-between items-center mt-1">
          <div className="flex items-center gap-1 text-xs">
            {isUpdating && (
              <>
                <SpinLoader className="w-5 h-5 p-1 animate-spin" />
                <span>Updating...</span>
              </>
            )}
          </div>
          {isEdited && (
            <div className="flex items-center gap-1">
              <button className="text-white" type="button" onClick={onCancel}>
                <XMarkIcon className="w-5 h-5 p-0.5 rounded-full bg-gray-400" />
              </button>
              <button className="text-white" type="button" disabled={!isEdited || isUpdating} onClick={onSubmit}>
                {isUpdating ? (
                  <SpinLoader className="w-5 h-5 p-1 rounded-full bg-blue-500 animate-spin" />
                ) : (
                  <CheckIcon className={`w-5 h-5 p-0.5 rounded-full ${!isEdited ? 'bg-gray-400' : 'bg-blue-500'}`} />
                )}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

NumberField.defaultProps = {
  autoFocus: false,
};

export default NumberField;
