/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unstable-nested-components */
import { PencilSquareIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { ProductCategory, ProductCategoryStatus } from 'types/product-gap';
import BaseButton from 'components/BaseButton';
import { ControlledFormProps } from 'types/form';
import { Controller } from 'react-hook-form';
import { noop } from 'lodash';
import FormField from 'components/Form/FormField';
import InputField from 'components/Form/InputField';
import SelectField from 'components/Form/SelectField';
import { requiredInputValidate } from 'utils/validate';
import useProductGapStore from 'stores/product-gap';
import { openDeleteConfirmModal } from 'components/BaseModal';

interface Props {
  addEditStatus: (status?: ProductCategoryStatus) => Promise<ProductCategoryStatus | undefined>;
}

function CreateNewCategoryForm({
  addEditStatus,
  register,
  control,
  errors,
}: Props & ControlledFormProps<ProductCategory>) {
  const store = useProductGapStore();

  return (
    <>
      <FormField
        className="mb-3"
        label="Category title"
        labelClassName="w-[12rem]"
        error={errors.title ? errors.title.message || 'Title is required' : ''}
      >
        <InputField {...register('title', requiredInputValidate)} placeholder="Enter Category Title" />
      </FormField>

      <FormField className="mb-3" label="Category description" labelClassName="w-[12rem]">
        <InputField {...register('description')} placeholder="Enter Category Description" multiline />
      </FormField>

      <FormField className="mb-3" label="Status" labelClassName="w-[12rem]">
        <Controller
          name="status.id"
          control={control}
          render={({ field }) => (
            <SelectField
              placeholder="Select Status"
              showClearBtn
              ref={field.ref}
              value={field.value}
              onChange={field.onChange}
              menus={store.categoryStatuses.map(item => ({
                value: item.id,
                label: item.name,
                item,
              }))}
              renderMenu={menu => (
                <>
                  <div className="text-sm font-medium truncate flex-1">{menu.label}</div>
                  <div className="hide-when-show-as-value">
                    <BaseButton
                      className="!min-w-[2rem] !h-8"
                      tooltip="Edit status"
                      hideTooltipToPreventJump
                      color="secondary"
                      variant="text"
                      iconBtn
                      onClick={() => addEditStatus(menu.item as ProductCategoryStatus)}
                    >
                      <PencilSquareIcon className="w-6 h-6" />
                    </BaseButton>
                    <BaseButton
                      className="!min-w-[2rem] !h-8"
                      tooltip="Delete status"
                      hideTooltipToPreventJump
                      color="error"
                      variant="text"
                      iconBtn
                      onClick={e => {
                        e.stopPropagation();
                        openDeleteConfirmModal(
                          `Are you sure you want to delete this category status?`,
                          'This action cannot be undone.',
                          async () => {
                            try {
                              await store.deleteCategoryStatus(menu.value as string);
                              if (field.value === menu.value) {
                                field.onChange(undefined);
                              }
                              return;
                            } catch {
                              noop();
                            }
                          },
                        );
                      }}
                    >
                      <TrashIcon className="w-6 h-6" />
                    </BaseButton>
                  </div>
                </>
              )}
            />
          )}
        />
        <BaseButton
          className="!min-w-[2rem] !h-8 !ml-3"
          tooltip="Add new status"
          hideTooltipToPreventJump
          color="secondary"
          variant="text"
          iconBtn
          onClick={() => addEditStatus()}
        >
          <PlusIcon width={24} />
        </BaseButton>
      </FormField>
    </>
  );
}

export default CreateNewCategoryForm;
