import { throttle } from 'lodash';
import { useCallback, useRef, useState } from 'react';

const DEFAULT_DEBOUNCE_TIME = 30;

interface CursorLocation {
  x: number;
  y: number;
}

function useDragHandler<T>(enterDragOffset: number, debounceTime = DEFAULT_DEBOUNCE_TIME) {
  const mouseDownPointRef = useRef<CursorLocation>({ x: 0, y: 0 });
  const startDragPointRef = useRef<CursorLocation>({ x: 0, y: 0 });
  const currentDragPointRef = useRef<CursorLocation>({ x: 0, y: 0 });
  const lastDragPointRef = useRef<CursorLocation>({ x: 0, y: 0 });
  const draggingRef = useRef(false);
  const [dragging, setDragging] = useState(false);
  const [offsetX, setOffsetX] = useState(0);
  const [offsetY, setOffsetY] = useState(0);
  const [draggingItem, setDraggingItem] = useState<T>();

  const handleMouseMove = useCallback(
    throttle((event: MouseEvent) => {
      if (draggingRef.current) {
        lastDragPointRef.current.x = currentDragPointRef.current.x;
        lastDragPointRef.current.y = currentDragPointRef.current.y;
        currentDragPointRef.current.x = event.clientX;
        currentDragPointRef.current.y = event.clientY;
        setOffsetX(event.clientX - startDragPointRef.current.x);
        setOffsetY(event.clientY - startDragPointRef.current.y);
      } else if (
        Math.abs(event.clientX - mouseDownPointRef.current.x) + Math.abs(event.clientY - mouseDownPointRef.current.y) >=
        enterDragOffset
      ) {
        draggingRef.current = true;
        startDragPointRef.current.x = event.clientX;
        startDragPointRef.current.y = event.clientY;
        setDragging(true);
        setOffsetX(0);
        setOffsetY(0);
        document.body.classList.add('dragging');
      }
    }, debounceTime),
    [],
  );

  const handleMouseUp = useCallback(() => {
    draggingRef.current = false;
    setDragging(false);
    setDraggingItem(undefined);
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
    document.body.classList.remove('dragging');
  }, []);

  const onMouseDown = useCallback((event: React.MouseEvent) => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    mouseDownPointRef.current.x = event.clientX;
    mouseDownPointRef.current.y = event.clientY;
  }, []);

  return {
    onMouseDown,
    dragging,
    offsetX,
    offsetY,
    startDragPointRef,
    currentDragPointRef,
    lastDragPointRef,
    draggingItem,
    setDraggingItem,
  };
}

export default useDragHandler;
