/* eslint-disable jsx-a11y/media-has-caption, no-use-before-define */
import React, {
  useState,
  useCallback,
  useEffect,
  lazy,
  Suspense,
  useRef,
  forwardRef,
  useImperativeHandle,
  useMemo,
} from 'react';
import MicIcon from '../icons/MicIcon';
import { StopIcon, PlayIcon, PauseIcon } from '@heroicons/react/24/solid';
import { TrashIcon } from '@heroicons/react/24/outline';
import styles from './AudioRecorder.module.css';
import { concat } from 'utils/styling';
import usePopupMessageStore from 'stores/popup-message';
import { SuperpanelAPIError } from 'utils/errors';
import BaseButton from 'components/BaseButton';
import { openWarningConfirmModal } from 'components/BaseModal';
import moment from 'moment';
import constants from 'utils/constants';
import fixWebmDuration from 'webm-duration-fix';

type AudioRecorderStatus = 'stopped' | 'recording' | 'paused' | 'saving';
const AudioTypes = ['audio/webm', 'audio/mp4', 'audio/ogg'];

const AUDIO_BITS_PER_SECOND = 64000;

const audioTrackConstraints: MediaTrackConstraints = {
  echoCancellation: true,
  noiseSuppression: true,
};

const LiveAudioVisualizerComponent = lazy(async () => {
  const { LiveAudioVisualizer } = await import('react-audio-visualize');
  return { default: LiveAudioVisualizer };
});

export type AudioRecorderRef = {
  startRecording: () => void;
  stopRecording: (playSound: boolean, notSave: boolean) => void;
};

interface Props {
  onRecordingComplete?: (file: File, type: string) => Promise<void>;
  onBeforeRecording?: () => Promise<MediaDeviceInfo | null>;
  onDeleteRecording?: () => Promise<boolean>;
  maxRecordingTime?: number;
  onRecordStatusChange?: (status: AudioRecorderStatus) => void;
}

const AudioRecorder = forwardRef<AudioRecorderRef, Props>(
  (
    { onRecordingComplete, onBeforeRecording, onDeleteRecording, maxRecordingTime, onRecordStatusChange }: Props,
    ref,
  ) => {
    const [status, setStatus] = useState<AudioRecorderStatus>('stopped');
    const [recordingTime, setRecordingTime] = useState(0);
    const timerIntervalRef = useRef<NodeJS.Timer>();
    const popupMessageStore = usePopupMessageStore();
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const notSaveTagRef = useRef(false);
    const audioChunksRef = useRef<Blob[]>([]);
    const supportedVideoType = useMemo(() => AudioTypes.find(type => MediaRecorder.isTypeSupported(type)), []);

    const saveAudio = useCallback(async (recordingBlob2: Blob, type: string) => {
      try {
        setStatus('saving');
        const fileName = `voice recording ${moment().format(constants.DATETIME_WITH_YEAR_FORMAT)}`;
        await onRecordingComplete?.(new File([recordingBlob2], fileName, { type }), type);
        setRecordingTime(0);
        setStatus('stopped');
      } catch (e) {
        const err = e as SuperpanelAPIError;
        popupMessageStore.error(`Failed to save recorded audio: ${err.displayErrorMessage || err.message}`);
        setStatus('paused');
      }
    }, []);

    const stopTimer = useCallback((parse: boolean) => {
      if (timerIntervalRef.current) {
        clearInterval(timerIntervalRef.current);
        timerIntervalRef.current = undefined;
      }
      if (!parse) {
        setRecordingTime(0);
      }
    }, []);

    const startTimer = useCallback(() => {
      const interval = setInterval(() => {
        setRecordingTime(time => {
          if (maxRecordingTime && time >= maxRecordingTime) {
            togglePauseResume();
            openWarningConfirmModal(
              `You can at max record ${Math.round(
                maxRecordingTime / 60,
              )} minutes, please save current recording first.`,
              null,
              'Save Recording',
              '',
            ).then(save => {
              if (save) {
                stopRecording(true, false);
              }
            });
            return time;
          }
          return time + 1;
        });
      }, 1000);
      timerIntervalRef.current = interval;
    }, []);

    /**
     * Calling this method would result in the recording to start. Sets `isRecording` to true
     */
    const startRecording = useCallback(async () => {
      if (!supportedVideoType) {
        openWarningConfirmModal(
          `Your browser does not support recording audio, please upgrade it or use other modern browsers.`,
          null,
          'OK',
          '',
        );
        return;
      }
      let device: MediaDeviceInfo | null = null;
      if (onBeforeRecording) {
        device = await onBeforeRecording();
        if (!device) {
          return;
        }
      }
      notSaveTagRef.current = false;
      audioChunksRef.current = [];
      navigator.mediaDevices
        .getUserMedia({
          audio: {
            deviceId: device?.deviceId,
            groupId: device?.groupId,
            ...audioTrackConstraints,
          },
          video: false,
        })
        .then(stream => {
          (document.getElementById('start-record-sound') as HTMLAudioElement)?.play();
          setStatus('recording');
          const recorder: MediaRecorder = new MediaRecorder(stream, {
            audioBitsPerSecond: AUDIO_BITS_PER_SECOND,
            mimeType: supportedVideoType,
          });
          recorder.start();
          mediaRecorderRef.current = recorder;
          startTimer();
          recorder.ondataavailable = event => {
            if (event.data && event.data.size > 0) {
              audioChunksRef.current.push(event.data);
            }
          };
          recorder.onstop = () => {
            if (!notSaveTagRef.current) {
              if (audioChunksRef.current.length) {
                if (supportedVideoType.includes('webm')) {
                  // fix webm recording no duration meta info issue which make recording can not play in safari and progress can not seekable
                  // https://github.com/buynao/webm-duration-fix, https://bugs.chromium.org/p/chromium/issues/detail?id=642012
                  fixWebmDuration(new Blob([...audioChunksRef.current], { type: supportedVideoType })).then(
                    fixedBlob => {
                      saveAudio(fixedBlob, supportedVideoType);
                    },
                  );
                } else {
                  saveAudio(new Blob([...audioChunksRef.current], { type: supportedVideoType }), supportedVideoType);
                }
              }
            } else {
              setStatus('stopped');
              stopTimer(false);
            }
          };
          recorder.onerror = event => {
            popupMessageStore.error(`audio recorder error: ${event}`);
            setStatus('stopped');
            stopTimer(false);
          };
        })
        .catch((err: DOMException) => {
          popupMessageStore.error(`Failed to start record audio: ${err.message}`);
        });
    }, [onBeforeRecording, supportedVideoType, supportedVideoType]);

    /**
     * Calling this method results in a recording in progress being stopped and the resulting audio being present in `recordingBlob`. Sets `isRecording` to false
     */
    const stopRecording = useCallback((playSound: boolean, notSave: boolean) => {
      if (playSound) {
        (document.getElementById('stop-record-sound') as HTMLAudioElement)?.play();
      }
      mediaRecorderRef.current?.stop();
      mediaRecorderRef.current = null;
      stopTimer(true);
      notSaveTagRef.current = notSave;
    }, []);

    /**
     * Calling this method would pause the recording if it is currently running or resume if it is paused. Toggles the value `isPaused`
     */
    const togglePauseResume = useCallback(() => {
      setStatus(prev => {
        if (prev === 'paused') {
          mediaRecorderRef.current?.resume();
          startTimer();
          return 'recording';
        } else {
          stopTimer(true);
          mediaRecorderRef.current?.pause();
          return 'paused';
        }
      });
    }, []);

    /**
     * Calling this method would try to delete the recording.
     */
    const deleteAudioRecord = useCallback(async () => {
      setStatus('paused');
      // save pause status, not change pause status while go deleting
      // if is not paused, will pause first
      stopTimer(true);
      mediaRecorderRef.current?.pause();
      if (onDeleteRecording) {
        // wait onDeleteRecording function result
        // if flag is false, stop pause and go recording
        const flag = await onDeleteRecording();
        if (!flag) {
          mediaRecorderRef.current?.resume();
          startTimer();
          setStatus('recording');
          return;
        }
      }

      stopRecording(false, true);
    }, [onDeleteRecording]);

    useImperativeHandle(ref, () => {
      return {
        startRecording,
        stopRecording,
      };
    });

    useEffect(() => {
      onRecordStatusChange?.(status);
    }, [status]);

    useEffect(() => {
      return () => {
        // guaranteed stop recording when destroy
        stopRecording(false, true);
        onRecordStatusChange?.('stopped');
      };
    }, []);

    let recordingButton: JSX.Element | null = null;
    if (status !== 'stopped') {
      recordingButton = (
        <>
          <span className={`${styles['audio-recorder-timer']}`}>
            {Math.floor(recordingTime / 60)}:{String(recordingTime % 60).padStart(2, '0')}
          </span>
          <span className={`${styles['audio-recorder-visualizer']}`}>
            {!!mediaRecorderRef.current && (
              <Suspense>
                <LiveAudioVisualizerComponent
                  mediaRecorder={mediaRecorderRef.current}
                  barWidth={2}
                  gap={2}
                  width={100}
                  height={20}
                  fftSize={512}
                  maxDecibels={-10}
                  minDecibels={-80}
                  smoothingTimeConstant={0.4}
                />
              </Suspense>
            )}
          </span>
          <BaseButton
            iconBtn
            color="secondary"
            variant="outlined"
            className={styles['audio-recorder-button']}
            onClick={togglePauseResume}
            disabled={status === 'saving'}
          >
            {status === 'paused' ? (
              <PlayIcon className={styles['audio-recorder-icon']} />
            ) : (
              <PauseIcon className={styles['audio-recorder-icon']} />
            )}
          </BaseButton>
          <BaseButton
            iconBtn
            color="error"
            variant="contained"
            className={styles['audio-recorder-button']}
            onClick={() => stopRecording(true, false)}
            loading={status === 'saving'}
            disabled={status === 'saving'}
          >
            <StopIcon className={styles['audio-recorder-icon']} />
          </BaseButton>
          <BaseButton
            iconBtn
            color="secondary"
            variant="outlined"
            className={styles['audio-recorder-button']}
            onClick={deleteAudioRecord}
            disabled={status === 'saving'}
          >
            <TrashIcon className={styles['audio-recorder-icon']} />
          </BaseButton>
        </>
      );
    } else {
      recordingButton = (
        <BaseButton
          iconBtn
          color="primary"
          variant="contained"
          className={concat(styles['audio-recorder-button'], styles['audio-recorder-large-button'])}
          onClick={startRecording}
        >
          <MicIcon className={styles['audio-recorder-large-icon']} />
        </BaseButton>
      );
    }

    return (
      <div className={`${styles['audio-recorder']} ${status !== 'stopped' && styles.recording}`}>
        <audio autoPlay={false} id="start-record-sound" src="/audios/start.mp3" />
        <audio autoPlay={false} id="stop-record-sound" src="/audios/stop.mp3" />
        {recordingButton}
      </div>
    );
  },
);

AudioRecorder.displayName = 'AudioRecorder';

AudioRecorder.defaultProps = {
  onRecordingComplete: undefined,
  onBeforeRecording: undefined,
  onDeleteRecording: undefined,
  maxRecordingTime: 60 * 30, // max recording time is 30 min as default
  onRecordStatusChange: undefined,
};

export default AudioRecorder;
