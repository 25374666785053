/* eslint-disable consistent-return, no-else-return, no-unused-expressions */
import { MicrophoneIcon } from '@heroicons/react/24/outline';
import { BaseModal } from 'components/BaseModal';
import SelectField from 'components/Form/SelectField';
import { isEqual } from 'lodash';
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { ListItem } from 'types/list-item';
import { ModalConfirmAction, ModalCloseAction, ModalAction } from 'types/modal';

const DEVICE_PERMISSION_DENIED_MSG = 'Permission denied';
const DEVICE_PERMISSION_DENIED_CODE = 0;

interface Props {
  modalId: string;
}

function AudioDeviceEnumModal({ modalId }: Props) {
  const [microphones, setMicrophones] = useState<ListItem<string>[]>();
  const [currentMicrophone, setCurrenMicrophone] = useState<ListItem<string>>();
  const [enumerateDevicesErr, setEnumerateDevicesErr] = useState<Error>();
  const [enumerating, setEnumerating] = useState(false);
  const microphonesRef = useRef<ListItem<string>[]>();
  const currentMicrophoneRef = useRef<ListItem<string>>();

  useEffect(() => {
    microphonesRef.current = microphones;
  }, [microphones]);

  useEffect(() => {
    currentMicrophoneRef.current = currentMicrophone;
  }, [currentMicrophone]);

  useEffect(() => {
    const enumAudioDeivces = (existingsMics?: ListItem<string>[], currentMic?: ListItem<string>) => {
      !existingsMics && setEnumerating(true);
      // get user media first to check permission
      navigator.mediaDevices?.getUserMedia({ audio: true }).then(
        () => {
          navigator.mediaDevices.enumerateDevices().then(
            devices => {
              !existingsMics && setEnumerating(false);
              const audioMicrophones = devices
                .filter(
                  device =>
                    device.kind === 'audioinput' && device.deviceId && device.groupId && device.deviceId !== 'default',
                )
                .sort((dev1, dev2) => {
                  const defaultDevice = devices.find(device => device.deviceId === 'default');
                  if (dev1.groupId === defaultDevice?.groupId) {
                    return -1;
                  }
                  if (dev2.groupId === defaultDevice?.groupId) {
                    return 1;
                  }
                  return 0;
                });
              const mics = audioMicrophones.map(mic => ({
                icon: <MicrophoneIcon className="w-5 h-5 mr-2" />,
                label: mic.label,
                value: mic.deviceId,
                item: mic,
              }));
              const micsChanged = !isEqual(
                mics.map(mic => mic.value),
                existingsMics?.map(mic => mic.value),
              );
              if (micsChanged) {
                setMicrophones(mics);
                if (!mics.find(mic => mic.value === currentMic?.value)) {
                  setCurrenMicrophone(mics[0]);
                }
              }
            },
            err => {
              !existingsMics && setEnumerating(false);
              !existingsMics && setEnumerateDevicesErr(err);
            },
          );
        },
        err => {
          !existingsMics && setEnumerating(false);
          !existingsMics && setEnumerateDevicesErr(err);
        },
      );
    };

    if (
      typeof navigator.mediaDevices?.enumerateDevices === 'function' &&
      typeof navigator.mediaDevices?.getUserMedia === 'function'
    ) {
      enumAudioDeivces();
      // keep enum audio devices to detect devices change
      const interval = setInterval(() => enumAudioDeivces(microphonesRef.current, currentMicrophoneRef.current), 3000);
      return () => {
        clearInterval(interval);
      };
    } else {
      setEnumerateDevicesErr(new Error('Your browser do not support recording audios!'));
    }
  }, []);

  const modalActions = useMemo(
    () =>
      [
        {
          label: 'Cancel',
          action: ModalCloseAction,
          color: 'secondary',
        },
        {
          label: 'Start recording',
          action: ModalConfirmAction,
          cb: () => Promise.resolve(currentMicrophone?.item),
          color: 'primary',
          disabled: !currentMicrophone,
        },
      ] as ModalAction[],
    [currentMicrophone],
  );

  const isPermissionDenied = useMemo(
    () =>
      enumerateDevicesErr?.message.toLowerCase().startsWith(DEVICE_PERMISSION_DENIED_MSG.toLowerCase()) ||
      (enumerateDevicesErr as unknown as { code: number })?.code === DEVICE_PERMISSION_DENIED_CODE,
    [enumerateDevicesErr],
  );

  return (
    <BaseModal
      title="Select Microphone to Record"
      modalId={modalId}
      actions={modalActions}
      bodyClassName="w-[480px] !pt-[32px] !pb-[48px]"
      body={
        <>
          <SelectField
            size="small"
            placeholder="Select Microphone"
            value={currentMicrophone?.value}
            onChange={newV => setCurrenMicrophone(microphones?.find(mic => mic.value === newV))}
            menus={microphones || []}
            errorLabel={enumerateDevicesErr?.message}
            loading={enumerating}
          />
          {isPermissionDenied && (
            <div className="text-sm text-red-500 pt-4">
              Microphone permission denied, you should grant permission in browser settings first to record audio.
            </div>
          )}
        </>
      }
    />
  );
}

export default AudioDeviceEnumModal;
