/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React, { useLayoutEffect, useState } from 'react';

interface Props {
  width: number;
  setWidth: (number: number) => void;
}

function ResizeBorderHandle({ width, setWidth }: Props) {
  const [tempWidth, setTempWidth] = useState<number>(width);

  useLayoutEffect(() => {
    setTempWidth(width);
  }, [width]);

  useLayoutEffect(() => {
    if (width !== tempWidth) setWidth(tempWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempWidth]);

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const containerRect = e.target.parentNode.getBoundingClientRect();
    const newWidth = e.pageX - containerRect.left;
    if (newWidth > 50) {
      setTempWidth(newWidth);
    }
  };

  return (
    <div
      className="absolute top-0 -right-1 bottom-0 w-2 cursor-col-resize hover:bg-blue-300 flex-shrink-0 flex-grow-0"
      draggable="true"
      onDrag={handleDrag}
      onMouseDown={e => e.stopPropagation()}
    />
  );
}

export default ResizeBorderHandle;
