import { User } from 'types/user';
import httpClient from './http-client';

export interface Response {
  user: User;
}

export default async (integrationName: 'chorus' | 'productboard', apiToken: string): Promise<Response> => {
  const { data } = await httpClient.post<Response>('/integration-token', {
    integrationName,
    apiToken,
  });
  return data;
};
