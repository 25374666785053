/* eslint-disable no-nested-ternary */

import React, { useMemo, useRef, useState } from 'react';

import CalendarIcon from 'components/icons/CalendarIcon';
import NoteIcon from 'components/icons/NoteIcon';
import OpportunityIcon from 'components/icons/OpportunityIcon';
import SearchIcon from 'components/icons/SearchIcon';
import AccountIcon from './icons/AccountIcon';
import CornerArrowIcon from './icons/CornerArrow';
import searchItems from '../api/search/search-items';
import Loader from './Loader';
import SearchItemView, { SearchItemProps as SearchItem, SearchItemTypeProps } from './SearchModal/SearchItemView';
import useDocumentStore from '../stores/document';

interface State {
  searchValue: string;
  finalValue: null | string;
  searchDocument: boolean;
  searchOpportunity: boolean;
  searchAccount: boolean;
  searchEvent: boolean;
  isSearching: boolean;
  isCreatingEntity: boolean;
  searchItems: SearchItem[];
}

function SearchModal() {
  const [state, setState] = useState<State>({
    searchValue: '',
    finalValue: null,
    searchDocument: false,
    searchOpportunity: false,
    searchAccount: false,
    searchEvent: false,
    isSearching: false,
    isCreatingEntity: false,
    searchItems: [],
  });

  const inputRef = useRef<HTMLInputElement>(null);
  const documentStore = useDocumentStore();

  const fetchSearchItems = async (updateSearchingFor: null | 'document' | 'opportunity' | 'account' | 'event') => {
    setState(prevState => ({ ...prevState, isSearching: true, searchItems: [] }));
    let { searchDocument, searchOpportunity, searchAccount, searchEvent } = state;

    if (updateSearchingFor === 'document') searchDocument = !searchDocument;
    if (updateSearchingFor === 'opportunity') searchOpportunity = !searchOpportunity;
    if (updateSearchingFor === 'account') searchAccount = !searchAccount;
    if (updateSearchingFor === 'event') searchEvent = !searchEvent;
    setState(prevState => ({ ...prevState, searchDocument, searchOpportunity, searchAccount, searchEvent }));

    const allFalse = !searchDocument && !searchOpportunity && !searchAccount && !searchEvent;
    const newSearchItems = (await searchItems(
      state.searchValue,
      allFalse ? true : searchDocument,
      allFalse ? true : searchOpportunity,
      allFalse ? true : searchAccount,
      allFalse ? true : searchEvent,
    )) as SearchItem[];
    setState(prevState => ({
      ...prevState,
      isSearching: false,
      searchItems: newSearchItems,
    }));
  };

  const searchResultsMemo = useMemo(() => {
    const items = [...state.searchItems];
    documentStore.crmNotes.forEach(crmNote => {
      if (state.finalValue && crmNote.title.toLowerCase().includes(state.finalValue.toLowerCase())) {
        const type = 'crm-note' as SearchItemTypeProps;
        items.push({
          documentId: null,
          crmId: crmNote.id,
          title: crmNote.title,
          oppStageName: null,
          type,
          datetime: crmNote.createdDate,
          parent: {
            id: crmNote.linkedEntity.id,
            type: crmNote.linkedEntity.name,
            name: 'Unknown',
          },
        });
      }
    });
    return items;
  }, [state.finalValue, state.searchItems]);

  return (
    <div className="w-[35rem]">
      <div className="border-b">
        <div className="flex items-center px-3 py-2 gap-2">
          <SearchIcon className="w-6 h-6 text-gray-400" />
          <input
            ref={inputRef}
            className="w-full text-sm placeholder:text-gray-400 placeholder:text-sm"
            placeholder="Search..."
            value={state.searchValue}
            onChange={e => setState(prevState => ({ ...prevState, searchValue: e.target.value, finalValue: null }))}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                setState(prevState => ({ ...prevState, finalValue: prevState.searchValue }));
                fetchSearchItems(null);
              }
            }}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
          />

          <button
            type="button"
            className={`${
              state.searchValue.length > 0 ? 'text-blue-600 bg-blue-100 border-blue-400' : 'text-gray-400'
            } hover:bg-blue-200 flex items-center rounded px-1 py-0.5 border`}
            onClick={() => {
              setState(prevState => ({ ...prevState, finalValue: prevState.searchValue }));
              fetchSearchItems(null);
            }}
            disabled={state.searchValue.length === 0}
          >
            <CornerArrowIcon className="w-4 h-4" />
          </button>
          <div className="rounded bg-gray-100 text-gray-400 text-sm p-1">
            {window.navigator.userAgent.indexOf('Mac') !== -1 ? '⌘' : 'Ctrl'}
          </div>
          <div className="w-6 rounded bg-gray-100 text-gray-400 text-sm p-1">K</div>
        </div>
      </div>
      <div className="p-4">
        <div className="text-sm text-gray-500">I&apos;m searching for...</div>
        <div className="mt-3 flex items-center flex-wrap gap-2">
          <button
            className={`flex items-center text-gray-500 gap-1 px-3 py-0.5 border rounded-full ${
              state.searchDocument && 'border-blue-500 text-blue-500'
            }`}
            type="button"
            onClick={() => {
              fetchSearchItems('document');
            }}
          >
            <NoteIcon className="w-5 h-5" />
            <div className="text-sm">Docs</div>
          </button>
          <button
            className={`flex items-center text-gray-500 gap-1 px-3 py-0.5 border rounded-full ${
              state.searchOpportunity && 'border-blue-500 text-blue-500'
            }`}
            type="button"
            onClick={() => {
              fetchSearchItems('opportunity');
            }}
          >
            <OpportunityIcon className="w-4 h-4 text-orange-400 fill-orange-400" />
            <div className="text-sm">Opportunity</div>
          </button>
          <button
            className={`flex items-center text-gray-500 gap-1 px-3 py-0.5 border rounded-full ${
              state.searchAccount && 'border-blue-500 text-blue-500'
            }`}
            type="button"
            onClick={() => {
              fetchSearchItems('account');
            }}
          >
            <AccountIcon className="w-4 h-4 text-orange-400 fill-orange-400" />
            <div className="text-sm">Account</div>
          </button>
          <button
            className={`flex items-center text-gray-500 gap-1 px-3 py-0.5 border rounded-full ${
              state.searchEvent && 'border-blue-500 text-blue-500'
            }`}
            type="button"
            onClick={() => {
              fetchSearchItems('event');
            }}
          >
            <CalendarIcon className="w-5 h-5" />
            <div className="text-sm">Events</div>
          </button>
        </div>
        {state.isSearching ? (
          <div className="flex flex-col justify-center items-center font-medium">
            <Loader className="w-20 h-20 mt-4" />
            <div>Searching...</div>
          </div>
        ) : searchResultsMemo.length > 0 ? (
          <div className="mt-4">
            <div className="text-sm text-gray-500">Showing {searchResultsMemo.length} results</div>
            <div className="mt-2 flex flex-col max-h-72 overflow-y-auto">
              {searchResultsMemo.map(searchItem => (
                <SearchItemView
                  key={`${searchItem.type}-${searchItem.documentId || searchItem.crmId}`}
                  searchItem={searchItem}
                />
              ))}
            </div>
          </div>
        ) : (
          state.finalValue && (
            <div className="flex flex-col justify-center items-center p-10 text-center">
              <div className="font-medium">We couldn&apos;t find any result for &quot;{state.finalValue}&quot;</div>
              <div className="text-sm text-gray-500 mt-4">
                You may want to try using different keywords or checking for typos.
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
}

export default SearchModal;
