import React from 'react';
import { MenuItem } from '@mui/material';

import EyeOffIcon from 'components/icons/EyeOffIcon';

interface Props {
  onGroupColumnHide: () => void;
  setIsMenuOpen: (isOpen: boolean) => void;
}

function HideAllColumnMenu({ onGroupColumnHide, setIsMenuOpen }: Props) {
  return (
    <MenuItem
      onClick={() => {
        onGroupColumnHide();
        setIsMenuOpen(false);
      }}
    >
      <div className="flex items-center text-sm">
        <EyeOffIcon className="w-4 h-4 mr-2" />
        Hide all columns
      </div>
    </MenuItem>
  );
}

export default HideAllColumnMenu;
