import { FieldValue } from 'types/field-value';
import httpClient from '../http-client';

export default async (
  fieldValues: {
    id: string;
    shownInDiscoveryDocument: boolean;
  }[],
): Promise<FieldValue[]> => {
  const { data } = await httpClient.post<FieldValue[]>(`/field-values`, {
    fieldValues,
  });
  return data;
};
