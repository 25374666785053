import React from 'react';
import { useNavigate } from 'react-router-dom';

import useDocumentStore from 'stores/document';

import { EntityType } from 'types/entity';

import { findDocumentNamesAndLinks } from 'utils/document-metadata';
import useSideMenuStore from '../../stores/side-menu';
import useNavMenuStore from '../../stores/nav-menu';
import AccountIcon from 'components/icons/AccountIcon';
import OpportunityIcon from 'components/icons/OpportunityIcon';
import NoteIcon from 'components/icons/NoteIcon';
import PdfIcon from 'components/icons/PdfIcon';
import WordIcon from 'components/icons/WordIcon';
import TxtIcon from 'components/icons/TxtIcon';
import { concat } from 'utils/styling';

interface Props {
  className?: string;
  documentId: string;
  crmNoteId: string | null;
  entityType: EntityType;
  postfixElement?: React.ReactNode;
}

function Breadcrumbs({ className, documentId, crmNoteId, entityType, postfixElement }: Props) {
  const navigate = useNavigate();

  const documentStore = useDocumentStore();
  const sideMenuStore = useSideMenuStore();
  const navMenuStore = useNavMenuStore();

  const nameAndLinks = findDocumentNamesAndLinks(
    documentId,
    crmNoteId,
    entityType,
    documentStore.documents,
    documentStore.crmNotes,
    documentStore.entityDocuments,
  ).reverse();

  return (
    <div className={concat('flex items-center gap-1 text-xs text-gray-400 flex-wrap', className)}>
      <button
        type="button"
        className="px-1 h-6 hover:bg-gray-100 rounded whitespace-nowrap text-left"
        onClick={() => {
          if (sideMenuStore.noteMenu === 'fixed') {
            navMenuStore.setState({
              pulseNoteMenu: true,
            });
          } else {
            sideMenuStore.setState({
              noteMenu: 'fixed',
              calendarMenu: null,
            });
          }
        }}
      >
        Documents
      </button>
      <div>/</div>
      {nameAndLinks.map((nameAndLink, index) => {
        const isLastItem = index === nameAndLinks.length - 1;
        return (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={`breadcrumbs-${index}-${nameAndLink.title}`}>
            <button
              type="button"
              className="max-w-full px-1 h-6 hover:bg-gray-100 rounded whitespace-nowrap text-left flex items-center truncate"
              onClick={() => {
                if (!isLastItem) navigate(nameAndLink.link);
              }}
              style={{ textDecoration: 'none' }}
            >
              {nameAndLink.type === 'account' && <AccountIcon className="min-w-[1rem] w-4 h-4 text-blue-500 mr-2" />}
              {nameAndLink.type === 'opportunity' && (
                <OpportunityIcon className="min-w-[1rem] w-4 h-4 fill-orange-400 text-orange-400 mr-2" />
              )}
              {nameAndLink.type === 'document' && <NoteIcon className="min-w-[1.25rem] w-5 h-5 text-gray-400 mr-1.5" />}
              {nameAndLink.type === 'pdf' && <PdfIcon className="min-w-[1.25rem] w-5 h-5 mr-1.5" />}
              {nameAndLink.type === 'word' && <WordIcon className="min-w-[1.25rem] w-5 h-5 mr-1.5" />}
              {nameAndLink.type === 'txt' && <TxtIcon className="min-w-[1.25rem] w-5 h-5 mr-1.5" />}
              <span className="truncate">{nameAndLink.title}</span>
            </button>
            {!isLastItem && <div>/</div>}
          </React.Fragment>
        );
      })}
      {postfixElement}
    </div>
  );
}

Breadcrumbs.defaultProps = {
  className: '',
  postfixElement: null,
};

export default Breadcrumbs;
