import React from 'react';

interface Props {
  className?: string;
}

function StarIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.25 1.5L11.6445 6.383L17 7.1705L13.125 10.9705L14.0395 16.337L9.25 13.8L4.4605 16.335L5.375 10.969L1.5 7.1705L6.855 6.383L9.25 1.5Z"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

StarIcon.defaultProps = {
  className: undefined,
};

export default StarIcon;
