import React, { useEffect, useState } from 'react';

import Popover, { Props as PopoverProps } from 'components/Popover';

import FieldFilter, { Props as FieldFilterProps } from './FieldFilter';
import { ColumnFilter } from './types';

type Props = Omit<FieldFilterProps & PopoverProps, 'type' | 'value' | 'children'> & {
  filter: ColumnFilter | undefined;
};

function FieldFilterPopover({
  anchorEl,
  onClose,
  isOpen,
  transformOrigin,
  anchorOrigin,
  columnDataType,
  name,
  onFilterUpdate,
  data,
  filter,
}: Props) {
  const [state, setState] = useState<ColumnFilter | undefined>(filter);

  useEffect(() => {
    setState(filter);
  }, [filter]);

  const close = () => {
    onFilterUpdate(state);
    onClose();
  };

  return (
    <Popover
      anchorEl={anchorEl}
      onClose={close}
      isOpen={isOpen}
      transformOrigin={transformOrigin}
      anchorOrigin={anchorOrigin}
    >
      <div className="w-72">
        <FieldFilter
          columnDataType={columnDataType}
          name={name}
          type={state?.type}
          value={state?.value}
          onFilterUpdate={setState}
          onClose={close}
          data={data}
        />
      </div>
    </Popover>
  );
}

export default FieldFilterPopover;
