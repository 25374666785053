import React from 'react';
import getOauthLink from 'utils/get-oauth-link';
import googleOauthGif from 'components/GIF/google-oauth.gif';

interface Props {
  onGoBack?: () => void;
}

function GoogleCalendarWarningView({ onGoBack }: Props) {
  return (
    <>
      <span className="text-gray-500">Be sure to check the box, just like the example below.</span>
      <div className="mt-5 flex justify-center">
        <div className="border-blue-500 border-2 p-2 rounded-lg">
          <img src={googleOauthGif} alt="google-gif" className="h-[500px]" />
        </div>
      </div>
      <div className="flex justify-center gap-4 mt-4">
        {onGoBack && (
          <button type="button" className="bg-gray-100 rounded-lg px-4 py-2 hover:bg-gray-200" onClick={onGoBack}>
            Go Back
          </button>
        )}
        <a
          className="bg-blue-500 text-white rounded-lg px-4 py-2 hover:bg-blue-300"
          href={getOauthLink('google', 'onboarding')}
        >
          Continue
        </a>
      </div>
    </>
  );
}

GoogleCalendarWarningView.defaultProps = {
  onGoBack: undefined,
};

export default GoogleCalendarWarningView;
