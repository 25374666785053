import httpClient from '../http-client';
import { ProductCategory } from '../../types/product-gap';

export default async (id: string, title: string, description: string, statusId: string): Promise<ProductCategory> => {
  const { data } = await httpClient.put<ProductCategory>(`/product-category/${id}`, {
    title,
    description,
    statusId,
  });
  return data;
};
