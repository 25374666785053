import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { MenuItem, Switch, Tooltip } from '@mui/material';
import { ChevronDownIcon, ChevronRightIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useParams } from 'react-router-dom';

import Popover from 'components/Popover';
import AccountIcon from 'components/icons/AccountIcon';
import MenuCollapseIcon from 'components/icons/menuCollapseIcon';
import SearchIcon from 'components/icons/SearchIcon';
import FadeIn from 'components/FadeIn';
import Loader from 'components/Loader';
import SearchModal from 'components/SearchModal';

import useDocumentStore from 'stores/document';
import useSideMenuStore from 'stores/side-menu';
import useNavMenuStore from 'stores/nav-menu';
import useModalStore from 'stores/modal';
import useUserStore from 'stores/user';
import useTemplateStore from 'stores/templates';

import { Document } from 'types/document';
import { EntityDocument } from 'types/entity';

import AccountLink from './NoteMenu/AccountLink';
import CreateNewEntityModal from './NoteMenu/CreateNewEntityModal';
import DocumentLink from './NoteMenu/DocumentLink';
import Deal from './NoteMenu/Deal';
import Filters, { FiltersRef } from './NoteMenu/Filters';
import styles from './NoteMenu.module.css';
import UserDomain from './UserDomain';
import NoteTopMenu from './NoteMenu/NoteTopMenu';
import FilterIcon from './icons/FilterIcon';
import { useNewDocumentModal } from './NewDocumentModal';
import FilterPill from './NoteMenu/Filters/FilterPill';

function filterAccountEntityDocuments(entityDocuments: EntityDocument[]): EntityDocument[] {
  const filteredEntityDocuments = entityDocuments.filter(document => document.entity.name === 'Account');
  return filteredEntityDocuments;
}

function filterOpportunityEntityDocuments(entityDocuments: EntityDocument[]): EntityDocument[] {
  const filteredEntityDocument = entityDocuments.filter(entityDocument => entityDocument.entity.name === 'Opportunity');

  return filteredEntityDocument;
}

const filterEntityDocuments = (
  entityDocuments: EntityDocument[],
): { account: EntityDocument; opportunities: EntityDocument[] }[] => {
  const accountEntityDocuments = filterAccountEntityDocuments(entityDocuments);
  const opportunityEntityDocuments = filterOpportunityEntityDocuments(entityDocuments);

  return accountEntityDocuments.map(account => {
    return {
      account,
      opportunities: opportunityEntityDocuments.filter(opportunity => opportunity.parentId === account.id),
    };
  });
};

const filterOpportunityDocuments = (entityDocuments: EntityDocument[]): EntityDocument[] => {
  const opportunityEntityDocuments = filterOpportunityEntityDocuments(entityDocuments);
  return opportunityEntityDocuments;
};

interface State {
  isFiltersDropdownOpen: boolean;
  isDealPlusDropdownOpen: boolean;
  isOtherDocOpen: boolean;
  headerHeight: number;
  isAllDocumentsOpen: boolean;
  isCreateNewDocumentLoading: boolean;
}

function eventCompare(a: Document, b: Document) {
  if (a.event?.startsAt && b.event?.startsAt) {
    const aDateTime = moment(a.event.startsAt);
    const bDateTime = moment(b.event.startsAt);
    if (aDateTime > bDateTime) {
      return aDateTime.valueOf() - bDateTime.valueOf();
    }
  }
  return 0;
}

function NoteMenu() {
  const sideMenuStore = useSideMenuStore();
  const navMenuStore = useNavMenuStore();
  const { documentId } = useParams();
  const documentStore = useDocumentStore();
  const templateStore = useTemplateStore();
  const { createNewDocumentWithModal } = useNewDocumentModal();

  const [state, setState] = useState<State>({
    isFiltersDropdownOpen: false,
    isDealPlusDropdownOpen: false,
    isOtherDocOpen: false,
    isAllDocumentsOpen: true,
    headerHeight: 0,
    isCreateNewDocumentLoading: false,
  });

  // refetch entities and documents by filter
  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      await Promise.all([documentStore.fetch(documentId), templateStore.fetch()]);
    };
    fetchData();
  }, []);

  const filterButtonRef = useRef<HTMLButtonElement>(null);
  const filtersRef = useRef<FiltersRef>(null);
  const dealPlusButtonRef = useRef<HTMLButtonElement>(null);
  const noteMenuRef = useRef<HTMLDivElement>(null);
  const otherDocumentRef = useRef<HTMLDivElement>(null);

  const modalStore = useModalStore();
  const userStore = useUserStore();

  const eventDocuments: Document[] = useMemo(() => {
    const ed = documentStore.documents.filter(d => !d.parent && !!d.event && !!d.noteServiceId);
    // eslint-disable-next-line no-nested-ternary
    ed.sort(eventCompare);
    return ed;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentStore.documents]);

  const otherDocuments: Document[] = useMemo(() => {
    return documentStore.documents.filter(d => !d.parent && !d.event && !!d.noteServiceId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentStore.documents]);

  useLayoutEffect(() => {
    if (otherDocumentRef && state.isOtherDocOpen) {
      const timeout = setTimeout(() => {
        otherDocumentRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }, 100);
      return () => clearTimeout(timeout);
    }
    return () => null;
  }, [otherDocumentRef, state.isOtherDocOpen]);

  useLayoutEffect(() => {
    if (sideMenuStore.noteMenu === 'fixed' && navMenuStore.activeEntityRef?.current) {
      const activeEntityRef = navMenuStore.activeEntityRef.current;
      const activeEntityRect = activeEntityRef.getBoundingClientRect();
      const noteMenuRect = noteMenuRef.current?.getBoundingClientRect();
      if (
        (noteMenuRect?.top && activeEntityRect.top < noteMenuRect.top) ||
        (noteMenuRect?.bottom && activeEntityRect.bottom > noteMenuRect.bottom)
      ) {
        setTimeout(() => {
          activeEntityRef.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'nearest' });
        }, 100);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navMenuStore.activeEntityRef, sideMenuStore.noteMenu]);

  useEffect(() => {
    if (navMenuStore.pulseNoteMenu) {
      const timeoutId = setTimeout(() => {
        navMenuStore.setState({
          pulseNoteMenu: false,
        });
      }, 900);

      return () => clearTimeout(timeoutId);
    }
    return () => null;
  }, [navMenuStore.pulseNoteMenu]);

  useEffect(() => {
    if (documentId) {
      if (otherDocuments.find(ed => ed.id === documentId)) {
        setState(prevState => ({ ...prevState, isOtherDocOpen: true }));
      } else if (eventDocuments.find(ed => ed.id === documentId)) {
        setState(prevState => ({ ...prevState, isOtherDocOpen: true }));
      }
    }
  }, [documentId, otherDocuments, eventDocuments]);

  const filteredEntityDocuments = useMemo(
    () => filterEntityDocuments(documentStore.entityDocuments),
    [documentStore.entityDocuments],
  );

  const filteredEntityDocumentsSortedAndSliced = useMemo(
    () =>
      filteredEntityDocuments
        .sort((a, b) => {
          if (a.account?.accessedAt === null) {
            return 1;
          }
          if (b.account?.accessedAt === null) {
            return -1;
          }
          return Date.parse(b.account?.accessedAt) - Date.parse(a.account?.accessedAt);
        })
        .slice(0, documentStore.numberOfItemsToShow),
    [filteredEntityDocuments, documentStore.numberOfItemsToShow],
  );

  const filteredOpportunityDocuments = useMemo(
    () => filterOpportunityDocuments(documentStore.entityDocuments),
    [documentStore.entityDocuments],
  );

  const filteredOpportunityDocumentsSortedAndSliced = useMemo(
    () =>
      filteredOpportunityDocuments
        .sort((a, b) => {
          if (a.accessedAt === null) {
            return 1;
          }
          if (b.accessedAt === null) {
            return -1;
          }
          return Date.parse(b.accessedAt) - Date.parse(a.accessedAt);
        })
        .slice(0, documentStore.numberOfItemsToShow),
    [filteredOpportunityDocuments, documentStore.numberOfItemsToShow],
  );

  const showOpportunitiesOnly = documentStore.showOpportunityOnly;
  const currentRootEntityIdRef = useRef('');

  useEffect(() => {
    // get opened account or opportunity hierarchy
    if (navMenuStore.activeEntityHierarchy.length) {
      // get root entity in hierarchy
      const rootEntityId = navMenuStore.activeEntityHierarchy.at(showOpportunitiesOnly ? -2 : -1)?.id || '';
      const rootEntity = showOpportunitiesOnly
        ? filteredOpportunityDocuments.find(item => item.id === rootEntityId)
        : filteredEntityDocuments.find(item => item.account.id === rootEntityId);
      // root entity exist but not show due to sort or slice
      // eslint-disable-next-line no-nested-ternary
      const rootEntityNotShow = rootEntity
        ? showOpportunitiesOnly
          ? !filteredOpportunityDocumentsSortedAndSliced.find(item => item.id === rootEntityId)
          : !filteredEntityDocumentsSortedAndSliced.find(item => item.account.id === rootEntityId)
        : false;
      // when root entity exist but now show, update accessed at to make it newest and show at the first row
      if (rootEntity && rootEntityNotShow) {
        if (currentRootEntityIdRef.current === rootEntityId) {
          return;
        }
        currentRootEntityIdRef.current = rootEntityId;
        if (showOpportunitiesOnly) {
          (rootEntity as EntityDocument).accessedAt = new Date().toISOString();
        } else {
          (
            rootEntity as {
              account: EntityDocument;
            }
          ).account.accessedAt = new Date().toISOString();
        }
        // refresh entityDocuments to re-render
        documentStore.setState({ entityDocuments: [...documentStore.entityDocuments] });
      }
    }
  }, [
    navMenuStore.activeEntityHierarchy,
    showOpportunitiesOnly,
    filteredOpportunityDocuments,
    filteredOpportunityDocumentsSortedAndSliced,
    filteredEntityDocuments,
    filteredEntityDocumentsSortedAndSliced,
  ]);

  if (documentStore.isLoading || documentStore.isLoading) {
    return (
      <div className="flex items-center justify-center h-full">
        <Loader />
      </div>
    );
  }

  return (
    <div
      className={`flex flex-col h-full border-r layout pl-2 pr-2 pb-2 ${navMenuStore.pulseNoteMenu && styles.pulse}`}
    >
      <div>
        <div className="flex justify-between sticky top-0 bg-white pt-4 pb-2 z-40 text-lg font-bold items-center">
          <UserDomain />
          {/* <span className="flex items-center gap-1"> */}
          <div className="flex gap-1 items-center">
            {userStore.user && !userStore.user.isIntegratedWithCrm && (
              <div className="w-6 h-6">
                <button
                  ref={dealPlusButtonRef}
                  type="button"
                  className="w-6 h-6 p-0.5 text-gray-400 rounded hover:bg-gray-50"
                  onClick={() => {
                    setState(prevState => ({ ...prevState, isDealPlusDropdownOpen: true }));
                  }}
                >
                  <PlusIcon />
                </button>
                <Popover
                  anchorEl={dealPlusButtonRef.current}
                  onClose={() => setState(prevState => ({ ...prevState, isDealPlusDropdownOpen: false }))}
                  isOpen={state.isDealPlusDropdownOpen}
                  transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                >
                  <div>
                    <MenuItem
                      className="p-2 flex items-center gap-2"
                      onClick={async () => {
                        modalStore.setState({
                          isOpen: true,
                          element: <CreateNewEntityModal entityName="Account" parentDocumentId={null} />,
                        });
                        setState(prevState => ({ ...prevState, isDealPlusDropdownOpen: false }));
                      }}
                    >
                      <AccountIcon className="w-5 h-5" />
                      <div className="text-sm text-gray-600">Create a new account</div>
                    </MenuItem>
                  </div>
                </Popover>
              </div>
            )}
            {sideMenuStore.noteMenu === 'fixed' ? (
              <button
                className="flex p-1 hover:bg-gray-200 rounded-lg animate-fade-in-down"
                type="button"
                onClick={() => {
                  sideMenuStore.close();
                  navMenuStore.setState({ buttonHover: null, menuHover: null, collapsedButton: 'note' });
                }}
              >
                <MenuCollapseIcon className="h-7 w-7 text-gray-400" />
              </button>
            ) : (
              <button
                className="flex p-1 hover:bg-gray-200 rounded-lg rotate-180 "
                type="button"
                onClick={() => {
                  sideMenuStore.setState({ noteMenu: 'fixed', calendarMenu: null });
                }}
              >
                <MenuCollapseIcon className="h-7 w-7 text-gray-400" />
              </button>
            )}
          </div>
        </div>
        <div>
          <NoteTopMenu
            icon={FilterIcon}
            ref={filterButtonRef}
            name="Filter"
            onClick={() => {
              setState(prevState => ({ ...prevState, isFiltersDropdownOpen: true }));
            }}
          />
          <NoteTopMenu
            icon={SearchIcon}
            name="Search"
            onClick={() => {
              modalStore.setState({
                isOpen: true,
                element: <SearchModal />,
                verticalPosition: 'top',
              });
            }}
          />
          <NoteTopMenu icon={PlusIcon} name="Add new document" onClick={() => createNewDocumentWithModal()} />

          <div className="flex justify-between items-center p-1 hover:bg-gray-100">
            <div className="ml-1 text-sm font-medium text-gray-500">Show only Opportunities</div>
            <div>
              <Switch
                checked={documentStore.showOpportunityOnly}
                size="small"
                sx={{
                  '&.MuiSwitch-root .Mui-checked': {
                    color: '#1DBC86',
                  },
                }}
                onClick={() => {
                  documentStore.setDocumentFilters(
                    documentStore.documentFilters,
                    !documentStore.showOpportunityOnly,
                    documentId,
                  );
                }}
              />
            </div>
          </div>
        </div>
        <Popover
          anchorEl={filterButtonRef.current}
          isOpen={state.isFiltersDropdownOpen}
          onClose={() => {
            filtersRef.current?.applyFilter(documentId);
            setState(prevState => ({ ...prevState, isFiltersDropdownOpen: false }));
          }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          <Filters ref={filtersRef} />
        </Popover>
        {(documentStore.documentFilters.length > 0 || documentStore.showOpportunityOnly) && (
          <div className="flex flex-wrap gap-1 pt-1 pb-1">
            {documentStore.documentFilters.map(filter => (
              <FilterPill key={filter.columnId} filter={filter} currentDocumentId={documentId} />
            ))}
            {/* {documentStore.showOpportunityOnly && (
              <div className="flex items-center gap-1 px-1 rounded-full border border-gray-300 text-gray-500">
                <OpportunityIcon className="w-4 h-4 fill-orange-400 text-orange-400" />
                <div className="flex font-normal text-sm">Opportunity Only</div>
                <button
                  type="button"
                  className="flex"
                  onClick={() => {
                    documentStore.setDocumentFilters(documentStore.documentFilters, false, documentId);
                  }}
                >
                  <XMarkIcon className="w-4 h-4 text-gray-400" />
                </button>
              </div>
            )} */}
          </div>
        )}
      </div>
      <div ref={noteMenuRef} className={`${styles['scrollbar-hide']} relative overflow-y-scroll`}>
        <div className="flex flex-col">
          <div className="bg-white flex items-center justify-between z-30 sticky h-10 top-0">
            {documentStore.showOpportunityOnly ? (
              <span className="text-[#FA9405] text-sm font-medium">Opportunities</span>
            ) : (
              <span className="text-[#5A6CD8] text-sm font-medium">Accounts</span>
            )}
          </div>
          <FadeIn isOpen={state.isAllDocumentsOpen}>
            <div className={`${!state.isAllDocumentsOpen && 'hidden'}`}>
              {showOpportunitiesOnly
                ? filteredOpportunityDocuments
                    .sort((a, b) => {
                      if (a.accessedAt === null) {
                        return 1;
                      }
                      if (b.accessedAt === null) {
                        return -1;
                      }
                      return Date.parse(b.accessedAt) - Date.parse(a.accessedAt);
                    })
                    .slice(0, documentStore.numberOfItemsToShow)
                    .map(o => <Deal key={o.id} opportunity={o} noteTemplates={templateStore.templates} />)
                : filteredEntityDocuments
                    .sort((a, b) => {
                      if (a.account?.accessedAt === null) {
                        return 1;
                      }
                      if (b.account?.accessedAt === null) {
                        return -1;
                      }
                      return Date.parse(b.account?.accessedAt) - Date.parse(a.account?.accessedAt);
                    })
                    .slice(0, documentStore.numberOfItemsToShow)
                    .map(({ account, opportunities }) => (
                      <AccountLink key={account.id} account={account} opportunities={opportunities} />
                    ))}
            </div>
          </FadeIn>
        </div>
        <div className="flex flex-col">
          <div
            ref={otherDocumentRef}
            className="bg-white flex items-center justify-between gap-3 z-30 sticky h-10 top-0"
          >
            <span className="text-sm font-normal text-gray-600">Unlinked documents</span>
            <div className="flex gap-0.5">
              <Tooltip title="Create New Document" placement="top">
                <button
                  className="flex items-center text-gray-400 hover:bg-gray-300 hover:text-gray-400 flex-shrink-0 flex-grow-0 rounded"
                  disabled={state.isCreateNewDocumentLoading}
                  type="button"
                  onClick={() => createNewDocumentWithModal()}
                >
                  <PlusIcon className="w-6 h-6 p-1" />
                </button>
              </Tooltip>

              <button
                className="flex items-center rounded flex-grow-0 flex-shrink-0 mr-1"
                onClick={event => {
                  event.preventDefault();
                  setState(prevState => ({ ...prevState, isOtherDocOpen: !prevState.isOtherDocOpen }));
                }}
                type="button"
              >
                {!state.isOtherDocOpen ? (
                  <ChevronRightIcon className="w-5 h-5 stroke-2 text-gray-400  font-medium hover:bg-gray-300 rounded p-1" />
                ) : (
                  <ChevronDownIcon className="w-5 h-5 stroke-2 text-gray-400  font-medium hover:bg-gray-300 rounded p-1" />
                )}
              </button>
            </div>
          </div>
          <FadeIn isOpen={state.isOtherDocOpen}>
            <div className={`${!state.isOtherDocOpen && 'hidden'}`}>
              {eventDocuments.map(eventDocument => (
                <DocumentLink key={eventDocument.id} document={eventDocument} ml="" />
              ))}
              {otherDocuments.map(document => (
                <DocumentLink key={document.id} document={document} ml="" />
              ))}
            </div>
          </FadeIn>
        </div>
      </div>
    </div>
  );
}

export default NoteMenu;
