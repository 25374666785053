import { CursorOverlayData, useRemoteCursorOverlayPositions } from '@slate-yjs/react';
import React, { CSSProperties, PropsWithChildren, useMemo, useRef } from 'react';
import { NoteUser } from 'note-service/note-service';

import colours from 'utils/colours';

import styles from './Overlay.module.css';
import useUserStore from 'stores/user';
import { uniqBy } from 'lodash';

type CaretProps = Pick<CursorOverlayData<Record<keyof NoteUser, string>>, 'caretPosition' | 'data'>;

function Caret({ caretPosition, data }: CaretProps) {
  const caretStyle: CSSProperties = {
    ...caretPosition,
    background: data?.colour,
  };

  const labelStyle: CSSProperties = {
    background: data?.colour,
  };

  return (
    <div style={caretStyle} className={styles.caret}>
      <div className={styles['caret-label']} style={labelStyle}>
        {data?.firstName} {data?.lastName}
      </div>
    </div>
  );
}

interface RemoteSelectionProps {
  cursor: CursorOverlayData<Record<keyof NoteUser, string>>;
}

function RemoteSelection({ cursor }: RemoteSelectionProps) {
  if (!cursor.data) {
    return null;
  }

  const selectionStyle: CSSProperties = {
    // Add a opacity to the background color
    backgroundColor: colours.addAlpha(cursor.data.colour, 0.3),
    position: 'absolute',
    pointerEvents: 'none',
  };

  return (
    <>
      {cursor.selectionRects.map((position: CSSProperties, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <div style={{ ...selectionStyle, ...position }} key={`${cursor.clientId}-${idx}`} />
      ))}
      {cursor.caretPosition && <Caret caretPosition={cursor.caretPosition} data={cursor.data} />}
    </>
  );
}

function RemoteCursorOverlay({ children }: PropsWithChildren) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [cursors] = useRemoteCursorOverlayPositions<Record<keyof NoteUser, string>>({
    containerRef,
  });
  const userStore = useUserStore();
  // only show cursor from other users
  const otherUserCursors = useMemo(
    () =>
      uniqBy(
        cursors.filter(cursor => cursor.data?.id !== userStore.user?.id),
        'data.id',
      ),
    [cursors, userStore.user?.id],
  );

  return (
    <div ref={containerRef} style={{ position: 'relative' }}>
      {children}
      {otherUserCursors.map((cursor: CursorOverlayData<Record<keyof NoteUser, string>>) => (
        <RemoteSelection key={cursor.clientId} cursor={cursor} />
      ))}
    </div>
  );
}

export default RemoteCursorOverlay;
