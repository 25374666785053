/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { ProductCategoryStatus, getEmptyProductCategoryStatus } from 'types/product-gap';
import { openFormModal } from 'components/BaseModal';
import { cloneDeep } from 'lodash';
import { v4 } from 'uuid';
import FormField from 'components/Form/FormField';
import InputField from 'components/Form/InputField';
import { requiredInputValidate } from 'utils/validate';
import useProductGapStore from 'stores/product-gap';
import apiErrors from 'utils/errors';

/**
 * open modal to create a new product category status or update an existing one
 * @param status if exist then update, otherwise create new
 * @returns new created/updated product category status
 */
const openNewCategoryStatusModal = (status?: ProductCategoryStatus): Promise<ProductCategoryStatus | undefined> => {
  const productCategoryStatus: ProductCategoryStatus = status ? cloneDeep(status) : getEmptyProductCategoryStatus();
  const modalId = v4();
  return openFormModal<ProductCategoryStatus>(
    {
      formData: productCategoryStatus,
      renderFormBody: ({ register, errors }) => (
        <FormField
          className="mb-3"
          label="Status name"
          labelClassName="w-[12rem]"
          error={errors.name ? errors.name.message || 'Name is required' : ''}
        >
          <InputField {...register('name', requiredInputValidate)} placeholder="Enter Status Title" multiline />
        </FormField>
      ),
      handleApiError: (error, setError) => {
        if (error.code === apiErrors.DatabaseError.code) {
          setError('name', {
            message: 'Name already exist',
            type: 'api',
          });
        }
      },
      actions: [
        {
          label: 'Cancel',
          color: 'secondary',
        },
        {
          label: status ? 'Update' : 'Create',
          color: 'primary',
          type: 'submit',
          cb: (fd: ProductCategoryStatus) => {
            if (fd.id) {
              return useProductGapStore.getState().updateCategoryStatus(fd.id, fd.name.trim());
            }
            return useProductGapStore.getState().createCategoryStatus(fd.name.trim());
          },
        },
      ],
    },
    {
      className: 'w-[46rem]',
      title: status ? 'Update product category status' : 'Create a new product category status',
      bodyClassName: '!pr-0',
    },
    modalId,
    { hideBackdrop: true },
  ).then(rsp => {
    return typeof rsp === 'object' ? (rsp as ProductCategoryStatus) : undefined;
  });
};

export default openNewCategoryStatusModal;
