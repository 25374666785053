/* eslint-disable no-use-before-define */
import { Account, Opportunity } from './entity';

export interface ProductboardFeature {
  id: string;
  name: string | null;
  description: string | null;
  archieved: boolean;
  status: {
    id: string | null;
    name: string | null;
  };
  timeframe: {
    startDate: string | null;
    endDate: string | null;
  };
  parent: {
    component: {
      id: string;
      name: string | null;
      description: string | null;
    } | null;
  };
}

export interface ProductCategory {
  id: string;
  title: string;
  description: string;
  integrationId: string;
  integration?: ProductboardFeature;
  status?: ProductCategoryStatus;
}

export enum ProductFieldType {
  TEXT = 'Text',
  NUMBER = 'Number',
  DATE = 'Date',
  SELECT = 'Select',
}

export interface SelectAdditionalData {
  options: string[];
}

export type ProductFieldAdditionalData = SelectAdditionalData | null;

export interface ProductField {
  id: string;
  title: string;
  fieldType: ProductFieldType;
  row: number;
  defaultValue: string;
  additionalData: ProductFieldAdditionalData;
  isMandatory: boolean;
  isDeleted: boolean;
}

export interface ProductFieldAnswer {
  id: string;
  title: string;
  type: ProductFieldType;
  isMandatory: boolean;
  additionalData: ProductFieldAdditionalData;
  answer: string;
}

export interface ProductGap {
  id: string;
  createdAt: string;
  createdBy: {
    id: string;
    firstName: string;
    lastName: string;
  };
  documentId: string | null;
  account: Account | null;
  opportunity: Opportunity | null;
  category: ProductCategory | null;
  fields: ProductFieldAnswer[];
  link: string | null;
  callId: string | null;
  startTime: number | null;
  callTitle: string | null;
}
export interface ProductCategoryStatus {
  id: string;
  name: string;
}

export const getEmptyProductGap = (): ProductGap => ({
  id: '',
  createdAt: '',
  createdBy: {
    id: '',
    firstName: '',
    lastName: '',
  },
  documentId: null,
  account: null,
  opportunity: null,
  category: null,
  fields: [],
  link: null,
  callId: null,
  startTime: null,
  callTitle: null,
});

export const getEmptyProductCategory = (): ProductCategory => ({
  id: '',
  title: '',
  description: '',
  integrationId: '',
});

export const getEmptyProductCategoryStatus = (): ProductCategoryStatus => ({
  id: '',
  name: '',
});
