/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { NumericFormat } from 'react-number-format';
import InputField from './InputField';

interface Props {
  value?: string | number;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  thousandSeparator?: string;
  placeholder?: string;
  loading?: boolean;
  isError?: boolean;
  size?: 'small' | 'normal';
  disabled?: boolean;
}

const NumberField = React.forwardRef<HTMLInputElement, Props>(
  ({ value, onChange, thousandSeparator, placeholder, loading, isError, size, disabled }: Props, ref) => {
    return (
      <NumericFormat
        inputRef={ref}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        customInput={InputField}
        thousandSeparator={thousandSeparator}
        allowLeadingZeros={false}
        loading={loading}
        size={size === 'normal' || !size ? 'medium' : 'small'}
        isError={isError}
        disabled={disabled}
      />
    );
  },
);

NumberField.displayName = 'NumberField';

NumberField.defaultProps = {
  value: undefined,
  onChange: undefined,
  thousandSeparator: ',',
  placeholder: '',
  loading: undefined,
  isError: undefined,
  size: undefined,
  disabled: undefined,
};

export default NumberField;
