import React, { useState } from 'react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import OpportunityIcon from 'components/icons/OpportunityIcon';
import { EntityDocument } from 'types/entity';
import { Document } from 'types/document';
import { Recording } from 'api/recordings/get-recordings';
import DocumentButton from './DocumentButton';

interface Props {
  opportunity: EntityDocument;
  recording: Recording;
  documents: Document[];
  mode: 'search' | 'default';
  updateData: (objType: 'opp' | 'doc', objId: string) => void;
}

function OpportunityButton({ opportunity, recording, documents, mode, updateData }: Props) {
  const [state, setState] = useState<boolean>(false);
  const isLinked = opportunity.id === recording.entityOrDocumentId;
  if (mode === 'default') {
    let noDoc = 0;

    const childList: JSX.Element[] = [];
    documents.forEach(doc => {
      const isLinkedSource = doc.id === recording.entityOrDocumentId;
      if (doc.parent && doc.parent.entityName === 'Opportunity' && doc.parent.id === opportunity.id) {
        noDoc += 1;
        childList.push(
          <DocumentButton
            key={doc.id}
            isLinked={isLinkedSource}
            doc={doc}
            updateData={updateData}
            sortedOpportunities={[]}
            sortedAccounts={[]}
          />,
        );
      }
    });

    return (
      <div>
        <div
          className={`flex h-8 justify-between items-center rounded-lg group hover:bg-slate-100 ${
            isLinked ? 'bg-gray-100' : null
          }`}
        >
          <button
            type="button"
            className={`flex w-[80%] h-full items-center justify-between text-sm p-2 rounded-l-lg ${
              isLinked ? 'bg-gray-100 cursor-default' : 'hover:bg-slate-200'
            }`}
            onClick={() => {
              updateData('opp', opportunity.id);
            }}
            disabled={isLinked}
          >
            <div className="flex items-center gap-2">
              <OpportunityIcon className="w-4 h-4 flex-none text-orange-500 fill-orange-500" />
              <span className="text-gray-500 text-ellipsis truncate">{opportunity.name}</span>
            </div>
            {isLinked && <CheckIcon className="w-5 h-5 flex-none text-blue-500" />}
          </button>
          <button
            type="button"
            className="w-[20%] opacity-0 group-hover:opacity-100 h-full text-xs px-1 text-white rounded-r-lg bg-blue-200 hover:bg-blue-300"
            onClick={() => {
              setState(!state);
            }}
          >
            <div className="flex gap-1 items-center">
              {noDoc} events
              <ChevronDownIcon className={`w-3 h-3 ml-2 ${state && 'rotate-180'}`} />
            </div>
          </button>
        </div>
        {state && <div className="ml-4">{childList}</div>}
      </div>
    );
  }
  return (
    <div>
      <div className="flex justify-between items-center pr-3 hover:bg-slate-100 rounded-lg group">
        <button
          type="button"
          className={`flex w-full items-center text-sm p-2 gap-2 ${isLinked ? 'cursor-default' : null} `}
          onClick={() => {
            updateData('opp', opportunity.id);
          }}
          disabled={isLinked}
        >
          <OpportunityIcon className="w-4 h-4 text-orange-500 fill-orange-500" />
          <span className="text-gray-500">{opportunity.name}</span>
        </button>
        {isLinked && <CheckIcon className="flex-none w-5 h-5 text-blue-500" />}
      </div>
    </div>
  );
}

export default OpportunityButton;
