/* eslint-disable react-hooks/exhaustive-deps */
import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/outline';
import BaseButton from 'components/BaseButton';
import NoteEditor from 'components/NoteEditor/NoteEditor';
import { CommentRecords, CustomElement } from 'components/NoteEditor/types';
import { cloneDeep, isEmpty, keys, sum, values } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { Editor, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import useUserStore from 'stores/user';
import { concat } from 'utils/styling';
import { openCommentPopover } from './CommentPopover';
import styles from './CommentRowButton.module.css';

interface Props {
  className?: string;
  editor: Editor;
  comments: CommentRecords | undefined;
  commentForBlock: boolean;
  darkStyle: boolean;
  element: CustomElement;
}

function CommentRowButton({ className, editor, comments, commentForBlock, darkStyle, element }: Props) {
  const ref = React.useRef<HTMLButtonElement>(null);
  const userStore = useUserStore();

  const handleBtnClick = useCallback(() => {
    const availabelComments = cloneDeep(comments || {});
    keys(availabelComments).forEach(id => {
      if (availabelComments[id]?.[0]?.resolved) {
        delete availabelComments[id];
      }
    });
    if (commentForBlock) {
      const path = ReactEditor.findPath(editor, element);
      Transforms.select(editor, path);
      const userId = userStore.user?.id;
      // add new comment for root node
      if (!availabelComments || isEmpty(availabelComments)) {
        if (userId) {
          Transforms.setNodes(editor, { commentInit: true }, { at: path });
          setTimeout(() => {
            NoteEditor.addNewCommentForCommentInitElements(editor, userId);
          });
        }
        return;
      }
    }
    const commentIds = keys(availabelComments);
    const commentIdClass = commentIds.map(id => `.comment.comment-${id}`);
    openCommentPopover(
      editor,
      availabelComments || {},
      false,
      ref.current?.parentElement?.querySelector?.(`${commentIdClass.join(', ')}`) ||
        ref.current?.parentElement?.parentElement ||
        undefined,
    );
  }, [comments, commentForBlock, element]);

  const commentCount = useMemo(() => {
    return sum(values(comments).map(item => (item[0]?.resolved ? 0 : item.length)));
  }, [comments]);

  return (
    <BaseButton
      ref={ref}
      variant={darkStyle ? 'contained' : 'text'}
      color={darkStyle ? 'primary' : 'secondary'}
      className={concat(styles.commentBtn, darkStyle && styles.overlap, className)}
      aria-label={commentCount ? 'View comments' : 'Add comments'}
      onClick={handleBtnClick}
      contentEditable={false}
      hideTooltipToPreventJump
      // eslint-disable-next-line no-nested-ternary
      tooltip={commentForBlock ? (commentCount ? 'View comments' : 'Add comments') : ''}
    >
      <ChatBubbleBottomCenterTextIcon
        width={commentForBlock ? 16 : 20}
        className={`${commentForBlock ? 'text-white' : 'text-gray-500'}`}
      />
      {!!commentCount && (
        <span className={`${commentForBlock ? 'text-white' : 'text-gray-500'}`}>
          {commentCount > 9 ? '9+' : commentCount}
        </span>
      )}
    </BaseButton>
  );
}
CommentRowButton.defaultProps = {
  className: '',
};

export default CommentRowButton;
