/* eslint-disable import/prefer-default-export */

import { ProductGap } from '../../types/product-gap';
import { RowData } from '../../components/Table/types';

export function generateRowDataFromProductGaps(productGaps: ProductGap[]): RowData[] {
  const rows: RowData[] = [];
  productGaps.forEach(gap => {
    const rowData: RowData = {};
    rowData.id = gap.id;
    rowData.createdAt = gap.createdAt;
    rowData.createdBy = `${gap.createdBy.firstName} ${gap.createdBy.lastName}`;
    if (gap.callId && gap.documentId) {
      rowData.callId = gap.callId;
      rowData.startTime = gap.startTime;
      rowData.callTitle = gap.callTitle;
    }

    rowData.account = {};
    if (gap.account) {
      rowData.account = {
        ...gap.account,
        owner: `${gap.account.owner?.firstName} ${gap.account.owner?.lastName}`,
      };
    }
    rowData.opportunity = {};
    if (gap.opportunity) {
      rowData.opportunity = {
        ...gap.opportunity,
        owner: `${gap.opportunity.owner?.firstName} ${gap.opportunity.owner?.lastName}`,
      };
    }

    rowData.category = gap.category;

    gap.fields.forEach(field => {
      rowData[`field-${field.title}`] = field.answer;
      if (field.title === 'Feedback') {
        rowData[`field-${field.title}`] = field;
      }
    });
    rows.push(rowData);
  });
  return rows;
}
