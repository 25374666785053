import React, { useEffect, useRef, useState } from 'react';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';

import { DateFilterType } from 'components/Table/types';
import CaretIcon from 'components/icons/CaretIcon';
import Popover from 'components/Popover';

import { FilterProps } from './type';
import constants from '../../../utils/constants';
import CalendarIcon from '../../icons/CalendarIcon';

interface TimeButtonProps {
  value: string | undefined;
  onChange: (value: string | null) => void;
}

function TimeButton({ value, onChange }: TimeButtonProps) {
  const time = value ? moment(value, 'HH:mm:ss').format('HH:mm:ss') : '';
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <TimePicker
        open={isOpen}
        onClose={() => setIsOpen(false)}
        inputFormat={constants.TIME_FORMAT}
        disableMaskedInput
        value={time}
        onChange={(newValue: Moment | null) => {
          if (newValue !== null) {
            onChange(newValue.format('HH:mm:ss'));
          } else {
            onChange(null);
          }
        }}
        renderInput={params => {
          return (
            <button
              type="button"
              className="bg-gray-100 px-2 py-1 flex items-center text-gray-500 text-sm"
              onClick={() => setIsOpen(true)}
            >
              <div ref={params.inputRef}>
                {time !== '' ? moment(time, 'HH:mm:ss').format(constants.TIME_FORMAT) : 'Select a time'}
              </div>
              <CalendarIcon className="ml-1" />
            </button>
          );
        }}
      />
    </LocalizationProvider>
  );
}

interface TimeRangeButtonProps {
  value: string[] | undefined;
  onChange: (value: string[]) => void;
}

function TimeRangeButton({ value, onChange }: TimeRangeButtonProps) {
  const [time1, setTime1] = useState<string | undefined>(value && value[0]);
  const [time2, setTime2] = useState<string | undefined>(value && value[1]);

  useEffect(() => {
    if (time1 && time2) {
      onChange([time1, time2]);
    }
  }, [time1, time2]);

  return (
    <div className="flex gap-2">
      <div>
        <span className="text-xs text-gray-400">From</span>
        <TimeButton value={time1} onChange={t => setTime1(t || undefined)} />
      </div>
      <div>
        <span className="text-xs text-gray-400">To</span>
        <TimeButton value={time2} onChange={t => setTime2(t || undefined)} />
      </div>
    </div>
  );
}

type Props = FilterProps<DateFilterType, undefined | string | string[]>;
function TimeFilter({ name, type, value, onFilterUpdate, onClose }: Props) {
  const [filterType, setFilterType] = useState<DateFilterType>(type);
  const [isFilterTypeOpen, setIsFilterTypeOpen] = useState(false);

  const ref = useRef<HTMLButtonElement>(null);

  const updateFilterType = (newFilterType: DateFilterType) => {
    setFilterType(newFilterType);
    if (newFilterType === 'Is empty' || newFilterType === 'Is not empty') {
      onFilterUpdate({
        type: newFilterType,
        value: '',
      });
    } else if (newFilterType === 'Is between' && !Array.isArray(value)) {
      onFilterUpdate(undefined);
    } else if (newFilterType !== 'Is between' && Array.isArray(value)) {
      onFilterUpdate(undefined);
    } else if (value !== undefined) {
      onFilterUpdate({
        value: '',
        type: newFilterType,
      });
    } else {
      onFilterUpdate(undefined);
    }
  };

  const updateFilterDates = (newDates: string[]) => {
    let newValue: null | [string, string] = null;
    if (newDates[0] && newDates[1]) {
      newValue = [newDates[0], newDates[1]];
    }
    if (newValue !== null) {
      onFilterUpdate({
        type: filterType,
        value: newValue,
      });
    } else {
      onFilterUpdate(undefined);
    }
  };

  const updateFilterDate = (newDate: string | null) => {
    if (!newDate) {
      onFilterUpdate(undefined);
    } else {
      onFilterUpdate({
        type: filterType,
        value: newDate,
      });
    }
  };

  return (
    <div className="px-2 pt-1 pb-2">
      <div className="flex items-center text-xs">
        <div className="text-gray-400">{name}</div>
        <button
          ref={ref}
          type="button"
          className="flex items-center rounded hover:bg-gray-100 px-2 py-2 text-sm"
          onClick={() => setIsFilterTypeOpen(true)}
        >
          <div>{filterType.toLowerCase()}</div>
          <CaretIcon className="w-4 h-4 rotate-180" />
        </button>
        <Popover
          anchorEl={ref.current}
          onClose={() => setIsFilterTypeOpen(false)}
          isOpen={isFilterTypeOpen}
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        >
          <div className="flex flex-col p-1">
            <button
              type="button"
              className="px-2 py-1 text-sm text-left rounded hover:bg-gray-100"
              onClick={() => {
                updateFilterType('Is');
                setIsFilterTypeOpen(false);
              }}
            >
              Is
            </button>
            <button
              type="button"
              className="px-2 py-1 text-sm text-left rounded hover:bg-gray-100"
              onClick={() => {
                updateFilterType('Is before');
                setIsFilterTypeOpen(false);
              }}
            >
              Is before
            </button>
            <button
              type="button"
              className="px-2 py-1 text-sm text-left rounded hover:bg-gray-100"
              onClick={() => {
                updateFilterType('Is after');
                setIsFilterTypeOpen(false);
              }}
            >
              Is after
            </button>
            <button
              type="button"
              className="px-2 py-1 text-sm text-left rounded hover:bg-gray-100"
              onClick={() => {
                updateFilterType('Is on or before');
                setIsFilterTypeOpen(false);
              }}
            >
              Is on or before
            </button>
            <button
              type="button"
              className="px-2 py-1 text-sm text-left rounded hover:bg-gray-100"
              onClick={() => {
                updateFilterType('Is on or after');
                setIsFilterTypeOpen(false);
              }}
            >
              Is on or after
            </button>
            <button
              type="button"
              className="px-2 py-1 text-sm text-left rounded hover:bg-gray-100"
              onClick={() => {
                updateFilterType('Is between');
                setIsFilterTypeOpen(false);
              }}
            >
              Is between
            </button>
            <button
              type="button"
              className="px-2 py-1 text-sm text-left rounded hover:bg-gray-100"
              onClick={() => {
                updateFilterType('Is empty');
                setIsFilterTypeOpen(false);
              }}
            >
              Is empty
            </button>
            <button
              type="button"
              className="px-2 py-1 text-sm text-left rounded hover:bg-gray-100"
              onClick={() => {
                updateFilterType('Is not empty');
                setIsFilterTypeOpen(false);
              }}
            >
              Is not empty
            </button>
          </div>
        </Popover>
      </div>
      <div className="my-1 flex justify-center items-center">
        {filterType === 'Is between' ? (
          <TimeRangeButton value={Array.isArray(value) ? value : undefined} onChange={updateFilterDates} />
        ) : (
          !['Is empty', 'Is not empty'].includes(filterType) && (
            <TimeButton value={value as string} onChange={time => updateFilterDate(time)} />
          )
        )}
      </div>
    </div>
  );
}

export default TimeFilter;
