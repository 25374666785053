import React, { useEffect, useState } from 'react';

import updateRecordingAssociation from 'api/recordings/update-recording-association';
import checkDocumentOpportunity, { CheckDocument } from 'api/documents/check_document_opportunity';

import OpportunityIcon from 'components/icons/OpportunityIcon';
import RecordingIcon from 'components/icons/RecordingIcon';
import datetimeObjToString from 'utils/datetime-obj-to-string';
import NoteIcon from 'components/icons/NoteIcon';
import CalendarIcon from 'components/icons/CalendarIcon';
import LinkBrokenIcon from 'components/icons/LinkBrokenIcon';
import Loader from 'components/Loader';
import OtherEventsToLink from 'components/OtherEventsToLink';

import useDocumentStore from 'stores/document';

import AccountIcon from 'components/icons/AccountIcon';
import { Recording } from 'api/recordings/get-recordings';
import { getEntityNames, getSourceType, SourceType } from './getSourceType';

function docEvtObjectGroup(recording: Recording, sourceType: SourceType, docName: string | null): JSX.Element | null {
  const { scheduledDate, scheduledTime } = datetimeObjToString(recording.callData.scheduled);
  return (
    <div className="rounded text-sm border border-gray-200 px-2">
      <div className="flex items-center gap-2">
        {sourceType.type?.includes('evt') ? (
          <CalendarIcon className="w-4 h-4 flex-none" />
        ) : (
          <NoteIcon className="w-4 h-4 flex-none" />
        )}
        <span className="text-gray-500 text-ellipsis truncate">{docName}</span>
      </div>
      {/* recording title */}
      <div className="ml-3">
        <div className="flex items-center gap-2 text-gray-500">
          <RecordingIcon className="w-5 h-5 fill-blue-400 text-blue-400" />
          <span className="">{recording.callData.title}</span>
        </div>
        <div className="text-xs text-gray-400">
          {scheduledDate} {scheduledTime}
        </div>
      </div>
    </div>
  );
}

function oppDocObjectGroup(oppName: string | null, docName: string | null): JSX.Element | null {
  return (
    <div className="flex text-sm items-center gap-2 rounded border border-gray-200 px-2">
      <div>
        <OpportunityIcon className="w-4 h-4 flex-none text-orange-500 fill-orange-500" />
        <span className="text-gray-500 text-ellipsis truncate">{oppName}</span>
      </div>
      <div>
        <NoteIcon className="w-4 h-4 flex-none" />
        <span className="text-gray-500 text-ellipsis truncate">{docName}</span>
      </div>
    </div>
  );
}

function acctDocObjectGroup(acctName: string | null, docName: string | null): JSX.Element | null {
  return (
    <div className="flex text-sm items-center gap-2 rounded border border-gray-200 px-2">
      <div>
        <AccountIcon className="w-4 h-4 flex-none" />
        <span className="text-gray-500 text-ellipsis truncate">{acctName}</span>
      </div>
      <div>
        <NoteIcon className="w-4 h-4 flex-none" />
        <span className="text-gray-500 text-ellipsis truncate">{docName}</span>
      </div>
    </div>
  );
}

function oppEvtObjectGroup(oppName: string | null, evtName: string | null): JSX.Element | null {
  return (
    <div className="flex flex-col text-sm gap-1 rounded border border-gray-200 px-2">
      <div className="flex items-center gap-1">
        <CalendarIcon className="w-4 h-4 flex-none" />
        <span className="text-gray-500 text-ellipsis truncate">{evtName}</span>
      </div>
      <div className="flex items-center gap-1 text-xs">
        (<OpportunityIcon className="w-4 h-4 flex-none text-orange-500 fill-orange-500" />
        <span className="text-gray-500 text-ellipsis truncate">{oppName}</span>)
      </div>
    </div>
  );
}

function acctEvtObjectGroup(acctName: string | null, evtName: string | null): JSX.Element | null {
  return (
    <div className="flex flex-col text-sm gap-1 rounded border border-gray-200 px-2">
      <div className="flex items-center gap-1">
        <CalendarIcon className="w-4 h-4 flex-none" />
        <span className="text-gray-500 text-ellipsis truncate">{evtName}</span>
      </div>
      <div className="flex items-center gap-1 text-xs">
        (<AccountIcon className="w-4 h-4 flex-none" />
        <span className="text-gray-500 text-ellipsis truncate">{acctName}</span>)
      </div>
    </div>
  );
}

function acctElement(name: string | null): JSX.Element | null {
  return (
    <div className="flex items-center text-sm gap-2 rounded border border-gray-200 px-2">
      <AccountIcon className="w-4 h-4 flex-none" />
      <span className="text-gray-500 text-ellipsis truncate">{name}</span>
    </div>
  );
}

function oppElement(name: string | null): JSX.Element | null {
  return (
    <div className="flex items-center text-sm gap-2 rounded border border-gray-200 px-2">
      <OpportunityIcon className="w-4 h-4 flex-none text-orange-500 fill-orange-500" />
      <span className="text-gray-500 text-ellipsis truncate">{name}</span>
    </div>
  );
}

function docElement(name: string | null, oppName: string | null): JSX.Element | null {
  return (
    <div className="flex flex-col gap-1 rounded border border-gray-200 px-2">
      <div className="flex items-center text-sm gap-2">
        <NoteIcon className="w-4 h-4 flex-none" />
        <span className="text-gray-500 text-ellipsis truncate">{name}</span>
      </div>
      {oppName !== null && (
        <span className="flex items-center text-xs gap-1">
          (<OpportunityIcon className="w-3 h-3 flex-none text-orange-500 fill-orange-500" />
          <span className="text-gray-500 text-ellipsis truncate">{oppName}</span>)
        </span>
      )}
    </div>
  );
}

function evtElement(name: string | null, oppName: string | null): JSX.Element | null {
  return (
    <div className="flex flex-col gap-1 rounded border border-gray-200 px-2">
      <div className="flex items-center gap-2">
        <CalendarIcon className="w-4 h-4 flex-none " />
        <span className="text-gray-500 text-sm text-ellipsis truncate">{name}</span>
      </div>
      {oppName !== null && (
        <span className="flex items-center text-xs gap-1">
          (<OpportunityIcon className="w-3 h-3 flex-none text-orange-500 fill-orange-500" />
          <span className="text-gray-500 text-ellipsis truncate">{oppName}</span>)
        </span>
      )}
    </div>
  );
}

function recElement(recording: Recording): JSX.Element | null {
  const { scheduledDate, scheduledTime } = datetimeObjToString(recording.callData.scheduled);
  return (
    <div className="text-sm rounded border border-gray-200 px-2">
      {/* recording title */}
      <div className="flex items-center gap-2 text-gray-500">
        <RecordingIcon className="w-5 h-5 fill-blue-400 text-blue-400" />
        <span className="">{recording.callData.title}</span>
      </div>
      <div className="text-xs text-gray-400">
        {scheduledDate} {scheduledTime}
      </div>
    </div>
  );
}

function divider(text: string): JSX.Element | null {
  return (
    <div className="relative text-xs flex py-2 items-center">
      <div className="flex-grow border-t border-gray-200" />
      <span className="flex-shrink mx-2 rounded px-2 bg-orange-200 text-gray-500">{text}</span>
      <div className="flex-grow border-t border-gray-200" />
    </div>
  );
}

interface ParentState {
  popupStage: 'none' | 'list' | 'confirm';
  objType: 'opp' | 'doc' | 'acct' | null;
  objId: string | null;
}

interface Props {
  parentState: ParentState;
  setParentState: React.Dispatch<React.SetStateAction<ParentState>>;
  recording: Recording;
  onChange: () => void;
  onClose: () => void;
}

interface State {
  isLoading: boolean;
  checkDocument: CheckDocument | null;
  checkedDocumentIds: string[];
}

function ConfirmView({ parentState, setParentState, recording, onChange, onClose }: Props) {
  const documentStore = useDocumentStore();
  const [state, setState] = useState<State>({ isLoading: false, checkDocument: null, checkedDocumentIds: [] });

  const sourceType = getSourceType(recording);
  const names = getEntityNames(recording);

  const toObjType = parentState.objType;
  const toObjId = parentState.objId;

  const updateDataOnClick = async (updateType: 'doc' | 'rec' | 'unlink') => {
    setState(prevState => ({
      ...prevState,
      isLoading: true,
    }));
    if (updateType === 'rec' && toObjType !== null && toObjId !== null) {
      await updateRecordingAssociation(toObjId, recording.id);
    } else if (updateType === 'doc' && recording.entityOrDocumentId !== null && toObjId !== null) {
      await documentStore.updateDocumentEntity(recording.entityOrDocumentId, state.checkedDocumentIds, toObjId);
    } else {
      await updateRecordingAssociation(null, recording.id);
    }
    setState(prevState => ({
      ...prevState,
      isLoading: false,
    }));
    onClose();
    onChange();
  };

  // TODO handle for account
  useEffect(() => {
    const checkDocument = async (documentId: string) => {
      setState(prevState => ({
        ...prevState,
        isLoading: true,
      }));
      const result = await checkDocumentOpportunity(documentId);
      if (result.extraDocumentsToAssign.length > 0) {
        setState(prevState => ({
          ...prevState,
          checkDocument: result,
        }));
      }
      setState(prevState => ({
        ...prevState,
        isLoading: false,
      }));
    };
    const linkedDoc = documentStore.documents.find(d => d.id === recording.entityOrDocumentId);
    if (toObjType === 'opp' && linkedDoc !== undefined && linkedDoc.event !== null) {
      if (recording.entityOrDocumentId) {
        checkDocument(recording.entityOrDocumentId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (state.isLoading) {
    return (
      <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <Loader className="w-20 h-20" />
      </div>
    );
  }

  let objectToMove: JSX.Element | null = null;
  let fromObject: JSX.Element | null = null;
  let toObject: JSX.Element | null = null;
  let updateType: 'rec' | 'doc' | 'unlink';

  // link to an acct
  if (toObjType === 'acct') {
    const acct = documentStore.entityDocuments.find(a => a.id === toObjId);
    if (acct) {
      toObject = acctElement(acct.name);
    }
    if (sourceType.type === 'opp') {
      objectToMove = recElement(recording);
      fromObject = oppElement(names.opp);
      updateType = 'rec';
    } else if (sourceType.type === 'doc') {
      objectToMove = docEvtObjectGroup(recording, sourceType, names.doc);
      updateType = 'doc';
    } else if (sourceType.type === 'doc-evt') {
      objectToMove = docEvtObjectGroup(recording, sourceType, names.doc);
      updateType = 'doc';
    } else if (sourceType.type === 'opp/doc') {
      objectToMove = docEvtObjectGroup(recording, sourceType, names.doc);
      fromObject = oppElement(names.opp);
      updateType = 'doc';
    } else if (sourceType.type === 'opp/doc-evt') {
      objectToMove = docEvtObjectGroup(recording, sourceType, names.doc);
      fromObject = oppElement(names.opp);
      updateType = 'doc';
    } else if (sourceType.type === 'acct') {
      objectToMove = recElement(recording);
      fromObject = acctElement(names.acct);
      updateType = 'rec';
    } else if (sourceType.type === 'acct/doc') {
      objectToMove = docEvtObjectGroup(recording, sourceType, names.doc);
      fromObject = acctElement(names.acct);
      updateType = 'doc';
    } else if (sourceType.type === 'acct/doc-evt') {
      objectToMove = docEvtObjectGroup(recording, sourceType, names.doc);
      fromObject = acctElement(names.acct);
      updateType = 'doc';
    } else if (sourceType.type === null) {
      objectToMove = recElement(recording);
      updateType = 'rec';
    }
  }
  // link to an opp
  if (toObjType === 'opp') {
    const opp = documentStore.entityDocuments.find(o => o.id === toObjId);
    if (opp) {
      toObject = oppElement(opp.name);
    }
    if (sourceType.type === 'opp') {
      objectToMove = recElement(recording);
      fromObject = oppElement(names.opp);
      updateType = 'rec';
    } else if (sourceType.type === 'doc') {
      objectToMove = docEvtObjectGroup(recording, sourceType, names.doc);
      updateType = 'doc';
    } else if (sourceType.type === 'doc-evt') {
      objectToMove = docEvtObjectGroup(recording, sourceType, names.doc);
      updateType = 'doc';
    } else if (sourceType.type === 'opp/doc') {
      objectToMove = docEvtObjectGroup(recording, sourceType, names.doc);
      fromObject = oppElement(names.opp);
      updateType = 'doc';
    } else if (sourceType.type === 'opp/doc-evt') {
      objectToMove = docEvtObjectGroup(recording, sourceType, names.doc);
      fromObject = oppElement(names.opp);
      updateType = 'doc';
    } else if (sourceType.type === 'acct') {
      objectToMove = recElement(recording);
      fromObject = acctElement(names.acct);
      updateType = 'rec';
    } else if (sourceType.type === 'acct/doc') {
      objectToMove = docEvtObjectGroup(recording, sourceType, names.doc);
      fromObject = acctElement(names.acct);
      updateType = 'doc';
    } else if (sourceType.type === 'acct/doc-evt') {
      objectToMove = docEvtObjectGroup(recording, sourceType, names.doc);
      fromObject = acctElement(names.acct);
      updateType = 'doc';
    } else if (sourceType.type === null) {
      objectToMove = recElement(recording);
      updateType = 'rec';
    }
  }
  // link to a doc
  if (toObjType === 'doc') {
    const doc = documentStore.documents.find(d => d.id === toObjId);
    // if doc is crm doc, we also show crm name under the doc
    updateType = 'rec';
    if (doc) {
      let oppName: null | string = null;
      if (doc.parent && doc.parent.entityName === 'Opportunity') {
        const entityId = doc.parent.id;
        const opp = documentStore.entityDocuments.find(o => o.id === entityId);
        if (opp) {
          oppName = opp.name;
        }
      }
      if (doc.event?.subject) {
        toObject = evtElement(doc.event.subject, oppName);
      } else {
        toObject = docElement(doc.docTitle, oppName);
      }
    }
    if (sourceType.type === 'opp') {
      objectToMove = recElement(recording);
      fromObject = oppElement(names.opp);
    } else if (sourceType.type === 'doc') {
      objectToMove = recElement(recording);
      fromObject = docElement(names.doc, null);
    } else if (sourceType.type === 'doc-evt') {
      objectToMove = recElement(recording);
      fromObject = evtElement(names.doc, null);
    } else if (sourceType.type === 'opp/doc') {
      objectToMove = recElement(recording);
      fromObject = oppDocObjectGroup(names.opp, names.doc);
    } else if (sourceType.type === 'opp/doc-evt') {
      objectToMove = recElement(recording);
      fromObject = oppEvtObjectGroup(names.opp, names.doc);
    } else if (sourceType.type === 'acct') {
      objectToMove = recElement(recording);
      fromObject = acctElement(names.acct);
    } else if (sourceType.type === 'acct/doc') {
      objectToMove = recElement(recording);
      fromObject = acctDocObjectGroup(names.acct, names.doc);
    } else if (sourceType.type === 'acct/doc-evt') {
      objectToMove = recElement(recording);
      fromObject = acctEvtObjectGroup(names.acct, names.doc);
    } else if (sourceType.type === null) {
      objectToMove = recElement(recording);
    }
  }

  // unlink
  if (toObjType === null) {
    updateType = 'unlink';
    if (sourceType.type === 'opp') {
      objectToMove = recElement(recording);
      fromObject = oppElement(names.opp);
    } else if (sourceType.type === 'doc') {
      objectToMove = recElement(recording);
      fromObject = docElement(names.opp, null);
    } else if (sourceType.type === 'doc-evt') {
      objectToMove = recElement(recording);
      fromObject = evtElement(names.doc, null);
    } else if (sourceType.type === 'opp/doc') {
      objectToMove = recElement(recording);
      fromObject = oppDocObjectGroup(names.opp, names.doc);
    } else if (sourceType.type === 'opp/doc-evt') {
      objectToMove = docEvtObjectGroup(recording, sourceType, names.doc);
      fromObject = oppEvtObjectGroup(names.opp, names.doc);
    } else if (sourceType.type === 'acct') {
      objectToMove = recElement(recording);
      fromObject = acctElement(names.acct);
    } else if (sourceType.type === 'acct/doc') {
      objectToMove = recElement(recording);
      fromObject = acctDocObjectGroup(names.acct, names.doc);
    } else if (sourceType.type === 'acct/doc-evt') {
      objectToMove = docEvtObjectGroup(recording, sourceType, names.doc);
      fromObject = acctEvtObjectGroup(names.acct, names.doc);
    }
  }

  let message: JSX.Element | null = null;

  if (toObject === null) {
    message = (
      <div className="flex flex-col gap-1">
        {divider('Unlink')}
        {objectToMove}
        {divider('From')}
        {fromObject}
      </div>
    );
  } else if (fromObject !== null) {
    message = (
      <div className="flex flex-col gap-1">
        {divider('Move')}
        {objectToMove}
        {divider('From')}
        {fromObject}
        {divider('To')}
        {toObject}
      </div>
    );
  } else {
    message = (
      <div className="flex flex-col gap-1">
        {divider('Link')}
        {objectToMove}
        {divider('To')}
        {toObject}
      </div>
    );
  }

  return (
    <div className="relative flex flex-col gap-2 py-4">
      <div className="flex flex-col gap-2 h-[460px] overflow-auto px-4 pb-12">
        {/* header */}
        <div className="w-10 h-10">
          <LinkBrokenIcon className="w-10 h-10 text-orange-500 stroke-2" />
        </div>
        <div>Are you sure you want to make the change?</div>
        <div className="flex flex-col">
          <div className="border rounded border-gray-300 px-2 pb-3 pt-1"> {message}</div>
          {state.checkDocument && (
            <OtherEventsToLink
              checkDocument={state.checkDocument}
              setDocumentIds={checkedDocumentIds =>
                setState(prevState => ({
                  ...prevState,
                  checkedDocumentIds,
                }))
              }
            />
          )}
        </div>
      </div>
      <div className="absolute bottom-0 left-0 pl-32 flex gap-2 pr-10 py-4 bg-white">
        <div>
          <button
            type="button"
            className="py-1 px-4 w-24 bg-gray-300 hover:bg-gray-400 text-gray-800 rounded"
            onClick={() => {
              setParentState(prevState => ({
                ...prevState,
                popupStage: 'list',
              }));
            }}
          >
            Go Back
          </button>
        </div>
        <div>
          <button
            type="button"
            className="flex gap-2 py-1 px-4 bg-blue-600 hover:bg-blue-500 text-white rounded"
            onClick={() => {
              updateDataOnClick(updateType);
            }}
          >
            <div>Continue</div>
          </button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmView;
