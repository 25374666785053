import BaseButton from 'components/BaseButton';
import React, { useState } from 'react';
import ButtonPopover from '../../components/ButtonPopover';
import { SalesEngineerSummary } from '../../types/team-member-request';
import LinkUserPopoverContent from './LinkUserPopoverContent';

interface Props {
  salesEngineerSummaries: SalesEngineerSummary[];
  onSelect: (value: string, salesEngineerSummary: SalesEngineerSummary) => void;
}

function LinkUsersToCrmButtonPopover({ salesEngineerSummaries, onSelect }: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedSalesEngineer, setSelectedSalesEngineer] = useState<SalesEngineerSummary | null>(null);

  return (
    <ButtonPopover
      btnClassName="!justify-start"
      btnColor="secondary"
      btnVariant="text"
      isPopoverOpen={isOpen}
      popoverToggle={show => {
        if (!show) {
          setSelectedSalesEngineer(null);
        }
        setIsOpen(show);
      }}
      popoverContent={
        <div className="flex flex-col">
          {selectedSalesEngineer !== null ? (
            <LinkUserPopoverContent
              value={selectedSalesEngineer.crmValue || ''}
              includedValues={salesEngineerSummaries.map(s => s.crmValue).filter(v => v !== null) as string[]}
              onSelect={value => {
                onSelect(value, selectedSalesEngineer);
                setIsOpen(false);
                setSelectedSalesEngineer(null);
              }}
            />
          ) : (
            <>
              {salesEngineerSummaries.map(s => (
                <BaseButton
                  className="!justify-start"
                  color="secondary"
                  variant="text"
                  key={s.id}
                  onClick={() => setSelectedSalesEngineer(s)}
                >
                  {s.firstName} {s.lastName}
                </BaseButton>
              ))}
            </>
          )}
        </div>
      }
    >
      Link user to CRM
    </ButtonPopover>
  );
}

export default LinkUsersToCrmButtonPopover;
