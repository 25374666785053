import moment from 'moment';
import React, { useState } from 'react';
import useContextPanelStore from 'stores/context-panel';
import useRecordingsStore from 'stores/recordings';
import PlayIcon from '../icons/PlayIcon';
import SpinLoader from '../icons/SpinLoader';
import useQueryParamState from 'components/Hooks/useQueryParamState';
import constants from 'utils/constants';

interface Props {
  recordingId: string;
  startTime: number;
  from: 'discoveryQuestionContextPanel' | 'docEditor' | 'chat' | 'reporting';
  elementId: string;
}

function PlayBack({ recordingId, startTime, from, elementId }: Props) {
  const [, setDrawer] = useQueryParamState(constants.RIGHT_DRAWER_QUERY_PARAM_KEY);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const recordingsStore = useRecordingsStore();
  const contextPanelStore = useContextPanelStore();

  return (
    <button
      id="playback-recording-button"
      type="button"
      className="flex items-center group gap-1 hover:cursor-pointer"
      style={{ lineHeight: '0.75rem' }}
      onClick={async () => {
        // if recording hasn't been pre loaded.
        if (!recordingsStore.recordings.map(i => i.id).includes(recordingId)) {
          setIsLoading(true);
          await recordingsStore.fetchById(recordingId);
          setIsLoading(false);
        }
        if (from === 'discoveryQuestionContextPanel' || from === 'docEditor' || from === 'chat') {
          setDrawer('recordings');
        } else {
          contextPanelStore.open('recordings');
        }
        recordingsStore.setState({
          startTime: startTime + Math.floor(Math.random() * 100),
          recordingId,
          fromContext: from,
          elementId,
        });
      }}
      disabled={isLoading}
    >
      {isLoading ? (
        <SpinLoader className="w-5 h-5 animate-spin" />
      ) : (
        <PlayIcon className="w-5 h-5 text-gray-400 group-hover:bg-blue-50 group-hover:text-blue-500" />
      )}
      <div className="text-blue-500 underline text-xs">{moment.utc(startTime).format('HH:mm:ss')}</div>
    </button>
  );
}

export default PlayBack;
