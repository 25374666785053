import React, { FormEvent, useState } from 'react';

import DocumentPlusIcon from 'components/icons/DocumentPlusIcon';
import XIcon from 'components/icons/XIcon';
import typoStyles from 'components/typo.module.css';

import { concat } from 'utils/styling';

import EmojiPickerComp from 'components/EmojiPicker';
import styles from './CreateTemplateModal.module.css';

interface Props {
  onClose: () => void;
  onSubmit: (name: string, icon: string | null) => Promise<void>;
}

interface Form {
  name: string;
  icon: string | null;
}

interface Validation {
  name: null | string;
}

const DEFAULT_VALIDATION: Validation = {
  name: null,
};

interface State {
  form: Form;
  isLoading: boolean;
  validation: Validation;
}

function CreateTemplateModal({ onSubmit, onClose }: Props) {
  const [state, setState] = useState<State>({
    form: {
      name: '',
      icon: null,
    },
    isLoading: false,
    validation: DEFAULT_VALIDATION,
  });
  const send = async (): Promise<void> => {
    let hasError = false;
    if (state.form.name === '') {
      hasError = true;
      setState({ ...state, validation: { name: 'A name is required' } });
    }
    if (hasError === false) {
      setState(oldState => ({ ...oldState, isLoading: true, validation: DEFAULT_VALIDATION }));
      await onSubmit(state.form.name, state.form.icon);
      setState(oldState => ({ ...oldState, isLoading: false }));
    }
  };
  const submit = async (event: FormEvent): Promise<void> => {
    event.preventDefault();
    await send();
  };
  return (
    <form className={styles.layout} onSubmit={event => submit(event)}>
      <div className={styles.header}>
        <span className={styles['document-icon-container']}>
          <DocumentPlusIcon className="w-7 h-7 text-blue-600" />
        </span>
        <button className={styles['close-button']} onClick={() => onClose()} type="button">
          <XIcon className={styles['close-icon']} />
        </button>
      </div>
      <span className={concat(typoStyles.lg, typoStyles.medium, typoStyles['text-grey-900'])}>Save as template</span>
      <p className={concat(typoStyles.xs, typoStyles['text-grey-500'])}>
        Save this meeting document as a template to re-use it in subsequent documents.
      </p>
      <div className={styles['name-container']}>
        <label className={concat(typoStyles.xs, typoStyles['text-grey-600'])} htmlFor="name">
          Pick an icon
        </label>
        <div className="p-1">
          <EmojiPickerComp
            onAddEmoji={emojiCode => setState({ ...state, form: { ...state.form, icon: emojiCode } })}
            currentIcon={state.form.icon}
            readOnly={false}
          />
        </div>

        <label className={concat(typoStyles.xs, typoStyles['text-grey-600'])} htmlFor="name">
          Enter a name for the template
        </label>
        <input
          className={concat(styles['text-input'], typoStyles.xs)}
          id="name"
          onChange={event => setState({ ...state, form: { ...state.form, name: event.target.value } })}
          placeholder="Enter a name for the template"
          type="text"
          value={state.form.name}
        />
        <span className={concat(typoStyles.xs, typoStyles['text-red-500'])}>{state.validation.name}</span>
      </div>
      <div className={styles['buttons-container']}>
        <button
          className={concat(typoStyles.sm, typoStyles['text-grey-600'], styles['cancel-button'])}
          disabled={state.isLoading}
          onClick={() => onClose()}
          type="button"
        >
          Cancel
        </button>
        <button
          className={concat(typoStyles.sm, typoStyles['text-white'], styles['submit-button'])}
          disabled={state.isLoading}
          type="submit"
        >
          Save Template
        </button>
      </div>
    </form>
  );
}

export default CreateTemplateModal;
