import httpClient from '../http-client';
import { AnalyticColumnSubHeader, ReportingColumn, ReportingFilter, ReportingSort } from '../../types/reporting';

export default async (
  fieldId: string,
  columnName: string,
  description: string,
  dataEntryType: 'number' | 'boolean' | 'string',
  columns: ReportingColumn[],
  filters: ReportingFilter[],
  sorts: ReportingSort[],
): Promise<AnalyticColumnSubHeader> => {
  const { data } = await httpClient.put<AnalyticColumnSubHeader>(`/reporting-columns`, {
    fieldId,
    columnName,
    description,
    dataEntryType,
    columns,
    filters,
    sorts,
  });
  return data;
};
