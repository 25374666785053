import isHotkey from 'is-hotkey';
import { Editor, Transforms } from 'slate';
import NoteEditor from '../NoteEditor';
import { BlockType, CustomElement, CustomText } from '../types';
import { createNode } from '../utils';
import { SlatePlugin } from './types';

export const isMarkActive = (editor: Editor, format: keyof CustomText): boolean => {
  const marks = NoteEditor.marks(editor) as CustomText;
  return marks ? marks[format] === true : false;
};

function enterKeyPlugin(event: React.KeyboardEvent<HTMLDivElement>, editor: Editor): boolean {
  let stopPlugin = false;
  const isEnterPressed = isHotkey('enter', event);
  const isShiftEnterPressed = isHotkey('shift+enter', event);
  if (isEnterPressed || isShiftEnterPressed) {
    const { selection } = editor;
    if (!selection) {
      return stopPlugin;
    }

    const selectionEndPosition = NoteEditor.sp_selectionEndPosition(editor);
    const elementId = selection.anchor.path[0];
    const node = editor.children[elementId];

    // case 1: we are in a table element
    if (node && node.type === BlockType.Table) {
      event.preventDefault();
      Transforms.insertText(editor, '\n');
      // case 2: selection end position is at the end of an element
    } else if (
      selectionEndPosition &&
      NoteEditor.sp_pointEquals(selectionEndPosition, NoteEditor.end(editor, [selectionEndPosition.path[0]]))
    ) {
      // check if the type is BulletList or OrderedList
      // press enter will reset the element into paragraph if the element is empty
      // we need to use the highest path so that we can get the whole string of the element
      if (
        NoteEditor.isBlock(editor, node) &&
        (node.type === BlockType.BulletedList || node.type === BlockType.OrderedList)
      ) {
        // if the element is empty
        if (NoteEditor.sp_isEmpty(editor, editor.children[selection.anchor.path[0]] as CustomElement)) {
          event.preventDefault();
          Transforms.setNodes<CustomElement>(editor, createNode(BlockType.Paragraph));
        }
      } else if (
        NoteEditor.isBlock(editor, node) &&
        !(node.type === BlockType.BulletedList || node.type === BlockType.OrderedList)
      ) {
        event.preventDefault();
        Transforms.insertNodes<CustomElement>(editor, createNode(BlockType.Paragraph));
      }
      stopPlugin = true;
    }
    // case 3: selection end position is at the start of an element, and the element is a heading
    else if (
      selectionEndPosition &&
      NoteEditor.sp_pointEquals(selectionEndPosition, NoteEditor.start(editor, [selectionEndPosition.path[0]]))
    ) {
      if (NoteEditor.isBlock(editor, node) && (node.type === BlockType.H1 || node.type === BlockType.H2)) {
        event.preventDefault();
        Transforms.insertNodes<CustomElement>(editor, createNode(BlockType.Paragraph));
        Transforms.move(editor, { distance: 1, unit: 'offset' });
      }
    }
  }
  return stopPlugin;
}

const EnterKeyPlugin: SlatePlugin = {
  key: 'enter-plugin',
  onKeyDown: enterKeyPlugin,
};

export default EnterKeyPlugin;
