import React from 'react';
import { getEmptyProductGap, ProductCategory, ProductGap } from 'types/product-gap';
import { openFormModal } from 'components/BaseModal';
import { cloneDeep } from 'lodash';
import { v4 } from 'uuid';
import CreateNewGapForm from './CreateNewGapForm';
import useModalStore from 'stores/modal';
import openNewCategoryModal from '../CreateNewCategoryModal/CreateNewCategoryModal';
import useProductGapStore from 'stores/product-gap';

/**
 * open modal to create a new product gap or update an existing one
 * @param gap if exist then update, otherwise create new
 * @returns new created/updated product gap
 */
const openNewGapModal = (gap?: ProductGap): Promise<ProductGap | undefined> => {
  const productGap: ProductGap = gap ? cloneDeep(gap) : getEmptyProductGap();
  const modalId = v4();
  return openFormModal<ProductGap>(
    {
      formData: productGap,
      renderFormBody: ({ register, control, errors }) => (
        <CreateNewGapForm
          productGap={productGap}
          register={register}
          control={control}
          errors={errors}
          addEditCategory={async (category?: ProductCategory) => {
            useModalStore.getState().toggleModalVisible(modalId, true);
            const newCategory = await openNewCategoryModal(category);
            useModalStore.getState().toggleModalVisible(modalId, false);
            return newCategory;
          }}
        />
      ),
      actions: [
        {
          label: 'Cancel',
          color: 'secondary',
        },
        {
          label: gap ? 'Update' : 'Create',
          color: 'primary',
          type: 'submit',
          cb: async (fd: ProductGap) => {
            const answers: { [id: string]: string } = {};
            fd.fields.forEach(field => {
              answers[field.id] = field.answer.trim();
            });
            const category = useProductGapStore.getState().productCategories.find(cat => cat.id === fd.category?.id);
            if (fd.id) {
              await useProductGapStore
                .getState()
                .updateProductGap(
                  fd.id,
                  fd.documentId,
                  fd.category?.id ?? '',
                  answers,
                  category?.integrationId || null,
                );
            } else {
              await useProductGapStore
                .getState()
                .createGap(fd.category?.id ?? '', fd.documentId, answers, category?.integrationId || null);
            }
          },
        },
      ],
    },
    {
      className: 'w-[46rem]',
      title: gap ? 'Update product gap' : 'Create a new product gap',
      bodyClassName: '!pl-0 !pr-0',
    },
    modalId,
  ).then(rsp => {
    return typeof rsp === 'object' ? (rsp as ProductGap) : undefined;
  });
};

export default openNewGapModal;
