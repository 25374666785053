import { XMarkIcon } from '@heroicons/react/24/outline';
import React from 'react';

import useDocumentStore from 'stores/document';
import { ReportingFilter } from '../../../types/reporting';
import FieldTypeIcon from './FieldTypeIcon';
import { CrmFieldType } from '../../../types/crm';

interface Props {
  filter: ReportingFilter;
  currentDocumentId?: string;
}

function FilterPill({ filter, currentDocumentId }: Props) {
  const documentStore = useDocumentStore();

  let fieldFilter;
  if (filter.groupId === 'Account') {
    fieldFilter = documentStore.fieldFilters.account.find(f => f.fieldId === filter.columnId);
  } else if (filter.groupId === 'Opportunity') {
    fieldFilter = documentStore.fieldFilters.opportunity.find(f => f.fieldId === filter.columnId);
  }
  const title = fieldFilter ? fieldFilter.fieldLabel : 'Unknown Field';

  return (
    <div className="flex items-center gap-1 px-1 rounded-full border border-gray-300 text-gray-500">
      <FieldTypeIcon className="w-4 h-4" fieldType={filter.dataEntryType as CrmFieldType} />
      <div className="flex font-normal text-sm">{title}</div>
      <button
        type="button"
        className="flex"
        onClick={() => {
          const filters = documentStore.documentFilters.filter(f => f.columnId !== filter.columnId);
          documentStore.setDocumentFilters(filters, documentStore.showOpportunityOnly, currentDocumentId);
        }}
      >
        <XMarkIcon className="w-4 h-4 text-gray-400" />
      </button>
    </div>
  );
}

FilterPill.defaultProps = {
  currentDocumentId: undefined,
};

export default FilterPill;
