import React, { useCallback, useEffect, useState } from 'react';
import getFieldTags from 'api/fields/get-field-tags';
import getFields from 'api/fields/get-fields';
import { DndContext } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Checkbox, FormControlLabel } from '@mui/material';
import { get, maxBy, minBy, noop, sortBy } from 'lodash';
import useUserStore from 'stores/user';
import { getLocalstorageItem, setLocalstorageItem } from 'utils/localstorage';
import ButtonPopover from './ButtonPopover';
import styles from './FieldManagement.module.css';
import ObjectView from './FieldManagement/ObjectView';
import { Field } from './FieldManagement/types';
import Loader from './Loader';
import FilterIcon from './icons/FilterIcon';
import updateField from 'api/field/update-field';

export interface FieldManagementFilter {
  isDefault: boolean;
  createdByMe: boolean;
  createdByOthers: boolean;
}

export const DEFAULT_FIELD_MANAGEMENT_FILTER: FieldManagementFilter = {
  isDefault: true,
  createdByMe: true,
  createdByOthers: false,
};

export const FIELD_MANAGEMENT_FILTER_LOCALSTORAGE_KEY = 'field-management-filter';

const FIELD_MANAGEMENT_FILTER_ITEMS = [
  { key: 'isDefault', label: 'Mandatory fields' },
  { key: 'createdByMe', label: 'Fields created by me' },
  { key: 'createdByOthers', label: 'Fields created by others' },
];

interface State {
  isLoading: boolean;
  accountFields: Field[];
  opportunityFields: Field[];
}

interface Props {
  cancelButtonText: string;
  next: () => void;
}

function FieldManagement({ cancelButtonText, next }: Props) {
  const [state, setState] = useState<State>({
    isLoading: false,
    accountFields: [],
    opportunityFields: [],
  });
  const userStore = useUserStore();

  const [fieldFilter, setFieldFilter] = useState<FieldManagementFilter>(
    getLocalstorageItem<FieldManagementFilter>(
      FIELD_MANAGEMENT_FILTER_LOCALSTORAGE_KEY,
      DEFAULT_FIELD_MANAGEMENT_FILTER,
    ),
  );

  const handleFilterChange = useCallback((key: string, checked: boolean) => {
    setFieldFilter(prev => {
      const newFilter = key
        ? {
            ...prev,
            [key]: FIELD_MANAGEMENT_FILTER_ITEMS.every(filter => filter.key === key || !get(prev, filter.key))
              ? true
              : checked,
          }
        : {
            isDefault: true,
            isAiEnabled: true,
            createdByMe: true,
            createdByOthers: true,
          };
      setLocalstorageItem(FIELD_MANAGEMENT_FILTER_LOCALSTORAGE_KEY, newFilter);
      return newFilter;
    });
  }, []);

  useEffect(() => {
    const fetch = async () => {
      setState(prevState => ({ ...prevState, isLoading: true }));
      const { accountFields, opportunityFields } = await getFields();
      const fieldTags = await getFieldTags();
      setState(prevState => ({
        ...prevState,
        isLoading: false,
        accountFields: accountFields.map(f => ({ ...f, isNew: false })),
        opportunityFields: opportunityFields.map(f => ({ ...f, isNew: false })),
        fieldTags,
      }));
    };
    fetch();
  }, []);

  const updateFieldValuesState = useCallback(
    (type: 'account' | 'opportunity', fromId: string, toId: string) => {
      const newFields = type === 'account' ? [...state.accountFields] : [...state.opportunityFields];
      const fromIndex = newFields.findIndex(fv => fv.id === fromId);
      const toIndex = newFields.findIndex(fv => fv.id === toId);
      const dragUp = fromIndex > toIndex;
      const minOrder = Math.floor(minBy(newFields, 'order')?.order ?? 0);
      const maxOrder = Math.ceil(maxBy(newFields, 'order')?.order ?? 0);
      // set new order between drop position
      const newOrder =
        (newFields[toIndex].order +
          (newFields[toIndex + (dragUp ? -1 : 1)]?.order ?? (dragUp ? minOrder - 1 : maxOrder + 1))) /
        2;
      try {
        setState(prev => ({ ...prev, isSaveFieldsLoading: true }));
        const newFromField = { ...newFields[fromIndex], order: newOrder };
        updateField(newFromField);
        newFields[fromIndex] = newFromField;
        setState(prevState => ({
          ...prevState,
          [type === 'account' ? 'accountFields' : 'opportunityFields']: sortBy(newFields, 'order'),
        }));
      } catch {
        noop();
      } finally {
        setState(prev => ({ ...prev, isSaveFieldsLoading: false }));
      }
    },
    [state.accountFields, state.opportunityFields],
  );

  return (
    <div>
      <div className="flex items-center justify-end mb-4 mr-4">
        <ButtonPopover
          btnColor="secondary"
          btnVariant="contained"
          btnClassName="!text-sm !h-7 !px-2"
          popoverClassName={styles.filterPopupContainer}
          popoverContent={
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    indeterminate={
                      FIELD_MANAGEMENT_FILTER_ITEMS.some(filter => get(fieldFilter, filter.key)) &&
                      !FIELD_MANAGEMENT_FILTER_ITEMS.every(filter => get(fieldFilter, filter.key))
                    }
                    checked={FIELD_MANAGEMENT_FILTER_ITEMS.every(filter => get(fieldFilter, filter.key))}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleFilterChange('', event.target.checked)
                    }
                  />
                }
                label="All fields"
              />
              <div className="flex flex-col items-start pl-4">
                {FIELD_MANAGEMENT_FILTER_ITEMS.map(filter => (
                  <FormControlLabel
                    key={filter.key}
                    control={
                      <Checkbox
                        size="small"
                        checked={get(fieldFilter, filter.key)}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          handleFilterChange(filter.key, event.target.checked)
                        }
                      />
                    }
                    label={filter.label}
                  />
                ))}
              </div>
            </>
          }
        >
          <FilterIcon className="w-4 h-4" />
          <span>Filter</span>
        </ButtonPopover>
      </div>
      <div className="flex items-center mb-4 gap-2 px-5">
        <h1 className="text-4xl font-semibold m-0">CRM Field Automation</h1>
      </div>
      <p className="text-gray-500 px-5">
        Let AI take care of automatic fill-ups for you by creating CRM field automation tasks.
      </p>
      {state.isLoading ? (
        <div className="w-full h-full flex items-center justify-center">
          <Loader />
        </div>
      ) : (
        <div>
          <DndContext
            onDragEnd={event => {
              const { active, over } = event;
              if (active && over && active.id !== over.id) {
                updateFieldValuesState('account', active.id as string, over.id as string);
              }
            }}
          >
            <SortableContext items={state.accountFields} strategy={verticalListSortingStrategy}>
              <ObjectView
                className="py-4 pr-4"
                entityName="Account"
                fields={state.accountFields}
                onFieldsChange={accountFields => {
                  setState(prevState => ({ ...prevState, accountFields }));
                }}
                fieldFilter={fieldFilter}
                userId={userStore.user?.id ?? ''}
                isAdmin={!!userStore.user?.isAdmin}
              />
            </SortableContext>
          </DndContext>
          <div className="border-t w-full" />
          <DndContext
            onDragEnd={event => {
              const { active, over } = event;
              if (active && over && active.id !== over.id) {
                updateFieldValuesState('opportunity', active.id as string, over.id as string);
              }
            }}
          >
            <SortableContext items={state.opportunityFields} strategy={verticalListSortingStrategy}>
              <ObjectView
                className="py-4 pr-4"
                entityName="Opportunity"
                fields={state.opportunityFields}
                onFieldsChange={opportunityFields => {
                  setState(prevState => ({ ...prevState, opportunityFields, isFieldsEdited: true }));
                }}
                fieldFilter={fieldFilter}
                userId={userStore.user?.id ?? ''}
                isAdmin={!!userStore.user?.isAdmin}
              />
            </SortableContext>
          </DndContext>
        </div>
      )}
      <div className="py-20" />
      {!!cancelButtonText && (
        <div className="w-full text-right mt-10 sticky bottom-0 bg-white pb-10 pt-5">
          <button
            type="button"
            className="w-40 h-12 rounded-lg bg-blue-600 hover:bg-blue-500 text-white disabled:bg-gray-200"
            onClick={next}
          >
            {cancelButtonText}
          </button>
        </div>
      )}
    </div>
  );
}

export default FieldManagement;
