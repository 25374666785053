/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable no-nested-ternary, consistent-return */
import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Editor, Transforms, Range } from 'slate';
import { SlatePluginKeyboardEvent } from './types';
import UnicodeEmoji from './EmojiCommandPlugin/UnicodeEmoji';
import styles from './EmojiCommandPlugin.module.css';
import { useSlateStatic } from 'slate-react';
import isHotkey from 'is-hotkey';
import { concat } from 'utils/styling';
import getPopoverInfo from 'utils/popover';
import { throttle } from 'lodash';

interface Emoji {
  emoji: string;
  description: string;
  version: string;
  keywords: string[];
  codePoints: string[];
  category: string;
  group: string;
  subgroup: string;
}

function filterEmoji(emojiObj: Emoji, emojiSearch: string | undefined) {
  if (!emojiSearch) {
    return false;
  }
  const search = emojiSearch.toLowerCase().trim();
  if (
    emojiObj.description.toLowerCase().includes(search) ||
    emojiObj.keywords.filter(k => k.toLowerCase().includes(search)).length
    // more match
    // ||
    // emojiObj.category.includes(search) ||
    // emojiObj.group.includes(search) ||
    // emojiObj.subgroup.includes(search)
  ) {
    return true;
  }
  return false;
}

type PortalProps = { element: HTMLDivElement | null; children: React.ReactNode };
const EMOJI_PER_LINE = 12;
const MAX_EMOJI_SHOW_COUNT = 500;

function Portal({ element, children }: PortalProps) {
  return typeof document === 'object' ? ReactDOM.createPortal(children, element || document.body) : null;
}

export type EmojiCommandRef = {
  searchTxt: () => string | null;
  setSearchTxt: React.Dispatch<React.SetStateAction<string | null>>;
  moveSelectedEmojiCursor: (offsetX: number, offsetY: number) => void;
  insertSelectedEmoji: () => void;
};

interface Props {
  scrollContainer: HTMLDivElement | null;
}

const EmojiCommand = React.forwardRef<EmojiCommandRef, Props>(({ scrollContainer }, forwardRef) => {
  // emoji search text after user input `:`, null means user have not input `:`
  const [searchTxt, setSearchTxt] = useState<string | null>(null);
  const [highlightedEmoji, setHighlightedEmoji] = useState('');
  const [commandCssProperty, setCommandCssProperty] = React.useState<CSSProperties | null>(null);
  const editor = useSlateStatic();
  const ref = React.useRef<HTMLDivElement>(null);
  const emojiShowRef = useRef(!!searchTxt);

  const updatePopupPosition = useCallback(() => {
    const domSelection = window.getSelection();
    if (domSelection) {
      const domRange = domSelection.getRangeAt(0);
      const popupInfo = getPopoverInfo(
        ((domSelection.focusNode as HTMLElement)?.getBoundingClientRect
          ? domSelection.focusNode
          : domRange) as unknown as Element,
        'left',
        20 * 16,
        28 * 16,
        5,
        undefined,
        true,
      );

      setCommandCssProperty(prev => ({
        opacity: '1',
        transform: popupInfo.style?.transform,
        ...popupInfo.anchorPosition,
        left: prev?.left || popupInfo.anchorPosition?.left,
      }));
    }
  }, []);

  React.useEffect(() => {
    const detectOutsideClick = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setSearchTxt(null);
        setHighlightedEmoji('');
      }
    };
    document.addEventListener('mousedown', detectOutsideClick);

    if (!searchTxt) {
      setCommandCssProperty(null);
      return () => {
        document.removeEventListener('mousedown', detectOutsideClick);
      };
    }

    updatePopupPosition();
    return () => {
      document.removeEventListener('mousedown', detectOutsideClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!searchTxt]);

  useEffect(() => {
    if (!searchTxt) {
      setHighlightedEmoji('');
    }
    emojiShowRef.current = !!searchTxt;
  }, [searchTxt]);

  React.useEffect(() => {
    if (scrollContainer) {
      const handleScroll = throttle(() => {
        if (emojiShowRef.current) {
          updatePopupPosition();
        }
      }, 10);
      scrollContainer.addEventListener('scroll', handleScroll);
      return () => scrollContainer.removeEventListener('scroll', handleScroll);
    }
  }, [scrollContainer]);

  const emojis = useMemo(() => {
    return searchTxt
      ? UnicodeEmoji.emojis.filter(e => filterEmoji(e, searchTxt || '')).slice(0, MAX_EMOJI_SHOW_COUNT)
      : null;
  }, [searchTxt]);

  useEffect(() => {
    if (emojis) {
      const exist = emojis?.find(e => e.emoji === highlightedEmoji);
      if (!exist) {
        setHighlightedEmoji(emojis[0]?.emoji || '');
      }
    }
  }, [emojis, highlightedEmoji]);

  const insertEmoji = useCallback(() => {
    const { selection } = editor;
    if (!selection) return;
    if (!highlightedEmoji) return;
    if (typeof searchTxt === 'string') {
      Transforms.delete(editor, { distance: searchTxt.length + 1, unit: 'character', reverse: true });
    }
    editor.insertText(highlightedEmoji);
    setSearchTxt(null);
    setHighlightedEmoji('');
  }, [editor, highlightedEmoji]);

  React.useImperativeHandle(forwardRef, () => ({
    searchTxt: () => searchTxt,
    setSearchTxt,
    moveSelectedEmojiCursor: (offsetX: number, offsetY: number) => {
      if (emojis) {
        const index = emojis.findIndex(e => e.emoji === highlightedEmoji);
        if (typeof index === 'number' && index >= 0) {
          const targetIndex = index + offsetX + offsetY * EMOJI_PER_LINE;
          const targetEmoji = emojis[targetIndex];
          if (targetEmoji) {
            setHighlightedEmoji(targetEmoji.emoji);
            const targetEmojiEl = ref.current?.querySelector(`.emoji-${targetEmoji.emoji}`);
            const rect = targetEmojiEl?.getBoundingClientRect();
            const containerRect = ref.current?.getBoundingClientRect();
            if (targetEmojiEl && rect && containerRect) {
              // scroll into view if needed
              if (rect.top - 4 < containerRect.top) {
                ref.current?.scrollBy(0, rect.top - 4 - containerRect.top);
              } else if (rect.bottom + 4 > containerRect.bottom) {
                ref.current?.scrollBy(0, rect.bottom + 4 - containerRect.bottom);
              }
            }
          }
        }
      }
    },
    insertSelectedEmoji: () => {
      insertEmoji();
    },
  }));

  if (emojis && commandCssProperty) {
    return (
      <Portal element={null}>
        <div ref={ref} className={styles.command} style={commandCssProperty}>
          <div className={styles.popupContent}>
            <div className={styles.emojis}>
              {emojis?.length === 0 && <span className="text-sm text-gray-500">No results</span>}
              {emojis.map(emoji => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                <div
                  key={emoji.emoji}
                  className={concat(
                    styles.emoji,
                    highlightedEmoji === emoji.emoji && styles.highlighted,
                    `emoji-${emoji.emoji}`,
                  )}
                  onMouseEnter={() => {
                    setHighlightedEmoji(emoji.emoji);
                  }}
                  onClick={insertEmoji}
                >
                  <div className={styles.emojiTxt}>{emoji.emoji}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Portal>
    );
  }
  return null;
});

EmojiCommand.displayName = 'EmojiCommand';

function EmojiCommandPlugin(scrollContainer: HTMLDivElement | null) {
  const emojiCommandRef = useRef<EmojiCommandRef>(null);
  const onKeyDown: SlatePluginKeyboardEvent = (event: React.KeyboardEvent<HTMLDivElement>, editor: Editor) => {
    if (editor.selection && Range.isCollapsed(editor.selection)) {
      const searchTxt = emojiCommandRef.current?.searchTxt();
      if (searchTxt === null) {
        // start to track input search string
        if (event.key === ':') {
          emojiCommandRef.current?.setSearchTxt('');
        }
      } else if (typeof searchTxt === 'string') {
        if (event.key === ':') {
          emojiCommandRef.current?.setSearchTxt('');
        } else if (isHotkey('space', event)) {
          emojiCommandRef.current?.setSearchTxt(null);
        } else if (isHotkey('delete', event)) {
          emojiCommandRef.current?.setSearchTxt(null);
        } else if (isHotkey('backspace', event)) {
          if (searchTxt.length >= 1) {
            emojiCommandRef.current?.setSearchTxt(searchTxt.slice(0, -1));
          } else {
            emojiCommandRef.current?.setSearchTxt(null);
          }
        } else if (isHotkey('esc', event)) {
          emojiCommandRef.current?.setSearchTxt(null);
        } else if ((event.ctrlKey || event.metaKey) && event.key.length === 1) {
          // escape emoji when ctrl or meta key is pressed with any other key
          emojiCommandRef.current?.setSearchTxt(null);
        } else if (
          isHotkey('left', event) ||
          isHotkey('right', event) ||
          isHotkey('up', event) ||
          isHotkey('down', event)
        ) {
          if (searchTxt.length >= 1) {
            event.preventDefault();
            emojiCommandRef.current?.moveSelectedEmojiCursor(
              isHotkey('left', event) ? -1 : isHotkey('right', event) ? 1 : 0,
              isHotkey('up', event) ? -1 : isHotkey('down', event) ? 1 : 0,
            );
          } else {
            emojiCommandRef.current?.setSearchTxt(null);
          }
        } else if (isHotkey('enter', event)) {
          if (searchTxt.length >= 1) {
            event.preventDefault();
            emojiCommandRef.current?.insertSelectedEmoji();
            return true;
          }
          emojiCommandRef.current?.setSearchTxt(null);
        } else if (event.key.length === 1) {
          emojiCommandRef.current?.setSearchTxt(prev => prev + event.key);
        }
      }
    }
    return false;
  };

  return {
    key: 'emoji-command-plugin',
    onKeyDown,
    element: <EmojiCommand scrollContainer={scrollContainer} ref={emojiCommandRef} />,
  };
}

export default EmojiCommandPlugin;
