import React, { useEffect, useState } from 'react';
import moment from 'moment';

import Popover from 'components/Popover';
import BaseDatePicker from 'components/BaseDatePicker';
import constants from 'utils/constants';

interface Props {
  anchorEl: HTMLButtonElement | null;
  isOpen: boolean;
  onClose: () => void;
  date: moment.Moment;
  onDateChange: (date: string) => void;
}

function DatePickerPopover({ anchorEl, isOpen, onClose, date, onDateChange }: Props) {
  const [currentDate, setCurrentDate] = useState<Date>(date.toDate());

  useEffect(() => {
    setCurrentDate(date.toDate());
  }, [date]);

  return (
    <Popover
      anchorEl={anchorEl}
      onClose={onClose}
      isOpen={isOpen}
      transformOrigin={{ horizontal: 'left', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
    >
      <div className="p-1">
        <BaseDatePicker
          mode="single"
          selected={currentDate}
          onSelect={d => {
            if (d) setCurrentDate(d);
          }}
        />
        <div className="my-3 flex justify-center items-center gap-3">
          <button className="px-8 py-2 rounded border hover:bg-gray-50 text-gray-500" type="button" onClick={onClose}>
            Cancel
          </button>
          <button
            className="px-8 py-2 rounded bg-blue-600 hover:bg-blue-500 text-white"
            type="button"
            onClick={() => {
              onDateChange(moment(currentDate).format(constants.ISO_DATE_FORMAT));
              onClose();
            }}
          >
            Apply
          </button>
        </div>
      </div>
    </Popover>
  );
}

export default DatePickerPopover;
