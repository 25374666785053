import { SuggestedAnswer } from 'types/field-value';
import httpClient from '../http-client';

export interface Response {
  suggestedAnswer: SuggestedAnswer;
}

export default async (suggestedAnswerId: string, acceptanceStatus: string | null): Promise<Response> => {
  const { data } = await httpClient.put<Response>(`/discovery-question-suggested-answers/${suggestedAnswerId}`, {
    acceptanceStatus,
  });
  return data;
};
