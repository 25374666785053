import React, { useRef } from 'react';
import { LinkElement as LinkElementType } from '../../types';
import styles from './LinkForm.module.css';

interface Props {
  element: LinkElementType;
  onUpdateLink: (newLink: string) => void;
}

function LinkFormInput({ element, onUpdateLink }: Props) {
  const [link, setLink] = React.useState<string>(element.link);

  const ref = useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (ref.current) ref.current.focus();
  }, [ref]);

  return (
    <div className="border-gray-300 border rounded">
      <input
        ref={ref}
        value={link}
        className={styles.input}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            onUpdateLink(link.trim());
          }
        }}
        onChange={e => {
          setLink(e.target.value);
        }}
      />
    </div>
  );
}

export default LinkFormInput;
