import { create } from 'zustand';

export type PanelContext = 'recordings' | 'chat' | 'product-gap' | 'product-category' | 'meetings' | null;

interface State {
  panelContext: PanelContext;
  jumpToSuggestedAnswerId?: string;
  setState: (newState: Partial<State>) => State;
  open: (panelContext: PanelContext) => void;
  close: () => void;
}

const useContextPanelStore = create<State>((set, get) => ({
  panelContext: null,
  jumpToSuggestedAnswerId: undefined,
  setState: newState => {
    set(state => {
      return { ...state, ...newState };
    });
    return get();
  },
  open: (panelContext: PanelContext) => {
    set(prevState => ({
      ...prevState,
      panelContext,
    }));
  },
  close: () => {
    set(prevState => ({ ...prevState, panelContext: null }));
  },
}));

export default useContextPanelStore;
