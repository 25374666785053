import React, { useMemo, useState } from 'react';
import styles from './DatePickerField.module.css';
import SpinLoader from 'components/icons/SpinLoader';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import constants from 'utils/constants';
import CalendarIcon from 'components/icons/CalendarIcon';
import BaseButton from 'components/BaseButton';
import moment, { Moment } from 'moment';
import { concat } from 'utils/styling';

interface Props {
  value: string | null | undefined;
  onChange: (value: string) => void;
  format?: string;
  loading?: boolean;
  isError?: boolean;
  placeholder?: string;
  size?: 'small' | 'normal';
  disabled?: boolean;
}

const DatePickerField = React.forwardRef<HTMLButtonElement, Props>(
  ({ value, onChange, format, loading, isError, placeholder, size, disabled }: Props, ref) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const displayValue = useMemo(() => {
      if (typeof value === 'string' && value.trim()) {
        return moment(value).format(format || constants.DATE_FORMAT);
      }
      return '';
    }, [value, format]);

    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          open={isOpen}
          onClose={() => setIsOpen(false)}
          disableMaskedInput
          value={value}
          onChange={(newValue: Moment | null) => {
            onChange(newValue ? newValue.toISOString() : '');
          }}
          renderInput={params => (
            <BaseButton
              ref={ref}
              type="button"
              color="secondary"
              variant="outlined"
              onClick={() => setIsOpen(true)}
              disabled={disabled}
              className={concat(
                styles.pickerBtn,
                styles[size || 'normal'],
                isOpen && styles.open,
                !value && styles.noValue,
                isError && styles.error,
              )}
              contentClassName={styles.pickBtnContent}
              disableRipple
            >
              <span className={styles.pickBtnLabel} ref={params.inputRef}>
                {displayValue || placeholder || 'Select a date'}
              </span>
              {loading ? (
                <SpinLoader className={concat('w-6 h-6 animate-spin text-orange-500')} />
              ) : (
                <CalendarIcon className={styles.pickBtnIcon} />
              )}
            </BaseButton>
          )}
        />
      </LocalizationProvider>
    );
  },
);

DatePickerField.displayName = 'DatePickerField';
DatePickerField.defaultProps = {
  loading: undefined,
  isError: undefined,
  format: undefined,
  placeholder: undefined,
  size: 'normal',
  disabled: undefined,
};

export default DatePickerField;
