import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useDocumentStore from 'stores/document';
import useModalStore from 'stores/modal';
import SpinLoader from './icons/SpinLoader';

import TrashIcon from './icons/TrashIcon';

interface Props {
  documentId: string;
  navigateTo: string | null;
}

function DeleteDocumentModal({ documentId, navigateTo }: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const modalStore = useModalStore();
  const documentStore = useDocumentStore();

  return (
    <div className="w-96 p-5">
      <div className="flex items-center justify-between">
        <div className="w-14 h-14 bg-red-200 rounded-full flex justify-center items-center">
          <TrashIcon className="w-8 h-8 text-red-500" />
        </div>
        <button
          type="button"
          className="w-7 h-7 p-1 rounded-lg text-gray-400 hover:bg-gray-200"
          onClick={modalStore.close}
        >
          <XMarkIcon />
        </button>
      </div>
      <div className="text-lg font-medium mt-5">Are you sure you want to delete?</div>
      <div className="text-sm text-gray-400 mt-3">Once document is removed, you cannot retrieve the document back.</div>
      <div className="flex justify-end items-center gap-2 mt-5">
        <button
          type="button"
          className="px-4 py-2 bg-gray-100 hover:bg-gray-200 text-gray-500 rounded"
          onClick={modalStore.close}
        >
          Cancel
        </button>
        <button
          type="button"
          className="flex items-center gap-2 px-4 py-2 bg-red-500 hover:bg-red-400 text-white rounded"
          onClick={async () => {
            setIsLoading(true);
            await documentStore.deleteDocument(documentId);
            setIsLoading(false);
            modalStore.close();
            // only navigate when current document deleted
            if (navigateTo && window.location.href.includes(documentId)) navigate(navigateTo);
          }}
        >
          {isLoading && <SpinLoader className="animate-spin w-4 h-4" />}
          Delete
        </button>
      </div>
    </div>
  );
}

export default DeleteDocumentModal;
