import React, { useCallback, useRef, useState } from 'react';
import Popover from 'components/Popover';
import { concat } from 'utils/styling';
import { ListItem } from 'types/list-item';
import BaseButton, { BaseButtonColor, BaseButtonVariant } from './BaseButton';
import PopoverMenuList from './PopoverMenuList';
import { TooltipProps } from '@mui/material';

interface Props<T> {
  btnVariant?: BaseButtonVariant;
  btnColor?: BaseButtonColor;
  btnClassName?: string;
  btnContentClassName?: string;
  popoverContent?: React.ReactNode;
  popoverClassName?: string;
  menus?: ListItem<T>[];
  handleMenuClick?: (menu: ListItem<T>) => void;
  children?: React.ReactNode;
  closeWhenPopoverClick?: boolean;
  iconBtn?: boolean;
  popoverToggle?: (show: boolean) => void;
  horizontalOrigin?: 'left' | 'right';
  tooltip?: string;
  tooltipPlacement?: TooltipProps['placement'];
  loading?: boolean;
  // the reason we need this is to handle nested popover buttons where clicking a child popover button will close the parent popover
  stopPropagation?: boolean;
  isPopoverOpen?: boolean;
  onBtnClick?: () => void;
  disabled?: boolean;
  stopPropagationWhenClick?: boolean;
}

function ButtonPopover<T>({
  btnVariant,
  btnColor,
  btnClassName,
  btnContentClassName,
  popoverContent,
  popoverClassName,
  menus,
  handleMenuClick,
  children,
  closeWhenPopoverClick,
  iconBtn,
  popoverToggle,
  horizontalOrigin,
  tooltip,
  tooltipPlacement,
  loading,
  stopPropagation,
  isPopoverOpen,
  onBtnClick,
  disabled,
  stopPropagationWhenClick,
}: Props<T>) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);

  const handlePopoverToggle = useCallback((show: boolean) => {
    setIsOpen(show);
    popoverToggle?.(show);
  }, []);

  return (
    <>
      <BaseButton
        ref={ref}
        variant={btnVariant}
        color={btnColor}
        type="button"
        className={concat('flex items-center gap-1 hover:bg-gray-100 rounded p-1', btnClassName)}
        contentClassName={btnContentClassName}
        onClick={e => {
          if (onBtnClick) {
            onBtnClick();
          } else {
            handlePopoverToggle(true);
          }
          if (stopPropagation) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
        iconBtn={iconBtn}
        tooltip={tooltip}
        tooltipPlacement={tooltipPlacement}
        loading={loading}
        disabled={disabled}
      >
        {children}
      </BaseButton>
      {menus ? (
        <PopoverMenuList
          isOpen={isOpen && isPopoverOpen !== false}
          onClose={() => handlePopoverToggle(false)}
          anchorEl={ref.current}
          menus={menus}
          handleMenuClick={handleMenuClick}
          popoverClassName={popoverClassName}
          horizontalOrigin={horizontalOrigin}
          stopPropagationWhenClick={stopPropagationWhenClick}
        />
      ) : (
        <Popover
          isOpen={isOpen && isPopoverOpen !== false}
          onClose={() => handlePopoverToggle(false)}
          anchorEl={ref.current}
          transformOrigin={{ vertical: 'top', horizontal: horizontalOrigin || 'right' }}
          anchorOrigin={{ vertical: 'bottom', horizontal: horizontalOrigin || 'right' }}
          disableAutoFocus
          onPopoverClick={() => closeWhenPopoverClick && handlePopoverToggle(false)}
          stopPropagationWhenClick={stopPropagationWhenClick}
        >
          <div className={concat('w-full px-3 py-2 w-72', popoverClassName)}>{popoverContent}</div>
        </Popover>
      )}
    </>
  );
}

ButtonPopover.defaultProps = {
  btnVariant: 'text',
  btnColor: 'primary',
  btnClassName: '',
  btnContentClassName: '',
  popoverClassName: '',
  children: undefined,
  closeWhenPopoverClick: false,
  iconBtn: false,
  popoverToggle: undefined,
  popoverContent: undefined,
  menus: undefined,
  handleMenuClick: undefined,
  horizontalOrigin: undefined,
  tooltip: undefined,
  tooltipPlacement: 'top',
  loading: undefined,
  stopPropagation: false,
  isPopoverOpen: undefined,
  onBtnClick: undefined,
  disabled: false,
  stopPropagationWhenClick: undefined,
};

export default ButtonPopover;
