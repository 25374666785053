/* eslint-disable react/jsx-props-no-spreading, jsx-a11y/click-events-have-key-events, import/no-cycle, jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState } from 'react';
import { Transforms, Selection } from 'slate';
import { ReactEditor, RenderElementProps, useSlateStatic } from 'slate-react';
import { concat } from 'utils/styling';
import useUserStore from 'stores/user';
import { LinkElement as LinkElementType } from '../types';
import { unwrapLink } from '../EditorContext/withInlines';
import LinkForm from './LinkElement/LinkForm';

import styles from './LinkElement.module.css';
import LinkBar from './LinkElement/LinkBar';
import NoteEditor from '../NoteEditor';

interface Props extends RenderElementProps {
  element: LinkElementType;
  readOnly: boolean;
}

function LinkElement({ attributes, element, readOnly, children }: Props) {
  const userStore = useUserStore();
  const [openLinkForm, setOpenLinkForm] = useState<boolean>(false);
  const [openLinkBar, setOpenLinkBar] = useState<boolean>(false);
  const ref = useRef(null);
  const editor = useSlateStatic();
  const previousSelection = useRef<Selection>(null);

  useEffect(() => {
    const open = !!element.beingEditedBy && element.beingEditedBy === userStore.user?.id;
    const path = ReactEditor.findPath(editor, element);
    previousSelection.current = {
      anchor: NoteEditor.end(editor, path),
      focus: NoteEditor.end(editor, path),
    };

    setOpenLinkForm(open);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element.beingEditedBy, userStore.user?.id]);

  const handleUpdateLink = (newLink: string) => {
    NoteEditor.sp_removeLinkBeingEditedBy(editor, element);
    const path = ReactEditor.findPath(editor, element);
    if (newLink) {
      setTimeout(() => {
        if (previousSelection.current) {
          ReactEditor.focus(editor);
          Transforms.select(editor, previousSelection.current);
        }
        if (newLink !== element.link) {
          Transforms.setNodes(
            editor,
            { link: newLink, beingEditedBy: null },
            {
              at: path,
            },
          );
        }
      });
    } else {
      setTimeout(() => {
        ReactEditor.focus(editor);
        if (previousSelection.current) {
          Transforms.select(editor, previousSelection.current);
        }
        unwrapLink(editor, path);
      });
    }
    setOpenLinkForm(false);
  };

  let className;

  if (openLinkForm) {
    className = concat(styles.link, styles['link-highlight']);
  }
  // ideally for collaborators, if a link is empty, we shouldn't show the underline
  // however, we have to still show it as
  // 1. when a user add a link. 2. execute undo. 3. then the element state will now be
  // {link = '', type = 'link'}
  // this is still a link type and if we don't show the underline, the use will feel odd as why the link button in the toolbar is disabled
  // TODO: re-implement the link editing highlight mechanism as when user execute undo once, the element state should go back to the original one where the element is unwrapped.
  else {
    className = styles.link;
  }

  return (
    <>
      {!readOnly && openLinkForm && (
        <LinkForm linkFormOpen={openLinkForm} refElement={ref} element={element} onUpdateLink={handleUpdateLink} />
      )}
      {openLinkBar && (
        <LinkBar
          linkBarOpen={openLinkBar}
          refElement={ref}
          element={element}
          setOpenLinkBar={setOpenLinkBar}
          setOpenLinForm={open => {
            const path = ReactEditor.findPath(editor, element);
            previousSelection.current = { anchor: NoteEditor.end(editor, path), focus: NoteEditor.end(editor, path) };
            setOpenLinkForm(open);
          }}
          readOnly={readOnly}
        />
      )}
      <span
        {...attributes}
        ref={ref}
        className={`${className} hover:cursor-pointer text-gray-600 font-medium`}
        onClick={() => {
          setOpenLinkBar(true);
        }}
      >
        {children}
      </span>
    </>
  );
}

export default LinkElement;
