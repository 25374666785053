import { User } from 'types/user';
import httpClient from './http-client';

export interface Response {
  user: User;
}

export default async (
  integrationType: 'calendar' | 'recording' | 'crm' | 'im',
  integrationName: 'none' | 'google' | 'microsoft' | 'zoom' | 'gong' | 'salesforce' | 'slack',
  code: null | string,
  cFrom: 'signup' | 'setting' | 'team' | null,
): Promise<Response> => {
  const { data } = await httpClient.post<Response>('/integration-oauth', {
    integrationType,
    integrationName,
    code,
    cFrom,
  });
  return data;
};
