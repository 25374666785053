export enum SalesforceFieldType {
  ID = 'id',
  ADDRESS = 'address',
  BOOLEAN = 'boolean',
  EMAIL = 'email',
  REFERENCE = 'reference',
  STRING = 'string',
  TEXTAREA = 'textarea',
  RICHTEXTAREA = 'richtextarea',
  PICKLIST = 'picklist',
  MULTI_PICKLIST = 'multipicklist',
  CURRENCY = 'currency',
  PERCENT = 'percent',
  PHONE = 'phone',
  DOUBLE = 'double',
  DATE = 'date',
  DATETIME = 'datetime',
  TIME = 'time',
  INT = 'int',
  URL = 'url',
}

export const EXCLUDE_TYPE_IN_EDIT = [SalesforceFieldType.ID, SalesforceFieldType.ADDRESS];

export const MINE_SUGGESTED_ANSWER_DATA_TYPE: SalesforceFieldType[] = [
  SalesforceFieldType.PHONE,
  SalesforceFieldType.EMAIL,
  SalesforceFieldType.CURRENCY,
  SalesforceFieldType.DOUBLE,
  SalesforceFieldType.INT,
  SalesforceFieldType.BOOLEAN,
  SalesforceFieldType.TEXTAREA,
  SalesforceFieldType.STRING,
  SalesforceFieldType.DATE,
  SalesforceFieldType.PICKLIST,
  SalesforceFieldType.MULTI_PICKLIST,
  SalesforceFieldType.DATETIME,
];
export const SF_HAS_OPTIONS: SalesforceFieldType[] = [SalesforceFieldType.PICKLIST, SalesforceFieldType.MULTI_PICKLIST];

export interface SalesforceRelationship {
  label: string;
  value: string;
  object: string;
}

export interface SalesforcePicklistValue {
  active: boolean;
  defaultValue: boolean;
  label: string;
  value: string;
}

export interface SalesforceAddressField {
  city?: string;
  country?: string;
  postalCode?: string;
  state?: string;
  street?: string;
}

export interface SalesforceField {
  id: string;
  object: 'Account' | 'Opportunity';
  label: string;
  name: string;
  type: SalesforceFieldType;
  value?: string | number | boolean | SalesforceAddressField;
  picklistValues: SalesforcePicklistValue[];
  relationship?: SalesforceRelationship;
  isEditable: boolean;
}

export const isAISupport = (t: SalesforceFieldType | undefined) => t && MINE_SUGGESTED_ANSWER_DATA_TYPE.includes(t);
export const isHasOptions = (t: SalesforceFieldType | undefined) => t && SF_HAS_OPTIONS.includes(t);
export const isTextField = (t: SalesforceFieldType | undefined) => t && [SalesforceFieldType.TEXTAREA].includes(t);
export const isBoolField = (t: SalesforceFieldType | undefined) => t && [SalesforceFieldType.BOOLEAN].includes(t);
