/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useMemo, useRef, useState } from 'react';
import ThreeVerticalDotsIcon from 'components/icons/ThreeVerticalDotsIcon';
import AccountIcon from 'components/icons/AccountIcon';
import OpportunityIcon from 'components/icons/OpportunityIcon';
import GroupColumnMenus from './GroupColumnMenus';
import { GroupColumnDefinition, DraggingColumnInfo } from '../types';
import Popover from '../../Popover';
import { concat } from 'utils/styling';
import styles from './Column.module.css';

function GroupColumnName({ groupColumnDefinition }: { groupColumnDefinition: GroupColumnDefinition }) {
  const groupHeaderIcon = useMemo(() => {
    if (groupColumnDefinition.group === 'Account') {
      return <AccountIcon className="min-w-[20px] w-5 h-5 mr-1 text-orange-400 fill-orange-400" />;
    }
    if (groupColumnDefinition.group === 'Opportunity') {
      return <OpportunityIcon className="min-w-[20px] w-5 h-5 mr-1 fill-orange-400 text-orange-400" />;
    }
    return null;
  }, [groupColumnDefinition.group]);

  if (groupColumnDefinition.type === 'ReportField') {
    return (
      <p className="truncate my-1 flex items-center select-none">
        {groupHeaderIcon}
        {groupColumnDefinition.group}
        <span className="text-gray-500"> ({groupColumnDefinition.data?.entityName})</span>
        {groupColumnDefinition.groupSequence !== undefined && (
          <span className="text-gray-500">&nbsp;-&nbsp;Group&nbsp;{groupColumnDefinition.groupSequence + 1}</span>
        )}
      </p>
    );
  }

  return (
    <p className="truncate my-1 flex items-center select-none">
      {groupHeaderIcon}
      {groupColumnDefinition.group}
      {groupColumnDefinition.groupSequence !== undefined && (
        <span className="text-gray-500">&nbsp;-&nbsp;Group&nbsp;{groupColumnDefinition.groupSequence + 1}</span>
      )}
    </p>
  );
}

interface Props {
  groupColumnDefinition: GroupColumnDefinition;
  onGroupColumnHide: () => void;
  isEven: boolean;
  onDraggingMouseDown: (event: React.MouseEvent<Element, MouseEvent>) => void;
  setDraggingItem: React.Dispatch<DraggingColumnInfo>;
  onColumnPin: () => void;
  className?: string;
}

function GroupColumn({
  groupColumnDefinition,
  onGroupColumnHide,
  isEven,
  onDraggingMouseDown,
  setDraggingItem,
  onColumnPin,
  className,
}: Props) {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const ref = useRef<HTMLButtonElement>(null);

  return (
    <>
      <div
        className={concat(
          'relative group flex font-semibold cursor-default justify-center items-center px-4 py-1 flex-shrink-0 flex-grow-0 hover:bg-gray-200 border-b border-gray-300',
          isEven ? 'bg-gray-50' : 'bg-gray-100',
          isMenuOpen && 'bg-gray-200',
          className,
        )}
        onMouseDown={e => {
          onDraggingMouseDown(e);
          setDraggingItem({
            colId: groupColumnDefinition.id,
            subColId: groupColumnDefinition.columns.map(col => col.id).join('_'),
            targetColName: groupColumnDefinition.group,
          });
        }}
      >
        <GroupColumnName groupColumnDefinition={groupColumnDefinition} />
        <div
          className={`absolute top-1/2 right-2 -translate-y-1/2 group-hover:block ${isMenuOpen ? 'block' : 'hidden'}`}
        >
          <button
            ref={ref}
            type="button"
            className="flex items-center justify-center w-6 h-6 p-0.5 rounded bg-white hover:bg-gray-300"
            onClick={() => setIsMenuOpen(true)}
          >
            <ThreeVerticalDotsIcon className="w-4 h-4" />
          </button>
        </div>
      </div>
      <Popover
        anchorEl={ref.current}
        onClose={() => setIsMenuOpen(false)}
        isOpen={isMenuOpen}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <div className={concat('w-48 px-1 py-2', styles.colMenuWrap)}>
          <GroupColumnMenus
            groupColumnDefinition={groupColumnDefinition}
            onGroupColumnHide={onGroupColumnHide}
            setIsMenuOpen={setIsMenuOpen}
            onColumnPin={onColumnPin}
          />
        </div>
      </Popover>
    </>
  );
}

GroupColumn.defaultProps = {
  className: '',
};

export default GroupColumn;
