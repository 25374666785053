import { ColumnConfig } from 'components/Table/types';

export type ViewSettingType = 'analytic_report' | 'product_gap' | 'task';

export interface ViewSetting {
  id: string;
  name: string;
  view: ColumnConfig[];
  viewType: ViewSettingType;
}

export const getEmptyViewSetting = (viewType: ViewSettingType = 'analytic_report'): ViewSetting => {
  return {
    id: '',
    name: '',
    view: [],
    viewType,
  };
};
