import { User } from '../types/user';
import config from './config';

const DEFAULT_SCRIPT_ID = 'initialize-heap-script';

const getHeapScript = (heapId: string) => `
  window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
  ${
    heapId &&
    heapId !== 'NONE' &&
    `heap.load(${heapId}, {
    secureCookie: true,
    disableTextCapture: false
  });`
  }
`;

const initialize = () => {
  const heapId = config.HEAP_ID;
  const hasScript = !!document.getElementById(DEFAULT_SCRIPT_ID);
  if (hasScript) {
    return;
  }
  const script = document.createElement('script');
  script.innerHTML = getHeapScript(heapId);
  script.id = DEFAULT_SCRIPT_ID;
  script.type = 'text/javascript';

  document.head.appendChild(script);
};

const addUserDetail = (user: User | null | undefined) => {
  if (!('identify' in heap)) {
    return;
  }

  if (!user) {
    heap.identify(null);
    return;
  }

  heap.identify(user.id);
  heap.addUserProperties({
    firstName: user.firstName,
    lastName: user.lastName,
    companyDomain: user.email.split('@').pop(),
  });
};

export default {
  initialize,
  addUserDetail,
};
