/* eslint-disable @typescript-eslint/no-explicit-any */

import { create } from 'zustand';
// eslint-disable-next-line import/no-extraneous-dependencies
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { createJSONStorage, persist } from 'zustand/middleware';

import getDocumentsByFilters from 'api/documents/get-documents-by-filters';
import getSalesforceInstanceUrl from 'api/salesforce/get-salesforce-instance-url';
import createEntity from 'api/entities/create-entity';
import createSfEntity from 'api/entities/create-sf-entity';
import syncDocumentNoteWithCrm from 'api/documents/sync-document-note-with-crm';
import importCrmNote from 'api/crm-notes/import-crm-note';
import createDocumentApi from 'api/documents/create-document';
import updateDocument from 'api/documents/update-document';
import deleteDocumentApi from 'api/documents/delete-document';
import getFilters, { AvailableFilters } from 'api/documents/get_filters';
import createOrUpdateSalesforceEvent from 'api/salesforce/create-or-update-salesforce-event';
import unlinkDocumentEntity from 'api/documents/unlink-document-entity';
import setDocumentOpportunity from 'api/documents/set-document-opportunity';
import getDocumentApi from 'api/documents/get-document';
import getEntity, { EntityDocumentRich } from 'api/entities/get-entity';
import checkAndGetTemplateApi from 'api/note-templates/check-and-get-template';
import { Call, CrmNote, Document, NoteService } from 'types/document';
import { EntityDocument, EntityName } from 'types/entity';

import { NoteTemplateType } from 'components/NoteEditor/types';
import { FieldSubHeader, ReportingFilter } from '../types/reporting';
import getFieldsFilter from '../api/fields/get-fields-filter';
import moment from 'moment';

export interface State {
  // Below are used for note menu filter
  numberOfItemsToShow: number;
  showOpportunityOnly: boolean;
  isLoadingFieldFilters: boolean;
  fetchFieldFilterTime: number;
  fieldFilters: {
    account: FieldSubHeader[];
    opportunity: FieldSubHeader[];
  };
  fetchFieldFilters: () => Promise<void>;
  documentFilters: ReportingFilter[];
  setDocumentFilters: (
    filters: ReportingFilter[],
    showOpportunityOnly: boolean,
    currentDocumentId?: string,
  ) => Promise<void>;

  // Below are used for document
  isLoading: boolean;
  currentOppDocId: string | null;
  currentAcctDocId: string | null;
  entityDocuments: EntityDocument[];
  documents: Document[];
  crmNotes: CrmNote[];
  instanceUrl: string;
  isRecentlyViewedDocsOpen: boolean;
  availableFilterParameters: AvailableFilters;
  setState: (newState: Partial<State>) => State;
  fetch: (currentPageDocumentId: undefined | string) => Promise<void>;
  fetchFilters: () => Promise<void>;
  createNewEntity: (name: string, entityName: EntityName, parentDocumentId: string | null) => Promise<EntityDocument>;
  getOrCreateNewSFEntity: (
    sfEntityId: string,
    sfEntityType: 'Opportunity' | 'Account',
  ) => Promise<EntityDocument | undefined>;
  getOrFetchEntityByDocId: (docId: string) => Promise<EntityDocument | undefined>;
  fetchEntityByDocId: (docId: string) => Promise<EntityDocumentRich>;
  deleteDocument: (documentId: string) => Promise<void>;
  getDocument: (documentId: string) => Promise<{ document: Document; noteService: NoteService | null }>;
  addDocumentCall: (documentId: string, call: Call) => void;
  checkAndGetTemplate: (documentId: string, type: NoteTemplateType, id: string) => Promise<any>;
  updateDocumentData: (documentId: string, updateData: Partial<Document>) => Promise<void>;
  updateDocumentEntity: (mainDocumentId: string, documentIds: string[], entityId: string) => Promise<void>;
  updateDocumentEventType: (documentId: string, eventType: string) => Promise<void>;
  unlinkDocumentEntity: (documentId: string) => Promise<void>;
  createDocument: (parentId: null | string, fileId?: null | string, documentName?: null | string) => Promise<Document>;
  createDocumentFromTemplate: (parentId: null | string, noteTemplateId: string) => Promise<Document>;
  createDocumentFromCrm: (crmNoteId: string) => Promise<Document>;
  // favouriteCrmNote: (crmNoteId: string, opportunityId: string) => Promise<void>;
  syncDocumentToCrm: (documentId: string, contentHash: string) => Promise<void>;
  isEntityExistInStore: (documentId: string) => boolean;
}

const useDocumentStore = create<State>()(
  persist(
    (set, get) => ({
      numberOfItemsToShow: 30,
      showOpportunityOnly: false,
      isLoadingFieldFilters: false,
      fetchFieldFilterTime: 0,
      fieldFilters: {
        account: [],
        opportunity: [],
      },
      fetchFieldFilters: async () => {
        // Don't fetch multiple times.
        if (get().isLoadingFieldFilters) return;

        // Only fetch when it's been 5 minutes since last fetch
        const prevFetchedTime = moment(get().fetchFieldFilterTime);
        const currentTime = moment();
        if (currentTime.isBefore(prevFetchedTime.add(5, 'minutes'))) return;

        set(prevState => ({ ...prevState, isLoadingFieldFilters: true }));
        const fieldFilters = await getFieldsFilter();

        // Making sure document filters are only applied to the field filters as field filters can be removed by user
        const fieldIds: string[] = [];
        fieldFilters.account.forEach(field => {
          if (!fieldIds.includes(field.fieldId)) {
            fieldIds.push(field.fieldId);
          }
        });
        fieldFilters.opportunity.forEach(field => {
          if (!fieldIds.includes(field.fieldId)) {
            fieldIds.push(field.fieldId);
          }
        });
        const documentFilters = get().documentFilters.filter(filter => {
          return fieldIds.includes(filter.columnId);
        });

        set(state => ({
          ...state,
          fieldFilters,
          documentFilters,
          isLoadingFieldFilters: false,
          fetchFieldFilterTime: moment().valueOf(),
        }));
      },
      documentFilters: [],
      setDocumentFilters: async (filters: ReportingFilter[], showOpportunityOnly, currentDocumentId) => {
        set(state => ({ ...state, documentFilters: filters, showOpportunityOnly }));
        await get().fetch(currentDocumentId);
      },

      isLoading: true,
      currentOppDocId: null,
      currentAcctDocId: null,
      entityDocuments: [],
      documents: [],
      crmNotes: [],
      instanceUrl: '',
      isRecentlyViewedDocsOpen: false,
      availableFilterParameters: {
        oppOwnerKeyById: {},
        accountOwnerKeyById: {},
        salesEngineers: [],
        pipefySalesEngineers: [],
        pipefySolutionArchitects: [],
        redoxSolutionEngineers: {},
        walnutSalesEngineers: {},
        opportunityStages: [],
        opportunityAmounts: [],
      },
      setState: (newState: Partial<State>) => {
        set(prevState => ({ ...prevState, ...newState }));
        return get();
      },
      fetch: async currentPageDocumentId => {
        set(prevState => ({
          ...prevState,
          isLoading: true,
        }));
        const [documentsRes, InstanceURLRes] = await Promise.all([
          getDocumentsByFilters(currentPageDocumentId, get().documentFilters, get().showOpportunityOnly),
          getSalesforceInstanceUrl(),
        ]);
        const newEntities: EntityDocument[] = documentsRes.entities;
        if (!documentsRes.entities.find(entityDoc => entityDoc.id === get().currentOppDocId)) {
          const currentOppDoc = get().entityDocuments.find(entityDoc => entityDoc.id === get().currentOppDocId);
          if (currentOppDoc) {
            newEntities.push({ ...currentOppDoc, isFilter: false });
          }
        }
        if (!documentsRes.entities.find(entityDoc => entityDoc.id === get().currentAcctDocId)) {
          const currentAcctDoc = get().entityDocuments.find(entityDoc => entityDoc.id === get().currentAcctDocId);
          if (currentAcctDoc) {
            newEntities.push({ ...currentAcctDoc, isFilter: false });
          }
        }
        set(prevState => ({
          ...prevState,
          entityDocuments: newEntities,
          documents: documentsRes.documents,
          crmNotes: documentsRes.crmNotes,
          instanceUrl: InstanceURLRes,
          isLoading: false,
        }));
      },
      fetchFilters: async () => {
        const data = await getFilters();
        set(prevState => ({
          ...prevState,
          availableFilterParameters: data,
        }));
      },
      // methods for entity
      createNewEntity: async (name, entityName, parentDocumentId) => {
        const res = await createEntity(name, entityName, parentDocumentId);
        set(prevState => ({
          ...prevState,
          entityDocuments: [...prevState.entityDocuments, res.entityDocument],
          documents: [...prevState.documents, ...res.documents],
        }));
        return res.entityDocument;
      },
      getOrCreateNewSFEntity: async (sfEntityId, sfEntityType) => {
        const entity = get().entityDocuments.find(
          entityDoc => entityDoc.crm?.id === sfEntityId && entityDoc.entity.name === sfEntityType,
        );
        if (entity) {
          return entity;
        }
        const res = await createSfEntity(sfEntityId, sfEntityType);
        const newEntities: EntityDocument[] = [];
        res.entityDocuments.forEach(entityDocument => {
          if (!get().entityDocuments.find(ed => ed.id === entityDocument.id)) {
            newEntities.push(entityDocument);
          }
        });
        const newDocuments: Document[] = [];
        res.documents.forEach(document => {
          if (!get().documents.find(ed => ed.id === document.id)) {
            newDocuments.push(document);
          }
        });
        set(prevState => ({
          ...prevState,
          entityDocuments: [...prevState.entityDocuments, ...newEntities],
          documents: [...prevState.documents, ...newDocuments],
        }));
        return res.entityDocuments.find(ed => ed.crm?.id === sfEntityId);
      },
      getOrFetchEntityByDocId: async docId => {
        const entity = get().entityDocuments.find(entityDoc => entityDoc.id === docId);
        if (entity) {
          return entity;
        }
        const res = await getEntity(docId);
        const newEntities: EntityDocument[] = [...get().entityDocuments];
        if (!newEntities.find(ed => ed.id === res.entityDocument.id)) {
          newEntities.push(res.entityDocument);
        }
        set(prevState => ({ ...prevState, entityDocuments: newEntities }));
        return res.entityDocument;
      },
      fetchEntityByDocId: async docId => {
        const res = await getEntity(docId);
        const newEntities: EntityDocument[] = [...get().entityDocuments];
        if (!newEntities.find(ed => ed.id === res.entityDocument.id)) {
          newEntities.push(res.entityDocument);
        }
        set(prevState => ({ ...prevState, entityDocuments: newEntities }));
        // this is not a good way
        // because each time note menu gets updated
        // get().fetch(res.entityDocument.id);
        return res;
      },
      // method for document
      deleteDocument: async (documentId: string) => {
        // set(prevState => ({ ...prevState, isLoading: true }));
        const succeed = await deleteDocumentApi(documentId);
        if (succeed) {
          set(prevState => ({
            ...prevState,
            documents: prevState.documents.filter(d => d.id !== documentId),
          }));
        }
        // set(prevState => ({ ...prevState, isLoading: false }));
      },
      getDocument: async (documentId: string) => {
        const { document, noteService } = await getDocumentApi(documentId);

        const { documents } = get();
        const documentIds = documents.map(d => d.id);
        if (documentIds.includes(documentId)) {
          set(prevState => ({
            ...prevState,
            documents: prevState.documents.map(doc => {
              if (doc.id === documentId) {
                return document;
              }
              return doc;
            }),
          }));
        } else {
          set(prevState => ({
            ...prevState,
            documents: [...prevState.documents, document],
          }));
        }

        return { document, noteService };
      },
      addDocumentCall: (documentId: string, call: Call) => {
        const { documents } = get();
        const documentIds = documents.map(d => d.id);
        if (documentIds.includes(documentId)) {
          set(prevState => ({
            ...prevState,
            documents: prevState.documents.map(doc => {
              if (doc.id === documentId) {
                return { ...doc, calls: [call, ...doc.calls] };
              }
              return doc;
            }),
          }));
        }
      },
      checkAndGetTemplate: async (documentId, type, id) => {
        const { document, templateData } = await checkAndGetTemplateApi(documentId, type, id);
        const newDocuments = get().documents.filter(d => d.id !== document.id);
        set(prevState => ({
          ...prevState,
          documents: [...newDocuments, document],
        }));
        return templateData;
      },
      updateDocumentData: async (documentId: string, updateData: Partial<Document>) => {
        const newDocument = await updateDocument(documentId, updateData);
        set(prevState => ({
          ...prevState,
          documents: prevState.documents.map(doc => {
            if (doc.id === documentId) {
              return newDocument;
            }
            return doc;
          }),
        }));
      },
      updateDocumentEntity: async (mainDocumentId, documentIds: string[], entityId: string | null) => {
        const newDocuments = await setDocumentOpportunity(mainDocumentId, documentIds, entityId);
        set(prevState => ({
          ...prevState,
          documents: prevState.documents.map(doc => {
            const newDoc = newDocuments.find(d => d.id === doc.id);
            if (newDoc) {
              return newDoc;
            }
            return doc;
          }),
        }));
      },
      updateDocumentEventType: async (documentId, eventType) => {
        await createOrUpdateSalesforceEvent(documentId, eventType);
      },
      unlinkDocumentEntity: async documentId => {
        const newDocument = await unlinkDocumentEntity(documentId);
        set(prevState => ({
          ...prevState,
          documents: prevState.documents.map(d => {
            if (d.id === newDocument.id) {
              return newDocument;
            }
            return d;
          }),
        }));
      },
      createDocument: async (
        parentId: null | string,
        fileId: null | string = null,
        documentName: null | string = null,
      ) => {
        const newDocument = await createDocumentApi(parentId, null, fileId, documentName);
        set(prevState => ({
          ...prevState,
          documents: [...prevState.documents, newDocument],
        }));
        return newDocument;
      },
      createDocumentFromTemplate: async (parentId: null | string, noteTemplateId: string) => {
        const newDocument = await createDocumentApi(parentId, noteTemplateId);
        set(prevState => ({
          ...prevState,
          documents: [...prevState.documents, newDocument],
        }));
        return newDocument;
      },
      createDocumentFromCrm: async (crmNoteId: string) => {
        const newDocument = await importCrmNote(crmNoteId);
        set(prevState => ({
          ...prevState,
          documents: [...prevState.documents, newDocument],
          crmNotes: prevState.crmNotes.filter(crmNote => crmNote.id !== crmNoteId),
        }));
        return newDocument;
      },
      syncDocumentToCrm: async (documentId: string, contentHash: string) => {
        const document = await syncDocumentNoteWithCrm(documentId);
        localStorage.setItem(documentId, contentHash);
        set(prevState => ({
          ...prevState,
          documents: prevState.documents.map(doc => {
            if (doc.id === document.id) {
              return document;
            }
            return doc;
          }),
        }));
      },
      isEntityExistInStore: (documentId: string) => {
        if (get().entityDocuments.find(entityDocument => entityDocument.id === documentId)) {
          return true;
        }
        return false;
      },
    }),
    {
      name: 'note-menu-store',
      storage: createJSONStorage(() => localStorage),
      version: 1,
      migrate: async (persistedState, version) => {
        const temp = { ...(persistedState as State) };
        if (version === 0) {
          // Changing Document Filters
          temp.documentFilters = [];
        }
        return temp;
      },
      partialize: state => ({
        fieldFilters: state.fieldFilters,
        documentFilters: state.documentFilters,
        showOpportunityOnly: state.showOpportunityOnly,
        isRecentlyViewedDocsOpen: state.isRecentlyViewedDocsOpen,
        numberOfItemsToShow: state.numberOfItemsToShow,
      }),
    },
  ),
);

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('Entity', useDocumentStore);
}

export default useDocumentStore;
