import httpClient from '../http-client';
import { DocTriggerCondition } from 'types/doc-trigger-condition';

export default async (trigger: DocTriggerCondition): Promise<DocTriggerCondition[]> => {
  const { data } = await httpClient.post<DocTriggerCondition[]>(`/doc-trigger`, {
    templateId: trigger.templateId,
    conditionFieldId: trigger.conditionField.id,
    conditionOperator: trigger.conditionOperator,
    conditionValue: trigger.conditionValue,
  });
  return data;
};
