/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { StandardTextFieldProps, TextField } from '@mui/material';
import { concat } from 'utils/styling';
import styles from './InputField.module.css';
import SpinLoader from 'components/icons/SpinLoader';
import { omit } from 'lodash';
import isHotkey from 'is-hotkey';

type Props = Omit<StandardTextFieldProps, 'label' | 'variant' | 'color'> & {
  loading?: boolean;
  isError?: boolean;
  endAdornment?: React.ReactNode;
};

const InputField = React.forwardRef<HTMLInputElement, Props>((props: Props, ref) => {
  return (
    <TextField
      {...omit(props, 'loading', 'isError', 'endAdornment')}
      inputRef={ref}
      className={concat(
        styles.inputWrap,
        props.size === 'small' && styles.small,
        props.isError && styles.error,
        props.className,
      )}
      onCopy={e => e.stopPropagation()}
      onPaste={e => e.stopPropagation()}
      onKeyDown={e => {
        if (isHotkey('mod+a', e)) {
          e.stopPropagation();
        }
        props.onKeyDown?.(e);
      }}
      InputProps={{
        endAdornment:
          props.endAdornment ||
          (props.loading ? (
            <SpinLoader className={concat('w-6 h-6 animate-spin text-orange-500', styles.loadingIcon)} />
          ) : null),
      }}
    />
  );
});

InputField.displayName = 'InputField';
InputField.defaultProps = {
  loading: undefined,
  isError: undefined,
  endAdornment: undefined,
};

export default InputField;
