import React from 'react';

interface Props {
  className?: string;
}

function EmptyCalendarIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="260"
      height="134"
      viewBox="0 0 260 134"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M129.995 134C58.3633 134 0.294434 130.609 0.294434 126.426C0.294434 122.243 58.3633 118.852 129.995 118.852C201.626 118.852 259.695 122.243 259.695 126.426C259.695 130.609 201.626 134 129.995 134Z"
        fill="url(#paint0_linear_12666_73134)"
      />
      <path
        d="M185.971 30.1746C186.832 30.1746 187.531 29.4763 187.531 28.6147C187.531 27.7531 186.833 27.0547 185.971 27.0547C182.149 27.0547 178.92 21.5747 178.92 15.088C178.92 14.2264 178.222 13.5273 177.36 13.5273C176.498 13.5273 175.799 14.2257 175.799 15.088C175.799 23.5481 180.267 30.1746 185.971 30.1746Z"
        fill="#CCDDFF"
      />
      <path
        d="M162.361 30.1746C163.223 30.1746 163.922 29.4763 163.922 28.6147C163.922 27.7531 163.223 27.0547 162.361 27.0547C158.54 27.0547 155.311 21.5747 155.311 15.088C155.311 14.2264 154.612 13.5273 153.751 13.5273C152.889 13.5273 152.19 14.2257 152.19 15.088C152.19 23.5481 156.657 30.1746 162.361 30.1746Z"
        fill="#CCDDFF"
      />
      <path
        d="M138.76 30.1746C139.621 30.1746 140.32 29.4763 140.32 28.6147C140.32 27.7531 139.622 27.0547 138.76 27.0547C134.937 27.0547 131.708 21.5747 131.708 15.088C131.708 14.2264 131.01 13.5273 130.148 13.5273C129.287 13.5273 128.588 14.2257 128.588 15.088C128.588 23.5481 133.056 30.1746 138.76 30.1746Z"
        fill="#CCDDFF"
      />
      <path
        d="M115.159 30.1746C116.02 30.1746 116.719 29.4763 116.719 28.6147C116.719 27.7531 116.02 27.0547 115.159 27.0547C111.336 27.0547 108.107 21.5747 108.107 15.088C108.107 14.2264 107.409 13.5273 106.548 13.5273C105.686 13.5273 104.988 14.2257 104.988 15.088C104.988 23.5481 109.455 30.1746 115.159 30.1746Z"
        fill="#CCDDFF"
      />
      <path
        d="M91.5494 30.1746C92.411 30.1746 93.1094 29.4763 93.1094 28.6147C93.1094 27.7531 92.411 27.0547 91.5494 27.0547C87.7277 27.0547 84.4988 21.5747 84.4988 15.088C84.4988 14.2264 83.8004 13.5273 82.9388 13.5273C82.0772 13.5273 81.3788 14.2257 81.3788 15.088C81.3781 23.5481 85.8453 30.1746 91.5494 30.1746Z"
        fill="#CCDDFF"
      />
      <path
        d="M67.9478 30.1746C68.8094 30.1746 69.5078 29.4763 69.5078 28.6147C69.5078 27.7531 68.8094 27.0547 67.9478 27.0547C64.1262 27.0547 60.8972 21.5747 60.8972 15.088C60.8972 14.2264 60.1988 13.5273 59.3372 13.5273C58.4756 13.5273 57.7772 14.2257 57.7772 15.088C57.7772 23.5481 62.2444 30.1746 67.9478 30.1746Z"
        fill="#CCDDFF"
      />
      <path
        d="M200.77 16.6797H48.7962C44.6769 16.6797 41.0077 20.0498 40.6425 24.1692L32.2292 119.042C31.864 123.161 34.9351 126.531 39.0544 126.531H191.028C195.147 126.531 198.816 123.161 199.182 119.042L207.596 24.1692C207.96 20.0498 204.889 16.6797 200.77 16.6797Z"
        fill="url(#paint1_linear_12666_73134)"
      />
      <path
        d="M50.0639 16.6797H202.037C206.157 16.6797 209.679 20.0498 209.865 24.1692L214.149 119.042C214.335 123.161 211.116 126.531 206.998 126.531H55.0241C50.9047 126.531 47.3828 123.161 47.1968 119.042L42.9129 24.1692C42.7269 20.0498 45.9445 16.6797 50.0639 16.6797Z"
        fill="url(#paint2_linear_12666_73134)"
      />
      <path
        d="M199.247 101.464L195.71 23.1131C195.638 21.5317 194.299 20.25 192.718 20.25H59.7098C58.1285 20.25 56.905 21.5317 56.9759 23.1131L60.5133 101.464C60.5848 103.046 61.924 104.327 63.5054 104.327H196.513C198.094 104.327 199.318 103.045 199.247 101.464Z"
        fill="#EBF2FF"
      />
      <path
        d="M203.013 107.851L199.297 25.5655C199.222 23.9045 197.816 22.5586 196.155 22.5586H56.4668C54.8065 22.5586 53.5208 23.9052 53.5957 25.5655L57.311 107.851C57.3859 109.512 58.7927 110.858 60.4537 110.858H200.141C201.802 110.858 203.087 109.512 203.013 107.851Z"
        fill="#E6EFFF"
      />
      <path
        d="M206.458 114.489L202.583 28.6601C202.504 26.9282 201.037 25.5234 199.305 25.5234H53.6038C51.8719 25.5234 50.5307 26.9275 50.609 28.6601L54.4842 114.489C54.5624 116.221 56.0301 117.625 57.762 117.625H203.463C205.195 117.625 206.536 116.221 206.458 114.489Z"
        fill="white"
      />
      <g opacity="0.8">
        <path
          d="M179.445 63.4148H194.907C196.35 63.4148 197.476 62.2341 197.411 60.7919L196.713 45.3299C196.648 43.887 195.415 42.707 193.972 42.707H178.51C177.067 42.707 175.94 43.887 176.005 45.3299L176.704 60.7919C176.769 62.2341 178.003 63.4148 179.445 63.4148Z"
          fill="#DCE9FF"
        />
        <path
          d="M155.836 63.4148H171.297C172.74 63.4148 173.867 62.2341 173.802 60.7919L173.104 45.3299C173.039 43.887 171.805 42.707 170.362 42.707H154.9C153.457 42.707 152.331 43.887 152.396 45.3299L153.094 60.7919C153.159 62.2341 154.393 63.4148 155.836 63.4148Z"
          fill="#DCE9FF"
        />
        <path
          d="M132.234 63.4148H147.696C149.139 63.4148 150.265 62.2341 150.2 60.7919L149.502 45.3299C149.437 43.887 148.204 42.707 146.761 42.707H131.299C129.856 42.707 128.729 43.887 128.794 45.3299L129.493 60.7919C129.558 62.2341 130.791 63.4148 132.234 63.4148Z"
          fill="#DCE9FF"
        />
        <path
          d="M108.632 63.4148H124.094C125.537 63.4148 126.664 62.2341 126.599 60.7919L125.901 45.3299C125.836 43.887 124.602 42.707 123.159 42.707H107.697C106.254 42.707 105.128 43.887 105.193 45.3299L105.891 60.7919C105.956 62.2341 107.19 63.4148 108.632 63.4148Z"
          fill="#DCE9FF"
        />
        <path
          d="M85.0231 63.4148H100.485C101.928 63.4148 103.054 62.2341 102.99 60.7919L102.291 45.3299C102.226 43.887 100.993 42.707 99.5498 42.707H84.0879C82.645 42.707 81.5185 43.887 81.5834 45.3299L82.2818 60.7919C82.3467 62.2341 83.5802 63.4148 85.0231 63.4148Z"
          fill="#DCE9FF"
        />
        <path
          d="M61.4215 63.4148H76.8834C78.3264 63.4148 79.4528 62.2341 79.388 60.7919L78.6896 45.3299C78.6247 43.887 77.3912 42.707 75.9483 42.707H60.4863C59.0434 42.707 57.9169 43.887 57.9818 45.3299L58.6802 60.7919C58.7458 62.2341 59.9793 63.4148 61.4215 63.4148Z"
          fill="#DCE9FF"
        />
        <path
          d="M67.9799 30.6708C69.1512 30.6708 70.0663 29.7135 70.0134 28.5436V28.5396C69.9639 27.4458 69.0274 26.5508 67.9324 26.5508C66.761 26.5508 65.8466 27.5081 65.8994 28.678V28.6827C65.9489 29.7758 66.8855 30.6708 67.9799 30.6708Z"
          fill="#325494"
        />
        <path
          d="M91.5814 30.6708C92.7527 30.6708 93.6679 29.7135 93.615 28.5436V28.5396C93.5655 27.4458 92.629 26.5508 91.5339 26.5508C90.3626 26.5508 89.4482 27.5081 89.501 28.678V28.6827C89.5505 29.7758 90.4864 30.6708 91.5814 30.6708Z"
          fill="#325494"
        />
        <path
          d="M115.191 30.6708C116.362 30.6708 117.277 29.7135 117.224 28.5436V28.5396C117.175 27.4458 116.238 26.5508 115.143 26.5508C113.972 26.5508 113.058 27.5081 113.11 28.678V28.6827C113.16 29.7758 114.096 30.6708 115.191 30.6708Z"
          fill="#325494"
        />
        <path
          d="M138.792 30.6708C139.964 30.6708 140.879 29.7135 140.826 28.5436V28.5396C140.776 27.4458 139.84 26.5508 138.745 26.5508C137.574 26.5508 136.659 27.5081 136.712 28.678V28.6827C136.761 29.7758 137.697 30.6708 138.792 30.6708Z"
          fill="#325494"
        />
        <path
          d="M162.394 30.6708C163.565 30.6708 164.48 29.7135 164.428 28.5436V28.5396C164.378 27.4458 163.441 26.5508 162.346 26.5508C161.175 26.5508 160.261 27.5081 160.314 28.678V28.6827C160.363 29.7758 161.299 30.6708 162.394 30.6708Z"
          fill="#325494"
        />
        <path
          d="M186.003 30.6708C187.175 30.6708 188.09 29.7135 188.037 28.5436V28.5396C187.987 27.4458 187.051 26.5508 185.956 26.5508C184.784 26.5508 183.87 27.5081 183.923 28.678V28.6827C183.972 29.7758 184.909 30.6708 186.003 30.6708Z"
          fill="#325494"
        />
      </g>
      <path
        d="M180.093 38.1464H191.965C192.686 38.1464 193.25 37.5563 193.217 36.8346C193.185 36.1134 192.568 35.5234 191.847 35.5234H179.974C179.253 35.5234 178.689 36.1134 178.722 36.8346C178.754 37.5563 179.372 38.1464 180.093 38.1464Z"
        fill="#CCDDFF"
      />
      <path
        d="M156.483 38.1464H168.356C169.077 38.1464 169.641 37.5563 169.608 36.8346C169.575 36.1134 168.958 35.5234 168.237 35.5234H156.365C155.644 35.5234 155.08 36.1134 155.113 36.8346C155.146 37.5563 155.762 38.1464 156.483 38.1464Z"
        fill="#CCDDFF"
      />
      <path
        d="M132.882 38.1464H144.754C145.475 38.1464 146.039 37.5563 146.006 36.8346C145.974 36.1134 145.357 35.5234 144.636 35.5234H132.763C132.042 35.5234 131.478 36.1134 131.511 36.8346C131.544 37.5563 132.161 38.1464 132.882 38.1464Z"
        fill="#CCDDFF"
      />
      <path
        d="M109.28 38.1464H121.153C121.874 38.1464 122.438 37.5563 122.405 36.8346C122.372 36.1134 121.755 35.5234 121.034 35.5234H109.162C108.441 35.5234 107.877 36.1134 107.91 36.8346C107.942 37.5563 108.558 38.1464 109.28 38.1464Z"
        fill="#CCDDFF"
      />
      <path
        d="M85.6709 38.1464H97.5433C98.2644 38.1464 98.8283 37.5563 98.7955 36.8346C98.7627 36.1134 98.146 35.5234 97.4248 35.5234H85.5525C84.8314 35.5234 84.2674 36.1134 84.3002 36.8346C84.333 37.5563 84.9497 38.1464 85.6709 38.1464Z"
        fill="#CCDDFF"
      />
      <path
        d="M62.0693 38.1464H73.9417C74.6628 38.1464 75.2267 37.5563 75.194 36.8346C75.1612 36.1134 74.5444 35.5234 73.8233 35.5234H61.9509C61.2298 35.5234 60.6659 36.1134 60.6986 36.8346C60.7308 37.5563 61.3482 38.1464 62.0693 38.1464Z"
        fill="#CCDDFF"
      />
      <g opacity="0.8">
        <path
          d="M180.523 87.407H195.985C197.428 87.407 198.554 86.227 198.49 84.7841L197.791 69.3222C197.726 67.8792 196.493 66.6992 195.05 66.6992H179.588C178.145 66.6992 177.018 67.8792 177.083 69.3222L177.782 84.7841C177.847 86.2263 179.08 87.407 180.523 87.407Z"
          fill="#DCE9FF"
        />
        <path
          d="M156.914 87.407H172.376C173.819 87.407 174.945 86.227 174.88 84.7841L174.182 69.3222C174.117 67.8792 172.883 66.6992 171.44 66.6992H155.979C154.536 66.6992 153.409 67.8792 153.474 69.3222L154.172 84.7841C154.238 86.2263 155.471 87.407 156.914 87.407Z"
          fill="#DCE9FF"
        />
        <path
          d="M133.312 87.407H148.774C150.217 87.407 151.343 86.227 151.279 84.7841L150.58 69.3222C150.515 67.8792 149.282 66.6992 147.839 66.6992H132.377C130.934 66.6992 129.807 67.8792 129.872 69.3222L130.571 84.7841C130.636 86.2263 131.869 87.407 133.312 87.407Z"
          fill="#DCE9FF"
        />
        <path
          d="M109.711 87.407H125.172C126.615 87.407 127.742 86.227 127.677 84.7841L126.979 69.3222C126.914 67.8792 125.68 66.6992 124.237 66.6992H108.775C107.332 66.6992 106.206 67.8792 106.271 69.3222L106.969 84.7841C107.034 86.2263 108.268 87.407 109.711 87.407Z"
          fill="#DCE9FF"
        />
        <path
          d="M86.1012 87.407H101.563C103.006 87.407 104.133 86.227 104.068 84.7841L103.369 69.3222C103.304 67.8792 102.07 66.6992 100.628 66.6992H85.166C83.7231 66.6992 82.5959 67.8792 82.6615 69.3222L83.3598 84.7841C83.4247 86.2263 84.6589 87.407 86.1012 87.407Z"
          fill="#DCE9FF"
        />
        <path
          d="M62.4996 87.407H77.9616C79.4045 87.407 80.531 86.227 80.4661 84.7841L79.7677 69.3222C79.7028 67.8792 78.4686 66.6992 77.0264 66.6992H61.5645C60.1216 66.6992 58.9951 67.8792 59.0599 69.3222L59.7583 84.7841C59.8232 86.2263 61.0567 87.407 62.4996 87.407Z"
          fill="#DCE9FF"
        />
      </g>
      <g opacity="0.8">
        <path
          d="M181.609 111.403H197.071C198.514 111.403 199.641 110.223 199.575 108.78L198.877 93.3182C198.812 91.8753 197.578 90.6953 196.136 90.6953H180.674C179.231 90.6953 178.104 91.8753 178.169 93.3182L178.868 108.78C178.933 110.222 180.167 111.403 181.609 111.403Z"
          fill="#DCE9FF"
        />
        <path
          d="M158 111.403H173.462C174.904 111.403 176.032 110.223 175.966 108.78L175.268 93.3182C175.203 91.8753 173.969 90.6953 172.526 90.6953H157.064C155.622 90.6953 154.494 91.8753 154.56 93.3182L155.258 108.78C155.323 110.222 156.557 111.403 158 111.403Z"
          fill="#DCE9FF"
        />
        <path
          d="M134.398 111.403H149.86C151.303 111.403 152.429 110.223 152.365 108.78L151.666 93.3182C151.601 91.8753 150.367 90.6953 148.925 90.6953H133.463C132.02 90.6953 130.893 91.8753 130.958 93.3182L131.657 108.78C131.722 110.222 132.956 111.403 134.398 111.403Z"
          fill="#DCE9FF"
        />
        <path
          d="M110.797 111.403H126.258C127.701 111.403 128.828 110.223 128.763 108.78L128.065 93.3182C128 91.8753 126.765 90.6953 125.323 90.6953H109.861C108.418 90.6953 107.292 91.8753 107.357 93.3182L108.055 108.78C108.12 110.222 109.354 111.403 110.797 111.403Z"
          fill="#DCE9FF"
        />
        <path
          d="M87.1871 111.403H102.649C104.092 111.403 105.218 110.223 105.154 108.78L104.455 93.3182C104.39 91.8753 103.156 90.6953 101.714 90.6953H86.252C84.8091 90.6953 83.6826 91.8753 83.7474 93.3182L84.4458 108.78C84.5107 110.222 85.7442 111.403 87.1871 111.403Z"
          fill="#DCE9FF"
        />
        <path
          d="M63.5856 111.403H79.0475C80.4904 111.403 81.6169 110.223 81.552 108.78L80.8537 93.3182C80.7888 91.8753 79.5546 90.6953 78.1123 90.6953H62.6504C61.2075 90.6953 60.081 91.8753 60.1459 93.3182L60.8443 108.78C60.9098 110.222 62.1434 111.403 63.5856 111.403Z"
          fill="#DCE9FF"
        />
      </g>
      <path
        d="M185.977 30.1733C191.681 30.1733 196.148 23.5468 196.148 15.0866C196.148 6.62654 191.681 0 185.977 0C180.274 0 175.806 6.62721 175.806 15.0866C175.806 15.9482 176.505 16.6466 177.367 16.6466C178.228 16.6466 178.927 15.9482 178.927 15.0866C178.927 8.60059 182.156 3.12062 185.977 3.12062C189.799 3.12062 193.028 8.60059 193.028 15.0866C193.028 21.5727 189.799 27.0533 185.977 27.0533C185.116 27.0533 184.417 27.7517 184.417 28.6133C184.417 29.4756 185.116 30.1733 185.977 30.1733Z"
        fill="#CCDDFF"
      />
      <path
        d="M162.368 30.1733C168.071 30.1733 172.539 23.5468 172.539 15.0866C172.539 6.62654 168.071 0 162.368 0C156.664 0 152.197 6.62721 152.197 15.0866C152.197 15.9482 152.896 16.6466 153.757 16.6466C154.619 16.6466 155.317 15.9482 155.317 15.0866C155.317 8.60059 158.546 3.12062 162.368 3.12062C166.189 3.12062 169.418 8.60059 169.418 15.0866C169.418 21.5727 166.189 27.0533 162.368 27.0533C161.506 27.0533 160.807 27.7517 160.807 28.6133C160.807 29.4756 161.506 30.1733 162.368 30.1733Z"
        fill="#CCDDFF"
      />
      <path
        d="M138.766 30.1733C144.47 30.1733 148.938 23.5468 148.938 15.0866C148.938 6.62654 144.47 0 138.766 0C133.063 0 128.595 6.62721 128.595 15.0866C128.595 15.9482 129.293 16.6466 130.155 16.6466C131.017 16.6466 131.715 15.9482 131.715 15.0866C131.715 8.60059 134.944 3.12062 138.766 3.12062C142.588 3.12062 145.817 8.60059 145.817 15.0866C145.817 21.5727 142.588 27.0533 138.766 27.0533C137.905 27.0533 137.206 27.7517 137.206 28.6133C137.206 29.4756 137.905 30.1733 138.766 30.1733Z"
        fill="#CCDDFF"
      />
      <path
        d="M115.165 30.1733C120.869 30.1733 125.336 23.5468 125.336 15.0866C125.336 6.62654 120.869 0 115.165 0C109.462 0 104.994 6.62721 104.994 15.0866C104.994 15.9482 105.692 16.6466 106.554 16.6466C107.416 16.6466 108.114 15.9482 108.114 15.0866C108.114 8.60059 111.343 3.12062 115.165 3.12062C118.987 3.12062 122.216 8.60059 122.216 15.0866C122.216 21.5727 118.987 27.0533 115.165 27.0533C114.304 27.0533 113.605 27.7517 113.605 28.6133C113.605 29.4756 114.304 30.1733 115.165 30.1733Z"
        fill="#CCDDFF"
      />
      <path
        d="M91.5553 30.1733C97.2587 30.1733 101.727 23.5468 101.727 15.0866C101.727 6.62654 97.2587 0 91.5553 0C85.8519 0 81.3847 6.62721 81.3847 15.0866C81.3847 15.9482 82.0831 16.6466 82.9447 16.6466C83.8063 16.6466 84.5046 15.9482 84.5046 15.0866C84.5046 8.60059 87.7336 3.12062 91.5553 3.12062C95.3776 3.12062 98.6066 8.60059 98.6066 15.0866C98.6066 21.5727 95.3776 27.0533 91.5553 27.0533C90.6937 27.0533 89.9953 27.7517 89.9953 28.6133C89.9953 29.4756 90.693 30.1733 91.5553 30.1733Z"
        fill="#CCDDFF"
      />
      <path
        d="M67.9544 30.1733C73.6578 30.1733 78.125 23.5468 78.125 15.0866C78.125 6.62654 73.6578 0 67.9544 0C62.251 0 57.7838 6.62721 57.7838 15.0866C57.7838 15.9482 58.4822 16.6466 59.3438 16.6466C60.2054 16.6466 60.9038 15.9482 60.9038 15.0866C60.9038 8.60059 64.1327 3.12062 67.9544 3.12062C71.7761 3.12062 75.0051 8.60059 75.0051 15.0866C75.0051 21.5727 71.7761 27.0533 67.9544 27.0533C67.0928 27.0533 66.3944 27.7517 66.3944 28.6133C66.3944 29.4756 67.0928 30.1733 67.9544 30.1733Z"
        fill="#CCDDFF"
      />
      <path
        d="M113.569 64.2828C119.027 63.1977 125.16 64.4607 127.344 70.2163C129.101 74.8447 126.851 79.8644 121.973 81.0872C117.692 82.1602 110.307 80.8464 108.781 75.9076C107.037 70.2618 114.112 68.4315 118.112 68.0944C119.312 67.9934 119.306 69.8658 118.112 69.9668C116.296 70.1199 114.226 70.2203 112.623 71.2163C109.254 73.3108 110.653 76.6695 113.506 78.275C116.3 79.8477 120.719 80.2892 123.511 78.5058C126.585 76.5418 126.686 72.2318 124.86 69.4249C122.444 65.7116 118.04 65.2975 114.067 66.0876C112.89 66.3217 112.386 64.5176 113.569 64.2828Z"
        fill="#325494"
      />
      <path
        d="M173.801 42.7319C168.562 49.6588 162.772 56.09 156.405 61.9981C155.522 62.8169 154.195 61.4957 155.081 60.6742C161.327 54.8779 167.043 48.5838 172.184 41.7867C172.902 40.8381 174.529 41.77 173.801 42.7319Z"
        fill="#CCDDFF"
      />
      <path
        d="M171.865 61.2112C167.923 54.194 161.009 48.209 154.487 43.6261C153.508 42.9384 154.443 41.3142 155.432 42.0092C162.226 46.7835 169.372 52.9504 173.482 60.266C174.072 61.3176 172.456 62.2628 171.865 61.2112Z"
        fill="#CCDDFF"
      />
      <path
        d="M99.4004 48.7239C95.3319 48.7239 91.264 48.7239 87.1955 48.7239C85.9908 48.7239 85.9888 46.8516 87.1955 46.8516C91.264 46.8516 95.3319 46.8516 99.4004 46.8516C100.605 46.8516 100.607 48.7239 99.4004 48.7239Z"
        fill="#325494"
      />
      <path
        d="M99.5332 52.1927C97.1752 52.1927 94.8178 52.1927 92.4598 52.1927C91.255 52.1927 91.253 50.3203 92.4598 50.3203C94.8178 50.3203 97.1752 50.3203 99.5332 50.3203C100.738 50.3203 100.74 52.1927 99.5332 52.1927Z"
        fill="#325494"
      />
      <path
        d="M172.518 96.638C169.027 96.638 165.536 96.638 162.047 96.638C160.842 96.638 160.84 94.7656 162.047 94.7656C165.537 94.7656 169.028 94.7656 172.518 94.7656C173.722 94.7656 173.724 96.638 172.518 96.638Z"
        fill="#325494"
      />
      <path
        d="M172.9 100.661C168.37 100.661 163.839 100.661 159.309 100.661C158.104 100.661 158.102 98.7891 159.309 98.7891C163.84 98.7891 168.37 98.7891 172.9 98.7891C174.105 98.7891 174.107 100.661 172.9 100.661Z"
        fill="#325494"
      />
      <path
        d="M172.9 104.822C170.635 104.822 168.37 104.822 166.105 104.822C164.9 104.822 164.898 102.949 166.105 102.949C168.37 102.949 170.635 102.949 172.9 102.949C174.105 102.949 174.107 104.822 172.9 104.822Z"
        fill="#CCDDFF"
      />
      <defs>
        <linearGradient
          id="paint0_linear_12666_73134"
          x1="-44.9646"
          y1="134"
          x2="-44.9646"
          y2="104.078"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#D6E3FA" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_12666_73134"
          x1="204.007"
          y1="44.0899"
          x2="132.006"
          y2="143.728"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF0FA" />
          <stop offset="1" stopColor="#EBF0F9" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_12666_73134"
          x1="201.995"
          y1="48.8307"
          x2="90.8939"
          y2="176.875"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0294333" stopColor="#E6EEF9" />
          <stop offset="1" stopColor="#D8E1F3" />
        </linearGradient>
      </defs>
    </svg>
  );
}

EmptyCalendarIcon.defaultProps = {
  className: undefined,
};

export default EmptyCalendarIcon;
