import React from 'react';

interface Props {
  className?: string;
}
function ProductboardComponentIcon({ className }: Props) {
  return (
    <svg viewBox="0 0 16 16" className={className}>
      <path
        d="M2 0h3c1.1 0 2 .9 2 2v3c0 1.1-.9 2-2 2H2C.9 7 0 6.1 0 5V2C0 .9.9 0 2 0zm9 0h3c1.1 0 2 .9 2 2v3c0 1.1-.9 2-2 2h-3c-1.1 0-2-.9-2-2V2c0-1.1.9-2 2-2zm0 9h3c1.1 0 2 .9 2 2v3c0 1.1-.9 2-2 2h-3c-1.1 0-2-.9-2-2v-3c0-1.1.9-2 2-2zM2 9h3c1.1 0 2 .9 2 2v3c0 1.1-.9 2-2 2H2c-1.1 0-2-.9-2-2v-3c0-1.1.9-2 2-2z"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  );
}

ProductboardComponentIcon.defaultProps = {
  className: undefined,
};
export default ProductboardComponentIcon;
