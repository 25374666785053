/* eslint-disable react/no-array-index-key, react/jsx-props-no-spreading */
import {
  Control,
  DefaultValues,
  FieldErrors,
  FieldValues,
  useForm,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { FormAction } from 'types/form';
import React, { useCallback, useState } from 'react';
import BaseButton from 'components/BaseButton';
import { concat } from 'utils/styling';
import styles from './BaseForm.module.css';
import { SuperpanelAPIError } from 'utils/errors';

export type RenderFormBodyFunc<T extends FieldValues> = (params: {
  register: UseFormRegister<T>;
  control: Control<T>;
  errors: FieldErrors<T>;
  watch: UseFormWatch<T>;
  setValue: UseFormSetValue<T>;
  clearErrors: UseFormClearErrors<T>;
  getValues: UseFormGetValues<T>;
}) => React.ReactNode;

export interface BaseFormProps<T extends FieldValues> {
  className?: string;
  formData: T;
  renderFormBody: RenderFormBodyFunc<T>;
  actions: FormAction<T>[];
  handleApiError?: (error: SuperpanelAPIError, setError: UseFormSetError<T>) => void;
}

function BaseForm<T extends FieldValues>({
  className,
  formData,
  renderFormBody,
  actions,
  handleApiError,
}: BaseFormProps<T>) {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
    setValue,
    clearErrors,
    getValues,
  } = useForm<T>({
    defaultValues: formData as DefaultValues<T>,
  });
  const [loadingBtn, setLoadingBtn] = useState<number | null>(null);

  const onSubmit = useCallback(
    (fd: T) => {
      const actIndex = actions.findIndex(action => action.type === 'submit');
      const ret = actions[actIndex]?.cb?.(fd);
      if (ret instanceof Promise) {
        setLoadingBtn(actIndex);
        ret
          .catch((err: SuperpanelAPIError) => {
            handleApiError?.(err, setError);
          })
          .finally(() => setLoadingBtn(null));
      }
    },
    [actions],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={concat(styles.formContainer, className)}>
      <div className={styles.formBody}>
        {renderFormBody({ register, control, errors, watch, setValue, clearErrors, getValues })}
      </div>
      <div className={styles.formFooter}>
        {actions.map((btn, index) => (
          <BaseButton
            key={index}
            className={concat(styles.formBtn, btn.className)}
            onClick={() => {
              // let form submit to handle
              if (btn.type === 'submit') {
                return;
              }
              if (btn.cb) {
                const ret = btn.cb?.(formData);
                if (ret instanceof Promise) {
                  setLoadingBtn(index);
                  ret.finally(() => setLoadingBtn(null));
                }
              }
            }}
            color={btn.color || 'secondary'}
            variant={btn.variant || 'contained'}
            type={btn.type}
            disabled={!!loadingBtn}
            loading={loadingBtn === index}
          >
            {btn.icon}
            {btn.label}
          </BaseButton>
        ))}
      </div>
    </form>
  );
}

BaseForm.defaultProps = {
  className: '',
  handleApiError: undefined,
};

export default BaseForm;
