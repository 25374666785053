/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from 'react';
import DropdownIcon from 'components/icons/DropdownIcon';
import { Invite } from 'types/member';
import Popover from 'components/Popover';
import RoleMenuDropdown from './RoleMenuDropdown';
import { Switch } from '@mui/material';
import BaseButton from 'components/BaseButton';
import inviteNewUser from '../../api/user/invite-new-user';
import SpinLoader from 'components/icons/SpinLoader';
import useUserStore from 'stores/user';
import usePopupMessageStore from '../../stores/popup-message';

function InviteEntry() {
  const userStore = useUserStore();
  const popupMessageStore = usePopupMessageStore();

  const [invite, setInvite] = React.useState<Invite>({
    email: '',
    role: 'Sales Engineer',
    isAdmin: false,
  });
  const [showRoleDropdown, setShowRoleDropdown] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const roleButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <div className="flex items-center py-0.5 w-full gap-5">
      <div className="w-full">
        <label htmlFor="email" className="text-xs text-gray-400">
          Email
        </label>
        <input
          id="email"
          type="text"
          name="email"
          placeholder="you@example.com"
          value={invite.email}
          onChange={e => {
            setInvite(prev => ({ ...prev, email: e.target.value }));
          }}
          autoComplete="off"
          className="w-full h-10 rounded py-1.5 px-3 text-gray-900 text-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
        />
      </div>
      <div>
        <label htmlFor="role" className="text-xs text-gray-400">
          Role
        </label>
        <button
          id="role"
          ref={roleButtonRef}
          type="button"
          className="h-10 rounded py-1.5 px-3 text-gray-900 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 w-52"
          onClick={() => setShowRoleDropdown(!showRoleDropdown)}
        >
          <div className="flex w-full justify-between">
            <span>{invite.role}</span>
            <DropdownIcon className="h-5 w-5 text-gray-400" />
          </div>
        </button>
        <Popover
          anchorEl={roleButtonRef.current}
          onClose={() => setShowRoleDropdown(false)}
          isOpen={showRoleDropdown}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <RoleMenuDropdown
            currentRole={invite.role}
            onChange={role => {
              setInvite(prev => ({ ...prev, role }));
            }}
          />
        </Popover>
      </div>
      <div>
        <label htmlFor="isAdmin" className="text-xs text-gray-400">
          Admin
        </label>
        <div className="h-10 flex items-center">
          <Switch
            id="isAdmin"
            checked={invite.isAdmin}
            sx={{
              '&.MuiSwitch-root .Mui-checked': {
                color: '#1DBC86',
              },
              '&.MuiSwitch-root .MuiSwitch-track': {
                backgroundColor: `${invite.isAdmin ? '#1DBC86' : '#000'} !important`,
              },
            }}
            onChange={() => setInvite(prev => ({ ...prev, isAdmin: !invite.isAdmin }))}
          />
        </div>
      </div>
      <div>
        <BaseButton
          type="button"
          variant="contained"
          className="!w-40 !py-5"
          onClick={async () => {
            setIsLoading(true);
            try {
              await inviteNewUser(invite.email, invite.role, invite.isAdmin);
              await userStore.fetchMembers();
              popupMessageStore.success('Invitation sent successfully!');
              setInvite({
                email: '',
                role: 'Other',
                isAdmin: false,
              });
              setIsLoading(false);
            } finally {
              setIsLoading(false);
            }
          }}
          disabled={isLoading}
        >
          Send Invite
          {isLoading && <SpinLoader className="ml-2 w-5 h-5 text-white animate-spin" />}
        </BaseButton>
      </div>
    </div>
  );
}

export default InviteEntry;
