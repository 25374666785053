/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React, { useMemo } from 'react';
import styles from './UserProfileAvatar.module.css';
import Tooltip, { TooltipPlacement } from './Tooltip';
import { concat } from 'utils/styling';

interface Props {
  size: number;
  userInitial?: string;
  avatarUrl?: string;
  fullName: string;
  bgColor?: string;
  className?: string;
  tooltipPlacement?: TooltipPlacement;
}

function UserProfileAvatar({ size, userInitial, avatarUrl, fullName, bgColor, className, tooltipPlacement }: Props) {
  const initial = useMemo(() => (userInitial || fullName)?.[0] ?? '', [userInitial, fullName]);
  return (
    <Tooltip title={fullName} placement={tooltipPlacement || 'bottom'}>
      <div
        className={concat(styles.container, className)}
        style={{ backgroundColor: bgColor || 'white', width: size, height: size, minWidth: size, fontSize: size / 2 }}
      >
        {avatarUrl ? <img alt={fullName} src={avatarUrl} /> : <span>{initial}</span>}
      </div>
    </Tooltip>
  );
}

UserProfileAvatar.defaultProps = {
  userInitial: undefined,
  avatarUrl: undefined,
  bgColor: '',
  className: '',
  tooltipPlacement: 'bottom',
};

export default UserProfileAvatar;
