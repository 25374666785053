/* eslint-disable no-nested-ternary, react/no-array-index-key, no-param-reassign */
import React, { useMemo, useRef, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import useUserStore from 'stores/user';
import DeleteProductGapModal from 'components/DeleteProductGapModal';
import { MenuItem } from '@mui/material';
import FieldEditorBlock from 'components/ContextPanelView/ProductGap/FieldEditorBlock';
import ProductGapCategorySelector from 'components/ProductGapCategorySelector';
import FolderSelector from 'components/FolderSelector';
import useContextPanelStore from '../../stores/context-panel';
import useProductGapStore from '../../stores/product-gap';
import constants from '../../utils/constants';
import ProductCategoryView from './ProductGap/ProductCategoryView';
import { currencyFormatter } from '../../utils/number-formatter';
import ProductboardButtons from './ProductGap/ProductboardButtons';
import ThreeVerticalDotsIcon from '../icons/ThreeVerticalDotsIcon';
import Popover from '../Popover';
import useModalStore from '../../stores/modal';
import TrashIcon from '../icons/TrashIcon';
import ProductFieldAnswer from '../ProductFieldAnswer';
import { ProductCategory } from '../../types/product-gap';
import OpportunityIcon from 'components/icons/OpportunityIcon';
import AccountIcon from 'components/icons/AccountIcon';

function ProductGap() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSettingOpen, setIsSettingOpen] = useState<boolean>(false);

  const settingButtonRef = useRef<HTMLButtonElement>(null);

  const contextPanelStore = useContextPanelStore();
  const productGapStore = useProductGapStore();
  const userStore = useUserStore();
  const modalStore = useModalStore();

  const productGap = useMemo(
    () => productGapStore.productGaps.find(c => c.id === productGapStore.productGapId) || null,
    [productGapStore.productGaps, productGapStore.productGapId],
  );

  const productGapFolder = useMemo(() => {
    return productGap?.account || productGap?.opportunity
      ? {
          label: productGap.opportunity?.name || productGap.account?.name || '',
          value: productGap.documentId || '',
          icon: productGap.opportunity ? (
            <OpportunityIcon className="min-w-[1rem] w-4 h-4 fill-orange-400 text-orange-400" />
          ) : (
            <AccountIcon className="min-w-[1rem] w-4 h-4 text-blue-500" />
          ),
        }
      : undefined;
  }, [productGap]);

  const publish = async () => {
    if (productGap) {
      setIsLoading(true);
      await productGapStore.syncGap(productGap.id);
      setIsLoading(false);
    }
  };

  const updateCategory = async (category: ProductCategory | null) => {
    if (productGap) {
      const fieldAnswers: { [fieldId: string]: string } = {};
      productGap.fields.forEach(f => {
        fieldAnswers[f.id] = f.answer;
      });
      await productGapStore.updateProductGap(
        productGap.id,
        productGap.documentId,
        category?.id || null,
        fieldAnswers,
        category?.integrationId || null,
      );
    }
  };

  const updateDocument = async (documentId: string | null) => {
    if (productGap) {
      const fieldAnswers: { [fieldId: string]: string } = {};
      productGap.fields.forEach(f => {
        fieldAnswers[f.id] = f.answer;
      });
      await productGapStore.updateProductGap(
        productGap.id,
        documentId,
        productGap.category?.id || null,
        fieldAnswers,
        productGap.category?.integrationId || null,
      );
    }
  };
  const updateProductGapAnswer = async (fieldId: string, answer: string) => {
    if (productGap) {
      const fieldAnswers: { [fieldId: string]: string } = {};
      productGap.fields.forEach(f => {
        if (f.id === fieldId) {
          fieldAnswers[f.id] = answer;
          f.answer = answer;
        } else {
          fieldAnswers[f.id] = f.answer;
        }
      });
      await productGapStore.updateProductGap(
        productGap.id,
        productGap.documentId,
        productGap.category?.id || null,
        fieldAnswers,
        productGap.category?.integrationId || null,
      );
    }
  };

  return (
    <div className="pb-10">
      <div className="flex justify-between items-center p-4">
        <div className="text-lg font-medium">Product Gap</div>
        <div className="flex items-center gap-2">
          {!userStore.user?.needsToSetupProduct && userStore.user?.productProvider !== 'Superpanel' && productGap && (
            <ProductboardButtons productGap={productGap} isLoading={isLoading} publish={publish} />
          )}
          <button
            ref={settingButtonRef}
            type="button"
            className="w-8 h-8 p-1.5 bg-slate-200 rounded text-gray-500 hover:bg-slate-300"
            onClick={() => setIsSettingOpen(prev => !prev)}
          >
            <ThreeVerticalDotsIcon className="w-5 h-5" />
          </button>
          <Popover
            anchorEl={settingButtonRef.current}
            onClose={() => setIsSettingOpen(false)}
            isOpen={isSettingOpen}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <div className="flex flex-col">
              <MenuItem
                className="p-2 flex items-center gap-2"
                onClick={() => {
                  if (productGap) {
                    modalStore.setState({
                      isOpen: true,
                      element: (
                        <DeleteProductGapModal
                          productGapId={productGap.id}
                          onDelete={() => {
                            contextPanelStore.close();
                          }}
                        />
                      ),
                    });
                  }
                }}
              >
                <TrashIcon className="w-5 h-5 text-gray-600" />
                <div className="text-sm text-gray-600">Delete</div>
              </MenuItem>
            </div>
          </Popover>
          <button
            type="button"
            className="w-8 h-8 p-2 bg-slate-200 rounded text-gray-500 hover:bg-slate-300"
            onClick={contextPanelStore.close}
          >
            <XMarkIcon />
          </button>
        </div>
      </div>
      <div className="px-8 text-sm">
        {!productGap ? (
          <div> Unable to find this product gap. Please try again. </div>
        ) : (
          <div>
            <div className="mt-2">
              <FieldEditorBlock className="mt-2" title="Created By">
                <div className="w-full">
                  {productGap.createdBy.firstName} {productGap.createdBy.lastName}
                </div>
              </FieldEditorBlock>
              <FieldEditorBlock className="mt-2" title="Created At">
                <div className="w-full">{moment(productGap.createdAt).format(constants.DATE_FORMAT)}</div>
              </FieldEditorBlock>
              <FieldEditorBlock className="mt-2" title="Category" truncate>
                <ProductGapCategorySelector
                  selectedCategoryId={productGap.category?.id || null}
                  onSelect={updateCategory}
                />
              </FieldEditorBlock>
              <FieldEditorBlock className="mt-2" title="Folder" truncate>
                <FolderSelector
                  selectedDocumentId={productGap?.documentId}
                  setDocumentId={updateDocument}
                  defaultFolder={productGapFolder}
                />
              </FieldEditorBlock>
              {productGap.fields.map((field, index) => {
                return (
                  <FieldEditorBlock key={productGap.id + field.id + index} className="mt-2" title={field.title}>
                    <ProductFieldAnswer
                      placeholder={
                        field.title === 'Title'
                          ? 'Enter title for the product gap here'
                          : field.title === 'Feedback'
                          ? 'Enter product feedback here'
                          : ''
                      }
                      multiline={field.title === 'Feedback'}
                      fieldType={field.type}
                      additionalData={field.additionalData}
                      answer={field.answer}
                      onUpdate={async answer => updateProductGapAnswer(field.id, answer)}
                    />
                  </FieldEditorBlock>
                );
              })}
              {productGap.account && (
                <div className="mt-8">
                  <div className="text-lg font-medium">Account</div>
                  <FieldEditorBlock className="mt-4" title="Name">
                    <div className="w-full">{productGap.account.name}</div>
                  </FieldEditorBlock>
                  <FieldEditorBlock className="mt-2" title="Owner">
                    <div className="w-full">
                      {productGap.account.owner?.firstName} {productGap.account.owner?.lastName}
                    </div>
                  </FieldEditorBlock>
                </div>
              )}
              {productGap.opportunity && (
                <div className="mt-8">
                  <div className="text-lg font-medium">Opportunity</div>
                  <FieldEditorBlock className="mt-4" title="Name">
                    <div className="w-full">{productGap.opportunity.name}</div>
                  </FieldEditorBlock>
                  <FieldEditorBlock className="mt-2" title="Owner">
                    <div className="w-full">
                      {productGap.opportunity.owner?.firstName} {productGap.opportunity.owner?.lastName}
                    </div>
                  </FieldEditorBlock>
                  <FieldEditorBlock className="mt-2" title="Amount">
                    <div className="w-full">{currencyFormatter.format(productGap.opportunity.amount || 0)}</div>
                  </FieldEditorBlock>
                  <FieldEditorBlock className="mt-2" title="Close Date">
                    <div className="w-full">{productGap.opportunity.closeDate}</div>
                  </FieldEditorBlock>
                  <FieldEditorBlock className="mt-2" title="Stage">
                    <div className="w-full">{productGap.opportunity.stageName}</div>
                  </FieldEditorBlock>
                </div>
              )}
              {productGap.category && (
                <div className="mt-8">
                  <div className="text-lg font-medium">Category</div>
                  <ProductCategoryView productCategory={productGap.category} />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ProductGap;
