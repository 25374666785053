import React from 'react';

interface Props {
  className?: string;
}

function RefreshIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.2002 8.20996V14.9668H21.6523"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.4616 20.5979C24.7881 22.6446 23.4606 24.4131 21.6835 25.6313C19.9064 26.8495 17.7781 27.4498 15.6263 27.3398C13.4721 27.1862 11.4269 26.3335 9.8015 24.9113C8.17614 23.4891 7.05951 21.5752 6.62131 19.4603C6.1419 17.3342 6.35228 15.1107 7.22193 13.1122C8.09158 11.1137 9.57527 9.4442 11.4578 8.34589C13.3177 7.28685 15.4784 6.88004 17.5961 7.19012C19.7138 7.50021 21.6671 8.5094 23.1453 10.0572L28.1986 14.9674"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

RefreshIcon.defaultProps = {
  className: undefined,
};

export default RefreshIcon;
