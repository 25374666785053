import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';

import useContextPanelStore from 'stores/context-panel';
import useFieldValuesStore from 'stores/field-values';

import { SuggestedAnswer as ISuggestedAnswer } from 'types/field-value';

import constants from 'utils/constants';

import PlayBack from './PlayBack';
import RecordingIcon from './RecordingsIcon';
import EllipsisTooltipContent from 'components/EllipsisTooltipContent';

interface Props {
  fieldValueId: string;
  suggestedAnswer: ISuggestedAnswer;
  updateAnswer: (answer: string) => Promise<void>;
  onReject: () => void;
  from: 'discoveryQuestionContextPanel' | 'docEditor' | 'reporting';
}

interface State {
  isLoading: boolean;
}

function SuggestedAnswerView({ fieldValueId, suggestedAnswer, updateAnswer, onReject, from }: Props) {
  const [state, setState] = useState<State>({
    isLoading: false,
  });

  const fieldValuesStore = useFieldValuesStore();
  const contextPanelStore = useContextPanelStore();

  const updateSuggestedAnswerStatus = async (status: 'accepted' | 'rejected'): Promise<void> => {
    setState(oldState => ({ ...oldState, isLoading: true }));
    if (suggestedAnswer.id !== fieldValueId) {
      await fieldValuesStore.updateSuggestedAnswerStatus(fieldValueId, suggestedAnswer.id, status);
    } else {
      await fieldValuesStore.updateFieldValueSuggestionStatus(fieldValueId, status);
    }
    setState(oldState => ({ ...oldState, isLoading: false }));
  };
  const elementId = `${from}-${suggestedAnswer.id}`;

  useEffect(() => {
    if (elementId === contextPanelStore.jumpToSuggestedAnswerId) {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        contextPanelStore.setState({ jumpToSuggestedAnswerId: undefined });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const suggestedAnswerEl = useMemo(() => {
    const suggestAns = suggestedAnswer.valueAiSuggestion || suggestedAnswer.suggestedAnswer;
    return <div className="inline text-sm">{suggestAns}</div>;
  }, [suggestedAnswer.suggestedAnswer]);

  return (
    <div id={elementId} className="animate-fade-in-down w-fit p-2 hover:bg-slate-50 rounded mt-1" data-heap-redact-text>
      <div className="flex gap-1 items-start">
        <EllipsisTooltipContent className="text-gray-500" maxDisplayLines={8}>
          {suggestedAnswerEl}
        </EllipsisTooltipContent>
        {!suggestedAnswer.acceptanceStatus && (
          <div className="flex items-start">
            <button
              id="accept-suggested-answer-button"
              type="button"
              className="inline-flex items-baseline ml-2 gap-1 group"
              onClick={async () => {
                await updateAnswer(suggestedAnswer.valueAiSuggestion || suggestedAnswer.suggestedAnswer);
                await updateSuggestedAnswerStatus('accepted');
              }}
              disabled={state.isLoading}
            >
              <CheckIcon className="self-center bg-blue-300 stroke-2 text-white rounded-full p-0.5 w-5 h-5 hover:bg-blue-400" />
            </button>
            <button
              id="reject-suggested-answer-button"
              type="button"
              className="inline-flex items-baseline ml-2 gap-1 group"
              onClick={async () => {
                await updateSuggestedAnswerStatus('rejected');
                onReject();
              }}
              disabled={state.isLoading}
            >
              <XMarkIcon className="self-center bg-gray-300 stroke-2 text-white rounded-full p-0.5 w-5 h-5 hover:bg-gray-400" />
            </button>
          </div>
        )}
      </div>
      {!!suggestedAnswer.callId && !suggestedAnswer.valueAiSuggestion && (
        <div className="mt-1 flex gap-2 items-center">
          <div className="flex items-center text-gray-400 text-xs gap-1">
            <RecordingIcon entityType={suggestedAnswer.callEntityName} />
            <span>
              {suggestedAnswer.callTitle || 'No Title'}
              {', '}
              {moment(suggestedAnswer.callStarted).format(constants.DATE_FORMAT)}
            </span>
          </div>
          <PlayBack
            recordingId={suggestedAnswer.callId}
            startTime={suggestedAnswer.contextStartTime}
            from={from}
            elementId={elementId}
          />
        </div>
      )}
    </div>
  );
}
export default SuggestedAnswerView;
