import { XMarkIcon } from '@heroicons/react/24/outline';
import { Switch } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { find } from 'lodash';

import CopyIcon from 'components/icons/CopyIcon';
import LinkIcon from 'components/icons/LinkIcon';
import { Member, MemberStatus } from 'types/member';
import { Document } from 'types/document';

import useDocumentStore from 'stores/document';
import useModalStore from 'stores/modal';
import usePopupMessageStore from 'stores/popup-message';
import useUserStore from 'stores/user';

import copyToClipboard from 'utils/copy-to-clipboard';
import { openMessageInfoModal } from 'components/BaseModal';
import inviteNewUser from 'api/user/invite-new-user';
import shareUserLink from 'api/user/share-user-link';
import SpinLoader from 'components/icons/SpinLoader';
import emailToDomain from 'utils/email-to-domain';
import config from 'utils/config';
import Tooltip from '../../../components/Tooltip';

interface Props {
  entityType: 'document' | 'opportunity' | 'account';
  documentId: string;
}

function ShareModal({ entityType, documentId }: Props) {
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [newInvite, setNewInvite] = useState<string>('');
  const [isDomainMismatch, setIsDomainMismatch] = useState<boolean>(false);

  const documentStore = useDocumentStore();
  const modalStore = useModalStore();
  const popupMessageStore = usePopupMessageStore();
  const userStore = useUserStore();
  const members = useMemo(() => {
    const searchStr = newInvite.trim().toLowerCase();

    return (userStore.members || [])
      .filter(m => {
        // Filter Only Active Member
        return m.status === MemberStatus.ACTIVE;
      })
      .filter(m => {
        // Show based on the invite email
        if (searchStr === '') return true;
        return (
          (m.firstName && m.firstName.toLowerCase().indexOf(searchStr.toLowerCase()) !== -1) ||
          (m.lastName && m.lastName.toLowerCase().indexOf(searchStr.toLowerCase()) !== -1) ||
          m.email.toLowerCase().indexOf(searchStr.toLowerCase()) !== -1
        );
      });
  }, [userStore.members, newInvite]);

  useEffect(() => {
    userStore.fetchMembers();
  }, [documentId, entityType]);

  useEffect(() => {
    if (isCopied) {
      const timer = setTimeout(() => setIsCopied(false), 1500);
      return () => clearTimeout(timer);
    }
    return () => null;
  }, [isCopied]);

  useEffect(() => {
    if (isDomainMismatch) {
      setIsDomainMismatch(false);
    }
  }, [newInvite]);

  const getFirstChar = (user: Member) => {
    if (user.firstName && user.firstName.length > 0) {
      return user.firstName.charAt(0);
    }

    if (user.email && user.email.length > 0) {
      return user.email.charAt(0);
    }

    return '';
  };

  let doc: Document | undefined;
  if (entityType === 'document') {
    doc = documentStore.documents.find(d => d.id === documentId);
  }

  return (
    <div className="w-[34rem]">
      <div className="flex justify-between items-center border-b px-5 py-3">
        <div className="text-lg font-medium capitalize">Share {entityType}</div>
        <button
          type="button"
          className="w-8 h-8 p-2 bg-slate-100 hover:bg-slate-200 rounded"
          onClick={modalStore.close}
        >
          <XMarkIcon className="text-gray-600" />
        </button>
      </div>
      <div className="pt-5 pb-8 px-5">
        <div>
          <div className="text-xs font-medium text-gray-600 mb-1">Invite people from your organization</div>
          <div className="flex gap-2 items-center">
            <input
              className="w-full border p-2 text-sm rounded"
              id="invite"
              type="text"
              placeholder="you@example.com"
              autoComplete="off"
              disabled={isLoading}
              value={newInvite}
              onChange={e => {
                setNewInvite(e.target.value);
              }}
            />
            <Tooltip
              title={
                doc && !doc.isPublic
                  ? 'Invitations are disabled because this document is private. Please make the document available to the team.'
                  : ''
              }
              placement="top"
            >
              <button
                className="w-20 bg-blue-500 text-white rounded-lg px-4 py-2 enabled:hover:bg-blue-300 disabled:opacity-75"
                type="button"
                disabled={isLoading || newInvite.length === 0 || userStore.membersLoading || (doc && !doc.isPublic)}
                onClick={async () => {
                  if (
                    config.ENV !== 'local' &&
                    userStore.user &&
                    emailToDomain(newInvite) !== emailToDomain(userStore.user?.email)
                  ) {
                    setIsDomainMismatch(true);
                  } else {
                    setIsLoading(true);
                    try {
                      const link = `${window.location.origin}/${entityType}/${documentId}`;
                      if (
                        find(members, user => {
                          return user.email === newInvite && user.firstName;
                        })
                      ) {
                        await shareUserLink(entityType, documentId, newInvite, link).then(() => {
                          popupMessageStore.success('An email with the shared link has been sent!');
                          setNewInvite('');
                        });
                      } else {
                        await inviteNewUser(newInvite, 'Other', false, link, documentId, entityType).then(() => {
                          popupMessageStore.success('An invitation have been sent!');
                          setNewInvite('');
                        });
                      }
                    } finally {
                      setIsLoading(false);
                    }
                  }
                }}
              >
                {isLoading ? <SpinLoader className="w-4 h-4 text-orange-400 animate-spin" /> : 'Invite'}
              </button>
            </Tooltip>
          </div>
        </div>
        <div className="my-2">
          {isDomainMismatch && userStore.user && (
            <div className="text-red-500">{`You can only invite user whoes email domain is ${emailToDomain(
              userStore.user?.email,
            )}`}</div>
          )}
          {!isDomainMismatch && (
            <>
              <div className="text-xs font-medium text-gray-600 mb-1">From Superpanel team:</div>
              <div className="flex flex-col gap-2 h-40 overflow-y-auto">
                {userStore.membersLoading && (
                  <div className="py-5 flex justify-center">
                    <SpinLoader className="w-10 h-10 animate-spin" />
                  </div>
                )}
                {!userStore.membersLoading &&
                  members.map(user => (
                    <div key={user.email}>
                      <button
                        className="flex flex-row"
                        type="button"
                        onClick={() => {
                          setNewInvite(user.email);
                        }}
                      >
                        <div className="h-10 w-10 flex items-center justify-center mr-2">
                          <div className="h-8 w-8 text-gray-600 border-solid border-2 rounded-full border-gray-400 flex items-center justify-center">
                            {getFirstChar(user)}
                          </div>
                        </div>
                        <div className="flex flex-col">
                          <div className="text-sm flex justify-start h-5">
                            {user.firstName} {user.lastName}
                          </div>
                          <div className="text-sm font-medium text-gray-400 flex justify-start">{user.email}</div>
                        </div>
                      </button>
                    </div>
                  ))}
              </div>
            </>
          )}
        </div>
        <div>
          <div className="text-xs font-medium text-gray-600 mb-1 capitalize">{entityType} Link</div>
          <div className="flex gap-2 items-center">
            <input
              className="w-full border p-2 text-sm rounded"
              id="copy"
              readOnly
              type="text"
              value={`${window.location.origin}/${entityType}/${documentId}`}
            />
            <button
              className="w-9 h-9 p-2 bg-slate-100 hover:bg-slate-200 rounded"
              onClick={() => {
                copyToClipboard('copy');
                setIsCopied(true);
              }}
              type="button"
            >
              <CopyIcon className="text-gray-600" />
            </button>
          </div>
          {isCopied && <div className="text-xs text-green-500">Link is copied</div>}
        </div>
        {entityType === 'document' && (
          <div className="mt-5 flex justify-between items-center">
            <div className="flex items-center gap-3">
              <div className="relative w-10 h-10 bg-blue-100 text-blue-500 rounded-full flex-shrink-0">
                <LinkIcon className="w-6 h-6 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2" />
              </div>
              <div>
                <div className="text-sm font-medium">Make available to the team</div>
                <div className="text-xs text-gray-400">
                  Anyone with access to the opportunity can view this {entityType}.
                </div>
              </div>
            </div>
            <div>
              <Switch
                checked={doc?.isPublic}
                onChange={event => {
                  if (doc?.isPublic && !!doc.crmNoteId) {
                    openMessageInfoModal(
                      'Alert',
                      'This document already synced, CRM linked document cannot be private!',
                    );
                  } else {
                    documentStore.updateDocumentData(documentId, { isPublic: event.target.checked });
                  }
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ShareModal;
