/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import FieldPicker from 'components/FieldPicker';
import useChatsStore from 'stores/chats';
import useFieldValuesStore from 'stores/field-values';
import useRecordingsStore from 'stores/recordings';
import FieldValuesView from './FieldValuesView';
import { PlusIcon } from '@heroicons/react/24/outline';
import ButtonPopover from 'components/ButtonPopover';
import Loader from 'components/Loader';
import { EntityDocument } from '../../types/entity';

interface Props {
  entityDocument: EntityDocument;
}

function DiscoveryQuestionList({ entityDocument }: Props) {
  const [isAcctAddFieldsOpen, setIsAcctAddFieldsOpen] = useState(false);
  const [isOppAddFieldsOpen, setIsOppAddFieldsOpen] = useState(false);
  const chatsStore = useChatsStore();
  const recordingsStore = useRecordingsStore();
  const fieldValuesStore = useFieldValuesStore();

  useEffect(() => {
    fieldValuesStore.fetchWithAITrigger(entityDocument.id);
    recordingsStore.fetch(entityDocument.id);
    chatsStore.fetch(entityDocument.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityDocument.id]);

  const filteredFieldValues = useMemo(
    () =>
      fieldValuesStore.fieldValues
        .filter(fv => fv.shownInDiscoveryDocument)
        .sort((a, b) => a.field.order - b.field.order),
    [fieldValuesStore.fieldValues],
  );

  const addFieldsBtn = useCallback(
    (type: 'account' | 'opportunity' | '') => {
      return (
        <ButtonPopover
          btnColor="secondary"
          btnVariant="outlined"
          btnClassName="!text-sm !text-gray-500 !w-fit"
          horizontalOrigin="left"
          isPopoverOpen={type === 'account' ? isAcctAddFieldsOpen : isOppAddFieldsOpen}
          popoverToggle={show => (type === 'account' ? setIsAcctAddFieldsOpen(show) : setIsOppAddFieldsOpen(show))}
          popoverContent={
            <div className="w-96">
              <FieldPicker
                inputValue=""
                addSearchField
                fields={fieldValuesStore.fieldValues
                  .filter(f => f.field.entity.toLowerCase() === type)
                  .map(f => f.field)}
                usedFieldIds={filteredFieldValues.map(f => f.field.id)}
                onAddFields={async fields => {
                  const fieldIds = fields.map(f => f.id);
                  const fieldValues = fieldValuesStore.fieldValues.filter(fv => fieldIds.includes(fv.field.id));
                  fieldValuesStore.updateFieldValues(
                    fieldValues.map(fieldValue => ({
                      id: fieldValue.id,
                      shownInDiscoveryDocument: true,
                    })),
                  );
                  if (type === 'account') {
                    setIsAcctAddFieldsOpen(false);
                  } else {
                    setIsOppAddFieldsOpen(false);
                  }
                }}
              />
            </div>
          }
        >
          <PlusIcon className="w-4 h-4" />
          <span>Add Fields</span>
        </ButtonPopover>
      );
    },
    [fieldValuesStore.fieldValues, filteredFieldValues, isAcctAddFieldsOpen, isOppAddFieldsOpen],
  );

  if (!entityDocument) {
    return null;
  }

  return (
    <div className="w-full h-full flex flex-col">
      <div className="flex flex-col gap-3">
        {!fieldValuesStore.isLoading ? (
          <FieldValuesView
            fieldValues={filteredFieldValues}
            documentId={entityDocument.id}
            entityName={entityDocument.entity.name}
            headerRightElement={addFieldsBtn}
          />
        ) : (
          <div className="flex items-center justify-center">
            <Loader className="w-12 h-12" />
          </div>
        )}
      </div>
    </div>
  );
}

export default DiscoveryQuestionList;
