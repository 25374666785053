/* eslint-disable  no-param-reassign, prefer-template, no-useless-concat */
import { BlockType, CustomElement, INDENTABLE_TYPES } from './types';
import Turndown from 'turndown';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const turndownPluginGfm = require('joplin-turndown-plugin-gfm');

export const toPx = (value?: number): string | undefined => (value ? `${Math.round(value)}px` : undefined);

export function createNode(blockType: BlockType, indentLevel: number | undefined = 0): CustomElement {
  // UnindentableBlock
  if (!INDENTABLE_TYPES.includes(blockType)) {
    return {
      type: blockType,
      children: [
        {
          text: '',
        },
      ],
    };
  }
  // IntentableBlock
  return {
    type: blockType,
    children: [
      {
        text: '',
      },
    ],
    indentLevel,
  };
}

export const int2roman = (original: number): string => {
  if (original < 1 || original > 3999) {
    return '*';
  }
  const numerals = [
    ['i', 'ii', 'iii', 'iv', 'v', 'vi', 'vii', 'viii', 'ix'], // 1-9
    ['x', 'xx', 'xxx', 'xl', 'l', 'lx', 'lxx', 'lxxx', 'xc'], // 10-90
    ['c', 'cc', 'ccc', 'cd', 'd', 'dc', 'dcc', 'dccc', 'cm'], // 100-900
    ['m', 'mm', 'mmm'], // 1000-3000
  ];

  // TODO: Could expand to support fractions, simply rounding for now
  const digits = Math.round(original).toString().split('');
  let position = digits.length - 1;

  return digits.reduce((roman, digit) => {
    if (digit !== '0') {
      // eslint-disable-next-line no-param-reassign
      roman += numerals[position][parseInt(digit, 10) - 1];
    }
    position -= 1;
    return roman;
  }, '');
};

export function int2letter(n: number) {
  const ordA = 'a'.charCodeAt(0);
  const ordZ = 'z'.charCodeAt(0);
  const len = ordZ - ordA + 1;

  let s = '';
  while (n >= 0) {
    s = String.fromCharCode((n % len) + ordA) + s;
    // eslint-disable-next-line no-param-reassign
    n = Math.floor(n / len) - 1;
  }
  return s;
}

export function isLetter(c: string) {
  return c.toLowerCase() !== c.toUpperCase() && c.length === 1;
}

export function findSlateNode(element: Element): Element | null {
  // eslint-disable-next-line no-restricted-syntax
  for (const attr of element.attributes) {
    if (attr.name.startsWith('data-slate')) {
      return element;
    }
  }

  const els = Array.from(element.getElementsByTagName('*'));
  // eslint-disable-next-line no-restricted-syntax
  for (const el of els) {
    // eslint-disable-next-line no-restricted-syntax
    for (const attr of el.attributes) {
      if (attr.name.startsWith('data-slate')) {
        return el;
      }
    }
  }
  return null;
}

const cleanAttribute = (attribute: string | null) => {
  return attribute ? attribute.replace(/(\n+\s*)+/g, '\n') : '';
};

// not escape when convert html to markdown
Turndown.prototype.escape = str => str;
const { gfm } = turndownPluginGfm;
const turndownService = new Turndown({
  headingStyle: 'atx',
});
turndownService.use(gfm);
/* ignore inline tags if it contain line break(multi lines) */
turndownService.addRule('bold', {
  filter: ['strong', 'b'],
  replacement: content => {
    return content.includes('\n') ? content : `**${content}**`;
  },
});
turndownService.addRule('strike', {
  filter: ['del', 's'],
  replacement: content => {
    return content.includes('\n') ? content : `<s>${content}</s>`;
  },
});
turndownService.addRule('italic', {
  filter: ['em', 'i'],
  replacement: content => {
    return content.includes('\n') ? content : `<i>${content}</i>`;
  },
});
turndownService.addRule('underline', {
  filter: ['u'],
  replacement: content => {
    return content.includes('\n') ? content : `<u>${content}</u>`;
  },
});
turndownService.addRule('code', {
  filter: ['code'],
  replacement: content => {
    return content.includes('\n') ? content : `\`${content}\``;
  },
});
// ignore style element
turndownService.addRule('ignore-style', {
  filter: ['style'],
  replacement: () => '',
});
// ignore style element
turndownService.addRule('code', {
  filter: ['code'],
  replacement: content => `\`${content}\``,
});
// li custom converter, code copied from source code of `turndown`, just reduce the white space count
turndownService.addRule('li', {
  filter: 'li',
  replacement: (content, node, options) => {
    content = content
      .replace(/^\n+/, '') // remove leading newlines
      .replace(/\n+$/, '\n') // replace trailing newlines with just a single one
      .replace(/\n/gm, '\n    '); // indent
    let prefix = options.bulletListMarker + ' ';
    const parent = node.parentNode;
    if (parent?.nodeName === 'OL') {
      const start = (parent as HTMLOListElement).getAttribute('start');
      const index = Array.prototype.indexOf.call(parent.children, node);
      prefix = (start ? Number(start) + index : index + 1) + '. ';
    }
    return prefix + content + (node.nextSibling && !/\n$/.test(content) ? '\n' : '');
  },
});
// `a` custom converter, code copied from source code of `turndown`, remove white space and line break
turndownService.addRule('a', {
  filter: (node, options) => {
    return !!(options.linkStyle === 'inlined' && node.nodeName === 'A' && node.getAttribute('href'));
  },
  replacement: (content, node) => {
    const href = (node as HTMLElement).getAttribute('href');
    return '[' + content.replace(/\n/g, '').trim() + '](' + href + ')';
  },
});
// `img` custom converter, code copied from source code of `turndown`, remove white space and line break
turndownService.addRule('img', {
  filter: 'img',
  replacement: (_content, node) => {
    const alt = cleanAttribute((node as HTMLElement).getAttribute('alt'));
    const src = (node as HTMLElement).getAttribute('src') || '';
    const title = cleanAttribute((node as HTMLElement).getAttribute('title'));
    const titlePart = title ? ' "' + title + '"' : '';
    return src ? '![' + alt.trim() + ']' + '(' + src + titlePart + ')' : '';
  },
});
// `p` custom converter, code copied from source code of `turndown`, just add one surrounding `\n` instead of two
turndownService.addRule('p', {
  filter: 'p',
  replacement: content => {
    return '\n' + content + '\n';
  },
});

export function translateHtmlToMarkdown(html: string): string {
  const md = turndownService.turndown(html);
  return md.trim();
}
