import { Popper } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Transforms } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';

import { BlockType, DiscoveryQuestionElement } from 'components/NoteEditor/types';
import SpinLoader from 'components/icons/SpinLoader';

import useNoteEditorStore from 'stores/note-editor';
import useFieldValuesStore from 'stores/field-values';

import { Field } from 'types/field-value';
import FieldPicker from 'components/FieldPicker';
import NoteEditor from 'components/NoteEditor/NoteEditor';

interface State {
  isLoading: boolean;
  inputValue: string;
  isDropdownOpen: boolean;
}

interface Props {
  element: DiscoveryQuestionElement;
  documentId: string | null;
}

function AddDiscoveryQuestion({ element, documentId }: Props) {
  const [state, setState] = useState<State>({
    isLoading: false,
    inputValue: '',
    isDropdownOpen: false,
  });

  const ref = useRef<HTMLInputElement>(null);

  const editor = useSlate();
  const path = ReactEditor.findPath(editor, element);

  const fieldValuesStore = useFieldValuesStore();
  const noteEditorStore = useNoteEditorStore();
  const fields = fieldValuesStore.fieldValues.map(dqa => dqa.field);
  const fieldPickerRef = useRef<React.ElementRef<typeof FieldPicker>>(null);

  useEffect(() => {
    // Implementing hacky way to autofocus on the input element inside slate
    // only auto popup dropdown when create discovery question element
    if (ref.current && element.discoveryQuestionId === '') {
      setTimeout(() => {
        if (ref.current) {
          ReactEditor.focus(editor);
          ref.current.focus();
          setState(prevState => ({ ...prevState, isDropdownOpen: true }));
        }
      }, 200);
      // set discoveryQuestionId to null which means it finished creating
      Transforms.setNodes(editor, { discoveryQuestionId: null }, { at: path });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  const usedFieldIds = useMemo(() => {
    return documentId ? noteEditorStore.discoveryQuestionList[documentId] || [] : [];
  }, [noteEditorStore.discoveryQuestionList, documentId]);

  const addFields = async (newFields: Field[]) => {
    ref.current?.blur();
    newFields.forEach((field, idx) => {
      const isAlreadyInDoc = usedFieldIds.includes(field.id);
      if (!isAlreadyInDoc) {
        if (idx === 0) {
          Transforms.setNodes(editor, { discoveryQuestionId: field.id }, { at: path });
        } else {
          Transforms.insertNodes(editor, {
            type: BlockType.DiscoveryQuestion,
            discoveryQuestionId: field.id,
            children: [{ text: '' }],
          });
        }
      }
    });
    const updateValues = newFields.map(field => {
      const fieldValue = fieldValuesStore.fieldValues.find(fv => fv.field.id === field.id);
      if (fieldValue) {
        return {
          id: fieldValue.id,
          shownInDiscoveryDocument: true,
        };
      }
      throw new Error('Field value not found');
    });
    fieldValuesStore.updateFieldValues(updateValues);
  };

  return (
    <div>
      <div className="relative">
        <input
          data-lpignore="true"
          ref={ref}
          className="w-full rounded bg-slate-100 px-3 py-4 mb-2 mt-1"
          value={state.inputValue}
          onChange={event => setState(prevState => ({ ...prevState, inputValue: event.target.value }))}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              // add checked fields first
              const added = fieldPickerRef.current?.addCheckedFields();
              if (added) {
                return;
              }
              // get all displayed filtered out and not added fields
              const discoveryQuestions = fieldPickerRef.current?.getDisplayedNotAddedFields();
              if (discoveryQuestions?.length) {
                if (discoveryQuestions.length === 1) {
                  // if just one field filtered out, add it
                  addFields(discoveryQuestions);
                  return;
                }
                // if have one field having exact name with input string, add it
                const exactNameDiscoveryQuestion = discoveryQuestions.find(
                  field => field.label?.toLowerCase().trim() === state.inputValue.toLowerCase().trim(),
                );
                if (exactNameDiscoveryQuestion) {
                  addFields([exactNameDiscoveryQuestion]);
                  return;
                }
              }
              // if user gives nothing then remove the node
              if (!state.inputValue.trim()) {
                NoteEditor.sp_deleteNode(editor, path);
              }
            } else if (event.key === 'Escape') {
              NoteEditor.sp_deleteNode(editor, path);
            }
          }}
          onFocus={() => setState(prevState => ({ ...prevState, isDropdownOpen: true }))}
          onBlur={() => {
            setState(prevState => ({ ...prevState, isDropdownOpen: false }));
          }}
          placeholder="Search discovery field by start typing..."
        />
        {state.isLoading && (
          <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center z-10 backdrop-blur-sm">
            <SpinLoader className="animate-spin w-5 h-5 mr-2 text-orange-500" />
          </div>
        )}
      </div>
      <Popper anchorEl={ref.current} open={state.isDropdownOpen} className="!my-1">
        <div style={{ width: `${ref.current?.getBoundingClientRect().width}px` }}>
          <FieldPicker
            inputValue={state.inputValue}
            addSearchField={false}
            fields={fields}
            usedFieldIds={usedFieldIds}
            onAddFields={addFields}
            ref={fieldPickerRef}
          />
        </div>
      </Popper>
    </div>
  );
}

export default AddDiscoveryQuestion;
