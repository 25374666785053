import httpClient from 'api/http-client';

export interface Response {
  token: string;
}

export default async (userId: string): Promise<Response> => {
  const { data } = await httpClient.post<Response>('/admin/get-user-token', { userId });
  return data;
};
