import React, { useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';

import SpinLoader from 'components/icons/SpinLoader';

import useModalStore from 'stores/modal';
import WarningIcon from 'components/icons/WarningIcon';

interface State {
  finalAnswer: string;
  isLoading: boolean;
}

interface Props {
  answer: string | null;
  suggestion: string;
  limit: number;
  onConfirm: (finalAnswer: string) => Promise<void>;
}

function FieldLengthValidationErrorModal({ answer, suggestion, limit, onConfirm }: Props) {
  const [state, setState] = useState<State>({
    finalAnswer: answer && answer.length > 0 ? `${answer}\n\n${suggestion}` : `${suggestion}`,
    isLoading: false,
  });

  const modalStore = useModalStore();

  return (
    <div className="w-[30rem] p-5">
      <div className="flex items-center justify-between">
        <div className="w-14 h-14 bg-red-200 rounded-full flex justify-center items-center">
          <WarningIcon className="w-8 h-8 text-red-500" />
        </div>
        <button
          type="button"
          className="w-7 h-7 p-1 rounded-lg text-gray-400 hover:bg-gray-200"
          onClick={modalStore.close}
        >
          <XMarkIcon />
        </button>
      </div>
      <div className="text-sm text-gray-400 mt-3">Please edit the answer to meet the field length limit.</div>
      <textarea
        className="resize-none w-full border rounded p-2 focus:border-blue-300 placeholder:text-xs text-gray-500 text-sm"
        rows={5}
        value={state.finalAnswer}
        onChange={e => setState(prevState => ({ ...prevState, finalAnswer: e.target.value }))}
        placeholder="Enter answer"
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
      />
      <div className={`flex justify-end ${state.finalAnswer.length > limit ? 'text-red-500' : 'text-green-500'}`}>
        {state.finalAnswer.length} / {limit}
      </div>
      <div className="flex justify-end items-center gap-2 mt-5">
        <button
          type="button"
          className="px-4 py-2 bg-gray-100 hover:bg-gray-200 text-gray-500 rounded"
          onClick={modalStore.close}
        >
          Cancel
        </button>
        <button
          type="button"
          className={`flex items-center gap-2 px-4 py-2 ${
            state.finalAnswer.length > limit ? 'bg-gray-500' : 'bg-green-500'
          } text-white rounded`}
          disabled={state.finalAnswer.length > limit || state.isLoading}
          onClick={async () => {
            setState(prev => ({ ...prev, isLoading: true }));
            await onConfirm(state.finalAnswer);
            setState(prev => ({ ...prev, isLoading: false }));
            modalStore.close();
          }}
        >
          {state.isLoading && <SpinLoader className="animate-spin w-4 h-4" />}
          Confirm
        </button>
      </div>
    </div>
  );
}

export default FieldLengthValidationErrorModal;
