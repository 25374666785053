import moment from 'moment';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import useCalendarEventsStore from 'stores/calendar-events';

interface State {
  lastLoadedAt: null | string;
}

function CalendarBackgroundDataLoader() {
  const location = useLocation();
  const [state, setState] = useState<State>({
    lastLoadedAt: null,
  });

  const calendarEventsStore = useCalendarEventsStore();
  // const documentStore = useDocumentStore();

  useEffect(() => {
    const intervalId = setInterval(() => {
      calendarEventsStore.setState({
        currentDateTime: moment().toISOString(true),
      });
    }, 10000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      (state.lastLoadedAt === null || moment().diff(moment(state.lastLoadedAt), 'minutes', true) > 1) &&
      ![
        '/calendar-integration',
        '/google-oauth',
        '/login',
        '/register',
        '/microsoft-oauth',
        '/recording-integration',
        '/salesforce-oauth',
        '/zoom-oauth',
        '/gong-oauth',
        '/user-invitation',
        '/questions-setup',
      ].includes(location.pathname) &&
      !location.pathname.startsWith('/admin')
    ) {
      calendarEventsStore.fetch();
      setState({
        lastLoadedAt: moment().toISOString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return null;
}

export default CalendarBackgroundDataLoader;
