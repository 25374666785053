import React, { useRef, useState } from 'react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { Menu, MenuItem } from '@mui/material';
import DocumentPlusIcon from 'components/icons/DocumentPlusIcon';
import useNoteEditorStore from 'stores/note-editor';
import DeleteDocumentModal from 'components/DeleteDocumentModal';
import { Document } from 'types/document';
import useModalStore from 'stores/modal';
import TrashIcon from 'components/icons/TrashIcon';
import ManageTemplateModal from 'components/ManageTemplateModal';
import { concat } from 'utils/styling';
import NoteEditor from 'components/NoteEditor/NoteEditor';
import { openInformationalModal } from 'components/BaseModal';

interface Props {
  document: Document;
  className?: string;
  showDelete?: boolean;
  onApplyTemplate?: () => void;
  onSaveAsTemplate?: () => void;
}

function DocumentMoreActionBtn({ document, className, showDelete, onApplyTemplate, onSaveAsTemplate }: Props) {
  const menuButtonRef = useRef<HTMLButtonElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const noteEditorStore = useNoteEditorStore();
  const modalStore = useModalStore();

  const openApplyTemplateModal = () => {
    modalStore.setState({
      element: <ManageTemplateModal document={document} />,
      isOpen: true,
      disableEscapeKeyDown: true,
    });
  };

  return (
    <>
      <button
        className={concat('px-1 text-sm rounded bg-gray-100 hover:bg-gray-200', className)}
        type="button"
        onClick={e => {
          e.stopPropagation();
          setIsMenuOpen(true);
        }}
        ref={menuButtonRef}
      >
        <EllipsisVerticalIcon className="w-5 h-5 mt-1" />
      </button>
      <Menu
        anchorEl={menuButtonRef.current}
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        onClick={e => e.stopPropagation()}
      >
        <MenuItem
          className="flex items-center gap-2"
          onClick={() => {
            setIsMenuOpen(false);
            openApplyTemplateModal();
            onApplyTemplate?.();
          }}
        >
          <DocumentPlusIcon className="w-5 h-5 text-gray-600" />
          <div className="text-sm text-gray-600">Apply a template</div>
        </MenuItem>
        {!!onSaveAsTemplate && (
          <MenuItem
            className="flex items-center gap-2"
            onClick={() => {
              if (
                noteEditorStore.currentDocumentEditor &&
                NoteEditor.sp_isEditorEmpty(noteEditorStore.currentDocumentEditor)
              ) {
                openInformationalModal('The document is empty, please save template with non-empty document.');
              } else {
                setIsMenuOpen(false);
                onSaveAsTemplate();
              }
            }}
          >
            <DocumentPlusIcon className="w-5 h-5 text-gray-600" />
            <div className="text-sm text-gray-600">Save as template</div>
          </MenuItem>
        )}
        {showDelete && (
          <MenuItem
            className="flex items-center gap-2"
            onClick={() => {
              setIsMenuOpen(false);
              modalStore.setState({
                isOpen: true,
                element: <DeleteDocumentModal documentId={document.id} navigateTo="/" />,
              });
            }}
            disabled={!!document.event}
          >
            <TrashIcon className="w-5 h-5 text-gray-600" />
            <div className="text-sm text-gray-600">Delete Document</div>
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
DocumentMoreActionBtn.defaultProps = {
  className: '',
  showDelete: false,
  onApplyTemplate: undefined,
  onSaveAsTemplate: undefined,
};

export default DocumentMoreActionBtn;
