import React from 'react';

interface Props {
  className?: string;
}

function TemplateManagementIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8125 3.00001H8.5625C8.14978 2.99842 7.75332 3.16081 7.4603 3.45148C7.16729 3.74214 7.00172 4.13729 7 4.55001V16.95C7.00172 17.3627 7.16729 17.7579 7.4603 18.0485C7.75332 18.3392 8.14978 18.5016 8.5625 18.5H17.9375C18.3502 18.5016 18.7467 18.3392 19.0397 18.0485C19.3327 17.7579 19.4983 17.3627 19.5 16.95V7.65001L14.8125 3.00001Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8125 3V7.65H19.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.375 11.5254H10.125"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.375 14.625H10.125"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6875 8.4248H10.125"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7429 21.7429H5.65272M4 20.0902V11"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3 3"
      />
    </svg>
  );
}

TemplateManagementIcon.defaultProps = {
  className: undefined,
};

export default TemplateManagementIcon;
