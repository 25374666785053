import { noop } from 'lodash';

export function getLocalstorageItem<T>(key: string, defaultVal: T, isObj = true): T {
  let obj = defaultVal;
  try {
    const item = localStorage.getItem(key) ?? '';
    obj = isObj ? JSON.parse(item) : item;
  } catch {
    noop();
  }
  return obj || defaultVal;
}

export function setLocalstorageItem<T>(key: string, val: T, isObj = true) {
  localStorage.setItem(key, isObj ? JSON.stringify(val) : `${val}`);
}

export async function clearAllIndexedDB() {
  const dbs = await window.indexedDB.databases();
  for (let i = 0; i < dbs.length; i += 1) {
    const db = dbs[i];
    if (db.name) {
      window.indexedDB.deleteDatabase(db.name);
    }
  }
}
