import { Member } from 'types/member';

export function getUserShortName(name: string, charCount = 2) {
  const shortName = name.split(' ').map(item => item[0]);
  return shortName.slice(0, charCount);
}

export function capitalizeName(name: string) {
  return name
    .split(' ')
    .map(str => str.charAt(0).toUpperCase() + str.slice(1))
    .join(' ');
}

export function getMemberName(member?: Member) {
  return member ? capitalizeName(`${member.firstName} ${member.lastName}`) : '';
}
