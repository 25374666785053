import React from 'react';
import FieldSettingIcon from '../../components/icons/FieldSettingIcon';
import { Field } from '../../types/field-value';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import ConfigureFieldButtonPopover from './ConfigureFieldButtonPopover';

interface Props {
  documentId: string;
  fields: Field[];
}

function ConfigureFieldView({ documentId, fields }: Props) {
  return (
    <div className="border rounded p-5 flex justify-center">
      <div className="text-center max-w-lg">
        <div className="flex justify-center">
          <div className="w-14 h-14 p-2 rounded-lg bg-blue-50">
            <FieldSettingIcon className="w-10 h-10 text-blue-500" />
          </div>
        </div>
        <div className="mt-2 font-medium text-gray-900">Configure your sales engineer field</div>
        <div className="mt-1 text-gray-500 text-sm">
          Please select a field that indicates a sales engineer field. This will allow Superpanel show the appropriate
          team members here.
        </div>
        <div className="mt-6">
          <ConfigureFieldButtonPopover
            btnClassName="!px-6 !py-5"
            btnVariant="contained"
            documentId={documentId}
            selectedId={null}
            fields={fields}
          >
            Configure Field
            <ChevronDownIcon className="w-5 h-5 ml-2" />
          </ConfigureFieldButtonPopover>
        </div>
      </div>
    </div>
  );
}

export default ConfigureFieldView;
