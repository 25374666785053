/* eslint-disable react/no-array-index-key, jsx-a11y/no-static-element-interactions */
import React, { useMemo, useRef, useState } from 'react';
import { ArrowDownIcon, ArrowUpIcon, ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { ColumnDefinition, ColumnFilter, DraggingColumnInfo } from '../types';
import ResizeBorderHandle from '../ResizeBorderHandle';
import ThreeVerticalDotsIcon from '../../icons/ThreeVerticalDotsIcon';
import Popover from '../../Popover';
import FilterIcon from '../../icons/FilterIcon';
import ColumnMenus from './ColumnMenus';
import FieldFilterPopover from '../FieldFilterPopover';
import { concat } from 'utils/styling';
import styles from './Column.module.css';
import { stringifyFilter } from '../utils';
import Tooltip from 'components/Tooltip';
import BaseButton from 'components/BaseButton';

interface Props {
  columnDefinition: ColumnDefinition;
  onColumnHide: () => void;
  onSortUpdate: (sort?: 'ASC' | 'DESC' | undefined) => void;
  onFilterUpdate: (filter?: ColumnFilter) => void;
  onWidthUpdate: (width: number) => void;
  isGroupWidth: boolean;
  isEven: boolean;
  onDraggingMouseDown: (event: React.MouseEvent<Element, MouseEvent>) => void;
  setDraggingItem: React.Dispatch<DraggingColumnInfo>;
  onColumnPin: () => void;
  className?: string;
}

function Column({
  columnDefinition,
  onColumnHide,
  onSortUpdate,
  onFilterUpdate,
  onWidthUpdate,
  isGroupWidth,
  isEven,
  onDraggingMouseDown,
  setDraggingItem,
  onColumnPin,
  className,
}: Props) {
  const [menuState, setMenuState] = useState<'MENU' | 'FILTER' | 'HIDE'>('HIDE');
  const buttonRef = useRef<HTMLButtonElement>(null);
  const filterStr = useMemo(
    () => stringifyFilter(columnDefinition.filter, columnDefinition.data?.dataEntryType || columnDefinition.type),
    [columnDefinition.filter, columnDefinition.data?.dataEntryType],
  );
  return (
    <>
      <div
        className={concat(
          'group relative flex font-semibold cursor-default items-center pr-4 min-h-[36px] border-r border-b border-gray-300 flex-shrink-0 flex-grow-0 hover:bg-gray-200',
          !isGroupWidth && 'relative',
          isEven ? 'bg-gray-50' : 'bg-gray-100',
          menuState === 'MENU' && 'bg-gray-200',
          className,
        )}
        style={{ width: columnDefinition.width }}
        onMouseDown={e => {
          onDraggingMouseDown(e);
          setDraggingItem({ colId: columnDefinition.id, subColId: '', targetColName: columnDefinition.name });
        }}
      >
        <ResizeBorderHandle width={columnDefinition.width} setWidth={width => onWidthUpdate(width)} />
        <span className={concat('py-1 pr-2 pl-4 h-full flex items-center select-none')}>
          {columnDefinition.name}{' '}
          {columnDefinition.type === 'ReportCalculatedField' && (
            <Tooltip title={<div className="">Data can have a short delay</div>} placement="top">
              <ExclamationTriangleIcon className="ml-1 w-4 h-4 text-yellow-500" />
            </Tooltip>
          )}
        </span>
        {!!columnDefinition.sort && (
          <BaseButton
            tooltip={columnDefinition.sort === 'ASC' ? 'Sort ascending' : 'Sort descending'}
            color="secondary"
            variant="text"
            iconBtn
            onClick={() => onSortUpdate(columnDefinition.sort === 'ASC' ? 'DESC' : undefined)}
          >
            {columnDefinition.sort === 'ASC' && (
              <ArrowUpIcon className="w-4 h-4 text-green-500 flex-shrink-0 flex-grow-0" />
            )}
            {columnDefinition.sort === 'DESC' && (
              <ArrowDownIcon className="w-4 h-4 text-green-500 flex-shrink-0 flex-grow-0" />
            )}
          </BaseButton>
        )}
        {!!columnDefinition.filter && (
          <div className="flex items-center truncate pr-4 h-full">
            <Tooltip
              title={
                filterStr ? (
                  <div>
                    <strong>Filter:&nbsp;</strong>
                    {filterStr}
                  </div>
                ) : undefined
              }
              placement="top"
            >
              <div className="flex items-center truncate">
                <FilterIcon className="w-4 h-4 text-green-500 flex-shrink-0 flex-grow-0 ml-1" />
                <span className="truncate text-xs text-gray-400 whitespace-nowrap">{filterStr}</span>
              </div>
            </Tooltip>
            <BaseButton
              tooltip="Clear filter"
              color="secondary"
              variant="text"
              iconBtn
              onClick={() => onFilterUpdate()}
            >
              <XMarkIcon width={16} />
            </BaseButton>
          </div>
        )}
        <div
          className={`absolute top-1/2 right-2 -translate-y-1/2 group-hover:block ${
            menuState !== 'HIDE' ? 'block' : 'hidden'
          }`}
        >
          <button
            ref={buttonRef}
            type="button"
            className="flex items-center justify-center w-6 h-6 p-0.5 rounded bg-white hover:bg-gray-300"
            onClick={() => setMenuState('MENU')}
          >
            <ThreeVerticalDotsIcon className="w-4 h-4" />
          </button>
        </div>
      </div>
      <Popover
        anchorEl={buttonRef.current}
        onClose={() => setMenuState('HIDE')}
        isOpen={menuState === 'MENU'}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <div className={concat('w-64 px-1 py-2', styles.colMenuWrap)}>
          <ColumnMenus
            columnDefinition={columnDefinition}
            onSortUpdate={sort => {
              onSortUpdate(sort);
              setMenuState('HIDE');
            }}
            onColumnHide={onColumnHide}
            setMenuState={setMenuState}
            onFilterUpdate={onFilterUpdate}
            onColumnPin={() => {
              onColumnPin();
              setMenuState('HIDE');
            }}
          />
        </div>
      </Popover>
      <FieldFilterPopover
        anchorEl={buttonRef.current}
        onClose={() => setMenuState('HIDE')}
        isOpen={menuState === 'FILTER'}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        columnDataType={columnDefinition.type}
        name={columnDefinition.name}
        filter={columnDefinition.filter}
        onFilterUpdate={onFilterUpdate}
        data={columnDefinition.data}
      />
    </>
  );
}

Column.defaultProps = {
  className: '',
};

export default Column;
