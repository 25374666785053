/* eslint-disable no-else-return, no-nested-ternary */
import { PopoverOrigin, PopoverProps } from '@mui/material';

const POPOVER_MENU_MARGIN_THRESHOLD = 16;
const POPOVER_MENU_MIN_HEIGHT = 100;
const POPOVER_MENU_ANCHOR_OFFSET = 8;

export type CustomPopoverInfo = Partial<
  Pick<
    PopoverProps,
    'anchorOrigin' | 'transformOrigin' | 'anchorReference' | 'anchorPosition' | 'marginThreshold' | 'style'
  >
>;

/**
 * calc popover info to not overlap with anchor element,
 * auto flip down or up, and has space with document body
 */
function getPopoverInfo(
  anchorEl: Element | null,
  horizontalOrigin?: 'left' | 'right',
  maxHeight?: number,
  width?: number,
  anchorOffset?: number,
  minWidth?: number,
  anchorPosition?: boolean,
): CustomPopoverInfo {
  if (anchorEl) {
    const rect = anchorEl.getBoundingClientRect();
    const parentRect = document.body.getBoundingClientRect();
    const popoverWidth =
      width !== undefined || minWidth !== undefined ? Math.max(width || 0, minWidth || 0) : undefined;
    const popoverHorizontalOrigin =
      rect.left + (popoverWidth || rect.width) + POPOVER_MENU_MARGIN_THRESHOLD > parentRect.width
        ? 'right'
        : rect.right - (popoverWidth || rect.width) - POPOVER_MENU_MARGIN_THRESHOLD < 0
        ? 'left'
        : horizontalOrigin || 'left';
    if (rect.bottom < parentRect.height * 0.6) {
      const top = rect.bottom + (typeof anchorOffset === 'number' ? anchorOffset : POPOVER_MENU_ANCHOR_OFFSET);
      return {
        anchorOrigin: anchorPosition
          ? undefined
          : ({ vertical: 'bottom', horizontal: popoverHorizontalOrigin } as PopoverOrigin),
        transformOrigin: anchorPosition
          ? undefined
          : ({ vertical: 'top', horizontal: popoverHorizontalOrigin } as PopoverOrigin),
        anchorReference: anchorPosition ? 'anchorPosition' : 'anchorEl',
        anchorPosition: anchorPosition
          ? { top, left: popoverHorizontalOrigin === 'left' ? rect.left : rect.right - (popoverWidth || rect.width) }
          : undefined,
        marginThreshold: -10000,
        style: {
          maxHeight: Math.min(
            Math.max(parentRect.height - rect.bottom - POPOVER_MENU_MARGIN_THRESHOLD, POPOVER_MENU_MIN_HEIGHT),
            maxHeight || Number.MAX_VALUE,
          ),
          width: popoverWidth,
        },
      };
    } else {
      const top = rect.top - (typeof anchorOffset === 'number' ? anchorOffset : POPOVER_MENU_ANCHOR_OFFSET);
      return {
        anchorOrigin: anchorPosition
          ? undefined
          : ({ vertical: 'top', horizontal: popoverHorizontalOrigin } as PopoverOrigin),
        transformOrigin: anchorPosition
          ? undefined
          : ({ vertical: 'bottom', horizontal: popoverHorizontalOrigin } as PopoverOrigin),
        anchorReference: anchorPosition ? 'anchorPosition' : 'anchorEl',
        anchorPosition: anchorPosition
          ? { top, left: popoverHorizontalOrigin === 'left' ? rect.left : rect.right - (popoverWidth || rect.width) }
          : undefined,
        marginThreshold: -10000,
        style: {
          maxHeight: Math.min(
            Math.max(top - POPOVER_MENU_MARGIN_THRESHOLD, POPOVER_MENU_MIN_HEIGHT),
            maxHeight || Number.MAX_VALUE,
          ),
          width: popoverWidth,
          transform: anchorPosition ? 'translateY(-100%)' : '',
        },
      };
    }
  } else {
    return {};
  }
}

export default getPopoverInfo;
