import React from 'react';

interface Props {
  className?: string;
}
function CategoryIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.7 6H4.8C4.08203 6 3.5 6.58203 3.5 7.3V17.2C3.5 17.918 4.08203 18.5 4.8 18.5H19.7C20.418 18.5 21 17.918 21 17.2V7.3C21 6.58203 20.418 6 19.7 6Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 14.25H18.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.75 15C7.16421 15 7.5 14.6642 7.5 14.25C7.5 13.8358 7.16421 13.5 6.75 13.5C6.33579 13.5 6 13.8358 6 14.25C6 14.6642 6.33579 15 6.75 15Z"
        fill="currentColor"
      />
      <path
        d="M6.75 11C7.16421 11 7.5 10.6642 7.5 10.25C7.5 9.83579 7.16421 9.5 6.75 9.5C6.33579 9.5 6 9.83579 6 10.25C6 10.6642 6.33579 11 6.75 11Z"
        fill="currentColor"
      />
      <path
        d="M10 10.25H18.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

CategoryIcon.defaultProps = {
  className: undefined,
};

export default CategoryIcon;
