import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMatch } from 'react-router-dom';
import MuiModal from '@mui/material/Modal';
import { PlusIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { withReact } from 'slate-react';
import { createEditor } from 'slate';

import { NoteTemplateInEdit, Template as TemplateApi } from 'api/note-templates/get-note-templates';

import XIcon from 'components/icons/XIcon';
import NoteTemplateElement from 'components/NoteTemplateElement';
import EditorTemplate from 'components/EditorTemplate';
import Plugins from 'components/NoteEditor/Plugins';
import SlashCommandMenus from 'components/NoteEditor/Plugins/SlashCommnadPlugin/SlashCommandMenus';
import withInlines from 'components/NoteEditor/EditorContext/withInlines';
import { BlockType, NoteTemplateType, CustomElement } from 'components/NoteEditor/types';
import { createNode } from 'components/NoteEditor/utils';
import LinkBrokenIcon from 'components/icons/LinkBrokenIcon';
import EmojiPickerComp from 'components/EmojiPicker';

import useDocumentStore from 'stores/document';
import useModalStore from 'stores/modal';
import useNoteEditorStore from 'stores/note-editor';

import isDisableApply from 'utils/is-disable-template-apply';
import useTemplateStore from 'stores/templates';
import useUserStore from 'stores/user';
import ResponseView from './ResponseView';
import useFieldValuesStore from '../stores/field-values';
import EmptySearchIcon from './icons/EmptySearchIcon';
import EmptyResponseIcon from './icons/EmptyResponseIcon';
import LoaderTransparentBackground from './LoaderTransparentBackground';
import { openWarningConfirmModal } from './BaseModal';
import { concat } from 'utils/styling';
import { Document } from 'types/document';
import NoteEditor from './NoteEditor/NoteEditor';

interface State {
  isLoading: boolean;
  mainBodyMode: 'list' | 'editor-view-only' | 'editor-edit';
  template: null | NoteTemplateInEdit;
  searchText: string;
  discardChangesPopup: boolean;
  missingTitlePopup: boolean;
  iconPickerOpen: boolean;
}

interface Props {
  document?: Document;
}

function ManageTemplateModal({ document: documentInput }: Props) {
  const [state, setState] = useState<State>({
    isLoading: false,
    mainBodyMode: 'list',
    template: null,
    searchText: '',
    discardChangesPopup: false,
    missingTitlePopup: false,
    iconPickerOpen: false,
  });

  const match = useMatch({ path: '/document/:documentId' });
  const documentId = useMemo(() => match?.params?.documentId, [match]);

  const documentStore = useDocumentStore();
  const userStore = useUserStore();
  const templateStore = useTemplateStore();
  const noteEditorStore = useNoteEditorStore();
  const modalStore = useModalStore();
  const fieldValuesStore = useFieldValuesStore();
  const currentTemplateContentRef = useRef<CustomElement[] | null>(null);

  const document = useMemo(
    () => documentInput || documentStore.documents.find(doc => doc.id === documentId),
    [documentInput, documentId, documentStore.documents],
  );

  const editor = useMemo(() => {
    if (state.template) {
      return withInlines(withReact(createEditor()), 'template', '', '', true);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.template]);

  const plugins = useMemo(() => {
    if (state.mainBodyMode === 'editor-view-only') {
      return [];
    }
    const slashCommand = [
      new SlashCommandMenus.DiscoveryQuestion(),
      new SlashCommandMenus.AIWorkerElement(),
      new SlashCommandMenus.Paragraph(),
      new SlashCommandMenus.TaskElement(),
      new SlashCommandMenus.Heading1(),
      new SlashCommandMenus.Heading2(),
      new SlashCommandMenus.OrderedList(),
      new SlashCommandMenus.BulletedList(),
      new SlashCommandMenus.ImageElement(),
      new SlashCommandMenus.TableElement(),
    ];
    return [
      new Plugins.HotKey(),
      new Plugins.SlashCommand(slashCommand),
      new Plugins.EmojiCommand(),
      new Plugins.EnterKey(),
      new Plugins.BackSpace(),
      new Plugins.ItemList(),
      new Plugins.Image(),
      new Plugins.Copy(),
      new Plugins.Paste(),
    ];
  }, [state.mainBodyMode]);

  const onModifyTemplate = useCallback((newTemplateContent: CustomElement[]) => {
    currentTemplateContentRef.current = newTemplateContent;
  }, []);

  const fetchTemplates = async () => {
    setState(prevState => ({ ...prevState, isLoading: true }));
    await templateStore.fetch();
    setState(prevState => ({ ...prevState, isLoading: false }));
  };

  useEffect(() => {
    if (state.mainBodyMode === 'list') {
      fetchTemplates();
    }
  }, [state.mainBodyMode]);

  const onCreateTemplate = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      mainBodyMode: 'editor-edit',
      template: {
        noteTemplateId: null,
        name: '',
        data: { children: [createNode(BlockType.Paragraph)] },
        icon: null,
        isMeta: false,
      },
    }));
  }, []);

  const templatePicked = templateStore.templates.find(t => {
    if (state.template) {
      return t.noteTemplateId === state.template.noteTemplateId;
    }
    return false;
  });

  let disableApply = false;
  if (templatePicked) {
    disableApply = isDisableApply(templatePicked, document);
  }

  const applyTemplateToDocument = async (type: NoteTemplateType, id: string) => {
    if (document) {
      const teamplateData = await documentStore.checkAndGetTemplate(document.id, type, id);
      noteEditorStore.setState({
        templateToApply: teamplateData,
        templateToApplyDocumentId: documentInput?.id || documentId,
      });
      // Below is method to trigger AI processing
      await fieldValuesStore.fetchWithAITrigger(document.id);
      modalStore.close();
    }
  };

  const templateElements: JSX.Element[] = [];
  templateStore.templates.forEach(t => {
    // template name contains search text
    if (t.name.toLowerCase().includes(state.searchText.toLowerCase())) {
      const disableApplyMini = isDisableApply(t, document);

      templateElements.push(
        <NoteTemplateElement
          key={t.noteTemplateId}
          template={t}
          disableApply={disableApplyMini}
          showApply={!!document}
          onEdit={(temp: TemplateApi) => {
            setState(prevState => ({
              ...prevState,
              mainBodyMode: 'editor-edit',
              template: {
                noteTemplateId: temp.noteTemplateId,
                name: temp.name,
                data: temp.data,
                icon: temp.icon,
                isMeta: temp.isMeta,
              },
            }));
          }}
          onPreview={(temp: TemplateApi) => {
            setState(prevState => ({
              ...prevState,
              mainBodyMode: 'editor-view-only',
              template: {
                noteTemplateId: temp.noteTemplateId,
                name: temp.name,
                data: temp.data,
                icon: temp.icon,
                isMeta: temp.isMeta,
              },
            }));
          }}
          onApply={async () => {
            if (
              noteEditorStore.currentDocumentEditor &&
              !NoteEditor.sp_isEditorEmpty(noteEditorStore.currentDocumentEditor)
            ) {
              const confirmed = await openWarningConfirmModal(
                'Document will be cleared!',
                'Current document is not empty, apply a template will clear current document content.',
              );
              if (!confirmed) {
                return;
              }
            }
            await applyTemplateToDocument(NoteTemplateType.CUSTOM, t.noteTemplateId);
          }}
          onDuplicate={() => {
            setState(prevState => ({
              ...prevState,
              mainBodyMode: 'editor-edit',
              template: {
                noteTemplateId: null,
                name: 'COPY OF'.concat(' ', t.name),
                data: t.data,
                icon: t.icon,
                isMeta: false,
              },
            }));
          }}
          onDelete={async () => {
            setState(prevState => ({ ...prevState, isLoading: true }));
            await templateStore.delete(t.noteTemplateId);
            setState(prevState => ({ ...prevState, isLoading: false }));
          }}
          onMetaToggle={() => {
            return templateStore.update(t.noteTemplateId, t.name, t.data, t.icon, !t.isMeta);
          }}
          isAdmin={!!userStore.user?.isAdmin}
        />,
      );
    }
  });

  let rightButton: null | JSX.Element = null;
  let leftButton: null | JSX.Element = null;
  let input: null | JSX.Element = null;
  if (state.template) {
    input = (
      <div className="flex items-center w-full">
        <div className="mx-1">
          <EmojiPickerComp
            onAddEmoji={emojiCode =>
              setState(prevState => {
                if (prevState.template) {
                  return {
                    ...prevState,
                    iconPickerOpen: false,
                    template: { ...prevState.template, icon: emojiCode },
                  };
                }
                return {
                  ...prevState,
                  iconPickerOpen: false,
                };
              })
            }
            currentIcon={state.template.icon}
            readOnly={state.mainBodyMode === 'editor-view-only'}
          />
        </div>

        <input
          type="text"
          className="placeholder grow text-lg font-medium placeholder:text-gray-400 truncate"
          value={state.template.name}
          onChange={e => {
            setState(prevState => {
              if (prevState.template) {
                return {
                  ...prevState,
                  template: { ...prevState.template, name: e.target.value },
                };
              }
              return prevState;
            });
          }}
          placeholder="Untitled Template"
          disabled={state.mainBodyMode === 'editor-view-only'}
        />
        {(state.mainBodyMode === 'editor-view-only' || state.mainBodyMode === 'editor-edit') && (
          <div
            className={concat(
              'whitespace-nowrap text-xs font-semibold ml-4 px-2 py-1 rounded-full animate-fade-in-down',
              state.mainBodyMode === 'editor-view-only' ? 'bg-blue-50 text-blue-500' : 'bg-orange-50 text-orange-500',
            )}
          >
            {state.mainBodyMode === 'editor-view-only' ? 'Preview Mode' : 'Edit Mode'}
          </div>
        )}
      </div>
    );
  }

  if (state.mainBodyMode === 'editor-view-only') {
    rightButton = (
      <div className="flex items-center gap-3">
        <button
          type="button"
          className="flex items-center px-3 py-2 rounded text-xs  border-blue-500 border text-blue-500"
          onClick={() => {
            setState(prevState => ({
              ...prevState,
              mainBodyMode: 'editor-edit',
            }));
          }}
        >
          Edit
        </button>
        {!!document && (
          <button
            type="button"
            className={`flex items-center px-3 py-2 text-xs  rounded ${
              disableApply ? 'bg-gray-200 text-gray-500 cursor-default' : 'bg-blue-500 text-white'
            }`}
            onClick={async () => {
              if (state.template && state.template.noteTemplateId) {
                if (
                  noteEditorStore.currentDocumentEditor &&
                  !NoteEditor.sp_isEditorEmpty(noteEditorStore.currentDocumentEditor)
                ) {
                  const confirmed = await openWarningConfirmModal(
                    'Document will be cleared!',
                    'Current document is not empty, apply a template will clear current document content.',
                  );
                  if (!confirmed) {
                    return;
                  }
                }
                await applyTemplateToDocument(NoteTemplateType.CUSTOM, state.template.noteTemplateId);
              }
            }}
            disabled={disableApply}
          >
            Apply Template
          </button>
        )}
      </div>
    );
    leftButton = (
      <div className="flex items-center flex-1">
        <button
          type="button"
          className="flex items-center bg-slate-200 px-3 py-2 rounded text-xs text-slate-600"
          onClick={() => {
            setState(prevState => ({ ...prevState, mainBodyMode: 'list', selectedTemplateId: null, template: null }));
          }}
        >
          Back
        </button>
        {input}
      </div>
    );
  } else {
    rightButton = (
      <div className="flex items-center gap-3">
        <button
          type="button"
          className="flex items-center px-3 py-2 rounded text-xs text-slate-500 bg-slate-200"
          onClick={() => {
            setState(prevState => ({ ...prevState, discardChangesPopup: true }));
          }}
        >
          Discard
        </button>
        <button
          type="button"
          className="flex items-center px-3 py-2 text-xs bg-blue-500 text-white rounded"
          onClick={async () => {
            if (state.template === null || state.template.name === '') {
              setState(prevState => ({ ...prevState, missingTitlePopup: true }));
            } else if (state.template && state.template.noteTemplateId !== null) {
              try {
                setState(prevState => ({ ...prevState, isLoading: true }));
                // update existing template
                await templateStore.update(
                  state.template.noteTemplateId,
                  state.template.name,
                  currentTemplateContentRef.current
                    ? { children: currentTemplateContentRef.current }
                    : state.template.data,
                  state.template.icon,
                  state.template.isMeta,
                );
                setState(prevState => ({ ...prevState, isLoading: false, mainBodyMode: 'list', template: null }));
              } catch {
                setState(prevState => ({ ...prevState, isLoading: false }));
              }
            }
            // create new template
            else if (state.template && state.template.noteTemplateId === null) {
              try {
                setState(prevState => ({ ...prevState, isLoading: true }));
                await templateStore.create(
                  state.template.name,
                  currentTemplateContentRef.current
                    ? { children: currentTemplateContentRef.current }
                    : state.template.data,
                  state.template.icon,
                  'template',
                  false,
                );
                setState(prevState => ({ ...prevState, isLoading: false, mainBodyMode: 'list', template: null }));
              } catch {
                setState(prevState => ({ ...prevState, isLoading: false }));
              }
            }
          }}
        >
          Save changes
        </button>
      </div>
    );
    leftButton = <div className="flex items-center flex-1">{input}</div>;
  }

  return (
    <div className="w-[80vw] h-[80vh]">
      {/* header */}
      <div className="flex h-[4rem] p-4 justify-between items-center border-b border-gray-200">
        <div className="text-lg font-medium">Browse Templates</div>
        <button type="button" className="flex bg-gray-200 rounded-lg p-2" onClick={modalStore.close}>
          <XIcon className="w-3 h-3 text-gray-500" />
        </button>
      </div>
      <div className="flex felx-col w-full" style={{ height: `calc(100% - 4rem)` }}>
        {/* menu bar */}
        <div className="flex flex-col justify-between px-4 py-3 w-1/4 h-full border-r border-gray-200">
          <div className="flex flex-col">
            {/* search bar */}
            <div className="flex items-center p-1 pl-2 border rounded-lg border-blue-400">
              <input
                type="text"
                className="w-full text-sm placeholder:text-gray-400"
                placeholder="Search templates"
                value={state.searchText}
                onChange={e => setState(prevState => ({ ...prevState, searchText: e.target.value }))}
              />
            </div>
          </div>
          <button type="button" className="hover:bg-gray-100 rounded p-1" onClick={onCreateTemplate}>
            <div className="flex items-center gap-2 text-gray-400 text-sm">
              <PlusIcon className="w-5 h-5 text-gray-400" />
              Create a Template
            </div>
          </button>
        </div>
        {/* main body */}
        <div className="w-3/4 h-full overflow-y-auto">
          {(!state.template || !editor) && (
            <ResponseView
              loading={state.isLoading}
              noData={!templateElements.length}
              noDataIcon={state.searchText ? <EmptySearchIcon /> : <EmptyResponseIcon />}
              noDataMsg={
                state.searchText
                  ? `We couldn't find any results for\n"${state.searchText}".`
                  : 'You do not have any templates... yet'
              }
              noDataDesc={
                state.searchText
                  ? 'You may want to try using different keywords or\nchecking for typos.'
                  : 'Click on the button below to create one.'
              }
              noDataBtnLabel={state.searchText ? '' : 'Create Template'}
              onButtonClick={onCreateTemplate}
            >
              {state.mainBodyMode === 'list' && (
                <div className="flex flex-wrap pt-3 ml-3 gap-4">{templateElements}</div>
              )}
            </ResponseView>
          )}
          {state.isLoading && state.template && editor && (
            <LoaderTransparentBackground text="Saving templates, please wait" />
          )}
          {!state.isLoading &&
            !!state.template &&
            !!editor &&
            (state.mainBodyMode === 'editor-view-only' || state.mainBodyMode === 'editor-edit') && (
              <div className="flex flex-col w-full h-full">
                {/* template header */}
                <div className="w-full px-3 flex justify-between border-b border-gray-200 py-3 gap-6">
                  {/* back button and title */}
                  {leftButton}
                  {/* edit and apply buttons */}
                  {rightButton}
                </div>
                <div className="w-full flex-1 max-h-full h-1">
                  <EditorTemplate
                    template={state.template}
                    onModifyTemplate={onModifyTemplate}
                    plugins={plugins}
                    key={state.mainBodyMode}
                    readOnly={state.mainBodyMode === 'editor-view-only'}
                  />
                </div>
              </div>
            )}
        </div>
      </div>
      <MuiModal
        open={state.discardChangesPopup}
        onClose={() => {
          setState(prevState => ({
            ...prevState,
            discardChangesPopup: false,
          }));
        }}
      >
        <div
          className="absolute left-1/2 bg-white rounded-lg"
          style={{
            top: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          {/* model header */}
          <div className="flex justify-between items-center p-4 w-80">
            <TrashIcon className="w-9 h-9 text-orange-500 rounded-full p-2 bg-orange-100" />
            <button
              type="button"
              onClick={() => {
                setState(prevState => ({ ...prevState, discardChangesPopup: false }));
              }}
            >
              <XMarkIcon className="w-5 h-5 hover:bg-gray-100 hover:cursor-pointer rounded" />
            </button>
          </div>
          {/* model body */}
          <p className="px-3 font-medium">Discard changes?</p>
          <p className="px-3 text-gray-500 text-sm">Are you sure you want to discard all changes?</p>
          {/* model footer */}
          <div className="flex items-center justify-end gap-3 mt-5 mb-3 mr-3">
            <button
              type="button"
              className="flex items-center px-3 py-2 rounded text-xs text-slate-600 bg-slate-200"
              onClick={() => {
                setState(prevState => ({ ...prevState, discardChangesPopup: false }));
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="flex items-center px-3 py-2 text-xs bg-red-500 text-white rounded"
              onClick={() => {
                setState(prevState => {
                  // discard changes for existing template
                  if (prevState.template !== null && prevState.template.noteTemplateId) {
                    const oldTempId = prevState.template.noteTemplateId;
                    const oldTemp = templateStore.templates.find(t => t.noteTemplateId === oldTempId);
                    if (oldTemp) {
                      return {
                        ...prevState,
                        discardChangesPopup: false,
                        mainBodyMode: 'editor-view-only',
                        template: {
                          noteTemplateId: oldTemp.noteTemplateId,
                          name: oldTemp.name,
                          data: oldTemp.data,
                          icon: oldTemp.icon,
                          isMeta: oldTemp.isMeta,
                        },
                      };
                    }
                  }
                  // discard changes for new template
                  else {
                    return {
                      ...prevState,
                      discardChangesPopup: false,
                      mainBodyMode: 'list',
                      template: null,
                    };
                  }
                  return prevState;
                });
              }}
            >
              Discard
            </button>
          </div>
        </div>
      </MuiModal>
      <MuiModal
        open={state.missingTitlePopup}
        onClose={() => {
          setState(prevState => ({
            ...prevState,
            missingTitlePopup: false,
          }));
        }}
      >
        <div
          className="absolute left-1/2 bg-white rounded-lg"
          style={{
            top: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          {/* model header */}
          <div className="flex justify-between items-center p-4 w-80">
            <div className="w-10 h-10">
              <LinkBrokenIcon className="w-10 h-10 text-orange-500 stroke-2" />
            </div>
            <button
              type="button"
              onClick={() => {
                setState(prevState => ({ ...prevState, missingTitlePopup: false }));
              }}
            >
              <XMarkIcon className="w-5 h-5 hover:bg-gray-100 hover:cursor-pointer rounded" />
            </button>
          </div>
          <div className="ml-4 font-medium mb-5 text-lg">Please add template title</div>
        </div>
      </MuiModal>
    </div>
  );
}

ManageTemplateModal.defaultProps = {
  document: undefined,
};

export default ManageTemplateModal;
