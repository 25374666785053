/* eslint-disable consistent-return */
import { MentionElement, MentionPopupResponseType } from 'components/NoteEditor/types';
import PopoverMenuList from 'components/PopoverMenuList';
import React, { useEffect, useMemo } from 'react';
import useNoteEditorStore from 'stores/note-editor';
import useUserStore from 'stores/user';
import { ListItem } from 'types/list-item';
import { Member } from 'types/member';
import { getMemberName } from 'utils/string';
import CommentUserAvatar from '../Comment/CommentUserAvatar';
import isHotkey from 'is-hotkey';
import BaseButton from 'components/BaseButton';

function MentionPopover() {
  const userStore = useUserStore();
  const noteEditorStore = useNoteEditorStore();
  const memberMenus = useMemo(
    () =>
      userStore.members?.map(member => ({
        icon: <CommentUserAvatar member={member} hideTooltip hideName />,
        label: getMemberName(member),
        value: member,
      })),
    [userStore.members],
  );

  useEffect(() => {
    if (noteEditorStore.mentionContext) {
      const onKeyDown = (event: KeyboardEvent) => {
        const isForwardDel = isHotkey('delete', event);
        const isBackwardDel = isHotkey('backspace', event);
        if (isForwardDel || isBackwardDel) {
          event.preventDefault();
          noteEditorStore.mentionContext?.onClose(null);
          noteEditorStore.setState({ mentionContext: null });
        }
      };
      document.addEventListener('keydown', onKeyDown);
      return () => {
        document.removeEventListener('keydown', onKeyDown);
      };
    }
  }, [noteEditorStore.mentionContext]);

  return (
    <PopoverMenuList
      isOpen={!!noteEditorStore.mentionContext}
      onClose={() => {
        noteEditorStore.mentionContext?.onClose(undefined);
        noteEditorStore.setState({ mentionContext: null });
      }}
      anchorEl={noteEditorStore.mentionContext?.ref || null}
      menus={memberMenus}
      handleMenuClick={(menu: ListItem<Member>) => {
        noteEditorStore.mentionContext?.onClose(menu.value);
      }}
      menusLoading={userStore.membersLoading}
      maxHeight={260}
      width={320}
      disableAutoFocus
      noMenusLabel={userStore.membersLoadError || undefined}
      extraElement={
        noteEditorStore.mentionContext?.clearable ? (
          <BaseButton
            onClick={() => {
              noteEditorStore.mentionContext?.onClose(null);
              noteEditorStore.setState({ mentionContext: null });
            }}
            color="primary"
            variant="text"
            className="!px-2 !mb-1 !-mt-2 !mr-4 self-end !text-sm"
          >
            CLEAR
          </BaseButton>
        ) : undefined
      }
    />
  );
}

export const openMentionPopover = (mentionElement: Element, clearable = false): Promise<MentionPopupResponseType> => {
  return new Promise<MentionPopupResponseType>(resolve => {
    useNoteEditorStore.setState({
      mentionContext: {
        ref: mentionElement,
        clearable,
        onClose: rsp => resolve(rsp),
      },
    });
  });
};

export default MentionPopover;
