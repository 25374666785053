import React from 'react';
import PopoverMui, { PopoverOrigin, PopoverReference } from '@mui/material/Popover';
import { PaperProps } from '@mui/material';

export interface Props {
  isOpen: boolean;
  onClose: () => void;
  anchorEl?: Element | null;
  children: React.ReactNode;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  anchorReference?: PopoverReference;
  anchorPosition?: { top: number; left: number };
  disableAutoFocus?: boolean;
  disableRestoreFocus?: boolean;
  stopPropagationWhenClick?: boolean;
  onPopoverClick?: (e: React.MouseEvent) => void;
  onPopoverMouseUp?: (e: React.MouseEvent) => void;
  onPopoverMouseDown?: (e: React.MouseEvent) => void;
  paperProps?: Partial<PaperProps>;
  marginThreshold?: number;
  hideBackdrop?: boolean;
}

function Popover({
  isOpen,
  onClose,
  anchorEl,
  children,
  anchorOrigin,
  transformOrigin,
  anchorReference,
  anchorPosition,
  disableAutoFocus,
  disableRestoreFocus,
  stopPropagationWhenClick,
  onPopoverClick,
  onPopoverMouseUp,
  onPopoverMouseDown,
  paperProps,
  marginThreshold,
  hideBackdrop,
}: Props) {
  return isOpen ? (
    <PopoverMui
      open={isOpen}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      anchorReference={anchorReference}
      anchorPosition={anchorPosition}
      disableAutoFocus={disableAutoFocus}
      disableRestoreFocus={disableRestoreFocus}
      onClick={e => {
        if (stopPropagationWhenClick) {
          e.stopPropagation();
        }
        onPopoverClick?.(e);
      }}
      onMouseUp={onPopoverMouseUp}
      onMouseDown={onPopoverMouseDown}
      PaperProps={paperProps}
      marginThreshold={marginThreshold}
      hideBackdrop={hideBackdrop}
    >
      {children}
    </PopoverMui>
  ) : null;
}

Popover.defaultProps = {
  anchorReference: 'anchorEl',
  anchorPosition: { top: 0, left: 0 },
  disableAutoFocus: false,
  disableRestoreFocus: false,
  stopPropagationWhenClick: false,
  onPopoverClick: undefined,
  anchorOrigin: undefined,
  transformOrigin: undefined,
  onPopoverMouseUp: undefined,
  onPopoverMouseDown: undefined,
  anchorEl: undefined,
  paperProps: undefined,
  marginThreshold: undefined,
  hideBackdrop: undefined,
};

export default Popover;
