import React from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import { concat } from 'utils/styling';
import styles from './BaseSwitch.module.css';
import SpinLoader from 'components/icons/SpinLoader';

interface BaseSwitchProps {
  className?: string;
  label?: React.ReactNode;
  labelClassName?: string;
  loading?: boolean;
  disabled?: boolean;
  checked?: boolean;
  onChange?: (v: boolean) => void;
}

const BaseSwitch = React.forwardRef<HTMLButtonElement, BaseSwitchProps>(
  ({ className, label, labelClassName, loading, disabled, checked, onChange }, ref) => {
    return (
      <FormControlLabel
        className={concat(styles.baseSwitch, !!label && styles.hasLabel, className)}
        disabled={disabled || loading}
        ref={ref}
        control={
          loading ? (
            <SpinLoader className={concat('animate-spin text-orange-500', styles.spinner)} />
          ) : (
            <Switch
              className={styles.switch}
              focusVisibleClassName=".Mui-focusVisible"
              disableRipple
              checked={!!checked}
              onChange={event => onChange?.(event.target.checked)}
              onDragOver={e => {
                e.preventDefault();
                return false;
              }}
            />
          )
        }
        label={<span className={labelClassName}>{label}</span>}
      />
    );
  },
);

BaseSwitch.displayName = 'BaseSwitch';

BaseSwitch.defaultProps = {
  className: undefined,
  label: undefined,
  labelClassName: undefined,
  loading: false,
  disabled: false,
  checked: false,
  onChange: undefined,
};

export default BaseSwitch;
