/* eslint-disable class-methods-use-this */
// eslint-disable-next-line max-classes-per-file
import * as decoding from 'lib0/decoding';
import * as encoding from 'lib0/encoding';
import * as syncProtocol from 'y-protocols/sync';
import * as awarenessProtocol from 'y-protocols/awareness';
import { io, Socket } from 'socket.io-client';
import * as Y from 'yjs';
import { IndexeddbPersistence } from 'y-indexeddb';

import usePopupMessageStore from 'stores/popup-message';
import useUserStore from 'stores/user';

import { debounce } from 'lodash';
import config from 'utils/config';
import errors, { SuperpanelAPIError } from 'utils/errors';
import { redirectTo } from '../api/handlers';
import { emitWBEvent } from '../utils/event';

const USER_DATA_UPDATE_DEBOUNCE_TIME = 500;

enum MessageType {
  MessageSync = 0,
  MessageAwareness = 1,
}

interface ErrorCallbackProps {
  isError: boolean;
  error?: SuperpanelAPIError;
}

export interface NoteUser {
  id: string;
  firstName: string;
  lastName: string;
  colour: string;
  isSelect: boolean; // user cursor is in the editor
}

export class NoteDocument {
  id: string;

  noteId: string;

  noteSecret: string;

  document: Y.Doc;

  offlinePersistence: IndexeddbPersistence;

  awareness: awarenessProtocol.Awareness;

  sendMessages: (message: Uint8Array) => void;

  documentUpdate: (update: Uint8Array) => void;

  awarenessUpdate: (update: Uint8Array) => void;

  handleUserDataUpdate?: (users: NoteUser[]) => void;

  constructor(
    componentId: string,
    noteId: string,
    noteSecret: string,
    sendMessage: (message: Uint8Array, noteId: string, noteSecret: string) => void,
    onDocumentUpdate: (update: Uint8Array, noteId: string, noteSecret: string) => void,
    onAwarenessUpdate: (update: Uint8Array, noteId: string, noteSecret: string) => void,
    onUserDataUpdate?: (users: NoteUser[]) => void,
  ) {
    this.id = componentId;
    this.noteId = noteId;
    this.noteSecret = noteSecret;

    this.sendMessages = (message: Uint8Array) => sendMessage(message, this.noteId, this.noteSecret);
    this.awarenessUpdate = (message: Uint8Array) => onAwarenessUpdate(message, this.noteId, this.noteSecret);
    this.documentUpdate = (message: Uint8Array) => onDocumentUpdate(message, this.noteId, this.noteSecret);

    this.document = new Y.Doc();
    this.document.on('update', this.onDocumentUpdate.bind(this));

    this.awareness = new awarenessProtocol.Awareness(this.document);
    this.awareness.on('update', this.onAwarenessUpdate.bind(this));
    this.awareness.on('change', () => {
      // TODO: Add method to add listener to awareness
      this.awareness.getStates();
    });

    this.offlinePersistence = new IndexeddbPersistence(this.noteId, this.document);

    this.handleUserDataUpdate = onUserDataUpdate
      ? debounce(() => {
          const awarenessStates = this.awareness.getStates();
          const users: NoteUser[] = [];
          awarenessStates.forEach(value => {
            if ('data' in value) {
              // this is assuming we are using slate-yjs package withCursors
              let isSelect = false;
              if (value?.selection?.anchor && value.selection.anchor.assoc <= 0) {
                isSelect = true;
              }

              const user: NoteUser = {
                ...(value.data as NoteUser),
                isSelect,
              };
              users.push(user);
            }
          });
          users.sort((a, b) => Number(b.isSelect) - Number(a.isSelect));
          const uniqueUsers = users.filter((user, index, self) => {
            return index === self.findIndex(t => t.id === user.id);
          });
          onUserDataUpdate?.(uniqueUsers);
        }, USER_DATA_UPDATE_DEBOUNCE_TIME)
      : undefined;

    if (this.handleUserDataUpdate) {
      this.awareness.on('update', this.handleUserDataUpdate);
    }
  }

  onDocumentUpdate(update: Uint8Array, origin: string) {
    if (origin !== this.id) this.documentUpdate(update);
  }

  onAwarenessUpdate({ added, updated, removed }: { added: number[]; updated: number[]; removed: number[] }) {
    const changedClients = added.concat(updated).concat(removed);
    const encoder = encoding.createEncoder();
    encoding.writeVarUint(encoder, MessageType.MessageAwareness);
    encoding.writeVarUint8Array(encoder, awarenessProtocol.encodeAwarenessUpdate(this.awareness, changedClients));
    this.awarenessUpdate(encoding.toUint8Array(encoder));
  }

  disconnect() {
    awarenessProtocol.removeAwarenessStates(this.awareness, [this.document.clientID], this.id);
    this.awareness.off('update', this.awarenessUpdate);
    this.document.off('update', this.documentUpdate);
    if (this.handleUserDataUpdate) {
      this.awareness.off('update', this.handleUserDataUpdate);
    }
  }

  applyMessage(message: Buffer) {
    const decoder = decoding.createDecoder(new Uint8Array(message));
    const encoder = encoding.createEncoder();
    const messageType = decoding.readVarUint(decoder);
    switch (messageType) {
      case MessageType.MessageSync: {
        try {
          encoding.writeVarUint(encoder, MessageType.MessageSync);
          syncProtocol.readSyncMessage(decoder, encoder, this.document, this.id);
          if (encoding.length(encoder) > 0) {
            const msg = encoding.toUint8Array(encoder);
            this.sendMessages(msg);
          }
        } catch (e) {
          // Error seems to happen time to time
        }
        break;
      }
      case MessageType.MessageAwareness: {
        awarenessProtocol.applyAwarenessUpdate(this.awareness, decoding.readVarUint8Array(decoder), this.id);
        break;
      }
      default:
        break;
    }

    // Send response if we have one
    if (encoding.length(encoder) > 0) {
      const msg = encoding.toUint8Array(encoder);
      this.sendMessages(msg);
    }
  }

  sync() {
    const encoder = encoding.createEncoder();
    encoding.writeVarUint(encoder, MessageType.MessageSync);
    syncProtocol.writeSyncStep1(encoder, this.document);
    const msg = encoding.toUint8Array(encoder);
    this.sendMessages(msg);
  }
}

class NoteService {
  websocket: Socket;

  connected = false;

  noteDocuments: { [noteId: string]: NoteDocument[] } = {};

  constructor() {
    this.websocket = io(config.NOTE_SERVER_URL, { autoConnect: true });

    this.websocket.on('connect', () => {
      this.connected = true;

      Object.keys(this.noteDocuments).forEach(noteId => {
        this.noteDocuments[noteId].forEach(doc => {
          this.websocket.emit('document-connect', {
            noteId: doc.noteId,
            noteSecret: doc.noteSecret,
          });
        });
      });

      const { user } = useUserStore.getState();
      if (user) {
        const { id, teamId, noteServiceWebSocketToken } = user;
        this.registerUser(id, teamId, noteServiceWebSocketToken);
      }
    });

    this.websocket.on('document-connect', (args: { noteId: string }) => {
      if (!(args.noteId in this.noteDocuments)) return;
      this.noteDocuments[args.noteId].forEach(noteDocument => {
        noteDocument.sync();
      });
    });

    this.websocket.on('document-notify', (args: { noteId: string; message: Buffer }) => {
      if (!(args.noteId in this.noteDocuments)) return;
      this.noteDocuments[args.noteId].forEach(noteDocument => {
        noteDocument.applyMessage(args.message);
      });
    });

    this.websocket.on('disconnect', () => {
      this.connected = false;
    });

    this.websocket.on('notification', (message: string) => {
      // json struct object
      if (message.startsWith('{')) {
        emitWBEvent(message);
      } else {
        const { warning } = usePopupMessageStore.getState();
        const { user } = useUserStore.getState();
        if (message && user) warning(message);
      }
    });

    this.websocket.on('error', (error: SuperpanelAPIError) => {
      const { throwError } = usePopupMessageStore.getState();
      throwError(error);
    });
  }

  connect() {
    this.websocket.connect();
  }

  disconnect() {
    this.websocket.disconnect();
  }

  callbackHandler({ isError, error }: ErrorCallbackProps) {
    if (isError && error) {
      const { throwError } = usePopupMessageStore.getState();
      throwError(error);
      if (error.code === errors.AuthenticationError.code) {
        useUserStore.setState({
          user: null,
        });
        redirectTo('login', null);
      }
    }
  }

  createNoteDocument(
    componentId: string,
    noteId: string,
    noteSecret: string,
    onUserDataUpdate?: (users: NoteUser[]) => void,
  ): NoteDocument {
    const noteDocument = new NoteDocument(
      componentId,
      noteId,
      noteSecret,
      this.sendMessage.bind(this),
      this.updateDocument.bind(this),
      this.awarenessDocument.bind(this),
      onUserDataUpdate,
    );

    if (noteId in this.noteDocuments) {
      this.noteDocuments[noteId].push(noteDocument);
    } else {
      this.noteDocuments[noteId] = [noteDocument];
    }

    return noteDocument;
  }

  async connectToNote(
    componentId: string,
    noteId: string,
    noteSecret: string,
    onUserDataUpdate?: (users: NoteUser[]) => void,
  ): Promise<NoteDocument> {
    const document = this.getNoteDocument(noteId, componentId);
    return new Promise((resolve, reject) => {
      if (!document) {
        const newDocument = this.createNoteDocument(componentId, noteId, noteSecret, onUserDataUpdate);
        this.websocket.emit(
          'document-connect',
          {
            noteId,
            noteSecret,
          },
          ({ isError }: ErrorCallbackProps) => {
            if (isError) {
              reject();
            } else {
              resolve(newDocument);
            }
          },
        );
      } else {
        resolve(document);
      }
    });
  }

  disconnectToNote(componentId: string, noteId: string) {
    if (!(noteId in this.noteDocuments)) return;

    const noteDocumentIndex = this.noteDocuments[noteId].findIndex(n => n.id === componentId);
    if (noteDocumentIndex < 0) return;

    this.noteDocuments[noteId][noteDocumentIndex].disconnect();
    this.noteDocuments[noteId].splice(noteDocumentIndex, 1);

    if (this.noteDocuments[noteId].length === 0) {
      delete this.noteDocuments[noteId];
      this.websocket.emit('document-disconnect', {
        noteId,
      });
    }
  }

  sendMessage(message: Uint8Array, noteId: string, noteSecret: string) {
    this.websocket.emit('document-message', { noteId, noteSecret, message }, this.callbackHandler);
  }

  updateDocument(message: Uint8Array, noteId: string, noteSecret: string) {
    this.websocket.emit('document-update', { noteId, noteSecret, message }, this.callbackHandler);
  }

  awarenessDocument(message: Uint8Array, noteId: string, noteSecret: string) {
    this.websocket.emit('document-awareness', { noteId, noteSecret, message }, this.callbackHandler);
  }

  getNoteDocument(noteId: string, componentId: string): NoteDocument | null {
    if (noteId in this.noteDocuments) {
      const noteDocuments = this.noteDocuments[noteId];
      const noteDocument = noteDocuments.find(doc => doc.id === componentId);
      return noteDocument || null;
    }
    return null;
  }

  registerUser(userId: string, teamId: string, token: string) {
    this.websocket.emit('socket-register', {
      token,
      userId,
      teamId,
    });
  }
}

export default new NoteService();
