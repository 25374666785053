import { Recording } from 'api/recordings/get-recordings';

export interface SourceType {
  type:
    | 'acct'
    | 'acct/doc-evt'
    | 'acct/doc'
    | 'opp'
    | 'doc-evt'
    | 'doc'
    | 'opp/doc-evt'
    | 'opp/doc'
    | null
    | 'protected';
}

export function getSourceType(recording: Recording): SourceType {
  if (recording.document === null) {
    return { type: null };
  }
  if (recording.document.isProtected) {
    return { type: 'protected' };
  }
  if (recording.document.entityName) {
    if (recording.document.entityName === 'Opportunity') {
      return { type: 'opp' };
    }
    if (recording.document.entityName === 'Account') {
      return { type: 'acct' };
    }
  }
  if (recording.document.parent) {
    if (recording.document.parent.entityName === 'Opportunity') {
      if (recording.document.isEvent) {
        return { type: 'opp/doc-evt' };
      }
      return { type: 'opp/doc' };
    }
    if (recording.document.isEvent) {
      return { type: 'acct/doc-evt' };
    }
    return { type: 'acct/doc' };
  }
  if (recording.document.isEvent) {
    return { type: 'doc-evt' };
  }
  return { type: 'doc' };
}

// TODO - refactor this component, too complicated
export function getEntityNames(recording: Recording): { acct: string | null; opp: string | null; doc: string | null } {
  const name = { acct: '', opp: '', doc: '' };
  if (recording.document && recording.document.docTitleGroup) {
    return recording.document.docTitleGroup;
  }
  return name;
}
