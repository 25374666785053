import React from 'react';
import { ProductFieldAdditionalData, ProductFieldType, SelectAdditionalData } from '../../../types/product-gap';
import SelectFieldSetting from './SelectFieldSetting';

interface Props {
  type: ProductFieldType;
  additionalData: ProductFieldAdditionalData;
  updateAdditionalData: (additionalData: ProductFieldAdditionalData) => Promise<void>;
}

function ProductFieldSetting({ type, additionalData, updateAdditionalData }: Props) {
  switch (type) {
    case ProductFieldType.SELECT:
      return (
        <SelectFieldSetting
          options={(additionalData as SelectAdditionalData).options}
          updateOptions={async (options: string[]) => {
            await updateAdditionalData({
              options,
            });
          }}
        />
      );
    default:
      return null;
  }
}

export default ProductFieldSetting;
