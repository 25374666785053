/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import { RenderElementProps } from 'slate-react';
import useNoteEditorStore from 'stores/note-editor';
import XIcon from 'components/icons/XIcon';
import RowsIcon from 'components/icons/RowsIcon';
import ColumnsIcon from 'components/icons/ColumnsIcon';
import UpArrowIcon from 'components/icons/UpArrowIcon';
import DownArrowIcon from 'components/icons/DownArrowIcon';
import { CustomEditor, TableDialogElement as TableDialogElementType } from '../../types';
import useEditorSelectedFocused from 'hooks/useEditorSelectedFocused';
import { concat } from 'utils/styling';
import styles from './Table.module.css';

interface Props extends RenderElementProps {
  element: TableDialogElementType;
  editor: CustomEditor;
}

interface TableProperties {
  rows: number;
  columns: number;
}

const MIN_ROW_COUNT = 1;
const MAX_ROW_COUNT = 20;
const MIN_COL_COUNT = 1;
const MAX_COL_COUNT = 20;

function TableDialogElement({ attributes, children, element, editor }: Props) {
  const noteEditorStore = useNoteEditorStore();
  const selected = useEditorSelectedFocused();
  const [tableProperties, setTableProperties] = React.useState<TableProperties>({
    rows: 2,
    columns: 2,
  });

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let value = parseInt(event.target.value, 10);
      if (value > 20) value = 20;
      if (value < 1) value = 1;
      setTableProperties(prevState => {
        return {
          ...prevState,
          [event.target.name]: value,
        };
      });
    },
    [setTableProperties],
  );

  const handleOnBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const field = event.target.name;
      const propertyValue = tableProperties[field as keyof typeof tableProperties];
      if (propertyValue > 20) return setTableProperties(prevState => ({ ...prevState, [field]: 20 }));
      if (propertyValue < 1 || Number.isNaN(propertyValue))
        return setTableProperties(prevState => ({ ...prevState, [field]: 1 }));
      return setTableProperties(prevState => {
        return {
          ...prevState,
          [field]: event.target.value,
        };
      });
    },
    [setTableProperties, tableProperties],
  );

  const handleIncrementOrDecrement = useCallback(
    (field: keyof typeof tableProperties, incrementOrDecrement: string) => {
      let newValue: number;
      if (incrementOrDecrement === 'decrement')
        newValue = Math.min(Math.max(tableProperties[field] - 1, MIN_ROW_COUNT), MAX_ROW_COUNT);
      if (incrementOrDecrement === 'increment')
        newValue = Math.min(Math.max(tableProperties[field] + 1, MIN_ROW_COUNT), MAX_ROW_COUNT);
      setTableProperties(prevState => {
        if (prevState[field] < 20) return { ...prevState, [field]: newValue };
        return prevState;
      });
    },
    [tableProperties],
  );

  return (
    <div {...attributes}>
      {children}
      <div
        contentEditable={false}
        className={concat(
          'flex flex-col w-[25rem] border rounded-xl drop-shadow-md bg-white p-6 select-none',
          selected && styles['table-dialog-selected'],
        )}
      >
        <div className="h-18">
          <div className="flex flex-row justify-between">
            <div className="text-lg font-medium text-gray-900">Create a table</div>
            <button
              type="button"
              className="flex rounded-lg p-2"
              onClick={() => {
                noteEditorStore.setState({
                  tableDialogId: element.tableDialogId,
                  currentActionEditor: editor,
                  tableProperties: null,
                });
              }}
            >
              <XIcon className="w-4 h-4 text-gray-500" />
            </button>
          </div>
          <div className="flex flex-row">
            <div className="flex flex-col w-1/2">
              <div className="flex flex-row text-slate-600 items-center">
                <span className="text-base pr-1">Rows</span>
                <RowsIcon />
              </div>
              <div className="flex flex-wrap rounded-md border border-slate-300 px-1 my-2 mr-4 focus-within:outline-none focus-within:border-blue-400 focus-within:ring focus-within:ring-sky-100">
                <div className="flex w-10/12 h-8">
                  <input
                    name="rows"
                    type="number"
                    min={MIN_ROW_COUNT}
                    max={MAX_ROW_COUNT}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    value={tableProperties.rows.toString()}
                    className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none text-sm text-gray-900 pl-3 w-full"
                  />
                </div>
                <div className="flex flex-col w-2/12 h-8">
                  <button
                    type="button"
                    onClick={() => handleIncrementOrDecrement('rows', 'increment')}
                    className="h-4 text-gray-800 text-center text-md font-semibold rounded-tr-md"
                  >
                    <UpArrowIcon className="w-2 h-2 mt-1" />
                  </button>
                  <button
                    type="button"
                    onClick={() => handleIncrementOrDecrement('rows', 'decrement')}
                    className="h-4 text-gray-800 text-center text-md font-semibold"
                  >
                    <DownArrowIcon className="w-2 h-2 mb-3" />
                  </button>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-1/2">
              <div className="flex flex-row text-slate-600 items-center">
                <span className="text-base pr-1">Columns</span>
                <ColumnsIcon />
              </div>
              <div className="flex flex-wrap rounded-md border border-slate-300 focus:border-blue-200 px-1 my-2 focus-within:outline-none focus-within:border-blue-400 focus-within:ring focus-within:ring-sky-100">
                <div className="flex w-10/12 h-8">
                  <input
                    name="columns"
                    type="number"
                    min={MIN_COL_COUNT}
                    max={MAX_COL_COUNT}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    value={tableProperties.columns.toString()}
                    className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none text-sm text-gray-900 pl-3 focus:outline-none w-full"
                  />
                </div>
                <div className="flex flex-col w-2/12 h-8">
                  <button
                    type="button"
                    onClick={() => handleIncrementOrDecrement('columns', 'increment')}
                    className="h-4 text-gray-800 text-center text-md font-semibold rounded-tr-md"
                  >
                    <UpArrowIcon className="w-2 h-2 mt-1" />
                  </button>
                  <button
                    type="button"
                    onClick={() => handleIncrementOrDecrement('columns', 'decrement')}
                    className="h-4 text-gray-800 text-center text-md font-semibold"
                  >
                    <DownArrowIcon className="w-2 h-2 mb-3" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="flex items-center justify-center bg-blue-600 hover:bg-blue-500 disabled:bg-blue-200 rounded-lg text-white flex-shrink-0 w-full h-9 mt-2"
            onClick={() => {
              if (Number.isNaN(tableProperties.rows) || tableProperties.rows < 1) {
                setTableProperties(prevState => ({ ...prevState, rows: 1 }));
              } else if (tableProperties.rows > 20) {
                setTableProperties(prevState => ({ ...prevState, rows: 20 }));
              }
              if (Number.isNaN(tableProperties.columns) || tableProperties.columns < 1) {
                setTableProperties(prevState => ({ ...prevState, columns: 1 }));
              } else if (tableProperties.columns > 20) {
                setTableProperties(prevState => ({ ...prevState, columns: 20 }));
              }
              noteEditorStore.setState({
                tableDialogId: element.tableDialogId,
                currentActionEditor: editor,
                tableProperties,
              });
            }}
          >
            Create table
          </button>
        </div>
      </div>
    </div>
  );
}

export default TableDialogElement;
