import React from 'react';
import { Calculation } from '../types';

interface Props {
  calculation: Calculation | undefined;
}

function CalculationText({ calculation }: Props) {
  switch (calculation) {
    case 'Count all':
      return <span>Count: </span>;
    case 'Count empty':
    case 'Percent empty':
      return <span>Empty: </span>;
    case 'Count not empty':
    case 'Percent not empty':
      return <span>Not empty: </span>;
    case 'Sum':
      return <span>Sum: </span>;
    case 'Average':
      return <span>Average: </span>;
    case 'Median':
      return <span>Median: </span>;
    case 'Min':
      return <span>Min: </span>;
    case 'Max':
      return <span>Max: </span>;
    case 'Count True':
    case 'Percent True':
      return <span>True: </span>;
    case 'Count False':
    case 'Percent False':
      return <span>False: </span>;

    default:
      return <span>Calculation</span>;
  }
}

export default CalculationText;
