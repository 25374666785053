import React from 'react';
import { NoteUser as INoteUser } from 'note-service/note-service';
import UserAvatar from 'components/UserAvatar';
import useUserStore from '../../../stores/user';

import styles from './NoteUsers.module.css';

interface Props {
  noteUsers: INoteUser[];
}
function NoteUsers({ noteUsers }: Props) {
  const userStore = useUserStore();

  return (
    <div className={styles.users}>
      {noteUsers.map(noteUser => {
        if (noteUser.id === userStore.user?.id) return null;
        return <UserAvatar size="lg" key={noteUser.id} user={noteUser} />;
      })}
    </div>
  );
}

export default NoteUsers;
