import { Editor } from 'slate';
import { SlatePlugin } from './types';

// TODO
// implement code plugin
// if in paragraph, the user type ``, and anything in between will be turned into code mark
// if the cursor is at the end of the code leaf, and the leaf is the last one in the element, then when user press ArrowRight, we add an empty text leaf without code mark
// if text if wrapped with ``, turn the text into code mark
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function CodePlugin(event: React.KeyboardEvent<HTMLDivElement>, editor: Editor): boolean {
  return false;
}

const CodeKeyPlugin: SlatePlugin = {
  key: 'code-plugin',
  onKeyDown: CodePlugin,
};

export default CodeKeyPlugin;
