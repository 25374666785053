import React from 'react';
import { GroupColumnDefinition } from '../types';
import HideAllColumnMenu from './GroupColumnMenus/HideAllColumnMenu';
import AddNewReportingColumnMenu from './GroupColumnMenus/AddNewReportingColumnMenu';
import PinLeftColumnMenu from './ColumnMenus/PinLeftColumnMenu';

interface Props {
  groupColumnDefinition: GroupColumnDefinition;
  onGroupColumnHide: () => void;
  setIsMenuOpen: (isOpen: boolean) => void;
  onColumnPin: () => void;
}

function GroupColumnMenus({ groupColumnDefinition, onGroupColumnHide, setIsMenuOpen, onColumnPin }: Props) {
  switch (groupColumnDefinition.type) {
    case 'ReportField':
      return (
        <>
          <AddNewReportingColumnMenu
            fieldId={groupColumnDefinition.id}
            fieldLabel={groupColumnDefinition.group}
            setIsMenuOpen={setIsMenuOpen}
          />
          <HideAllColumnMenu onGroupColumnHide={onGroupColumnHide} setIsMenuOpen={setIsMenuOpen} />
          <PinLeftColumnMenu
            column={groupColumnDefinition}
            onColumnPin={() => {
              onColumnPin();
              setIsMenuOpen(false);
            }}
          />
        </>
      );
    default:
      return (
        <>
          <HideAllColumnMenu onGroupColumnHide={onGroupColumnHide} setIsMenuOpen={setIsMenuOpen} />
          <PinLeftColumnMenu
            column={groupColumnDefinition}
            onColumnPin={() => {
              onColumnPin();
              setIsMenuOpen(false);
            }}
          />
        </>
      );
  }
}

export default GroupColumnMenus;
