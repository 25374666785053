import React from 'react';

interface Props {
  className?: string;
}
function SortIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.4444 4.88867V19.1109M16.4444 19.1109L12.8889 15.5553M16.4444 19.1109L20 15.5553M7.55556 19.1109V4.88867M7.55556 4.88867L4 8.44423M7.55556 4.88867L11.1111 8.44423"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SortIcon.defaultProps = {
  className: undefined,
};

export default SortIcon;
