/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
import { PlusIcon } from '@heroicons/react/24/outline';
import NoteEditor from 'components/NoteEditor/NoteEditor';
import {
  BlockType,
  TableCellElement as TableCellElementType,
  TableElement as TableElementType,
  TableRowElement as TableRowElementType,
} from 'components/NoteEditor/types';
import React, { useMemo } from 'react';
import { Editor, Transforms } from 'slate';
import { RenderElementProps, useSlate } from 'slate-react';
import useNoteEditorTableOperationStore from 'stores/note-editor-table-operation';
import { concat } from 'utils/styling';
import styles from './Table.module.css';
import useEditorSelectedFocused from 'hooks/useEditorSelectedFocused';

interface Props extends RenderElementProps {
  element: TableElementType;
}

function TableElement({ attributes, children, element: tableElement }: Props) {
  const editor = useSlate();
  const noteEditorStore = useNoteEditorTableOperationStore();
  const path = useMemo(() => {
    return editor.children.findIndex(node => (node as TableElementType).uuid === tableElement.uuid);
  }, [editor?.children, tableElement.uuid]);
  const selected = useEditorSelectedFocused();

  const rowElement = useMemo(() => {
    const [rowNode] = NoteEditor.node(editor, [path, 0]);
    return rowNode as TableRowElementType;
  }, [tableElement]);

  return (
    <div {...attributes} className={concat(styles.table, selected && styles['table-selected'])}>
      <div>{children}</div>
      {noteEditorStore.tableHoverRowIndex[path] === tableElement.children.length - 1 && (
        <button
          type="button"
          className={styles['add-row-btn']}
          onClick={() => {
            const insertPath = [path, tableElement.children.length - 1];
            const [row] = NoteEditor.node(editor, insertPath);
            const newRow = {
              type: BlockType.TableRow,
              children: (row as TableRowElementType).children.map((child: TableCellElementType) => ({
                type: BlockType.TableCell,
                width: child.width,
                children: [{ text: '' }],
              })),
            };
            insertPath[insertPath.length - 1] = insertPath[insertPath.length - 1] + 1;
            Transforms.insertNodes(editor, newRow as TableRowElementType, {
              at: insertPath,
            });
          }}
        >
          <PlusIcon className="h-3 w-3 text-gray-400" />
        </button>
      )}
      {noteEditorStore.tableHoverColIndex[path] === rowElement.children.length - 1 && (
        <button
          type="button"
          className={styles['add-col-btn']}
          onClick={() => {
            const rightTopCellPath = [path, 0, rowElement.children.length - 1];
            const colCells = NoteEditor.sp_table_GetColCellPaths(editor, rightTopCellPath);
            const [cellNode] = NoteEditor.node(editor, rightTopCellPath);
            Editor.withoutNormalizing(editor, () => {
              colCells.forEach(cellPath => {
                const insertPath = [...cellPath];
                insertPath[insertPath.length - 1] = insertPath[insertPath.length - 1] + 1;
                Transforms.insertNodes(
                  editor,
                  {
                    type: (cellNode as TableCellElementType).type,
                    width: (cellNode as TableCellElementType).width,
                    children: [{ text: '' }],
                  } as TableCellElementType,
                  {
                    at: insertPath,
                  },
                );
              });
            });
            useNoteEditorTableOperationStore.setState({
              tableFocusedColIndex: {},
            });
          }}
        >
          <PlusIcon className="h-3 w-3 text-gray-400" />
        </button>
      )}
    </div>
  );
}

export default TableElement;
