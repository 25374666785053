import React, { useEffect, useRef, useState } from 'react';
import styles from './index.module.css';
import Tooltip, { TooltipPlacement } from 'components/Tooltip';
import { concat } from 'utils/styling';

interface Props {
  className?: string;
  children: React.ReactNode;
  tooltipPlacement?: TooltipPlacement;
  maxDisplayLines: number;
}

// show ellipsis and tooltip when content overflow, otherwise hide tooltip
function EllipsisTooltipContent({ className, children, tooltipPlacement = 'top', maxDisplayLines }: Props) {
  const [showTooltip, setShowTooltip] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      const el = contentRef.current;
      const isOverflowing = el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;
      setShowTooltip(isOverflowing);
    }
  }, [children]);

  return (
    <Tooltip title={showTooltip ? children : undefined} placement={tooltipPlacement}>
      <div
        ref={contentRef}
        className={concat(styles.content, className)}
        style={{ WebkitLineClamp: maxDisplayLines, lineClamp: maxDisplayLines }}
      >
        {children}
      </div>
    </Tooltip>
  );
}

EllipsisTooltipContent.defaultProps = {
  className: '',
  tooltipPlacement: 'top',
};

export default EllipsisTooltipContent;
