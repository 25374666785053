import React, { useEffect, useMemo, useState } from 'react';
import {
  BlockType,
  BlockTypeInline,
  CustomRenderElementProps,
  DocSource,
  LinkElement as LinkElementType,
  MentionElement as MentionElementType,
  NoteTemplateType,
  TableElement as TableElementType,
} from './types';
import EmptyElement from './Elements/EmptyElement';
import SortableElement from './Elements/SortableElement';
import NoteEditor from './NoteEditor';
import LinkElement from './CustomElement/LinkElement';
import TableCellElement from './Elements/Table/TableCellElement';
import TableRowElement from './Elements/Table/TableRow';
import MentionElement from './CustomElement/Mention/MentionElement';
import { Editor } from 'slate';

interface Props extends CustomRenderElementProps {
  editor: Editor;
  applyTemplate?: (type: NoteTemplateType, id: string) => Promise<void>;
  openApplyTemplateModal?: () => void;
  readOnly: boolean;
  enableComment: boolean;
  onAddNewBlock?: (action: string) => void;
  docSource: DocSource;
  asideEdgeWidth?: number;
  scrollContainer?: HTMLDivElement;
  isEditorEmpty?: boolean;
}

function Element({
  editor,
  applyTemplate,
  openApplyTemplateModal,
  attributes,
  children,
  element,
  blockPlaceholder,
  emptyParagraphPlaceholder,
  documentId,
  opportunityId,
  readOnly,
  enableComment,
  onAddNewBlock,
  docSource,
  asideEdgeWidth,
  scrollContainer,
  isEditorEmpty,
}: Props) {
  if (isEditorEmpty) {
    return (
      <EmptyElement
        editor={editor}
        attributes={attributes}
        applyTemplate={applyTemplate}
        openApplyTemplateModal={openApplyTemplateModal}
        emptyParagraphPlaceholder={emptyParagraphPlaceholder}
        element={element}
        documentId={documentId}
        opportunityId={opportunityId}
      >
        {children}
      </EmptyElement>
    );
  }

  // handle inline block
  if (editor.isInline(element)) {
    switch (element.type) {
      case BlockTypeInline.Link: {
        return (
          <LinkElement attributes={attributes} element={element as LinkElementType} readOnly={readOnly}>
            {children}
          </LinkElement>
        );
      }
      case BlockTypeInline.Mention: {
        return (
          <MentionElement
            attributes={attributes}
            element={element as MentionElementType}
            documentId={documentId}
            opportunityId={opportunityId}
            docSource={docSource}
          >
            {children}
          </MentionElement>
        );
      }
      default: {
        return null;
      }
    }
  }

  if (element.type === BlockType.TableCell) {
    return (
      <TableCellElement attributes={attributes} element={element as TableElementType} editor={editor}>
        {children}
      </TableCellElement>
    );
  }

  if (element.type === BlockType.TableRow) {
    return (
      <TableRowElement attributes={attributes} element={element as TableElementType} editor={editor}>
        {children}
      </TableRowElement>
    );
  }

  // handle non-inline block
  return (
    <SortableElement
      editor={editor}
      element={element}
      attributes={attributes}
      blockPlaceholder={blockPlaceholder}
      documentId={documentId}
      opportunityId={opportunityId}
      readOnly={readOnly}
      enableComment={enableComment}
      onAddNewBlock={onAddNewBlock}
      asideEdgeWidth={asideEdgeWidth}
      scrollContainer={scrollContainer}
    >
      {children}
    </SortableElement>
  );
}

Element.defaultProps = {
  applyTemplate: undefined,
  openApplyTemplateModal: undefined,
  onAddNewBlock: undefined,
  asideEdgeWidth: undefined,
  scrollContainer: undefined,
  isEditorEmpty: false,
};

export default Element;
