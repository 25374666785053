import { EntityName } from './entity';
import { SalesforceFieldType } from './salesforce';
import { CrmFieldValue } from './crm';

export interface SuggestedAnswer {
  id: string;
  contextStartTime: number;
  suggestedAnswer: string;
  acceptanceStatus?: string;
  createdAt: string;
  fieldId: string;
  callId: string;
  callTitle: string;
  callStarted: string;
  callEntityDocumentId: string;
  callEntityName: EntityName | null;
  valueAiSuggestion?: string | null;
  acceptanceStatusAiSuggestion?: 'accepted' | 'rejected' | null;
}
export interface Tag {
  id: string;
  tag: string;
  colour: string;
}

export interface SalesforcePicklistValue {
  active: boolean;
  defaultValue: boolean;
  label: string;
  value: string;
}

export interface CrmField {
  id?: string;
  label: string;
  name: string;
  durableId?: string;
  type: SalesforceFieldType;
  picklistValues: SalesforcePicklistValue[];
  isDefault: boolean;
}

export type DataType = SalesforceFieldType;
export type JobStatus = 'complete' | 'pending' | 'failed' | 'new';

export interface Field {
  id: string;
  label: string;
  order: number;
  createdAtTimestamp?: number;
  isDefault: boolean;
  isAiEnabled: boolean | null;
  isUsedInMenuFilter: boolean;
  isUsedInTeamMemberRequest: boolean;
  isSalesEngineerField: boolean;
  description: string | null;
  crmField: CrmField;
  dataType: DataType;
  dataLength: null | number;
  tags: Tag[];
  entity: EntityName;
  parentId: null | string;
  createdBy: string;
  initializationJobStatus: JobStatus;
  autoAcceptAI: boolean;
}

export interface SuggestedAnswerNLPStatus {
  callId: string;
  status: 'complete' | 'pending' | 'failed' | null;
}

export interface FieldValue {
  id: string;
  shownInDiscoveryDocument: boolean;
  field: Field;
  suggestedAnswers: SuggestedAnswer[];
  isAiProcessing: boolean;
  valueAiSuggestion: string | null;
  acceptanceStatusAiSuggestion: 'accepted' | 'rejected' | null;
  suggestedAnswerNLPStatus: SuggestedAnswerNLPStatus[];
  value: CrmFieldValue;
  document: {
    id: string;
    entity: {
      id: string;
      name: string;
      objId: string;
    } | null;
  };
}

export const isCRMField = (field: Field) => !!field.crmField.durableId;
export const getFieldValueValue = (fv: FieldValue) => {
  const vt = fv.field.dataType;
  const v = fv.value.value;
  if (SalesforceFieldType.BOOLEAN === vt) {
    const strV = `${v}`;
    return strV === 'true';
  }

  return v;
};
