import React from 'react';

import { ProductField, ProductFieldType } from 'types/product-gap';

import useProductGapStore from 'stores/product-gap';

interface Props {
  onCreate: (productField: ProductField) => void;
}

function AddNewProductField({ onCreate }: Props) {
  const productGapStore = useProductGapStore();

  const create = async (productFieldType: ProductFieldType) => {
    const productField = await productGapStore.createProductField(productFieldType);
    onCreate(productField);
  };

  return (
    <div className="w-44 p-2">
      <div className="px-2 text-xs text-gray-500">Type</div>
      <div className="mt-2">
        <button
          type="button"
          className="w-full flex items-center p-2 text-left rounded hover:bg-gray-100"
          onClick={() => create(ProductFieldType.TEXT)}
        >
          Text
        </button>
        <button
          type="button"
          className="w-full flex items-center p-2 text-left rounded hover:bg-gray-100"
          onClick={() => create(ProductFieldType.NUMBER)}
        >
          Number
        </button>
        <button
          type="button"
          className="w-full flex items-center p-2 text-left rounded hover:bg-gray-100"
          onClick={() => create(ProductFieldType.DATE)}
        >
          Date
        </button>
        <button
          type="button"
          className="w-full flex items-center p-2 text-left rounded hover:bg-gray-100"
          onClick={() => create(ProductFieldType.SELECT)}
        >
          Select
        </button>
      </div>
    </div>
  );
}

export default AddNewProductField;
