import { ChevronRightIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import integrationOauth from 'api/integration-oauth';

import Gong from 'components/logos/Gong';
import SignupContainer from 'components/SignupContainer';
import typoStyles from 'components/typo.module.css';

import useUserStore from 'stores/user';

import { concat } from 'utils/styling';
import { redirect } from 'utils/redirect-after-login';

import Chorus from 'components/logos/Chorus';
import useModalStore from 'stores/modal';
import ChorusAPITokenModal from 'components/ChorusAPIToken';
import settingsRecording from 'components/SettingsRecording';
import GongAPITokenModal from 'components/GongAPIToken';
import styles from './RecordingIntegration.module.css';

interface State {
  isLoading: boolean;
  chorusOpen: boolean;
  chorusToken: string;
  isSetChorusTokenLoading: boolean;
  isChorusTokenError: boolean;
}

// TODO add state in OAuth request to enhance security
function RecordingIntegration() {
  const [state, setState] = useState<State>({
    isLoading: false,
    chorusOpen: false,
    chorusToken: '',
    isSetChorusTokenLoading: false,
    isChorusTokenError: false,
  });
  const modalStore = useModalStore();

  const navigate = useNavigate();

  const userStore = useUserStore();

  if (!userStore.user?.needsToSetUpRecording) {
    return (
      <div className="flex gap-3 text-green-600 text-xl m-4">
        Recording has not been setup, please ask your admin to setup from the setting page
        <button
          type="button"
          className="bg-blue-600 text-white text-xl px-2 py-1"
          onClick={() => {
            navigate('/');
          }}
        >
          Go Back
        </button>
      </div>
    );
  }

  const skip = async (): Promise<void> => {
    try {
      const { user } = await integrationOauth('recording', 'none', null, null);
      userStore.setState({ user });

      redirect(navigate, user);
    } catch {
      /* empty */
    }
    setState(prevState => ({
      ...prevState,
      isLoading: false,
    }));
  };

  const nextStep = async (): Promise<void> => {
    navigate('/questions-setup');
  };

  const settingRecordingList = settingsRecording(
    userStore,
    <button
      key="gong"
      className={styles['oauth-link']}
      onClick={() => {
        modalStore.setState({
          isOpen: true,
          element: <GongAPITokenModal nextStep={nextStep} />,
        });
      }}
      type="button"
    >
      <div className={styles['link-left']}>
        <Gong className={styles.logo} />
        <div className={styles['link-text']}>
          <div className={concat(typoStyles.lg, typoStyles['semi-bold'])}>Gong</div>
          <div className={concat(typoStyles.sm, typoStyles['text-grey-400'])}>Connect with Gong</div>
        </div>
      </div>
      <ChevronRightIcon className={styles.chevron} />
    </button>,
    <button
      key="chorus"
      className={styles['oauth-link']}
      onClick={() => {
        modalStore.setState({
          isOpen: true,
          element: <ChorusAPITokenModal nextStep={nextStep} />,
        });
      }}
      type="button"
    >
      <div className={styles['link-left']}>
        <Chorus className={styles.logo} />
        <div className={styles['link-text']}>
          <div className={concat(typoStyles.lg, typoStyles['semi-bold'])}>Chorus</div>
          <div className={concat(typoStyles.sm, typoStyles['text-grey-400'])}>Connect with Chorus</div>
        </div>
      </div>
      <ChevronRightIcon className={styles.chevron} />
    </button>,
  );

  return (
    <SignupContainer currentStepIndex={2} title="Link Meeting Recording" totalStepNumber={3}>
      <p className={typoStyles['text-grey-500']}>
        Sync to where you save meeting recordings. We will help you extract key information and summarize your meeting.
      </p>
      {settingRecordingList}
      {/* <button
        className={styles['oauth-link']}
        onClick={() =>
          goToOAuth(
            `https://zoom.us/oauth/authorize?client_id=${config.ZOOM_CLIENT_ID}&redirect_uri=${config.ZOOM_REDIRECT_URL}&response_type=code`,
          )
        }
        type="button"
      >
        <div className={styles['link-left']}>
          <Zoom className={styles.logo} />
          <div className={styles['link-text']}>
            <div className={concat(typoStyles.lg, typoStyles['semi-bold'])}>Zoom</div>
            <div className={concat(typoStyles.sm, typoStyles['text-grey-400'])}>Connect with Zoom cloud recordings</div>
          </div>
        </div>
        <ChevronRightIcon className={styles.chevron} />
      </button> */}
      <div className={styles['skip-button-container']}>
        <button
          className={concat(typoStyles['text-grey-400'], styles['skip-button'])}
          disabled={state.isLoading}
          onClick={() => skip()}
          type="button"
        >
          Skip, I do not record meetings.
        </button>
      </div>
    </SignupContainer>
  );
}

export default RecordingIntegration;
