import React, { useRef, useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { Calculation, ColumnDataType } from './types';
import Popover from '../Popover';
import CalculationText from './CalulationCell/CalculationText';
import CalculationMenus from './CalulationCell/CalculationMenus';
import CalculationAnswer from './CalulationCell/CalculationAnswer';

interface Props {
  groupId: string | undefined;
  columnId: string;
  dataType: ColumnDataType;
  width: number;
  values: any[];
  calculation: Calculation | undefined;
  updateCalculation: (calculation?: Calculation) => void;
  columnData: undefined | any;
}

function CalculationCell({
  groupId,
  columnId,
  dataType,
  width,
  values,
  calculation,
  updateCalculation,
  columnData,
}: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const ref = useRef<HTMLButtonElement>(null);

  return (
    <>
      <button
        ref={ref}
        type="button"
        style={{ width: `${width}px` }}
        className="flex items-center text-gray-400 px-4 py-2 flex-shrink-0 border-r border-b border-gray-300 flex-grow-0 bg-gray-100 hover:bg-gray-200 truncate"
        onClick={() => setIsOpen(true)}
      >
        <div>
          <CalculationText calculation={calculation} />
          <CalculationAnswer
            groupId={groupId}
            columnId={columnId}
            dataType={dataType}
            calculation={calculation}
            initialValues={values}
          />
        </div>
        <ChevronDownIcon className="w-4 h-4 ml-1" />
      </button>
      <Popover
        anchorEl={ref.current}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'center' }}
      >
        <div>
          <CalculationMenus
            dataType={dataType}
            updateCalculation={calc => {
              updateCalculation(calc);
              setIsOpen(false);
            }}
            columnData={columnData}
          />
        </div>
      </Popover>
    </>
  );
}

export default CalculationCell;
