import React from 'react';

interface Props {
  className?: string;
}

function EditIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4781 4.5L16.5621 7.5L8.08107 15.7595H4.99707V12.7595L13.4781 4.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.99707 19.9082H18.9971"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

EditIcon.defaultProps = {
  className: undefined,
};

export default EditIcon;
