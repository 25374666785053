/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import FieldValueTemplateView from 'components/DiscoveryQuestionAnswerNoteTemplate';
import { RenderElementProps } from 'slate-react';

import { DiscoveryQuestionElement } from '../types';

import styles from './DiscoveryQuestion.module.css';
import AddDiscoveryQuestionTemplate from './DiscoveryQuestion/AddDiscoveryQuestionTemplate';
import { concat } from 'utils/styling';
import useEditorSelectedFocused from 'hooks/useEditorSelectedFocused';

interface Props extends RenderElementProps {
  element: DiscoveryQuestionElement;
  readOnly: boolean;
}

function DiscoveryQuestionTemplate({ attributes, element, readOnly, children }: Props) {
  const { discoveryQuestionId } = element;
  const selected = useEditorSelectedFocused();

  let content = <AddDiscoveryQuestionTemplate element={element} />;
  if (discoveryQuestionId) {
    content = (
      <FieldValueTemplateView discoveryQuestionId={discoveryQuestionId} element={element} readOnly={readOnly} />
    );
  }

  return (
    <div
      {...attributes}
      className={`${concat(
        styles.container,
        selected && styles.selected,
      )} border rounded border-gray-200 shadow-sm py-1 px-2`}
    >
      {children}
      <div className="select-none" contentEditable={false}>
        {content}
      </div>
    </div>
  );
}

export default DiscoveryQuestionTemplate;
