import { Descendant } from 'slate';
import httpClient from '../http-client';
import { Task } from 'types/task';

export default async (
  documentId: string | null,
  content: Descendant[],
  done = false,
  dueDate = '',
  assignedToUserId = '',
): Promise<Task> => {
  const { data } = await httpClient.post<Task>(`/task`, {
    documentId,
    done,
    dueDate,
    assignedToUserId,
    content,
  });
  return data;
};
