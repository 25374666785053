/* eslint-disable camelcase, consistent-return, no-else-return */
import { useCallback, useContext, useEffect, useRef } from 'react';
import { UNSAFE_NavigationContext } from 'react-router-dom';
import { History, Transition } from 'history';

/**
 * Hook to guard page leave
 * @param when when guard will work
 * @param ignoreUrls ignore redirect urls
 * @param cb callback
 */
const useLeavePageGuard = (when: boolean, ignoreUrls: string[], cb: (redirectUrl: string) => Promise<boolean>) => {
  const navigator = useContext(UNSAFE_NavigationContext).navigator as unknown as History;
  const targetLocationRef = useRef<{ transition: Transition; unblock: () => void }>();

  const handleGuardCheck = useCallback((redirectUrl: string) => {
    if (targetLocationRef.current) {
      cb(redirectUrl ?? '').then(canNav => {
        if (canNav && targetLocationRef.current) {
          targetLocationRef.current.unblock();
          targetLocationRef.current.transition.retry();
          targetLocationRef.current = undefined;
        }
      });
    }
  }, []);

  useEffect(() => {
    if (when) {
      const unblock = navigator.block?.(locationState => {
        if (!ignoreUrls.includes(locationState.location?.pathname)) {
          if (locationState.location?.pathname) {
            targetLocationRef.current = { transition: locationState, unblock };
            handleGuardCheck(locationState.location?.pathname);
            // prevent navigation
            return false;
          } else {
            // continue navigate
            unblock();
            locationState.retry();
          }
        } else {
          // continue navigate
          unblock();
          locationState.retry();
        }
      });

      return () => {
        unblock?.();
      };
    }
  }, [when, ignoreUrls]);
};

export default useLeavePageGuard;
