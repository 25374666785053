/* eslint-disable react/jsx-props-no-spreading, no-plusplus */
import React, { useMemo } from 'react';
import { Editor } from 'slate';
import { useSlate } from 'slate-react';
import { concat } from 'utils/styling';
import NoteEditor from '../NoteEditor';
import { CustomRenderElementProps, ListElement } from '../types';
import { int2letter, int2roman } from '../utils';

import styles from './BasicElement.module.css';

function inferListIndex(editor: Editor, element: ListElement, elementIndex: number) {
  let orderNum = 1;
  for (let index = elementIndex - 1; index >= 0; index--) {
    const currentNode = editor.children[index];
    if (currentNode.type === element.type && (currentNode.indentLevel || 0) === (element.indentLevel || 0)) {
      orderNum++;
    } else {
      break;
    }
  }
  let orderNumStr = '';
  if (!element.indentLevel) {
    orderNumStr = `${orderNum}. `;
  } else if (element.indentLevel === 1) {
    orderNumStr = `${int2letter(orderNum - 1)}. `;
  } else {
    orderNumStr = `${int2roman(orderNum)}. `;
  }
  return orderNumStr;
}

function OrderedListItemElement({ attributes, children, element }: CustomRenderElementProps) {
  const editor = useSlate();
  const orderNumStr = useMemo(() => {
    const rootPath = editor.children.findIndex(node => (node as ListElement).uuid === element.uuid);
    return inferListIndex(editor, element as ListElement, rootPath);
  }, [editor?.children, element.uuid]);

  return (
    <div
      {...attributes}
      className={concat(
        styles.list,
        NoteEditor.sp_isEmpty(editor, element) && styles['empty-ordered-list'],
        styles['ordered-list'],
      )}
      style={
        {
          '--listprefix': `"${orderNumStr}"`,
          '--listprefix-length': orderNumStr.length,
        } as React.CSSProperties
      }
    >
      {children}
    </div>
  );
}

export default OrderedListItemElement;
