import { Editor, Element, NodeOperation, BaseOperation, InsertNodeOperation } from 'slate';
import { BlockType, CustomElement, DocSource, TaskElement } from '../types';
import NoteEditor from '../NoteEditor';
import { emitDocumentEvent } from 'utils/event';

const withVoids = (editor: Editor, docSource?: DocSource, documentId?: string, opportunityId?: string) => {
  const { isVoid, apply } = editor;

  // eslint-disable-next-line no-param-reassign
  editor.isVoid = element => {
    if (
      element.type === BlockType.Image ||
      element.type === BlockType.DiscoveryQuestion ||
      element.type === BlockType.ProductGap ||
      element.type === BlockType.TableDialog ||
      element.type === BlockType.Task ||
      element.type === BlockType.AIWorker
    ) {
      return true;
    }
    return isVoid(element);
  };

  // eslint-disable-next-line no-param-reassign
  editor.apply = (op: BaseOperation) => {
    const newOp = op as NodeOperation | InsertNodeOperation;
    if (op.type === 'remove_node') {
      if (documentId) {
        const taskNodeInfos = NoteEditor.nodes(editor, {
          at: op.path,
          match: n => {
            return Element.isElement(n) && n.type === BlockType.Task;
          },
          mode: 'all',
        });
        let nodeInfo = taskNodeInfos.next();
        while (!nodeInfo.done) {
          if (nodeInfo.value) {
            const node = nodeInfo.value[0] as TaskElement;
            if (node.taskId && docSource) {
              emitDocumentEvent({
                opportunityId: opportunityId || '',
                documentId: documentId || '',
                type: 'TASK_DELETE',
                sourceElement: nodeInfo.value[0] as CustomElement,
                commentContent: null,
                docSource,
              });
            }
          }
          nodeInfo = taskNodeInfos.next();
        }
      }
    }
    apply(newOp);
  };

  return editor;
};

export default withVoids;
