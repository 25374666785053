import React, { useCallback, useMemo } from 'react';
import useRecordingsStore from 'stores/recordings';
import useContextPanelStore from 'stores/context-panel';
import RecordingTable from './Recordings/RecordingTable';
import RecordingDetail from './Recordings/RecordingDetail';
import constants from 'utils/constants';
import useQueryParamState from 'components/Hooks/useQueryParamState';

function Recordings() {
  const recordingsStore = useRecordingsStore();
  const contextPanelStore = useContextPanelStore();
  const [drawer, setDrawer] = useQueryParamState(constants.RIGHT_DRAWER_QUERY_PARAM_KEY);

  const handleClose = useCallback(() => {
    if (drawer) {
      setDrawer('');
    } else {
      contextPanelStore.close();
    }
  }, [drawer]);

  const recording = useMemo(
    () =>
      recordingsStore.recordings.find(r => {
        return r.id === recordingsStore.recordingId;
      }),
    [recordingsStore.recordings, recordingsStore.recordingId],
  );

  if (recording) {
    return <RecordingDetail recording={recording} onClose={handleClose} />;
  }

  return <RecordingTable onClose={handleClose} loading={recordingsStore.isLoading} />;
}

export default Recordings;
