import { CheckIcon, ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useRef, useState } from 'react';

import getSalesforceItems, { SalesforceItem } from 'api/salesforce/get-salesforce-items';

import Popover from 'components/Popover';
import Loader from 'components/Loader';
import SpinLoader from 'components/icons/SpinLoader';
import { CrmReferenceValue } from 'types/crm';

interface State {
  isMenuOpen: boolean;
  menus: SalesforceItem[];
  searchValue: string;
  isLoading: boolean;
}

export function ReferenceFieldList({
  selectedId,
  disableIds,
  object,
  onChange,
}: {
  selectedId: string;
  disableIds: string[];
  object: string;
  onChange: (value: string) => void;
}) {
  const [state, setState] = useState<State>({
    isMenuOpen: false,
    menus: [],
    searchValue: '',
    isLoading: true,
  });

  const fetchListOfItems = async () => {
    setState(prevState => ({ ...prevState, isLoading: true }));
    const { salesforceItems } = await getSalesforceItems(object, state.searchValue);
    setState(prevState => ({ ...prevState, isLoading: false, menus: salesforceItems }));
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      fetchListOfItems();
    }, 500);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.searchValue]);

  return (
    <div className="max-h-52 w-64 p-2">
      <div className="w-full border rounded py-1 px-2 flex items-center">
        <MagnifyingGlassIcon className="w-4 h-4 mr-2 text-gray-400" />
        <input
          className="w-full text-sm placeholder:text-sm"
          placeholder="Search"
          value={state.searchValue}
          onChange={e => setState(prevState => ({ ...prevState, searchValue: e.target.value }))}
        />
      </div>
      {state.isLoading ? (
        <div className="w-full flex justify-center items-center">
          <Loader className="w-10 h-10" />
        </div>
      ) : (
        <div className="flex flex-col mt-2">
          {state.menus.length > 0 ? (
            state.menus.map(menu => (
              <button
                type="button"
                key={menu.id}
                className="flex items-center justify-between text-left text-sm hover:bg-blue-50 rounded px-2 py-1"
                onClick={() => {
                  onChange(menu.id);
                  setState(prevState => ({ ...prevState, isMenuOpen: false }));
                }}
                disabled={disableIds.includes(menu.id)}
              >
                {menu.name}
                {menu.id === selectedId && (
                  <div>
                    <CheckIcon className="w-4 h-4 text-blue-500" />
                  </div>
                )}
              </button>
            ))
          ) : (
            <div className="text-sm text-gray-400"> No search result for &quot;{state.searchValue}&quot; </div>
          )}
        </div>
      )}
    </div>
  );
}

interface Props {
  isEditable: boolean;
  isUpdating: boolean;
  crmReferenceValue: CrmReferenceValue;
  onChange: (value: string) => void;
}

function ReferenceField({ isEditable, isUpdating, crmReferenceValue, onChange }: Props) {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const ref = useRef<HTMLButtonElement>(null);

  return (
    <div className="w-full">
      <button
        ref={ref}
        type="button"
        disabled={!isEditable || isUpdating}
        className="flex gap-2"
        onClick={() => setIsMenuOpen(true)}
      >
        <div className="text-gray-500">{crmReferenceValue.value || 'Please select...'}</div>
        <ChevronDownIcon className="w-4 h-4 text-gray-400" />
      </button>
      <div className="flex items-center text-xs gap-1 mt-1">
        {isUpdating && (
          <>
            <SpinLoader className="w-5 h-5 p-1 animate-spin" />
            <span>Updating...</span>
          </>
        )}
      </div>
      <Popover
        isOpen={isMenuOpen}
        anchorEl={ref.current}
        onClose={() => setIsMenuOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: -5, horizontal: 'left' }}
      >
        <ReferenceFieldList
          selectedId={crmReferenceValue.id}
          disableIds={[]}
          object={crmReferenceValue.object}
          onChange={value => {
            onChange(value);
            setIsMenuOpen(false);
          }}
        />
      </Popover>
    </div>
  );
}

export default ReferenceField;
