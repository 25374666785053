import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import loginOauth from 'api/login-oauth';

import Loader from 'components/Loader';
import typoStyles from 'components/typo.module.css';

import useUserStore from 'stores/user';

import { User } from 'types/user';

import { redirect } from 'utils/redirect-after-login';
import { concat } from 'utils/styling';
import registerOauth from 'api/register-oauth';
// import integrationOauth from '../api/integration-oauth';
// import useOAuthStore from '../stores/oauth';

import integrationOauth from 'api/integration-oauth';
import styles from './SalesforceOauth.module.css';

interface State {
  isLoading: boolean;
}

function SalesforceOauth() {
  const [searchParams] = useSearchParams();
  const searchParamCode = searchParams.get('code');
  const stateSF = searchParams.get('state');

  const navigate = useNavigate();

  const [state, setState] = useState<State>({
    isLoading: true,
  });

  // const oAuthStore = useOAuthStore();
  const userStore = useUserStore();

  useEffect(() => {
    const oAuth = async (codeP: string): Promise<User> => {
      // User should not oauth within superpanel, it should be done in signup/register page
      // if (oAuthStore.authWith === 'integration') {
      //   const { user } = await integrationOauth('crm', 'salesforce', codeP, null);
      //   return user;
      // }
      if (stateSF === 'team') {
        const { user } = await integrationOauth('crm', 'salesforce', codeP, 'team');
        return user;
      }
      if (stateSF === 'login') {
        const { user } = await loginOauth('salesforce', codeP);
        return user;
      }
      const token = stateSF === 'register' ? null : stateSF;
      const { user } = await registerOauth('salesforce', codeP, token);
      return user;
    };

    const authorize = async (codeP: string): Promise<void> => {
      try {
        const user = await oAuth(codeP);
        userStore.setState({ user });
        redirect(navigate, user);
      } catch {
        /* empty */
      }
      setState({ isLoading: false });
    };

    if (searchParamCode) {
      authorize(searchParamCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParamCode]);

  return (
    <div className={styles.layout}>
      <div className={styles.container}>
        <span className={concat(typoStyles.xl, typoStyles['semi-bold'])}>Salesforce Authorization</span>
        {state.isLoading ? <Loader /> : null}
      </div>
    </div>
  );
}

export default SalesforceOauth;
