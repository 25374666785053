import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import React, { ReactNode, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { useParams, NavigateFunction, createSearchParams } from 'react-router-dom';

import typoStyles from 'components/typo.module.css';
import useCalendarEventsStore from 'stores/calendar-events';

import useSideMenuStore from 'stores/side-menu';
import { concat } from 'utils/styling';
import useNavMenuStore from 'stores/nav-menu';
import useDocumentStore from 'stores/document';
import calendarUtil, { IEventBlock } from '../utils/calendar-util';

import styles from './Calendar.module.css';
import DatePickerPopover from './Calendar/DatePickerPopover';
import EventBlock from './Calendar/EventBlock';
import MenuCollapseIcon from './icons/menuCollapseIcon';
import RefreshIcon from './icons/RefreshIcon';
import moment from 'moment';
import constants from '../utils/constants';
import SpinLoader from './icons/SpinLoader';
import UserDomain from './UserDomain';
import ResponseView from './ResponseView';
import EmptyCalendarIcon from './icons/EmptyCalendarIcon';
import CalendarBackgroundDataLoader from './BackgroundDataLoader';

const HOURS = [
  '12 AM',
  '1 AM',
  '2 AM',
  '3 AM',
  '4 AM',
  '5 AM',
  '6 AM',
  '7 AM',
  '8 AM',
  '9 AM',
  '10 AM',
  '11 AM',
  '12 PM',
  '1 PM',
  '2 PM',
  '3 PM',
  '4 PM',
  '5 PM',
  '6 PM',
  '7 PM',
  '8 PM',
  '9 PM',
  '10 PM',
  '11 PM',
];

interface Props {
  needsToSetUpCalendar: boolean;
  navigate: NavigateFunction;
}

function Calendar({ needsToSetUpCalendar, navigate }: Props) {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false);
  const datePickerButtonRef = useRef<HTMLButtonElement>(null);

  const { documentId } = useParams();
  const navMenuStore = useNavMenuStore();
  const calendarEventsStore = useCalendarEventsStore();
  const sideMenuStore = useSideMenuStore();
  const documentStore = useDocumentStore();

  const currentTimeRef = useRef<HTMLDivElement>(null);
  const calendarScrollerRef = useRef<HTMLDivElement>(null);

  const currentDateTime = moment(calendarEventsStore.currentDateTime);
  const selectedDate = moment(calendarEventsStore.selectedDate);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const refs = React.useRef<{ [key: string]: any }>({});

  useEffect(() => {
    if (!needsToSetUpCalendar && documentId) {
      const document = documentStore.documents.find(d => d.id === documentId);
      if (document && document.event) {
        calendarEventsStore.updateSelectedDate(moment(document.event.startsAt).format(constants.ISO_DATE_FORMAT));
        // calendarEventsStore.fetch();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId, needsToSetUpCalendar]);

  useLayoutEffect(() => {
    if (calendarEventsStore.selectedDate) {
      const event = calendarEventsStore.calendarEvents?.find(e => e.documentId === documentId);
      if (event && event.documentId) {
        const ref = refs.current[event.documentId];
        const calendarRect = calendarScrollerRef.current?.getBoundingClientRect();
        const refRect = ref?.getBoundingClientRect();
        if (
          (calendarRect?.top && refRect?.top && refRect?.top < calendarRect.top) ||
          (calendarRect?.bottom && refRect?.bottom && refRect?.bottom > calendarRect.bottom)
        ) {
          ref.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId, calendarEventsStore.selectedDate, refs, calendarScrollerRef]);

  useEffect(() => {
    if (currentTimeRef.current) {
      const dims = currentTimeRef.current.getBoundingClientRect();
      (calendarScrollerRef.current as HTMLDivElement).scrollTo(0, dims.top - 5.5 * 16);
    }
  }, [calendarEventsStore.selectedDate, calendarScrollerRef, currentTimeRef]);

  const adjustSelectedDate = (dayDiff: number) => {
    calendarEventsStore.updateSelectedDate(selectedDate.add(dayDiff, 'days').format(constants.ISO_DATE_FORMAT));
  };

  const setTodayAsSelectedDate = () => {
    calendarEventsStore.updateSelectedDate(moment().format(constants.ISO_DATE_FORMAT));
  };

  const hourElements: ReactNode = HOURS.map(hour => (
    <span key={hour} className={`${styles.hour} relative`}>
      <span className={`${concat(styles['hour-label'], typoStyles.xs, typoStyles['text-grey-400'])} absolute -top-2`}>
        {hour}
      </span>
      <hr className={`${styles['hour-line']} border-b border-gray-200`} />
    </span>
  ));

  let currentTimeElement: null | ReactNode = null;
  // had to turn into date only, other wise the diff is 0 even for tomorrow
  const date = moment(currentDateTime.format('YYYY-MM-DD'));
  if (date.diff(selectedDate, 'days') === 0) {
    const currentHours = moment(calendarEventsStore.currentDateTime).hours();
    const currentMinutes = moment(calendarEventsStore.currentDateTime).minutes();
    currentTimeElement = (
      <div
        className={styles['current-time']}
        ref={currentTimeRef}
        style={{
          top: `${(currentHours + currentMinutes / 60) * 4}rem`,
        }}
      >
        <span className={styles['current-time-circle']} />
        <hr className={`${styles['current-time-line']} border-b border-red-500`} />
      </div>
    );
  }

  let eventBlocks: IEventBlock[] = [];
  let maxRightTick = 0;
  if (calendarEventsStore.calendarEvents) {
    eventBlocks = calendarUtil.arrangeCalendarEventBlock(
      calendarEventsStore.calendarEvents,
      calendarEventsStore.selectedDate,
    );
    maxRightTick = Math.max(...eventBlocks.map(e => e.right));
  }
  return (
    <div className={styles.layout}>
      <div className="px-3 pb-3 h-[6.5rem]">
        <div className="flex justify-between sticky top-0 bg-white pt-4 pb-2 z-30 text-lg font-bold items-center">
          <UserDomain />
          {sideMenuStore.calendarMenu === 'fixed' ? (
            <button
              className="flex p-1 hover:bg-gray-200 rounded-lg animate-fade-in-down"
              type="button"
              onClick={() => {
                sideMenuStore.close();
                navMenuStore.setState({ buttonHover: null, menuHover: null, collapsedButton: 'cal' });
              }}
            >
              <MenuCollapseIcon className="h-7 w-7 text-gray-400" />
            </button>
          ) : (
            <button
              className="flex p-1 hover:bg-gray-200 rounded-lg rotate-180 "
              type="button"
              onClick={() => {
                sideMenuStore.setState({ calendarMenu: 'fixed', noteMenu: null });
              }}
            >
              <MenuCollapseIcon className="h-7 w-7 text-gray-400" />
            </button>
          )}
        </div>
        {!needsToSetUpCalendar && (
          <>
            <CalendarBackgroundDataLoader />
            <nav className="flex justify-between items-center">
              <div className="flex gap-2 items-center">
                <button className={styles['change-date-button']} onClick={() => adjustSelectedDate(-1)} type="button">
                  <ChevronLeftIcon className={styles.chevron} />
                </button>
                <button
                  ref={datePickerButtonRef}
                  type="button"
                  className="text-xs font-medium hover:bg-gray-100 rounded p-1"
                  onClick={() => setIsDatePickerOpen(true)}
                >
                  {selectedDate.format('ddd, MMM D')}
                </button>
                <button className={styles['change-date-button']} onClick={() => adjustSelectedDate(1)} type="button">
                  <ChevronRightIcon className={styles.chevron} />
                </button>
              </div>
              {calendarEventsStore.isLoading && <SpinLoader className="animate-spin w-4 h-4 text-gray-500 ml-1" />}
              <div className="flex items-center ml-1">
                <button
                  className={`${styles['today-button']} text-xs text-gray-500`}
                  onClick={() => setTodayAsSelectedDate()}
                  type="button"
                >
                  Today
                </button>
                <button
                  className={`${styles['refresh-button']} flex items-center`}
                  onClick={() => {
                    calendarEventsStore.fetch();
                  }}
                  type="button"
                >
                  <RefreshIcon className="text-gray-400" />
                </button>
              </div>
            </nav>
          </>
        )}
      </div>
      {!needsToSetUpCalendar && (
        <div className={styles['calendar-scroller']} ref={calendarScrollerRef}>
          <div className={styles['calendar-container']}>
            <div className={styles.hours}>{hourElements}</div>
            {eventBlocks.map(eventBlock => (
              <EventBlock key={eventBlock.event.id} eventBlock={eventBlock} maxRight={maxRightTick} />
            ))}
            {currentTimeElement}
          </div>
        </div>
      )}
      {needsToSetUpCalendar && (
        <ResponseView
          loading={false}
          noData
          noDataIcon={<EmptyCalendarIcon className="w-[230px] mb-[-24px]" />}
          noDataMsg="There’s nothing here... yet"
          noDataDesc={
            <span>
              Connect your calendar
              <br />
              to import your meetings and better organize your notes.
            </span>
          }
          noDataBtnLabel="+ Connect your Calendar"
          onButtonClick={() =>
            navigate({
              pathname: '/calendar-integration',
              search: createSearchParams({ 'no-step': 'true' }).toString(),
            })
          }
        />
      )}
      <DatePickerPopover
        anchorEl={datePickerButtonRef.current}
        isOpen={isDatePickerOpen}
        onClose={() => setIsDatePickerOpen(false)}
        date={selectedDate}
        onDateChange={newDate => calendarEventsStore.updateSelectedDate(newDate)}
      />
    </div>
  );
}

export default Calendar;
