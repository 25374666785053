/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */

import * as React from 'react';
import { DayPicker } from 'react-day-picker';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

export type BaseDatePickerProps = React.ComponentProps<typeof DayPicker>;

function BaseDatePicker({ ...props }: BaseDatePickerProps) {
  return (
    <DayPicker
      showOutsideDays
      captionLayout="dropdown-buttons"
      fromYear={new Date().getFullYear() - 10}
      toYear={new Date().getFullYear() + 2}
      className="p-3"
      classNames={{
        months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
        month: 'space-y-4',
        vhidden: 'hidden',
        caption: 'flex justify-center pt-1 relative items-center',
        caption_dropdowns: 'flex relative items-center gap-2',
        caption_label: 'hidden',
        dropdown: 'font-medium text-sm',
        nav: 'space-x-1 flex items-center',
        nav_button: 'rounded-full hover:bg-gray-300 h-7 w-7 bg-transparent p-1.5 opacity-50 hover:opacity-100',
        nav_button_previous: 'absolute left-1',
        nav_button_next: 'absolute right-1',
        table: 'w-full border-collapse space-y-1',
        head_row: 'flex',
        head_cell: 'rounded-md w-9 font-normal text-[0.8rem]',
        row: 'flex w-full mt-2',
        cell: 'text-center text-sm p-0 relative [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
        day: 'h-9 w-9 p-0 font-normal aria-selected:opacity-100',
        day_selected: 'bg-blue-600 text-white hover:bg-blue-600 focus:bg-blue-600 rounded-full',
        day_range_start: 'rounded-l-full rounded-r-none',
        day_range_end: 'rounded-r-full rounded-l-none',
        day_range_middle: 'rounded-none',
        day_outside: 'opacity-50',
        day_disabled: 'opacity-50',
        day_hidden: 'invisible',
        day_today: 'bg-blue-200 rounded-full',
      }}
      components={{
        IconLeft: () => <ChevronLeftIcon className="h-4 w-4 stroke-2" />,
        IconRight: () => <ChevronRightIcon className="h-4 w-4 stroke-2" />,
      }}
      {...props}
    />
  );
}

BaseDatePicker.displayName = 'BaseDatePicker';

export default BaseDatePicker;
