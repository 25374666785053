/* eslint-disable no-nested-ternary */
import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import useContextPanelStore from 'stores/context-panel';
import Recordings from './ContextPanelView/Recordings';
import ChatDetail from './ContextPanelView/Chats/ChatDetail';
import styles from './ContextPanelView.module.css';
import ProductGap from './ContextPanelView/ProductGap';
import ProductCategory from './ContextPanelView/ProductCategory';
import { concat } from 'utils/styling';
import Meetings from './ContextPanelView/Meetings';
import useQueryParamState from './Hooks/useQueryParamState';
import constants from 'utils/constants';
import useLocalStorageState from './Hooks/useLocalStorageState';
import useDragHandler from 'hooks/useDragHandler';
import { useParams, useSearchParams } from 'react-router-dom';
import useDocumentStore from 'stores/document';

const MIN_SECTION_WIDTH = 0.25;
const SWIPE_CLOSE_WIDTH = 300;

interface SeparatorProps {
  onMouseDown: (e: React.MouseEvent) => void;
}

function Separator({ onMouseDown }: SeparatorProps) {
  return (
    <button type="button" className={styles.separator} onMouseDown={onMouseDown}>
      <div className={styles.bar} />
      <div className={styles.bar} />
    </button>
  );
}

interface Props {
  children: ReactNode;
  viewKey: string;
  scrollable?: boolean;
  rightSectionDefaultWidth?: string;
}

function ContextPanelView({ children, viewKey, scrollable, rightSectionDefaultWidth }: Props) {
  const [width, setWidth] = useLocalStorageState<string>(viewKey || '', '', false);
  const containerRef = useRef<HTMLDivElement>(null);
  const rightRef = useRef<HTMLDivElement>(null);
  const { onStartDrag, dragging, offsetX } = useDragHandler(0, () => {
    if (containerRef.current && rightRef.current) {
      setWidth(`${(rightRef.current.clientWidth / containerRef.current.clientWidth) * 100}%`);
    } else {
      setWidth('');
    }
    // notify other components size change
    window.dispatchEvent(new Event('resize'));
  });
  const startResizeRightWidth = useRef(0);
  const containerWidthRef = useRef(0);

  const contextPanelStore = useContextPanelStore();
  const [showMeetings] = useQueryParamState(constants.CONTEXT_PANEL_MEETINGS);
  const [currentMeeting, setCurrentMeeting] = useQueryParamState(constants.CURRENT_MEETING);
  const [searchParams, setSearchParams] = useSearchParams();
  const [closeRight, setCloseRight] = useState(false);
  const offsetXWhenClose = useRef(0);
  const { documentId } = useParams();
  const crmParentId = searchParams.get('entity-document-id');
  const documentStore = useDocumentStore();
  const meetingParentId = useMemo(() => {
    const document = documentStore.documents.find(doc => doc.id === documentId);
    return document?.parent?.id || crmParentId || documentId || '';
  }, [documentId, documentStore.documents, documentStore.crmNotes, crmParentId]);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (dragging) {
      const rightWidth = startResizeRightWidth.current - offsetX;
      if (rightWidth <= Math.max(MIN_SECTION_WIDTH * containerWidthRef.current, SWIPE_CLOSE_WIDTH) && offsetX > 10) {
        setCloseRight(true);
        offsetXWhenClose.current = offsetX;
        setTimeout(() => {
          setCloseRight(false);
          contextPanelStore.close();
          searchParams.delete(constants.CONTEXT_PANEL_MEETINGS);
          searchParams.delete(constants.CURRENT_MEETING);
          setSearchParams(searchParams);
          setWidth('');
        }, 300);
      }
    }
  }, [dragging, offsetX, searchParams]);

  const rightElement = useMemo<ReactNode>(() => {
    if (contextPanelStore.panelContext === 'recordings') {
      return <Recordings />;
    }
    if (contextPanelStore.panelContext === 'chat') {
      return <ChatDetail />;
    }
    if (contextPanelStore.panelContext === 'product-gap') {
      return <ProductGap />;
    }
    if (contextPanelStore.panelContext === 'product-category') {
      return <ProductCategory />;
    }
    if (contextPanelStore.panelContext === 'meetings' || !!showMeetings) {
      return (
        <div className={styles['meetings-wrap']} ref={scrollContainerRef}>
          <Meetings
            className="px-4"
            meetingParentId={meetingParentId}
            currentMeeting={currentMeeting}
            setCurrentMeeting={id => setCurrentMeeting(id)}
            scrollContainerRef={scrollContainerRef}
          />
        </div>
      );
    }
    return null;
  }, [contextPanelStore.panelContext, meetingParentId, currentMeeting, showMeetings]);

  useEffect(() => {
    // notify other components size change
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    });
  }, [contextPanelStore.panelContext]);

  return (
    <div
      ref={containerRef}
      className={concat(styles.container, scrollable && styles.scrollable, !!rightElement && styles['show-right'])}
    >
      <div className={styles['left-element']}>{children}</div>
      {!!rightElement && (
        <div
          className={styles['right-section']}
          ref={rightRef}
          style={{
            width: dragging
              ? Math.min(
                  startResizeRightWidth.current - (closeRight ? offsetXWhenClose.current : offsetX),
                  (containerRef.current?.clientWidth ?? 0) - MIN_SECTION_WIDTH * containerWidthRef.current,
                )
              : width || rightSectionDefaultWidth,
            transform: closeRight ? `translateX(${rightRef.current?.clientWidth}px)` : 'none',
          }}
        >
          <Separator
            onMouseDown={e => {
              onStartDrag(e);
              const rect = rightRef.current?.getBoundingClientRect();
              if (rect) {
                startResizeRightWidth.current = rect.width;
              }
              containerWidthRef.current = containerRef.current?.clientWidth || 0;
            }}
          />
          <div className={concat(styles['right-element'])}>{rightElement}</div>
        </div>
      )}
    </div>
  );
}

ContextPanelView.defaultProps = {
  scrollable: true,
  rightSectionDefaultWidth: `50%`,
};

export default ContextPanelView;
