import { CustomElement } from '../../components/NoteEditor/types';
import httpClient from '../http-client';

export interface NoteTemplateModifiedBy {
  id?: string;
  firstName: string;
  lastName: string;
  colour: string;
}

export interface NoteTemplate {
  children: CustomElement[];
}

export interface NoteTemplateInEdit {
  noteTemplateId: string | null;
  name: string;
  data: NoteTemplate;
  icon: string | null;
  isMeta: boolean;
}
export interface Template {
  id: string;
  noteTemplateId: string;
  name: string;
  data: NoteTemplate;
  icon: string | null;
  lastModifiedBy: NoteTemplateModifiedBy;
  lastModifiedAt: string;
  opportunityFieldCount: number;
  accountFieldCount: number;
  isMeta: boolean;
}

export default async (isMeta?: boolean): Promise<Template[]> => {
  const { data } = await httpClient.get<Template[]>(`/note-templates${isMeta ? '/meta' : ''}`);
  return data;
};
