import useNoteEditorStore from 'stores/note-editor';
// eslint-disable-next-line import/no-unresolved
import { DOMNode } from 'slate-react/dist/utils/dom';
import { ReactEditor } from 'slate-react';
import { Editor } from 'slate';
import { isValidImageFileUpload } from 'utils/file';
import { SlatePlugin } from './types';
import { findSlateNode } from '../utils';

function onDropInsert(event: React.DragEvent<HTMLDivElement>, editor: Editor) {
  const files = Array.from(event.dataTransfer.items).map(item => item.getAsFile() as File);
  if (!isValidImageFileUpload(files)) return false;
  const targetNode = findSlateNode(event.target as Element);
  const slateNode = targetNode ? ReactEditor.toSlateNode(editor, targetNode as DOMNode) : null;
  const uploadTargetPath =
    slateNode !== null && ReactEditor.findPath(editor, slateNode).length > 0
      ? [ReactEditor.findPath(editor, slateNode)[0]]
      : [];
  if (uploadTargetPath.length > 0) {
    useNoteEditorStore.setState({
      filesToUpload: files,
      currentActionEditor: editor,
      uploadTargetPath,
    });
    return true;
  }

  useNoteEditorStore.setState({ filesToUpload: files, currentActionEditor: editor });
  event.preventDefault();
  return true;
}

const ImagePlugin: SlatePlugin = {
  key: 'image-plugin',
  onDrop: onDropInsert,
};

export default ImagePlugin;
