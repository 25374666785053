/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import createDocTriggerCondition from 'api/doc-trigger-conditions/create-doc-trigger-condition';
import updateDocTriggerCondition from 'api/doc-trigger-conditions/update-doc-trigger-condition';
import { Template } from 'api/note-templates/get-note-templates';
import { openFormModal, openWarningConfirmModal } from 'components/BaseModal';
import FormField from 'components/Form/FormField';
import SelectField from 'components/Form/SelectField';
import { cloneDeep } from 'lodash';
import { DocTriggerCondition, getEmptyDocTriggerCondition } from 'types/doc-trigger-condition';
import { ControlledFormProps } from 'types/form';
import TemplateInfo from './TemplateInfo';
import getStageNameField from 'api/fields/get-stage-name-field';
import { Field } from 'components/FieldManagement/types';
import useTemplateStore from 'stores/templates';
import { BlockType } from 'components/NoteEditor/types';

type Props = {
  allDocTriggers: DocTriggerCondition[] | undefined;
} & ControlledFormProps<DocTriggerCondition>;

function DocTriggerConditionForm({ allDocTriggers, control, errors, setValue, getValues, watch }: Props) {
  const templateStore = useTemplateStore();
  const initTemplateId = useMemo(() => getValues?.('templateId'), []);
  const templateId = watch?.('templateId');

  const templateMenus = useMemo(() => {
    return templateStore.templates.map(tmpl => ({
      label: tmpl.name,
      value: tmpl.id,
      item: tmpl,
      disabled: tmpl.id !== initTemplateId && !!allDocTriggers?.find(trigger => trigger.templateId === tmpl.id),
    }));
  }, [templateStore.templates, initTemplateId]);

  const templateExist = useMemo(
    () => !!templateMenus.find(menu => menu.value === templateId),
    [templateMenus, templateId],
  );

  return (
    <div className="pl-3">
      <FormField
        vertical
        className="mb-3 pl-3"
        label="Document template"
        error={errors.templateId ? errors.templateId.message || 'Template is required' : ''}
      >
        <Controller
          name="templateId"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <SelectField
              {...field}
              value={templateExist ? field.value : ''}
              onChange={async (newV, selectedMenu) => {
                const template = selectedMenu?.item as Template;
                if (template?.data?.children?.find(element => element.type === BlockType.AIWorker)) {
                  field.onChange(newV);
                } else {
                  const confirmed = await openWarningConfirmModal(
                    'Template does not have any AI section auto completion task defind!',
                    'You should add AI Documentation for this template in future to activate this documentation task.',
                  );
                  if (confirmed) {
                    field.onChange(newV);
                  }
                }
              }}
              placeholder="Select template"
              size="small"
              menus={templateMenus}
              loading={templateStore.fetching}
              menuClassName="truncate"
              errorLabel="Can not find any meta document templates for select"
              renderMenu={menu => (
                <TemplateInfo className="truncate" template={menu.item as Template} showAIWorkerCount />
              )}
            />
          )}
        />
      </FormField>
      <FormField
        vertical
        className="mb-3 pl-3"
        label={
          <span>
            Trigger AI generate document when <strong>Oppotunity Stage</strong> <strong>Is</strong>
          </span>
        }
        error={errors.conditionValue ? errors.conditionValue.message || 'Condition value is required' : ''}
      >
        <Controller
          name="conditionValue"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <SelectField
              {...field}
              onChange={(value, selectedMenu) => {
                field.onChange(value);
                if (selectedMenu?.item) {
                  setValue?.('conditionField.id', (selectedMenu.item as Field).id);
                }
              }}
              placeholder="Select condition value"
              size="small"
              menus={() => {
                return getStageNameField().then(stage => {
                  return (
                    stage.crmField?.picklistValues.map(value => ({
                      label: value.label,
                      value: value.value,
                      item: stage,
                    })) ?? []
                  );
                });
              }}
            />
          )}
        />
      </FormField>
    </div>
  );
}

function openEditDocTriggerConditionModal(
  trigger: DocTriggerCondition | undefined,
  allDocTriggers: DocTriggerCondition[] | undefined,
) {
  const docTrigger: DocTriggerCondition = trigger ? cloneDeep(trigger) : getEmptyDocTriggerCondition();

  return openFormModal<DocTriggerCondition>(
    {
      formData: docTrigger,
      renderFormBody: ({ register, control, errors, watch, setValue, getValues }) => (
        <DocTriggerConditionForm
          register={register}
          control={control}
          errors={errors}
          watch={watch}
          setValue={setValue}
          getValues={getValues}
          allDocTriggers={allDocTriggers}
        />
      ),
      actions: [
        {
          label: 'Cancel',
          color: 'secondary',
        },
        {
          label: trigger ? 'Update' : 'Create',
          color: 'primary',
          type: 'submit',
          cb: (fd: DocTriggerCondition) => {
            return fd.id ? updateDocTriggerCondition(fd) : createDocTriggerCondition(fd);
          },
        },
      ],
    },
    {
      className: 'w-[32rem]',
      bodyClassName: '!px-0',
      title: `${trigger ? 'Update' : 'Create'} document automation `,
    },
  ).then(rsp => {
    return typeof rsp === 'object' ? (rsp as DocTriggerCondition) : undefined;
  });
}

export default openEditDocTriggerConditionModal;
