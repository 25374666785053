import { Buffer } from 'buffer';
import MicrosoftCalendar from 'components/logos/MicrosoftCalendar';
import Salesforce from 'components/logos/Salesforce';
import Superpanel from 'components/logos/Superpanel';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { storeRedirectUrl } from 'utils/redirect-after-login';
import googleOauthGif from 'components/GIF/google-oauth.gif';
import getUserInvitation from '../api/user/get-user-invitation';
import Loader from '../components/Loader';
import Gmail from '../components/logos/Gmail';
import getOauthLink from '../utils/get-oauth-link';

interface State {
  isLoading: boolean;
  signUpWith: 'CRM' | 'CALENDAR' | null;
  isGoogleGif: boolean;
}

function UserInvitation() {
  const [state, setState] = useState<State>({
    isLoading: true,
    signUpWith: null,
    isGoogleGif: false,
  });
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const encodedUrl = searchParams.get('link');

  useEffect(() => {
    const fetch = async (t: string) => {
      setState(prevState => ({ ...prevState, isLoading: true }));
      try {
        const signUpWith = await getUserInvitation(t);
        setState(prevState => ({ ...prevState, signUpWith }));
      } catch (e) {
        // handle error
      }
      setState(prevState => ({ ...prevState, isLoading: false }));
    };

    if (!token) {
      setState({ isLoading: false, signUpWith: null, isGoogleGif: false });
    } else {
      fetch(token);
    }
  }, [token]);

  useEffect(() => {
    if (encodedUrl) {
      const shareLink = Buffer.from(encodedUrl, 'base64').toString();
      const baseUrl = window.location.origin;
      if (shareLink.startsWith(baseUrl)) {
        storeRedirectUrl(shareLink.substring(baseUrl.length));
      }
    }
  }, [encodedUrl]);

  if (state.isLoading) {
    return (
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
        <Loader className="w-32 h-32" />
      </div>
    );
  }

  if (!token || state.signUpWith === null) {
    return <div>Unauthorized invitation. Please contact your administrator.</div>;
  }

  return (
    <div className="flex h-screen">
      {state.isGoogleGif && (
        <div className="w-1/2 h-full flex items-center">
          <div className="w-full flex flex-col gap-4">
            <div className="flex text-gray-500 justify-center">
              <span>Be sure to check the box, just like the example below.</span>
            </div>
            <div className="flex justify-center">
              <div className="border-blue-500 border-2 p-2 rounded-lg">
                <img src={googleOauthGif} alt="google-gif" className="h-[500px]" />
              </div>
            </div>
            <div className="flex justify-center gap-4 mb-10">
              <button
                type="button"
                className="bg-gray-100 rounded-lg px-4 py-2 hover:bg-gray-200"
                onClick={() => setState(previousState => ({ ...previousState, isGoogleGif: false }))}
              >
                Go Back
              </button>
              <a
                className="bg-blue-500 text-white rounded-lg px-4 py-2 hover:bg-blue-300"
                href={getOauthLink('google', token)}
              >
                Continue
              </a>
            </div>
          </div>
        </div>
      )}
      {!state.isGoogleGif && (
        <div className="relative w-1/2 h-full flex flex-col gap-8 justify-center items-center">
          <div className="absolute top-12 left-9 flex items-center gap-2">
            <Superpanel className="w-10 h-10 p-1.5 border rounded" />
            <div className="text-xl font-semibold">Superpanel</div>
          </div>
          <div className="flex flex-col gap-2 justify-center items-center">
            <div className="text-2xl font-semibold">Create your Superpanel account!</div>
          </div>
          {state.signUpWith === 'CRM' ? (
            <>
              <a
                className="border rounded-lg w-2/3 px-10 py-3 flex items-center justify-center bg-blue-200"
                href={getOauthLink('salesforce', token)}
              >
                <Salesforce className="w-9 h-9 mr-3" />
                <span className="font-medium text-gray-700">Continue with Salesforce</span>
              </a>
              <button
                type="button"
                className="text-orange-400 px-4 py-2 hover:bg-gray-100 rounded-lg"
                onClick={() =>
                  setState(previousState => ({ ...previousState, isLoading: false, signUpWith: 'CALENDAR' }))
                }
              >
                Don&apos;t have Salesforce account? Sign up with Google/Microsoft
              </button>
            </>
          ) : (
            <>
              <button
                type="button"
                className="border rounded-lg w-2/3 px-10 py-3 flex items-center justify-center group"
                onClick={() => setState(previousState => ({ ...previousState, isGoogleGif: true }))}
              >
                <Gmail className="w-9 h-9 mr-3" />
                <span className="font-medium group-hover:underline group-hover:text-blue-500">
                  Continue with Google
                </span>
              </button>
              <a
                className="border rounded-lg w-2/3 px-10 py-3 flex items-center justify-center"
                href={getOauthLink('microsoft', token)}
              >
                <MicrosoftCalendar className="w-9 h-9 mr-3" />
                <span className="font-medium">Continue with Microsoft</span>
              </a>
              <button
                type="button"
                className="text-orange-400 px-4 py-2 hover:bg-gray-100 rounded-lg"
                onClick={() => setState(previousState => ({ ...previousState, isLoading: false, signUpWith: 'CRM' }))}
              >
                Sign up with Salesforce
              </button>
            </>
          )}
          <div className="absolute bottom-8 left-8 text-gray-500 text-sm">© Superpanel 2022</div>
        </div>
      )}
      <div className="w-1/2 h-full">
        <img className="w-full h-full" src="/images/background.jpeg" alt="Superpanel" />
      </div>
    </div>
  );
}

export default UserInvitation;
