/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import Loader from 'components/Loader';
import React, { useEffect, useLayoutEffect, useRef } from 'react';
import moment from 'moment';

import SendIcon from 'components/icons/SendIcon';
import { Chat } from 'types/chat';

import useChatsStore from 'stores/chats';
import useUserStore from 'stores/user';
import useNavMenuStore from 'stores/nav-menu';

import EraserIcon from 'components/icons/EraserIcon';
import ChatAIIcon from 'components/icons/ChatAIIcon';
import CopyIcon from 'components/icons/CopyIcon';
import { EntityType } from 'types/entity';
import Title from 'components/EntityHeader/Title';
import FadeIn from 'components/FadeIn';
import PlayBack from 'components/FieldValueView/PlayBack';
import DocumentRefLink from './DocumentRefLink';
import Tooltip from 'components/Tooltip';

import EmptyChatBackgroundNoRecording from './EmptyChatBackgroundNoRecording';
import EmptyChatBackground from './EmptyChatBackground';

import styles from './ChatDetail.module.css';
import useQueryParamState from 'components/Hooks/useQueryParamState';
import constants from 'utils/constants';

function hasRecording(activeEntityHierarchy: { id: string; entityType: EntityType }[]) {
  let entityId = null;
  const activeEntities = activeEntityHierarchy.map(val => val.entityType);
  let currentScope: 'opportunity' | 'account' = 'opportunity';
  if (activeEntities.includes('opportunity')) {
    currentScope = 'opportunity';
    entityId = activeEntityHierarchy.filter(val => val.entityType === 'opportunity')[0].id;
  } else if (activeEntities.includes('account')) {
    currentScope = 'account';
    entityId = activeEntityHierarchy.filter(val => val.entityType === 'account')[0].id;
  }
  return { hasRecordings: true, currentScope, entityId };
}

function ChatDetail() {
  const chatStore = useChatsStore();
  const userStore = useUserStore();
  const navMenuStore = useNavMenuStore();
  const { chats, awaitingResponse } = chatStore;
  const [messageCopyState, setMessageCopyState] = React.useState<{ [key: string]: boolean }>({});
  const [, setDrawer] = useQueryParamState(constants.RIGHT_DRAWER_QUERY_PARAM_KEY);

  useEffect(() => {
    const chatId = Object.keys(messageCopyState).find(key => messageCopyState[key] === true);
    if (chatId) {
      setTimeout(() => {
        setMessageCopyState(prevState => ({ ...prevState, [chatId]: false }));
      }, 2000);
    }
  }, [messageCopyState]);

  const [currentChats, setCurrentChats] = React.useState<Array<Chat>>([]);
  const [newMessage, setNewMessage] = React.useState('');
  const messagesEndRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setCurrentChats(chats);
  }, [chats]);

  useLayoutEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });
  }, [currentChats]);

  const sendMessage = async (message: string) => {
    await chatStore.sendMessage(message);
  };

  if (chatStore.isLoading) {
    return (
      <div className="relative w-full h-full">
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <Loader />
        </div>
      </div>
    );
  }

  const dialogElements: null | JSX.Element[] = [];

  const handleSubmit = () => {
    if (newMessage.length && !awaitingResponse) {
      const newChatMessage: Chat = {
        id: '',
        message: newMessage,
        role: 'user',
        docId: chatStore.documentId,
        userId: userStore.user?.id || '',
        teamId: '',
        firstName: userStore.user?.firstName || null,
        lastName: userStore.user?.lastName || null,
        status: 'pending',
        createdAt: moment().toISOString(),
      };
      setCurrentChats(prevState => [...prevState, newChatMessage]);
      sendMessage(newMessage);
      setNewMessage('');
    }
  };

  const { hasRecordings, currentScope, entityId } = hasRecording(navMenuStore.activeEntityHierarchy);

  let title = <div />;
  if (entityId) {
    title = <Title type={currentScope} documentId={entityId} crmNoteId={null} sfHyperLink={false} />;
  }
  currentChats
    ?.sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt)))
    .forEach(chat => {
      const { message, role, status } = chat;
      if (role === 'user') {
        dialogElements.push(
          <div key={chat.id} className="flex flex-col mr-3">
            <div className="flex flex-row justify-end mb-3 group">
              <div className="flex items-center gap-2">
                <div className="text-sm font-normal" data-heap-redact-text>
                  <div className={`${styles.box} rounded-lg bg-gray-100`}>{message}</div>
                </div>
              </div>
            </div>
            {status === 'failed' && (
              <div className="flex justify-end mb-3 pr-8 text-xs text-red-500">Request failed. Please try again.</div>
            )}
          </div>,
        );
      } else if (role === 'agent') {
        dialogElements.push(
          <div key={chat.id} className="flex mb-3 group ml-3">
            <ChatAIIcon />
            <div className="flex items-center gap-2" data-heap-redact-text>
              <div className={`${styles['box-left']} text-sm font-normal`}>
                <div className={`${styles.box} rounded-lg bg-indigo-50	`}>
                  <div>{message}</div>
                  <div className="my-1 flex gap-2 items-center flex-wrap">
                    {chat.source &&
                      chat.source.map(s => {
                        const para = s.snippet.split('\n');
                        return (
                          <Tooltip
                            key={s.id}
                            title={
                              // <div className="h-40 overflow-auto" style={{ whiteSpace: 'pre-line' }}>
                              <div className="flex flex-col gap-2 max-h-60 overflow-auto flex-wrap">
                                {para.map((p, idx) => (
                                  <div
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={`${p}-${idx}`}
                                    className=""
                                    style={{ whiteSpace: 'pre-line' }}
                                  >
                                    {p}
                                  </div>
                                ))}
                              </div>
                            }
                            placement="top"
                          >
                            <div>
                              {s.refType === 'call' && (
                                <PlayBack
                                  recordingId={s.callId!}
                                  startTime={s.contextStartTime || parseFloat(s.positions[0])}
                                  from="chat"
                                  elementId=""
                                />
                              )}
                              {s.refType === 'note' && (
                                <DocumentRefLink documentId={s.documentId!} positions={s.positions} />
                              )}
                            </div>
                          </Tooltip>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-center w-10">
              {!messageCopyState[chat.id] ? (
                <button
                  type="button"
                  className="flex flex-col justify-center ml-3 text-gray-300 hover:text-gray-400"
                  onClick={() => {
                    navigator.clipboard.writeText(message);
                    setMessageCopyState(prevState => ({ ...prevState, [chat.id]: true }));
                  }}
                >
                  <CopyIcon />
                </button>
              ) : (
                <CheckIcon className="w-4 h-4 ml-3 text-green-400" />
              )}
            </div>
          </div>,
        );
      }
    });

  const awaitingResponseAnimation = (
    <div className="flex mb-3 group items-center">
      <div className="flex-none ml-3">
        <ChatAIIcon />
        {/* <div className="flex-none w-8 h-8 rounded-full bg-blue-400 flex justify-center items-center text-white">AI</div> */}
      </div>
      <div className="flex items-center gap-2">
        <div className={`${styles['box-left']} text-sm font-normal`}>
          <div className={`${styles.box}`}>
            <div className={`${styles.typing}`}>
              <div className={`${styles.dot}`} />
              <div className={`${styles.dot}`} />
              <div className={`${styles.dot}`} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="text-sm flex flex-col h-full">
      <div className="flex justify-between items-center p-4">
        {/* <div className="px-0.5 text-lg font-medium">AI Chat</div> */}
        <FadeIn isOpen>
          <span>{title}</span>
        </FadeIn>
        <div className="flex items-center gap-2">
          <button
            type="button"
            className="w-8 h-8 p-2 bg-slate-200 rounded text-gray-500 hover:bg-slate-300"
            onClick={() => {
              chatStore.clearMessages();
            }}
            disabled={chatStore.awaitingResponse}
          >
            <EraserIcon className="" />
          </button>
          <button
            type="button"
            className="w-8 h-8 p-2 bg-slate-200 rounded text-gray-500 hover:bg-slate-300"
            onClick={() => setDrawer('')}
          >
            <XMarkIcon />
          </button>
        </div>
      </div>
      <div className="flex gap-1 items-center px-2 pl-4 py-2 bg-blue-50 mb-2 h-14">
        <span className="text-blue-600 font-light text-xs">AI chat is in beta testing.</span>
        <span className="flex gap-1 items-center">
          <span className="rounded-xl px-2 bg-blue-100 text-blue-500 text-xs">Beta</span>
        </span>
      </div>
      {hasRecordings && currentChats.length > 0 && (
        <div className="overflow-auto h-full">
          {dialogElements}
          {awaitingResponse && awaitingResponseAnimation}
          <div ref={messagesEndRef} /> {/* This is for scrolling to bottom of the chat */}
        </div>
      )}
      {hasRecordings && currentChats.length === 0 && (
        <div className="flex flex-col gap-2 items-center justify-center h-full">
          <EmptyChatBackground />
          <span className="text-lg font-medium">No chats yet</span>
          <span className="text-xs w-60 text-center font-light">
            Start chatting with AI by typing into the message box below.
          </span>
        </div>
      )}
      {!hasRecordings && (
        <div className="flex flex-col gap-2 items-center justify-center h-full">
          <EmptyChatBackgroundNoRecording />
          <span className="text-lg font-medium">AI Chat is Unavailable</span>
          <span className="text-xs w-60 text-center font-light">
            AI Chat is only available on opportunities or accounts with recordings. We&#39;re working hard to make AI
            Chat available for all situations.
          </span>
        </div>
      )}
      <form
        className="flex flex-row items-center h-16 rounded bg-white w-full py-4"
        onSubmit={e => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div className="flex-grow pl-3">
          <div className="relative w-full">
            <input
              data-heap-redact-text
              className="flex w-full border rounded focus:outline-none focus:border-indigo-300 pl-4 h-10"
              onChange={e => setNewMessage(e.target.value)}
              maxLength={5000}
              value={newMessage}
              onKeyDown={event => {
                if (event.key === 'Enter') handleSubmit();
              }}
              disabled={!hasRecordings}
              placeholder={hasRecordings ? 'Ask me about the opportunity' : 'Add recordings to start chatting'}
            />
          </div>
        </div>
        <div className="ml-4 mr-3">
          <button
            type="submit"
            disabled={awaitingResponse || !hasRecordings}
            className="flex items-center justify-center bg-blue-600 hover:bg-blue-500 disabled:bg-blue-200 rounded-lg text-white flex-shrink-0 w-9 h-9"
          >
            <SendIcon className="w-4 h-4 transform rotate-45 ml-1 mb-1" />
          </button>
        </div>
      </form>
    </div>
  );
}

export default ChatDetail;
