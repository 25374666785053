/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import { cloneDeep, flattenDeep, max } from 'lodash';
import { Descendant, Editor, Element, Node, Path, Range, Text, Transforms } from 'slate';
import useImageEntityStore from 'stores/image-entity';
import { elementToHtml } from '../../../utils/convert-element';
import { DEFAULT_TABLE_CELL_WIDTH } from '../Elements/Table/TableCellElement';
import NoteEditor from '../NoteEditor';
import {
  BlockType,
  CustomElement,
  CustomText,
  ImageElement,
  NoteCopyPasteFormat,
  TableCellElement,
  TableElement,
  TableRowElement,
} from '../types';
import { SlatePlugin } from './types';
import { translateHtmlToMarkdown } from '../utils';

export const normalizeCopiedFragment = (fragment: Descendant[]): Descendant[] => {
  const imageEntities = useImageEntityStore.getState().entities;
  // filter fragment
  const normalizedFragment = fragment.filter((item: CustomElement | CustomText) => {
    const block = item as CustomElement;
    if (block.type === BlockType.Image) {
      const element = block as ImageElement;
      // ignore image element when do not get url
      return element.url || (element.fileId && imageEntities[element.fileId]);
    }
    if (block.type === BlockType.Table) {
      // ignore table without rows
      return (block as TableElement).children.length;
    }
    // ignore singlaton table cell and table row
    if (block.type === BlockType.TableCell || block.type === BlockType.TableRow) {
      return false;
    }
    // ignore new table dialog
    if (block.type === BlockType.TableDialog) {
      return false;
    }
    return true;
  });
  // modify fragment
  normalizedFragment.forEach((item: CustomElement | CustomText) => {
    const block = item as CustomElement;
    // make copied table all rows have same count of columns
    if (block.type === BlockType.Table) {
      const node = block as TableElement;
      const colCount = max(
        (node.children as TableRowElement[]).map(row => Math.max(row?.children?.length ?? 0, 1)),
      ) as number;

      const colWidth = Array.from(
        { length: colCount },
        (_item, i) =>
          node.children[1]?.children[i]?.width ?? node.children[0]?.children[i]?.width ?? DEFAULT_TABLE_CELL_WIDTH,
      );
      node.children.forEach((row: TableRowElement) => {
        if (row.children.length < colCount) {
          row.children = [
            ...Array.from(
              { length: colCount - row.children.length },
              (_item, i) =>
                ({
                  type: BlockType.TableCell,
                  children: [{ text: '' }],
                  width: colWidth[i],
                } as any),
            ),
            ...row.children.map((cell, cellIndex) => ({
              ...cell,
              width: colWidth[cellIndex + colCount - row.children.length],
            })),
          ];
        }
      });
    }
    if (
      block.type === BlockType.Paragraph ||
      block.type === BlockType.H1 ||
      block.type === BlockType.H2 ||
      block.type === BlockType.BulletedList ||
      block.type === BlockType.OrderedList
    ) {
      block.indentLevel = block.indentLevel ?? 0;
    }
    // remove comments
    block.commentInit = undefined;
    block.comments = undefined;
  });
  return normalizedFragment;
};

export const getTableCellContent = (fragment: Descendant[]): Descendant[] => {
  return flattenDeep(
    fragment.map(table =>
      (table as TableElement).children?.map((row: TableRowElement) =>
        row.children?.map((cell: TableCellElement) => cell.children),
      ),
    ),
  ).filter(item => !!item);
};

export const copyEditorFragment = (event: React.ClipboardEvent<HTMLDivElement>, editor: Editor, isCut: boolean) => {
  const { selection } = editor;
  if (selection) {
    let fragment: Descendant[] = [];
    let copySingleTableCell = false;
    // if selection is collapsed, select current whole block node
    const [root1Element, root1Path] = NoteEditor.node(editor, [selection.anchor.path[0]]);
    const [root2Element, root2Path] = NoteEditor.node(editor, [selection.focus.path[0]]);
    if (Range.isCollapsed(selection)) {
      // copy table cell
      if ((root1Element as CustomElement).type === BlockType.Table) {
        const [node, path] = NoteEditor.node(editor, selection.anchor.path);
        Transforms.select(editor, {
          anchor: NoteEditor.start(editor, path),
          focus: NoteEditor.end(editor, path),
        });
        if (Text.isText(node)) {
          fragment = [node as Descendant];
          if (isCut) {
            Transforms.insertText(editor, '', { at: path });
          }
        }
      } else {
        // copy entier block
        fragment = [root1Element as Descendant];
      }
    } else {
      const root1Node = root1Element as CustomElement;
      const root2Node = root2Element as CustomElement;
      const leaf1Path = selection.anchor.path;
      const leaf2Path = selection.focus.path;
      // select whole table when copy
      if (root1Node.type === 'table' || root2Node.type === 'table') {
        // when selection inside one table
        if (root1Path[0] === root2Path[0]) {
          // when selection between multi table cell, select whole table
          if (!Path.equals(leaf1Path.slice(0, 3), leaf2Path.slice(0, 3))) {
            Transforms.select(editor, {
              anchor: NoteEditor.start(editor, root1Path),
              focus: NoteEditor.end(editor, root1Path),
            });
          } else {
            copySingleTableCell = true;
          }
        } else {
          // when selection between multi table
          const anchorBefore = Path.isBefore(leaf1Path, leaf2Path);
          Transforms.select(editor, {
            anchor:
              root1Node.type === 'table'
                ? anchorBefore
                  ? NoteEditor.start(editor, root1Path)
                  : NoteEditor.end(editor, root1Path)
                : selection.anchor,
            focus:
              root2Node.type === 'table'
                ? anchorBefore
                  ? NoteEditor.end(editor, root2Path)
                  : NoteEditor.start(editor, root2Path)
                : selection.focus,
          });
        }
      }
      if (editor.selection) {
        fragment = Node.fragment(editor, editor.selection);
      }
    }
    if (fragment.length) {
      const fragmentToCopy = (
        copySingleTableCell ? [{ type: BlockType.Paragraph, children: getTableCellContent(fragment) }] : fragment
      ) as Descendant[];
      const normalizedFragment = normalizeCopiedFragment(cloneDeep(fragmentToCopy));
      const html = elementToHtml({
        children: fragmentToCopy,
      } as Element);
      const markdown = translateHtmlToMarkdown(html);
      event.clipboardData.setData(NoteCopyPasteFormat.html, html);
      event.clipboardData.setData(NoteCopyPasteFormat.markdown, markdown);
      event.clipboardData.setData(NoteCopyPasteFormat.superpanel, JSON.stringify(normalizedFragment));
    }
  }
};

function copyPlugin(event: React.ClipboardEvent<HTMLDivElement>, editor: Editor): boolean {
  event.preventDefault();
  copyEditorFragment(event, editor, false);
  return true;
}

const CopyPlugin: SlatePlugin = {
  key: 'copy2-plugin',
  onCopy: copyPlugin,
};

export default CopyPlugin;
