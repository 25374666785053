/* eslint-disable no-param-reassign,import/no-extraneous-dependencies */
import { mountStoreDevtool } from 'simple-zustand-devtools';
import moment from 'moment';
import { create } from 'zustand';

import getEvents from 'api/calendar/get-events';
import { Event } from 'types/document';
import constants from 'utils/constants';

export interface State {
  isLoading: boolean;
  calendarEvents: null | Event[];
  currentDateTime: string;
  isCreateNewDocumentLoading: boolean;
  selectedDate: string;
  fetch: () => Promise<void>;
  updateSelectedDate: (date: string) => void;
  setState: (newState: Partial<State>) => State;
}

const useCalendarEventsStore = create<State>((set, get) => ({
  isLoading: true,
  calendarEvents: null,
  currentDateTime: moment().toISOString(true),
  isCreateNewDocumentLoading: false,
  selectedDate: moment().format(constants.ISO_DATE_FORMAT),
  fetch: async () => {
    // only show loader if calendarEvents contains no events from the new date
    const currentEvents = get().calendarEvents;
    let showLoader = true;
    if (currentEvents && currentEvents.length > 0) {
      if (
        currentEvents.some(
          evt => evt.startsAt && moment(evt.startsAt).format(constants.ISO_DATE_FORMAT) === get().selectedDate,
        )
      ) {
        showLoader = false;
      }
    }
    if (showLoader) {
      set(prevState => ({ ...prevState, isLoading: true }));
    }
    const newCalendarEvents = await getEvents(get().selectedDate);
    let newCalendarEventsAll: Event[] = [];
    if (!currentEvents || currentEvents.length === 0) {
      newCalendarEventsAll = newCalendarEvents;
    } else {
      currentEvents.forEach(evt => {
        const newEvent = newCalendarEvents.find(nEvt => nEvt.id === evt.id);
        // handle update for all events
        if (newEvent) {
          newCalendarEventsAll.push(newEvent);
        }
        // handle delete only for the events from the selected date
        else if (!(evt.startsAt && moment(evt.startsAt).format(constants.ISO_DATE_FORMAT) === get().selectedDate)) {
          newCalendarEventsAll.push(evt);
        }
      });
      // handle all new events added
      newCalendarEvents.forEach(evt => {
        const isToAdd = newCalendarEventsAll.every(nEvt => nEvt.id !== evt.id);
        if (isToAdd) {
          newCalendarEventsAll.push(evt);
        }
      });
    }
    if (showLoader) {
      set(prevState => ({ ...prevState, calendarEvents: newCalendarEventsAll, isLoading: false }));
    } else {
      set(prevState => ({ ...prevState, calendarEvents: newCalendarEventsAll }));
    }
  },
  updateSelectedDate: (date: string) => {
    set(prevState => ({ ...prevState, selectedDate: date }));
    get().fetch();
  },
  setState: (newState: Partial<State>) => {
    set(state => {
      return { ...state, ...newState };
    });
    return get();
  },
}));

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('CalendarEvent', useCalendarEventsStore);
}

export default useCalendarEventsStore;
