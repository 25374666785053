/* eslint-disable jsx-a11y/no-autofocus, no-console */
import { CheckIcon, EllipsisVerticalIcon, HashtagIcon, PlayIcon, TrashIcon } from '@heroicons/react/24/outline';
import BaseButton from 'components/BaseButton';
import ResponseView from 'components/ResponseView';
import { debounce, isEmpty, isEqual } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Descendant } from 'slate';
import useUserStore from 'stores/user';
import { AIWorker, AIWorkerScope, getDefaultScopeObj, WorkerContext } from 'types/ai-worker';
import { concat } from 'utils/styling';
import styles from './AIWorker.module.css';
import { openDeleteConfirmModal } from 'components/BaseModal';
import apiErrors, { SuperpanelAPIError } from 'utils/errors';
import ButtonPopover from 'components/ButtonPopover';

import {
  createAIWorker,
  createAIWorkerContext,
  createTplAIWorker,
  deleteAIWorker,
  deleteAIWorkerResult,
  deleteTplAIWorker,
  getAIWorker,
  getAIWorkerContext,
  getTplAIWorker,
  initTplAIWorker,
  runAIWorker,
  updateAIWorker,
  updateTplAIWorker,
} from 'api/ai-worker';
import { AIWorkerWBEvent, WB_EVENT_AI_WORKER } from 'types/event';
import { wbSocketEventEmitter } from 'utils/event';
import { markDownToElements } from 'utils/convert-element';
import ReactMarkdown from 'react-markdown';
import { useAIWorkerScopeModal } from './AIWorkerScopeModal';
import { useUserDomain } from '../../../UserDomain';
import isHotkey from 'is-hotkey';
import EraserIcon from 'components/icons/EraserIcon';
import { AIWorkerContextView } from './AIWorkerContextView';
import SpinLoader from '../../../icons/SpinLoader';
import { updateAIWorkerContext } from '../../../../api/ai-worker';
import InputField from 'components/Form/InputField';

const AI_WORKER_CONTENT_UPDATE_DEBOUNCE_TIME = 500;

interface Props {
  className?: string;
  id: string;
  beingCreatedBy?: string;
  documentId: string | null;
  onCreate?: (worker: AIWorker) => void;
  onInstantiate?: (worker: AIWorker) => void;
  init?: AIWorker;
  onUpdate?: (worker: AIWorker) => void;
  autoFocus?: boolean;
  onDelete?: (leftContent?: Descendant[]) => void;
  onFocus?: () => void;
  isFromTemplate?: boolean;
  readOnly?: boolean;
  blockUUID?: string;
}

function AIWorkerEditor({
  className,
  id,
  beingCreatedBy,
  documentId,
  onCreate,
  onInstantiate,
  init,
  onUpdate,
  autoFocus,
  onDelete,
  onFocus,
  isFromTemplate,
  readOnly: readOnlyFromParent,
  blockUUID,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [updating, setUpdating] = useState<string>('');
  const [deleting, setDeleting] = useState(false);
  const [contextUpdating, setContextUpdating] = useState(false);
  const [error, setError] = useState('');
  const [workerEntity, setWorkerEntity] = useState<AIWorker | undefined>(init);
  const [context, setContext] = useState<WorkerContext | undefined>();
  const userStore = useUserStore();
  const isWorkerCompleted = workerEntity && workerEntity.status === 'complete' && !isEmpty(workerEntity.result);
  const readOnly = readOnlyFromParent || (workerEntity && workerEntity.status === 'running');
  const { createNewDocumentWithModal } = useAIWorkerScopeModal();
  const domain = useUserDomain();
  const inputRef = useRef<HTMLInputElement>(null);
  const playDisabled = readOnly || isFromTemplate || contextUpdating;
  const scopeDisabled = readOnly || contextUpdating;

  const fetchNewWorkerContext = (workerId: string) => {
    if (isFromTemplate) {
      return;
    }
    console.log('fetchNewWorkerContext ...');
    setContextUpdating(true);
    getAIWorkerContext(workerId)
      .then(e => {
        setContext(e);
      })
      .catch(console.error)
      .finally(() => {
        setContextUpdating(false);
      });
  };

  const onRulesUpdate = useCallback(
    async (rules: string[]) => {
      if (workerEntity) {
        setUpdating('worker');
        updateAIWorkerContext(workerEntity.id, { rules })
          .then(() => deleteAIWorkerResult(workerEntity.id))
          .then(() => {
            setWorkerEntity({
              ...workerEntity,
              status: 'new',
              failedReason: undefined,
              result: undefined,
            });
          })
          .catch(console.error)
          .finally(() => {
            setUpdating('');
          });
      }
    },
    [workerEntity],
  );

  const tryToCreateNewContext = (workerId: string, createNew = true) => {
    if (isFromTemplate) {
      return;
    }
    console.log('tryToCreateNewContext ...');
    setContext(undefined);

    if (createNew) {
      setContextUpdating(true);
      createAIWorkerContext(workerId)
        .then(setContext)
        .catch(console.error)
        .finally(() => {
          setContextUpdating(false);
        });
    }
  };

  let getWorkerFun = getAIWorker;
  if (isFromTemplate) {
    getWorkerFun = getTplAIWorker;
  }

  let updateWorkerFun = updateAIWorker;
  if (isFromTemplate) {
    updateWorkerFun = updateTplAIWorker;
  }

  let deleteWorkerFun = deleteAIWorker;
  if (isFromTemplate) {
    deleteWorkerFun = deleteTplAIWorker;
  }

  useEffect(() => {
    // case 1. create a real worker instance in document
    if (!isFromTemplate && !id && documentId && beingCreatedBy === userStore.user?.id) {
      setIsLoading(true);
      createAIWorker({
        documentId,
        scopeObj: getDefaultScopeObj(),
        text: '',
      })
        .then(rsp => {
          onCreate?.(rsp);
          setWorkerEntity(rsp);
          onUpdate?.(rsp);
          setTimeout(() => {
            inputRef.current?.focus();
          });
        })
        .catch(() => setError('failed to create AI worker!'))
        .finally(() => setIsLoading(false));
    }
    // case 2. create a teamplate worker in template
    else if (isFromTemplate && !id && beingCreatedBy === userStore.user?.id) {
      setIsLoading(true);
      createTplAIWorker({
        documentId: undefined,
        scopeObj: getDefaultScopeObj(),
        text: '',
      })
        .then(rsp => {
          onCreate?.(rsp);
          setWorkerEntity(rsp);
          onUpdate?.(rsp);
          setTimeout(() => {
            inputRef.current?.focus();
          });
        })
        .catch(() => setError('failed to create AI Worker!'))
        .finally(() => setIsLoading(false));
    }
    if (id && !workerEntity) {
      getWorkerFun(id)
        .then(async rsp => {
          let rep1 = rsp;
          // The moment when user applies a template, if there are workers in the template, we need to instantiate them
          // if the editor is not a template editor and the work is a template work, we instantiate it
          // instantiate work from template
          if (!isFromTemplate && rsp.isTemplate && documentId) {
            rep1 = await initTplAIWorker(id, documentId);
          }
          setWorkerEntity(rep1);
          onInstantiate?.(rep1);
          onUpdate?.(rep1);
          fetchNewWorkerContext(rep1.id);
        })
        .catch((err: SuperpanelAPIError) => {
          if (err.code === apiErrors.DatabaseError.code) {
            setWorkerEntity({ isDeleted: true } as AIWorker);
          } else {
            setError('failed to load ai worker!');
          }
        });
    }
  }, [id, documentId]);

  const onWbEvent = (e: AIWorkerWBEvent) => {
    if (e.ai_worker_id !== workerEntity?.id) {
      return;
    }
    if (e.action === 'context_update') {
      console.log('got event, now try to fetch new context');
      fetchNewWorkerContext(e.ai_worker_id);
    }
    if (!e.ai_worker) {
      return;
    }
    setWorkerEntity(e.ai_worker);
  };

  useEffect(() => {
    wbSocketEventEmitter.on(WB_EVENT_AI_WORKER, onWbEvent);
    return () => {
      wbSocketEventEmitter.removeListener(WB_EVENT_AI_WORKER, onWbEvent);
    };
  }, [workerEntity]);

  const handleWorkerUpdate = useCallback(
    (newV: string | boolean | null | Descendant[] | AIWorkerScope, field: 'scopeObj' | 'status') => {
      if (workerEntity?.id) {
        setUpdating(field);
        updateWorkerFun(workerEntity.id, { [field]: newV })
          .then(rsp => {
            setWorkerEntity(rsp);
            onUpdate?.(rsp);
            if (field !== 'status') {
              tryToCreateNewContext(workerEntity.id, !!workerEntity.text);
            } else {
              setContext(undefined);
            }
            setTimeout(() => {
              inputRef.current?.focus();
            }, 200);
          })
          .finally(() => setUpdating(''));
      } else {
        const newWorker = { ...workerEntity, [field]: newV } as AIWorker;
        setWorkerEntity(newWorker);
        onUpdate?.(newWorker);
      }
    },
    [workerEntity],
  );

  const handleWorkerContentUpdate = useCallback(
    debounce(
      (newContent: string) => {
        if (!isEqual(workerEntity?.text, newContent) || !context) {
          if (workerEntity?.id) {
            setUpdating('text');
            updateWorkerFun(workerEntity.id, { text: newContent })
              .then(rsp => {
                setWorkerEntity(rsp);
                onUpdate?.(rsp);
                tryToCreateNewContext(workerEntity.id, !!rsp.text);
              })
              .finally(() => setUpdating(''));
          } else {
            const newWorker = { ...workerEntity, content: newContent } as AIWorker;
            setWorkerEntity(newWorker);
            onUpdate?.(newWorker);
          }
        }
      },
      workerEntity?.id ? AI_WORKER_CONTENT_UPDATE_DEBOUNCE_TIME : 0,
    ),
    [workerEntity, context],
  );

  const runWorker = () => {
    if (!workerEntity) {
      return;
    }
    if (!blockUUID) {
      return;
    }

    if (isFromTemplate) {
      return;
    }

    setWorkerEntity({ ...workerEntity, status: 'running' });

    runAIWorker(workerEntity.id, blockUUID)
      .then(rsp => {
        setWorkerEntity(rsp);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.error(e);
        setWorkerEntity({ ...workerEntity, status: 'new' });
      });
  };

  const commit = () => {
    if (!workerEntity || !workerEntity.result || !workerEntity.result.result) {
      return;
    }
    const r = workerEntity.result.result.toString();
    const nodes = markDownToElements(r.substring(0, 3000));
    onDelete?.(nodes);
    deleteWorkerFun(workerEntity.id);
  };

  const viewEle = (
    <ResponseView loading={!workerEntity && !error} error={error} className={styles.aiWorker} smallLoader>
      {workerEntity?.isDeleted ? (
        <span className="text-gray-400">This worker has been moved or deleted, please remove this block.</span>
      ) : (
        <div className={concat(styles.aiWorker, className)}>
          <div className="mr-2 font-medium mt-2 ml-2 flex flex-row items-center w-full">
            <span className="text-gray-400 flex-1">{`Create a section with @${domain}-AI`}</span>
          </div>
          <div className="flex flex-row items-center w-full mt-2">
            <InputField
              className={styles.aiSearchInput}
              defaultValue={workerEntity?.text || ''}
              onCopy={e => e.stopPropagation()}
              onPaste={e => e.stopPropagation()}
              onBlur={e => handleWorkerContentUpdate(e.target.value)}
              onKeyDown={e => {
                if (isHotkey('mod+a', e)) {
                  e.stopPropagation();
                } else if (isHotkey('enter', e)) {
                  handleWorkerContentUpdate((e.target as HTMLInputElement).value);
                  e.stopPropagation();
                  e.preventDefault();
                }
              }}
              ref={inputRef}
              maxRows={10}
              placeholder="What do you want AI to help you ..."
              autoFocus={autoFocus}
              onFocus={onFocus}
              disabled={!!readOnly}
              multiline
              size="small"
              endAdornment={
                <div className={styles.actions}>
                  <BaseButton
                    className={styles.actionBtn}
                    tooltip={scopeDisabled ? undefined : 'Scope setting'}
                    color="secondary"
                    variant="text"
                    iconBtn
                    onClick={() => {
                      if (workerEntity) {
                        createNewDocumentWithModal({ isFromTpl: !!isFromTemplate, worker: workerEntity }, newScope =>
                          handleWorkerUpdate(newScope, 'scopeObj'),
                        );
                      }
                    }}
                    loading={false}
                    disabled={scopeDisabled}
                  >
                    <HashtagIcon width={20} />
                  </BaseButton>

                  <BaseButton
                    className={styles.actionBtn}
                    tooltip={playDisabled ? undefined : 'start run AI worker'}
                    color="secondary"
                    variant="text"
                    iconBtn
                    onClick={runWorker}
                    loading={workerEntity?.status === 'running'}
                    disabled={playDisabled}
                  >
                    <PlayIcon width={20} />
                  </BaseButton>
                  {!!onDelete && (
                    <ButtonPopover
                      btnColor="secondary"
                      btnVariant="text"
                      tooltip={readOnly ? undefined : 'More actions'}
                      btnClassName={styles.actionBtn}
                      closeWhenPopoverClick
                      iconBtn
                      menus={[{ label: 'Delete AI worker', value: 'delete', icon: <TrashIcon className="w-5 h-5" /> }]}
                      handleMenuClick={async menu => {
                        if (menu.value === 'delete') {
                          const confirmed = await openDeleteConfirmModal(
                            `Are you sure you want to delete this AI worker?`,
                            'Once the AI worker is removed, you cannot retrieve it back.',
                          );
                          if (confirmed) {
                            try {
                              setDeleting(true);
                              await deleteWorkerFun(id);
                              onDelete?.();
                            } finally {
                              setDeleting(false);
                            }
                          }
                        }
                      }}
                      horizontalOrigin="right"
                      popoverClassName="w-[12rem]"
                      disabled={readOnly}
                    >
                      <EllipsisVerticalIcon width={16} />
                    </ButtonPopover>
                  )}
                </div>
              }
            />
          </div>
        </div>
      )}
    </ResponseView>
  );

  const renderResultView = () => {
    if (workerEntity?.status === 'new' && context && context.searchAndCompose && context.contexts) {
      const disabled = updating === 'worker';
      const btnCls = disabled ? 'cursor-not-allowed' : 'hover:bg-gray-200';
      return (
        <button
          type="button"
          onClick={() => runWorker()}
          disabled={disabled}
          className={`flex items-center bg-gray-100 rounded px-2 py-1 text-sm ml-3 mt-4 mb-4 text-gray-500 ${btnCls}`}
        >
          Do you want me continue writing?
        </button>
      );
    }

    if (workerEntity?.status === 'running' && (!workerEntity?.result || !workerEntity.result.result)) {
      return (
        <div className="flex flex-row p-4 items-center pl-2">
          <SpinLoader className="w-5 h-5 animate-spin text-orange-500 mr-2" />
          <span className="text-sm text-gray-500">i am writing contents, please waiting...</span>
        </div>
      );
    }

    if (workerEntity?.status === 'new' || workerEntity?.status === 'failed' || !workerEntity?.result) {
      return <span />;
    }

    const t = (workerEntity.result.result || '').toString();
    return (
      <div>
        <div className="p-3">
          <div className={styles.clearStyle}>
            <div className={styles.markdown}>
              <ReactMarkdown>{t}</ReactMarkdown>
            </div>
          </div>
        </div>
        {isWorkerCompleted && (
          <div className={styles.retActions}>
            <BaseButton
              color="error"
              variant="outlined"
              tooltip="Drop result and update the task description"
              onClick={() => {
                handleWorkerUpdate('new', 'status');
              }}
              className={styles.retEraserActionBtn}
            >
              <EraserIcon className="w-4 h-4" />
            </BaseButton>
            <BaseButton color="success" variant="outlined" tooltip="Commit this result" onClick={commit}>
              <CheckIcon className="w-4 h-4" />
            </BaseButton>
          </div>
        )}
      </div>
    );
  };
  return (
    <div>
      {viewEle}
      {workerEntity && (
        <AIWorkerContextView
          onRulesUpdate={onRulesUpdate}
          context={context}
          loading={contextUpdating}
          worker={workerEntity}
        />
      )}
      {renderResultView()}
      {workerEntity?.status === 'failed' && workerEntity?.failedReason && (
        <div className="mt-4 mb-2 text-red-600">{workerEntity.failedReason}</div>
      )}
    </div>
  );
}

AIWorkerEditor.displayName = 'AIWorkerEditor';

AIWorkerEditor.defaultProps = {
  className: '',
  beingCreatedBy: undefined,
  init: undefined,
  onCreate: undefined,
  onInstantiate: undefined,
  onUpdate: undefined,
  autoFocus: false,
  onDelete: undefined,
  onFocus: undefined,
  isFromTemplate: false,
  readOnly: false,
  blockUUID: undefined,
};

export default AIWorkerEditor;
