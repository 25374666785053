import React from 'react';
import { concat } from '../../utils/styling';
import SalesforceIcon from '../../components/icons/SalesforceIcon';
import Gong from 'components/logos/Gong';
import { Switch } from '@mui/material';
import Slack from 'components/logos/Slack';
import Chorus from '../../components/logos/Chorus';
import Zoom from '../../components/logos/Zoom';
import GoogleCalendar from '../../components/logos/GoogleCalendar';
import MicrosoftCalendar from '../../components/logos/MicrosoftCalendar';
import useUserStore from 'stores/user';
import useModalStore from 'stores/modal';
import { openInformationalModal, openMessageInfoModal } from '../../components/BaseModal';
import getOauthLink from '../../utils/get-oauth-link';
import GoogleCalendarWarningView from 'components/GoogleCalendarWarningView';
import GongAPITokenModal from '../../components/GongAPIToken';
import ChorusAPITokenModal from 'components/ChorusAPIToken';
import ZoomAPITokenModal from 'components/ZoomAPITokenModal';
import Productgap from '../../components/logos/Productgap';
import Productboard from 'components/logos/Productboard';
import openProductGapSettingModal from './ProductGapSettingModal';
import ProductboardAPIToken from 'components/ProductboardAPIToken';
import TeamMemberRequestIcon from '../../components/icons/TeamMemberRequestIcon';
import TeamMemberRequestTutorial from './TeamMemberRequestTutorial';

interface IntegrationCardProps {
  className?: string;
  icon: JSX.Element;
  title: string;
  isDisabled: boolean;
  isToggled: boolean;
  onToggle: () => void;
}

function IntegrationCard({ className, icon, title, isDisabled, isToggled, onToggle }: IntegrationCardProps) {
  return (
    <div className={concat('flex items-center justify-between p-5 border rounded-lg h-22 shadow-xl w-72', className)}>
      <div className="flex items-center gap-4">
        {icon}
        <div className="text-lg font-medium">{title}</div>
      </div>
      <div>
        <Switch
          id="isAdmin"
          disabled={isDisabled}
          sx={{
            '&.MuiSwitch-root .Mui-checked': {
              color: `${isDisabled ? 'rgba(29,188,134,0.5)' : '#1DBC86'}`,
            },
            '&.MuiSwitch-root .MuiSwitch-track': {
              backgroundColor: `${isToggled ? '#1DBC86' : '#000'} !important`,
            },
          }}
          checked={isToggled}
          onChange={onToggle}
        />
      </div>
    </div>
  );
}

IntegrationCard.defaultProps = {
  className: undefined,
};

function IntegrationSettingView() {
  const userStore = useUserStore();
  const modalStore = useModalStore();

  return (
    <div>
      <div>
        <div className="text-xl font-medium">Integrations</div>
        <div className="py-2" />
        <div className="text-sm text-slate-500">Supercharge your workflow and connect the tool you use everyday.</div>
      </div>
      <div className="py-6" />
      <div>
        <div className="text-xl font-medium">CRM</div>
        <div className="py-2" />
        <div className="text-sm text-slate-500">
          Transform your workflow with seamless integration into your CRM system. When integrated, our solution ensures
          easy tracking of deals and flawlessly syncs all records and data.
        </div>
        <div className="py-3" />
        <div className="flex gap-10">
          <IntegrationCard
            icon={<SalesforceIcon className="w-10 h-10" />}
            title="Salesforce"
            isDisabled={!!userStore.user?.isIntegratedWithCrm}
            isToggled={!!userStore.user?.isIntegratedWithCrm}
            onToggle={() =>
              openInformationalModal(
                'Apologies for the Inconvenience',
                "We're very sorry for the trouble you've experienced. If you need assistance with CRM integration, our support team is ready to help. Please contact Superpanel directly.",
              )
            }
          />
        </div>
      </div>
      <div className="py-6" />
      <div>
        <div className="text-xl font-medium">Calendar</div>
        <div className="py-2" />
        <div className="text-sm text-slate-500">
          Revolutionize your communication workflow with our messenger integration. Seamlessly connect to your preferred
          messaging platform to effortlessly notified with meeting summaries and approve discoveries in real-time.
        </div>
        <div className="py-3" />
        <div className="flex flex-wrap gap-10">
          <IntegrationCard
            icon={<GoogleCalendar className="w-10 h-10" />}
            title="Google"
            isDisabled={userStore.user?.calendarIntegrationName !== null}
            isToggled={userStore.user?.calendarIntegrationName === 'google'}
            onToggle={() => {
              modalStore.setState({
                isOpen: true,
                element: (
                  <div className="p-10">
                    <GoogleCalendarWarningView />
                  </div>
                ),
              });
            }}
          />
          <IntegrationCard
            icon={<MicrosoftCalendar className="w-10 h-10" />}
            title="Outlook"
            isDisabled={userStore.user?.calendarIntegrationName !== null}
            isToggled={userStore.user?.calendarIntegrationName === 'microsoft'}
            onToggle={() => {
              window.location.href = getOauthLink('microsoft', 'onboarding');
            }}
          />
        </div>
      </div>
      <div className="py-6" />
      <div>
        <div className="text-xl font-medium">Call Recording</div>
        <div className="py-2" />
        <div className="text-sm text-slate-500">
          Leverage the power of call recordings to transform your meetings into actionable insights. Our integration
          seamlessly captures and summarizes crucial moments, turning lengthy discussions into concise, valuable
          takeaways.
        </div>
        <div className="py-3" />
        <div className="flex flex-wrap gap-10">
          <IntegrationCard
            icon={<Gong className="w-10 h-10" />}
            title="Gong"
            isDisabled={userStore.user?.teamRecordingIntegrationType !== null}
            isToggled={userStore.user?.teamRecordingIntegrationType === 'gong'}
            onToggle={() => {
              modalStore.setState({
                isOpen: true,
                element: <GongAPITokenModal nextStep={() => window.location.reload()} />,
              });
            }}
          />
          <IntegrationCard
            icon={<Chorus className="w-10 h-10" />}
            title="Chorus"
            isDisabled={userStore.user?.teamRecordingIntegrationType !== null}
            isToggled={userStore.user?.teamRecordingIntegrationType === 'chorus'}
            onToggle={() => {
              modalStore.setState({
                isOpen: true,
                element: <ChorusAPITokenModal nextStep={() => window.location.reload()} />,
              });
            }}
          />
          <IntegrationCard
            icon={<Zoom className="w-10 h-10" />}
            title="Zoom"
            isDisabled={userStore.user?.teamRecordingIntegrationType !== null}
            isToggled={userStore.user?.teamRecordingIntegrationType === 'zoom'}
            onToggle={() => {
              modalStore.setState({
                isOpen: true,
                element: <ZoomAPITokenModal nextStep={() => window.location.reload()} />,
              });
            }}
          />
        </div>
      </div>
      <div className="py-6" />
      <div>
        <div className="text-xl font-medium">Messenger</div>
        <div className="py-2" />
        <div className="text-sm text-slate-500">
          Revolutionize your communication workflow with our messenger integration. Seamlessly connect to your preferred
          messaging platform to effortlessly notified with meeting summaries and approve discoveries in real-time.
        </div>
        <div className="py-3" />
        <div className="flex flex-wrap gap-10">
          <IntegrationCard
            icon={<Slack className="w-8 h-8" />}
            title="Slack"
            isDisabled={!!userStore.user?.isSlackIntegrated}
            isToggled={!!userStore.user?.isSlackIntegrated}
            onToggle={() => {
              window.location.href = getOauthLink('slack', '123"');
            }}
          />
        </div>
      </div>
      <div className="py-6" />
      <div>
        <div className="text-xl font-medium">Features</div>
        <div className="py-2" />
        <div className="text-sm text-slate-500">
          Empower your workflow with a personalized and tailored experience by unlocking custom features within our
          system.
        </div>
        <div className="py-3" />
        <div className="flex flex-wrap gap-10">
          <IntegrationCard
            icon={
              userStore.user?.productProvider === 'ProductBoard' ? (
                <Productboard className="h-10 w-10" />
              ) : (
                <Productgap className="h-10 w-10 text-blue-500" />
              )
            }
            title="Product Gap"
            isDisabled={userStore.user?.productProvider !== null}
            isToggled={userStore.user?.productProvider !== null}
            onToggle={async () => {
              if (userStore.user?.productProvider === null) {
                const rsp = await openProductGapSettingModal();
                if (rsp === 'product-board') {
                  modalStore.setState({
                    isOpen: true,
                    element: <ProductboardAPIToken />,
                  });
                }
              }
            }}
          />
          {(userStore.isSuperpanelUser() || userStore.isCortexUser()) && (
            <IntegrationCard
              icon={<TeamMemberRequestIcon className="h-10 w-10 text-blue-500 shrink-0" />}
              title="Team Member Request"
              isDisabled={!!userStore.user?.isTeamMemberRequestSetup}
              isToggled={!!userStore.user?.isTeamMemberRequestSetup}
              onToggle={async () => {
                let autoCreateLink = false;
                const isTeamMemberRequestSetup = !!userStore.user?.isTeamMemberRequestSetup;
                if (!isTeamMemberRequestSetup) {
                  await openMessageInfoModal(
                    'Auto Create Link',
                    "Empower your account executive to assemble a dynamic deal team! Simply click the 'OK' button below, and a link to submit a request for team members will be seamlessly added to your CRM opportunity page. (Given that your CRM integration has the permission to create the link)",
                    'No, do not create',
                    async () => {
                      autoCreateLink = true;
                    },
                  );
                }
                await userStore.updateIntegration('team-member-request', { autoCreateLink });
                if (!isTeamMemberRequestSetup) {
                  modalStore.setState({ isOpen: true, element: <TeamMemberRequestTutorial /> });
                }
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default IntegrationSettingView;
