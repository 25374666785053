import React from 'react';

interface Props {
  className?: string;
}

function RowsIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4667 3H3.86667C3.38802 3 3 3.38802 3 3.86667V6.26667C3 6.74531 3.38802 7.13333 3.86667 7.13333H12.4667C12.9453 7.13333 13.3333 6.74531 13.3333 6.26667V3.86667C13.3333 3.38802 12.9453 3 12.4667 3Z"
        stroke="#AFB9CC"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4667 9.19922H3.86667C3.38802 9.19922 3 9.58724 3 10.0659V12.4659C3 12.9445 3.38802 13.3326 3.86667 13.3326H12.4667C12.9453 13.3326 13.3333 12.9445 13.3333 12.4659V10.0659C13.3333 9.58724 12.9453 9.19922 12.4667 9.19922Z"
        stroke="#AFB9CC"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

RowsIcon.defaultProps = {
  className: undefined,
};
export default RowsIcon;
