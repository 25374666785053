export default {
  SMALL_SCREEN_WIDTH_SIZE: 1400,
  DATETIME_FORMAT: 'MMM D, LT',
  DATETIME_WITH_YEAR_FORMAT: 'MMM D, YYYY LT',
  DATE_FORMAT: 'MMM D, YYYY',
  ISO_DATE_FORMAT: 'YYYY-MM-DD',
  TIME_FORMAT: 'LT',
  RIGHT_DRAWER_QUERY_PARAM_KEY: 'right-drawer',
  CONTEXT_PANEL_MEETINGS: 'meetings',
  CURRENT_MEETING: 'current-meeting',
  EDITOR_BOTTOM_SECTION_ID: 'editor-bottom-section',
};
