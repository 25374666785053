/* eslint-disable react/no-array-index-key */
import React, { useCallback, useMemo } from 'react';
import { ColumnConfig, RowData } from './types';
import CalculationCell from './CalculationCell';
import { calculateFooterDataFilter, getTableSequenceCellWidth } from './utils';
import { concat } from 'utils/styling';

interface ColumnConfigViewProps {
  columnConfig: ColumnConfig;
  rows: any[];
  updateColumnConfig: (columnConfig: ColumnConfig) => void;
}

function ColumnConfigView({ columnConfig, rows, updateColumnConfig }: ColumnConfigViewProps) {
  if ('group' in columnConfig) {
    return (
      <>
        {columnConfig.columns.map((column, columnIndex) => {
          if (column.isHidden) return null;

          const groupData = calculateFooterDataFilter(column.type, rows, column.data).map(
            row => row[columnConfig.id] || {},
          );
          const values = groupData.map(group => group[column.id]);
          return (
            <CalculationCell
              key={`group-calculate-${column.id}-${columnIndex}`}
              groupId={columnConfig.id}
              columnId={column.id}
              dataType={column.type}
              width={column.width}
              calculation={column.calculation}
              values={values}
              updateCalculation={calculation => {
                const columns = [...columnConfig.columns];
                columns[columnIndex].calculation = calculation;
                updateColumnConfig({ ...columnConfig, columns });
              }}
              columnData={column.data}
            />
          );
        })}
      </>
    );
  }

  if (columnConfig.isHidden) return null;
  return (
    <CalculationCell
      key={columnConfig.id}
      groupId={undefined}
      columnId={columnConfig.id}
      dataType={columnConfig.type}
      width={columnConfig.width}
      calculation={columnConfig.calculation}
      values={rows.map(row => row[columnConfig.id])}
      updateCalculation={calculation => {
        updateColumnConfig({ ...columnConfig, calculation });
      }}
      columnData={columnConfig.data}
    />
  );
}

interface Props {
  columnConfigs: ColumnConfig[];
  setColumnConfigs: (columnDefinitions: ColumnConfig[]) => void;
  rows: RowData[];
  showSequence?: boolean;
  maxSequence?: number;
}

function CalculationFooter({ columnConfigs, setColumnConfigs, rows, showSequence, maxSequence }: Props) {
  const sequenceCellWidth = useMemo(
    () => (showSequence ? getTableSequenceCellWidth(maxSequence ?? 0) : 0),
    [showSequence, maxSequence],
  );
  const pinnedColumns = useMemo(
    () =>
      columnConfigs.filter(columnConfig => {
        return (
          !!columnConfig.isPinned &&
          ('group' in columnConfig ? !columnConfig.columns.every(subCol => subCol.isHidden) : !columnConfig.isHidden)
        );
      }),
    [columnConfigs],
  );
  const nonPinnedColumns = useMemo(
    () =>
      columnConfigs.filter(columnConfig => {
        return !columnConfig.isPinned;
      }),
    [columnConfigs],
  );
  const totalWidth = useMemo(
    () =>
      columnConfigs.reduce((acc, columnConfig) => {
        if ('group' in columnConfig) {
          return acc + columnConfig.columns.reduce((acc2, column) => acc2 + (column.isHidden ? 0 : column.width), 0);
        }
        return acc + (columnConfig.isHidden ? 0 : columnConfig.width) + sequenceCellWidth;
      }, 0),
    [columnConfigs],
  );

  const handleUpdateConfig = useCallback(
    (newColumnConfig: ColumnConfig) => {
      const newColumnConfigs = [...columnConfigs];
      let gIndex: number | null = null;
      // need to find the index of the group column
      // there can be multiple groups sharing the same id
      newColumnConfigs.every((config, index) => {
        // check config id first, config id may not be unique for a decoupled group
        if (config.id === newColumnConfig.id && 'columns' in newColumnConfig && 'columns' in config) {
          // there must be at least one column id match to pinpoint the position of the group
          newColumnConfig.columns.every(col => {
            if (config.columns.find(configCol => col.id === configCol.id)) {
              gIndex = index;
              return false;
            }
            return true;
          });
          return false;
        }
        if (config.id === newColumnConfig.id) {
          gIndex = index;
          return false;
        }
        return true;
      });
      if (gIndex !== null) {
        newColumnConfigs[gIndex] = newColumnConfig;
      }
      setColumnConfigs(newColumnConfigs);
    },
    [columnConfigs],
  );

  return (
    <div className="flex sticky bottom-0 z-10 bg-white" style={{ width: `${totalWidth}px` }}>
      {(!!showSequence || !!pinnedColumns.length) && (
        <div className={concat('flex sticky left-0 border-r', !!pinnedColumns.length && 'border-r-2 border-blue-200')}>
          {showSequence && (
            <div
              className="flex items-center pl-2 border-r border-b border-gray-300 w-9 bg-gray-100"
              style={{ width: sequenceCellWidth }}
            >
              &nbsp;
            </div>
          )}
          {pinnedColumns.map((columnConfig, colIndex) => (
            <ColumnConfigView
              key={`calculate-${columnConfig.id}-${colIndex}`}
              columnConfig={columnConfig}
              rows={rows}
              updateColumnConfig={newColumnConfig => handleUpdateConfig(newColumnConfig)}
            />
          ))}
        </div>
      )}
      {nonPinnedColumns.map((columnConfig, colIndex) => (
        <ColumnConfigView
          key={`calculate-${columnConfig.id}-${colIndex}`}
          columnConfig={columnConfig}
          rows={rows}
          updateColumnConfig={newColumnConfig => {
            handleUpdateConfig(newColumnConfig);
          }}
        />
      ))}
    </div>
  );
}

CalculationFooter.defaultProps = {
  showSequence: undefined,
  maxSequence: 0,
};

export default CalculationFooter;
