import React, { useRef, useState } from 'react';
import { NumberFilterType } from 'components/Table/types';
import CaretIcon from 'components/icons/CaretIcon';
import Popover from 'components/Popover';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { FilterProps } from './type';

type Props = FilterProps<NumberFilterType, string>;

function NumberFilter({ name, type, value, onFilterUpdate, onClose }: Props) {
  const [filterType, setFilterType] = useState<NumberFilterType>(type);
  const [isFilterTypeOpen, setIsFilterTypeOpen] = useState(false);

  const ref = useRef<HTMLButtonElement>(null);

  const updateFilterType = (newFilterType: NumberFilterType) => {
    setFilterType(newFilterType);
    if (newFilterType === 'Is empty' || newFilterType === 'Is not empty') {
      onFilterUpdate({
        type: newFilterType,
        value: '',
      });
    } else if (value !== '') {
      onFilterUpdate({
        type: newFilterType,
        value,
      });
    } else {
      onFilterUpdate(undefined);
    }
  };

  const updateFilterValue = (newValue: string) => {
    if (!newValue) {
      onFilterUpdate(undefined);
    } else {
      onFilterUpdate({
        type: filterType,
        value: newValue,
      });
    }
  };

  return (
    <div className="px-2 pt-1 pb-2">
      <div className="flex items-center text-xs">
        <div className="text-gray-400">{name}</div>
        <button
          ref={ref}
          type="button"
          className="flex items-center rounded hover:bg-gray-100 px-2 py-2 text-sm"
          onClick={() => setIsFilterTypeOpen(true)}
        >
          <div>{filterType.toLowerCase()}</div>
          <CaretIcon className="w-4 h-4 rotate-180" />
        </button>
        <Popover
          anchorEl={ref.current}
          onClose={() => setIsFilterTypeOpen(false)}
          isOpen={isFilterTypeOpen}
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        >
          <div className="flex flex-col p-1">
            <button
              type="button"
              className="px-2 py-1 text-sm text-left rounded hover:bg-gray-100"
              onClick={() => {
                updateFilterType('=');
                setIsFilterTypeOpen(false);
              }}
            >
              =
            </button>
            <button
              type="button"
              className="px-2 py-1 text-sm text-left rounded hover:bg-gray-100"
              onClick={() => {
                updateFilterType('≠');
                setIsFilterTypeOpen(false);
              }}
            >
              ≠
            </button>
            <button
              type="button"
              className="px-2 py-1 text-sm text-left rounded hover:bg-gray-100"
              onClick={() => {
                updateFilterType('>');
                setIsFilterTypeOpen(false);
              }}
            >
              {'>'}
            </button>
            <button
              type="button"
              className="px-2 py-1 text-sm text-left rounded hover:bg-gray-100"
              onClick={() => {
                updateFilterType('<');
                setIsFilterTypeOpen(false);
              }}
            >
              {'<'}
            </button>
            <button
              type="button"
              className="px-2 py-1 text-sm text-left rounded hover:bg-gray-100"
              onClick={() => {
                updateFilterType('≥');
                setIsFilterTypeOpen(false);
              }}
            >
              ≥
            </button>
            <button
              type="button"
              className="px-2 py-1 text-sm text-left rounded hover:bg-gray-100"
              onClick={() => {
                updateFilterType('≤');
                setIsFilterTypeOpen(false);
              }}
            >
              ≤
            </button>
            <button
              type="button"
              className="px-2 py-1 text-sm text-left rounded hover:bg-gray-100"
              onClick={() => {
                updateFilterType('Is empty');
                setIsFilterTypeOpen(false);
              }}
            >
              Is empty
            </button>
            <button
              type="button"
              className="px-2 py-1 text-sm text-left rounded hover:bg-gray-100"
              onClick={() => {
                updateFilterType('Is not empty');
                setIsFilterTypeOpen(false);
              }}
            >
              Is not empty
            </button>
          </div>
        </Popover>
      </div>
      <div className="my-1">
        {!['Is empty', 'Is not empty'].includes(filterType) && (
          <div className="bg-gray-50 rounded border px-2 py-1 text-sm w-full flex justify-between items-center">
            <input
              className="w-full bg-transparent outline-none"
              type="number"
              value={value}
              onChange={e => updateFilterValue(e.target.value)}
              onKeyUp={e => {
                if (e.key === 'Enter') {
                  onClose();
                }
              }}
            />
            {value && (
              <button
                type="button"
                onClick={() => {
                  updateFilterValue('');
                }}
              >
                <XMarkIcon className="w-3 h-3 bg-gray-400 rounded-full text-white" />
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default NumberFilter;
