import React, { useEffect, useReducer, useRef, useState } from 'react';
import moment from 'moment';

import AIIcon from 'components/icons/AIIcon';
import AccountIcon from 'components/icons/AccountIcon';
import EditIcon from 'components/icons/EditIcon';
import Popover from 'components/Popover';
import SpinLoader from 'components/icons/SpinLoader';
import FieldValueView from 'components/FieldValueView';
import { isEmpty } from 'components/Table/utils';
import OpportunityIcon from 'components/icons/OpportunityIcon';

import useFieldValuesStore from 'stores/field-values';
import useRecordingsStore from 'stores/recordings';
import useReportingStore from 'stores/reporting';

import { CrmFieldType } from 'types/crm';

import constants from 'utils/constants';

import CurrencyCellValue from './CurrencyCellValue';
import SuggestedAnswerView from '../../FieldValueView/SuggestedAnswerView';

interface AnswerProps {
  value: any;
  isEmptyValue: boolean;
  dataEntryType: CrmFieldType;
  columnName: string;
  entityName: string;
  documentId: string | null;
}

function Answer({ value, isEmptyValue, dataEntryType, columnName, entityName, documentId }: AnswerProps) {
  if (isEmptyValue) return <div className="whitespace-pre-wrap line-clamp-6 text-gray-300">Empty</div>;

  switch (dataEntryType) {
    case 'currency':
      return <CurrencyCellValue cellValue={value} />;
    case 'location': {
      const { latitude, longitude } = value;
      return (
        <div className="whitespace-pre-wrap line-clamp-6">
          {latitude}, {longitude}
        </div>
      );
    }
    case 'boolean': {
      return <div className="whitespace-pre-wrap line-clamp-6">{value ? 'True' : 'False'}</div>;
    }
    case 'picklist':
      if (columnName === 'Stage' && entityName === 'Opportunity') {
        if (value === 'Closed Won') {
          return <div className="px-2 rounded-full bg-orange-500 w-fit text-white">{value}</div>;
        }
        if (value === 'Closed Lost') {
          return <div className="px-2 rounded-full bg-gray-500 w-fit text-white">{value}</div>;
        }
        if (value === 'Qualification') {
          return <div className="px-2 rounded-full bg-yellow-600 w-fit text-white">{value}</div>;
        }
        if (value === 'Needs Analysis') {
          return <div className="px-2 rounded-full bg-green-700 w-fit text-white">{value}</div>;
        }
      }
      return <div className="px-2 rounded-full bg-gray-300 w-fit">{value}</div>;
    case 'multipicklist': {
      const valueLists = value.split(';').sort() as string[];
      return (
        <div className="flex flex-wrap gap-1">
          {valueLists.map(v => (
            <span key={v} className="px-2 rounded-full bg-gray-300">
              {v}
            </span>
          ))}
        </div>
      );
    }
    case 'percent': {
      return <div className="whitespace-pre-wrap line-clamp-6">{value}%</div>;
    }
    case 'date': {
      return <div>{moment(value, 'YYYY-MM-DD').format(constants.DATE_FORMAT)}</div>;
    }
    case 'datetime': {
      return <div>{moment(value, 'YYYY-MM-DDTHH:mm:ss').format(constants.DATETIME_WITH_YEAR_FORMAT)}</div>;
    }
    case 'time': {
      return <div>{moment(value, 'HH:mm:ss').format(constants.TIME_FORMAT)}</div>;
    }
    case 'richtextarea': {
      return (
        <div
          className="max-h-[10rem] w-full h-full overflow-y-auto overflow-x-hidden no-scrollbar"
          dangerouslySetInnerHTML={{ __html: value }}
        />
      );
    }
    case 'url': {
      return (
        <div>
          <a
            href={`${value}`.startsWith('http') ? value : `http://${value}`}
            target="_blank"
            rel="noreferrer"
            className="text-blue-500 hover:underline"
          >
            {value}
          </a>
        </div>
      );
    }
    default: {
      if (entityName === 'Opportunity' && columnName === 'Name' && documentId) {
        return (
          <a
            className="flex items-start text-left hover:text-blue-400 hover:underline"
            href={`/opportunity/${documentId}`}
            target="_blank"
            rel="noreferrer"
          >
            <OpportunityIcon className="min-w-[16px] w-4 h-4 mt-0.5 mr-2 fill-orange-400 text-orange-400" />
            {value}
          </a>
        );
      }
      if (entityName === 'Account' && columnName === 'Account Name' && documentId) {
        return (
          <a
            className="flex items-start text-left hover:text-blue-400 hover:underline"
            href={`/account/${documentId}`}
            target="_blank"
            rel="noreferrer"
          >
            <AccountIcon className="min-w-[16px] w-4 h-4 mt-1 mr-2 text-orange-400 fill-orange-400" />
            {value}
          </a>
        );
      }
      return <div className="max-h-[10rem] w-full h-full overflow-y-auto overflow-x-hidden no-scrollbar">{value}</div>;
    }
  }
}

interface Props {
  fieldValueId: string;
  answer: string;
  dataEntryType: CrmFieldType;
  columnName: string;
  entityName: 'Opportunity' | 'Account';
  documentId: string | null;
  closePopoverOnValueChange: boolean;
  onValueChange: (value: string) => Promise<void>;
  triggerAI?: () => void;
  prefixIcon?: React.ReactNode;
}

function ReportingFieldAnswer({
  fieldValueId,
  answer,
  dataEntryType,
  columnName,
  entityName,
  documentId,
  closePopoverOnValueChange,
  onValueChange,
  triggerAI,
  prefixIcon,
}: Props) {
  const [value, setValue] = useState<string>(answer);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const ref = useRef<HTMLDivElement>(null);
  const isEmptyValue = isEmpty(value);

  const fieldValueStore = useFieldValuesStore();
  const recordingStore = useRecordingsStore();
  const reportingStore = useReportingStore();

  const fieldValue = fieldValueStore.fieldValues.find(fv => fv.id === fieldValueId);

  useEffect(() => {
    setValue(answer);
  }, [answer]);

  const fetch = async () => {
    setIsLoading(true);
    await fieldValueStore.fetchFieldValue(fieldValueId);
    await recordingStore.fetchByFieldValueId(fieldValueId);
    setIsLoading(false);
  };

  useEffect(() => {
    if (isEditOpen) setTimeout(() => forceUpdate(), 300);
    if (isEditOpen && !fieldValue) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditOpen, fieldValue]);

  const update = async (v: string) => {
    if (closePopoverOnValueChange) {
      setIsEditOpen(false);
    } else {
      setValue(v);
    }
    if (v !== answer) await onValueChange(v);
  };

  const suggestion = reportingStore.suggestionByFieldValueId[fieldValueId];

  return (
    <div ref={ref} className="px-4 py-2 relative group w-full h-full whitespace-normal flex">
      {prefixIcon}
      {suggestion && isEmptyValue ? (
        <div>
          <SuggestedAnswerView
            fieldValueId={fieldValueId}
            suggestedAnswer={suggestion}
            updateAnswer={async newAnswer => {
              await fieldValueStore.updateFieldValueValue(fieldValueId, newAnswer);
              update(newAnswer);
              reportingStore.removeSuggestions(fieldValueId);
            }}
            onReject={() => reportingStore.removeSuggestions(fieldValueId)}
            from="reporting"
          />
        </div>
      ) : (
        <div>
          <Answer
            value={value}
            isEmptyValue={isEmptyValue}
            dataEntryType={dataEntryType}
            columnName={columnName}
            entityName={entityName}
            documentId={documentId}
          />
        </div>
      )}

      {triggerAI && !isEmptyValue && (
        <button
          type="button"
          className="absolute gap-1 top-1 right-9 hidden group-hover:flex group-hover:items-center rounded border bg-white p-1 text-sm text-gray-500 hover:bg-gray-200"
          onClick={() => {
            triggerAI();
          }}
        >
          <AIIcon className="w-5 h-5 text-[#AFB9CC]" />
        </button>
      )}
      <button
        type="button"
        className="absolute gap-1 top-1 right-1 hidden group-hover:flex group-hover:items-center rounded border bg-white p-1 text-sm text-gray-500 hover:bg-gray-200"
        onClick={e => {
          e.stopPropagation();
          setIsEditOpen(true);
        }}
      >
        <EditIcon className="w-5 h-5 text-[#AFB9CC]" />
      </button>
      <Popover
        anchorEl={ref.current}
        onClose={() => {
          setIsEditOpen(false);
        }}
        isOpen={isEditOpen}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
      >
        <div className="w-96 px-2 min-h-[2rem] max-h-[25rem] overflow-y-auto">
          {isLoading && (
            <div className="w-full h-full flex justify-center items-center p-5">
              <SpinLoader className="animate-spin w-6 h-6 text-orange-500" />
            </div>
          )}
          {!isLoading && (
            <FieldValueView
              from="reporting"
              fieldValue={fieldValue}
              onChange={async fv => {
                if (fv.value.referenceValue) {
                  await update(fv.value.referenceValue.value);
                  return;
                }
                await update(fv.value.value);
              }}
              hideHeading
              autoFocus
            />
          )}
        </div>
      </Popover>
    </div>
  );
}

ReportingFieldAnswer.defaultProps = {
  triggerAI: undefined,
  prefixIcon: undefined,
};

export default ReportingFieldAnswer;
