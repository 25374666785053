import React from 'react';
import { NoteUser as INoteUser } from 'note-service/note-service';
import { NoteTemplateModifiedBy } from 'api/note-templates/get-note-templates';
import styles from './UserAvatar.module.css';
import Tooltip from './Tooltip';

function UserAvatar({ size, user }: { size: 'lg' | 'sm'; user: INoteUser | NoteTemplateModifiedBy }) {
  let initial = '?';
  let color = '';
  if (user.id === undefined) {
    color = '#fcba03';
  } else {
    if (user.firstName && user.lastName) {
      initial = `${user.firstName[0]}${user.lastName[0]}`;
    }
    color = user.colour;
  }
  let bgCls = '';
  let textCls = '';
  if (size === 'lg') {
    bgCls = 'w-8 h-8';
    textCls = 'text-base';
  } else {
    bgCls = 'w-5 h-5 pt-[2px]';
    textCls = 'text-xs';
  }
  return (
    <Tooltip title={`${user.firstName} ${user.lastName}`} placement="top">
      <div className={`${styles.user} ${bgCls}`} style={{ backgroundColor: color }}>
        <div className={`${textCls}`}>{initial}</div>
      </div>
    </Tooltip>
  );
}

export default UserAvatar;
