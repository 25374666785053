import React from 'react';

interface Props {
  className?: string;
}
function WarningIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8112 4.6959L3.69489 16.4968C3.54822 16.7489 3.47056 17.0351 3.46973 17.3267C3.46891 17.6183 3.54494 17.905 3.69018 18.1578C3.83637 18.4115 4.04623 18.6226 4.29901 18.7704C4.5518 18.9181 4.83875 18.9973 5.13152 19.0001H19.3642C19.657 18.9973 19.944 18.9181 20.1967 18.7704C20.4495 18.6226 20.6594 18.4115 20.8056 18.1578C20.9508 17.905 21.0269 17.6183 21.026 17.3267C21.0252 17.0351 20.9475 16.7489 20.8009 16.4968L13.6845 4.6959C13.5336 4.45041 13.3224 4.24768 13.0709 4.10705C12.8194 3.96642 12.536 3.89258 12.2479 3.89258C11.9597 3.89258 11.6764 3.96642 11.4249 4.10705C11.1734 4.24768 10.9621 4.45041 10.8112 4.6959V4.6959Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0332 9.35156V11.9208"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0332 14.0605V14.4888"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

WarningIcon.defaultProps = {
  className: '',
};

export default WarningIcon;
