import React from 'react';

interface Props {
  className?: string;
}

function OpportunityIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M40 12H22L18 8H8C5.8 8 4 9.8 4 12V20H44V16C44 13.8 42.2 12 40 12Z" fill="#FA9405" />
      <path
        d="M40 12H8C5.8 12 4 13.8 4 16V36C4 38.2 5.8 40 8 40H40C42.2 40 44 38.2 44 36V16C44 13.8 42.2 12 40 12Z"
        fill="#FCB95B"
      />
      <rect x="12" y="14" width="24" height="24" rx="2" fill="#FCB95B" />
      <mask id="mask0_12270_151096" maskUnits="userSpaceOnUse" x="12" y="14" width="24" height="24">
        <rect x="12" y="14" width="24" height="24" rx="2" fill="white" />
      </mask>
      <g mask="url(#mask0_12270_151096)">
        <path
          d="M29.6057 31.3125H18.3963C18.1307 31.3125 17.8916 31.5516 17.8916 31.8172V31.8438C17.8916 32.7203 18.6088 33.4375 19.4854 33.4375H28.5166C29.3932 33.4375 30.1104 32.7203 30.1104 31.8438V31.8172C30.1104 31.5516 29.8713 31.3125 29.6057 31.3125Z"
          fill="white"
        />
        <path
          d="M30.9072 20.4219C30.0307 20.4219 29.3135 21.1391 29.3135 22.0156C29.3135 22.4938 29.526 22.9453 29.8713 23.2375C29.4197 24.2734 28.3838 24.9906 27.1619 24.9375C25.7541 24.8578 24.6119 23.7156 24.5322 22.3078C24.5322 22.0687 24.5322 21.8563 24.5854 21.6437C25.1697 21.4047 25.5947 20.8469 25.5947 20.1562C25.5947 19.2797 24.8775 18.5625 24.001 18.5625C23.1244 18.5625 22.4072 19.2797 22.4072 20.1562C22.4072 20.8203 22.8322 21.4047 23.4166 21.6437C23.4697 21.8563 23.4697 22.0687 23.4697 22.3078C23.39 23.7156 22.2479 24.8578 20.84 24.9375C19.6182 25.0172 18.5557 24.2734 18.1307 23.2375C18.476 22.9453 18.6885 22.4938 18.6885 22.0156C18.6885 21.1391 17.9713 20.4219 17.0947 20.4219C16.2182 20.4219 15.501 21.1391 15.501 22.0156C15.501 22.8922 16.2182 23.6094 17.0947 23.6094L17.8385 29.2937C17.865 29.5328 18.0775 29.7188 18.3432 29.7188H29.6588C29.8979 29.7188 30.1104 29.5328 30.1635 29.2937L30.9072 23.6094C31.7838 23.6094 32.501 22.8922 32.501 22.0156C32.501 21.1391 31.7838 20.4219 30.9072 20.4219Z"
          fill="white"
        />
      </g>
    </svg>
  );
}

OpportunityIcon.defaultProps = {
  className: undefined,
};

export default OpportunityIcon;
