import React, { useState } from 'react';

import openModal, { openWarningConfirmModal } from 'components/BaseModal';
import WarningIcon from 'components/icons/WarningIcon';
import { SyncDirection } from '../types';
import { ModalCloseAction, ModalConfirmAction } from '../../../types/modal';

export const openQuestionWarningModal = (numberOfAnswers: number) => {
  return openWarningConfirmModal(
    ``,
    <>
      <p>
        You are about to link a question that has {numberOfAnswers} answers in Superpanel. The answer will be
        transferred to Salesforce by merging both the Salesforce and Superpanel answers.
      </p>
      <p>
        For instance, if the answer from Salesforce is <span className="font-bold">&quot;Salesforce&quot;</span> and the
        answer from Superpanel is <span className="font-bold">&quot;Superpanel&quot;</span>, the combined answer{' '}
        <span className="font-bold">&quot;Salesforce Superpanel&quot;</span> will be upload to Salesforce.
      </p>
      <p>Do you still want to continue?</p>
    </>,
  );
};

function DateWarningBody({
  numberOfAnswers,
  syncDirection,
  onChange,
}: {
  numberOfAnswers: number;
  syncDirection: SyncDirection;
  onChange: (direction: SyncDirection) => void;
}) {
  const [direction, setDirection] = useState<SyncDirection>(syncDirection);
  return (
    <div className="text-sm text-gray-500">
      <p>You are about to link a question that has {numberOfAnswers} answers in Superpanel.</p>
      <p>Please choose one of the following options for synchronizing the answers.</p>
      <div className="mt-4" />
      <select
        className="border rounded px-2 py-1"
        onChange={e => {
          const sd = e.target.value as SyncDirection;
          setDirection(sd);
          onChange(sd);
        }}
        value={direction}
      >
        <option value="PULL_FROM_CRM">Download the answer from Salesforce</option>
        <option value="PUSH_TO_CRM">Upload the answer to Salesforce</option>
      </select>
    </div>
  );
}

export const openDateWarningModal = (numberOfAnswers: number): Promise<SyncDirection | null> => {
  let syncDirection: SyncDirection = 'PULL_FROM_CRM';
  return openModal({
    className: 'w-[32rem]',
    title: (
      <div className="w-12 h-12 rounded-full border-8 border-orange-100 bg-orange-200 flex items-center justify-center">
        <WarningIcon className="text-orange-500" />
      </div>
    ),
    body: (
      <DateWarningBody
        numberOfAnswers={numberOfAnswers}
        syncDirection={syncDirection}
        onChange={d => {
          syncDirection = d;
        }}
      />
    ),
    actions: [
      { label: 'Cancel', action: ModalCloseAction, color: 'secondary' },
      { label: 'Continue', action: ModalConfirmAction, color: 'warning' },
    ],
  }).then(rsp => {
    if (rsp === ModalConfirmAction) {
      return syncDirection;
    }
    return null;
  });
};
