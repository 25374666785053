import React from 'react';

import { Field, isCRMField } from 'types/field-value';
import SalesforceSyncIcon from 'components/icons/SalesforceSyncIcon';
import Tooltip from '../Tooltip';

interface Props {
  field: Field;
}

function QuestionTitle({ field }: Props) {
  const title = field.label;
  return (
    <>
      <div className={`pr-4 ${field.parentId ? 'text-sm text-slate-500' : 'font-medium'}`}>{title}</div>
      <div className="flex items-center flex-wrap gap-2">
        {isCRMField(field) && (
          <div className="flex items-center gap-1 px-1 border rounded-full flex-shrink-0 flex-grow-0 h-fit text-xs text-gray-400">
            <SalesforceSyncIcon className="w-5 h-5" />
            {field.crmField.label}
          </div>
        )}
        {field.tags.length >= 1 && (
          <div className="flex items-center rounded-full px-2 py-0.5 text-xs whitespace-nowrap bg-gray-200 text-gray-500">
            {field.tags[0].tag}
          </div>
        )}
        {field.tags.length > 1 && (
          <Tooltip
            title={
              <div>
                {field.tags.map((tag, index) => {
                  if (index === 0) return null;
                  return <div key={tag.id}>{tag.tag}</div>;
                })}
              </div>
            }
            placement="top"
          >
            <div className="flex items-center py-0 rounded-full px-2 py-0.5 text-xs whitespace-nowrap bg-gray-200 text-gray-500">
              {`+${field.tags.length - 1}`}
            </div>
          </Tooltip>
        )}
      </div>
    </>
  );
}

export default QuestionTitle;
