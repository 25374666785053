import React from 'react';

import Salesforce from 'components/logos/Salesforce';
import Superpanel from 'components/logos/Superpanel';
import Gmail from 'components/logos/Gmail';
import MicrosoftCalendar from 'components/logos/MicrosoftCalendar';

import getOauthLink from 'utils/get-oauth-link';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getRedirectUrl } from 'utils/redirect-after-login';
import FadeIn from 'components/FadeIn';

function Login() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const stateError = searchParams.get('state');
  const redirectUrl = getRedirectUrl() || '';
  const canRegister =
    redirectUrl.startsWith('/document') ||
    redirectUrl.startsWith('/team-member-requests') ||
    redirectUrl.startsWith('/account') ||
    redirectUrl.startsWith('/opportunity');

  let errorEle: JSX.Element | null = null;
  if (stateError === 'UserNotExistError') {
    errorEle = (
      <FadeIn isOpen>
        <div className="text-sm font-medium text-red-500">User does not exist</div>
      </FadeIn>
    );
  }

  return (
    <div className="flex h-screen">
      <div className="relative w-1/2 h-full flex flex-col gap-8 justify-center items-center">
        <div className="absolute top-12 left-9 flex items-center gap-2">
          <Superpanel className="w-10 h-10 p-1.5 border rounded" />
          <div className="text-xl font-semibold">Superpanel</div>
        </div>
        <div className="flex flex-col gap-2 justify-center items-center">
          <div className="text-2xl font-semibold">Sign in</div>
        </div>
        {errorEle}
        <a
          className="border rounded-lg w-2/3 px-10 py-3 flex items-center justify-center bg-blue-200"
          href={getOauthLink('salesforce', 'login')}
        >
          <Salesforce className="w-9 h-9 mr-3" />
          <span className="font-medium text-gray-700">Continue with Salesforce</span>
        </a>
        <div className="text-gray-400 font-medium">Or without CRM</div>

        <a
          className="border rounded-lg w-2/3 px-10 py-3 flex items-center justify-center"
          href={getOauthLink('google', 'login')}
        >
          <Gmail className="w-9 h-9 mr-3" />
          <span className="font-medium">Continue with Google</span>
        </a>
        <a
          className="border rounded-lg w-2/3 px-10 py-3 flex items-center justify-center"
          href={getOauthLink('microsoft', 'login')}
        >
          <MicrosoftCalendar className="w-9 h-9 mr-3" />
          <span className="font-medium">Continue with Microsoft</span>
        </a>
        {canRegister && (
          <button
            type="button"
            className="text-orange-400 px-4 py-2 hover:bg-gray-100 rounded-lg"
            onClick={() => {
              navigate('/register', { state: { onlySaleForce: true } });
            }}
          >
            Don&apos;t have account? Sign up with Salesforce
          </button>
        )}
        <div className="absolute bottom-8 left-8 text-gray-500 text-sm">© Superpanel 2022</div>
      </div>
      <div className="w-1/2 h-full">
        <img className="w-full h-full" src="/images/background.jpeg" alt="Superpanel" />
      </div>
    </div>
  );
}

export default Login;
