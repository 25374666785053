import { ArrowLeftIcon, ChevronRightIcon, PlayIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactPlayer from 'react-player';

import Loader from 'components/Loader';

import useRecordingsStore from 'stores/recordings';

import { Recording, SPParty, SPSentence } from 'types/recording';

import styles from './RecordingDetail.module.css';
import useQueryParamState from 'components/Hooks/useQueryParamState';
import constants from 'utils/constants';
import { concat } from 'utils/styling';
import UserProfileAvatar from 'components/UserProfileAvatar';
import { groupBy, keys, sortBy, values } from 'lodash';
import { AVATAR_BG_COLORS } from 'pages/Document/DocumentView/MeetingAttendees';

const isPartyInternal = (party: SPParty) => {
  return party.affiliation === 'Internal' || party.affiliation === 'rep';
};

interface Props {
  recording: Recording;
  onClose: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onPlay = (timeToStart: number, player: any) => {
  // it's weird that without setTimeout, the firsttime a play is triggerred from the meeting note suggested question won't work
  // setTimeout(()=> {
  player?.current?.seekTo(timeToStart, 'seconds');
  // }, 500)
  // player?.current?.seekTo(parseFloat(timeToStart), "seconds")
};

function formatSeconds(seconds: number) {
  if (seconds < 3600) {
    return new Date(seconds * 1000).toISOString().substring(14, 19);
  }
  return new Date(seconds * 1000).toISOString().substring(11, 19);
}

function findSentenceStartTime(transcript: SPSentence[], startTime: number): number | null {
  for (let i = 0; i < transcript.length; i += 1) {
    const { sentences } = transcript[i];
    for (let j = 0; j < sentences.length; j += 1) {
      const sentence = sentences[j];
      if (sentence.start <= startTime && startTime <= sentence.end) {
        return sentences[0].start;
      }
    }
  }
  return null;
}

function RecordingDetail({ recording, onClose }: Props) {
  const recordingsStore = useRecordingsStore();
  const [isReady, setIsReady] = useState<boolean>(false);
  const [videoLoadErr, setVideoLoadErr] = useState<boolean>(false);
  const player = useRef<ReactPlayer>(null);
  const [, setDrawer] = useQueryParamState(constants.RIGHT_DRAWER_QUERY_PARAM_KEY);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const refs = React.useRef<{ [key: number]: any }>({});

  useEffect(() => {
    if (recordingsStore.startTime) {
      // if param contains start-time, we jump to the start-time and play
      onPlay(recordingsStore.startTime / 1000, player);
      recordingsStore.setState({ playing: true });
    }
    return () => {
      recordingsStore.setState({
        isLoading: false,
        playing: false,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady, recordingsStore.startTime]);

  useEffect(() => {
    return () => {
      recordingsStore.setState({
        isLoading: false,
        playing: false,
        startTime: undefined,
        recordingId: undefined,
        fromContext: undefined,
        elementId: undefined,
      });
    };
  }, []);

  useEffect(() => {
    if (recordingsStore.startTime && recording.transcriptData && refs) {
      const startTime = findSentenceStartTime(recording.transcriptData.transcript, recordingsStore.startTime);
      if (!startTime) {
        return;
      }
      const ref = refs.current[startTime];
      if (ref) {
        ref.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordingsStore.startTime, refs]);

  const parties = useMemo(() => {
    const allParties: { [speakerId: string]: SPParty & { color: string } } = {};
    const speakingParties = keys(groupBy(recording.transcriptData?.transcript, 'speakerId'));
    speakingParties.forEach(partyId => {
      const partyInfo = recording.callData.parties.find(party => party.speakerId === partyId);
      if (partyInfo) {
        allParties[partyId] = {
          ...partyInfo,
          color: '',
        };
      }
    });
    const partyIds = keys(allParties);
    const allInternal = partyIds.every(id => isPartyInternal(allParties[id]));
    const allExternal = partyIds.every(id => !isPartyInternal(allParties[id]));
    // if all parties are internal, just set the first party at left('Internal')
    if (allInternal) {
      partyIds.forEach((id, index) => {
        allParties[id].affiliation = index === 0 ? 'Internal' : 'External';
      });
    }
    // if all parties are external, just set the first party at left('Internal')
    if (allExternal) {
      partyIds.forEach((id, index) => {
        allParties[id].affiliation = index === 0 ? 'Internal' : 'External';
      });
    }
    // set color for each party
    sortBy(values(allParties), item => (isPartyInternal(item) ? 0 : 1)).forEach((item, index) => {
      // eslint-disable-next-line no-param-reassign
      item.color = AVATAR_BG_COLORS[index % AVATAR_BG_COLORS.length];
    });
    return allParties;
  }, [recording]);

  let playerElement: null | JSX.Element = null;

  let mediaUrl: null | string = null;
  if (recording.callData.videoUrl) {
    mediaUrl = recording.callData.videoUrl;
  } else if (recording.callData.audioUrl) {
    mediaUrl = recording.callData.audioUrl;
  }
  if (recording.isRecordingBeingDownloaded) {
    playerElement = (
      <div key="player-loader" className="flex flex-col items-center justify-center w-full h-[100px] mb-3">
        <div className="flex">
          <Loader className="w-12 h-12" />
        </div>
        <div className="text font-medium text-gray-500">This recordings is being processed.</div>
      </div>
    );
  } else if (mediaUrl) {
    playerElement = (
      <div className="flex flex-col items-center">
        {!isReady && !videoLoadErr && recording.callType === 'superpanel' && <Loader className="w-12 h-12" />}
        {/* prevent video theme jump and just hide it when not loaded */}
        <div
          className={concat(
            'w-full flex items-center',
            !isReady && recording.callType === 'superpanel' && 'opacity-0 h-[80px]',
          )}
        >
          <ReactPlayer
            width={476}
            ref={player}
            url={mediaUrl}
            height={recording.callType === 'superpanel' ? '80px' : '100%'}
            controls
            playing={recordingsStore.playing}
            onReady={() => setIsReady(true)}
            onError={() => setVideoLoadErr(true)}
          />
        </div>
        {videoLoadErr && (
          <div className={concat(styles.error)}>
            {recording.callType === 'superpanel' ? 'Failed to load audio!' : 'Failed to load video!'}
          </div>
        )}
      </div>
    );
  }

  const dialogElements: null | JSX.Element[] = [];
  if (recording.transcriptData) {
    recording.transcriptData.transcript.forEach(sentenceGroup => {
      // the sentenceGroup is empty, we don't render it
      if (sentenceGroup.sentences.length === 0) {
        return;
      }
      let party: (SPParty & { color: string }) | null = null;

      if (sentenceGroup.speakerId) {
        party = parties[sentenceGroup.speakerId];
      }

      const button = (
        <button
          className="flex-none w-8 h-8 flex justify-center items-center bg-blue-500 rounded-full opacity-0 group-hover:opacity-100"
          onClick={() => {
            if (mediaUrl !== null) {
              recordingsStore.setState({ startTime: sentenceGroup.sentences[0].start });
            }
          }}
          type="button"
        >
          <PlayIcon className="fill-slate-50 stroke-transparent w-5 h-5" />
        </button>
      );

      if (party && isPartyInternal(party)) {
        dialogElements.push(
          <div
            key={sentenceGroup.sentences[0].start}
            ref={ele => {
              refs.current[sentenceGroup.sentences[0].start] = ele;
            }}
            className="flex justify-end mb-3 group"
            // ref={previousSpeaker !== phrase.speaker ? null : (ref.ref as React.RefObject<HTMLDivElement>)}
          >
            <div className="flex items-center gap-2">
              {button}
              <div className={`${styles['box-right']} text-sm font-normal`}>
                <div className={`${styles.box}`}>{sentenceGroup.sentences.map(s => s.text).join(' ')}</div>
              </div>
            </div>
            <div className="flex-none">
              <UserProfileAvatar
                size={32}
                userInitial={party ? undefined : '?'}
                fullName={party?.name ?? 'Unkown Person'}
                className="text-white"
                bgColor={party?.color ?? AVATAR_BG_COLORS[AVATAR_BG_COLORS.length - 1]}
                tooltipPlacement="left"
              />
              <div className="text-gray-400 text-xs">{formatSeconds(sentenceGroup.sentences[0].start / 1000)}</div>
            </div>
          </div>,
        );
      } else {
        dialogElements.push(
          <div
            key={sentenceGroup.sentences[0].start}
            ref={ele => {
              refs.current[sentenceGroup.sentences[0].start] = ele;
            }}
            className="flex mb-3 group"
            // ref={previousSpeaker !== phrase.speaker ? null : (ref.ref as React.RefObject<HTMLDivElement>)}
          >
            <div className="flex-none">
              <UserProfileAvatar
                size={32}
                userInitial={party ? undefined : '?'}
                fullName={party?.name ?? 'Unkown Person'}
                className="text-white"
                tooltipPlacement="right"
                bgColor={party?.color ?? AVATAR_BG_COLORS[AVATAR_BG_COLORS.length - 1]}
              />
              <div className="text-gray-400 text-xs">{formatSeconds(sentenceGroup.sentences[0].start / 1000)}</div>
            </div>

            <div className="flex items-center gap-2">
              <div className={`${styles['box-left']} text-sm font-normal`}>
                <div className={`${styles.box}`}>{sentenceGroup.sentences.map(s => s.text).join(' ')}</div>
              </div>
              {button}
            </div>
          </div>,
        );
      }
    });
  } else {
    dialogElements.push(
      <div key="transcript-loader" className="flex flex-col items-center justify-center w-full h-[100px] mb-3">
        <div className="flex">
          <Loader className="w-12 h-12" />
        </div>
        <div className="text font-medium text-gray-500">Generating transcript.</div>
      </div>,
    );
  }

  let backButton: null | JSX.Element = null;
  if (recordingsStore.fromContext === 'docEditor') {
    backButton = (
      <button
        type="button"
        className="flex gap-1 hover:bg-gray-200 px-1 rounded-md hover:cursor-pointer items-center"
        onClick={() => {
          recordingsStore.setState({ recordingId: undefined });
        }}
      >
        <ArrowLeftIcon className="w-5 h-5 text-gray-400" />
        <div className="text-gray-400">Recordings</div>
      </button>
    );
  } else if (recordingsStore.fromContext === 'chat') {
    backButton = (
      <button
        type="button"
        className="flex gap-1 hover:bg-gray-200 px-1 rounded-md hover:cursor-pointer items-center"
        onClick={() => {
          setDrawer('chat');
        }}
      >
        <ArrowLeftIcon className="w-5 h-5 text-gray-400" />
        <div className="text-gray-400">AI Chat</div>
      </button>
    );
  }
  return (
    <div className="px-3 text-sm flex flex-col h-full">
      {/* <div className="sticky top-0 z-10 bg-white"> */}
      <div className="flex flex-col">
        <div className="flex justify-between py-2">
          <div className="flex justify-between items-center gap-1">
            {backButton}
            <ChevronRightIcon className="w-5 h-5 text-gray-400" />
            <div className="">{recording?.callData.title}</div>
          </div>
          <div>
            <button
              type="button"
              className="w-8 h-8 p-2 text-gray-400 bg-gray-100 rounded-lg"
              onClick={() => {
                onClose();
              }}
            >
              <XMarkIcon />
            </button>
          </div>
        </div>
        <div className="mb-2"> {playerElement}</div>
      </div>

      <div className="overflow-auto"> {dialogElements}</div>
    </div>
  );
}

export default RecordingDetail;
