import { Menu } from '@mui/material';
import EmojiPicker, { Emoji } from 'emoji-picker-react';
import React, { useRef, useState } from 'react';

interface Props {
  onAddEmoji: (emojiIcon: string) => void;
  currentIcon: string | null;
  readOnly: boolean;
}

function EmojiPickerComp({ onAddEmoji, currentIcon, readOnly }: Props) {
  const [state, setState] = useState<boolean>(false);

  const iconButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <button
        type="button"
        ref={iconButtonRef}
        className={`flex items-center p-1 ${readOnly ? 'hover:cursor-default' : 'hover:bg-gray-100 rounded'}`}
        onClick={() => {
          if (readOnly) return;
          setState(true);
        }}
      >
        {currentIcon ? (
          <span className="text-xl flex items-center">
            <Emoji unified={currentIcon} size={22} />
          </span>
        ) : (
          <Emoji unified="1f5d2-fe0f" size={22} />
        )}
      </button>
      <Menu anchorEl={iconButtonRef.current} open={state} onClose={() => setState(false)}>
        <div>
          <EmojiPicker
            onEmojiClick={event => {
              onAddEmoji(event.unified);
              setState(false);
            }}
          />
        </div>
      </Menu>
    </>
  );
}

export default EmojiPickerComp;
