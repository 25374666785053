import React from 'react';

import Gmail from 'components/logos/Gmail';

import config from 'utils/config';

function AdminLogin() {
  const OAUTH_LINK = `https://accounts.google.com/o/oauth2/auth?access_type=offline&client_id=${config.ADMIN_GOOGLE_CLIENT_ID}&include_granted_scopes=true&redirect_uri=${config.ADMIN_GOOGLE_REDIRECT_URL}&response_type=code&scope=https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile`;

  return (
    <div className="flex h-screen">
      <div className="relative w-1/2 h-full flex flex-col gap-8 justify-center items-center">
        <div className="flex flex-col gap-2 justify-center items-center">
          <div className="text-2xl font-semibold">Sign in</div>
        </div>
        <a className="border rounded-lg w-2/3 px-10 py-3 flex items-center justify-center" href={OAUTH_LINK}>
          <Gmail className="w-9 h-9 mr-3" />
          <span className="font-medium">Continue with Google</span>
        </a>
        <div className="absolute bottom-8 left-8 text-gray-500 text-sm">© Superpanel 2022</div>
      </div>
    </div>
  );
}

export default AdminLogin;
