import React, { useCallback, useEffect, useRef, useState } from 'react';
import Popover from 'components/Popover';
import getSalesforceEvent from '../../../api/salesforce/get-salesforce-event';
import useDocumentStore from '../../../stores/document';
import { SalesforcePicklistValue } from '../../../types/salesforce';
import BaseButton from 'components/BaseButton';
import MeetingIcon from 'components/icons/MeetingIcon';
import { EnvelopeIcon, InformationCircleIcon, PhoneIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { concat } from 'utils/styling';

interface State {
  isMenuOpen: boolean;
  eventType: string | null;
  picklistValues: SalesforcePicklistValue[];
  isLoading: boolean;
  insufficientPermissions: boolean;
}

interface Props {
  documentId: string;
  className?: string;
}

function EventActivitySelect({ documentId, className }: Props) {
  const [state, setState] = useState<State>({
    isMenuOpen: false,
    eventType: null,
    picklistValues: [],
    isLoading: false,
    insufficientPermissions: false,
  });

  const documentStore = useDocumentStore();

  useEffect(() => {
    const fetchEventTypes = async (docId: string) => {
      setState(prevState => ({ ...prevState, isLoading: true }));
      const { eventType, eventTypeList, insufficientPermissions } = await getSalesforceEvent(docId);
      setState(prevState => ({
        ...prevState,
        eventType,
        picklistValues: eventTypeList,
        isLoading: false,
        insufficientPermissions,
      }));
    };

    fetchEventTypes(documentId);
  }, [documentId]);

  const ref = useRef<HTMLButtonElement>(null);

  const addEventType = async (picklistValue: SalesforcePicklistValue) => {
    setState(prevState => ({ ...prevState, isLoading: true, isMenuOpen: false }));
    await documentStore.updateDocumentEventType(documentId, picklistValue.value);
    setState(prevState => ({ ...prevState, eventType: picklistValue.value, isLoading: false }));
  };

  const getEventIcon = useCallback((eventType: string) => {
    switch (eventType.trim().toLowerCase()) {
      case 'email':
        return <EnvelopeIcon className="min-w-[1.25rem] w-5 h-5 " />;
      case 'call':
        return <PhoneIcon className="min-w-[1.25rem] w-5 h-5 " />;
      case 'meeting':
        return <MeetingIcon className="min-w-[1.25rem] w-5 h-5 " />;
      case 'other':
        return <InformationCircleIcon className="min-w-[1.25rem] w-5 h-5 " />;
      case '':
        return <QuestionMarkCircleIcon className="min-w-[1.25rem] w-5 h-5 " />;
      default:
        return (
          <div className="min-w-[1.25rem] w-5 h-5 rounded-full border uppercase pl-[4px] pt-[1px]">{eventType[0]}</div>
        );
    }
  }, []);

  return (
    <>
      <BaseButton
        ref={ref}
        color="secondary"
        variant="outlined"
        onClick={e => {
          e.stopPropagation();
          if (!state.insufficientPermissions) {
            setState(prevState => ({ ...prevState, isMenuOpen: true }));
          }
        }}
        className={concat('!pl-1.5 !pr-1.5 !text-sm !font-normal', className)}
        loading={state.isLoading}
        tooltip={
          // eslint-disable-next-line no-nested-ternary
          state.eventType
            ? `Event Type: ${state.eventType}`
            : state.insufficientPermissions
            ? 'Insufficient permission. Please ask your Salesforce admin to grant access to FieldDefinition object'
            : 'Select Event Type'
        }
        tooltipPlacement="bottom"
      >
        {getEventIcon(state.eventType ?? '')}
        {state.insufficientPermissions && <span className="flex w-full text-orange-500 truncate">Error</span>}
        {!state.insufficientPermissions && (
          <span className="w-full truncate">{state.eventType ? state.eventType : 'Event Type'}</span>
        )}
      </BaseButton>
      <Popover
        anchorEl={ref.current}
        onClose={() => setState(prevState => ({ ...prevState, isMenuOpen: false }))}
        isOpen={state.isMenuOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        stopPropagationWhenClick
      >
        <div style={{ width: 200 }}>
          <div className="text-sm">
            {state.picklistValues.length === 0 ? (
              <button
                key="Please contact Superpanel to add activity link"
                type="button"
                className="relative flex justify-between items-center w-full text-left p-2 rounded-lg hover:bg-slate-100"
              >
                <span>Please contact Superpanel to add activity link</span>
              </button>
            ) : (
              <div className="p-2">
                {state.picklistValues.map(eventType => (
                  <button
                    key={eventType.value}
                    type="button"
                    className="relative flex items-center w-full text-left p-2 rounded-lg hover:bg-slate-100 gap-2 text-gray-500"
                    onClick={() => addEventType(eventType)}
                  >
                    {getEventIcon(eventType.value)}
                    <span>{eventType.label}</span>
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
      </Popover>
    </>
  );
}

EventActivitySelect.defaultProps = {
  className: '',
};

export default EventActivitySelect;
