import httpClient from '../http-client';

import { SalesforceFieldType, SalesforcePicklistValue } from 'types/salesforce';

export interface SalesforceField {
  label: string;
  name: string;
  order: number;
  isEditable: boolean;
  selected: boolean;
  type: SalesforceFieldType;
  durableId: string;
  picklistValues: SalesforcePicklistValue[];
}

export interface Response {
  opportunityFields: SalesforceField[];
  accountFields: SalesforceField[];
}

export default async (): Promise<Response> => {
  const { data } = await httpClient.get<Response>(`/salesforce/fields`);
  return data;
};
