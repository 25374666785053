import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ChevronDownIcon, ChevronRightIcon, PlusIcon } from '@heroicons/react/24/outline';
import { Link, useLocation } from 'react-router-dom';

import NoteIcon from 'components/icons/NoteIcon';
import Popover from 'components/Popover';
import OpportunityIcon from 'components/icons/OpportunityIcon';

import useDocumentStore from 'stores/document';

import { EntityDocument } from 'types/entity';

import orderDocuments from 'utils/order-documents';

import { MenuItem } from '@mui/material';
import FadeIn from 'components/FadeIn';
import getTemplateToPopulate from 'utils/get-template-to-populate';
import { Template } from 'api/note-templates/get-note-templates';
import useNavMenuStore from '../../stores/nav-menu';
import useModalStore from 'stores/modal';
import CrmNoteLink from './CrmNoteLink';
import DocumentLink from './DocumentLink';
import TemplateLink from './TemplateLink';
import { useNewDocumentModal } from '../NewDocumentModal';
import UploadDocumentModal from './UploadDocumentModal';
import { Document } from 'types/document';

interface Props {
  opportunity: EntityDocument;
  noteTemplates: Template[];
}

function Deal({ opportunity, noteTemplates }: Props) {
  const [isNewOpen, setIsNewOpen] = useState<boolean>(false);

  const newButtonRef = useRef<HTMLButtonElement>(null);
  const dealLinkRef = useRef<HTMLDivElement>(null);

  const location = useLocation();

  const documentStore = useDocumentStore();
  const navMenuStore = useNavMenuStore();
  const { createNewDocumentWithModal } = useNewDocumentModal();
  const modalStore = useModalStore();

  const isOpen = useMemo(
    () => navMenuStore.entitiesOpen.includes(opportunity.id),
    [navMenuStore.entitiesOpen, opportunity.id],
  );
  const path = `/opportunity/${opportunity.id}`;

  useEffect(() => {
    if (dealLinkRef.current && location.pathname.match(path)) {
      navMenuStore.setState({ activeEntityRef: dealLinkRef });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealLinkRef, location.pathname]);

  const documents = useMemo(
    () =>
      documentStore.documents.filter(
        d => d.parent && d.parent.entityName === 'Opportunity' && d.parent.id === opportunity.id,
      ),
    [documentStore.documents, opportunity],
  );
  const crmNotes = useMemo(
    () =>
      documentStore.crmNotes.filter(
        c => c.linkedEntity.name === 'Opportunity' && c.linkedEntity.id === opportunity.crm?.id,
      ),
    [documentStore.crmNotes, opportunity],
  );
  const outcaseTemplates = getTemplateToPopulate(noteTemplates, documents);

  const opportunityItems = useMemo(() => orderDocuments(documents, crmNotes), [documents, crmNotes]);
  const filterExist = useMemo(() => documentStore.documentFilters.length > 0, [documentStore.documentFilters]);

  return (
    <div ref={dealLinkRef} className="relative flex flex-col mb-0.5 items-stretch bg-white scroll-m-36">
      <Link
        className={`group flex justify-between items-center gap-0.5 p-0.5 rounded overflow-x-clip hover:bg-gray-200 sticky top-10
        z-10 hover:text-black ${
          location.pathname.match(path)
            ? 'text-blue-600 bg-gray-100 font-semibold'
            : 'text-gray-500 bg-white font-medium'
        }`}
        to={path}
        style={{ textDecoration: 'none' }}
      >
        <button
          className="flex items-center rounded flex-grow-0 flex-shrink-0"
          onClick={event => {
            event.preventDefault();
            if (isOpen) {
              navMenuStore.removeEntityOpen(opportunity.id);
            } else {
              navMenuStore.addEntityOpen([opportunity.id]);
            }
          }}
          type="button"
        >
          {!isOpen ? (
            <ChevronRightIcon className="w-5 h-5 stroke-2 text-gray-400 font-medium hover:bg-gray-300 rounded p-1" />
          ) : (
            <ChevronDownIcon className="w-5 h-5 stroke-2 text-gray-400 font-medium hover:bg-gray-300 rounded p-1" />
          )}{' '}
        </button>
        <div className="flex items-center gap-2 flex-1 w-1">
          <OpportunityIcon className="min-w-[1.5rem] w-6 h-6 text-orange-400 fill-orange-400" />
          <span className="text-sm truncate">{opportunity.name}</span>
          <span className="text-sm text-gray-400 font-light">({Object.keys(opportunityItems).length})</span>
        </div>
        <div className="flex items-center">
          <button
            ref={newButtonRef}
            type="button"
            className={`w-6 h-6 text-gray-400 hover:bg-gray-300 rounded p-1 ${
              !isNewOpen && 'opacity-0'
            } group-hover:opacity-100`}
            onClick={event => {
              event.preventDefault();
              setIsNewOpen(prevState => !prevState);
            }}
          >
            <PlusIcon />
          </button>
        </div>
        {!opportunity.isFilter && filterExist && (
          <div className="absolute right-6 w-9 px-1 text-xs text-gray-400 bg-gray-100 border rounded-lg border-gray-400 line-through">
            filter
          </div>
        )}
      </Link>
      <FadeIn isOpen={isOpen}>
        {isOpen ? (
          <div>
            {outcaseTemplates.map(template => (
              <TemplateLink key={template.noteTemplateId} oppId={opportunity.id} template={template} />
            ))}
            {opportunityItems.map(element => {
              if ('docTitle' in element) {
                return (
                  <DocumentLink key={element.id} document={element} ml="ml-[28px] pl-1" addPaddingOffSet={false} />
                );
              }
              return <CrmNoteLink key={element.id} crmNote={element} ml="ml-[28px] pl-1" addPaddingOffSet={false} />;
            })}
          </div>
        ) : null}
      </FadeIn>

      <Popover
        isOpen={isNewOpen}
        anchorEl={newButtonRef.current}
        onClose={() => setIsNewOpen(false)}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <div>
          <MenuItem
            className="p-2 flex items-center gap-2"
            onClick={async () => {
              createNewDocumentWithModal({ parentId: opportunity.id, title: null });
              setIsNewOpen(false);
            }}
          >
            <NoteIcon className="w-5 h-5 text-gray-500" />
            <div className="text-sm text-gray-600">Create a new document</div>
          </MenuItem>
          <MenuItem
            className="p-2 flex items-center gap-2"
            onClick={() => {
              modalStore.setState({
                element: (
                  <UploadDocumentModal
                    parentDocumentId={opportunity.id}
                    siblingDocuments={opportunityItems as Document[]}
                  />
                ),
                isOpen: true,
              });
              setIsNewOpen(false);
            }}
          >
            <NoteIcon className="w-5 h-5 text-gray-500" />
            <div className="text-sm text-gray-600">Upload document</div>
          </MenuItem>
        </div>
      </Popover>
    </div>
  );
}

export default Deal;
