import React from 'react';

interface Props {
  className?: string;
}

function CodeIcon({ className }: Props) {
  return (
    <svg className={className} viewBox="0 0 30 30" fill="currentColor">
      <path d="M11.625,4L0,15l11.625,11L13,24.563L2.906,15L13,5.438L11.625,4z M18.375,4L17,5.438L27.094,15L17,24.563L18.375,26L30,15L18.375,4z" />
    </svg>
  );
}

CodeIcon.defaultProps = {
  className: undefined,
};

export default CodeIcon;
