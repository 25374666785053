import { CheckIcon, ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React, { useMemo, useRef, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import useReportingStore from 'stores/reporting';
import useModalStore from '../../../../stores/modal';
import SpinLoader from '../../../icons/SpinLoader';
import Popover from '../../../Popover';

function capitalizeFirstLetter(value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

interface Props {
  fieldId: string;
  fieldLabel: string;
}
function CreateAnalyticColumnModal({ fieldId, fieldLabel }: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDataTypeMenuOpen, setIsDataTypeMenuOpen] = useState<boolean>(false);
  const [columnName, setColumnName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [dataType, setDataType] = useState<'number' | 'boolean' | 'string' | ''>('');

  const dataTypeButtonRef = useRef<HTMLButtonElement>(null);
  const modalStore = useModalStore();
  const reportingStore = useReportingStore();

  const [submitClicked, setSubmitClicked] = useState(false);
  const isDisabled = useMemo(
    () => isLoading || !dataType || !columnName.trim() || !description,
    [isLoading, dataType, columnName, description],
  );

  return (
    <div className="w-[30rem]">
      <div className="flex items-center justify-between p-5 border-b">
        <div className="text-xl font-medium">Add new column</div>
        <button
          type="button"
          className="w-7 h-7 p-1 rounded-lg text-gray-400 bg-gray-100 hover:bg-gray-200"
          onClick={modalStore.close}
        >
          <XMarkIcon />
        </button>
      </div>
      <div className="p-5">
        <div className="text-sm font-medium">Add new column for &quot;{fieldLabel}&quot;?</div>
        <div className="mt-4">
          <div className="text-xs px-2 text-gray-500">
            <span>Column Name&nbsp;</span>
            {submitClicked && !columnName.trim() && (
              <span role="alert" className="text-red-500">
                (Required)
              </span>
            )}
          </div>
          <input
            className="border rounded w-full p-2"
            value={columnName}
            onChange={e => setColumnName(e.target.value)}
            maxLength={100}
          />
        </div>
        <div className="mt-4">
          <div className="text-xs px-2 text-gray-500">
            <span>Data Type&nbsp;</span>
            {submitClicked && !dataType && (
              <span role="alert" className="text-red-500">
                (Required)
              </span>
            )}
          </div>
          <div>
            <button
              ref={dataTypeButtonRef}
              type="button"
              className="border rounded w-full p-2 flex justify-between h-9"
              onClick={() => setIsDataTypeMenuOpen(true)}
            >
              <div className="text-sm">
                {capitalizeFirstLetter(dataType !== 'boolean' ? dataType : 'True or False')}
              </div>
              <ChevronDownIcon className="w-4 h-4" />
            </button>
            <Popover
              anchorEl={dataTypeButtonRef.current}
              onClose={() => setIsDataTypeMenuOpen(false)}
              isOpen={isDataTypeMenuOpen}
              transformOrigin={{ horizontal: 'left', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            >
              <div style={{ width: `${dataTypeButtonRef.current?.getBoundingClientRect().width || 200}px` }}>
                <MenuItem
                  onClick={() => {
                    setDataType('string');
                    setIsDataTypeMenuOpen(false);
                  }}
                  disabled={dataType === 'string'}
                >
                  <div className="text-sm flex justify-between items-center">
                    <div>String</div>
                    {dataType === 'string' && <CheckIcon className="ml-2 w-4 h-4 text-green-500" />}
                  </div>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setDataType('number');
                    setIsDataTypeMenuOpen(false);
                  }}
                  disabled={dataType === 'number'}
                >
                  <div className="text-sm flex justify-between items-center">
                    <div>Number</div>
                    {dataType === 'number' && <CheckIcon className="ml-2 w-4 h-4 text-green-500" />}
                  </div>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setDataType('boolean');
                    setIsDataTypeMenuOpen(false);
                  }}
                  disabled={dataType === 'boolean'}
                >
                  <div className="text-sm flex justify-between items-center">
                    <div>True or False</div>
                    {dataType === 'boolean' && <CheckIcon className="ml-2 w-4 h-4 text-green-500" />}
                  </div>
                </MenuItem>
              </div>
            </Popover>
          </div>
        </div>
        <div className="mt-4">
          <div className="text-xs px-2 text-gray-500">Description</div>
          <textarea
            className="border rounded w-full p-2 resize-none h-24"
            value={description}
            onChange={e => setDescription(e.target.value)}
            placeholder={
              ['string', 'number'].includes(dataType)
                ? 'Help AI understand this column\n\nExplicitly state how to extract the key knowledge.'
                : 'Help AI understand this column\n\nExplicitly state the condition for True and False, respectively.'
            }
            maxLength={600}
          />
        </div>
        <div className="flex justify-end items-center gap-2 mt-5">
          <button
            type="button"
            className="px-4 py-2 bg-gray-100 hover:bg-gray-200 text-gray-500 rounded"
            onClick={modalStore.close}
          >
            Cancel
          </button>
          <button
            type="button"
            className={`flex items-center gap-2 px-4 py-2 text-white rounded ${
              isDisabled ? 'bg-gray-400 cursor-default' : 'bg-blue-600 hover:bg-blue-400 '
            }`}
            onClick={async () => {
              if (!isDisabled && dataType) {
                setIsLoading(true);
                await reportingStore.createAnalyticTableColumn(
                  fieldId,
                  columnName.trim(),
                  description.trim(),
                  dataType,
                );
                setIsLoading(false);
                await modalStore.close();
              } else {
                setSubmitClicked(true);
              }
            }}
            disabled={isLoading}
          >
            {isLoading && <SpinLoader className="animate-spin w-4 h-4" />}
            Create
          </button>
        </div>
      </div>
    </div>
  );
}

export default CreateAnalyticColumnModal;
