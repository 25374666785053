import React from 'react';
import { Link } from 'react-router-dom';
import useModalStore from 'stores/modal';
import BaseButton from '../../components/BaseButton';
import ThreeVerticalDotsIcon from '../../components/icons/ThreeVerticalDotsIcon';

function TeamMemberRequestTutorial() {
  const modalStore = useModalStore();
  return (
    <div className="w-[60rem] p-5">
      <div className="text-xl font-medium">Complete Team Member Request Setup</div>
      <div className="py-5 overflow-y-scroll max-h-[40rem]">
        <div className="underline font-semibold text-lg">How to setup the request form field</div>
        <div className="py-1" />
        <div>
          This step establishes a standardized request form with fields powered by Superpanel AI. Simply visit the field
          management page and choose the desired fields.
        </div>
        <div className="py-2" />
        <div className="px-10">
          <ol>
            <li>
              Navigate to{' '}
              <Link className="text-blue-500 hover:text-blue-400" to="/crm-task" onClick={() => modalStore.close()}>
                CRM Automation Page
              </Link>
            </li>
            <li>
              Edit or create the fields that you want to include to your team member request form.
              <img
                className="w-[50rem]"
                src="/images/team-member-request/edit-field.png"
                alt="Team Member Request - Edit Field"
              />
            </li>
            <li>
              Check the <span className="font-semibold">Use in Team Member Request Form</span> checkbox and Click{' '}
              <span className="font-semibold">Update</span>
              <img
                className="w-[50rem]"
                src="/images/team-member-request/update-field.png"
                alt="Team Member Request - Update Field"
              />
            </li>
            <li>
              Click <span className="font-semibold">Save Fields</span>
            </li>
          </ol>
        </div>
        <div className="py-3" />
        <div className="text-lg underline font-semibold">How to configure user role</div>
        <div className="py-1" />
        <div>
          This stage outlines the procedure for determining the user role that will be responsible for finalizing the
          team member request. Please add both <span className="font-semibold">Sales Engineer Manager</span> and{' '}
          <span className="font-semibold">Sales Engineer</span>
        </div>
        <div className="py-2" />
        <div className="px-10">
          <ol>
            <li>
              Navigate to <span className="font-semibold">Team</span> tab under{' '}
              <span className="font-semibold">Settings Page</span>, Edit the member that you want to assign a role to by
              clicking the <ThreeVerticalDotsIcon className="w-4 h-4" /> icon
              <img
                className="w-[50rem] py-4"
                src="/images/team-member-request/edit-member.png"
                alt="Team Member Request - Edit Member"
              />
            </li>
            <li>
              Assign a role and click <span className="font-semibold">Update</span>. Make sure to assign both{' '}
              <span className="font-semibold">Sales Engineer Manager</span> and{' '}
              <span className="font-semibold">Sales Engineer</span>
              <img
                className="w-[40rem] px-10"
                src="/images/team-member-request/update-member-role.png"
                alt="Team Member Request - Edit Member"
              />
            </li>
          </ol>
        </div>
      </div>
      <div className="flex justify-end">
        <BaseButton color="secondary" variant="contained" className="!px-7 !py-5" onClick={() => modalStore.close()}>
          Close
        </BaseButton>
      </div>
    </div>
  );
}

export default TeamMemberRequestTutorial;
