import { NavigateFunction } from 'react-router-dom';
import { User } from 'types/user';

const REDIRECT_URL_KEY = 'redirect-url';

export const storeRedirectUrl = (url: string) => {
  localStorage.setItem(REDIRECT_URL_KEY, url);
};

export const getRedirectUrl = (): string | null => {
  return localStorage.getItem(REDIRECT_URL_KEY);
};

export const redirect = (navigate: NavigateFunction, user: User) => {
  if (user.needsToSetUpCrm) {
    navigate('/crm-integration');
    return;
  }

  if (user.calendarIntegrationName === null && !user.hasSkippedCalendarIntegration) {
    navigate('/calendar-integration');
    return;
  }

  if (user.needsToSetUpRecording) {
    navigate('/recording-integration');
    return;
  }

  if (user.needsToSetUpQuestions) {
    navigate('/questions-setup');
    return;
  }

  const redirectUrl = localStorage.getItem(REDIRECT_URL_KEY);
  if (redirectUrl !== null) {
    localStorage.removeItem(REDIRECT_URL_KEY);
    navigate(redirectUrl);
    return;
  }
  navigate('/');
};

export default {
  storeRedirectUrl,
  getRedirectUrl,
  redirect,
};
