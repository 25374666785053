/* eslint-disable no-use-before-define, react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/destructuring-assignment */
import withInlines from 'components/NoteEditor/EditorContext/withInlines';
import { withNormalizeInlineNode } from 'components/NoteEditor/EditorContext/withNormalizeNode';
import Leaf from 'components/NoteEditor/Leaf';
import NoteEditor from 'components/NoteEditor/NoteEditor';
import HotKeyPlugin from 'components/NoteEditor/Plugins/HotKeyPlugin';
import PastePlugin from 'components/NoteEditor/Plugins/PastePlugin';
import {
  BlockType,
  CustomElement,
  LinkElement as LinkElementType,
  MentionElement as MentionElementType,
} from 'components/NoteEditor/types';
import isHotkey from 'is-hotkey';
import { cloneDeep, isArray } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Descendant, Editor, Node, Point, Range, Transforms, createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, ReactEditor, RenderElementProps, RenderLeafProps, Slate, withReact } from 'slate-react';
import LinkElement from '../LinkElement';
import MentionElement from '../Mention/MentionElement';
import styles from './TaskSlateEditor.module.css';
import { concat } from 'utils/styling';
import { createNode } from 'components/NoteEditor/utils';
import EditorToolbar from '../Toolbar/EditorToolbar';
import useNoteEditorStore from 'stores/note-editor';
import EmojiCommandPlugin from 'components/NoteEditor/Plugins/EmojiCommandPlugin';

export type TaskSlateEditorRef = {
  focus: () => void;
};

interface Props {
  className?: string;
  content?: Descendant[];
  readOnly: boolean;
  onChange: (newContent: Descendant[]) => void;
  documentId: string | null;
  opportunityId: string | null;
  autoFocus?: boolean;
  onSplitToNewTask?: (newContent: Descendant[]) => void;
  onDeleteCurrentTask?: (leftContent: Descendant[]) => void;
  onComment?: () => void;
  onFocus?: () => void;
  placeholder?: string;
  scrollContainer?: HTMLDivElement;
}

const TaskSlateEditor = React.forwardRef<TaskSlateEditorRef, Props>(
  (
    {
      className,
      content,
      readOnly,
      onChange,
      documentId,
      opportunityId,
      autoFocus,
      onSplitToNewTask,
      onDeleteCurrentTask,
      onComment,
      onFocus,
      scrollContainer,
      placeholder,
    }: Props,
    ref,
  ) => {
    const noteEditorStore = useNoteEditorStore();
    const isInitUpdate = useRef(true);
    const editor = useMemo(() => withNormalizeInlineNode(withInlines(withReact(withHistory(createEditor())))), []);
    const renderElement = useCallback(
      (props: RenderElementProps) => (
        <Element {...props} readOnly={readOnly} documentId={documentId || ''} opportunityId={opportunityId || ''} />
      ),
      [readOnly],
    );
    const renderLeaf = useCallback((props: RenderLeafProps) => <Leaf {...props} />, []);
    const initContent = useRef(
      isArray(content) && content.length
        ? content
        : [{ type: BlockType.Paragraph, children: [{ text: '' }] } as CustomElement],
    );
    const emojiPluginRef = useRef(EmojiCommandPlugin(scrollContainer || null));

    useEffect(() => {
      if (!readOnly) {
        Transforms.select(editor, NoteEditor.end(editor, []));
      }
    }, [readOnly]);

    React.useImperativeHandle(ref, () => ({
      focus: () => {
        ReactEditor.focus(editor);
      },
    }));

    return (
      <div className={concat(styles.editorContainer, className)}>
        <Slate
          editor={editor}
          initialValue={initContent.current}
          onChange={newContent => {
            if (!isInitUpdate.current) {
              onChange(newContent || createNode(BlockType.Paragraph));
            } else {
              isInitUpdate.current = false;
            }
          }}
        >
          <Editable
            onFocus={onFocus}
            autoFocus={!readOnly && autoFocus}
            className={styles.editor}
            readOnly={readOnly}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder={placeholder}
            onKeyDown={event => {
              const stop = emojiPluginRef.current?.onKeyDown(event, editor);
              if (stop) {
                return;
              }
              const isEnterPressed = isHotkey('enter', event);
              const isShiftEnterPressed = isHotkey('shift+enter', event);
              // remove current node when enter in empty task
              if (
                isEnterPressed &&
                !isShiftEnterPressed &&
                editor.children.every(el => NoteEditor.isEmpty(editor, el) && onDeleteCurrentTask)
              ) {
                event.preventDefault();
                onDeleteCurrentTask?.(cloneDeep(editor.children));
                return;
              }
              // remove current node when backspace in the header of task
              if (
                isHotkey('backspace', event) &&
                editor.selection &&
                Range.isCollapsed(editor.selection) &&
                Point.equals(editor.selection.anchor, Editor.start(editor, [])) &&
                onDeleteCurrentTask
              ) {
                event.preventDefault();
                onDeleteCurrentTask?.(cloneDeep(editor.children));
                return;
              }
              // split to new task when enter
              if (isEnterPressed && !isShiftEnterPressed && onSplitToNewTask) {
                event.preventDefault();
                const { selection } = editor;
                if (selection) {
                  const selectionBegin = Point.isAfter(selection.anchor, selection.focus)
                    ? selection.focus
                    : selection.anchor;
                  const selectionEnd = Point.isAfter(selection.anchor, selection.focus)
                    ? selection.anchor
                    : selection.focus;
                  const blockEnd = NoteEditor.end(editor, []);
                  const fragment = cloneDeep(Node.fragment(editor, { anchor: selectionEnd, focus: blockEnd }));
                  onSplitToNewTask(fragment);
                  Transforms.delete(editor, {
                    at: { anchor: selectionBegin, focus: blockEnd },
                  });
                }
                return;
              }
              // select all for current task editor
              if (isHotkey('mod+a', event) && !NoteEditor.sp_isSelectAll(editor)) {
                event.stopPropagation();
                event.preventDefault();
                Transforms.select(editor, []);
              }
              HotKeyPlugin.onKeyDown?.(event, editor, true);
            }}
            onPaste={e => PastePlugin.onPaste?.(e, editor, true)}
          />
          {emojiPluginRef.current?.element}
          <EditorToolbar
            editor={editor}
            enableComment
            onComment={onComment}
            hide={!!noteEditorStore.commentContext}
            scrollContainer={scrollContainer}
          />
        </Slate>
      </div>
    );
  },
);

TaskSlateEditor.displayName = 'TaskSlateEditor';

TaskSlateEditor.defaultProps = {
  className: '',
  content: undefined,
  autoFocus: false,
  onSplitToNewTask: undefined,
  onDeleteCurrentTask: undefined,
  onComment: undefined,
  placeholder: 'Enter the task...',
  onFocus: undefined,
  scrollContainer: undefined,
};

const Element = (props: RenderElementProps & { readOnly: boolean; documentId: string; opportunityId: string }) => {
  const { attributes, children, element, documentId, opportunityId } = props;
  switch (element.type) {
    case 'mention':
      return (
        <MentionElement
          {...props}
          element={element as MentionElementType}
          documentId={documentId}
          opportunityId={opportunityId}
          docSource="main-doc"
        />
      );
    case 'link':
      return <LinkElement {...props} element={element as LinkElementType} readOnly={props.readOnly} />;
    default:
      return <div {...attributes}>{children}</div>;
  }
};

export default TaskSlateEditor;
