import React from 'react';
import BaseCheckbox from 'components/Form/BaseCheckbox';

interface Props {
  isEditable: boolean;
  isUpdating: boolean;
  value: boolean;
  onChange: () => void;
}

function BooleanField({ isEditable, isUpdating, value, onChange }: Props) {
  return (
    <div className="w-full">
      <BaseCheckbox
        loading={isUpdating}
        className="!ml-0 w-full"
        label={isUpdating ? 'Updating..' : ''}
        disabled={!isEditable || isUpdating}
        checked={value}
        onChange={onChange}
      />
    </div>
  );
}

export default BooleanField;
