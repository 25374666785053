import httpClient from '../http-client';
import { ColumnConfig } from 'components/Table/types';
import { ViewSetting, ViewSettingType } from 'types/view-setting';

export default async (name: string, view: ColumnConfig[], viewType: ViewSettingType): Promise<ViewSetting> => {
  const { data } = await httpClient.post<ViewSetting>(`/view-setting`, {
    name,
    view,
    viewType,
  });
  return data;
};
