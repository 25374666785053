import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

import ContextPanelView from 'components/ContextPanelView';
import SpinLoader from 'components/icons/SpinLoader';
import FieldValueView from 'components/FieldValueView';

import useTeamMemberRequestStore from 'stores/team-member-request';
import useDocumentStore from 'stores/document';
import useFieldValuesStore from 'stores/field-values';

import OpportunitySelector from './TeamMemberRequestPage/OpportunitySelector';
import ConfigureFieldView from './TeamMemberRequestPage/ConfigureFieldView';
import AssignTeamMember from './TeamMemberRequestPage/AssignTeamMember';
import StatusPill from '../components/TeamMemberRequest/StatusPill';
import useUserStore from '../stores/user';
import AssignedTeamMember from './TeamMemberRequestPage/AssignedTeamMember';
import useSuggestedAnswer from '../hooks/useSuggestedAnswer';

function TeamMemberRequestPage() {
  const { requestId } = useParams();
  useSuggestedAnswer();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [documentId, setDocumentId] = useState<string>('');

  const documentStore = useDocumentStore();
  const fieldValueStore = useFieldValuesStore();
  const teamMemberRequestStore = useTeamMemberRequestStore();
  const userStore = useUserStore();

  const request = teamMemberRequestStore.teamMemberRequests.find(r => r.id === requestId);

  const fetchTeamMembmerRequestStore = async (rId: string) => {
    setIsLoading(true);
    const r = await teamMemberRequestStore.fetchById(rId);
    setDocumentId(r.document.id);
  };

  useEffect(() => {
    if (requestId) {
      fetchTeamMembmerRequestStore(requestId);
      teamMemberRequestStore.fetchById(requestId);
    }
  }, [requestId]);

  const fetchDocumentRelated = async (docId: string) => {
    await fieldValueStore.fetch(docId);
    await documentStore.getOrFetchEntityByDocId(docId);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!documentStore.isLoading && documentId) {
      fetchDocumentRelated(documentId);
    }
  }, [documentStore.isLoading, documentId]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!request) {
    return <div>Request not found</div>;
  }

  const sortedTeamMemberRequestFieldValues = fieldValueStore.fieldValues
    .filter(fv => fv.field.isUsedInTeamMemberRequest)
    .sort((a, b) => {
      if (a.field.entity === 'Account' && b.field.entity === 'Opportunity') {
        return -1;
      }
      if (a.field.entity === 'Opportunity' && b.field.entity === 'Account') {
        return 1;
      }

      return a.field.order - b.field.order;
    });

  const opportunityFields = fieldValueStore.fieldValues
    .filter(fv => fv.field.entity === 'Opportunity')
    .map(fv => fv.field);

  const salesEngineerFieldValue = fieldValueStore.fieldValues.find(fv => fv.field.isSalesEngineerField);

  return (
    <div className="w-full h-full flex flex-col">
      <div className="flex items-center px-8 py-4 border-b">
        <Link
          to="/team-member-requests"
          className="text-sm text-slate-500 mr-4 bg-slate-200 hover:bg-slate-300 rounded-lg p-1 h-6 w-6"
        >
          <ArrowLeftIcon className="w-4 h-4" />
        </Link>
        <div className="font-medium">Team Member Requests for {request.document.name}</div>
        <div className="ml-3 text-xs">
          <StatusPill status={request.status} />
        </div>
      </div>
      <ContextPanelView viewKey='team-member-request-page-context-panel"'>
        <div>
          <div className="py-5" />
          <div className="flex flex-wrap gap-10 justify-center">
            <div className="flex flex-col items-center h-full">
              <div className="w-full lg:w-[45rem] p-5 border rounded">
                <div className="p-2 bg-gray-100 font-gray-800 w-fit rounded">Team Member Request Form</div>
                <div className="py-2" />
                <div>
                  <div className="text-sm text-slate-500 py-1">Requester</div>
                  <div className="border rounded px-4 py-2">
                    {request.requestor.firstName} {request.requestor.lastName}
                  </div>
                </div>
                <div className="py-2" />
                <div>
                  <div className="text-sm text-slate-500 py-1">Opportunity</div>
                  <OpportunitySelector requestId={request.id} opportunityDocumentId={request.document.id} />
                </div>
                <div className="py-2" />
                <div>
                  <div className="text-sm text-slate-500 py-1">Fields</div>
                  {fieldValueStore.isLoading ? (
                    <div className="flex items-center">
                      <SpinLoader className="w-6 h-6 animate-spin mr-2" />
                      <span className="text-sm text-gray-500">
                        Please wait... we are loading rest of the form. This may take a few seconds.
                      </span>
                    </div>
                  ) : (
                    <div className="py-2">
                      {sortedTeamMemberRequestFieldValues.map(fv => (
                        <FieldValueView key={fv.id} from="reporting" fieldValue={fv} />
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="w-full lg:w-[45rem] border rounded h-fit">
              <div className="p-10">
                <AssignedTeamMember status={request.status} assignees={request.assignees} />
              </div>
              {userStore.user?.role === 'Sales Engineer Manager' && (
                <>
                  <div className="border-t" />
                  <div className="py-10 px-5">
                    {!salesEngineerFieldValue ? (
                      <ConfigureFieldView documentId={documentId} fields={opportunityFields} />
                    ) : (
                      <AssignTeamMember
                        requestId={request.id}
                        documentId={documentId}
                        fieldValue={salesEngineerFieldValue}
                        fields={opportunityFields}
                      />
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="py-20" />
        </div>
      </ContextPanelView>
    </div>
  );
}

export default TeamMemberRequestPage;
