import React from 'react';

import { CrmFieldType } from 'types/crm';

import { Calculation, ColumnDataType } from '../types';
import NumberMenuItems from './CalculationMenus/NumberMenuItems';
import BooleanMenuItems from './CalculationMenus/BooleanMenuItems';
import DefaultMenuItems from './CalculationMenus/DefaultMenuItems';

interface Props {
  dataType: ColumnDataType;
  updateCalculation: (calculation: Calculation | undefined) => void;
  columnData: undefined | any;
}

function CalculationMenus({ dataType, updateCalculation, columnData }: Props) {
  switch (dataType) {
    case 'ReportField': {
      const { dataEntryType } = columnData;
      if (dataEntryType === 'number') {
        return <NumberMenuItems updateCalculation={updateCalculation} />;
      }
      if (dataEntryType === 'boolean') {
        return <BooleanMenuItems updateCalculation={updateCalculation} />;
      }
      return <DefaultMenuItems updateCalculation={updateCalculation} />;
    }
    case 'ReportEntityField': {
      const { dataEntryType } = columnData as { dataEntryType: CrmFieldType };
      if (
        dataEntryType === 'currency' ||
        dataEntryType === 'double' ||
        dataEntryType === 'int' ||
        dataEntryType === 'percent'
      ) {
        return <NumberMenuItems updateCalculation={updateCalculation} />;
      }

      if (dataEntryType === 'boolean') {
        return <BooleanMenuItems updateCalculation={updateCalculation} />;
      }
      return <DefaultMenuItems updateCalculation={updateCalculation} />;
    }
    case 'Number':
    case 'Currency':
    case 'ReportCalculatedField':
      return <NumberMenuItems updateCalculation={updateCalculation} />;
    case 'Boolean':
      return <BooleanMenuItems updateCalculation={updateCalculation} />;
    default:
      return <DefaultMenuItems updateCalculation={updateCalculation} />;
  }
}

export default CalculationMenus;
