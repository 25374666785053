import React from 'react';
import { useNavigate } from 'react-router-dom';

import SignupContainer from 'components/SignupContainer';

import FieldManagement from '../components/FieldManagement';
// import useUser from '../hooks/userUser';
import useUserStore from '../stores/user';
import { redirect } from 'utils/redirect-after-login';

function QuestionSetup() {
  const navigate = useNavigate();

  const userStore = useUserStore();

  const next = async () => {
    if (userStore.user) {
      const { user } = await userStore.updateUser({
        insertLinkInCalendar: userStore.user.insertLinkInCalendar,
        needsToSetUpQuestions: false,
      });
      // userStore.user is not updated, why???
      if (user) {
        redirect(navigate, user);
      }
    }
  };

  return (
    <SignupContainer currentStepIndex={3} totalStepNumber={3}>
      <FieldManagement cancelButtonText="I'll do this later" next={next} />
    </SignupContainer>
  );
}

export default QuestionSetup;
