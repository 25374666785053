import React, { useState } from 'react';

import Salesforce from 'components/logos/Salesforce';
import Superpanel from 'components/logos/Superpanel';
import Gmail from 'components/logos/Gmail';
import MicrosoftCalendar from 'components/logos/MicrosoftCalendar';

import getOauthLink from 'utils/get-oauth-link';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import FadeIn from 'components/FadeIn';
import googleOauthGif from 'components/GIF/google-oauth.gif';
import { get } from 'lodash';

function Register() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const stateError = searchParams.get('state');
  const onlySaleForce = get(location.state, 'onlySaleForce');
  const [state, setState] = useState<boolean>(false);

  let errorEle: JSX.Element | null = null;
  if (stateError === 'UserExistError') {
    errorEle = (
      <FadeIn isOpen>
        <div className="text-sm font-medium text-red-500">
          User already exist. Please
          <button
            type="button"
            className="px-1 py-1 font-medium text-blue-500 hover:bg-gray-100 rounded"
            onClick={() => {
              navigate('/login');
            }}
          >
            sign in
          </button>
        </div>
      </FadeIn>
    );
  }

  return (
    <div className="flex h-screen">
      {state && (
        <div className="w-1/2 h-full flex items-center">
          <div className="w-full flex flex-col gap-4">
            <div className="flex text-gray-500 justify-center">
              <span>Be sure to check the box, just like the example below.</span>
            </div>
            <div className="flex justify-center">
              <div className="border-blue-500 border-2 p-2 rounded-lg">
                <img src={googleOauthGif} alt="google-gif" className="h-[500px]" />
              </div>
            </div>
            <div className="flex justify-center gap-4 mb-10">
              <button
                type="button"
                className="bg-gray-100 rounded-lg px-4 py-2 hover:bg-gray-200"
                onClick={() => setState(false)}
              >
                Go Back
              </button>
              <a
                className="bg-blue-500 text-white rounded-lg px-4 py-2 hover:bg-blue-300"
                href={getOauthLink('google', 'register')}
              >
                Continue
              </a>
            </div>
          </div>
        </div>
      )}
      {!state && (
        <div className="relative w-1/2 h-full flex flex-col gap-8 justify-center items-center">
          <div className="absolute top-12 left-9 flex items-center gap-2">
            <Superpanel className="w-10 h-10 p-1.5 border rounded" />
            <div className="text-xl font-semibold">Superpanel</div>
            <button
              type="button"
              className="ml-40 font-semibold text-orange-400 px-2 py-1 hover:bg-gray-100 rounded"
              onClick={() => {
                navigate('/login');
              }}
            >
              Already have an account? Login
            </button>
          </div>
          <div className="flex flex-col gap-2 justify-center items-center">
            <div className="text-2xl font-semibold">Create Account</div>
          </div>
          {errorEle}

          <a
            className="border rounded-lg w-2/3 px-10 py-3 flex items-center justify-center bg-blue-200"
            href={getOauthLink('salesforce', 'register')}
          >
            <Salesforce className="w-9 h-9 mr-3" />
            <span className="font-medium text-gray-700">Continue with Salesforce</span>
          </a>
          {!onlySaleForce && (
            <>
              <div className="text-gray-400 font-medium">Do not have CRM?</div>

              <button
                type="button"
                className="border rounded-lg w-2/3 px-10 py-3 flex items-center justify-center"
                onClick={() => setState(true)}
              >
                <Gmail className="w-9 h-9 mr-3" />
                <span className="font-medium">Continue with Google</span>
              </button>
              <a
                className="border rounded-lg w-2/3 px-10 py-3 flex items-center justify-center"
                href={getOauthLink('microsoft', 'register')}
              >
                <MicrosoftCalendar className="w-9 h-9 mr-3" />
                <span className="font-medium">Continue with Microsoft</span>
              </a>
            </>
          )}

          <div className="text-sm text-center text-gray-500">
            <div>By continuing, you&apos;re agreeing to the Superpanel&nbsp;</div>
            <div>
              <a
                className="text-blue-500 hover:underline"
                href="https://superpanel.io/terms-conditions"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Conditions
              </a>
              &nbsp;and&nbsp;
              <a
                className="text-blue-500 hover:underline"
                href="https://superpanel.io/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </div>
          </div>
          <div className="absolute bottom-8 left-8 text-gray-500 text-sm">© Superpanel 2022</div>
        </div>
      )}
      <div className="w-1/2 h-full">
        <img className="w-full h-full" src="/images/background.jpeg" alt="Superpanel" />
      </div>
    </div>
  );
}

export default Register;
