import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';

import SpinLoader from 'components/icons/SpinLoader';
import { EditorContent, useEditor } from '@tiptap/react';
import { StarterKit } from '@tiptap/starter-kit';
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import { TextAlign } from '@tiptap/extension-text-align';

import styles from './RichTextAreaField.module.css';
import isHotkey from 'is-hotkey';

interface Props {
  isEditable: boolean;
  isUpdating: boolean;
  isEdited: boolean;
  answer: string;
  onAnswerChange: (value: string) => void;
  onCancel: () => void;
  onSubmit: () => void;
  autoFocus?: boolean;
}

function RichTextAreaField({
  isEditable,
  isUpdating,
  isEdited,
  answer,
  onAnswerChange,
  onCancel,
  onSubmit,
  autoFocus,
}: Props) {
  const [isFirstTimeLoad, setIsFirstTimeLoad] = useState<boolean>(true);
  const editor = useEditor({
    extensions: [
      StarterKit,
      Image.configure({
        inline: true,
      }),
      Link.configure({
        openOnClick: true,
        validate: href => /^https?:\/\//.test(href),
      }),
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
    ],
    editable: isEditable,
    content: answer,
    // this will auto focus the editor when it's loaded in a document
    // hence remove
    // autofocus: true,
    onUpdate: ({ editor: e }) => {
      if (isFirstTimeLoad && !isUpdating) {
        setIsFirstTimeLoad(false);
      } else {
        const emptyParagraphRegex = /<p><\/p>/g;
        const html = e.getHTML().replace(emptyParagraphRegex, '<br>');
        if (answer !== html) onAnswerChange(html);
      }
    },
  });

  useEffect(() => {
    if (editor) {
      editor.setEditable(!isUpdating);
    }
  }, [editor, isUpdating]);

  useEffect(() => {
    if (editor) {
      const emptyParagraphRegex = /<p><\/p>/g;
      const html = editor.getHTML().replace(emptyParagraphRegex, '<br>');
      if (answer !== html) editor.commands.setContent(answer);
    }
  }, [editor, answer]);

  return (
    <>
      <EditorContent
        data-slate-editor
        className={styles.editor}
        editor={editor}
        autoFocus={autoFocus}
        onCopy={e => e.stopPropagation()}
        onPaste={e => e.stopPropagation()}
        onKeyDown={e => {
          if (isHotkey('mod+a', e)) {
            e.stopPropagation();
          }
        }}
      />
      {isEditable && (
        <div className="w-full flex justify-between items-center mt-1">
          <div className="flex items-center gap-1 text-xs">
            {isUpdating && (
              <>
                <SpinLoader className="w-5 h-5 p-1 animate-spin" />
                <span>Updating...</span>
              </>
            )}
          </div>
          {isEdited && (
            <div className="flex items-center gap-1 mt-1">
              <button className="text-white" type="button" onClick={onCancel}>
                <XMarkIcon className="w-5 h-5 p-0.5 rounded-full bg-gray-400" />
              </button>
              <button
                className="text-white"
                type="button"
                disabled={!isEdited || isUpdating}
                onClick={() => {
                  onSubmit();
                  setIsFirstTimeLoad(true);
                }}
              >
                {isUpdating ? (
                  <SpinLoader className="w-5 h-5 p-1 rounded-full bg-blue-500 animate-spin" />
                ) : (
                  <CheckIcon className={`w-5 h-5 p-0.5 rounded-full ${!isEdited ? 'bg-gray-400' : 'bg-blue-500'}`} />
                )}
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
}

RichTextAreaField.defaultProps = {
  autoFocus: false,
};

export default RichTextAreaField;
