import React from 'react';
import { useParams } from 'react-router-dom';

import useRecordingsStore from 'stores/recordings';
import AccountIcon from 'components/icons/AccountIcon';
import OpportunityIcon from 'components/icons/OpportunityIcon';
import NoteIcon from 'components/icons/NoteIcon';
import CalendarIcon from 'components/icons/CalendarIcon';
// import useDocumentStore from 'stores/document';
import { Recording } from 'types/recording';
import datetimeObjToString from '../../../utils/datetime-obj-to-string';
// import { EntityDocument } from 'types/entity';
// import { Document } from 'types/document';
// import Loader from 'components/Loader';

interface Props {
  recording: Recording;
  idx: number;
}

// interface State {
//   isLoading: boolean;
// }

function RecordingTableRow({ recording, idx }: Props) {
  const recordingsStore = useRecordingsStore();
  const { documentId } = useParams();
  // const [state, setState] = useState<State>({
  //   isLoading: true,
  // });

  // if (state.isLoading) {
  //   return (
  //     <div className="h-full w-full flex items-center justify-center">
  //       <Loader className="w-10 h-10" />
  //     </div>
  //   );
  // }

  let scheduledDate = '';
  let scheduledTime = '';
  let duration = '';
  let icon: null | JSX.Element = null;

  if (recording.callData.scheduled) {
    const { scheduledDate: date, scheduledTime: time } = datetimeObjToString(recording.callData.scheduled);
    scheduledDate = date;
    scheduledTime = time;
  } else if (recording.callData.started) {
    const { scheduledDate: date, scheduledTime: time } = datetimeObjToString(recording.callData.started);
    scheduledDate = date;
    scheduledTime = time;
  }
  if (recording.callData.duration) {
    duration = Math.round(recording.callData.duration / 60)
      .toString()
      .concat(' ', 'minutes');
  }
  if (recording.entityType === 'Account') {
    icon = (
      <span className="flex-shrink-0">
        <AccountIcon className="w-4 h-4" />
      </span>
    );
  } else if (recording.entityType === 'Opportunity') {
    icon = (
      <span className="bg-orange-400 rounded pt-[1px] px-[3px] flex-shrink-0">
        <OpportunityIcon className="w-3 h-3 fill-white text-white" />
      </span>
    );
  } else if (recording.isEvent) {
    icon = (
      <span className="flex-shrink-0">
        <CalendarIcon className="w-5 h-5 text-gray-500" />
      </span>
    );
  } else {
    icon = (
      <span className="flex-shrink-0">
        <NoteIcon className="w-4 h-4 text-gray-500" />
      </span>
    );
  }

  return (
    <tr
      key={recording.callData.id}
      className={`text-sm hover:bg-gray-200 hover:cursor-pointer ${
        recording.entityOrDocumentId === documentId ? 'bg-gray-100' : ''
      }`}
      onClick={() => {
        // docEditor is just used to mean the recording is opended from the recording table view
        recordingsStore.setState({ recordingId: recording.id, fromContext: 'docEditor' });
      }}
    >
      <td className="py-3 px-2 text-gray-500 font-medium">{idx + 1}</td>
      <td className="self-center gap-1 items-center py-3 px-2 text-black font-medium">
        <span>
          {icon}{' '}
          {`${recording.entityType === 'Opportunity' ? `${recording.entityName}: ` : ''}${recording.callData.title}`}
        </span>
      </td>
      <td className="py-3 px-2 text-black font-medium">
        <div>
          <span className="pr-2 text-gray-600 ">{scheduledDate}</span>
          <span className="text-gray-600">{scheduledTime}</span>
        </div>
        <div className="flex justify-around text-gray-400">{duration}</div>
      </td>
    </tr>
  );
}

export default RecordingTableRow;
