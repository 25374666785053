/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-useless-fragment */

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ReactEditor, useSlate } from 'slate-react';
import SpinLoader from 'components/icons/SpinLoader';
import TrashIcon from 'components/icons/TrashIcon';
import ThreeVerticalDotsIcon from 'components/icons/ThreeVerticalDotsIcon';
import Popover from 'components/Popover';
import DeleteProductGapModal from 'components/DeleteProductGapModal';
import ProductGapCategorySelector from 'components/ProductGapCategorySelector';

import useModalStore from 'stores/modal';
import useProductGapStore from 'stores/product-gap';

import { ProductCategory } from 'types/product-gap';

import { Transforms } from 'slate';
import ProductFieldAnswer from 'components/ProductFieldAnswer';
import { concat } from 'utils/styling';
import PlayBack from 'components/FieldValueView/PlayBack';
import moment from 'moment';
import useRecordingsStore from 'stores/recordings';
import RecordingIcon from 'components/FieldValueView/RecordingsIcon';
import NoteEditor from '../NoteEditor';
import { BlockType, CustomElement, CustomRenderElementProps, ProductGapElement } from '../types';
import FieldBlock from './ProductGapElementView/FieldBlock';
import WarningIcon from '../../icons/WarningIcon';
import ProductboardButton from './ProductGapElementView/ProductboardButton';
import useUserStore from '../../../stores/user';
import styles from './ProductGapElementView.module.css';
import constants from '../../../utils/constants';
import { isEmpty, values } from 'lodash';
import BaseButton from 'components/BaseButton';
import CommentRowButton from './Comment/CommentRowButton';
import useEditorSelectedFocused from 'hooks/useEditorSelectedFocused';

interface FieldAnswers {
  [fieldId: string]: string;
}

interface State {
  isLoading: boolean;
  isPopoverOpen: boolean;
  isDeleted: boolean;
  formState: 'NONE' | 'CREATE' | 'UPDATE';
  category: ProductCategory | null;
  fieldAnswers: FieldAnswers;
  fieldAnswerErrors: {
    [fieldId: string]: string;
  };
  link: string | null;
  startTime: number | null | undefined;
  recordingId: string | null | undefined;
}

interface Props extends CustomRenderElementProps {
  element: ProductGapElement;
}

function ProductGapElementView({ attributes, element, documentId, children }: Props) {
  const selected = useEditorSelectedFocused();
  const [state, setState] = useState<State>({
    isLoading: false,
    isPopoverOpen: false,
    isDeleted: false,
    formState: 'NONE',
    category: null,
    fieldAnswers: {},
    fieldAnswerErrors: {},
    link: null,
    startTime: null,
    recordingId: null,
  });
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { productGapId, startTime, recordingId } = element;

  const editor = useSlate();
  const path = ReactEditor.findPath(editor, element);

  const productGapStore = useProductGapStore();
  const recordingStore = useRecordingsStore();
  const modalStore = useModalStore();
  const userStore = useUserStore();
  const stateRef = useRef(state);
  const productGapIdRef = useRef(productGapId);

  const createProductGap = async (
    categoryId: string | null,
    fieldAnswers: FieldAnswers,
    integrationId: string | null,
    inputSource?: HTMLInputElement,
  ) => {
    if (documentId) {
      setState(prev => ({ ...prev, formState: 'CREATE' }));
      const productGap = await productGapStore.createGap(categoryId, documentId, fieldAnswers, integrationId);
      Transforms.setNodes(editor, { productGapId: productGap.id }, { at: path });
      setState(prev => ({ ...prev, formState: 'NONE' }));
      setTimeout(() => {
        inputSource?.focus();
      });
    }
  };

  const updateProductGap = async (
    categoryId: string | null,
    fieldAnswers: FieldAnswers,
    integrationId: string | null,
  ) => {
    if (productGapIdRef.current && documentId) {
      setState(prev => ({ ...prev, formState: 'UPDATE' }));
      await productGapStore.updateProductGap(
        productGapIdRef.current,
        documentId,
        categoryId,
        fieldAnswers,
        integrationId,
      );
      setState(prev => ({ ...prev, formState: 'NONE' }));
    }
  };

  const validate = (fieldAnswers: FieldAnswers) => {
    const fieldAnswerErrors: { [fieldId: string]: string } = {};
    productGapStore.productFields.forEach(p => {
      const answer = fieldAnswers[p.id];
      if (p.isMandatory && !answer?.trim()) {
        fieldAnswerErrors[p.id] = 'This field is mandatory';
      }
    });
    setState(prev => ({ ...prev, fieldAnswerErrors }));
    return Object.keys(fieldAnswerErrors).length === 0;
  };

  const onCategoryUpdate = (category: ProductCategory | null) => {
    setState(prev => ({ ...prev, category }));
    if (!validate(state.fieldAnswers)) return;
    if (productGapId) {
      // update product gap
      updateProductGap(category ? category.id : null, state.fieldAnswers, category?.integrationId || null);
    } else if (state.formState !== 'CREATE') {
      // create product gap
      createProductGap(category ? category.id : null, state.fieldAnswers, category?.integrationId || null);
    }
  };

  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  useEffect(() => {
    productGapIdRef.current = productGapId;
  }, [productGapId]);

  const onFieldAnswerUpdateOrCreate = async (data: { [key: string]: any }, inputSource?: HTMLInputElement) => {
    const newFieldAnswers = { ...stateRef.current.fieldAnswers, ...data };
    // newFieldAnswers[fieldId] = answer;
    setState(prev => ({ ...prev, fieldAnswers: newFieldAnswers }));
    if (!validate(newFieldAnswers)) return;
    if (productGapIdRef.current) {
      // update product gap
      await updateProductGap(
        stateRef.current.category ? stateRef.current.category.id : null,
        newFieldAnswers,
        stateRef.current.category?.integrationId || null,
      );
    } else if (stateRef.current.formState !== 'CREATE') {
      // create product gap
      await createProductGap(
        stateRef.current.category ? stateRef.current.category.id : null,
        newFieldAnswers,
        stateRef.current.category?.integrationId || null,
        inputSource,
      );
    }
  };

  const fetch = async (gapId: string) => {
    setState(prev => ({ ...prev, isLoading: true }));
    try {
      const productGap = await productGapStore.getProductGap(gapId);
      if (productGap.documentId === documentId) {
        const newFieldAnswers = { ...state.fieldAnswers };
        productGap.fields.forEach(fieldAnswer => {
          newFieldAnswers[fieldAnswer.id] = fieldAnswer.answer;
        });
        setState(prev => ({
          ...prev,
          category: productGap.category,
          fieldAnswers: newFieldAnswers,
          link: productGap.link,
          // these conditions are to handle test on local where time can be 0
          startTime:
            productGap.startTime !== null && productGap.startTime !== undefined ? productGap.startTime : startTime,
          recordingId: productGap.callId || recordingId,
        }));
        setTimeout(() => {
          validate(newFieldAnswers);
        });
      } else {
        // product gap is not in the document
        setState(prev => ({ ...prev, isDeleted: true }));
      }
    } catch (e) {
      // empty
      setState(prev => ({ ...prev, isDeleted: true }));
    }
    setState(prev => ({ ...prev, isLoading: false }));
  };

  const productFieldsPlaceholder = useMemo(() => {
    const count = productGapStore.productFields.filter(f => f.title !== 'Feedback' && f.title !== 'Title').length + 1;
    return Array.from({ length: count > 3 ? Math.ceil(count / 3) * 3 - count : 0 }, (_item, i) => i);
  }, [productGapStore.productFields]);

  useEffect(() => {
    if (productGapId) {
      fetch(productGapId);
    }
    // initialize product gap using default value
    // i tried to separate this logic into two pieces, but the 2nd react setState override the 1st one, so i am combining the update into one
    else if (element.defaultFeedback && element.defaultFeedback) {
      const feedbackFieldId = productGapStore.productFields.find(pf => pf.title === 'Feedback')?.id;
      const feedbackTitleId = productGapStore.productFields.find(pf => pf.title === 'Title')?.id;

      if (feedbackFieldId && feedbackTitleId) {
        onFieldAnswerUpdateOrCreate({
          [feedbackFieldId]: element.defaultFeedback,
          [feedbackTitleId]: element.defaultTitle,
        });
      }
    }
    if (!productGapId) {
      validate(state.fieldAnswers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setState(prev => {
      const fieldAnswers = { ...prev.fieldAnswers };
      productGapStore.productFields.forEach(field => {
        if (!fieldAnswers[field.id]) {
          fieldAnswers[field.id] = field.defaultValue;
        }
      });
      validate(fieldAnswers);
      return {
        ...prev,
        fieldAnswers,
      };
    });
  }, [productGapStore.productFields]);

  const deleteNode = () => {
    NoteEditor.sp_deleteNode(editor, path);
  };

  const deleteElement = () => {
    if (productGapId) {
      modalStore.setState({
        isOpen: true,
        element: (
          <DeleteProductGapModal
            productGapId={productGapId}
            onDelete={() => {
              setState(prevState => ({ ...prevState, isPopoverOpen: false }));
              deleteNode();
            }}
          />
        ),
      });
    } else {
      deleteNode();
    }
  };

  const publishProductboard = async () => {
    if (productGapId) {
      const productGap = await productGapStore.syncGap(productGapId);
      setState(prev => ({ ...prev, link: productGap.link }));
    }
  };

  const feedbackField = useMemo(
    () => productGapStore.productFields.find(f => f.title === 'Feedback'),
    [productGapStore.productFields],
  );
  const titleField = useMemo(
    () => productGapStore.productFields.find(f => f.title === 'Title'),
    [productGapStore.productFields],
  );

  const nextElement = editor.children[path[0] + 1] as CustomElement | undefined;
  const isNextElementProductGap = nextElement ? nextElement.type === BlockType.ProductGap : false;

  const recording = recordingStore.recordings.find(r => r.id === state.recordingId);

  return (
    <div
      {...attributes}
      className={`${concat(
        styles.container,
        selected && styles.selected,
      )} w-full relative group border rounded border-gray-200 shadow-sm`}
    >
      {children}
      {state.isLoading || (isEmpty(state.fieldAnswers) && !state.isDeleted) ? (
        <div className="w-full p-2 flex items-center justify-center select-none" contentEditable={false}>
          <SpinLoader className="w-6 h-6 animate-spin text-orange-400" />
        </div>
      ) : (
        <>
          {state.isDeleted ? (
            <div
              className="flex justify-center w-full text-center text-gray-300 p-2 select-none"
              contentEditable={false}
            >
              This product gap has been moved or deleted, please remove this block.
            </div>
          ) : (
            <div className="pt-1 select-none" contentEditable={false}>
              <div>
                {titleField && (
                  <div className="flex gap-2 items-center text-xs text-gray-500 px-2 py-1">
                    <span className="pr-4 text-base font-medium text-black">Product Gap</span>
                    {state.recordingId && recording && (state.startTime || state.startTime === 0) && (
                      <div>
                        <div className="flex gap-1 items-center">
                          <RecordingIcon entityType={recording.entityType} />
                          <div className="text-gray-500 text-xs">
                            {recording.callData.title
                              ? `${recording.callData.title}, ${moment(recording.callData.started).format(
                                  constants.DATE_FORMAT,
                                )}`
                              : 'No Title'}
                          </div>
                          <div className="invisible group-hover:visible">
                            <PlayBack
                              recordingId={state.recordingId}
                              startTime={state.startTime}
                              from="docEditor"
                              elementId=""
                            />
                          </div>
                        </div>
                        {/* <PlayBack recordingId={recordingId} startTime={startTime} from="docEditor" elementId="" /> */}
                      </div>
                    )}
                  </div>
                )}
                <div className="w-full relative">
                  {!!titleField && (
                    <div className="relative bg-slate-100 mx-2 my-1">
                      <ProductFieldAnswer
                        placeholder="Enter title for the product gap here"
                        fieldType={titleField.fieldType}
                        additionalData={titleField.additionalData}
                        answer={state.fieldAnswers[titleField.id]}
                        onUpdate={async (answer, inputSource) =>
                          onFieldAnswerUpdateOrCreate({ [titleField.id]: answer }, inputSource)
                        }
                        isError={!!state.fieldAnswerErrors[titleField.id]}
                        disableLoader
                        disabled={state.formState === 'CREATE'}
                      />
                    </div>
                  )}
                  {!!feedbackField && (
                    <FieldBlock title="Feedback" isError={false}>
                      <ProductFieldAnswer
                        placeholder="Enter product feedback here"
                        fieldType={feedbackField.fieldType}
                        additionalData={feedbackField.additionalData}
                        answer={state.fieldAnswers[feedbackField.id]}
                        onUpdate={async (answer, inputSource) =>
                          onFieldAnswerUpdateOrCreate({ [feedbackField.id]: answer }, inputSource)
                        }
                        isError={!!state.fieldAnswerErrors[feedbackField.id]}
                        disableLoader
                        disabled={state.formState === 'CREATE'}
                      />
                    </FieldBlock>
                  )}
                  <div className={styles.fieldBlockConatainer}>
                    <FieldBlock title="Category" isError={false}>
                      <ProductGapCategorySelector
                        selectedCategoryId={state.category?.id || null}
                        onSelect={onCategoryUpdate}
                        disabled={state.formState === 'CREATE'}
                      />
                    </FieldBlock>
                    {productGapStore.productFields.map(f => {
                      if (f.title === 'Feedback' || f.title === 'Title') return null;
                      return (
                        <FieldBlock key={f.id} title={f.title} isError={false}>
                          <ProductFieldAnswer
                            fieldType={f.fieldType}
                            additionalData={f.additionalData}
                            answer={state.fieldAnswers[f.id]}
                            onUpdate={async (answer, inputSource) =>
                              onFieldAnswerUpdateOrCreate({ [f.id]: answer }, inputSource)
                            }
                            isError={!!state.fieldAnswerErrors[f.id]}
                            disableLoader
                            disabled={state.formState === 'CREATE'}
                          />
                        </FieldBlock>
                      );
                    })}
                    {productFieldsPlaceholder.map(i => (
                      <div key={i} className="bg-white" />
                    ))}
                  </div>
                </div>
                <div className={concat('invisible group-hover:visible', styles.actionBtns)}>
                  {(!element.comments ||
                    isEmpty(element.comments) ||
                    values(element.comments).every(comment => !!comment[0].resolved)) && (
                    <CommentRowButton
                      editor={editor}
                      comments={element.comments}
                      commentForBlock
                      darkStyle
                      element={element}
                    />
                  )}
                  <BaseButton
                    ref={buttonRef}
                    variant="contained"
                    color="primary"
                    className={styles.moreActionBtn}
                    onClick={() => {
                      setState(prev => ({ ...prev, isPopoverOpen: true }));
                    }}
                  >
                    <ThreeVerticalDotsIcon className="w-4 h-4" />
                  </BaseButton>
                  <Popover
                    anchorEl={buttonRef.current}
                    onClose={() => setState(prev => ({ ...prev, isPopoverOpen: false }))}
                    isOpen={state.isPopoverOpen}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  >
                    <div className="flex flex-col w-60">
                      {!userStore.user?.needsToSetupProduct && userStore.user?.productProvider === 'ProductBoard' && (
                        <ProductboardButton
                          link={state.link}
                          disabled={!productGapId || Object.keys(state.fieldAnswerErrors).length > 0}
                          publish={publishProductboard}
                        />
                      )}
                      <button
                        type="button"
                        className="p-2 flex items-center gap-2 hover:bg-slate-100"
                        onClick={deleteElement}
                      >
                        <TrashIcon className="w-5 h-5 text-gray-600" />
                        <div className="text-sm text-gray-600">Delete</div>
                      </button>
                    </div>
                  </Popover>
                </div>
              </div>
              {Object.keys(state.fieldAnswerErrors).length > 0 && !productGapId && (
                <div className="flex items-center text-xs text-red-500 mt-2 ml-2">
                  <WarningIcon className="w-4 h-4 text-red-500" /> This form will not be submitted until all mandatory
                  fields are filled
                </div>
              )}
              {Object.keys(state.fieldAnswerErrors).length > 0 && productGapId && (
                <div className="flex items-center text-xs text-red-500 mt-2 ml-2">
                  <WarningIcon className="w-4 h-4 text-red-500" /> This form will not be updated until all mandatory
                  fields are filled
                </div>
              )}
              <div className="flex align-items-center justify-between">
                <div className="text-xs flex items-center text-gray-400 px-2 min-h-[2rem] flex-1 min-w-[4rem]">
                  {(state.formState === 'CREATE' || state.formState === 'UPDATE') && (
                    <>
                      <SpinLoader className="w-4 min-w-[1rem] h-4 animate-spin text-orange-500 mr-1" />
                      <span className="truncate">
                        {state.formState === 'CREATE' ? 'Creating a product gap form' : 'Updating a product gap form'}
                      </span>
                    </>
                  )}
                </div>
                {!isNextElementProductGap && (
                  <BaseButton
                    type="button"
                    color="secondary"
                    className="flex-1"
                    contentClassName="!block truncate"
                    variant="text"
                    onClick={() => {
                      Transforms.insertNodes(
                        editor,
                        {
                          type: BlockType.ProductGap,
                          productGapId: null,
                          children: [{ text: '' }],
                        },
                        {
                          at: [path[0] + 1],
                        },
                      );
                    }}
                  >
                    + Add more product feedback
                  </BaseButton>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default ProductGapElementView;
