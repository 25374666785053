import isHotkey from 'is-hotkey';
import { Editor, Node, NodeEntry, Path, Point, Range, Transforms } from 'slate';
import {
  BlockType,
  CustomElement,
  CustomText,
  INDENTABLE_TYPES,
  NON_TEXT_TYPES,
  TableCellElement,
  TableElement,
  TableRowElement,
} from '../types';
import { createNode } from '../utils';
import { SlatePlugin } from './types';
import NoteEditor from '../NoteEditor';

export const isMarkActive = (editor: Editor, format: keyof CustomText): boolean => {
  const marks = NoteEditor.marks(editor) as CustomText;
  return marks ? marks[format] === true : false;
};

export function backspaceKeyPlugin(
  event: React.KeyboardEvent<HTMLDivElement> | React.ClipboardEvent<HTMLDivElement>,
  editor: Editor,
  isCut: boolean,
): boolean {
  const isForwardDel = isHotkey('delete', event as React.KeyboardEvent<HTMLDivElement>);
  const isBackwardDel = isHotkey('backspace', event as React.KeyboardEvent<HTMLDivElement>);
  const { selection } = editor;
  if (!selection || (!isForwardDel && !isBackwardDel && !isCut)) {
    return false;
  }
  if (Range.isCollapsed(selection)) {
    if (isCut && NoteEditor.sp_checkBlockTypes(editor, INDENTABLE_TYPES)) {
      event.preventDefault();
      Transforms.removeNodes(editor, {
        at: selection.anchor.path.slice(0, 1),
      });
      if (!editor.children.length) {
        Transforms.insertNodes<CustomElement>(editor, createNode(BlockType.Paragraph));
      }
    } else if ((isBackwardDel || isForwardDel) && NoteEditor.isPathDescendantOfTable(editor, selection.anchor.path)) {
      const parentPath = NoteEditor.getParentPathByType(editor, selection.anchor.path, BlockType.TableCell);
      if (parentPath) {
        const isDelCellEdge = Point.equals(
          selection.anchor,
          isBackwardDel ? Editor.start(editor, parentPath) : Editor.end(editor, parentPath),
        );
        // prevent delete cell block
        if (isDelCellEdge) {
          event.preventDefault();
          return true;
        }
      }
    } else if (isBackwardDel) {
      // case: handle indent level
      const indentLevel = NoteEditor.sp_indentLevel(editor) ?? 0;
      if (NoteEditor.sp_checkBlockTypes(editor, INDENTABLE_TYPES)) {
        if (NoteEditor.sp_cursorPosition(editor) === 'start') {
          if (indentLevel > 0) {
            // Lower Indent Level
            event.preventDefault();
            Transforms.setNodes(editor, { indentLevel: indentLevel - 1 }, { at: [selection.anchor.path[0]] });
          }
          // turn non paragraph block into paragraph, this is a business rule
          else if (!NoteEditor.sp_checkBlockType(editor, BlockType.Paragraph)) {
            event.preventDefault();
            // convert to paragraph
            Transforms.setNodes(editor, { type: BlockType.Paragraph });
          }
          // if at empty paragraph, and the previous block is discovery question or product gap block, we don't delete right away, rather, we make the previous block selected, and user can confirm if they want to delete
          else if (
            selection.anchor.path[0] > 0 &&
            NoteEditor.sp_checkBlockTypesByBlockIdx(editor, NON_TEXT_TYPES, selection.anchor.path[0] - 1)
          ) {
            event.preventDefault();
            Transforms.select(editor, [selection.anchor.path[0] - 1]);
          }
        }
      }
    }
  } else {
    // if all document is selected
    // eslint-disable-next-line no-lonely-if
    if (NoteEditor.sp_isSelectAll(editor)) {
      event.preventDefault();
      Transforms.removeNodes<CustomElement>(editor, {
        hanging: true,
        voids: true,
        mode: 'highest',
        at: { anchor: NoteEditor.start(editor, []), focus: NoteEditor.end(editor, []) },
      });
      Transforms.insertNodes<CustomElement>(editor, createNode(BlockType.Paragraph));
    } else {
      // handle table delete
      const parent1Path = NoteEditor.getParentPathByType(editor, selection.anchor.path, BlockType.Table);
      const parent2Path = NoteEditor.getParentPathByType(editor, selection.focus.path, BlockType.Table);
      if (parent1Path || parent2Path) {
        if (isBackwardDel || isForwardDel || isCut) {
          // when select multi cells in same table, delete content in selected cells
          if (
            parent1Path &&
            parent2Path &&
            Path.equals(parent1Path, parent2Path) &&
            !Path.equals(selection.anchor.path.slice(0, 3), selection.focus.path.slice(0, 3))
          ) {
            event.preventDefault();
            const [table] = NoteEditor.hasPath(editor, parent1Path) ? NoteEditor.node(editor, parent1Path) : [];
            const selectedCells: NodeEntry[] = [];
            if (table) {
              (table as TableElement).children?.forEach((row: Node, rowIndex: number) =>
                (row as TableRowElement).children?.forEach((node: Node, cellIndex: number) => {
                  const cell = node as TableCellElement;
                  const cellPath = [...parent1Path, rowIndex, cellIndex];
                  if (
                    cell.type === BlockType.TableCell &&
                    (Range.includes(selection, NoteEditor.start(editor, cellPath)) ||
                      Range.includes(selection, NoteEditor.end(editor, cellPath)))
                  ) {
                    selectedCells.push([cell, cellPath]);
                  }
                }),
              );
            }
            NoteEditor.withoutNormalizing(editor, () => {
              selectedCells.forEach(cellEntry => {
                Transforms.removeNodes(editor, {
                  at: cellEntry[1],
                });
                Transforms.insertNodes(editor, { ...cellEntry[0], children: [{ text: '' }] } as TableCellElement, {
                  at: cellEntry[1],
                });
              });
            });
            Transforms.collapse(editor, { edge: 'start' });
          }
        }
      }
    }
  }

  return isForwardDel || isBackwardDel || isCut;
}

const BackspaceKeyPlugin: SlatePlugin = {
  key: 'backspace-plugin',
  onKeyDown: (event: React.KeyboardEvent<HTMLDivElement>, editor: Editor) => backspaceKeyPlugin(event, editor, false),
};

export default BackspaceKeyPlugin;
