import React, { useCallback, useState } from 'react';

import SelectField from 'components/Form/SelectField';
import OpportunityIcon from '../../components/icons/OpportunityIcon';
import useDocumentStore from '../../stores/document';
import searchItems from '../../api/search/search-items';
import moment from 'moment/moment';
import useTeamMemberRequestStore from '../../stores/team-member-request';

interface Props {
  requestId: string;
  opportunityDocumentId: string | null;
}

function OpportunitySelector({ requestId, opportunityDocumentId }: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const documentStore = useDocumentStore();
  const teamMemberRequestStore = useTeamMemberRequestStore();

  const menus = useCallback(
    (searchStr?: string) => {
      if (searchStr) {
        return searchItems(searchStr, false, true, false, false).then(rsp =>
          rsp
            .map(item => ({
              label: item.title,
              value: item.documentId || '',
              icon: <OpportunityIcon className="min-w-[1rem] w-4 h-4 fill-orange-400 text-orange-400" />,
            }))
            .filter(item => item.value),
        );
      }
      return documentStore.entityDocuments
        .sort((a, b) => {
          return moment(a.accessedAt).unix() - moment(b.accessedAt).unix();
        })
        .filter(ed => ed.entity.name === 'Opportunity')
        .map(item => ({
          label: item.name,
          value: item.id,
          icon: <OpportunityIcon className="min-w-[1rem] w-4 h-4 fill-orange-400 text-orange-400" />,
        }));
    },
    [documentStore.entityDocuments, documentStore.isLoading],
  );

  const updateOpportunityDocumentId = async (id: string | null) => {
    if (id) {
      setIsLoading(true);
      await teamMemberRequestStore.updateRequest(requestId, id);
      setIsLoading(false);
    }
  };

  const loading = isLoading || documentStore.isLoading;

  return (
    <SelectField
      placeholder="Select Opportunity"
      value={opportunityDocumentId}
      onChange={v => updateOpportunityDocumentId((v as string) || null)}
      searchAble
      popoverMinWidth={Math.min(400, document.body.clientWidth)}
      menus={menus}
      loading={loading}
      showClearBtn={false}
    />
  );
}

export default OpportunitySelector;
