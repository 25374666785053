import { ClockIcon, EllipsisVerticalIcon, TrashIcon, UserIcon } from '@heroicons/react/24/outline';
import updateTask from 'api/task/update-task';
import BaseButton from 'components/BaseButton';
import BaseCheckbox from 'components/Form/BaseCheckbox';
import DatePickerButton from 'components/Form/DatePickerButton';
import { BlockType } from 'components/NoteEditor/types';
import ResponseView from 'components/ResponseView';
import { debounce, isEqual } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Descendant } from 'slate';
import useUserStore from 'stores/user';
import { Task } from 'types/task';
import { getMemberName } from 'utils/string';
import { concat } from 'utils/styling';
import { openMentionPopover } from '../Mention/MentionPopover';
import styles from './TaskEditor.module.css';
import TaskSlateEditor, { TaskSlateEditorRef } from './TaskSlateEditor';
import createTask from 'api/task/create-task';
import CommentUserAvatar from '../Comment/CommentUserAvatar';
import constants from 'utils/constants';
import { openDeleteConfirmModal } from 'components/BaseModal';
import deleteTask from 'api/task/delete-task';
import { createNode } from 'components/NoteEditor/utils';
import apiErrors, { SuperpanelAPIError } from 'utils/errors';
import ButtonPopover from 'components/ButtonPopover';
import createTemplateTask from 'api/task/create-template-task';
import getTemplateTask from 'api/task/get-template-task';
import instantiateTemplateTask from 'api/task/instantiate-template-task';
import getTask from 'api/task/get-task';
import updateTemplateTask from 'api/task/update-template-task';
import deleteTemplateTask from 'api/task/delete-template-task';

const TASK_CONTENT_UPDATE_DEBOUNCE_TIME = 500;

interface Props {
  className?: string;
  taskId: string;
  beingCreatedBy?: string;
  taskInitContent?: Descendant[];
  documentId: string | null;
  opportunityId: string | null;
  onCreateTask?: (task: Task) => void;
  onInstantiateTask?: (task: Task) => void;
  initTask?: Task;
  onUpdate?: (task: Task) => void;
  autoFocus?: boolean;
  onSplitToNewTask?: (newContent: Descendant[]) => void;
  onDelete?: (leftContent?: Descendant[]) => void;
  canDeleteInSlateEditor?: boolean;
  onComment?: () => void;
  onFocus?: () => void;
  scrollContainer?: HTMLDivElement;
  isFromTemplate?: boolean;
  readOnly?: boolean;
  hideCheckbox?: boolean;
}

const TaskEditor = React.forwardRef<TaskSlateEditorRef, Props>(
  (
    {
      className,
      taskId,
      beingCreatedBy,
      taskInitContent,
      documentId,
      opportunityId,
      onCreateTask,
      onInstantiateTask,
      initTask,
      onUpdate,
      autoFocus,
      onSplitToNewTask,
      onDelete,
      canDeleteInSlateEditor,
      onComment,
      onFocus,
      scrollContainer,
      isFromTemplate,
      readOnly,
      hideCheckbox,
    }: Props,
    ref,
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isLoading, setIsLoading] = useState(false);
    const [updating, setUpdating] = useState<'done' | 'assignedToUserId' | 'dueDate' | 'content' | ''>('');
    const [deleting, setDeleting] = useState(false);
    const [error, setError] = useState('');
    const [taskEntity, setTaskEntity] = useState<Task | undefined>(initTask);
    const userStore = useUserStore();
    const assignToRef = useRef<HTMLButtonElement>(null);
    const editorRef = useRef<TaskSlateEditorRef>(null);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    useImperativeHandle(ref, () => editorRef.current!, []);

    let getTaskFun = getTask;
    if (isFromTemplate) {
      getTaskFun = getTemplateTask;
    }

    let updateTaskFun = updateTask;
    if (isFromTemplate) {
      updateTaskFun = updateTemplateTask;
    }

    let deleteTaskFun = deleteTask;
    if (isFromTemplate) {
      deleteTaskFun = deleteTemplateTask;
    }

    useEffect(() => {
      // case 1. create a real task instance in document
      if (!isFromTemplate && !taskId && documentId && beingCreatedBy === userStore.user?.id) {
        setIsLoading(true);
        createTask(documentId, taskInitContent || [createNode(BlockType.Paragraph)])
          .then(rsp => {
            onCreateTask?.(rsp);
            setTaskEntity(rsp);
            onUpdate?.(rsp);
            setTimeout(() => {
              editorRef.current?.focus();
            });
          })
          .catch(() => setError('failed to create task!'))
          .finally(() => setIsLoading(false));
      }
      // case 2. create a teamplate task in template
      else if (isFromTemplate && !taskId && beingCreatedBy === userStore.user?.id) {
        setIsLoading(true);
        createTemplateTask(taskInitContent || [createNode(BlockType.Paragraph)])
          .then(rsp => {
            onCreateTask?.(rsp);
            setTaskEntity(rsp);
            onUpdate?.(rsp);
            setTimeout(() => {
              editorRef.current?.focus();
            });
          })
          .catch(() => setError('failed to create task!'))
          .finally(() => setIsLoading(false));
      }
      if (taskId && !taskEntity) {
        getTaskFun(taskId)
          .then(async rsp => {
            let rep1 = rsp;
            // The moment when user applies a template, if there are tasks in the template, we need to instantiate them
            // if the editor is not a template editor and the task is a template task, we instantiate it
            // instantiate task from template
            if (!isFromTemplate && rsp.isTemplateTask && documentId) {
              rep1 = await instantiateTemplateTask(taskId, documentId);
            }
            setTaskEntity(rep1);
            onInstantiateTask?.(rep1);
            onUpdate?.(rep1);
          })
          .catch((err: SuperpanelAPIError) => {
            if (err.code === apiErrors.DatabaseError.code) {
              setTaskEntity({ isDeleted: true } as Task);
            } else {
              setError('failed to load task!');
            }
          });
      }
    }, [taskId, documentId]);

    const handleTaskUpdate = useCallback(
      (newV: string | boolean | null | Descendant[], field: 'done' | 'assignedToUserId' | 'dueDate') => {
        if (taskEntity?.id) {
          setUpdating(field);
          updateTaskFun(taskEntity.id, { [field]: newV })
            .then(rsp => {
              setTaskEntity(rsp);
              onUpdate?.(rsp);
            })
            .finally(() => setUpdating(''));
        } else {
          const newTask = { ...taskEntity, [field]: newV } as Task;
          setTaskEntity(newTask);
          onUpdate?.(newTask);
        }
      },
      [taskEntity],
    );

    const handleTaskContentUpdate = useCallback(
      debounce(
        (newContent: Descendant[]) => {
          if (!isEqual(taskEntity?.content || [createNode(BlockType.Paragraph)], newContent)) {
            if (taskEntity?.id) {
              setUpdating('content');
              updateTaskFun(taskEntity.id, { content: newContent })
                .then(rsp => {
                  setTaskEntity(rsp);
                  onUpdate?.(rsp);
                })
                .finally(() => setUpdating(''));
            } else {
              const newTask = { ...taskEntity, content: newContent } as Task;
              setTaskEntity(newTask);
              onUpdate?.(newTask);
            }
          }
        },
        taskEntity?.id ? TASK_CONTENT_UPDATE_DEBOUNCE_TIME : 0,
      ),
      [taskEntity],
    );

    const dueDateStr = useMemo(
      () => (taskEntity?.dueDate ? moment(taskEntity.dueDate).format(constants.DATE_FORMAT) : ''),
      [taskEntity?.dueDate],
    );

    const dueDateTooltip = useMemo(() => (dueDateStr ? `Due date: ${dueDateStr}` : 'Select due date'), [dueDateStr]);

    const assignedToMember = useMemo(
      () => userStore.members?.find(item => item.id === taskEntity?.assignedToUserId),
      [taskEntity?.assignedToUserId, userStore.members],
    );

    const assignedToTooltip = useMemo(
      () => (assignedToMember ? `Assigned to: ${getMemberName(assignedToMember)}` : 'Assign to'),
      [assignedToMember],
    );

    return (
      <ResponseView loading={!taskEntity && !error} error={error} className={styles.taskWrap} smallLoader>
        {taskEntity?.isDeleted ? (
          <span className={styles.deletedTip}>This task has been moved or deleted, please remove this block.</span>
        ) : (
          <div className={concat(styles.taskWrap, taskEntity?.done && styles.done, className)}>
            {!hideCheckbox && (
              <BaseCheckbox
                checked={taskEntity?.done}
                onChange={checked => handleTaskUpdate(checked, 'done')}
                loading={updating === 'done'}
                className={styles.checkbox}
                disabled={readOnly}
              />
            )}
            <TaskSlateEditor
              ref={editorRef}
              className={styles.editor}
              readOnly={!!readOnly}
              content={taskEntity?.content || undefined}
              onChange={content => handleTaskContentUpdate(content)}
              documentId={documentId}
              opportunityId={opportunityId}
              autoFocus={autoFocus}
              onSplitToNewTask={onSplitToNewTask}
              onDeleteCurrentTask={
                canDeleteInSlateEditor
                  ? leftContent => {
                      onDelete?.(leftContent);
                    }
                  : undefined
              }
              onComment={onComment}
              onFocus={onFocus}
              scrollContainer={scrollContainer}
            />
            <div className={styles.actions}>
              <DatePickerButton
                className={concat(styles.actionBtn)}
                tooltip={dueDateTooltip}
                value={taskEntity?.dueDate}
                onChange={date => handleTaskUpdate(date, 'dueDate')}
                renderContent={() => (
                  <div className="flex items-center gap-1">
                    {!!dueDateStr && <span className={styles.dueDateStr}>{dueDateStr}</span>}
                    <ClockIcon width={20} />
                  </div>
                )}
                loading={updating === 'dueDate'}
                disabled={readOnly}
              />
              <BaseButton
                className={styles.actionBtn}
                tooltip={assignedToTooltip}
                color="secondary"
                variant="text"
                iconBtn
                ref={assignToRef}
                onClick={() => {
                  if (assignToRef.current) {
                    openMentionPopover(assignToRef.current, true).then(rsp => {
                      if (rsp && rsp.id !== taskEntity?.assignedToUserId) {
                        handleTaskUpdate(rsp.id, 'assignedToUserId');
                      }
                      if (rsp === null && !!taskEntity?.assignedToUserId) {
                        handleTaskUpdate('', 'assignedToUserId');
                      }
                    });
                  }
                }}
                loading={updating === 'assignedToUserId'}
                disabled={readOnly}
              >
                {assignedToMember ? (
                  <CommentUserAvatar
                    member={assignedToMember}
                    hideName
                    hideTooltip
                    nameClassName={concat(styles.actionBtnSelected, 'assigned-user-btn')}
                  />
                ) : (
                  <UserIcon width={20} />
                )}
              </BaseButton>
              {!!onDelete && (
                <ButtonPopover
                  btnColor="secondary"
                  btnVariant="text"
                  tooltip="More actions"
                  btnClassName={styles.actionBtn}
                  closeWhenPopoverClick
                  iconBtn
                  loading={deleting}
                  menus={[{ label: 'Delete task', value: 'delete', icon: <TrashIcon className="w-5 h-5" /> }]}
                  handleMenuClick={async menu => {
                    if (menu.value === 'delete') {
                      const confirmed = await openDeleteConfirmModal(
                        `Are you sure you want to delete this task?`,
                        'Once the task is removed, you cannot retrieve it back.',
                      );
                      if (confirmed) {
                        try {
                          setDeleting(true);
                          await deleteTaskFun(taskId);
                          onDelete?.();
                        } finally {
                          setDeleting(false);
                        }
                      }
                    }
                  }}
                  horizontalOrigin="right"
                  popoverClassName="w-[12rem]"
                  disabled={readOnly}
                >
                  <EllipsisVerticalIcon width={16} />
                </ButtonPopover>
              )}
            </div>
          </div>
        )}
      </ResponseView>
    );
  },
);

TaskEditor.displayName = 'TaskEditor';

TaskEditor.defaultProps = {
  className: '',
  beingCreatedBy: undefined,
  initTask: undefined,
  taskInitContent: undefined,
  onCreateTask: undefined,
  onInstantiateTask: undefined,
  onUpdate: undefined,
  autoFocus: false,
  onSplitToNewTask: undefined,
  onDelete: undefined,
  canDeleteInSlateEditor: undefined,
  onComment: undefined,
  onFocus: undefined,
  scrollContainer: undefined,
  isFromTemplate: false,
  readOnly: false,
  hideCheckbox: false,
};

export default TaskEditor;
