import { XMarkIcon } from '@heroicons/react/24/outline';
import React from 'react';
import useModalStore from '../../../stores/modal';

interface Props {
  onOkay: () => Promise<void>;
}

function SyncWarningModal({ onOkay }: Props) {
  const modalStore = useModalStore();

  return (
    <div className="w-96 p-5">
      <div className="flex justify-end items-center">
        <button
          type="button"
          className="flex justify-center items-center rounded bg-gray-100 hover:bg-gray-200 p-1"
          onClick={modalStore.close}
        >
          <XMarkIcon className="w-5 h-5" />
        </button>
      </div>
      <div className="my-5">
        <div className="text-lg font-medium">Sync Private Document</div>
        <div className="mt-3 text-sm text-gray-400">
          Syncing private document to Salesforce Note will automatically change the document to public. Would you like
          to continue?
        </div>
      </div>
      <div className="flex justify-end items-center">
        <button
          className="px-4 py-3 bg-gray-200 hover:bg-gray-300 rounded mr-2"
          type="button"
          onClick={modalStore.close}
        >
          Cancel
        </button>
        <button
          className="px-4 py-3 bg-blue-500 hover:bg-blue-400 text-white rounded"
          type="button"
          onClick={() => {
            onOkay();
            modalStore.close();
          }}
        >
          Continue
        </button>
      </div>
    </div>
  );
}

export default SyncWarningModal;
