import { useEffect } from 'react';
import noteService from '../note-service/note-service';
import useUserStore from '../stores/user';

function WebsocketNoteServiceConnector() {
  const userStore = useUserStore();

  const userId = userStore.user?.id;
  const teamId = userStore.user?.teamId;
  const noteServiceWebSocketToken = userStore.user?.noteServiceWebSocketToken;

  useEffect(() => {
    noteService.connect();
    return () => noteService.disconnect();
  }, []);

  useEffect(() => {
    if (userId && teamId && noteServiceWebSocketToken) {
      noteService.registerUser(userId, teamId, noteServiceWebSocketToken);
    }
  }, [userId, teamId, noteServiceWebSocketToken]);

  return null;
}

export default WebsocketNoteServiceConnector;
