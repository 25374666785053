import React from 'react';
import moment from 'moment';
import { CalendarIcon, CheckIcon } from '@heroicons/react/24/outline';

import NoteIcon from 'components/icons/NoteIcon';
import OpportunityIcon from 'components/icons/OpportunityIcon';

import { EntityDocument } from 'types/entity';
import { Document } from 'types/document';

import constants from 'utils/constants';
import AccountIcon from 'components/icons/AccountIcon';

interface Props {
  isLinked: boolean;
  doc: Document;
  updateData: (objType: 'opp' | 'doc', objId: string) => void;
  sortedOpportunities: EntityDocument[];
  sortedAccounts: EntityDocument[];
}

interface DocTitleProps {
  doc: Document;
}

function DocTitle({ doc }: DocTitleProps) {
  let eventTime = '';
  if (doc.event) {
    eventTime = moment(doc.event.startsAt).format(constants.DATETIME_FORMAT);
  }

  return (
    <div className="flex items-start text-sm pl-2 gap-2">
      {doc.event !== null ? (
        <>
          <CalendarIcon className="w-4 h-4 flex-none text-blue-500" />
          <div className="text-gray-500 text-left">
            <div className="">{doc.event.subject}</div>
            <div>{eventTime}</div>
          </div>
        </>
      ) : (
        <>
          <NoteIcon className="w-4 h-4 flex-none text-blue-500" />
          <span className="text-gray-500">{doc.docTitle}</span>
        </>
      )}
    </div>
  );
}

function DocumentButton({ isLinked, doc, updateData, sortedOpportunities, sortedAccounts }: Props) {
  let oppName = '';
  let acctName = '';
  // get associated opportunity and account
  const opp = sortedOpportunities.find(
    o => doc.parent && doc.parent.entityName === 'Opportunity' && doc.parent.id === o.id,
  );
  if (opp) {
    oppName = opp.name;
    const acct = sortedAccounts?.find(a => a.id === opp.parentId);
    if (acct) {
      acctName = acct.name;
    }
  }
  // get associated account
  if (oppName === '' && acctName === '') {
    const acct = sortedAccounts.find(a => doc.parent && doc.parent.entityName === 'Account' && doc.parent.id === a.id);
    if (acct) {
      acctName = acct.name;
    }
  }

  let fullTitle: JSX.Element = <DocTitle doc={doc} />;

  fullTitle = (
    <div className="flex flex-col gap-1">
      <DocTitle doc={doc} />
      {oppName !== '' && (
        <div className="flex items-center gap-2 pl-8 text-xs">
          <span className="text-gray-500">(</span>
          <OpportunityIcon className="w-3 h-3 text-orange-500 fill-orange-500" />
          <span className="text-gray-500">{oppName}</span>
          <span className="text-gray-500">)</span>
        </div>
      )}
      {acctName !== '' && (
        <div className="flex items-center gap-2 pl-8 text-xs">
          <span className="text-gray-500">(</span>
          <AccountIcon className="w-3 h-3" />
          <span className="text-gray-500">{acctName}</span>
          <span className="text-gray-500">)</span>
        </div>
      )}
    </div>
  );

  return (
    <button
      type="button"
      className={`flex justify-between w-full min-h-8 items-center pr-3 p-2 hover:bg-slate-100 rounded-lg ${
        isLinked ? 'cursor-default' : 'hover:bg-gray-100 hover:cursor-pointer'
      }`}
      onClick={() => updateData('doc', doc.id)}
      disabled={isLinked}
    >
      {fullTitle}
      {isLinked && <CheckIcon className="w-5 h-5 flex-none text-blue-500" />}
    </button>
  );
}

export default DocumentButton;
