/* eslint-disable no-nested-ternary */
import React, { useMemo, useRef, useState } from 'react';
import { ShareIcon } from '@heroicons/react/24/outline';

import { EntityDocumentRich as ApiResponse } from 'api/entities/get-entity';

import EntityHeader from 'components/EntityHeader';
import ContextPanelView from 'components/ContextPanelView';
import NoteEditor from 'components/NoteEditor';
import Plugins, { Plugin } from 'components/NoteEditor/Plugins';

import useDocumentStore from 'stores/document';
import useModalStore from 'stores/modal';

import orderDocuments from 'utils/order-documents';

import DocumentLink from './DocumentLink';
import OpportunityLink from './OpportunityLink';
import DocumentTitle from 'pages/Document/DocumentView/DocumentTitle';
import TitleTags from 'components/EntityHeader/TitleTags';
import DiscoveryQuestionList from 'components/DiscoveryQuestionList/DiscoveryQuestionList';
import ShareModal from 'pages/Document/DocumentView/ShareModal';
import { PanelContext } from 'stores/context-panel';
import Tabs from 'components/Tabs';
import EntityVectorizationStatus from 'components/EntityVectorizationStatus';
import { useNewDocumentModal } from 'components/NewDocumentModal';
import UploadDocumentModal from 'components/NoteMenu/UploadDocumentModal';

interface Props {
  apiResponse: ApiResponse;
}
function AccountView({ apiResponse }: Props) {
  const documentStore = useDocumentStore();
  const modalStore = useModalStore();
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const { createNewDocumentWithModal } = useNewDocumentModal();

  const opportunities = useMemo(
    () => documentStore.entityDocuments.filter(o => o.parentId === apiResponse.entityDocument.id),
    [documentStore.entityDocuments, apiResponse.entityDocument],
  );
  const accountDocuments = useMemo(
    () =>
      documentStore.documents.filter(
        d => d.parent && d.parent.entityName === 'Account' && d.parent.id === apiResponse.entityDocument.id,
      ),
    [documentStore.documents, apiResponse.entityDocument],
  );
  const accountCrmDocuments = useMemo(
    () =>
      documentStore.crmNotes.filter(
        n => n.linkedEntity.name === 'Account' && n.linkedEntity.id === apiResponse.entityDocument.crm?.id,
      ),
    [documentStore.crmNotes, apiResponse.entityDocument],
  );
  const accountItems = useMemo(
    () => orderDocuments(accountDocuments, accountCrmDocuments),
    [accountDocuments, accountCrmDocuments],
  );

  const panelContexts: PanelContext[] = useMemo(() => {
    return ['recordings', 'chat', 'meetings'];
  }, []);

  const plugins = useMemo<Plugin[]>(() => {
    return [
      new Plugins.HotKey(),
      new Plugins.EmojiCommand(),
      new Plugins.EnterKey(),
      new Plugins.TabKey(),
      new Plugins.BackSpace(),
      new Plugins.ItemList(),
      new Plugins.Copy(),
      new Plugins.Cut(),
      new Plugins.Paste(),
      new Plugins.ItemList(),
    ];
  }, []);
  const openShareModal = () => {
    modalStore.setState({
      element: <ShareModal entityType="account" documentId={apiResponse.entityDocument.id} />,
      isOpen: true,
    });
  };

  const tabs = useMemo(
    () => [
      { label: 'Fields', value: 'fields' },
      { label: 'Documents', value: 'documents' },
    ],
    [],
  );
  const [currentTab, setCurrentTab] = useState(tabs[0].value);

  return (
    <div className="w-full h-full flex flex-col">
      <EntityHeader
        entityType="account"
        documentId={apiResponse.entityDocument.id}
        crmNoteId={null}
        panelContexts={panelContexts}
        menuItems={
          <button
            id="share-button"
            className="px-2 py-1 text-sm rounded bg-gray-100 hover:bg-gray-200 flex items-center gap-2"
            onClick={() => openShareModal()}
            type="button"
          >
            <ShareIcon className="h-4 w-4 text-gray-500" />
            <span>Share</span>
          </button>
        }
      />
      <EntityVectorizationStatus entityId={apiResponse.entityDocument.id} />
      <ContextPanelView viewKey="doc-view-context-panel" scrollable={false}>
        <div className="flex items-center pr-5 gap-2 pb-2">
          <Tabs
            tabs={tabs}
            tabClassName="!text-gray-500 !text-sm !font-normal"
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            containerClassName="flex-1"
          />
          <button
            className="bg-blue-600 hover:bg-blue-700 text-sm text-white px-2 py-1 rounded mt-1"
            onClick={() => createNewDocumentWithModal({ parentId: apiResponse.entityDocument.id, title: null })}
            type="button"
          >
            New Document
          </button>
          <button
            className="px-2 py-1 text-sm rounded bg-gray-100 hover:bg-gray-200 flex items-center gap-2 mt-1"
            onClick={() =>
              modalStore.setState({
                element: (
                  <UploadDocumentModal
                    parentDocumentId={apiResponse.entityDocument.id}
                    siblingDocuments={accountDocuments}
                  />
                ),
                isOpen: true,
              })
            }
            type="button"
          >
            Upload Document
          </button>
        </div>
        <div className="w-full h-full overflow-y-auto" ref={scrollContainerRef}>
          <div className="flex flex-col m-auto max-w-[900px] pb-6 px-10 context-panel-left-content">
            <div className="ml-[4rem] mb-2">
              <DocumentTitle disabled initTitle={apiResponse.entityDocument.name} placeholder="Untitled" />
              <TitleTags documentId={apiResponse.entityDocument.id} type="account" />
            </div>
            <div className={currentTab === 'fields' ? '' : 'hidden'}>
              <NoteEditor
                key={apiResponse.entityDocument.id}
                documentId={apiResponse.entityDocument.id}
                opportunityId={null}
                blockPlaceholder="This a new line"
                emptyParagraphPlaceholder="Start to log your account knowledge"
                serviceId={apiResponse.note.serviceId}
                serviceToken={apiResponse.note.serviceToken}
                plugins={plugins}
                type="acc-editor"
                autoFocusWhenEmpty
                scrollContainer={scrollContainerRef.current || undefined}
              />
              <div className="flex flex-col ml-[4rem] mr-[1rem] gap-2">
                <DiscoveryQuestionList entityDocument={apiResponse.entityDocument} />
              </div>
            </div>
            {currentTab === 'documents' && (
              <div className="flex flex-col ml-[4rem] mr-[1rem] gap-2 mt-4">
                {opportunities.map(o => (
                  <OpportunityLink key={o.id} title={o.name} to={`/opportunity/${o.id}`} />
                ))}
                {accountItems.map(element => {
                  if ('docTitle' in element) {
                    // Document
                    return (
                      <DocumentLink
                        key={element.id}
                        id={element.id}
                        title={element.event ? element.event.subject : element.docTitle}
                        link={element.uploadedFile ? `/uploaded-file/${element.id}` : `/document/${element.id}`}
                        event={element.event}
                        isPublic={element.isPublic}
                        isFavourite={element.isFavourite}
                        isRecordingAvailable={element.calls.length > 0}
                        isFavorable
                        type={
                          element.uploadedFile
                            ? element.uploadedFile.fileType.endsWith('pdf')
                              ? 'pdf'
                              : element.uploadedFile.fileType.includes('text/plain')
                              ? 'txt'
                              : 'word'
                            : 'document'
                        }
                      />
                    );
                  }
                  // CRM Document
                  return (
                    <DocumentLink
                      key={element.id}
                      id={element.id}
                      title={element.title}
                      link={`/crm-note/${element.id}`}
                      event={null}
                      isPublic
                      isFavourite={false}
                      isRecordingAvailable={false}
                      isFavorable={false}
                      type="crm"
                    />
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </ContextPanelView>
    </div>
  );
}

export default AccountView;
