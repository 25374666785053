/* eslint-disable jsx-a11y/no-static-element-interactions */
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import Menu from '@mui/material/Menu';
import React, { useMemo, useRef, useState } from 'react';

import { CrmFieldType } from 'types/crm';
import { ColumnFilter } from '../../Table/types';
import { ReportingFilter } from '../../../types/reporting';
import { stringifyFilter } from '../../Table/utils';
import Tooltip from '../../Tooltip';
import FilterIcon from '../../icons/FilterIcon';
import FieldTypeIcon from './FieldTypeIcon';

interface Props {
  fieldType: CrmFieldType;
  title: string;
  children: JSX.Element;
  reportingFilter?: ReportingFilter;
}

function FilterMenu({ fieldType, title, children, reportingFilter }: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  const filterStr = useMemo(() => {
    let columnFilter: ColumnFilter | undefined;
    if (reportingFilter) {
      columnFilter = {
        type: reportingFilter.filterType,
        value: reportingFilter.filterValue,
      };
    }
    return stringifyFilter(columnFilter, fieldType);
  }, [fieldType, reportingFilter]);

  return (
    <div
      onMouseEnter={() => {
        setIsOpen(true);
      }}
      onMouseLeave={() => {
        setIsOpen(false);
      }}
    >
      <div ref={ref} className={`flex justify-between items-center px-5 py-2 rounded-lg ${isOpen && 'bg-blue-50'}`}>
        <div className="flex items-center text-sm shrink-0">
          <FieldTypeIcon className="w-5 h-5 mr-2 text-gray-400" fieldType={fieldType} />
          <div>{title}</div>
        </div>
        <div className="flex gap-2 truncate">
          {reportingFilter && (
            <Tooltip
              title={
                filterStr ? (
                  <div>
                    <strong>Filter:&nbsp;</strong>
                    {filterStr}
                  </div>
                ) : undefined
              }
              placement="top"
            >
              <div className="flex items-center truncate">
                <FilterIcon className="w-4 h-4 text-green-500 flex-shrink-0 flex-grow-0 ml-1" />
                <span className="truncate text-xs text-gray-400 whitespace-nowrap">{filterStr}</span>
              </div>
            </Tooltip>
          )}
          <ChevronRightIcon className="w-4 h-4 stroke-2 text-gray-400 shrink-0" />
        </div>
      </div>
      <Menu
        style={{ pointerEvents: 'none', marginLeft: '0.5rem' }}
        anchorEl={ref.current}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => setIsOpen(false)}
        variant="menu"
      >
        <div style={{ pointerEvents: 'auto' }}>{children}</div>
      </Menu>
    </div>
  );
}

FilterMenu.defaultProps = {
  reportingFilter: undefined,
};

export default FilterMenu;
