import { User } from 'types/user';
import httpClient from './http-client';

export interface Response {
  user: User;
}

export default async (type: 'salesforce' | 'google' | 'microsoft', code: string): Promise<Response> => {
  const { data } = await httpClient.post<Response>('/login-oauth', { type, code });
  return data;
};
