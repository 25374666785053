import React, { useEffect, useMemo, useState } from 'react';

import SalesforceIcon from 'components/icons/SalesforceIcon';
import ContextPanelView from 'components/ContextPanelView';
import EntityHeader from 'components/EntityHeader';

import useChatsStore from 'stores/chats';
import useFieldValuesStore from 'stores/field-values';
import useRecordingsStore from 'stores/recordings';
import { PanelContext } from 'stores/context-panel';

import { CrmNote } from 'types/document';
import { EntityDocument } from '../../types/entity';

import styles from './CrmNoteView.module.css';

interface Props {
  crmNote: CrmNote;
  entityDocument: EntityDocument;
  crmNoteImported: () => Promise<void>;
}

interface State {
  isImportLoading: boolean;
}

function CrmNoteView({ crmNote, entityDocument, crmNoteImported }: Props) {
  const [state, setState] = useState<State>({
    isImportLoading: false,
  });

  const chatsStore = useChatsStore();
  const fieldValuesStore = useFieldValuesStore();
  const recordingsStore = useRecordingsStore();

  useEffect(() => {
    fieldValuesStore.fetch(entityDocument.id);
    recordingsStore.fetch(entityDocument.id);
    chatsStore.fetch(entityDocument.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityDocument.id]);

  const importCrmNote = async () => {
    setState(oldState => ({ ...oldState, isImportLoading: true }));
    await crmNoteImported();
    setState(oldState => ({ ...oldState, isImportLoading: false }));
  };

  const panelContexts: PanelContext[] = useMemo(() => ['recordings', 'chat', 'meetings'], []);

  return (
    <div className={styles.layout}>
      <EntityHeader
        entityType="crm-note"
        documentId={entityDocument.id}
        crmNoteId={crmNote.id}
        panelContexts={panelContexts}
        menuItems={null}
      />
      <ContextPanelView viewKey="doc-view-context-panel">
        <div className="flex justify-center">
          <div className="w-3/4 py-12">
            <div className="flex justify-between items-center text-sm border border-blue-600 bg-blue-50 rounded-lg px-5 py-3">
              <div className="text-blue-600">
                This is a read-only Salesforce note. Import this note into Superpanel to edit and save changes.
              </div>
              <button
                type="button"
                className="flex items-center bg-blue-600 rounded px-5 py-2 text-white hover:bg-blue-500"
                disabled={state.isImportLoading}
                onClick={() => importCrmNote()}
              >
                <SalesforceIcon className="w-6 h-6 mr-2" />
                <span>{state.isImportLoading ? 'Importing' : 'Import'}</span>
              </button>
            </div>
            <div className="text-3xl font-bold my-8">My Salesforce Note</div>
            {/* eslint-disable-next-line react/no-danger */}
            <div className="text-gray-500 text-sm" dangerouslySetInnerHTML={{ __html: crmNote.content || '' }} />
          </div>
        </div>
      </ContextPanelView>
    </div>
  );
}

export default CrmNoteView;
