import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import getCrmNote, { Response as ApiResponse } from 'api/crm-notes/get-crm-note';

import Loader from 'components/Loader';

import useContextPanelStore from 'stores/context-panel';
import useDocumentStore from 'stores/document';

import CrmNoteView from './CrmNote/CrmNoteView';
import styles from './CrmNote.module.css';
import RightDrawerPanel from 'components/RightDrawerPanel';

interface State {
  isLoading: boolean;
  apiResponse: null | ApiResponse;
}

function CrmNoteC() {
  const navigate = useNavigate();
  const { crmNoteId } = useParams();

  const [state, setState] = useState<State>({
    isLoading: true,
    apiResponse: null,
  });

  const documentStore = useDocumentStore();
  const contextPanelStore = useContextPanelStore();

  useEffect(() => {
    const fetchCrmNote = async (): Promise<void> => {
      if (crmNoteId !== undefined && (state.apiResponse === null || state.apiResponse.crmNote.id !== crmNoteId)) {
        setState(oldState => ({ ...oldState, isLoading: true }));
        const apiResponse = await getCrmNote(crmNoteId);
        setState(oldState => ({ ...oldState, isLoading: false, apiResponse }));
      }
    };

    fetchCrmNote();

    return () => {
      contextPanelStore.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crmNoteId]);

  if (state.isLoading || state.apiResponse === null) {
    return (
      <div className={styles['crm-note-loader']}>
        <Loader />
      </div>
    );
  }

  const { crmNote, entityDocument } = state.apiResponse;
  const importNote = async () => {
    const document = await documentStore.createDocumentFromCrm(crmNote.id);
    navigate(`/document/${document.id}`);
  };

  return (
    <div className={styles['crm-note-container']}>
      <CrmNoteView crmNote={crmNote} entityDocument={entityDocument} crmNoteImported={() => importNote()} />
      <RightDrawerPanel />
    </div>
  );
}

export default CrmNoteC;
