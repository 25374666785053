import React from 'react';
import { TrashIcon } from '@heroicons/react/24/outline';
import ButtonPopover from 'components/ButtonPopover';
import EditIcon from 'components/icons/EditIcon';
import { DocTriggerCondition } from 'types/doc-trigger-condition';
import openEditDocTriggerConditionModal from './EditDocTriggerConditionModal';
import { openDeleteConfirmModal } from 'components/BaseModal';
import ThreeVerticalDotsIcon from 'components/icons/ThreeVerticalDotsIcon';
import styles from './DocTriggerConditionRow.module.css';
import TemplateInfo from './TemplateInfo';
import { concat } from 'utils/styling';
import { noop } from 'lodash';
import deleteDocTriggerCondition from 'api/doc-trigger-conditions/delete-doc-trigger-condition';

interface Props {
  className?: string;
  docTrigger: DocTriggerCondition;
  onUpdate: (newDocTrigger: DocTriggerCondition) => void;
  onDelete: () => void;
  allDocTriggers: DocTriggerCondition[] | undefined;
}

function DocTriggerConditionRow({ className, docTrigger, onUpdate, onDelete, allDocTriggers }: Props) {
  return (
    <div className={concat(styles.row, className)}>
      <div className={styles.content}>
        <div className={concat(styles.label)}>Let AI work on</div>
        {!!docTrigger.template && (
          <div className={concat(styles.borderContent, 'px-2 max-w-full')}>
            <TemplateInfo className="w-full truncate" template={docTrigger.template} showAIWorkerCount />
          </div>
        )}
        <div className={concat(styles.label)}>when</div>
        <div className={concat(styles.borderContent)}>
          <div className={styles.field}>
            {docTrigger.conditionField.entity} {docTrigger.conditionField.label}
          </div>
          <div className={styles.operator}>{docTrigger.conditionOperator}</div>
          <div className={styles.value}>{docTrigger.conditionValue}</div>
        </div>
      </div>
      <ButtonPopover
        btnColor="secondary"
        btnVariant="text"
        tooltip="Edit or Delete Document Automation"
        btnClassName="!w-8 !mr-1"
        closeWhenPopoverClick
        menus={[
          { label: 'Edit', value: 'edit', icon: <EditIcon className="w-5 h-5" /> },
          { label: 'Delete', value: 'delete', icon: <TrashIcon className="w-5 h-5" /> },
        ]}
        handleMenuClick={async menu => {
          if (menu.value === 'edit') {
            const newDocTrigger = await openEditDocTriggerConditionModal(docTrigger, allDocTriggers);
            if (newDocTrigger) {
              onUpdate(newDocTrigger);
            }
          }
          if (menu.value === 'delete') {
            openDeleteConfirmModal(
              `Are you sure you want to delete this documentation task?`,
              'This action cannot be undone.',
              async () => {
                try {
                  await deleteDocTriggerCondition(docTrigger.id);
                  onDelete();
                } catch {
                  noop();
                }
              },
            );
          }
        }}
        horizontalOrigin="right"
        popoverClassName="w-[12rem]"
      >
        <ThreeVerticalDotsIcon className="shrink-0 w-5 h-5 text-gray-400" />
      </ButtonPopover>
    </div>
  );
}

DocTriggerConditionRow.defaultProps = {
  className: '',
};

export default DocTriggerConditionRow;
