import React, { useEffect } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import useUserStore from 'stores/user';
import useDocumentStore from 'stores/document';
import useNavMenuStore from 'stores/nav-menu';

import { EntityType } from 'types/entity';
import { findActiveEntityHierarchy, findParentEntityIds } from 'utils/document-metadata';
import updateUserHistory from '../api/user/update-user-history';

interface Props {
  type: EntityType;
  children: JSX.Element;
}

function RecentPage({ type, children }: Props) {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const entityId = searchParams.get('entity-document-id');
  const { documentId, crmNoteId } = useParams();

  const userStore = useUserStore();
  const documentStore = useDocumentStore();
  const navMenuStore = useNavMenuStore();

  const { user } = userStore;
  const userId = user?.id;
  const { isLoading } = documentStore;

  let docParent;
  if (type === 'document' && documentId) {
    const document = documentStore.documents.find(doc => doc.id === documentId);
    if (document && document.event) {
      docParent = document.parent;
    }
  }

  useEffect(() => {
    const update = async (docType: EntityType, docId: string, noteId: string | null) => {
      const { effectedDocuments, recentlyViewedDocs } = await updateUserHistory(docType, docId, noteId);
      // documentStore.updateEntityDocumentAccessedAt(effectedDocuments);
      userStore.updateRecentlyViewedDocs(recentlyViewedDocs);
    };

    const process = async () => {
      if (userId && !isLoading) {
        let docId = documentId || null;
        const noteId = crmNoteId || null;
        if (type === 'crm-note' && noteId && entityId) {
          if (entityId) {
            // the store should have the data
            const res = documentStore.entityDocuments.find(eDoc => eDoc.id === entityId);
            if (res) {
              docId = res.id;
            }
          }
        }
        if (docId) {
          update(type, docId, noteId);
        }
      }
    };
    process();
  }, [documentId, crmNoteId, entityId, type, userId, isLoading, docParent]);

  useEffect(() => {
    /* Calculate if the nav menu should be open */
    if (documentStore.isLoading || documentStore.isLoading) {
      return;
    }

    const openEntities = findParentEntityIds(
      documentId,
      crmNoteId,
      documentStore.entityDocuments,
      documentStore.documents,
      documentStore.crmNotes,
    );
    navMenuStore.addEntityOpen(openEntities);
  }, [documentId, crmNoteId, documentStore.isLoading, documentStore.isLoading, docParent]);

  useEffect(() => {
    const activeEntityHierarchy = findActiveEntityHierarchy(
      documentId,
      crmNoteId,
      type,
      documentStore.entityDocuments,
      documentStore.documents,
      documentStore.crmNotes,
    );
    navMenuStore.setState({ activeEntityHierarchy });
  }, [location.pathname, documentId, crmNoteId, documentStore.isLoading, documentStore.isLoading, docParent]);

  // if (isLoading) {
  //   return <div>Loading</div>;
  // }

  return children;
}

export default RecentPage;
