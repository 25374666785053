import React, { useEffect, useState } from 'react';
import { LockClosedIcon } from '@heroicons/react/24/outline';
import { useNavigate, useParams } from 'react-router-dom';

import { EntityDocumentRich as ApiResponse } from 'api/entities/get-entity';
import errors from 'utils/errors';
import useSuggestedAnswer from 'hooks/useSuggestedAnswer';

import Loader from 'components/Loader';
import useDocumentStore from 'stores/document';
import useRecording from 'hooks/useRecording';
import AccountView from './AccountPage/AccountView';
import useContextPanelStore from '../stores/context-panel';
import RightDrawerPanel from 'components/RightDrawerPanel';

interface State {
  isLoading: boolean;
  isAuthorized: boolean;
  apiResponse: null | ApiResponse;
}

function AccountPage() {
  const [state, setState] = useState<State>({
    isLoading: true,
    isAuthorized: true,
    apiResponse: null,
  });
  useSuggestedAnswer();

  const { documentId } = useParams();
  useRecording(documentId);
  const navigate = useNavigate();
  const documentStore = useDocumentStore();
  const contextPanelStore = useContextPanelStore();

  useEffect(() => {
    const fetch = async (id: string) => {
      setState(prevState => ({ ...prevState, isLoading: true, isAuthorized: true, apiResponse: null }));
      try {
        const apiResponse = await documentStore.fetchEntityByDocId(id);
        setState(prevState => ({ ...prevState, apiResponse }));
      } catch (e) {
        if (e instanceof errors.PermissionError) {
          setState(prevState => ({ ...prevState, isAuthorized: false }));
        }
      }

      setState(prevState => ({ ...prevState, isLoading: false }));
    };

    if (documentId) {
      documentStore.setState({ currentAcctDocId: documentId });
      fetch(documentId);
    }
    return () => {
      contextPanelStore.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId]);

  if (documentId === undefined) {
    navigate('/');
    return null;
  }

  if (state.isLoading) {
    return (
      <div className="h-full w-full flex items-center justify-center">
        <Loader />
      </div>
    );
  }

  if (!state.isAuthorized) {
    return (
      <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 text-gray-400">
        <div className="flex flex-col items-center justify-center">
          <div>
            <LockClosedIcon className="w-32 h-32" />
          </div>
          <div className="text-sm">You do not have the required access to view this opportunity.</div>
        </div>
      </div>
    );
  }

  if (!state.apiResponse) {
    return <div>Unknown Account. Please re-direct to correct document page.</div>;
  }
  return (
    <>
      <AccountView apiResponse={state.apiResponse} />
      <RightDrawerPanel />
    </>
  );
}

export default AccountPage;
