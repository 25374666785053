import React, { useEffect, useState } from 'react';
import { CalendarIcon, LockClosedIcon } from '@heroicons/react/24/outline';
import moment from 'moment';

import AccountIcon from 'components/icons/AccountIcon';
import NoteIcon from 'components/icons/NoteIcon';
import OpportunityIcon from 'components/icons/OpportunityIcon';
import SalesforceNoteIcon from 'components/icons/SalesforceNoteIcon';
import SalesforceLinkIcon from 'components/icons/SalesforceLinkIcon';

import useDocumentStore from 'stores/document';

import { EntityDocument, EntityType } from 'types/entity';

import constants from 'utils/constants';
import EditableTitle from './EditableTitle';

interface Props {
  documentId: string;
  crmNoteId: string | null;
  type: EntityType;
  sfHyperLink?: boolean;
}

interface State {
  data: EntityDocument | null;
}

function Title({ documentId, crmNoteId, type, sfHyperLink }: Props) {
  const documentStore = useDocumentStore();
  const [state, setState] = useState<State>({
    data: null,
  });

  useEffect(() => {
    const fetch = async () => {
      if (['account', 'opportunity'].includes(type)) {
        const res = await documentStore.getOrFetchEntityByDocId(documentId);
        if (res) {
          setState(prevState => ({ ...prevState, data: res }));
        }
      }
    };
    fetch();
  }, []);

  let title = <span>Unknown Title</span>;
  let icon = <OpportunityIcon className="w-6 h-6 fill-orange-400 text-orange-400" />;
  let link;

  if (type === 'account') {
    if (state.data) {
      title = <span>{state.data.name}</span>;
      if (state.data.crm) {
        link = `${documentStore.instanceUrl}/${state.data.crm.id}`;
      }
    }
    icon = <AccountIcon className="w-5 h-5" />;
  } else if (type === 'opportunity') {
    if (state.data) {
      title = <span>{state.data.name}</span>;
      if (state.data.crm) {
        link = `${documentStore.instanceUrl}/${state.data.crm.id}`;
      }
    }
    icon = <OpportunityIcon className="w-5 h-5 fill-orange-400 text-orange-400" />;
  } else if (type === 'document') {
    const document = documentStore.documents.find(d => d.id === documentId);
    if (document) {
      icon = <NoteIcon className="w-5 h-5 text-gray-400 flex-shrink-0" />;
      title = (
        <div className="flex items-center -ml-2">
          <EditableTitle documentId={document.id} documentTitle={document.docTitle} />
          {!document.isPublic && (
            <span className="flex items-center gap-2 border rounded-full text-sm px-2 text-gray-400">
              <LockClosedIcon className="w-4 h-4" />
              Private
            </span>
          )}
        </div>
      );

      if (document.event) {
        icon = <CalendarIcon className="w-5 h-5 text-gray-400 flex-shrink-0" />;
        title = (
          <div className="flex items-center">
            <span className="truncate">{document.event.subject}</span>
            <span className="text-base text-gray-500 px-2">
              ({moment(document.event.startsAt).format(constants.DATETIME_FORMAT)})
            </span>
            {!document.isPublic && (
              <span className="flex items-center gap-2 border rounded-full text-sm px-2 text-gray-400">
                <LockClosedIcon className="w-4 h-4" />
                Private
              </span>
            )}
          </div>
        );
      }

      if (document.parent && document.parent.crmId) {
        link = `${documentStore.instanceUrl}/${document.parent.crmId}`;
      }
    }
  } else if (type === 'crm-note' && crmNoteId) {
    const crmNote = documentStore.crmNotes.find(n => n.id === crmNoteId);
    icon = <SalesforceNoteIcon className="w-5 h-5" />;
    if (crmNote) {
      title = <span>{crmNote.title}</span>;
      link = `${documentStore.instanceUrl}/${crmNote.linkedEntity.id}`;
    }
  }
  return (
    <div className="flex items-center">
      <div className="w-5 h-5 mr-2">{icon}</div>
      <span className="font-medium">{title}</span>
      {link && sfHyperLink && (
        <a id="crm-link-from-entity-title" className="w-5 h-5 ml-2" href={link} target="_blank" rel="noreferrer">
          <SalesforceLinkIcon className="w-5 h-5" />
        </a>
      )}
    </div>
  );
}

Title.defaultProps = {
  sfHyperLink: true,
};

export default Title;
