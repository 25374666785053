import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { openWarningConfirmModal } from '../../components/BaseModal';
import SpinLoader from '../../components/icons/SpinLoader';
import { Field } from 'types/field-value';
import { SalesforceFieldType } from '../../types/salesforce';
import CheckIcon from '../../components/icons/CheckIcon';
import ButtonPopover from '../../components/ButtonPopover';
import { BaseButtonColor, BaseButtonVariant } from '../../components/BaseButton';
import updateField from '../../api/field/update-field';
import useFieldValuesStore from '../../stores/field-values';

interface Props {
  btnVariant?: BaseButtonVariant;
  btnColor?: BaseButtonColor;
  btnClassName?: string;
  documentId: string;
  selectedId: string | null;
  fields: Field[];
  children: React.ReactNode;
}

interface State {
  isLoading: boolean;
  isPopoverOpen: boolean;
  selectedId: string | null;
}

function ConfigureFieldButtonPopover({
  btnVariant,
  btnColor,
  btnClassName,
  documentId,
  selectedId,
  fields,
  children,
}: Props) {
  const fieldValueStore = useFieldValuesStore();

  const [state, setState] = useState<State>({
    isLoading: false,
    isPopoverOpen: false,
    selectedId,
  });

  const selectionFields = fields.filter(f =>
    [SalesforceFieldType.REFERENCE, SalesforceFieldType.PICKLIST, SalesforceFieldType.MULTI_PICKLIST].includes(
      f.dataType,
    ),
  );

  if (selectionFields.length === 0) {
    return <div>No CRM fields has been found</div>;
  }

  const onSelectField = async (field: Field) => {
    setState(prev => ({ ...prev, isLoading: true, selectedId: field.id }));
    await updateField({ ...field, isSalesEngineerField: true });
    await fieldValueStore.fetch(documentId);
    setState(prev => ({ ...prev, isLoading: false, isPopoverOpen: false }));
  };

  return (
    <ButtonPopover
      btnVariant={btnVariant}
      btnColor={btnColor}
      btnClassName={btnClassName}
      isPopoverOpen={state.isPopoverOpen}
      popoverToggle={show => setState(prev => ({ ...prev, isPopoverOpen: show }))}
      popoverContent={
        <div className="py-1 text-gray-600">
          {selectionFields.map(field => {
            const isFieldInitialized = field.initializationJobStatus === 'complete';
            return (
              <MenuItem
                key={field.id}
                className="w-full"
                disabled={state.isLoading || state.selectedId === field.id || !isFieldInitialized}
                onClick={async () => {
                  const confirmed = await openWarningConfirmModal(
                    'Are you sure you want to configure this field?',
                    <>
                      <p>
                        You are about to link the <span className="font-semibold">{field.label}</span> field as the
                        Sales Engineer field.
                      </p>
                      <div className="py-2" />
                      <p>
                        This step will enable Superpanel to calculate the number of deals the users are involved with.
                      </p>
                      <div className="py-2" />
                      {selectedId ? (
                        <p>Please note, this action will remove previously linked users. Would you like to continue?</p>
                      ) : (
                        <p>Would you like to continue?</p>
                      )}
                    </>,
                  );
                  if (confirmed) {
                    await onSelectField(field);
                  }
                }}
              >
                {isFieldInitialized ? field.label : `Please wait, ${field.label} is being initialized`}
                {((field.id === state.selectedId && state.isLoading) || !isFieldInitialized) && (
                  <SpinLoader className="ml-2 w-4 h-4 animate-spin" />
                )}
                {field.id === state.selectedId && !state.isLoading && <CheckIcon className="ml-2 w-6 h-6 shrink-0" />}
              </MenuItem>
            );
          })}
        </div>
      }
    >
      {children}
    </ButtonPopover>
  );
}

ConfigureFieldButtonPopover.defaultProps = {
  btnVariant: 'text',
  btnColor: 'primary',
  btnClassName: '',
};

export default ConfigureFieldButtonPopover;
