import React from 'react';
import { CrmFieldType } from '../../../types/crm';
import CalendarIcon from '../../icons/CalendarIcon';
import StageIcon from '../../icons/StageIcon';
import DollarIcon from '../../icons/DollarIcon';
import UserIcon from '../../icons/UserIcon';
import TypeIcon from '../../icons/TypeIcon';

interface Props {
  className?: string;
  fieldType: CrmFieldType;
}

function FieldTypeIcon({ className, fieldType }: Props) {
  switch (fieldType) {
    case 'date':
    case 'datetime':
      return <CalendarIcon className={className} />;
    case 'multipicklist':
    case 'picklist':
      return <StageIcon className={className} />;
    case 'currency':
    case 'double':
    case 'int':
    case 'percent':
      return <DollarIcon className={className} />;
    case 'reference':
      return <UserIcon className={className} />;
    default:
      return <TypeIcon className={className} />;
  }
}

FieldTypeIcon.defaultProps = {
  className: undefined,
};

export default FieldTypeIcon;
