import { State as PopupMessageStoreState } from 'stores/popup-message';
import { State as UserStoreState } from 'stores/user';
import errors, { POPUP_MSG_IGNORE_ERRORS, SuperpanelAPIError } from '../utils/errors';
import httpClient from './http-client';

export const redirectTo = (to: 'register' | 'login', state: 'UserNotExistError' | 'UserExistError' | null) => {
  if (state) {
    window.location.href = `/${to}?state=${state}`;
  } else {
    window.location.href = `/${to}`;
  }
};

export const reload = () => {
  window.location.reload();
};

export const errorHandler = (popupMessageStore: PopupMessageStoreState, userStore: UserStoreState) => {
  httpClient.interceptors.response.use(
    response => {
      // response can be undefined
      if (response && response.status >= 300) {
        const { generalErrorCode, additionalInfos } = response.data;
        let error = new SuperpanelAPIError();
        if (generalErrorCode === errors.AuthenticationError.code) {
          redirectTo('login', null);
          userStore.setState({ user: null });
          error = new errors.AuthenticationError();
        }
        if (generalErrorCode === errors.UserNotExistError.code) {
          redirectTo('login', 'UserNotExistError');
          userStore.setState({ user: null });
          error = new errors.AuthenticationError();
        }
        if (generalErrorCode === errors.UserExistError.code) {
          redirectTo('register', 'UserExistError');
          userStore.setState({ user: null });
          error = new errors.AuthenticationError();
        }
        if (generalErrorCode === errors.IntegrationError.code) {
          const { integrationName } = additionalInfos;
          error = new errors.IntegrationError(integrationName);
        }
        if (generalErrorCode === errors.IntegrationValidationError.code) {
          const { integrationName, errorMessage } = additionalInfos;
          error = new errors.IntegrationValidationError(integrationName, errorMessage);
        }
        if (generalErrorCode === errors.SerializationError.code) {
          error = new errors.SerializationError();
        }
        if (generalErrorCode === errors.OauthAppAlreadyInstalledError.code) {
          error = new errors.OauthAppAlreadyInstalledError();
        }
        if (generalErrorCode === errors.DatabaseError.code) {
          error = new errors.DatabaseError();
        }
        if (generalErrorCode === errors.UnlinkDocumentWithFieldError.code) {
          error = new errors.UnlinkDocumentWithFieldError();
        }
        if (generalErrorCode === errors.UpgradeDocParentEntityError.code) {
          error = new errors.UpgradeDocParentEntityError();
        }
        if (generalErrorCode === errors.ApplyTemplateWithIncompatibleFieldsError.code) {
          error = new errors.ApplyTemplateWithIncompatibleFieldsError();
        }
        if (generalErrorCode === errors.GeneralError.code) {
          const { message } = additionalInfos;
          error = new errors.GeneralError(message);
        }
        if (generalErrorCode === errors.PermissionError.code) {
          error = new errors.PermissionError();
        }
        if (generalErrorCode === errors.ApiMismatch.code) {
          error = new errors.ApiMismatch();
        }
        if (generalErrorCode === errors.SalesforceFieldDefinitionTablePermissionError.code) {
          error = new errors.SalesforceFieldDefinitionTablePermissionError();
        }
        if (generalErrorCode === errors.IntegrationInsufficientPermissionToUpdateField.code) {
          error = new errors.IntegrationInsufficientPermissionToUpdateField();
        }
        if (generalErrorCode === errors.RegisterCrossDomainError.code) {
          error = new errors.RegisterCrossDomainError();
        }
        if (generalErrorCode === errors.RegisterCRMOnlyError.code) {
          error = new errors.RegisterCRMOnlyError();
        }
        if (generalErrorCode === errors.InvitationExpiredError.code) {
          error = new errors.InvitationExpiredError();
        }
        if (!POPUP_MSG_IGNORE_ERRORS.includes(error.code)) {
          popupMessageStore.throwError(error);
        }
        return Promise.reject(error);
      }
      return response;
    },
    error => {
      if (error instanceof SuperpanelAPIError) {
        throw error;
      }
      if (error.code === 'ECONNABORTED') {
        popupMessageStore.throwError(new errors.ApiTimeoutError());
        return;
      }
      popupMessageStore.throwError(new errors.UnexpectedError());
    },
  );
};

export default {
  redirectTo,
  errorHandler,
};
