import Loader from 'components/Loader';
import React from 'react';
import { concat } from 'utils/styling';
import { noop } from 'lodash';
import styles from './index.module.css';
import SpinLoader from 'components/icons/SpinLoader';

interface Props {
  className?: string;
  loading?: boolean;
  error?: string;
  noData?: boolean;
  noDataIcon?: React.ReactNode;
  noDataMsg?: string | React.ReactNode;
  noDataDesc?: string | React.ReactNode;
  noDataBtnLabel?: string;
  onButtonClick?: () => void;
  children?: React.ReactNode;
  loadingExtra?: React.ReactNode;
  smallLoader?: boolean;
}
function ResponseView({
  className,
  loading,
  error,
  noData,
  noDataIcon,
  noDataMsg,
  noDataDesc,
  noDataBtnLabel,
  onButtonClick,
  children,
  loadingExtra,
  smallLoader,
}: Props) {
  if (loading) {
    return (
      <div className={styles['loader-wrap']}>
        {smallLoader ? <SpinLoader className="animate-spin w-6 h-6 text-orange-500" /> : <Loader />}
        {loadingExtra}
      </div>
    );
  }
  if (error) {
    return <div className={concat(styles['error-wrap'], className)}>{error}</div>;
  }
  if (noData) {
    return (
      <div className={concat(styles['no-data-wrap'], className)}>
        <div className="my-4">{noDataIcon}</div>
        {!!noDataMsg && (
          <h4 role="alert" className="font-medium">
            {noDataMsg}
          </h4>
        )}
        {!!noDataDesc && <span className="text-sm text-gray-500">{noDataDesc}</span>}
        {!!noDataBtnLabel && (
          <button
            type="button"
            className="bg-blue-600 hover:bg-blue-500 text-white rounded px-4 py-2 mt-2"
            onClick={onButtonClick}
          >
            {noDataBtnLabel}
          </button>
        )}
      </div>
    );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

ResponseView.defaultProps = {
  className: '',
  loading: false,
  error: '',
  noData: false,
  noDataIcon: undefined,
  noDataMsg: '',
  noDataDesc: '',
  onButtonClick: noop,
  noDataBtnLabel: '',
  children: undefined,
  loadingExtra: undefined,
  smallLoader: false,
};

export default ResponseView;
