import React from 'react';
import useFieldValuesStore from '../../stores/field-values';
import { SalesforceFieldType } from '../../types/salesforce';
import { PicklistFieldList } from '../../components/FieldValueView/AnswerView/PicklistField';
import { ReferenceFieldList } from '../../components/FieldValueView/AnswerView/ReferenceField';
import { CrmReferenceValue } from '../../types/crm';

interface Props {
  value: string;
  includedValues: string[];
  onSelect: (value: string) => void;
}

function LinkUserPopoverContent({ value, includedValues, onSelect }: Props) {
  const fieldValueStore = useFieldValuesStore();
  const fieldValue = fieldValueStore.fieldValues.find(fv => fv.field.isSalesEngineerField);

  if (!fieldValue) {
    return <div>Sales Engineer Field is unknown</div>;
  }

  switch (fieldValue.field.dataType) {
    case SalesforceFieldType.PICKLIST:
    case SalesforceFieldType.MULTI_PICKLIST: {
      const picklistValues = fieldValue.field.crmField ? fieldValue.field.crmField.picklistValues : [];
      return (
        <PicklistFieldList
          selectedValue={value}
          disableValues={includedValues}
          width={200}
          picklistValues={picklistValues}
          onChange={onSelect}
        />
      );
    }
    case SalesforceFieldType.REFERENCE: {
      const { referenceValue } = fieldValue.value;
      return (
        <ReferenceFieldList
          selectedId={value}
          disableIds={includedValues}
          object={(referenceValue as CrmReferenceValue).object}
          onChange={onSelect}
        />
      );
    }
    default:
      return <div>Unknown Field Type</div>;
  }
}

export default LinkUserPopoverContent;
