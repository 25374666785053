import React from 'react';
import BaseButton from 'components/BaseButton';
import { concat } from 'utils/styling';
import { BlockType } from 'components/NoteEditor/types';
import styles from './Menu.module.css';

export interface Props {
  text: string;
  description: string;
  imageUrl: string;
  selected: boolean;
  onMouseHover: () => void;
  onClick: () => void;
  blockType: BlockType;
}

function Menu({ text, description, imageUrl, selected, onMouseHover, onClick, blockType }: Props) {
  return (
    <BaseButton
      id={text}
      variant="text"
      color="secondary"
      className={concat(styles.menu, selected && styles.selected)}
      data-menu={blockType}
      onMouseOver={onMouseHover}
      onClick={onClick}
    >
      <img alt={text} className={styles.img} src={imageUrl} />
      <div className={styles['text-wrap']}>
        <span className={styles.text}>{text}</span>
        <span className={styles.description}>{description}</span>
      </div>
    </BaseButton>
  );
}

export default Menu;
