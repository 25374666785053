/* eslint-disable import/no-cycle, react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import { Range, Text, Transforms } from 'slate';
import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/outline';
import NoteEditor from 'components/NoteEditor/NoteEditor';
import useUserStore from 'stores/user';
import styles from './CommentToolbarButton.module.css';
import { CustomEditor } from 'components/NoteEditor/types';

interface Props {
  editor: CustomEditor;
  onClick?: () => void;
}

function CommentToolbarButton({ editor, onClick }: Props) {
  const userStore = useUserStore();

  const addComment = useCallback(() => {
    const { selection } = editor;
    const userId = userStore.user?.id;
    if (selection && Range.isExpanded(selection) && userId) {
      // split nodes which will asign comment
      Transforms.setNodes(editor, { commentInit: true }, { match: Text.isText, split: true });
      setTimeout(() => {
        NoteEditor.addNewCommentForCommentInitElements(editor, userId);
      });
    }
  }, []);

  return (
    <button
      type="button"
      className={styles.button}
      onMouseDown={e => {
        e.preventDefault();
        if (onClick) {
          onClick();
        } else {
          addComment();
        }
      }}
    >
      <ChatBubbleBottomCenterTextIcon style={{ height: '1rem', width: '1rem' }} />
    </button>
  );
}

CommentToolbarButton.defaultProps = {
  onClick: undefined,
};

export default CommentToolbarButton;
