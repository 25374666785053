import { ShareIcon } from '@heroicons/react/24/outline';
import React, { ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import SalesforceIcon from 'components/icons/SalesforceIcon';
import ContextPanelView from 'components/ContextPanelView';
import EntityHeader from 'components/EntityHeader';
import SpinLoader from 'components/icons/SpinLoader';
import useModalStore from 'stores/modal';
import { PanelContext } from 'stores/context-panel';
import useDocumentStore from 'stores/document';
import useNoteEditorStore from 'stores/note-editor';
import { Document, NoteService } from 'types/document';
import { NoteUser } from 'note-service/note-service';
import useUserStore from '../../stores/user';
import styles from './DocumentView.module.css';
import NoteUsers from './DocumentView/NoteUsers';
import ShareModal from './DocumentView/ShareModal';
import SyncWarningModal from './DocumentView/SyncWarningModal';
import { concat } from 'utils/styling';
import DocumentEditor from './DocumentEditor';
import DocumentMoreActionBtn from './DocumentView/DocumentMoreActionBtn';
import { NoteEditorRef } from 'components/NoteEditor';
import CreateTemplateModal from './DocumentView/CreateTemplateModal';
import useTemplateStore from 'stores/templates';
import usePopupMessageStore from 'stores/popup-message';
import { isEqual, cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { BlockType } from 'components/NoteEditor/types';
import { SuperpanelAPIError } from 'utils/errors';

interface Props {
  document: Document;
  noteService: NoteService;
  setLoading: (isLoading: boolean) => void;
  onUpdatedDocFolderByApi?: () => void;
}

interface State {
  isCrmSyncLoading: boolean;
  isMenuOpen: boolean;
  selectedOpportunityId: string;
  title: string;
  isApplyTemplateLoading: boolean;
}

function DocumentView({ document, noteService, setLoading, onUpdatedDocFolderByApi }: Props) {
  const [state, setState] = useState<State>({
    isCrmSyncLoading: false,
    isMenuOpen: false,
    selectedOpportunityId:
      (document.parent && document.parent.entityName === 'Opportunity' && document.parent.id) || '',
    title: document.docTitle,
    isApplyTemplateLoading: false,
  });

  const [noteUsers, setNoteUsers] = useState<NoteUser[]>([]);

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const modalStore = useModalStore();
  const documentStore = useDocumentStore();
  const noteEditorStore = useNoteEditorStore();
  const userStore = useUserStore();
  const editorRef = useRef<NoteEditorRef>(null);
  const templateStore = useTemplateStore();
  const popupMessageStore = usePopupMessageStore();

  const renderSyncText = () => {
    if (state.isCrmSyncLoading) {
      return 'Syncing';
    }
    if (localStorage.getItem(document.id) === noteEditorStore.contentHash) {
      return 'Synced';
    }
    return 'Sync';
  };

  let crmSyncElement: null | ReactNode = null;
  if (userStore.user?.isIntegratedWithCrm && document.parent && document.parent) {
    const syncWithCrm = async () => {
      setState(oldState => ({ ...oldState, isCrmSyncLoading: true }));
      try {
        await documentStore.syncDocumentToCrm(document.id, noteEditorStore.contentHash);
      } catch (e) {
        /* empty */
      }

      setState(oldState => ({ ...oldState, isCrmSyncLoading: false }));
    };
    crmSyncElement = (
      <button
        id="crm-sync-button"
        className="flex items-center gap-2 bg-gray-100 hover:bg-gray-200 rounded px-2 py-1 text-sm"
        disabled={state.isCrmSyncLoading}
        onClick={() => {
          if (!document.isPublic) {
            modalStore.setState({
              isOpen: true,
              element: <SyncWarningModal onOkay={syncWithCrm} />,
            });
          } else {
            syncWithCrm();
          }
        }}
        type="button"
      >
        <SalesforceIcon className="h-4 w-4" />
        <span className={`${localStorage.getItem(document.id) === noteEditorStore.contentHash && 'text-gray-500'}`}>
          {renderSyncText()}
        </span>
      </button>
    );
  }

  const openShareModal = () => {
    modalStore.setState({
      element: <ShareModal entityType="document" documentId={document.id} />,
      isOpen: true,
    });
  };

  const panelContexts = useMemo<PanelContext[]>(() => {
    let listItem: PanelContext[] = [];
    if (document.parent) {
      listItem = ['recordings', 'chat', 'meetings'];
    } else if (document.event) {
      listItem = ['recordings'];
    }
    return listItem;
  }, [document]);

  const handleNoteUserUpdate = useCallback((users: NoteUser[]) => {
    setNoteUsers(prev => (isEqual(prev, users) ? prev : users));
  }, []);

  const onSaveAsTemplate = useCallback(() => {
    const createNewTemplate = async (name: string, icon: null | string): Promise<void> => {
      const editor = editorRef.current?.getEditor();
      if (editor) {
        try {
          const editorContent = cloneDeep(editor.children)
            // remove product gap
            .filter(element => element.type !== BlockType.ProductGap)
            .map(element => ({
              ...element,
              uuid: uuidv4(), // assign new uuid to each node
            }));
          await templateStore.create(name, { children: editorContent }, icon, 'document', false);
          modalStore.close();
          popupMessageStore.success(
            `Template created successfully! ${
              editorContent.length !== editor.children.length
                ? 'We have removed the product gap fields when saving as template'
                : ''
            }`,
          );
        } catch (e) {
          const err = e as SuperpanelAPIError;
          popupMessageStore.error(`Failed to create template: ${err.message || err.displayErrorMessage}`);
        }
      }
    };

    modalStore.setState({
      element: <CreateTemplateModal onClose={modalStore.close} onSubmit={createNewTemplate} />,
      isOpen: true,
    });
  }, []);

  return (
    <div className={styles.layout}>
      <EntityHeader
        entityType="document"
        documentId={document.id}
        crmNoteId={null}
        panelContexts={panelContexts}
        menuItems={
          <>
            <NoteUsers noteUsers={noteUsers} />
            {crmSyncElement}
            <button
              id="share-button"
              className="px-2 py-1 text-sm rounded bg-gray-100 hover:bg-gray-200 flex items-center gap-2"
              onClick={() => openShareModal()}
              type="button"
            >
              <ShareIcon className={concat('h-4 w-4 text-gray-500')} />
              <span>Share</span>
            </button>
            <DocumentMoreActionBtn document={document} showDelete onSaveAsTemplate={onSaveAsTemplate} />
          </>
        }
      />
      <ContextPanelView viewKey="doc-page-context-panel" scrollable={false}>
        <div className={styles['parent-container']} id="note-editor-parent-container" ref={scrollContainerRef}>
          <div className={concat(styles.container, 'context-panel-left-content')}>
            {state.isApplyTemplateLoading && (
              <div className="flex justify-center items-center h-full">
                <SpinLoader className="animate-spin w-6 h-6 text-orange-500" />
              </div>
            )}
            {!state.isApplyTemplateLoading && (
              <div className={styles['note-container']}>
                <DocumentEditor
                  ref={editorRef}
                  document={document}
                  noteService={noteService}
                  setLoading={setLoading}
                  onUpdatedDocFolderByApi={onUpdatedDocFolderByApi}
                  showDocumentHeader
                  onNoteUserUpdate={handleNoteUserUpdate}
                  autoFocusWhenEmpty
                  scrollContainerRef={scrollContainerRef}
                  bottomMarginSectionClassName={styles.bottomMargin}
                  isMeetingEditor={false}
                />
              </div>
            )}
          </div>
        </div>
      </ContextPanelView>
    </div>
  );
}

DocumentView.defaultProps = {
  onUpdatedDocFolderByApi: undefined,
};

export default DocumentView;
