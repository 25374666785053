import { ColumnConfig } from 'components/Table/types';

export type PopoverPage = 'setting' | 'filter' | 'sort';

export const defultMyTesk: ColumnConfig = {
  id: 'myTasks',
  name: 'My Related Tasks',
  width: 0,
  type: 'Boolean',
  isHidden: false,
  filter: {
    type: 'Is',
    value: 'True',
  },
};

export const defaultTaskFields: ColumnConfig[] = [
  defultMyTesk,
  {
    id: 'assignedToUserId',
    name: 'Assigned To',
    width: 0,
    type: 'Text',
    isHidden: false,
  },
  {
    id: 'done',
    name: 'Done',
    width: 0,
    type: 'Boolean',
    isHidden: false,
  },
  {
    id: 'dueDate',
    name: 'Due Date',
    width: 0,
    type: 'Date',
    isHidden: false,
  },
  {
    id: 'content',
    name: 'Content',
    width: 0,
    type: 'Text',
    isHidden: false,
  },
  {
    id: 'account',
    group: 'Account',
    columns: [
      {
        id: 'name',
        name: 'Name',
        width: 0,
        type: 'Text',
        isHidden: false,
      },
    ],
  },
  {
    id: 'opportunity',
    group: 'Opportunity',
    columns: [
      {
        id: 'name',
        name: 'Name',
        width: 0,
        type: 'Text',
        isHidden: false,
      },
    ],
  },
];
