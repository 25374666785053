/* eslint-disable no-nested-ternary, react/no-array-index-key */
import { ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material';
import React, { useMemo } from 'react';
import { ListItem } from 'types/list-item';
import { concat } from 'utils/styling';
import Popover from './Popover';
import styles from './PopoverMenuList.module.css';
import SpinLoader from './icons/SpinLoader';
import getPopoverInfo from 'utils/popover';

interface Props<T> {
  isOpen: boolean;
  onClose: () => void;
  anchorEl: Element | null;
  disableAutoFocus?: boolean;
  disableRestoreFocus?: boolean;
  menus?: ListItem<T>[];
  menusLoading?: boolean;
  popoverClassName?: string;
  noMenusLabel?: string;
  handleMenuClick?: (menu: ListItem<T>) => void;
  maxHeight?: number;
  width?: number;
  filter?: string;
  horizontalOrigin?: 'left' | 'right';
  extraElement?: React.ReactNode;
  stopPropagationWhenClick?: boolean;
}

function PopoverMenuList<T>({
  isOpen,
  onClose,
  anchorEl,
  disableAutoFocus,
  disableRestoreFocus,
  menus,
  menusLoading,
  popoverClassName,
  noMenusLabel,
  handleMenuClick,
  maxHeight,
  width,
  filter,
  horizontalOrigin,
  extraElement,
  stopPropagationWhenClick,
}: Props<T>) {
  const popoverInfo = useMemo(() => {
    if (isOpen && anchorEl) {
      return getPopoverInfo(anchorEl, horizontalOrigin, maxHeight, width);
    }
    return undefined;
  }, [anchorEl, isOpen, maxHeight, width]);

  const menusFiltered = useMemo(
    () =>
      filter?.trim() ? menus?.filter(menu => menu.label.toLowerCase().includes(filter.trim().toLowerCase())) : menus,
    [menus, filter],
  );

  return (
    <Popover
      isOpen={isOpen}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorReference="anchorEl"
      anchorOrigin={popoverInfo?.anchorOrigin}
      transformOrigin={popoverInfo?.transformOrigin}
      disableAutoFocus={disableAutoFocus}
      disableRestoreFocus={disableRestoreFocus}
      stopPropagationWhenClick={stopPropagationWhenClick}
    >
      <div className={concat(styles.menuContent, popoverClassName)} style={popoverInfo?.style}>
        {menusLoading ? (
          <SpinLoader className="animate-spin w-5 h-5 text-orange-500" />
        ) : menusFiltered?.length === 0 ? (
          <span className="py-2">{!menus?.length ? noMenusLabel : !menusFiltered?.length ? 'No Results' : ''}</span>
        ) : (
          <MenuList className={styles.menuList} autoFocusItem>
            {menusFiltered?.map((menu: ListItem<T>, index: number) => (
              <MenuItem
                key={index}
                onClick={() => {
                  handleMenuClick?.(menu);
                  onClose();
                }}
              >
                {!!menu.icon && <ListItemIcon>{menu.icon}</ListItemIcon>}
                <ListItemText>{menu.label}</ListItemText>
              </MenuItem>
            ))}
          </MenuList>
        )}
        {extraElement}
      </div>
    </Popover>
  );
}

PopoverMenuList.defaultProps = {
  disableAutoFocus: undefined,
  disableRestoreFocus: undefined,
  menus: undefined,
  menusLoading: false,
  noMenusLabel: 'List not found',
  handleMenuClick: undefined,
  maxHeight: undefined,
  width: undefined,
  filter: '',
  popoverClassName: '',
  horizontalOrigin: 'left',
  extraElement: undefined,
  stopPropagationWhenClick: undefined,
};

export default PopoverMenuList;
