import { EntityName } from 'types/entity';
import { Field as ApiField } from 'types/field-value';
import { SalesforceFieldType } from '../../types/salesforce';

export type SyncDirection = 'PUSH_TO_CRM' | 'PULL_FROM_CRM' | 'CONCAT';

export type Field = Omit<ApiField, 'dataType' | 'dataLength' | 'initializationJobStatus'> & {
  syncDirection?: SyncDirection;
  isNew?: boolean;
};

export const getEmptyField = (entityName: EntityName, order: number): Field => {
  return {
    id: '',
    label: '',
    order,
    crmField: {
      id: '',
      label: '',
      name: '',
      type: SalesforceFieldType.TEXTAREA,
      isDefault: false,
      durableId: '',
      picklistValues: [],
    },
    isDefault: false,
    isAiEnabled: false,
    isUsedInMenuFilter: false,
    isUsedInTeamMemberRequest: false,
    isSalesEngineerField: false,
    description: '',
    tags: [],
    entity: entityName,
    parentId: null,
    createdBy: '',
    autoAcceptAI: false,
  };
};
