import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useTeamMemberRequestStore from '../stores/team-member-request';
import moment from 'moment';
import constants from '../utils/constants';
import OpportunityIcon from '../components/icons/OpportunityIcon';
import StatusPill from '../components/TeamMemberRequest/StatusPill';
import EditIcon from '../components/icons/EditIcon';
import { currencyFormatter } from '../utils/number-formatter';
import Loader from '../components/Loader';
import ResponseView from 'components/ResponseView';
import EmptySearchIcon from '../components/icons/EmptySearchIcon';

type Tab = 'Pending Request' | 'Assigned Requests';

function TeamMemberRequestsPage() {
  const [tab, setTab] = useState<Tab>('Pending Request');
  const navigate = useNavigate();
  const teamMemberRequestStore = useTeamMemberRequestStore();

  useEffect(() => {
    teamMemberRequestStore.fetch();
  }, []);

  const switchTab = (t: Tab) => {
    setTab(t);
  };

  const requests = teamMemberRequestStore.teamMemberRequests.filter(request => {
    return tab === 'Pending Request' ? request.status === 'Pending' : request.status !== 'Pending';
  });

  if (teamMemberRequestStore.isLoading) {
    return (
      <div className="h-full w-full flex items-center justify-center">
        <Loader className="w-20 h-20" />
      </div>
    );
  }

  return (
    <div>
      <div className="w-full border-b">
        <div className="px-5">
          <div className="font-semibold text-lg py-3">Team Member Requests</div>
          <div className="w-full flex justify-between items-center">
            <div className="flex items-center gap-2">
              <button
                type="button"
                className={`text-xs font-medium px-2 py-2 ${
                  tab === 'Pending Request' ? 'text-blue-500 border-b-2 border-blue-500' : 'text-gray-500'
                }`}
                onClick={() => switchTab('Pending Request')}
              >
                Pending Requests
              </button>
              <button
                type="button"
                className={`text-xs font-medium px-2 py-2 ${
                  tab === 'Assigned Requests' ? 'text-blue-500 border-b-2 border-blue-500' : 'text-gray-500'
                }`}
                onClick={() => switchTab('Assigned Requests')}
              >
                Assigned Requests
              </button>
            </div>
            <div className="flex items-center gap-2">
              <div className="flex">
                <Link to="/team-member-requests/create" style={{ textDecoration: 'none' }}>
                  <button
                    type="button"
                    className="bg-blue-600 hover:bg-blue-500 text-xs text-white px-2 py-1.5 rounded"
                  >
                    New Request
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <ResponseView
          noDataIcon={<EmptySearchIcon />}
          noData={requests.length === 0}
          noDataMsg="You have no requests... yet"
          noDataDesc="Click on the button below to create your team member request."
          noDataBtnLabel="+ New Request"
          onButtonClick={() => {
            navigate('/team-member-requests/create');
          }}
        >
          <table className="w-full divide-y border-collapse">
            <colgroup>
              <col className="w-2/12" />
              <col className="w-5/12" />
              <col className="w-1/12" />
              <col className="w-2/12" />
              <col className="w-1/12" />
              <col className="w-1/12" />
            </colgroup>
            <thead className="bg-gray-100">
              <tr>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                  Requestor
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Opportunity
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Date
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Status
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Value
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y">
              {requests.map(r => {
                return (
                  <tr key={r.id} className="text-gray-500">
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-3">
                      {r.requestor.firstName} {r.requestor.lastName}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm">
                      <div className="flex items-center">
                        <OpportunityIcon className="w-5 h-5 mr-2" />
                        {r.document.name}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm">
                      {moment(r.createdAt).format(constants.DATE_FORMAT)}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm">
                      <StatusPill status={r.status} />
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm">
                      {currencyFormatter.format(r.document.amount)}
                    </td>
                    <td className="whitespace-nowrap px-8 py-4 text-right font-medium">
                      <Link to={`/team-member-requests/${r.id}`}>
                        <EditIcon className="text-slate-400" />
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </ResponseView>
      </div>
    </div>
  );
}

export default TeamMemberRequestsPage;
