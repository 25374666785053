import { ClockIcon, EllipsisHorizontalIcon, LockClosedIcon, StarIcon, UserIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import CalendarIcon from 'components/icons/CalendarIcon';
import NoteIcon from 'components/icons/NoteIcon';

import { Document } from 'types/document';

import constants from 'utils/constants';
import useNavMenuStore from 'stores/nav-menu';
import DocumentLinkPopover from '../DocumentLinkPopover';
import PdfIcon from 'components/icons/PdfIcon';
import WordIcon from 'components/icons/WordIcon';
import TxtIcon from 'components/icons/TxtIcon';

interface Props {
  document: Document;
  ml: string;
  addPaddingOffSet?: boolean;
}

function DocumentLink({ document, ml, addPaddingOffSet = true }: Props) {
  const navMenuStore = useNavMenuStore();

  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);

  const buttonRef = useRef<HTMLButtonElement>(null);
  const documentLinkRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  const url = useMemo(
    () => (document.uploadedFile ? `/uploaded-file/${document.id}` : `/document/${document.id}`),
    [document],
  );
  const urlMatch = useMemo(
    () => location.pathname.length > 1 && url.startsWith(location.pathname),
    //  || ((!!document.event || !!document.calls.length) &&
    //   location.search.includes(`${constants.CURRENT_MEETING}=${document.id}`))
    [url, location, document],
  );

  useEffect(() => {
    if (documentLinkRef.current && urlMatch) {
      navMenuStore.setState({ activeEntityRef: documentLinkRef });
    }
  }, [documentLinkRef, urlMatch]);

  const docIcon = useMemo(() => {
    const iconClassName = 'shrink-0 w-5 h-5';
    if (!!document.event || !!document.calls.length) {
      return <CalendarIcon className={iconClassName} />;
    }
    if (document.uploadedFile) {
      if (document.uploadedFile.fileType.endsWith('pdf')) {
        return <PdfIcon className={iconClassName} />;
      }
      if (document.uploadedFile.fileType.includes('text/plain')) {
        return <TxtIcon className={iconClassName} />;
      }
      return <WordIcon className={iconClassName} />;
    }
    return <NoteIcon className={iconClassName} />;
  }, [document]);

  const docTitle = useMemo(
    () =>
      // eslint-disable-next-line no-nested-ternary
      document.event
        ? document.event.subject
          ? document.event.subject
          : document.docTitle
        : document.docTitle
        ? document.docTitle
        : 'Untitled',
    [document],
  );

  const offset = useMemo(() => (addPaddingOffSet ? 'ml-2' : ''), [addPaddingOffSet]);

  return (
    <div ref={documentLinkRef} className={`relative flex gap-1 items-center ${ml} scroll-m-36`}>
      <Link
        className={`relative w-full flex flex-col justify-center mb-0.5 rounded hover:bg-gray-200 hover:text-black group ${
          urlMatch ? 'text-blue-500 bg-gray-100 font-semibold' : 'text-gray-500 font-medium'
        }`}
        to={url}
        style={{ textDecoration: 'none' }}
      >
        <div className={`flex items-center gap-2 p-1 w-full ${offset}`}>
          {docIcon}
          <div className="flex-1 pr-4 flex gap-1 items-center text-left truncate">
            <span className="text-sm truncate">{docTitle}</span>
            {!document.isPublic && (
              <span
                className={`flex gap-1 text-sm 
                  ${urlMatch ? 'text-gray-500 font-normal hover:text-gray-500' : 'text-gray-400 hover:text-gray-400'}`}
              >
                <LockClosedIcon className="w-4 h-4 flex-shrink-0" />
              </span>
            )}
            {document.isFavourite && <StarIcon className="w-4 h-4 fill-yellow-400 text-yellow-400 flex-shrink-0" />}
          </div>
        </div>
        {!!document.event && (
          <div
            className={`pl-2 text-xs flex gap-2 items-center 
          ${urlMatch ? 'text-gray-500 font-normal hover:text-gray-500' : 'text-gray-400 hover:text-gray-400'}
          `}
          >
            <ClockIcon className="w-3 h-3" />
            <div className="w-28">{moment(document.event.startsAt).format(constants.DATETIME_FORMAT)}</div>
            <UserIcon className="w-3 h-3 ml-1" />
            <div>{document.event.attendees.length}</div>
          </div>
        )}

        <div
          className={`absolute top-1/2 right-1 transform -translate-y-1/2 ${
            popoverOpen ? 'block' : 'hidden'
          } group-hover:block`}
        >
          <button
            ref={buttonRef}
            type="button"
            className="z-10 flex items-center bg-gray-200 hover:bg-gray-300 text-gray-500 rounded"
            onClick={e => {
              setPopoverOpen(true);
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <EllipsisHorizontalIcon className="w-6 h-6" />
          </button>
        </div>
      </Link>
      <DocumentLinkPopover
        anchorEl={buttonRef.current}
        onClose={() => setPopoverOpen(false)}
        isOpen={popoverOpen}
        documentId={document.id}
        isFavorable
        hideFavoriteToggle={!!document.uploadedFile}
        hidePublicToggle={!!document.uploadedFile}
      />
    </div>
  );
}

DocumentLink.defaultProps = {
  addPaddingOffSet: true,
};

export default DocumentLink;
