import React from 'react';
import { Link } from 'react-router-dom';
import OpportunityIcon from '../../components/icons/OpportunityIcon';

interface Props {
  title: string;
  to: string;
}

function OpportunityLink({ title, to }: Props) {
  return (
    <div id="link-from-acct-page" className="flex items-center">
      <div className="w-full flex gap-2 items-center text-gray-500 font-medium">
        <OpportunityIcon className="w-4 h-4 fill-orange-400 text-orange-400" />
        <Link to={to} className="flex items-center flex-grow gap-2 ">
          <span>{title}</span>
        </Link>
      </div>
    </div>
  );
}

export default OpportunityLink;
