import React, { useEffect, useRef, useState } from 'react';
import Loader from 'components/Loader';
import useReportingStore from 'stores/reporting';
import { Pagination } from '@mui/material';
import TableReport from './ReportingPage/TableReport';
import { TableRef } from '../components/Table';
import DownloadIcon from '../components/icons/DownloadIcon';
import FilterButton from './ReportingPage/FilterButton';
import CustomizeButton from './ReportingPage/CustomizeButton';
import useReportingBackgroundFetch from './ReportingPage/useReportingBackgroundFetch';
import useTableConfig from './ReportingPage/useTableConfig';
import useFieldValuesStore from '../stores/field-values';
import useSuggestedAnswer from '../hooks/useSuggestedAnswer';
import ContextPanelView from '../components/ContextPanelView';
import useContextPanelStore from '../stores/context-panel';
import SortButton from './ReportingPage/SortButton';
import exportReporting from '../api/reporting/export-reporting';
import { GroupColumnDefinition } from '../components/Table/types';
import LoaderTransparentBackground from 'components/LoaderTransparentBackground';
import ManageViewsButton from 'components/ViewSetting/ManageViewsButton';
import { concat } from 'utils/styling';
import {
  getReportingCalculations,
  getReportingColumns,
  getReportingFilters,
  getReportingSorts,
} from './ReportingPage/utils';

function ReportingPage() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { tableConfig, updateTableConfig } = useTableConfig(isLoading);
  useReportingBackgroundFetch();
  useSuggestedAnswer();

  const tableRef = useRef<TableRef>(null);

  const fieldValuesStore = useFieldValuesStore();
  const reportingStore = useReportingStore();
  const contextPanelStore = useContextPanelStore();

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      await reportingStore.fetchTableColumns();
      setIsLoading(false);
    };
    fetch();
    return () => {
      fieldValuesStore.reset();
      contextPanelStore.close();
      reportingStore.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoading) {
      // updating based on filter and sort changes
      const columns = getReportingColumns(tableConfig);
      const filters = getReportingFilters(tableConfig);
      const sorts = getReportingSorts(tableConfig);
      const calculations = getReportingCalculations(tableConfig);
      reportingStore.updateFiltersAndSort(columns, filters, sorts, calculations, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading) {
      // updating based on filter and sort changes
      const columns = getReportingColumns(tableConfig);
      const filters = getReportingFilters(tableConfig);
      const sorts = getReportingSorts(tableConfig);
      const calculations = getReportingCalculations(tableConfig);
      reportingStore.updateFiltersAndSort(columns, filters, sorts, calculations, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableConfig]);

  if (isLoading) {
    return (
      <div className="flex items-center w-full h-full justify-center">
        <Loader />
      </div>
    );
  }

  return (
    <div className="w-full h-full flex flex-col">
      <div className="flex flex-col font-medium border-b border-gray-300">
        <div className="p-4 border-b flex items-center">
          <span className="font-semibold text-2xl">Reports</span>
          <span className="font-normal ml-2 rounded-full bg-blue-100 px-2 text-blue-500 text-xs">Beta</span>
        </div>
        <div className="flex items-center justify-between py-2 px-4">
          <div>
            {reportingStore.totalPages > 0 && (
              <Pagination
                count={reportingStore.totalPages}
                page={reportingStore.page}
                onChange={(e, v) => reportingStore.updatePage(v)}
              />
            )}
          </div>
          <div className="flex gap-2 text-sm items-center">
            <CustomizeButton
              tableConfig={tableConfig}
              updateTableConfig={columns => updateTableConfig(columns as GroupColumnDefinition[])}
            />
            <FilterButton
              tableConfig={tableConfig}
              updateTableConfig={columns => updateTableConfig(columns as GroupColumnDefinition[])}
            />
            <SortButton
              tableConfig={tableConfig}
              updateTableConfig={columns => updateTableConfig(columns as GroupColumnDefinition[])}
            />
            <button
              type="button"
              className="flex items-center gap-1 text-gray-400 hover:bg-gray-100 rounded p-1"
              onClick={() => {
                exportReporting(
                  getReportingFilters(tableConfig),
                  getReportingSorts(tableConfig),
                  getReportingColumns(tableConfig),
                );
                // tableRef.current?.exportData('Report');
              }}
            >
              <DownloadIcon />
              Export
            </button>
            <ManageViewsButton
              type="analytic_report"
              columns={tableConfig}
              onColumnsUpdate={newConfig => updateTableConfig(newConfig as GroupColumnDefinition[], true)}
              btnClassName={concat('!text-sm !bg-blue-50 !h-auto self-stretch w-[120px]')}
              localStorageKeyForCurrentView="ANALYTIC_REPORT_CURRENT_VIEW_ID"
            />
          </div>
        </div>
      </div>
      <ContextPanelView viewKey="reporting-context-panel" rightSectionDefaultWidth="600px">
        <div className="relative h-full">
          <TableReport ref={tableRef} columnConfigs={tableConfig} updateColumnConfigs={updateTableConfig} />
          {reportingStore.isFilteringAndSorting && (
            <LoaderTransparentBackground text="Applying the change, please wait..." />
          )}
        </div>
      </ContextPanelView>
    </div>
  );
}

export default ReportingPage;
