/* eslint-disable react/jsx-props-no-spreading, no-useless-return */
import openModal, { BaseModal } from 'components/BaseModal';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import useModalStore from 'stores/modal';
import { ModalAction, ModalCloseAction } from 'types/modal';
import styles from './CountDown.module.css';
import { concat } from 'utils/styling';
import { v4 as uuidv4 } from 'uuid';

interface Props {
  className?: string;
  seconds: number;
  onEnd: () => void;
}

function CountDown({ className, seconds, onEnd }: Props) {
  const [current, setCurrent] = useState(seconds);
  const ref = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const animateCountDownNum = () => {
      requestAnimationFrame(() => {
        ref.current?.classList.remove('big');
        requestAnimationFrame(() => {
          ref.current?.classList.add('big');
        });
      });
    };
    animateCountDownNum();
    const interval = setInterval(() => {
      setCurrent(prev => {
        if (prev <= 1) {
          clearInterval(interval);
          setTimeout(() => {
            onEnd();
          });
        } else {
          animateCountDownNum();
        }
        return prev === 1 ? 1 : prev - 1;
      });
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className={concat(styles.countDownWrap, className)}>
      <span className={concat(styles.countDownNum, 'big')} ref={ref}>
        {current}
      </span>
      <button type="button" onClick={onEnd}>
        Skip
      </button>
    </div>
  );
}

CountDown.defaultProps = {
  className: '',
};

const openCountDownModal = (props: Omit<Props, 'onEnd'>): Promise<void> => {
  const modalId = uuidv4();
  return openModal(
    {
      className: styles.countDownModalContent,
      body: (
        <CountDown
          {...props}
          onEnd={() => {
            useModalStore.getState().close(ModalCloseAction, modalId);
          }}
        />
      ),
      bodyClassName: styles.countDownModalBody,
    },
    modalId,
    { className: styles.countDownModal, hideBackdrop: false, disableEscapeKeyDown: true, disableBackdropClose: true },
  ).then(() => {
    return;
  });
};

export default openCountDownModal;
