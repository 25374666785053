import React from 'react';
import TooltipMui from '@mui/material/Tooltip';

export type TooltipPlacement =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';

interface Props {
  children: React.ReactElement;
  title: string | React.ReactNode;
  placement: TooltipPlacement;
}

function Tooltip({ children, title, placement }: Props) {
  return (
    <TooltipMui title={title} placement={placement} arrow>
      {children}
    </TooltipMui>
  );
}

export default Tooltip;
