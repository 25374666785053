/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import MuiModal from '@mui/material/Modal';

import useModalStore from 'stores/modal';

import styles from './Modal.module.css';
import { keys } from 'lodash';
import { ModalCloseAction } from 'types/modal';
import { concat } from 'utils/styling';

function Modal() {
  const modalStore = useModalStore();

  return (
    <>
      {keys(modalStore.modals).map(modalId => {
        const modal = modalStore.modals[modalId];
        return (
          <MuiModal
            key={modalId}
            open={modal.isOpen}
            onClose={(_event, reason) => {
              if (modal.disableBackdropClose && reason === 'backdropClick') {
                return;
              }
              modalStore.close(ModalCloseAction, modalId);
            }}
            disableEscapeKeyDown={modal.disableEscapeKeyDown}
            hideBackdrop={modal.hideBackdrop}
            disableRestoreFocus={modal.disableRestoreFocus}
          >
            <div
              className={concat(styles.modal, modal.isHidden && 'hidden', modal.className)}
              style={{
                top: modal.verticalPosition === 'top' ? '10rem' : '50%',
                transform: modal.verticalPosition === 'top' ? 'translateX(-50%)' : 'translate(-50%, -50%)',
              }}
            >
              {modal.element}
            </div>
          </MuiModal>
        );
      })}
    </>
  );
}

export default Modal;
