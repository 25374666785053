import { Editor } from 'slate';
import { copyEditorFragment } from './CopyPlugin';
import { SlatePlugin } from './types';
import { backspaceKeyPlugin } from './BackspacePlugin';

function cutPlugin(event: React.ClipboardEvent<HTMLDivElement>, editor: Editor): boolean {
  copyEditorFragment(event, editor, true);
  // reuse logic in backspace key plugin to delete selection when cut
  backspaceKeyPlugin(event, editor, true);
  return true;
}

const CutPlugin: SlatePlugin = {
  key: 'cut-plugin',
  onCut: cutPlugin,
};

export default CutPlugin;
