import React, { useRef, useState } from 'react';
import useUserStore from 'stores/user';
import moment from 'moment';
import constants from '../../utils/constants';
import { concat } from 'utils/styling';
import ThreeVerticalDotsIcon from '../../components/icons/ThreeVerticalDotsIcon';
import { ActionType, Member } from '../../types/member';
import Popover from 'components/Popover';
import { MenuItem } from '@mui/material';
import usePopupMessageStore from 'stores/popup-message';
import useModalStore from '../../stores/modal';
import DeleteMemberModal from './DeleteMemberModal';
import SpinLoader from '../../components/icons/SpinLoader';
import { openFormModal } from '../../components/BaseModal';
import EditMemberForm from './EditMemberForm';
import InviteEntry from './InviteEntry';
import InvitationEntry from './InvitationEntry';

interface MemberEditButtonProps {
  member: Member;
}

function MemberEditButton({ member }: MemberEditButtonProps) {
  const userStore = useUserStore();
  const popupMessageStore = usePopupMessageStore();
  const modalStore = useModalStore();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isUpdatingStatus, setIsUpdatingStatus] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const ref = useRef<HTMLButtonElement>(null);

  const isAdmin = !!userStore.user?.isAdmin;

  return (
    <>
      <button ref={ref} type="button" className="hover:bg-gray-100 rounded-lg p-1" onClick={() => setIsOpen(true)}>
        <ThreeVerticalDotsIcon />
      </button>
      <Popover anchorEl={ref.current} isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <div className="w-44 text-slate-700">
          <MenuItem
            disabled={!isAdmin}
            onClick={async () => {
              await openFormModal<Member>(
                {
                  formData: member,
                  renderFormBody: ({ register, control, errors }) => (
                    <EditMemberForm register={register} control={control} errors={errors} />
                  ),
                  actions: [
                    {
                      label: 'Cancel',
                      color: 'secondary',
                    },
                    {
                      label: 'Update',
                      color: 'primary',
                      type: 'submit',
                      cb: async (fd: Member) => {
                        await userStore.updateMember(fd.email, ActionType.UPDATE, fd.role, null, fd.isAdmin);
                        await userStore.fetchMembers();
                      },
                    },
                  ],
                },
                {
                  className: 'w-[46rem]',
                  title: 'Edit Member',
                  bodyClassName: '!px-10',
                },
              ).then(rsp => {
                return typeof rsp === 'object' ? (rsp as Member) : undefined;
              });
              setIsOpen(false);
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            disabled={!isAdmin || isUpdatingStatus}
            onClick={async () => {
              setIsUpdatingStatus(true);
              const actionType = member.status === 'active' ? ActionType.DEACTIVATE : ActionType.REACTIVATE;
              await userStore.updateMember(member.email, actionType, null, null, null);
              popupMessageStore.success(`Successfully ${actionType} member`);
              await userStore.fetchMembers();
              setIsUpdatingStatus(false);
              setIsOpen(false);
            }}
          >
            <div className="flex items-center">
              <div>{member.status === 'active' ? 'Deactivate' : 'Reactivate'}</div>
              {isUpdatingStatus && (
                <div>
                  <SpinLoader className="ml-2 w-6 h-6 animate-spin" />
                </div>
              )}
            </div>
          </MenuItem>
          <MenuItem
            disabled={!isAdmin || isDeleting}
            onClick={() => {
              modalStore.setState({
                isOpen: true,
                element: (
                  <DeleteMemberModal
                    member={member}
                    onDelete={async transferEmail => {
                      setIsDeleting(true);
                      await userStore.updateMember(member.email, ActionType.DELETE, null, transferEmail, null);
                      popupMessageStore.success(`Successfully deleted member`);
                      await userStore.fetchMembers();
                      setIsDeleting(false);
                      setIsOpen(false);
                    }}
                  />
                ),
              });
            }}
          >
            <div className="flex items-center">
              <div className="text-red-500">Delete</div>
              {isDeleting && (
                <div>
                  <SpinLoader className="ml-2 w-6 h-6 animate-spin" />
                </div>
              )}
            </div>
          </MenuItem>
        </div>
      </Popover>
    </>
  );
}

function TeamSettingView() {
  const userStore = useUserStore();
  return (
    <div>
      <div>
        <div className="text-xl font-medium">Team</div>
        <div className="py-2" />
        <div className="text-sm text-slate-500">Add and manage team members and team information.</div>
      </div>
      <div className="py-6" />
      <div>
        <div className="text-xl font-medium">Your team members</div>
        <div className="py-2" />
        <table className="w-full divide-y border-collapse border">
          <colgroup>
            <col className="w-4/12" />
            <col className="w-2/12" />
            <col className="w-3/12" />
            <col className="w-2/12" />
            <col className="w-1/12" />
          </colgroup>
          <thead className="bg-slate-100 text-left text-slate-600 z-10">
            <tr className="divide-x">
              <th scope="col" className="font-medium px-5 py-4">
                User
              </th>
              <th scope="col" className="font-medium px-5 py-4">
                Status
              </th>
              <th scope="col" className="font-medium px-5 py-4">
                Role
              </th>
              <th scope="col" className="font-medium px-5 py-4">
                Date Added
              </th>
              <th scope="col" className="px-3 font-medium">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y text-slate-700">
            {userStore.members.map(m => {
              if (m.id === userStore.user?.id) return null;
              return (
                <tr key={m.id} className="divide-x">
                  <td className="px-5 py-4">
                    <div className="flex items-center">
                      <div className="font-medium">
                        {m.firstName} {m.lastName}
                      </div>
                      {m.isAdmin && (
                        <div className="ml-2 rounded-full text-orange-500 bg-orange-100 px-4 text-sm">Admin</div>
                      )}
                    </div>
                    <div className="text-slate-500 text-sm">{m.email}</div>
                  </td>
                  <td className="px-5">
                    <div
                      className={concat(
                        'rounded-full text-sm px-4 py-1 w-fit',
                        m.status === 'active' ? 'bg-green-50 text-green-600' : 'bg-gray-100 text-gray-600',
                      )}
                    >
                      {m.status === 'active' ? 'Active' : 'Deactivated'}
                    </div>
                  </td>
                  <td className="px-5">{m.role}</td>
                  <td className="px-5">{moment(m.createdAt).format(constants.DATE_FORMAT)}</td>
                  <td className="px-5">
                    <MemberEditButton member={m} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="py-6" />
      <div>
        <div className="text-xl font-medium">Invite Team Members</div>
        <div className="py-2" />
        <div>
          <InviteEntry />
        </div>
        <div className="py-3" />
        {userStore.invitations.length > 0 && (
          <>
            <div className="font-medium underline">Invitations</div>
            <div className="py-1" />
            <div className="divide-y">
              {userStore.invitations.map(invitation => {
                return <InvitationEntry key={invitation.id} invitation={invitation} />;
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default TeamSettingView;
