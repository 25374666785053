/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { openFormModal } from 'components/BaseModal';
import FolderSelector from 'components/FolderSelector';
import FormField from 'components/Form/FormField';
import TaskEditor from 'components/NoteEditor/CustomElement/Task/TaskEditor';
import { Controller } from 'react-hook-form';
import { Task, getEmptyTask } from 'types/task';
import { requiredSlateValidate } from 'utils/validate';
import createTask from 'api/task/create-task';

/**
 * open modal to create a new task
 * @returns new created task
 */
const openNewTaskModal = (): Promise<Task | undefined> => {
  const newTask = { task: getEmptyTask(), documentId: null };
  return openFormModal<{ task: Task; documentId: string | null }>(
    {
      formData: newTask,
      renderFormBody: ({ errors, control }) => (
        <>
          <FormField
            className="mb-3"
            label="Task"
            labelClassName="w-[6rem]"
            fieldClassName="pl-2"
            error={errors.task ? 'Content is mandatory' : ''}
          >
            <Controller
              name="task"
              control={control}
              rules={{ validate: newT => requiredSlateValidate.validate(newT.content) }}
              render={({ field }) => (
                <TaskEditor
                  taskId=""
                  initTask={newTask.task}
                  documentId={null}
                  opportunityId={null}
                  ref={field.ref}
                  onUpdate={newT => {
                    field.onChange({ ...field.value, ...newT });
                  }}
                  autoFocus
                  hideCheckbox
                />
              )}
            />
          </FormField>
          <FormField className="mb-3" label="Folder" labelClassName="w-[6rem]">
            <Controller
              name="documentId"
              control={control}
              render={({ field }) => (
                <FolderSelector selectedDocumentId={field.value} setDocumentId={field.onChange} ref={field.ref} />
              )}
            />
          </FormField>
        </>
      ),
      actions: [
        {
          label: 'Cancel',
          color: 'secondary',
        },
        {
          label: 'Create',
          color: 'primary',
          type: 'submit',
          cb: (fd: { task: Task; documentId: string | null }) => {
            return createTask(fd.documentId, fd.task.content, fd.task.done, fd.task.dueDate, fd.task.assignedToUserId);
          },
        },
      ],
    },
    {
      className: 'w-[46rem]',
      title: 'Create a new task',
      bodyClassName: '!pr-0',
    },
  ).then(rsp => {
    return typeof rsp === 'object' ? (rsp as unknown as Task) : undefined;
  });
};

export default openNewTaskModal;
