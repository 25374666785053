import React, { useCallback, useMemo } from 'react';
import { ColumnConfig, GroupColumnDefinition } from '../../components/Table/types';
import useReportingStore from '../../stores/reporting';
import Table, { TableRef } from '../../components/Table';

interface Props {
  columnConfigs: GroupColumnDefinition[];
  updateColumnConfigs: (columnConfigs: GroupColumnDefinition[]) => void;
}

const TableReport = React.forwardRef<TableRef, Props>(({ columnConfigs, updateColumnConfigs }, ref) => {
  const { tableRows, perPage, page } = useReportingStore();

  const isAllColumnHidden = useMemo(
    () => columnConfigs.every(c => c.columns.every(col => col.isHidden)),
    [columnConfigs],
  );

  const setColumnConfigs = useCallback(
    (configs: ColumnConfig[]) => {
      updateColumnConfigs(configs as GroupColumnDefinition[]);
    },
    [updateColumnConfigs],
  );

  if (isAllColumnHidden) {
    return (
      <div className="w-full h-full flex flex-col items-center justify-center gap-3">
        <div className="text-lg font-medium">All columns are set to be hidden</div>
        <div className="text-sm text-gray-700">Click on the button below to show all columns.</div>
        <button
          type="button"
          className="rounded bg-blue-600 hover:bg-blue-500 text-white px-4 py-2"
          onClick={() => {
            const newColumnConfigs = columnConfigs.map(c => ({
              ...c,
              columns: c.columns.map(col => ({
                ...col,
                isHidden: false,
              })),
            }));
            updateColumnConfigs(newColumnConfigs);
          }}
        >
          Show all columns
        </button>
      </div>
    );
  }

  return (
    <Table
      ref={ref}
      columnConfigs={columnConfigs}
      setColumnConfigs={setColumnConfigs}
      rows={tableRows}
      category={null}
      showSequence
      sequenceOffset={perPage * (page - 1)}
    />
  );
});

TableReport.displayName = 'TableReport';

export default TableReport;
