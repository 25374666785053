/* eslint-disable consistent-return */
import React, { useRef } from 'react';

import Popover from 'components/Popover';
import TrashIcon from 'components/icons/TrashIcon';

import styles from './SortableElementMenu.module.css';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  anchorEl: Element | null;
  deleteElement: () => void;
}

function SortableElementMenu({ isOpen, onClose, anchorEl, deleteElement }: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (isOpen) {
      const detectOutsideClick = (event: MouseEvent) => {
        if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
          onClose();
        }
      };
      document.addEventListener('mousedown', detectOutsideClick);
      return () => {
        document.removeEventListener('mousedown', detectOutsideClick);
      };
    }
  }, [isOpen]);

  return (
    <Popover
      isOpen={isOpen}
      onClose={onClose}
      anchorEl={anchorEl}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      disableRestoreFocus // disable restore focus to prevent editor focus at beginning when close popover
      disableAutoFocus // disable auto focus to prevent editor lose focus when open popover
      hideBackdrop // hide backdrop to prevent click on backdrop to make editor lose focus
    >
      <div className={styles.container} ref={containerRef}>
        <button type="button" className="flex items-center gap-2 p-1 hover:bg-gray-100 w-full" onClick={deleteElement}>
          <TrashIcon className="w-5 h-5 text-gray-600" />
          <div className="text-sm text-gray-600">Delete</div>
        </button>
      </div>
    </Popover>
  );
}

export default SortableElementMenu;
