import { MenuItem } from '@mui/material';
import React from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { ColumnDefinition } from '../../types';

interface Props {
  columnDefinition: ColumnDefinition;
  onSortUpdate: (sort: 'ASC' | 'DESC' | undefined) => void;
}

function ClearSortColumnMenu({ columnDefinition, onSortUpdate }: Props) {
  return columnDefinition.sort ? (
    <MenuItem onClick={() => onSortUpdate(undefined)}>
      <div className="flex items-center text-sm">
        <XMarkIcon className="w-4 h-4 mr-2" />
        Clear sort
      </div>
    </MenuItem>
  ) : null;
}

export default ClearSortColumnMenu;
