import httpClient from '../http-client';
import { Recording } from '../../types/recording';

export interface Response {
  recordings: Recording[];
}

export default async (documentId: string): Promise<Response> => {
  const { data } = await httpClient.get<Response>(`/recordings/?documentId=${documentId}`);
  return data;
};
