import { LockClosedIcon, LockOpenIcon } from '@heroicons/react/24/outline';
import MenuItem from '@mui/material/MenuItem';
import React, { useState } from 'react';
import useDocumentStore from '../stores/document';
import useModalStore from '../stores/modal';
import DeleteDocumentModal from './DeleteDocumentModal';
import SpinLoader from './icons/SpinLoader';
import StarIcon from './icons/StarIcon';
import TrashIcon from './icons/TrashIcon';
import Popover from './Popover';

interface State {
  isLoading: boolean;
}

interface Props {
  anchorEl: HTMLButtonElement | null;
  onClose: () => void;
  isOpen: boolean;
  documentId: string;
  isFavorable: boolean;
  hideFavoriteToggle?: boolean;
  hidePublicToggle?: boolean;
}

function DocumentLinkPopover({
  anchorEl,
  onClose,
  isOpen,
  documentId,
  isFavorable,
  hideFavoriteToggle,
  hidePublicToggle,
}: Props) {
  const [state, setState] = useState<State>({ isLoading: false });

  const documentStore = useDocumentStore();
  const modalStore = useModalStore();

  const document = documentStore.documents.find(doc => doc.id === documentId);
  if (!document) {
    onClose();
    return <div>Unknown Document</div>;
  }
  return (
    <Popover
      anchorEl={anchorEl}
      onClose={onClose}
      isOpen={isOpen}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <div className="relative">
        {state.isLoading ? (
          <div className="flex items-center justify-center p-5">
            <SpinLoader className="animate-spin w-6 h-6 text-orange-500" />
          </div>
        ) : (
          <div className="flex flex-col">
            {hidePublicToggle !== true && (
              <MenuItem
                className="p-2 flex items-center gap-2"
                onClick={async () => {
                  if (isFavorable) {
                    setState(prevState => ({ ...prevState, isLoading: true }));
                    await documentStore.updateDocumentData(document.id, { isPublic: !document.isPublic });
                    setState(prevState => ({ ...prevState, isLoading: false }));
                  }
                }}
                disabled={!isFavorable}
              >
                {document.isPublic ? (
                  <LockClosedIcon className="ml-1 w-4 h-4 text-gray-600" />
                ) : (
                  <LockOpenIcon className="ml-1 w-4 h-4 text-gray-600" />
                )}
                <div className="text-sm text-gray-600">{document.isPublic ? 'Set to Private' : 'Set to Public'}</div>
              </MenuItem>
            )}
            {hideFavoriteToggle !== true && (
              <MenuItem
                className="p-2 flex items-center gap-2"
                onClick={async () => {
                  if (isFavorable) {
                    setState(prevState => ({ ...prevState, isLoading: true }));
                    await documentStore.updateDocumentData(document.id, { isFavourite: !document.isFavourite });
                    setState(prevState => ({ ...prevState, isLoading: false }));
                  }
                }}
                disabled={!isFavorable}
              >
                <StarIcon className="w-5 h-5 text-yellow-400" />
                <div className="text-sm text-gray-600">{document.isFavourite ? 'Unfavorite' : 'Favorite'}</div>
              </MenuItem>
            )}
            <MenuItem
              className="p-2 flex items-center gap-2"
              onClick={async () => {
                onClose();
                if (isFavorable) {
                  setState(prevState => ({ ...prevState, isMenuOpen: false }));
                  modalStore.setState({
                    isOpen: true,
                    element: <DeleteDocumentModal documentId={documentId} navigateTo="/" />,
                  });
                }
              }}
              disabled={!!document.event}
            >
              <TrashIcon className="w-5 h-5 text-gray-600" />
              <div className="text-sm text-gray-600">Delete</div>
            </MenuItem>
          </div>
        )}
      </div>
    </Popover>
  );
}

DocumentLinkPopover.defaultProps = {
  hideFavoriteToggle: false,
  hidePublicToggle: false,
};

export default DocumentLinkPopover;
