import React from 'react';

interface Props {
  className?: string;
}

function RowsIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 3.53333L3 12.1333C3 12.612 3.38802 13 3.86667 13H6.26667C6.74531 13 7.13333 12.612 7.13333 12.1333L7.13333 3.53333C7.13333 3.05469 6.74531 2.66667 6.26667 2.66667H3.86667C3.38802 2.66667 3 3.05469 3 3.53333Z"
        stroke="#AFB9CC"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.20312 3.53333V12.1333C9.20312 12.612 9.59114 13 10.0698 13H12.4698C12.9484 13 13.3365 12.612 13.3365 12.1333V3.53333C13.3365 3.05469 12.9484 2.66667 12.4698 2.66667H10.0698C9.59114 2.66667 9.20312 3.05469 9.20312 3.53333Z"
        stroke="#AFB9CC"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

RowsIcon.defaultProps = {
  className: undefined,
};
export default RowsIcon;
