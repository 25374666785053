import { Invitation, Member } from 'types/member';
import httpClient from '../http-client';

interface Response {
  members: Member[];
  invitations: Invitation[];
}

export default async (): Promise<Response> => {
  const { data } = await httpClient.get<Response>('/user/management');
  return data;
};
