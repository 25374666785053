import React from 'react';

interface Props {
  className?: string;
}

function CaretIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6078_55216)">
        <path
          d="M8.70956 12.2897L11.2996 9.69973C11.6896 9.30973 12.3196 9.30973 12.7096 9.69973L15.2996 12.2897C15.9296 12.9197 15.4796 13.9997 14.5896 13.9997H9.40956C8.51956 13.9997 8.07956 12.9197 8.70956 12.2897Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_6078_55216">
          <rect width="24" height="24" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
}

CaretIcon.defaultProps = {
  className: undefined,
};
export default CaretIcon;
