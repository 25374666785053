import httpClient from '../http-client';
import { FieldValue } from 'types/field-value';

export default async (
  fieldValueId: string,
  acceptanceStatusAiSuggestion: 'accepted' | 'rejected' | '',
): Promise<FieldValue> => {
  const { data } = await httpClient.put(`/field-values/${fieldValueId}`, { acceptanceStatusAiSuggestion });
  return data;
};
