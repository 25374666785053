import { Field, isCRMField } from 'types/field-value';

function getFieldName(field: Field) {
  if (isCRMField(field)) {
    return field.crmField.label || field.label;
  }
  return field.label;
}

export default getFieldName;
