import React from 'react';

interface Props {
  className?: string;
}

function DocumentPlusIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.4992 10.8V7.84C18.4992 6.49587 18.4992 5.82381 18.2376 5.31042C18.0075 4.85883 17.6404 4.49168 17.1888 4.26158C16.6754 4 16.0033 4 14.6592 4H9.53922C8.19509 4 7.52303 4 7.00964 4.26158C6.55805 4.49168 6.1909 4.85883 5.9608 5.31042C5.69922 5.82381 5.69922 6.49587 5.69922 7.84V16.16C5.69922 17.5041 5.69922 18.1762 5.9608 18.6896C6.1909 19.1412 6.55805 19.5083 7.00964 19.7384C7.52303 20 8.19509 20 9.53922 20H12.0992M13.6992 11.2H8.89922M10.4992 14.4H8.89922M15.2992 8H8.89922M16.8992 19.2V14.4M14.4992 16.8H19.2992"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

DocumentPlusIcon.defaultProps = {
  className: undefined,
};

export default DocumentPlusIcon;
