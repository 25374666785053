/* eslint-disable no-param-reassign  */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  pointerWithin,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { Editable, ReactEditor, RenderElementProps, RenderLeafProps, Slate, withReact } from 'slate-react';
import { withHistory } from 'slate-history';
import { createEditor, Descendant, Editor, Transforms } from 'slate';
import { NoteTemplateInEdit } from 'api/note-templates/get-note-templates';
import { createPortal } from 'react-dom';
import { SortableContext } from '@dnd-kit/sortable';
import useNoteEditorStore from 'stores/note-editor';
import fileUploadStart from 'api/files/file-upload-start';
import useImageEntityStore from 'stores/image-entity';
import { handleFileUpload } from 'utils/file';
import fileUploadComplete from 'api/files/file-upload-complete';
import usePopupMessageStore from 'stores/popup-message';
import { SuperpanelAPIError } from 'utils/errors';
import Loader from './Loader';
import NoteEditor from './NoteEditor/NoteEditor';
import withInlines from './NoteEditor/EditorContext/withInlines';
import MarginElementBottom from './NoteEditor/CustomElement/MarginElementBottom';
import { BlockType, CustomElement, ImageElement } from './NoteEditor/types';
import SlatePlugins, { SlatePluginsChildrenProps } from './NoteEditor/Plugins/SlatePlugins';
import { Plugin } from './NoteEditor/Plugins';
import Leaf from './NoteEditor/Leaf';
import Element from './NoteEditor/Element';
import withVoids from './NoteEditor/EditorContext/withVoids';
import { withNormalizeBlockNode, withNormalizeInlineNode } from './NoteEditor/EditorContext/withNormalizeNode';
import EditorToolbar from './NoteEditor/CustomElement/Toolbar/EditorToolbar';
import withBlockUUID from './NoteEditor/EditorContext/withBlockUUID';
import constants from 'utils/constants';
import { noop } from 'lodash';
import DraggingElementEditor from './NoteEditor/Elements/DraggingElement';

interface Props {
  template: NoteTemplateInEdit;
  plugins: Plugin[];
  readOnly: boolean;
  onModifyTemplate: (children: CustomElement[]) => void;
}

function EditorTemplate({ template, plugins, readOnly, onModifyTemplate }: Props) {
  const useNoteEditor = useNoteEditorStore();
  const imageEntityStore = useImageEntityStore();
  const popupMessageStore = usePopupMessageStore();

  const editor = useMemo(() => {
    if (template) {
      return withNormalizeBlockNode(
        withNormalizeInlineNode(
          withVoids(withInlines(withBlockUUID(withReact(withHistory(createEditor()))), 'template', '', '', true)),
        ),
      );
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template.noteTemplateId]);

  const editorRef = useRef(editor);

  useEffect(() => {
    if (editor) {
      // force normalize editor to add uuid to all nodes
      Editor.normalize(editor, { force: true });
      if (!Editor.string(editor, []).trim()) {
        try {
          ReactEditor.focus(editor);
          Transforms.select(editor, NoteEditor.end(editor, []));
        } catch {
          noop();
        }
      }
    }
    editorRef.current = editor;
  }, [editor]);

  const [dragElementId, setDragElementId] = useState<string | null>(null);

  const [scrollContainer, setScrollContainer] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (
      useNoteEditor.filesToUpload !== null &&
      useNoteEditor.filesToUpload.length &&
      editor &&
      useNoteEditor.currentActionEditor === editor
    ) {
      useNoteEditor.filesToUpload.forEach(async file => {
        // get presigned post url & data to upload file to
        const presignedData = await fileUploadStart(file.name, file.type);
        const { id } = presignedData;

        // create loading image element (no uploaded url yet)
        imageEntityStore.upsertEntity(id, { url: null, fileName: file.name, fileType: file.type });
        const imageElement: ImageElement = {
          fileId: id,
          type: BlockType.Image,
          children: [{ text: '' }],
        };

        // if we can get target path for inserted image, use that
        if (useNoteEditor.uploadTargetPath) {
          Transforms.insertNodes(editor, imageElement, { at: useNoteEditor.uploadTargetPath });
          useNoteEditor.setState({ uploadTargetPath: null });
        } else {
          Transforms.insertNodes(editor, imageElement);
        }

        // do actual upload to s3 presigned post url
        const uploadResult = await handleFileUpload({ file, presignedData });

        // if upload is okay, update image element/store with uploaded url
        if (uploadResult.ok) {
          const completeResult = await fileUploadComplete(id);
          if (completeResult?.url) {
            imageEntityStore.upsertEntity(id, { url: completeResult.url, fileName: file.name, fileType: file.type });
          }
        } else {
          // if failed, remove image element/store to avoid rendering empty image element
          Transforms.delete(editor, { at: ReactEditor.findPath(editor, imageElement) });
          imageEntityStore.removeEntity(id);

          // display error message
          let displayErrorMessage = 'There was an issue uplaoding your file. Please try again.';
          const xml = new DOMParser().parseFromString(await uploadResult.text(), 'text/xml');
          const errorMessage = xml.getElementsByTagName('Message')[0].childNodes[0].nodeValue;

          if (errorMessage) {
            displayErrorMessage = errorMessage;
          }

          popupMessageStore.throwError({
            displayErrorMessage,
          } as SuperpanelAPIError);
        }
      });

      if (useNoteEditor.fileDialogId) {
        const fileDialogElement = editor.children.find(
          (x: ImageElement) => x && x.fileId === useNoteEditor.fileDialogId,
        );
        if (fileDialogElement) {
          Transforms.delete(editor, { at: ReactEditor.findPath(editor, fileDialogElement) });
        }
        useNoteEditor.setState({ fileDialogId: null });
      }
      useNoteEditor.setState({ filesToUpload: null, currentActionEditor: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useNoteEditor.filesToUpload]);

  useEffect(() => {
    if (useNoteEditor.tableDialogId !== null && useNoteEditor.currentActionEditor === editor && editor) {
      NoteEditor.createNewTable(
        editor,
        useNoteEditor.tableDialogId,
        useNoteEditor.tableProperties?.rows ?? 0,
        useNoteEditor.tableProperties?.columns ?? 0,
      );
      useNoteEditor.setState({ tableDialogId: null, currentActionEditor: null, tableProperties: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useNoteEditor.tableDialogId]);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
  );

  const draggingElement = useMemo(
    () => (dragElementId ? editor?.children.find((x: CustomElement) => x.uuid === dragElementId) : null),
    [editor?.children, dragElementId],
  );

  /* ---- Drag and Drop functions ---- */
  const clearSelection = useCallback(() => {
    if (!editorRef.current) {
      return;
    }

    ReactEditor.blur(editorRef.current);
    Transforms.deselect(editorRef.current);
    window.getSelection()?.empty();
  }, []);

  const handleDragStart = useCallback((event: DragStartEvent) => {
    if (!editorRef.current) {
      return;
    }
    if (event.active) {
      clearSelection();
      setDragElementId(event.active.id as string);
    }
  }, []);

  const handleDragEnd = useCallback((event: DragEndEvent) => {
    if (!editorRef.current) {
      return;
    }
    const fromId = event.active?.id as string;
    const toId = event.over?.id as string;
    if (fromId && toId && fromId !== toId) {
      const fromIndex = editorRef.current.children.findIndex((x: CustomElement) => x.uuid === fromId);
      const toIndex =
        toId === constants.EDITOR_BOTTOM_SECTION_ID
          ? editorRef.current.children.length
          : editorRef.current.children.findIndex((x: CustomElement) => x.uuid === toId);
      if (fromIndex >= 0 && toIndex >= 0) {
        Transforms.moveNodes(editorRef.current, {
          at: [fromIndex],
          to: [fromIndex < toIndex ? toIndex - 1 : toIndex],
        });
      }
    }
    setDragElementId(null);
  }, []);

  const handleDragCancel = useCallback(() => {
    setDragElementId(null);
  }, []);

  const disableSortingStrategy = useCallback(() => {
    return null;
  }, []);

  const renderEditorContent = useCallback(
    (funProps: SlatePluginsChildrenProps) => {
      if (!editor) {
        return null;
      }

      return (
        <>
          {funProps.pluginElements}
          <Editable
            renderElement={(props: RenderElementProps) => (
              <Element
                editor={editor}
                attributes={props.attributes}
                element={props.element}
                blockPlaceholder={readOnly ? '' : "Type '/' to add discovery fields or more"}
                emptyParagraphPlaceholder={readOnly ? '' : "Type '/' to add discovery fields or more"}
                documentId={null}
                opportunityId={null}
                readOnly={readOnly}
                enableComment={false}
                onAddNewBlock={funProps.onAction}
                docSource="template"
              >
                {props.children}
              </Element>
            )}
            renderLeaf={(props: RenderLeafProps) => (
              <Leaf attributes={props.attributes} leaf={props.leaf} text={props.text}>
                {props.children}
              </Leaf>
            )}
            onKeyDown={funProps.onKeyDown}
            onDrop={funProps.onDrop}
            onCopy={funProps.onCopy}
            onCut={funProps.onCut}
            onPaste={funProps.onPaste}
            readOnly={readOnly}
          />
        </>
      );
    },
    [editor, readOnly],
  );

  if (!editor) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <Loader />
      </div>
    );
  }

  return (
    <div
      ref={el => {
        setScrollContainer(el);
      }}
      className="flex flex-col w-full h-full relative overflow-y-auto"
    >
      <DndContext
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
        collisionDetection={pointerWithin}
      >
        <SortableContext
          id="uuid"
          items={(editor?.children || []) as unknown[] as { id: string }[]}
          strategy={disableSortingStrategy}
        >
          <div className="w-full px-3 py-4">
            <Slate
              editor={editor}
              initialValue={template.data.children}
              onChange={onModifyTemplate as (children: Descendant[]) => void}
            >
              {!!scrollContainer && (
                <SlatePlugins editor={editor} parentRef={scrollContainer} plugins={plugins}>
                  {renderEditorContent}
                </SlatePlugins>
              )}
              <EditorToolbar editor={editor} enableTypeSelect scrollContainer={scrollContainer || undefined} />
            </Slate>
          </div>
          {!readOnly && <MarginElementBottom editor={editor} className="pb-6" />}
        </SortableContext>
        {draggingElement &&
          createPortal(
            <DragOverlay adjustScale={false} zIndex={9999}>
              <DraggingElementEditor
                element={draggingElement}
                documentId={null}
                opportunityId={null}
                enableComment={false}
              />
            </DragOverlay>,
            document.body,
          )}
      </DndContext>
    </div>
  );
}

export default EditorTemplate;
