/* eslint-disable import/no-extraneous-dependencies */
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { create } from 'zustand';
import deleteProductCategory from 'api/product-gaps/delete-product-category';
import updateProductCategory from 'api/product-gaps/update-product-category';
import createProductCategoryStatus from 'api/product-gaps/create-product-category-status';
import getProductCategoryStatuses from 'api/product-gaps/get-product-category-statuses';
import deleteProductCategoryStatus from 'api/product-gaps/delete-product-category-status';
import updateProductCategoryStatus from 'api/product-gaps/update-product-category-status';
import {
  ProductCategory,
  ProductCategoryStatus,
  ProductField,
  ProductFieldType,
  ProductGap,
} from '../types/product-gap';
import getProductCategories from '../api/product-gaps/get-product-categories';
import createProductCategory from '../api/product-gaps/create-product-category';
import getProductGaps from '../api/product-gaps/get-product-gaps';
import createProductGap from '../api/product-gaps/create-product-gap';
import syncProductGap from '../api/product-gaps/sync-product-gap';
import getProductFields from '../api/product-gaps/get-product-fields';
import createProductField from '../api/product-gaps/create-product-field';
import updateProductFieldsRow from '../api/product-gaps/update-product-fields-row';
import updateProductField from '../api/product-gaps/update-product-field';
import getProductGap from '../api/product-gaps/get-product-gap';
import deleteProductGap from '../api/product-gaps/delete-product-gap';
import deleteProductField from '../api/product-gaps/delete-product-field';
import updateProductGap from '../api/product-gaps/update-product-gap';

interface State {
  productGaps: ProductGap[];
  productFields: ProductField[];
  productCategories: ProductCategory[];
  categoryStatuses: ProductCategoryStatus[];
  productGapId: string;
  productCategoryId: string;
  isLoading: boolean;
  loaded: boolean;
  productFieldsFetchedTime: number;
  fetch: (fetchStatus: boolean) => Promise<void>;
  getProductGap: (productGapId: string) => Promise<ProductGap>;
  createGap: (
    categoryId: string | null,
    documentId: string | null,
    productFieldAnswers: { [fieldId: string]: string },
    integrationId: string | null,
    suggestedProductFeedbackId?: string,
  ) => Promise<ProductGap>;
  deleteProductGap: (productGapId: string) => Promise<void>;
  updateProductGap: (
    productGapId: string,
    documentId: string | null,
    productCategoryId: string | null,
    fieldAnswers: { [fieldId: string]: string },
    integrationId: string | null,
  ) => Promise<ProductGap>;
  syncGap: (gapId: string) => Promise<ProductGap>;
  createCategory: (title: string, description: string, statusId: string) => Promise<ProductCategory>;
  deleteCategory: (id: string) => Promise<void>;
  updateCategory: (id: string, title: string, description: string, statusId: string) => Promise<ProductCategory>;
  createCategoryStatus: (name: string) => Promise<ProductCategoryStatus>;
  deleteCategoryStatus: (id: string) => Promise<void>;
  updateCategoryStatus: (id: string, name: string) => Promise<ProductCategoryStatus>;
  getProductFields: () => Promise<ProductField[]>;
  createProductField: (productFieldType: ProductFieldType) => Promise<ProductField>;
  updateProductField: (newProductField: ProductField) => Promise<void>;
  updateProductFieldRows: (productFields: { id: string; row: number }[]) => Promise<void>;
  deleteProductField: (productFieldId: string) => Promise<void>;
  setState: (newState: Partial<State>) => State;
}

const useProductGapStore = create<State>((set, get) => ({
  productGaps: [],
  productFields: [],
  productCategories: [],
  categoryStatuses: [],
  productGapId: '',
  productCategoryId: '',
  isLoading: false,
  loaded: false,
  productFieldsFetchedTime: 0,
  getProductFields: async () => {
    const currentTime = new Date().getTime();
    // Fetch product fields if they haven't been fetched in the last minute
    if (currentTime - get().productFieldsFetchedTime > 60000) {
      const productFields = await getProductFields();
      set(prev => ({ ...prev, productFields, productFieldsFetchedTime: new Date().getTime() }));
    }
    return get().productFields;
  },
  fetch: async fetchStatus => {
    set(prev => ({
      ...prev,
      isLoading: true,
      loaded: false,
    }));
    const productGaps = await getProductGaps();
    get().getProductFields();
    const productCategories = await getProductCategories();
    const categoryStatuses = fetchStatus ? await getProductCategoryStatuses() : [];
    set(prev => {
      return {
        ...prev,
        productGaps,
        productCategories,
        categoryStatuses,
        isLoading: false,
        loaded: true,
      };
    });
  },
  getProductGap: async (productGapId: string) => {
    const productGap = await getProductGap(productGapId);
    let found = false;
    const productGaps = get().productGaps.map(gap => {
      if (gap.id === productGapId) {
        found = true;
        return productGap;
      }
      return gap;
    });

    if (!found) {
      productGaps.push(productGap);
    }
    set(prev => {
      return {
        ...prev,
        productGaps,
      };
    });
    return productGap;
  },
  createGap: async (categoryId, documentId, productFieldAnswers, integrationId, suggestedProductFeedbackId?) => {
    const productGap = await createProductGap(
      categoryId,
      documentId,
      productFieldAnswers,
      integrationId,
      suggestedProductFeedbackId,
    );
    set(prev => {
      return {
        ...prev,
        productGaps: [...prev.productGaps, productGap],
      };
    });
    return productGap;
  },
  deleteProductGap: async (productGapId: string) => {
    await deleteProductGap(productGapId);
    set(prev => {
      return {
        ...prev,
        productGaps: prev.productGaps.filter(gap => gap.id !== productGapId),
      };
    });
  },
  updateProductGap: async (productGapId, documentId, productCategoryId, fieldAnswers, integrationId) => {
    const productGap = await updateProductGap(productGapId, documentId, productCategoryId, fieldAnswers, integrationId);
    set(prev => {
      return {
        ...prev,
        productGaps: prev.productGaps.map(gap => {
          if (gap.id === productGapId) {
            return productGap;
          }
          return gap;
        }),
      };
    });
    return productGap;
  },
  syncGap: async (gapId: string) => {
    const productGap = await syncProductGap(gapId);
    set(prev => {
      return {
        ...prev,
        productGaps: prev.productGaps.map(gap => {
          if (gap.id === gapId) {
            return productGap;
          }
          return gap;
        }),
      };
    });
    return productGap;
  },
  createCategory: async (title: string, description: string, statusId: string) => {
    const productCategory = await createProductCategory(title, description, statusId);
    set(prev => {
      return {
        ...prev,
        productCategories: [...prev.productCategories, productCategory],
      };
    });
    return productCategory;
  },
  deleteCategory: async (id: string) => {
    await deleteProductCategory(id);
    set(prev => {
      return {
        ...prev,
        productCategories: prev.productCategories.filter(category => category.id !== id),
      };
    });
  },
  updateCategory: async (id, title, description, statusId) => {
    const category = await updateProductCategory(id, title, description, statusId);
    set(prev => {
      return {
        ...prev,
        productCategories: prev.productCategories.map(gap => {
          if (gap.id === id) {
            return category;
          }
          return gap;
        }),
        productGaps: prev.productGaps.map(gap => {
          if (gap.category?.id === id) {
            return {
              ...gap,
              category,
            };
          }
          return gap;
        }),
      };
    });
    return category;
  },
  createCategoryStatus: async (name: string) => {
    const status = await createProductCategoryStatus(name);
    set(prev => {
      return {
        ...prev,
        categoryStatuses: [...prev.categoryStatuses, status],
      };
    });
    return status;
  },
  deleteCategoryStatus: async (id: string) => {
    await deleteProductCategoryStatus(id);
    set(prev => {
      return {
        ...prev,
        categoryStatuses: prev.categoryStatuses.filter(item => item.id !== id),
        productCategories: prev.productCategories.map(item => {
          if (item.status?.id === id) {
            return {
              ...item,
              status: undefined,
            };
          }
          return item;
        }),
        productGaps: prev.productGaps.map(item => {
          if (item.category?.status?.id === id) {
            return {
              ...item,
              category: {
                ...item.category,
                status: undefined,
              },
            };
          }
          return item;
        }),
      };
    });
  },
  updateCategoryStatus: async (id: string, name: string) => {
    const status = await updateProductCategoryStatus(id, name);
    set(prev => {
      return {
        ...prev,
        categoryStatuses: prev.categoryStatuses.map(item => {
          if (item.id === id) {
            return status;
          }
          return item;
        }),
        productCategories: prev.productCategories.map(item => {
          if (item.status?.id === id) {
            return {
              ...item,
              status,
            };
          }
          return item;
        }),
        productGaps: prev.productGaps.map(item => {
          if (item.category?.status?.id === id) {
            return {
              ...item,
              category: {
                ...item.category,
                status,
              },
            };
          }
          return item;
        }),
      };
    });
    return status;
  },
  createProductField: async (productFieldType: ProductFieldType) => {
    const productField = await createProductField(productFieldType);
    set(prev => {
      return {
        ...prev,
        productFields: [...prev.productFields, productField],
      };
    });
    return productField;
  },
  updateProductField: async newProductField => {
    const productField = await updateProductField(
      newProductField.id,
      newProductField.title,
      newProductField.defaultValue,
      newProductField.isMandatory,
      newProductField.additionalData,
    );
    set(prev => {
      return {
        ...prev,
        productFields: prev.productFields.map(field => {
          if (field.id === productField.id) {
            return productField;
          }
          return field;
        }),
      };
    });
  },
  updateProductFieldRows: async (productFields: { id: string; row: number }[]) => {
    const newProductFields = await updateProductFieldsRow(productFields);
    set(prev => {
      return {
        ...prev,
        productFields: newProductFields,
      };
    });
  },
  deleteProductField: async (productFieldId: string) => {
    await deleteProductField(productFieldId);
    set(prev => {
      return {
        ...prev,
        productFields: prev.productFields.filter(field => field.id !== productFieldId),
      };
    });
  },
  setState: (newState: Partial<State>) => {
    set(state => {
      return { ...state, ...newState };
    });
    return get();
  },
}));

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('Product Gap', useProductGapStore);
}

export default useProductGapStore;
