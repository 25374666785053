import React from 'react';
import { ReactEditor } from 'slate-react';
import NoteEditor from '../NoteEditor';
import { CustomElement } from '../types';
import { Editor } from 'slate';
import { concat } from 'utils/styling';
import { useDroppable } from '@dnd-kit/core';
import styles from './MarginElementBottom.module.css';
import constants from 'utils/constants';

interface Props {
  editor: Editor;
  className?: string;
}

function MarginElementBottom({ editor, className }: Props) {
  const { isOver, setNodeRef } = useDroppable({
    id: constants.EDITOR_BOTTOM_SECTION_ID,
  });
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      ref={setNodeRef}
      className={concat(styles.bottomSection, 'editor-bottom-section', isOver && styles.dragOver, className)}
      onClick={() => {
        if (NoteEditor.sp_isLastBlockEmpty(editor) === false) {
          // add an empty block
          NoteEditor.sp_addEmptyBlock(editor, editor.children.at(-1) as CustomElement);
          NoteEditor.sp_focusEditor(editor);
          NoteEditor.sp_selectLastBlock(editor);
        } else if (NoteEditor.sp_isLastBlockEmpty(editor) === true) {
          NoteEditor.sp_focusEditor(editor);
          ReactEditor.deselect(editor);
          NoteEditor.sp_selectLastBlock(editor);
        }
      }}
    />
  );
}

MarginElementBottom.defaultProps = {
  className: '',
};

export default MarginElementBottom;
