import React from 'react';

interface Props {
  className?: string;
}
function FilterIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 5H4.5L10.7 12.358V17.4445L13.8 19V12.358L20 5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

FilterIcon.defaultProps = {
  className: undefined,
};

export default FilterIcon;
