import me from 'api/me';
import ResponseView from 'components/ResponseView';
import EmptyHomeIcon from 'components/icons/EmptyHomeIcon';
import React, { useEffect, useMemo } from 'react';
import useSideMenuStore from 'stores/side-menu';
import useUserStore from 'stores/user';

function EmptyPage() {
  const sideMenuStore = useSideMenuStore();
  const userStore = useUserStore();

  useEffect(() => {
    // Every 5 second we fetch user from backend to check if onboarding is completed
    const fetchMe = async (): Promise<void> => {
      try {
        const { user } = await me();
        userStore.setState({ user });
      } catch {
        /* empty */
      }
    };

    const intervalId = setInterval(() => {
      if (
        userStore.user?.isFetchingSixMonthsCalendarEventHistory ||
        userStore.user?.isFetchingSalesforceDataForOnboarding ||
        userStore.user?.isFetchingRecordingCallsForOnboarding ||
        userStore.user?.isFetchingSixMonthsRecordingCallsForOnboarding
      )
        fetchMe();
    }, 5000);
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStore.user]);

  useEffect(() => {
    if (sideMenuStore.calendarMenu === null && sideMenuStore.noteMenu === null) {
      sideMenuStore.setState({
        calendarMenu: 'fixed',
        noteMenu: null,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onboardingInfo: string[] = [];
  if (userStore.user?.isFetchingSixMonthsCalendarEventHistory) {
    onboardingInfo.push('We are importing your calendar information');
  }
  if (userStore.user?.isFetchingSalesforceDataForOnboarding) {
    onboardingInfo.push('We are importing your recent accounts and opportunities');
  }
  if (userStore.user?.isFetchingRecordingCallsForOnboarding) {
    onboardingInfo.push('We are importing your recent calls');
  }
  if (userStore.user?.isFetchingSixMonthsRecordingCallsForOnboarding) {
    onboardingInfo.push('We are importing your calls for the last 6 months');
  }

  const isLoading = useMemo(
    () =>
      !!userStore.user?.isFetchingSixMonthsCalendarEventHistory ||
      !!userStore.user?.isFetchingSalesforceDataForOnboarding ||
      !!userStore.user?.isFetchingRecordingCallsForOnboarding ||
      !!userStore.user?.isFetchingSixMonthsRecordingCallsForOnboarding,
    [userStore.user],
  );

  return (
    <div className="h-full flex flex-col">
      <div className="w-full h-20 border-b" />
      <div className="flex-1" />
      <ResponseView
        className="flex-1"
        loading={isLoading}
        noData={!onboardingInfo.length}
        noDataIcon={<EmptyHomeIcon className="max-w-[80%] h-auto" />}
        noDataDesc={<span className="text-gray-400">Select a meeting or document on the left</span>}
        loadingExtra={
          <div className="flex justify-center items-center flex-col gap-4 text-gray-600">
            {onboardingInfo.map(info => (
              <span key={info} className=" text-gray-600">
                {info}
              </span>
            ))}
          </div>
        }
      />
      <div className="flex-3" />
    </div>
  );
}

export default EmptyPage;
