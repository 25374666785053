/* eslint-disable react/jsx-props-no-spreading */

import typoStyles from 'components/typo.module.css';
import { Emoji } from 'emoji-picker-react';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { concat } from 'utils/styling';
import SpinLoader from 'components/icons/SpinLoader';
import useDocumentStore from 'stores/document';
import useModalStore from 'stores/modal';
import LinkBrokenIcon from 'components/icons/LinkBrokenIcon';
import useTemplateStore from 'stores/templates';
import { CustomRenderElementProps, NoteTemplateType } from '../types';

import styles from './EmptyElement.module.css';

interface TemplateButtonProps {
  text: string;
  icon: string | null;
  onClick: () => void;
  templateCopy: string | null;
}

function TemplateButton({ text, icon, onClick, templateCopy }: TemplateButtonProps) {
  return (
    <button
      className={`${styles.button} text-sm flex items-start gap-2  ${
        templateCopy ? 'text-gray-400 cursor-default hover:bg-gray-100' : 'text-gray-600 hover:bg-gray-100'
      }`}
      type="button"
      onClick={onClick}
    >
      {icon ? <Emoji unified={icon} size={20} /> : <Emoji unified="1f5d2-fe0f" size={20} />}
      <div className="flex flex-wrap">
        <span>{text}</span>
        {templateCopy && <span className="text-gray-300">&nbsp;{templateCopy}</span>}
      </div>
    </button>
  );
}

interface State {
  isTemplateLoading: boolean;
}

interface Props extends CustomRenderElementProps {
  applyTemplate?: (type: NoteTemplateType, id: string) => Promise<void>;
  openApplyTemplateModal?: () => void;
}

function EmptyElement({
  applyTemplate,
  openApplyTemplateModal,
  emptyParagraphPlaceholder,
  attributes,
  children,
  documentId,
}: Props) {
  const [state, setState] = useState<State>({
    isTemplateLoading: false,
  });
  const modalStore = useModalStore();

  const documentStore = useDocumentStore();
  const templateStore = useTemplateStore();
  const doc = useMemo(
    () => documentStore.documents.find(d => d.id === documentId),
    [documentStore.documents, documentId],
  );

  const fetchTemplates = async () => {
    setState(prevState => ({ ...prevState, isTemplateLoading: true }));
    await templateStore.fetch();
    setState(prevState => ({ ...prevState, isTemplateLoading: false }));
  };

  useEffect(() => {
    if (applyTemplate) {
      fetchTemplates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let templateElement: null | ReactNode = null;

  if (applyTemplate) {
    templateElement = (
      <div className={concat(styles.container)} contentEditable={false}>
        {templateStore.templates.length > 0 && !state.isTemplateLoading && (
          <div className={styles.template}>
            <div className={concat(typoStyles['text-grey-400'], 'mt-3')}>My recent templates</div>
            {templateStore.templates.slice(0, 8).map(template => {
              let templateCopy: null | string = null;
              let popupCopy: null | JSX.Element = null;
              if (doc) {
                if (doc.parent?.entityName === 'Account' && template.opportunityFieldCount > 0) {
                  templateCopy = '(Link this Document to an Opportunity to use this Template)';
                  popupCopy = (
                    <div className="flex flex-col gap-2 text-justify	">
                      <span>
                        This template contains opportunity fields, and is only available in documents under an
                        opportunity
                      </span>
                      <span>You are trying to use it for an account document</span>
                    </div>
                  );
                } else if (doc.parent === null && template.opportunityFieldCount > 0) {
                  templateCopy = '(Link this Document to an Opportunity to use this Template)';
                  popupCopy = (
                    <div className="flex flex-col gap-2 text-justify">
                      <span>
                        This template contains opportunity fields, and is only available in documents under an
                        opportunity
                      </span>
                      <span>You are trying to use it for a non CRM document</span>
                    </div>
                  );
                } else if (doc.parent === null && template.accountFieldCount > 0) {
                  templateCopy = '(Link this Document to an Account or Opportunity to use this Template)';
                  popupCopy = (
                    <div className="flex flex-col text-justify">
                      <span>
                        This template contains account fields, and is only available in documents under an account or
                        opportunity
                      </span>
                    </div>
                  );
                }
              }
              return (
                <TemplateButton
                  key={template.noteTemplateId}
                  text={template.name}
                  icon={template.icon}
                  onClick={() => {
                    if (templateCopy !== null) {
                      modalStore.setState({
                        isOpen: true,
                        element: (
                          <div className="w-[30rem] py-7 px-10">
                            <div className="flex flex-col gap-3">
                              <LinkBrokenIcon className="w-7 h-7 text-orange-500 stroke-2" />
                              {popupCopy}
                            </div>
                          </div>
                        ),
                      });
                    } else {
                      applyTemplate(NoteTemplateType.CUSTOM, template.noteTemplateId);
                    }
                  }}
                  templateCopy={templateCopy}
                />
              );
            })}
          </div>
        )}
        {state.isTemplateLoading && (
          <div>
            <SpinLoader className="animate-spin w-6 h-6 text-orange-500" />
          </div>
        )}
      </div>
    );
  }
  let allTemplateButton: null | ReactNode = null;

  if (openApplyTemplateModal) {
    allTemplateButton = (
      <div className="mt-4 w-full" contentEditable={false}>
        <button
          type="button"
          className="flex justify-start text-gray-400 py-1 w-full hover:bg-gray-100 rounded"
          onClick={openApplyTemplateModal}
        >
          See all templates
        </button>
      </div>
    );
  }

  return (
    <div className="px-[4rem] empty-element">
      <div
        className={styles['empty-paragraph']}
        style={
          {
            '--emptyParagraphPlaceholder': `"${emptyParagraphPlaceholder} "`,
          } as React.CSSProperties
        }
      >
        <div {...attributes}>{children}</div>
      </div>
      {templateElement}
      {allTemplateButton}
    </div>
  );
}

EmptyElement.defaultProps = {
  applyTemplate: undefined,
  openApplyTemplateModal: undefined,
};

export default EmptyElement;
