import React from 'react';
import { currencyFormatter } from 'utils/number-formatter';
import { Calculation, ColumnDataType } from '../types';
import { getValue, isEmpty } from '../utils';
import useReportingStore from '../../../stores/reporting';

interface Props {
  groupId: string | undefined;
  columnId: string;
  dataType: ColumnDataType;
  calculation: Calculation | undefined;
  initialValues: any[];
}

function CalculationAnswer({ groupId, columnId, dataType, calculation, initialValues }: Props) {
  const reportingStore = useReportingStore();
  if (['ReportField', 'ReportFieldAnswer', 'ReportEntityField', 'ReportCalculatedField'].includes(dataType)) {
    if (groupId && groupId in reportingStore.tableCalculation && columnId in reportingStore.tableCalculation[groupId]) {
      return <span>{reportingStore.tableCalculation[groupId][columnId]}</span>;
    }
    return <span />;
  }

  const values = initialValues.map(v => getValue(dataType, v));
  let answer = '';
  switch (calculation) {
    case 'Count all':
      answer = `${values.length}`;
      break;
    case 'Count empty':
      answer = `${values.filter(value => isEmpty(value)).length}`;
      break;
    case 'Count not empty':
      answer = `${values.filter(value => !isEmpty(value)).length}`;
      break;
    case 'Percent empty': {
      const empty = values.filter(value => isEmpty(value)).length;
      const total = values.length;
      if (total > 0) answer = `${Math.round((empty / total) * 100)}%`;
      else answer = '0%';
      break;
    }
    case 'Percent not empty': {
      const empty = values.filter(value => !isEmpty(value)).length;
      const total = values.length;
      if (total > 0) answer = `${Math.round((empty / total) * 100)}%`;
      else answer = '0%';
      break;
    }
    case 'Sum': {
      const totalSum = values.reduce((sum, value) => {
        if (typeof value === 'number') return Number(sum) + Number(value);
        return sum;
      });
      if (totalSum) answer = `${totalSum.toFixed(2)}`;
      if (dataType === 'Currency') answer = `${currencyFormatter.format(totalSum)}`;
      break;
    }
    case 'Average': {
      const totalSum = values.reduce((sum, value) => {
        if (typeof value === 'number') return Number(sum) + Number(value);
        return sum;
      });
      let average = 0;
      if (totalSum) {
        const { length } = values.filter(value => typeof value === 'number');
        answer = '0';
        if (length > 0) {
          average = totalSum / length;
          answer = `${average.toFixed(2)}`;
        }
      }
      if (dataType === 'Currency') answer = `${currencyFormatter.format(average)}`;
      break;
    }
    case 'Median': {
      if (values.length <= 0) break;

      const sorted = values
        .filter(value => typeof value === 'number')
        .sort((a, b) => {
          if (typeof a === 'number' && typeof b === 'number') return a - b;
          return a;
        });
      const middle = Math.floor(sorted.length / 2);
      if (sorted.length % 2 === 0) {
        answer = `${(sorted[middle - 1] + sorted[middle]) / 2}`;
        break;
      }
      answer = `${sorted[middle]}`;
      break;
    }
    case 'Min': {
      const min = Math.min(...values.filter(value => typeof value === 'number'));
      answer = `${min.toFixed(2)}`;
      if (dataType === 'Currency') answer = `${currencyFormatter.format(min)}`;
      break;
    }
    case 'Max': {
      const max = Math.max(...values.filter(value => typeof value === 'number'));
      answer = `${max.toFixed(2)}`;
      if (dataType === 'Currency') answer = `${currencyFormatter.format(max)}`;
      break;
    }
    case 'Count True':
      answer = `${values.filter(value => value === true).length}`;
      break;
    case 'Count False':
      answer = `${values.filter(value => value === false).length}`;
      break;
    case 'Percent True': {
      const totalTrue = values.filter(value => value === true).length;
      const total = values.length;
      if (total > 0) answer = `${Math.round((totalTrue / total) * 100)}%`;
      else answer = '0%';
      break;
    }
    case 'Percent False': {
      const totalTrue = values.filter(value => value === false).length;
      const total = values.length;
      if (total > 0) answer = `${Math.round((totalTrue / total) * 100)}%`;
      else answer = '0%';
      break;
    }
    default:
      break;
  }
  return <span>{answer}</span>;
}

export default CalculationAnswer;
