/* eslint-disable no-use-before-define */
import { YjsEditor } from '@slate-yjs/core';
import { BaseEditor, BaseOperation, Descendant, Editor } from 'slate';
import { ReactEditor, RenderElementProps } from 'slate-react';
import { Member } from 'types/member';

export type DocSource = 'main-doc' | 'main-doc-comment' | 'template';

export enum NoteCopyPasteFormat {
  markdown = 'text/plain',
  html = 'text/html',
  superpanel = 'text/superpanel-clipboard',
}

export enum NoteTemplateType {
  BLANK = 'BLANK',
  CUSTOM = 'CUSTOM',
}

export type CustomText = {
  text: string;
  code?: boolean;
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  strikethrough?: boolean;
  caret?: {
    name: string;
  };
  alphaColor?: string;
  comment?: string;
  commentInit?: boolean;
};

export type HotKeyIndent = 'indent' | 'outdent';

export enum BlockType {
  Paragraph = 'paragraph',
  H1 = 'heading-1',
  H2 = 'heading-2',
  H3 = 'heading-3',
  BulletedList = 'bulleted-list',
  OrderedList = 'ordered-list',
  DiscoveryQuestion = 'discovery-question',
  ProductGap = 'product-gap',
  Image = 'image',
  Table = 'table',
  TableRow = 'table-row',
  TableCell = 'table-cell',
  TableDialog = 'table-dialog',
  Task = 'task',
  AIWorker = 'ai-worker',
}

export enum BlockTypeInline {
  Link = 'link',
  Mention = 'mention',
}

export type BlockTypeAll = BlockType | BlockTypeInline;

export const INDENTABLE_TYPES = [
  BlockType.Paragraph,
  BlockType.H1,
  BlockType.H2,
  BlockType.H3,
  BlockType.BulletedList,
  BlockType.OrderedList,
];

export const NON_TEXT_TYPES = [
  BlockType.DiscoveryQuestion,
  BlockType.ProductGap,
  BlockType.Image,
  BlockType.Table,
  BlockType.Task,
  BlockType.AIWorker,
  BlockType.TableDialog,
];

export type CommentContent = (CustomText | LinkElement | MentionElement)[];

export type CommentPopupResponseType = null | CommentRecords;

export interface CommentRecord {
  userId: string;
  createdDate: string;
  updatedDate: string;
  content: CommentContent;
  resolved?: boolean;
}

export type CommentRecords = { [commentId: string]: CommentRecord[] };

export const COMMENTABLE_VOID_BLOCKS: BlockTypeAll[] = [
  BlockType.DiscoveryQuestion,
  BlockType.ProductGap,
  BlockType.Image,
  BlockType.Task,
  BlockType.AIWorker,
];

export type BlockElement = {
  uuid?: string;
  type: BlockTypeAll;
  children: CustomText[];
  indentLevel?: number;
  comments?: CommentRecords;
  commentInit?: boolean;
};

export type MentionPopupResponseType = null | undefined | Member; // null means clear, undefined means not select

export type MentionElement = BlockElement & {
  mentionUserId: string;
  mentionId: string;
  mentionByUserId?: string;
  beingEditedBy?: string;
};

export type ListElement = Omit<BlockElement, 'type' | 'indentLevel'> & {
  type: BlockType.OrderedList | BlockType.BulletedList;
  indentLevel: number;
};

export type DiscoveryQuestionElement = BlockElement & {
  discoveryQuestionId: null | string;
};

export type ProductGapElement = BlockElement & {
  productGapId: string | null;
  defaultFeedback?: string | null;
  defaultTitle?: string | null;
  // some old ProductGapElement has startTime and recordingId in slate, we can do migration, but it's an overkill
  startTime?: number | null;
  recordingId?: string | null;
};

export type LinkElement = BlockElement & {
  link: string;
  beingEditedBy: string | null;
};

export type ImageElement = BlockElement & {
  fileId?: string;
  url?: string;
  isInput?: boolean;
  fileName?: string;
  width?: number;
};

export type TableCellElement = BlockElement & {
  children: CustomText[];
  width?: number;
};

export type TableRowElement = BlockElement & {
  children: TableCellElement[];
};

export type TableElement = BlockElement & {
  children: TableRowElement[];
};

export type TableDialogElement = BlockElement & {
  tableDialogId?: string;
};

export type TaskElement = BlockElement & {
  taskId: string;
  beingCreatedBy?: string;
  initContent?: Descendant[];
};

export type AIWorkerElement = BlockElement & {
  aiWorkerId: string;
  beingCreatedBy?: string;
  initContent?: Descendant[];
};

export type CustomElement =
  | BlockElement
  | ListElement
  | DiscoveryQuestionElement
  | LinkElement
  | ProductGapElement
  | ImageElement
  | TableElement
  | TableDialogElement
  | MentionElement
  | TaskElement
  | AIWorkerElement;

export interface CustomEditor extends ReactEditor, YjsEditor {
  children: CustomElement[];
  sp_uuid: string;
  editor_uuid: string;
}

export interface CustomRenderElementProps extends RenderElementProps {
  editor: Editor;
  element: CustomElement;
  blockPlaceholder?: string;
  emptyParagraphPlaceholder?: string;
  documentId: string | null;
  opportunityId: string | null;
}

declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor & CustomEditor;
    Element: CustomElement;
    Text: CustomText;
    Operation: BaseOperation & { isRemote?: boolean };
  }
}

export interface RootElement {
  children: CustomElement[];
}
