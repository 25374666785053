import React, { useState } from 'react';
import FolderSelect from './FolderSelect';
import useModalStore from '../stores/modal';
import useDocumentStore from '../stores/document';
import { useNavigate } from 'react-router-dom';
import SpinLoader from './icons/SpinLoader';

export interface NewDocumentModalRet {
  title: string | null;
  parentId: string | null;
}
export interface NewDocumentModalProps {
  onConfirm: (ret: NewDocumentModalRet) => Promise<void>;
  initValue: NewDocumentModalRet;
}
function NewDocumentModal({ onConfirm, initValue }: NewDocumentModalProps) {
  const modalStore = useModalStore();
  const [ret, setRet] = useState<NewDocumentModalRet>({
    title: initValue.title,
    parentId: initValue.parentId,
  });
  const [isLoading, setIsLoading] = useState(false);
  return (
    <div className="w-[40rem]">
      <div className="border-b p-8 pb-4 pt-4">
        <div className="text-xl font-bold text-gray-700 mb-8 mt-4">New Document</div>
        <input
          className="w-full text-sm placeholder:text-gray-400"
          placeholder="Title..."
          value={ret.title || ''}
          onChange={e =>
            setRet(prevState => ({
              ...prevState,
              title: e.target.value,
            }))
          }
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        />
      </div>

      <div className="border-b">
        <FolderSelect
          className="px-8 py-4 flex w-full"
          documentId="0"
          entityDocumentId={ret.parentId}
          setLoading={() => undefined}
          onSelected={id => setRet(p => ({ ...p, parentId: id }))}
        />
      </div>

      <div className="mt-4 p-8 pt-2 pb-4 flex flex-row justify-around">
        <button
          onClick={() => modalStore.close()}
          type="button"
          className="px-4 py-2 bg-gray-100 hover:bg-gray-200 text-gray-500 rounded"
        >
          Cancel
        </button>
        <button
          onClick={async () => {
            setIsLoading(true);
            await onConfirm({ ...ret }).then(() => modalStore.close());
            setIsLoading(false);
          }}
          type="button"
          className="w-24 px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded"
          disabled={isLoading}
        >
          {isLoading ? <SpinLoader className="w-4 h-4 text-orange-500 animate-spin" /> : <span>Create</span>}
        </button>
      </div>
    </div>
  );
}

export function useNewDocumentModal() {
  const modalStore = useModalStore();
  const documentStore = useDocumentStore();
  const navigate = useNavigate();

  const createNewDocumentWithModal = (initValue?: NewDocumentModalRet) => {
    modalStore.setState({
      isOpen: true,
      element: (
        <NewDocumentModal
          initValue={initValue || { title: null, parentId: null }}
          onConfirm={async ret => {
            try {
              const document = await documentStore.createDocument(ret.parentId);
              if (ret.title) {
                await documentStore.updateDocumentData(document.id, { docTitle: ret.title });
              }
              navigate(`/document/${document.id}`);
            } catch (e) {
              /* empty */
            }
          }}
        />
      ),
    });
  };

  return { createNewDocumentWithModal };
}

export default NewDocumentModal;
