import React, { useEffect, useRef } from 'react';

import SpinLoader from 'components/icons/SpinLoader';

import useFieldValuesStore from 'stores/field-values';

import AnswerView from './FieldValueView/AnswerView';

import QuestionTitle from './FieldValueView/QuestionTitle';
import { FieldValue } from '../types/field-value';
import AccountIcon from './icons/AccountIcon';
import OpportunityIcon from './icons/OpportunityIcon';
import SuggestedAnswersView from './FieldValueView/SuggestedAnswersView';

interface Props {
  fieldValue: FieldValue | undefined;
  from: 'discoveryQuestionContextPanel' | 'docEditor' | 'reporting';
  onEnter?: () => void;
  onChange?: (fieldValue: FieldValue) => void;
  hideHeading?: boolean;
  autoFocus?: boolean;
}

function FieldValueView({ fieldValue, from, onEnter, onChange, hideHeading = false, autoFocus }: Props) {
  // this contains local state of the discoveryQuestions
  // the local and global doesn't fully match
  // some places we use local, and some other global

  const fieldValuesStore = useFieldValuesStore();

  const editor = useRef<React.ElementRef<typeof AnswerView>>(null);
  const firstAiStatusCheck = useRef(true);
  useEffect(() => {
    if (fieldValue && ['new', 'pending'].includes(fieldValue?.field.initializationJobStatus)) {
      const interval = setInterval(() => {
        fieldValuesStore.fetchFieldValue(fieldValue.id);
      }, 4000);

      return () => clearInterval(interval);
    }
    return () => null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldValue?.field.initializationJobStatus]);

  useEffect(() => {
    if (firstAiStatusCheck.current) {
      firstAiStatusCheck.current = false;
      return;
    }
    if (!fieldValue?.isAiProcessing && fieldValue?.id) {
      fieldValuesStore.fetchFieldValue(fieldValue.id, false);
    }
  }, [fieldValue?.isAiProcessing]);

  if (fieldValuesStore.isLoading) {
    return (
      <div className="w-full h-full flex justify-center items-center mb-10">
        <SpinLoader className="animate-spin w-6 h-6 text-orange-500" />
      </div>
    );
  }

  if (!fieldValue) {
    return (
      <div className="flex gap-4 w-full">
        <div className="text-gray-300 px-2 my-2">This field has been deleted, please remove</div>
      </div>
    );
  }

  if (['new', 'pending'].includes(fieldValue.field.initializationJobStatus)) {
    return (
      <div className="flex items-center text-gray-400 p-2 my-2">
        <SpinLoader className="animate-spin w-6 h-6 mr-2" />
        <span>Please wait, the field &quot;{fieldValue.field.label}&quot; is being initialized</span>
      </div>
    );
  }

  const { isAiProcessing } = fieldValue;
  return (
    <div className="pt-1 relative pb-2">
      {!hideHeading && (
        <div className="mb-3">
          <div className="flex gap-2 w-full">
            <div className="flex flex-wrap items-center">
              <QuestionTitle field={fieldValue.field} />
            </div>
          </div>
          {fieldValue.document.entity && fieldValue.field.parentId === null && (
            <div className="flex items-center mt-1">
              {fieldValue.document.entity.name.toLowerCase() === 'account' ? (
                <AccountIcon className="min-w-[1rem] w-4 h-4 text-blue-500 mr-2" />
              ) : (
                <OpportunityIcon className="min-w-[1rem] w-4 h-4 fill-orange-400 text-orange-400 mr-2" />
              )}
              <div className="text-xs text-gray-400">{fieldValue.document.entity.name}</div>
            </div>
          )}
        </div>
      )}
      <div>
        <div className="flex flex-col">
          <AnswerView
            ref={editor}
            fieldValue={fieldValue}
            onEnter={onEnter}
            onChange={onChange}
            autoFocus={autoFocus}
          />
          {isAiProcessing && (
            <div className="flex mt-1">
              <div className="flex text-gray-500 bg-blue-50 px-2 py-1 rounded items-center gap-2">
                <span className="text-xs">✨ AI is processing recordings</span>
                <SpinLoader className="animate-spin w-4 h-4 text-blue-600" />
              </div>
            </div>
          )}
          <SuggestedAnswersView
            fieldValue={fieldValue}
            suggestedAnswers={fieldValue.suggestedAnswers}
            updateAnswer={async answer => {
              if (editor.current) {
                await editor.current.updateAnswer(answer);
              }
            }}
            from={from}
          />
        </div>
      </div>
    </div>
  );
}

FieldValueView.defaultProps = {
  onEnter: undefined,
  onChange: undefined,
  hideHeading: false,
  autoFocus: undefined,
};

export default FieldValueView;
