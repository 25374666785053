import httpClient from '../http-client';
import { ProductField, ProductFieldAdditionalData } from '../../types/product-gap';

export default async (
  productFieldId: string,
  title: string,
  defaultValue: string,
  isMandatory: boolean,
  additionalData: ProductFieldAdditionalData,
): Promise<ProductField> => {
  const { data } = await httpClient.put<ProductField>(`/product-fields/${productFieldId}`, {
    title,
    defaultValue,
    isMandatory,
    additionalData,
  });
  return data;
};
