import httpClient from 'api/http-client';

export interface User {
  id: string;
  name: string;
  calendarEmail: string;
  role: string;
  teamId: string;
  lastLoginAt: string;
  crmEmail: string;
}

export default async (): Promise<User[]> => {
  const { data } = await httpClient.get<User[]>('/admin/users');
  return data;
};
