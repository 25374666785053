import { CrmNote, Document } from 'types/document';
import { EntityDocument } from 'types/entity';
import httpClient from '../http-client';
import { ReportingFilter } from '../../types/reporting';

interface Response {
  entities: EntityDocument[];
  documents: Document[];
  crmNotes: CrmNote[];
}

export default async (
  currentPageDocumentId: undefined | string,
  filters: ReportingFilter[],
  showOpportunityOnly: boolean,
): Promise<Response> => {
  const { data } = await httpClient.post<Response>(`/documents/filter`, {
    filters,
    currentPageDocumentId,
    showOpportunityOnly,
  });

  return data;
};
