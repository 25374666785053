import React from 'react';

interface Props {
  className?: string;
}

function TransferArrowIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="100"
      height="24"
      viewBox="0 0 67 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M58.5 8L53.5 5.11325V10.8868L58.5 8ZM8.5 8.5H54V7.5H8.5V8.5Z" fill="#E6E9F0" />
    </svg>
  );
}

TransferArrowIcon.defaultProps = {
  className: undefined,
};

export default TransferArrowIcon;
