import React from 'react';
import { ColumnDefinition, ColumnFilter } from '../types';
import SortAscendingColumnMenu from './ColumnMenus/SortAscendingColumnMenu';
import SortDescendingColumnMenu from './ColumnMenus/SortDescendingColumnMenu';
import FilterColumnMenu from './ColumnMenus/FilterColumnMenu';
import HideColumnMenu from './ColumnMenus/HideColumnMenu';
import DeleteReportingAnalyticColumnMenu from './ColumnMenus/DeleteReportingAnalyticColumnMenu';
import EditAnalyticColumnMenu from './ColumnMenus/EditAnalyticColumnMenu';
import ClearSortColumnMenu from './ColumnMenus/ClearSortColumnMenu';
import PinLeftColumnMenu from './ColumnMenus/PinLeftColumnMenu';

interface Props {
  columnDefinition: ColumnDefinition;
  onSortUpdate: (sort: 'ASC' | 'DESC' | undefined) => void;
  onColumnHide: () => void;
  setMenuState: (menuState: 'MENU' | 'FILTER' | 'HIDE') => void;
  onFilterUpdate: (filter?: ColumnFilter) => void;
  onColumnPin: () => void;
}

function ColumnMenus({
  columnDefinition,
  onSortUpdate,
  onColumnHide,
  setMenuState,
  onFilterUpdate,
  onColumnPin,
}: Props) {
  switch (columnDefinition.type) {
    case 'ReportEntityField': {
      return (
        <>
          <ClearSortColumnMenu columnDefinition={columnDefinition} onSortUpdate={onSortUpdate} />
          <SortAscendingColumnMenu columnDefinition={columnDefinition} onSortUpdate={onSortUpdate} />
          <SortDescendingColumnMenu columnDefinition={columnDefinition} onSortUpdate={onSortUpdate} />
          <FilterColumnMenu
            columnDefinition={columnDefinition}
            setMenuState={setMenuState}
            onFilterUpdate={onFilterUpdate}
          />
          <PinLeftColumnMenu column={columnDefinition} onColumnPin={onColumnPin} />
          <HideColumnMenu onColumnHide={onColumnHide} setMenuState={setMenuState} />
        </>
      );
    }
    case 'ReportField': {
      return (
        <>
          <SortAscendingColumnMenu columnDefinition={columnDefinition} onSortUpdate={onSortUpdate} />
          <SortDescendingColumnMenu columnDefinition={columnDefinition} onSortUpdate={onSortUpdate} />
          <FilterColumnMenu
            columnDefinition={columnDefinition}
            setMenuState={setMenuState}
            onFilterUpdate={onFilterUpdate}
          />
          <PinLeftColumnMenu column={columnDefinition} onColumnPin={onColumnPin} />
          <HideColumnMenu onColumnHide={onColumnHide} setMenuState={setMenuState} />
          <EditAnalyticColumnMenu
            analyticColumnId={columnDefinition.id}
            columnName={columnDefinition.name}
            setMenuState={setMenuState}
          />
          <DeleteReportingAnalyticColumnMenu
            analyticColumnId={columnDefinition.id}
            columnName={columnDefinition.name}
            setMenuState={setMenuState}
          />
        </>
      );
    }
    default: {
      return (
        <>
          <ClearSortColumnMenu columnDefinition={columnDefinition} onSortUpdate={onSortUpdate} />
          <SortAscendingColumnMenu columnDefinition={columnDefinition} onSortUpdate={onSortUpdate} />
          <SortDescendingColumnMenu columnDefinition={columnDefinition} onSortUpdate={onSortUpdate} />
          <FilterColumnMenu
            columnDefinition={columnDefinition}
            setMenuState={setMenuState}
            onFilterUpdate={onFilterUpdate}
          />
          <PinLeftColumnMenu column={columnDefinition} onColumnPin={onColumnPin} />
          <HideColumnMenu onColumnHide={onColumnHide} setMenuState={setMenuState} />
        </>
      );
    }
  }
}

export default ColumnMenus;
