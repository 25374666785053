/* eslint-disable react/jsx-props-no-spreading, import/no-cycle, react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import Leaf from 'components/NoteEditor/Leaf';
import {
  BlockTypeInline,
  CustomElement,
  DocSource,
  MentionElement as MentionElementType,
} from 'components/NoteEditor/types';
import React, { useEffect, useMemo, useRef } from 'react';
import { Transforms } from 'slate';
import { ReactEditor, RenderElementProps, useSlateStatic } from 'slate-react';
import useUserStore from 'stores/user';
import { getMemberName } from 'utils/string';
import { concat } from 'utils/styling';
import styles from './MentionElement.module.css';
import { openMentionPopover } from './MentionPopover';
import useEditorSelectedFocused from 'hooks/useEditorSelectedFocused';

interface Props extends RenderElementProps {
  element: MentionElementType;
  documentId: string | null;
  opportunityId: string | null;
  docSource: DocSource;
}

function MentionElement({ attributes, element, children }: Props) {
  const selected = useEditorSelectedFocused();
  const userStore = useUserStore();
  const ref = useRef<HTMLSpanElement>(null);
  const editor = useSlateStatic();
  const memberName = useMemo(() => {
    const member = userStore.members?.find(item => item.id === element.mentionUserId);
    return getMemberName(member);
  }, [element.mentionUserId, userStore.members]);

  useEffect(() => {
    if (!element.mentionUserId && element.beingEditedBy === userStore.user?.id) {
      if (ref.current) {
        openMentionPopover(ref.current).then(rsp => {
          const path = ReactEditor.findPath(editor, element);
          if (!rsp) {
            Transforms.removeNodes(editor, {
              match: node => (node as CustomElement).type === BlockTypeInline.Mention,
              at: path,
            });
            Transforms.insertText(editor, '@');
          } else {
            Transforms.setNodes<CustomElement>(
              editor,
              { mentionUserId: rsp.id, mentionByUserId: userStore.user?.id, beingEditedBy: undefined },
              {
                at: path,
              },
            );
            Transforms.insertText(editor, ' ');
          }
        });
      }
    }
  }, [element.mentionUserId, userStore.user]);

  return (
    <span
      {...attributes}
      className={concat(
        styles.mention,
        selected ? styles.selected : '',
        !element.mentionUserId ? 'mention-init' : '',
        'mention',
        'rounded',
        element.mentionId && `mention-${element.mentionId}`,
      )}
    >
      {children}
      <span ref={ref} contentEditable={false}>
        <Leaf leaf={element.children[0]} text={element.children[0]}>
          <span>@{memberName}</span>
        </Leaf>
      </span>
    </span>
  );
}

export default MentionElement;
