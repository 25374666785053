import { Field } from 'types/field-value';
import httpClient from '../http-client';

interface Response {
  accountFields: Field[];
  opportunityFields: Field[];
}

export default async (): Promise<Response> => {
  const url = '/fields';
  const { data } = await httpClient.get<Response>(url);
  return data;
};
