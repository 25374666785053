import React, { useMemo } from 'react';
import { concat } from 'utils/styling';

interface Props {
  className?: string;
  url: string;
}

function PdfPreview({ className, url }: Props) {
  const urlWithOptions = useMemo(() => `${url}#toolbar=0&navpanes=0`, [url]);
  return (
    <embed
      type="application/pdf"
      src={urlWithOptions}
      className={concat('w-full', className)}
      width="100%"
      height="100%"
    />
  );
}

PdfPreview.defaultProps = {
  className: '',
};

export default PdfPreview;
