import { State } from 'stores/user';

function settingsRecording(
  userStore: State,
  gongButton: JSX.Element,
  chorusButton: JSX.Element,
  zoomButton?: JSX.Element,
  slackButton?: JSX.Element,
) {
  const recordingsButtons: JSX.Element[] = [];

  if (userStore.user?.needsToSetUpRecording) {
    if (userStore.user?.teamRecordingIntegrationType === 'gong') {
      recordingsButtons.push(gongButton);
    } else if (userStore.user?.teamRecordingIntegrationType === 'chorus') {
      recordingsButtons.push(chorusButton);
    } else if (zoomButton && userStore.user?.teamRecordingIntegrationType === 'zoom') {
      recordingsButtons.push(zoomButton);
    } else if (userStore.user?.teamRecordingIntegrationType === null) {
      recordingsButtons.push(gongButton);
      recordingsButtons.push(chorusButton);
      if (zoomButton) {
        recordingsButtons.push(zoomButton);
      }
    }
  } else if (userStore.user?.teamRecordingIntegrationType === 'gong') {
    recordingsButtons.push(gongButton);
  } else if (userStore.user?.teamRecordingIntegrationType === 'chorus') {
    recordingsButtons.push(chorusButton);
  } else if (zoomButton && userStore.user?.teamRecordingIntegrationType === 'zoom') {
    recordingsButtons.push(zoomButton);
  } else if (userStore.user?.teamRecordingIntegrationType === null) {
    recordingsButtons.push(gongButton);
    recordingsButtons.push(chorusButton);
    if (zoomButton) {
      recordingsButtons.push(zoomButton);
    }
  }

  // always add slack button.
  if (slackButton) {
    recordingsButtons.push(slackButton);
  }
  return recordingsButtons;
}
export default settingsRecording;
