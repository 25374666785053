import React, { useMemo } from 'react';
import { Template } from 'api/note-templates/get-note-templates';
import { Emoji } from 'emoji-picker-react';
import { concat } from 'utils/styling';
import { BlockType } from 'components/NoteEditor/types';

interface Props {
  className?: string;
  template: Template;
  showAIWorkerCount?: boolean;
}

function TemplateInfo({ className, template, showAIWorkerCount }: Props) {
  const aiWorkerCount = useMemo(
    () =>
      showAIWorkerCount
        ? template?.data?.children?.filter(element => element.type === BlockType.AIWorker).length ?? 0
        : 0,
    [template, showAIWorkerCount],
  );

  return (
    <div className={concat('flex items-center gap-1', className)}>
      {!!template.icon && <Emoji unified={template.icon} size={18} />}
      <span className="truncate">{template.name}</span>
      {showAIWorkerCount && (
        <span className={concat('text-xs px-2', aiWorkerCount ? 'text-green-500' : 'text-red-400')}>
          {!aiWorkerCount && '(No AI tasks found in the temp!)'}
        </span>
      )}
    </div>
  );
}
TemplateInfo.defaultProps = {
  className: '',
  showAIWorkerCount: false,
};

export default TemplateInfo;
