import React from 'react';

interface Props {
  className?: string;
}

function TrashIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 7.59961H19"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8892 20H8.11116C7.69949 20.0003 7.30452 19.8372 7.01291 19.5466C6.7213 19.2561 6.55686 18.8617 6.55566 18.45V7.6H8.88866V6.05C8.88986 5.63824 9.05436 5.24378 9.34609 4.95318C9.63781 4.66259 10.0329 4.4996 10.4447 4.5H13.5557C13.9673 4.49987 14.3621 4.66297 14.6536 4.95353C14.9451 5.2441 15.1095 5.63842 15.1107 6.05V7.6H17.4442V18.45C17.443 18.8616 17.2786 19.2559 16.9871 19.5465C16.6956 19.837 16.3008 20.0001 15.8892 20V20Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4443 11.4746V16.1246"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5557 11.4746V16.1246"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

TrashIcon.defaultProps = {
  className: undefined,
};

export default TrashIcon;
