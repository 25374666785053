import { Template } from 'api/note-templates/get-note-templates';
import { Field } from 'components/FieldManagement/types';

export interface DocTriggerCondition {
  id: string;
  templateId: string;
  template?: Template;
  conditionField: Field;
  conditionOperator: 'is';
  conditionValue: string;
}

export const getEmptyDocTriggerCondition = (): DocTriggerCondition => ({
  id: '',
  templateId: '',
  conditionField: { id: '' } as Field,
  conditionOperator: 'is',
  conditionValue: '',
});
