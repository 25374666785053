/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading, no-param-reassign */
import { PencilSquareIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { isEqual, noop, omit } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Popover from 'components/Popover';
import useProductGapStore from 'stores/product-gap';
import { ProductCategory, ProductFieldAnswer, ProductFieldType, ProductGap } from 'types/product-gap';
import BaseButton from 'components/BaseButton';
import { openDeleteConfirmModal } from 'components/BaseModal';
import FormField from 'components/Form/FormField';
import InputField from 'components/Form/InputField';
import NumberField from 'components/Form/NumberField';
import SelectField from 'components/Form/SelectField';
import ReorderableList from 'components/ReorderableList';
import ProductboardComponentIcon from 'components/icons/ProductboardComponentIcon';
import { Controller, FieldArrayWithId, useFieldArray } from 'react-hook-form';
import { ControlledFormProps } from 'types/form';
import { concat } from 'utils/styling';
import useUserStore from '../../../stores/user';
import { User } from '../../../types/user';
import AddNewProductField from '../AddNewProductField';
import EditProductField from '../EditProductField';
import FolderSelector from 'components/FolderSelector';
import getPopoverInfo from 'utils/popover';
import DatePickerField from 'components/Form/DatePickerField';
import { arraySwap } from '@dnd-kit/sortable';

interface Props {
  productGap: ProductGap;
  addEditCategory: (category?: ProductCategory) => Promise<ProductCategory | undefined>;
}

interface State {
  isAddNewFieldOpen: boolean;
  editProductFieldState: {
    element: Element;
    productFieldId: string;
  } | null;
}

function CreateNewGapForm({ addEditCategory, register, control, errors }: Props & ControlledFormProps<ProductGap>) {
  const [state, setState] = useState<State>({
    isAddNewFieldOpen: false,
    editProductFieldState: null,
  });
  const addNewFieldButtonRef = useRef<HTMLButtonElement>(null);
  const productGapStore = useProductGapStore();
  const { user } = useUserStore() as { user: User };

  const { fields, append, update, remove, move } = useFieldArray({
    control,
    name: 'fields',
    keyName: 'keyId',
  });

  const elementPopoverInfo = useMemo(() => {
    if (state.editProductFieldState?.element) {
      return getPopoverInfo(state.editProductFieldState.element, 'left', undefined, undefined, 0, undefined, true);
    }
    return undefined;
  }, [state.editProductFieldState?.element]);

  useEffect(() => {
    const newFields = productGapStore.productFields
      .map(pf => {
        const field = fields.find(f => f.id === pf.id);
        return field
          ? undefined
          : {
              keyId: pf.id,
              id: pf.id,
              title: pf.title,
              type: pf.fieldType,
              isMandatory: pf.isMandatory,
              additionalData: pf.additionalData,
              answer: pf.defaultValue,
            };
      })
      .filter(pf => pf) as ProductFieldAnswer[];
    append(newFields, { shouldFocus: false });
    fields.forEach((pf, index) => {
      const field = productGapStore.productFields.find(f => f.id === pf.id);
      if (field) {
        if (
          pf.title !== field.title ||
          pf.type !== field.fieldType ||
          pf.isMandatory !== field.isMandatory ||
          !isEqual(pf.additionalData, field.additionalData)
        ) {
          update(index, {
            id: field.id,
            title: field.title,
            type: field.fieldType,
            isMandatory: field.isMandatory,
            additionalData: field.additionalData,
            answer: pf.answer,
          });
        }
      } else {
        remove(index);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productGapStore.productFields]);

  return (
    <>
      <FormField className="pl-8 mb-3" label="Category" labelClassName="w-[9rem]">
        <Controller
          name="category.id"
          control={control}
          render={({ field }) => (
            <SelectField
              placeholder="Select Category"
              className={user.isAdmin && user.productProvider === 'Superpanel' ? '!w-[calc(100%-44px)]' : ''}
              showClearBtn
              ref={field.ref}
              value={field.value}
              onChange={field.onChange}
              localSearchAble
              menus={productGapStore.productCategories.map(item => ({
                icon: <ProductboardComponentIcon className="w-2 h-2 fill-gray-300" />,
                label: item.title || '',
                value: item.id,
                description: item.integration?.parent.component?.name || item.description || '',
                item,
              }))}
              loading={productGapStore.isLoading}
              renderMenu={(menu, isDisplayValue) => (
                <>
                  <div className="text-left flex-1 w-[calc(100%-8rem)]">
                    {menu.description && (
                      <div className="text-xs w-full truncate flex items-center gap-2 text-gray-400">
                        <ProductboardComponentIcon className="w-2 h-2 fill-gray-300" />
                        <div className="flex-1 truncate">{menu.description}</div>
                      </div>
                    )}
                    <div className={concat('text-sm font-medium', isDisplayValue ? 'truncate' : 'whitespace-pre-wrap')}>
                      {menu.label}
                    </div>
                  </div>
                  <div className="hide-when-show-as-value">
                    {user.isAdmin && user.productProvider === 'Superpanel' && (
                      <>
                        <BaseButton
                          className="!min-w-[2rem] !h-8"
                          tooltip="Edit category"
                          hideTooltipToPreventJump
                          color="secondary"
                          variant="text"
                          iconBtn
                          onClick={() => addEditCategory(menu.item as ProductCategory)}
                        >
                          <PencilSquareIcon className="w-6 h-6" />
                        </BaseButton>
                        <BaseButton
                          className="!min-w-[2rem] !h-8"
                          tooltip="Delete category"
                          hideTooltipToPreventJump
                          color="error"
                          variant="text"
                          iconBtn
                          onClick={e => {
                            e.stopPropagation();
                            openDeleteConfirmModal(
                              `Are you sure you want to delete this category?`,
                              'This action cannot be undone.',
                              async () => {
                                try {
                                  await productGapStore.deleteCategory(menu.value as string);
                                  if (field.value === menu.value) {
                                    field.onChange(undefined);
                                  }
                                  return;
                                } catch {
                                  noop();
                                }
                              },
                            );
                          }}
                        >
                          <TrashIcon className="w-6 h-6" />
                        </BaseButton>
                      </>
                    )}
                  </div>
                </>
              )}
            />
          )}
        />
        {user.isAdmin && user.productProvider === 'Superpanel' && (
          <BaseButton
            className="!min-w-[2rem] !h-8 !ml-3"
            tooltip="Add new category"
            hideTooltipToPreventJump
            color="secondary"
            variant="text"
            iconBtn
            onClick={() => addEditCategory()}
          >
            <PlusIcon width={24} />
          </BaseButton>
        )}
      </FormField>
      <FormField className="pl-8 mb-3" label="Folder" labelClassName="w-[9rem]">
        <Controller
          name="documentId"
          control={control}
          render={({ field }) => (
            <FolderSelector selectedDocumentId={field.value} setDocumentId={field.onChange} ref={field.ref} />
          )}
        />
      </FormField>

      <ReorderableList
        list={fields}
        isDragDisabled={useCallback(
          (field: FieldArrayWithId<ProductGap>) => field.title === 'Feedback' || field.title === 'Title',
          [],
        )}
        listItemClassName={concat('mb-3', 'pl-8')}
        renderListItem={(field, index) => (
          <FormField
            key={field.id}
            label={
              field.title === 'Feedback' || field.title === 'Title' ? (
                field.title
              ) : (
                <BaseButton
                  color="secondary"
                  className="!pl-0 !text-sm whitespace-pre-wrap !text-left !justify-start !h-auto !w-full"
                  onClick={e => {
                    setState(prevState => ({
                      ...prevState,
                      editProductFieldState: { element: e.target as Element, productFieldId: field.id },
                    }));
                  }}
                >
                  {field.title}
                </BaseButton>
              )
            }
            labelClassName="w-[9rem]"
            error={errors.fields?.[index]?.answer ? 'This field is mandatory' : ''}
          >
            {field.type === ProductFieldType.TEXT && (
              <InputField
                {...register(`fields.${index}.answer`, { required: field.isMandatory })}
                placeholder={
                  field.title === 'Title'
                    ? 'Enter title for the product gap here'
                    : field.title === 'Feedback'
                    ? 'Enter product feedback here'
                    : ''
                }
              />
            )}
            {field.type === ProductFieldType.NUMBER && (
              <Controller
                name={`fields.${index}.answer`}
                control={control}
                rules={{ required: field.isMandatory }}
                render={({ field: fd }) => <NumberField value={fd.value} onChange={fd.onChange} ref={fd.ref} />}
              />
            )}
            {field.type === ProductFieldType.DATE && (
              <Controller
                name={`fields.${index}.answer`}
                control={control}
                rules={{ required: field.isMandatory }}
                render={({ field: fd }) => <DatePickerField value={fd.value} onChange={fd.onChange} ref={fd.ref} />}
              />
            )}
            {field.type === ProductFieldType.SELECT && (
              <Controller
                name={`fields.${index}.answer`}
                control={control}
                rules={{ required: field.isMandatory }}
                render={({ field: fd }) => (
                  <SelectField
                    showClearBtn
                    ref={fd.ref}
                    value={fd.value}
                    onChange={fd.onChange}
                    menus={
                      field.additionalData?.options?.map(item => ({
                        label: item,
                        value: item,
                      })) || []
                    }
                  />
                )}
              />
            )}
          </FormField>
        )}
        handleReorder={({ fromIndex, toIndex }) => {
          if (fields[toIndex].title !== 'Feedback' && fields[toIndex].title !== 'Title') {
            move(fromIndex, toIndex);
            const newFieldValues = arraySwap(productGapStore.productFields, fromIndex, toIndex);
            newFieldValues.forEach((field, index) => {
              field.row = index;
            });
            productGapStore.updateProductFieldRows(newFieldValues);
          }
        }}
      />
      {user.isAdmin && (
        <BaseButton
          ref={addNewFieldButtonRef}
          color="secondary"
          className="!ml-8 w-fit"
          onClick={() => setState(prevState => ({ ...prevState, isAddNewFieldOpen: true }))}
        >
          + Add New Field
        </BaseButton>
      )}
      <Popover
        anchorEl={addNewFieldButtonRef.current}
        isOpen={state.isAddNewFieldOpen}
        onClose={() => setState(prevState => ({ ...prevState, isAddNewFieldOpen: false }))}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <AddNewProductField
          onCreate={productField => {
            setState(prevState => ({
              ...prevState,
              isAddNewFieldOpen: false,
              editProductFieldState: {
                element: addNewFieldButtonRef.current as HTMLButtonElement,
                productFieldId: productField.id,
              },
            }));
          }}
        />
      </Popover>
      {state.editProductFieldState && (
        <Popover
          onClose={() => setState(prevState => ({ ...prevState, editProductFieldState: null }))}
          isOpen={!!state.editProductFieldState?.element}
          {...omit(elementPopoverInfo, 'style')}
          paperProps={{ style: elementPopoverInfo?.style }}
        >
          <EditProductField
            productFieldId={state.editProductFieldState.productFieldId}
            onUpdate={async newEditField => {
              await productGapStore.updateProductField(newEditField);
            }}
            onDelete={async id => {
              await productGapStore.deleteProductField(id);
              setState(prevState => ({
                ...prevState,
                editProductFieldState: null,
              }));
            }}
            onCancel={() => setState(prevState => ({ ...prevState, editProductFieldState: null }))}
          />
        </Popover>
      )}
    </>
  );
}

export default CreateNewGapForm;
