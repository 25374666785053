/* eslint-disable consistent-return */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-promise-executor-return, react/no-array-index-key */
import React, { useState } from 'react';
import useModalStore, { SingleModalState } from 'stores/modal';
import { IconButton } from '@mui/material';
import { concat } from 'utils/styling';
import { ModalAction, ModalCloseAction, ModalConfirmAction, ModalResponseType } from 'types/modal';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { v4 } from 'uuid';
import { FieldValues } from 'react-hook-form';
import BaseButton from './BaseButton';
import TrashIcon from './icons/TrashIcon';
import styles from './BaseModal.module.css';
import BaseForm, { BaseFormProps } from './Form/BaseForm';
import WarningIcon from './icons/WarningIcon';
import InfoIcon from './icons/InfoIcon';

export interface BaseModalProps {
  className?: string;
  title?: string | React.ReactNode;
  body?: React.ReactNode;
  actions?: ModalAction[];
  modalId: string;
  footerClassName?: string;
  bodyScroll?: boolean;
  bodyClassName?: string;
}

export function BaseModal({
  className,
  title,
  body,
  actions,
  modalId,
  footerClassName,
  bodyScroll = true,
  bodyClassName,
}: BaseModalProps) {
  const modalStore = useModalStore();
  const [loadingBtn, setLoadingBtn] = useState('');

  return (
    <div className={concat(styles.modalContainer, className)}>
      <div className={styles.modalHeader}>
        <div className={styles.titleWrap}>
          {typeof title === 'string' ? <h2 className={styles.modalTitle}>{title}</h2> : title}
        </div>
        <IconButton
          className={styles.closeBtn}
          aria-label="Close modal"
          onClick={() => modalStore.close(ModalCloseAction, modalId)}
        >
          <XMarkIcon width={24} />
        </IconButton>
      </div>
      {!!body && (
        <div className={concat(styles.modalBody, bodyScroll && styles.modalBodyScroll, bodyClassName)}>{body}</div>
      )}
      {!!actions?.length && (
        <div className={concat(styles.modalFooter, footerClassName)}>
          {actions.map((btn, index) => (
            <BaseButton
              key={btn.action + index}
              className={concat(styles.modalBtn, btn.className)}
              onClick={() => {
                if (btn.cb) {
                  const ret = btn.cb();
                  if (ret instanceof Promise) {
                    setLoadingBtn(btn.action);
                    ret
                      .then(rsp => {
                        modalStore.close((rsp as object) || btn.action, modalId);
                      })
                      .finally(() => setLoadingBtn(''));
                  } else {
                    modalStore.close(btn.action, modalId);
                  }
                } else {
                  modalStore.close(btn.action, modalId);
                }
              }}
              color={btn.color || 'secondary'}
              variant={btn.variant || 'contained'}
              type={btn.type}
              disabled={!!loadingBtn || btn.disabled}
              loading={loadingBtn === btn.action}
            >
              {btn.icon}
              {btn.label}
            </BaseButton>
          ))}
        </div>
      )}
    </div>
  );
}

const openModal = (
  modalProps: Omit<BaseModalProps, 'modalId'>,
  modalId?: string,
  modalState?: Partial<
    Pick<
      SingleModalState,
      'element' | 'verticalPosition' | 'disableEscapeKeyDown' | 'hideBackdrop' | 'className' | 'disableBackdropClose'
    >
  >,
): Promise<ModalResponseType> => {
  const modalInstanceId = modalId || v4();
  return new Promise<ModalResponseType>(resolve =>
    useModalStore.getState().setState(
      {
        ...modalState,
        isOpen: true,
        element: <BaseModal {...modalProps} modalId={modalInstanceId} />,
        closeCallback: (modalResponse: ModalResponseType) => resolve(modalResponse),
      },
      modalInstanceId,
    ),
  );
};

export const openDeleteConfirmModal = (
  label: string,
  description?: string,
  deleteCb?: () => Promise<void>,
): Promise<boolean> => {
  return openModal({
    className: 'w-[32rem]',
    title: (
      <div className={styles.redRipple}>
        <TrashIcon className={styles.redTrash} />
      </div>
    ),
    body: (
      <>
        <h3 className={styles.bodyLabel}>{label}</h3>
        {!!description && <span className={styles.bodyDesc}>{description}</span>}
      </>
    ),
    actions: [
      { label: 'Cancel', action: ModalCloseAction, color: 'secondary' },
      { label: 'Continue', action: ModalConfirmAction, color: 'error', cb: deleteCb },
    ],
  }).then(rsp => {
    return rsp === ModalConfirmAction;
  });
};

export const openWarningConfirmModal = (
  label: string,
  body?: React.ReactNode,
  confirmText = 'Continue',
  cancelText = 'Cancel',
): Promise<boolean> => {
  return openModal({
    className: 'w-[32rem]',
    title: (
      <div className="w-12 h-12 rounded-full border-8 border-orange-100 bg-orange-200 flex items-center justify-center">
        <WarningIcon className="text-orange-500" />
      </div>
    ),
    body: (
      <>
        <h3 className={styles.bodyLabel}>{label}</h3>
        {!!body && <span className={styles.bodyDesc}>{body}</span>}
      </>
    ),
    actions: [
      cancelText ? { label: cancelText, action: ModalCloseAction, color: 'secondary' } : undefined,
      { label: confirmText, action: ModalConfirmAction, color: 'warning' },
    ].filter(action => action) as ModalAction[],
  }).then(rsp => {
    return rsp === ModalConfirmAction;
  });
};

export const openInformationalModal = (
  label: React.ReactNode,
  description?: string,
  title?: string,
): Promise<boolean> => {
  return openModal({
    className: 'w-[32rem]',
    title: title || (
      <div className="w-12 h-12 rounded-full border-8 border-orange-100 bg-orange-200 flex items-center justify-center">
        <InfoIcon className="text-orange-500" />
      </div>
    ),
    body: (
      <>
        <h3 className={styles.bodyLabel}>{label}</h3>
        {!!description && <span className={styles.bodyDesc}>{description}</span>}
      </>
    ),
    actions: [{ label: 'Close', action: ModalConfirmAction, color: 'primary' }],
  }).then(rsp => {
    return rsp === ModalConfirmAction;
  });
};

export const openMessageInfoModal = (
  title: string,
  message: string,
  cancelBtnLable?: string,
  confirmCb?: () => Promise<void>,
) => {
  const actions: ModalAction[] = [{ label: 'OK', action: ModalConfirmAction, color: 'primary', cb: confirmCb }];
  if (cancelBtnLable) {
    actions.unshift({ label: cancelBtnLable, action: ModalCloseAction });
  }
  return openModal({
    className: 'w-[32rem]',
    title,
    body: <h3 className={styles.bodyLabel}>{message}</h3>,
    actions,
  });
};

export function openFormModal<T extends FieldValues>(
  formProps: BaseFormProps<T>,
  modalProps: Omit<BaseModalProps, 'modalId' | 'body' | 'actions'>,
  modalId?: string,
  modalState?: Partial<
    Pick<
      SingleModalState,
      'element' | 'verticalPosition' | 'disableEscapeKeyDown' | 'hideBackdrop' | 'disableRestoreFocus'
    >
  >,
): Promise<T | typeof ModalCloseAction> {
  const modalInstanceId = modalId || v4();
  const actionsCb = formProps.actions.map(btn => ({
    ...btn,
    cb: (fd: T) => {
      if (btn.cb) {
        const ret = btn.cb(fd);
        if (ret instanceof Promise) {
          return ret.then(rsp => {
            useModalStore.getState().close(rsp || ModalCloseAction, modalInstanceId);
            return rsp as T;
          });
        }
        useModalStore.getState().close(ModalCloseAction, modalInstanceId);
      } else {
        useModalStore.getState().close(ModalCloseAction, modalInstanceId);
      }
    },
  }));
  return openModal(
    {
      ...modalProps,
      body: (
        <BaseForm
          formData={formProps.formData}
          renderFormBody={formProps.renderFormBody}
          actions={actionsCb}
          handleApiError={formProps.handleApiError}
        />
      ),
      bodyScroll: modalProps.bodyScroll === undefined ? false : modalProps.bodyScroll,
    },
    modalInstanceId,
    modalState,
  ) as Promise<T | typeof ModalCloseAction>;
}

export default openModal;
