import React from 'react';
import { SalesforceAddressField } from 'types/salesforce';

interface Props {
  answer: SalesforceAddressField | null;
}

function AddressField({ answer }: Props) {
  if (!answer) {
    return <div className="text-gray-200">Empty string</div>;
  }
  return (
    <div>
      <div>{answer.street}</div>
      <div>
        {answer.city} {answer.state} {answer.postalCode}
      </div>
    </div>
  );
}

export default AddressField;
