/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactPlayer from 'react-player/lazy';
import { find } from 'lodash';
import MuiModal from '@mui/material/Modal';

import styles from './Modal.module.css';
import { concat } from 'utils/styling';
import useUserStore from 'stores/user';
import WarningIcon from './icons/WarningIcon';
import { UserNotification } from 'types/user';

function Notification() {
  const userStore = useUserStore();
  const locationIns = useLocation();
  const [open, setOpen] = React.useState(false);
  const [notification, setNotification] = React.useState<UserNotification>();

  useEffect(() => {
    if (userStore.user?.notification) {
      const noti = find(userStore.user?.notification, item => locationIns.pathname.startsWith(item.location));
      if (noti) {
        setNotification(noti);
        setOpen(true);
      }
    }
  }, [userStore.user?.notification, locationIns, setNotification]);

  const updateNotification = () => {
    // filter and clone user notification
    const clonedNotification = userStore.user?.notification
      ?.filter(item => item.location !== notification?.location)
      .map(item => {
        return { ...item };
      });
    userStore.updateUser({ notification: JSON.stringify(clonedNotification) });
  };

  if (userStore.user?.id && notification) {
    return (
      <MuiModal
        key="sys-notification"
        open={open}
        onClose={() => {
          setOpen(false);
          updateNotification();
        }}
        disableEscapeKeyDown
      >
        <div
          className={concat(styles.modal)}
          style={{
            top: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <div className="w-[34rem] p-5">
            <div className="flex items-center gap-4">
              <WarningIcon className="w-8 h-8 text-orange-400" />
              <h3 className="text-gray-600">{notification.title}</h3>
            </div>
            <div className="flex justify-between whitespace-break-spaces items-center px-5 py-3">
              {notification.body}
            </div>
            {notification.image && <img className="w-full" src={notification.image} alt="notification" />}
            {notification.video && (
              <div className="w-full">
                <ReactPlayer width="100%" url={notification.video} />
              </div>
            )}
          </div>
        </div>
      </MuiModal>
    );
  }
  return null;
}

export default Notification;
