import React, { useState } from 'react';

import Productboard from 'components/logos/Productboard';
import SpinLoader from 'components/icons/SpinLoader';
import ExternalLinkIcon from 'components/icons/ExternalLinkIcon';

interface Props {
  link: string | null;
  disabled: boolean;
  publish: () => Promise<void>;
}

function ProductboardButton({ link, disabled, publish }: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  if (link) {
    return (
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
        className="p-2 flex items-center gap-2 text-sm text-gray-600 hover:bg-slate-100"
      >
        <Productboard className="w-5 h-5 p-0.5" />
        <div>Link to product board</div>
        <ExternalLinkIcon className="w-5 h-5" />
      </a>
    );
  }

  const buttonDisabled = disabled || isLoading;
  const onClick = async () => {
    setIsLoading(true);
    await publish();
    setIsLoading(false);
  };
  return (
    <button
      type="button"
      className={`p-2 text-sm hover:bg-slate-100 flex items-center gap-2 ${
        buttonDisabled ? 'text-gray-300 cursor-default' : 'text-gray-600'
      }`}
      onClick={onClick}
      disabled={buttonDisabled}
    >
      <Productboard className="w-5 h-5 p-0.5" />
      <div>Publish to productboard</div>
      {isLoading && <SpinLoader className="w-4 h-4 animate-spin" />}
    </button>
  );
}

export default ProductboardButton;
