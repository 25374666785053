/* eslint-disable no-param-reassign,import/no-extraneous-dependencies */
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { EntityType } from 'types/entity';
import { create } from 'zustand';

interface State {
  buttonHover: null | string;
  menuHover: null | string;
  collapsedButton: null | string;
  showHint: null | string;
  pulseNoteMenu: boolean;
  activeEntityHierarchy: { id: string; entityType: EntityType }[];
  activeEntityRef: null | React.RefObject<HTMLDivElement>;
  setState: (newState: Partial<State>) => State;

  entitiesOpen: string[];
  addEntityOpen: (entityIds: string[]) => void;
  removeEntityOpen: (entityId: string) => void;
}

const useNavMenuStore = create<State>((set, get) => ({
  buttonHover: null,
  menuHover: null,
  collapsedButton: null,
  showHint: null,
  pulseNoteMenu: false,
  activeEntityHierarchy: [],
  activeEntityRef: null,
  setState: newState => {
    set(state => {
      return { ...state, ...newState };
    });
    return get();
  },
  entitiesOpen: [],
  addEntityOpen: entityIds => {
    set(state => {
      const newEntitiesOpen = [...state.entitiesOpen, ...entityIds];
      return { ...state, entitiesOpen: [...new Set(newEntitiesOpen)] };
    });
  },
  removeEntityOpen: entityId => {
    set(state => {
      return { ...state, entitiesOpen: state.entitiesOpen.filter(id => id !== entityId) };
    });
  },
}));

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('NavMenuStore', useNavMenuStore);
}

export default useNavMenuStore;
