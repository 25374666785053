import React from 'react';
import { convertValueToDisplayValue } from '../utils';
import { TableRow } from '../../../types/reporting';
import ReportingFieldAnswer from './ReportingFieldAnswer';
import useReportingStore from '../../../stores/reporting';
import { TagIcon } from '@heroicons/react/24/outline';

interface Props {
  cellValue: string | null;
  columnName: string;
  columnData: {
    fieldId: string;
    entityName: 'Account' | 'Opportunity';
  };
  rowData: TableRow;
}

function ReportFieldAnswerCellValue({ cellValue, columnName, columnData, rowData }: Props) {
  const reportingStore = useReportingStore();
  const { fieldId, entityName } = columnData;

  if (entityName === 'Opportunity' && !(entityName in rowData))
    return <div className="text-gray-300 px-4 py-2">No Opportunity</div>;

  const displayValue = convertValueToDisplayValue(cellValue, 'Text');
  const fieldRowData = rowData[fieldId];

  if (!fieldRowData) return <div className="text-gray-300 px-4 py-2">No Data</div>;

  const { fieldValueId } = fieldRowData;

  const columnIds: string[] = [];
  Object.keys(fieldRowData).forEach(key => {
    if (!['answer', 'fieldValueId'].includes(key)) {
      columnIds.push(key);
    }
  });

  return (
    <ReportingFieldAnswer
      answer={displayValue}
      fieldValueId={fieldValueId}
      dataEntryType="string"
      columnName={columnName}
      entityName={entityName}
      documentId={null}
      closePopoverOnValueChange={false}
      onValueChange={value => reportingStore.updateFieldValueId(fieldValueId, fieldId, value)}
      triggerAI={() => reportingStore.triggerAiAnalyticTableEntry(fieldId, fieldValueId, columnIds)}
      prefixIcon={<TagIcon className="min-w-[16px] w-4 h-4 mt-0.5 mr-2 text-blue-500" />}
    />
  );
}

export default ReportFieldAnswerCellValue;
