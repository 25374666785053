import { openDeleteConfirmModal, openWarningConfirmModal } from 'components/BaseModal';
import useLeavePageGuard from 'hooks/useLeavePageGuard';
import { cloneDeep, get } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import useDocumentStore, { State } from 'stores/document';
import useModalStore from 'stores/modal';
import usePopupMessageStore from 'stores/popup-message';
import useRecordingsStore from 'stores/recordings';
import { CrmNote, Document, Parent } from 'types/document';
import { EntityDocument, Opportunity } from 'types/entity';
import { ModalResponseType } from 'types/modal';
import { v4 as uuidv4 } from 'uuid';
import createVoice from '../../api/invoice/create-voice';
import AudioDeviceEnumModal from './AudioDeviceEnumModal';
import AudioRecorder, { AudioRecorderRef } from './AudioRecorder';
import styles from './AudioRecorderFloatingButton.module.css';
import openCountDownModal from './CountDown';

type EntityInfoType = 'account' | 'opportunity' | 'document' | 'crm-note' | 'uploaded-file';

interface EntityInfo {
  type: EntityInfoType;
  id: string;
  detail?: EntityDocument | Document | CrmNote;
}

const parseUrlToEntity = (url: string): EntityInfo | null => {
  const sections = url
    .split('/')
    .map(section => section.trim())
    .filter(section => section);
  if (
    sections.length >= 2 &&
    ['account', 'opportunity', 'document', 'crm-note', 'uploaded-file'].includes(sections[0])
  ) {
    return { type: sections[0] as EntityInfoType, id: sections[1] };
  }
  return null;
};

const findEntityInStore = (entityInfo: EntityInfo | null, docStore: State, byCrmId: boolean) => {
  if (entityInfo) {
    switch (entityInfo.type) {
      case 'account':
      case 'opportunity':
        return docStore.entityDocuments.find(item =>
          byCrmId ? item.crm?.id === entityInfo.id : item.id === entityInfo.id,
        );
      case 'document':
      case 'uploaded-file':
        return docStore.documents.find(item => item.id === entityInfo.id);
      case 'crm-note':
        return docStore.crmNotes.find(item => item.id === entityInfo.id);
      default:
        return null;
    }
  }
  return null;
};

const convertLinkedEntityToParent = (linkedEntity?: {
  id: string;
  name: 'Opportunity' | 'Account';
}): Parent | undefined => {
  return linkedEntity ? { entityName: linkedEntity.name, id: linkedEntity.id, crmId: '', name: '' } : undefined;
};

const DISMISS_RECORDING_WARNING_MSG = 'Do you want dismiss current recording?';

function AudioRecorderFloatingButton() {
  const audioRecorderRef = useRef<AudioRecorderRef>(null);
  const modalStore = useModalStore();
  const popupMessageStore = usePopupMessageStore();
  const documentStore = useDocumentStore();
  const recordingsStore = useRecordingsStore();
  const navigate = useNavigate();

  const [recordingEntity, setRecordingEntity] = useState<EntityInfo | undefined>();
  const recordingEntityRef = useRef<EntityInfo | undefined>(recordingEntity);
  useEffect(() => {
    if (recordingEntity) {
      recordingEntityRef.current = recordingEntity;
    }
  }, [recordingEntity]);

  const docMatch = useMatch({ path: '/document/:documentId' });
  const accMatch = useMatch({ path: '/account/:documentId' });
  const oppoMatch = useMatch({ path: '/opportunity/:documentId' });
  const crmNoteMatch = useMatch({ path: '/crm-note/:crmNoteId' });
  const uploadedDocMatch = useMatch({ path: '/uploaded-file/:documentId' });
  const entityInfo = useMemo(() => {
    return parseUrlToEntity(
      get(docMatch || accMatch || oppoMatch || crmNoteMatch || uploadedDocMatch, ['pathname']) ?? '',
    );
  }, [docMatch, accMatch, oppoMatch, crmNoteMatch, uploadedDocMatch]);

  const entityDetail = useMemo(() => findEntityInStore(entityInfo, documentStore, false), [entityInfo, documentStore]);

  // can record when current entity is account/opportunity/document, when document it should have parent
  const canRecord = useMemo(() => {
    switch (entityInfo?.type) {
      case 'account':
      case 'opportunity':
        return !!entityDetail;
      case 'document':
        return !!(entityDetail as Document)?.parent;
      default:
        return false;
    }
  }, [entityInfo, entityDetail]);

  const showAudioDeviceEnumModal = useCallback((): Promise<ModalResponseType> => {
    const modalInstanceId = uuidv4();
    return new Promise<ModalResponseType>(resolve => {
      modalStore.setState(
        {
          element: <AudioDeviceEnumModal modalId={modalInstanceId} />,
          isOpen: true,
          closeCallback: (modalResponse: ModalResponseType) => resolve(modalResponse),
        },
        modalInstanceId,
      );
    });
  }, []);

  // ignore urls here used to trigger rebind block handler
  const ignoreUrls = useMemo(() => [entityInfo?.id || '', '/login'], [entityInfo?.id]);
  useLeavePageGuard(!!recordingEntity, ignoreUrls, async (redirectUrl: string) => {
    const redirectEntityInfo = parseUrlToEntity(redirectUrl);
    let canNavigateInScope = false;
    const redirectEntityDetail = findEntityInStore(redirectEntityInfo, useDocumentStore.getState(), false);
    if (redirectEntityInfo && redirectEntityDetail && recordingEntityRef.current) {
      let recordingEntityClone = cloneDeep(recordingEntityRef.current);
      if (recordingEntityRef.current.type === 'document') {
        // when recording document, the navigation rule is same as recording its parent
        const parent = (recordingEntityRef.current.detail as Document)?.parent;
        if (parent) {
          const parentType = parent.entityName.toLowerCase() as EntityInfoType;
          recordingEntityClone = {
            type: parentType,
            id: parent.id,
            detail: findEntityInStore({ type: parentType, id: parent.id }, useDocumentStore.getState(), false) as
              | EntityDocument
              | undefined,
          };
        }
      }
      if (recordingEntityClone.type === 'account') {
        // when recording account, can navigate inside all items under this account
        if (redirectEntityInfo.type === 'account') {
          canNavigateInScope = redirectEntityInfo.id === recordingEntityClone.id;
        } else if (redirectEntityInfo.type === 'opportunity') {
          canNavigateInScope = (redirectEntityDetail as EntityDocument).parentId === recordingEntityClone.id;
        } else if (
          redirectEntityInfo.type === 'document' ||
          redirectEntityInfo.type === 'crm-note' ||
          redirectEntityInfo.type === 'uploaded-file'
        ) {
          const redirectDocParentDetail =
            redirectEntityInfo.type === 'document' || redirectEntityInfo.type === 'uploaded-file'
              ? (redirectEntityDetail as Document).parent
              : convertLinkedEntityToParent((redirectEntityDetail as CrmNote).linkedEntity);
          if (redirectDocParentDetail?.entityName === 'Account') {
            canNavigateInScope =
              redirectEntityInfo.type === 'crm-note'
                ? redirectDocParentDetail?.id === (recordingEntityClone.detail as EntityDocument)?.crm?.id
                : redirectDocParentDetail?.id === recordingEntityClone.id;
          }
          if (redirectDocParentDetail?.entityName === 'Opportunity') {
            const oppoParentDetail = findEntityInStore(
              { type: 'opportunity', id: redirectDocParentDetail.id },
              useDocumentStore.getState(),
              redirectEntityInfo.type === 'crm-note',
            ) as EntityDocument | undefined;
            canNavigateInScope = oppoParentDetail?.parentId === recordingEntityClone.id;
          }
        }
      } else if (recordingEntityClone.type === 'opportunity') {
        // when recording opportunity, can navigate inside all items under this opportunity and its parent and parent's direct docs
        if (redirectEntityInfo.type === 'account') {
          canNavigateInScope = redirectEntityInfo.id === (recordingEntityClone.detail as EntityDocument)?.parentId;
        } else if (redirectEntityInfo.type === 'opportunity') {
          canNavigateInScope = redirectEntityDetail.id === recordingEntityClone.id;
        } else if (
          redirectEntityInfo.type === 'document' ||
          redirectEntityInfo.type === 'crm-note' ||
          redirectEntityInfo.type === 'uploaded-file'
        ) {
          const redirectDocParentDetail =
            redirectEntityInfo.type === 'document' || redirectEntityInfo.type === 'uploaded-file'
              ? (redirectEntityDetail as Document).parent
              : convertLinkedEntityToParent((redirectEntityDetail as CrmNote).linkedEntity);
          if (redirectDocParentDetail?.entityName === 'Account') {
            canNavigateInScope =
              redirectEntityInfo.type === 'crm-note'
                ? redirectDocParentDetail?.id ===
                  ((recordingEntityClone.detail as EntityDocument)?.crm as Opportunity)?.accountId
                : redirectDocParentDetail?.id === (recordingEntityClone.detail as EntityDocument)?.parentId;
          }
          if (redirectDocParentDetail?.entityName === 'Opportunity') {
            canNavigateInScope =
              redirectEntityInfo.type === 'crm-note'
                ? redirectDocParentDetail.id === (recordingEntityClone.detail as EntityDocument)?.crm?.id
                : redirectDocParentDetail.id === recordingEntityClone.id;
          }
        }
      }
    }
    if (canNavigateInScope) {
      return true;
    }
    // delay for a while, fix issue when navigaton triggered by close action of other modals
    await new Promise<void>(resolve => {
      setTimeout(() => {
        resolve();
      }, 0);
    });
    const close = await openWarningConfirmModal(DISMISS_RECORDING_WARNING_MSG, undefined, 'Yes', 'No');
    if (close) {
      audioRecorderRef.current?.stopRecording(false, true);
    }
    return close;
  });

  if (!(entityInfo && entityDetail && (canRecord || recordingEntity))) {
    return null;
  }

  return (
    <div className={styles.btnContainer}>
      <AudioRecorder
        ref={audioRecorderRef}
        onRecordingComplete={async (file: File, type: string) => {
          if (recordingEntityRef.current) {
            let newDoc: Document | null = null;
            const fileName = file.name;
            if (recordingEntityRef.current.type === 'account' || recordingEntityRef.current.type === 'opportunity') {
              // when recording account/opportunity, create a new doc under it ad attach the voice to new doc
              newDoc = await documentStore.createDocument(recordingEntityRef.current.id, null, fileName);
            }
            const call = await createVoice(file, newDoc?.id || recordingEntityRef.current.id, fileName, type);
            documentStore.addDocumentCall(recordingEntityRef.current.id, call);
            recordingsStore.fetch(recordingEntityRef.current.id, false);
            if (newDoc) {
              navigate(`/document/${newDoc.id}`);
            }
            popupMessageStore.success('Audio Recording is created successfully');
          }
        }}
        onBeforeRecording={async () => {
          const rsp = await showAudioDeviceEnumModal();
          if (typeof rsp === 'object') {
            const audioDevice = rsp as MediaDeviceInfo;
            await openCountDownModal({
              seconds: 3,
            });
            return audioDevice;
          }
          return null;
        }}
        onDeleteRecording={() => {
          return openDeleteConfirmModal(
            'You are deleting current audio recording',
            'This action cannot be undone and current audio recording will be lost.',
          );
        }}
        // 45 min
        maxRecordingTime={2700}
        onRecordStatusChange={status => {
          setRecordingEntity(current => {
            if (status === 'stopped') {
              return undefined;
            } else {
              return current || { ...entityInfo, detail: entityDetail };
            }
          });
        }}
      />
    </div>
  );
}

export default AudioRecorderFloatingButton;
