/* eslint-disable no-nested-ternary */
import React, { useMemo, useRef, useState } from 'react';
import { QuestionMarkCircleIcon, ShareIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { EntityDocumentRich as ApiResponse } from 'api/entities/get-entity';

import NoteEditor from 'components/NoteEditor';
import Plugins, { Plugin } from 'components/NoteEditor/Plugins';
import ContextPanelView from 'components/ContextPanelView';
import Tooltip from 'components/Tooltip';
import typoStyles from 'components/typo.module.css';
import EntityHeader from 'components/EntityHeader';
import useDocumentStore from 'stores/document';
import { PanelContext } from 'stores/context-panel';
import useModalStore from 'stores/modal';

import { concat } from 'utils/styling';
import orderDocuments from 'utils/order-documents';
import getTemplateToPopulate from 'utils/get-template-to-populate';
import { Template } from 'api/note-templates/get-note-templates';
import styles from './OpportunityView.module.css';
import DocumentLink from './OpportunityView/DocumentLink';
import TemplateLink from './OpportunityView/TemplateLink';
import DocumentTitle from 'pages/Document/DocumentView/DocumentTitle';
import TitleTags from 'components/EntityHeader/TitleTags';
import DiscoveryQuestionList from 'components/DiscoveryQuestionList/DiscoveryQuestionList';
import ShareModal from 'pages/Document/DocumentView/ShareModal';
import Tabs from 'components/Tabs';
import EntityVectorizationStatus from '../../components/EntityVectorizationStatus';
import { useNewDocumentModal } from 'components/NewDocumentModal';
import UploadDocumentModal from 'components/NoteMenu/UploadDocumentModal';

interface Props {
  apiResponse: ApiResponse;
  noteTemplates: Template[];
  documentId: string;
}

function OpportunityView({ apiResponse, noteTemplates, documentId }: Props) {
  const modalStore = useModalStore();
  const MAX_ITEMS = 8;
  const [isShowAll, setIsShowAll] = React.useState<boolean>(false);

  const documentStore = useDocumentStore();
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const { createNewDocumentWithModal } = useNewDocumentModal();

  const documents = useMemo(
    () =>
      documentStore.documents.filter(
        d => d.parent && d.parent.entityName === 'Opportunity' && d.parent.id === apiResponse.entityDocument.id,
      ),
    [documentStore.documents, apiResponse.entityDocument],
  );
  const crmNotes = useMemo(
    () =>
      documentStore.crmNotes.filter(
        note => note.linkedEntity.name === 'Opportunity' && note.linkedEntity.id === apiResponse.entityDocument.crm?.id,
      ),
    [documentStore.crmNotes, apiResponse.entityDocument],
  );

  const outcaseTemplates = useMemo(() => getTemplateToPopulate(noteTemplates, documents), [noteTemplates, documents]);

  const opportunityItems = useMemo(() => orderDocuments(documents, crmNotes), [documents, crmNotes]);

  const timeSpent = useMemo(() => moment.duration(apiResponse.timeSpent, 'seconds'), [apiResponse.timeSpent]);

  const panelContexts: PanelContext[] = useMemo(() => {
    return ['recordings', 'chat', 'meetings'];
  }, []);

  const plugins = useMemo<Plugin[]>(() => {
    return [
      new Plugins.HotKey(),
      new Plugins.EmojiCommand(),
      new Plugins.EnterKey(),
      new Plugins.TabKey(),
      new Plugins.BackSpace(),
      new Plugins.ItemList(),
      new Plugins.Copy(),
      new Plugins.Cut(),
      new Plugins.Paste(),
      new Plugins.ItemList(),
    ];
  }, []);
  const openShareModal = () => {
    modalStore.setState({
      element: <ShareModal entityType="opportunity" documentId={documentId} />,
      isOpen: true,
    });
  };

  const tabs = useMemo(
    () => [
      { label: 'Fields', value: 'fields' },
      { label: 'Documents', value: 'documents' },
    ],
    [],
  );
  const [currentTab, setCurrentTab] = useState(tabs[0].value);

  return (
    <div className="w-full h-full flex flex-col">
      <EntityHeader
        entityType="opportunity"
        documentId={apiResponse.entityDocument.id}
        crmNoteId={null}
        panelContexts={panelContexts}
        menuItems={
          <button
            id="share-button"
            className="px-2 py-1 text-sm rounded bg-gray-100 hover:bg-gray-200 flex items-center gap-2"
            onClick={() => openShareModal()}
            type="button"
          >
            <ShareIcon className="h-4 w-4 text-gray-500" />
            <span>Share</span>
          </button>
        }
      />
      <EntityVectorizationStatus entityId={apiResponse.entityDocument.id} />
      <ContextPanelView viewKey="doc-view-context-panel" scrollable={false}>
        <div className="flex items-center pr-5 gap-2 pb-2">
          <Tabs
            tabs={tabs}
            tabClassName="!text-gray-500 !text-sm !font-normal"
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            containerClassName="flex-1"
          />
          <button
            className="bg-blue-600 hover:bg-blue-700 text-sm text-white px-2 py-1 rounded mt-1"
            onClick={() => createNewDocumentWithModal({ parentId: apiResponse.entityDocument.id, title: null })}
            type="button"
          >
            New Document
          </button>
          <button
            className="px-2 py-1 text-sm rounded bg-gray-100 hover:bg-gray-200 flex items-center gap-2 mt-1"
            onClick={() =>
              modalStore.setState({
                element: (
                  <UploadDocumentModal parentDocumentId={apiResponse.entityDocument.id} siblingDocuments={documents} />
                ),
                isOpen: true,
              })
            }
            type="button"
          >
            Upload Document
          </button>
        </div>
        <div className="w-full h-full overflow-y-auto" ref={scrollContainerRef}>
          <div className="flex flex-col m-auto max-w-[900px] pb-6 px-10 context-panel-left-content">
            <div className="ml-[4rem] mb-2">
              <DocumentTitle disabled initTitle={apiResponse.entityDocument.name} placeholder="Untitled" />
              <TitleTags documentId={apiResponse.entityDocument.id} type="opportunity" />
            </div>
            <div className={currentTab === 'fields' ? '' : 'hidden'}>
              <NoteEditor
                key={apiResponse.entityDocument.id}
                autoFocusWhenEmpty
                documentId={apiResponse.entityDocument.id}
                opportunityId={apiResponse.entityDocument.crm ? apiResponse.entityDocument.crm.id : null}
                blockPlaceholder="This a new line"
                emptyParagraphPlaceholder="Start to log your opportunity knowledge"
                serviceId={apiResponse.note.serviceId}
                serviceToken={apiResponse.note.serviceToken}
                plugins={plugins}
                type="opp-editor"
                scrollContainer={scrollContainerRef.current || undefined}
              />
              <div className="flex flex-col ml-[4rem] mr-[1rem] gap-4 mb-4">
                <div className={styles.metrics}>
                  <span className={styles['metric-block']}>
                    <div className="flex items-center text-gray-500 text-sm">
                      <span>Total meetings</span>
                      <Tooltip
                        title={
                          <>
                            Number of private and public events and calls that are linked to this opportunity. Data can
                            have a short delay.
                          </>
                        }
                        placement="top"
                      >
                        <QuestionMarkCircleIcon className="ml-1 w-4 h-4 text-gray-400" />
                      </Tooltip>
                    </div>
                    <span className={typoStyles['semi-bold']}>{apiResponse.numberOfMeetings}</span>
                  </span>
                  <span className={styles['metric-block']}>
                    <span className={concat(typoStyles.sm, typoStyles['text-grey-500'])}>Discovery Completion</span>
                    <span className={typoStyles['semi-bold']}>
                      {`${apiResponse.numberOfDiscoveryQuestionsCompleted}/${apiResponse.numberOfDiscoveryQuestionsTotal}`}
                    </span>
                  </span>
                  <span className={styles['metric-block']}>
                    <div className="flex items-center text-gray-500 text-sm">
                      <span>Time spent</span>
                      <Tooltip title="Total Time Spent on Meetings" placement="top">
                        <QuestionMarkCircleIcon className="ml-1 w-4 h-4 text-gray-400" />
                      </Tooltip>
                    </div>
                    <span className={typoStyles['semi-bold']}>
                      {timeSpent.asMinutes() >= 60
                        ? `${timeSpent.hours() + timeSpent.days() * 24}H, ${timeSpent.minutes()} M`
                        : `${timeSpent.minutes()} M`}
                    </span>
                  </span>
                </div>
              </div>
              <div className="ml-[4rem] mr-[1rem]">
                <div className="font-medium mb-2">Discovery Summary</div>
                <DiscoveryQuestionList entityDocument={apiResponse.entityDocument} />
              </div>
            </div>

            {currentTab === 'documents' && (
              <div className="flex flex-col ml-[4rem] mr-[1rem] gap-2 mt-4">
                {outcaseTemplates.map(template => (
                  <TemplateLink
                    key={template.noteTemplateId}
                    oppId={apiResponse.entityDocument.id}
                    template={template}
                  />
                ))}
                {opportunityItems.map((element, idx) => {
                  if (!isShowAll && idx >= MAX_ITEMS) {
                    return null;
                  }
                  if ('docTitle' in element) {
                    // Document
                    return (
                      <DocumentLink
                        key={element.id}
                        id={element.id}
                        title={element.event ? element.event.subject : element.docTitle}
                        link={element.uploadedFile ? `/uploaded-file/${element.id}` : `/document/${element.id}`}
                        event={element.event}
                        isPublic={element.isPublic}
                        isFavourite={element.isFavourite}
                        isRecordingAvailable={element.calls.length > 0}
                        isFavorable
                        type={
                          element.uploadedFile
                            ? element.uploadedFile.fileType.endsWith('pdf')
                              ? 'pdf'
                              : element.uploadedFile.fileType.includes('text/plain')
                              ? 'txt'
                              : 'word'
                            : 'document'
                        }
                      />
                    );
                  }
                  // CRM Document
                  return (
                    <DocumentLink
                      key={element.id}
                      id={element.id}
                      title={element.title}
                      link={`/crm-note/${element.id}`}
                      event={null}
                      isPublic
                      isFavourite={false}
                      isRecordingAvailable={false}
                      isFavorable={false}
                      type="crm"
                    />
                  );
                })}
                {!isShowAll && Object.entries(opportunityItems).length > MAX_ITEMS && (
                  <div className="flex justify-center text-gray-500">
                    <button
                      className="text-gray-500 text-sm px-1 rounded border border-gray-300 hover:bg-gray-200"
                      onClick={() => {
                        setIsShowAll(true);
                      }}
                      type="button"
                    >
                      {Object.entries(opportunityItems).length - MAX_ITEMS} more
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </ContextPanelView>
    </div>
  );
}

export default OpportunityView;
