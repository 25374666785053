/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { openFormModal } from 'components/BaseModal';
import { cloneDeep } from 'lodash';
import FormField from 'components/Form/FormField';
import InputField from 'components/Form/InputField';
import { requiredInputValidate } from 'utils/validate';
import apiErrors from 'utils/errors';
import { Tag } from 'types/field-value';
import createFieldTag from 'api/fields/create-field-tag';

/**
 * open modal to create a new field tag or update an existing one
 * @param tag if exist then update, otherwise create new
 * @returns new created/updated tag
 */
const openNewFieldTagModal = (tag?: Tag): Promise<Tag | undefined> => {
  const fieldTag: Tag = tag
    ? cloneDeep(tag)
    : {
        id: '',
        tag: '',
        colour: '',
      };
  return openFormModal<Tag>(
    {
      formData: fieldTag,
      renderFormBody: ({ register, errors }) => (
        <FormField
          className="mb-3"
          label="Tag name"
          labelClassName="w-20"
          error={errors.tag ? errors.tag.message || 'Tag Name is required' : ''}
        >
          <InputField {...register('tag', requiredInputValidate)} placeholder="Enter Tag Name" />
        </FormField>
      ),
      handleApiError: (error, setError) => {
        if (error.code === apiErrors.DatabaseError.code) {
          setError('tag', {
            message: 'Tag name already exist',
            type: 'api',
          });
        }
      },
      actions: [
        {
          label: 'Cancel',
          color: 'secondary',
        },
        {
          label: tag ? 'Update' : 'Create',
          color: 'primary',
          type: 'submit',
          cb: (fd: Tag) => {
            return createFieldTag(fd.tag.trim());
          },
        },
      ],
    },
    {
      className: 'w-[32rem]',
      title: tag ? 'Update Tag' : 'Create a new Tag',
      bodyClassName: '!pr-0',
    },
  ).then(rsp => {
    return typeof rsp === 'object' ? (rsp as Tag) : undefined;
  });
};

export default openNewFieldTagModal;
