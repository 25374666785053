import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
// import * as Sentry from '@sentry/react';
// import { BrowserTracing } from '@sentry/tracing';
import { errorHandler } from 'api/handlers';

import Modal from 'components/Modal';
import Notification from 'components/Notification';
import Page from 'components/Page';
import RecentPage from 'components/RecentPage';
import PopupMessage from 'components/PopupMessage';
import MustBeLoggedIn from 'components/utils/MustBeLoggedIn';
import WebsocketNoteServiceConnector from 'components/WebsocketNoteServiceConnector';
import KeyboardListener from 'components/KeyboardListener';

import AccountPage from 'pages/AccountPage';
import CalendarIntegration from 'pages/CalendarIntegration';
import CrmNote from 'pages/CrmNote';
import Document from 'pages/Document';
import GoogleOauth from 'pages/GoogleOauth';
import Login from 'pages/Login';
import MicrosoftOauth from 'pages/MicrosoftOauth';
import Opportunity from 'pages/Opportunity';
import QuestionsSetup from 'pages/QuestionsSetup';
import RecordingIntegration from 'pages/RecordingIntegration';
import RecordingPage from 'pages/RecordingPage';
import SalesforceOauth from 'pages/SalesforceOauth';
import Settings from 'pages/Settings';
import Test from 'pages/Test';
import ZoomOauth from 'pages/ZoomOauth';
import GongOauth from 'pages/GongOauth';
// import CrmIntegration from 'pages/CrmIntegration';
import EmptyPage from 'pages/EmptyPage';
import CrmTaskPage from 'pages/CrmTaskPage';
import UserInvitation from 'pages/UserInvitation';
import RedirectPage from 'pages/RedirectPage';
import TeamMemberRequestCreatePage from './pages/TeamMemberRequestCreatePage';
import TeamMemberRequestsPage from 'pages/TeamMemberRequestsPage';
import Register from 'pages/Register';
import CrmIntegrationTeam from 'pages/CrmIntegrationTeam';
import ReportingPage from 'pages/ReportingPage';
import SlackOauth from 'pages/SlackOauth';
import ProductGapPage from './pages/ProductGapPage';
import TaskManagementPage from 'pages/TaskManagementPage';

import useUserStore from 'stores/user';
import usePopupMessageStore from 'stores/popup-message';

// import config from 'utils/config';
import './index.css';
import './normalize.css';

import AdminGoogleOauth from 'admin-src/pages/AdminGoogleOauth';
import AdminLogin from 'admin-src/pages/AdminLogin';
import AdminUser from 'admin-src/pages/AdminUser';
import AdminMustBeLoggedIn from 'admin-src/components/utils/AdminMustBeLoggedIn';
import AdminMain from 'admin-src/pages/AdminMain';
import AdminTeamDetailed from 'admin-src/pages/AdminTeamDetailed';
import AdminTeam from 'admin-src/pages/AdminTeam';

import reportWebVitals from './reportWebVitals';
import Heap from './utils/heap';
import Intercom from './utils/intercom';

import Sentry from './utils/sentry';
import TeamMemberRequestPage from './pages/TeamMemberRequestPage';
import UploadedFilePreviewPage from 'pages/UploadedFilePreview/UploadedFilePreviewPage';
import AudioRecorderFloatingButton from 'components/AudioRecorder/AudioRecorderFloatingButton';

// when we test Gong Oauth locally, the redirect uri is 127.0.0.1 (we cannot use localhost), hence the domain is changed from localhost to 127.0.0.1, and hence the error:
// error Access to XMLHttpRequest at 'http://localhost:8000/calendar-events' from origin 'http://127.0.0.1:3000' has been blocked by CORS policy: Response to preflight request doesn't pass access control check: No 'Access-Control-Allow-Origin' header is present on the requested resource.
// as a hack, we add this
// TODO anything better we can do?
// This system is in place because of Gong that support 127.0.0.1 but does not support localhost
if (window.location.href.includes('127.0.0.1:3000')) {
  window.location.replace(window.location.href.replace('127.0.0.1', 'localhost'));
}

function App() {
  const popupMessageStore = usePopupMessageStore();
  const userStore = useUserStore();
  errorHandler(popupMessageStore, userStore);

  useEffect(() => {
    Intercom.initialize(userStore.user);
  }, [userStore.user]);

  return (
    <BrowserRouter basename="/">
      <WebsocketNoteServiceConnector />
      <PopupMessage />
      <Modal />
      <Notification />
      <KeyboardListener />
      <AudioRecorderFloatingButton />
      <Routes>
        {/* -------Admin app routes-------- */}
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/admin/google-oauth" element={<AdminGoogleOauth />} />
        <Route
          path="/admin/users"
          element={
            <AdminMustBeLoggedIn>
              <AdminUser />
            </AdminMustBeLoggedIn>
          }
        />
        <Route
          path="/admin/teams"
          element={
            <AdminMustBeLoggedIn>
              <AdminTeam />
            </AdminMustBeLoggedIn>
          }
        />
        <Route
          path="/admin/teams/:teamId"
          element={
            <AdminMustBeLoggedIn>
              <AdminTeamDetailed />
            </AdminMustBeLoggedIn>
          }
        />
        <Route
          path="/admin"
          element={
            <AdminMustBeLoggedIn>
              <AdminMain />
            </AdminMustBeLoggedIn>
          }
        />
        {/* -------Web app routes-------- */}
        <Route
          path="/"
          element={
            <MustBeLoggedIn>
              <Page>
                <EmptyPage />
              </Page>
            </MustBeLoggedIn>
          }
        />
        <Route
          path="/crm-note/:crmNoteId"
          element={
            <MustBeLoggedIn>
              <Page>
                <RecentPage type="crm-note">
                  <CrmNote />
                </RecentPage>
              </Page>
            </MustBeLoggedIn>
          }
        />
        <Route
          path="/uploaded-file/:documentId"
          element={
            <MustBeLoggedIn>
              <Page>
                <RecentPage type="uploaded-file">
                  <UploadedFilePreviewPage />
                </RecentPage>
              </Page>
            </MustBeLoggedIn>
          }
        />
        <Route
          path="/product-gap"
          element={
            <MustBeLoggedIn>
              <Page>
                <ProductGapPage />
              </Page>
            </MustBeLoggedIn>
          }
        />
        <Route
          path="/task"
          element={
            <MustBeLoggedIn>
              <Page>
                <TaskManagementPage />
              </Page>
            </MustBeLoggedIn>
          }
        />
        <Route
          path="/document/:documentId"
          element={
            <MustBeLoggedIn>
              <Page>
                <RecentPage type="document">
                  <Document />
                </RecentPage>
              </Page>
            </MustBeLoggedIn>
          }
        />
        <Route
          path="/account/:documentId"
          element={
            <MustBeLoggedIn>
              <Page>
                <RecentPage type="account">
                  <AccountPage />
                </RecentPage>
              </Page>
            </MustBeLoggedIn>
          }
        />
        <Route
          path="/opportunity/:documentId"
          element={
            <MustBeLoggedIn>
              <Page>
                <RecentPage type="opportunity">
                  <Opportunity />
                </RecentPage>
              </Page>
            </MustBeLoggedIn>
          }
        />
        <Route
          path="/opportunity-discovery-questions/:documentId"
          element={
            <MustBeLoggedIn>
              <Page>
                <RecentPage type="opportunity">
                  <Opportunity />
                </RecentPage>
              </Page>
            </MustBeLoggedIn>
          }
        />
        <Route
          path="/recording"
          element={
            <MustBeLoggedIn>
              <Page>
                <RecordingPage />
              </Page>
            </MustBeLoggedIn>
          }
        />
        <Route
          path="/reporting"
          element={
            <MustBeLoggedIn>
              <Page>
                <ReportingPage />
              </Page>
            </MustBeLoggedIn>
          }
        />
        <Route
          path="/team-member-requests"
          element={
            <MustBeLoggedIn>
              <Page>
                <TeamMemberRequestsPage />
              </Page>
            </MustBeLoggedIn>
          }
        />
        <Route
          path="/team-member-requests/create"
          element={
            <MustBeLoggedIn>
              <Page>
                <TeamMemberRequestCreatePage />
              </Page>
            </MustBeLoggedIn>
          }
        />
        <Route
          path="/team-member-requests/:requestId"
          element={
            <MustBeLoggedIn>
              <Page>
                <TeamMemberRequestPage />
              </Page>
            </MustBeLoggedIn>
          }
        />
        {/* <Route
          path="/crm-integration"
          element={
            <MustBeLoggedIn>
              <CrmIntegration />
            </MustBeLoggedIn>
          }
        /> */}
        <Route
          path="/crm-integration-for-team-J5N5PJokMQQo2mtXCUOSLkwMi2nUY"
          element={
            <MustBeLoggedIn>
              <CrmIntegrationTeam />
            </MustBeLoggedIn>
          }
        />
        <Route
          path="/calendar-integration"
          element={
            <MustBeLoggedIn>
              <CalendarIntegration />
            </MustBeLoggedIn>
          }
        />
        <Route path="/google-oauth" element={<GoogleOauth />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/microsoft-oauth" element={<MicrosoftOauth />} />
        <Route
          path="/questions-setup"
          element={
            <MustBeLoggedIn>
              <QuestionsSetup />
            </MustBeLoggedIn>
          }
        />
        <Route
          path="/recording-integration"
          element={
            <MustBeLoggedIn>
              <RecordingIntegration />
            </MustBeLoggedIn>
          }
        />
        <Route
          path="/crm-task"
          element={
            <MustBeLoggedIn>
              <CrmTaskPage />
            </MustBeLoggedIn>
          }
        />
        <Route path="/salesforce-oauth" element={<SalesforceOauth />} />
        <Route
          path="/settings"
          element={
            <MustBeLoggedIn>
              <Page>
                <Settings />
              </Page>
            </MustBeLoggedIn>
          }
        />
        <Route path="/zoom-oauth" element={<ZoomOauth />} />
        <Route path="/gong-oauth" element={<GongOauth />} />
        <Route path="/slack-oauth" element={<SlackOauth />} />
        <Route path="/test" element={<Test />} />
        <Route path="/user-invitation" element={<UserInvitation />} />
        <Route
          path="/redirect"
          element={
            <MustBeLoggedIn>
              <RedirectPage />
            </MustBeLoggedIn>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Adding Heap
Heap.initialize();

// Adding Sentry
Sentry.initialize();
