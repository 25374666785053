import React from 'react';
import { SalesforceFieldType } from 'types/salesforce';

interface Props {
  value: string | number | boolean | object;
  type: SalesforceFieldType;
}

function ViewOnlyField({ value, type }: Props) {
  if (type === SalesforceFieldType.BOOLEAN) {
    return <input type="checkbox" disabled checked={value as boolean} />;
  }

  if (!value) {
    return <div className="text-gray-400">Empty string</div>;
  }

  return <div className="text-gray-400">{JSON.stringify(value)}</div>;
}

export default ViewOnlyField;
