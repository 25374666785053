import { useEffect } from 'react';
import useFieldValuesStore from 'stores/field-values';

export default function useSuggestedAnswer() {
  const fieldValuesStore = useFieldValuesStore();

  useEffect(() => {
    // Every 2 second we fetch new AI processing status from backend
    const intervalId = setInterval(() => {
      fieldValuesStore.fieldValues.forEach(fieldValue => {
        if (fieldValue.isAiProcessing) {
          fieldValuesStore.checkAiProcessing(fieldValue.id);
        }
      });
    }, 4000);
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldValuesStore.fieldValues]);
}
