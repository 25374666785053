import React from 'react';

interface Props {
  className?: string;
}
function AttachmentIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5657 12.1575L12.4437 19.1575C11.5639 20.0165 10.3832 20.4973 9.15366 20.4973C7.92415 20.4973 6.7434 20.0165 5.86366 19.1575C5.43233 18.7365 5.08958 18.2335 4.85556 17.6781C4.62153 17.1227 4.50098 16.526 4.50098 15.9233C4.50098 15.3206 4.62153 14.7239 4.85556 14.1685C5.08958 13.6131 5.43233 13.11 5.86366 12.689L12.9857 5.68904C13.5721 5.11644 14.3593 4.7959 15.1789 4.7959C15.9985 4.7959 16.7857 5.11644 17.3722 5.68904C17.6597 5.96971 17.8883 6.30506 18.0443 6.67537C18.2003 7.04568 18.2807 7.44346 18.2807 7.84529C18.2807 8.24713 18.2003 8.64491 18.0443 9.01521C17.8883 9.38552 17.6597 9.72088 17.3722 10.0015L10.2422 17.0015C9.94891 17.2877 9.5554 17.4479 9.14566 17.4479C8.73592 17.4479 8.34241 17.2877 8.04916 17.0015C7.90541 16.8612 7.79118 16.6936 7.71319 16.5084C7.63519 16.3233 7.59502 16.1244 7.59502 15.9235C7.59502 15.7227 7.63519 15.5238 7.71319 15.3387C7.79118 15.1535 7.90541 14.9859 8.04916 14.8455L14.6292 8.38654"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

AttachmentIcon.defaultProps = {
  className: undefined,
};
export default AttachmentIcon;
