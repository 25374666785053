import React, { useCallback, useState } from 'react';
import { ProductFieldAdditionalData, ProductFieldType } from 'types/product-gap';
import InputField from './Form/InputField';
import { debounce } from 'lodash';
import DatePickerField from './Form/DatePickerField';
import NumberField from './Form/NumberField';
import SelectField from './Form/SelectField';

const UPDATE_DEBOUNCE_TIME = 500;

interface Props {
  placeholder?: string;
  fieldType: ProductFieldType;
  additionalData: ProductFieldAdditionalData;
  answer: string;
  onUpdate: (answer: string, inputSource?: HTMLInputElement) => Promise<void> | void;
  multiline?: boolean;
  isError?: boolean;
  disableLoader?: boolean;
  disabled?: boolean;
}
function ProductFieldAnswer({
  placeholder,
  fieldType,
  additionalData,
  answer,
  onUpdate,
  multiline,
  isError,
  disableLoader,
  disabled,
}: Props) {
  const [localAnswer, setLocalAnser] = useState(answer);
  const [updating, setUpdating] = useState(false);
  const updateFieldDebounce = useCallback(
    debounce(async (newAnswer: string, inputSource: HTMLInputElement) => {
      setUpdating(true);
      try {
        await onUpdate(newAnswer, inputSource);
      } finally {
        setUpdating(false);
      }
    }, UPDATE_DEBOUNCE_TIME),
    [],
  );
  const updateFieldImmediately = useCallback(async (newAnswer: string) => {
    setLocalAnser(newAnswer);
    setUpdating(true);
    try {
      await onUpdate(newAnswer);
    } finally {
      setUpdating(false);
    }
  }, []);
  switch (fieldType) {
    case ProductFieldType.TEXT: {
      return (
        <InputField
          onChange={e => {
            setLocalAnser(e.target.value);
            updateFieldDebounce(e.target.value, e.target as HTMLInputElement);
          }}
          multiline={multiline}
          value={localAnswer}
          placeholder={placeholder}
          loading={!disableLoader && updating}
          isError={isError && !localAnswer.trim()}
          disabled={disabled}
        />
      );
    }
    case ProductFieldType.DATE:
      return (
        <DatePickerField
          value={localAnswer}
          onChange={updateFieldImmediately}
          loading={!disableLoader && updating}
          isError={isError}
          disabled={disabled}
        />
      );
    case ProductFieldType.NUMBER:
      return (
        <NumberField
          value={localAnswer}
          onChange={e => {
            const newV = e.target.value.toString();
            setLocalAnser(newV);
            updateFieldDebounce(newV, e.target);
          }}
          loading={!disableLoader && updating}
          placeholder={placeholder}
          isError={isError && !localAnswer.trim()}
          disabled={disabled}
        />
      );
    case ProductFieldType.SELECT: {
      return (
        <SelectField
          showClearBtn
          value={localAnswer}
          onChange={newV => updateFieldImmediately(newV?.toString() || '')}
          menus={
            additionalData?.options.map(item => ({
              value: item,
              label: item,
            })) || []
          }
          loading={!disableLoader && updating}
          placeholder={placeholder}
          isError={isError}
          disabled={disabled}
        />
      );
    }
    default:
      return <div>Unknown Field Type</div>;
  }
}

ProductFieldAnswer.defaultProps = {
  placeholder: '',
  multiline: undefined,
  isError: undefined,
  disableLoader: false,
  disabled: undefined,
};

export default ProductFieldAnswer;
