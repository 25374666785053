import React, { useMemo, useState } from 'react';
import { WorkerContextRow } from '../../../../types/ai-worker';
import NoteIcon from '../../../icons/NoteIcon';
import RecordingIcon from '../../../icons/RecordingIcon';
import AttachmentIcon from '../../../icons/AttachmentIcon';
import PdfIcon from 'components/icons/PdfIcon';
import WordIcon from 'components/icons/WordIcon';
import TxtIcon from 'components/icons/TxtIcon';
import useModalStore from '../../../../stores/modal';
import { ModalCloseAction } from '../../../../types/modal';
import SpinLoader from '../../../icons/SpinLoader';

export interface WorkerContextRowViewProps {
  row: WorkerContextRow;
}

export interface ContextRow {
  type: string;
  title: string;
  text: string;
  id: string;
}

export interface AIWorkerContextModalInit {
  row: ContextRow;
  rawRow: WorkerContextRow;
}

export interface AIWorkerContextModalProps {
  onConfirm: (scope: WorkerContextRow) => Promise<void>;
  initValue: AIWorkerContextModalInit;
  modalId: string;
}

export function AIWorkerContextModal({ onConfirm, initValue, modalId }: AIWorkerContextModalProps) {
  const modalStore = useModalStore();
  const [isLoading, setIsLoading] = useState(false);
  const textContent = useMemo(
    () =>
      initValue.rawRow.text
        .split(/\n+/)
        .map(p => `<p class="py-1.5">${p}</p>`)
        .join(''),
    [initValue.rawRow.text],
  );
  return (
    <div className="w-[30rem]">
      <div className="border-b px-2 pb-6 pt-4">
        <div className="text-xl px-6 font-bold text-gray-700 mb-4 mt-3">{initValue.row.title}</div>
        <div
          className="overflow-y-auto px-6 max-h-[30rem] whitespace-pre-line text-sm"
          dangerouslySetInnerHTML={{ __html: textContent }}
        />
      </div>

      <div className="mt-4 pt-0 pb-4 flex flex-row justify-around">
        <button
          onClick={() => modalStore.close(ModalCloseAction, modalId)}
          type="button"
          className="px-4 py-2 bg-gray-100 hover:bg-gray-200 text-gray-500 rounded"
        >
          Cancel
        </button>
        <button
          onClick={async () => {
            await onConfirm(initValue.rawRow).then(() => modalStore.close(ModalCloseAction, modalId));
          }}
          type="button"
          className="w-24 px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded"
          disabled={isLoading}
        >
          {isLoading ? <SpinLoader className="w-4 h-4 text-orange-500 animate-spin" /> : <span>OK</span>}
        </button>
      </div>
    </div>
  );
}

export function useAIWorkerContextModal() {
  const modalStore = useModalStore();
  const modalId = `${Date.now()}-${Math.random()}`;
  const createAIWorkerContextWithModal = (
    initValue: AIWorkerContextModalInit,
    onRet: (scope: WorkerContextRow) => void,
  ) => {
    modalStore.setState(
      {
        isOpen: true,
        element: (
          <AIWorkerContextModal
            modalId={modalId}
            initValue={initValue}
            onConfirm={async ret => {
              onRet({ ...ret });
            }}
          />
        ),
      },
      modalId,
    );
  };

  return { createAIWorkerContextWithModal };
}

const getTitle = (text: string, lines: string[]): string => {
  const TITLE_FLAG = '[Title]';
  const title = (lines.find(i => i.includes(TITLE_FLAG)) || `${TITLE_FLAG}: N/A`).substring(TITLE_FLAG.length + 2);
  if (title !== 'N/A') {
    return title;
  }
  if (text.includes('CRM Field')) return 'CRM Field';
  return lines[0];
};
const parseContextRow = (row: WorkerContextRow): ContextRow => {
  const CONTEXT_FLAG = '[Possible Relevant Context]';
  const lines = row.text.split('\n');
  const title = getTitle(row.text, lines);
  const textIndex = lines.findIndex(i => i.includes(CONTEXT_FLAG));
  let text = '';
  if (textIndex < 0) {
    text = lines.join(' ');
  } else {
    lines[textIndex] = lines[textIndex].substring(CONTEXT_FLAG.length + 1);
    text = lines.slice(textIndex, lines.length).join(' ');
  }
  return {
    id: row.id,
    type: row.metadata.type,
    text: text.trim(),
    title: title.trim(),
  };
};
export function AIWorkerContextRowView({ row }: WorkerContextRowViewProps) {
  const r = parseContextRow(row);
  const { createAIWorkerContextWithModal } = useAIWorkerContextModal();

  const renderIcon = () => {
    const cls = 'w-5 h-5 shrink-0';
    if (r.type === 'call') {
      return <RecordingIcon className={cls} />;
    }
    if (r.type === 'note') {
      return <NoteIcon className={cls} />;
    }
    if (r.type === 'file' && r.title) {
      const t = r.title.toLowerCase();
      if (t.endsWith('doc') || t.endsWith('docx')) {
        return <WordIcon className={cls} />;
      }
      if (t.endsWith('txt')) {
        return <TxtIcon className={cls} />;
      }
      if (t.endsWith('pdf')) {
        return <PdfIcon className={cls} />;
      }
    }
    return <AttachmentIcon className={cls} />;
  };

  const openDetails = () => {
    createAIWorkerContextWithModal({ row: r, rawRow: row }, ret => {
      // eslint-disable-next-line no-console
      console.log(ret);
    });
  };
  return (
    <div className="flex flex-row mt-2">
      <button
        onClick={openDetails}
        type="button"
        className="flex flex-row border-[1px] border-gray-300 text-gray-600 rounded items-center pl-0.5 pr-0.5"
      >
        {renderIcon()}
        <div className="px-1 max-w-[12rem] min-w-[3rem] truncate text-ellipsis text-xs text-left">{r.title}</div>
      </button>
      <div className="ml-2 text-ellipsis truncate text-gray-500">{r.text}</div>
    </div>
  );
}
