import React, { useRef, useState } from 'react';
import { BooleanFilterType, BooleanFilterValue } from 'components/Table/types';
import CaretIcon from 'components/icons/CaretIcon';
import Popover from 'components/Popover';
import { FilterProps } from './type';
import BaseCheckbox from 'components/Form/BaseCheckbox';

type Props = FilterProps<BooleanFilterType, BooleanFilterValue | undefined>;

function BooleanFilter({ name, type, value, onFilterUpdate }: Props) {
  const [filterType, setFilterType] = useState<BooleanFilterType>(type);
  const [isFilterTypeOpen, setIsFilterTypeOpen] = useState(false);

  const ref = useRef<HTMLButtonElement>(null);

  const updateFilterType = (newFilterType: BooleanFilterType) => {
    setFilterType(newFilterType);
    if (newFilterType === 'Is empty' || newFilterType === 'Is not empty') {
      onFilterUpdate({
        type: newFilterType,
        value: '',
      });
    } else if (value !== undefined) {
      onFilterUpdate({
        value,
        type: newFilterType,
      });
    } else {
      onFilterUpdate(undefined);
    }
  };

  const updateFilterValue = (newValue?: BooleanFilterValue) => {
    if (!newValue) {
      onFilterUpdate(undefined);
    } else {
      onFilterUpdate({
        type: filterType,
        value: newValue,
      });
    }
  };

  return (
    <div className="px-2 pt-1 pb-2">
      <div className="flex items-center text-xs">
        <div className="text-gray-400">{name}</div>
        <button
          ref={ref}
          type="button"
          className="flex items-center rounded hover:bg-gray-100 px-2 py-2 text-sm"
          onClick={() => setIsFilterTypeOpen(true)}
        >
          <div>{filterType.toLowerCase()}</div>
          <CaretIcon className="w-4 h-4 rotate-180" />
        </button>
        <Popover
          anchorEl={ref.current}
          onClose={() => setIsFilterTypeOpen(false)}
          isOpen={isFilterTypeOpen}
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        >
          <div className="flex flex-col p-1">
            <button
              type="button"
              className="px-2 py-1 text-sm text-left rounded hover:bg-gray-100"
              onClick={() => {
                updateFilterType('Is');
                setIsFilterTypeOpen(false);
              }}
            >
              Is
            </button>
            <button
              type="button"
              className="px-2 py-1 text-sm text-left rounded hover:bg-gray-100"
              onClick={() => {
                updateFilterType('Is empty');
                setIsFilterTypeOpen(false);
              }}
            >
              Is empty
            </button>
            <button
              type="button"
              className="px-2 py-1 text-sm text-left rounded hover:bg-gray-100"
              onClick={() => {
                updateFilterType('Is not empty');
                setIsFilterTypeOpen(false);
              }}
            >
              Is not empty
            </button>
          </div>
        </Popover>
      </div>
      <div className="my-1">
        {!['Is empty', 'Is not empty'].includes(filterType) && (
          <div>
            <BaseCheckbox
              className="!ml-0 w-full"
              label="True"
              checked={value === 'True'}
              onChange={() => {
                updateFilterValue(value === 'True' ? undefined : 'True');
              }}
            />
            <BaseCheckbox
              className="!ml-0 w-full"
              label="False"
              checked={value === 'False'}
              onChange={() => {
                updateFilterValue(value === 'False' ? undefined : 'False');
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default BooleanFilter;
