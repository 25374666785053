export default {
  ENV: process.env.REACT_APP_ENV || 'local',
  BACKEND_API_VERSION: '1.12.0',
  NOTE_SERVER_URL: process.env.REACT_APP_NOTE_SERVER_URL || 'ws://localhost:1234',
  GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID || '',
  ADMIN_GOOGLE_CLIENT_ID: process.env.REACT_APP_ADMIN_GOOGLE_CLIENT_ID || '',
  ADMIN_GOOGLE_REDIRECT_URL:
    process.env.REACT_APP_ADMIN_GOOGLE_REDIRECT_URL || 'http://localhost:3000/admin/google-oauth',
  GOOGLE_REDIRECT_URL: process.env.REACT_APP_GOOGLE_REDIRECT_URL || 'http://localhost:3000/google-oauth',
  MICROSOFT_CLIENT_ID: process.env.REACT_APP_MICROSOFT_CLIENT_ID || '',
  MICROSOFT_REDIRECT_URL: process.env.REACT_APP_MICROSOFT_REDIRECT_URL || 'http://localhost:3000/microsoft-oauth',
  SALESFORCE_CLIENT_ID: process.env.REACT_APP_SALESFORCE_CLIENT_ID || '',
  SALESFORCE_REDIRECT_URL: process.env.REACT_APP_SALESFORCE_REDIRECT_URL || 'http://localhost:3000/salesforce-oauth',
  SERVER_URL: process.env.REACT_APP_SERVER_URL || 'http://localhost:8000',
  SERVER_WEBSOCKET: process.env.REACT_APP_SERVER_WEBSOCKET || 'ws://localhost:8000/ws',
  ZOOM_CLIENT_ID: process.env.REACT_APP_ZOOM_CLIENT_ID || '',
  ZOOM_REDIRECT_URL: process.env.REACT_APP_ZOOM_REDIRECT_URL || 'http://localhost:3000/zoom-oauth',
  GONG_CLIENT_ID: process.env.REACT_APP_GONG_CLIENT_ID || '',
  GONG_REDIRECT_URL: process.env.REACT_APP_GONG_REDIRECT_URL || 'http://localhost:3000/gong-oauth',
  HEAP_ID: process.env.REACT_APP_HEAP_ID || '',
  INTERCOM_ID: process.env.REACT_APP_INTERCOM_ID || '',
  SLACK_CLIENT_ID: process.env.REACT_APP_SLACK_CLIENT_ID || '',
};
