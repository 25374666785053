// eslint-disable-next-line import/no-extraneous-dependencies
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

export interface State {
  calendarMenu: null | 'hover' | 'fixed';
  noteMenu: null | 'hover' | 'fixed';
  setState: (newState: Partial<State>) => State;
  close: () => void;
}

const useSideMenuStore = create(
  persist<State>(
    (set, get) => ({
      calendarMenu: null,
      noteMenu: null,
      setState: (newState: Partial<State>) => {
        set(prevState => ({
          ...prevState,
          ...newState,
        }));
        return get();
      },
      close: () => {
        set(state => ({ ...state, calendarMenu: null, noteMenu: null }));
      },
    }),
    {
      name: 'side-menu-store',
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('SideMenuStore', useSideMenuStore);
}

export default useSideMenuStore;
