import React, { useMemo } from 'react';
import { FieldValue, SuggestedAnswer } from '../../types/field-value';
import SuggestedAnswerView from './SuggestedAnswerView';
import { ChevronUpIcon } from '@heroicons/react/24/outline';
import VerifiedSuggestedAnswer from './VerifiedSuggestedAnswer';
import { sortBy } from 'lodash';
import moment from 'moment';

interface Props {
  fieldValue: FieldValue;
  suggestedAnswers: SuggestedAnswer[];
  updateAnswer: (answer: string) => Promise<void>;
  from: 'discoveryQuestionContextPanel' | 'docEditor' | 'reporting';
}

function SuggestedAnswersView({ fieldValue, suggestedAnswers, updateAnswer, from }: Props) {
  const [isCollapsed, setIsCollapsed] = React.useState<boolean>(true);

  const allSuggestedAnswers = useMemo(() => {
    // convert valueAiSuggestion to suggestedAnswer
    const aiSuggestedAnser: SuggestedAnswer | null = fieldValue.valueAiSuggestion
      ? {
          id: fieldValue.id,
          contextStartTime: 0,
          suggestedAnswer: fieldValue.valueAiSuggestion,
          acceptanceStatus: fieldValue.acceptanceStatusAiSuggestion || undefined,
          createdAt: '',
          fieldId: fieldValue.field.id,
          callId: '',
          callTitle: '',
          callStarted: '',
          callEntityDocumentId: '',
          callEntityName: null,
        }
      : null;
    // show latest suggestion first
    const sortedSuggestedAnswers = sortBy(suggestedAnswers, item => -moment(item.createdAt).valueOf());
    return sortBy(
      // put valueAiSuggestion into suggestedAnswers array
      aiSuggestedAnser ? [aiSuggestedAnser, ...sortedSuggestedAnswers] : sortedSuggestedAnswers,
      item => (item.id === fieldValue.id ? 0 : 100000) + (item.acceptanceStatus ? 10000 : 0),
    );
  }, [fieldValue, suggestedAnswers]);

  const firstSuggestedAnswer = useMemo(
    () =>
      // collapse all suggestions when synthesize suggestion accepted
      fieldValue.acceptanceStatusAiSuggestion === 'accepted'
        ? undefined
        : allSuggestedAnswers.find(item => !item.acceptanceStatus),
    [fieldValue, allSuggestedAnswers],
  );
  const collapsedSuggestedAnswers = useMemo(
    () => allSuggestedAnswers.filter(item => item !== firstSuggestedAnswer),
    [allSuggestedAnswers, firstSuggestedAnswer],
  );

  const btnLabel = useMemo(() => {
    if (isCollapsed) {
      const verifiedSuggestedAnswersCount = allSuggestedAnswers.filter(item => item.acceptanceStatus).length;
      if (collapsedSuggestedAnswers.length !== verifiedSuggestedAnswersCount) {
        return `${collapsedSuggestedAnswers.length} ${
          firstSuggestedAnswer ? 'other' : ''
        } suggested answers, ${verifiedSuggestedAnswersCount} already reviewed`;
      }
      if (firstSuggestedAnswer) {
        return `Other reviewed AI suggestions`;
      }
      return `Reviewed AI suggestions`;
    }
    return 'Hide suggested answers';
  }, [allSuggestedAnswers, isCollapsed, firstSuggestedAnswer]);

  return (
    <div>
      {firstSuggestedAnswer && (
        <SuggestedAnswerView
          fieldValueId={fieldValue.id}
          suggestedAnswer={firstSuggestedAnswer}
          updateAnswer={updateAnswer}
          onReject={() => null}
          from={from}
        />
      )}
      {!!collapsedSuggestedAnswers.length && (
        <>
          <button
            type="button"
            className="flex text-blue-500 font-medium rounded hover:text-blue-600 hover:cursor-pointer items-center pb-1 ml-2"
            onClick={() => setIsCollapsed(prev => !prev)}
          >
            <span className="flex gap-1 items-center text-xs">
              <span>{btnLabel}</span>
              <ChevronUpIcon className={`w-3 h-3 ${isCollapsed && 'rotate-180'}`} />
            </span>
          </button>
          {!isCollapsed &&
            collapsedSuggestedAnswers.map(suggestedAnswer =>
              !suggestedAnswer.acceptanceStatus ? (
                <SuggestedAnswerView
                  key={suggestedAnswer.id}
                  fieldValueId={fieldValue.id}
                  suggestedAnswer={suggestedAnswer}
                  updateAnswer={updateAnswer}
                  onReject={() => null}
                  from={from}
                />
              ) : (
                <VerifiedSuggestedAnswer
                  key={suggestedAnswer.id}
                  fieldValueId={fieldValue.id}
                  suggestedAnswer={suggestedAnswer}
                  updateAnswer={updateAnswer}
                  from={from}
                />
              ),
            )}
        </>
      )}
    </div>
  );
}

export default SuggestedAnswersView;
