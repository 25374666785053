import React, { useState } from 'react';
import errors from 'utils/errors';
import useModalStore from 'stores/modal';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import config from 'utils/config';
import integrationToken from 'api/integration-token-gong';
import Gong from './logos/Gong';

interface State {
  gongAccessKey: string;
  gongAccessKeySecret: string;
  isSetGongTokenLoading: boolean;
  isGongTokenError: boolean;
}

function GongAPITokenModal({ nextStep }: { nextStep: () => void }) {
  const [state, setState] = useState<State>({
    gongAccessKey: '',
    gongAccessKeySecret: '',
    isSetGongTokenLoading: false,
    isGongTokenError: false,
  });
  const modalStore = useModalStore();

  const goToOAuth = (link: string) => {
    if (!state.isSetGongTokenLoading) {
      window.location.href = link;
    }
  };
  const buttonDisabled =
    state.gongAccessKey.length === 0 || state.gongAccessKeySecret.length === 0 || state.isSetGongTokenLoading;

  return (
    <div
      className="absolute left-1/2 bg-white rounded-lg p-3 pr-6 w-[1000px]"
      style={{
        top: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      {/* model header */}
      <div className="flex justify-between items-center px-4 pt-4 pb-2 font-medium text-2xl">Add Gong Integration</div>
      <div className="text-lg font-semibold px-4 pt-2">Method 1.</div>
      <div className="flex justify-between items-center p-4">
        You must be a Gong Technical admin and please get the token from Gong &gt; Company Settings &gt; API (Under
        Ecosystem) &gt; API Keys.
      </div>
      <div className="flex flex-col gap-3 text-gray-500">
        <div className="flex gap-2 items-center pl-4">
          Gong access key
          <input
            className="flex border rounded focus:outline-none focus:border-indigo-300 h-10 w-96 p-2"
            onChange={e => {
              setState(prevState => ({ ...prevState, gongAccessKey: e.target.value }));
            }}
            maxLength={5000}
            value={state.gongAccessKey}
          />
        </div>
        <div className="flex gap-2 items-center pl-4">
          Gong access key secret
          <input
            type="password"
            className="flex border rounded focus:outline-none focus:border-indigo-300 h-10 w-96 p-2"
            onChange={e => {
              setState(prevState => ({ ...prevState, gongAccessKeySecret: e.target.value }));
            }}
            maxLength={5000}
            value={state.gongAccessKeySecret}
          />
        </div>
        {state.isGongTokenError && (
          <div className="text-red-500 text-sm">Error: Please check your Gong token and try again.</div>
        )}
      </div>
      <div className="my-3 flex flex-row-reverse items-center gap-3">
        <button
          className={`px-8 py-2 rounded ${
            buttonDisabled ? 'bg-gray-200 text-gray-500 cursor-default' : 'bg-blue-600 hover:bg-blue-500 text-white'
          }`}
          type="button"
          onClick={async () => {
            setState(prevState => ({ ...prevState, isSetGongTokenLoading: true, isGongTokenError: false }));

            try {
              await integrationToken('gong', state.gongAccessKey, state.gongAccessKeySecret);
              setState(prevState => ({ ...prevState, isSetGongTokenLoading: false }));
              modalStore.close();
              nextStep();
            } catch (e) {
              if (e instanceof errors.IntegrationError) {
                setState(prevState => ({
                  ...prevState,
                  isSetGongTokenLoading: false,
                  isGongTokenError: true,
                }));
              }
            }
          }}
          disabled={buttonDisabled}
        >
          Add
        </button>
      </div>
      <div className="w-full border-b border-gray-200 my-4" />
      <div className="mb-4">
        <div className="text-lg font-semibold px-4">Method 2.</div>
        <div className="flex gap-3 items-center">
          <div className="flex justify-between items-center p-4">
            Connect to Gong via OAuth. (You must be a Gong Technical admin)
          </div>
          <button
            key="gong"
            className="flex items-center justify-between px-3 py-2 border border-gray-200 rounded-lg hover:bg-gray-100 gap-4"
            onClick={() =>
              goToOAuth(
                `https://app.gong.io/oauth2/authorize?client_id=${config.GONG_CLIENT_ID}&response_type=code&redirect_uri=${config.GONG_REDIRECT_URL}`,
              )
            }
            type="button"
          >
            <div className="flex items-center gap-1">
              <Gong className="w-10 h-10" />
              <div className="flex gap-1 items-center">
                <div className="text-lg font-semibold mr-10">Gong</div>
              </div>
            </div>
            <ChevronRightIcon className="w-7 h-7" />
          </button>
        </div>
      </div>
    </div>
  );
}
export default GongAPITokenModal;
