import React from 'react';
import { MenuItem } from '@mui/material';
import { Calculation } from '../../types';

interface Props {
  updateCalculation: (calculation: Calculation | undefined) => void;
}

function BooleanMenuItems({ updateCalculation }: Props) {
  return (
    <>
      <MenuItem onClick={() => updateCalculation(undefined)}>
        <div className="text-sm">None</div>
      </MenuItem>
      <MenuItem
        onClick={() => {
          updateCalculation('Count all');
        }}
      >
        <div className="text-sm">Count all</div>
      </MenuItem>
      <MenuItem onClick={() => updateCalculation('Count empty')}>
        <div className="text-sm">Count empty</div>
      </MenuItem>
      <MenuItem onClick={() => updateCalculation('Count not empty')}>
        <div className="text-sm">Count not empty</div>
      </MenuItem>
      <MenuItem onClick={() => updateCalculation('Percent empty')}>
        <div className="text-sm">Percent empty</div>
      </MenuItem>
      <MenuItem onClick={() => updateCalculation('Percent not empty')}>
        <div className="text-sm">Percent not empty</div>
      </MenuItem>
      <MenuItem onClick={() => updateCalculation('Count True')}>
        <div className="text-sm">Count True</div>
      </MenuItem>
      <MenuItem onClick={() => updateCalculation('Count False')}>
        <div className="text-sm">Count False</div>
      </MenuItem>
      <MenuItem onClick={() => updateCalculation('Percent True')}>
        <div className="text-sm">Percent True</div>
      </MenuItem>
      <MenuItem onClick={() => updateCalculation('Percent False')}>
        <div className="text-sm">Percent False</div>
      </MenuItem>
    </>
  );
}

export default BooleanMenuItems;
