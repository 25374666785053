import React from 'react';

import { CrmFieldType, CrmPicklistValue } from 'types/crm';
import TextFilter from './FieldFilter/TextFilter';
import {
  BooleanFilterType,
  BooleanFilterValue,
  ColumnDataType,
  DateFilterType,
  FilterType,
  FilterValue,
  NumberFilterType,
  PicklistType,
  ReferenceFilterValue,
  TextFilterType,
} from './types';
import { FilterProps } from './FieldFilter/type';
import BooleanFilter from './FieldFilter/BooleanFilter';
import NumberFilter from './FieldFilter/NumberFilter';
import DateFilter from './FieldFilter/DateFilter';
import PicklistFilter from './FieldFilter/PicklistFilter';
import { DataEntryType } from '../../types/reporting';
import TimeFilter from './FieldFilter/TimeFilter';
import SalesforceReferenceFilter from './FieldFilter/SalesforceReferenceFilter';

export interface Props extends FilterProps<FilterType | undefined, FilterValue | undefined> {
  columnDataType: ColumnDataType;
  data: undefined | any;
}

function FieldFilter({ columnDataType, name, type, value, onFilterUpdate, onClose, data }: Props) {
  switch (columnDataType) {
    case 'ReportEntityField': {
      const { dataEntryType, picklistValues, referenceTo } = data as {
        dataEntryType: CrmFieldType;
        picklistValues: CrmPicklistValue[];
        referenceTo: string | null;
      };
      if (
        dataEntryType === 'currency' ||
        dataEntryType === 'double' ||
        dataEntryType === 'int' ||
        dataEntryType === 'percent'
      ) {
        return (
          <NumberFilter
            name={name}
            type={(type as NumberFilterType) || '='}
            value={(value as string) || ''}
            onFilterUpdate={onFilterUpdate}
            onClose={onClose}
          />
        );
      }

      if (dataEntryType === 'boolean') {
        return (
          <BooleanFilter
            name={name}
            type={(type as BooleanFilterType) || 'Is'}
            value={(value as BooleanFilterValue) || undefined}
            onFilterUpdate={onFilterUpdate}
            onClose={onClose}
          />
        );
      }

      if (dataEntryType === 'date' || dataEntryType === 'datetime') {
        let dateValue: undefined | Date | Date[];
        if (type && value) {
          if (type === 'Is between') {
            const filterValue = value as string[];
            dateValue = [new Date(filterValue[0]), new Date(filterValue[1])];
          } else {
            const filterValue = value as string;
            dateValue = new Date(filterValue);
          }
        }
        return (
          <DateFilter
            name={name}
            type={(type as DateFilterType) || 'Is'}
            value={dateValue}
            onFilterUpdate={onFilterUpdate}
            onClose={onClose}
          />
        );
      }

      if (dataEntryType === 'time') {
        return (
          <TimeFilter
            name={name}
            type={(type as DateFilterType) || 'Is'}
            value={value as string}
            onFilterUpdate={onFilterUpdate}
            onClose={onClose}
          />
        );
      }

      if (dataEntryType === 'picklist' || dataEntryType === 'multipicklist') {
        return (
          <PicklistFilter
            picklistOptions={picklistValues.map(p => p.label)}
            name={name}
            type={(type as PicklistType) || 'Is'}
            value={(value as string[]) || []}
            onFilterUpdate={onFilterUpdate}
            onClose={onClose}
          />
        );
      }

      if (dataEntryType === 'reference') {
        return (
          <SalesforceReferenceFilter
            name={name}
            type={(type as PicklistType) || 'Is'}
            value={(value as ReferenceFilterValue[]) || []}
            referenceTo={referenceTo as string}
            onFilterUpdate={onFilterUpdate}
            onClose={onClose}
          />
        );
      }

      return (
        <TextFilter
          name={name}
          type={(type as TextFilterType) || 'Contains'}
          value={(value as string) || ''}
          onFilterUpdate={onFilterUpdate}
          onClose={onClose}
        />
      );
    }
    case 'ReportField': {
      const { dataEntryType } = data as {
        dataEntryType: DataEntryType;
      };
      if (dataEntryType === 'number') {
        return (
          <NumberFilter
            name={name}
            type={(type as NumberFilterType) || '='}
            value={(value as string) || ''}
            onFilterUpdate={onFilterUpdate}
            onClose={onClose}
          />
        );
      }

      if (dataEntryType === 'boolean') {
        return (
          <BooleanFilter
            name={name}
            type={(type as BooleanFilterType) || 'Is'}
            value={(value as BooleanFilterValue) || undefined}
            onFilterUpdate={onFilterUpdate}
            onClose={onClose}
          />
        );
      }
      return (
        <TextFilter
          name={name}
          type={(type as TextFilterType) || 'Contains'}
          value={(value as string) || ''}
          onFilterUpdate={onFilterUpdate}
          onClose={onClose}
        />
      );
    }
    case 'Date': {
      let dateValue: undefined | Date | Date[];
      if (type && value) {
        if (type === 'Is between') {
          const filterValue = value as string[];
          dateValue = [new Date(filterValue[0]), new Date(filterValue[1])];
        } else {
          const filterValue = value as string;
          dateValue = new Date(filterValue);
        }
      }
      return (
        <DateFilter
          name={name}
          type={(type as DateFilterType) || 'Is'}
          value={dateValue}
          onFilterUpdate={onFilterUpdate}
          onClose={onClose}
        />
      );
    }
    case 'ReportCalculatedField':
    case 'Number':
    case 'Currency':
      return (
        <NumberFilter
          name={name}
          type={(type as NumberFilterType) || '='}
          value={(value as string) || ''}
          onFilterUpdate={onFilterUpdate}
          onClose={onClose}
        />
      );
    case 'Boolean':
      return (
        <BooleanFilter
          name={name}
          type={(type as BooleanFilterType) || 'Is'}
          value={(value as BooleanFilterValue) || undefined}
          onFilterUpdate={onFilterUpdate}
          onClose={onClose}
        />
      );
    default:
      return (
        <TextFilter
          name={name}
          type={(type as TextFilterType) || 'Contains'}
          value={(value as string) || ''}
          onFilterUpdate={onFilterUpdate}
          onClose={onClose}
        />
      );
  }
}

export default FieldFilter;
