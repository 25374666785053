import React from 'react';

interface Props {
  className?: string;
}

function MenuCollapseIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="5.33333" transform="matrix(0 -1 -1 0 32 32)" fill="#F5F7FC" />
      <path
        d="M14.6113 20.6667L9.33333 15.3334L14.6113 10.0001"
        stroke="#566480"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 20.6667L16.722 15.3334L22 10.0001"
        stroke="#566480"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

MenuCollapseIcon.defaultProps = {
  className: undefined,
};

export default MenuCollapseIcon;
