import { AttendeePure, Document } from 'types/document';
import httpClient from '../http-client';

export interface CheckDocument {
  showCallRecordingWarning: boolean;
  showSuggestedAnswersWarning: boolean;
  showDiscoveryQuestionAnswerWarning: boolean;
  showSalesforceNoteWarning: boolean;
  attendeesToAddToCRM: AttendeePure[];
  extraDocumentsToAssign: Document[];
}

export default async (documentId: string): Promise<CheckDocument> => {
  const { data } = await httpClient.get<CheckDocument>(`/documents/${documentId}/check-opportunity`);
  return data;
};
