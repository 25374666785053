/* eslint-disable import/no-extraneous-dependencies */
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { create } from 'zustand';
import getChats from '../api/chats/get-chats';
import clearChats from '../api/chats/clear-chats';
import createChats from '../api/chats/create-chats';
import { Chat } from '../types/chat';

interface State {
  chats: Chat[];
  isLoading: boolean;
  awaitingResponse: boolean;
  documentId: string;
  setState: (newState: Partial<State>) => State;
  fetch: (documentId: string) => Promise<void>;
  clearMessages: () => Promise<void>;
  sendMessage: (message: string) => Promise<void>;
}

const useChatsStore = create<State>((set, get) => ({
  chats: [],
  isLoading: false,
  awaitingResponse: false,
  documentId: '',
  setState: (newState: Partial<State>) => {
    set(state => {
      return { ...state, ...newState };
    });
    return get();
  },
  fetch: async (documentId: string): Promise<void> => {
    set(state => {
      return {
        ...state,
        chats: [],
        isLoading: true,
        documentId,
      };
    });
    const chats = await getChats(documentId);

    set(state => {
      return { ...state, chats, isLoading: false };
    });
  },
  clearMessages: async (): Promise<void> => {
    set(state => {
      return {
        ...state,
        isLoading: true,
      };
    });
    await clearChats(get().documentId);
    set(state => {
      return {
        ...state,
        chats: [],
        isLoading: false,
      };
    });
  },
  sendMessage: async (message: string): Promise<void> => {
    set(state => {
      return {
        ...state,
        awaitingResponse: true,
      };
    });

    const { chats } = await createChats(get().documentId, message);

    set(state => {
      return {
        ...state,
        chats: [...state.chats, ...chats],
        awaitingResponse: false,
      };
    });
  },
}));

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('Chats', useChatsStore);
}

export default useChatsStore;
