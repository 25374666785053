import { Parent } from 'types/document';
import httpClient from '../http-client';

export interface SPParty {
  id?: string;
  emailAddress?: string;
  name?: string;
  speakerId?: string;
  affiliation?: string; // Allowed: External, Internal, Unknown
  phoneNumber?: string;
}

export interface CallData {
  id: string;
  url?: string;
  audioUrl?: string;
  videoUrl?: string;
  title?: string;
  scheduled?: string;
  started?: string;
  duration?: number;
  media?: string;
  parties: SPParty[];
  // we are omitting rawData, it's either gong raw data or chorus raw data, this filed is not used in frontend.
  // rawData: Union[GongCall, ChorusEngagement]
}

interface documentTitleGroup {
  acct: string | null;
  opp: string | null;
  doc: string | null;
}

interface callDocument {
  id?: string;
  docTitleGroup?: documentTitleGroup;
  parent?: Parent | null;
  entityName?: null | 'Opportunity' | 'Account';
  isProtected: boolean;
  isEvent?: boolean;
}

export interface Recording {
  id: string;
  callData: CallData;
  crmOpportunityId: null | string;
  crmAccountId: null | string;
  entityOrDocumentId: null | string;
  document: null | callDocument;
}

export interface Response {
  recordings: Recording[];
}
export default async (): Promise<Response> => {
  const { data } = await httpClient.get<Response>(`/recordings`);
  return data;
};
