import React, { useRef } from 'react';
import { Invitation } from '../../types/member';
import useUserStore from '../../stores/user';
import usePopupMessageStore from '../../stores/popup-message';
import { concat } from 'utils/styling';
import { Switch, Tooltip } from '@mui/material';
import DropdownIcon from 'components/icons/DropdownIcon';
import Popover from '../../components/Popover';
import RoleMenuDropdown from './RoleMenuDropdown';
import updateInvites from 'api/user/update-invites';
import ThreeVerticalDotsIcon from 'components/icons/ThreeVerticalDotsIcon';
import MenuItem from '@mui/material/MenuItem';
import inviteNewUser from 'api/user/invite-new-user';
import deleteInvites from 'api/user/delete-invites';

interface Props {
  invitation: Invitation;
}

function InvitationEntry({ invitation }: Props) {
  const roleButtonRef = useRef<HTMLButtonElement>(null);
  const actionButtonRef = useRef<HTMLButtonElement>(null);
  const [showRoleDropdown, setShowRoleDropdown] = React.useState<boolean>(false);
  const [showActionDropdown, setShowActionDropdown] = React.useState<boolean>(false);

  const userStore = useUserStore();
  const popupMessageStore = usePopupMessageStore();

  const disableChanges = invitation.status !== 'pending';
  return (
    <div className="flex h-12 items-center py-10 gap-5">
      <div className="w-full">
        <div>
          <div className="text-xs text-gray-400">Email</div>
          <div className="flex gap-4 h-10 items-center">
            <div>{invitation.email}</div>
            <div
              className={concat(
                `text-xs capitalize font-medium h-5 rounded-full p-0.5 px-4 text-center align-middle`,
                invitation.status === 'expired' ? 'text-red-400 bg-red-50' : 'text-orange-400 bg-orange-50',
              )}
            >
              {invitation.status}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="text-xs text-gray-400">Role</div>
        <button
          ref={roleButtonRef}
          disabled={disableChanges}
          type="button"
          className="h-10 rounded py-1.5 px-3 text-gray-900 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 w-52 disabled:cursor-not-allowed disabled:opacity-50"
          onClick={() => setShowRoleDropdown(!showRoleDropdown)}
        >
          <Tooltip
            arrow
            title={disableChanges ? 'Can only be changed for other active or pending users' : ''}
            placement="top"
          >
            <div className="flex w-full justify-between">
              <span>{invitation.role}</span>
              <DropdownIcon className="h-5 w-5 text-gray-400" />
            </div>
          </Tooltip>
        </button>
        <Popover
          anchorEl={roleButtonRef.current}
          onClose={() => setShowRoleDropdown(false)}
          isOpen={showRoleDropdown}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <RoleMenuDropdown
            currentRole={invitation.role}
            onChange={async role => {
              await updateInvites(invitation.email, role, invitation.isAdmin);
              popupMessageStore.success('Member updated successfully');
              await userStore.fetchMembers();
            }}
          />
        </Popover>
      </div>
      <div>
        <label htmlFor="isAdmin" className="text-xs text-gray-400">
          Admin
        </label>
        <div className="h-10 flex items-center">
          <Tooltip
            arrow
            title={disableChanges ? 'Can only be changed for other active or pending users' : ''}
            placement="top"
          >
            <span className={disableChanges ? 'cursor-not-allowed' : ''}>
              <Switch
                id="isAdmin"
                checked={invitation.isAdmin}
                disabled={disableChanges}
                sx={{
                  '&.MuiSwitch-root .Mui-checked': {
                    color: `${disableChanges ? 'rgba(29,188,134,0.5)' : '#1DBC86'}`,
                  },
                  '&.MuiSwitch-root .MuiSwitch-track': {
                    backgroundColor: `${invitation.isAdmin ? '#1DBC86' : '#000'} !important`,
                  },
                }}
                onChange={async () => {
                  await updateInvites(invitation.email, invitation.role, !invitation.isAdmin);
                  popupMessageStore.success('Member updated successfully');
                  await userStore.fetchMembers();
                }}
              />
            </span>
          </Tooltip>
        </div>
      </div>
      <div>
        <button ref={actionButtonRef} type="button" onClick={() => setShowActionDropdown(!showActionDropdown)}>
          <div>
            <ThreeVerticalDotsIcon className="w-11 h-11 p-2.5 rounded-lg bg-slate-100 text-gray-400" />
          </div>
        </button>
        <Popover
          anchorEl={actionButtonRef.current}
          onClose={() => setShowActionDropdown(false)}
          isOpen={showActionDropdown}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          <div>
            <MenuItem
              onClick={async () => {
                setShowActionDropdown(false);
                await inviteNewUser(invitation.email, invitation.role, invitation.isAdmin);
                popupMessageStore.success('Invitation has been sent!');
                userStore.fetchMembers();
              }}
            >
              <span className="text-sm">Resend Invite</span>
            </MenuItem>
            <MenuItem
              onClick={async () => {
                setShowActionDropdown(false);
                await deleteInvites(invitation.email);
                popupMessageStore.success('Invite has been deleted!');
                userStore.fetchMembers();
              }}
            >
              <span className="text-red-500 text-sm">Delete Invite </span>
            </MenuItem>
          </div>
        </Popover>
      </div>
    </div>
  );
}

export default InvitationEntry;
