/* eslint-disable no-param-reassign, import/no-extraneous-dependencies  */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { create } from 'zustand';
import lodash from 'lodash';
import createReportingColumns from 'api/reporting/create-reporting-columns';
import createAnalyticTableColumn from 'api/reporting/create-analytic-table-column';
import deleteAnalyticTableColumns from 'api/reporting/delete-analytic-table-columns';
import getReportingColumns from 'api/reporting/get-reporting-columns';
import getReportingRows from 'api/reporting/get-reporting-rows';

import {
  EntityGroupHeader,
  ReportingCalculation,
  ReportingColumn,
  ReportingFilter,
  ReportingSort,
  TableCalculation,
  TableColumn,
  TableRow,
} from 'types/reporting';
import { SuggestedAnswer } from 'types/field-value';
import updateAnalyticEntryValue from '../api/reporting/update-analytic-entry-value';
import updateAnalyticTableColumn from '../api/reporting/update-analytic-table-column';
import triggerAiAnalyticTableEntry from '../api/reporting/trigger-ai-analytic-table-entry';

const DEFAULT_REPORTING_RESPONSE_PER_PAGE = 50;

function findStatusKey(value: any) {
  if (typeof value !== 'object') return false;
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const key in value) {
    if (key === 'status') {
      if (['new', 'pending'].includes(value[key] || '')) {
        return true;
      }
    }
    if (typeof value[key] === 'object') {
      if (findStatusKey(value[key])) return true;
    }
  }
  return false;
}

function checkIfRowNeedsUpdate(tableRows: TableRow[]) {
  // eslint-disable-next-line no-restricted-syntax
  for (const row in tableRows) {
    if (findStatusKey(tableRows[row])) return true;
  }
  return false;
}

export interface State {
  columns: ReportingColumn[] | null;
  filters: ReportingFilter[] | null;
  sorts: ReportingSort[] | null;
  calculations: ReportingCalculation[] | null;
  page: number;
  totalPages: number;
  perPage: number;
  isFilteringAndSorting: boolean;
  isBackgroundFetching: boolean;
  tableColumns: TableColumn[];
  tableRows: TableRow[];
  tableCalculation: TableCalculation;
  suggestionByFieldValueId: {
    [fieldValueId: string]: SuggestedAnswer;
  };
  removeSuggestions: (fieldValueId: string) => void;
  createdFieldIds: string[]; // tracking ids that user created which is used to figure out if table column should be visible
  createdColumnIds: string[]; // tracking ids that user deleted which is used to figure out if table column should be visible
  setState: (newState: Partial<State>) => State;
  fetchTableColumns: () => Promise<void>;
  fetchTableRowsTime: Date;
  fetchTableRows: (skipEntryJobCreation: boolean) => Promise<void>;
  reset: () => void;
  updatePage: (page: number) => Promise<void>;
  updateFiltersAndSort: (
    columns: ReportingColumn[],
    filters: ReportingFilter[],
    sorts: ReportingSort[],
    calculations: ReportingCalculation[],
    skip: boolean,
  ) => Promise<void>;
  createAnalyticTableColumns: (fieldIds: string[]) => Promise<void>;
  createAnalyticTableColumn: (
    fieldId: string,
    columnName: string,
    description: string,
    dataType: 'string' | 'number' | 'boolean',
  ) => Promise<void>;
  deleteAnalyticTableColumns: (columnIds: string[]) => Promise<void>;
  updateColumns: () => Promise<void>;
  updateAnalyticTableColumn: (analyticColumnId: string, columnName: string, description: string) => Promise<void>;
  updateAnalyticTableEntry: (
    fieldId: string,
    analyticColumnId: string,
    fieldValueId: string,
    value: any,
  ) => Promise<void>;
  triggerAiAnalyticTableEntry: (fieldId: string, fieldValueId: string, columnIds: string[]) => Promise<void>;
  updateFieldValueId: (fieldValueId: string, fieldId: string, value: string) => Promise<void>;
}

const useReportingStore = create<State>((set, get) => ({
  columns: null,
  filters: null,
  sorts: null,
  calculations: null,
  page: 1,
  totalPages: 0,
  perPage: DEFAULT_REPORTING_RESPONSE_PER_PAGE,
  isFilteringAndSorting: false,
  isBackgroundFetching: false,
  tableColumns: [],
  tableRows: [],
  tableCalculation: {},
  suggestionByFieldValueId: {},
  removeSuggestions: (fieldValueId: string) => {
    set(state => {
      const suggestionByFieldValueId = { ...state.suggestionByFieldValueId };
      delete suggestionByFieldValueId[fieldValueId];
      return {
        ...state,
        suggestionByFieldValueId,
      };
    });
  },
  fields: [],
  createdFieldIds: [],
  createdColumnIds: [],
  setState: (newState: Partial<State>) => {
    set(state => {
      return { ...state, ...newState };
    });
    return get();
  },
  fetchTableColumns: async () => {
    const tableColumns = await getReportingColumns();
    set(state => {
      return {
        ...state,
        tableColumns,
      };
    });
  },
  fetchTableRowsTime: new Date(),
  fetchTableRows: async (skipEntryJobCreation: boolean) => {
    const fetchTableRowsTime = new Date();
    set(state => ({ ...state, fetchTableRowsTime }));
    const { tableRows, tableCalculation, totalPages, perPage, suggestionByFieldValueId } = await getReportingRows(
      get().columns || [],
      get().filters || [],
      get().sorts || [],
      get().calculations || [],
      get().page,
      skipEntryJobCreation,
    );

    // Make sure the latest row fetch get stored in the state as fetchTableRows can be called multiple places
    if (fetchTableRowsTime < get().fetchTableRowsTime) return;

    set(state => {
      return {
        ...state,
        tableRows,
        tableCalculation,
        totalPages,
        suggestionByFieldValueId,
        perPage: perPage || DEFAULT_REPORTING_RESPONSE_PER_PAGE,
      };
    });
  },
  reset: () => {
    set(state => ({
      ...state,
      filters: null,
      sorts: null,
      calculations: null,
      page: 1,
      totalPages: 0,
      isFilteringAndSorting: false,
      tableColumns: [],
      tableRows: [],
      tableCalculation: {},
      createdFieldIds: [],
      createdColumnIds: [],
    }));
  },
  updatePage: async (page: number) => {
    if (get().page !== page) {
      set(state => ({ ...state, page, isFilteringAndSorting: true }));
      await get().fetchTableRows(false);
      set(state => ({ ...state, isFilteringAndSorting: false }));
    }
  },
  updateFiltersAndSort: async (columns, filters, sorts, calculations, skip) => {
    if (
      !lodash.isEqual(columns, get().columns) ||
      !lodash.isEqual(filters, get().filters) ||
      !lodash.isEqual(sorts, get().sorts) ||
      !lodash.isEqual(calculations, get().calculations)
    ) {
      set(state => {
        return {
          ...state,
          columns,
          filters,
          sorts,
          calculations,
        };
      });
      set(state => ({ ...state, isFilteringAndSorting: true, page: 1 }));

      await get().fetchTableRows(skip);
      set(state => ({ ...state, isFilteringAndSorting: false }));
    }
  },
  createAnalyticTableColumns: async (fieldIds: string[]) => {
    const tableColumns = await createReportingColumns(fieldIds);
    await get().fetchTableRows(true);

    const fieldColumns = tableColumns.filter(tc => tc.type === 'field');

    const accountEntityColumn = tableColumns.find(tc => tc.type === 'entity' && tc.entityName === 'Account');
    const opportunityEntityColumn = tableColumns.find(tc => tc.type === 'entity' && tc.entityName === 'Opportunity');

    set(state => {
      const newTableColumns: TableColumn[] = state.tableColumns.map(tc => {
        if (accountEntityColumn && tc.type === 'entity' && tc.entityName === 'Account') {
          return {
            ...tc,
            columns: [...tc.columns, ...accountEntityColumn.columns],
          } as EntityGroupHeader;
        }

        if (opportunityEntityColumn && tc.type === 'entity' && tc.entityName === 'Opportunity') {
          return {
            ...tc,
            columns: [...tc.columns, ...opportunityEntityColumn.columns],
          } as EntityGroupHeader;
        }
        return tc;
      });
      return {
        ...state,
        tableColumns: [...newTableColumns, ...fieldColumns],
        createdFieldIds: [...state.createdFieldIds, ...fieldIds],
      };
    });
  },
  createAnalyticTableColumn: async (fieldId, columnName, description, dataType) => {
    const tableColumn = await createAnalyticTableColumn(
      fieldId,
      columnName,
      description,
      dataType,
      get().columns || [],
      get().filters || [],
      get().sorts || [],
    );

    set(state => {
      return {
        ...state,
        createdColumnIds: [...state.createdColumnIds, tableColumn.id],
        tableColumns: state.tableColumns.map(tc => {
          if (tc.type === 'field' && tc.fieldId === fieldId) {
            return {
              ...tc,
              columns: [...tc.columns, tableColumn],
            };
          }
          return tc;
        }),
      };
    });
  },
  deleteAnalyticTableColumns: async columnIds => {
    await deleteAnalyticTableColumns(columnIds);
    set(state => {
      const tableColumns = state.tableColumns.map(tc => {
        if (tc.type === 'field') {
          const columns = tc.columns.filter(c => !columnIds.includes(c.id));
          return {
            ...tc,
            columns,
          };
        }
        return tc;
      });
      return {
        ...state,
        tableColumns,
      };
    });
  },
  updateColumns: async () => {
    const { tableColumns, tableRows, isBackgroundFetching } = get();
    if (isBackgroundFetching) return;

    let isColumnsNeedUpdate = false;
    for (let i = 0; i < tableColumns.length; i += 1) {
      const tc = tableColumns[i];

      if ('fieldId' in tc) {
        if (tc.status === 'new' || tc.status === 'pending') {
          isColumnsNeedUpdate = true;
        }
      } else {
        for (let j = 0; j < tc.columns.length; j += 1) {
          const c = (tc as EntityGroupHeader).columns[j];
          if (c.status === 'new') {
            isColumnsNeedUpdate = true;
          }
        }
      }
    }

    // only update new or pending status entries, otherwise, run into race condition if user are updating the entry at the same time
    try {
      if (isColumnsNeedUpdate) {
        set(state => ({ ...state, isBackgroundFetching: true }));
        await get().fetchTableColumns();
        await get().fetchTableRows(false);
      } else if (checkIfRowNeedsUpdate(tableRows)) {
        set(state => ({ ...state, isBackgroundFetching: true }));
        await get().fetchTableRows(false);
      }
    } catch (e) {
      // no console
    }
    set(state => ({ ...state, isBackgroundFetching: false }));
  },
  updateAnalyticTableColumn: async (analyticColumnId, columnName, description) => {
    const analyticColumnSubHeader = await updateAnalyticTableColumn(analyticColumnId, columnName, description);
    set(state => {
      return {
        ...state,
        tableColumns: state.tableColumns.map(tc => {
          if (tc.type === 'field') {
            return {
              ...tc,
              columns: tc.columns.map(c => {
                if (c.id === analyticColumnId) {
                  return analyticColumnSubHeader;
                }
                return c;
              }),
            };
          }
          return tc;
        }),
      };
    });
  },
  updateAnalyticTableEntry: async (fieldId, analyticColumnId, fieldValueId, value) => {
    const res = await updateAnalyticEntryValue(analyticColumnId, fieldValueId, value);
    set(state => {
      return {
        ...state,
        tableRows: state.tableRows.map(row => {
          if (fieldId in row && row[fieldId].fieldValueId === fieldValueId && analyticColumnId in row[fieldId]) {
            return {
              ...row,
              [fieldId]: {
                ...row[fieldId],
                [analyticColumnId]: {
                  status: res.status,
                  value: res.value,
                },
              },
            };
          }
          return row;
        }),
      };
    });
  },
  triggerAiAnalyticTableEntry: async (fieldId: string, fieldValueId: string, columnIds: string[]): Promise<void> => {
    await triggerAiAnalyticTableEntry(fieldValueId, columnIds);
    set(state => {
      return {
        ...state,
        tableRows: state.tableRows.map(row => {
          if (fieldId in row && row[fieldId].fieldValueId === fieldValueId) {
            Object.keys(row[fieldId]).forEach(key => {
              if (columnIds.includes(key)) {
                row[fieldId][key].status = 'pending';
              }
            });
          }
          return row;
        }),
      };
    });
  },
  updateFieldValueId: async (fieldValueId: string, fieldId: string, value: string) => {
    set(state => {
      return {
        ...state,
        tableRows: state.tableRows.map(row => {
          if (row.Account && row.Account[fieldId] && row.Account[fieldId].fieldValueId === fieldValueId) {
            row.Account[fieldId].value = value;
          }

          if (row.Opportunity && row.Opportunity[fieldId] && row.Opportunity[fieldId].fieldValueId === fieldValueId) {
            row.Opportunity[fieldId].value = value;
          }

          if (fieldId in row && row[fieldId].fieldValueId === fieldValueId) {
            row[fieldId].answer = value;
          }

          return row;
        }),
      };
    });
  },
}));

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('Reporting', useReportingStore);
}
export default useReportingStore;
