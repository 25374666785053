import React, { useState } from 'react';
import BaseButton from '../../components/BaseButton';
import useUserStore from '../../stores/user';
import usePopupMessageStore from '../../stores/popup-message';

function AiSettingView() {
  const userStore = useUserStore();
  const popupMessageStore = usePopupMessageStore();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEdited, setIsEdited] = useState<boolean>(false);
  const [context, setContext] = useState<string>(userStore.user?.teamContext || '');

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    await userStore.updateUser({
      teamContext: context,
    });
    popupMessageStore.success('AI settings has been updated');
    setIsEdited(false);
    setIsLoading(false);
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="flex justify-between">
        <div>
          <div className="text-xl font-medium">AI Settings</div>
          <div className="py-2" />
          <div className="text-sm text-slate-500">Manage AI related settings here.</div>
        </div>
        <div>
          <BaseButton
            type="submit"
            variant="contained"
            className="!py-5 !px-6"
            disabled={!isEdited}
            loading={isLoading}
          >
            Save
          </BaseButton>
        </div>
      </div>
      <div className="py-6" />
      <div>
        <div className="text-xl font-medium">AI Context</div>
        <div className="py-2" />
        <div className="text-sm text-slate-500">
          Provide the AI some context about your company, your products, sales methodology, specific terminology, lingo,
          or the meaning of your Salesforce fields, etc
        </div>
        <textarea
          name="about"
          rows={3}
          className="block w-full min-h-[30rem] mt-1.5 rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          placeholder="Our company A has products B that solve C. We measure our sales process by X, Y, and Z."
          value={context}
          onChange={e => {
            setIsEdited(true);
            setContext(e.target.value);
          }}
          maxLength={3500}
        />
      </div>
    </form>
  );
}

export default AiSettingView;
