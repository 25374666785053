import React, { forwardRef, useEffect } from 'react';
import { ProductCategory } from 'types/product-gap';
import useProductGapStore from 'stores/product-gap';
import { min, noop } from 'lodash';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import SelectField from './Form/SelectField';
import { openDeleteConfirmModal } from './BaseModal';
import BaseButton from './BaseButton';
import ProductboardComponentIcon from './icons/ProductboardComponentIcon';
import useUserStore from 'stores/user';
import { concat } from 'utils/styling';

interface Props {
  className?: string;
  selectedCategoryId: string | null;
  onSelect: (category: ProductCategory | null) => void;
  onEdit?: (category: ProductCategory) => void;
  onDelete?: (category: ProductCategory) => void;
  disabled?: boolean;
}

// This component only shows the categories for Productboard Features.
const ProductGapCategorySelector = forwardRef<HTMLInputElement, Props>(
  ({ className, selectedCategoryId, onSelect, onEdit, onDelete, disabled }: Props, ref) => {
    const productGapStore = useProductGapStore();
    const userStore = useUserStore();

    useEffect(() => {
      if (!productGapStore.isLoading && !productGapStore.loaded && userStore.user?.productProvider) {
        productGapStore.fetch(userStore.user?.productProvider === 'Superpanel');
      }
    }, [userStore.user?.productProvider, productGapStore.loaded, productGapStore.isLoading]);

    return (
      <SelectField
        className={className}
        placeholder="Select Category"
        showClearBtn
        ref={ref}
        value={selectedCategoryId || ''}
        onChange={newV => onSelect(productGapStore.productCategories.find(cat => cat.id === newV) || null)}
        menus={productGapStore.productCategories.map(item => ({
          icon: <ProductboardComponentIcon className="w-2 h-2 fill-gray-300" />,
          label: item.title || '',
          value: item.id,
          description: item.integration?.parent.component?.name || item.description || '',
          item,
        }))}
        loading={productGapStore.isLoading}
        disabled={disabled}
        localSearchAble
        popoverMinWidth={Math.min(400, document.body.clientWidth)}
        renderMenu={(menu, isDisplayValue) => (
          <>
            <div className="text-left flex-1 w-[calc(100%-8rem)]">
              {menu.description && (
                <div className="text-xs w-full truncate flex items-center gap-2 text-gray-400">
                  <ProductboardComponentIcon className="w-2 h-2 fill-gray-300" />
                  <div className="flex-1 truncate">{menu.description}</div>
                </div>
              )}
              <div className={concat('text-sm font-medium', isDisplayValue ? 'truncate' : 'whitespace-pre-wrap')}>
                {menu.label}
              </div>
            </div>
            <div className="hide-when-show-as-value">
              {!!onEdit && (
                <BaseButton
                  className="!min-w-[2rem] !h-8"
                  tooltip="Edit category"
                  hideTooltipToPreventJump
                  color="secondary"
                  variant="text"
                  iconBtn
                  onClick={() => onEdit(menu.item as ProductCategory)}
                >
                  <PencilSquareIcon className="w-6 h-6" />
                </BaseButton>
              )}
              {!!onDelete && (
                <BaseButton
                  className="!min-w-[2rem] !h-8"
                  tooltip="Delete category"
                  hideTooltipToPreventJump
                  color="error"
                  variant="text"
                  iconBtn
                  onClick={e => {
                    e.stopPropagation();
                    openDeleteConfirmModal(
                      `Are you sure you want to delete this category?`,
                      'This action cannot be undone.',
                      async () => {
                        try {
                          await productGapStore.deleteCategory(menu.value as string);
                          if (selectedCategoryId === menu.value) {
                            onSelect(null);
                          }
                          return;
                        } catch {
                          noop();
                        }
                      },
                    );
                  }}
                >
                  <TrashIcon className="w-6 h-6" />
                </BaseButton>
              )}
            </div>
          </>
        )}
      />
    );
  },
);
ProductGapCategorySelector.displayName = 'ProductGapCategorySelector';
ProductGapCategorySelector.defaultProps = {
  className: '',
  onEdit: undefined,
  onDelete: undefined,
  disabled: undefined,
};

export default ProductGapCategorySelector;
