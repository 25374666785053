/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import useUserStore from 'stores/user';
import { concat } from '../utils/styling';
import UserSettingView from './Settings/UserSettingView';
import AiSettingView from './Settings/AiSettingView';
import IntegrationSettingView from './Settings/IntegrationSettingView';
import TeamSettingView from './Settings/TeamSettingView';

type Tabs = 'user-settings' | 'ai-settings' | 'integrations' | 'team';

function Settings() {
  const userStore = useUserStore();

  const [tabs, setTabs] = useState<Tabs>('user-settings');

  useEffect(() => {
    userStore.fetchMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="h-full">
      <div className="border-b border-gray-300 pl-8 h-[6.4rem]">
        <div className="flex items-center">
          <div className="text-2xl font-medium py-4">Settings</div>
        </div>
        <div className="flex items-center gap-10">
          <button
            type="button"
            className={concat('py-2', tabs === 'user-settings' && 'border-b-2 border-b-blue-500 text-blue-500')}
            onClick={() => setTabs('user-settings')}
          >
            User Settings
          </button>
          <button
            type="button"
            className={concat('py-2', tabs === 'ai-settings' && 'border-b-2 border-b-blue-500 text-blue-500')}
            onClick={() => setTabs('ai-settings')}
          >
            AI Settings
          </button>
          <button
            type="button"
            className={concat('py-2', tabs === 'integrations' && 'border-b-2 border-b-blue-500 text-blue-500')}
            onClick={() => setTabs('integrations')}
          >
            Integrations
          </button>
          <button
            type="button"
            className={concat('py-2', tabs === 'team' && 'border-b-2 border-b-blue-500 text-blue-500')}
            onClick={() => setTabs('team')}
          >
            Team
          </button>
        </div>
      </div>
      <div className="flex justify-center py-10 h-[calc(100%-6.4rem)] overflow-y-auto">
        <div className="w-3/5">
          {tabs === 'user-settings' && <UserSettingView />}
          {tabs === 'ai-settings' && <AiSettingView />}
          {tabs === 'integrations' && <IntegrationSettingView />}
          {tabs === 'team' && <TeamSettingView />}
          <div className="py-20">&nbsp;</div>
        </div>
      </div>
    </div>
  );
}
export default Settings;
