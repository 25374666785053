import httpClient from '../http-client';
import { AnalyticColumnSubHeader } from '../../types/reporting';

export default async (
  analyticColumnId: string,
  columnName: string,
  description: string,
): Promise<AnalyticColumnSubHeader> => {
  const { data } = await httpClient.post<AnalyticColumnSubHeader>(`/reporting-columns/${analyticColumnId}`, {
    columnName,
    description,
  });
  return data;
};
