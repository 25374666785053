import React, { useEffect, useMemo, useState } from 'react';

import useRecordingsStore from 'stores/recordings';
import { ArrowDownOnSquareIcon } from '@heroicons/react/24/outline';
import AIICon from 'components/icons/AIIcon';
import SpinLoader from 'components/icons/SpinLoader';
import useNoteEditorStore from 'stores/note-editor';
import PlayBack from 'components/FieldValueView/PlayBack';
import updateSuggestedProductFeedbackStatus from 'api/product-gaps/update-product-feedback-suggestion';
import Tooltip from 'components/Tooltip';

import { ProductFeedbackItem } from 'types/recording';
import useProductGapStore from 'stores/product-gap';
import { concat } from 'utils/styling';

interface State {
  isShowing: boolean;
  isLoading: boolean;
  isCopied: boolean;
}

function SuggestedProductFeedbackItem({
  item,
  idx,
  recordingId,
  documentId,
}: {
  item: ProductFeedbackItem;
  idx: number;
  recordingId: string;
  documentId: string;
}) {
  const [state, setState] = useState<boolean>(false);
  const noteEditorStore = useNoteEditorStore();
  const recordingsStore = useRecordingsStore();
  const productGapStore = useProductGapStore();

  const createProductGap = async (title: string, feedback: string, suggestedProductFeedbackId?: string) => {
    setState(true);
    const fieldAnswers: { [key: string]: string } = {};
    productGapStore.productFields.every(field => {
      if (field.title === 'Title' && field.isMandatory) {
        fieldAnswers[field.id] = title;
        return true;
      }
      if (field.title === 'Feedback' && field.isMandatory) {
        fieldAnswers[field.id] = feedback;
        return true;
      }
      return true;
    });
    const productGap = await productGapStore.createGap(
      null,
      documentId,
      fieldAnswers,
      null,
      suggestedProductFeedbackId,
    );
    setState(false);
    return productGap.id;
  };

  return (
    <div className="gap-2">
      <div className="flex items-start gap-2">
        <div className="whitespace-pre-line text-gray-600 text-sm">
          {item.label === null && `${idx + 1}. `}
          {item.value}
        </div>
        <div className="flex flex-col h-full">
          <div className="flex space-x-4">
            {item.acceptanceStatus === null ? (
              <Tooltip title={<div>Add product gap to the document</div>} placement="top">
                <button
                  type="button"
                  // onClick={() => props.setState(prevState => ({ ...prevState, isInserted: true, isShowing: false }))}
                >
                  <ArrowDownOnSquareIcon
                    onClick={async () => {
                      const newRecordings = recordingsStore.recordings.map(r => {
                        if (r.id !== recordingId) {
                          return r;
                        }
                        let newItems: ProductFeedbackItem[] = [];
                        if (r.productFeedbackData) {
                          newItems = r.productFeedbackData.map(itm => {
                            if (itm.id === item.id) {
                              return { ...itm, acceptanceStatus: 'accepted' };
                            }
                            return itm;
                          });
                        }
                        return { ...r, productFeedbackData: newItems };
                      });
                      recordingsStore.setState({ recordings: newRecordings });
                      await updateSuggestedProductFeedbackStatus(item.id, 'accepted');
                      const productGapId = await createProductGap(item.label, item.value, item.id);
                      noteEditorStore.setState({
                        callSuggestedFeedbackItem: { ...item, productGapId, documentId },
                      });
                    }}
                    className="w-5 h-5 text-gray-400 hover:text-gray-500"
                  />
                </button>
              </Tooltip>
            ) : (
              <div>
                {!state ? (
                  <div className="text-xs text-green-500 pt-0.5">Added</div>
                ) : (
                  <SpinLoader className="w-4 h-4 animate-spin" />
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {item.startTime !== null && (
        <div className="flex">
          <PlayBack recordingId={recordingId} startTime={item.startTime} from="docEditor" elementId="" />
        </div>
      )}
    </div>
  );
}

function ProdFeedbackSelect({ documentId, className }: { documentId: string; className?: string }) {
  const [state, setState] = useState<State>({
    isLoading: false,
    isShowing: false,
    isCopied: false,
  });

  const recordingsStore = useRecordingsStore();

  useEffect(() => {
    const timer = setTimeout(() => {
      setState(prevState => ({ ...prevState, isCopied: false }));
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, [state.isCopied]);

  const matchedRecordings = useMemo(
    () =>
      recordingsStore.recordings.filter(
        recording =>
          recording.entityOrDocumentId &&
          documentId === recording.entityOrDocumentId &&
          (recording.productFeedbackData || recording.isProductFeedbackBeingProcessed),
      ),
    [recordingsStore.recordings, documentId],
  );

  if (recordingsStore.isLoading) {
    return null;
  }

  if (matchedRecordings && matchedRecordings.length > 0) {
    return (
      <div className={concat('my-2 p-2 bg-blue-50 rounded-lg mr-4', className)}>
        <div className="w-full justify-between gap-1 flex-shrink-0 flex-grow-0 align flex items-center">
          <div className="flex flex-row gap-2">
            <AIICon className="w-5 h-5 text-[#2D6CEA]" />
            <span className="text-gray-800 font-medium text-sm">Product Feedback</span>
            {matchedRecordings.find(r => r.isProductFeedbackBeingProcessed) !== undefined ? (
              <div className="w-5 h-5">
                <SpinLoader className="animate-spin w-5 h-5 text-orange-500" />
              </div>
            ) : (
              <span className="flex gap-1 items-center">
                <span className="rounded-xl px-2 bg-blue-100 text-blue-500 text-xs">Beta</span>
              </span>
            )}
          </div>
          <button
            type="button"
            className="w-12 text-xs bg-blue-800 py-1 px-2 rounded text-white hover:bg-blue-700"
            onClick={() => {
              setState(prevState => {
                return { ...prevState, isShowing: !state.isShowing };
              });
            }}
          >
            {state.isShowing ? 'Hide' : 'Show'}
          </button>
        </div>
        {state.isShowing &&
          matchedRecordings.map((recording, index) => {
            if (recording.productFeedbackData) {
              const summaryItems: JSX.Element[] = [];
              recording.productFeedbackData.forEach((item, idx) => {
                if (item.label) {
                  summaryItems.push(
                    <div key={`${item.startTime}Title`} className="flex text-sm grow-0 rounded font-medium">
                      {idx + 1}. {item.label}
                    </div>,
                  );
                }
                summaryItems.push(
                  <SuggestedProductFeedbackItem
                    key={`${item.startTime}Summary`}
                    item={item}
                    idx={idx}
                    recordingId={recording.id}
                    documentId={documentId}
                  />,
                );
              });

              return (
                <div
                  key={recording.id}
                  className={`flex justify-between items-center w-full p-2 pr-2 ${
                    matchedRecordings.length > 0 && index !== matchedRecordings.length - 1 ? 'border-b-2' : ''
                  }`}
                >
                  <div className="flex flex-col gap-2 ml-[20px]">{summaryItems}</div>
                </div>
              );
            }
            return '';
          })}
      </div>
    );
  }
  return null;
}

ProdFeedbackSelect.defaultProps = {
  className: '',
};

export default ProdFeedbackSelect;
