import React from 'react';
import useNavMenuStore from 'stores/nav-menu';
import { concat } from 'utils/styling';

import styles from './SideMenu.module.css';

type State = 'hover' | 'fixed' | null;

interface Props {
  menuName: 'note' | 'cal';
  state: State;
  children: JSX.Element;
}

function SideMenu({ menuName, state, children }: Props) {
  const [hoverOpacity, setHoverOpacity] = React.useState(0);
  const navMenuStore = useNavMenuStore();

  React.useEffect(() => {
    let timeout: any = null;
    if (hoverOpacity < 1) {
      timeout = setTimeout(() => {
        setHoverOpacity(preState => (preState >= 1 ? 1 : preState + 0.06));
      }, 35);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hoverOpacity]);

  React.useEffect(() => {
    if (navMenuStore.buttonHover !== null && state !== 'fixed') {
      setHoverOpacity(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navMenuStore.buttonHover]);

  let stateClassName = styles['sidebar-hidden'];
  if (navMenuStore.buttonHover === menuName || navMenuStore.menuHover === menuName)
    stateClassName = styles['sidebar-hover'];
  if (state === 'fixed') {
    stateClassName = styles['sidebar-fixed'];
  }
  return (
    <div
      className={`${concat(styles.sidebar, stateClassName)} bg-white`}
      onMouseEnter={() => {
        if (state !== 'fixed') {
          navMenuStore.setState({ menuHover: menuName });
        }
      }}
      onMouseLeave={() => {
        if (state !== 'fixed') {
          navMenuStore.setState({ menuHover: null });
        }
      }}
    >
      <div className="h-full" style={{ opacity: hoverOpacity }}>
        {children}
      </div>
    </div>
  );
}

export default SideMenu;
