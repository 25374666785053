import { ColumnDefinition, GroupColumnDefinition } from '../../components/Table/types';
import { ReportingCalculation, ReportingColumn, ReportingFilter, ReportingSort } from '../../types/reporting';

function getReportingColumn(
  groupColumnDefinition: GroupColumnDefinition,
  columnDefinition: ColumnDefinition,
): ReportingColumn {
  const type = columnDefinition.type === 'ReportEntityField' ? 'entity' : 'field';
  return {
    type,
    groupId: groupColumnDefinition.id,
    columnId: columnDefinition.id,
  };
}

export function getReportingFilters(groupTableColumns: GroupColumnDefinition[]): ReportingFilter[] {
  const filters: ReportingFilter[] = [];
  groupTableColumns.forEach(groupTableConfig => {
    groupTableConfig.columns.forEach(config => {
      if (config.type === 'ReportLoadingField') {
        // eslint-disable-next-line no-continue
        return;
      }
      if (config.filter) {
        filters.push({
          ...getReportingColumn(groupTableConfig, config),
          dataEntryType: config.type === 'ReportFieldAnswer' ? 'string' : config.data.dataEntryType,
          filterType: config.filter.type,
          filterValue: config.filter.value,
        });
      }
    });
  });
  return filters;
}

export function getReportingSorts(groupTableColumns: GroupColumnDefinition[]): ReportingSort[] {
  const sorts: ReportingSort[] = [];
  groupTableColumns.forEach(groupTableConfig => {
    groupTableConfig.columns.forEach(config => {
      if (config.type === 'ReportLoadingField') {
        // eslint-disable-next-line no-continue
        return;
      }
      if (config.sort) {
        sorts.push({
          ...getReportingColumn(groupTableConfig, config),
          sort: config.sort,
        });
      }
    });
  });
  return sorts;
}

export function getReportingCalculations(groupTableColumns: GroupColumnDefinition[]): ReportingCalculation[] {
  const calculations: ReportingCalculation[] = [];
  groupTableColumns.forEach(groupTableConfig => {
    groupTableConfig.columns.forEach(config => {
      if (config.type === 'ReportLoadingField') {
        // eslint-disable-next-line no-continue
        return;
      }
      if (config.calculation) {
        calculations.push({
          ...getReportingColumn(groupTableConfig, config),
          entityName: config.data.entityName,
          calculation: config.calculation,
        });
      }
    });
  });
  return calculations;
}

export function getReportingColumns(groupTableColumns: GroupColumnDefinition[]): ReportingColumn[] {
  const exportColumns: ReportingColumn[] = [];
  groupTableColumns.forEach(groupTableConfig => {
    groupTableConfig.columns.forEach(config => {
      if (config.type === 'ReportLoadingField' || config.isHidden) {
        return;
      }
      exportColumns.push(getReportingColumn(groupTableConfig, config));
    });
  });
  return exportColumns;
}
