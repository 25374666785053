import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSlate } from 'slate-react';
import { BlockType, CustomElement, DiscoveryQuestionElement } from './types';
import NoteEditor from './NoteEditor';
import { debounce } from 'lodash';

const DISCOVERY_FIELDS_SUMMARY_DEBOUNCE_TIME = 500;

interface Props {
  onEditorEmptyToggle: (isEditorEmpty: boolean) => void;
  onDiscoveryQuestionListChange: (addedFields: string[]) => void;
}
/**
 * Monitor slate content, whether content is empty and all added discovery question fields
 */
function SlateEditorContentMonitor({ onEditorEmptyToggle, onDiscoveryQuestionListChange }: Props) {
  // useSlate will trigger component re-render immediatly when any editor content update
  const editor = useSlate();
  const editorEmptyRef = useRef<boolean>();

  // get all added discovery question fields with debounce
  const getAddedDiscoveryQuestionFields = useCallback(
    debounce((children: CustomElement[]) => {
      const discoveryQuestionList: string[] = [];
      children.forEach(element => {
        if ((element as CustomElement).type === BlockType.DiscoveryQuestion) {
          const discoQid = (element as DiscoveryQuestionElement).discoveryQuestionId;
          if (discoQid) {
            discoveryQuestionList.push(discoQid);
          }
        }
      });
      onDiscoveryQuestionListChange(discoveryQuestionList);
    }, DISCOVERY_FIELDS_SUMMARY_DEBOUNCE_TIME),
    [onDiscoveryQuestionListChange],
  );

  useEffect(() => {
    const isEmpty = !!editor.children.length && NoteEditor.sp_isEditorEmpty(editor);
    // emit event when editor empty status changed
    if (editorEmptyRef.current !== isEmpty) {
      editorEmptyRef.current = isEmpty;
      onEditorEmptyToggle(isEmpty);
    }
    // get all added discovery question fields
    getAddedDiscoveryQuestionFields(editor.children);
    // useSlate will trigger useEffect immediatly when any editor content update
  }, [editor.children]);

  return null;
}

export default SlateEditorContentMonitor;
