import { MenuItem } from '@mui/material';
import React from 'react';
import { ArrowUpIcon } from '@heroicons/react/24/outline';
import { ColumnDefinition } from '../../types';

interface Props {
  columnDefinition: ColumnDefinition;
  onSortUpdate: (sort: 'ASC' | 'DESC' | undefined) => void;
}

function SortAscendingColumnMenu({ columnDefinition, onSortUpdate }: Props) {
  return columnDefinition.sort !== 'ASC' ? (
    <MenuItem onClick={() => onSortUpdate(columnDefinition.sort === 'ASC' ? undefined : 'ASC')}>
      <div className="flex items-center text-sm">
        <ArrowUpIcon className="w-4 h-4 mr-2" />
        Sort ascending
      </div>
    </MenuItem>
  ) : null;
}

export default SortAscendingColumnMenu;
