import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import integrationOauth from 'api/integration-oauth';

import Loader from 'components/Loader';
import typoStyles from 'components/typo.module.css';

import useUserStore from 'stores/user';

import { redirect } from 'utils/redirect-after-login';
import { concat } from 'utils/styling';

import styles from './Oauth.module.css';

interface State {
  isLoading: boolean;
}

function ZoomOauth() {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const from = searchParams.get('from');

  const [state, setState] = useState<State>({
    isLoading: true,
  });

  const navigate = useNavigate();

  const userStore = useUserStore();

  useEffect(() => {
    const authorize = async (codeP: string, fromP: 'setting'): Promise<void> => {
      try {
        const { user } = await integrationOauth('recording', 'zoom', codeP, fromP);
        userStore.setState({ user });
        if (fromP === 'setting') {
          navigate('/settings');
        } else {
          redirect(navigate, user);
        }
      } catch {
        /* empty */
      }

      setState({ isLoading: false });
    };

    if (code) {
      authorize(code, 'setting');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, from]);

  return (
    <div className={styles.layout}>
      <div className={styles.container}>
        <span className={concat(typoStyles.xl, typoStyles['semi-bold'])}>Zoom Authorization</span>
        {state.isLoading ? <Loader /> : null}
      </div>
    </div>
  );
}

export default ZoomOauth;
