/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Field } from './types';
import { ControlledFormProps } from 'types/form';
import InputField from 'components/Form/InputField';
import FormField from 'components/Form/FormField';
import { openFormModal, openInformationalModal, openWarningConfirmModal } from 'components/BaseModal';
import { Tag } from 'types/field-value';
import useUserStore from 'stores/user';
import { EntityName } from 'types/entity';
import { dependentInputValidate as dependendInputValidate, requiredInputValidate } from '../../utils/validate';
import Tabs from 'components/Tabs';
import BaseSwitch from 'components/Form/BaseSwitch';
import AIIcon from 'components/icons/AIIcon';
import { concat } from 'utils/styling';
import SelectField from 'components/Form/SelectField';
import { PlusIcon, ShieldExclamationIcon } from '@heroicons/react/24/outline';
import { ListItem } from 'types/list-item';
import TagBlock from 'components/TagBlock';
import BaseButton from 'components/BaseButton';
import openNewFieldTagModal from './CreateNewFieldTagModal';
import getFieldTags from 'api/fields/get-field-tags';
import createField from 'api/field/create-field';
import updateField from 'api/field/update-field';
import getSalesforceFields, { SalesforceField, Response } from 'api/salesforce/get-salesforce-fields';
import SalesforceIcon from 'components/icons/SalesforceIcon';
import { EXCLUDE_TYPE_IN_EDIT, isAISupport, isHasOptions, SalesforceFieldType } from 'types/salesforce';
import { cloneDeep } from 'lodash';
import checkFieldLink from 'api/fields/check-field-link';
import { openDateWarningModal, openQuestionWarningModal } from './SortableField/add-field-warning-modal';
import FilterIcon from '../icons/FilterIcon';
import TeamMemberRequestIcon from '../icons/TeamMemberRequestIcon';
import { capitalizeName } from 'utils/string';
import { PickListOptions } from './PickListOptions';

interface Props {
  entityName: EntityName;
  isAdmin: boolean;
  existingCRMFields: Field[];
  isCreate: boolean;
}

function FieldEditForm({
  entityName,
  isAdmin,
  existingCRMFields,
  register,
  control,
  errors,
  watch,
  setValue,
  clearErrors,
  getValues,
  isCreate,
}: Props & ControlledFormProps<Field>) {
  const userStore = useUserStore();
  const isAiEnabled = watch?.('isAiEnabled');
  const isDefaultCrmField = watch?.('crmField.isDefault');
  const dataType = watch?.('crmField.type');
  const crmDurableId = watch?.('crmField.durableId');

  const tabs = useMemo(
    () => [
      { label: 'General Settings', value: 'general', className: errors.label ? '!text-red-500' : '' },
      {
        label: 'AI Settings',
        value: 'ai',
        disabled: !isAiEnabled,
        className: errors.description ? '!text-red-500' : '',
      },
    ],
    [isAiEnabled, errors.label, errors.description],
  );

  const [currentTab, setCurrentTab] = useState(tabs[0].value);
  const [fieldTags, setFieldTags] = useState<Tag[]>([]);
  const [loadingTags, setLoadingTags] = useState(false);
  const [loadingFT, setLoadingFT] = useState(false);
  const tagMenus = useMemo<ListItem<string>[]>(
    () =>
      fieldTags.map(tag => ({
        label: tag.tag,
        value: tag.id,
        item: tag,
      })),
    [fieldTags],
  );
  const [sfTypes, setSFTypes] = useState<Response | undefined>();
  const sfMenus = useMemo(() => {
    if (!sfTypes) return [];
    const menus = (entityName === 'Account' ? sfTypes.accountFields : sfTypes.opportunityFields)
      .filter(item => dataType === item.type)
      .map(item => ({
        value: item.durableId,
        label: item.label,
        item,
        disabled:
          !!existingCRMFields.find(f => f.crmField?.durableId === item.durableId) && item.durableId !== crmDurableId,
      }));
    return menus;
  }, [dataType, sfTypes]);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        setLoadingTags(true);
        const tags = await getFieldTags();
        setFieldTags(tags);
      } finally {
        setLoadingTags(false);
      }
    };

    const fetchFieldFT = async () => {
      try {
        setLoadingFT(true);
        const rsp = await getSalesforceFields();
        setSFTypes(rsp);
      } finally {
        setLoadingFT(false);
      }
    };
    fetchTags();
    fetchFieldFT();
  }, []);

  const setCRMFieldConfig = (f: SalesforceField | undefined) => {
    setValue?.('crmField.name', f?.name || '');
    setValue?.('crmField.isDefault', false);
    setValue?.('crmField.durableId', f?.durableId || '');
    setValue?.('crmField.picklistValues', f?.picklistValues || []);
  };

  const buildInputValidate = () => {
    return {
      validate: (value: string | null | undefined) => {
        const nonEmpty = requiredInputValidate.validate(value);
        if (!nonEmpty) {
          return nonEmpty;
        }
        const field = existingCRMFields.filter(i => i.label === value)[0];
        const curId = getValues?.('id');
        if (field) {
          if (!curId || curId !== field.id) {
            return false;
          }
        }
        return true;
      },
    };
  };

  const getInputErrorMsg = () => {
    if (!errors.label) {
      return '';
    }
    const v = requiredInputValidate.validate(getValues?.('label'));
    if (!v) {
      return 'Name is required';
    }
    return 'Name is exist';
  };

  return (
    <div className="pl-3">
      <Tabs
        containerClassName="border-b mb-4 pl-3"
        tabClassName="!px-0 !min-w-[120px]"
        tabs={tabs}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      />
      {/* fields in General settings tab */}
      <FormField
        className={concat('mb-3 pl-3', currentTab !== 'general' && 'hidden')}
        label="Name"
        labelClassName="w-20"
        error={getInputErrorMsg()}
      >
        <InputField
          {...register('label', buildInputValidate())}
          placeholder="Short description about the field, e.g., client budget"
          autoComplete="off"
          disabled={!!isDefaultCrmField}
          size="small"
        />
      </FormField>

      <FormField
        className={concat('mb-3 pl-3', currentTab !== 'general' && 'hidden')}
        label="Discovery Question Type"
        labelClassName="w-[164px]"
      >
        <Controller
          name="crmField.type"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <SelectField
              {...field}
              placeholder="Select Discovery Question Type"
              size="small"
              disabled={!isCreate}
              onChange={(newV, selectedMenu) => {
                field.onChange(newV || '');
                const sfType = selectedMenu?.item;
                // when link or select other CRM field
                if (sfType) {
                  setValue?.('crmField.type', sfType as SalesforceFieldType);
                  setCRMFieldConfig(undefined);
                  // when current AI enabled, but selected sf field not support ai, disable ai
                  if (isAiEnabled && !isAISupport(sfType as SalesforceFieldType)) {
                    setValue?.('isAiEnabled', false);
                  }
                }
              }}
              menus={Object.values(SalesforceFieldType)
                .filter(i => !EXCLUDE_TYPE_IN_EDIT.includes(i))
                .map(i => ({
                  value: i,
                  label: i,
                  item: i,
                }))}
              localSearchAble
              renderMenu={menu => (
                <div className="flex items-center truncate">
                  <div className="text-sm truncate">{menu.label}</div>
                </div>
              )}
              menuClassName="truncate"
            />
          )}
        />
      </FormField>

      {isHasOptions(dataType) && (
        <FormField
          className={concat('mb-3 pl-3', currentTab !== 'general' && 'hidden')}
          label="List Options"
          labelClassName="w-[164px]"
          error={
            errors.crmField?.picklistValues ? errors.crmField?.picklistValues?.message || 'Must enter options' : ''
          }
        >
          <Controller
            name="crmField.picklistValues"
            control={control}
            rules={{ required: isHasOptions(dataType) }}
            render={({ field }) => {
              return (
                <PickListOptions
                  options={field.value}
                  disabled={!!crmDurableId}
                  onOptionsUpdate={async v => {
                    field.onChange(v);
                  }}
                />
              );
            }}
          />
        </FormField>
      )}
      {userStore.user?.isIntegratedWithCrm && (
        <FormField
          className={concat('mb-3 pl-3', currentTab !== 'general' && 'hidden')}
          label="Link/Unlink to Salesforce"
          labelClassName="w-[164px]"
        >
          <Controller
            name="crmField.durableId"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <SelectField
                {...field}
                placeholder="Select salesforce field to link"
                size="small"
                showClearBtn
                disabled={isDefaultCrmField || (!isCreate && !!crmDurableId)}
                clearValueLabel="Unlink"
                onChange={(newV, selectedMenu) => {
                  if (isAdmin) {
                    field.onChange(newV || '');
                    const selectedSfField = selectedMenu?.item as SalesforceField;
                    setCRMFieldConfig(selectedSfField);
                  } else {
                    openInformationalModal(
                      field.value ? 'Only Admin can unlink from Salesforce' : 'Only Admin can link to Salesforce',
                    );
                  }
                }}
                errorLabel="No salesforce fields for select"
                loading={loadingFT}
                menus={sfMenus}
                localSearchAble
                renderMenu={(menu, isDisplayValue) => (
                  <div className="flex items-center truncate">
                    <SalesforceIcon className="border rounded w-6 h-6 p-1 bg-white mr-2" />
                    <div className="text-sm truncate">{menu.label}</div>
                    {!isDisplayValue && (
                      <span className="text-xs px-2 text-gray-400">
                        ({capitalizeName((menu.item as SalesforceField).type)})
                      </span>
                    )}
                  </div>
                )}
                menuClassName="truncate"
              />
            )}
          />
        </FormField>
      )}

      <Controller
        name="isDefault"
        control={control}
        render={({ field }) => (
          <BaseSwitch
            {...field}
            checked={field.value}
            onChange={newV => {
              if (isAdmin) {
                field.onChange(newV);
              } else {
                openInformationalModal('Only admin is allowed to change this setting');
              }
            }}
            label={
              <div className="flex items-center gap-3">
                <ShieldExclamationIcon className="w-4 h-4" />
                <span>Set as mandatory</span>
              </div>
            }
            className={concat('flex-row-reverse !w-full !pl-3', currentTab !== 'general' && '!hidden')}
          />
        )}
      />

      <Controller
        name="isAiEnabled"
        control={control}
        render={({ field }) => (
          <BaseSwitch
            {...field}
            onChange={v => {
              const sfFieldType = getValues?.('crmField.type');
              if (v) {
                // only allow to enable AI when field type is question
                if (sfFieldType && isAISupport(sfFieldType)) {
                  field.onChange(v);
                  setCurrentTab(tabs[v ? 1 : 0].value);
                } else {
                  // open warning modal when user try to enable ai on field of non-question type
                  openInformationalModal('We are expanding our AI capabilities to other field types. Stay tuned!');
                }
              } else {
                field.onChange(v);
                clearErrors?.('description');
              }
            }}
            checked={!!field.value}
            label={
              <div className="flex items-center gap-3">
                <AIIcon className="w-4 h-4" />
                <span>Assign to AI</span>
              </div>
            }
            className={concat('flex-row-reverse !w-full !pl-3', currentTab !== 'general' && '!hidden')}
          />
        )}
      />

      <Controller
        name="isUsedInMenuFilter"
        control={control}
        render={({ field }) => (
          <BaseSwitch
            {...field}
            checked={field.value}
            onChange={v => {
              field.onChange(v);
            }}
            label={
              <div className="flex items-center gap-3">
                <FilterIcon className="w-4 h-4" />
                <span>Use in {entityName} Menu Filter</span>
              </div>
            }
            className={concat('flex-row-reverse !w-full !pl-3', currentTab !== 'general' && '!hidden')}
          />
        )}
      />
      {userStore.user?.isTeamMemberRequestSetup && (
        <Controller
          name="isUsedInTeamMemberRequest"
          control={control}
          render={({ field }) => (
            <BaseSwitch
              {...field}
              checked={field.value}
              onChange={v => {
                field.onChange(v);
              }}
              disabled={!userStore.user?.isAdmin}
              label={
                <div className="flex items-center gap-3">
                  <TeamMemberRequestIcon className="w-4 h-4" />
                  <span>Use in Team Member Request Form</span>
                </div>
              }
              className={concat('flex-row-reverse !w-full !pl-3', currentTab !== 'general' && '!hidden')}
            />
          )}
        />
      )}
      <FormField
        className={concat('mb-3 pl-3', currentTab !== 'general' && 'hidden')}
        label="Tags"
        labelClassName="w-20"
        fieldClassName="!items-start"
      >
        <Controller
          name="tags"
          control={control}
          render={({ field }) => (
            <SelectField
              {...field}
              value={field.value.map(tag => tag.id)}
              placeholder="Pick tags"
              size="small"
              showClearBtn
              clearValueLabel="Remove tag"
              onChange={newV => {
                const newTags = newV ?? ([] as string[]);
                if (newTags instanceof Array) {
                  field.onChange(newTags.map(tag => fieldTags.find(item => item.id === tag)).filter(tag => tag));
                }
              }}
              menus={tagMenus}
              localSearchAble
              multiple
              multiline
              renderMenu={menu => <TagBlock tag={menu.item as Tag} />}
              valueClassName="flex-wrap gap-x-2 gap-y-1 py-1.5"
              loading={loadingTags}
            />
          )}
        />
        <BaseButton
          className="!min-w-[2rem] !h-8 !ml-3"
          tooltip="Add new tag"
          hideTooltipToPreventJump
          color="secondary"
          variant="text"
          iconBtn
          onClick={async () => {
            const newTag = await openNewFieldTagModal();
            if (newTag) {
              setFieldTags(prev => [...prev, newTag]);
            }
          }}
        >
          <PlusIcon width={24} />
        </BaseButton>
      </FormField>

      <FormField
        className={concat('mb-3 pl-3', currentTab !== 'ai' && 'hidden')}
        label="AI Prompt"
        labelClassName="w-20"
        error={errors.description ? errors.description.message || 'AI Prompt is required' : ''}
      >
        <Controller
          name="description"
          control={control}
          rules={dependendInputValidate(!!isAiEnabled)}
          render={({ field }) => (
            <InputField
              {...field}
              value={field.value || ''}
              placeholder="AI Prompt description about the field"
              size="small"
            />
          )}
        />
      </FormField>

      <Controller
        name="autoAcceptAI"
        control={control}
        render={({ field }) => (
          <BaseSwitch
            {...field}
            checked={field.value}
            label={
              <div className="flex items-center gap-1">
                <AIIcon className="w-4 h-4" />
                <span>Auto-accept suggested answers</span>
              </div>
            }
            className={concat('flex-row-reverse !w-full !pl-3', currentTab !== 'ai' && '!hidden')}
          />
        )}
      />
    </div>
  );
}

function openFieldEditModal(
  field: Field,
  onCreateOrUpdateField: (field: Field) => void,
  isCreate: boolean,
  isAdmin: boolean,
  existingCRMFields: Field[],
) {
  return openFormModal<Field>(
    {
      formData: field,
      renderFormBody: ({ register, control, errors, watch, setValue, clearErrors, getValues }) => (
        <FieldEditForm
          entityName={field.entity}
          register={register}
          control={control}
          errors={errors}
          watch={watch}
          isCreate={isCreate}
          setValue={setValue}
          clearErrors={clearErrors}
          getValues={getValues}
          isAdmin={isAdmin}
          existingCRMFields={existingCRMFields}
        />
      ),
      actions: [
        {
          label: 'Cancel',
          color: 'secondary',
        },
        {
          label: isCreate ? 'Create' : 'Update',
          color: 'primary',
          type: 'submit',
          cb: async (f: Field) => {
            const fieldClone = cloneDeep(f);
            if (!fieldClone.crmField.durableId) {
              delete fieldClone.crmField.durableId;
            }
            fieldClone.crmField.picklistValues = (fieldClone.crmField.picklistValues || []).filter(i => !!i);
            if (fieldClone.crmField?.durableId) {
              // when update field and changed CRM field, check the pull logic
              if (fieldClone.crmField?.durableId !== field.crmField?.durableId && !isCreate) {
                // update field syncDirection when change crm field
                fieldClone.syncDirection = 'PULL_FROM_CRM';
                const { numberOfAnswers, error } = await checkFieldLink(field.id, fieldClone.crmField?.durableId);
                if (error) {
                  openInformationalModal('We were unable to link field', error);
                  throw new Error(error);
                }
                if (numberOfAnswers === 0) {
                  fieldClone.syncDirection = 'PULL_FROM_CRM';
                } else {
                  const t = field.crmField.type;
                  if (t === 'string' || t === 'textarea') {
                    const syncDirection = await openQuestionWarningModal(numberOfAnswers);
                    if (syncDirection) {
                      fieldClone.syncDirection = 'CONCAT';
                    } else {
                      throw new Error('Sync direction not selected');
                    }
                  } else {
                    const syncDirection = await openDateWarningModal(numberOfAnswers);
                    if (syncDirection) {
                      fieldClone.syncDirection = syncDirection;
                    } else {
                      throw new Error('Sync direction not selected');
                    }
                  }
                }
              }
            }
            const doSave = async () => {
              const newField = isCreate ? await createField(fieldClone) : await updateField(fieldClone);
              onCreateOrUpdateField(newField);
            };
            if (isCreate && !!fieldClone.crmField.durableId) {
              const r = await openWarningConfirmModal(
                'Once you establish a connection between this field and a Salesforce field, the link is permanent and cannot be undone!',
              );
              if (r) {
                await doSave();
              } else {
                throw new Error('Use Cancel');
              }
            } else {
              await doSave();
            }
          },
        },
      ],
    },
    {
      className: 'w-[34rem]',
      bodyClassName: '!px-0',
      title: `${isCreate ? 'Create' : 'Edit'} Field Automation Task`,
    },
  ).then(rsp => {
    return typeof rsp === 'object' ? (rsp as Field) : undefined;
  });
}

export default openFieldEditModal;
