import React, { useEffect, useState } from 'react';

const update = () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const percent = Math.floor(Math.random() * 10);
      if (percent <= 8) {
        resolve('hello');
      } else {
        reject();
      }
    }, 1000);
  });
};

function Test() {
  const [originalValue, setOriginalValue] = useState<string>('');
  const [value, setValue] = useState<string>('');
  const [isEditing, setIsEditing] = useState<boolean>(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      update()
        .then(() => {
          setIsEditing(false);
          setOriginalValue(value);
        })
        .catch(() => {
          if (!isEditing) {
            setValue(originalValue);
          }
        });
    }, 200);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, isEditing, originalValue]);

  const onChange = (v: string) => {
    setIsEditing(true);
    setValue(v);
  };

  return (
    <div className="flex items-center justify-center" style={{ width: '100vw', height: '100vh' }}>
      <div className="w-[50rem] h-[20rem]">
        <input className="w-96 border rounded px-2 py-1" value={value} onChange={e => onChange(e.target.value)} />
        <div>{isEditing && 'Editing...'}</div>
        <div>Original Value:</div>
        <div>{originalValue}</div>
      </div>
    </div>
  );
}

export default Test;
