import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/24/outline';

import GoogleCalendar from 'components/logos/GoogleCalendar';
import MicrosoftCalendar from 'components/logos/MicrosoftCalendar';
import SignupContainer from 'components/SignupContainer';
import typoStyles from 'components/typo.module.css';

import useUserStore from 'stores/user';

import { redirect } from 'utils/redirect-after-login';
import { concat } from 'utils/styling';
import getOauthLink from '../utils/get-oauth-link';

import styles from './CalendarIntegration.module.css';
import LoaderTransparentBackground from 'components/LoaderTransparentBackground';
import GoogleCalendarWarningView from 'components/GoogleCalendarWarningView';

function CalendarIntegration() {
  const userStore = useUserStore();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const noStep = !!searchParams.get('no-step');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [state, setState] = useState<boolean>(false);

  const skip = async (): Promise<void> => {
    setIsLoading(true);
    const userStoreData = await userStore.updateUser({ hasSkippedCalendarIntegration: true });
    setIsLoading(false);
    if (noStep) {
      navigate(-1);
    }
    // we cannot use userStore.user here, as user somehow isn't updated yet
    if (userStoreData.user) {
      redirect(navigate, userStoreData.user);
    }
  };

  const calendarIntegrationPart = (
    <>
      {state && (
        <div className="h-screen">
          <GoogleCalendarWarningView onGoBack={() => setState(false)} />
        </div>
      )}
      {!state && (
        <>
          <h3 className="text-gray-500 font-medium">Superpanel works better with your calendar integration. </h3>
          <button type="button" className={styles['oauth-link']} onClick={() => setState(true)}>
            <div className={styles['link-left']}>
              <GoogleCalendar className={styles.logo} />
              <div className={styles['link-text']}>
                <div className={concat(typoStyles.lg, typoStyles['semi-bold'])}>Google Calendar</div>
                <div className={concat(typoStyles.sm, typoStyles['text-grey-400'])}>Connect with Google Calendar</div>
              </div>
            </div>
            <ChevronRightIcon className={styles.chevron} />
          </button>
          <a className={styles['oauth-link']} href={getOauthLink('microsoft', 'onboarding')}>
            <div className={styles['link-left']}>
              <MicrosoftCalendar className={styles.logo} />
              <div className={styles['link-text']}>
                <div className={concat(typoStyles.lg, typoStyles['semi-bold'])}>Microsoft Calendar</div>
                <div className={concat(typoStyles.sm, typoStyles['text-grey-400'])}>
                  Connect with Microsoft Calendar
                </div>
              </div>
            </div>
            <ChevronRightIcon className={styles.chevron} />
          </a>
          <div className="flex justify-end items-center gap-4">
            <button
              type="button"
              className="hover:bg-gray-200 bg-gray-100 text-gray-500 rounded px-2 py-2"
              onClick={skip}
            >
              Continue without calendar integration
            </button>
          </div>
        </>
      )}
    </>
  );

  return (
    <>
      {!noStep && (
        <SignupContainer currentStepIndex={1} title="Link Your Calendar" totalStepNumber={3}>
          {calendarIntegrationPart}
        </SignupContainer>
      )}
      {noStep && (
        <div className="w-full h-full overflow-y-auto">
          <div className="flex flex-col items-center my-20">
            <div className="w-2/4 flex flex-col gap-y-6 min-w-[20rem] max-w-[50rem]">{calendarIntegrationPart}</div>
          </div>
        </div>
      )}
      {isLoading && <LoaderTransparentBackground text="Skipping calendar integration..." />}
    </>
  );
}

export default CalendarIntegration;
