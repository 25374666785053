/* eslint-disable react/no-array-index-key, no-param-reassign */
import React, { useCallback, useMemo } from 'react';
import { ColumnConfig, ColumnDefinition, DraggingColumnInfo, GroupColumnDefinition } from './types';
import Column from './Columns/Column';
import GroupColumn from './Columns/GroupColumn';
import { getTableSequenceCellWidth, swapTableColPostion } from './utils';
import { cloneDeep, findIndex, findLastIndex } from 'lodash';
import { concat } from 'utils/styling';

interface ColumnConfigViewProps {
  columnConfig: ColumnConfig;
  setColumnConfig: (columnConfig: ColumnConfig) => void;
  isEven: boolean;
  onDraggingMouseDown: (
    event: React.MouseEvent<Element, MouseEvent>,
    col: ColumnConfig,
    subCol?: ColumnDefinition,
  ) => void;
  draggingItem: DraggingColumnInfo | undefined;
  setDraggingItem: React.Dispatch<DraggingColumnInfo>;
  onColumnPin: (col: ColumnConfig, subCol: ColumnDefinition | undefined) => void;
}

function ColumnView({
  columnConfig,
  setColumnConfig,
  isEven,
  onDraggingMouseDown,
  draggingItem,
  setDraggingItem,
  onColumnPin,
}: ColumnConfigViewProps) {
  const isAllHidden = useMemo(
    () => 'group' in columnConfig && columnConfig.columns.every(column => column.isHidden),
    [columnConfig, (columnConfig as GroupColumnDefinition).columns],
  );
  const groupWidth = useMemo(
    () =>
      ((columnConfig as GroupColumnDefinition).columns || []).reduce(
        (acc, column) => acc + (column.isHidden ? 0 : column.width),
        0,
      ),
    [columnConfig, (columnConfig as GroupColumnDefinition).columns],
  );

  const subColIds = useMemo(
    () => ((columnConfig as GroupColumnDefinition).columns || []).map(item => item.id).join('_'),
    [columnConfig],
  );

  const isDragging = useMemo(() => {
    return (
      draggingItem &&
      columnConfig.id === draggingItem.colId &&
      (draggingItem.subColId
        ? subColIds.includes(draggingItem.subColId)
        : draggingItem.targetColName ===
          ((columnConfig as GroupColumnDefinition).group || (columnConfig as ColumnDefinition).name))
    );
  }, [columnConfig, draggingItem, subColIds]);

  if ('group' in columnConfig) {
    if (isAllHidden) {
      return null;
    }
    return (
      <div className="relative border-r border-gray-300 flex flex-col" style={{ width: groupWidth }}>
        <GroupColumn
          groupColumnDefinition={columnConfig}
          onGroupColumnHide={() => {
            const groupColumn = { ...columnConfig };
            groupColumn.columns.forEach((column, index) => {
              groupColumn.columns[index].isHidden = true;
            });
            setColumnConfig(groupColumn);
          }}
          isEven={isEven}
          onDraggingMouseDown={event => onDraggingMouseDown(event, columnConfig)}
          setDraggingItem={setDraggingItem}
          onColumnPin={() => onColumnPin(columnConfig, undefined)}
          className={isDragging && subColIds === draggingItem?.subColId ? '!bg-blue-100' : ''}
        />
        <div className="flex flex-grow">
          {columnConfig.columns.map((column, columnIndex) => {
            if (column.isHidden) return null;
            function updateGroupKey<K extends keyof ColumnDefinition>(key: K, value: ColumnDefinition[K]) {
              const newColumnConfig = { ...columnConfig } as GroupColumnDefinition;
              newColumnConfig.columns[columnIndex][key] = value;
              setColumnConfig(newColumnConfig);
            }

            return (
              <Column
                key={`group-column-${column.id}-${columnIndex}`}
                columnDefinition={column}
                onColumnHide={() => updateGroupKey('isHidden', true)}
                onSortUpdate={sort => updateGroupKey('sort', sort)}
                onFilterUpdate={filter => updateGroupKey('filter', filter)}
                onWidthUpdate={width => updateGroupKey('width', width)}
                isGroupWidth={columnIndex === columnConfig.columns.length - 1}
                isEven={isEven}
                onDraggingMouseDown={event => onDraggingMouseDown(event, columnConfig, column)}
                setDraggingItem={colInfo =>
                  setDraggingItem({
                    colId: columnConfig.id,
                    subColId: colInfo.colId,
                    targetColName: colInfo.targetColName,
                  })
                }
                onColumnPin={() => onColumnPin(columnConfig, column)}
                className={isDragging && draggingItem?.subColId.includes(column.id) ? '!bg-blue-100' : ''}
              />
            );
          })}
        </div>
      </div>
    );
  }

  function updateKey<K extends keyof ColumnDefinition>(key: K, value: ColumnDefinition[K]) {
    const newColumnConfig = { ...columnConfig } as ColumnDefinition;
    newColumnConfig[key] = value;
    setColumnConfig(newColumnConfig);
  }

  if (columnConfig.isHidden) {
    return null;
  }

  return (
    <Column
      columnDefinition={columnConfig}
      onColumnHide={() => updateKey('isHidden', true)}
      onSortUpdate={sort => updateKey('sort', sort)}
      onFilterUpdate={filter => updateKey('filter', filter)}
      onWidthUpdate={width => updateKey('width', width)}
      isGroupWidth={false}
      isEven={isEven}
      onDraggingMouseDown={event => onDraggingMouseDown(event, columnConfig)}
      setDraggingItem={setDraggingItem}
      onColumnPin={() => onColumnPin(columnConfig, undefined)}
      className={isDragging ? '!bg-blue-100' : ''}
    />
  );
}

interface Props {
  columnConfigs: ColumnConfig[];
  setColumnConfigs: (columnConfigs: ColumnConfig[]) => void;
  showSequence?: boolean;
  maxSequence?: number;
  onDraggingMouseDown: (event: React.MouseEvent<Element, MouseEvent>) => void;
  draggingItem: DraggingColumnInfo | undefined;
  setDraggingItem: React.Dispatch<DraggingColumnInfo>;
}

function Columns({
  columnConfigs,
  setColumnConfigs,
  showSequence,
  maxSequence,
  onDraggingMouseDown,
  draggingItem,
  setDraggingItem,
}: Props) {
  const sequenceCellWidth = useMemo(
    () => (showSequence ? getTableSequenceCellWidth(maxSequence ?? 0) : 0),
    [showSequence, maxSequence],
  );
  const pinnedColumns = useMemo(
    () =>
      columnConfigs.filter(columnConfig => {
        return (
          !!columnConfig.isPinned &&
          ('group' in columnConfig ? !columnConfig.columns.every(subCol => subCol.isHidden) : !columnConfig.isHidden)
        );
      }),
    [columnConfigs],
  );
  const nonPinnedColumns = useMemo(
    () =>
      columnConfigs.filter(columnConfig => {
        return !columnConfig.isPinned;
      }),
    [columnConfigs],
  );
  const totalWidth = useMemo(
    () =>
      columnConfigs.reduce((acc, columnConfig) => {
        if ('group' in columnConfig) {
          return acc + columnConfig.columns.reduce((acc2, column) => acc2 + (column.isHidden ? 0 : column.width), 0);
        }
        return acc + (columnConfig.isHidden ? 0 : columnConfig.width) + sequenceCellWidth;
      }, 0),
    [columnConfigs],
  );

  const onColumnPin = useCallback(
    (col: ColumnConfig, subCol: ColumnDefinition | undefined) => {
      const pinned = !col.isPinned;
      const index = columnConfigs.indexOf(col);
      const subIndex = subCol ? ((col as GroupColumnDefinition).columns || []).indexOf(subCol) : undefined;
      const newCols = cloneDeep(columnConfigs);
      if (subIndex !== undefined && subIndex >= 0) {
        const subCols = (newCols[index] as GroupColumnDefinition).columns
          .splice(subIndex, 1)
          .map(subItem => ({ ...subItem, isPinned: pinned }));
        newCols.splice(index, 0, { ...newCols[index], columns: subCols } as GroupColumnDefinition);
      }
      const toIndex = pinned
        ? findLastIndex(newCols, item => !!item.isPinned)
        : findIndex(newCols, item => !item.isPinned);
      newCols[index].isPinned = pinned;
      (newCols[index] as GroupColumnDefinition).columns?.forEach(subItem => {
        subItem.isPinned = pinned;
      });
      const cols = swapTableColPostion(
        newCols,
        index,
        subCol ? 0 : undefined,
        toIndex + (pinned ? 1 : -1),
        undefined,
        false,
      );
      if (cols) {
        setColumnConfigs(cols);
      } else {
        setColumnConfigs(newCols);
      }
    },
    [columnConfigs],
  );

  return (
    <div className="flex sticky top-0 z-20" style={{ width: `${totalWidth}px` }}>
      {(!!showSequence || !!pinnedColumns.length) && (
        <div
          className={concat('flex sticky left-0 z-30 border-r', !!pinnedColumns.length && 'border-r-2 border-blue-200')}
        >
          {showSequence && (
            <div
              className="flex items-center pl-2 border-r border-b border-gray-300 w-9 bg-gray-100 sequence-th"
              style={{ width: sequenceCellWidth }}
            >
              &nbsp;
            </div>
          )}
          {pinnedColumns.map((columnConfig, index) => (
            <ColumnView
              key={`column-${columnConfig.id}-${index}`}
              columnConfig={columnConfig}
              setColumnConfig={newColumnConfig => {
                const newColumnConfigs = [...columnConfigs];
                const colIndex = newColumnConfigs.findIndex(config => config === columnConfig);
                if (colIndex >= 0) {
                  newColumnConfigs[colIndex] = newColumnConfig;
                  setColumnConfigs(newColumnConfigs);
                }
              }}
              isEven={index % 2 !== 0}
              onDraggingMouseDown={onDraggingMouseDown}
              draggingItem={draggingItem}
              setDraggingItem={setDraggingItem}
              onColumnPin={onColumnPin}
            />
          ))}
        </div>
      )}
      <div className="flex">
        {nonPinnedColumns.map((columnConfig, index) => (
          <ColumnView
            key={`column-${columnConfig.id}-${index}`}
            columnConfig={columnConfig}
            setColumnConfig={newColumnConfig => {
              const newColumnConfigs = [...columnConfigs];
              const colIndex = newColumnConfigs.findIndex(config => config === columnConfig);
              if (colIndex >= 0) {
                newColumnConfigs[colIndex] = newColumnConfig;
                setColumnConfigs(newColumnConfigs);
              }
            }}
            isEven={(index + pinnedColumns.length - 1) % 2 === 0}
            onDraggingMouseDown={onDraggingMouseDown}
            draggingItem={draggingItem}
            setDraggingItem={setDraggingItem}
            onColumnPin={onColumnPin}
          />
        ))}
      </div>
    </div>
  );
}

Columns.defaultProps = {
  showSequence: false,
  maxSequence: 0,
};

export default Columns;
