import React from 'react';
import { openFormModal } from 'components/BaseModal';
import { cloneDeep } from 'lodash';
import { getEmptyProductCategory, ProductCategory, ProductCategoryStatus } from 'types/product-gap';
import { v4 } from 'uuid';
import CreateNewCategoryForm from './CreateNewCategoryForm';
import useModalStore from 'stores/modal';
import useProductGapStore from 'stores/product-gap';
import apiErrors from 'utils/errors';
import openNewCategoryStatusModal from '../CreateNewStatusModal/CreateNewStatusModal';

/**
 * open modal to create a new product category or update an existing one
 * @param category if exist then update, otherwise create new
 * @returns new created/updated product category
 */
const openNewCategoryModal = (category?: ProductCategory): Promise<ProductCategory | undefined> => {
  const productCategory: ProductCategory = category ? cloneDeep(category) : getEmptyProductCategory();
  const modalId = v4();
  return openFormModal<ProductCategory>(
    {
      formData: productCategory,
      renderFormBody: ({ register, control, errors }) => (
        <CreateNewCategoryForm
          register={register}
          control={control}
          errors={errors}
          addEditStatus={async (status?: ProductCategoryStatus) => {
            useModalStore.getState().toggleModalVisible(modalId, true);
            const newStatus = await openNewCategoryStatusModal(status);
            useModalStore.getState().toggleModalVisible(modalId, false);
            return newStatus;
          }}
        />
      ),
      handleApiError: (error, setError) => {
        if (error.code === apiErrors.DatabaseError.code) {
          setError('title', {
            message: 'Title already exist',
            type: 'api',
          });
        }
      },
      actions: [
        {
          label: 'Cancel',
          color: 'secondary',
        },
        {
          label: category ? 'Update' : 'Create',
          color: 'primary',
          type: 'submit',
          cb: (fd: ProductCategory) => {
            if (fd.id) {
              return useProductGapStore
                .getState()
                .updateCategory(fd.id, fd.title.trim(), fd.description.trim(), fd.status?.id ?? '');
            }
            return useProductGapStore
              .getState()
              .createCategory(fd.title.trim(), fd.description.trim(), fd.status?.id ?? '');
          },
        },
      ],
    },
    {
      className: 'w-[46rem]',
      title: category ? 'Update product category' : 'Create a new product category',
      bodyClassName: '!pr-0',
    },
    modalId,
    { hideBackdrop: true },
  ).then(rsp => {
    return typeof rsp === 'object' ? (rsp as ProductCategory) : undefined;
  });
};

export default openNewCategoryModal;
