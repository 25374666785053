import { CommentContent, CustomElement, DocSource } from 'components/NoteEditor/types';
import { EventEmitter } from 'events';
import { useEffect } from 'react';
import { WBEvent } from '../types/event';

export const DOCUMENT_EVENT_KEY = 'NOTE_DOC_EVENT';
export type DOCUMENT_EVENT_TYPE = 'MENTION_CREATE' | 'MENTION_DELETE' | 'TASK_DELETE';

export interface DocumentEventPayload {
  opportunityId: string;
  documentId: string;
  type: DOCUMENT_EVENT_TYPE;
  sourceElement: CustomElement | CustomElement[] | null;
  commentContent: CommentContent | null;
  docSource: DocSource;
}

const documentEventEmitter = new EventEmitter();

export const useDocumentEventHandler = (eventHandler: (event: DocumentEventPayload) => void) => {
  useEffect(() => {
    documentEventEmitter.addListener(DOCUMENT_EVENT_KEY, eventHandler);
    return () => {
      documentEventEmitter.removeListener(DOCUMENT_EVENT_KEY, eventHandler);
    };
  }, [eventHandler]);
};

export const emitDocumentEvent = (event: DocumentEventPayload) => {
  documentEventEmitter.emit(DOCUMENT_EVENT_KEY, event);
};

export const wbSocketEventEmitter = new EventEmitter();

export const emitWBEvent = (message: string) => {
  const e = JSON.parse(message) as WBEvent;
  wbSocketEventEmitter.emit(e.type, e.body);
};
