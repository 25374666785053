import httpClient from '../http-client';

interface Response {
  numberOfAnswers: number;
  error: string | null;
}

export default async (fieldId: string, durableId: string): Promise<Response> => {
  const { data } = await httpClient.get<Response>(`/fields/${fieldId}/link-check?durableId=${durableId}`);
  return data;
};
