import React, { useEffect, useRef, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { NumericFormat } from 'react-number-format';

import EditIcon from 'components/icons/EditIcon';
import Popover from 'components/Popover';

import useReportingStore from 'stores/reporting';

import AIIcon from 'components/icons/AIIcon';
import { ColumnDataType } from '../../types';
import { isEmpty } from '../../utils';
import BaseCheckbox from 'components/Form/BaseCheckbox';

interface Props {
  value: any;
  isAnswerEmpty: boolean;
  columnType: ColumnDataType;
  fieldId: string;
  fieldValueId: string;
  analyticColumnId: string;
  placeholder?: string;
  autoFocus?: boolean;
}

function EditableValue({
  value,
  isAnswerEmpty,
  columnType,
  fieldId,
  fieldValueId,
  analyticColumnId,
  placeholder,
  autoFocus,
}: Props) {
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [displayValue, setDisplayValue] = useState<any>(value);

  const ref = useRef<HTMLDivElement>(null);

  const reportingStore = useReportingStore();

  useEffect(() => {
    if (value !== displayValue) setDisplayValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const update = async () => {
    let v = displayValue;
    if (columnType === 'Boolean') v = displayValue === 'True';

    await reportingStore.updateAnalyticTableEntry(fieldId, analyticColumnId, fieldValueId, v);
  };

  return (
    <div ref={ref} className="px-4 py-2 relative group w-full h-full whitespace-normal">
      {isEmpty(displayValue) ? <span className="text-gray-300">Empty</span> : <span>{displayValue}</span>}
      {!isAnswerEmpty && (
        <button
          type="button"
          className="absolute gap-1 top-1 right-9 hidden group-hover:flex group-hover:items-center rounded border bg-white p-1 text-sm text-gray-500 hover:bg-gray-200"
          onClick={() => {
            reportingStore.triggerAiAnalyticTableEntry(fieldId, fieldValueId, [analyticColumnId]);
          }}
        >
          <AIIcon className="w-5 h-5 text-[#AFB9CC]" />
        </button>
      )}
      <button
        type="button"
        className="absolute gap-1 top-1 right-1 hidden group-hover:flex group-hover:items-center rounded border bg-white p-1 text-sm text-gray-500 hover:bg-gray-200"
        onClick={() => {
          setIsEditOpen(true);
        }}
      >
        <EditIcon className="w-5 h-5 text-[#AFB9CC]" />
      </button>
      <Popover
        anchorEl={ref.current}
        onClose={() => {
          setIsEditOpen(false);
          update();
        }}
        isOpen={isEditOpen}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
      >
        <div className="w-96 p-2 min-h-[2rem] max-h-[25rem] overflow-y-auto">
          <div className="flex items-center w-full px-2 py-2 rounded bg-slate-100">
            {columnType === 'Text' && (
              <TextareaAutosize
                className="resize-none overflow-y-hidden bg-transparent w-full h-full text-sm text-gray-500 placeholder:text-gray-400 py-2 -my-2 d-block"
                value={displayValue}
                onChange={e => setDisplayValue(e.target.value)}
                placeholder={placeholder}
                autoFocus
                onFocus={e => {
                  e.target.selectionStart = displayValue?.length ?? 0;
                  e.target.selectionEnd = displayValue?.length ?? 0;
                }}
              />
            )}
            {columnType === 'Number' && (
              <NumericFormat
                className="text-sm bg-transparent text-gray-500 w-full"
                value={displayValue}
                onValueChange={values => setDisplayValue(values.floatValue || 0)}
                allowLeadingZeros={false}
                placeholder={placeholder}
                autoFocus
              />
            )}
            {columnType === 'Boolean' && (
              <BaseCheckbox
                className="!ml-0 w-full"
                label={placeholder}
                checked={displayValue === 'True'}
                onChange={checked => {
                  setDisplayValue(checked ? 'True' : 'False');
                }}
              />
            )}
          </div>
        </div>
      </Popover>
    </div>
  );
}

EditableValue.defaultProps = {
  placeholder: '',
  autoFocus: false,
};

export default EditableValue;
