/* eslint-disable @typescript-eslint/no-explicit-any */
import httpClient from 'api/http-client';

export interface TeamDetail {
  id: string;
  enableSharedCRM: boolean;
  crmProvider: string | null;
  adminUser: string;
  opportunityInfo: { [id: string]: any };
}

export default async (id: string): Promise<TeamDetail> => {
  const { data } = await httpClient.get<TeamDetail>(`/admin/teams/${id}`);
  return data;
};
