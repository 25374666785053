import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { concat } from 'utils/styling';
import styles from './BaseCheckbox.module.css';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import SpinLoader from 'components/icons/SpinLoader';

interface BaseCheckboxProps {
  className?: string;
  label?: string;
  labelClassName?: string;
  loading?: boolean;
  disabled?: boolean;
  checked?: boolean;
  onChange?: (v: boolean) => void;
  circleIcon?: boolean;
  indeterminate?: boolean;
}

const BaseCheckbox = React.forwardRef<HTMLButtonElement, BaseCheckboxProps>(
  ({ className, label, labelClassName, loading, disabled, checked, onChange, circleIcon, indeterminate }, ref) => {
    return (
      <FormControlLabel
        className={concat(styles.baseCheckbox, className)}
        disabled={disabled || loading}
        control={
          loading ? (
            <SpinLoader className={concat('animate-spin text-orange-500', styles.spinner)} />
          ) : (
            <Checkbox
              ref={ref}
              icon={circleIcon ? <div className={concat('w-5 h-5', styles.circle)} /> : undefined}
              checkedIcon={circleIcon ? <CheckCircleIcon className="w-5 h-5" /> : undefined}
              size="small"
              checked={checked}
              indeterminate={indeterminate}
              onChange={event => onChange?.(event.target.checked)}
              onDragOver={e => {
                e.preventDefault();
                return false;
              }}
            />
          )
        }
        label={<span className={labelClassName}>{label}</span>}
      />
    );
  },
);

BaseCheckbox.displayName = 'BaseCheckbox';

BaseCheckbox.defaultProps = {
  className: undefined,
  label: undefined,
  labelClassName: undefined,
  loading: false,
  disabled: false,
  checked: false,
  onChange: undefined,
  circleIcon: undefined,
  indeterminate: undefined,
};

export default BaseCheckbox;
