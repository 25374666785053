/* eslint-disable no-param-reassign, import/no-extraneous-dependencies  */
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { create } from 'zustand';
import { RecentlyViewedDoc, User, UserUpdateRequest } from 'types/user';
import updateUser from 'api/user/update-user';
import getMembers from 'api/user/get-members';
import updateMember from 'api/user/update-members';
import { ActionType, Invitation, Member } from 'types/member';
import Heap from '../utils/heap';
import deleteIntegration from '../api/user/delete-integration';
import Sentry from '../utils/sentry';
import updateIntegration from '../api/user/update-integration';

export interface State {
  user: null | User;
  membersLoading: boolean;
  membersLoadError: string;
  members: Member[];
  invitations: Invitation[];
  setIntegrationFrom: 'setting' | 'signup';
  setState: (newState: Partial<State>) => State;
  updateUser: (updateData: Partial<UserUpdateRequest>) => Promise<State>;
  updateRecentlyViewedDocs: (recentlyViewedDocs: RecentlyViewedDoc[]) => void;
  fetchMembers: () => Promise<void>;
  updateMember: (
    email: string,
    actionType: ActionType,
    role: string | null,
    transferEmail: string | null,
    isAdmin: boolean | null,
  ) => Promise<void>;
  updateIntegration: (integrationName: 'team-member-request', payload?: any) => Promise<void>;
  deleteIntegration: (integrationName: 'productboard') => Promise<void>;
  isSuperpanelUser: () => boolean;
  isCortexUser: () => boolean;
  isRedoxUser: () => boolean;
  isWalnutUser: () => boolean;
  isAnalyticTableEnabled: () => boolean;
}

const useUserStore = create<State>((set, get) => ({
  user: null,
  setIntegrationFrom: 'signup',
  membersLoading: false,
  membersLoadError: '',
  members: [],
  invitations: [],
  setState: (newState: Partial<State>) => {
    if ('user' in newState) {
      Heap.addUserDetail(newState.user);
      Sentry.addUserDetail(newState.user);
    }

    set(state => {
      return { ...state, ...newState };
    });
    return get();
  },
  updateUser: async (updateData: Partial<UserUpdateRequest>) => {
    const user = await updateUser(updateData);
    set(state => {
      return { ...state, user };
    });
    return get();
  },
  updateRecentlyViewedDocs: (recentlyViewedDocs: RecentlyViewedDoc[]) => {
    set(state => {
      if (!state.user) {
        return state;
      }
      return { ...state, user: { ...state.user, recentlyViewedDocs } };
    });
  },
  fetchMembers: async () => {
    set({ membersLoading: true, membersLoadError: '' });
    try {
      const { members, invitations } = await getMembers();
      set(state => {
        return { ...state, members, invitations };
      });
    } catch (e) {
      set({ membersLoadError: 'Failed to load members!' });
    } finally {
      set({ membersLoading: false });
    }
  },
  updateMember: async (
    email: string,
    actionType: ActionType,
    role: string | null,
    transferEmail: string | null,
    isAdmin: boolean | null,
  ) => {
    await updateMember(email, actionType, role, transferEmail, isAdmin);
  },
  updateIntegration: async (integrationName: 'team-member-request', payload?: any) => {
    const user = await updateIntegration(integrationName, payload);
    set(state => {
      return {
        ...state,
        user,
      };
    });
  },
  deleteIntegration: async (integrationName: 'productboard') => {
    const user = await deleteIntegration(integrationName);
    set(state => {
      return {
        ...state,
        user: { ...user, needsToSetupProduct: true },
      };
    });
  },
  isSuperpanelUser: () => {
    return get().user?.email?.includes('@superpanel.io') || get().user?.email?.includes('@appfaspo.com') || false;
  },
  isRedoxUser: () => {
    return get().user?.email?.includes('@redoxengine.com') || false;
  },
  isCortexUser: () => {
    return get().user?.email?.includes('@cortex.io') || false;
  },
  isWalnutUser: () => {
    return get().user?.email?.includes('@walnut.io') || false;
  },
  isAnalyticTableEnabled: () => {
    return get().user?.isIntegratedWithCrm || false;
  },
}));

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('User', useUserStore);
}
export default useUserStore;
