import React from 'react';

import useReportingStore from 'stores/reporting';

import { DefaultEntry, EntityRowData } from 'types/reporting';
import { CrmFieldType } from 'types/crm';

import ReportingFieldAnswer from './ReportingFieldAnswer';

interface Props {
  cellValue: EntityRowData | null;
  fieldId: string;
  rowData: DefaultEntry;
  columnName: string;
  columnData: {
    entityName: 'Account' | 'Opportunity';
    dataEntryType: CrmFieldType;
  };
}

function ReportEntityFieldCellValue({ cellValue, fieldId, rowData, columnName, columnData }: Props) {
  const { entityName, dataEntryType } = columnData;
  const reportingStore = useReportingStore();
  if (entityName === 'Opportunity' && !(entityName in rowData))
    return <div className="px-4 py-2 text-gray-300">No Opportunity</div>;
  if (!cellValue) return <div className="text-green-400 px-4 py-2 t">field is being initialized...</div>;
  const { value, fieldValueId } = cellValue;

  const documentId = entityName === 'Opportunity' ? rowData.OpportunityDocumentId : rowData.AccountDocumentId;

  return (
    <ReportingFieldAnswer
      answer={value}
      fieldValueId={fieldValueId}
      dataEntryType={dataEntryType}
      columnName={columnName}
      entityName={entityName}
      documentId={documentId}
      closePopoverOnValueChange
      onValueChange={async newValue => {
        await reportingStore.updateFieldValueId(fieldValueId, fieldId, newValue);
        await reportingStore.fetchTableRows(false);
      }}
    />
  );
}

export default ReportEntityFieldCellValue;
