/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from 'react';
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { arraySwap } from '@dnd-kit/sortable';
import FormField from 'components/Form/FormField';
import BaseButton from 'components/BaseButton';
import InputField from 'components/Form/InputField';
import { requiredInputValidate } from 'utils/validate';
import ReorderableList from 'components/ReorderableList';

interface Props {
  options: string[];
  updateOptions: (options: string[]) => Promise<void>;
}
function SelectFieldSetting({ options, updateOptions }: Props) {
  const [isAddingOption, setIsAddingOption] = useState<boolean>(false);
  const [newOption, setNewOption] = useState<string>('');
  const [newOptionEmptyError, setNewOptionEmptyError] = useState(false);
  const [localOptions, setLocalOptions] = useState(options);
  const [deletingOptionIndex, setDeletingOptionIndex] = useState(-1);

  const addNewOption = async () => {
    if (newOption !== '') {
      await updateOptions([...options, newOption]);
      setLocalOptions([...options, newOption]);
    }
    setNewOption('');
    setIsAddingOption(false);
  };

  return (
    <div>
      <FormField
        labelClassName="w-full -mr-2"
        vertical
        label={
          <div className="flex justify-between w-full">
            <span>Options</span>
            <BaseButton
              iconBtn
              color="secondary"
              variant="text"
              tooltip="Add select option"
              onClick={() => {
                setNewOptionEmptyError(false);
                setIsAddingOption(true);
                setNewOption('');
              }}
            >
              <PlusIcon className="w-4 h-4 text-gray-500" />
            </BaseButton>
          </div>
        }
        error={
          isAddingOption && newOptionEmptyError && !requiredInputValidate.validate(newOption)
            ? 'Option is mandatory'
            : ''
        }
      >
        {isAddingOption && (
          <InputField
            autoFocus
            size="small"
            placeholder="Type a new option"
            onChange={e => setNewOption(e.target.value)}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                if (!requiredInputValidate.validate(newOption)) {
                  setNewOptionEmptyError(true);
                } else {
                  addNewOption();
                }
              }
            }}
            value={newOption}
          />
        )}
      </FormField>
      <ReorderableList<string>
        list={localOptions}
        listItemClassName="pl-2"
        dragHandlerStaticPos
        renderListItem={(option, index) => (
          <div className="flex justify-between items-center py-1 w-full">
            <div className="flex items-center">
              <div>{option}</div>
            </div>
            <BaseButton
              color="secondary"
              iconBtn
              loading={deletingOptionIndex === index}
              onClick={async () => {
                try {
                  const newOptions = localOptions.filter((_item, itemIndex) => itemIndex !== index);
                  setDeletingOptionIndex(index);
                  await updateOptions(newOptions);
                  setLocalOptions(newOptions);
                } finally {
                  setDeletingOptionIndex(-1);
                }
              }}
            >
              <XMarkIcon className="w-4 h-4 text-gray-500" />
            </BaseButton>
          </div>
        )}
        handleReorder={({ fromIndex, toIndex }) => {
          const newOptions = arraySwap(localOptions, fromIndex, toIndex);
          setLocalOptions(newOptions);
          updateOptions(newOptions);
        }}
      />
    </div>
  );
}

export default SelectFieldSetting;
