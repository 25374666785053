/* eslint-disable import/prefer-default-export, no-plusplus */

import usePopupMessageStore, { PopupMessageColour } from 'stores/popup-message';
import moment from 'moment';
import { S3FileUploadObject } from 'types/file';

const allowedImageTypes = ['image/png', 'image/jpeg', 'image/gif'];

export const isValidImageFileUpload = (files: File[]): boolean => {
  // user can highligh text and drag and drop it to another place to trigger this function
  if (files.every(file => file === null)) {
    return false;
  }
  for (let i = 0; i < files.length; i += 1) {
    const file = files[i];
    if (file.type && !allowedImageTypes.includes(file.type)) {
      usePopupMessageStore.setState(prevState => ({
        ...prevState,
        isDisplayed: true,
        popupMessage: {
          colour: PopupMessageColour.RED,
          message: 'Please try uploading a supported file type such as .jpg, .png, or .gif.',
          link: null,
        },
        popupTime: moment(),
      }));
      return false;
    }
    // file size limit validation
    if (file.size > 5000000) {
      usePopupMessageStore.setState(prevState => ({
        ...prevState,
        isDisplayed: true,
        popupMessage: {
          colour: PopupMessageColour.RED,
          message: 'Please try uploading a file smaller than 5MB.',
          link: null,
        },
        popupTime: moment(),
      }));
      return false;
    }
  }
  return true;
};

export const handleFileUpload = ({
  file,
  presignedData,
}: {
  file: File;
  presignedData: S3FileUploadObject;
}): Promise<Response> => {
  const postData = new FormData();

  Object.keys(presignedData.fields).forEach(key => {
    postData.append(key, presignedData.fields[key]);
  });

  postData.append('file', file);

  return fetch(presignedData.url, {
    method: 'POST',
    body: postData,
  });
};

export const formatFileSize = (size: number): string => {
  let bytes = size;
  if (bytes === 0) {
    return '0';
  }

  const units = ['B', 'KB', 'MB'];
  let i = 0;
  while (bytes > 1024 && i < units.length) {
    bytes /= 1024;
    i++;
  }

  return `${bytes.toFixed(2)} ${units[i]}`;
};
