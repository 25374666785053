import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import integrationOauth from 'api/integration-oauth';

import Loader from 'components/Loader';
import typoStyles from 'components/typo.module.css';

import useUserStore from 'stores/user';

import { redirect } from 'utils/redirect-after-login';
import { concat } from 'utils/styling';
import { User } from 'types/user';
import registerOauth from 'api/register-oauth';
import loginOauth from '../api/login-oauth';

import styles from './GoogleOauth.module.css';
import { SuperpanelAPIError } from 'utils/errors';
import { openWarningConfirmModal } from 'components/BaseModal';

interface State {
  isLoading: boolean;
}

function GoogleOauth() {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const stateGoogle = searchParams.get('state');
  const errorMsg = searchParams.get('error');
  const [state, setState] = useState<State>({
    isLoading: true,
  });

  const navigate = useNavigate();

  const userStore = useUserStore();

  useEffect(() => {
    if (errorMsg && errorMsg === 'access_denied') {
      // User denied to provide google calendar access
      // But we still need user pass to main page
      navigate('/');
    }
  }, [errorMsg]);

  useEffect(() => {
    const oAuth = async (codeP: string): Promise<User> => {
      if (stateGoogle === 'onboarding') {
        const { user } = await integrationOauth('calendar', 'google', codeP, null);
        return user;
      }
      if (stateGoogle === 'login') {
        const { user } = await loginOauth('google', codeP);
        return user;
      }

      const token = stateGoogle === 'register' ? null : stateGoogle;
      const { user } = await registerOauth('google', codeP, token);
      return user;
    };
    const authorize = async (codeP: string): Promise<void> => {
      try {
        const user = await oAuth(codeP);
        userStore.setState({ user });
        redirect(navigate, user);
      } catch (e) {
        setState({ isLoading: false });
        const err = e as SuperpanelAPIError;
        await openWarningConfirmModal(err.displayErrorMessage, '', 'Back to Register', '');
        navigate('/register', { replace: true });
      }

      setState({ isLoading: false });
    };

    if (code) {
      authorize(code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);
  return (
    <div className={styles.layout}>
      <div className={styles.container}>
        <span className={concat(typoStyles.xl, typoStyles['semi-bold'])}>Google Authorization</span>
        {state.isLoading ? <Loader /> : null}
        <div className="text-lg">Please wait...</div>
        <span className="text-center text-gray-500">
          We are syncing your calendar events with salesforce. This task may take a while.
        </span>
      </div>
    </div>
  );
}

export default GoogleOauth;
