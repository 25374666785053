/* eslint-disable no-param-reassign, import/no-extraneous-dependencies  */
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { create } from 'zustand';

export interface AdminUser {
  userName: string;
}

export interface State {
  adminUser: null | AdminUser;
  setState: (newState: Partial<State>) => State;
}

const adminUseUserStore = create<State>((set, get) => ({
  adminUser: null,
  setState: (newState: Partial<State>) => {
    set(state => {
      return { ...state, ...newState };
    });
    return get();
  },
}));

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('AdminUser', adminUseUserStore);
}
export default adminUseUserStore;
