/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role, react/no-danger */
import React, { useCallback, useRef } from 'react';
import styles from './DocumentTitle.module.css';
import { concat } from 'utils/styling';
import isHotkey from 'is-hotkey';
import { debounce } from 'lodash';

const UPDATE_DEBOUNCE_TIME = 500;

interface Props {
  initTitle: string;
  placeholder: string;
  className?: string;
  onEnter?: () => void;
  onUpdate?: (newTitle: string) => void;
  disabled?: boolean;
}

function DocumentTitle({ initTitle, placeholder, className, onEnter, onUpdate, disabled }: Props) {
  const title = useRef(initTitle);
  const initTitleRef = useRef(initTitle);

  const handleUpdateDebounce = useCallback(
    debounce((newTitle: string) => {
      onUpdate?.(newTitle);
    }, UPDATE_DEBOUNCE_TIME),
    [],
  );

  const handleInput = useCallback((e: React.FormEvent<HTMLHeadingElement>) => {
    const newTitle = (e.target as HTMLHeadingElement).innerHTML.replace(/&nbsp;/g, ' ');
    title.current = newTitle;
    handleUpdateDebounce(newTitle);
  }, []);

  return (
    <h1
      role="textbox"
      placeholder={placeholder}
      contentEditable={!disabled}
      onInput={handleInput}
      className={concat(styles.title, className)}
      dangerouslySetInnerHTML={{ __html: initTitleRef.current }}
      onKeyDown={event => {
        const isEnterPressed = isHotkey('enter', event);
        const isShiftEnterPressed = isHotkey('shift+enter', event);
        if (isShiftEnterPressed) {
          event.preventDefault();
          return;
        }
        if (isEnterPressed) {
          onEnter?.();
          event.preventDefault();
        }
      }}
    />
  );
}

DocumentTitle.defaultProps = {
  className: '',
  onEnter: undefined,
  onUpdate: undefined,
  disabled: undefined,
};

export default DocumentTitle;
