import React from 'react';

import Productboard from 'components/logos/Productboard';
import SpinLoader from 'components/icons/SpinLoader';
import { ProductGap } from 'types/product-gap';
import ExternalLinkIcon from '../../icons/ExternalLinkIcon';

interface Props {
  productGap: ProductGap;
  isLoading: boolean;
  publish: () => void;
}

function ProductboardButtons({ productGap, isLoading, publish }: Props) {
  if (productGap.link) {
    return (
      <a
        href={productGap.link}
        target="_blank"
        rel="noreferrer"
        className="p-2 border rounded flex items-center text-gray-500 gap-2 text-sm"
      >
        <Productboard className="w-4 h-4" />
        <div>Link</div>
        <ExternalLinkIcon className="w-4 h-4" />
      </a>
    );
  }

  return (
    <button
      type="button"
      className="p-2 border rounded text-gray-500 text-sm hover:bg-slate-100 flex items-center gap-2"
      onClick={publish}
      disabled={isLoading}
    >
      {isLoading && <SpinLoader className="w-6 h-6 animate-spin" />}
      <Productboard className="w-4 h-4" />
      <div>Publish</div>
    </button>
  );
}

export default ProductboardButtons;
