import React from 'react';

import AccountIcon from 'components/icons/AccountIcon';
import OpportunityIcon from 'components/icons/OpportunityIcon';

interface Props {
  entityType: 'Account' | 'Opportunity' | null;
}

function RecordingIcon({ entityType }: Props) {
  switch (entityType) {
    case 'Account':
      return <AccountIcon className="w-3 h-3 shrink-0" />;
    case 'Opportunity':
      return <OpportunityIcon className="w-4 h-4 fill-orange-400 text-orange-400 shrink-0" />;
    default:
      return null;
  }
}

export default RecordingIcon;
