import React from 'react';

interface Props {
  className?: string;
}

function FieldSettingIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7116 9.33333H6.22222C4.99492 9.33333 4 8.33841 4 7.11111C4 5.88381 4.99492 4.88889 6.22222 4.88889H14.7116M9.28844 19.1111H17.7778C19.0051 19.1111 20 18.1162 20 16.8889C20 15.6616 19.0051 14.6667 17.7778 14.6667H9.28844M4 16.8889C4 18.6071 5.39289 20 7.11111 20C8.82933 20 10.2222 18.6071 10.2222 16.8889C10.2222 15.1707 8.82933 13.7778 7.11111 13.7778C5.39289 13.7778 4 15.1707 4 16.8889ZM20 7.11111C20 8.82933 18.6071 10.2222 16.8889 10.2222C15.1707 10.2222 13.7778 8.82933 13.7778 7.11111C13.7778 5.39289 15.1707 4 16.8889 4C18.6071 4 20 5.39289 20 7.11111Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

FieldSettingIcon.defaultProps = {
  className: undefined,
};

export default FieldSettingIcon;
