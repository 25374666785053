import httpClient from '../http-client';

export default async (documentId: string): Promise<boolean> => {
  try {
    await httpClient.delete<void>(`/documents/${documentId}`);
    return true;
  } catch (error) {
    return false;
  }
};
