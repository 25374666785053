/* eslint-disable no-param-reassign,import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { create } from 'zustand';

import { ProductFeedbackItemToAdd } from 'types/recording';
import {
  CommentPopupResponseType,
  CommentRecords,
  CustomEditor,
  MentionPopupResponseType,
} from 'components/NoteEditor/types';
import { Editor } from 'slate';

export interface State {
  currentDocumentEditor: Editor | null;
  discoveryQuestionList: { [documentId: string]: string[] };
  contentHash: string;
  callSummaryToAdd: null | { recordingId: string; documentId: string };
  callSuggestedFeedbackItem: null | ProductFeedbackItemToAdd;
  filesToUpload: null | File[];
  fileDialogId: null | string;
  uploadTargetPath: null | number[];
  tableDialogId: null | string;
  currentActionEditor: Editor | null; // the editor which current take action like create table, image, mention
  tableProperties: null | { rows: number; columns: number };
  templateToApply: null | any;
  templateToApplyDocumentId: string;
  commentContext: null | {
    editor: CustomEditor;
    comments: CommentRecords;
    ref: null | Element;
    isInit: boolean;
    onClose: (rsp: CommentPopupResponseType) => void;
  };
  mentionContext: null | {
    ref: null | Element;
    clearable: boolean;
    onClose: (rsp: MentionPopupResponseType) => void;
  };
  setState: (newState: Partial<State>) => State;
  resetState: () => State;
}

const useNoteEditorStore = create<State>((set, get) => ({
  currentDocumentEditor: null,
  discoveryQuestionList: {},
  contentHash: '',
  callSummaryToAdd: null,
  callSuggestedFeedbackItem: null,
  filesToUpload: null,
  fileDialogId: null,
  uploadTargetPath: null,
  tableDialogId: null,
  currentActionEditor: null,
  tableProperties: null,
  templateToApply: null,
  templateToApplyDocumentId: '',
  commentContext: null,
  mentionContext: null,
  setState: (newState: Partial<State>) => {
    set(state => {
      return { ...state, ...newState };
    });
    return get();
  },
  resetState: () => {
    set(() => {
      return {
        callSummaryToAdd: null,
        callSuggestedFeedbackItem: null,
      };
    });
    return get();
  },
}));

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('NoteEditor', useNoteEditorStore);
}

export default useNoteEditorStore;
