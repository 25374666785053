import React, { useCallback, useEffect, useMemo, useState } from 'react';
import TrashIcon from 'components/icons/TrashIcon';
import useProductGapStore from 'stores/product-gap';
import { ProductField, ProductFieldType } from 'types/product-gap';
import ProductFieldAnswerView from './ProductFieldAnswerView';
import ProductFieldSetting from './EditProductField/ProductFieldSetting';
import InputField from 'components/Form/InputField';
import FormField from 'components/Form/FormField';
import { requiredInputValidate } from 'utils/validate';
import { debounce } from 'lodash';
import BaseCheckbox from 'components/Form/BaseCheckbox';
import BaseButton from 'components/BaseButton';
import { openDeleteConfirmModal } from 'components/BaseModal';

const UPDATE_DEBOUNCE_TIME = 500;
interface Props {
  productFieldId: string;
  onUpdate: (newProductField: ProductField) => Promise<void>;
  onDelete: (id: string) => Promise<void>;
  onCancel: () => void;
}

function EditProductField({ productFieldId, onUpdate, onDelete, onCancel }: Props) {
  const productGapStore = useProductGapStore();
  const [fieldName, setFieldName] = useState('');
  const [defaultValue, setDefaultValue] = useState('');
  const [updatingField, setUpdatingField] = useState('');
  const updateFieldDebounce = useCallback(
    debounce(async (newProductField: ProductField, field: string) => {
      setUpdatingField(field);
      try {
        await onUpdate(newProductField);
      } finally {
        setUpdatingField('');
      }
    }, UPDATE_DEBOUNCE_TIME),
    [],
  );
  const updateFieldImmediately = useCallback(async (newProductField: ProductField, field: string) => {
    setUpdatingField(field);
    try {
      await onUpdate(newProductField);
    } finally {
      setUpdatingField('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteField = async () => {
    if (await openDeleteConfirmModal(`Are you sure you want to delete this field?`, 'This action cannot be undone.')) {
      setUpdatingField('delete');
      try {
        await onDelete(productFieldId);
      } finally {
        setUpdatingField('');
      }
      onCancel();
    }
  };

  const productField = useMemo(
    () => productGapStore.productFields.find(pf => pf.id === productFieldId),
    [productGapStore.productFields, productFieldId],
  );

  useEffect(() => {
    if (productField?.title !== fieldName) {
      setFieldName(productField?.title ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productField]);

  useEffect(() => {
    if (productField?.defaultValue !== defaultValue) {
      setDefaultValue(productField?.defaultValue ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productField]);

  if (!productField) {
    return <div>Unable to find the field</div>;
  }

  return (
    <div className="w-72 p-3 text-sm text-gray-600">
      <h3 className="text-black text-md">Edit Field</h3>
      <div className="mt-3">
        <FormField
          label="Field Name"
          vertical
          className="mb-2"
          error={requiredInputValidate.validate(fieldName) ? '' : 'Field name is mandatory'}
        >
          <InputField
            size="small"
            placeholder="Enter field name"
            value={fieldName}
            onChange={e => {
              const newV = e.target.value;
              setFieldName(newV);
              if (requiredInputValidate.validate(newV)) {
                updateFieldDebounce({ ...productField, title: newV }, 'name');
              }
            }}
            loading={updatingField === 'name'}
          />
        </FormField>
      </div>
      {productField.fieldType !== ProductFieldType.DATE && (
        <FormField label="Default Value" vertical className="mb-2">
          <ProductFieldAnswerView
            productFieldType={productField.fieldType}
            answer={defaultValue}
            additionalData={productField.additionalData}
            onChange={newV => {
              setDefaultValue(newV);
              if (productField.fieldType === ProductFieldType.SELECT) {
                updateFieldImmediately({ ...productField, defaultValue: newV || '' }, 'default-value');
              } else {
                updateFieldDebounce({ ...productField, defaultValue: newV || '' }, 'default-value');
              }
            }}
            inputPlaceholder="Input default value"
            selectPlaceholder="Select default value"
            loading={updatingField === 'default-value'}
          />
        </FormField>
      )}
      <div className="mt-2">
        <ProductFieldSetting
          type={productField.fieldType}
          additionalData={productField.additionalData}
          updateAdditionalData={async additionalData =>
            updateFieldImmediately(
              {
                ...productField,
                additionalData,
              },
              '',
            )
          }
        />
      </div>
      <div className="pt-2 mt-4 border-t">
        <BaseCheckbox
          loading={updatingField === 'mandatory'}
          label="Mark as mandatory"
          checked={productField.isMandatory}
          onChange={checked => {
            updateFieldImmediately({ ...productField, isMandatory: checked }, 'mandatory');
          }}
        />
        <BaseButton
          type="button"
          color="error"
          className="!justify-start !w-[calc(100%+1.25rem)] !-ml-2.5 !-mr-2.5 !max-w-none"
          onClick={deleteField}
          loading={updatingField === 'delete'}
        >
          <TrashIcon className="w-5 h-5 mr-1 red-500" />
          <div className="text-sm">Delete field</div>
        </BaseButton>
      </div>
    </div>
  );
}

export default EditProductField;
