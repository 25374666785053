import { Descendant } from 'slate';
import httpClient from '../http-client';
import { Task } from 'types/task';

export default async (
  id: string,
  task: { done?: boolean; content?: Descendant[]; dueDate?: string; assignedToUserId?: string },
): Promise<Task> => {
  const { data } = await httpClient.put<Task>(`/task/${id}`, task);
  return data;
};
