/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import { Transforms } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';
import { concat } from 'utils/styling';
import FieldValueView from '../../FieldValueView';
import NoteEditor from '../NoteEditor';

import { CustomRenderElementProps, DiscoveryQuestionElement } from '../types';
import styles from './DiscoveryQuestion.module.css';
import AddDiscoveryQuestion from './DiscoveryQuestion/AddDiscoveryQuestion';
import useFieldValuesStore from '../../../stores/field-values';
import useEditorSelectedFocused from 'hooks/useEditorSelectedFocused';

interface Props extends CustomRenderElementProps {
  element: DiscoveryQuestionElement;
}

function DiscoveryQuestion({ attributes, element, documentId, opportunityId, children }: Props) {
  const { discoveryQuestionId } = element;
  const editor = useSlate();
  const fieldValueStore = useFieldValuesStore();
  const selected = useEditorSelectedFocused();

  const content = useMemo(() => {
    return !discoveryQuestionId ? (
      <AddDiscoveryQuestion element={element} documentId={documentId} />
    ) : (
      <FieldValueView
        fieldValue={fieldValueStore.fieldValues.find(fv => fv.field.id === discoveryQuestionId)}
        from="docEditor"
        onEnter={() => {
          ReactEditor.focus(editor);
          NoteEditor.sp_addEmptyBlock(editor, element);
          const path = NoteEditor.sp_getElementPath(editor, element);
          Transforms.select(editor, path);
          Transforms.move(editor, { distance: 1, unit: 'line' });
        }}
      />
    );
  }, [discoveryQuestionId, fieldValueStore.fieldValues]);

  if (opportunityId === null && documentId === null) {
    return (
      <div
        {...attributes}
        className={concat('w-full text-red-400 border-dotted rounded px-5 py-2', selected && styles.selected)}
      >
        {children}
        <span className="select-none" contentEditable={false}>
          To display discovery field, you must assign opportunity to document
        </span>
      </div>
    );
  }

  return (
    <div
      {...attributes}
      className={`${concat(styles.container, selected && styles.selected)} border rounded border-gray-200 shadow-sm`}
    >
      {children}
      <div className="select-none" contentEditable={false}>
        {content}
      </div>
    </div>
  );
}

export default DiscoveryQuestion;
