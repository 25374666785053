import React from 'react';

interface Props {
  className?: string;
}

function CalendarIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.7 6.0498H6.3C5.58203 6.0498 5 6.63183 5 7.3498V18.6998C5 19.4178 5.58203 19.9998 6.3 19.9998H17.7C18.418 19.9998 19 19.4178 19 18.6998V7.3498C19 6.63183 18.418 6.0498 17.7 6.0498Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.1113 4.5V7.6"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.88867 4.5V7.6"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 10.7002H19"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

CalendarIcon.defaultProps = {
  className: undefined,
};

export default CalendarIcon;
