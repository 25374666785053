import httpClient from '../http-client';
import { SalesEngineerSummary } from 'types/team-member-request';

export default async (fieldValueId: string, userId: string, value: string): Promise<SalesEngineerSummary> => {
  const { data } = await httpClient.post<SalesEngineerSummary>(`/team-member-requests/sales-engineer-summary`, {
    fieldValueId,
    userId,
    value,
  });
  return data;
};
