import React, { useEffect, useState } from 'react';
import getUserToken from 'admin-src/api/fetch-user-token';
import SpinLoader from 'components/icons/SpinLoader';
import fetchUsers, { User } from 'admin-src/api/fetch-users';

interface State {
  isLoading: boolean;
  users: User[];
}

function GetTokenButton({ userId }: { userId: string }) {
  const [isCopied, setIsCopied] = useState<boolean>(false);
  return (
    <>
      <button
        type="button"
        className="bg-gray-200 hover:bg-gray-300 round"
        onClick={async () => {
          const userToken = await getUserToken(userId);
          navigator.clipboard.writeText(userToken.token);
          setIsCopied(true);
        }}
      >
        get token
      </button>
      {isCopied && <div className="text-sm text-gray-500">copied</div>}
    </>
  );
}

function AdminUser() {
  const [state, setState] = useState<State>({
    isLoading: true,
    users: [],
  });
  useEffect(() => {
    const getUsers = async () => {
      setState(prevState => ({ ...prevState, isLoading: true, isAuthorized: true }));
      const users = await fetchUsers();
      setState(prevState => ({ ...prevState, users, isLoading: false }));
    };

    getUsers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (state.isLoading) {
    return <SpinLoader className="w-4 h-4 animate-spin" />;
  }

  const userRows = state.users.map(user => {
    return (
      <tr key={user.id} className="">
        <td className="  text-gray-500 font-medium">{user.id}</td>
        <td className="  text-black font-medium">{user.name}</td>
        <td className="  text-black font-medium">{user.calendarEmail}</td>
        <td className="  text-black font-medium">{user.role}</td>
        <td className="  text-black font-medium">{user.teamId}</td>
        <td className="  text-black font-medium">{user.lastLoginAt}</td>
        <td className="  text-black font-medium">{user.crmEmail}</td>
        <td className="  text-black font-medium">
          <GetTokenButton userId={user.id} />
        </td>
      </tr>
    );
  });

  const userTable: JSX.Element = (
    <table className="w-full" cellSpacing={0}>
      <thead>
        <tr className="text-gray-600 font-semibold bg-gray-100">
          <td className="w-[10%]  ">id</td>
          <td className="w-[10%]   text-gray-600">name</td>
          <td className="w-[10%] ">calendar email</td>
          <td className="w-[10%] ">role</td>
          <td className="w-[10%] ">team id</td>
          <td className="w-[20%] ">last login at</td>
          <td className="w-[20%] ">crm email</td>
          <td className="w-[10%] ">token</td>
        </tr>
      </thead>
      <tbody className="">{userRows}</tbody>
    </table>
  );

  return (
    <div className="felx m-4">
      <div className="text-2xl">User</div>
      <div className="flex h-[600px] overflow-scroll py-5">{userTable}</div>
    </div>
  );
}

export default AdminUser;
