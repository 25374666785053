import React, { forwardRef, ForwardedRef } from 'react';

export interface NoteTopMenuProps {
  icon: React.ComponentType<{
    className?: string;
  }>;
  name: string;
  onClick: () => void;
}

export default forwardRef(function NoteTopMenu(props: NoteTopMenuProps, ref: ForwardedRef<HTMLButtonElement>) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { name, icon, onClick } = props;
  return (
    <button
      type="button"
      ref={ref}
      onClick={e => {
        e.preventDefault();
        onClick?.();
      }}
      className="w-full flex flex-row items-center  cursor-pointer hover:bg-gray-100 gap-1 p-1 rounded"
    >
      <props.icon className="w-5 h-5 text-gray-500" />
      <div className="text-sm text-gray-500 font-medium">{name}</div>
    </button>
  );
});
