import React, { useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';

import SpinLoader from 'components/icons/SpinLoader';
import TrashIcon from 'components/icons/TrashIcon';

import useModalStore from 'stores/modal';
import useProductGapStore from '../stores/product-gap';

interface Props {
  productGapId: string;
  onDelete: () => void;
}

function DeleteProductGapModal({ productGapId, onDelete }: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const modalStore = useModalStore();
  const productGapStore = useProductGapStore();

  return (
    <div className="w-[30rem] p-5">
      <div className="flex items-center justify-between">
        <div className="w-14 h-14 bg-red-200 rounded-full flex justify-center items-center">
          <TrashIcon className="w-8 h-8 text-red-500" />
        </div>
        <button
          type="button"
          className="w-7 h-7 p-1 rounded-lg text-gray-400 hover:bg-gray-200"
          onClick={modalStore.close}
        >
          <XMarkIcon />
        </button>
      </div>
      <div className="text-lg font-medium mt-5">Are you sure you want to delete the product gap?</div>
      <div className="text-sm text-gray-400 mt-3">Once the product gap is removed, you cannot retrieve it back.</div>
      <div className="flex justify-end items-center gap-2 mt-5">
        <button
          type="button"
          className="px-4 py-2 bg-gray-100 hover:bg-gray-200 text-gray-500 rounded"
          onClick={modalStore.close}
        >
          Cancel
        </button>
        <button
          type="button"
          className="flex items-center gap-2 px-4 py-2 bg-red-500 hover:bg-red-400 text-white rounded"
          onClick={async () => {
            setIsLoading(true);
            await productGapStore.deleteProductGap(productGapId);
            setIsLoading(false);
            modalStore.close();
            onDelete();
          }}
        >
          {isLoading && <SpinLoader className="animate-spin w-4 h-4" />}
          Delete
        </button>
      </div>
    </div>
  );
}

export default DeleteProductGapModal;
