import httpClient from '../http-client';
import { ProductGap } from '../../types/product-gap';

export default async (
  productGapId: string,
  documentId: string | null,
  categoryId: string | null,
  fieldAnswers: { [fieldId: string]: string },
  integrationId: string | null,
): Promise<ProductGap> => {
  const { data } = await httpClient.put<ProductGap>(`/product-gaps/${productGapId}`, {
    documentId,
    categoryId,
    fieldAnswers,
    integrationId,
  });
  return data;
};
