import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import styles from './DocAccordion.module.css';
import useDocumentStore from 'stores/document';
import { Document, NoteService } from 'types/document';
import DocumentEditor from 'pages/Document/DocumentEditor';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import DocumentHeader from 'pages/Document/DocumentHeader';
import { noop } from 'lodash';
import Loader from 'components/Loader';
import { concat } from 'utils/styling';
import SummarySelect from 'pages/Document/DocumentView/SummarySelect';
import ProdFeedbackSelect from 'pages/Document/DocumentView/ProductFeedback';
import useUserStore from 'stores/user';
import { useParams } from 'react-router-dom';

interface Props {
  doc: Document;
  currentDoc: string;
  setCurrentDoc: (id: string) => void;
  scrollContainerRef: React.RefObject<HTMLDivElement>;
}

function DocAccordion({ doc, currentDoc, setCurrentDoc, scrollContainerRef }: Props) {
  const [loading, setLoading] = useState('');
  const [noteService, setNoteService] = useState<NoteService | null>(null);
  const documentStore = useDocumentStore();
  const userStore = useUserStore();
  const { documentId } = useParams();

  const fetchDocument = useCallback(async (docId: string) => {
    try {
      setLoading(docId);
      const { noteService: noteSvc } = await documentStore.getDocument(docId);
      setNoteService(noteSvc);
    } finally {
      setLoading('');
    }
  }, []);

  const document = useMemo(
    () => documentStore.documents.find(d => d.id === currentDoc),
    [documentStore.documents, currentDoc],
  );

  useEffect(() => {
    if (currentDoc === doc.id && loading !== doc.id && !noteService) {
      fetchDocument(currentDoc);
    }
  }, [doc, currentDoc, noteService, loading]);

  return (
    <Accordion
      expanded={currentDoc === doc.id}
      onChange={(_event, expanded) => {
        setCurrentDoc(expanded ? doc.id : '');
      }}
      id={doc.id}
      className={styles.docAccordion}
    >
      <AccordionSummary expandIcon={<ChevronDownIcon className="text-gray-400 w-4 h-4" />}>
        {!!doc && (
          <DocumentHeader
            className={concat(styles.docHeader)}
            titleClassName={concat(styles.docTitle, currentDoc === doc.id && styles.docTitleFocused)}
            document={doc}
            setLoading={noop}
            docAccordionMode
            onApplyTemplate={() => {
              setCurrentDoc(doc.id);
            }}
          />
        )}
      </AccordionSummary>
      <AccordionDetails>
        {doc.id !== documentId ? (
          <div className={concat(styles.accordionContent, loading && styles.loading)}>
            {loading && (
              <div className="h-full w-full flex items-center justify-center">
                <Loader className="w-20 h-20" />
              </div>
            )}
            {!loading && currentDoc === doc.id && !!document && !!noteService && (
              <div className={styles.cardBody}>
                <DocumentEditor
                  className={styles.editor}
                  showDocumentHeader={false}
                  document={document}
                  setLoading={() => setLoading(doc.id)}
                  noteService={noteService}
                  docPrefixElement={
                    <>
                      <SummarySelect documentId={doc.id} className="!mx-9" />
                      {!userStore.user?.needsToSetupProduct && (
                        <ProdFeedbackSelect documentId={document.id} className="!mx-9" />
                      )}
                    </>
                  }
                  scrollContainerRef={scrollContainerRef}
                  isMeetingEditor
                />
              </div>
            )}
          </div>
        ) : (
          <div className="relative">
            <div className="blur-sm">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
            </div>
            <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full text-lg text-gray-700 font-semibold">
              👈 This meeting has been opened on the left panel
            </span>
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  );
}

export default DocAccordion;
