import { Document } from 'types/document';
import httpClient from '../http-client';

/**
 * Create new document
 * @param parentId document parent(account/opportunity) id
 * @param noteTemplateId note template id used to create new document
 * @param fileId the file id used to create a new document with uploaded pdf/word file
 * @param documentName the new document name
 * @returns new created document
 */
export default async (
  parentId: null | string,
  noteTemplateId: null | string,
  fileId: null | string = null,
  documentName: null | string = null,
): Promise<Document> => {
  const { data } = await httpClient.post<Document>(`/documents`, { parentId, noteTemplateId, fileId, documentName });
  return data;
};
