import React, { useEffect, useRef, useState } from 'react';

import useContextPanelStore from 'stores/context-panel';
import useSideMenuStore from 'stores/side-menu';

import constants from 'utils/constants';

interface Props {
  children: React.ReactNode;
}

/**
 * Listens to screen width and close side menu if context panel is open
 */
function ScreenWidthListener({ children }: Props) {
  const [screenWidth, setScreenWidth] = useState<number>(0);

  const ref = useRef<HTMLDivElement>(null);

  const contextPanelStore = useContextPanelStore();
  const sideMenuStore = useSideMenuStore();

  useEffect(() => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      setScreenWidth(rect.width);
    }

    const onWindowResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', onWindowResize);
    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, [ref]);

  useEffect(() => {
    if (screenWidth < constants.SMALL_SCREEN_WIDTH_SIZE) {
      const isContextPanelOpen = contextPanelStore.panelContext !== null;
      const isSideMenuOpen = sideMenuStore.calendarMenu !== 'fixed' || sideMenuStore.noteMenu !== 'fixed';

      if (isContextPanelOpen && isSideMenuOpen) {
        sideMenuStore.close();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenWidth]);

  return (
    <div ref={ref} className="flex w-screen h-screen">
      {children}
    </div>
  );
}

export default ScreenWidthListener;
