import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/outline';
import SpinLoader from 'components/icons/SpinLoader';
import React, { useState } from 'react';

import useModalStore from 'stores/modal';
import { AnalyticColumnSubHeader } from 'types/reporting';
import useReportingStore from '../../../../stores/reporting';

function capitalizeFirstLetter(value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

interface Props {
  analyticColumnSubHeader: AnalyticColumnSubHeader;
}

function EditAnalyticColumnModal({ analyticColumnSubHeader }: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [columnName, setColumnName] = useState<string>(analyticColumnSubHeader.columnName);
  const [description, setDescription] = useState<string>(analyticColumnSubHeader.description);

  const modalStore = useModalStore();
  const reportingStore = useReportingStore();

  return (
    <div className="w-[30rem]">
      <div className="flex items-center justify-between p-5 border-b">
        <div className="text-xl font-medium">Edit column</div>
        <button
          type="button"
          className="w-7 h-7 p-1 rounded-lg text-gray-400 bg-gray-100 hover:bg-gray-200"
          onClick={modalStore.close}
        >
          <XMarkIcon />
        </button>
      </div>
      <div className="p-5">
        <div className="mt-4">
          <div className="text-xs px-2 text-gray-500">Column Name</div>
          <input
            className="border rounded w-full p-2"
            value={columnName}
            onChange={e => setColumnName(e.target.value)}
          />
        </div>
        <div className="mt-4">
          <div className="text-xs px-2 text-gray-500">Data Type</div>
          <div>
            <div className="border rounded w-full p-2 flex justify-between bg-gray-200 cursor-not-allowed">
              <div className="text-sm">{capitalizeFirstLetter(analyticColumnSubHeader.dataEntryType)}</div>
              <ChevronDownIcon className="w-4 h-4" />
            </div>
          </div>
        </div>
        <div className="mt-4">
          <div className="text-xs px-2 text-gray-500">Description</div>
          <textarea
            className="border rounded w-full p-2 resize-none h-24"
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
        </div>
        <div className="flex justify-end items-center gap-2 mt-5">
          <button
            type="button"
            className="px-4 py-2 bg-gray-100 hover:bg-gray-200 text-gray-500 rounded"
            onClick={modalStore.close}
          >
            Cancel
          </button>
          <button
            type="button"
            className={`flex items-center gap-2 px-4 py-2 text-white rounded ${
              isLoading ? 'bg-gray-400' : 'bg-blue-600 hover:bg-blue-400 '
            }`}
            onClick={async () => {
              setIsLoading(true);
              await reportingStore.updateAnalyticTableColumn(analyticColumnSubHeader.id, columnName, description);
              setIsLoading(false);
              await modalStore.close();
            }}
            disabled={isLoading}
          >
            {isLoading && <SpinLoader className="animate-spin w-4 h-4" />}
            Update
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditAnalyticColumnModal;
