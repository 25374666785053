import React from 'react';

interface Props {
  className?: string;
}
function Productgap({ className }: Props) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 14.575C6 14.575 6.7815 13.8 9.125 13.8C11.4685 13.8 13.0315 15.35 15.375 15.35C17.7185 15.35 18.5 14.575 18.5 14.575V5.275C18.5 5.275 17.719 6.05 15.375 6.05C13.031 6.05 11.469 4.5 9.125 4.5C6.781 4.5 6 5.275 6 5.275V14.575Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 20.0002V14.5752"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

Productgap.defaultProps = {
  className: undefined,
};

export default Productgap;
