import SalesforceNoteIcon from 'components/icons/SalesforceNoteIcon';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { CrmNote } from 'types/document';
import { EntityDocument } from 'types/entity';
import useDocumentStore from '../../stores/document';
import useNavMenuStore from '../../stores/nav-menu';

interface Props {
  crmNote: CrmNote;
  ml: string;
  addPaddingOffSet?: boolean;
}
interface State {
  data: EntityDocument | null;
}

function CrmNoteLink({ crmNote, ml, addPaddingOffSet = true }: Props) {
  const [state, setState] = useState<State>({
    data: null,
  });
  const documentStore = useDocumentStore();

  useEffect(() => {
    const fetch = async () => {
      const entityDocument = await documentStore.getOrCreateNewSFEntity(
        crmNote.linkedEntity.id,
        crmNote.linkedEntity.name,
      );
      if (entityDocument) {
        setState({ data: entityDocument });
      }
    };
    fetch();
  }, []);

  const location = useLocation();
  const url = `/crm-note/${crmNote.id}`;

  const title = crmNote.title.length > 23 ? crmNote.title.slice(0, 23).concat('...') : crmNote.title;

  const crmNoteLinkRef = React.useRef<HTMLDivElement>(null);

  const navMenuStore = useNavMenuStore();

  useEffect(() => {
    if (crmNoteLinkRef.current && location.pathname.match(url)) {
      navMenuStore.setState({ activeEntityRef: crmNoteLinkRef });
    }
  }, [crmNoteLinkRef, location.pathname]);

  let offset = '';
  if (addPaddingOffSet) {
    offset = 'ml-2';
  }

  let toUrl = '';
  if (state.data) {
    toUrl = `${url}?entity-document-id=${state.data.id}`;
  }
  return (
    <div ref={crmNoteLinkRef} className={`relative flex gap-1 items-center pr-3 ${ml} scroll-m-36`}>
      <Link
        className={`w-full flex items-center gap-0.5 rounded my-0.5 hover:bg-gray-200 hover:text-black ${
          location.pathname.match(url) ? 'text-blue-600 bg-gray-100 font-semibold' : 'text-gray-500 font-medium'
        }`}
        to={toUrl}
        style={{ textDecoration: 'none' }}
      >
        <div className={`flex items-center gap-2 p-1 decoration-0 overflow-x-clipw ${offset}`}>
          <SalesforceNoteIcon className="min-w-[1.25rem] w-5 h-5 flex-shrink-0" />

          <div className="text-sm w-full overflow-x-clip overflow-ellipsis whitespace-nowrap text-left">
            <span>{title}</span>
          </div>
        </div>
      </Link>
    </div>
  );
}

CrmNoteLink.defaultProps = {
  addPaddingOffSet: true,
};

export default CrmNoteLink;
