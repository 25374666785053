/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-props-no-spreading, jsx-a11y/click-events-have-key-events */
import React, { useRef } from 'react';
import { BlockType, CustomEditor, AIWorkerElement as AIWorkerElementType } from 'components/NoteEditor/types';
import { Descendant, Transforms } from 'slate';
import { ReactEditor, RenderElementProps, useSelected } from 'slate-react';
import { concat } from 'utils/styling';
import AIWorkerEditor from './AIWorkerEditor';
import styles from './AIWorkerElement.module.css';
import { createNode } from 'components/NoteEditor/utils';

interface Props extends RenderElementProps {
  element: AIWorkerElementType;
  documentId: string | null;
  editor: CustomEditor;
  isFromTemplate?: boolean;
  readOnly?: boolean;
}

function AIWorkerElement({ attributes, element, children, documentId, editor, isFromTemplate, readOnly }: Props) {
  const selected = useSelected();
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={ref}
      contentEditable={false}
      className={concat(
        styles.aiWorkerContainer,
        selected && styles.selected,
        'ai-worker border rounded border-gray-200 mb-2 mt-2',
        element.aiWorkerId && `ai-worker-${element.aiWorkerId}`,
      )}
      onClick={() => {
        if (!selected) {
          Transforms.select(editor, ReactEditor.findPath(editor, element));
        }
      }}
    >
      <AIWorkerEditor
        id={element.aiWorkerId}
        beingCreatedBy={element.beingCreatedBy}
        blockUUID={element.uuid}
        documentId={documentId}
        onCreate={newWorker => {
          const path = ReactEditor.findPath(editor, element);
          Transforms.setNodes(
            editor,
            { aiWorkerId: newWorker.id, beingCreatedBy: undefined, initContent: undefined },
            { at: path },
          );
        }}
        onInstantiate={newWorker => {
          const path = ReactEditor.findPath(editor, element);
          Transforms.setNodes(
            editor,
            { aiWorkerId: newWorker.id, beingCreatedBy: undefined, initContent: undefined },
            { at: path },
          );
        }}
        onDelete={(leftContent: Descendant[] | undefined) => {
          ReactEditor.focus(editor);
          const path = ReactEditor.findPath(editor, element);
          Transforms.removeNodes(editor, { at: path });
          Transforms.insertNodes(editor, leftContent?.length ? leftContent : createNode(BlockType.Paragraph), {
            at: path,
          });
        }}
        onFocus={() => {
          if (!selected) {
            Transforms.select(editor, ReactEditor.findPath(editor, element));
          }
        }}
        isFromTemplate={isFromTemplate}
        readOnly={readOnly}
      />
      <span {...attributes}>{children}</span>
    </div>
  );
}

AIWorkerElement.defaultProps = {
  isFromTemplate: false,
  readOnly: false,
};

export default AIWorkerElement;
