import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';

import useContextPanelStore from 'stores/context-panel';

import { SuggestedAnswer as ISuggestedAnswer } from 'types/field-value';
import useFieldValuesStore from '../../stores/field-values';
import useRecordingsStore from '../../stores/recordings';
import constants from '../../utils/constants';
import ThreeVerticalDotsIcon from '../icons/ThreeVerticalDotsIcon';
import Popover from '../Popover';

import PlayBack from './PlayBack';
import RecordingIcon from './RecordingsIcon';

interface Props {
  fieldValueId: string;
  suggestedAnswer: ISuggestedAnswer;
  updateAnswer: (answer: string) => Promise<void>;
  from: 'discoveryQuestionContextPanel' | 'docEditor' | 'reporting';
}

interface State {
  isLoading: boolean;
  isMoreOpen: boolean;
}

function VerifiedSuggestedAnswer({ fieldValueId, suggestedAnswer, updateAnswer, from }: Props) {
  const [state, setState] = useState<State>({
    isLoading: false,
    isMoreOpen: false,
  });

  const buttonRef = useRef<HTMLButtonElement>(null);

  const fieldValuesStore = useFieldValuesStore();
  const contextPanelStore = useContextPanelStore();
  const recordingStore = useRecordingsStore();

  const updateSuggestedAnswerStatus = async (status: 'accepted' | 'rejected' | null): Promise<void> => {
    setState(oldState => ({ ...oldState, isLoading: true }));
    if (suggestedAnswer.id !== fieldValueId) {
      await fieldValuesStore.updateSuggestedAnswerStatus(fieldValueId, suggestedAnswer.id, status);
    } else {
      await fieldValuesStore.updateFieldValueSuggestionStatus(fieldValueId, status);
    }
    if (status === 'accepted') {
      updateAnswer(suggestedAnswer.suggestedAnswer);
    }
    setState(oldState => ({ ...oldState, isLoading: false, isMoreOpen: false }));
  };
  const elementId = `${from}-${suggestedAnswer.id}`;

  useEffect(() => {
    if (elementId === contextPanelStore.jumpToSuggestedAnswerId) {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        contextPanelStore.setState({ jumpToSuggestedAnswerId: undefined });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const recording = recordingStore.recordings.find(r => r.id === suggestedAnswer.callId);

  return (
    <div id={elementId} className="w-fit p-2 group hover:bg-slate-50 rounded">
      <div className="flex items-start gap-1">
        <div className="text-gray-500 inline text-sm">{suggestedAnswer.suggestedAnswer.trim()}</div>
        {suggestedAnswer.acceptanceStatus === 'accepted' ? (
          <div className="flex items-center ml-2 gap-1 text-gray-400 text-sm">
            <CheckIcon className="bg-green-200 text-green-600 stroke-2 rounded-full p-0.5 w-5 h-5" />
            <div>Accepted</div>
          </div>
        ) : (
          <div className="flex items-center ml-2 gap-1 text-gray-400 text-sm">
            <XMarkIcon className="self-center bg-red-200 text-red-600 stroke-2 rounded-full p-0.5 w-5 h-5" />
            <div>Dismissed</div>
          </div>
        )}
        <button
          ref={buttonRef}
          type="button"
          className={`w-5 h-5 ${!state.isMoreOpen && 'hidden group-hover:block'} `}
          onClick={() => {
            setState(prevState => ({ ...prevState, isMoreOpen: true }));
          }}
        >
          <ThreeVerticalDotsIcon className="w-5 h-5 text-gray-400 fill-gray-400" />
        </button>
        <Popover
          anchorEl={buttonRef.current}
          onClose={() => setState(prevState => ({ ...prevState, isMoreOpen: false }))}
          isOpen={state.isMoreOpen}
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        >
          <div className="flex flex-col">
            <button
              type="button"
              className="text-left px-3 py-1 hover:bg-gray-100 text-sm"
              onClick={() => updateSuggestedAnswerStatus(null)}
              disabled={state.isLoading}
            >
              Unmark as {suggestedAnswer.acceptanceStatus === 'accepted' ? 'accepted' : 'dismissed'}
            </button>
            <button
              type="button"
              className="text-left px-3 py-1 hover:bg-gray-100 text-sm"
              onClick={() =>
                updateSuggestedAnswerStatus(suggestedAnswer.acceptanceStatus === 'accepted' ? 'rejected' : 'accepted')
              }
              disabled={state.isLoading}
            >
              {suggestedAnswer.acceptanceStatus === 'accepted' ? 'Reject' : 'Accept'}
            </button>
          </div>
        </Popover>
      </div>
      {!!suggestedAnswer.callId && (
        <div className="mt-1 flex gap-2 items-center">
          {recording && <RecordingIcon entityType={recording.entityType} />}
          <div className="text-gray-400 text-xs">
            {recording && recording.callData.title
              ? `${recording.callData.title}, ${moment(recording.callData.started).format(constants.DATE_FORMAT)}`
              : 'No Title'}
          </div>
          <PlayBack
            recordingId={suggestedAnswer.callId}
            startTime={suggestedAnswer.contextStartTime}
            from={from}
            elementId={elementId}
          />
        </div>
      )}
    </div>
  );
}
export default VerifiedSuggestedAnswer;
