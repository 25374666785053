import React, { useState } from 'react';
import { CheckIcon } from '@heroicons/react/24/outline';
import AccountIcon from 'components/icons/AccountIcon';
import { Recording } from 'api/recordings/get-recordings';
import useDocumentStore from 'stores/document';
import SpinLoader from 'components/icons/SpinLoader';
import { SearchItem } from '../../../api/search/search-items';

interface Props {
  searchItem: SearchItem;
  recording: Recording;
  updateData: (objType: 'opp' | 'doc' | 'acct', objId: string) => void;
}

function AccountButtonFromSearch({ searchItem, recording, updateData }: Props) {
  const documentStore = useDocumentStore();
  let isLinked = false;
  if (searchItem.documentId !== null && recording.entityOrDocumentId === searchItem.documentId) {
    isLinked = true;
  } else if (searchItem.crmId !== null && recording.crmAccountId === searchItem.crmId) {
    isLinked = true;
  }

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getOrCreateNewSFEntityMain = async (salesforceCrmId: string) => {
    const destinationEntity = await documentStore.getOrCreateNewSFEntity(salesforceCrmId, 'Account');
    return destinationEntity;
  };

  return (
    <div>
      <div className="flex justify-between items-center pr-3 hover:bg-slate-100 rounded-lg group">
        <button
          type="button"
          className={`flex w-full items-center text-sm p-2 gap-2 ${isLinked ? 'cursor-default' : null} `}
          onClick={async () => {
            setIsLoading(true);
            // we need to get or create entity first
            if (searchItem.documentId) {
              await documentStore.getOrFetchEntityByDocId(searchItem.documentId);
              updateData('acct', searchItem.documentId);
            } else if (searchItem.crmId) {
              const entity = await getOrCreateNewSFEntityMain(searchItem.crmId);
              if (entity) {
                updateData('acct', entity.id);
              }
            }
            setIsLoading(false);
          }}
          disabled={isLinked || isLoading}
        >
          <AccountIcon className="w-4 h-4" />
          <span className="text-gray-500">{searchItem.title}</span>
          {isLoading && (
            <div>
              <SpinLoader className="animate-spin w-4 h-4 text-orange-500" />
            </div>
          )}
        </button>
        {isLinked && <CheckIcon className="flex-none w-5 h-5 text-blue-500" />}
      </div>
    </div>
  );
}

export default AccountButtonFromSearch;
