/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { MemberRole } from 'types/member';
import { CheckIcon } from '@heroicons/react/24/outline';

interface RoleMenuProps {
  memberRole: MemberRole;
  isCurrentRole: boolean;
  onChange: (role: MemberRole) => void;
}

function RoleMenu({ memberRole, isCurrentRole, onChange }: RoleMenuProps) {
  return (
    <MenuItem
      className="block w-full px-4 py-2 text-left text-sm hover:bg-gray-50"
      onClick={() => onChange(memberRole)}
      disabled={isCurrentRole}
    >
      {memberRole} {isCurrentRole && <CheckIcon className="w-4 h-4 text-emerald-500 ml-2" />}
    </MenuItem>
  );
}

interface Props {
  currentRole: MemberRole;
  onChange: (role: MemberRole) => void;
}

function RoleDropdownMenu({ currentRole, onChange }: Props) {
  return (
    <div className="w-52 bg-white focus:outline-none">
      <RoleMenu
        isCurrentRole={currentRole === 'Sales Engineer Manager'}
        memberRole="Sales Engineer Manager"
        onChange={onChange}
      />
      <RoleMenu isCurrentRole={currentRole === 'Sales Engineer'} memberRole="Sales Engineer" onChange={onChange} />
      <RoleMenu
        isCurrentRole={currentRole === 'Account Executive'}
        memberRole="Account Executive"
        onChange={onChange}
      />
      <RoleMenu isCurrentRole={currentRole === 'Other'} memberRole="Other" onChange={onChange} />
    </div>
  );
}

export default RoleDropdownMenu;
