import httpClient from '../http-client';
import { ProductCategory } from '../../types/product-gap';

export default async (title: string, description: string, statusId: string): Promise<ProductCategory> => {
  const { data } = await httpClient.post<ProductCategory>(`/product-categories`, {
    title,
    description,
    statusId,
  });
  return data;
};
