import { DocumentChartBarIcon, EllipsisVerticalIcon, LockClosedIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import CalendarIcon from 'components/icons/CalendarIcon';
import NoteIcon from 'components/icons/NoteIcon';
import StarIcon from 'components/icons/StarIcon';
import SalesforceNoteIcon from 'components/icons/SalesforceNoteIcon';
import DocumentLinkPopover from 'components/DocumentLinkPopover';
import RecordingIcon from 'components/icons/RecordingIcon';

import { Event } from 'types/document';
import PdfIcon from 'components/icons/PdfIcon';
import WordIcon from 'components/icons/WordIcon';
import TxtIcon from 'components/icons/TxtIcon';

function TimerPill({ event }: { event: Event }) {
  const endsAt = moment(event.endsAt);
  const endsAtDiff = moment().diff(endsAt, 'minutes');
  if (endsAtDiff > 0) {
    // Event already ended
    return null;
  }

  const startsAt = moment(event.startsAt);
  const startsAtDiff = moment().diff(startsAt, 'minutes');

  if (startsAtDiff < -30) {
    // Event will happen more than 30 minutes later
    return null;
  }

  return (
    <span className="">
      {startsAtDiff > 0 && endsAtDiff < 0 ? 'Now' : `In ${startsAtDiff > 1 ? 'minutes' : 'minute'}`}
    </span>
  );
}

interface State {
  isLoading: boolean;
  isMenuOpen: boolean;
}

interface Props {
  id: string;
  title: string;
  link: string;
  event: null | Event;
  isPublic: boolean;
  isFavourite: boolean;
  isRecordingAvailable: boolean;
  isFavorable: boolean;
  type: 'default' | 'crm' | 'document' | 'pdf' | 'word' | 'txt';
}

function DocumentLink({
  id,
  title,
  link,
  event,
  isPublic,
  isFavourite,
  isRecordingAvailable,
  isFavorable,
  type,
}: Props) {
  const [state, setState] = useState<State>({
    isLoading: false,
    isMenuOpen: false,
  });

  const buttonRef = useRef<HTMLButtonElement>(null);

  let docIcon: JSX.Element = <NoteIcon className="w-5 h-5" />;
  if (event) {
    docIcon = <CalendarIcon className="w-5 h-5" />;
  } else if (type === 'crm') {
    docIcon = <SalesforceNoteIcon className="w-5 h-5" />;
  } else if (type === 'default') {
    docIcon = <DocumentChartBarIcon className="w-[18px] h-[18px] flex-shrink-0" />;
  } else if (type === 'pdf') {
    docIcon = <PdfIcon className="shrink-0 w-5 h-5" />;
  } else if (type === 'txt') {
    docIcon = <TxtIcon className="shrink-0 w-5 h-5" />;
  } else if (type === 'word') {
    docIcon = <WordIcon className="shrink-0 w-5 h-5" />;
  }

  return (
    <div id="link-from-opp-page" className="w-full flex justify-between rounded group">
      <div className="w-full flex gap-2 items-center text-gray-500 font-medium">
        <div className="flex items-center">{docIcon}</div>
        <Link to={link} className="flex items-center flex-grow gap-2">
          <span>{title}</span>
          {event && <span className="ml-2 text-gray-400">({moment(event.startsAt).format('MMM DD')})</span>}
          {isRecordingAvailable && <RecordingIcon className="w-5 h-5 text-gray-400" />}
          {!isPublic && (
            <span className="ml-1 flex gap-1 text-xs text-gray-400">
              <LockClosedIcon className="w-4 h-4" />
            </span>
          )}
          {event && <TimerPill event={event} />}
          {isFavourite && <StarIcon className="w-5 h-5 text-yellow-400" />}
        </Link>
      </div>
      {(type === 'document' || type === 'word' || type === 'pdf' || type === 'txt') && (
        <div>
          <button
            ref={buttonRef}
            className={`flex items-center justify-center rounded w-6 h-6 bg-gray-200 px-1 py-1 ${
              state.isMenuOpen ? 'block' : 'hidden group-hover:block'
            }`}
            type="button"
            onClick={() => setState(prevState => ({ ...prevState, isMenuOpen: true }))}
          >
            <EllipsisVerticalIcon className="w-4 h-4" />
          </button>
          <DocumentLinkPopover
            anchorEl={buttonRef.current}
            onClose={() => setState(prevState => ({ ...prevState, isMenuOpen: false }))}
            isOpen={state.isMenuOpen}
            documentId={id}
            isFavorable={isFavorable}
            hideFavoriteToggle={type !== 'document'}
            hidePublicToggle={type !== 'document'}
          />
        </div>
      )}
    </div>
  );
}

export default DocumentLink;
