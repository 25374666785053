/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { RenderLeafProps } from 'slate-react';
import typoStyles from 'components/typo.module.css';
import codeStyles from './Leaf.module.css';
import { CustomText } from './types';
import CommentLeafElement from './CustomElement/Comment/CommentLeafElement';
import { concat } from 'utils/styling';

interface Props extends Omit<RenderLeafProps, 'text' | 'attributes'> {
  leaf: CustomText;
  text?: CustomText;
  attributes?: {
    'data-slate-leaf': true;
  };
}

function Leaf({ attributes, children, leaf, text }: Props) {
  let element = children;

  if (leaf.bold) {
    element = <strong className={concat(codeStyles.bold, typoStyles['semi-bold'])}>{element}</strong>;
  }

  if (leaf.code) {
    element = <span className={codeStyles.code}>{element}</span>;
  }

  if (leaf.italic) {
    element = <em>{element}</em>;
  }

  if (leaf.underline) {
    element = <u>{element}</u>;
  }

  if (leaf.strikethrough) {
    element = <del>{element}</del>;
  }

  if (leaf.comment || leaf.commentInit) {
    element = (
      <CommentLeafElement commentId={leaf.comment} commentInit={!!leaf.commentInit} text={text}>
        {element}
      </CommentLeafElement>
    );
  }

  return (
    <span
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...attributes}
      style={{
        position: 'relative',
        backgroundColor: leaf.alphaColor,
      }}
    >
      {element}
    </span>
  );
}

Leaf.defaultProps = {
  text: undefined,
  attributes: undefined,
};

export default Leaf;
