import React from 'react';

interface Props {
  className?: string;
}

function Gmail({ className }: Props) {
  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.90917 26.9939H8.36391V16.1783L2 11.4067V25.0853C2 26.1414 2.85595 26.9939 3.90917 26.9939Z"
        fill="#4285F4"
      />
      <path
        d="M23.6367 26.9939H28.0915C29.1479 26.9939 30.0006 26.1382 30.0006 25.0853V11.4067L23.6367 16.1783"
        fill="#34A853"
      />
      <path
        d="M23.6367 7.90819L23.6367 16.1783L30.0006 11.4067L30.0006 8.86251C30.0006 6.50216 27.3054 5.15657 25.4185 6.57215"
        fill="#FBBC04"
      />
      <path d="M8.36523 16.1784V7.90767L16.0019 13.6336L23.6386 7.90767V16.1784L16.0019 21.9044" fill="#EA4335" />
      <path
        d="M2 8.86251V11.4067L8.36391 16.1783V7.90819L6.58201 6.57215C4.69193 5.15657 2 6.50216 2 8.86251Z"
        fill="#C5221F"
      />
    </svg>
  );
}

Gmail.defaultProps = {
  className: undefined,
};
export default Gmail;
