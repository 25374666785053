import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';

import { CheckDocument } from 'api/documents/check_document_opportunity';

import OpportunityIcon from 'components/icons/OpportunityIcon';
import Loader from 'components/Loader';
import OtherEventsToLink from 'components/OtherEventsToLink';
import LinkBrokenIcon from 'components/icons/LinkBrokenIcon';
import AccountIcon from 'components/icons/AccountIcon';

import useModalStore from 'stores/modal';
import useDocumentStore from 'stores/document';

import { EntityDocument } from 'types/entity';

interface Props {
  checkDocument: CheckDocument;
  entityDocument: EntityDocument | null;
  documentId: string;
  setLoading: (isLoading: boolean) => void;
  onUpdatedDocFolderByApi?: () => void;
}

interface State {
  modalStage: 'loading' | 'confirm' | 'none' | 'done';
  checkedDocumentIds: string[];
}

function ChangeOpportunityWarningModal({
  checkDocument,
  entityDocument,
  documentId,
  setLoading,
  onUpdatedDocFolderByApi,
}: Props) {
  // TODO: order checkDocument.events
  const modalStore = useModalStore();
  const documentStore = useDocumentStore();
  const document = documentStore.documents.find(d => d.id === documentId);

  const [state, setState] = useState<State>({
    modalStage: 'none',
    checkedDocumentIds: [],
  });

  const updateSelectedEntity = async () => {
    modalStore.close();
    setLoading(true);
    if (entityDocument) {
      await documentStore.updateDocumentEntity(documentId, state.checkedDocumentIds, entityDocument.id);
      onUpdatedDocFolderByApi?.();
    } else {
      await documentStore.unlinkDocumentEntity(documentId);
      // hacky way to fix
      // https://github.com/orgs/faspo/projects/4/views/2?filterQuery=&pane=issue&itemId=32921269
      // someone, the unlink triggers the doc view to be reloaded, and fetching AI chat which cause error
      window.location.reload();
    }
    setLoading(false);
  };

  if (state.modalStage === 'loading') {
    return (
      <div className="flex-grow flex items-center h-full justify-center">
        <Loader />
      </div>
    );
  }

  let docName = '';
  if (document?.event) {
    docName = document?.event?.subject;
  } else if (document?.docTitle) {
    docName = document?.docTitle;
  }

  let defaultWarningInfo: null | JSX.Element = null;
  defaultWarningInfo = (
    <div className="px-2 border border-gray-300 rounded-lg mt-3">
      <div className="my-3 font-medium text-lg">Are you sure you want make the change?</div>
      <div>
        <ul className="px-5 text-gray-500">
          {checkDocument.showCallRecordingWarning && (
            <li className="my-4">
              <span className="bg-amber-200 text-gray-700 p-1 rounded">Call recordings</span> will be moved to the new{' '}
              {entityDocument?.entity.name}
            </li>
          )}
          {checkDocument.showSuggestedAnswersWarning && (
            <li className="my-4">
              <span className="bg-amber-200 text-gray-700 p-1 rounded">Suggested answers</span> to call recordings will
              be transferred to the new {entityDocument?.entity.name}
            </li>
          )}
          {checkDocument.showDiscoveryQuestionAnswerWarning && (
            <>
              <li className="my-4">Answers in the document will be lost!</li>
              {entityDocument && (
                <li className="my-4">However, previous answers in the old account or opportunity will be kept</li>
              )}
            </>
          )}
          {checkDocument.showSalesforceNoteWarning && (
            <li className="my-4">
              {entityDocument
                ? 'We will remove the synced Salesforce note from the old opportunity and create a new Salesforce note for the new opportunity.'
                : 'We will remove the synced Salesforce note'}
            </li>
          )}
        </ul>
      </div>
    </div>
  );

  let otherEvents: JSX.Element | null = null;
  if (checkDocument.extraDocumentsToAssign.length !== 0) {
    otherEvents = (
      <OtherEventsToLink
        checkDocument={checkDocument}
        setDocumentIds={checkedDocumentIds =>
          setState(prevState => ({
            ...prevState,
            checkedDocumentIds,
          }))
        }
      />
    );
  }

  const confirmMessage = entityDocument ? (
    <div className="flex-inline items-center mt-3">
      <span className="pr-1">Are you sure you want to link the document</span>
      <span className="bg-green-100 rounded-lg px-2 mr-1">{docName}</span>
      {checkDocument.extraDocumentsToAssign.length > 0 && state.checkedDocumentIds.length > 0 && (
        <span className="bg-green-100 rounded-lg px-1">
          <span className="px-1">and</span>
          <span className="px-1">{state.checkedDocumentIds.length}</span>
          <span className="px-1">related event(s)</span>
        </span>
      )}
      <span className="px-1">to</span>
      {entityDocument?.entity.name === 'Opportunity' && (
        <span className="gap-1 pl-1 bg-orange-100  rounded-lg pr-1">
          <OpportunityIcon className="h-4 w-4 pt-1 text-orange-400 fill-orange-400" /> {entityDocument.name}
        </span>
      )}
      {entityDocument?.entity.name === 'Account' && (
        <span className="gap-1 pl-1 bg-orange-100  rounded-lg pr-1">
          <AccountIcon className="h-4 w-4 pt-1 " /> {entityDocument.name}
        </span>
      )}
      ?
    </div>
  ) : (
    <div className="flex-inline items-center mt-3">
      <span className="pr-1">Are you sure you want to continue to unlink the document?</span>
    </div>
  );
  return (
    <div className="max-w-2xl">
      <div className="px-6 pb-6 overflow-auto max-h-[80vh] w-[36rem]">
        <div className="flex justify-between sticky top-0 bg-white pt-6">
          <div>
            <div className="flex justify-center items-center w-14 h-14 bg-orange-100 rounded-full">
              <div className="flex justify-center items-center w-10 h-10 bg-orange-200 rounded-full">
                <LinkBrokenIcon className="w-7 h-7 text-orange-500 stroke-2" />
              </div>
            </div>
          </div>
          <div className="mt-2">
            <button type="button" onClick={modalStore.close}>
              <XMarkIcon className="w-6 h-6 text-gray-500 stroke-2 rounded hover:bg-gray-300" />
            </button>
          </div>
        </div>
        {state.modalStage === 'confirm' ? (
          confirmMessage
        ) : (
          <>
            {defaultWarningInfo}
            {otherEvents}
          </>
        )}
      </div>
      <div className="flex justify-end gap-2 static bottom-0 pt-2 pr-10 pb-6">
        <div>
          <button
            type="button"
            className="py-3 px-6 bg-gray-300 hover:bg-gray-400 text-gray-800 rounded"
            onClick={() => {
              if (state.modalStage === 'none') {
                modalStore.close();
              } else if (state.modalStage === 'confirm') {
                setState(prevState => ({
                  ...prevState,
                  modalStage: 'none',
                }));
              }
            }}
          >
            {state.modalStage === 'none' ? 'Cancel' : 'Go Back'}
          </button>
        </div>
        <div>
          <button
            type="button"
            className="flex gap-2 py-3 px-6 bg-blue-600 hover:bg-blue-500 text-white rounded"
            onClick={() => {
              if (state.modalStage === 'confirm') {
                // link to an opp
                updateSelectedEntity();
              } else if (state.modalStage === 'none') {
                setState(prevState => ({
                  ...prevState,
                  modalStage: 'confirm',
                }));
              }
            }}
          >
            <div>Continue</div>
          </button>
        </div>
      </div>
    </div>
  );
}

ChangeOpportunityWarningModal.defaultProps = {
  onUpdatedDocFolderByApi: undefined,
};

export default ChangeOpportunityWarningModal;
