import { Template } from 'api/note-templates/get-note-templates';
import { Document } from 'types/document';

const isDisableApply = (t: Template, document?: Document): boolean => {
  return (
    !document ||
    (document.parent?.entityName === 'Account' && t.opportunityFieldCount > 0) ||
    (document.parent === null && (t.opportunityFieldCount > 0 || t.accountFieldCount > 0))
  );
};

export default isDisableApply;
