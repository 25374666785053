import { Editor, NodeOperation, BaseOperation, InsertNodeOperation } from 'slate';
import { get, set } from 'lodash';
import { v4 } from 'uuid';

const withBlockUUID = (editor: Editor) => {
  const { apply } = editor;

  // eslint-disable-next-line no-param-reassign
  editor.apply = (op: BaseOperation) => {
    const newOp = op as NodeOperation | InsertNodeOperation;

    // if event is split_node, that mean this block is part of old block
    // and if new block has uuid, we need change the uuid to new uuid to prevent same uuid in one doc
    if (newOp.type === 'split_node') {
      if (get(newOp.properties, 'uuid')) {
        set(newOp.properties, 'uuid', v4());
      }
    }
    apply(newOp);
  };

  return editor;
};

export default withBlockUUID;
