import { Document, NoteService } from 'types/document';
import httpClient from '../http-client';

interface Response {
  document: Document;
  noteService: NoteService;
}

export default async (documentId: string): Promise<Response> => {
  // eslint-disable-next-line no-console
  httpClient.get(`/document-load-vector/${documentId}`).catch(console.error);
  const { data } = await httpClient.get<Response>(`/documents/${documentId}`);
  return data;
};
