/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
function sortObject(obj: any) {
  return Object.keys(obj)
    .sort()
    .reduce((result: any, key: any) => {
      result[key] = obj[key];
      return result;
    }, {});
}

export default sortObject;
