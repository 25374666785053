import { User } from 'types/user';
import httpClient from './http-client';

export interface Response {
  user: User;
}

export default async (): Promise<Response> => {
  const { data } = await httpClient.post<Response>('/integration-token', {
    integrationName: 'product_superpanel',
  });
  return data;
};
