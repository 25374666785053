import React from 'react';
import styles from './FormField.module.css';
import { InputLabel } from '@mui/material';
import { concat } from 'utils/styling';

interface Props {
  className?: string;
  label: React.ReactNode;
  children: React.ReactNode;
  error?: React.ReactNode;
  vertical?: boolean;
  labelClassName?: string;
  fieldClassName?: string;
}

function FormField({ className, label, children, error, vertical, labelClassName, fieldClassName }: Props) {
  return (
    <div className={concat(styles.formField, vertical && styles.vertical, className)}>
      <InputLabel className={concat(styles.label, labelClassName)}>{label}</InputLabel>
      <div className={styles.field}>
        <div className={concat(styles.fieldWrap, fieldClassName)}>{children}</div>
        {!!error && <div className={styles.error}>{error}</div>}
      </div>
    </div>
  );
}
FormField.defaultProps = {
  className: '',
  error: undefined,
  vertical: false,
  labelClassName: '',
  fieldClassName: '',
};

export default FormField;
