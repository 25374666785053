import React, { useMemo } from 'react';
import { Tag } from 'types/field-value';
import styles from './index.module.css';
import { concat } from 'utils/styling';

interface Props {
  className?: string;
  tag: Tag;
  defaultColour?: string;
  postfixElement?: React.ReactNode;
}
function TagBlock({ className, tag, defaultColour, postfixElement }: Props) {
  const colour = useMemo(() => tag.colour || defaultColour, [tag, defaultColour]);
  const bgColour = useMemo(() => `${colour}10`, [colour]);

  return (
    <div
      style={{ background: bgColour, color: colour, borderColor: colour }}
      className={concat(styles.tagItem, className)}
    >
      {tag.tag}
      {postfixElement}
    </div>
  );
}

TagBlock.defaultProps = {
  className: '',
  defaultColour: '#2D6CEA',
  postfixElement: undefined,
};

export default TagBlock;
