/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon, UserIcon } from '@heroicons/react/24/outline';
import { TreeItem, TreeView } from '@mui/x-tree-view';
import getTasks from 'api/task/get-tasks';
import BaseButton from 'components/BaseButton';
import MentionPopover from 'components/NoteEditor/CustomElement/Mention/MentionPopover';
import TaskEditor from 'components/NoteEditor/CustomElement/Task/TaskEditor';
import ResponseView from 'components/ResponseView';
import AccountIcon from 'components/icons/AccountIcon';
import EmptyResponseIcon from 'components/icons/EmptyResponseIcon';
import EmptySearchIcon from 'components/icons/EmptySearchIcon';
import OpportunityIcon from 'components/icons/OpportunityIcon';
import { cloneDeep, flatten, get, groupBy, isArray, keys } from 'lodash';
import useUserStore from 'stores/user';
import { Task } from 'types/task';
import { capitalizeName, getMemberName } from 'utils/string';
import Popover from '../../components/Popover';
import CategoryIcon from '../../components/icons/CategoryIcon';
import { defaultTaskFields, defultMyTesk } from './SettingPopover/types';
import styles from './index.module.css';
import useLocalStorageState from 'components/Hooks/useLocalStorageState';
import { filterFunction, sortData } from 'components/Table/utils';
import { elementToText } from 'utils/convert-element';
import openNewTaskModal from './CreateNewTaskModal';
import CommentUserAvatar from 'components/NoteEditor/CustomElement/Comment/CommentUserAvatar';
import { Member } from 'types/member';
import { concat } from 'utils/styling';
import { BlockType, CustomElement } from 'components/NoteEditor/types';
import { Text } from 'slate';
import FilterButton from 'pages/ReportingPage/FilterButton';
import SortButton from 'pages/ReportingPage/SortButton';

type GroupType = 'account' | 'opportunity' | 'assignedToUserId' | 'none';
const DEFAULT_GROUP_TYPE = 'account';
interface TreeItemData {
  groupId: string;
  groupType: GroupType;
  groupLabel: string;
  groupCount: number;
  assignedToUser?: Member;
  subGroups?: TreeItemData[];
  tasks?: Task[];
}

function TaskManagementPage() {
  const userStore = useUserStore();

  const groupBtnRef = useRef<HTMLButtonElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const [openGroup, setOpenGroup] = useState(false);
  const [group, setGroup] = useLocalStorageState<GroupType>('TASK_MANAGEMENT_GROUP_BY', DEFAULT_GROUP_TYPE);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [tasks, setTasks] = useState<Task[]>();

  const [fields, setFields] = useLocalStorageState('TASK_MANAGEMENT_FIELDS', cloneDeep(defaultTaskFields));

  // This is just for backward compatibility, for user that don't have myTask field
  useEffect(() => {
    if (!userStore.membersLoading && !userStore.membersLoadError && !userStore.members.length) {
      userStore.fetchMembers();
    }
    if (!fields.find(f => f.id === defultMyTesk.id)) {
      setFields([defultMyTesk, ...fields]);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    getTasks()
      .then(
        rsp =>
          setTasks(
            rsp.filter(
              task =>
                isArray(task.content) &&
                task.content.some(
                  item =>
                    (item as CustomElement).type === BlockType.Paragraph &&
                    (item as CustomElement).children?.some(subItem => Text.isText(subItem) && !!subItem.text),
                ),
            ),
          ),
        () => setError('Failed to load tasks!'),
      )
      .finally(() => setLoading(false));
  }, []);

  const createNewTask = async () => {
    const newTask = await openNewTaskModal();
    if (newTask && tasks) {
      tasks.push(newTask);
      setTasks([...tasks]);
    }
  };

  const hasFilters = useMemo(
    () => fields.some(f => ('group' in f ? f.columns.some(col => !!col.filter) : !!f.filter)),
    [fields],
  );

  const groupTasks = (taskEntities: Task[], groupByField: GroupType): TreeItemData[] => {
    const groups = groupBy(taskEntities, groupByField === 'assignedToUserId' ? groupByField : `${groupByField}.id`);
    return keys(groups)
      .map(groupId => {
        const subTasks = groups[groupId];
        const subTasksHaveOppo = groupByField === 'account' ? subTasks.filter(item => item.opportunity?.id) : [];
        const subTasksNotHaveOppo =
          groupByField === 'account' ? subTasks.filter(item => !item.opportunity?.id) : subTasks;
        return {
          groupId,
          groupType: groupByField,
          groupLabel: get(
            groups,
            groupByField === 'assignedToUserId' ? [groupId, 0, groupByField] : [groupId, 0, groupByField, 'name'],
            '',
          ),
          groupCount: subTasks.length,
          assignedToUser:
            groupByField === 'assignedToUserId' ? userStore.members.find(member => member.id === groupId) : undefined,
          subGroups:
            groupByField === 'account' && subTasksHaveOppo.length
              ? groupTasks(subTasksHaveOppo, 'opportunity')
              : undefined,
          tasks:
            groupByField === 'assignedToUserId'
              ? subTasks
              : subTasksNotHaveOppo.length
              ? subTasksNotHaveOppo
              : undefined,
        };
      })
      .sort((a, b) => (!b.groupLabel ? -1 : 0));
  };

  const tasksFilteredAndSorted: Task[] = useMemo(() => {
    // apply filters
    const filters = flatten(
      fields.map(f =>
        'group' in f
          ? f.columns.map(col =>
              col.filter ? { field: `${f.id}.${col.id}`, filter: filterFunction(col.type, col.filter) } : undefined,
            )
          : f.filter
          ? { field: f.id, filter: filterFunction(f.type, f.filter) }
          : undefined,
      ),
    ).filter(f => f) as { field: string; filter: (value: any) => boolean }[];
    const tasksFiltered = (tasks ?? []).filter(row => {
      return filters.every(filterItem => {
        let value = get(row, filterItem.field);
        if (filterItem.field === defultMyTesk.id) {
          value = row.assignedToUserId === userStore.user?.id || row.createdById === userStore.user?.id;
        }
        if (filterItem.field === 'assignedToUserId') {
          value = getMemberName(userStore.members?.find(member => member.id === value));
        }
        if (typeof value === 'object' && value instanceof Array && filterItem.field === 'content') {
          value = value.map(node => elementToText(node)).join('');
        }
        return filterItem.filter(value);
      });
    });
    fields
      .slice()
      .reverse()
      .forEach(columnConfig => {
        if ('group' in columnConfig) {
          columnConfig.columns
            .slice()
            .reverse()
            .forEach(column => {
              if (column.sort) {
                tasksFiltered.sort(sortData(column.id, column.type, column.sort, columnConfig.id));
              }
            });
        } else if (columnConfig.sort) {
          tasksFiltered.sort(sortData(columnConfig.id, columnConfig.type, columnConfig.sort, null));
        }
      });
    return tasksFiltered;
  }, [tasks, fields, group, userStore.members]);

  const tasksTreeData: TreeItemData[] | undefined = useMemo(() => {
    return group !== 'none' ? groupTasks(tasksFilteredAndSorted, group) : undefined;
  }, [tasksFilteredAndSorted]);

  const renderTaskList = (items: Task[]) => {
    return items.map(item => {
      const showAccOppo = group !== 'account' && group !== 'opportunity' && (!!item.account || !!item.opportunity);
      return (
        <TreeItem
          className={concat(styles.taskTreeItem, showAccOppo && styles.taskTreeItemShowAccOppo)}
          nodeId={item.id}
          key={item.id}
          label={
            <>
              <TaskEditor
                taskId={item.id}
                initTask={item}
                documentId={item.documentId}
                opportunityId={null}
                onUpdate={newTask => {
                  if (tasks) {
                    const index = tasks.findIndex(task => task.id === item.id);
                    if (index >= 0) {
                      tasks.splice(index, 1, { ...item, ...newTask });
                      setTasks([...tasks]);
                    }
                  }
                }}
                onDelete={() => {
                  if (tasks) {
                    const index = tasks.findIndex(task => task.id === item.id);
                    if (index >= 0) {
                      tasks.splice(index, 1);
                      setTasks([...tasks]);
                    }
                  }
                }}
                scrollContainer={scrollContainerRef.current || undefined}
              />
              {showAccOppo && (
                <div className={concat('flex items-center gap-2 w-full truncate', styles.accountOppoDetailRow)}>
                  {!!item.account && (
                    <div className="flex items-center gap-1 truncate">
                      <AccountIcon className="min-w-[0.75rem] w-3 h-3" />
                      <span className="text-xs text-gray-500 truncate">{item.account.name}</span>
                    </div>
                  )}
                  {!!item.opportunity && (
                    <div className="flex items-center gap-1 truncate">
                      <OpportunityIcon className="min-w-[0.75rem] w-3 h-3 fill-orange-400" />
                      <span className="text-xs text-gray-500 truncate">{item.opportunity.name}</span>
                    </div>
                  )}
                </div>
              )}
            </>
          }
        />
      );
    });
  };

  const renderTreeGroupList = (items: TreeItemData[]) => {
    return items.map(item => (
      <TreeItem
        nodeId={item.groupId}
        key={item.groupId}
        label={
          <div className="flex items-center gap-1">
            {item.groupType === 'assignedToUserId' ? (
              item.assignedToUser ? (
                <CommentUserAvatar member={item.assignedToUser} hideName />
              ) : (
                <UserIcon width={20} />
              )
            ) : item.groupType === 'account' ? (
              <AccountIcon className="min-w-[1.25rem] w-5 h-5" />
            ) : (
              <OpportunityIcon className="min-w-[1.25rem] w-5 h-5 fill-orange-400" />
            )}
            <strong className={styles.treeLabel}>
              {group === 'assignedToUserId'
                ? item.assignedToUser
                  ? getMemberName(item.assignedToUser)
                  : 'Not Assigned'
                : item.groupLabel || `No ${capitalizeName(item.groupType)}`}
            </strong>{' '}
            <span>({item.groupCount})</span>
          </div>
        }
      >
        {item.subGroups && renderTreeGroupList(item.subGroups)}
        {item.tasks && renderTaskList(item.tasks)}
      </TreeItem>
    ));
  };

  return (
    <div className={styles.pageContainer}>
      <div className={styles.pageHeader}>
        <div className="px-5 py-2">
          <h1 className="font-semibold text-lg py-3 my-0">Tasks</h1>
          <div className="w-full flex justify-between items-center">
            <div className="flex items-center gap-2">
              <button
                ref={groupBtnRef}
                type="button"
                className="text-sm text-gray-500 hover:bg-gray-100 rounded p-2 flex items-center gap-1 capitalize"
                onClick={() => setOpenGroup(true)}
              >
                <CategoryIcon className="w-5 h-5" />
                Group By: {group === 'assignedToUserId' ? 'Assigned User' : group}
              </button>
              <Popover
                anchorEl={groupBtnRef.current}
                onClose={() => setOpenGroup(false)}
                isOpen={openGroup}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
              >
                <div className="w-48 flex flex-col">
                  <button
                    type="button"
                    className="px-4 py-2 text-left hover:bg-gray-100"
                    onClick={() => {
                      setGroup('account');
                      setOpenGroup(false);
                    }}
                  >
                    Account
                  </button>
                  <button
                    type="button"
                    className="px-4 py-2 text-left hover:bg-gray-100"
                    onClick={() => {
                      setGroup('opportunity');
                      setOpenGroup(false);
                    }}
                  >
                    Opportunity
                  </button>
                  <button
                    type="button"
                    className="px-4 py-2 text-left hover:bg-gray-100"
                    onClick={() => {
                      setGroup('assignedToUserId');
                      setOpenGroup(false);
                    }}
                  >
                    Assigned To User
                  </button>
                  <button
                    type="button"
                    className="px-4 py-2 text-left hover:bg-gray-100"
                    onClick={() => {
                      setGroup('none');
                      setOpenGroup(false);
                    }}
                  >
                    None
                  </button>
                </div>
              </Popover>
            </div>
            <div className="flex items-center gap-2 text-sm">
              <FilterButton tableConfig={fields} updateTableConfig={setFields} />
              <SortButton tableConfig={fields} updateTableConfig={setFields} />
              <BaseButton
                color="primary"
                variant="contained"
                className="!text-sm !h-auto min-h-[32px]"
                onClick={createNewTask}
              >
                Add New
              </BaseButton>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.pageContent} ref={scrollContainerRef}>
        <ResponseView
          loading={loading}
          error={error}
          noData={!tasksFilteredAndSorted.length}
          noDataIcon={hasFilters ? <EmptySearchIcon /> : <EmptyResponseIcon />}
          noDataMsg={
            hasFilters ? `We couldn't find any results for\n"current filter".` : 'You do not have any tasks... yet'
          }
          noDataDesc={
            hasFilters ? 'You may want to try using different filters.' : 'Click on the button below to create one.'
          }
          noDataBtnLabel={hasFilters ? '' : 'Create Task'}
          onButtonClick={createNewTask}
        >
          <TreeView
            className={group === 'none' ? styles.noGroup : ''}
            defaultCollapseIcon={<ChevronUpIcon className="!w-5 !min-w-[1.25rem] !h-5 mr-[6px]" />}
            defaultExpandIcon={<ChevronDownIcon className="!w-5 !min-w-[1.25rem] !h-5 mr-[6px]" />}
          >
            {tasksTreeData ? renderTreeGroupList(tasksTreeData) : renderTaskList(tasksFilteredAndSorted)}
          </TreeView>
        </ResponseView>
        <div className="py-10" />
      </div>
      <MentionPopover />
    </div>
  );
}

export default TaskManagementPage;
