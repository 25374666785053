import React from 'react';

interface Props {
  className?: string;
}

function ChatIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 7.8C3 6.11984 3 5.27976 3.32698 4.63803C3.6146 4.07354 4.07354 3.6146 4.63803 3.32698C5.27976 3 6.11984 3 7.8 3H16.2C17.8802 3 18.7202 3 19.362 3.32698C19.9265 3.6146 20.3854 4.07354 20.673 4.63803C21 5.27976 21 6.11984 21 7.8V13.5C21 14.8978 21 15.5967 20.7716 16.1481C20.4672 16.8831 19.8831 17.4672 19.1481 17.7716C18.5967 18 17.8978 18 16.5 18C16.0114 18 15.7671 18 15.5405 18.0535C15.2383 18.1248 14.9569 18.2656 14.7185 18.4645C14.5397 18.6137 14.3931 18.8091 14.1 19.2L12.64 21.1467C12.4229 21.4362 12.3143 21.5809 12.1812 21.6327C12.0647 21.678 11.9353 21.678 11.8188 21.6327C11.6857 21.5809 11.5771 21.4362 11.36 21.1467L9.9 19.2C9.60685 18.8091 9.46028 18.6137 9.2815 18.4645C9.04312 18.2656 8.76169 18.1248 8.45951 18.0535C8.23287 18 7.98858 18 7.5 18C6.10218 18 5.40326 18 4.85195 17.7716C4.11687 17.4672 3.53284 16.8831 3.22836 16.1481C3 15.5967 3 14.8978 3 13.5V7.8Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.25 11.5L9.64223 12.2845C9.77497 12.5499 9.84135 12.6827 9.93002 12.7977C10.0087 12.8998 10.1002 12.9913 10.2023 13.07C10.3173 13.1587 10.4501 13.225 10.7155 13.3578L11.5 13.75L10.7155 14.1422C10.4501 14.275 10.3173 14.3413 10.2023 14.43C10.1002 14.5087 10.0087 14.6002 9.93002 14.7023C9.84135 14.8173 9.77497 14.9501 9.64223 15.2155L9.25 16L8.85777 15.2155C8.72503 14.9501 8.65865 14.8173 8.56998 14.7023C8.4913 14.6002 8.3998 14.5087 8.29772 14.43C8.18269 14.3413 8.04995 14.275 7.78446 14.1422L7 13.75L7.78446 13.3578C8.04995 13.225 8.18269 13.1587 8.29772 13.07C8.3998 12.9913 8.4913 12.8998 8.56998 12.7977C8.65865 12.6827 8.72503 12.5499 8.85777 12.2845L9.25 11.5Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 6L14.0893 7.53221C14.2303 7.89882 14.3008 8.08213 14.4105 8.23632C14.5076 8.37298 14.627 8.49237 14.7637 8.58954C14.9179 8.69918 15.1012 8.76968 15.4678 8.91069L17 9.5L15.4678 10.0893C15.1012 10.2303 14.9179 10.3008 14.7637 10.4105C14.627 10.5076 14.5076 10.627 14.4105 10.7637C14.3008 10.9179 14.2303 11.1012 14.0893 11.4678L13.5 13L12.9107 11.4678C12.7697 11.1012 12.6992 10.9179 12.5895 10.7637C12.4924 10.627 12.373 10.5076 12.2363 10.4105C12.0821 10.3008 11.8988 10.2303 11.5322 10.0893L10 9.5L11.5322 8.91069C11.8988 8.76968 12.0821 8.69918 12.2363 8.58954C12.373 8.49237 12.4924 8.37298 12.5895 8.23632C12.6992 8.08213 12.7697 7.89882 12.9107 7.53221L13.5 6Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

ChatIcon.defaultProps = {
  className: undefined,
};

export default ChatIcon;
