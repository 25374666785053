/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { create } from 'zustand';

import getFieldValue from 'api/fields/get-field-value';
import updateSuggestedAnswer from 'api/suggested-answer/update-suggested-answer';
import updateSuggestedAnswers from 'api/suggested-answer/update-suggested-answers';
import getFieldValues from 'api/fields/get-field-values';
import updateFieldValues from 'api/fields/update-field-values';
import getFieldValueStatus from 'api/fields/get-field-value-status';

import { FieldValue } from 'types/field-value';
import updateFieldValueValue from '../api/fields/update-field-value-value';
import { isAISupport } from '../types/salesforce';
import updateFieldValue from 'api/fields/update-field-value';

export interface State {
  fieldValues: FieldValue[];
  id: string;
  isLoading: boolean;
  reset: () => State;
  fetch: (id: string) => Promise<FieldValue[]>;
  fetchWithAITrigger: (id: string) => Promise<FieldValue[]>;
  fetchFieldValue: (fieldValueId: string, triggerAI?: boolean) => Promise<FieldValue>;
  checkAiProcessing: (fieldValueId: string) => Promise<void>;
  triggerAiProcess: (fieldValueId: string) => Promise<void>;
  updateSuggestedAnswerStatus: (
    fieldValueId: string,
    suggestedAnswerId: string,
    status: 'accepted' | 'rejected' | null,
  ) => Promise<void>;
  updateFieldValues: (updateFieldValues: { id: string; shownInDiscoveryDocument: boolean }[]) => Promise<void>;
  updateFieldValueValue: (fieldValueId: string, value: any) => Promise<FieldValue>;
  updateFieldValueSuggestionStatus: (
    fieldValueId: string,
    status: 'accepted' | 'rejected' | null,
  ) => Promise<FieldValue>;
}

const useFieldValuesStore = create<State>((set, get) => ({
  fieldValues: [],
  fieldValuesForTemplate: [],
  id: '',
  isLoading: false,
  reset: () => {
    set(state => {
      return { ...state, fieldValues: [] };
    });
    return get();
  },
  fetch: async (id): Promise<FieldValue[]> => {
    set(state => ({
      ...state,
      isLoading: true,
      id,
      fieldValues: [],
    }));
    const discoveryQuestionAnswers = await getFieldValues(id, false, false);
    set(state => ({ ...state, isLoading: false, fieldValues: discoveryQuestionAnswers }));
    return discoveryQuestionAnswers;
  },
  fetchWithAITrigger: async (id): Promise<FieldValue[]> => {
    set(state => ({
      ...state,
      isLoading: true,
      id,
      fieldValues: [],
    }));
    const discoveryQuestionAnswers = await getFieldValues(id, true, false);
    set(state => ({ ...state, isLoading: false, fieldValues: discoveryQuestionAnswers }));
    return discoveryQuestionAnswers;
  },
  fetchFieldValue: async (fieldValueId: string, triggerAI = true): Promise<FieldValue> => {
    const fieldValue = await getFieldValue(fieldValueId);
    set(state => {
      const isFieldValueExists = state.fieldValues.some(fv => fv.id === fieldValueId);
      if (!isFieldValueExists) {
        return {
          ...state,
          fieldValues: [...state.fieldValues, fieldValue],
        };
      }

      return {
        ...state,
        fieldValues: state.fieldValues.map(fv => {
          if (fv.id === fieldValueId) {
            return fieldValue;
          }
          return fv;
        }),
      };
    });

    if (triggerAI && isAISupport(fieldValue.field.dataType) && fieldValue.field.isAiEnabled) {
      get().triggerAiProcess(fieldValueId);
    }
    return fieldValue;
  },
  checkAiProcessing: async (fieldValueId: string): Promise<void> => {
    const { isAiProcessing, suggestedAnswers, valueAiSuggestion, acceptanceStatusAiSuggestion } =
      await getFieldValueStatus(fieldValueId);
    set(state => ({
      ...state,
      fieldValues: state.fieldValues.map(fieldValue => {
        if (fieldValue.id === fieldValueId) {
          return { ...fieldValue, isAiProcessing, suggestedAnswers, valueAiSuggestion, acceptanceStatusAiSuggestion };
        }
        return fieldValue;
      }),
    }));
  },
  triggerAiProcess: async (fieldValueId: string): Promise<void> => {
    set(state => ({
      ...state,
      fieldValues: state.fieldValues.map(fieldValue => {
        if (fieldValue.id === fieldValueId) {
          return { ...fieldValue, isAiProcessing: true };
        }
        return fieldValue;
      }),
    }));
    const { isAiProcessing } = await updateSuggestedAnswers(fieldValueId);
    set(state => ({
      ...state,
      fieldValues: state.fieldValues.map(fieldValue => {
        if (fieldValue.id === fieldValueId) {
          return { ...fieldValue, isAiProcessing };
        }
        return fieldValue;
      }),
    }));
  },
  updateSuggestedAnswerStatus: async (
    fieldValueId: string,
    suggestedAnswerId: string,
    status: 'accepted' | 'rejected' | null,
  ): Promise<void> => {
    const { suggestedAnswer } = await updateSuggestedAnswer(suggestedAnswerId, status);
    set(state => ({
      ...state,
      fieldValues: state.fieldValues.map(dqa => {
        if (dqa.id === fieldValueId) {
          return {
            ...dqa,
            suggestedAnswers: dqa.suggestedAnswers.map(sa => {
              if (sa.id === suggestedAnswerId) return suggestedAnswer;
              return sa;
            }),
          };
        }
        return dqa;
      }),
    }));
  },
  updateFieldValues: async (updateValues: { id: string; shownInDiscoveryDocument: boolean }[]): Promise<void> => {
    const updatedFieldValues = await updateFieldValues(updateValues);
    const updatedFieldValuesById: { [id: string]: FieldValue } = {};
    updatedFieldValues.forEach(fv => {
      updatedFieldValuesById[fv.id] = fv;
    });
    set(state => {
      // for newly added ids, we need to mark them and we need to mine suggested answers
      return {
        ...state,
        fieldValues: state.fieldValues.map(fv => {
          if (fv.id in updatedFieldValuesById) {
            return updatedFieldValuesById[fv.id];
          }
          return fv;
        }),
      };
    });

    get().fieldValues.forEach(fv => {
      if (fv.id in updatedFieldValuesById) {
        // if the new field value is shown in discovery document, we need to mine suggested answers
        if (
          updatedFieldValuesById[fv.id].shownInDiscoveryDocument &&
          isAISupport(updatedFieldValuesById[fv.id].field.dataType) &&
          fv.field.isAiEnabled
        ) {
          get().triggerAiProcess(fv.id);
        }
      }
    });
  },
  updateFieldValueValue: async (fieldValueId: string, value: any): Promise<FieldValue> => {
    const fieldValue = await updateFieldValueValue(fieldValueId, value);
    set(state => ({
      ...state,
      fieldValues: state.fieldValues.map(fv => {
        if (fv.id === fieldValueId) {
          return fieldValue;
        }
        return fv;
      }),
    }));
    return fieldValue;
  },
  updateFieldValueSuggestionStatus: async (
    fieldValueId: string,
    status: 'accepted' | 'rejected' | null,
  ): Promise<FieldValue> => {
    const fieldValue = await updateFieldValue(fieldValueId, status || '');
    set(state => ({
      ...state,
      fieldValues: state.fieldValues.map(fv => {
        if (fv.id === fieldValueId) {
          return fieldValue;
        }
        return fv;
      }),
    }));
    return fieldValue;
  },
}));

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('FieldValues', useFieldValuesStore);
}

export default useFieldValuesStore;
