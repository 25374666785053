/* eslint-disable no-nested-ternary */
import React from 'react';
import integrateProductGap from 'api/integration-token-product-gap';
import openModal, { openMessageInfoModal } from 'components/BaseModal';
import Productboard from 'components/logos/Productboard';
import Productgap from 'components/logos/Productgap';
import { ModalCloseAction, ModalConfirmAction, ModalIndeterminateAction } from 'types/modal';
import useUserStore from 'stores/user';

const openProductGapSettingModal = (): Promise<'product-gap' | 'product-board' | typeof ModalCloseAction> => {
  return openModal({
    className: 'w-[36rem]',
    footerClassName: '!gap-8 my-4',
    title: 'Setup Product Gap',
    actions: [
      {
        label: 'I do not have Productboard',
        action: ModalConfirmAction,
        color: 'primary',
        icon: <Productgap className="h-16 w-16" />,
        cb: () => {
          openMessageInfoModal('Confirm', 'Are you sure to use Product Gap without Productboard?', 'Back', async () => {
            await integrateProductGap();
          }).then(rsp => {
            // reopen setting modal when click back
            if (rsp === ModalCloseAction) {
              openProductGapSettingModal();
            }
            if (rsp === ModalConfirmAction) {
              const store = useUserStore.getState();
              if (store.user) {
                store.setState({
                  user: { ...store.user, needsToSetupProduct: false, productProvider: 'Superpanel' },
                });
              }
            }
          });
        },
        className: 'w-1/2 !h-40 !text-lg gap-8',
      },
      {
        label: 'Using Productboard',
        action: ModalIndeterminateAction,
        color: 'secondary',
        icon: <Productboard className="h-16 w-16" />,
        className: 'w-1/2 !h-40 !text-lg gap-8',
      },
    ],
  }).then(rsp => {
    return rsp === ModalConfirmAction
      ? 'product-gap'
      : rsp === ModalIndeterminateAction
      ? 'product-board'
      : ModalCloseAction;
  });
};

export default openProductGapSettingModal;
