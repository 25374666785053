import { Descendant } from 'slate';
import httpClient from '../http-client';
import { Task } from 'types/task';

export default async (content: Descendant[], done = false, dueDate = '', assignedToUserId = ''): Promise<Task> => {
  const { data } = await httpClient.post<Task>(`/template-task`, {
    documentId: null,
    done,
    dueDate,
    assignedToUserId,
    content,
  });
  return data;
};
