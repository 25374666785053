import config from './config';

export default (oAuthType: 'google' | 'microsoft' | 'salesforce' | 'slack', state: string): string => {
  if (oAuthType === 'google') {
    return `https://accounts.google.com/o/oauth2/auth?access_type=offline&client_id=${config.GOOGLE_CLIENT_ID}&include_granted_scopes=true&redirect_uri=${config.GOOGLE_REDIRECT_URL}&response_type=code&state=${state}&scope=https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/calendar`;
  }
  if (oAuthType === 'microsoft') {
    return `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${config.MICROSOFT_CLIENT_ID}&redirect_uri=${config.MICROSOFT_REDIRECT_URL}&state=${state}&response_type=code&scope=offline_access email openid profile User.Read Calendars.ReadWrite Calendars.ReadWrite.Shared`;
  }
  if (oAuthType === 'salesforce') {
    return `https://login.salesforce.com/services/oauth2/authorize?client_id=${config.SALESFORCE_CLIENT_ID}&state=${state}&redirect_uri=${config.SALESFORCE_REDIRECT_URL}&response_type=code`;
  }
  return `https://slack.com/oauth/v2/authorize?scope=chat:write,incoming-webhook,users:read,users.profile:read,users:read.email,channels:join&client_id=${config.SLACK_CLIENT_ID}&state=${state}`;
};
