import { MenuItem } from '@mui/material';
import React from 'react';
import { ArrowDownIcon } from '@heroicons/react/24/outline';
import { ColumnDefinition } from '../../types';

interface Props {
  columnDefinition: ColumnDefinition;
  onSortUpdate: (sort: 'ASC' | 'DESC' | undefined) => void;
}

function SortDescendingColumnMenu({ columnDefinition, onSortUpdate }: Props) {
  return columnDefinition.sort !== 'DESC' ? (
    <MenuItem onClick={() => onSortUpdate(columnDefinition.sort === 'DESC' ? undefined : 'DESC')}>
      <div className="flex items-center text-sm">
        <ArrowDownIcon className="w-4 h-4 mr-2" />
        Sort descending
      </div>
    </MenuItem>
  ) : null;
}

export default SortDescendingColumnMenu;
