import React from 'react';
import { MenuItem } from '@mui/material';
import { ColumnConfig } from 'components/Table/types';
import { ArrowLeftOnRectangleIcon } from '@heroicons/react/24/outline';

interface Props {
  column: ColumnConfig;
  onColumnPin: () => void;
}

function PinLeftColumnMenu({ column, onColumnPin }: Props) {
  return (
    <MenuItem onClick={onColumnPin}>
      <div className="flex items-center text-sm">
        {column.isPinned ? (
          <ArrowLeftOnRectangleIcon className="w-4 h-4 mr-2 rotate-180" />
        ) : (
          <ArrowLeftOnRectangleIcon className="w-4 h-4 mr-2" />
        )}
        {column.isPinned ? 'Unpin' : 'Pin left'}
      </div>
    </MenuItem>
  );
}

export default PinLeftColumnMenu;
