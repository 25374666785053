import moment from 'moment';
import constants from './constants';

export default (datetime: string | undefined): { scheduledDate: string; scheduledTime: string } => {
  let scheduledDate = '';
  let scheduledTime = '';
  if (datetime) {
    const scheduled = moment(datetime);
    scheduledDate = scheduled.format(constants.DATE_FORMAT);
    scheduledTime = scheduled.format(constants.TIME_FORMAT);
  }
  return { scheduledDate, scheduledTime };
};
