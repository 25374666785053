/* eslint-disable no-nested-ternary */
import React from 'react';
import { DataEntryType, EntryRow, RowData } from 'types/reporting';
import { ColumnDataType } from '../types';
import { convertValueToDisplayValue } from '../utils';
import EditableValue from './ReportFieldCellValue/EditableValue';
import SpinLoader from '../../icons/SpinLoader';

const getColumnDefinitionType = (dataEntryType: DataEntryType): ColumnDataType => {
  let type: ColumnDataType = 'Text';
  if (dataEntryType === 'number') {
    type = 'Number';
  } else if (dataEntryType === 'boolean') {
    type = 'Boolean';
  }
  return type;
};

interface Props {
  cellValue: RowData | null;
  columnData: {
    dataEntryType: DataEntryType;
    entityName: 'Account' | 'Opportunity';
  };
  rowData: EntryRow;
  fieldId: string;
  analyticColumnId: string;
}

function ReportFieldCellValue({ cellValue, columnData, rowData, fieldId, analyticColumnId }: Props) {
  const { entityName, dataEntryType } = columnData;
  let isAnswerEmpty = false;
  if (rowData[fieldId] && 'answer' in rowData[fieldId]) {
    if (rowData[fieldId].answer === null || rowData[fieldId].answer === undefined || rowData[fieldId].answer === '')
      isAnswerEmpty = true;
  }
  if (entityName === 'Opportunity' && !(entityName in rowData))
    return <div className="text-gray-300 px-4 py-2">No Opportunity</div>;

  if (!cellValue) return <div className="text-gray-300 px-4 py-2">No Data</div>;

  if (cellValue.status === 'new' || cellValue.status === 'pending') {
    return <SpinLoader className="w-5 h-5 animate-spin mx-4 my-2" />;
  }

  if (cellValue.status === 'failed') {
    return <div className="text-red-300 px-4 py-2">Failed to process data</div>;
  }

  const { fieldValueId } = rowData[fieldId];
  const columnDefinitionType = getColumnDefinitionType(dataEntryType);
  const displayValue = convertValueToDisplayValue(cellValue.value, columnDefinitionType);

  return (
    <EditableValue
      value={displayValue}
      isAnswerEmpty={isAnswerEmpty}
      columnType={columnDefinitionType}
      fieldId={fieldId}
      fieldValueId={fieldValueId}
      analyticColumnId={analyticColumnId}
      placeholder={
        columnDefinitionType === 'Number'
          ? 'Input your number here'
          : columnDefinitionType === 'Boolean'
          ? 'Please toggle checkbox'
          : 'Input your text here'
      }
      autoFocus
    />
  );
}

export default ReportFieldCellValue;
