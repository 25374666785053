import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

function useQueryParamState(queryParamKey: string): [string, (newQueryParam: string) => void] {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsRef = useRef(searchParams);
  const state = useMemo<string>(() => {
    return searchParams.get(queryParamKey) || '';
  }, [searchParams]);

  useEffect(() => {
    searchParamsRef.current = searchParams;
  }, [searchParams]);

  const setState = useCallback((newQueryParam: string) => {
    const currentSearchParams = searchParamsRef.current;
    if (newQueryParam) {
      currentSearchParams.set(queryParamKey, newQueryParam);
    } else {
      currentSearchParams.delete(queryParamKey);
    }
    setSearchParams(currentSearchParams);
  }, []);

  return [state, setState];
}

export default useQueryParamState;
