import React from 'react';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import { IEventBlock } from 'utils/calendar-util';

interface Props {
  eventBlock: IEventBlock;
  maxRight: number;
}

function EventBlock({ eventBlock, maxRight }: Props) {
  const location = useLocation();
  const navigate = useNavigate();

  const { isExternal } = eventBlock.event;

  const startsAt = moment(eventBlock.event.startsAt);
  const endsAt = moment(eventBlock.event.endsAt);
  const eventDuration = endsAt.diff(startsAt, 'minute');

  const path = `/document/${eventBlock.event.documentId}`;
  const isOnPage = eventBlock.event.documentId && location.pathname.match(path);

  const onClick = async () => {
    if (eventBlock.event.documentId) {
      navigate(path);
      // return;
    }

    // I think all the event must have doc in the first place
    // if (!calendarEventsStore.calendarEvents) {
    //   return;
    // }
    // calendarEventsStore.setState({
    //   isCreateNewDocumentLoading: true,
    // });
    // try {
    //   const document = await documentStore.createDocument(null, eventBlock.event.id);
    //   calendarEventsStore.setState({
    //     calendarEvents: calendarEventsStore.calendarEvents.map(storeEvent => {
    //       if (storeEvent.id === eventBlock.event.id) {
    //         return {
    //           ...storeEvent,
    //           documentId: document.id,
    //         };
    //       }
    //       return storeEvent;
    //     }),
    //   });
    //   navigate(`/document/${document.id}`);
    //   return;
    // } catch (e) {
    //   calendarEventsStore.setState({
    //     isCreateNewDocumentLoading: false,
    //   });
    // }
  };

  return (
    <button
      className={`absolute p-0.5 text-left overflow-hidden border border-white rounded-lg ${
        isExternal ? 'bg-blue-600 hover:bg-blue-500 text-white' : 'bg-gray-200 hover:bg-gray-300'
      }`}
      style={{
        top: `${(eventBlock.top / 60) * 4}rem`,
        left: `${(eventBlock.left / maxRight) * 12 + 4}rem`,
        height: `${((eventBlock.bottom - eventBlock.top) / 60) * 4}rem`,
        width: `${((eventBlock.right - eventBlock.left) / maxRight) * 12}rem`,
        textDecoration: 'none',
      }}
      onClick={onClick}
      type="button"
    >
      <div
        className={`h-full ${eventDuration < 30 ? 'text-[0.625rem] px-1.5' : 'text-xs p-1.5'} ${
          isOnPage && `border-l-4 ${isExternal ? 'border-l-white' : 'border-l-gray-500'} border-solid rounded`
        } `}
      >
        <div className="truncate">{eventBlock.event.subject}</div>
        {eventDuration > 30 && (
          <div className="truncate">
            {startsAt.format('h:mm a')}&nbsp;&ndash;&nbsp;{endsAt.format('h:mm a')}
          </div>
        )}
      </div>
    </button>
  );
}

export default EventBlock;
