/* eslint-disable react/no-array-index-key, no-loop-func */
/* eslint-disable import/no-cycle, react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import { openDeleteConfirmModal } from 'components/BaseModal';
import NoteEditor from 'components/NoteEditor/NoteEditor';
import {
  BlockElement,
  BlockType,
  BlockTypeInline,
  COMMENTABLE_VOID_BLOCKS,
  CommentContent,
  CommentPopupResponseType,
  CommentRecord,
  CommentRecords,
  CustomEditor,
  CustomElement,
  CustomText,
  MentionElement,
} from 'components/NoteEditor/types';
import Popover from 'components/Popover';
import { cloneDeep, flatten, groupBy, isEmpty, keys, set, values } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Node, NodeEntry, Path, Text, Transforms } from 'slate';
import useNoteEditorStore from 'stores/note-editor';
import useUserStore from 'stores/user';
import { ListItem } from 'types/list-item';
import { Member } from 'types/member';
import { getMemberName } from 'utils/string';
import CommentEditor from './CommentEditor';
import styles from './CommentPopover.module.css';
import CommentUserAvatar from './CommentUserAvatar';
import PostedCommentRow from './PostedCommentRow';
import { emitDocumentEvent } from 'utils/event';
import { ReactEditor } from 'slate-react';

interface Props {
  documentId: string;
  opportunityId: string;
}

function CommentPopover({ documentId, opportunityId }: Props) {
  const userStore = useUserStore();
  const noteEditorStore = useNoteEditorStore();
  const members = useMemo<{ [userId: string]: Member }>(() => {
    const ret = {};
    userStore.members?.forEach(member => set(ret, [member.id], member));
    return ret;
  }, [userStore.members]);
  const containerRef = useRef<HTMLDivElement>(null);

  const [postedComments, setPostedComments] = useState<CommentRecords>({});
  const [nodesCommented, setNodesCommented] = useState<{ [commentId: string]: string[] }>({});

  const closePopover = () => {
    const editor = noteEditorStore.commentContext?.editor;
    const { selection } = editor || { selection: null };
    setTimeout(() => {
      if (editor) {
        ReactEditor.focus(editor);
        if (selection) {
          Transforms.select(editor, selection);
        }
      }
    });
    noteEditorStore.setState({ commentContext: null });
  };

  useEffect(() => {
    if (noteEditorStore.commentContext?.comments && noteEditorStore.commentContext?.editor) {
      const commentsCopy = cloneDeep(noteEditorStore.commentContext?.comments ?? {});
      keys(commentsCopy).forEach(id => {
        commentsCopy[id] = commentsCopy[id].filter(comment => comment.createdDate);
        if (commentsCopy[id][0]?.resolved) {
          delete commentsCopy[id];
        }
      });
      setPostedComments(commentsCopy);
      const ids = keys(commentsCopy);
      const linesCommented: { [commentId: string]: { text: string; path: Path }[] } = {};
      const mentionNodeInfos = NoteEditor.nodes(noteEditorStore.commentContext.editor, {
        at: [],
        match: (n, p) => {
          return (
            (Text.isText(n) && ids.some(commentId => !!n.comment?.includes(commentId))) ||
            (p.length === 1 &&
              COMMENTABLE_VOID_BLOCKS.includes((n as BlockElement).type) &&
              ids.some(commentId => !!(n as BlockElement).comments?.[commentId]))
          );
        },
      });
      let nodeInfo = mentionNodeInfos.next();
      while (!nodeInfo.done) {
        if (nodeInfo.value) {
          if (COMMENTABLE_VOID_BLOCKS.includes((nodeInfo.value[0] as BlockElement).type)) {
            const node = nodeInfo.value[0] as BlockElement;
            if (node.comments) {
              const innerText =
                node.type === BlockType.Image
                  ? 'Image'
                  : node.type === BlockType.DiscoveryQuestion
                  ? 'Discovery Question'
                  : node.type === BlockType.ProductGap
                  ? 'Product Gap'
                  : node.type === BlockType.Task
                  ? 'Task'
                  : node.type === BlockType.AIWorker
                  ? 'AI Worker'
                  : '';
              const commentIds = keys(node.comments);
              if (innerText.trim() && commentIds.length) {
                commentIds.forEach(commentId => {
                  linesCommented[commentId] = [
                    ...(linesCommented[commentId] || []),
                    { text: innerText.trim(), path: (nodeInfo.value as NodeEntry)[1] },
                  ];
                });
              }
            }
          } else {
            const node = nodeInfo.value[0] as CustomText;
            if (node.comment) {
              let innerText = NoteEditor.string(noteEditorStore.commentContext.editor, nodeInfo.value[1]);
              if (!innerText.trim()) {
                const mentionPath = NoteEditor.getParentPathByType(
                  noteEditorStore.commentContext.editor,
                  nodeInfo.value[1],
                  BlockTypeInline.Mention,
                );
                if (mentionPath) {
                  const mentionNode =
                    Node.has(noteEditorStore.commentContext.editor, mentionPath) &&
                    (Node.get(noteEditorStore.commentContext.editor, mentionPath) as MentionElement);
                  if (mentionNode && mentionNode.mentionUserId) {
                    const member = useUserStore.getState().members?.find(item => item.id === mentionNode.mentionUserId);
                    innerText = `@${getMemberName(member)}`;
                  }
                }
              }
              const commentIds = node.comment.split(' ').filter(it => it);
              if (innerText.trim() && commentIds.length) {
                commentIds.forEach(commentId => {
                  linesCommented[commentId] = [
                    ...(linesCommented[commentId] || []),
                    { text: innerText.trim(), path: (nodeInfo.value as NodeEntry)[1] },
                  ];
                });
              }
            }
          }
        }
        nodeInfo = mentionNodeInfos.next();
      }
      // show content commented in each line for each block
      const linesCommentedMerged: { [commentId: string]: string[] } = {};
      keys(linesCommented).forEach(commentId => {
        linesCommentedMerged[commentId] = flatten(
          values(groupBy(linesCommented[commentId], comment => comment.path[0])).map(items =>
            items.map(item => item.text).join(''),
          ),
        );
      });
      setNodesCommented(linesCommentedMerged);
    } else {
      setPostedComments({});
    }
  }, [noteEditorStore.commentContext?.comments]);

  const getCommentMenuActions = useCallback(
    (comment: CommentRecord): ListItem<string>[] => {
      return [
        { label: 'Edit comment', value: 'edit', icon: <PencilSquareIcon className="w-5 h-5" /> },
        { label: 'Delete comment', value: 'delete', icon: <TrashIcon className="w-5 h-5" /> },
      ].slice(userStore.user?.id === comment.userId ? 0 : 1);
    },
    [userStore.user?.id],
  );

  const anchorPosition = useRef<{ top: number; left: number }>({ top: 0, left: 0 });

  useEffect(() => {
    const ref = noteEditorStore.commentContext?.ref;
    if (ref) {
      const rect = ref.getBoundingClientRect();
      anchorPosition.current = {
        top: rect.bottom + 4,
        left: rect.left + rect.width / 2 - Math.min(480, window.innerWidth) / 2,
      };
    }
  }, [noteEditorStore.commentContext?.ref]);

  return (
    <Popover
      isOpen={!!noteEditorStore.commentContext}
      onClose={() => {
        noteEditorStore.commentContext?.onClose(null);
        closePopover();
      }}
      anchorReference="anchorPosition"
      anchorEl={noteEditorStore.commentContext?.ref || null}
      anchorPosition={anchorPosition.current}
      disableRestoreFocus
      disableAutoFocus
    >
      <div className={styles.popoverWrap} ref={containerRef}>
        <div className={styles.commentGroups}>
          {keys(postedComments).map((id: string) => (
            <div className={styles.commentGroup} key={id}>
              <div className={styles.postedComments}>
                {postedComments[id]?.map((comment, index) => (
                  <PostedCommentRow
                    key={`${id}-${comment.userId}-${comment.createdDate}-${index}`}
                    editorKey={`${id}-${comment.userId}-${comment.createdDate}-${index}`}
                    members={members}
                    comment={comment}
                    menus={getCommentMenuActions(comment)}
                    onAction={(action, content) => {
                      if (action === 'resolve') {
                        const newPostedComments = cloneDeep(postedComments);
                        if (newPostedComments[id][0]) {
                          // use the first comment to tag resolved
                          newPostedComments[id][0].resolved = !newPostedComments[id][0].resolved;
                        }
                        if (noteEditorStore.commentContext?.editor) {
                          NoteEditor.applyNewComments(noteEditorStore.commentContext.editor, newPostedComments);
                        }
                        // remove resolved comments from view
                        keys(newPostedComments).forEach(key => {
                          if (newPostedComments[key][0]?.resolved) {
                            delete newPostedComments[key];
                          }
                        });
                        // close popover when all comments resolved
                        if (
                          isEmpty(newPostedComments) ||
                          keys(newPostedComments).every(key => !newPostedComments[key].length)
                        ) {
                          closePopover();
                        } else {
                          setPostedComments({ ...newPostedComments });
                        }
                      } else if (action === 'delete') {
                        openDeleteConfirmModal('Would you like to delete this comment?').then(confirm => {
                          if (confirm) {
                            const deletedNode = postedComments[id][index];
                            const newPostedComments = { ...postedComments };
                            newPostedComments[id] = newPostedComments[id].filter(
                              (_item, itemIndex) => itemIndex !== index,
                            );
                            if (noteEditorStore.commentContext?.editor && !newPostedComments[id].length) {
                              // remove leaf node comment if all comments deleted
                              NoteEditor.updateCommentInLeaf(noteEditorStore.commentContext.editor, id, '');
                            }
                            if (noteEditorStore.commentContext?.editor) {
                              NoteEditor.applyNewComments(noteEditorStore.commentContext.editor, newPostedComments);
                            }
                            if (!newPostedComments[id].length) {
                              // remove comment group
                              delete newPostedComments[id];
                            }
                            emitDocumentEvent({
                              opportunityId: opportunityId || '',
                              documentId: documentId || '',
                              type: 'MENTION_DELETE',
                              sourceElement: deletedNode.content as CustomElement[],
                              commentContent: null,
                              docSource: 'main-doc-comment',
                            });
                            // close popover when all comments deleted
                            if (
                              isEmpty(newPostedComments) ||
                              keys(newPostedComments).every(key => !newPostedComments[key].length)
                            ) {
                              closePopover();
                            } else {
                              setPostedComments({ ...newPostedComments });
                            }
                          }
                        });
                      } else if (action === 'update' && content) {
                        const newPostedComments = { ...postedComments };
                        newPostedComments[id] = newPostedComments[id].map((item, itemIndex) =>
                          itemIndex === index ? { ...item, content, updatedDate: new Date().toISOString() } : item,
                        );
                        if (noteEditorStore.commentContext?.editor) {
                          NoteEditor.applyNewComments(noteEditorStore.commentContext.editor, newPostedComments);
                        }
                        setPostedComments({ ...newPostedComments });
                      }
                    }}
                    isFirst={index === 0}
                    contentPrefix={
                      index === 0 && nodesCommented[id] ? (
                        <div className={styles.commentedLines}>
                          {nodesCommented[id].slice(0, 3).map((node, nodeIndex) => (
                            <div className={styles.commentedLine} key={nodeIndex}>
                              {node}
                            </div>
                          ))}
                        </div>
                      ) : null
                    }
                    documentId={documentId}
                    opportunityId={opportunityId}
                  />
                ))}
              </div>
              <div className={styles.editingComment} id={`input-${id}`}>
                <CommentEditor
                  readOnly={false}
                  clearAfterSubmit
                  onSubmit={(content: CommentContent) => {
                    if (userStore.user) {
                      const newPostedComments = { ...postedComments };
                      const newComment = {
                        userId: userStore.user?.id ?? '',
                        createdDate: new Date().toISOString(),
                        updatedDate: '',
                        content,
                      };
                      newPostedComments[id] = [...newPostedComments[id], newComment];
                      setPostedComments(newPostedComments);
                      if (noteEditorStore.commentContext?.editor) {
                        if (noteEditorStore.commentContext?.isInit) {
                          noteEditorStore.commentContext?.onClose(newPostedComments);
                          closePopover();
                        }
                        NoteEditor.applyNewComments(
                          noteEditorStore.commentContext.editor as CustomEditor,
                          newPostedComments,
                        );
                      }
                    } else {
                      noteEditorStore.commentContext?.onClose(null);
                      closePopover();
                    }
                  }}
                  prefix={<CommentUserAvatar member={members[userStore.user?.id ?? '']} hideName />}
                  documentId={documentId}
                  opportunityId={opportunityId}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </Popover>
  );
}

export const openCommentPopover = (
  editor: CustomEditor,
  comments: CommentRecords,
  isInit: boolean,
  commentLeafElement?: Element,
): Promise<CommentPopupResponseType> => {
  const commentIds = keys(comments);
  const commentIdClass = commentIds.map(id => `.comment.comment-${id}`);
  return new Promise<CommentPopupResponseType>(resolve => {
    useNoteEditorStore.setState({
      commentContext: {
        editor,
        comments,
        isInit,
        ref:
          commentLeafElement ||
          document.querySelector(!commentIdClass.length || isInit ? '.comment-init' : `${commentIdClass.join(', ')}`),
        onClose: rsp => resolve(rsp),
      },
    });
  });
};

export default CommentPopover;
