import useUserStore from '../stores/user';
import React, { useEffect, useState } from 'react';

function capitalize(word: string) {
  const lower = word.toLowerCase();
  return word.charAt(0).toUpperCase() + lower.slice(1);
}

export const useUserDomain = () => {
  const userStore = useUserStore();
  const [domain, setDomain] = useState<string>();
  useEffect(() => {
    if (userStore.user) {
      const domainStr = userStore.user?.email.split('@').pop()?.split('.')[0];
      if (domainStr) {
        setDomain(capitalize(domainStr) || 'N/A');
      }
    }
  }, [userStore.user]);
  return domain;
};

function UserDomain() {
  const domain = useUserDomain();
  if (!domain) {
    return <div />;
  }
  return (
    <div className="font-medium flex text-sm text-gray-700 items-center">
      <span className="font-bold bg-gray-100 rounded-md w-7 h-7 flex mr-2 items-center justify-center text-gray-500">
        {domain[0]}
      </span>
      <span>{domain}</span>
    </div>
  );
}

export default UserDomain;
