/* eslint-disable jsx-a11y/no-autofocus */
import React, { useEffect, useRef, useState } from 'react';

import Popover from 'components/Popover';

import useDocumentStore from 'stores/document';

interface Props {
  documentId: string;
  documentTitle: string;
}

function EditableTitle({ documentId, documentTitle }: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [title, setTitle] = useState<string>(documentTitle);
  const ref = useRef<HTMLButtonElement>(null);
  const documentStore = useDocumentStore();

  useEffect(() => {
    if (title !== documentTitle) {
      const timeout = setTimeout(() => {
        documentStore.updateDocumentData(documentId, { docTitle: title });
      }, 1000);

      return () => clearTimeout(timeout);
    }
    return () => null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId, title]);

  return (
    <div className="font-medium border-0 truncate hover:bg-gray-200 rounded px-2">
      <button ref={ref} type="button" onClick={() => setIsOpen(true)}>
        <div className="truncate">{title}</div>
      </button>
      <Popover
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        isOpen={isOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <div className="w-60 h-10 p-1">
          <input
            className="w-full border p-1.5"
            onChange={e => setTitle(e.target.value)}
            type="text"
            value={title}
            onKeyUp={event => {
              if (event.key === 'Enter') {
                setIsOpen(false);
              }
            }}
            autoFocus
          />
        </div>
      </Popover>
    </div>
  );
}

export default EditableTitle;
