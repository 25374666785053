import httpClient from '../http-client';
import { ReportingColumn, ReportingFilter, ReportingSort } from '../../types/reporting';

export default async (
  filters: ReportingFilter[],
  sorts: ReportingSort[],
  columns: ReportingColumn[],
): Promise<void> => {
  const { data } = await httpClient.post<Blob>(
    `/reporting-export`,
    {
      filters,
      sorts,
      columns,
    },
    { responseType: 'blob' },
  );

  const downloadUrl = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.setAttribute('download', `reporting-${new Date().toISOString().split('T')[0]}.xlsx`); // any other extension
  document.body.appendChild(link);
  link.click();
  link.remove();
};
