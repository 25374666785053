import { CheckCircleIcon, QuestionMarkCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import React, { ReactNode } from 'react';
import { Attendee } from 'types/document';

export function MeetingAttendee({ attendee, inModal }: { attendee: Attendee; inModal: boolean }) {
  let h = 'h-5';
  let w = 'w-5';
  let p = '0.5';
  let text = 'text-sm';

  if (inModal) {
    h = 'h-4';
    w = 'w-4';
    p = '0';
    text = 'text-xs';
  }

  let imageElement: ReactNode = (
    <span
      className={`flex items-center justify-center ${h} ${w} rounded-full bg-gray-400 text-white text-xs font-medium`}
    >
      {attendee.initials}
    </span>
  );
  if (attendee.profilePictureUrl) {
    imageElement = <img alt={attendee.name} className={`${h} ${w} rounded-full`} src={attendee.profilePictureUrl} />;
  }

  let statusElement: ReactNode = null;
  if (attendee.status === 'confirmed') {
    statusElement = <CheckCircleIcon className={`${h} ${w} text-white fill-green-600`} title="Confirmed" />;
  } else if (attendee.status === 'declined') {
    statusElement = <XCircleIcon className={`${h} ${w} text-white fill-red-500`} />;
  } else if (attendee.status === 'maybe') {
    statusElement = <QuestionMarkCircleIcon className={`${h} ${w} text-white fill-blue-600`} title="Maybe" />;
  } else if (attendee.status === 'pending') {
    statusElement = <QuestionMarkCircleIcon className={`${h} ${w} text-white fill-gray-400`} title="Pending" />;
  }

  return (
    <button
      type="button"
      className={`m-0.5 ${p} flex items-center gap-2 rounded-full bg-gray-100 ${
        inModal ? 'hover:cursor-default' : null
      }`}
    >
      {imageElement}
      <span className={`${text} font-light`}>
        {attendee.name}
        {attendee.isOrganizer && <span className="ml-1 text-xs text-gray-500">(Organizer)</span>}
      </span>
      {statusElement}
    </button>
  );
}

export default MeetingAttendee;
