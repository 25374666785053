/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-props-no-spreading, jsx-a11y/click-events-have-key-events */
import React, { useRef } from 'react';
import { BlockType, CommentRecords, CustomEditor, TaskElement as TaskElementType } from 'components/NoteEditor/types';
import { Descendant, Editor, Transforms } from 'slate';
import { ReactEditor, RenderElementProps, useSelected } from 'slate-react';
import { concat } from 'utils/styling';
import TaskEditor from './TaskEditor';
import styles from './TaskElement.module.css';
import useUserStore from 'stores/user';
import { createNode } from 'components/NoteEditor/utils';
import NoteEditor from 'components/NoteEditor/NoteEditor';
import { cloneDeep, isEmpty, keys } from 'lodash';
import { openCommentPopover } from '../Comment/CommentPopover';

interface Props extends RenderElementProps {
  element: TaskElementType;
  documentId: string | null;
  opportunityId: string | null;
  editor: CustomEditor;
  comments: CommentRecords | undefined;
  scrollContainer?: HTMLDivElement;
  isFromTemplate?: boolean;
  readOnly?: boolean;
}

function TaskElement({
  attributes,
  element,
  children,
  documentId,
  opportunityId,
  editor,
  comments,
  scrollContainer,
  isFromTemplate,
  readOnly,
}: Props) {
  const selected = useSelected();
  const userStore = useUserStore();
  const ref = useRef<HTMLDivElement>(null);

  const addComment = () => {
    const path = ReactEditor.findPath(editor, element);
    Transforms.select(editor, path);
    const userId = userStore.user?.id;
    const availabelComments = cloneDeep(comments || {});
    keys(availabelComments).forEach(id => {
      if (availabelComments[id]?.[0]?.resolved) {
        delete availabelComments[id];
      }
    });
    // add new comment for root node
    if (!availabelComments || isEmpty(availabelComments)) {
      if (userId) {
        Transforms.setNodes(editor, { commentInit: true }, { at: path });
        setTimeout(() => {
          NoteEditor.addNewCommentForCommentInitElements(editor, userId);
        });
        return;
      }
    }
    const commentIds = keys(availabelComments);
    const commentIdClass = commentIds.map(id => `.comment.comment-${id}`);
    openCommentPopover(
      editor,
      availabelComments || {},
      false,
      ref.current?.parentElement?.querySelector?.(`${commentIdClass.join(', ')}`) ||
        ref.current?.parentElement?.parentElement ||
        undefined,
    );
  };

  return (
    <div
      ref={ref}
      contentEditable={false}
      className={concat(
        styles.taskContainer,
        selected && styles.selected,
        'task',
        element.taskId && `task-${element.taskId}`,
      )}
      onClick={() => {
        if (!selected) {
          Transforms.select(editor, ReactEditor.findPath(editor, element));
        }
      }}
    >
      <TaskEditor
        taskId={element.taskId}
        beingCreatedBy={element.beingCreatedBy}
        taskInitContent={element.initContent}
        documentId={documentId}
        opportunityId={opportunityId}
        onCreateTask={newTask => {
          const path = ReactEditor.findPath(editor, element);
          Transforms.setNodes(
            editor,
            { taskId: newTask.id, beingCreatedBy: undefined, initContent: undefined },
            { at: path },
          );
        }}
        onInstantiateTask={newTask => {
          const path = ReactEditor.findPath(editor, element);
          Transforms.setNodes(
            editor,
            { taskId: newTask.id, beingCreatedBy: undefined, initContent: undefined },
            { at: path },
          );
        }}
        onSplitToNewTask={(fragment: Descendant[]) => {
          const path = ReactEditor.findPath(editor, element);
          Transforms.insertNodes(
            editor,
            {
              type: BlockType.Task,
              beingCreatedBy: userStore.user?.id || '',
              initContent: fragment,
              children: [{ text: '' }],
            } as TaskElementType,
            { at: [path[0] + 1] },
          );
          Transforms.select(editor, [path[0] + 1]);
        }}
        onDelete={(leftContent: Descendant[] | undefined) => {
          ReactEditor.focus(editor);
          const path = ReactEditor.findPath(editor, element);
          Transforms.removeNodes(editor, { at: path });
          Transforms.insertNodes(editor, leftContent?.length ? leftContent : createNode(BlockType.Paragraph), {
            at: path,
          });
          Transforms.select(editor, Editor.start(editor, path));
        }}
        canDeleteInSlateEditor
        onComment={addComment}
        onFocus={() => {
          if (!selected) {
            Transforms.select(editor, ReactEditor.findPath(editor, element));
          }
        }}
        scrollContainer={scrollContainer}
        isFromTemplate={isFromTemplate}
        readOnly={readOnly}
      />
      <span {...attributes}>{children}</span>
    </div>
  );
}

TaskElement.defaultProps = {
  scrollContainer: undefined,
  isFromTemplate: false,
  readOnly: false,
};

export default TaskElement;
