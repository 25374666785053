const entityMap: { [key: string]: string } = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  "'": '&#39;',
  '/': '&#x2F;',
  '`': '&#x60;',
  '=': '&#x3D;',
};

const escapeHtml = (str: string): string => {
  return String(str).replace(/[&<>"'`=/]/g, s => entityMap[s] ?? '');
};

export default escapeHtml;
