import React from 'react';

interface Props {
  className?: string;
}

function ThreeVerticalDotsIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 13.7002C12.8284 13.7002 13.5 13.0286 13.5 12.2002C13.5 11.3718 12.8284 10.7002 12 10.7002C11.1716 10.7002 10.5 11.3718 10.5 12.2002C10.5 13.0286 11.1716 13.7002 12 13.7002Z"
        fill="currentColor"
      />
      <path
        d="M12 7.5C12.8284 7.5 13.5 6.82843 13.5 6C13.5 5.17157 12.8284 4.5 12 4.5C11.1716 4.5 10.5 5.17157 10.5 6C10.5 6.82843 11.1716 7.5 12 7.5Z"
        fill="currentColor"
      />
      <path
        d="M12 19.9004C12.8284 19.9004 13.5 19.2288 13.5 18.4004C13.5 17.572 12.8284 16.9004 12 16.9004C11.1716 16.9004 10.5 17.572 10.5 18.4004C10.5 19.2288 11.1716 19.9004 12 19.9004Z"
        fill="currentColor"
      />
    </svg>
  );
}

ThreeVerticalDotsIcon.defaultProps = {
  className: undefined,
};

export default ThreeVerticalDotsIcon;
