import React from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import useContextPanelStore from '../../stores/context-panel';
import useProductGapStore from '../../stores/product-gap';

function ProductCategory() {
  const contextPanelStore = useContextPanelStore();
  const productGapStore = useProductGapStore();

  const productCategory =
    productGapStore.productCategories.find(c => c.id === productGapStore.productCategoryId) || null;

  return (
    <div>
      <div className="flex justify-between items-center p-4">
        <div className="text-lg font-medium">Product Category</div>
        <div className="flex items-center">
          <button
            type="button"
            className="w-8 h-8 p-2 bg-slate-200 rounded text-gray-500 hover:bg-slate-300"
            onClick={contextPanelStore.close}
          >
            <XMarkIcon />
          </button>
        </div>
      </div>
      <div className="py-4 px-8 text-sm">
        {!productCategory ? (
          <div> Unable to find this category. Please try again. </div>
        ) : (
          <div>
            <div className="font-bold text-3xl">{productCategory.title}</div>
            <div className="mt-2">
              <div className="mt-4 flex">
                <div className="w-40 flex-grow-0 flex-shrink-0 text-gray-500">Description</div>
                <div className="w-full h-40">
                  <textarea className="w-full h-full" value={productCategory.description} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ProductCategory;
