import React from 'react';
import { ProductFieldAnswer } from 'types/product-gap';
import useProductGapStore from '../../../stores/product-gap';
import useContextPanelStore from '../../../stores/context-panel';
import SidebarIcon from '../../icons/SidebarIcon';
import PlayBack from 'components/FieldValueView/PlayBack';

interface Props {
  cellValue: ProductFieldAnswer | null;
  rowData: { [id: string]: string };
}

function ProductFeedbackCellValue({ cellValue, rowData }: Props) {
  const productGapStore = useProductGapStore();
  const contextPanelStore = useContextPanelStore();

  if (!cellValue) return <div />;
  return (
    <div className="px-4 py-2 relative group w-full h-full whitespace-normal">
      <div className="line-clamp-6">
        {rowData.callId && (
          <div className="mt-1 flex gap-2 items-center">
            <div className="text-gray-400 text-xs">{rowData.callTitle}</div>
            <PlayBack
              recordingId={rowData.callId}
              startTime={Number(rowData.startTime)}
              from="docEditor"
              elementId=""
            />
          </div>
        )}
        {cellValue.answer}
      </div>
      <button
        type="button"
        className="absolute gap-1 top-1 right-1 hidden group-hover:flex group-hover:items-center rounded border bg-white p-1 text-sm text-gray-500 hover:bg-gray-200"
        onClick={() => {
          productGapStore.setState({ productGapId: rowData.id });
          contextPanelStore.setState({ panelContext: 'product-gap' });
        }}
      >
        <SidebarIcon className="w-5 h-5" />
        <div>OPEN</div>
      </button>
    </div>
  );
}

export default ProductFeedbackCellValue;
