import React, { useState } from 'react';

import FieldValueView from 'components/FieldValueView';
import CaretIcon from 'components/icons/CaretIcon';
import ExternalLinkIcon from 'components/icons/ExternalLinkIcon';

import useDocumentStore from 'stores/document';

import { FieldValue } from 'types/field-value';
import { Opportunity } from 'types/entity';

import QuestionSettingButton from './QuestionSettingButton';
import BaseButton from 'components/BaseButton';

interface State {
  isAccountOpen: boolean;
  isOpportunityOpen: boolean;
}

interface Props {
  fieldValues: FieldValue[];
  documentId: string;
  entityName: 'Account' | 'Opportunity';
  headerRightElement: (type: 'account' | 'opportunity' | '') => React.ReactNode;
}

function FieldValuesView({ fieldValues, documentId, entityName, headerRightElement }: Props) {
  const [state, setState] = useState<State>({
    isAccountOpen: true,
    isOpportunityOpen: true,
  });

  const documentStore = useDocumentStore();
  const opportunityEntityDocuments = documentStore.entityDocuments.find(
    o => o.id === documentId && o.entity.name === 'Opportunity',
  );

  const accountFieldValues = fieldValues.filter(
    fieldValue => fieldValue.document.entity && fieldValue.document.entity.name === 'Account',
  );
  const opportunityFieldValues = fieldValues.filter(
    fieldValue => fieldValue.document.entity && fieldValue.document.entity.name === 'Opportunity',
  );

  return (
    <div>
      <div>
        <div className="pb-1 flex items-center z-10 bg-white sticky -top-1">
          <div className="flex items-center flex-1 -ml-2 mr-2">
            <BaseButton
              color="secondary"
              className="!pl-0 !text-black"
              onClick={() => setState(prevState => ({ ...prevState, isAccountOpen: !prevState.isAccountOpen }))}
            >
              <CaretIcon className={state.isAccountOpen ? 'rotate-0' : 'rotate-180'} />
              <div className="text-left whitespace-nowrap">
                <span className="text-[#5A6CD8]">Account</span>
              </div>
            </BaseButton>
            {documentStore.instanceUrl && opportunityEntityDocuments && opportunityEntityDocuments.crm && (
              <a
                className="w-6 h-6 ml-2"
                title="Open in Salesforce"
                href={`${documentStore.instanceUrl}/${(opportunityEntityDocuments.crm as Opportunity).accountId}`}
                target="_blank"
                rel="noreferrer"
              >
                <ExternalLinkIcon className="w-5 h-5 text-gray-400 hover:text-gray-500" />
              </a>
            )}
          </div>
          {headerRightElement('account')}
        </div>
        {accountFieldValues.length <= 0 && <div>No account discovery field</div>}
        {state.isAccountOpen && (
          <div className="ml-5">
            {accountFieldValues.map(fieldValue => (
              <div key={fieldValue.id} className="flex justify-between items-start">
                <div className="flex-1">
                  <FieldValueView key={fieldValue.id} fieldValue={fieldValue} from="discoveryQuestionContextPanel" />
                </div>
                <QuestionSettingButton discoveryQuestionAnswer={fieldValue} />
              </div>
            ))}
          </div>
        )}
      </div>
      {entityName === 'Opportunity' && (
        <div>
          <div className="pt-1 pb-3 flex items-center z-10 bg-white sticky top-0">
            <div className="flex items-center flex-1 -ml-2 mr-2">
              <BaseButton
                color="secondary"
                className="!pl-0 !text-black"
                onClick={() =>
                  setState(prevState => ({ ...prevState, isOpportunityOpen: !prevState.isOpportunityOpen }))
                }
              >
                <CaretIcon className={state.isOpportunityOpen ? 'rotate-0' : 'rotate-180'} />
                <div className="text-left whitespace-nowrap">
                  <span className="text-[#FA9405]">Opportunity</span>{' '}
                </div>
              </BaseButton>
              {documentStore.instanceUrl && opportunityEntityDocuments && opportunityEntityDocuments.crm && (
                <a
                  className="w-6 h-6 ml-2"
                  title="Open in Salesforce"
                  href={`${documentStore.instanceUrl}/${opportunityEntityDocuments.crm.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <ExternalLinkIcon className="w-5 h-5 text-gray-400 hover:text-gray-500" />
                </a>
              )}
            </div>
            {headerRightElement('opportunity')}
          </div>
          {opportunityFieldValues.length <= 0 && <div>No opportunity discovery field</div>}
          {state.isOpportunityOpen && (
            <div className="ml-5">
              {opportunityFieldValues.map(fieldValue => (
                <div key={fieldValue.id} className="flex justify-between items-start">
                  <div className="flex-1">
                    <FieldValueView key={fieldValue.id} fieldValue={fieldValue} from="discoveryQuestionContextPanel" />
                  </div>
                  <QuestionSettingButton discoveryQuestionAnswer={fieldValue} />
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default FieldValuesView;
