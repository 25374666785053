/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import CellValue from './Rows/CellValue';
import { ColumnConfig, RowData } from './types';
import { getTableSequenceCellWidth } from './utils';
import { concat } from 'utils/styling';
import styles from './Rows.module.css';

function ColumnConfigView({ columnConfig, row }: { columnConfig: ColumnConfig; row: any }) {
  if ('group' in columnConfig) {
    const groupData = row[columnConfig.id] || {};
    return (
      <>
        {columnConfig.columns.map((column, colIndex) => {
          if (column.isHidden) return null;
          return (
            <CellValue
              key={`grouprow-${column.id}-${colIndex}`}
              value={groupData[column.id]}
              width={column.width}
              type={column.type}
              rowData={row}
              columnId={column.id}
              columnName={column.name}
              columnData={column.data}
              groupId={columnConfig.id}
            />
          );
        })}
      </>
    );
  }

  if (columnConfig.isHidden) return null;
  return (
    <CellValue
      key={`row-${columnConfig.id}`}
      value={row[columnConfig.id]}
      width={columnConfig.width}
      type={columnConfig.type}
      rowData={row}
      columnId={columnConfig.id}
      columnName={columnConfig.name}
      columnData={columnConfig.data}
    />
  );
}

interface Props {
  columnConfigs: ColumnConfig[];
  rows: RowData[];
  showSequence?: boolean;
  sequenceOffset?: number;
}
function Rows({ columnConfigs, rows, showSequence, sequenceOffset }: Props) {
  const sequenceCellWidth = useMemo(
    () => (showSequence ? getTableSequenceCellWidth((sequenceOffset ?? 0) + rows.length) : 0),
    [showSequence, sequenceOffset, rows.length],
  );
  const pinnedColumns = useMemo(
    () =>
      columnConfigs.filter(columnConfig => {
        return (
          !!columnConfig.isPinned &&
          ('group' in columnConfig ? !columnConfig.columns.every(subCol => subCol.isHidden) : !columnConfig.isHidden)
        );
      }),
    [columnConfigs],
  );
  const nonPinnedColumns = useMemo(
    () =>
      columnConfigs.filter(columnConfig => {
        return !columnConfig.isPinned;
      }),
    [columnConfigs],
  );
  const totalWidth = useMemo(
    () =>
      columnConfigs.reduce((acc, columnConfig) => {
        if ('group' in columnConfig) {
          return acc + columnConfig.columns.reduce((acc2, column) => acc2 + (column.isHidden ? 0 : column.width), 0);
        }
        return acc + (columnConfig.isHidden ? 0 : columnConfig.width) + sequenceCellWidth;
      }, 0),
    [columnConfigs],
  );

  return (
    <div className="flex" style={{ width: `${totalWidth}px` }}>
      <div className="flex flex-col">
        {rows.map((row, rowIndex) => {
          return (
            <div key={`row-${rowIndex}`} className={concat('flex', styles.row)}>
              {(!!showSequence || !!pinnedColumns.length) && (
                <div
                  className={concat(
                    'sticky left-0 float-left bg-white z-10 flex border-r',
                    !!pinnedColumns.length && 'border-r-2 border-blue-200',
                    styles.pinnedRowSection,
                  )}
                >
                  {showSequence && (
                    <div className="pl-2 py-2 border-r border-b border-gray-300" style={{ width: sequenceCellWidth }}>
                      {(sequenceOffset ?? 0) + rowIndex + 1}
                    </div>
                  )}
                  {pinnedColumns.map((columnConfig, colIndex) => (
                    <ColumnConfigView
                      key={`row-${columnConfig.id}-${colIndex}`}
                      columnConfig={columnConfig}
                      row={row}
                    />
                  ))}
                </div>
              )}
              {nonPinnedColumns.map((columnConfig, colIndex) => (
                <ColumnConfigView key={`row-${columnConfig.id}-${colIndex}`} columnConfig={columnConfig} row={row} />
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
}

Rows.defaultProps = {
  showSequence: false,
  sequenceOffset: 0,
};

export default Rows;
