/* eslint-disable import/prefer-default-export */

export function findAllIndex<T>(array: T[], predict: (item: T) => boolean): number[] {
  const indexes: number[] = [];
  array.forEach((item, index) => {
    if (predict(item)) {
      indexes.push(index);
    }
  });
  return indexes;
}
