import moment from 'moment';
import React, { useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import { Event } from 'types/document';
import TurndownService from 'turndown';
import styles from './CalendarInformation.module.css';
import BaseButton from 'components/BaseButton';
import CalendarIcon from 'components/icons/CalendarIcon';
import { concat } from 'utils/styling';
import { openInformationalModal } from 'components/BaseModal';
import constants from 'utils/constants';

const EMPTY_DESCRIPTION = 'No meeting description set';
interface Props {
  event: null | Event;
  className?: string;
}

function CalendarInformation({ event, className }: Props) {
  const meetingTime = useMemo(
    () => (event?.startsAt ? moment(event.startsAt).format(constants.DATETIME_FORMAT) : ''),
    [event],
  );

  const descriptionProcessed = useMemo(() => {
    let description = EMPTY_DESCRIPTION;
    if (event?.description && event?.description.trim() !== '') {
      description = event.description;
    }

    const turndownService = new TurndownService();
    let processed = description;
    // this was to handle html
    const isHtml = /<\/?[a-z][\s\S]*>/i.test(description);
    if (isHtml) {
      processed = turndownService.turndown(description);
    }
    return processed;
  }, [event?.description]);

  if (!event) {
    return null;
  }

  return (
    <BaseButton
      color="secondary"
      variant="outlined"
      tooltipPlacement="bottom"
      className={concat(styles.btn, '!font-normal', className)}
      onClick={e => {
        e.stopPropagation();
        openInformationalModal(
          <>
            <div className={styles.infoItem}>
              <div className={styles.infoItemTitle}>Meeting time: </div>
              <div className={styles.infoItemValue}>{meetingTime}</div>
            </div>
            <div className={styles.infoItem}>
              <div className={styles.infoItemTitle}>Meeting description: </div>
              <div className={styles.infoItemValue}>
                <ReactMarkdown>{descriptionProcessed}</ReactMarkdown>
              </div>
            </div>
          </>,
          '',
          'Meeting Information',
        );
      }}
      tooltip="Meeting Information"
    >
      <CalendarIcon className={concat(styles.ÏÏicon, styles.calendarIcon)} />
      <span className="truncate">{meetingTime}</span>
    </BaseButton>
  );
}

CalendarInformation.defaultProps = {
  className: '',
};

export default CalendarInformation;
