import React from 'react';
import { ColumnDataType, RowData } from '../types';
import { convertValueToDisplayValue } from '../utils';
import ProductFeedbackCellValue from './ProductFeedbackCellValue';
import ProductCategoryCellValue from './ProductCategoryCellValue';
import CurrencyCellValue from './CurrencyCellValue';
import ReportFieldCellValue from './ReportFieldCellValue';
import ReportFieldAnswerCellValue from './ReportFieldAnswerCellValue';
import ReportEntityFieldCellValue from './ReportEntityFieldCellValue';
import { TableRow } from '../../../types/reporting';
import SpinLoader from '../../icons/SpinLoader';
import { get } from 'lodash';
import AccountIcon from 'components/icons/AccountIcon';
import OpportunityIcon from 'components/icons/OpportunityIcon';

interface Props {
  value: any;
  width: number;
  type: ColumnDataType;
  rowData: RowData;
  columnId: string;
  columnName: string;
  columnData?: any;
  groupId?: string;
}

function CellValue({ value, width, type, rowData, columnId, columnName, columnData, groupId }: Props) {
  let element;
  if (
    (groupId === 'account' || groupId === 'opportunity') &&
    columnId === 'name' &&
    get(rowData, [groupId, 'documentId'])
  ) {
    element = (
      <a
        className="px-4 py-2 flex items-start text-left hover:text-blue-400 hover:underline"
        href={`/${groupId}/${get(rowData, [groupId, 'documentId'])}`}
        target="_blank"
        rel="noreferrer"
      >
        {groupId === 'account' ? (
          <AccountIcon className="min-w-[16px] w-4 h-4 mt-1 mr-2" />
        ) : (
          <OpportunityIcon className="min-w-[16px] w-4 h-4 mt-0.5 mr-2 fill-orange-400 text-orange-400" />
        )}
        {value}
      </a>
    );
  } else {
    switch (type) {
      case 'ProductFeedback':
        element = <ProductFeedbackCellValue cellValue={value} rowData={rowData} />;
        break;
      case 'ProductCategory':
        element = <ProductCategoryCellValue cellValue={value} />;
        break;
      case 'Currency':
        element = <CurrencyCellValue cellValue={value} />;
        break;
      case 'ReportCalculatedField': {
        if (value === undefined) {
          element = <div className="px-4 py-2 text-gray-300">No Opportunity</div>;
          break;
        }
        if (columnId === 'DiscoveryCompletenessScore') {
          if (value === null) element = <div className="px-4 py-2 text-gray-300">Empty</div>;
          else if (value <= 33)
            element = (
              <div className="px-4 py-2">
                <div className="rounded bg-red-100 text-red-700 px-4 py-1 text-left">{value}%</div>
              </div>
            );
          else if (value <= 66)
            element = (
              <div className="px-4 py-2">
                <div className="rounded bg-orange-100 text-orange-700 px-4 py-1 text-left">{value}%</div>
              </div>
            );
          else
            element = (
              <div className="px-4 py-2">
                <div className="rounded bg-green-100 text-green-700 px-4 py-1 text-left">{value}%</div>
              </div>
            );
          break;
        }
        if (value === null) element = <div className="px-4 py-2 text-gray-300">No Meetings</div>;
        else element = <div className="px-4 py-2">{value}</div>;
        break;
      }
      case 'ReportField':
        element = (
          <ReportFieldCellValue
            cellValue={value}
            columnData={columnData}
            rowData={rowData}
            fieldId={groupId as string}
            analyticColumnId={columnId}
          />
        );
        break;
      case 'ReportFieldAnswer':
        element = (
          <ReportFieldAnswerCellValue
            cellValue={value}
            columnName={columnName}
            columnData={columnData}
            rowData={rowData as TableRow}
          />
        );
        break;
      case 'ReportLoadingField':
        element = <SpinLoader className="w-5 h-5 animate-spin" />;
        break;
      case 'ReportFailedField':
        element = <div className="text-red-300">Failed to process data</div>;
        break;
      case 'ReportEntityField':
        element = (
          <ReportEntityFieldCellValue
            cellValue={value}
            rowData={rowData as TableRow}
            fieldId={columnId}
            columnName={columnName}
            columnData={columnData}
          />
        );
        break;
      default:
        element = <div className="px-4 py-2">{convertValueToDisplayValue(value, type)}</div>;
        break;
    }
  }

  return (
    <div className="flex-shrink-0 border-r border-b border-gray-300 flex-grow-0 truncate" style={{ width }}>
      <div className="flex w-full h-full">{element}</div>
    </div>
  );
}

CellValue.defaultProps = {
  columnData: undefined,
  groupId: undefined,
};
export default CellValue;
