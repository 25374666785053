import { ColumnDefinition, GroupColumnDefinition } from '../../../components/Table/types';
import { AnalyticColumnSubHeader, EntityGroupHeader, FieldGroupHeader, FieldSubHeader } from '../../../types/reporting';

export function createLoadingEntityFieldColumnDefinition(fieldSubHeader: FieldSubHeader): ColumnDefinition {
  return {
    data: {
      key: 'createLoadingEntityFieldColumnDefinition',
    },
    id: fieldSubHeader.fieldId,
    name: fieldSubHeader.fieldLabel,
    width: 250,
    type: 'ReportLoadingField',
    isHidden: true,
  };
}

export function createEntityFieldColumnDefinition(
  fieldSubHeader: FieldSubHeader,
  entityName: 'Account' | 'Opportunity',
  isHidden: boolean,
): ColumnDefinition {
  if (fieldSubHeader.status === 'new') return createLoadingEntityFieldColumnDefinition(fieldSubHeader);
  return {
    data: {
      key: 'createEntityFieldColumnDefinition',
      isCrmDefault: fieldSubHeader.isCrmDefault,
      entityName,
      dataEntryType: fieldSubHeader.type,
      picklistValues: fieldSubHeader.picklistValues,
      referenceTo: fieldSubHeader.referenceTo,
    },
    id: fieldSubHeader.fieldId,
    name: fieldSubHeader.fieldLabel,
    width: 250,
    type: 'ReportEntityField',
    isHidden,
  };
}

export function createEntityGroupColumnDefinition(entityGroupHeader: EntityGroupHeader): GroupColumnDefinition {
  return {
    data: {
      key: 'createEntityGroupColumnDefinition',
    },
    id: entityGroupHeader.entityName,
    group: entityGroupHeader.entityName,
    columns: [],
  };
}

export function createAnswerColumnDefinition(fieldId: string, entityName: string, isHidden: boolean): ColumnDefinition {
  return {
    data: {
      key: 'createAnswerColumnDefinition',
      fieldId,
      entityName,
    },
    id: 'answer',
    name: 'Answer',
    width: 370,
    type: 'ReportFieldAnswer',
    isHidden,
  };
}
export function createFieldColumnDefinition(
  analyticColumnSubHeader: AnalyticColumnSubHeader,
  entityName: string,
  isHidden: boolean,
): ColumnDefinition {
  return {
    data: {
      key: 'createColumnDefinition',
      dataEntryType: analyticColumnSubHeader.dataEntryType,
      entityName,
    },
    id: analyticColumnSubHeader.id,
    name: analyticColumnSubHeader.columnName,
    width: 250,
    type: 'ReportField',
    isHidden,
  };
}
export function createFieldGroupLoadingColumnDefinition(fieldGroupHeader: FieldGroupHeader): GroupColumnDefinition {
  return {
    data: {
      key: 'createFieldGroupLoadingColumnDefinition',
    },
    id: fieldGroupHeader.fieldId,
    group: fieldGroupHeader.fieldLabel,
    columns: [
      {
        id: `Loading`,
        name: 'Loading Column',
        width: 250,
        type: 'ReportLoadingField',
        isHidden: true,
      },
    ],
  };
}

export function createFieldGroupColumnDefinition(fieldGroupHeader: FieldGroupHeader): GroupColumnDefinition {
  if (['new', 'pending'].includes(fieldGroupHeader.status || '')) {
    return createFieldGroupLoadingColumnDefinition(fieldGroupHeader);
  }
  return {
    data: {
      key: 'createFieldGroupColumnDefinition',
      entityName: fieldGroupHeader.entityName,
    },
    id: fieldGroupHeader.fieldId,
    group: fieldGroupHeader.fieldLabel,
    columns: [],
    type: 'ReportField',
  };
}
