import React from 'react';
import PlayIcon from '../../icons/NoteIcon';

interface Props {
  documentId: string;
  positions: [string];
}

function DocumentRefLink({ documentId, positions }: Props) {
  return (
    <button
      id="playback-recording-button"
      type="button"
      className="flex items-center group gap-1 hover:cursor-pointer"
      style={{ lineHeight: '0.75rem' }}
      onClick={async () => {
        window.open(`/document/${documentId}#${positions[0]}`, '_blank');
      }}
    >
      <PlayIcon className="w-5 h-5 text-gray-400 group-hover:bg-blue-50 group-hover:text-blue-500" />

      <div className="text-blue-500 underline text-xs">Snippet</div>
    </button>
  );
}

export default DocumentRefLink;
