import React, { useEffect } from 'react';

import useContextPanelStore from 'stores/context-panel';
import useNavMenuStore from 'stores/nav-menu';
import useSideMenuStore from 'stores/side-menu';

import constants from 'utils/constants';
import Tooltip from '../Tooltip';

type State = 'hover' | 'fixed' | null;

interface Props {
  isAtDocPage: boolean;
  state: State;
  buttonName: 'note' | 'cal';
  setState: (state: State) => void;
  onClick: (state: State) => void;
  children: JSX.Element;
}

function SideMenuToggleButton({ isAtDocPage, state, buttonName, setState, onClick, children }: Props) {
  const sideMenuStore = useSideMenuStore();
  const contextPanelStore = useContextPanelStore();
  const navMenuStore = useNavMenuStore();

  useEffect(() => {
    // closed the menu, add dot
    if (navMenuStore.collapsedButton === buttonName) {
      navMenuStore.setState({ showHint: buttonName, collapsedButton: null });
    }
    // open the menu, remove dot
    if (sideMenuStore.calendarMenu === 'fixed') {
      navMenuStore.setState({ showHint: null });
    }
    // open the menu, remove dot
    if (sideMenuStore.noteMenu === 'fixed') {
      navMenuStore.setState({ showHint: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navMenuStore.collapsedButton, sideMenuStore]);

  return (
    <div
      className="relative"
      onMouseEnter={() => {
        if (state !== 'fixed') setState('hover');
        // navMenuStore.setState({ buttonHover: buttonName });
      }}
      onMouseLeave={() => {
        if (state !== 'fixed') setState(null);
        // navMenuStore.setState({ buttonHover: null });
      }}
    >
      <div
        className={`relative mx-[5px] py-1 px-[4px] ${
          buttonName === 'cal' ? 'border-x border-t rounded-t-lg' : 'border-x border-b rounded-b-lg'
        }  ${isAtDocPage ? 'border-gray-300' : 'border-gray-300'}`}
      >
        <Tooltip title={buttonName === 'cal' ? 'Calendar' : 'Documents'} placement="right">
          <button
            type="button"
            className={`flex flex-col items-center w-full hover:text-blue-500 hover:bg-blue-100 py-0.5 rounded-lg ${
              state === 'fixed' ? 'text-blue-500 bg-blue-100' : 'text-slate-400'
            }`}
            onClick={() => {
              if (state !== 'fixed') {
                onClick('fixed');
                if (window.innerWidth < constants.SMALL_SCREEN_WIDTH_SIZE) {
                  contextPanelStore.close();
                }
              } else {
                sideMenuStore.close();
              }
            }}
          >
            {children}
          </button>
        </Tooltip>

        {navMenuStore.showHint === buttonName && isAtDocPage && (
          <div className="absolute top-2 right-1 w-2 h-2 rounded-full bg-blue-500 animate-fade-in-down" />
        )}
      </div>
    </div>
  );
}

export default SideMenuToggleButton;
