import { useEffect, useState } from 'react';
import lodash from 'lodash';
import useReportingStore from '../../stores/reporting';
import { GroupColumnDefinition } from '../../components/Table/types';
import useTableStore from '../../stores/table';
import {
  createAnswerColumnDefinition,
  createEntityFieldColumnDefinition,
  createEntityGroupColumnDefinition,
  createFieldColumnDefinition,
  createFieldGroupColumnDefinition,
} from './useTableConfig/utils';
import { findAllIndex } from 'utils/array';
import { addGroupSequnceNumToColumnDefs } from 'components/Table/utils';
import { TableColumn } from '../../types/reporting';

function generateTableConfig(
  existingColumns: GroupColumnDefinition[],
  tableColumns: TableColumn[],
  createdFieldIds: string[],
  createdColumnIds: string[],
) {
  let newConfigs = lodash.cloneDeep(existingColumns);

  const isNewUser = newConfigs.length === 0;
  // Add missing column configs
  tableColumns.forEach(tableColumn => {
    let columnIndex = 0;
    if (tableColumn.type === 'field') {
      let columnIndexes = findAllIndex(newConfigs, config => config.id === tableColumn.fieldId);

      const groupColumnDefinition = createFieldGroupColumnDefinition(tableColumn);
      if (!columnIndexes.length) {
        // Creating Field Group Definition
        newConfigs.push(groupColumnDefinition);
      } else if (columnIndexes.some(index => newConfigs[index]?.data.key !== 'createFieldGroupColumnDefinition')) {
        newConfigs = newConfigs.filter(config => config.id !== tableColumn.fieldId);
        newConfigs.push(groupColumnDefinition);
      }
      columnIndexes = findAllIndex(newConfigs, config => config.id === tableColumn.fieldId);
      columnIndex = columnIndexes[columnIndexes.length - 1];

      // Update Group Name
      columnIndexes.forEach(index => {
        if (newConfigs[index].group !== groupColumnDefinition.group) {
          newConfigs[index].group = groupColumnDefinition.group;
        }
      });

      // Skip creating columns if the column is about loading
      if (columnIndexes.every(index => newConfigs[index]?.data.key === 'createFieldGroupLoadingColumnDefinition')) {
        return;
      }

      // Check if answer column exists in the group, if not add answer column
      const isAnswerColumnExists = columnIndexes.some(index =>
        newConfigs[index].columns.find(config => config.id === 'answer'),
      );
      if (!isAnswerColumnExists) {
        newConfigs[columnIndex].columns.unshift(
          createAnswerColumnDefinition(tableColumn.fieldId, tableColumn.entityName, true),
        );
      }

      // Loop through all the columns in field
      tableColumn.columns.forEach(column => {
        const columnConfigIndex = columnIndexes
          .map(colIndex => {
            const subIndex = newConfigs[colIndex].columns.findIndex(config => config.id === column.id);
            return subIndex >= 0 ? [colIndex, subIndex] : undefined;
          })
          .filter(item => !!item)[0];

        const columnDefinition = createFieldColumnDefinition(
          column,
          tableColumn.entityName,
          !(!isNewUser || createdFieldIds.includes(tableColumn.fieldId) || createdColumnIds.includes(column.id)),
        );

        // Create Column Config if not exists
        if (!columnConfigIndex) {
          newConfigs[columnIndex].columns.push(columnDefinition);
          return;
        }

        // Change Column Config if column key is different
        if (newConfigs[columnConfigIndex[0]].columns[columnConfigIndex[1]].data?.key !== columnDefinition.data?.key) {
          newConfigs[columnConfigIndex[0]].columns[columnConfigIndex[1]] = columnDefinition;
        }

        // Update Column Name
        if (newConfigs[columnConfigIndex[0]].columns[columnConfigIndex[1]].name !== columnDefinition.name) {
          newConfigs[columnConfigIndex[0]].columns[columnConfigIndex[1]].name = columnDefinition.name;
        }
      });
    } else {
      // Creating Entity Group Column
      let columnIndexes = findAllIndex(newConfigs, config => config.id === tableColumn.entityName);
      if (!columnIndexes.length) {
        // Create Group Column Definition
        newConfigs.push(createEntityGroupColumnDefinition(tableColumn));
      }
      columnIndexes = findAllIndex(newConfigs, config => config.id === tableColumn.entityName);
      columnIndex = columnIndexes[columnIndexes.length - 1];

      // Loop through all the columns in field
      tableColumn.columns.forEach(column => {
        const columnConfigIndex = columnIndexes
          .map(colIndex => {
            const subIndex = newConfigs[colIndex].columns.findIndex(config => config.id === column.fieldId);
            return subIndex >= 0 ? [colIndex, subIndex] : undefined;
          })
          .filter(item => !!item)[0];

        const columnDefinition = createEntityFieldColumnDefinition(
          column,
          tableColumn.entityName,
          !((isNewUser && column.isCrmDefault) || createdFieldIds.includes(column.fieldId)),
        );

        // Create Column Config if not exists
        if (!columnConfigIndex) {
          newConfigs[columnIndex].columns.push(columnDefinition);
          return;
        }

        // Change Column Config if column key is different or column name is different
        if (
          !lodash.isEqual(newConfigs[columnConfigIndex[0]].columns[columnConfigIndex[1]].data, columnDefinition.data)
        ) {
          newConfigs[columnConfigIndex[0]].columns[columnConfigIndex[1]] = columnDefinition;
        }

        // Update Column Name
        if (newConfigs[columnConfigIndex[0]].columns[columnConfigIndex[1]].name !== columnDefinition.name) {
          newConfigs[columnConfigIndex[0]].columns[columnConfigIndex[1]].name = columnDefinition.name;
        }
      });
    }
  });

  // Add discovery completeness score and total meetings column
  const columnIndexes = findAllIndex(newConfigs, config => config.id === 'Opportunity');
  if (columnIndexes.length) {
    const index = columnIndexes[columnIndexes.length - 1];
    const subColumns = columnIndexes.map(colIndex => newConfigs[colIndex].columns).flat();
    let isDiscoveryCompletenessScoreExists = false;
    let isTotalMeetingsExists = false;
    let isTotalMeetingsNeedUpdate = false;
    subColumns.forEach(c => {
      if (c.id === 'DiscoveryCompletenessScore') isDiscoveryCompletenessScoreExists = true;
      if (c.id === 'TotalMeeting') {
        isTotalMeetingsExists = true;
        if (c.name === 'Total Meeting') {
          isTotalMeetingsNeedUpdate = true;
        }
      }
    });

    if (!isDiscoveryCompletenessScoreExists) {
      newConfigs[index].columns.push({
        data: {
          entityName: 'Opportunity',
          dataEntryType: 'number',
        },
        id: 'DiscoveryCompletenessScore',
        name: 'Discovery Completion',
        width: 250,
        type: 'ReportCalculatedField',
        isHidden: false,
      });
    }

    if (!isTotalMeetingsExists) {
      newConfigs[index].columns.push({
        data: {
          entityName: 'Opportunity',
          dataEntryType: 'number',
        },
        id: 'TotalMeeting',
        name: 'Total Meetings',
        width: 250,
        type: 'ReportCalculatedField',
        isHidden: false,
      });
    }
    // migrating total meeting -> total meetings
    // good to remove this after a few weeks
    else if (isTotalMeetingsNeedUpdate) {
      newConfigs[index].columns = newConfigs[index].columns.map(c => {
        if (c.id === 'TotalMeeting') {
          return {
            ...c,
            name: 'Total Meetings',
          };
        }
        return c;
      });
    }
  }

  // Remove unused columns
  const requiredFieldIds: string[] = [];
  const requiredColumnIds: string[] = [];
  tableColumns.forEach(tc => {
    if (tc.type === 'field') {
      requiredFieldIds.push(tc.fieldId);
      tc.columns.forEach(c => {
        requiredColumnIds.push(c.id);
      });
    } else {
      tc.columns.forEach(c => {
        requiredFieldIds.push(c.fieldId);
      });
    }
  });

  // Removing group columns
  newConfigs = newConfigs.filter(config => {
    if (config.data?.key === 'createEntityGroupColumnDefinition') return true;
    return requiredFieldIds.includes(config.id);
  });

  // Removing sub columns
  newConfigs = newConfigs
    .map(config => {
      if (config.data?.key === 'createFieldGroupLoadingColumnDefinition') return config;
      if (config.data?.key === 'createEntityGroupColumnDefinition') {
        return {
          ...config,
          columns: config.columns.filter(c => {
            return requiredFieldIds.includes(c.id) || c.id === 'DiscoveryCompletenessScore' || c.id === 'TotalMeeting';
          }),
        };
      }

      return {
        ...config,
        columns: config.columns.filter(c => {
          return requiredColumnIds.includes(c.id) || c.id === 'answer';
        }),
      };
    })
    .filter(config => config.columns.length);

  return newConfigs;
}

function useTableConfig(isLoading: boolean) {
  const [isGeneratedByTableStore, setIsGeneratedByTableStore] = useState<boolean>(false);
  const [tableConfig, setTableConfig] = useState<GroupColumnDefinition[]>([]);
  const { createdFieldIds, createdColumnIds, tableColumns } = useReportingStore();
  const tableStore = useTableStore();

  const updateTableConfig = (config: GroupColumnDefinition[], regenerateColumn = false) => {
    let colConfig = addGroupSequnceNumToColumnDefs(config) as GroupColumnDefinition[];
    if (regenerateColumn) colConfig = generateTableConfig(colConfig, tableColumns, createdFieldIds, createdColumnIds);

    tableStore.setState({
      reportingTableConfigs: colConfig,
    });
    setTableConfig(colConfig);
  };

  useEffect(() => {
    if (isGeneratedByTableStore) return;
    setTableConfig(tableStore.reportingTableConfigs);
    setIsGeneratedByTableStore(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isGeneratedByTableStore || isLoading) return;
    // Generating Table Configs
    const newConfigs = generateTableConfig(tableConfig, tableColumns, createdFieldIds, createdColumnIds);
    updateTableConfig(newConfigs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableColumns, isGeneratedByTableStore, isLoading]);

  return { tableConfig, updateTableConfig };
}

export default useTableConfig;
