import React from 'react';
import { MenuItem } from '@mui/material';

import TrashIcon from 'components/icons/TrashIcon';
import useReportingStore from 'stores/reporting';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { openDeleteConfirmModal } from '../../../BaseModal';

interface Props {
  analyticColumnId: string;
  columnName: string;
  setMenuState: (menuState: 'MENU' | 'FILTER' | 'HIDE') => void;
}

function DeleteReportingAnalyticColumnMenu({ analyticColumnId, columnName, setMenuState }: Props) {
  const reportingStore = useReportingStore();

  return (
    <MenuItem
      onClick={async () => {
        setMenuState('HIDE');
        if (
          await openDeleteConfirmModal(
            `Are you sure you want to delete '${columnName}' column?`,
            'This action cannot be undone and all your data under the column will be lost.',
          )
        )
          await reportingStore.deleteAnalyticTableColumns([analyticColumnId]);
      }}
    >
      <div className="flex items-center text-sm">
        <TrashIcon className="w-4 h-4 mr-2" /> Delete column
        <div className="ml-2">
          <ExclamationTriangleIcon className="flex w-4 h-4 text-yellow-500" />
        </div>
      </div>
    </MenuItem>
  );
}

export default DeleteReportingAnalyticColumnMenu;
