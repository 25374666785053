import { User } from 'types/user';
import httpClient from './http-client';

export interface Response {
  user: User;
}

export default async (): Promise<Response> => {
  const { data } = await httpClient.get<Response>('/me');
  return data;
};
