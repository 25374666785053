/* eslint-disable react/no-unstable-nested-components */

import React, { forwardRef } from 'react';
import moment from 'moment';
import searchItems from 'api/search/search-items';
import useDocumentStore from '../stores/document';
import SelectField, { SelectMenuValueType } from './Form/SelectField';
import OpportunityIcon from './icons/OpportunityIcon';
import AccountIcon from './icons/AccountIcon';
import { ListItem } from 'types/list-item';

interface Props {
  selectedDocumentId: string | null;
  setDocumentId: (documentId: string | null) => void;
  defaultFolder?: ListItem<SelectMenuValueType>;
  placeholder?: string;
  size?: 'small' | 'medium';
}

const FolderSelector = forwardRef<HTMLInputElement, Props>(
  ({ selectedDocumentId, setDocumentId, defaultFolder, placeholder, size }: Props, ref) => {
    return (
      <SelectField
        size={size}
        placeholder={placeholder || 'Select Folder'}
        showClearBtn
        ref={ref}
        value={selectedDocumentId}
        onChange={newV => setDocumentId((newV as string) || null)}
        searchAble
        clearValueLabel="Unlink"
        popoverMinWidth={Math.min(400, document.body.clientWidth)}
        menus={(searchStr?: string) => {
          if (searchStr) {
            return searchItems(searchStr, false, true, true, false).then(rsp =>
              rsp
                .map(item => ({
                  label: item.title,
                  value: item.documentId || '',
                  icon:
                    item.type === 'opportunity' ? (
                      <OpportunityIcon className="min-w-[1rem] w-4 h-4 fill-orange-400 text-orange-400" />
                    ) : (
                      <AccountIcon className="min-w-[1rem] w-4 h-4 text-blue-500" />
                    ),
                }))
                .filter(item => item.value),
            );
          }
          return useDocumentStore
            .getState()
            .entityDocuments.sort((a, b) => {
              return moment(a.accessedAt).unix() - moment(b.accessedAt).unix();
            })
            .map(item => ({
              label: item.name,
              value: item.id,
              icon:
                item.entity.name === 'Opportunity' ? (
                  <OpportunityIcon className="w-4 h-4 fill-orange-400 text-orange-400" />
                ) : (
                  <AccountIcon className="w-4 h-4 text-blue-500" />
                ),
            }));
        }}
        defaultMenu={defaultFolder}
      />
    );
  },
);

FolderSelector.displayName = 'FolderSelector';

FolderSelector.defaultProps = {
  defaultFolder: undefined,
  placeholder: undefined,
  size: 'medium',
};

export default FolderSelector;
