import React from 'react';

interface Props {
  className?: string;
}
function LinkBrokenIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.6 5.6V4M14.4 18.4V20M5.6 9.6H4M18.4 14.4H20M6.33137 6.33137L5.2 5.2M17.6686 17.6686L18.8 18.8M12 16.5255L10.3029 18.2225C9.05327 19.4722 7.02714 19.4722 5.77746 18.2225C4.52778 16.9729 4.52778 14.9467 5.77746 13.6971L7.47452 12M16.5255 12L18.2225 10.3029C19.4722 9.05327 19.4722 7.02714 18.2225 5.77746C16.9729 4.52778 14.9467 4.52778 13.6971 5.77746L12 7.47452"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

LinkBrokenIcon.defaultProps = {
  className: undefined,
};

export default LinkBrokenIcon;
