import { CrmNote } from 'types/document';
import { EntityDocument } from 'types/entity';
import httpClient from '../http-client';

export interface Response {
  crmNote: CrmNote;
  entityDocument: EntityDocument;
}

export default async (crmNoteId: string): Promise<Response> => {
  const { data } = await httpClient.get<Response>(`/crm-notes/${crmNoteId}`);
  return data;
};
