import React from 'react';

interface Props {
  className?: string;
}

function DownArrowIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="6.3"
      height="3.6"
      viewBox="0 0 6.3 3.6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m5.8,3.1L3.2.5.5,3.1" fill="none" stroke="#afb9cc" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

DownArrowIcon.defaultProps = {
  className: undefined,
};

export default DownArrowIcon;
