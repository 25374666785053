import React from 'react';
import { TeamMemberRequestStatus } from '../../types/team-member-request';

interface Props {
  status: TeamMemberRequestStatus;
}

function StatusPill({ status }: Props) {
  switch (status) {
    case 'Pending':
      return (
        <div className="flex items-center w-fit rounded-full px-4 py-0.5 bg-gray-100 text-gray-700 gap-2">
          <div className="w-2 h-2 bg-gray-500 rounded-full" />
          <span>{status}</span>
        </div>
      );
    case 'Assigned':
      return (
        <div className="flex items-center w-fit rounded-full px-4 py-0.5 bg-green-100 text-green-700 gap-2">
          <div className="w-2 h-2 bg-green-500 rounded-full" />
          <span>{status}</span>
        </div>
      );
    default:
      return <div className="w-fit rounded-full px-4 py-0.5 bg-stone-400 text-white">{status}</div>;
  }
}

export default StatusPill;
