/* eslint-disable @typescript-eslint/no-explicit-any */
import { NoteTemplateType } from 'components/NoteEditor/types';
import { Document } from 'types/document';
import httpClient from '../http-client';

export interface CheckAndGetTemplate {
  document: Document;
  templateData: any;
}

export default async (
  documentId: string,
  templateType: NoteTemplateType,
  templateId: string,
): Promise<CheckAndGetTemplate> => {
  const { data } = await httpClient.put<CheckAndGetTemplate>(`/documents/${documentId}/check-and-get-template`, {
    templateType,
    templateId,
  });
  return data;
};
