import React, { useEffect, useRef, useState } from 'react';

import getSalesforceItems, { SalesforceItem } from 'api/salesforce/get-salesforce-items';

import { PicklistType, ReferenceFilterValue } from 'components/Table/types';
import CaretIcon from 'components/icons/CaretIcon';
import Popover from 'components/Popover';
import BaseCheckbox from 'components/Form/BaseCheckbox';

import { FilterProps } from './type';
import Loader from '../../Loader';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';

interface State {
  menus: SalesforceItem[];
  searchValue: string;
  isLoading: boolean;
}

interface Props extends FilterProps<PicklistType, ReferenceFilterValue[]> {
  referenceTo: string;
}

function SalesforceReferenceFilter({ name, type, value, onFilterUpdate, referenceTo }: Props) {
  const [filterType, setFilterType] = useState<PicklistType>(type);
  const [isFilterTypeOpen, setIsFilterTypeOpen] = useState(false);
  const [state, setState] = useState<State>({
    menus: [],
    searchValue: '',
    isLoading: true,
  });

  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    // This can be removed after certain point.
    // This is added as we are chaing the value from string to object.
    if (value && value.length > 0) {
      const temp = value.map(v => {
        if (typeof v === 'string') {
          return {
            value: v,
            label: v,
          };
        }
        return v;
      });
    }
  }, [value]);

  const fetchListOfItems = async () => {
    setState(prevState => ({ ...prevState, isLoading: true }));
    const { salesforceItems } = await getSalesforceItems(referenceTo, state.searchValue);
    setState(prevState => ({ ...prevState, isLoading: false, menus: salesforceItems }));
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      fetchListOfItems();
    }, 500);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.searchValue]);

  const updateFilterType = (newFilterType: PicklistType) => {
    setFilterType(newFilterType);
    if (newFilterType === 'Is empty' || newFilterType === 'Is not empty') {
      onFilterUpdate({
        type: newFilterType,
        value: '',
      });
    } else if (value.length !== 0) {
      onFilterUpdate({
        type: newFilterType,
        value,
      });
    } else {
      onFilterUpdate(undefined);
    }
  };

  const updateFilterValue = (newValue: ReferenceFilterValue) => {
    let newValues = value ? [...value] : [];
    if (!newValues.map(v => v.value).includes(newValue.value)) {
      newValues.push(newValue);
    } else {
      newValues = newValues.filter(v => v.value !== newValue.value);
    }

    if (newValues.length === 0) {
      onFilterUpdate(undefined);
    } else {
      onFilterUpdate({ type: filterType, value: newValues });
    }
  };

  return (
    <div className="px-2 pt-1 pb-2">
      <div className="flex items-center text-xs">
        <div className="text-gray-400">{name}</div>
        <button
          ref={ref}
          type="button"
          className="flex items-center rounded hover:bg-gray-100 px-2 py-2 text-sm"
          onClick={() => setIsFilterTypeOpen(true)}
        >
          <div>{filterType.toLowerCase()}</div>
          <CaretIcon className="w-4 h-4 rotate-180" />
        </button>
        <Popover
          anchorEl={ref.current}
          onClose={() => setIsFilterTypeOpen(false)}
          isOpen={isFilterTypeOpen}
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        >
          <div className="flex flex-col p-1">
            <button
              type="button"
              className="px-2 py-1 text-sm text-left rounded hover:bg-gray-100"
              onClick={() => {
                updateFilterType('Is');
                setIsFilterTypeOpen(false);
              }}
            >
              Is
            </button>
            <button
              type="button"
              className="px-2 py-1 text-sm text-left rounded hover:bg-gray-100"
              onClick={() => {
                updateFilterType('Is not');
                setIsFilterTypeOpen(false);
              }}
            >
              Is not
            </button>
            <button
              type="button"
              className="px-2 py-1 text-sm text-left rounded hover:bg-gray-100"
              onClick={() => {
                updateFilterType('Is empty');
                setIsFilterTypeOpen(false);
              }}
            >
              Is empty
            </button>
            <button
              type="button"
              className="px-2 py-1 text-sm text-left rounded hover:bg-gray-100"
              onClick={() => {
                updateFilterType('Is not empty');
                setIsFilterTypeOpen(false);
              }}
            >
              Is not empty
            </button>
          </div>
        </Popover>
      </div>
      <div className="my-1">
        {!['Is empty', 'Is not empty'].includes(filterType) && (
          <div>
            <div className="w-full border rounded py-1 px-2 flex items-center">
              <MagnifyingGlassIcon className="w-4 h-4 mr-2 text-gray-400" />
              <input
                className="w-full text-sm placeholder:text-sm"
                placeholder="Search"
                value={state.searchValue}
                onChange={e => setState(prevState => ({ ...prevState, searchValue: e.target.value }))}
              />
            </div>
            {state.isLoading ? (
              <div className="w-full flex justify-center items-center">
                <Loader className="w-10 h-10" />
              </div>
            ) : (
              <div className="max-h-96 overflow-y-auto">
                {state.menus.map(menu => {
                  return (
                    <BaseCheckbox
                      key={menu.id}
                      className="!ml-0 w-full"
                      label={menu.name}
                      checked={value.some(v => v.value === menu.id)}
                      onChange={() => {
                        updateFilterValue({
                          value: menu.id,
                          label: menu.name,
                        });
                      }}
                    />
                  );
                })}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default SalesforceReferenceFilter;
