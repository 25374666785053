import React, { useCallback, useRef, useState } from 'react';

// eslint-disable-next-line import/no-cycle
import { FieldManagementFilter } from 'components/FieldManagement';
import SortableField from './SortableField';
import { Field, getEmptyField } from './types';
import openFieldEditModal from './OpenFieldEditModal';
import { EntityName } from 'types/entity';
import { maxBy } from 'lodash';

interface Props {
  className: string;
  entityName: EntityName;
  fields: Field[];
  onFieldsChange: (fields: Field[]) => void;
  fieldFilter: FieldManagementFilter;
  userId: string;
  isAdmin: boolean;
}
function ObjectView({ className, entityName, fields, onFieldsChange, fieldFilter, userId, isAdmin }: Props) {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const addNewField = useCallback(() => {
    openFieldEditModal(
      getEmptyField(entityName, Math.ceil(maxBy(fields, 'order')?.order ?? 0) + 1),
      newField => onFieldsChange([...fields, { ...newField, isNew: true }]),
      true,
      isAdmin,
      fields.filter(f => f.crmField),
    );
  }, [isAdmin, entityName, fields]);

  return (
    <div className={className}>
      <div className="flex justify-between items-center">
        <div className="text-2xl font-semibold px-5">{entityName}</div>
        <div className="flex flex-1 justify-end ml-2">
          <button
            id="field-management-add-new-field-button"
            ref={buttonRef}
            type="button"
            className="rounded px-3 py-1 text-sm text-blue-600 bg-blue-50 hover:bg-blue-100"
            onClick={() => {
              addNewField();
            }}
          >
            Add a new {entityName.toLowerCase()} field
          </button>
        </div>
      </div>
      <div className="mt-3 flex flex-col gap-4">
        {fields.map((field, index) => {
          if (
            field.parentId ||
            !(
              (fieldFilter.isDefault ? field.isDefault : false) ||
              (fieldFilter.createdByMe ? field.createdBy === userId : false) ||
              (fieldFilter.createdByOthers ? field.createdBy !== userId : false)
            )
          ) {
            return null;
          }
          return (
            <SortableField
              key={field.id}
              field={field}
              existingCRMFields={fields.filter(f => f.crmField)}
              onFieldChange={newField => {
                const newFields = [...fields];
                newFields[index] = newField;
                onFieldsChange(newFields);
              }}
              onFieldDelete={() => {
                const newFields = [...fields];
                newFields.splice(index, 1);
                onFieldsChange(newFields);
              }}
              isAdmin={isAdmin}
            />
          );
        })}
      </div>
    </div>
  );
}

export default ObjectView;
