/* eslint-disable react/no-array-index-key */
import React from 'react';
import { ColumnConfig, GroupColumnDefinition } from './types';
import SortFieldButton from './SortFieldMenu/SortFieldButton';
import BaseButton from 'components/BaseButton';
import styles from './FilterFieldMenu.module.css';

interface Props {
  columnConfigs: ColumnConfig[];
  updateColumnConfigs: (columnConfigs: ColumnConfig[], closePopover?: boolean) => void;
}

function SortFieldMenu({ columnConfigs, updateColumnConfigs }: Props) {
  return (
    <div className="flex flex-col">
      <div className={styles.clearFilterBtnWrap}>
        <BaseButton
          className={styles.clearFilterBtn}
          onClick={() => {
            updateColumnConfigs(
              columnConfigs.map(columnConfig => {
                if ('group' in columnConfig) {
                  return {
                    ...columnConfig,
                    columns: columnConfig.columns.map(column => ({
                      ...column,
                      sort: undefined,
                    })),
                  };
                }
                return {
                  ...columnConfig,
                  sort: undefined,
                };
              }),
              true,
            );
          }}
        >
          Clear all sorts
        </BaseButton>
      </div>
      {columnConfigs.map((config, configIndex) => {
        if (!('group' in config)) {
          return (
            <SortFieldButton
              key={config.id + configIndex}
              columnDefinition={config}
              updateColumnDefinition={newColumnDefinition => {
                const newConfigs = [...columnConfigs];
                newConfigs[configIndex] = newColumnDefinition;
                updateColumnConfigs(newConfigs);
              }}
            />
          );
        }
        return (
          <div key={config.id + configIndex}>
            <div className="text-xs text-gray-400 py-1">
              {config.group}
              {config.groupSequence !== undefined && <span>&nbsp;-&nbsp;Group&nbsp;{config.groupSequence + 1}</span>}
            </div>
            <div className="flex flex-col">
              {config.columns.map((col, columnIndex) => (
                <SortFieldButton
                  key={`${config.id}-${col.id}`}
                  columnDefinition={col}
                  updateColumnDefinition={newColumnDefinition => {
                    const newConfigs = [...columnConfigs];
                    (newConfigs[configIndex] as GroupColumnDefinition).columns[columnIndex] = newColumnDefinition;
                    updateColumnConfigs(newConfigs);
                  }}
                  btnClassName="pl-4"
                />
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default SortFieldMenu;
