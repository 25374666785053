/* eslint-disable react/jsx-props-no-spreading, react/no-array-index-key */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import useModalStore from '../../stores/modal';
import SpinLoader from '../icons/SpinLoader';
import { formatFileSize, handleFileUpload } from 'utils/file';
import BaseButton from 'components/BaseButton';
import { CheckIcon, InformationCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import _, { uniqWith } from 'lodash';
import usePopupMessageStore from 'stores/popup-message';
import Tooltip from 'components/Tooltip';
import fileUploadStart from 'api/files/file-upload-start';
import fileUploadComplete from 'api/files/file-upload-complete';
import useDocumentStore from 'stores/document';
import { Document } from 'types/document';
import { openWarningConfirmModal } from 'components/BaseModal';

const MAX_UPLOAD_FILE_SIZE = 10 * 1024 * 1024; // 10MB

interface Props {
  parentDocumentId: string;
  siblingDocuments: Document[];
}

function UploadDocumentModal({ parentDocumentId, siblingDocuments }: Props) {
  const [uploadStatuses, setUploadStatuses] = useState<(boolean | string)[]>([]);
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const isLoading = useMemo(() => uploadStatuses.some(item => item === true), [uploadStatuses]);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'text/plain': ['.txt'],
    },
    multiple: true,
    disabled: isLoading,
  });
  const popupMessageStore = usePopupMessageStore();
  const documentStore = useDocumentStore();

  useEffect(() => {
    setFilesToUpload(prev => {
      const files = uniqWith(
        [...prev, ...acceptedFiles],
        (fileA, fileB) => fileA.name === fileB.name && fileA.size === fileB.size,
      );
      const filteredFiles = files.filter(file => file.size <= MAX_UPLOAD_FILE_SIZE);
      if (filteredFiles.length !== files.length) {
        popupMessageStore.error(`File size should be less than ${formatFileSize(MAX_UPLOAD_FILE_SIZE)}`);
      }
      return filteredFiles;
    });
    setUploadStatuses([]);
  }, [acceptedFiles]);

  const modalStore = useModalStore();

  const onUploadFile = useCallback(
    async (file: File) => {
      // get pre-asigned url to upload
      const presignedData = await fileUploadStart(file.name, file.type);
      //  upload file to s3
      const uploadResult = await handleFileUpload({ file, presignedData });
      // if upload to s3 is okay
      if (uploadResult.ok) {
        // complete file upload
        await fileUploadComplete(presignedData.id);
        let documentFullName = file.name;
        let sameDocumentNameSequence = 0;
        const fileName = documentFullName.substring(0, documentFullName.lastIndexOf('.'));
        const fileExtension = documentFullName.substring(documentFullName.lastIndexOf('.') + 1);
        // eslint-disable-next-line no-loop-func
        while (siblingDocuments.find(doc => doc.docTitle === documentFullName)) {
          sameDocumentNameSequence += 1;
          documentFullName = `${fileName} (${sameDocumentNameSequence + 1}).${fileExtension}`;
        }
        // use file id to create new document
        await documentStore.createDocument(parentDocumentId, presignedData.id, documentFullName);
      } else {
        throw new Error('Failed to upload file to s3!');
      }
    },
    [parentDocumentId, siblingDocuments],
  );

  useEffect(() => {
    // when finished uploading all documents
    if (
      filesToUpload.length &&
      filesToUpload.length === uploadStatuses.length &&
      uploadStatuses.every(item => item === false)
    ) {
      modalStore.close();
      popupMessageStore.success(`Document${filesToUpload.length ? 's' : ''} uploaded successfully`);
    }
  }, [filesToUpload, uploadStatuses]);

  const onUpload = async () => {
    const duplicatedNameFiles = _([
      ...filesToUpload.map(file => file.name),
      ...siblingDocuments.filter(doc => doc.uploadedFile).map(doc => doc.docTitle),
    ])
      .groupBy()
      .pickBy(x => x.length > 1)
      .keys()
      .value();
    if (duplicatedNameFiles.length) {
      const continueUpload = await openWarningConfirmModal(
        'File with same name already exist:',
        <>
          <ul className="pl-4 pb-3">
            {duplicatedNameFiles.map(file => (
              <li key={file}>{file}</li>
            ))}
          </ul>
          <div className="text-lg font-medium">Do you want to continue to upload file?</div>
        </>,
      );
      if (!continueUpload) {
        return;
      }
    }
    setUploadStatuses(filesToUpload.map(() => true));
    filesToUpload.forEach(file => {
      const index = filesToUpload.indexOf(file);
      onUploadFile(file).then(
        () => {
          setUploadStatuses(prev => {
            const statuses = [...prev];
            statuses[index] = false;
            return statuses;
          });
        },
        err => {
          setUploadStatuses(prev => {
            const statuses = [...prev];
            statuses[index] = err.message || 'Failed to upload!';
            return statuses;
          });
        },
      );
    });
  };

  return (
    <div className="w-[36rem] max-w-[100vw]">
      <div className="p-8 pb-4 pt-4 min-h-[14rem] max-h-[80vh]">
        <div className="text-xl font-bold text-gray-700 mb-4 mt-4">Upload Document</div>
        <div
          {...getRootProps({
            className:
              'h-20 flex items-center justify-center rounded-lg border-dashed border-2 border-gray-500 focus:border-blue-500',
          })}
        >
          <input {...getInputProps()} />
          <div className="text-sm text-gray-400 cursor-default px-4">
            Drag and drop some files(.pdf, .txt, .docx) here, or click to select files
          </div>
        </div>
        <div className="flex flex-col gap-1 px-4 pt-4 flex-1">
          {filesToUpload.map((file, index) => (
            <div key={index} className="text-sm text-gray-600 flex items-center w-full h-6">
              <div className="flex items-center flex-1 truncate">
                <span className="truncate">{file.name}</span>
                <span className="pl-2 text-xs text-gray-400 ">{formatFileSize(file.size)}</span>
              </div>
              {typeof uploadStatuses[index] === 'string' && (
                <Tooltip placement="top" title={uploadStatuses[index]}>
                  <InformationCircleIcon className="shrink-0 w-5 h-5 text-red-500 ml-2" />
                </Tooltip>
              )}
              {uploadStatuses[index] === true && (
                <SpinLoader className="animate-spin shrink-0 w-5 h-5 text-orange-500 ml-2" />
              )}
              {uploadStatuses[index] === false && <CheckIcon className="shrink-0 w-5 h-5 ml-2 text-green-500" />}
              {typeof uploadStatuses[index] !== 'boolean' && (
                <BaseButton
                  className="!ml-2"
                  color="error"
                  variant="text"
                  iconBtn
                  onClick={() => setFilesToUpload(prev => prev.filter((_file, i) => i !== index))}
                >
                  <TrashIcon width={20} />
                </BaseButton>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="p-8 pt-2 pb-4 flex flex-row justify-around">
        <button
          onClick={() => modalStore.close()}
          type="button"
          className="px-4 py-2 bg-gray-100 hover:bg-gray-200 text-gray-500 rounded"
          disabled={isLoading}
        >
          Cancel
        </button>
        <button
          onClick={onUpload}
          type="button"
          className="w-24 px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded"
          disabled={isLoading || !filesToUpload.length}
        >
          {isLoading ? <SpinLoader className="w-4 h-4 text-orange-500 animate-spin" /> : <span>Upload</span>}
        </button>
      </div>
    </div>
  );
}

export default UploadDocumentModal;
