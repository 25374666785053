import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Loader from 'components/Loader';
import typoStyles from 'components/typo.module.css';

import { concat } from 'utils/styling';
import adminUseUserStore, { AdminUser } from '../stores/adminUser';
import adminLoginOauth from '../api/admin-login-oauth';

interface State {
  isLoading: boolean;
}

function AdminGoogleOauth() {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const [state, setState] = useState<State>({
    isLoading: true,
  });

  const navigate = useNavigate();

  const adminUserStore = adminUseUserStore();

  useEffect(() => {
    const oAuth = async (codeP: string): Promise<AdminUser> => {
      const { adminUser } = await adminLoginOauth(codeP);
      return adminUser;
    };
    const authorize = async (codeP: string): Promise<void> => {
      try {
        const adminUser = await oAuth(codeP);
        adminUserStore.setState({ adminUser });
        navigate('/admin');
      } catch {
        /* empty */
      }
      setState({ isLoading: false });
    };

    if (code) {
      authorize(code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);
  return (
    <div>
      <div>
        <span className={concat(typoStyles.xl, typoStyles['semi-bold'])}>Google Authorization</span>
        {state.isLoading ? <Loader /> : null}
        <div className="text-lg">Please wait...</div>
      </div>
    </div>
  );
}

export default AdminGoogleOauth;
