import NotEditableIcon from 'components/icons/NotEditableIcon';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { FieldValue, getFieldValueValue } from '../../types/field-value';
import ViewOnlyField from './AnswerView/ViewOnlyField';
import { isBoolField, isTextField, SalesforceAddressField, SalesforceFieldType } from '../../types/salesforce';
import AddressField from './AnswerView/AddressField';
import StringField from './AnswerView/StringField';
import RichTextAreaField from './AnswerView/RichTextAreaField';
import NumberField from './AnswerView/NumberField';
import EmailField from './AnswerView/EmailField';
import BooleanField from './AnswerView/BooleanField';
import DateField from './AnswerView/DateField';
import DatetimeField from './AnswerView/DatetimeField';
import TimeField from './AnswerView/TimeField';
import PicklistField from './AnswerView/PicklistField';
import MultiPicklistField from './AnswerView/MultiPicklistField';
import ReferenceField from './AnswerView/ReferenceField';
import { CrmReferenceValue } from '../../types/crm';
import useFieldValuesStore from '../../stores/field-values';
import useModalStore from '../../stores/modal';
import FieldLengthValidationErrorModal from './AnswerView/FieldLengthValidationErrorModal';

interface Handle {
  updateAnswer: (answer: string) => Promise<void>;
}

interface Props {
  fieldValue: FieldValue;
  onEnter?: () => void;
  onChange?: (fieldValue: FieldValue) => void;
  autoFocus?: boolean;
}

const AnswerView = forwardRef(({ fieldValue, onEnter, onChange, autoFocus }: Props, ref: React.Ref<Handle>) => {
  const { referenceValue } = fieldValue.value;
  const value = getFieldValueValue(fieldValue);
  const [answer, setAnswer] = useState<any>(referenceValue !== null ? referenceValue : value);
  const [isEdited, setIsEdited] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const fieldValueStore = useFieldValuesStore();
  const modalStore = useModalStore();

  // TODO need user field permission for editable
  const isEditable = true;
  const picklistValues = fieldValue.field.crmField ? fieldValue.field.crmField.picklistValues : [];
  const { dataType, dataLength } = fieldValue.field;

  useEffect(() => {
    const { value: v, referenceValue: rv } = fieldValue.value;
    setAnswer(rv !== null ? rv : getFieldValueValue(fieldValue));
  }, [fieldValue.value]);

  const updateFieldValue = async (newValue: any) => {
    setIsUpdating(true);
    const newFieldValue = await fieldValueStore.updateFieldValueValue(fieldValue.id, newValue);
    if (onChange) onChange(newFieldValue);
    setIsUpdating(false);
    setIsEdited(false);
  };

  const updateAnswer = (newAnswer: any) => {
    setIsEdited(true);
    setAnswer(newAnswer);
  };

  const revertAnswer = () => {
    setAnswer(referenceValue !== null ? referenceValue : value);
    setIsEdited(false);
  };

  useImperativeHandle(ref, () => {
    return {
      updateAnswer: async (suggestion: string) => {
        const valueLength = answer ? (answer as string).length : 0;

        // We are adding 2 extra characters on validation because we add 2 new line ending when we merge two answers
        // Check below answerFinal constants
        if (dataLength && suggestion.length + valueLength + 2 > dataLength) {
          modalStore.setState({
            isOpen: true,
            element: (
              <FieldLengthValidationErrorModal
                answer={answer}
                suggestion={suggestion}
                limit={dataLength}
                onConfirm={updateFieldValue}
              />
            ),
          });
          return;
        }
        let answerFinal = suggestion;
        if (isTextField(fieldValue.field.dataType)) {
          answerFinal = answer ? `${answer}\n\n${suggestion}` : suggestion;
        }
        if (isBoolField(fieldValue.field.dataType)) {
          setAnswer(answerFinal === 'True');
          await updateFieldValue(answerFinal === 'True');
        } else {
          setAnswer(answerFinal);
          await updateFieldValue(answerFinal);
        }
      },
    };
  });

  let inputElement: JSX.Element;
  let padding = 'p-2';
  switch (dataType) {
    case SalesforceFieldType.ADDRESS:
      inputElement = <AddressField answer={answer as SalesforceAddressField} />;
      break;
    case SalesforceFieldType.STRING:
    case SalesforceFieldType.URL:
    case SalesforceFieldType.PHONE:
    case SalesforceFieldType.TEXTAREA:
      // make the textarea more clickable
      padding = '';
      inputElement = (
        <StringField
          isEditable={isEditable}
          isUpdating={isUpdating}
          maxLimit={dataLength}
          isLineEndingAllowed={dataType === SalesforceFieldType.TEXTAREA}
          isEdited={isEdited}
          answer={(answer || '') as string}
          onAnswerChange={updateAnswer}
          onCancel={revertAnswer}
          onSubmit={() => updateFieldValue(answer)}
          autoFocus={autoFocus}
        />
      );
      break;
    case SalesforceFieldType.RICHTEXTAREA:
      inputElement = (
        <RichTextAreaField
          isEditable={isEditable}
          isUpdating={isUpdating}
          isEdited={isEdited}
          answer={(answer || '') as string}
          onAnswerChange={updateAnswer}
          onCancel={revertAnswer}
          onSubmit={() => updateFieldValue(answer)}
          autoFocus={autoFocus}
        />
      );
      break;
    case SalesforceFieldType.INT:
    case SalesforceFieldType.DOUBLE:
    case SalesforceFieldType.PERCENT:
    case SalesforceFieldType.CURRENCY:
      padding = '';
      inputElement = (
        <NumberField
          isEditable={isEditable}
          isUpdating={isUpdating}
          isEdited={isEdited}
          format={dataType}
          answer={(answer || 0) as number}
          onAnswerChange={updateAnswer}
          onCancel={revertAnswer}
          onSubmit={() => updateFieldValue(answer)}
          autoFocus={autoFocus}
        />
      );
      break;
    case SalesforceFieldType.EMAIL:
      inputElement = (
        <EmailField
          isEditable={isEditable}
          isUpdating={isUpdating}
          isEdited={isEdited}
          answer={(answer || '') as string}
          onAnswerChange={updateAnswer}
          onCancel={revertAnswer}
          onSubmit={() => updateFieldValue(answer)}
        />
      );
      break;
    case SalesforceFieldType.BOOLEAN:
      inputElement = (
        <BooleanField
          isEditable={isEditable}
          isUpdating={isUpdating}
          value={answer as boolean}
          onChange={() => updateFieldValue(!value)}
        />
      );
      break;
    case SalesforceFieldType.DATE:
      inputElement = (
        <DateField
          isEditable={isEditable}
          isUpdating={isUpdating}
          value={answer as string}
          onChange={updateFieldValue}
        />
      );
      break;

    case SalesforceFieldType.DATETIME:
      inputElement = (
        <DatetimeField
          isEditable={isEditable}
          isUpdating={isUpdating}
          value={answer as string}
          onChange={updateFieldValue}
        />
      );
      break;
    case SalesforceFieldType.TIME:
      inputElement = (
        <TimeField
          isEditable={isEditable}
          isUpdating={isUpdating}
          value={answer as string}
          onChange={updateFieldValue}
        />
      );
      break;

    case SalesforceFieldType.PICKLIST:
      inputElement = (
        <PicklistField
          isEditable={isEditable}
          isUpdating={isUpdating}
          value={answer as string}
          picklistValues={picklistValues}
          onChange={updateFieldValue}
        />
      );
      break;
    case SalesforceFieldType.MULTI_PICKLIST:
      inputElement = (
        <MultiPicklistField
          isEditable={isEditable}
          isUpdating={isUpdating}
          value={answer as string}
          picklistValues={picklistValues}
          onChange={updateFieldValue}
        />
      );
      break;
    case SalesforceFieldType.REFERENCE:
      inputElement = (
        <ReferenceField
          isEditable={isEditable}
          isUpdating={isUpdating}
          crmReferenceValue={answer as CrmReferenceValue}
          onChange={updateFieldValue}
        />
      );
      break;
    default:
      inputElement = <ViewOnlyField value={fieldValue.value.value} type={fieldValue.field.dataType} />;
  }

  return (
    <div
      className={`flex items-center w-full h-full rounded bg-slate-100 text-sm text-gray-500 placeholder:text-gray-400 group ${padding}`}
      data-heap-redact-text
    >
      <div className="w-full h-full p-0.5">{inputElement}</div>
      {!isEditable && (
        <div className="group-hover:block hidden">
          <NotEditableIcon className="w-4 h-4" />
        </div>
      )}
    </div>
  );
});

AnswerView.displayName = 'AnswerView';
AnswerView.defaultProps = {
  onEnter: undefined,
  onChange: undefined,
  autoFocus: false,
};

export default AnswerView;
