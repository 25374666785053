/* eslint-disable no-return-assign */
/* eslint-disable no-plusplus */
/* eslint-disable no-return-assign, import/no-cycle */
import { BasePoint, Editor, Path, Point, Range, Transforms, Location, Node, Text, NodeEntry, Element } from 'slate';
import { ReactEditor } from 'slate-react';
import { cloneDeep, isArray, isEmpty, keys, sortBy, uniq } from 'lodash';
import { v4 } from 'uuid';
import useUserStore from 'stores/user';
import {
  BlockElement,
  BlockType,
  BlockTypeAll,
  BlockTypeInline,
  COMMENTABLE_VOID_BLOCKS,
  CommentRecords,
  CustomElement,
  CustomText,
  DiscoveryQuestionElement,
  LinkElement,
  MentionElement,
  TableCellElement,
  TableDialogElement,
  TableElement,
  TableRowElement,
} from './types';
import { createNode } from './utils';
import { openCommentPopover } from './CustomElement/Comment/CommentPopover';
import { DEFAULT_TABLE_CELL_WIDTH } from './Elements/Table/TableCellElement';

// extend class method
const NoteEditor = {
  ...Editor,
  // define helper functions here
  // superpanel <sp> name spaced helper function
  sp_isSelectAll: (editor: Editor) => {
    if (editor.selection) {
      const [begin, end] = Editor.edges(editor, []);
      if (
        Range.equals({ anchor: begin, focus: end }, editor.selection) ||
        Range.equals({ anchor: end, focus: begin }, editor.selection)
      ) {
        return true;
      }
    }
    return false;
  },
  sp_isRangeCollapsed: (editor: Editor) => {
    if (editor.selection) {
      return Range.isCollapsed(editor.selection);
    }
    return false;
  },
  sp_isEditorEmpty: (editor: Editor) => {
    return (
      editor.children.length === 0 ||
      (editor.children.length === 1 &&
        NoteEditor.sp_isEmpty(editor, editor.children[0]) &&
        editor.children[0].type === BlockType.Paragraph)
    );
  },
  sp_checkBlockTypesByBlockIdx: (editor: Editor, types: BlockTypeAll[], idx: number): boolean => {
    return types.includes((editor.children[idx] as CustomElement).type);
  },
  sp_checkBlockType: (editor: Editor, type: BlockType): boolean => {
    if (editor.selection) {
      return (editor.children[editor.selection.anchor.path[0]] as CustomElement).type === type;
    }
    return false;
  },
  sp_checkBlockTypes: (editor: Editor, types: BlockTypeAll[]): boolean => {
    if (editor.selection) {
      return types.includes((editor.children[editor.selection.anchor.path[0]] as CustomElement).type);
    }
    return false;
  },
  // sp_checkElementType: (editor: Editor, type: ElementType): boolean => {
  //   if (editor.selection) {
  //     return (editor.children[editor.selection.anchor.path] as CustomElement).type === type;
  //   }
  //   return false;
  // },
  sp_checkBlockTypeByBlockIdx: (editor: Editor, type: BlockType, idx: number): boolean => {
    if (editor.selection) {
      return (editor.children[idx] as CustomElement).type === type;
    }
    return false;
  },
  sp_checkIfTableIsInSelection: (editor: Editor): boolean => {
    if (editor.selection) {
      const [start, end] = Editor.edges(editor, editor.selection);
      const startBlock = editor.children[start.path[0]];
      const endBlock = editor.children[end.path[0]];
      if (startBlock.type === BlockType.Table || endBlock.type === BlockType.Table) {
        return true;
      }
    }
    return false;
  },
  sp_isEmpty: (editor: Editor, element: CustomElement): boolean => {
    if (Editor.isVoid(editor, element)) {
      return false;
    }
    if (Element.isElement(element) && isArray(element.children)) {
      return element.children.every(child => Text.isText(child) && !child.text);
    }
    return Editor.isEmpty(editor, element);
  },
  sp_indentLevel: (editor: Editor): number | undefined => {
    if (editor.selection) {
      const { indentLevel } = editor.children[editor.selection.anchor.path[0]] as CustomElement;
      return indentLevel;
    }
    return undefined;
  },
  sp_cursorPosition: (editor: Editor) => {
    if (editor.selection) {
      if (Range.isCollapsed(editor.selection)) {
        if (Point.equals(Editor.start(editor, [editor.selection.anchor.path[0]]), editor.selection.anchor)) {
          return 'start';
        }
        if (Point.equals(Editor.end(editor, [editor.selection.anchor.path[0]]), editor.selection.anchor)) {
          return 'end';
        }
        return 'middle';
      }
      return undefined;
    }
    return false;
  },
  sp_selectionEndPosition: (editor: Editor) => {
    if (editor.selection) {
      if (Range.isCollapsed(editor.selection)) {
        return editor.selection.anchor;
      }
      if (Range.isBackward(editor.selection)) {
        return editor.selection.anchor;
      }
      return editor.selection.focus;
    }
    return undefined;
  },
  sp_blockEndPosition: (editor: Editor, idx: number) => {
    return Editor.end(editor, [idx]);
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sp_NodeEndPosition: (editor: Editor, at: any) => {
    return Editor.end(editor, at);
  },
  sp_unhangRange: (editor: Editor) => {
    // use default slate unhang first
    if (editor.selection) {
      const rangeProcessed = NoteEditor.unhangRange(editor, editor.selection);
      const newRange = { focus: rangeProcessed.focus, anchor: rangeProcessed.anchor };
      // continue check if the range is still hanging
      if (!Range.isCollapsed(rangeProcessed)) {
        // if the range hangs multiple elements
        if (rangeProcessed.anchor.path[0] !== rangeProcessed.focus.path[0]) {
          if (Range.isBackward(rangeProcessed)) {
            // if the focus position is the last position of the focus element
            // TODO may be we can just use Editor.isEng, Edigor.isStart
            const lastPositionOfFocusElement = Editor.end(editor, [rangeProcessed.focus.path[0]]);
            if (Range.isCollapsed({ anchor: lastPositionOfFocusElement, focus: rangeProcessed.focus })) {
              // move the focus to the beginning of the next element
              newRange.focus = NoteEditor.start(editor, [rangeProcessed.focus.path[0] + 1]);
            }
            // if the range still hangs multiple elements
            if (rangeProcessed.anchor.path[0] > rangeProcessed.focus.path[0]) {
              // if the anchor position is the first position of the anchor element
              const firstPositionOfFocusElement = NoteEditor.start(editor, [rangeProcessed.anchor.path[0]]);
              if (Range.isCollapsed({ anchor: firstPositionOfFocusElement, focus: rangeProcessed.anchor })) {
                // move the anchor to the end of the last element
                newRange.anchor = NoteEditor.end(editor, [rangeProcessed.anchor.path[0] - 1]);
              }
            }
          } else {
            // if the anchor position is the last position of the anchor element
            const lastPositionOfFocusElement = NoteEditor.end(editor, [rangeProcessed.anchor.path[0]]);
            if (Range.isCollapsed({ anchor: lastPositionOfFocusElement, focus: rangeProcessed.anchor })) {
              // move the anchor to the beginning of the next element
              newRange.anchor = NoteEditor.start(editor, [rangeProcessed.anchor.path[0] + 1]);
            }
            // if the range still hangs multiple elements
            if (rangeProcessed.focus.path[0] > rangeProcessed.anchor.path[0]) {
              // if the focus position is the first position of the focus element
              const firstPositionOfFocusElement = NoteEditor.start(editor, [rangeProcessed.focus.path[0]]);
              if (Range.isCollapsed({ anchor: firstPositionOfFocusElement, focus: rangeProcessed.focus })) {
                // move the focus to the end of the last element
                newRange.focus = NoteEditor.end(editor, [rangeProcessed.focus.path[0] - 1]);
              }
            }
          }
        }
      }
      return newRange;
    }
    return editor.selection;
  },
  sp_pointEquals: (pt1: Point | undefined, pt2: Point | undefined) => {
    if (!(pt1 && pt2)) {
      return false;
    }
    return Point.equals(pt1, pt2);
  },
  sp_pathEquals: (p1: Path | undefined, p2: Path | undefined) => {
    if (!(p1 && p2)) {
      return false;
    }
    return Path.equals(p1, p2);
  },
  // TODO the place that calls this method may need to unhange the selection first
  sp_isSelectionSpanMultipleBlocks: (editor: Editor) => {
    const selectionUnhanged = NoteEditor.sp_unhangRange(editor);
    if (!selectionUnhanged) {
      return false;
    }
    if (selectionUnhanged && Range.isCollapsed(selectionUnhanged)) {
      return false;
    }
    const [pt1, pt2] = Editor.edges(editor, selectionUnhanged);
    if (pt1.path[0] !== pt2.path[0]) {
      return true;
    }
    return false;
  },
  sp_isSelectionSpanMultipleTableCells: (editor: Editor) => {
    const selectionUnhanged = NoteEditor.sp_unhangRange(editor);
    if (!selectionUnhanged) {
      return false;
    }
    if (selectionUnhanged && Range.isCollapsed(selectionUnhanged)) {
      return false;
    }
    const [pt1, pt2] = Editor.edges(editor, selectionUnhanged);
    if (pt1.path[0] !== pt2.path[0] || pt1.path[1] !== pt2.path[1] || pt1.path[2] !== pt2.path[2]) {
      return true;
    }
    return false;
  },
  sp_isLastBlockEmpty: (editor: Editor) => {
    // check is the last block a paragraph block and is empty
    try {
      if (editor.children.at(-1)) {
        if ((editor.children.at(-1) as CustomElement).type === BlockType.Paragraph) {
          if (Editor.isEmpty(editor, editor.children.at(-1) as CustomElement)) {
            return true;
          }
        }
        return false;
      }
      return null;
    } catch (error) {
      return null;
    }
  },
  sp_addEmptyBlock: (editor: Editor, anchorElement: CustomElement) => {
    const node: CustomElement = createNode(BlockType.Paragraph);
    const path = ReactEditor.findPath(editor, anchorElement);
    path[0] += 1;
    Transforms.insertNodes(editor, node, { at: path });
  },
  sp_addNewBlockIfCurrentNodeNotEmpty: (editor: Editor, anchorElement: CustomElement) => {
    const path = ReactEditor.findPath(editor, anchorElement);
    let rootPath = path[0];
    // insert new block when current is not empty paragraph
    if (!(anchorElement.type === BlockType.Paragraph && NoteEditor.sp_isEmpty(editor, anchorElement))) {
      NoteEditor.sp_addEmptyBlock(editor, anchorElement);
      rootPath += 1;
    }
    Transforms.select(editor, [rootPath]);
  },
  sp_focusEditor: (editor: Editor) => {
    ReactEditor.focus(editor);
  },
  sp_selectLastBlock: (editor: Editor) => {
    Transforms.select(editor, {
      anchor: {
        path: [editor.children.length - 1, 0],
        offset: 0,
      },
      focus: {
        path: [editor.children.length - 1, 0],
        offset: 0,
      },
    });
  },
  sp_getElementPath: (editor: Editor, element: CustomElement): Path => {
    return ReactEditor.findPath(editor, element);
  },
  sp_removeLinkBeingEditedBy: (editor: Editor, element: LinkElement): void => {
    const path = ReactEditor.findPath(editor, element);
    // !!!Note: this actually removes the field!!! https://github.com/ianstormtaylor/slate/issues/4836
    Transforms.setNodes(editor, { beingEditedBy: null }, { at: path });
  },
  sp_addBeingEditedBy: (editor: Editor, element: LinkElement, beingEditedBy: string): void => {
    const path = ReactEditor.findPath(editor, element);
    Transforms.setNodes(editor, { beingEditedBy }, { at: path });
  },
  sp_isAtTheEndOfLinkElement: (editor: Editor, point: BasePoint): { isAt: boolean; outside: boolean } => {
    // check if position is at the end of a Link element
    // we need to check 2 cases:
    // 1. this checks if the current point is at the end of a Link element
    const pathParent = [...point.path];
    pathParent.pop();
    const nodeEntry = Editor.node(editor, pathParent);
    const node = nodeEntry[0];
    const path = nodeEntry[1];
    if ('type' in node) {
      if (node.type === BlockTypeInline.Link) {
        const endPosition = NoteEditor.sp_NodeEndPosition(editor, path);
        // select is at the end of a Link element
        if (point.offset === endPosition.offset) {
          if (JSON.stringify(point.path) === JSON.stringify(endPosition.path)) {
            return { isAt: true, outside: false };
          }
        }
      }
    }
    // 2. this checks if the point before the current point is at the end of a Link element
    const pointBefore = Editor.before(editor, point, { distance: 1, unit: 'offset' });
    if (pointBefore) {
      const pathParentBefore = [...pointBefore.path];
      pathParentBefore.pop();
      const nodeEntryBefore = Editor.node(editor, pathParentBefore);
      const nodeBefore = nodeEntryBefore[0];
      const pathBefore = nodeEntryBefore[1];
      if ('type' in nodeBefore) {
        if (nodeBefore.type === BlockTypeInline.Link) {
          const endPosition = NoteEditor.sp_NodeEndPosition(editor, pathBefore);
          // select is at the end of a Link element
          if (pointBefore.offset === endPosition.offset) {
            if (JSON.stringify(pointBefore.path) === JSON.stringify(endPosition.path)) {
              return { isAt: true, outside: true };
            }
          }
        }
      }
    }
    return { isAt: false, outside: false };
  },
  sp_isPointAfterEmpty: (editor: Editor, location: Location): boolean => {
    const pointAfter = Editor.after(editor, location, { distance: 1, unit: 'offset' });
    if (pointAfter) {
      const text = Editor.string(editor, pointAfter.path);
      if (text.length > 0) {
        return false;
      }
    }
    return true;
  },
  sp_removeDiscoveryQuestionNote: (editor: Editor, fieldId: string): void => {
    // if the note is the only child, we need to add an empty paragraph block
    if (editor.children.length === 1) {
      const node: CustomElement = createNode(BlockType.Paragraph);
      Transforms.insertNodes(editor, node, { at: [1] });
    }
    Transforms.removeNodes(editor, {
      at: [],
      match: node =>
        NoteEditor.isBlock(editor, node as CustomElement) &&
        (node as DiscoveryQuestionElement).discoveryQuestionId === fieldId,
    });
  },
  sp_deleteNode: (editor: Editor, path: Path): void => {
    // if deleting the last note and it is disco q
    if (editor.children.length === 1) {
      Transforms.removeNodes(editor, { at: path, hanging: false });
      const node: CustomElement = createNode(BlockType.Paragraph);
      Transforms.insertNodes(editor, node, { at: path });
    } else {
      Transforms.removeNodes(editor, { at: path });
    }
  },
  sp_getQuestionIds: (editor: Editor): string[] => {
    const usedFieldIds: string[] = [];
    editor.children.forEach((child: CustomElement) => {
      if (child.type === BlockType.DiscoveryQuestion) {
        const discoveryQuestionElement = child as DiscoveryQuestionElement;
        if (discoveryQuestionElement.discoveryQuestionId) {
          usedFieldIds.push(discoveryQuestionElement.discoveryQuestionId);
        }
      }
    });
    return usedFieldIds;
  },
  // get table cells which have same row index with provided cell path
  sp_table_GetColCells: (editor: Editor, cellPath: Path): TableCellElement[] => {
    const rowIndex = cellPath[cellPath.length - 1];
    const [tableNode] = NoteEditor.node(editor, cellPath.slice(0, -2));
    return (tableNode as TableElement).children.map((child: TableRowElement) => child.children[rowIndex]);
  },
  // get table cells path which have same row index with provided cell path
  sp_table_GetColCellPaths: (editor: Editor, cellPath: Path): Path[] => {
    const rowIndex = cellPath[cellPath.length - 1];
    const [tableNode, tablePath] = NoteEditor.node(editor, cellPath.slice(0, -2));
    return (tableNode as TableElement).children.map((_, index) => [...tablePath, index, rowIndex]);
  },
  // get parent path by type
  getParentPathByType(editor: Editor, path: Path, type: BlockTypeAll): Path | null {
    if (!path) return null;
    const len = path.length;
    // eslint-disable-next-line no-plusplus
    for (let i = len - 1; i >= 0; i--) {
      const node = Node.has(editor, path.slice(0, i)) ? (Node.get(editor, path.slice(0, i)) as CustomElement) : null;
      if (node && node.type === type) {
        return path.slice(0, i);
      }
    }
    return null;
  },
  getParentPathByTypes(editor: Editor, path: Path, types: string[]): Path | null {
    const len = path.length;
    // eslint-disable-next-line no-plusplus
    for (let i = len - 1; i >= 0; i--) {
      const node: any = Node.has(editor, path.slice(0, i)) && Node.get(editor, path.slice(0, i));
      if (node && types.includes(node.type)) {
        return path.slice(0, i);
      }
    }
    return null;
  },
  isPathDescendantOfType(editor: Editor, path: Path, parentType: BlockTypeAll): boolean {
    const parentPath = NoteEditor.getParentPathByType(editor, path, parentType);
    return !!parentPath;
  },
  isPathDescendantOfTable(editor: Editor, path: Path): boolean {
    return NoteEditor.isPathDescendantOfType(editor, path, BlockType.Table);
  },
  async addNewCommentForCommentInitElements(editor: Editor, userId: string) {
    const { selection } = editor;
    if (userId && selection) {
      const commentId = v4();
      const rsp = await openCommentPopover(
        editor,
        {
          [commentId]: [
            {
              userId,
              createdDate: '',
              updatedDate: '',
              content: [{ text: '' }],
            },
          ],
        },
        true,
      );
      const newComments = typeof rsp === 'object' ? rsp : undefined;
      const nodeEntries = NoteEditor.updateCommentInLeaf(editor, '', newComments ? commentId : '');
      const rootPaths = sortBy(
        uniq(nodeEntries.map(nodeEntry => nodeEntry[1][0]).filter(rootNodePath => typeof rootNodePath === 'number')),
      );
      for (
        let index = Math.min(selection.anchor.path[0], selection.focus.path[0]);
        index <= Math.max(selection.anchor.path[0], selection.focus.path[0]);
        index++
      ) {
        if (!rootPaths.includes(index)) {
          rootPaths.push(index);
        }
      }
      if (newComments) {
        rootPaths.forEach(rootPath => {
          const node = Node.has(editor, [rootPath]) && Node.get(editor, [rootPath]);
          if (node) {
            const rootNode = node as BlockElement;
            const comments = cloneDeep(rootNode.comments || {});
            keys(newComments).forEach(id => (comments[id] = newComments[id]));
            Transforms.setNodes(
              editor,
              {
                comments,
                commentInit: undefined,
              },
              { at: [rootPath] },
            );
          }
        });
      } else {
        rootPaths.forEach(rootPath => {
          Transforms.setNodes(
            editor,
            {
              commentInit: undefined,
            },
            { at: [rootPath] },
          );
        });
      }
    }
  },
  applyNewComments(editor: Editor, newComments: CommentRecords) {
    editor.children.forEach((node, index) => {
      const rootNode = node as BlockElement;
      const isCommentableBlock = COMMENTABLE_VOID_BLOCKS.includes(rootNode.type);
      if (rootNode.comments && keys(rootNode.comments).some(id => newComments[id])) {
        const comments = cloneDeep(rootNode.comments);
        keys(comments).forEach(id => {
          if (isCommentableBlock || newComments[id]) {
            if (newComments[id]?.length) {
              comments[id] = newComments[id];
            } else {
              delete comments[id];
            }
          }
        });
        Transforms.setNodes(
          editor,
          {
            comments: isEmpty(comments) ? undefined : comments,
          },
          { at: [index] },
        );
      }
    });
  },
  // if delete, commentId is existing id, newCommentId is empty
  // if add, commentId is empty and newCommentId is new id
  updateCommentInLeaf(editor: Editor, commentId: string, newCommentId: string): NodeEntry[] {
    const mentionNodeInfos = NoteEditor.nodes(editor, {
      at: [],
      match: n => {
        return Text.isText(n) && (commentId ? !!n.comment?.includes(commentId) : !!n.commentInit);
      },
      mode: 'lowest',
    });
    const nodes: NodeEntry[] = [];
    let nodeInfo = mentionNodeInfos.next();
    while (!nodeInfo.done) {
      if (nodeInfo.value) {
        nodes.push(nodeInfo.value);
        const node = nodeInfo.value[0] as CustomText;
        const newComment = commentId
          ? (node.comment ?? '').replace(commentId, newCommentId).trim()
          : `${node.comment ?? ''} ${newCommentId}`.trim();
        Transforms.setNodes(
          editor,
          { comment: newComment || undefined, commentInit: undefined },
          { at: nodeInfo.value[1] },
        );
      }
      nodeInfo = mentionNodeInfos.next();
    }
    return nodes;
  },
  addNewMentionAfterAt(editor: Editor) {
    const { selection } = editor;
    if (selection && Range.isCollapsed(selection)) {
      const mention: MentionElement = {
        type: BlockTypeInline.Mention,
        mentionUserId: '',
        mentionId: v4(),
        beingEditedBy: useUserStore.getState().user?.id,
        children: [{ text: '' }],
      };
      Transforms.insertNodes(editor, [mention, { text: '' }]);
    }
  },
  createNewTable(editor: Editor, tableDialogId: string, rows: number, columns: number) {
    if (rows > 0 && columns > 0) {
      const tableRows = [];
      const cellWidth = DEFAULT_TABLE_CELL_WIDTH;
      for (let i = 0; i < rows; i += 1) {
        const tableCells = [];
        for (let j = 0; j < columns; j += 1) {
          tableCells.push({
            type: BlockType.TableCell,
            children: [{ text: '' }],
            width: cellWidth,
          });
        }
        tableRows.push({
          type: BlockType.TableRow,
          children: tableCells,
        });
      }
      const tableElement: TableElement = {
        type: BlockType.Table,
        children: tableRows,
      } as TableElement;
      Transforms.insertNodes(editor, tableElement);
    }

    // cleanup table dialog element
    const tableDialogElement = editor.children.find((x: TableDialogElement) => x && x.tableDialogId === tableDialogId);
    if (tableDialogElement) {
      Transforms.delete(editor, { at: ReactEditor.findPath(editor, tableDialogElement) });
    }
  },
};

export default NoteEditor;
