/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { Member } from '../../types/member';
import FormField from '../../components/Form/FormField';
import InputField from '../../components/Form/InputField';
import { ControlledFormProps } from '../../types/form';
import { Controller } from 'react-hook-form';
import SelectField from 'components/Form/SelectField';
import { Checkbox } from '@mui/material';

function EditMemberForm({ control, errors, register }: ControlledFormProps<Member>) {
  return (
    <>
      <FormField
        className="mb-3"
        label="First Name"
        labelClassName="w-32"
        error={errors.firstName ? errors.firstName.message || 'First Name is required' : ''}
      >
        <InputField disabled {...register('firstName')} placeholder="Enter First Name" />
      </FormField>
      <FormField
        className="mb-3"
        label="Last Name"
        labelClassName="w-32"
        error={errors.lastName ? errors.lastName.message || 'Last Name is required' : ''}
      >
        <InputField disabled {...register('lastName')} placeholder="Enter Last Name" />
      </FormField>
      <FormField
        className="mb-3"
        label="Email"
        labelClassName="w-32"
        error={errors.email ? errors.email.message || 'Email is required' : ''}
      >
        <InputField disabled {...register('email')} placeholder="Email Address" />
      </FormField>
      <FormField className="mb-3" label="Role" labelClassName="w-32">
        <Controller
          name="role"
          control={control}
          render={({ field }) => (
            <SelectField
              placeholder="Select Role"
              ref={field.ref}
              value={field.value}
              onChange={field.onChange}
              menus={[
                { value: 'Sales Engineer Manager', label: 'Sales Engineer Manager' },
                { value: 'Sales Engineer', label: 'Sales Engineer' },
                { value: 'Account Executive', label: 'Account Executive' },
                { value: 'Other', label: 'Other' },
              ]}
            />
          )}
        />
      </FormField>
      <FormField className="mb-3" label="Admin" labelClassName="w-32">
        <Controller
          name="isAdmin"
          control={control}
          render={({ field }) => <Checkbox size="small" checked={field.value} onChange={field.onChange} />}
        />
      </FormField>
    </>
  );
}

export default EditMemberForm;
