import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import SpinLoader from 'components/icons/SpinLoader';
import isHotkey from 'is-hotkey';

interface Props {
  isEditable: boolean;
  isUpdating: boolean;
  maxLimit: number | null;
  isLineEndingAllowed: boolean;
  isEdited: boolean;
  answer: string;
  onAnswerChange: (newAnswer: string) => void;
  onCancel: () => void;
  onSubmit: () => Promise<void>;
  autoFocus?: boolean;
}

function StringField({
  isEditable,
  isUpdating,
  maxLimit,
  isLineEndingAllowed,
  isEdited,
  answer,
  onAnswerChange,
  onCancel,
  onSubmit,
  autoFocus,
}: Props) {
  const updateNoLineEnding = (data: string) => {
    onAnswerChange(data.replace(/(?:\r\n|\r|\n)/g, ''));
  };

  const updateWithLineEnding = (data: string) => {
    onAnswerChange(data);
  };

  return (
    <>
      <TextareaAutosize
        className="resize-none p-2 overflow-y-hidden bg-transparent w-full h-full text-inherit align-middle text-sm"
        value={answer}
        onChange={e => {
          if (!isLineEndingAllowed) {
            updateNoLineEnding(e.target.value);
          } else {
            updateWithLineEnding(e.target.value);
          }
        }}
        onCopy={e => e.stopPropagation()}
        onPaste={e => e.stopPropagation()}
        onKeyDown={e => {
          if (e.key === 'Enter' && !e.shiftKey && isEdited) {
            e.preventDefault();
            onSubmit();
          }
          if (isHotkey('mod+a', e)) {
            e.stopPropagation();
          }
        }}
        maxLength={maxLimit || undefined}
        disabled={!isEditable || isUpdating}
        placeholder="Please type..."
        autoFocus={autoFocus}
        onFocus={e => {
          e.target.selectionStart = answer?.length ?? 0;
          e.target.selectionEnd = answer?.length ?? 0;
        }}
      />
      {isEditable && (!!maxLimit || isUpdating || isEdited) && (
        <div className="w-full flex justify-between items-center mt-1">
          <div className="flex items-center gap-1 text-xs">
            {maxLimit && (
              <span className="text-gray-400">
                {answer?.length || 0}/{maxLimit}
              </span>
            )}
            {isUpdating && (
              <>
                <SpinLoader className="w-5 h-5 p-1 animate-spin" />
                <span>Updating...</span>
              </>
            )}
          </div>
          {isEdited && (
            <div className="flex items-center gap-1">
              <button className="text-white" type="button" onClick={onCancel}>
                <XMarkIcon className="w-5 h-5 p-0.5 rounded-full bg-gray-400" />
              </button>
              <button className="text-white" type="button" disabled={!isEdited || isUpdating} onClick={onSubmit}>
                {isUpdating ? (
                  <SpinLoader className="w-5 h-5 p-1 rounded-full bg-blue-500 animate-spin" />
                ) : (
                  <CheckIcon className={`w-5 h-5 p-0.5 rounded-full ${!isEdited ? 'bg-gray-400' : 'bg-blue-500'}`} />
                )}
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
}

StringField.defaultProps = {
  autoFocus: false,
};

export default StringField;
