/* eslint-disable no-nested-ternary, consistent-return, jsx-a11y/no-static-element-interactions, import/no-cycle, no-param-reassign */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useFocused, useSlateSelection } from 'slate-react';
import { LinkIcon } from '@heroicons/react/24/outline';
import CodeIcon from 'components/NoteEditor/icons/CodeIcon';
import { isLinkActive, wrapLink } from 'components/NoteEditor/EditorContext/withInlines';
import { Editor, Node, Path, Point, Range, Text, Transforms } from 'slate';
import useUserStore from 'stores/user';
import stylesButton from './ToolbarButton.module.css';
import styles from './EditorToolbar.module.css';
import { get, keys, throttle } from 'lodash';
import ToolbarButton from './ToolbarButton';
import { BlockType, CustomEditor, CustomElement, CustomText, INDENTABLE_TYPES } from 'components/NoteEditor/types';
import { Portal } from '@mui/material';
import ToolbarTypeSelector from './ToolbarTypeSelector';
import CommentToolbarButton from '../Comment/CommentToolbarButton';
import NoteEditor from 'components/NoteEditor/NoteEditor';

export const FormatTypes = ['code', 'bold', 'italic', 'underline', 'strikethrough', 'link'];

export function EditorLeafFormatButtons({
  editor,
  userId,
  linkButtonDisabled,
  highlightToolBarType,
  onToggleFormat,
  firstElementBorderLeft,
}: {
  editor: Editor;
  userId: string;
  linkButtonDisabled: boolean;
  highlightToolBarType: string[] | null;
  onToggleFormat?: (format: keyof CustomText, on: boolean) => void;
  firstElementBorderLeft: boolean;
}) {
  return (
    <>
      <ToolbarButton
        onToggleFormat={onToggleFormat}
        editor={editor}
        isActive={highlightToolBarType?.includes('bold')}
        format="bold"
        borderLeft={firstElementBorderLeft}
      >
        <span style={{ fontWeight: 600 }}>B</span>
      </ToolbarButton>
      <ToolbarButton
        onToggleFormat={onToggleFormat}
        editor={editor}
        isActive={highlightToolBarType?.includes('italic')}
        format="italic"
      >
        <span style={{ fontStyle: 'italic' }}>I</span>
      </ToolbarButton>
      <ToolbarButton
        onToggleFormat={onToggleFormat}
        editor={editor}
        isActive={highlightToolBarType?.includes('underline')}
        format="underline"
      >
        <span style={{ textDecoration: 'underline' }}>U</span>
      </ToolbarButton>
      <ToolbarButton
        onToggleFormat={onToggleFormat}
        editor={editor}
        isActive={highlightToolBarType?.includes('strikethrough')}
        format="strikethrough"
      >
        <span style={{ fontStyle: 'italic', textDecoration: 'line-through' }}>S</span>
      </ToolbarButton>
      <ToolbarButton
        onToggleFormat={onToggleFormat}
        editor={editor}
        isActive={highlightToolBarType?.includes('code')}
        format="code"
      >
        <CodeIcon className={styles.icon} />
      </ToolbarButton>

      <button
        type="button"
        className={`${stylesButton.button} disabled:opacity-50 disabled:cursor-not-allowed`}
        onMouseDown={event => {
          event.preventDefault();
          // current implementation:
          // assign one uuid to both element and link for state
          // when we render the element, we will compare the linkBeingEditedBy field witht the linkContext.linkBeingEditedBy, if they match, we show the link form
          // this is needed for the two
          // 1. two ppl are adding link at the same time, they should only see the one they are editing themselves
          // 2. when link form is opened, there should be one and only one form open
          // TODO: when we do this way, it's basically 2 operations involved in adding a new link
          // 1. wrap parts as interim link element
          // 2. apply a link
          // a consequence is that when user wants to undo adding the new link, the use has to undo twice to fully revert back
          // an enhanced solution could be (not sure if it's valid):
          // we should treat wraping parts as an interim link element as a staging state change and should not impact the editor state
          // we could
          // 1. apply wrapping change to a cloned editor, or to a cloned editor.children. (we may need to copy some source code from transform.wrapABC function and apply to the json structure of the children object)
          // 2. using the staging children state to render
          // 3. once the edit is done, apply the link to the real editor.

          if (userId && editor.selection) {
            wrapLink(editor, '', userId);
          }
        }}
        disabled={linkButtonDisabled}
      >
        <span style={{ display: 'flex' }}>
          <LinkIcon style={{ height: '1rem', width: '1rem' }} />
        </span>
      </button>
    </>
  );
}

EditorLeafFormatButtons.defaultProps = {
  onToggleFormat: undefined,
};

interface Props {
  editor: CustomEditor;
  enableTypeSelect?: boolean;
  enableComment?: boolean;
  scrollContainer?: HTMLDivElement;
  onComment?: () => void;
  hide?: boolean;
}

function EditorToolbar({ editor, enableTypeSelect, enableComment, scrollContainer, onComment, hide }: Props) {
  const [openToolbar, setOpenToolbar] = useState(false);
  const openToolbarRef = useRef<boolean>(false);
  const [highlightToolBarType, setHighlightToolBarType] = useState<string[] | null>(null);
  const [isLinkButtonDisabled, setIsLinkButtonDisabled] = useState<boolean>(false);
  const [toolbarAnchorPosition, setToolbarAnchorPosition] = useState<{ top: number; left: number }>();
  const selection = useSlateSelection();
  const focused = useFocused();
  const userStore = useUserStore();
  const [showTypeSelect, setShowTypeSelect] = useState(false);

  const getActiveMarks = useCallback((localEditor: Editor): string[] => {
    const marks = FormatTypes.reduce((obj, item) => {
      obj[item] = true;
      return obj;
    }, {} as { [format: string]: boolean });
    const itNode = NoteEditor.nodes(localEditor, {
      match: n => {
        return Text.isText(n) && !!n.text;
      },
      mode: 'lowest',
    });
    let node = itNode.next();
    while (!node.done) {
      const textNode = node.value[0] as CustomText;
      keys(marks).forEach(format => {
        marks[format] = marks[format] && get(textNode, [format]);
      });
      node = itNode.next();
    }
    return FormatTypes.filter(format => marks[format]);
  }, []);

  useEffect(() => {
    openToolbarRef.current = openToolbar;
  }, [openToolbar]);

  const updateToolbarPosition = useCallback(() => {
    const domSelection = window.getSelection();
    if (!domSelection?.rangeCount) {
      return;
    }
    const domRange = domSelection?.getRangeAt(0);
    // when select inside one block, use selection dom range to calc toolbar postion
    // when select among multi blocks, use the start selection container to calc toolbar postion
    const rect =
      domRange?.startContainer === domRange?.endContainer ||
      (domRange?.commonAncestorContainer as HTMLDivElement)?.getAttribute?.('data-slate-node') === 'element'
        ? domRange?.getBoundingClientRect()
        : domRange?.startContainer?.parentElement?.getBoundingClientRect();
    setToolbarAnchorPosition(
      rect
        ? { top: rect.top - 56, left: Math.min(rect.left + rect.width / 2 - 160, document.body.clientWidth - 400) }
        : undefined,
    );
  }, []);

  useEffect(() => {
    if (selection && focused && Range.isExpanded(selection) && !!Editor.string(editor, selection)) {
      updateToolbarPosition();
      setHighlightToolBarType(getActiveMarks(editor));
      const selectionInSameBlock =
        selection.anchor.path.length === selection.focus.path.length &&
        selection.anchor.path.length > 1 &&
        Path.equals(selection.anchor.path.slice(0, -1), selection.focus.path.slice(0, -1));
      setIsLinkButtonDisabled(!selectionInSameBlock || isLinkActive(editor));
      setOpenToolbar(true);
      if (enableTypeSelect) {
        const rootPath = selection.anchor.path.slice(0, 1);
        setShowTypeSelect(
          selectionInSameBlock &&
            Node.has(editor, rootPath) &&
            INDENTABLE_TYPES.includes((Node.get(editor, rootPath) as CustomElement).type as BlockType),
        );
      }
    } else {
      setOpenToolbar(false);
      // reset selection, otherwise, when task editor is refocused, the highligh is still there
      if (editor.selection) {
        if (Point.isBefore(editor.selection.anchor, editor.selection.focus)) {
          Transforms.select(editor, { anchor: editor.selection.focus, focus: editor.selection.focus });
        } else {
          Transforms.select(editor, { anchor: editor.selection.anchor, focus: editor.selection.anchor });
        }
      }
    }
  }, [selection, focused]);

  useEffect(() => {
    if (scrollContainer) {
      const handleScroll = throttle(() => {
        if (openToolbarRef.current) {
          updateToolbarPosition();
        }
      }, 10);
      scrollContainer.addEventListener('scroll', handleScroll);
      return () => scrollContainer.removeEventListener('scroll', handleScroll);
    }
  }, [scrollContainer]);

  return (
    <Portal container={document.body}>
      {openToolbar && !hide && (
        <div
          className={styles.container}
          style={toolbarAnchorPosition}
          onMouseDown={e => {
            // prevent toolbar from taking focus away from editor
            e.preventDefault();
          }}
        >
          {showTypeSelect && <ToolbarTypeSelector />}
          <EditorLeafFormatButtons
            editor={editor}
            highlightToolBarType={highlightToolBarType}
            userId={userStore.user?.id ?? ''}
            linkButtonDisabled={isLinkButtonDisabled}
            firstElementBorderLeft
            onToggleFormat={() => setHighlightToolBarType(getActiveMarks(editor))}
          />
          {enableComment && <CommentToolbarButton editor={editor} onClick={onComment} />}
        </div>
      )}
    </Portal>
  );
}

EditorToolbar.defaultProps = {
  enableTypeSelect: undefined,
  enableComment: undefined,
  scrollContainer: undefined,
  onComment: undefined,
  hide: undefined,
};

export default EditorToolbar;
