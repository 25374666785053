import React, { useEffect, useState } from 'react';
import useUserStore from '../stores/user';
import OpportunitySelector from './TeamMemberRequestsCreatePage/OpportunitySelector';
import useFieldValuesStore from '../stores/field-values';
import SpinLoader from '../components/icons/SpinLoader';
import FieldValueView from '../components/FieldValueView';
import useDocumentStore from '../stores/document';
import { concat } from '../utils/styling';
import ContextPanelView from 'components/ContextPanelView';
import { Link, useSearchParams } from 'react-router-dom';
import useTeamMemberRequestStore from '../stores/team-member-request';
import Loader from '../components/Loader';
import CheckIcon from '../components/icons/CheckIcon';
import useSuggestedAnswer from '../hooks/useSuggestedAnswer';

function TeamMemberRequestCreatePage() {
  const [searchParams] = useSearchParams();
  useSuggestedAnswer();

  const crmOpportunityId = searchParams.get('opportunityId');

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [opportunityDocumentId, setOpportunityDocumentId] = useState<string | null>(null);
  const [submitStatus, setSubmitStatus] = useState<'none' | 'loading' | 'success'>('none');
  const [requestId, setRequestId] = useState<string | null>(null);

  const userStore = useUserStore();
  const fieldValueStore = useFieldValuesStore();
  const documentStore = useDocumentStore();
  const teamMemberRequestStore = useTeamMemberRequestStore();

  useEffect(() => {
    if (crmOpportunityId && !documentStore.isLoading) {
      const fetch = async () => {
        setIsLoading(true);
        const entityDocument = await documentStore.getOrCreateNewSFEntity(crmOpportunityId, 'Opportunity');
        if (!entityDocument) {
          return;
        }
        setOpportunityDocumentId(entityDocument.id);
        await fieldValueStore.fetchWithAITrigger(entityDocument.id);
        setIsLoading(false);
      };
      fetch();
    }
  }, [documentStore.isLoading, crmOpportunityId]);

  useEffect(() => {
    if (opportunityDocumentId === null) {
      fieldValueStore.reset();
    } else {
      fieldValueStore.fetch(opportunityDocumentId);
    }
  }, [opportunityDocumentId]);

  if (isLoading || documentStore.isLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Loader />
      </div>
    );
  }

  if (submitStatus === 'success') {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <div className="max-w-lg border rounded-lg p-5">
          <div className="flex justify-center">
            <div className="w-16 h-16 bg-green-100 rounded-full p-2">
              <div className="w-12 h-12 bg-green-500 rounded-full p-3">
                <CheckIcon className="text-white" />
              </div>
            </div>
          </div>
          <div className="text-2xl font-semibold text-center">Your request has been submitted successfully</div>
          <div className="py-2" />
          <div className="text-gray-500 text-center">
            <div>An SE manager will review your request as soon as possible.</div>
            <div>Once a team member is assigned, you will get an e-mail notification.</div>
            <div className="py-2" />
            <div>
              <Link className="text-blue-500 hover:text-blue-400" to={`/team-member-requests/${requestId}`}>
                View or edit your request →
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const submit = async () => {
    if (!opportunityDocumentId) return;
    setSubmitStatus('loading');
    const { id } = await teamMemberRequestStore.createRequest(opportunityDocumentId);
    setSubmitStatus('success');
    setRequestId(id);
  };

  const isSubmitDisabled = opportunityDocumentId === null || submitStatus !== 'none' || fieldValueStore.isLoading;

  const fieldValues = fieldValueStore.fieldValues
    .filter(fv => fv.field.isUsedInTeamMemberRequest)
    .sort((a, b) => {
      if (a.field.entity === 'Account' && b.field.entity === 'Opportunity') {
        return -1;
      }
      if (a.field.entity === 'Opportunity' && b.field.entity === 'Account') {
        return 1;
      }

      return a.field.order - b.field.order;
    });

  return (
    <div className="w-full h-full flex flex-col">
      <div className="flex items-center justify-between py-2 px-5 border-b">
        <div className="font-semibold text-lg py-3">Request a Team Member</div>
      </div>
      <ContextPanelView viewKey="team-member-request-create-page-context-panel">
        <div className="flex flex-col items-center h-full">
          <div className="py-5" />
          <div className="w-full lg:w-[45rem] p-5 border rounded">
            <div className="p-2 bg-gray-100 font-gray-800 w-fit rounded">Team Member Request Form</div>
            <div className="py-2" />
            <div>
              <div className="text-sm text-slate-500 py-1">Requester</div>
              <div className="border rounded px-4 py-2">
                {userStore?.user?.firstName} {userStore?.user?.lastName}
              </div>
            </div>
            <div className="py-2" />
            <div>
              <div className="text-sm text-slate-500 py-1">Opportunity</div>
              <OpportunitySelector
                opportunityDocumentId={opportunityDocumentId}
                setOpportunityDocumentId={setOpportunityDocumentId}
              />
            </div>
            <div className="py-2" />
            <div>
              {opportunityDocumentId && (
                <>
                  {fieldValueStore.isLoading && (
                    <div className="flex items-center">
                      <SpinLoader className="w-6 h-6 animate-spin mr-2" />
                      <span className="text-sm text-gray-500">
                        Please wait... we are loading rest of the form. This may take a few seconds.
                      </span>
                    </div>
                  )}
                  {!fieldValueStore.isLoading && fieldValues.length > 0 && (
                    <>
                      <div className="text-sm text-slate-500 py-1">Fields</div>
                      <div className="py-2">
                        {fieldValues.map(fv => (
                          <FieldValueView key={fv.id} from="reporting" fieldValue={fv} />
                        ))}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
            <div className="py-2" />
            <div className="flex justify-end">
              <button
                type="button"
                className={concat(
                  'text-white px-5 py-3 rounded',
                  isSubmitDisabled ? 'bg-gray-400' : 'bg-blue-600 hover:bg-blue-500',
                )}
                onClick={submit}
                disabled={isSubmitDisabled}
              >
                Submit Request
              </button>
            </div>
          </div>
          <div className="py-20">&nbsp;</div>
        </div>
      </ContextPanelView>
    </div>
  );
}

export default TeamMemberRequestCreatePage;
