import { CustomElement } from 'components/NoteEditor/types';
import httpClient from '../http-client';

export interface templateData {
  children: CustomElement[];
}

export default async (
  noteTemplateId: string,
  templateName: string,
  templateData: templateData,
  templateIcon: string | null,
  isMeta: boolean,
): Promise<void> => {
  await httpClient.put<void>(`/note-templates/${noteTemplateId}`, {
    templateName,
    templateData,
    templateIcon,
    isMeta,
  });
};
