import React, { useCallback, useMemo } from 'react';
import { Tab as MuiTab, Tabs as MuiTabs } from '@mui/material';
import { ListItem } from 'types/list-item';
import { concat } from 'utils/styling';
import styles from './index.module.css';

interface Props {
  containerClassName?: string;
  tabClassName?: string;
  tabs: ListItem<string>[];
  currentTab: string;
  setCurrentTab: (newTab: string) => void;
  orientation?: 'horizontal' | 'vertical';
}
function Tabs({ containerClassName, tabClassName, tabs, currentTab, setCurrentTab, orientation }: Props) {
  const onChange = useCallback(
    (_event: React.SyntheticEvent<Element, Event>, newValue: number) => setCurrentTab(tabs[newValue].value),
    [setCurrentTab, tabs],
  );
  const value = useMemo(() => tabs.findIndex(item => item.value === currentTab), [currentTab, tabs]);

  return (
    <MuiTabs
      className={concat(
        styles.tabs,
        styles.tabsContainer,
        orientation === 'vertical' && styles.vertical,
        containerClassName,
      )}
      value={value}
      onChange={onChange}
      orientation={orientation}
    >
      {tabs.map(tab => (
        <MuiTab
          key={tab.value}
          icon={tab.icon as React.ReactElement}
          className={concat(styles.tab, tabClassName, tab.className)}
          label={tab.label}
          disabled={tab.disabled}
        />
      ))}
    </MuiTabs>
  );
}

Tabs.defaultProps = {
  containerClassName: '',
  tabClassName: '',
  orientation: 'horizontal',
};

export default Tabs;
