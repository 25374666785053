/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from 'react';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';

import SpinLoader from 'components/icons/SpinLoader';
import isHotkey from 'is-hotkey';

interface Props {
  isEditable: boolean;
  isUpdating: boolean;
  isEdited: boolean;
  answer: string;
  onAnswerChange: (value: string) => void;
  onCancel: () => void;
  onSubmit: () => void;
}

function EmailField({ isEditable, isUpdating, isEdited, answer, onAnswerChange, onSubmit, onCancel }: Props) {
  const [error, setError] = useState<string>('');

  const validateEmail = (testEmail: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(testEmail);
  };

  const update = () => {
    if (answer !== '' && !validateEmail(answer)) {
      setError('Invalid email address');
    } else {
      setError('');
      onSubmit();
    }
  };

  return (
    <div className="w-full">
      <input
        type="email"
        className="resize-none overflow-y-hidden bg-transparent w-full h-full text-gray-500 placeholder:text-gray-400"
        value={answer}
        onChange={e => {
          onAnswerChange(e.target.value);
        }}
        disabled={!isEditable || isUpdating}
        onCopy={e => e.stopPropagation()}
        onPaste={e => e.stopPropagation()}
        onKeyDown={e => {
          if (e.key === 'Enter' && isEdited) {
            e.preventDefault();
            update();
          }
          if (isHotkey('mod+a', e)) {
            e.stopPropagation();
          }
        }}
        autoFocus
      />
      {isEditable && (
        <div className="w-full flex justify-between items-center mt-1">
          <div className="flex items-center gap-1 text-xs">
            {error && <span className="text-red-400 text-xs">{error}</span>}
            {isUpdating && (
              <>
                <SpinLoader className="w-5 h-5 p-1 animate-spin" />
                <span>Updating...</span>
              </>
            )}
          </div>
          {isEdited && (
            <div className="flex items-center gap-1">
              <button
                className="text-white"
                type="button"
                onClick={() => {
                  onCancel();
                  setError('');
                }}
              >
                <XMarkIcon className="w-5 h-5 p-0.5 rounded-full bg-gray-400" />
              </button>
              <button className="text-white" type="button" disabled={!isEdited || isUpdating} onClick={update}>
                {isUpdating ? (
                  <SpinLoader className="w-5 h-5 p-1 rounded-full bg-blue-500 animate-spin" />
                ) : (
                  <CheckIcon className={`w-5 h-5 p-0.5 rounded-full ${!isEdited ? 'bg-gray-400' : 'bg-blue-500'}`} />
                )}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default EmailField;
