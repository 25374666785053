import httpClient from '../http-client';

export type SearchItemType = 'document' | 'opportunity' | 'account' | 'event';

export interface SearchItem {
  documentId: string | null;
  crmId: string | null;
  title: string;
  oppStageName: string | null;
  type: SearchItemType;
  datetime: string;
  parent: {
    type: 'Opportunity' | 'Account';
    name: string;
    id?: string;
  } | null;
  uploadedFileType?: string | null;
}

export default async (
  search: string,
  searchDocument: boolean,
  searchOpportunity: boolean,
  searchAccount: boolean,
  searchEvent: boolean,
): Promise<SearchItem[]> => {
  const params = new URLSearchParams();
  params.set('search', search);
  params.set('searchDocument', `${searchDocument}`);
  params.set('searchOpportunity', `${searchOpportunity}`);
  params.set('searchAccount', `${searchAccount}`);
  params.set('searchEvent', `${searchEvent}`);
  const { data } = await httpClient.get<SearchItem[]>(`/search-items?${params.toString()}`);
  return data;
};
