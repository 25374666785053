/* eslint-disable react/jsx-props-no-spreading */
import React, { memo, useMemo } from 'react';
import NoteEditor from 'components/NoteEditor/NoteEditor';
import {
  TableCellElement as TableCellElementType,
  TableRowElement as TableRowElementType,
  TableElement as TableElementType,
  BlockType,
} from 'components/NoteEditor/types';
import { Editor, Node, Transforms } from 'slate';
import { ReactEditor, RenderElementProps, useSlateStatic } from 'slate-react';
import useNoteEditorTableOperationStore from 'stores/note-editor-table-operation';
import { concat } from 'utils/styling';
import TableCellMenuBtn from './TableCellMenuBtn';
import styles from './Table.module.css';

interface Props extends RenderElementProps {
  element: TableRowElementType;
  editor: Editor;
}

function TableRowElement({ attributes, children, element: tableRowElement }: Props) {
  const noteEditorStore = useNoteEditorTableOperationStore();
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, tableRowElement);
  const tableElement = useMemo(() => {
    const parentPath = path.slice(0, -1);
    return Node.has(editor, parentPath) ? (Node.get(editor, parentPath) as TableElementType) : null;
  }, [path]);

  return (
    <div
      {...attributes}
      className={concat(
        styles['table-row'],
        noteEditorStore.tableFocusedRowIndex[path[path.length - 2]] === path[path.length - 1]
          ? styles['row-focused']
          : '',
      )}
    >
      {children}
      <div contentEditable={false} style={{ userSelect: 'none' }}>
        <div className={styles['float-row-icon-btn-wrap']}>
          <TableCellMenuBtn
            OnAdd={offset => {
              const currentPath = ReactEditor.findPath(editor, tableRowElement);
              const [currentNode] = NoteEditor.node(editor, currentPath);
              const newRow = {
                type: BlockType.TableRow,
                children: (currentNode as TableRowElementType).children.map((child: TableCellElementType) => ({
                  type: BlockType.TableCell,
                  width: child.width,
                  children: [{ text: '' }],
                })),
              };
              const insertPath = [...currentPath];
              insertPath[insertPath.length - 1] = insertPath[insertPath.length - 1] + offset;
              Transforms.insertNodes(editor, newRow as TableRowElementType, {
                at: insertPath,
              });
              useNoteEditorTableOperationStore.setState({
                tableFocusedRowIndex: {},
                tableFocusedColIndex: {},
              });
            }}
            OnDelete={() => {
              Transforms.removeNodes(editor, { at: path });
              useNoteEditorTableOperationStore.setState({
                tableFocusedRowIndex: {},
                tableFocusedColIndex: {},
              });
            }}
            OnToggleVisible={visible => {
              useNoteEditorTableOperationStore.setState({
                tableFocusedRowIndex: visible ? { [path[path.length - 2]]: path[path.length - 1] } : {},
                tableFocusedColIndex: {},
              });
            }}
            type="column"
            canDelete={!!tableElement?.children?.length && tableElement.children.length > 1}
          />
        </div>
      </div>
    </div>
  );
}

export default memo(TableRowElement);
