/* eslint-disable react/no-array-index-key, no-console */
import React, { useCallback, useEffect, useState } from 'react';
import { AIWorker, WorkerContext } from '../../../../types/ai-worker';
import { AIWorkerContextRowView } from './AIWorkerContextRowView';
import SpinLoader from '../../../icons/SpinLoader';
import TrashIcon from 'components/icons/TrashIcon';
import { PlusIcon } from '@heroicons/react/24/outline';
import BaseButton from '../../../BaseButton';
import { debounce } from 'lodash';
import styles from './AIWorker.module.css';
import InputField from 'components/Form/InputField';
import { concat } from 'utils/styling';

export interface AIWorkerContextViewProps {
  context?: WorkerContext;
  loading: boolean;
  worker: AIWorker;
  onRulesUpdate: (rules: string[]) => Promise<void>;
}

export function AIWorkerContextView({ context, loading, worker, onRulesUpdate }: AIWorkerContextViewProps) {
  const searchTips = context?.searchTip.split('PLACE_HOLDER') || [];
  const searchWords = context?.searchAndCompose?.search_phrases || [];
  const [rules, setRules] = useState<string[]>([]);
  const [rulesUpdating, setRulesUpdating] = useState(false);
  const disabled = worker.status === 'running';

  useEffect(() => {
    if (context?.searchAndCompose) {
      setRules(context?.searchAndCompose.writing_requirements);
    }
  }, [context?.searchAndCompose]);

  const handleRulesContentUpdate = useCallback(
    debounce((newRules: string[]) => {
      setRulesUpdating(true);
      onRulesUpdate(newRules)
        .catch(console.error)
        .finally(() => setRulesUpdating(false));
    }, 300),
    [],
  );

  if (loading) {
    return (
      <div className="flex flex-row p-4 text-sm items-center pl-2">
        <SpinLoader className="w-5 h-5 animate-spin text-orange-500 mr-2" />
        <span>🤔 Let me think how I can help with this task, one moment please...</span>
      </div>
    );
  }
  if (!context || !context.searchAndCompose || !context.contexts) {
    return <div />;
  }

  const handleUpdate = (i: number, txt: string) => {
    const newRules = [...rules];
    newRules[i] = txt;
    handleRulesContentUpdate(newRules);
    setRules(newRules);
  };

  const onDelete = (i: number) => {
    const newRules = [...rules];
    newRules.splice(i, 1);
    handleRulesContentUpdate(newRules);
    setRules(newRules);
  };
  const onAdd = () => {
    const newRules = [...rules];
    newRules.push('');
    handleRulesContentUpdate(newRules);
    setRules(newRules);
  };

  const hasDelete = (i: number) => rules.length > 1 && i >= 0;
  const hasAdd = (i: number) => i === rules.length - 1 && rules[i];
  return (
    <div className="px-3 py-2 border-b-gray-100 border-b-[1px] text-sm pt-4 pb-4">
      <div className="mb-2 text-gray-500">
        {searchTips[0]}
        {searchWords.map((t, i) => (
          <span className="font-semibold" key={t}>
            {`${t}${i < searchWords.length - 1 ? '", "' : ''}`}
          </span>
        ))}
        {searchTips[1]}
      </div>
      {context.contexts.map(v => (
        <AIWorkerContextRowView row={v} key={v.id} />
      ))}
      <div className="mt-4 mb-2 flex flex-row items-center text-gray-500">
        {context.composeTip}
        {rulesUpdating && <SpinLoader className="animate-spin w-3 h-3 ml-2" />}
      </div>
      {rules.map((t, i) => (
        <div key={i} className="w-full flex flex-row">
          <div className="w-1 h-1 bg-black rounded-sm mr-2 mt-4" />
          <InputField
            className={concat(styles.aiSearchInput, styles.aiSearchInputGray)}
            type="text"
            disabled={disabled}
            onChange={e => handleUpdate(i, e.target.value)}
            value={t}
            size="small"
            endAdornment={
              <div className="ml-2 w-24 h-full flex items-end py-0.5">
                {hasDelete(i) && (
                  <BaseButton color="secondary" disabled={disabled} onClick={() => onDelete(i)}>
                    <TrashIcon className="w-5 h-5 text-gray-500" />
                  </BaseButton>
                )}
                {hasAdd(i) && (
                  <BaseButton color="secondary" disabled={disabled} onClick={() => onAdd()}>
                    <PlusIcon className="w-5 h-5 text-gray-500" />
                  </BaseButton>
                )}
              </div>
            }
            multiline
          />
        </div>
      ))}
    </div>
  );
}

AIWorkerContextView.defaultProps = {
  context: undefined,
};
