import httpClient from '../http-client';
import {
  AIWorker,
  AIWorkerScope,
  AIWorkerScopeRow,
  UpdateWorkerContextReq,
  WorkerContext,
} from '../../types/ai-worker';

interface AIWorkerBody {
  documentId: string | null | undefined;
  text: string;
  scopeObj: AIWorkerScope;
  status?: string;
}

export const getAIWorker = async (id: string): Promise<AIWorker> => {
  const { data } = await httpClient.get<AIWorker>(`/ai_worker/${id}`);
  return data;
};

export const createAIWorker = async (req: AIWorkerBody): Promise<AIWorker> => {
  const { data } = await httpClient.post<AIWorker>(`/ai_worker`, req);
  return data;
};
export const deleteAIWorker = async (id: string): Promise<void> => {
  await httpClient.delete(`/ai_worker/${id}`);
};
export const updateAIWorker = async (id: string, req: Partial<AIWorkerBody>): Promise<AIWorker> => {
  const { data } = await httpClient.put<AIWorker>(`/ai_worker/${id}`, req);
  return data;
};

export const getTplAIWorker = async (id: string): Promise<AIWorker> => {
  const { data } = await httpClient.get<AIWorker>(`/template-ai_worker/${id}`);
  return data;
};

export const createTplAIWorker = async (req: AIWorkerBody): Promise<AIWorker> => {
  const { data } = await httpClient.post<AIWorker>(`/template-ai_worker`, req);
  return data;
};
export const deleteTplAIWorker = async (id: string): Promise<void> => {
  await httpClient.delete(`/template-ai_worker/${id}`);
};
export const initTplAIWorker = async (id: string, documentId: string): Promise<AIWorker> => {
  const { data } = await httpClient.post<AIWorker>(`/template-ai_worker/${id}`, {
    documentId,
  });
  return data;
};
export const updateTplAIWorker = async (id: string, req: Partial<AIWorkerBody>): Promise<AIWorker> => {
  const { data } = await httpClient.put<AIWorker>(`/template-ai_worker/${id}`, req);
  return data;
};

export const runAIWorker = async (aiWorkerId: string, blockId: string): Promise<AIWorker> => {
  const { data } = await httpClient.post<AIWorker>(`/ai_worker/run_by_worker`, {
    aiWorkerId,
    blockId,
  });
  return data;
};

export const runWorkerByDocument = async (documentId: string): Promise<[AIWorker]> => {
  const { data } = await httpClient.post<[AIWorker]>(`/ai_worker/run_by_document`, {
    documentId,
  });
  return data;
};

export const getAIWorkerScopes = async (id: string): Promise<AIWorkerScopeRow[]> => {
  const { data } = await httpClient.get<AIWorkerScopeRow[]>(`/ai_worker/${id}/scopes`);
  return data;
};

export const createAIWorkerContext = async (id: string): Promise<WorkerContext> => {
  const { data } = await httpClient.post<WorkerContext>(`/ai_worker_context/${id}`);
  return data;
};

export const getAIWorkerContext = async (id: string): Promise<WorkerContext> => {
  const { data } = await httpClient.get<WorkerContext>(`/ai_worker_context/${id}`);
  return data;
};

export const updateAIWorkerContext = async (id: string, req: UpdateWorkerContextReq): Promise<void> => {
  await httpClient.put<WorkerContext>(`/ai_worker_context/${id}`, req);
};

export const deleteAIWorkerResult = async (id: string): Promise<void> => {
  await httpClient.delete<WorkerContext>(`/ai_worker_result/${id}`);
};
