import { useEffect } from 'react';
import useReportingStore from '../../stores/reporting';

function UseReportingBackgroundFetch() {
  // check processing status every 8 seconds
  const reportingStore = useReportingStore();

  useEffect(() => {
    // Every 8 second we fetch new AI processing status from backend
    const intervalId = setInterval(async () => {
      await reportingStore.updateColumns();
    }, 4000);
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export default UseReportingBackgroundFetch;
