import { Recording } from 'api/recordings/get-recordings';
import httpClient from '../http-client';

export interface Response {
  recordings: Recording[];
}

export default async (phrase: string): Promise<Response> => {
  const params = new URLSearchParams();
  params.set('phrase', phrase);
  const { data } = await httpClient.get<Response>(`/recordings?${params.toString()}`);
  return data;
};
