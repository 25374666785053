/* eslint-disable react/jsx-props-no-spreading */

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Moment } from 'moment';
import React, { useState } from 'react';

import CalendarIcon from 'components/icons/CalendarIcon';
import SpinLoader from 'components/icons/SpinLoader';

import constants from 'utils/constants';

import styles from './DateField.module.css';

interface Props {
  isEditable: boolean;
  isUpdating: boolean;
  value: string;
  onChange: (value: string) => void;
}

function DateField({ isEditable, isUpdating, value, onChange }: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          className={styles.date}
          open={isOpen}
          onClose={() => setIsOpen(false)}
          inputFormat={constants.DATE_FORMAT}
          disableMaskedInput
          value={value}
          onChange={newValue => {
            const date = (newValue as Moment | null)?.format('YYYY-MM-DD') || '';
            if (date) {
              onChange(date);
              setIsOpen(false);
            }
          }}
          renderInput={params => (
            <button
              type="button"
              className="bg-gray-100 px-2 py-1 flex items-center text-gray-500 text-sm"
              onClick={() => setIsOpen(true)}
              disabled={!isEditable || isUpdating}
            >
              <div ref={params.inputRef}>{params.inputProps?.value || 'Select a date'}</div>
              <CalendarIcon className="ml-1" />
            </button>
          )}
        />
      </LocalizationProvider>
      <div className="flex items-center text-xs gap-1">
        {isUpdating && (
          <>
            <SpinLoader className="w-5 h-5 p-1 animate-spin" />
            <span>Updating...</span>
          </>
        )}
      </div>
    </div>
  );
}

export default DateField;
