import isHotkey from 'is-hotkey';
import { Editor, Transforms, Node } from 'slate';
import { ReactEditor } from 'slate-react';
import { BlockType } from '../types';
import { SlatePlugin } from './types';

function tabKeyPlugin(event: React.KeyboardEvent<HTMLDivElement>, editor: Editor): boolean {
  let stopPlugin = false;

  const isTabPressed = isHotkey('tab', event);
  const isShiftTabPressed = isHotkey('shift+tab', event);
  if (isTabPressed || isShiftTabPressed) {
    const { selection } = editor;
    if (!selection) {
      return stopPlugin;
    }

    const tableElementId = selection.anchor.path[0];
    const tableRowElementId = selection.anchor.path[1];
    const tableCellElementId = selection.anchor.path[2];

    const tableNode = editor.children[tableElementId];

    if (tableNode && tableNode.type === BlockType.Table) {
      const tableRowNode = tableNode.children[tableRowElementId] as unknown as Element;
      // hitting tab should take user to the next cell
      if (isTabPressed) {
        let nextCell;
        if (tableRowNode.children.length > tableCellElementId + 1) {
          // get next cell over
          nextCell = tableRowNode.children[tableCellElementId + 1];
        } else if (
          tableRowNode.children.length <= tableCellElementId + 1 &&
          tableNode.children.length > tableRowElementId + 1
        ) {
          // get next row & cell over
          const nextRow = tableNode.children[tableRowElementId + 1] as unknown as Element;
          // eslint-disable-next-line prefer-destructuring
          nextCell = nextRow.children[0];
        }

        if (nextCell) {
          const nextCellPath = ReactEditor.findPath(editor, nextCell as unknown as Node);
          Transforms.select(editor, nextCellPath);
        }
        // hitting shift+tab should take user to the previous cell
      } else if (isShiftTabPressed) {
        let prevCell;
        // get previous cell over, if it exists
        if (tableRowNode.children[tableCellElementId - 1]) {
          prevCell = tableRowNode.children[tableCellElementId - 1];
          // get prev row & last cell, if it exists
        } else if (tableNode.children[tableRowElementId - 1]) {
          const nextRow = tableNode.children[tableRowElementId - 1] as unknown as Element;
          // eslint-disable-next-line prefer-destructuring
          prevCell = nextRow.children[nextRow.children.length - 1];
        }

        if (prevCell) {
          const nextCellPath = ReactEditor.findPath(editor, prevCell as unknown as Node);
          Transforms.select(editor, nextCellPath);
        }
      }
      stopPlugin = true;
    }
  }

  return stopPlugin;
}

const TabKeyPlugin: SlatePlugin = {
  key: 'tab-plugin',
  onKeyDown: tabKeyPlugin,
};

export default TabKeyPlugin;
