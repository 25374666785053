/* eslint-disable jsx-a11y/no-static-element-interactions */
import { ArrowRightOnRectangleIcon } from '@heroicons/react/24/outline';
import React, { ReactNode, useRef, useState } from 'react';
import { Link, NavigateFunction, useNavigate } from 'react-router-dom';

import logout from 'api/logout';

import Calendar from 'components/Calendar';
import NoteIcon from 'components/icons/NoteIcon';
import SettingIcon from 'components/icons/SettingIcon';
import SearchIcon from 'components/icons/SearchIcon';
import TemplateManagementIcon from 'components/icons/TemplateManagementIcon';
import CalendarIcon from 'components/icons/CalendarIcon';
import FieldSettingIcon from 'components/icons/FieldSettingIcon';
import NoteMenu from 'components/NoteMenu';
import SideMenu from 'components/Page/SideMenu';
import SideMenuToggleButton from 'components/Page/SideMenuToggleButton';
import Popover from 'components/Popover';
import SearchModal from 'components/SearchModal';
import Tooltip from 'components/Tooltip';
import ManageTemplateModal from 'components/ManageTemplateModal';

import useSideMenuStore from 'stores/side-menu';
import useUserStore, { State as UserState } from 'stores/user';
import useModalStore from 'stores/modal';

import { concat } from 'utils/styling';

import styles from './Page.module.css';
import ScreenWidthListener from './Page/ScreenWidthListener';
import RecordingIcon from './icons/RecordingIcon';
import FlagIcon from './icons/FlagIcon';
import TaskIcon from './icons/TaskIcon';
import TableIcon from './icons/TableIcon';
import TeamMemberRequestIcon from './icons/TeamMemberRequestIcon';

interface State {
  // isCreateNewDocumentLoading: boolean;
  isSettingOpen: boolean;
}

interface Props {
  children: ReactNode;
}

export function logUserOut(userStore: UserState, navigate: NavigateFunction) {
  return async (): Promise<void> => {
    try {
      await logout();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('logout error');
    }
    userStore.setState({ user: null });
    navigate('/login');
  };
}

function Page({ children }: Props) {
  const [state, setState] = useState<State>({
    // isCreateNewDocumentLoading: false,
    isSettingOpen: false,
  });
  const settingRef = useRef<HTMLButtonElement>(null);

  const navigate = useNavigate();

  const modalStore = useModalStore();
  const sideMenuStore = useSideMenuStore();
  const userStore = useUserStore();

  const isAtDocPage = true;

  return (
    <ScreenWidthListener>
      <nav className={concat(styles['flex-column-center'], styles['nav-bar'])}>
        <div className={concat(styles['flex-column-center'], styles['nav-top'])}>
          {/* <Superpanel className={styles.logo} /> */}
          <div className="flex flex-col items-center flex-shrink-0 flex-grow-0 w-full gap-8">
            <div
              className="flex flex-col w-full
            py-[0.2rem]"
            >
              <SideMenuToggleButton
                isAtDocPage={isAtDocPage}
                buttonName="cal"
                state={sideMenuStore.calendarMenu}
                setState={calendarMenu => sideMenuStore.setState({ calendarMenu })}
                onClick={calendarMenu => {
                  sideMenuStore.setState({ calendarMenu, noteMenu: null });
                }}
              >
                <CalendarIcon className="w-5 h-5" />
              </SideMenuToggleButton>
              <div className={`h-2 border-x ${isAtDocPage ? 'border-gray-300' : 'border-gray-300'} mx-[5px]`} />
              <SideMenuToggleButton
                isAtDocPage={isAtDocPage}
                buttonName="note"
                state={sideMenuStore.noteMenu}
                setState={noteMenu => sideMenuStore.setState({ noteMenu })}
                onClick={noteMenu => {
                  sideMenuStore.setState({ noteMenu, calendarMenu: null });
                }}
              >
                <NoteIcon className="w-5 h-5" />
              </SideMenuToggleButton>
            </div>
            <Tooltip title="Search" placement="right">
              <button
                className={styles['link-btn']}
                type="button"
                onClick={() => {
                  modalStore.setState({
                    isOpen: true,
                    element: <SearchModal />,
                    verticalPosition: 'top',
                  });
                }}
              >
                <SearchIcon className={styles.icon} />
              </button>
            </Tooltip>
          </div>
        </div>
        <div>
          <div className="flex flex-col items-center gap-2">
            {!userStore.user?.needsToSetupProduct && (
              <Tooltip title="Product Gap" placement="right">
                <Link to="/product-gap/" className={styles.link}>
                  <button className={styles['link-btn']} type="button">
                    <FlagIcon className={styles.icon} />
                  </button>
                </Link>
              </Tooltip>
            )}
            {userStore.user?.isTeamMemberRequestSetup &&
              (userStore.user?.isAdmin ||
                userStore.user?.role === 'Sales Engineer Manager' ||
                userStore.user?.role === 'Account Executive') && (
                <Tooltip title="Team Member Request" placement="right">
                  <Link to="/team-member-requests" className={styles.link}>
                    <button className={styles['link-btn']} type="button">
                      <TeamMemberRequestIcon className={styles.icon} />
                    </button>
                  </Link>
                </Tooltip>
              )}
            <Tooltip title="Task Management" placement="right">
              <Link to="/task/" className={styles.link}>
                <button className={styles['link-btn']} type="button">
                  <TaskIcon className={styles.icon} />
                </button>
              </Link>
            </Tooltip>
            {userStore.isAnalyticTableEnabled() && (
              <Tooltip title="Table View" placement="right">
                <Link to="/reporting/" className={styles.link}>
                  <button className={styles['link-btn']} type="button">
                    <TableIcon className={styles.icon} />
                  </button>
                </Link>
              </Tooltip>
            )}
            <Tooltip title="Template Management" placement="right">
              <button
                className={styles['link-btn']}
                onClick={() =>
                  modalStore.setState({ isOpen: true, element: <ManageTemplateModal />, disableEscapeKeyDown: true })
                }
                type="button"
              >
                <TemplateManagementIcon className={styles.icon} />
              </button>
            </Tooltip>
            <Tooltip title="Automations" placement="right">
              <Link to="/crm-task" className={styles.link}>
                <button className={styles['link-btn']} type="button">
                  <FieldSettingIcon className={styles.icon} />
                </button>
              </Link>
            </Tooltip>
            <Tooltip title="Recordings" placement="right">
              <Link to="/recording/" className={styles.link}>
                <button className={styles['link-btn']} type="button">
                  <RecordingIcon className={styles.icon} />
                </button>
              </Link>
            </Tooltip>
            <Tooltip title="Settings" placement="right">
              <button
                ref={settingRef}
                className={styles['link-btn']}
                onClick={() => setState(prev => ({ ...prev, isSettingOpen: true }))}
                type="button"
              >
                <SettingIcon className={styles.icon} />
              </button>
            </Tooltip>
          </div>
        </div>
      </nav>
      <SideMenu menuName="cal" state={sideMenuStore.calendarMenu}>
        <Calendar needsToSetUpCalendar={userStore.user?.calendarIntegrationName === null} navigate={navigate} />
      </SideMenu>
      <SideMenu menuName="note" state={sideMenuStore.noteMenu}>
        <NoteMenu />
      </SideMenu>
      <Popover
        isOpen={state.isSettingOpen}
        onClose={() => setState(prev => ({ ...prev, isSettingOpen: false }))}
        anchorEl={settingRef.current}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <div>
          <button className={styles['setting-item']} onClick={() => logUserOut(userStore, navigate)()} type="button">
            <ArrowRightOnRectangleIcon className={styles['setting-icon']} />
            Logout
          </button>
          <button
            className={styles['setting-item']}
            onClick={() => {
              navigate(`/settings`);
            }}
            type="button"
          >
            <SettingIcon className={styles['setting-icon']} />
            Settings
          </button>
        </div>
      </Popover>
      <div className={styles['children-container']}>{children}</div>
    </ScreenWidthListener>
  );
}

export default Page;
