import React, { useCallback, useMemo, useRef, useImperativeHandle } from 'react';
import NoteEditor, { NoteEditorRef } from 'components/NoteEditor';
import { BlockType, NoteTemplateType } from 'components/NoteEditor/types';
import Plugins, { Plugin } from 'components/NoteEditor/Plugins';
import SlashCommandMenus, {
  SlashCommandMenu,
} from 'components/NoteEditor/Plugins/SlashCommnadPlugin/SlashCommandMenus';
import ManageTemplateModal from 'components/ManageTemplateModal';
import useModalStore from 'stores/modal';
import useFieldValuesStore from 'stores/field-values';
import useDocumentStore from 'stores/document';
import useNoteEditorStore from 'stores/note-editor';
import { Document, NoteService } from 'types/document';
import { NoteUser } from 'note-service/note-service';
import useUserStore from '../../stores/user';
import { ReactEditor } from 'slate-react';
import { createNode } from 'components/NoteEditor/utils';
import { Transforms } from 'slate';
import DocumentHeader from './DocumentHeader';

interface Props {
  document: Document;
  noteService: NoteService;
  setLoading: (isLoading: boolean) => void;
  onUpdatedDocFolderByApi?: () => void;
  showDocumentHeader?: boolean;
  onNoteUserUpdate?: (users: NoteUser[]) => void;
  docPrefixElement?: React.ReactNode;
  autoFocusWhenEmpty?: boolean;
  scrollContainerRef: React.RefObject<HTMLDivElement>;
  className?: string;
  bottomMarginSectionClassName?: string;
  isMeetingEditor: boolean;
}

const DocumentEditor = React.forwardRef<NoteEditorRef, Props>(
  (
    {
      document,
      noteService,
      setLoading,
      onUpdatedDocFolderByApi,
      showDocumentHeader,
      onNoteUserUpdate,
      docPrefixElement,
      autoFocusWhenEmpty,
      scrollContainerRef,
      className,
      bottomMarginSectionClassName,
      isMeetingEditor,
    }: Props,
    ref,
  ) => {
    const modalStore = useModalStore();
    const fieldValuesStore = useFieldValuesStore();
    const documentStore = useDocumentStore();
    const noteEditorStore = useNoteEditorStore();
    const userStore = useUserStore();
    const noteEditorRef = useRef<NoteEditorRef>(null);
    useImperativeHandle(ref, () => noteEditorRef.current!, []);

    const applyTemplateToNote = useCallback(
      async (type: NoteTemplateType, id: string) => {
        try {
          const templateData = await documentStore.checkAndGetTemplate(document.id, type, id);
          noteEditorStore.setState({
            templateToApply: templateData,
            templateToApplyDocumentId: document.id,
          });
          // Below is method to trigger AI processing
          await fieldValuesStore.fetchWithAITrigger(document.id);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error('failed to apply template');
        }
      },
      [document],
    );

    const openApplyTemplateModal = useCallback(() => {
      modalStore.setState({
        element: <ManageTemplateModal document={document} />,
        isOpen: true,
        disableEscapeKeyDown: true,
      });
    }, [document]);
    const plugins = useMemo<Plugin[]>(() => {
      const slashCommandMenus: SlashCommandMenu[] = [
        new SlashCommandMenus.Paragraph(),
        new SlashCommandMenus.TaskElement(),
        new SlashCommandMenus.Heading1(),
        new SlashCommandMenus.Heading2(),
        new SlashCommandMenus.OrderedList(),
        new SlashCommandMenus.BulletedList(),
        new SlashCommandMenus.ImageElement(),
        new SlashCommandMenus.TableElement(),
      ];
      if (document.parent) {
        slashCommandMenus.unshift(new SlashCommandMenus.DiscoveryQuestion());
        slashCommandMenus.splice(1, 0, new SlashCommandMenus.AIWorkerElement());
      }
      if (!userStore.user?.needsToSetupProduct) {
        slashCommandMenus.push(new SlashCommandMenus.ProductGap());
      }
      return [
        new Plugins.HotKey(),
        new Plugins.SlashCommand(slashCommandMenus),
        new Plugins.EmojiCommand(),
        new Plugins.EnterKey(),
        new Plugins.TabKey(),
        new Plugins.BackSpace(),
        new Plugins.ItemList(),
        new Plugins.Image(),
        new Plugins.Copy(),
        new Plugins.Cut(),
        new Plugins.Paste(),
        new Plugins.ItemList(),
      ];
    }, [userStore.user?.needsToSetupProduct, document]);

    return (
      <NoteEditor
        key={document.id}
        className={className}
        bottomMarginSectionClassName={bottomMarginSectionClassName}
        ref={noteEditorRef}
        prefixElement={
          <>
            {showDocumentHeader ? (
              <DocumentHeader
                className="pl-[4rem]"
                titleClassName="!mb-1"
                document={document}
                setLoading={setLoading}
                onUpdatedDocFolderByApi={onUpdatedDocFolderByApi}
                onTitleEnterPress={() => {
                  const editor = noteEditorRef.current?.getEditor();
                  if (editor) {
                    const node = createNode(BlockType.Paragraph);
                    Transforms.insertNodes(editor, node, { at: [0] });
                    ReactEditor.focus(editor);
                    Transforms.select(editor, [0]);
                  }
                }}
                onTitleUpdate={newTitle => documentStore.updateDocumentData(document.id, { docTitle: newTitle })}
              />
            ) : undefined}
            {docPrefixElement}
          </>
        }
        documentId={document.id}
        opportunityId={document.parent && document.parent.entityName === 'Opportunity' ? document.parent.id : null}
        applyTemplate={applyTemplateToNote}
        openApplyTemplateModal={openApplyTemplateModal}
        serviceId={noteService.id}
        serviceToken={noteService.token}
        plugins={plugins}
        onNoteUserUpdate={onNoteUserUpdate}
        type={isMeetingEditor ? 'meeting-editor' : 'doc-editor'}
        scrollContainer={scrollContainerRef.current || undefined}
        autoFocusWhenEmpty={autoFocusWhenEmpty}
      />
    );
  },
);

DocumentEditor.defaultProps = {
  onUpdatedDocFolderByApi: undefined,
  showDocumentHeader: undefined,
  onNoteUserUpdate: undefined,
  docPrefixElement: undefined,
  autoFocusWhenEmpty: undefined,
  className: '',
  bottomMarginSectionClassName: '',
};

DocumentEditor.displayName = 'DocumentEditor';

export default DocumentEditor;
