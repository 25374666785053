import httpClient from '../http-client';
import { UserRole } from '../../types/user';

export default async (
  email: string,
  role: UserRole,
  isAdmin: boolean,
  link?: string,
  documentId?: string,
  entityType?: string,
): Promise<void> => {
  const emailProcessed = email.trim();
  await httpClient.post(`/user/invite`, {
    email: emailProcessed,
    role,
    link,
    documentId,
    isAdmin,
    entityType,
  });
};
