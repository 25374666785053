/* eslint-disable no-nested-ternary */
import { CrmNote, Document } from 'types/document';
import { EntityDocument, EntityType } from 'types/entity';

interface DocumentNameAndLink {
  title: string;
  link: string;
  isEvent?: boolean;
  type: string;
}

export function findDocumentNamesAndLinks(
  documentId: string,
  crmNoteId: string | null,
  entityType: EntityType,
  documents: Document[],
  crmNotes: CrmNote[],
  entityDocuments: EntityDocument[],
): DocumentNameAndLink[] {
  const nameAndLinks: DocumentNameAndLink[] = [];
  if (entityType === 'crm-note' && crmNoteId) {
    const crmNote = crmNotes.find(n => n.id === crmNoteId);
    const entityDocument = entityDocuments.find(e => e.id === documentId);
    if (crmNote && entityDocument) {
      nameAndLinks.push({
        title: crmNote.title,
        link: `/crm-note/${crmNote.id}?entity-document-id=${documentId}`,
        type: entityType,
      });
      const entityName = entityDocument.entity.name === 'Opportunity' ? 'opportunity' : 'account';
      nameAndLinks.push(
        ...findDocumentNamesAndLinks(entityDocument.id, null, entityName, documents, crmNotes, entityDocuments),
      );
    }
  } else if (entityType === 'document' || entityType === 'uploaded-file') {
    const document = documents.find(d => d.id === documentId);
    if (document) {
      nameAndLinks.push({
        title: document.event ? document.event.subject : document.docTitle,
        link: `/document/${document.id}`,
        isEvent: !!document.event,
        type:
          entityType === 'uploaded-file'
            ? document.uploadedFile?.fileType.endsWith('pdf')
              ? 'pdf'
              : document.uploadedFile?.fileType.includes('text/plain')
              ? 'txt'
              : 'word'
            : entityType,
      });

      if (document.parent) {
        const parentId = document.parent.id;
        const parentEntityName = document.parent.entityName === 'Opportunity' ? 'opportunity' : 'account';
        nameAndLinks.push(
          ...findDocumentNamesAndLinks(parentId, null, parentEntityName, documents, crmNotes, entityDocuments),
        );
      }
    }
  } else {
    const entityDocument = entityDocuments.find(e => e.id === documentId);
    if (entityDocument) {
      nameAndLinks.push({
        title: entityDocument.name,
        link: `/${entityDocument.entity.name.toLowerCase()}/${entityDocument.id}`,
        type: entityType === 'meetings' ? entityDocument.entity.name.toLowerCase() : entityType,
      });

      if (entityDocument.parentId) {
        nameAndLinks.push(
          ...findDocumentNamesAndLinks(entityDocument.parentId, null, 'account', documents, crmNotes, entityDocuments),
        );
      }
    }
  }

  return nameAndLinks;
}

const findParentEntityIdsByDocId = (
  docId: string,
  entityDocuments: EntityDocument[],
  documents: Document[],
): string[] => {
  const parentIds: string[] = [];
  const document = documents.find(d => d.id === docId);
  if (document && document.parent) {
    const documentParentId = document.parent.id;
    parentIds.push(documentParentId);
    parentIds.push(...findParentEntityIdsByDocId(documentParentId, entityDocuments, documents));
  }

  const entityDocument = entityDocuments.find(e => e.id === docId);
  if (entityDocument) {
    parentIds.push(entityDocument.id);
    if (entityDocument.parentId) {
      parentIds.push(...findParentEntityIdsByDocId(entityDocument.parentId, entityDocuments, documents));
    }
  }
  return parentIds;
};

const findParentEntityIdsByCrmNoteId = (
  crmNoteId: string,
  crmNotes: CrmNote[],
  entityDocuments: EntityDocument[],
  documents: Document[],
): string[] => {
  const crmNote = crmNotes.find(c => c.id === crmNoteId);
  if (crmNote) {
    const entityDocument = entityDocuments.find(e => e.crm?.id === crmNote.linkedEntity.id);
    if (entityDocument) {
      return findParentEntityIdsByDocId(entityDocument.id, entityDocuments, documents);
    }
  }
  return [];
};

export function findParentEntityIds(
  docId: string | null | undefined,
  crmNoteId: string | null | undefined,
  entityDocuments: EntityDocument[],
  documents: Document[],
  crmNotes: CrmNote[],
): string[] {
  const entityIds: string[] = [];
  if (docId) {
    entityIds.push(...findParentEntityIdsByDocId(docId, entityDocuments, documents));
  } else if (crmNoteId) {
    entityIds.push(...findParentEntityIdsByCrmNoteId(crmNoteId, crmNotes, entityDocuments, documents));
  }
  return entityIds;
}

const findActiveDocHierarchy = (
  docId: string,
  entityType: EntityType,
  entityDocuments: EntityDocument[],
  documents: Document[],
): { id: string; entityType: EntityType }[] => {
  const hierarchy: { id: string; entityType: EntityType }[] = [];

  if (window.location.pathname.includes(docId)) {
    hierarchy.push({ id: docId, entityType });
  }

  const document = documents.find(d => d.id === docId);
  if (document && document.parent) {
    const documentParentId = document.parent.id;
    const parentEntityType = document.parent.entityName === 'Opportunity' ? 'opportunity' : 'account';
    hierarchy.push({
      id: documentParentId,
      entityType: parentEntityType,
    });
    hierarchy.push(...findActiveDocHierarchy(documentParentId, parentEntityType, entityDocuments, documents));
  }

  const entityDocument = entityDocuments.find(e => e.id === docId);
  if (entityDocument) {
    const entityDocumentType = entityDocument.entity.name === 'Opportunity' ? 'opportunity' : 'account';
    hierarchy.push({
      id: entityDocument.id,
      entityType: entityDocumentType,
    });
    if (entityDocument.parentId) {
      hierarchy.push(
        ...findActiveDocHierarchy(entityDocument.parentId, entityDocumentType, entityDocuments, documents),
      );
    }
  }
  return hierarchy;
};

const findActiveCrmNoteHierarchy = (
  noteId: string,
  entityType: EntityType,
  entityDocuments: EntityDocument[],
  documents: Document[],
  crmNotes: CrmNote[],
): { id: string; entityType: EntityType }[] => {
  const hierarchy: { id: string; entityType: EntityType }[] = [];
  if (window.location.pathname.includes(noteId)) {
    hierarchy.push({ id: noteId, entityType });
  }
  const crmNote = crmNotes.find(c => c.id === noteId);
  if (crmNote) {
    const entityDocument = entityDocuments.find(e => e.crm?.id === crmNote.linkedEntity.id);
    if (entityDocument) {
      hierarchy.push(...findActiveDocHierarchy(entityDocument.id, entityType, entityDocuments, documents));
    }
  }
  return hierarchy;
};

export function findActiveEntityHierarchy(
  docId: string | null | undefined,
  crmNoteId: string | null | undefined,
  type: EntityType,
  entityDocuments: EntityDocument[],
  documents: Document[],
  crmNotes: CrmNote[],
): { id: string; entityType: EntityType }[] {
  const hierarchy: { id: string; entityType: EntityType }[] = [];
  if (docId) {
    hierarchy.push(...findActiveDocHierarchy(docId, type, entityDocuments, documents));
  } else if (crmNoteId) {
    hierarchy.push(...findActiveCrmNoteHierarchy(crmNoteId, type, entityDocuments, documents, crmNotes));
  }
  return hierarchy?.length
    ? hierarchy.filter(
        (value, index, self) => index === self.findIndex(t => t.id === value.id && t.entityType === value.entityType),
      )
    : [];
}
