import React, { useState } from 'react';
import { CheckIcon } from '@heroicons/react/24/outline';

import useDocumentStore from 'stores/document';
import SpinLoader from 'components/icons/SpinLoader';
import { SearchItem } from '../../../api/search/search-items';
import { Recording } from '../../../api/recordings/get-recordings';
import NoteIcon from '../../../components/icons/NoteIcon';
import OpportunityIcon from '../../../components/icons/OpportunityIcon';
import AccountIcon from '../../../components/icons/AccountIcon';

interface Props {
  searchItem: SearchItem;
  recording: Recording;
  updateData: (objType: 'opp' | 'doc' | 'acct', objId: string) => void;
}

function DocumentButtonFromSearch({ searchItem, recording, updateData }: Props) {
  const isLinked = recording.entityOrDocumentId === searchItem.documentId;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const documentStore = useDocumentStore();
  const { documentId } = searchItem;
  if (documentId === null) {
    return null;
  }

  return (
    <button
      type="button"
      className={`flex justify-between w-full min-h-8 items-center pr-3 p-2 hover:bg-slate-100 rounded-lg ${
        isLinked ? 'cursor-default' : 'hover:bg-gray-100 hover:cursor-pointer'
      }`}
      onClick={async () => {
        setIsLoading(true);
        await documentStore.getDocument(documentId);
        updateData('doc', documentId);
        setIsLoading(false);
      }}
      disabled={isLinked || isLoading}
    >
      <div className="text-sm text-gray-500">
        <div className="flex gap-2 text-left">
          <NoteIcon className="w-5 h-5 flex-none text-blue-500 flex-shrink-0 flex-grow-0" />
          <span>{searchItem.title}</span>
        </div>
        {searchItem.parent && (
          <div className="flex items-center pl-6 text-xs mt-1">
            ({' '}
            {searchItem.parent.type === 'Opportunity' ? (
              <OpportunityIcon className="mx-1 w-3.5 h-3.5 text-orange-500 fill-orange-500" />
            ) : (
              <AccountIcon className="mx-1 w-3 h-3" />
            )}{' '}
            {searchItem.parent.name} )
          </div>
        )}
      </div>
      {isLinked && <CheckIcon className="w-5 h-5 flex-none text-blue-500" />}
      {isLoading && (
        <div>
          <SpinLoader className="animate-spin w-4 h-4 text-orange-500" />
        </div>
      )}
    </button>
  );
}

export default DocumentButtonFromSearch;
