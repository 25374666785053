import { User } from 'types/user';
import httpClient from './http-client';

export interface Response {
  user: User;
}

export default async (
  type: 'salesforce' | 'google' | 'microsoft',
  code: string,
  invitationToken: string | null,
): Promise<Response> => {
  const { data } = await httpClient.post<Response>('/register-oauth', { type, code, invitationToken });
  return data;
};
