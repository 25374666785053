import React from 'react';
import styles from './FieldBlock.module.css';
import { concat } from 'utils/styling';

interface Props {
  title: string;
  children: JSX.Element;
  isError: boolean;
}

function FieldBlock({ title, children, isError }: Props) {
  return (
    <div
      className={concat(
        `overflow-hidden relative w-full bg-white px-2 pt-1 pb-2 flex flex-col justify-between`,
        styles.field,
        isError && styles.error,
      )}
    >
      <div className="text-xs text-gray-500 py-1">{title}</div>
      {children}
    </div>
  );
}

export default FieldBlock;
