/* eslint-disable import/no-extraneous-dependencies */
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { create } from 'zustand';
import { File } from 'types/file';
import getFile from 'api/files/get-file';

export interface State {
  entities: {
    [entityId: string]: File;
  };
  fetchEntity: (entityId: string) => Promise<void>;
  upsertEntity: (entityId: string, entity: File) => void;
  removeEntity: (entityId: string) => void;
}

const useImageEntityStore = create<State>(set => ({
  entities: {},
  fetchEntity: async (entityId: string) => {
    set(state => {
      return {
        entities: {
          ...state.entities,
          [entityId]: { loading: true },
        },
      };
    });
    const entity = await getFile(entityId);
    set(state => {
      return {
        entities: {
          ...state.entities,
          [entityId]: { ...entity, loading: false },
        },
      };
    });
  },
  upsertEntity: (entityId, entity) => {
    set(state => {
      return {
        entities: {
          ...state.entities,
          [entityId]: entity,
        },
      };
    });
  },
  removeEntity: entityId => {
    set(state => {
      const newEntities = { ...state.entities };
      delete newEntities[entityId];
      return {
        entities: newEntities,
      };
    });
  },
}));

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('ImageEntity', useImageEntityStore);
}

export default useImageEntityStore;
