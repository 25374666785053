import React, { useEffect, useMemo, useState } from 'react';
import ButtonPopover from 'components/ButtonPopover';
import { ColumnConfig } from 'components/Table/types';
import { ViewSetting, ViewSettingType } from 'types/view-setting';
import getViewSettings from 'api/view-setting/get-view-settings';
import styles from './ManageViewsButton.module.css';
import BaseButton from 'components/BaseButton';
import openNewViewSettingModal from './CreateNewViewSettingModal';
import { ArrowUpTrayIcon, EllipsisVerticalIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import ResponseView from 'components/ResponseView';
import EmptyResponseIcon from 'components/icons/EmptyResponseIcon';
import useLocalStorageState from 'components/Hooks/useLocalStorageState';
import { openDeleteConfirmModal, openWarningConfirmModal } from 'components/BaseModal';
import deleteViewSetting from 'api/view-setting/delete-view-setting';
import { cloneDeep, isEqual } from 'lodash';
import { concat } from 'utils/styling';
import updateViewSetting from 'api/view-setting/update-view-setting';

interface Props {
  type: ViewSettingType;
  columns: ColumnConfig[];
  btnClassName?: string;
  localStorageKeyForCurrentView?: string;
  onColumnsUpdate: (newColumns: ColumnConfig[]) => void;
}

function ManageViewsButton({ type, columns, btnClassName, localStorageKeyForCurrentView, onColumnsUpdate }: Props) {
  const [viewSettings, setViewSettings] = useState<ViewSetting[]>();
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState('');
  const [currentView, setCurrentView] = useLocalStorageState<string>(localStorageKeyForCurrentView || '', '', false);

  const currentViewName = useMemo(() => {
    return viewSettings?.find(item => item.id === currentView)?.name;
  }, [currentView, viewSettings]);

  const currentViewModified = useMemo(() => {
    const currentViewSetting = viewSettings?.find(item => item.id === currentView);
    return currentViewSetting ? !isEqual(currentViewSetting?.view, JSON.parse(JSON.stringify(columns))) : false;
  }, [currentView, viewSettings, columns]);

  useEffect(() => {
    if (type) {
      setLoading(true);
      getViewSettings(type)
        .then(
          rsp => setViewSettings(rsp),
          () => setViewSettings([]),
        )
        .finally(() => setLoading(false));
    }
  }, [type]);

  const createNewView = async () => {
    const newView = await openNewViewSettingModal(columns);
    if (newView) {
      setViewSettings(prev => [newView, ...(prev || [])]);
      setCurrentView(newView.id);
    }
  };

  const editViewName = async (view: ViewSetting) => {
    const newView = await openNewViewSettingModal(view.view, view);
    if (newView) {
      setViewSettings(prev => prev?.map(item => (item.id === newView.id ? newView : item)));
    }
  };

  const updateViewColumns = async (view: ViewSetting) => {
    try {
      setUpdating(view.id);
      const newView = await updateViewSetting(view.id, view.name, columns);
      setViewSettings(prev => prev?.map(item => (item.id === newView.id ? newView : item)));
    } finally {
      setUpdating('');
    }
  };

  const deleteView = async (view: ViewSetting) => {
    const confirmed = await openDeleteConfirmModal(
      `Are you sure you want to delete this view?`,
      'Once the view is removed, you cannot retrieve it back.',
    );
    if (confirmed) {
      try {
        setUpdating(view.id);
        await deleteViewSetting(view.id);
        setViewSettings(prev => prev?.filter(item => item.id !== view.id));
      } finally {
        setUpdating('');
      }
    }
  };

  const applyView = async (view: ViewSetting) => {
    if (currentViewModified) {
      const confirmed = await openWarningConfirmModal(
        `Are you sure you want to apply this view?`,
        'Your current view is modified, you will lose the change if you apply this view.',
      );
      if (confirmed) {
        setCurrentView(view.id);
      } else {
        return;
      }
    } else {
      setCurrentView(view.id);
    }
    if (!isEqual(view.view, columns)) {
      onColumnsUpdate(cloneDeep(view.view));
    }
  };

  return (
    <ButtonPopover
      btnColor="primary"
      btnVariant="text"
      btnClassName={btnClassName}
      horizontalOrigin="right"
      loading={loading}
      popoverClassName={styles.popupContainer}
      closeWhenPopoverClick
      popoverContent={
        <ResponseView
          loading={loading}
          noData={!viewSettings?.length}
          noDataIcon={<EmptyResponseIcon className="w-[85px] h-[65px]" />}
          noDataMsg="You do not have saved any views..."
          noDataDesc="Save current setting as a new view."
          noDataBtnLabel="Save View"
          onButtonClick={createNewView}
        >
          <BaseButton color="primary" variant="contained" onClick={createNewView} className="!m-2 !text-sm !rounded">
            Save current view
          </BaseButton>
          <h3 className="w-full text-sm pt-1 pb-2 px-2 text-gray-400">Existing Views</h3>
          <div className="">
            {viewSettings?.map(view => (
              <div className="grid grid-cols-6 gap-2" key={view.id}>
                <div className="flex col-span-5">
                  <BaseButton
                    color="secondary"
                    variant="text"
                    className="!justify-start !text-sm text-left !h-auto flex-1 !text-gray-500"
                    contentClassName="!items-start"
                    onClick={() => {
                      applyView(view);
                    }}
                    tooltip={currentView !== view.id ? 'Use this view' : ''}
                  >
                    <span className="text-left">{view.name}</span>
                    {currentView === view.id && (
                      <span className={concat('px-1', currentViewModified ? 'text-yellow-500' : 'text-green-500')}>
                        {`(${currentViewModified ? 'modified' : 'current'})`}
                      </span>
                    )}
                  </BaseButton>
                </div>

                <div className="">
                  <ButtonPopover
                    btnColor="secondary"
                    btnVariant="text"
                    tooltip="Actions"
                    btnClassName="!min-w-[2rem] !h-8"
                    closeWhenPopoverClick
                    iconBtn
                    stopPropagation
                    menus={[
                      {
                        label: 'Save change',
                        value: 'update-setting',
                        icon: <ArrowUpTrayIcon className="w-5 h-5" />,
                      },
                      {
                        label: 'Update view name',
                        value: 'update',
                        icon: <PencilSquareIcon className="w-5 h-5" />,
                      },
                      { label: 'Delete view', value: 'delete', icon: <TrashIcon className="w-5 h-5" /> },
                    ].slice(currentView === view.id && currentViewModified ? 0 : 1)}
                    handleMenuClick={async menu => {
                      if (menu.value === 'update-setting') {
                        updateViewColumns(view);
                      }
                      if (menu.value === 'update') {
                        editViewName(view);
                      }
                      if (menu.value === 'delete') {
                        deleteView(view);
                      }
                    }}
                    horizontalOrigin="right"
                    popoverClassName="w-[12rem]"
                  >
                    <EllipsisVerticalIcon width={20} />
                  </ButtonPopover>
                </div>
              </div>
            ))}
          </div>
        </ResponseView>
      }
    >
      <span
        className={
          currentViewName ? concat(currentViewModified ? 'text-yellow-500' : 'text-green-500', 'truncate') : ''
        }
      >
        {currentViewName ? `View: ${currentViewName}` : 'Manage Views'}
      </span>
    </ButtonPopover>
  );
}

ManageViewsButton.defaultProps = {
  btnClassName: undefined,
  localStorageKeyForCurrentView: undefined,
};

export default ManageViewsButton;
