import React, { useState } from 'react';
import errors from 'utils/errors';
import useModalStore from 'stores/modal';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import config from 'utils/config';
import integrationToken from 'api/integration-token-zoom';
import Zoom from './logos/Zoom';

interface State {
  zoomClientId: string;
  zoomClientSecret: string;
  zoomAccountId: string;
  isSetZoomTokenLoading: boolean;
  isZoomTokenError: boolean;
}

function ZoomAPITokenModal({ nextStep }: { nextStep: () => void }) {
  const [state, setState] = useState<State>({
    zoomClientId: '',
    zoomClientSecret: '',
    zoomAccountId: '',
    isSetZoomTokenLoading: false,
    isZoomTokenError: false,
  });
  const modalStore = useModalStore();

  const goToOAuth = (link: string) => {
    if (!state.isSetZoomTokenLoading) {
      window.location.href = link;
    }
  };
  const buttonDisabled =
    state.zoomClientId.length === 0 ||
    state.zoomClientSecret.length === 0 ||
    state.zoomAccountId.length === 0 ||
    state.isSetZoomTokenLoading;

  return (
    <div
      className="absolute left-1/2 bg-white rounded-lg p-3 pr-6 w-[1000px]"
      style={{
        top: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      {/* model header */}
      <div className="flex justify-between items-center px-4 pt-4 pb-2 font-medium text-2xl">Add Zoom Integration</div>
      <div className="text-lg font-semibold px-4 pt-2">Method 1.</div>
      <div className="flex items-center p-4 gap-2">
        You must be a Zoom admin and please follow
        <a
          href="https://superpanel.notion.site/How-to-create-a-zoom-app-and-integrate-with-Superpanel-60100ca5ef4f4cdca175f64dc21cfc49"
          target="_blank"
          rel="noreferrer"
          className="text-blue-500 hover:underline"
        >
          How to create a zoom app and integrate with Superpanel
        </a>
        to setup .
      </div>
      <div className="flex flex-col gap-3 text-gray-500">
        <div className="flex gap-2 items-center pl-4">
          Zoom Account ID
          <input
            className="flex border rounded focus:outline-none focus:border-indigo-300 h-10 w-96 p-2"
            onChange={e => {
              setState(prevState => ({ ...prevState, zoomAccountId: e.target.value }));
            }}
            maxLength={5000}
            value={state.zoomAccountId}
          />
        </div>
        <div className="flex gap-2 items-center pl-4">
          Zoom Client ID
          <input
            type="password"
            className="flex border rounded focus:outline-none focus:border-indigo-300 h-10 w-96 p-2"
            onChange={e => {
              setState(prevState => ({ ...prevState, zoomClientId: e.target.value }));
            }}
            maxLength={5000}
            value={state.zoomClientId}
          />
        </div>
        <div className="flex gap-2 items-center pl-4">
          Zoom Client Secret
          <input
            type="password"
            className="flex border rounded focus:outline-none focus:border-indigo-300 h-10 w-96 p-2"
            onChange={e => {
              setState(prevState => ({ ...prevState, zoomClientSecret: e.target.value }));
            }}
            maxLength={5000}
            value={state.zoomClientSecret}
          />
        </div>
        {state.isZoomTokenError && (
          <div className="text-red-500 text-sm">Error: Please check your Zoom key and try again.</div>
        )}
      </div>
      <div className="my-3 flex flex-row-reverse items-center gap-3">
        <button
          className={`px-8 py-2 rounded ${
            buttonDisabled ? 'bg-gray-200 text-gray-500 cursor-default' : 'bg-blue-600 hover:bg-blue-500 text-white'
          }`}
          type="button"
          onClick={async () => {
            setState(prevState => ({ ...prevState, isSetZoomTokenLoading: true, isZoomTokenError: false }));

            try {
              await integrationToken('zoom', state.zoomAccountId, state.zoomClientId, state.zoomClientSecret);
              setState(prevState => ({ ...prevState, isSetZoomTokenLoading: false }));
              modalStore.close();
              nextStep();
            } catch (e) {
              if (e instanceof errors.IntegrationError) {
                setState(prevState => ({
                  ...prevState,
                  isSetZoomTokenLoading: false,
                  isZoomTokenError: true,
                }));
              }
            }
          }}
          disabled={buttonDisabled}
        >
          Add
        </button>
      </div>
      <div className="w-full border-b border-gray-200 my-4" />
      {/* TODO Zoom Oauth  */}
      {false && (
        <div className="mb-4">
          <div className="text-lg font-semibold px-4">Method 2.</div>
          <div className="flex gap-3 items-center">
            <div className="flex justify-between items-center p-4">
              Connect to Zoom via OAuth. (You must be a Zoom Technical admin)
            </div>
            <button
              key="gong"
              className="flex items-center justify-between px-3 py-2 border border-gray-200 rounded-lg hover:bg-gray-100 gap-4"
              onClick={() =>
                goToOAuth(
                  `https://zoom.us/oauth/authorize?response_type=code&client_id=${config.ZOOM_CLIENT_ID}&redirect_uri=${config.ZOOM_REDIRECT_URL}&from=setting`,
                )
              }
              type="button"
            >
              <div className="flex items-center gap-1">
                <Zoom className="w-10 h-10" />
                <div className="flex gap-1 items-center">
                  <div className="text-lg font-semibold mr-10">Zoom</div>
                </div>
              </div>
              <ChevronRightIcon className="w-7 h-7" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
export default ZoomAPITokenModal;
