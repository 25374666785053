/* eslint-disable react/jsx-props-no-spreading, @typescript-eslint/ban-types */
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { DndContext, DraggableAttributes, MouseSensor, useSensor, useSensors } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { v4 } from 'uuid';
import BaseButton from './BaseButton';
import DragHandlerIcon from './icons/DragHandlerIcon';
import { concat } from 'utils/styling';
import { get } from 'lodash';

interface SortableItemProps<T> {
  id: string;
  isDragDisabled: boolean;
  className?: string;
  dragHandlerStaticPos?: boolean;
  dragHandlerClassName?: string;
  horizon?: boolean;
  hideDragIcon?: boolean;
  renderListItem: (
    item: T,
    index: number,
    attributes?: DraggableAttributes,
    listeners?: Record<string, Function>,
  ) => React.ReactNode;
  item: T;
  index: number;
}
function SortableItem<T>({
  id,
  isDragDisabled,
  className,
  dragHandlerStaticPos,
  dragHandlerClassName,
  horizon,
  hideDragIcon,
  renderListItem,
  item,
  index,
}: SortableItemProps<T>) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id, disabled: isDragDisabled });

  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={concat('flex items-center relative', !horizon && 'w-full', className)}
    >
      {!isDragDisabled && !hideDragIcon && (
        <BaseButton
          className={concat(
            '!min-w-[2rem] !h-8',
            !dragHandlerStaticPos && '!absolute left-0 top-[8px]',
            dragHandlerClassName,
          )}
          hideTooltipToPreventJump
          color="secondary"
          variant="text"
          iconBtn
          {...attributes}
          {...listeners}
        >
          <DragHandlerIcon className="w-3 h-3 text-gray-500" />
        </BaseButton>
      )}
      {renderListItem(item, index, attributes, listeners)}
    </div>
  );
}

SortableItem.defaultProps = {
  className: '',
  dragHandlerStaticPos: false,
  dragHandlerClassName: '',
  horizon: undefined,
  hideDragIcon: undefined,
};

interface Props<T> {
  list: T[];
  handleReorder: (reorderParams: { fromIndex: number; toIndex: number }) => void;
  renderListItem: (
    item: T,
    index: number,
    attributes?: DraggableAttributes,
    listeners?: Record<string, Function>,
  ) => React.ReactNode;
  isDragDisabled?: (item: T) => boolean;
  listItemClassName?: string | ((item: T) => string);
  dragHandlerStaticPos?: boolean;
  dragHandlerClassName?: string;
  useListItemId?: boolean;
  filter?: (item: T) => boolean;
  horizon?: boolean;
  hideDragIcon?: boolean;
}

function ReorderableList<T>({
  list,
  handleReorder,
  renderListItem,
  isDragDisabled,
  listItemClassName,
  dragHandlerStaticPos,
  dragHandlerClassName,
  useListItemId,
  filter,
  horizon,
  hideDragIcon,
}: Props<T>) {
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
  );
  const currentListIdsRef = useRef<{ id: string; item: T }[]>([]);

  const listIds = useMemo(() => {
    return list
      .filter(item => !filter || filter(item))
      .map(item => ({
        id:
          (useListItemId === false ? '' : (get(item, 'id') as string)) ||
          currentListIdsRef.current.find(it => it.item === item)?.id ||
          v4(),
        item,
      }));
  }, [list, filter]);

  useEffect(() => {
    currentListIdsRef.current = listIds;
  }, [listIds]);

  const getIsDragDisabled = useCallback(
    (item: T) => {
      return isDragDisabled ? isDragDisabled(item) : false;
    },
    [isDragDisabled],
  );

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={event => {
        const { active, over } = event;
        if (active.id !== over?.id) {
          const oldIndex = listIds.findIndex(item => item.id === active.id);
          const newIndex = listIds.findIndex(item => item.id === over?.id);
          handleReorder({
            fromIndex: oldIndex,
            toIndex: newIndex,
          });
        }
      }}
    >
      <SortableContext items={listIds} strategy={horizon ? horizontalListSortingStrategy : verticalListSortingStrategy}>
        {listIds.map((listItem, index) => (
          <SortableItem
            key={listItem.id}
            id={listItem.id}
            isDragDisabled={getIsDragDisabled(listItem.item)}
            className={typeof listItemClassName === 'function' ? listItemClassName(listItem.item) : listItemClassName}
            dragHandlerStaticPos={dragHandlerStaticPos}
            dragHandlerClassName={dragHandlerClassName}
            horizon={horizon}
            hideDragIcon={hideDragIcon}
            item={listItem.item}
            index={index}
            renderListItem={renderListItem}
          />
        ))}
      </SortableContext>
    </DndContext>
  );
}

ReorderableList.defaultProps = {
  isDragDisabled: undefined,
  listItemClassName: '',
  dragHandlerStaticPos: false,
  dragHandlerClassName: '',
  useListItemId: undefined,
  filter: undefined,
  horizon: undefined,
  hideDragIcon: undefined,
};

export default ReorderableList;
