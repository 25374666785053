import React from 'react';

import { Field, isCRMField } from 'types/field-value';
import getFieldName from 'components/utils/GetFieldName';
import { CheckIcon } from '@heroicons/react/24/outline';
import SalesforceIcon from '../icons/SalesforceIcon';

interface Props {
  field: Field;
  isAlreadyInDoc: boolean;
  onClick: () => void;
  isSelected: boolean;
}

function FieldButton({ field, isAlreadyInDoc, onClick, isSelected }: Props): JSX.Element {
  const name = getFieldName(field);

  return (
    <button
      key={field.id}
      type="button"
      className={`flex items-center w-full p-2.5 hover:bg-slate-100 justify-between ${
        isAlreadyInDoc && 'cursor-default text-gray-400'
      }`}
      onMouseDown={e => {
        if (!isAlreadyInDoc) {
          onClick();
        }
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div>
        <div className="flex items-center gap-1">
          <span className="text-sm text-left">{name}</span>
          <span className="text-gray-400 text-xs">•</span>
          <span className="text-gray-400 text-xs">{field.entity}</span>
          {isCRMField(field) && (
            <span className="w-5 h-5">
              <SalesforceIcon className="w-5 h-5" />
            </span>
          )}
          <span className="text-gray-400 text-xs">{isAlreadyInDoc && '(added)'}</span>
        </div>
      </div>

      {isSelected && <CheckIcon className="w-5 h-5 text-green-500" />}
    </button>
  );
}

export default FieldButton;
