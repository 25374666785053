import React from 'react';

interface Props {
  className?: string;
}

function LinkIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6992 13.0251C11.032 13.4701 11.4567 13.8383 11.9443 14.1047C12.4319 14.3711 12.9711 14.5295 13.5254 14.5692C14.0796 14.6089 14.6359 14.5289 15.1565 14.3347C15.6771 14.1405 16.1499 13.8366 16.5427 13.4436L18.8677 11.1186C19.5735 10.3878 19.9641 9.40892 19.9552 8.39291C19.9463 7.3769 19.5388 6.40502 18.8203 5.68659C18.1018 4.96817 17.1299 4.56069 16.1138 4.5519C15.0978 4.54312 14.119 4.93374 13.3882 5.63964L12.0557 6.96464"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.7995 11.4758C13.4666 11.0309 13.042 10.6627 12.5544 10.3963C12.0668 10.1299 11.5276 9.97147 10.9733 9.93178C10.4191 9.89208 9.86279 9.97205 9.34218 10.1663C8.82156 10.3605 8.34881 10.6644 7.95597 11.0573L5.63097 13.3823C4.92804 14.1137 4.53994 15.0916 4.55 16.1059C4.56005 17.1203 4.96747 18.0902 5.68477 18.8075C6.40206 19.5248 7.37203 19.9322 8.38638 19.9423C9.40074 19.9524 10.3786 19.5643 11.11 18.8613L12.4355 17.5358"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

LinkIcon.defaultProps = {
  className: undefined,
};

export default LinkIcon;
