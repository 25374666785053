import React, { useEffect, useState } from 'react';
import me from 'api/me';
import useUserStore from 'stores/user';

interface State {
  hasCalledBackEnd: boolean;
}

export default function useUser(hardReset: boolean) {
  const userStore = useUserStore();
  const [state, setState] = useState<State>({
    hasCalledBackEnd: false,
  });

  useEffect(() => {
    const fetchMe = async (): Promise<void> => {
      // api /me may throw error
      try {
        const { user } = await me();
        userStore.setState({ user });
      } catch (error) {
        // todo capture GET http://localhost:8000/me 401 (Unauthorized) so that sentry won't report error
        setState({ hasCalledBackEnd: true });
        throw error;
      }
    };

    if (hardReset || userStore.user === null) {
      fetchMe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return state;
}
