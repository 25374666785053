import { ArrowDownIcon, ArrowLeftIcon, ArrowRightIcon, ArrowUpIcon } from '@heroicons/react/24/outline';
import Popover from 'components/Popover';
import DragHandlerIcon from 'components/icons/DragHandlerIcon';
import TrashIcon from 'components/icons/TrashIcon';
import React, { useCallback, useRef, useState } from 'react';
import { noop } from 'lodash';
import { concat } from 'utils/styling';
import styles from './Table.module.css';

interface Props {
  OnAdd: (offset: number) => void;
  OnDelete: () => void;
  type: 'row' | 'column';
  OnToggleVisible?: (menuVisible: boolean) => void;
  canDelete: boolean;
}

function TableCellMenuBtn({ OnAdd, OnDelete, type, OnToggleVisible, canDelete }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const btnRef = useRef<HTMLButtonElement>(null);

  const handleAdd = (offset: number) => {
    setIsOpen(false);
    OnAdd(offset);
  };

  return (
    <>
      <button
        type="button"
        className={concat(styles['float-icon-btn'], styles['float-left-icon-btn'])}
        onClick={() => {
          setIsOpen(true);
          OnToggleVisible?.(true);
        }}
        ref={btnRef}
      >
        <DragHandlerIcon className="h-2.5 w-2.5 text-gray-400" />
      </button>
      <Popover
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
          OnToggleVisible?.(false);
        }}
        anchorEl={btnRef.current}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <div className={styles['menu-container']}>
          {type === 'row' ? (
            <>
              <button type="button" onClick={() => handleAdd(0)}>
                <ArrowLeftIcon className="w-5 h-5 text-gray-600" />
                <div className="text-sm text-gray-600">Insert Left</div>
              </button>
              <button type="button" onClick={() => handleAdd(1)}>
                <ArrowRightIcon className="w-5 h-5 text-gray-600" />
                <div className="text-sm text-gray-600">Insert Right</div>
              </button>
            </>
          ) : (
            <>
              <button type="button" onClick={() => handleAdd(0)}>
                <ArrowUpIcon className="w-5 h-5 text-gray-600" />
                <div className="text-sm text-gray-600">Insert Above</div>
              </button>
              <button type="button" onClick={() => handleAdd(1)}>
                <ArrowDownIcon className="w-5 h-5 text-gray-600" />
                <div className="text-sm text-gray-600">Insert Below</div>
              </button>
            </>
          )}
          {canDelete && (
            <button type="button" onClick={OnDelete}>
              <TrashIcon className="w-5 h-5 text-gray-600" />
              <div className="text-sm text-gray-600">Delete</div>
            </button>
          )}
        </div>
      </Popover>
    </>
  );
}

TableCellMenuBtn.defaultProps = {
  OnToggleVisible: noop,
};

export default TableCellMenuBtn;
