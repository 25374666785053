import React from 'react';
import Recordings from './ContextPanelView/Recordings';
import ChatDetail from './ContextPanelView/Chats/ChatDetail';
import styles from './RightDrawerPanel.module.css';
import { Drawer } from '@mui/material';
import useQueryParamState from './Hooks/useQueryParamState';
import constants from 'utils/constants';

interface Props {
  width?: number;
}

function RightDrawerPanel({ width }: Props) {
  const [drawer] = useQueryParamState(constants.RIGHT_DRAWER_QUERY_PARAM_KEY);

  return (
    <Drawer
      open={!!drawer}
      anchor="right"
      ModalProps={{
        hideBackdrop: true,
        disableScrollLock: true,
        className: styles.rightSideDrawer,
        disableEnforceFocus: true,
        disableRestoreFocus: true,
      }}
    >
      <div style={{ width }} className={styles.rightSideDrawerContent}>
        {drawer === 'recordings' && <Recordings />}
        {drawer === 'chat' && <ChatDetail />}
      </div>
    </Drawer>
  );
}

RightDrawerPanel.defaultProps = {
  width: 500,
};

export default RightDrawerPanel;
