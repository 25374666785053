/* eslint-disable no-use-before-define */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle, no-useless-escape, no-param-reassign */
import { DEFAULT_TABLE_CELL_WIDTH } from 'components/NoteEditor/Elements/Table/TableCellElement';
import { get, max, maxBy } from 'lodash';
import { Element, Node, Text } from 'slate';
import useImageEntityStore from 'stores/image-entity';
import useUserStore from 'stores/user';
import {
  BlockType,
  BlockTypeAll,
  BlockTypeInline,
  CustomElement,
  CustomText,
  DiscoveryQuestionElement,
  ImageElement,
  LinkElement,
  ListElement,
  MentionElement,
  ProductGapElement,
  RootElement,
  TableCellElement,
  TableElement,
  TableRowElement,
  TaskElement,
} from '../components/NoteEditor/types';
import escapeHtml from './escape-html';
import { getMemberName } from './string';
import isUrl from 'is-url';
import { translateHtmlToMarkdown } from 'components/NoteEditor/utils';

const MARKDOWN_IMG_URL_GLOBAL_REGEX = /!{0,1}\[([^\[\]]*)\]\(([^()]+)\)/g;
const MARKDOWN_URL_REGEX = /^!{0,1}\[(.*)\]\(([^()]+)\)$/;
const MARKDOWN_TABLE_REGEX = /^\|.*\|$/;
const MARKDOWN_TABLE_HEADER_BODY_SEPERATE_REGEX = /^\|([ \t]{0,}[-]{0,}[ \t]{0,}\|)+$/;

export function elementToText(element: RootElement | CustomElement | CustomText): string {
  let text = '';
  if ('text' in element) {
    if (text === '') {
      text = element.text;
    } else {
      text += ` ${element.text}`;
    }
  }
  if ('children' in element) {
    element.children.forEach((e: any) => {
      const childrenText = elementToText(e);
      if (childrenText) {
        if (text === '') {
          text = childrenText;
        } else {
          text += ` ${childrenText}`;
        }
      }
    });
  }
  return text;
}

export function markDownToChildren(markdown: string): CustomText[] {
  /* Does not support underscore */
  const children: CustomText[] = [];
  if (markdown === '') {
    children.push({
      text: '',
    });
    return children;
  }

  // Regex for extracting style of line
  const styleRegex =
    /\*\*(?<bold>[^*]+)\*\*|\<i\>(?<italic>[^\<\>]+)\<\/i\>|\<u\>(?<underline>[^\<\>]+)\<\/u\>|`(?<code>[^`]+)`|\<s\>(?<strikethrough>[^\<\>]+)\<\/s\>|\[{0,1}!{0,1}\[(?<urlName>([^\[]*))\]\((?<urlLink>[^\[\]]+)\)|<(?<url>.*)>|(?<normal>.+?)|(?<whiteSpace>\s+?)/gm;
  const matches = markdown.matchAll(styleRegex);
  const matchesArray = [...matches];
  let normalStr = '';
  for (const [idx, match] of matchesArray.entries()) {
    const { bold, italic, underline, code, strikethrough, urlName, urlLink, url, normal, whiteSpace } =
      match.groups as {
        bold?: string;
        italic?: string;
        underline?: string;
        code?: string;
        strikethrough?: string;
        urlName?: string;
        urlLink?: string;
        url?: string;
        normal?: string;
        whiteSpace?: string;
      };
    // this is to increase speed for big amount of text paste
    // otherwise it's slow due to one char appending at a time
    if (normal || whiteSpace) {
      normalStr += normal || whiteSpace;
      // handle last element
      if (idx === matchesArray.length - 1) {
        children.push({
          text: normalStr,
        });
      }
    } else {
      if (normalStr.length > 0) {
        children.push({
          text: normalStr,
        });
        normalStr = '';
      }
      if (bold) {
        children.push({
          bold: true,
          text: bold,
        });
      } else if (italic) {
        children.push({
          italic: true,
          text: italic,
        });
      } else if (underline) {
        children.push({
          underline: true,
          text: underline,
        });
      } else if (code) {
        children.push({
          code: true,
          text: code,
        });
      } else if (strikethrough) {
        children.push({
          strikethrough: true,
          text: strikethrough,
        });
      } else if (urlLink) {
        // only add non-image link for children nodes, current we just allow image as block node
        const isImgLink = urlName?.includes('](') && urlName?.includes(')');
        // when it is image link
        if (isImgLink) {
          const imgName = urlName?.split('](')?.[0] ?? '';
          const linkTxt = urlName?.split(')').slice(-1)[0] ?? '';
          const linkContent = imgName ? `${imgName} ${linkTxt}` : linkTxt;
          children.push({
            type: BlockTypeInline.Link,
            link: urlLink || '',
            beingEditedBy: null,
            children: markDownToChildren(linkContent || urlLink || ''),
          } as any);
          children.push({
            text: '',
          });
        } else {
          children.push({
            type: BlockTypeInline.Link,
            link: urlLink || urlName || '',
            beingEditedBy: null,
            children: markDownToChildren(urlName || urlLink || ''),
          } as any);
          children.push({
            text: '',
          });
        }
      } else if (url) {
        if (isUrl(url)) {
          children.push({
            type: BlockTypeInline.Link,
            link: url,
            beingEditedBy: null,
            children: [{ text: url }],
          } as any);
          children.push({
            text: '',
          });
        } else {
          children.push({
            text: `<${url}>`,
          });
        }
      }
    }
  }
  return children;
}

function validateList(value: string): { type: BlockType; indentLevel: number; text: string } {
  // Checking bullet list
  const bulletListRegex = /^(?<space>\s*)\* (?<text>.*)$/g;
  const bulletMatches = Array.from(value.matchAll(bulletListRegex));
  if (bulletMatches.length === 1) {
    const { space, text } = bulletMatches[0].groups as { space: string; text: string };
    return {
      type: BlockType.BulletedList,
      indentLevel: space.length > 0 ? Math.floor(space.length / 3) : 0,
      text,
    };
  }

  // Checking number list
  const numberListRegex = /^(?<space>\s*)\d\. (?<text>.*)$/g;
  const numberListMatches = Array.from(value.matchAll(numberListRegex));
  if (numberListMatches.length === 1) {
    const { space, text } = numberListMatches[0].groups as { space: string; text: string };
    return {
      type: BlockType.OrderedList,
      indentLevel: space.length > 0 ? Math.floor(space.length / 3) : 0,
      text,
    };
  }

  return {
    type: BlockType.Paragraph,
    indentLevel: 0,
    text: value,
  };
}

function getSlateNodeContentString(node: CustomElement | CustomText): string {
  let content = '';
  if (Text.isText(node)) {
    content = `${content}${node.text || ''}`;
  } else {
    node.children.forEach((child: CustomText) => {
      content = `${content}${getSlateNodeContentString(child)}`;
    });
  }
  return content;
}

function markdownTableToElement(rows: string[]): TableElement {
  const rowCells = rows.map(row =>
    row
      .slice(1, row.length - 2)
      .split('|')
      .map(cell => cell.trim()),
  );
  const colCount = max(rowCells.map(row => row.length)) as number;
  const rowElements = Array.from(
    { length: rowCells.length },
    (_rowItem, rowIndex) =>
      ({
        type: BlockType.TableRow,
        children: Array.from({ length: colCount }, (_colItem, colIndex) => {
          const cellContent = (rowCells[rowIndex][colIndex] || '').trim();
          return {
            type: BlockType.TableCell,
            width: DEFAULT_TABLE_CELL_WIDTH,
            children: markDownToChildren(cellContent),
          } as TableCellElement;
        }),
      } as TableRowElement),
  );
  // adjust cell width according to cell content length
  const cellContentLengths = rowElements.map(row =>
    row.children.map(
      (cell: TableCellElement) => maxBy(getSlateNodeContentString(cell).split('<br>'), 'length')?.length || 0,
    ),
  );
  const colWidths = Array.from({ length: colCount }, (_colItem, colIndex) => {
    const maxContentLength = maxBy(cellContentLengths.map(row => row[colIndex])) ?? 0;
    return Math.min(Math.max(maxContentLength * 6, DEFAULT_TABLE_CELL_WIDTH), DEFAULT_TABLE_CELL_WIDTH * 3);
  });
  // set cell width and replace <br> with \n
  rowElements.forEach(row =>
    row.children.forEach((cell: TableCellElement, cellIndex: number) => {
      cell.width = colWidths[cellIndex];
      cell.children.forEach((cellTxt: CustomText) => {
        if (Text.isText(cellTxt)) {
          cellTxt.text = cellTxt.text.replace(/<br>/g, '\n');
        }
      });
    }),
  );
  return {
    type: BlockType.Table,
    children: rowElements,
  } as TableElement;
}

export function markDownToElements(markdown: string): CustomElement[] {
  const elements: CustomElement[] = [];
  const lines = markdown.split('\n');
  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < lines.length; index++) {
    const line = lines[index];
    const lineTrim = line.trim();
    if (!lineTrim) {
      // eslint-disable-next-line no-continue
      continue;
    }
    const imgPatternMatch = lineTrim.match(MARKDOWN_IMG_URL_GLOBAL_REGEX);
    // when have image or link image
    if (imgPatternMatch && (lineTrim.startsWith('![') || lineTrim.startsWith('[!['))) {
      imgPatternMatch.forEach(urlSection => {
        const urlMatch = urlSection.trim().match(MARKDOWN_URL_REGEX);
        if (!urlMatch) {
          return;
        }
        const name = urlMatch[1];
        const url = urlMatch[2]?.trim();
        // image
        if (urlSection.startsWith('!')) {
          elements.push({
            type: BlockType.Image,
            fileId: '',
            url,
            isInput: false,
            fileName: (name || '').trim() || url,
            children: [{ text: '' }],
          } as ImageElement);
        } else {
          // link
          elements.push({
            type: BlockType.Paragraph,
            children: [
              {
                type: BlockTypeInline.Link,
                link: url,
                beingEditedBy: null,
                children: [{ text: name }],
              } as CustomElement,
              { text: '' },
            ],
          } as CustomElement);
        }
      });
    } else if (lineTrim.startsWith('#')) {
      if (!lineTrim.match(/^#+$/)) {
        const count = lineTrim.match(/^#+/)?.[0]?.length ?? 1;
        elements.push({
          type: count <= 1 ? BlockType.H1 : BlockType.H2,
          indentLevel: 0,
          children: markDownToChildren(lineTrim.slice(count).trim()),
        });
      }
    } else if (lineTrim.startsWith('-')) {
      if (!lineTrim.match(/^-+$/)) {
        elements.push({
          type: BlockType.BulletedList,
          indentLevel: 0,
          children: markDownToChildren(lineTrim.slice(1).trim()),
        });
      }
    } else {
      const tableMatch = lineTrim.match(MARKDOWN_TABLE_REGEX);
      if (tableMatch) {
        const rows = [lineTrim];
        let rowIndex = 1;
        while ((lines[index + rowIndex]?.trim() ?? '').match(MARKDOWN_TABLE_REGEX)) {
          rows.push(lines[index + rowIndex].trim());
          rowIndex += 1;
        }
        elements.push(markdownTableToElement(rows.filter(row => !MARKDOWN_TABLE_HEADER_BODY_SEPERATE_REGEX.test(row))));
        index += rowIndex - 1;
        // eslint-disable-next-line no-continue
        continue;
      }

      const { type, indentLevel, text } = validateList(line);
      const children = markDownToChildren(text);
      if (children.length) {
        elements.push({
          type,
          indentLevel,
          children,
        });
      }
    }
  }
  return elements;
}

export function salesforceHtmlToElements(html: string): CustomElement[] {
  const markdown = translateHtmlToMarkdown(html);
  return markDownToElements(markdown);
}

export function normalizeElements(elements: ListElement[]): ListElement[] {
  const normalizedElements: ListElement[] = [];
  elements.forEach((element, index) => {
    const prevElement = elements[index - 1];
    if (!prevElement || element.indentLevel === 0) {
      /* Initial Starting */
      normalizedElements.push({
        type: element.type,
        indentLevel: 0,
        children: element.children.filter(
          (e: Node) => (e as CustomElement).type === BlockTypeInline.Link || (Text.isText(e) && e.text),
        ),
      });
    } else {
      let indentLevel = element.indentLevel as number;
      const maxIndentLevel = normalizedElements[normalizedElements.length - 1].indentLevel ?? 0 + 1;
      if (indentLevel > maxIndentLevel) {
        indentLevel = maxIndentLevel;
      }

      normalizedElements.push({
        type: element.type,
        indentLevel,
        children: element.children.filter(
          (e: Node) => (e as CustomElement).type === BlockTypeInline.Link || (Text.isText(e) && e.text),
        ),
      });
    }
  });
  return normalizedElements;
}

export function convertListElementsToHtml(
  close: boolean,
  currentIndex: number,
  type: 'ul' | 'ol',
  listElements: ListElement[],
  nextCurrentIndentLevel: boolean,
): string {
  const bulletElement = listElements[currentIndex];
  const nextElement = listElements[currentIndex + 1];
  let html = '';
  if (close) {
    html += `<${type}>`;
  }

  html += `<li>${elementToHtml(bulletElement)}`;
  if (nextElement && nextElement.indentLevel > bulletElement.indentLevel) {
    html += convertListElementsToHtml(true, currentIndex + 1, type, listElements, true);
  }
  html += `</li>`;

  for (let i = currentIndex + 1; i < listElements.length; i += 1) {
    const element = listElements[i];
    if (element.indentLevel === bulletElement.indentLevel && nextCurrentIndentLevel) {
      html += convertListElementsToHtml(false, i, type, listElements, false);
    } else if (element.indentLevel < bulletElement.indentLevel) {
      break;
    }
  }

  if (close) {
    html += `</${type}>`;
  }
  return html;
}

const getOpeningTag = (lastElementType: null | BlockTypeAll, currentElementType: null | BlockTypeAll): string => {
  if (lastElementType === currentElementType) {
    return '';
  }
  if (currentElementType === BlockType.BulletedList) {
    return '<ul>';
  }
  if (currentElementType === BlockType.OrderedList) {
    return '<ol>';
  }
  return '';
};

const getClosingTag = (lastElementType: null | BlockTypeAll, currentElementType: null | BlockTypeAll): string => {
  if (lastElementType === currentElementType) {
    return '';
  }
  if (lastElementType === BlockType.BulletedList) {
    return '</ul>';
  }
  if (lastElementType === BlockType.OrderedList) {
    return '</ol>';
  }
  return '';
};

const getStyleString = (element: string, styles: { [elementType: string]: string }): string => {
  if (styles[element]) {
    return ` style="${styles[element]}"`;
  }
  return '';
};

const formatTextNodeToHtml = (textNode: Text): string => {
  const formatTags = {
    code: 'code',
    bold: 'b',
    italic: 'i',
    underline: 'u',
    strikethrough: 's',
  };
  const tags = Object.keys(formatTags)
    .filter(key => get(textNode, [key]) === true)
    .map(key => get(formatTags, [key]));
  return `${tags.map(tag => `<${tag}>`).join('')}${escapeHtml(textNode.text)}${tags.map(tag => `</${tag}>`).join('')}`;
};

export const elementToHtml = (
  element: Element,
  styles: { [elementType: string]: string } = {},
  noLink = false,
  singleBlock = false,
): string => {
  let html = '';
  let lastElementType: null | BlockTypeAll = null;

  for (let childIndex = 0; childIndex < element.children.length; childIndex += 1) {
    const child = element.children[childIndex];
    if (Text.isText(child)) {
      const textChild = child as Text;
      if (textChild.text !== '') {
        html = `${html}${formatTextNodeToHtml(textChild)}`;
      } else {
        html += singleBlock ? '' : `<br>`;
      }
    } else if (Element.isElement(child)) {
      const elementChild = child as CustomElement;
      let htmlElement = '';
      if (elementChild.type === BlockType.BulletedList) {
        const listElements: ListElement[] = [];
        let listItem = elementChild;
        while (listItem.type === BlockType.BulletedList) {
          listElements.push(listItem as ListElement);
          listItem = element.children[childIndex + 1] as CustomElement;
          if ((listItem && listItem.type !== BlockType.BulletedList) || !listItem) {
            break;
          }
          childIndex += 1;
        }
        const normalizedElements = normalizeElements(listElements);
        htmlElement = convertListElementsToHtml(false, 0, 'ul', normalizedElements, true);
      } else if (elementChild.type === BlockType.OrderedList) {
        const listElements: ListElement[] = [];
        let listItem = elementChild;
        while (listItem.type === BlockType.OrderedList) {
          listElements.push(listItem as ListElement);
          listItem = element.children[childIndex + 1] as CustomElement;
          if ((listItem && listItem.type !== BlockType.OrderedList) || !listItem) {
            break;
          }
          childIndex += 1;
        }
        const normalizedElements = normalizeElements(listElements);
        htmlElement = convertListElementsToHtml(false, 0, 'ol', normalizedElements, true);
      } else if (elementChild.type === BlockType.Paragraph) {
        htmlElement = `<p${getStyleString('p', styles)}>${elementToHtml(elementChild)}</p>`;
      } else if (elementChild.type === BlockType.H1 || elementChild.type === BlockType.H2) {
        htmlElement = `<br><p${getStyleString(
          elementChild.type === BlockType.H1 ? 'h1' : 'h2',
          styles,
        )}><strong>${elementToHtml(elementChild)}</strong></p>`;
      } else if (elementChild.type === BlockType.DiscoveryQuestion) {
        const { discoveryQuestionId } = elementChild as DiscoveryQuestionElement;
        htmlElement = `<p${getStyleString('question', styles)}>$DISCOVERY_QUESTION_ID_${
          discoveryQuestionId || ''
        }_$DISCOVERY_QUESTION_ID</p>`;
      } else if (elementChild.type === BlockType.ProductGap) {
        const { productGapId } = elementChild as ProductGapElement;
        if (productGapId)
          htmlElement = `<p${getStyleString('gap', styles)}>$PRODUCT_GAP_ID_${productGapId || ''}_$PRODUCT_GAP_ID</p>`;
      } else if (elementChild.type === BlockType.Task) {
        const { taskId } = elementChild as TaskElement;
        if (taskId) htmlElement = `<p${getStyleString('task', styles)}>$TASK_ID_${taskId || ''}_$TASK_ID</p>`;
      } else if (elementChild.type === BlockTypeInline.Link) {
        const { link } = elementChild as LinkElement;
        htmlElement = noLink
          ? `<span${getStyleString('a', styles)}>${elementToHtml(elementChild)}</span>`
          : `<a href="${link}"${getStyleString('a', styles)}>${elementToHtml(elementChild)}</a>`;
      } else if (elementChild.type === BlockTypeInline.Mention) {
        const { mentionUserId } = elementChild as MentionElement;
        const member = useUserStore.getState().members?.find(item => item.id === mentionUserId);
        htmlElement = `<span${getStyleString('mention', styles)}>@${getMemberName(member)}</span>`;
      } else if (elementChild.type === BlockType.Table) {
        const tableElement = elementChild as TableElement;
        htmlElement = `<table${getStyleString('table', styles)}><tbody>${tableElement.children
          .map((row: TableRowElement) => {
            return `<tr${getStyleString('tr', styles)}>${row.children
              .map((cell: TableCellElement) => {
                return `<td${getStyleString('td', styles)}>${elementToHtml(cell)}</td>`;
              })
              .join('')}</tr>`;
          })
          .join('')}</tbody></table>`;
      } else if (elementChild.type === BlockType.Image) {
        const imgElement = elementChild as ImageElement;
        htmlElement = `<img src="${imgElement.url || 'blank'}" alt="${
          imgElement.fileName || (useImageEntityStore.getState().entities[imgElement.fileId || '']?.fileName ?? '')
        }"${getStyleString('img', styles)}>`;
      } else if (![BlockType.TableDialog].includes(elementChild.type)) {
        htmlElement = `<p>${elementToHtml(elementChild)}</p>`;
      }
      html = `${html}${getClosingTag(lastElementType, elementChild.type)}${getOpeningTag(
        lastElementType,
        elementChild.type,
      )}${htmlElement}`;
      lastElementType = elementChild.type;
    }
  }
  html = `${html}${getClosingTag(lastElementType, null)}`;
  return html;
};

export default {
  elementToText,
  salesforceHtmlToElements,
  normalizeElements,
  convertListElementsToHtml,
  elementToHtml,
  markDownToElements,
};
