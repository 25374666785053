import React from 'react';

interface Props {
  className?: string;
}

function CopyIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6998 6.9248H8.2248C7.50683 6.9248 6.9248 7.50683 6.9248 8.2248V15.6998C6.9248 16.4178 7.50683 16.9998 8.2248 16.9998H15.6998C16.4178 16.9998 16.9998 16.4178 16.9998 15.6998V8.2248C16.9998 7.50683 16.4178 6.9248 15.6998 6.9248Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.825 11.575H3.05C2.63891 11.575 2.24467 11.4117 1.95398 11.121C1.6633 10.8303 1.5 10.4361 1.5 10.025V3.05C1.5 2.63891 1.6633 2.24467 1.95398 1.95398C2.24467 1.6633 2.63891 1.5 3.05 1.5H10.025C10.4361 1.5 10.8303 1.6633 11.121 1.95398C11.4117 2.24467 11.575 2.63891 11.575 3.05V3.825"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

CopyIcon.defaultProps = {
  className: undefined,
};

export default CopyIcon;
