import React from 'react';

interface Props {
  className?: string;
}

function ExternalLinkIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6665 12.7785V17.445C16.6665 17.6493 16.6263 17.8515 16.5481 18.0402C16.4699 18.229 16.3553 18.4004 16.2109 18.5449C16.0665 18.6893 15.895 18.8039 15.7063 18.8821C15.5175 18.9603 15.3153 19.0005 15.111 19.0005H6.5555C6.14296 19.0005 5.74731 18.8366 5.4556 18.5449C5.16388 18.2532 5 17.8575 5 17.445V8.88948C5 8.47694 5.16388 8.08129 5.4556 7.78958C5.74731 7.49787 6.14296 7.33398 6.5555 7.33398H11.222"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.334 5H19.0005V9.6665"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4443 13.5555L18.9998 5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

ExternalLinkIcon.defaultProps = {
  className: undefined,
};

export default ExternalLinkIcon;
