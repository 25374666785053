import React, { useEffect, useState } from 'react';
import fetchUsers, { User } from 'admin-src/api/fetch-users';
import SpinLoader from 'components/icons/SpinLoader';
import { useNavigate } from 'react-router-dom';

interface State {
  isLoading: boolean;
  users: User[];
}

function AdminMain() {
  const [state, setState] = useState<State>({
    isLoading: true,
    users: [],
  });
  const navigate = useNavigate();

  useEffect(() => {
    const getUsers = async () => {
      setState(prevState => ({ ...prevState, isLoading: true, isAuthorized: true }));
      const users = await fetchUsers();
      setState(prevState => ({ ...prevState, users, isLoading: false }));
    };

    getUsers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (state.isLoading) {
    return <SpinLoader className="w-4 h-4 animate-spin" />;
  }

  return (
    <div className="flex gap-2 m-4">
      <button
        type="button"
        className="p-2 bg-gray-100"
        onClick={() => {
          navigate('/admin/teams');
        }}
      >
        Team
      </button>
      <button
        type="button"
        className="p-2 bg-gray-100"
        onClick={() => {
          navigate('/admin/users');
        }}
      >
        User
      </button>
    </div>
  );
}

export default AdminMain;
