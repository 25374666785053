import React, { useRef, useState } from 'react';

import { DateFilterType } from 'components/Table/types';
import CaretIcon from 'components/icons/CaretIcon';
import Popover from 'components/Popover';
import { FilterProps } from './type';
import BaseDatePicker from '../../BaseDatePicker';
import { DateRange } from 'react-day-picker';

type DateValue = undefined | Date | Date[];

type Props = FilterProps<DateFilterType, DateValue>;

function DateFilter({ name, type, value, onFilterUpdate, onClose }: Props) {
  const [filterType, setFilterType] = useState<DateFilterType>(type);
  const [isFilterTypeOpen, setIsFilterTypeOpen] = useState(false);

  const from = value && Array.isArray(value) ? value[0] : undefined;
  const to = value && Array.isArray(value) ? value[1] : undefined;

  const [dateRangeValue, setDateRangeValue] = useState<DateRange>({ from, to });

  const ref = useRef<HTMLButtonElement>(null);

  const updateFilterType = (newFilterType: DateFilterType) => {
    setFilterType(newFilterType);
    if (newFilterType === 'Is empty' || newFilterType === 'Is not empty') {
      onFilterUpdate({
        type: newFilterType,
        value: '',
      });
    } else if (newFilterType === 'Is between' && !Array.isArray(value)) {
      onFilterUpdate(undefined);
    } else if (newFilterType !== 'Is between' && Array.isArray(value)) {
      onFilterUpdate(undefined);
    } else if (value !== undefined) {
      onFilterUpdate({
        value: Array.isArray(value) ? [value[0].toISOString(), value[1].toISOString()] : value.toISOString(),
        type: newFilterType,
      });
    } else {
      onFilterUpdate(undefined);
    }
  };

  const updateFilterDates = (fromDate?: Date, toDate?: Date) => {
    let newValue: null | [string, string] = null;

    setDateRangeValue({
      from: fromDate,
      to: toDate,
    });
    if (fromDate && toDate) {
      newValue = [fromDate.toISOString(), toDate.toISOString()];
    }
    if (newValue !== null) {
      onFilterUpdate({
        type: filterType,
        value: newValue,
      });
    } else {
      onFilterUpdate(undefined);
    }
  };

  const updateFilterDate = (newDate: Date | null) => {
    if (!newDate) {
      onFilterUpdate(undefined);
    } else {
      onFilterUpdate({
        type: filterType,
        value: newDate.toISOString(),
      });
    }
  };

  return (
    <div className="px-2 pt-1 pb-2">
      <div className="flex items-center text-xs">
        <div className="text-gray-400">{name}</div>
        <button
          ref={ref}
          type="button"
          className="flex items-center rounded hover:bg-gray-100 px-2 py-2 text-sm"
          onClick={() => setIsFilterTypeOpen(true)}
        >
          <div>{filterType.toLowerCase()}</div>
          <CaretIcon className="w-4 h-4 rotate-180" />
        </button>
        <Popover
          anchorEl={ref.current}
          onClose={() => setIsFilterTypeOpen(false)}
          isOpen={isFilterTypeOpen}
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        >
          <div className="flex flex-col p-1">
            <button
              type="button"
              className="px-2 py-1 text-sm text-left rounded hover:bg-gray-100"
              onClick={() => {
                updateFilterType('Is');
                setIsFilterTypeOpen(false);
              }}
            >
              Is
            </button>
            <button
              type="button"
              className="px-2 py-1 text-sm text-left rounded hover:bg-gray-100"
              onClick={() => {
                updateFilterType('Is before');
                setIsFilterTypeOpen(false);
              }}
            >
              Is before
            </button>
            <button
              type="button"
              className="px-2 py-1 text-sm text-left rounded hover:bg-gray-100"
              onClick={() => {
                updateFilterType('Is after');
                setIsFilterTypeOpen(false);
              }}
            >
              Is after
            </button>
            <button
              type="button"
              className="px-2 py-1 text-sm text-left rounded hover:bg-gray-100"
              onClick={() => {
                updateFilterType('Is on or before');
                setIsFilterTypeOpen(false);
              }}
            >
              Is on or before
            </button>
            <button
              type="button"
              className="px-2 py-1 text-sm text-left rounded hover:bg-gray-100"
              onClick={() => {
                updateFilterType('Is on or after');
                setIsFilterTypeOpen(false);
              }}
            >
              Is on or after
            </button>
            <button
              type="button"
              className="px-2 py-1 text-sm text-left rounded hover:bg-gray-100"
              onClick={() => {
                updateFilterType('Is between');
                setIsFilterTypeOpen(false);
              }}
            >
              Is between
            </button>
            <button
              type="button"
              className="px-2 py-1 text-sm text-left rounded hover:bg-gray-100"
              onClick={() => {
                updateFilterType('Is empty');
                setIsFilterTypeOpen(false);
              }}
            >
              Is empty
            </button>
            <button
              type="button"
              className="px-2 py-1 text-sm text-left rounded hover:bg-gray-100"
              onClick={() => {
                updateFilterType('Is not empty');
                setIsFilterTypeOpen(false);
              }}
            >
              Is not empty
            </button>
          </div>
        </Popover>
      </div>
      <div className="my-1 flex justify-center items-center">
        {filterType === 'Is between' ? (
          <BaseDatePicker
            mode="range"
            selected={dateRangeValue}
            onSelect={d => {
              updateFilterDates(d?.from, d?.to);
            }}
          />
        ) : (
          !['Is empty', 'Is not empty'].includes(filterType) && (
            <BaseDatePicker
              mode="single"
              selected={value ? (value as Date) : undefined}
              onSelect={d => updateFilterDate(d || null)}
            />
          )
        )}
      </div>
    </div>
  );
}

export default DateFilter;
