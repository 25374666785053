import { EntityType } from '../../types/entity';
import { RecentlyViewedDoc } from '../../types/user';
import httpClient from '../http-client';

export interface EffectedDocuments {
  [documentId: string]: string;
}

interface Response {
  effectedDocuments: EffectedDocuments;
  recentlyViewedDocs: RecentlyViewedDoc[];
}

export default async (docType: EntityType, documentId: string, crmNoteId: string | null): Promise<Response> => {
  const { data } = await httpClient.post<Response>(`/user/history`, {
    documentId,
    crmNoteId,
    docType,
  });
  return data;
};
