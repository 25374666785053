import React from 'react';

interface Props {
  className?: string;
}

function EmptyHomeIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="657"
      height="342"
      viewBox="0 0 657 342"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3" filter="url(#filter0_i_11870_211906)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M245.01 47.9103C245.435 35.726 277.172 38.4725 289.414 39.6479C301.657 40.8233 302.768 24.1319 292.205 21.6508C281.643 19.1697 262.488 20.4501 252.563 18.1599C242.638 15.8696 255.193 3.58681 241.67 2.10629C228.147 0.625873 200.185 1.99655 191.849 1.69578C178.756 1.22337 172.038 16.9909 194.977 17.7064C201.303 17.9036 202.572 31.9113 181.219 28.0635C167.82 25.6489 141.529 25.5932 136.059 31.7579C132.921 35.2949 134.009 44.7975 139.404 50.0449C143.411 53.9429 156.156 54.7029 161.149 55.604C172.861 57.7181 170.546 69.0625 166.552 71.3984C157.101 76.9258 131.163 66.494 130.467 81.6722C130.16 88.3793 153.761 89.4622 163.438 89.3802C187.172 89.1794 202.766 91.2946 203.861 83.196C204.959 75.0658 189.112 80.5534 189.894 70.5583C190.302 65.3353 189.208 54.237 199.375 54.8195C185.455 54.0219 244.436 64.3664 245.01 47.9103Z"
          fill="url(#paint0_linear_11870_211906)"
        />
      </g>
      <g opacity="0.2" filter="url(#filter1_i_11870_211906)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M420.862 119.649C420.831 107.458 452.648 109.014 464.926 109.73C477.204 110.446 477.69 93.7243 467.042 91.6407C456.394 89.5569 437.3 91.5538 427.297 89.6369C417.293 87.72 429.379 74.9755 415.81 74.0026C402.241 73.0297 374.351 75.4467 366.009 75.4583C352.907 75.4766 346.784 91.4847 369.734 91.3405C376.063 91.3008 377.856 105.251 356.374 102.206C342.893 100.295 316.618 101.224 311.384 107.589C308.381 111.241 309.823 120.696 315.41 125.738C319.561 129.483 332.325 129.765 337.348 130.479C349.132 132.152 347.243 143.576 343.339 146.059C334.101 151.937 307.791 142.484 307.664 157.678C307.608 164.392 331.233 164.59 340.9 164.145C364.61 163.056 380.272 164.585 381.063 156.452C381.856 148.286 366.226 154.363 366.632 144.346C366.845 139.111 365.336 128.062 375.518 128.263C361.578 127.987 420.905 136.115 420.862 119.649Z"
          fill="url(#paint1_linear_11870_211906)"
        />
      </g>
      <g opacity="0.205804">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M373.716 237.701C371.474 239.755 362.744 235.877 359.202 229.682C355.66 223.487 361.989 221.149 353.152 211.297C344.315 201.444 338.69 197.71 343.659 181.128C348.628 164.547 341.206 155.509 350.727 151.04C360.248 146.57 366.023 160.752 365.142 173.33C364.26 185.908 366.557 188.472 375.92 187.006C385.283 185.541 377.692 204.117 377.762 208.142C377.832 212.166 383.654 219.542 382.572 225.187C381.489 230.831 375.958 235.646 373.716 237.701Z"
          fill="url(#paint2_linear_11870_211906)"
        />
        <path
          d="M367.926 220.262C367.872 217.173 368.484 207.535 372.626 201.6"
          stroke="url(#paint3_linear_11870_211906)"
          strokeWidth="6"
          strokeLinecap="round"
        />
        <path
          d="M360.975 202.353C360.921 199.264 352.213 197.247 347.867 191.46"
          stroke="url(#paint4_linear_11870_211906)"
          strokeWidth="6"
          strokeLinecap="round"
        />
        <path
          d="M379.084 252.08C379.061 250.766 376.695 244.881 374.413 238.055C367.267 216.678 355.89 190.4 355.57 172.059"
          stroke="url(#paint5_linear_11870_211906)"
          strokeWidth="6"
          strokeLinecap="round"
        />
      </g>
      <path
        d="M190.964 260.918C188.994 244.87 196.309 193.559 211.075 164.161"
        stroke="url(#paint6_linear_11870_211906)"
        strokeWidth="6"
        strokeLinecap="round"
      />
      <ellipse
        cx="4.688"
        cy="14.7118"
        rx="4.688"
        ry="14.7118"
        transform="matrix(0.79855 -0.601929 0.601701 0.798721 182.172 152.07)"
        fill="url(#paint7_linear_11870_211906)"
      />
      <ellipse
        cx="4.68886"
        cy="14.7092"
        rx="4.68886"
        ry="14.7092"
        transform="matrix(0.156389 0.987696 -0.987681 0.15648 236.922 165.707)"
        fill="url(#paint8_linear_11870_211906)"
      />
      <ellipse
        cx="4.68887"
        cy="14.7091"
        rx="4.68887"
        ry="14.7091"
        transform="matrix(0.104498 0.994525 -0.994519 0.104559 231.195 188.688)"
        fill="url(#paint9_linear_11870_211906)"
      />
      <ellipse
        cx="4.68888"
        cy="14.7091"
        rx="4.68888"
        ry="14.7091"
        transform="matrix(0.104498 0.994525 -0.994519 0.104559 226.664 210.094)"
        fill="url(#paint10_linear_11870_211906)"
      />
      <ellipse
        cx="4.68777"
        cy="14.7126"
        rx="4.68777"
        ry="14.7126"
        transform="matrix(0.898743 0.438476 -0.438266 0.898845 220.914 132.41)"
        fill="url(#paint11_linear_11870_211906)"
      />
      <ellipse
        cx="4.68827"
        cy="14.711"
        rx="4.68827"
        ry="14.711"
        transform="matrix(0.669021 -0.743243 0.743046 0.66924 171.906 178.465)"
        fill="url(#paint12_linear_11870_211906)"
      />
      <ellipse
        cx="4.68841"
        cy="14.7106"
        rx="4.68841"
        ry="14.7106"
        transform="matrix(0.587671 -0.8091 0.808934 0.587899 165.414 203.5)"
        fill="url(#paint13_linear_11870_211906)"
      />
      <g opacity="0.2">
        <path
          d="M182.015 266.909C173.969 256.608 157.678 218.001 155.673 191.477"
          stroke="#A1B8E3"
          strokeWidth="6"
          strokeLinecap="round"
        />
        <ellipse
          cx="3.79086"
          cy="11.8929"
          rx="3.79086"
          ry="11.8929"
          transform="matrix(0.374511 -0.927222 0.927145 0.374702 130.609 195.137)"
          fill="#A1B8E3"
        />
        <ellipse
          cx="3.79055"
          cy="11.8939"
          rx="3.79055"
          ry="11.8939"
          transform="matrix(0.642676 0.766138 -0.765951 0.642899 174.227 181.785)"
          fill="#A1B8E3"
        />
        <ellipse
          cx="3.79061"
          cy="11.8937"
          rx="3.79061"
          ry="11.8937"
          transform="matrix(0.601701 0.798721 -0.79855 0.601929 179.828 200.09)"
          fill="#A1B8E3"
        />
        <ellipse
          cx="3.79061"
          cy="11.8937"
          rx="3.79061"
          ry="11.8937"
          transform="matrix(0.601701 0.798721 -0.79855 0.601929 185.594 216.82)"
          fill="#A1B8E3"
        />
        <ellipse
          cx="3.7899"
          cy="11.8959"
          rx="3.7899"
          ry="11.8959"
          transform="matrix(0.996192 -0.0871814 0.0871301 0.996197 149.273 165.375)"
          fill="#A1B8E3"
        />
        <ellipse
          cx="3.79098"
          cy="11.8926"
          rx="3.79098"
          ry="11.8926"
          transform="matrix(0.190755 -0.981638 0.981617 0.190863 134.484 217.703)"
          fill="#A1B8E3"
        />
        <ellipse
          cx="3.79102"
          cy="11.8925"
          rx="3.79102"
          ry="11.8925"
          transform="matrix(0.08713 -0.996197 0.996193 0.0871815 140.406 237.758)"
          fill="#A1B8E3"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M544.366 271.653C519.896 262.364 457.564 240.266 402.75 240.266C347.936 240.266 279.988 276.034 236.139 280.093C192.29 284.152 79.495 286.964 79.495 286.964V341.481L544.366 329.937C544.366 329.937 555.5 329.937 634.998 329.937C643.904 320.226 623.997 308.5 544.366 271.653Z"
        fill="url(#paint14_linear_11870_211906)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 322.434C61.5 293.934 166.294 240.266 221.108 240.266C275.921 240.266 343.869 276.034 387.719 280.093C431.568 284.152 656.5 341.481 656.5 341.481H544.363H79.4922C79.4922 341.481 13 341.481 0 322.434Z"
        fill="url(#paint15_linear_11870_211906)"
      />
      <ellipse opacity="0.2" cx="407.454" cy="263.55" rx="28.6099" ry="1.61685" fill="#8699BF" />
      <g opacity="0.821875">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M404.225 241.519C400.143 243.294 389.907 233.763 387.956 223.499C386.004 213.234 395.88 213.017 388.342 195.165C380.804 177.313 374.797 169.462 389.576 148.878C404.356 128.295 398.388 112.246 413.696 110.598C429.005 108.951 430.242 131.335 423.029 148.326C415.815 165.317 417.773 169.96 431.432 172.396C445.091 174.832 425.732 196.926 423.911 202.531C422.091 208.135 426.635 221.121 422.447 228.418C418.259 235.716 408.308 239.743 404.225 241.519Z"
          fill="url(#paint16_linear_11870_211906)"
        />
        <path
          d="M404.52 214.621C405.917 210.319 411.357 197.269 419.919 191.028"
          stroke="url(#paint17_linear_11870_211906)"
          strokeWidth="6"
          strokeLinecap="round"
        />
        <path
          d="M403.431 186.519C404.829 182.217 393.736 175.273 390.477 165.19"
          stroke="url(#paint18_linear_11870_211906)"
          strokeWidth="6"
          strokeLinecap="round"
        />
        <path
          d="M404.801 263.98C405.396 262.15 404.925 252.875 405.018 242.338C405.312 209.341 402.086 167.542 410.383 142"
          stroke="url(#paint19_linear_11870_211906)"
          strokeWidth="6"
          strokeLinecap="round"
        />
      </g>
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M233.391 305.867L361.076 318.454C360.029 321.316 357.256 323.177 354.211 323.061L233.391 318.454"
        fill="url(#paint20_linear_11870_211906)"
      />
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M184.901 322.363L306.951 327.363V334.255L184.578 337.512"
        fill="url(#paint21_linear_11870_211906)"
      />
      <defs>
        <filter
          id="filter0_i_11870_211906"
          x="130.461"
          y="-12.5938"
          width="168.961"
          height="102.082"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-14" />
          <feGaussianBlur stdDeviation="12.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.788056 0 0 0 0 0.827193 0 0 0 0 0.89764 0 0 0 0.337126 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_11870_211906" />
        </filter>
        <filter
          id="filter1_i_11870_211906"
          x="307.664"
          y="59.7734"
          width="166.945"
          height="104.516"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-14" />
          <feGaussianBlur stdDeviation="12.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.788056 0 0 0 0 0.827193 0 0 0 0 0.89764 0 0 0 0.337126 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_11870_211906" />
        </filter>
        <linearGradient
          id="paint0_linear_11870_211906"
          x1="187.11"
          y1="77.8817"
          x2="178.185"
          y2="17.8729"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECF1FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_11870_211906"
          x1="363.738"
          y1="150.868"
          x2="354.559"
          y2="90.4951"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECF1FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_11870_211906"
          x1="342.295"
          y1="172.454"
          x2="384.311"
          y2="188.237"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CFDDF3" />
          <stop offset="1" stopColor="#A1B8E3" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_11870_211906"
          x1="367.602"
          y1="201.688"
          x2="367.926"
          y2="220.262"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CFDDF3" />
          <stop offset="1" stopColor="#A1B8E3" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_11870_211906"
          x1="360.781"
          y1="191.234"
          x2="360.975"
          y2="202.353"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CFDDF3" />
          <stop offset="1" stopColor="#A1B8E3" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_11870_211906"
          x1="355.57"
          y1="172.059"
          x2="356.974"
          y2="252.466"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CFDDF3" />
          <stop offset="1" stopColor="#A1B8E3" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_11870_211906"
          x1="179.562"
          y1="168.031"
          x2="190.971"
          y2="260.917"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CFDDF3" />
          <stop offset="1" stopColor="#A1B8E3" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_11870_211906"
          x1="15.2334"
          y1="-35.034"
          x2="-9.05055"
          y2="-29.3584"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CFDDF3" />
          <stop offset="1" stopColor="#A1B8E3" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_11870_211906"
          x1="15.2361"
          y1="-35.0277"
          x2="-9.05128"
          y2="-29.3492"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CFDDF3" />
          <stop offset="1" stopColor="#A1B8E3" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_11870_211906"
          x1="15.2362"
          y1="-35.0275"
          x2="-9.05129"
          y2="-29.349"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CFDDF3" />
          <stop offset="1" stopColor="#A1B8E3" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_11870_211906"
          x1="15.2362"
          y1="-35.0275"
          x2="-9.0513"
          y2="-29.349"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CFDDF3" />
          <stop offset="1" stopColor="#A1B8E3" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_11870_211906"
          x1="15.2326"
          y1="-35.0358"
          x2="-9.05034"
          y2="-29.361"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CFDDF3" />
          <stop offset="1" stopColor="#A1B8E3" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_11870_211906"
          x1="15.2342"
          y1="-35.0321"
          x2="-9.05077"
          y2="-29.3555"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CFDDF3" />
          <stop offset="1" stopColor="#A1B8E3" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_11870_211906"
          x1="15.2347"
          y1="-35.031"
          x2="-9.05089"
          y2="-29.354"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CFDDF3" />
          <stop offset="1" stopColor="#A1B8E3" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_11870_211906"
          x1="763.887"
          y1="230.335"
          x2="762.09"
          y2="350.818"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#D6E3FA" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_11870_211906"
          x1="771.043"
          y1="341.481"
          x2="771.043"
          y2="141.557"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#D6E3FA" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_11870_211906"
          x1="391.821"
          y1="136.221"
          x2="442.465"
          y2="178.096"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CFDDF3" />
          <stop offset="1" stopColor="#A1B8E3" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_11870_211906"
          x1="412.922"
          y1="188.754"
          x2="404.515"
          y2="214.619"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CFDDF3" />
          <stop offset="1" stopColor="#A1B8E3" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_11870_211906"
          x1="408.461"
          y1="171.035"
          x2="403.429"
          y2="186.518"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CFDDF3" />
          <stop offset="1" stopColor="#A1B8E3" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_11870_211906"
          x1="410.383"
          y1="142"
          x2="373.992"
          y2="253.966"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CFDDF3" />
          <stop offset="1" stopColor="#A1B8E3" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_11870_211906"
          x1="350.979"
          y1="296.751"
          x2="174.618"
          y2="296.751"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D0DCF1" />
          <stop offset="1" stopColor="white" stopOpacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_11870_211906"
          x1="297.275"
          y1="314.333"
          x2="128.251"
          y2="314.333"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D0DCF1" />
          <stop offset="1" stopColor="white" stopOpacity="0.01" />
        </linearGradient>
      </defs>
    </svg>
  );
}

EmptyHomeIcon.defaultProps = {
  className: undefined,
};

export default EmptyHomeIcon;
