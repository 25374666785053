/* eslint-disable no-use-before-define, react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/destructuring-assignment */
import { ArrowUpCircleIcon, CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import BaseButton from 'components/BaseButton';
import withInlines from 'components/NoteEditor/EditorContext/withInlines';
import { withNormalizeInlineNode } from 'components/NoteEditor/EditorContext/withNormalizeNode';
import Leaf from 'components/NoteEditor/Leaf';
import NoteEditor from 'components/NoteEditor/NoteEditor';
import HotKeyPlugin from 'components/NoteEditor/Plugins/HotKeyPlugin';
import PastePlugin from 'components/NoteEditor/Plugins/PastePlugin';
import {
  BlockType,
  BlockTypeInline,
  CommentContent,
  CustomElement,
  LinkElement as LinkElementType,
  MentionElement as MentionElementType,
} from 'components/NoteEditor/types';
import isHotkey from 'is-hotkey';
import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Transforms, createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, RenderElementProps, RenderLeafProps, Slate, withReact } from 'slate-react';
import LinkElement from '../LinkElement';
import styles from './CommentEditor.module.css';
import { emitDocumentEvent } from 'utils/event';
import MentionElement from '../Mention/MentionElement';
import EditorToolbar from '../Toolbar/EditorToolbar';
import EmojiCommandPlugin from 'components/NoteEditor/Plugins/EmojiCommandPlugin';

interface Props {
  content?: CommentContent;
  readOnly: boolean;
  onSubmit?: (content: CommentContent) => void;
  prefix?: React.ReactNode;
  onCancel?: () => void;
  submitBtntype?: 'arrow' | 'check';
  clearAfterSubmit?: boolean;
  documentId: string;
  opportunityId: string;
}

function CommentEditor({
  content,
  readOnly,
  onSubmit,
  prefix,
  onCancel,
  submitBtntype,
  clearAfterSubmit,
  documentId,
  opportunityId,
}: Props) {
  const editor = useMemo(
    () => withNormalizeInlineNode(withInlines(withReact(withHistory(createEditor())), 'main-doc-comment')),
    [],
  );
  const renderElement = useCallback(
    (props: RenderElementProps) => (
      <Element {...props} readOnly={readOnly} documentId={documentId || ''} opportunityId={opportunityId || ''} />
    ),
    [readOnly],
  );
  const renderLeaf = useCallback((props: RenderLeafProps) => <Leaf {...props} />, []);
  const initContent = useRef([{ type: BlockType.Paragraph, children: content || [{ text: '' }] } as CustomElement]);
  const emojiPluginRef = useRef(EmojiCommandPlugin(null));

  useEffect(() => {
    if (!readOnly) {
      Transforms.select(editor, NoteEditor.end(editor, []));
    }
  }, [readOnly]);

  const handleCancel = () => {
    const range = { anchor: NoteEditor.start(editor, []), focus: NoteEditor.end(editor, []) };
    Transforms.delete(editor, { at: range });
    Transforms.insertFragment(editor, cloneDeep(initContent.current), { at: [] });
    onCancel?.();
  };

  const handleSubmit = () => {
    const isEmpty = NoteEditor.sp_isEmpty(editor, editor.children[0]);
    if (!isEmpty) {
      const newContent = (editor.children[0]?.children || [{ text: '' }]) as CommentContent;
      onSubmit?.(newContent);
      initContent.current = [{ type: BlockType.Paragraph, children: newContent } as CustomElement];
      if (clearAfterSubmit) {
        Transforms.delete(editor, {
          at: { anchor: NoteEditor.start(editor, []), focus: NoteEditor.end(editor, []) },
        });
      }
      if (newContent.find(item => (item as MentionElementType).type === BlockTypeInline.Mention)) {
        emitDocumentEvent({
          opportunityId: opportunityId || '',
          documentId: documentId || '',
          type: 'MENTION_CREATE',
          sourceElement: null,
          commentContent: newContent,
          docSource: 'main-doc-comment',
        });
      }
    }
  };

  return (
    <div className={styles.editorContainer}>
      {!!prefix && <div className={styles.prefixWrap}>{prefix}</div>}
      <Slate editor={editor} initialValue={initContent.current}>
        <Editable
          autoFocus={!readOnly}
          className={styles.editor}
          readOnly={readOnly}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder="Enter some comment..."
          onKeyDown={event => {
            const stop = emojiPluginRef.current?.onKeyDown(event, editor);
            if (stop) {
              return;
            }
            const isEnterPressed = isHotkey('enter', event);
            const isShiftEnterPressed = isHotkey('shift+enter', event);
            if (isEnterPressed && !isShiftEnterPressed) {
              event.preventDefault();
              handleSubmit();
              return;
            }
            HotKeyPlugin.onKeyDown?.(event, editor, true);
          }}
          onPaste={e => PastePlugin.onPaste?.(e, editor, true)}
        />
        {emojiPluginRef.current?.element}
        <EditorToolbar editor={editor} />
      </Slate>
      {!readOnly && (
        <div className={styles.btns}>
          {!!onCancel && (
            <BaseButton color="secondary" variant="text" iconBtn onClick={handleCancel}>
              <XCircleIcon width={24} />
            </BaseButton>
          )}
          <BaseButton color="primary" variant="text" iconBtn onClick={handleSubmit}>
            {submitBtntype === 'arrow' ? <ArrowUpCircleIcon width={24} /> : <CheckCircleIcon width={24} />}
          </BaseButton>
        </div>
      )}
    </div>
  );
}

CommentEditor.defaultProps = {
  onSubmit: undefined,
  prefix: undefined,
  onCancel: undefined,
  submitBtntype: 'arrow',
  clearAfterSubmit: false,
  content: undefined,
};

const Element = (props: RenderElementProps & { readOnly: boolean; documentId: string; opportunityId: string }) => {
  const { attributes, children, element } = props;
  switch (element.type) {
    case 'mention':
      return (
        <MentionElement
          {...props}
          element={element as MentionElementType}
          documentId={props.documentId}
          opportunityId={props.opportunityId}
          docSource="main-doc-comment"
        />
      );
    case 'link':
      return <LinkElement {...props} element={element as LinkElementType} readOnly={props.readOnly} />;
    default:
      return <div {...attributes}>{children}</div>;
  }
};

export default CommentEditor;
