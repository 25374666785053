import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import useContextPanelStore from 'stores/context-panel';
import useDocumentStore from 'stores/document';
import RightDrawerPanel from 'components/RightDrawerPanel';
import UploadedFilePreview from './UploadedFilePreview';
import getFile from 'api/files/get-file';
import { SuperpanelAPIError } from 'utils/errors';
import { File } from 'types/file';
import ResponseView from 'components/ResponseView';
import EmptyResponseIcon from 'components/icons/EmptyResponseIcon';
import axios from 'axios';
import mammoth from 'mammoth/mammoth.browser';

interface State {
  isLoading: boolean;
  file: null | File;
  error: string;
  fileContent: string;
}

const defaultState: State = {
  isLoading: true,
  file: null,
  error: '',
  fileContent: '',
};

function UploadedFilePreviewPage() {
  const { documentId } = useParams();
  const [state, setState] = useState<State>({ ...defaultState });
  const documentStore = useDocumentStore();
  const contextPanelStore = useContextPanelStore();
  // tag for whether document already loaded
  const fetched = useRef(false);

  const document = useMemo(
    () => documentStore.documents.find(d => d.id === documentId),
    [documentStore.documents, documentId],
  );

  const fileId = useMemo(
    () => documentStore.documents.find(d => d.id === documentId)?.uploadedFile?.id,
    [documentStore.documents, documentId],
  );

  useEffect(() => {
    if (
      documentId &&
      !documentStore.isLoading &&
      !documentStore.documents.find(d => d.id === documentId) &&
      !fetched.current
    ) {
      fetched.current = true;
      documentStore.getDocument(documentId);
    } else if (!fetched.current && documentStore.documents.find(d => d.id === documentId)) {
      fetched.current = true;
    }
  }, [documentId, documentStore.isLoading, documentStore.documents]);

  useEffect(() => {
    const fetchFile = async (): Promise<void> => {
      if (fileId) {
        try {
          setState({ ...defaultState });
          const file = await getFile(fileId);
          setState(oldState => ({ ...oldState, file }));
          const isWord = file.fileType?.toLowerCase().includes('word');
          const isTxt = file.fileType?.toLowerCase().includes('text/plain');
          if (file.url && (isWord || isTxt)) {
            const rsp = await axios.get(file.url, {
              responseType: isTxt ? 'text' : 'arraybuffer',
            });
            if (isWord) {
              const fileContent = await mammoth.convertToHtml(
                { arrayBuffer: rsp.data },
                { includeDefaultStyleMap: true },
              );
              setState(oldState => ({
                ...oldState,
                fileContent: fileContent.value,
              }));
            }
            if (isTxt) {
              setState(oldState => ({
                ...oldState,
                fileContent: rsp.data,
              }));
            }
          }
        } catch (err) {
          const error = err as SuperpanelAPIError;
          setState(oldState => ({
            ...oldState,
            error: error.displayErrorMessage || error.message || 'Failed to load file!',
          }));
        } finally {
          setState(oldState => ({ ...oldState, isLoading: false }));
        }
      }
    };
    fetchFile();
    return () => {
      contextPanelStore.close();
    };
  }, [fileId]);

  return (
    <ResponseView
      loading={state.isLoading}
      error={state.error}
      noData={!state.file}
      noDataIcon={<EmptyResponseIcon />}
      noDataMsg="Failed load file!"
    >
      <UploadedFilePreview
        documentId={documentId || ''}
        document={document}
        file={state.file}
        onImportNode={undefined}
        fileContent={state.fileContent}
      />
      <RightDrawerPanel />
    </ResponseView>
  );
}

export default UploadedFilePreviewPage;
