/* eslint-disable max-classes-per-file */

export class SuperpanelAPIError extends Error {
  static code = '';

  code: string;

  link: string | null = null;

  displayErrorMessage: string;

  constructor() {
    super();
    this.code = (this.constructor as typeof SuperpanelAPIError).code;
    this.displayErrorMessage = `An unexpected error happened, please try again or contact us. [${this.code}]`;
  }
}
class AuthenticationError extends SuperpanelAPIError {
  static code = 'AUTHENTICATION_ERROR';

  displayErrorMessage = 'Unauthorized, please try again after login';
}

class OauthAppAlreadyInstalledError extends SuperpanelAPIError {
  static code = 'OAUTH_APP_ALREADY_INSTALLED';

  link = 'https://myaccount.google.com/permissions';

  displayErrorMessage = 'The OAuth app has already been installed, please remove it from your account and try again.';
}
class IntegrationError extends SuperpanelAPIError {
  static code = 'INTEGRATION_ERROR';

  displayErrorMessage: string;

  constructor(integrationName: string) {
    super();
    this.displayErrorMessage = `Something went wrong with ${integrationName}. Please try again later.`;
  }
}

class IntegrationValidationError extends IntegrationError {
  static code = 'INTEGRATION_VALIDATION_ERROR';

  displayErrorMessage: string;

  constructor(integrationName: string, errorMessage: string) {
    super(integrationName);
    this.displayErrorMessage = `${integrationName} Validation Error: ${errorMessage}`;
  }
}

class UserNotExistError extends SuperpanelAPIError {
  static code = 'USER_NOT_EXIST_ERROR';

  displayErrorMessage = 'No account found, please signup as a new user.';
}

class UserExistError extends SuperpanelAPIError {
  static code = 'USER_EXIST_ERROR';

  displayErrorMessage = 'User already exists, please login.';
}

class SerializationError extends SuperpanelAPIError {
  static code = 'SERIALIZATION_ERROR';
}

class PermissionError extends SuperpanelAPIError {
  static code = 'PERMISSION_ERROR';

  displayErrorMessage = 'You are not authorized to access this page.';
}

class DatabaseError extends SuperpanelAPIError {
  static code = 'DATABASE_ERROR';
}

class UnlinkDocumentWithFieldError extends SuperpanelAPIError {
  static code = 'UNLINK_DOCUMENT_WITH_FIELD_ERROR';

  displayErrorMessage =
    'Unlinking a document with fields will cause collision. If you want to unlink this document, please remove all the fields first.';
}

class UpgradeDocParentEntityError extends SuperpanelAPIError {
  static code = 'UPGRADE_DOC_PARENT_ENTITY_ERROR';

  displayErrorMessage =
    'Failed! Reason: moving an opportunity doc up to an account level will cause discovery fields collision.';
}

class ApplyTemplateWithIncompatibleFieldsError extends SuperpanelAPIError {
  static code = 'APPLY_TEMPLATE_WITH_INCOMPATIBLE_FIELDS_ERROR';

  displayErrorMessage =
    'Failed! Reason: the template contains incompatible fields. You cannot apply this template to this document.';
}

class SalesforceFieldDefinitionTablePermissionError extends SuperpanelAPIError {
  static code = 'SALESFORCE_FIELD_DEFINITION_TABLE_PERMISSION_ERROR';

  displayErrorMessage =
    'Insufficient permission. Please ask your Salesforce admin to grant access to FieldDefinition object';
}
class IntegrationInsufficientPermissionToUpdateField extends SuperpanelAPIError {
  static code = 'INTEGRATION_INSUFFICIENT_PERMISSION_TO_UPDATE_FIELD';

  displayErrorMessage = 'You do not have permission to update the value to this field.';
}
class RegisterCrossDomainError extends SuperpanelAPIError {
  static code = 'REGISTER_CROSS_DOMAIN_ERROR';

  displayErrorMessage = 'You must use the account with the same domain as the one you are invited';
}
class RegisterCRMOnlyError extends SuperpanelAPIError {
  static code = 'REGISTER_CRM_ONLY_ERROR';

  displayErrorMessage = 'You must use your CRM account to register, please go back and try again.';
}

class InvitationExpiredError extends SuperpanelAPIError {
  static code = 'INVITATION_EXPIRED_ERROR';

  displayErrorMessage = 'This invitation has expired, please contact your Superpanel admin to resend the invitation.';
}

class GeneralError extends SuperpanelAPIError {
  static code = 'GENERAL_ERROR';

  displayErrorMessage: string;

  constructor(message: string) {
    super();
    this.displayErrorMessage = `Something went wrong: ${message}`;
  }
}

class UnexpectedError extends SuperpanelAPIError {
  static code = 'UNEXPECTED_ERROR';
}

class ApiMismatch extends SuperpanelAPIError {
  static code = 'API_VERSION_MISMATCH_ERROR';

  displayErrorMessage = 'Superpanel has a new version ready. Please refresh the browser page';
}

class ApiTimeoutError extends SuperpanelAPIError {
  static code = 'API_TIMEOUT';

  displayErrorMessage = 'API exceed the time limit. Please try again later';
}

const errors = {
  SuperpanelAPIError,
  AuthenticationError,
  OauthAppAlreadyInstalledError,
  IntegrationError,
  IntegrationValidationError,
  UserNotExistError,
  UserExistError,
  SerializationError,
  DatabaseError,
  UnlinkDocumentWithFieldError,
  UpgradeDocParentEntityError,
  ApplyTemplateWithIncompatibleFieldsError,
  SalesforceFieldDefinitionTablePermissionError,
  IntegrationInsufficientPermissionToUpdateField,
  RegisterCrossDomainError,
  RegisterCRMOnlyError,
  InvitationExpiredError,
  GeneralError,
  UnexpectedError,
  PermissionError,
  ApiMismatch,
  ApiTimeoutError,
};

// the errors which will not show with popup message
export const POPUP_MSG_IGNORE_ERRORS = [errors.RegisterCRMOnlyError.code];

export default errors;
