/* eslint-disable no-await-in-loop, no-restricted-syntax */
import { useEffect, useRef } from 'react';
import useFieldValuesStore from 'stores/field-values';
import useRecordingsStore from 'stores/recordings';

export default function useRecording(documentId: string | undefined) {
  const recordingsStore = useRecordingsStore();
  const fieldValuesStore = useFieldValuesStore();
  const workerDoneRef = useRef(true);
  useEffect(() => {
    // Every 4 second we fetch recording downloading status from backend
    if (!documentId) {
      return () => null;
    }
    const intervalId = setInterval(async () => {
      if (!workerDoneRef.current) {
        return;
      }
      try {
        workerDoneRef.current = false;
        for (const recording of recordingsStore.recordings) {
          if (
            recording.isRecordingBeingDownloaded &&
            (recording.callType !== 'superpanel' || recording.status === 'pending' || recording.status === 'new')
          ) {
            const isSomeRecordingDone = await recordingsStore.checkDownloadStatus(documentId);
            if (isSomeRecordingDone) {
              await fieldValuesStore.fetch(documentId);
            }
            break;
          }
          if (recording.isSummaryBeingProcessed) {
            await recordingsStore.checkSummaryStatus(documentId);
            break;
          }
          if (recording.isProductFeedbackBeingProcessed) {
            await recordingsStore.checkProductFeedbackStatus(documentId);
            break;
          }
        }
      } finally {
        workerDoneRef.current = true;
      }
    }, 4000);
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId, recordingsStore.recordings]);
}
