import React from 'react';
import { CheckIcon } from '@heroicons/react/24/outline';
import { EntityDocument } from 'types/entity';
import AccountIcon from 'components/icons/AccountIcon';
import { Recording } from 'api/recordings/get-recordings';

interface Props {
  account: EntityDocument;
  recording: Recording;
  updateData: (objType: 'opp' | 'doc' | 'acct', objId: string) => void;
}

function AccountButton({ account, recording, updateData }: Props) {
  const isLinked = account.id === recording.entityOrDocumentId;

  return (
    <div>
      <div className="flex justify-between items-center pr-3 hover:bg-slate-100 rounded-lg group">
        <button
          type="button"
          className={`flex w-full items-center text-sm p-2 gap-2 ${isLinked ? 'cursor-default' : null} `}
          onClick={() => {
            updateData('acct', account.id);
          }}
          disabled={isLinked}
        >
          <AccountIcon className="w-4 h-4" />
          <span className="text-gray-500">{account.name}</span>
        </button>
        {isLinked && <CheckIcon className="flex-none w-5 h-5 text-blue-500" />}
      </div>
    </div>
  );
}

export default AccountButton;
