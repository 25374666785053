import StarIcon from 'components/icons/StarIcon';

import React from 'react';

import { Template } from 'api/note-templates/get-note-templates';
import { useNavigate } from 'react-router-dom';
import useDocumentStore from 'stores/document';
import { DocumentPlusIcon } from '@heroicons/react/24/outline';
import { Emoji } from 'emoji-picker-react';

interface Props {
  oppId: string;
  template: Template;
}

interface State {
  isLoading: boolean;
}

function TemplateLink({ oppId, template }: Props) {
  const navigate = useNavigate();
  const documentStore = useDocumentStore();
  const [state, setState] = React.useState<State>({ isLoading: false });
  let docTitle = template.name;
  const { icon } = template;
  if (docTitle.length > 20) {
    docTitle = docTitle.slice(0, 20).concat('...');
  }

  return (
    <div className="relative flex gap-1 items-center ml-[28px] pl-1 scroll-m-36">
      <button
        type="button"
        className={`relative w-full flex flex-col justify-center mb-0.5 rounded hover:bg-gray-200 hover:text-black group text-gray-500 `}
        style={{ textDecoration: 'none' }}
        onClick={async () => {
          setState({
            ...state,
            isLoading: true,
          });
          try {
            const document = await documentStore.createDocumentFromTemplate(oppId, template.noteTemplateId);
            navigate(`/document/${document.id}`);
          } catch (e) {
            setState({
              ...state,
              isLoading: false,
            });
          }
          setState({
            ...state,
            isLoading: false,
          });
        }}
        disabled={state.isLoading}
      >
        <div className="flex items-center gap-2 px-[6px] py-1 overflow-x-clip">
          {/* <NoteIcon className="w-5 h-5 flex-shrink-0" /> */}
          <DocumentPlusIcon className="w-[18px] h-[18px] flex-grow-0 flex-shrink-0" />
          <div className="w-[90%] flex gap-1 items-center text-left font-medium">
            {icon && <Emoji unified={icon} size={20} />}
            <span className="text-sm overflow-x-clip overflow-ellipsis whitespace-nowrap">{docTitle}</span>
            <StarIcon className="w-4 h-4 text-yellow-400" />
          </div>
        </div>
      </button>
    </div>
  );
}

export default TemplateLink;
