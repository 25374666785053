/* eslint-disable no-nested-ternary */
import isUrl from 'is-url';
import { set, get, isEqual } from 'lodash';
import { v4 } from 'uuid';
import { BasePoint, Descendant, Editor, Element, Node, Path, Range, Transforms } from 'slate';
import useNoteEditorStore from 'stores/note-editor';
import { isValidImageFileUpload } from 'utils/file';
import { elementToHtml, markDownToChildren, markDownToElements } from '../../../utils/convert-element';
import { wrapLink } from '../EditorContext/withInlines';
import NoteEditor from '../NoteEditor';
import {
  BlockType,
  CustomElement,
  CustomText,
  INDENTABLE_TYPES,
  NoteCopyPasteFormat,
  ProductGapElement,
  TaskElement,
} from '../types';
import { SlatePlugin } from './types';
import { createNode, translateHtmlToMarkdown } from '../utils';

export const normalizePasteFragment = (fragment: Descendant[]): Descendant[] => {
  // filter fragment
  const normalizedFragment = fragment.filter((item: CustomElement | CustomText) => {
    const block = item as CustomElement;
    if (block.type === BlockType.DiscoveryQuestion) {
      // ignore all DiscoveryQuestion untill we properly handle field value copy and paste
      // edge cases
      // 1. copy opp field value and paste to another opp
      // 2. copy opp field value and paste to account doc, account cannot have opp value
      return false;
      // below is a logic to check if the same discovery question already exist in the doc
      // return !existingFragment.find((it: Descendant) => {
      //   const frag = it as CustomElement;
      //   return (
      //     frag.type === BlockType.DiscoveryQuestion &&
      //     (frag as DiscoveryQuestionElement).discoveryQuestionId ===
      //       (block as DiscoveryQuestionElement).discoveryQuestionId
      //   );
      // });
    }
    if (block.type === BlockType.ProductGap && (block as ProductGapElement).productGapId) {
      // ignore ProductGap which has id
      return false;
    }
    if (block.type === BlockType.Task && (block as TaskElement).taskId) {
      // ignore task which has id
      return false;
    }
    return true;
  });
  return normalizedFragment;
};

const insertContentIntoEditor = (editor: Editor, content: (CustomElement | Node)[], plainText: string) => {
  const { selection } = editor;
  if (content.length <= 0) {
    return;
  }
  const insertIntoTableCell = (insertStartPoint: BasePoint, cellPath: Path, clearTrailingContent: boolean) => {
    if (clearTrailingContent) {
      const insertCellEnd = NoteEditor.end(editor, cellPath);
      if (selection && Range.isExpanded(selection)) {
        Transforms.select(editor, { anchor: insertStartPoint, focus: insertCellEnd });
        Transforms.delete(editor);
      }
    }
    Transforms.insertNodes(editor, markDownToChildren(plainText));
  };

  const insertIntoBlockNode = (
    insertRootPath: Path,
    insertRootNode: CustomElement | undefined,
    isSelectAll: boolean,
  ) => {
    // paste in same line if insert node is indentable types and paste first node is paragraph
    if (
      INDENTABLE_TYPES.includes(insertRootNode?.type as BlockType) &&
      (content[0] as CustomElement).type === BlockType.Paragraph &&
      !isSelectAll
    ) {
      Transforms.insertNodes(editor, [...(content[0] as CustomElement).children]);
      Transforms.insertNodes(editor, [...content.slice(1)]);
    } else {
      if (isSelectAll) {
        Transforms.removeNodes<CustomElement>(editor, {
          hanging: true,
          voids: true,
          mode: 'highest',
          at: { anchor: NoteEditor.start(editor, []), focus: NoteEditor.end(editor, []) },
        });
      }
      Transforms.insertNodes(editor, content);
      // remove empty paragraph at paste location after paste
      if (insertRootNode?.type === BlockType.Paragraph && !Editor.string(editor, insertRootPath)) {
        Transforms.removeNodes(editor, { at: insertRootPath });
      }
      if (editor.children.length > 1) {
        Transforms.insertNodes<CustomElement>(editor, createNode(BlockType.Paragraph));
      }
    }
  };

  // when paste content, we will check if content has uuid (copy to old element)
  // and change it to new uuid to prevent same uuid in one doc
  content.forEach(c => {
    if (get(c, 'uuid')) {
      set(c, 'uuid', v4());
    }
  });

  // when no selection, insert fragment at end of doc
  if (!selection) {
    Transforms.insertNodes(editor, content);
    return;
  }
  // get root node to insert
  const [root1Element, root1Path] = NoteEditor.node(editor, [selection.anchor.path[0]]);
  const [root2Element, root2Path] = NoteEditor.node(editor, [selection.focus.path[0]]);
  const leaf1Path = selection.anchor.path;
  const leaf2Path = selection.focus.path;
  const root1Node = root1Element as CustomElement;
  const root2Node = root2Element as CustomElement;
  const selectAll = NoteEditor.sp_isSelectAll(editor);
  if ((root1Node.type === 'table' || root2Node.type === 'table') && !selectAll) {
    // when selection inside one table
    if (root1Path[0] === root2Path[0]) {
      const insertCellStart = Path.isBefore(leaf1Path, leaf2Path) ? selection.anchor : selection.focus;
      const selectionInOneCell = isEqual(leaf1Path.slice(0, 3), leaf2Path.slice(0, 3));
      insertIntoTableCell(insertCellStart, insertCellStart.path.slice(0, 3), !selectionInOneCell);
    } else {
      // when selection between multi table
      const insertRootNode = Path.isBefore(leaf1Path, leaf2Path) ? root1Node : root2Node;
      if (insertRootNode.type === BlockType.Table) {
        const insertCellStart = Path.isBefore(leaf1Path, leaf2Path) ? selection.anchor : selection.focus;
        insertIntoTableCell(insertCellStart, insertCellStart.path.slice(0, 3), true);
      } else {
        const insertRootPath = Path.isBefore(leaf1Path, leaf2Path) ? leaf1Path.slice(0, 1) : leaf2Path.slice(0, 1);
        insertIntoBlockNode(insertRootPath, insertRootNode, selectAll);
      }
    }
  } else {
    const insertRootPath = Path.isBefore(leaf1Path, leaf2Path) ? leaf1Path.slice(0, 1) : leaf2Path.slice(0, 1);
    const insertRootNode = Node.has(editor, insertRootPath)
      ? (Node.get(editor, insertRootPath) as CustomElement)
      : undefined;
    insertIntoBlockNode(insertRootPath, insertRootNode, selectAll);
  }
};

function pastePlugin(event: React.ClipboardEvent<HTMLDivElement>, editor: Editor, inlinePaste?: boolean): boolean {
  const html = event.clipboardData.getData(NoteCopyPasteFormat.html);
  const markdown = event.clipboardData.getData(NoteCopyPasteFormat.markdown) || '';
  const superpanel = event.clipboardData.getData(NoteCopyPasteFormat.superpanel);
  const files = Array.from(event.clipboardData.files); // files to paste
  // paste image file
  if (files.length && !html && isValidImageFileUpload(files) && !inlinePaste) {
    event.preventDefault();
    useNoteEditorStore.setState({ filesToUpload: files, currentActionEditor: editor });
    return true;
  }

  if (!html && !superpanel && markdown) {
    if (isUrl(markdown)) {
      event.preventDefault();
      wrapLink(editor, markdown);
      return true;
    }
    if (markdown === '@') {
      event.preventDefault();
      Transforms.insertText(editor, '@');
      return true;
    }
  }

  if (inlinePaste) {
    return true;
  }

  event.preventDefault();
  try {
    // when paste within app
    if (superpanel) {
      let slateJson = JSON.parse(superpanel);
      if (slateJson && typeof slateJson === 'object') {
        if (!(slateJson instanceof Array)) {
          slateJson = [slateJson];
        }
        // insert content to current location
        insertContentIntoEditor(
          editor,
          normalizePasteFragment(slateJson),
          markdown || translateHtmlToMarkdown(elementToHtml({ children: slateJson } as Element)),
        );
      }
    } else if (html) {
      const markdownStr = translateHtmlToMarkdown(html);
      insertContentIntoEditor(editor, markDownToElements(markdownStr), markdownStr);
    } else if (markdown) {
      // when paste markdown, treat it as plain text for now
      insertContentIntoEditor(editor, markDownToElements(markdown), markdown);
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('paste err', err);
  }
  return true;
}

const PastePlugin: SlatePlugin = {
  key: 'paste-plugin',
  onPaste: pastePlugin,
};

export default PastePlugin;
