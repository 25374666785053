/* eslint-disable no-restricted-syntax, no-param-reassign, no-plusplus, no-nested-ternary, no-continue */
import React, { useCallback, useEffect, useRef } from 'react';
import { ColumnConfig, ColumnDefinition, DraggingColumnInfo, GroupColumnDefinition, RowData } from './types';
import Columns from './Columns';
import Rows from './Rows';
import CalculationFooter from './CalculationFooter';
import styles from './SimpleTable.module.css';
import { concat } from 'utils/styling';
import useDragHandler from 'components/Hooks/useDragHandler';
import { ArrowsPointingOutIcon } from '@heroicons/react/24/outline';
import { swapTableColPostion } from './utils';

interface Props {
  columnConfigs: ColumnConfig[];
  setColumnConfigs: (columnDefinitions: ColumnConfig[]) => void;
  rows: RowData[];
  showSequence?: boolean;
  sequenceOffset?: number;
}

function SimpleTable({ columnConfigs, setColumnConfigs, rows, showSequence, sequenceOffset }: Props) {
  const tableDivRef = useRef<HTMLDivElement>(null);
  const {
    onMouseDown,
    dragging,
    offsetX,
    offsetY,
    startDragPointRef,
    currentDragPointRef,
    lastDragPointRef,
    draggingItem,
    setDraggingItem,
  } = useDragHandler<DraggingColumnInfo>(5);
  const columnConfigsRef = useRef(columnConfigs);

  useEffect(() => {
    columnConfigsRef.current = columnConfigs;
  }, [columnConfigs]);

  const swapColPostion = useCallback(
    (
      fromColIndex: number,
      fromSubColIndex: number | undefined,
      toColIndex: number,
      toSubColIndex: number | undefined,
    ) => {
      const newCols = swapTableColPostion(
        columnConfigsRef.current,
        fromColIndex,
        fromSubColIndex,
        toColIndex,
        toSubColIndex,
      );
      if (newCols) {
        setColumnConfigs(newCols);
      }
    },
    [],
  );

  useEffect(() => {
    if (tableDivRef.current && dragging && draggingItem) {
      const tableLeft =
        tableDivRef.current.getBoundingClientRect().left +
        (tableDivRef.current?.querySelector('.sequence-th')?.clientWidth ?? 0);
      const tableScrollLeft = tableDivRef.current.scrollLeft;
      let distanceToCurrentColLeft = startDragPointRef.current.x + offsetX - tableLeft + tableScrollLeft;
      const draggingColIndex = columnConfigsRef.current.findIndex(col => {
        const subColIds = ((col as GroupColumnDefinition).columns || []).map(item => item.id).join('_');
        return (
          col.id === draggingItem.colId &&
          (draggingItem.subColId
            ? subColIds.includes(draggingItem.subColId)
            : draggingItem.targetColName === ((col as GroupColumnDefinition).group || (col as ColumnDefinition).name))
        );
      });
      const draggingCol = columnConfigsRef.current[draggingColIndex];
      const draggingSubColIndex = (draggingCol as GroupColumnDefinition)?.group
        ? !draggingItem.subColId ||
          draggingItem.targetColName ===
            ((draggingCol as GroupColumnDefinition).group || (draggingCol as ColumnDefinition).name)
          ? undefined
          : (draggingCol as GroupColumnDefinition).columns.findIndex(col => draggingItem.subColId === col.id)
        : undefined;
      for (let index = 0; index < columnConfigsRef.current.length; index++) {
        const col = columnConfigsRef.current[index];
        let reachedColRange = false;
        if ((col as GroupColumnDefinition).group) {
          for (let subIndex = 0; subIndex < (col as GroupColumnDefinition).columns.length; subIndex++) {
            const subCol = (col as GroupColumnDefinition).columns[subIndex];
            const colWidth = subCol.width;
            if (subCol.isHidden) {
              continue;
            }
            if (
              distanceToCurrentColLeft >= (col.isPinned ? tableScrollLeft : 0) &&
              distanceToCurrentColLeft < (col.isPinned ? tableScrollLeft + colWidth : colWidth)
            ) {
              if (
                currentDragPointRef.current.x !== lastDragPointRef.current.x &&
                (currentDragPointRef.current.x - lastDragPointRef.current.x > 0
                  ? draggingSubColIndex !== undefined
                    ? index > draggingColIndex || (index === draggingColIndex && subIndex > draggingSubColIndex)
                    : index > draggingColIndex
                  : draggingSubColIndex !== undefined
                  ? index < draggingColIndex || (index === draggingColIndex && subIndex < draggingSubColIndex)
                  : index < draggingColIndex)
              ) {
                swapColPostion(draggingColIndex, draggingSubColIndex, index, subIndex);
              }
              reachedColRange = true;
              break;
            } else {
              distanceToCurrentColLeft -= colWidth;
            }
          }
        } else {
          if ((col as ColumnDefinition).isHidden) {
            continue;
          }
          const colWidth = (col as ColumnDefinition).width;
          if (
            distanceToCurrentColLeft >= (col.isPinned ? tableScrollLeft : 0) &&
            distanceToCurrentColLeft < (col.isPinned ? tableScrollLeft + colWidth : colWidth)
          ) {
            if (
              currentDragPointRef.current.x !== lastDragPointRef.current.x &&
              (currentDragPointRef.current.x - lastDragPointRef.current.x > 0
                ? index > draggingColIndex
                : index < draggingColIndex)
            ) {
              swapColPostion(draggingColIndex, draggingSubColIndex, index, undefined);
            }
            reachedColRange = true;
          } else {
            distanceToCurrentColLeft -= colWidth;
          }
        }
        if (reachedColRange) {
          break;
        }
      }
    }
  }, [offsetX, dragging, draggingItem]);

  return (
    <div className={concat('w-full h-full overflow-auto text-sm font-sans', styles.tableContainer)} ref={tableDivRef}>
      <Columns
        columnConfigs={columnConfigs}
        setColumnConfigs={setColumnConfigs}
        showSequence={showSequence}
        maxSequence={(sequenceOffset ?? 0) + rows.length}
        onDraggingMouseDown={onMouseDown}
        draggingItem={draggingItem}
        setDraggingItem={setDraggingItem}
      />
      <Rows columnConfigs={columnConfigs} rows={rows} showSequence={showSequence} sequenceOffset={sequenceOffset} />
      <CalculationFooter
        columnConfigs={columnConfigs}
        rows={rows}
        setColumnConfigs={setColumnConfigs}
        showSequence={showSequence}
        maxSequence={(sequenceOffset ?? 0) + rows.length}
      />
      {dragging && !!draggingItem && (
        <div
          className={styles.tableOverlay}
          style={{ left: startDragPointRef.current.x + offsetX, top: startDragPointRef.current.y + offsetY }}
        >
          <ArrowsPointingOutIcon width={16} className={styles.movingIcon} />
          <span>{draggingItem.targetColName}</span>
        </div>
      )}
    </div>
  );
}

SimpleTable.defaultProps = {
  showSequence: false,
  sequenceOffset: 0,
};

export default SimpleTable;
