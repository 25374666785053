import React, { useCallback, useEffect, useState } from 'react';
import { DocTriggerCondition } from 'types/doc-trigger-condition';
import getCrmDocTriggers from 'api/doc-trigger-conditions/get-doc-trigger-conditions';
import ResponseView from '../ResponseView';
import DocTriggerConditionRow from './DocTriggerConditionRow';
import EmptyResponseIcon from 'components/icons/EmptyResponseIcon';
import openEditDocTriggerConditionModal from './EditDocTriggerConditionModal';
import useTemplateStore from 'stores/templates';

function CrmDocTaskManagement() {
  const [loading, setLoading] = useState(false);
  const [docTriggers, setDocTriggers] = useState<DocTriggerCondition[]>();
  const templateStore = useTemplateStore();

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true);
        const triggers = await getCrmDocTriggers();
        if (!templateStore.fetching && !templateStore.templates.length) {
          await templateStore.fetch();
        }
        setDocTriggers(
          triggers.map(trigger => ({
            ...trigger,
            template: useTemplateStore.getState().templates.find(item => item.id === trigger.templateId),
          })),
        );
      } finally {
        setLoading(false);
      }
    };
    fetch();
  }, []);

  const addNewDocTrigger = useCallback(
    async (docTrigger: DocTriggerCondition | undefined, existingDocTriggers: DocTriggerCondition[] | undefined) => {
      const newTrigger = await openEditDocTriggerConditionModal(docTrigger, existingDocTriggers);
      if (newTrigger) {
        setDocTriggers(prev => [
          { ...newTrigger, template: templateStore.templates.find(tmpl => tmpl.id === newTrigger.templateId) },
          ...(prev ?? []),
        ]);
      }
    },
    [templateStore.templates],
  );

  return (
    <div className="w-full h-full flex flex-col">
      <div className="flex items-center justify-between mb-4 gap-2 px-5">
        <h1 className="text-4xl font-semibold m-0">Document Automation</h1>
        <button
          type="button"
          className="rounded px-3 py-1 text-sm text-blue-600 bg-blue-50 hover:bg-blue-100"
          onClick={() => addNewDocTrigger(undefined, docTriggers)}
        >
          Add a new document automation
        </button>
      </div>
      <p className="text-gray-500 px-5">
        Effortlessly generate documents by assigning document tasks and letting AI trigger the process automatically.
      </p>
      <ResponseView
        loading={loading || templateStore.fetching}
        error={!loading && !docTriggers ? 'Failed to load documentation tasks!' : ''}
        noData={!docTriggers?.length}
        noDataIcon={<EmptyResponseIcon />}
        noDataMsg="You do not have any document automation... yet"
      >
        <div className="px-5 pt-4 pb-10">
          {docTriggers?.map(trigger => (
            <DocTriggerConditionRow
              key={trigger.id}
              className="mb-4 shadow rounded pl-4 py-2"
              docTrigger={trigger}
              onDelete={() => setDocTriggers(prev => prev?.filter(item => item.id !== trigger.id))}
              onUpdate={newTrigger =>
                setDocTriggers(prev =>
                  prev?.map(item =>
                    item.id !== newTrigger.id
                      ? item
                      : {
                          ...newTrigger,
                          template: templateStore.templates.find(tmpl => tmpl.id === newTrigger.templateId),
                        },
                  ),
                )
              }
              allDocTriggers={docTriggers}
            />
          ))}
        </div>
      </ResponseView>
    </div>
  );
}

export default CrmDocTaskManagement;
