/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import { createEditor } from 'slate';
import { Editable, RenderElementProps, RenderLeafProps, Slate, withReact } from 'slate-react';
import Element from '../Element';
import { CustomElement } from '../types';
import withInlines from '../EditorContext/withInlines';
import Leaf from '../Leaf';
import { cloneDeep } from 'lodash';

interface Props {
  element: CustomElement;
  documentId: string | null;
  opportunityId: string | null;
  enableComment: boolean;
}

function DraggingElementEditor({ element, documentId, opportunityId, enableComment }: Props) {
  const editor = React.useMemo(() => withInlines(withReact(createEditor())), []);
  const initValue = useMemo(() => [cloneDeep(element)], [element]);

  return (
    // add padding to make sure not trigger bottom element hover style
    <div className="opacity-50 py-4 -mt-4" key={element.uuid}>
      <Slate editor={editor} initialValue={initValue}>
        <Editable
          readOnly
          // we need to use renderElement and renderLeaf, otherwise, the inline Link in block didn't get render well
          renderElement={(props: RenderElementProps) => (
            <Element
              editor={editor}
              attributes={props.attributes}
              element={props.element}
              blockPlaceholder={"Type '/' to add discovery fields or more"}
              emptyParagraphPlaceholder="Type '/' to add discovery fields or more"
              documentId={documentId}
              opportunityId={opportunityId}
              readOnly
              enableComment={enableComment}
              docSource="main-doc"
            >
              {props.children}
            </Element>
          )}
          renderLeaf={(props: RenderLeafProps) => (
            <Leaf attributes={props.attributes} leaf={props.leaf} text={props.text}>
              {props.children}
            </Leaf>
          )}
        />
      </Slate>
    </div>
  );
}

export default DraggingElementEditor;
