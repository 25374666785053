import React, { useEffect, useState } from 'react';
import Loader from 'components/Loader';
import { Navigate, useSearchParams } from 'react-router-dom';

import useDocumentStore from 'stores/document';
import { EntityDocument } from 'types/entity';

export default function RedirectPage() {
  const [searchParams] = useSearchParams();
  const documentStore = useDocumentStore();

  const discoveryDocumentId = searchParams.get('discoveryDocumentId');
  const accountId = searchParams.get('accountId');
  const opportunityId = searchParams.get('opportunityId');

  const crmOpportunityId = discoveryDocumentId || opportunityId;
  const crmAccountId = accountId;

  const [isLoading, setIsLoading] = useState(true);
  const [entity, setEntity] = useState<EntityDocument | null>(null);

  useEffect(() => {
    const fetchEntity = async (crmId: string, entityType: 'Opportunity' | 'Account') => {
      setIsLoading(true);
      const entityDocument = await documentStore.getOrCreateNewSFEntity(crmId, entityType);

      setEntity(entityDocument || null);
      setIsLoading(false);
    };

    if (crmOpportunityId) {
      fetchEntity(crmOpportunityId, 'Opportunity');
    } else if (crmAccountId) {
      fetchEntity(crmAccountId, 'Account');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discoveryDocumentId, accountId, opportunityId]);

  if (entity) {
    if (discoveryDocumentId) {
      return <Navigate to={`/opportunity/${entity?.id}`} replace />;
    }
    if (accountId) {
      return <Navigate to={`/account/${entity?.id}`} replace />;
    }
    if (opportunityId) {
      return <Navigate to={`/opportunity/${entity?.id}`} replace />;
    }
  }

  if (!isLoading) {
    // if no match, redirect home
    return <Navigate to="/" replace />;
  }

  return (
    <div className="flex items-center justify-center h-full">
      <Loader />
    </div>
  );
}
