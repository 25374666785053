/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-no-useless-fragment */
import { Button as MuiButton, ButtonProps as MuiButtonProps, Tooltip, TooltipProps } from '@mui/material';
import React, { useState } from 'react';
import { concat } from 'utils/styling';
import { omit } from 'lodash';
import styles from './BaseButton.module.css';
import SpinLoader from './icons/SpinLoader';

export type BaseButtonVariant = 'text' | 'outlined' | 'contained';
export type BaseButtonColor = 'primary' | 'secondary' | 'error' | 'warning' | 'success';
export type BaseButtonProps = Omit<MuiButtonProps, 'variant' | 'color'> & {
  variant?: BaseButtonVariant;
  color?: BaseButtonColor;
  type?: 'submit' | 'button';
  loading?: boolean;
  iconBtn?: boolean;
  tooltip?: string;
  hideTooltipToPreventJump?: boolean;
  contentClassName?: string;
  tooltipPlacement?: TooltipProps['placement'];
};

const BaseButton = React.forwardRef<HTMLButtonElement, BaseButtonProps>((props, ref) => {
  const [hideTooltip, setHideTooltip] = useState(false);
  const renderBtn = () => {
    return (
      <MuiButton
        {...omit(props, 'loading', 'iconBtn', 'hideTooltipToPreventJump', 'contentClassName', 'tooltipPlacement')}
        disabled={props.disabled || props.loading}
        ref={ref}
        className={concat(
          'button',
          styles.baseBtn,
          props.iconBtn && styles.iconBtn,
          props.loading && styles.loading,
          props.className,
        )}
        type={props.type}
        disableElevation
        onClick={e => {
          // prevent tooltip jump when anchor button disapear
          if (props.tooltip && props.hideTooltipToPreventJump) {
            setHideTooltip(true);
            setTimeout(() => {
              setHideTooltip(false);
            });
          }
          props.onClick?.(e);
        }}
      >
        <div className={concat(styles.btnContent, props.contentClassName)}>{props.children}</div>
        {props.loading && <SpinLoader className={concat('animate-spin', styles.animateSpin)} />}
      </MuiButton>
    );
  };
  return props.tooltip && !hideTooltip ? (
    <Tooltip title={hideTooltip ? '' : props.tooltip} placement={props.tooltipPlacement} arrow>
      {renderBtn()}
    </Tooltip>
  ) : (
    renderBtn()
  );
});
BaseButton.displayName = 'BaseButton';
BaseButton.defaultProps = {
  variant: 'text',
  color: 'primary',
  type: 'button',
  iconBtn: false,
  tooltip: '',
  loading: false,
  hideTooltipToPreventJump: false,
  contentClassName: '',
  tooltipPlacement: 'top',
};

export default BaseButton;
