import { EntityDocument } from 'types/entity';
import httpClient from '../http-client';

export interface Note {
  serviceId: string;
  serviceToken: string;
}

export interface EntityDocumentRich {
  entityDocument: EntityDocument;
  note: Note;
  numberOfDiscoveryQuestionsCompleted: number;
  numberOfDiscoveryQuestionsTotal: number;
  numberOfMeetings: number;
  timeSpent: number;
}

export default async (documentId: string): Promise<EntityDocumentRich> => {
  // eslint-disable-next-line no-console
  httpClient.get(`/entity-load-vector/${documentId}`).catch(console.error);
  const { data } = await httpClient.get<EntityDocumentRich>(`/entities/${documentId}`);
  return data;
};
