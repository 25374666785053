import React from 'react';
import { Account, EntityType, Opportunity } from '../../types/entity';
import useDocumentStore from '../../stores/document';
import { currencyFormatter } from '../../utils/number-formatter';

function Tag({ title, value }: { title: string; value: string }) {
  return (
    <div className="bg-slate-100 text-gray-500 rounded p-1 w-fit text-xs">
      {title}: {value}
    </div>
  );
}

interface Props {
  documentId: string;
  type: EntityType;
}
function TitleTags({ documentId, type }: Props) {
  const documentStore = useDocumentStore();
  switch (type) {
    case 'account': {
      const entityDocument = documentStore.entityDocuments.find(d => d.id === documentId);
      if (!entityDocument) return null;
      const crm = entityDocument.crm as Account | undefined;
      if (!crm) return null;
      const ownerName = `${crm.owner?.firstName} ${crm.owner?.lastName}`;
      return (
        <div className="mt-1">
          <Tag title="Owner" value={ownerName} />
        </div>
      );
    }
    case 'opportunity': {
      const entityDocument = documentStore.entityDocuments.find(d => d.id === documentId);
      if (!entityDocument) return null;
      const crm = entityDocument.crm as Opportunity | undefined;
      if (!crm) return null;
      const ownerName = `${crm.owner?.firstName} ${crm.owner?.lastName}`;
      return (
        <div className="mt-1 flex items-center gap-1 text-slate-200">
          <Tag title="Owner" value={ownerName} /> <span>•</span>
          {crm.amount && (
            <>
              <Tag title="Amount" value={currencyFormatter.format(crm.amount)} /> <span>•</span>
            </>
          )}
          {crm.salesEngineer && (
            <>
              <Tag title="Sales Engineer" value={crm.salesEngineer} /> <span>•</span>
            </>
          )}
          <Tag title="Stage" value={crm.stageName} />
        </div>
      );
    }
    default:
      return null;
  }
}

export default TitleTags;
