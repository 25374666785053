/* eslint-disable jsx-a11y/mouse-events-have-key-events, import/no-cycle, consistent-return */

import React, { CSSProperties, useCallback, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Editor, Range, Transforms } from 'slate';
import { useSlateStatic } from 'slate-react';
import { BlockType, CustomElement } from '../types';
import Menu from './SlashCommnadPlugin/Menu';
import { SlatePluginKeyboardEvent } from './types';
import styles from './SlashCommandPlugin.module.css';
import NoteEditor from '../NoteEditor';
import { SlashCommandMenu } from './SlashCommnadPlugin/SlashCommandMenus';
import { createNode } from '../utils';
import { cloneDeep, throttle } from 'lodash';

type PortalProps = { element: HTMLDivElement | null; children: React.ReactNode };

function Portal({ element, children }: PortalProps) {
  return typeof document === 'object' ? ReactDOM.createPortal(children, element || document.body) : null;
}

function filterMenusByCommand(command: string, menus: SlashCommandMenu[]): SlashCommandMenu[] {
  const com = command.replace(/^\//, '');
  return menus.filter(menu => {
    if (com) {
      return menu.blockType.includes(com);
    }
    return true;
  });
}

interface State {
  show: boolean;
  command: string;
  highlightedBlock: BlockType;
  menus: SlashCommandMenu[];
}

const DEFAULT_STATE: State = {
  show: false,
  command: '',
  highlightedBlock: BlockType.DiscoveryQuestion,
  menus: [],
};

const insertNode = (editor: Editor, node: CustomElement, commandLength: number) => {
  const { selection } = editor;
  if (!selection) return;
  const addElementIndex = selection.focus.path[0];
  // always add an empty paragraph when heading node is void, because current slate has a bug
  // that it will not correctly copy/cut when selected heading node is void
  const addParagraph = addElementIndex === 0 && NoteEditor.isVoid(editor, node);
  if (addParagraph) {
    Transforms.insertNodes(editor, createNode(BlockType.Paragraph), {
      at: [addElementIndex],
    });
  }
  Transforms.insertNodes(editor, node, {
    at: [addElementIndex + (addParagraph ? 1 : 0)],
  });
  if (commandLength > 0) {
    Transforms.delete(editor, { distance: commandLength, unit: 'character', reverse: true });
  }
  Transforms.select(editor, [addElementIndex]);
};

export type SlashCommandRef = {
  scrollMenuIntoView: (name: string) => void;
  state: () => State;
  setState: React.Dispatch<React.SetStateAction<State>>;
};

interface Props {
  scrollContainer: HTMLDivElement | null;
  commandMenus: SlashCommandMenu[];
}

const SlashCommand = React.forwardRef<SlashCommandRef, Props>(({ scrollContainer, commandMenus }: Props, ref) => {
  const [state, setState] = React.useState<State>({ ...DEFAULT_STATE, menus: commandMenus });
  const mouseActiveInMenus = useRef(false);
  const [commandCssProperty, setCommandCssProperty] = React.useState<CSSProperties | null>();
  const editor = useSlateStatic();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const slashShowRef = useRef(state.show);

  const updatePopupPosition = useCallback(() => {
    const domSelection = window.getSelection();
    if (domSelection) {
      const domRange = domSelection.getRangeAt(0);
      const rect =
        (domSelection.focusNode as HTMLElement)?.getBoundingClientRect?.() || domRange.getBoundingClientRect();
      const parentRect = document.body.getBoundingClientRect();

      const edgePadding = 16;
      const minHeight = 100;
      if (rect.top < parentRect.height / 2) {
        const top = rect.top + rect.height + 5;
        setCommandCssProperty(prev => ({
          opacity: '1',
          top,
          left: prev?.left || rect.left + 5,
          maxHeight: Math.max(parentRect.height - top - edgePadding, minHeight),
          zIndex: 2000,
        }));
      } else {
        const top = rect.top - 5;
        setCommandCssProperty(prev => ({
          opacity: '1',
          top,
          left: prev?.left || rect.left + 5,
          transform: 'translateY(-100%)',
          maxHeight: Math.max(top - edgePadding, minHeight),
          zIndex: 2000,
        }));
      }
    }
  }, []);

  React.useEffect(() => {
    // reset mouse active flag when open status change
    mouseActiveInMenus.current = false;

    const detectOutsideClick = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setState(DEFAULT_STATE);
      }
    };
    document.addEventListener('mousedown', detectOutsideClick);

    if (!state.show) {
      setCommandCssProperty(null);
      return () => {
        document.removeEventListener('mousedown', detectOutsideClick);
      };
    }

    setTimeout(() => {
      updatePopupPosition();
    });

    return () => {
      document.removeEventListener('mousedown', detectOutsideClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.show]);

  React.useEffect(() => {
    slashShowRef.current = state.show;
  }, [state.show]);

  React.useEffect(() => {
    if (scrollContainer) {
      const handleScroll = throttle(() => {
        if (slashShowRef.current) {
          updatePopupPosition();
        }
      }, 10);
      scrollContainer.addEventListener('scroll', handleScroll);
      return () => scrollContainer.removeEventListener('scroll', handleScroll);
    }
  }, [scrollContainer]);

  React.useImperativeHandle(ref, () => ({
    scrollMenuIntoView: (menu: string) => {
      if (containerRef.current) {
        const menuElement = containerRef.current.querySelector(`[data-menu="${menu}"]`);
        if (menuElement) {
          const rect = menuElement.getBoundingClientRect();
          const parentRect = containerRef.current.getBoundingClientRect();
          if (rect.top - 8 < parentRect.top) {
            containerRef.current.scrollBy(0, rect.top - 8 - parentRect.top);
          } else if (rect.bottom + 8 > parentRect.bottom) {
            containerRef.current.scrollBy(0, rect.bottom + 8 - parentRect.bottom);
          }
        }
      }
    },
    state: () => state,
    setState,
  }));

  if (state.show && commandCssProperty) {
    return (
      <Portal element={null}>
        <div
          ref={containerRef}
          tabIndex={0}
          className={styles.command}
          style={{ ...commandCssProperty }}
          role="button"
          onMouseDown={e => e.preventDefault()}
          onMouseMove={() => {
            mouseActiveInMenus.current = true;
          }}
        >
          <div style={{ width: '100%' }}>
            {state.menus.length <= 0 && <div className={styles['block-title']}>No results</div>}
            {state.menus.map(menu => (
              <Menu
                key={menu.text}
                text={menu.text}
                description={menu.description}
                imageUrl={menu.imageUrl}
                selected={menu.blockType === state.highlightedBlock}
                onMouseHover={() => {
                  // only focus hovered element when mouse is active which means mouse has moved inside the popover
                  if (mouseActiveInMenus.current) {
                    setState(prevState => ({ ...prevState, highlightedBlock: menu.blockType }));
                  }
                }}
                onClick={() => {
                  menu.onClick(() => insertNode(editor, menu.node, state.command.length));
                  setState(DEFAULT_STATE);
                }}
                blockType={menu.blockType}
              />
            ))}
          </div>
        </div>
      </Portal>
    );
  }
  return null;
});

SlashCommand.displayName = 'SlashCommand';

function SlashCommandPlugin(scrollContainer: HTMLDivElement | null, commandMenus: SlashCommandMenu[]) {
  const slashCommandRef = useRef<SlashCommandRef>(null);

  const onKeyDown: SlatePluginKeyboardEvent = (event, editor): boolean => {
    // curosr is placed at an empty paragraph block
    // this is diff from notion, but similar to fellowapp
    if (
      !slashCommandRef.current?.state().show &&
      event.key === '/' &&
      editor.selection &&
      NoteEditor.string(editor, [editor.selection.anchor.path[0]]) === '' &&
      Range.isCollapsed(editor.selection) &&
      (editor.children[editor.selection.anchor.path[0]] as CustomElement).type === BlockType.Paragraph
    ) {
      slashCommandRef.current?.setState({ ...DEFAULT_STATE, command: '/', menus: commandMenus, show: true });
    }
    if (slashCommandRef.current?.state().show) {
      if (event.key.length === 1) {
        slashCommandRef.current.setState(prevState => {
          const command = prevState.command + event.key;
          const menus = filterMenusByCommand(command, commandMenus);
          let highlightedBlock = BlockType.Paragraph;
          // if user input @ to trigger mention popover, hide slash menu
          if (command.includes('@')) {
            return { ...DEFAULT_STATE, menus: commandMenus };
          }
          if (menus.length > 0) {
            highlightedBlock =
              menus.find(m => m.blockType === prevState.highlightedBlock)?.blockType || menus[0].blockType;
          } else {
            const temp = filterMenusByCommand(command.slice(0, -1), commandMenus);
            if (temp.length === 0) return { ...DEFAULT_STATE, menus: commandMenus };
          }

          return {
            ...prevState,
            command,
            menus,
            highlightedBlock,
          };
        });
      }

      if (event.key === 'Backspace') {
        slashCommandRef.current?.setState(prevState => {
          if (prevState.command.length === 0) {
            return { ...DEFAULT_STATE, menus: commandMenus };
          }
          const command = prevState.command.slice(0, -1);
          const menus = filterMenusByCommand(command, commandMenus);
          let highlightedBlock = BlockType.Paragraph;
          // if user clear all input command, hide slash menu
          if (!command.trim()) {
            return { ...DEFAULT_STATE, menus: commandMenus };
          }
          if (menus.length > 0) {
            highlightedBlock =
              menus.find(m => m.blockType === prevState.highlightedBlock)?.blockType || menus[0].blockType;
          } else {
            const temp = filterMenusByCommand(command.slice(0, -1), commandMenus);
            if (temp.length === 0) return { ...DEFAULT_STATE, menus: commandMenus };
          }

          return {
            ...prevState,
            command,
            menus,
            highlightedBlock,
          };
        });
      }

      if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
        event.preventDefault();
        slashCommandRef.current.setState(prevState => {
          const menus = filterMenusByCommand(prevState.command, commandMenus);
          let location = menus.findIndex(m => m.blockType === prevState.highlightedBlock);
          if (location === -1) {
            return { ...prevState };
          }
          if (event.key === 'ArrowUp' && location > 0) {
            location -= 1;
          }

          if (event.key === 'ArrowDown' && location < menus.length - 1) {
            location += 1;
          }

          const highlightedBlock = menus[location].blockType;
          slashCommandRef.current?.scrollMenuIntoView(highlightedBlock);
          return {
            ...prevState,
            highlightedBlock,
          };
        });
      }

      if (event.key === 'Enter') {
        event.preventDefault();
        const menu = slashCommandRef.current
          ?.state()
          .menus.find(m => m.blockType === slashCommandRef.current?.state().highlightedBlock);
        if (menu)
          menu.onClick(() => {
            insertNode(editor, cloneDeep(menu.node), slashCommandRef.current?.state().command.length ?? 0);
            slashCommandRef.current?.setState(DEFAULT_STATE);
          });
        return true;
      }
      // hide slash command plugin when arrow left right
      if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
        slashCommandRef.current?.setState(DEFAULT_STATE);
        return true;
      }
    }
    return false;
  };

  return {
    key: 'slash-command-plugin',
    onKeyDown,
    onAction: (action: string) => {
      if (action === 'show-slash-menu') {
        slashCommandRef.current?.setState({ ...DEFAULT_STATE, menus: commandMenus, show: true });
        return true;
      }
      return false;
    },
    element: <SlashCommand commandMenus={commandMenus} scrollContainer={scrollContainer} ref={slashCommandRef} />,
  };
}

export default SlashCommandPlugin;
