import React, { useEffect, useState } from 'react';
import useModalStore from 'stores/modal';
import SpinLoader from '../../../icons/SpinLoader';
import ChevronDownIcon from '@heroicons/react/24/outline/ChevronDownIcon';
import { Checkbox } from '@mui/material';
import BaseButton from '../../../BaseButton';
import { AIWorker, AIWorkerScope, AIWorkerScopeRow, AIWorkerScopeRowType } from 'types/ai-worker';
import { ModalCloseAction } from 'types/modal';
import { getAIWorkerScopes } from '../../../../api/ai-worker';

export interface AIWorkerScopeModalInit {
  isFromTpl: boolean;
  worker: AIWorker;
}

export interface AIWorkerScopeModalProps {
  onConfirm: (scope: AIWorkerScope) => Promise<void>;
  initValue: AIWorkerScopeModalInit;
  modalId: string;
}

export function AIWorkerScopeModal({ onConfirm, initValue, modalId }: AIWorkerScopeModalProps) {
  const modalStore = useModalStore();
  const [scope, setScope] = useState<AIWorkerScope>({
    ...initValue.worker.scopeObj,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [expand, setExpand] = useState<Record<string, boolean>>({});
  const [scopeRows, setScopeRows] = useState<AIWorkerScopeRow[]>([]);

  const hasOppt = scopeRows.some(i => i.typeName === 'Opportunity');
  const showCrmNote = scopeRows.some(i => i.typeName === 'CrmNote') || initValue.isFromTpl;

  useEffect(() => {
    getAIWorkerScopes(initValue.worker.id).then(setScopeRows);
  }, []);

  const handleCheckboxClick = (row: AIWorkerScopeRow, checked: boolean) => {
    let values = [...((scope[row.typeName] || []) as string[])];
    const groupRows = scopeRows.filter(i => i.typeName === row.typeName);
    if (values.includes('all')) {
      values = groupRows.map(i => i.id);
    }

    if (values.includes(row.id)) {
      values.splice(values.indexOf(row.id), 1);
    }
    if (checked) {
      values.push(row.id);
    }
    const allChecked = !groupRows.some(i => !values.includes(i.id));
    if (allChecked) {
      values = ['all'];
    }
    setScope({ ...scope, [row.typeName]: values });
  };

  const renderCheckbox = (row: AIWorkerScopeRow) => {
    const values = (scope[row.typeName] || []) as string[];
    const checked = values.includes('all') || values.includes(row.id);
    return (
      <div key={row.id} className="flex flex-row items-center ml-6">
        <Checkbox size="small" checked={checked} onChange={() => handleCheckboxClick(row, !checked)} />
        <span className="text-base truncate">{row.label}</span>
      </div>
    );
  };

  const renderGroup = (label: string, type: AIWorkerScopeRowType) => {
    const renderRows = scopeRows.filter(i => i.typeName === type);
    const groupRows = scopeRows.filter(i => i.typeName === type);
    const values = (scope[type] || []) as string[];
    const checked = values.includes('all') || (groupRows.length > 0 && !groupRows.some(i => !values.includes(i.id)));
    const indeterminate = !values.includes('all') && groupRows.some(i => values.includes(i.id));
    return (
      <div>
        <div className="mt-2 w-full flex flex-col">
          <div className="flex flex-row items-center w-full">
            <Checkbox
              size="small"
              checked={checked}
              indeterminate={indeterminate}
              onChange={() =>
                setScope({
                  ...scope,
                  [type]: checked ? [] : ['all'],
                })
              }
            />
            <BaseButton
              className="w-full"
              contentClassName="flex-1 flex flex-row cursor-pointer items-center"
              onClick={() => {
                setExpand({ [label]: !expand[label] });
              }}
            >
              <span className="text-base text-black flex-1 text-left">{label}</span>
              <ChevronDownIcon className="w-5 h-5" />
            </BaseButton>
          </div>
        </div>
        {expand[label] && <div>{renderRows.map(i => renderCheckbox(i))}</div>}
      </div>
    );
  };
  return (
    <div className="w-[30rem]">
      <div className="border-b p-8 pb-4 pt-4">
        <div className="text-xl font-bold text-gray-700 mb-8 mt-4">AI Worker Scope Setting</div>

        <div className="overflow-y-auto max-h-[30rem]">
          {renderGroup('All Account Fields', 'Account')}
          {hasOppt && renderGroup('All Opportunity Fields', 'Opportunity')}
          {showCrmNote && renderGroup('All Crm Documents', 'CrmNote')}
          {renderGroup('All Documents', 'Note')}
          {renderGroup('All Files', 'File')}
        </div>
      </div>

      <div className="mt-4 pt-0 pb-4 flex flex-row justify-around">
        <button
          onClick={() => modalStore.close(ModalCloseAction, modalId)}
          type="button"
          className="px-4 py-2 bg-gray-100 hover:bg-gray-200 text-gray-500 rounded"
        >
          Cancel
        </button>
        <button
          onClick={async () => {
            setIsLoading(true);
            await onConfirm(scope).then(() => modalStore.close(ModalCloseAction, modalId));
            setIsLoading(false);
          }}
          type="button"
          className="w-24 px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded"
          disabled={isLoading}
        >
          {isLoading ? <SpinLoader className="w-4 h-4 text-orange-500 animate-spin" /> : <span>Update</span>}
        </button>
      </div>
    </div>
  );
}

export function useAIWorkerScopeModal() {
  const modalStore = useModalStore();
  const modalId = `${Date.now()}-${Math.random()}`;
  const createAIWorkerScopeWithModal = (initValue: AIWorkerScopeModalInit, onRet: (scope: AIWorkerScope) => void) => {
    modalStore.setState(
      {
        isOpen: true,
        element: (
          <AIWorkerScopeModal
            modalId={modalId}
            initValue={initValue}
            onConfirm={async ret => {
              onRet({ ...ret });
            }}
          />
        ),
      },
      modalId,
    );
  };

  return { createNewDocumentWithModal: createAIWorkerScopeWithModal };
}
