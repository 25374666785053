import React from 'react';
import NoteIcon from './NoteIcon';
import SalesforceIcon from './SalesforceIcon';

interface Props {
  className?: string;
}

function SalesforceNoteIcon({ className }: Props) {
  return (
    <div className="flex relative">
      <NoteIcon className={className} />
      <SalesforceIcon className="absolute top-0 right-0 w-3 h-3" />
    </div>
  );
}

SalesforceNoteIcon.defaultProps = {
  className: undefined,
};

export default SalesforceNoteIcon;
