import { EntityDocument, EntityName } from 'types/entity';
import { Document } from 'types/document';
import httpClient from '../http-client';

export interface Response {
  entityDocument: EntityDocument;
  documents: Document[];
}

export default async (name: string, entityName: EntityName, parentDocumentId: null | string): Promise<Response> => {
  const { data } = await httpClient.put<Response>(`/entities`, {
    name,
    entityName,
    parentDocumentId,
  });
  return data;
};
