/* eslint-disable no-nested-ternary */
import React, { CSSProperties } from 'react';
import { Transforms } from 'slate';
import { useSlate } from 'slate-react';

import ChevronDown from 'components/NoteEditor/icons/ChevronDown';
import { BlockType, CustomElement } from 'components/NoteEditor/types';

import styles from './ToolbarTypeSelect.module.css';

interface ToolbarSelectionsProps {
  blockType: BlockType;
  label?: string;
}

function ToolbarSelections({ blockType, label }: ToolbarSelectionsProps) {
  const editor = useSlate();

  const updateNode = () => {
    if (editor.selection) {
      const path = [editor.selection.focus.path[0]];
      Transforms.setNodes<CustomElement>(editor, { type: blockType }, { at: path });
      Transforms.deselect(editor);
    }
  };

  return (
    <button type="button" className={styles.selection} onClick={updateNode}>
      <img alt="Text" src={`/images/notes/${blockType}.svg`} className={styles['selection-img']} />
      <span>{label || blockType.replace('-', ' ')}</span>
    </button>
  );
}

ToolbarSelections.defaultProps = {
  label: undefined,
};

const TURN_INTO_POPUP_HEIGHT = 213;

function ToolbarTypeSelector() {
  const [open, setOpen] = React.useState<boolean>(false);
  const [selectorCssProperty, setSelectorCssProperty] = React.useState<CSSProperties>({});
  const [blockType, setBlockType] = React.useState<BlockType>(BlockType.Paragraph);
  const buttonRef = React.createRef<HTMLButtonElement>();
  const editor = useSlate();

  React.useEffect(() => {
    if (editor.selection) {
      const child = editor.children[editor.selection.focus.path[0]] as CustomElement;
      setBlockType(child.type as BlockType);
    }
  }, [editor]);

  React.useEffect(() => {
    if (open && buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      const parentRect = document.body.getBoundingClientRect();
      if (rect.bottom + TURN_INTO_POPUP_HEIGHT + 16 > parentRect.height) {
        setSelectorCssProperty({
          top: 0 - TURN_INTO_POPUP_HEIGHT - 8,
          left: 0,
        });
      } else {
        setSelectorCssProperty({
          top: rect.height + 5,
          left: 0,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div className={styles.selector}>
      <button
        type="button"
        className={styles.button}
        ref={buttonRef}
        onMouseDown={e => {
          setOpen(prev => !prev);
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div>
          {blockType === BlockType.Paragraph
            ? 'Text'
            : blockType === BlockType.OrderedList
            ? 'Numbered List'
            : blockType.replace('-', ' ')}
        </div>
        <div style={{ paddingLeft: '0.5rem' }}>
          <ChevronDown style={{ width: '0.625rem', height: '0.625rem' }} />
        </div>
      </button>
      {open && (
        <div
          className={styles.dropdown}
          tabIndex={0}
          role="button"
          style={{
            ...selectorCssProperty,
            display: open ? 'hidden' : 'block',
            opacity: open ? 1 : 0,
          }}
          onMouseDown={e => {
            e.preventDefault();
          }}
        >
          <div className={styles['dropdown-title']}>TURN INTO</div>
          <ToolbarSelections blockType={BlockType.Paragraph} label="Text" />
          <ToolbarSelections blockType={BlockType.H1} />
          <ToolbarSelections blockType={BlockType.H2} />
          <ToolbarSelections blockType={BlockType.OrderedList} label="Numbered List" />
          <ToolbarSelections blockType={BlockType.BulletedList} />
        </div>
      )}
    </div>
  );
}

export default ToolbarTypeSelector;
