import { Editor, Node, Range, Transforms } from 'slate';
import NoteEditor from '../NoteEditor';
import { BlockElement, BlockType, CustomElement } from '../types';
import { SlatePlugin } from './types';

function applyBulletPoint(event: React.KeyboardEvent<HTMLDivElement>, editor: Editor): boolean {
  if (event.key === ' ') {
    const { selection } = editor;
    if (!selection || !Range.isCollapsed(selection)) {
      return false;
    }
    const path = selection.anchor.path.slice(0, 1);
    if (!Node.has(editor, path) || (Node.get(editor, path) as BlockElement).type !== BlockType.Paragraph) {
      return false;
    }
    // typed */s at the beginning of the element
    const isTypedStar =
      NoteEditor.string(editor, [selection.anchor.path[0]]).startsWith('*') &&
      selection.anchor.path[1] === 0 &&
      selection.anchor.offset === 1;
    if (isTypedStar) {
      event.preventDefault();
      Transforms.setNodes<CustomElement>(editor, { type: BlockType.BulletedList }, { at: [selection.anchor.path[0]] });
      Transforms.delete(editor, { distance: 1, unit: 'character', reverse: true });
      return false;
    }
    // typed -/s at the beginning of the element
    const isTypedDash =
      NoteEditor.string(editor, [selection.anchor.path[0]]).startsWith('-') &&
      selection.anchor.path[1] === 0 &&
      selection.anchor.offset === 1;
    if (isTypedDash) {
      event.preventDefault();
      Transforms.setNodes<CustomElement>(editor, { type: BlockType.BulletedList }, { at: [selection.anchor.path[0]] });
      Transforms.delete(editor, { distance: 1, unit: 'character', reverse: true });
    }
    return false;
  }
  return false;
}

function applyOrderedPoint(event: React.KeyboardEvent<HTMLDivElement>, editor: Editor): boolean {
  if (event.key === ' ') {
    const { selection } = editor;
    if (!selection || !Range.isCollapsed(selection)) {
      return false;
    }
    const path = selection.anchor.path.slice(0, 1);
    if (!Node.has(editor, path) || (Node.get(editor, path) as BlockElement).type !== BlockType.Paragraph) {
      return false;
    }
    // typed [any_digits]. at the beginning of the element
    const elementString = NoteEditor.string(editor, [selection.anchor.path[0]]);
    let numberOfLeadingDigit = 0;
    for (let i = 0; i < elementString.length; i += 1) {
      if (elementString[i] >= '0' && elementString[i] <= '9') {
        numberOfLeadingDigit += 1;
      } else if (numberOfLeadingDigit !== 0 && elementString[i] === '.') {
        if (selection.anchor.path[1] === 0 && selection.anchor.offset === numberOfLeadingDigit + 1) {
          event.preventDefault();
          Transforms.setNodes<CustomElement>(
            editor,
            { type: BlockType.OrderedList },
            { at: [selection.anchor.path[0]] },
          );
          Transforms.delete(editor, { distance: 1, unit: 'character', reverse: true });
          Transforms.delete(editor, { distance: 1, unit: 'character', reverse: true });
          return true;
        }
      }
    }
  }
  return false;
}

const ItemListPlugin: SlatePlugin = {
  key: 'item-list-plugin',
  onKeyDown: (event, editor) => {
    const stop = applyBulletPoint(event, editor);
    if (stop) {
      return true;
    }
    return applyOrderedPoint(event, editor);
  },
};

export default ItemListPlugin;
