import React, { useEffect, useState } from 'react';
import { getLocalstorageItem, setLocalstorageItem } from 'utils/localstorage';

function useLocalStorageState<T>(
  localStorageKey: string,
  defaultV: T,
  isObj = true,
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [state, setState] = useState<T>(getLocalstorageItem(localStorageKey, defaultV, isObj));
  useEffect(() => {
    if (localStorageKey) {
      setLocalstorageItem(localStorageKey, state, isObj);
    }
  }, [state]);
  return [state, setState];
}

export default useLocalStorageState;
