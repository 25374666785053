/* eslint-disable react/jsx-props-no-spreading */

import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';

import CalendarIcon from 'components/icons/CalendarIcon';
import SpinLoader from 'components/icons/SpinLoader';
import constants from 'utils/constants';

import styles from './DateField.module.css';

interface Props {
  isEditable: boolean;
  isUpdating: boolean;
  value: string;
  onChange: (value: string) => void;
}

function DatetimeField({ isEditable, isUpdating, value, onChange }: Props) {
  const initValue = value ? moment(value, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DDTHH:mm:ss') : '';
  const [datetime, setDatetime] = useState<string>(initValue);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (initValue !== datetime) {
      setDatetime(initValue);
    }
  }, [initValue]);

  useEffect(() => {
    if (!isOpen && datetime !== initValue) {
      onChange(datetime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <div className="w-full">
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DateTimePicker
          className={styles.date}
          open={isOpen}
          onClose={() => setIsOpen(false)}
          disableMaskedInput
          inputFormat={constants.DATETIME_WITH_YEAR_FORMAT}
          value={datetime}
          onChange={(newValue: Moment | null) => {
            const newDatetime = newValue?.format('YYYY-MM-DDTHH:mm:ss') || '';
            setDatetime(newDatetime);
          }}
          renderInput={params => (
            <button
              type="button"
              className="bg-gray-100 px-2 py-1 flex items-center text-gray-500 text-sm"
              onClick={() => setIsOpen(true)}
              disabled={!isEditable || isUpdating}
            >
              <div ref={params.inputRef}>{params.inputProps?.value || 'Select a datetime'}</div>
              <CalendarIcon className="ml-1" />
            </button>
          )}
        />
      </LocalizationProvider>
      <div className="flex items-center text-xs gap-1">
        {isUpdating && (
          <>
            <SpinLoader className="w-5 h-5 p-1 animate-spin" />
            <span>Updating...</span>
          </>
        )}
      </div>
    </div>
  );
}

export default DatetimeField;
