import React from 'react';

interface Props {
  className?: string;
}

function SearchIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8335 16.667C14.0553 16.667 16.667 14.0553 16.667 10.8335C16.667 7.61175 14.0553 5 10.8335 5C7.61175 5 5 7.61175 5 10.8335C5 14.0553 7.61175 16.667 10.8335 16.667Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.9996 18.9996L14.9551 14.9551"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SearchIcon.defaultProps = {
  className: undefined,
};

export default SearchIcon;
