/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-static-element-interactions, react/no-unknown-property */
import SpinLoader from 'components/icons/SpinLoader';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ReactEditor } from 'slate-react';
import useImageEntityStore from 'stores/image-entity';
import useNoteEditorStore from 'stores/note-editor';
import { isValidImageFileUpload } from 'utils/file';
import { concat } from 'utils/styling';
import { CustomEditor, CustomRenderElementProps, ImageElement as ImageElementType } from '../types';
import styles from './ImageElement.module.css';
import useDragHandler from 'hooks/useDragHandler';
import { Transforms } from 'slate';
import { isEmpty, values } from 'lodash';
import CommentRowButton from '../CustomElement/Comment/CommentRowButton';
import useEditorSelectedFocused from 'hooks/useEditorSelectedFocused';

const MIN_IMG_WIDTH = 100;

interface Props extends CustomRenderElementProps {
  element: ImageElementType;
  editor: CustomEditor;
}

function ImageElement({ attributes, children, element, editor }: Props) {
  const inputFile = React.useRef<HTMLInputElement>(null);
  const imageEntityStore = useImageEntityStore();
  const noteEditorStore = useNoteEditorStore();
  const entity = useMemo(
    () => (element.fileId ? imageEntityStore.entities[element.fileId] : undefined),
    [element.fileId, imageEntityStore.entities],
  );
  const selected = useEditorSelectedFocused();
  const imgWrapRef = useRef<HTMLDivElement>(null);
  const startResizeImgWidth = useRef<number>(0);
  const [imgLoaded, setImgLoaded] = useState(false);

  const { onStartDrag, dragging, offsetX, draggingItem, setDraggingItem } = useDragHandler<'left' | 'right'>(0, () => {
    const path = ReactEditor.findPath(editor, element);
    Transforms.setNodes(editor, { width: imgWrapRef.current?.clientWidth }, { at: path });
  });

  useEffect(() => {
    // if entity is not found, fetch it
    if (!element.isInput && !entity && element.fileId && !element.url) {
      // prevent same image load multi times
      if (!useImageEntityStore.getState().entities[element.fileId]?.loading) {
        imageEntityStore.fetchEntity(element.fileId);
      }
    }
  }, [entity, element.fileId, element.isInput]);

  // loaded state
  if ((entity && entity.url) || element.url) {
    return (
      <figure {...attributes} className={styles.figure}>
        {children}
        <div
          contentEditable={false}
          className={concat(
            styles.imgWrap,
            dragging && styles.dragging,
            selected && styles.selected,
            'rounded select-none',
            typeof element.width !== 'number' && !imgLoaded && 'w-full h-28',
          )}
          ref={imgWrapRef}
          style={{
            width: dragging
              ? Math.max(
                  startResizeImgWidth.current + (draggingItem === 'left' ? -offsetX * 2 : offsetX * 2),
                  MIN_IMG_WIDTH,
                )
              : element.width,
          }}
        >
          <img
            draggable={false}
            src={entity?.url || element.url}
            alt={entity?.fileName || element.fileName}
            className={concat(styles.img, (typeof element.width === 'number' || dragging) && styles.imgParentSize)}
            onLoad={() => setImgLoaded(true)}
          />
          {imgLoaded && (
            <>
              <div
                className={concat(styles.dragHandlerWrap, styles.left)}
                onMouseDown={e => {
                  onStartDrag(e);
                  setDraggingItem('left');
                  startResizeImgWidth.current = element.width || imgWrapRef.current?.clientWidth || MIN_IMG_WIDTH;
                }}
              >
                <div className={styles.dragHandler} />
              </div>
              <div
                className={concat(styles.dragHandlerWrap, styles.right)}
                onMouseDown={e => {
                  onStartDrag(e);
                  setDraggingItem('right');
                  startResizeImgWidth.current = element.width || imgWrapRef.current?.clientWidth || MIN_IMG_WIDTH;
                }}
              >
                <div className={styles.dragHandler} />
              </div>
            </>
          )}
          <div className={styles.commentBtnWrap}>
            {(!element.comments ||
              isEmpty(element.comments) ||
              values(element.comments).every(comment => !!comment[0].resolved)) && (
              <CommentRowButton
                editor={editor}
                comments={element.comments}
                commentForBlock
                darkStyle
                element={element}
              />
            )}
          </div>
        </div>
      </figure>
    );
  }
  // loading state
  if (entity && !entity.url) {
    return (
      <div {...attributes}>
        {children}
        <div
          contentEditable={false}
          className={concat('flex w-full h-28 items-center rounded select-none', selected && styles.selected)}
        >
          <SpinLoader className="animate-spin w-8 h-8 text-orange-500 mx-auto" />
        </div>
      </div>
    );
  }

  // input image
  if (element.isInput) {
    return (
      <div {...attributes}>
        {children}
        <div
          contentEditable={false}
          className={concat(
            'w-fit flex flex-col border rounded-xl drop-shadow-md bg-white mb-2 select-none',
            selected && styles.selected,
          )}
        >
          <div className="flex flex-row h-12 w-full border-b pl-4">
            <div className="flex border-b-2 border-blue-600 text-blue-600 w-14 h-12 items-center justify-center text-sm">
              Upload
            </div>
          </div>
          <div className="h-18 p-2 py-4">
            <input
              ref={inputFile}
              type="file"
              className="hidden"
              onChange={event => {
                if (!event.target.files) return;
                const files = Array.from(event.target.files);
                if (!isValidImageFileUpload(files)) return;
                noteEditorStore.setState({
                  filesToUpload: files,
                  currentActionEditor: editor,
                  fileDialogId: element.fileId,
                });
              }}
            />
            <button
              type="submit"
              className="flex items-center justify-center bg-blue-600 hover:bg-blue-500 disabled:bg-blue-200 rounded-lg text-white flex-shrink-0 w-80 h-9"
              onClick={() => {
                if (inputFile.current) {
                  inputFile.current.click();
                }
              }}
            >
              Choose an image
            </button>
          </div>
        </div>
      </div>
    );
  }

  // failed/not found state
  return (
    <div {...attributes}>
      {children}
      <div
        contentEditable={false}
        className={concat(
          'flex w-full h-28 border rounded items-center justify-center border border-dashed select-none',
          selected && styles.selected,
        )}
      >
        <SpinLoader className="animate-spin w-8 h-8 text-orange-500" />
      </div>
    </div>
  );
}

export default ImageElement;
