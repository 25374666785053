import httpClient from '../http-client';

export default async (
  entityType: 'document' | 'opportunity' | 'account',
  documentId: string,
  email: string,
  link: string,
): Promise<void> => {
  const emailProcessed = email.trim();
  await httpClient.post(`/user/share`, {
    documentId,
    email: emailProcessed,
    link,
    entityType,
  });
};
