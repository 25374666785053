/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/destructuring-assignment */

import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import ContextPanelView from '../components/ContextPanelView';
import useProductGapStore from '../stores/product-gap';
import openNewGapModal from './ProductGapPage/CreateNewGapModal/CreateNewGapModal';
import { Category, ColumnConfig } from '../components/Table/types';
import Popover from '../components/Popover';
import { ProductCategory } from '../types/product-gap';
import useTableStore from '../stores/table';
import { currencyFormatter } from '../utils/number-formatter';
import Table, { TableRef } from '../components/Table';
import { generateRowDataFromProductGaps } from './ProductGapPage/utils';
import CategoryIcon from '../components/icons/CategoryIcon';
import useContextPanelStore from '../stores/context-panel';
import useUserStore from 'stores/user';
import { addGroupSequnceNumToColumnDefs, filterTableRows } from 'components/Table/utils';
import CustomizeButton from './ReportingPage/CustomizeButton';
import FilterButton from './ReportingPage/FilterButton';
import SortButton from './ReportingPage/SortButton';
import ResponseView from 'components/ResponseView';
import EmptySearchIcon from 'components/icons/EmptySearchIcon';
import EmptyResponseIcon from 'components/icons/EmptyResponseIcon';
import DownloadIcon from 'components/icons/DownloadIcon';
import BaseButton from 'components/BaseButton';

type View = 'Category' | 'Account' | 'Opportunity' | 'None';

interface State {
  isSettingOpen: boolean;
  isAddNewOptionOpen: boolean;
  view: View;
  isViewOpen: boolean;
}

function ProductGapPage() {
  const productGapStore = useProductGapStore();
  const tableStore = useTableStore();
  const userStore = useUserStore();
  const contextPanelStore = useContextPanelStore();

  const [state, setState] = React.useState<State>({
    isSettingOpen: false,
    isAddNewOptionOpen: false,
    view: 'Category',
    isViewOpen: false,
  });

  const viewRef = useRef<HTMLButtonElement>(null);
  const tableRef = useRef<TableRef>(null);

  const updateTableConfig = useCallback((columns: ColumnConfig[]) => {
    tableStore.setState({ productGapTableConfigs: columns });
  }, []);

  useEffect(() => {
    productGapStore.fetch(userStore.user?.productProvider === 'Superpanel');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      contextPanelStore.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStore.user?.productProvider]);

  useEffect(() => {
    tableStore.generateProductGapTableConfigs(productGapStore.productFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productGapStore.productFields]);

  const hasFilters = useMemo(
    () =>
      tableStore.productGapTableConfigs.some(f => ('group' in f ? f.columns.some(col => !!col.filter) : !!f.filter)),
    [tableStore.productGapTableConfigs],
  );

  const rowData = useMemo(() => {
    return filterTableRows(
      generateRowDataFromProductGaps(productGapStore.productGaps),
      tableStore.productGapTableConfigs,
    );
  }, [productGapStore.productGaps, tableStore.productGapTableConfigs]);

  const CATEGORIZED_VIEWS: {
    [view: string]: Category | null;
  } = useMemo(
    () => ({
      Category: {
        title: 'Category',
        getUniques(row) {
          return [
            {
              uniqueId: row.category ? row.category.title : 'ZZZZZ',
              groupData: row.category ? row.category : 'ZZZZZ',
            },
          ];
        },
        render(groupData: ProductCategory | 'ZZZZZ', rows) {
          let totalAmount = 0;
          const addedAmountOpportunityIds: string[] = [];
          rows.forEach(row => {
            if (!addedAmountOpportunityIds.includes(row.opportunity.id)) {
              totalAmount += row.opportunity.amount || 0;
              addedAmountOpportunityIds.push(row.opportunity.id);
            }
          });

          if (groupData === 'ZZZZZ') {
            return (
              <div className="flex gap-2 items-center">
                <div className="font-semibold">No Category</div>
                <div className="text-xs bg-white text-gray-500 rounded-full px-2 no-underline">
                  Count: {rows.length}
                </div>
                <div className="text-xs bg-white text-gray-500 rounded-full px-2 no-underline">
                  Total Amount: {currencyFormatter.format(totalAmount)}
                </div>
              </div>
            );
          }

          return (
            <div className="flex gap-2 items-center">
              <div className="font-semibold">{groupData.title}</div>
              <div className="text-xs bg-white text-gray-500 rounded-full px-2 no-underline">
                Status: {groupData?.status?.name}
              </div>
              <div className="text-xs bg-white text-gray-500 rounded-full px-2 no-underline">Count: {rows.length}</div>
              <div className="text-xs bg-white text-gray-500 rounded-full px-2 no-underline">
                Total Amount: {currencyFormatter.format(totalAmount)}
              </div>
            </div>
          );
        },
      },
      Account: {
        title: 'Account',
        getUniques(row) {
          const accountId = row.account.id;
          return [
            {
              uniqueId: accountId || 'ZZZZZ',
              groupData: accountId || 'ZZZZZ',
            },
          ];
        },
        render(groupData: string, rows) {
          let totalAmount = 0;
          const addedAmountOpportunityIds: string[] = [];
          rows.forEach(row => {
            if (!addedAmountOpportunityIds.includes(row.opportunity.id)) {
              totalAmount += row.opportunity.amount || 0;
              addedAmountOpportunityIds.push(row.opportunity.id);
            }
          });

          if (groupData === 'ZZZZZ') {
            return (
              <div className="flex gap-2 items-center">
                <div className="font-semibold">No Account</div>
                <div className="text-xs bg-white text-gray-500 rounded-full px-2 no-underline">
                  Count: {rows.length}
                </div>
                <div className="text-xs bg-white text-gray-500 rounded-full px-2 no-underline">
                  Total Amount: {currencyFormatter.format(totalAmount)}
                </div>
              </div>
            );
          }

          const { account } = rows[0];
          return (
            <div className="flex gap-2 items-center">
              <div className="font-semibold">{account.name}</div>
              <div className="text-xs bg-white text-gray-500 rounded-full px-2 no-underline">Count: {rows.length}</div>
              <div className="text-xs bg-white text-gray-500 rounded-full px-2 no-underline">
                Total Amount: {currencyFormatter.format(totalAmount)}
              </div>
            </div>
          );
        },
      },
      Opportunity: {
        title: 'Opportunity',
        getUniques(row) {
          const opportunityId = row.opportunity.id;
          return [
            {
              uniqueId: opportunityId || 'ZZZZZZ',
              groupData: opportunityId || 'ZZZZZZ',
            },
          ];
        },
        render(groupData: string, rows) {
          let totalAmount = 0;
          const addedAmountOpportunityIds: string[] = [];
          rows.forEach(row => {
            if (!addedAmountOpportunityIds.includes(row.opportunity.id)) {
              totalAmount += row.opportunity.amount;
              addedAmountOpportunityIds.push(row.opportunity.id);
            }
          });

          if (groupData === 'ZZZZZZ') {
            return (
              <div className="flex gap-2 items-center">
                <div className="font-semibold">No Opportunity</div>
                <div className="text-xs bg-gray-100 text-gray-500 rounded-full px-2 no-underline">
                  Count: {rows.length}
                </div>
                <div className="text-xs bg-gray-100 text-gray-500 rounded-full px-2 no-underline">
                  Total Amount: {currencyFormatter.format(totalAmount)}
                </div>
              </div>
            );
          }

          const { opportunity } = rows[0];
          return (
            <div className="flex gap-2 items-center">
              <div className="font-semibold">{opportunity.name}</div>
              <div className="text-xs bg-gray-100 text-gray-500 rounded-full px-2 no-underline">
                Count: {rows.length}
              </div>
              <div className="text-xs bg-gray-100 text-gray-500 rounded-full px-2 no-underline">
                Total Amount: {currencyFormatter.format(totalAmount)}
              </div>
            </div>
          );
        },
      },
      None: null,
    }),
    [],
  );

  return (
    <div className="w-full h-full flex flex-col">
      <div className="w-full border-b">
        <div className="px-5 py-2">
          <div className="font-semibold text-lg py-3">Product Gaps</div>
          <div className="w-full flex justify-between items-center">
            <div className="flex items-center gap-2">
              <button
                ref={viewRef}
                type="button"
                className="text-sm text-gray-500 hover:bg-gray-100 rounded p-2 flex items-center gap-1"
                onClick={() => setState(prevState => ({ ...prevState, isViewOpen: true }))}
              >
                <CategoryIcon className="w-5 h-5" />
                Group By: {state.view}
              </button>
              <Popover
                anchorEl={viewRef.current}
                onClose={() => setState(prevState => ({ ...prevState, isViewOpen: false }))}
                isOpen={state.isViewOpen}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
              >
                <div className="w-40 flex flex-col">
                  <button
                    type="button"
                    className="px-4 py-2 text-left hover:bg-gray-100"
                    onClick={() => setState(prevState => ({ ...prevState, isViewOpen: false, view: 'Category' }))}
                  >
                    Category
                  </button>
                  <button
                    type="button"
                    className="px-4 py-2 text-left hover:bg-gray-100"
                    onClick={() => setState(prevState => ({ ...prevState, isViewOpen: false, view: 'Account' }))}
                  >
                    Account
                  </button>
                  <button
                    type="button"
                    className="px-4 py-2 text-left hover:bg-gray-100"
                    onClick={() => setState(prevState => ({ ...prevState, isViewOpen: false, view: 'Opportunity' }))}
                  >
                    Opportunity
                  </button>
                  <button
                    type="button"
                    className="px-4 py-2 text-left hover:bg-gray-100"
                    onClick={() => setState(prevState => ({ ...prevState, isViewOpen: false, view: 'None' }))}
                  >
                    None
                  </button>
                </div>
              </Popover>
            </div>
            <div className="flex items-center gap-2">
              <CustomizeButton tableConfig={tableStore.productGapTableConfigs} updateTableConfig={updateTableConfig} />
              <FilterButton tableConfig={tableStore.productGapTableConfigs} updateTableConfig={updateTableConfig} />
              <SortButton tableConfig={tableStore.productGapTableConfigs} updateTableConfig={updateTableConfig} />
              <button
                type="button"
                className="flex items-center gap-1 text-gray-400 hover:bg-gray-100 rounded p-1"
                onClick={() => {
                  tableRef.current?.exportData('Product Gap');
                }}
              >
                <DownloadIcon />
                Export
              </button>
              <BaseButton
                color="primary"
                variant="contained"
                className="!text-sm !h-auto min-h-[32px]"
                onClick={() => {
                  openNewGapModal();
                }}
              >
                Add New
              </BaseButton>
            </div>
          </div>
        </div>
      </div>
      <ContextPanelView viewKey="product-gap-context-panel" rightSectionDefaultWidth="600px">
        <ResponseView
          loading={productGapStore.isLoading}
          noData={!rowData.length}
          noDataIcon={hasFilters ? <EmptySearchIcon /> : <EmptyResponseIcon />}
          noDataMsg={
            hasFilters
              ? `We couldn't find any results for\n"current filter".`
              : 'You do not have any product gaps... yet'
          }
          noDataDesc={
            hasFilters ? 'You may want to try using different filters.' : 'Click on the button below to create one.'
          }
          noDataBtnLabel={hasFilters ? '' : 'Create Product Gap'}
          onButtonClick={() => openNewGapModal()}
        >
          <div className="w-full h-full px-2">
            <div className="pt-4 h-full">
              <Table
                ref={tableRef}
                columnConfigs={tableStore.productGapTableConfigs}
                setColumnConfigs={columnConfigs =>
                  tableStore.setState({ productGapTableConfigs: addGroupSequnceNumToColumnDefs(columnConfigs) })
                }
                rows={rowData}
                category={CATEGORIZED_VIEWS[state.view] || null}
                notFilterData
              />
            </div>
          </div>
        </ResponseView>
      </ContextPanelView>
    </div>
  );
}

export default ProductGapPage;
