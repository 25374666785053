import React, { useMemo } from 'react';
import { ArrowTopRightOnSquareIcon, ClockIcon } from '@heroicons/react/24/outline';

import { Recording } from 'api/recordings/get-recordings';
import ResponseView from 'components/ResponseView';
import EmptySearchIcon from 'components/icons/EmptySearchIcon';
import EmptyResponseIcon from 'components/icons/EmptyResponseIcon';
import { useNavigate } from 'react-router-dom';

import { Document } from 'types/document';
import datetimeObjToString from 'utils/datetime-obj-to-string';
import useDocumentStore from 'stores/document';
import { EntityDocument } from 'types/entity';
import PopupButton from './PopupButton';
import { concat } from 'utils/styling';

interface Props {
  recordings: Recording[];
  // eslint-disable-next-line react/require-default-props
  subView: 'allRecordings' | 'unlinkedRecordings';
  documents: Document[];
  onChange: () => void;
  loading: boolean;
  searchStr: string;
  contentClassName: string;
}

function sortingByAccessedAt(a: EntityDocument, b: EntityDocument): number {
  if (a.accessedAt === null) {
    return 1;
  }
  if (b.accessedAt === null) {
    return -1;
  }
  return Date.parse(b.accessedAt) - Date.parse(a.accessedAt);
}

function RecordingPageTable({ recordings, subView, documents, onChange, loading, searchStr, contentClassName }: Props) {
  const documentStore = useDocumentStore();
  const navigate = useNavigate();

  let recordingsPerSubView = [];

  if (subView === 'allRecordings') {
    recordingsPerSubView = recordings;
  } else {
    recordingsPerSubView = recordings.filter(r => r.entityOrDocumentId === null);
  }

  // eslint-disable-next-line func-names
  recordingsPerSubView.sort(function (a, b) {
    if (a.callData.scheduled && b.callData.scheduled) {
      if (a.callData.scheduled < b.callData.scheduled) {
        return 1;
      }
      if (a.callData.scheduled > b.callData.scheduled) {
        return -1;
      }
    } else if (a.callData.started && b.callData.started) {
      if (a.callData.started < b.callData.started) {
        return 1;
      }
      if (a.callData.started > b.callData.started) {
        return -1;
      }
    }
    return 0;
  });

  const sortedOpportunities = useMemo(() => {
    const opportunities = documentStore.entityDocuments.filter(doc => doc.entity.name === 'Opportunity');
    if (opportunities) {
      const sorted = [...opportunities];
      sorted.sort(sortingByAccessedAt);
      return sorted;
    }
    return [];
  }, [documentStore.entityDocuments]);

  const sortedAccounts = useMemo(() => {
    const accounts = documentStore.entityDocuments.filter(doc => doc.entity.name === 'Account');
    if (accounts) {
      const sorted = [...accounts];
      sorted.sort(sortingByAccessedAt);
      return sorted;
    }
    return [];
  }, [documentStore.entityDocuments]);

  const recordingRows = recordingsPerSubView.map(recording => {
    const { scheduledDate, scheduledTime } = datetimeObjToString(
      recording.callData.scheduled ? recording.callData.scheduled : recording.callData.started,
    );

    let duration = '';
    if (recording.callData.duration) {
      duration = Math.round(recording.callData.duration / 60)
        .toString()
        .concat(' ', 'minutes');
    }
    const internalParties: JSX.Element[] = [];
    const externalParties: JSX.Element[] = [];
    recording.callData.parties.forEach(p => {
      let id = '';
      if (p.name) {
        id = p.name;
      } else if (p.emailAddress) {
        id = p.emailAddress;
      }
      if (p.affiliation !== 'Internal') {
        externalParties.push(
          <div key={p.id ? p.id : p.speakerId} className="flex ml-20 text-gray-500">
            {id}
          </div>,
        );
      } else {
        internalParties.push(
          <div key={p.id ? p.id : p.speakerId} className="flex ml-20 text-gray-400">
            {id}
          </div>,
        );
      }
    });
    const parties = externalParties.concat(internalParties);
    return (
      <tr key={recording.callData.id} className="">
        <td className="py-3 px-2 text-gray-500 font-medium">
          <div className="flex flex-col gap-1">
            <a
              className="px-2 py-1 flex gap-2 items-center hover:rounded hover:bg-gray-200 hover:cursor-pointer"
              href={recording.callData.url}
              target="_blank"
              rel="noreferrer"
            >
              <span>{recording.callData.title}</span>
              <ArrowTopRightOnSquareIcon className="w-4 h-4 flex-none" />
            </a>
            <div className="flex pl-3 gap-1 items-center">
              <ClockIcon className="w-4 h-4" />
              <div className="text-sm text-gray-400 font-medium">
                {scheduledDate} {scheduledTime} ({duration})
              </div>
            </div>
          </div>
        </td>
        <td className="py-3 px-2 text-black font-medium">
          <PopupButton
            recording={recording}
            documents={documents}
            sortedOpportunities={sortedOpportunities}
            sortedAccounts={sortedAccounts}
            onChange={onChange}
          />
        </td>
        <td className="py-3 px-1 text-black font-medium">
          <div className="flex flex-col">{parties}</div>
        </td>
      </tr>
    );
  });

  const recordingTable: JSX.Element = (
    <table className="w-full" cellSpacing={0}>
      <thead>
        <tr className="text-gray-600 font-semibold bg-gray-100">
          <td className="w-[35%] py-3 px-4"> Recording</td>
          <td className="w-[35%] py-3 px-4 text-gray-600">Deal/Event</td>
          <td className="w-[30%] py-3 pl-24">Attendees</td>
        </tr>
      </thead>
      <tbody className="">{recordingRows}</tbody>
    </table>
  );

  return (
    <ResponseView
      loading={loading}
      noData={!recordingRows.length}
      noDataIcon={searchStr ? <EmptySearchIcon /> : <EmptyResponseIcon />}
      noDataMsg={
        searchStr ? `We couldn't find any results for\n"${searchStr}".` : 'You do not have any recordings... yet'
      }
      noDataDesc={
        searchStr
          ? 'You may want to try using different keywords or\nchecking for typos.'
          : 'Link your call recording software to start\nimporting vour recordings.'
      }
      noDataBtnLabel={searchStr ? '' : 'Import Recordings'}
      onButtonClick={() => navigate('/settings')}
    >
      <div className={concat('text-sm', contentClassName)}>{recordingTable}</div>
    </ResponseView>
  );
}

export default RecordingPageTable;
