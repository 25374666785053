import { SalesforcePicklistValue } from '../../types/field-value';

import httpClient from '../http-client';

interface Response {
  eventType: string | null;
  eventTypeList: SalesforcePicklistValue[];
  insufficientPermissions: boolean;
}

export default async (documentId: string): Promise<Response> => {
  const { data } = await httpClient.get<Response>(`/salesforce/event/${documentId}`);
  return data;
};
