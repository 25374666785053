import searchItems from 'api/search/search-items';
import { EntityDocument } from 'types/entity';

export interface SearchNormalized {
  sfId: string | null;
  name: string;
  stageName?: string;
  lastViewedDate: string;
  id: null | string;
  sfEntityType: 'Opportunity' | 'Account' | null;
}

export default async function searchObjects(
  isIntegratedWithCrm: boolean,
  searchValue: string,
  entityDocuments: EntityDocument[],
) {
  let searchOpps: SearchNormalized[] = [];
  let searchAccts: SearchNormalized[] = [];
  if (isIntegratedWithCrm) {
    const searchRes = await searchItems(searchValue, false, true, true, false);
    searchRes.forEach(s => {
      if (s.type === 'opportunity') {
        searchOpps.push({
          sfId: s.crmId,
          name: s.title,
          stageName: s.oppStageName || '',
          lastViewedDate: s.datetime,
          id: null,
          sfEntityType: 'Opportunity',
        });
      }
    });
    searchRes.forEach(s => {
      if (s.type === 'account') {
        searchAccts.push({
          sfId: s.crmId,
          name: s.title,
          lastViewedDate: s.datetime,
          id: null,
          sfEntityType: 'Account',
        });
      }
    });
  } else {
    const searchOppsFiltered = entityDocuments.filter(
      ed => ed.entity.name === 'Opportunity' && ed.name.toLowerCase().includes(searchValue.toLowerCase()),
    );
    const searchAcctsFiltered = entityDocuments.filter(
      ed => ed.entity.name === 'Account' && ed.name.toLowerCase().includes(searchValue.toLowerCase()),
    );
    searchOpps = searchOppsFiltered.map(s => ({
      sfId: null,
      name: s.name,
      lastViewedDate: s.accessedAt,
      id: s.id,
      sfEntityType: null,
    }));
    searchAccts = searchAcctsFiltered.map(s => ({
      sfId: null,
      name: s.name,
      lastViewedDate: s.accessedAt,
      id: s.id,
      sfEntityType: null,
    }));
  }
  return { searchOpps, searchAccts };
}
