/* eslint-disable react/jsx-props-no-spreading, consistent-return */
import React, { CSSProperties, useLayoutEffect, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { TrashIcon } from '@heroicons/react/24/outline';
import DragHandlerIcon from 'components/icons/DragHandlerIcon';
import ThreeVerticalDotsIcon from 'components/icons/ThreeVerticalDotsIcon';
import { openDeleteConfirmModal, openInformationalModal } from 'components/BaseModal';
import { concat } from 'utils/styling';
import FieldView from './SortableField/FieldView';
import { Field } from './types';
import openFieldEditModal from './OpenFieldEditModal';
import ButtonPopover from 'components/ButtonPopover';
import EditIcon from 'components/icons/EditIcon';
import { noop } from 'lodash';
import TagBlock from 'components/TagBlock';
import deleteField from 'api/field/delete-field';

interface Props {
  field: Field;
  existingCRMFields: Field[];
  onFieldChange: (field: Field) => void;
  onFieldDelete: () => void;
  isAdmin: boolean;
}

function SortableField({ field, existingCRMFields, onFieldChange, onFieldDelete, isAdmin }: Props) {
  const sortable = useSortable({ id: field.id });
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [hightLightNew, setHightLightNew] = useState(false);

  useLayoutEffect(() => {
    if (field.isNew) {
      containerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      setHightLightNew(true);
      const timer = setTimeout(() => {
        setHightLightNew(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [field.isNew]);

  const sortableStyles = {
    transition: sortable.transition,
    transform: CSS.Translate.toString(sortable.transform),
  } as CSSProperties;

  return (
    <div
      className={concat(
        'relative w-full flex gap-2 group',
        hightLightNew && 'rounded outline-2 outline-blue-200 outline',
      )}
      ref={sortable.setNodeRef}
      style={sortableStyles}
    >
      <button
        type="button"
        {...sortable.listeners}
        {...sortable.attributes}
        className="absolute top-2.5 -left-1 w-5 h-5"
      >
        <DragHandlerIcon className="w-5 h-5 p-1 text-gray-400" />
      </button>
      <div className="w-full flex justify-between ml-5 shadow rounded pl-4 pr-1 py-1" ref={containerRef}>
        <div className="w-full text-sm flex-grow">
          <FieldView
            field={field}
            postfixElement={
              <div className="flex-1 flex justify-end">
                <ButtonPopover
                  btnColor="secondary"
                  btnVariant="text"
                  tooltip="Edit or Delete Field Automation"
                  btnClassName="!w-8"
                  closeWhenPopoverClick
                  menus={[
                    { label: 'Edit', value: 'edit', icon: <EditIcon className="w-5 h-5" /> },
                    { label: 'Delete', value: 'delete', icon: <TrashIcon className="w-5 h-5" /> },
                  ]}
                  handleMenuClick={async menu => {
                    if (menu.value === 'edit') {
                      openFieldEditModal(field, onFieldChange, false, isAdmin, existingCRMFields);
                    }
                    if (menu.value === 'delete') {
                      if (field.crmField?.isDefault) {
                        openInformationalModal('Can not delete this CRM default field!');
                      } else {
                        openDeleteConfirmModal(
                          `Are you sure you want to delete this field?`,
                          'This action cannot be undone.',
                          async () => {
                            try {
                              await deleteField(field.id);
                              onFieldDelete();
                            } catch {
                              noop();
                            }
                          },
                        );
                      }
                    }
                  }}
                  horizontalOrigin="right"
                  popoverClassName="w-[12rem]"
                >
                  <ThreeVerticalDotsIcon className="shrink-0 w-5 h-5 text-gray-400" />
                </ButtonPopover>
              </div>
            }
          />
          {!!field.tags.length && (
            <div className="mt-2 mb-1 flex flex-wrap gap-2">
              {field.tags.map(tag => (
                <TagBlock key={tag.id} tag={tag} />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SortableField;
