import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  pointerWithin,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { withCursors, withYHistory, withYjs, YjsEditor } from '@slate-yjs/core';
import { createPortal } from 'react-dom';
import { createEditor, Editor, Range, Selection, Transforms } from 'slate';
import { Editable, ReactEditor, RenderElementProps, RenderLeafProps, Slate, useSlate, withReact } from 'slate-react';
import * as Y from 'yjs';
import { v4 as uuidv4 } from 'uuid';
import Loader from 'components/Loader';
import noteService, { NoteDocument, NoteUser } from 'note-service/note-service';
import useNoteEditorStore from 'stores/note-editor';
import useRecordingsStore from 'stores/recordings';
import useImageEntityStore from 'stores/image-entity';
import fileUploadStart from 'api/files/file-upload-start';
import fileUploadComplete from 'api/files/file-upload-complete';
import usePopupMessageStore from 'stores/popup-message';
import { SuperpanelAPIError } from 'utils/errors';
import sortObject from 'utils/order-object-keys';
import useUserStore from 'stores/user';
import { handleFileUpload } from 'utils/file';
import isHotkey from 'is-hotkey';
import { DocumentEventPayload, useDocumentEventHandler } from 'utils/event';
import NoteEditor from './NoteEditor';
import DraggingElementEditor from './Elements/DraggingElement';
import Leaf from './Leaf';
import Element from './Element';
import SlatePlugins, { SlatePluginsChildrenProps } from './Plugins/SlatePlugins';
import { Plugin } from './Plugins';
import {
  BlockType,
  BlockTypeInline,
  CustomEditor,
  CustomElement,
  ImageElement,
  MentionElement as MentionElementType,
  NoteTemplateType,
  TaskElement,
} from './types';
import withInlines from './EditorContext/withInlines';
import styles from './index.module.css';
import RemoteCursorOverlay from './Overlay';
import MarginElementBottom from './CustomElement/MarginElementBottom';
import { withNormalizeBlockNode, withNormalizeInlineNode } from './EditorContext/withNormalizeNode';
import withVoids from './EditorContext/withVoids';
import CommentPopover, { openCommentPopover } from './CustomElement/Comment/CommentPopover';
import MentionPopover from './CustomElement/Mention/MentionPopover';
import { useLocation } from 'react-router-dom';
import { isEqual, keys, noop } from 'lodash';
import handleMentionEvent from './handleMentionEvent';
import EditorToolbar from './CustomElement/Toolbar/EditorToolbar';
import useSideMenuStore from 'stores/side-menu';
import useResizeHandler from 'hooks/useResizeHandler';
import withBlockUUID from './EditorContext/withBlockUUID';
import { scrollSelectionIntoView } from './scrollSelectionIntoView';
import constants from 'utils/constants';
import { concat } from 'utils/styling';
import SlateEditorContentMonitor from './SlateEditorContentMonitor';

export type NoteEditorRef = {
  getEditor: () => CustomEditor | null;
};

interface State {
  isLoading: boolean;
  value: CustomElement[];
  componentId: string;
  noteDocument: NoteDocument | null;
}

interface Props {
  documentId: string | null;
  opportunityId: string | null;
  applyTemplate?: (type: NoteTemplateType, id: string) => Promise<void>;
  openApplyTemplateModal?: () => void;
  blockPlaceholder?: string;
  emptyParagraphPlaceholder?: string;
  serviceId: string;
  serviceToken: string;
  plugins: Plugin[];
  onNoteUserUpdate?: (users: NoteUser[]) => void;
  type: 'acc-editor' | 'opp-editor' | 'doc-editor' | 'meeting-editor';
  scrollContainer?: HTMLDivElement;
  prefixElement?: React.ReactNode;
  autoFocusWhenEmpty?: boolean;
  className?: string;
  bottomMarginSectionClassName?: string;
}

const Index = React.forwardRef<NoteEditorRef, Props>(
  (
    {
      documentId,
      opportunityId,
      applyTemplate,
      openApplyTemplateModal,
      blockPlaceholder,
      emptyParagraphPlaceholder,
      serviceId,
      serviceToken,
      plugins,
      onNoteUserUpdate,
      type,
      scrollContainer,
      prefixElement,
      autoFocusWhenEmpty,
      className,
      bottomMarginSectionClassName,
    }: Props,
    ref,
  ) => {
    const [state, setState] = useState<State>({
      isLoading: true,
      value: [],
      componentId: uuidv4(),
      noteDocument: null,
    });
    const useNoteEditor = useNoteEditorStore();
    const recordingsStore = useRecordingsStore();
    const imageEntityStore = useImageEntityStore();
    const popupMessageStore = usePopupMessageStore();
    const alreadyOpenedHashElement = useRef(false);
    const sideMenuStore = useSideMenuStore();
    const [asideEdgeWidth, setAsideEdgeWidth] = useState(0);
    const initFocused = useRef(false);

    const [dragElementId, setDragElementId] = useState<string | null>(null);

    const containerRef = useRef<HTMLDivElement>(null);

    const userStore = useUserStore();

    const { hash } = useLocation();

    const editor = useMemo(() => {
      if (state.noteDocument === null || !documentId) return null;
      const noteDocument = state.noteDocument as NoteDocument;
      const sharedType = noteDocument.document.get('content', Y.XmlText) as Y.XmlText;
      const user = {
        id: userStore.user?.id,
        firstName: userStore.user?.firstName,
        lastName: userStore.user?.lastName,
        colour: userStore.user?.colour,
        isSelect: false,
      } as Record<keyof NoteUser, string | boolean>;
      return withNormalizeBlockNode(
        withNormalizeInlineNode(
          withVoids(
            withInlines(
              withBlockUUID(
                withReact(
                  withYHistory(
                    withCursors(withYjs(createEditor(), sharedType), noteDocument.awareness, {
                      data: user,
                    }),
                  ),
                ),
              ),
              'main-doc',
              documentId,
              opportunityId || undefined,
            ),
          ),
        ),
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.noteDocument, documentId]);

    const editorRef = useRef(editor);
    useEffect(() => {
      editorRef.current = editor;
    }, [editor]);
    useImperativeHandle(ref, () => ({
      getEditor: () => editorRef.current,
    }));

    useEffect(() => {
      const connect = async () => {
        setState(prevState => ({ ...prevState, isLoading: true, noteDocument: null }));
        const noteDocument = await noteService.connectToNote(
          state.componentId,
          serviceId,
          serviceToken,
          onNoteUserUpdate,
        );
        setState(prevState => ({ ...prevState, isLoading: true, noteDocument }));
      };

      connect();
      return () => noteService.disconnectToNote(state.componentId, serviceId);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceId, serviceToken, state.componentId]);

    // Connect editor in useEffect to comply with concurrent mode requirements.
    useEffect(() => {
      editorRef.current = editor;
      if (editor !== null) {
        YjsEditor.connect(editor);
        return () => YjsEditor.disconnect(editor);
      }
      return () => null;
    }, [editor]);

    const sensors = useSensors(
      useSensor(MouseSensor, {
        activationConstraint: {
          distance: 10,
        },
      }),
    );

    const [isEditorEmpty, setIsEditorEmpty] = useState(false);

    const handleAddedDiscoveryFieldsChange = useCallback(
      (addedFields: string[]) => {
        if (documentId) {
          const currentFields = useNoteEditor.discoveryQuestionList[documentId];
          if (!isEqual(addedFields, currentFields)) {
            useNoteEditor.setState({
              discoveryQuestionList: { ...useNoteEditor.discoveryQuestionList, [documentId]: addedFields },
            });
          }
        }
      },
      [useNoteEditor, documentId],
    );

    useEffect(() => {
      // used for synced crm document comparison
      if (editor?.children && editor?.children.length !== 0) {
        useNoteEditor.setState({
          contentHash: JSON.stringify(editor.children.map(child => sortObject(child))),
        });
      }
      return () => {
        useNoteEditor.resetState();
      };
      // here, this editor children change emit by slate has debounce time, not updated immediatly
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editor?.children]);

    useEffect(() => {
      // one page may have two document opened, so we need to check the documentId
      if (
        useNoteEditor.callSummaryToAdd !== null &&
        useNoteEditor.callSummaryToAdd.documentId === documentId &&
        editor
      ) {
        // make sure the q id is in the state
        const recording = recordingsStore.recordings.find(d => d.id === useNoteEditor.callSummaryToAdd?.recordingId);
        let callSummary = null;
        if (recording?.summaryData) {
          callSummary = recordingsStore.stringfySummary(recording?.summaryData);
        }
        if (recording && callSummary) {
          Transforms.insertNodes(
            editor,
            // this is somewhat duplicated with the constructor from SlashCommandPlugin.SlashCommandMenus
            // TODO, for better organization, we can combine all the construction into one place
            {
              type: BlockType.Paragraph,
              children: [{ text: callSummary.toCopy }],
            },
            { at: [0] },
          );
        }
        useNoteEditor.setState({ callSummaryToAdd: null });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [useNoteEditor.callSummaryToAdd]);

    useEffect(() => {
      // there can be two documents opened at the same time, so we need to check the documentId
      if (
        useNoteEditor.callSuggestedFeedbackItem !== null &&
        useNoteEditor.callSuggestedFeedbackItem.documentId === documentId &&
        editor
      ) {
        Transforms.insertNodes(
          editor,
          // this is somewhat duplicated with the constructor from SlashCommandPlugin.SlashCommandMenus
          // TODO, for better organization, we can combine all the construction into one place
          {
            type: BlockType.ProductGap,
            children: [{ text: '' }],
            productGapId: useNoteEditor.callSuggestedFeedbackItem.productGapId,
            defaultFeedback: useNoteEditor.callSuggestedFeedbackItem.value,
            defaultTitle: useNoteEditor.callSuggestedFeedbackItem.label,
          },
          { at: [0] },
        );

        useNoteEditor.setState({ callSuggestedFeedbackItem: null });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [useNoteEditor.callSuggestedFeedbackItem]);

    useEffect(() => {
      if (
        useNoteEditor.filesToUpload !== null &&
        useNoteEditor.filesToUpload.length &&
        editor &&
        useNoteEditor.currentActionEditor === editor
      ) {
        useNoteEditor.filesToUpload.forEach(async file => {
          // get presigned post url & data to upload file to
          const presignedData = await fileUploadStart(file.name, file.type);
          const { id } = presignedData;

          // create loading image element (no uploaded url yet)
          imageEntityStore.upsertEntity(id, { url: null, fileName: file.name, fileType: file.type });
          const imageElement: ImageElement = {
            fileId: id,
            type: BlockType.Image,
            children: [{ text: '' }],
          };

          // if we can get target path for inserted image, use that
          if (useNoteEditor.uploadTargetPath) {
            Transforms.insertNodes(editor, imageElement, { at: useNoteEditor.uploadTargetPath });
            useNoteEditor.setState({ uploadTargetPath: null });
          } else {
            Transforms.insertNodes(editor, imageElement);
          }
          // do actual upload to s3 presigned post url
          const uploadResult = await handleFileUpload({ file, presignedData });

          // if upload is okay, update image element/store with uploaded url
          if (uploadResult.ok) {
            const completeResult = await fileUploadComplete(id);
            if (completeResult?.url) {
              imageEntityStore.upsertEntity(id, { url: completeResult.url, fileName: file.name, fileType: file.type });
            }
          } else {
            // if failed, remove image element/store to avoid rendering empty image element
            Transforms.delete(editor, { at: ReactEditor.findPath(editor, imageElement) });
            imageEntityStore.removeEntity(id);

            // display error message
            let displayErrorMessage = 'There was an issue uplaoding your file. Please try again.';
            const xml = new DOMParser().parseFromString(await uploadResult.text(), 'text/xml');
            const errorMessage = xml.getElementsByTagName('Message')[0].childNodes[0].nodeValue;

            if (errorMessage) {
              displayErrorMessage = errorMessage;
            }

            popupMessageStore.throwError({
              displayErrorMessage,
            } as SuperpanelAPIError);
          }
        });

        if (useNoteEditor.fileDialogId) {
          const fileDialogElement = editor.children.find(
            (x: ImageElement) => x && x.fileId === useNoteEditor.fileDialogId,
          );
          if (fileDialogElement) {
            Transforms.delete(editor, { at: ReactEditor.findPath(editor, fileDialogElement) });
          }
          useNoteEditor.setState({ fileDialogId: null });
        }
        useNoteEditor.setState({ filesToUpload: null, currentActionEditor: null });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [useNoteEditor.filesToUpload]);

    useEffect(() => {
      if (useNoteEditor.tableDialogId !== null && useNoteEditor.currentActionEditor === editor && editor) {
        NoteEditor.createNewTable(
          editor,
          useNoteEditor.tableDialogId,
          useNoteEditor.tableProperties?.rows ?? 0,
          useNoteEditor.tableProperties?.columns ?? 0,
        );
        useNoteEditor.setState({ tableDialogId: null, currentActionEditor: null, tableProperties: null });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [useNoteEditor.tableDialogId]);

    // apply template from frontend
    useEffect(() => {
      if (useNoteEditor.templateToApply !== null && useNoteEditor.templateToApplyDocumentId === documentId && editor) {
        // check store whether reset to avoid apply template twice when open same doc both in left and right
        if (!useNoteEditorStore.getState().templateToApplyDocumentId) {
          return;
        }
        Transforms.select(editor, []);
        if (editor.selection) {
          Transforms.delete(editor, { at: editor.selection });
          Transforms.insertNodes<CustomElement>(
            editor,
            // assign new uuid
            useNoteEditor.templateToApply.children.map((node: CustomElement) => ({ ...node, uuid: uuidv4() })),
          );
        }
        useNoteEditor.setState({ templateToApply: null, templateToApplyDocumentId: '' });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editor, useNoteEditor.templateToApply, useNoteEditor.templateToApplyDocumentId, documentId]);

    useEffect(() => {
      // close comment and mention when destroy
      return () => {
        useNoteEditorStore.setState({
          commentContext: null,
          mentionContext: null,
        });
      };
    }, []);

    useEffect(() => {
      // focus when doc content loaded
      if (editor && editor.children.length >= 1) {
        // focus when autoFocusWhenEmpty and doc is empty
        if (!initFocused.current && autoFocusWhenEmpty && !Editor.string(editor, []).trim()) {
          try {
            ReactEditor.focus(editor);
            Transforms.select(editor, NoteEditor.end(editor, []));
          } catch {
            noop();
            console.error('error 33764----');
          }
        }
        initFocused.current = true;
      }
      // here, this editor children change emit by slate has debounce time, not updated immediatly
    }, [editor?.children]);

    useEffect(() => {
      const selectAll = (currentEditor: CustomEditor, currentSelection: Selection | null) => {
        // select all doc
        if (!currentSelection || currentSelection.focus.path.length < 1 || currentSelection.anchor.path.length < 1) {
          Transforms.select(currentEditor, []);
        } else {
          // get parent path, if inside table cell (path length > 3) then get table cell node as parent, otherwise get block node
          const parentFocusPath = currentSelection.focus.path.slice(0, currentSelection.focus.path.length > 3 ? 3 : 1);
          const parentAnchorPath = currentSelection.anchor.path.slice(
            0,
            currentSelection.focus.path.length > 3 ? 3 : 1,
          );
          const isForward = Range.isForward(currentSelection);
          const e2eRange = {
            anchor: isForward
              ? NoteEditor.start(currentEditor, parentAnchorPath)
              : NoteEditor.end(currentEditor, parentAnchorPath),
            focus: isForward
              ? NoteEditor.end(currentEditor, parentFocusPath)
              : NoteEditor.start(currentEditor, parentFocusPath),
          };
          // if whole parent node range selected, then select all doc, otherwise, select whole parent node
          if (Range.equals(currentSelection, e2eRange)) {
            // select all doc
            Transforms.select(currentEditor, []);
          } else {
            // select whole blocks when part of blocks selected
            Transforms.select(currentEditor, e2eRange);
          }
        }
      };

      const handleDocumentKeydown = (e: KeyboardEvent) => {
        if (isHotkey('mod+a', e)) {
          if (editor && containerRef.current?.contains(e.target as Node)) {
            e.preventDefault();
            if (!ReactEditor.isFocused(editor)) {
              NoteEditor.sp_focusEditor(editor);
              // wait editor get focused then select all
              setTimeout(() => {
                selectAll(editor, null);
              }, 200);
            } else {
              selectAll(editor, editor.selection);
            }
          }
        }
        if (isHotkey('mod+s', e)) {
          e.preventDefault();
        }
      };
      document.addEventListener('keydown', handleDocumentKeydown);
      return () => {
        document.removeEventListener('keydown', handleDocumentKeydown);
      };
    }, [editor]);

    useEffect(() => {
      if (editor && type === 'doc-editor') {
        useNoteEditor.setState({ currentDocumentEditor: editor });
      }
    }, [editor, type]);

    useEffect(() => {
      if (!userStore.membersLoading && !userStore.membersLoadError && !userStore.members.length) {
        userStore.fetchMembers();
      }
    }, []);

    useEffect(() => {
      if (hash && hash.length > 1 && editor?.children.length) {
        if (!alreadyOpenedHashElement.current) {
          const isBlockUUIDMatched = (uuid?: string) => uuid && hash.endsWith(uuid);
          const [hashNodeInfo] = NoteEditor.nodes(editor, {
            at: [],
            match: (n, p) => {
              const node = n as CustomElement;
              if (isBlockUUIDMatched(node.uuid)) {
                return true;
              }
              return (
                (node.type === BlockTypeInline.Mention && hash.includes((node as MentionElementType).mentionId)) ||
                (p.length === 1 &&
                  keys(node.comments).some(commentId => {
                    const comment = node.comments?.[commentId] || [];
                    if (comment[0]?.resolved) {
                      return false;
                    }
                    return comment.some(item =>
                      item.content.find(
                        itemNode =>
                          (itemNode as MentionElementType).type === BlockTypeInline.Mention &&
                          hash.includes((itemNode as MentionElementType).mentionId),
                      ),
                    );
                  })) ||
                (node.type === BlockType.Task && hash.includes((node as TaskElement).taskId))
              );
            },
          });
          if (hashNodeInfo) {
            const scrollToView = (ele: HTMLElement | undefined | null | Element, highLightClassName = 'high-light') => {
              if (ele) {
                ele.scrollIntoView();
                ele.classList.add(highLightClassName);
                setTimeout(() => {
                  ele.classList.remove(highLightClassName);
                }, 3000);
              }
            };

            // scroll by uuid
            if (isBlockUUIDMatched((hashNodeInfo[0] as CustomElement).uuid)) {
              try {
                const ele = ReactEditor.toDOMNode(editor, hashNodeInfo[0]);
                scrollToView(ele, 'block-uuid-high-light');
                return;
              } catch (e) {
                return;
              }
            }

            // when open task
            if (
              (hashNodeInfo[0] as TaskElement).type === BlockType.Task &&
              hash.includes((hashNodeInfo[0] as TaskElement).taskId)
            ) {
              // when mention in main doc
              setTimeout(() => {
                const taskEl = containerRef.current?.querySelector(
                  `.task.task-${(hashNodeInfo[0] as TaskElement).taskId}`,
                );
                scrollToView(taskEl);
              });
              alreadyOpenedHashElement.current = true;
              return;
            }
            // when mention inside comment
            if (hashNodeInfo[1].length === 1) {
              const rootNode = hashNodeInfo[0] as CustomElement;
              const comments = rootNode.comments || {};
              const commentId = keys(comments).find(key => {
                const comment = comments[key] || [];
                return comment.some(item =>
                  item.content.find(
                    itemNode =>
                      (itemNode as MentionElementType).type === BlockTypeInline.Mention &&
                      hash.includes((itemNode as MentionElementType).mentionId),
                  ),
                );
              });
              if (commentId) {
                setTimeout(() => {
                  containerRef.current?.querySelector(`.comment.comment-${commentId}`)?.scrollIntoView();
                  openCommentPopover(editor, { [commentId]: comments[commentId] }, false);
                });
              }
            } else {
              // when mention in main doc
              setTimeout(() => {
                const mentionEl = containerRef.current?.querySelector(
                  `.mention.mention-${(hashNodeInfo[0] as MentionElementType).mentionId}`,
                );
                scrollToView(mentionEl);
              });
            }
          }
        }
        alreadyOpenedHashElement.current = true;
      }
    }, [hash, editor?.children]);

    useDocumentEventHandler(
      useCallback(
        (event: DocumentEventPayload) => {
          if (event.type.startsWith('MENTION_') && editor) {
            handleMentionEvent(event, editor);
          }
          if (event.docSource === 'main-doc') {
            if (event.type === 'TASK_DELETE' && (event.sourceElement as CustomElement)?.type === BlockType.Task) {
              const node = event.sourceElement as TaskElement;
              if (node.taskId) {
                // NOTE: for now, if task deleted by doc edit, not call api to delete it.
                // when user click the delete icon button in task, we will call api to delete it.
                // deleteTask(node.taskId);
              }
            }
          }
        },
        [editor],
      ),
    );

    const draggingElement = useMemo(
      () => (dragElementId ? editor?.children.find((x: CustomElement) => x.uuid === dragElementId) : null),
      [dragElementId, editor?.children],
    );

    const docParentContainerElRef = useRef<HTMLDivElement>();

    useEffect(() => {
      docParentContainerElRef.current =
        (document.getElementById('note-editor-parent-container') as HTMLDivElement) || undefined;
    }, []);

    useResizeHandler(
      100,
      useCallback(() => {
        const docWidth = 900;
        setAsideEdgeWidth(Math.max(0, ((docParentContainerElRef.current?.clientWidth || 0) - docWidth) / 2));
      }, []),
      [sideMenuStore.calendarMenu, sideMenuStore.noteMenu],
    );

    /* ---- Drag and Drop functions ---- */
    const clearSelection = useCallback(() => {
      if (!editorRef.current) {
        return;
      }

      ReactEditor.blur(editorRef.current);
      Transforms.deselect(editorRef.current);
      window.getSelection()?.empty();
    }, []);

    const handleDragStart = useCallback((event: DragStartEvent) => {
      if (!editorRef.current) {
        return;
      }
      if (event.active) {
        clearSelection();
        setDragElementId(event.active.id as string);
      }
    }, []);

    const handleDragEnd = useCallback((event: DragEndEvent) => {
      if (!editorRef.current) {
        return;
      }
      const fromId = event.active?.id as string;
      const toId = event.over?.id as string;
      if (fromId && toId && fromId !== toId) {
        const fromIndex = editorRef.current.children.findIndex((x: CustomElement) => x.uuid === fromId);
        const toIndex =
          toId === constants.EDITOR_BOTTOM_SECTION_ID
            ? editorRef.current.children.length
            : editorRef.current.children.findIndex((x: CustomElement) => x.uuid === toId);
        if (fromIndex >= 0 && toIndex >= 0) {
          Transforms.moveNodes(editorRef.current, {
            at: [fromIndex],
            to: [fromIndex < toIndex ? toIndex - 1 : toIndex],
          });
        }
      }
      setDragElementId(null);
    }, []);

    const handleDragCancel = useCallback(() => {
      setDragElementId(null);
    }, []);

    const disableSortingStrategy = useCallback(() => {
      return null;
    }, []);

    const renderEditorContent = useCallback(
      (funProps: SlatePluginsChildrenProps) => {
        if (!editor) {
          return null;
        }

        return (
          <>
            {funProps.pluginElements}
            <Editable
              renderElement={(props: RenderElementProps) => (
                <Element
                  editor={editor}
                  applyTemplate={applyTemplate}
                  openApplyTemplateModal={openApplyTemplateModal}
                  attributes={props.attributes}
                  element={props.element}
                  blockPlaceholder={blockPlaceholder}
                  emptyParagraphPlaceholder={emptyParagraphPlaceholder}
                  documentId={documentId}
                  opportunityId={opportunityId}
                  readOnly={false}
                  enableComment
                  onAddNewBlock={funProps.onAction}
                  docSource="main-doc"
                  asideEdgeWidth={asideEdgeWidth}
                  scrollContainer={scrollContainer}
                  isEditorEmpty={isEditorEmpty}
                >
                  {props.children}
                </Element>
              )}
              renderLeaf={(props: RenderLeafProps) => (
                <Leaf attributes={props.attributes} leaf={props.leaf} text={props.text}>
                  {props.children}
                </Leaf>
              )}
              onKeyDown={funProps.onKeyDown}
              onDrop={funProps.onDrop}
              onCopy={funProps.onCopy}
              onCut={funProps.onCut}
              onPaste={funProps.onPaste}
              scrollSelectionIntoView={(e, domRange) => {
                scrollSelectionIntoView(e, domRange);
              }}
            />
          </>
        );
      },
      [editor, scrollContainer, asideEdgeWidth, applyTemplate, openApplyTemplateModal, isEditorEmpty],
    );

    if (!editor) {
      return (
        <div className={styles.loader}>
          <Loader />
        </div>
      );
    }

    return (
      <div id="note-editor-container" ref={containerRef} className={concat(styles.container, className)}>
        <DndContext
          sensors={sensors}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragCancel={handleDragCancel}
          collisionDetection={pointerWithin}
        >
          <SortableContext
            id="uuid"
            items={editor.children as unknown[] as { id: string }[]}
            strategy={disableSortingStrategy}
          >
            <Slate editor={editor} initialValue={state.value}>
              <SlateEditorContentMonitor
                onEditorEmptyToggle={setIsEditorEmpty}
                onDiscoveryQuestionListChange={handleAddedDiscoveryFieldsChange}
              />
              <div className={styles['sub-container']}>
                {prefixElement}
                <RemoteCursorOverlay>
                  <SlatePlugins editor={editor} parentRef={docParentContainerElRef.current || null} plugins={plugins}>
                    {renderEditorContent}
                  </SlatePlugins>
                </RemoteCursorOverlay>
                <EditorToolbar
                  hide={!!useNoteEditor.commentContext}
                  editor={editor}
                  enableTypeSelect
                  enableComment
                  scrollContainer={scrollContainer}
                />
                <CommentPopover documentId={documentId || ''} opportunityId={opportunityId || ''} />
                <MentionPopover />
                <MarginElementBottom editor={editor} className={bottomMarginSectionClassName} />
              </div>
            </Slate>
          </SortableContext>
          {draggingElement &&
            createPortal(
              <DragOverlay adjustScale={false}>
                <DraggingElementEditor
                  element={draggingElement}
                  documentId={documentId}
                  opportunityId={opportunityId}
                  enableComment
                />
              </DragOverlay>,
              document.body,
            )}
        </DndContext>
      </div>
    );
  },
);

Index.defaultProps = {
  applyTemplate: undefined,
  openApplyTemplateModal: undefined,
  blockPlaceholder: "Type '/' to add discovery fields or more",
  emptyParagraphPlaceholder: 'Start typing or select from the list of templates below',
  onNoteUserUpdate: undefined,
  scrollContainer: undefined,
  prefixElement: undefined,
  autoFocusWhenEmpty: undefined,
  className: '',
  bottomMarginSectionClassName: '',
};
Index.displayName = 'NoteEditor';
export default Index;
