import React from 'react';

interface Props {
  className?: string;
}
function DownloadIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.13281 16.8213L12.2508 20.0013L15.3683 16.8213"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.251 12.8467V20.0012"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.1721 17.6876C19.8525 17.1934 20.3608 16.4981 20.6251 15.6998C20.8895 14.9014 20.8966 14.0402 20.6456 13.2376C20.4037 12.4384 19.9121 11.7378 19.2429 11.2384C18.5737 10.7391 17.762 10.4674 16.9271 10.4631H15.9451C15.6428 9.22623 14.9716 8.11055 14.0204 7.26414C13.0692 6.41772 11.8831 5.8806 10.6196 5.72408C9.35826 5.5811 8.08344 5.82827 6.96696 6.43229C5.85049 7.0363 4.94605 7.9681 4.37557 9.10208C3.79187 10.2451 3.56194 11.5361 3.71511 12.8104C3.86829 14.0847 4.39762 15.2844 5.23557 16.2566"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

DownloadIcon.defaultProps = {
  className: undefined,
};

export default DownloadIcon;
