import { Tooltip } from '@mui/material';
import React, { useMemo } from 'react';
import { Member } from 'types/member';
import { getMemberName, getUserShortName } from 'utils/string';
import styles from './CommentUserAvatar.module.css';
import { concat } from 'utils/styling';

interface Props {
  member: Member | undefined;
  hideTooltip?: boolean;
  hideName?: boolean;
  nameClassName?: string;
}

function CommentUserAvatar({ member, hideTooltip, hideName, nameClassName }: Props) {
  const name = useMemo(() => getMemberName(member), [member]);
  const shortName = useMemo(() => getUserShortName(name), [name]);
  const tooltip = useMemo(() => {
    return hideTooltip ? '' : `${name}\n${member?.email}`;
  }, [member, hideTooltip, name]);

  return (
    <div className={styles.nameWrap}>
      <Tooltip title={hideTooltip ? undefined : <div className="whitespace-pre-line">{tooltip}</div>} placement="top">
        <div className={concat(styles.shortName, nameClassName)}>
          <span>{shortName}</span>
        </div>
      </Tooltip>
      {!hideName && <span className={styles.fullName}>{name}</span>}
    </div>
  );
}

CommentUserAvatar.defaultProps = {
  hideTooltip: false,
  hideName: false,
  nameClassName: '',
};

export default CommentUserAvatar;
