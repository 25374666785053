/* eslint-disable import/no-cycle, react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import { BlockElement, CommentRecords, CustomText } from 'components/NoteEditor/types';
import { keys } from 'lodash';
import React, { useCallback, useMemo, useRef } from 'react';
import { Range } from 'slate';
import useNoteEditorStore from 'stores/note-editor';
import { concat } from 'utils/styling';
import { ReactEditor, useSlate } from 'slate-react';
import styles from './CommentLeafElement.module.css';
import { openCommentPopover } from './CommentPopover';

interface Props {
  commentId: string | undefined;
  commentInit: boolean;
  children: React.ReactNode;
  text?: CustomText;
}

function CommentLeafElement({ commentId, commentInit, children, text }: Props) {
  const editor = useSlate();
  const { commentContext } = useNoteEditorStore();
  const ref = useRef<HTMLSpanElement>(null);

  const rootPath = (text ? ReactEditor.findPath(editor, text) : [])[0];

  const commentIds = useMemo(() => {
    const comments = editor.children?.[rootPath ?? 0]?.comments ?? {};
    const rootCommentIds = keys(comments).filter(id => comments[id].length && !comments[id][0].resolved);
    const ids = (commentId ?? '')
      .split(' ')
      .map(item => item.trim())
      .filter(item => item && rootCommentIds.includes(item));
    return ids;
  }, [commentId, rootPath, editor.children?.[rootPath ?? 0]?.comments]);

  const commentIdClasses = useMemo(() => commentIds.map(item => `comment-${item}`), [commentIds]);
  const isActive = useMemo(() => {
    return commentIds.some(id => !!commentContext?.comments[id]);
  }, [commentIds, commentContext?.comments]);

  const handleClick = useCallback(
    async (e: React.MouseEvent) => {
      if (!commentIds.length) {
        return;
      }
      if (editor.selection && Range.isExpanded(editor.selection)) {
        return;
      }
      e.stopPropagation();
      const comments: CommentRecords = {};
      commentIds.forEach(id => {
        const rootNode = editor.children.find(child => (child as BlockElement).comments?.[id]);
        if (rootNode) {
          comments[id] = (rootNode as BlockElement).comments?.[id] ?? [];
        }
      });
      openCommentPopover(editor, comments, false, ref.current || undefined);
    },
    [commentIds],
  );

  return (
    <span
      ref={ref}
      className={concat(
        styles.comment,
        'comment',
        commentInit ? 'comment-init' : '',
        isActive && styles.commentActive,
        styles[`comment-${commentIdClasses.length + (commentInit ? 1 : 0)}`],
        ...commentIdClasses,
      )}
      onClick={handleClick}
    >
      {children}
    </span>
  );
}

CommentLeafElement.defaultProps = {
  text: undefined,
};

export default CommentLeafElement;
