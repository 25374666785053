import React, { useCallback, useMemo } from 'react';

import RecordingIcon from 'components/icons/RecordingIcon';
import { PanelContext } from 'stores/context-panel';
import constants from 'utils/constants';
import ChatIcon from './icons/ChatIcon';
import useQueryParamState from './Hooks/useQueryParamState';
import MeetingIcon from './icons/MeetingIcon';
import { useSearchParams } from 'react-router-dom';

function ContextIcon({ context }: { context: PanelContext }) {
  switch (context) {
    case 'recordings':
      return <RecordingIcon className="w-5 h-5 text-gray-500" />;
    case 'chat':
      return <ChatIcon className="w-5 h-5 text-gray-500" />;
    case 'meetings':
      return <MeetingIcon className="w-5 h-5 text-gray-500" />;
    default:
      return null;
  }
}

function ContextText({ context }: { context: PanelContext }) {
  switch (context) {
    case 'recordings':
      return <>Recordings</>;
    case 'chat':
      return <>AI Chat</>;
    case 'meetings':
      return <>Meetings</>;
    default:
      return null;
  }
}

interface Props {
  contexts: PanelContext[];
}

function ContextPanelNavbar({ contexts }: Props) {
  const [drawer, setDrawer] = useQueryParamState(constants.RIGHT_DRAWER_QUERY_PARAM_KEY);
  const [searchParams, setSearchParams] = useSearchParams();
  const showingMeetings = useMemo(() => !!searchParams.get(constants.CONTEXT_PANEL_MEETINGS), [searchParams]);

  const toggleMeetings = useCallback(() => {
    if (searchParams.get(constants.CONTEXT_PANEL_MEETINGS)) {
      searchParams.delete(constants.CONTEXT_PANEL_MEETINGS);
    } else {
      searchParams.set(constants.CONTEXT_PANEL_MEETINGS, 'true');
    }
    setSearchParams(searchParams);
  }, [searchParams]);

  return (
    <div className="flex gap-7">
      {contexts.map(context => (
        <button
          id={context === null ? undefined : `${context}-context-panel-button`}
          key={context}
          className="flex flex-col justify-center group mt-2"
          onClick={() => {
            // show meetings in right panel, and show others in right drawer
            if (context === 'meetings') {
              toggleMeetings();
            } else {
              setDrawer(drawer === context ? '' : context || '');
            }
          }}
          type="button"
        >
          <div className="flex gap-1 mb-1 items-center">
            <ContextIcon context={context} />
            <span className="text-xs text-gray-500">
              <ContextText context={context} />
            </span>
          </div>
          <div
            className={`h-1 w-6 transform transition-all rounded-sm self-center scale-x-150 ${
              (context === 'meetings' ? showingMeetings : context === drawer) ? 'bg-blue-300' : 'bg-transparent'
            }`}
          />
        </button>
      ))}
    </div>
  );
}

export default ContextPanelNavbar;
