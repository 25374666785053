import { debounce } from 'lodash';
import { DependencyList, useCallback, useEffect } from 'react';

function useResizeHandler(debounceTime: number, resizeHandler: () => void, deps: DependencyList) {
  const debounceHandler = useCallback(
    debounce(() => {
      resizeHandler();
    }, debounceTime),
    [],
  );

  useEffect(() => {
    window.addEventListener('resize', debounceHandler);
    return () => {
      window.removeEventListener('resize', debounceHandler);
    };
  }, []);

  useEffect(() => {
    resizeHandler();
  }, deps);
}

export default useResizeHandler;
