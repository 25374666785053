import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';

import Loader from 'components/Loader';

import useUserStore from 'stores/user';
import { storeRedirectUrl } from 'utils/redirect-after-login';
import { logUserOut } from 'components/Page';
import moment from 'moment';
import useUser from 'hooks/userUser';

interface Props {
  children: JSX.Element;
}

function IdelLogUserOut(): null | JSX.Element {
  const userStore = useUserStore();
  const navigate = useNavigate();
  const TIME_OUT = 72 * 60 * 60 * 1000;
  const [timeout, setTimeOut] = useState(TIME_OUT);
  const [count, setCount] = useState(1);

  const onIdle = () => {
    logUserOut(userStore, navigate)();
  };

  const onAction = () => {
    setCount(prevState => prevState + 1);
  };

  const { message } = useIdleTimer({
    onIdle,
    onAction,
    onMessage: () => {
      setTimeOut(TIME_OUT);
    },
    timeout,
    crossTab: true,
    leaderElection: true,
    syncTimers: 200,
  });

  useEffect(() => {
    message({ lastActiveAt: moment().format('DD hh:mm') });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  return <div />;
}

function MustBeLoggedIn({ children }: Props): null | JSX.Element {
  const location = useLocation();
  const userStore = useUserStore();
  const state = useUser(false);

  if (userStore.user !== null) {
    return (
      <>
        <IdelLogUserOut />
        {children}
      </>
    );
  }
  if (state.hasCalledBackEnd && userStore.user === null) {
    storeRedirectUrl(location.pathname + location.search);
    return <Navigate to="/login" />;
  }
  return (
    <div className="w-[100vw] h-[100vh] flex items-center justify-center">
      <Loader />
    </div>
  );
}

export default MustBeLoggedIn;
