import { CustomElement } from 'components/NoteEditor/types';
import httpClient from '../http-client';

export interface templateData {
  children: CustomElement[];
}

export default async (
  templateName: string,
  templateData: templateData,
  templateIcon: string | null,
  createVia: 'document' | 'template',
  isMeta?: boolean,
): Promise<void> => {
  await httpClient.post<void>(`/note-templates/`, {
    templateName,
    templateIcon,
    createVia,
    templateData,
    isMeta: !!isMeta,
  });
};
