import React from 'react';
import TagBlock from 'components/TagBlock';

import { Tag } from 'types/field-value';

interface Props {
  tag: Tag;
  onClickTag: () => void;
  numOfFieldNotInDoc: number;
  isSelected: boolean;
}

function FieldTag({ tag, onClickTag, numOfFieldNotInDoc, isSelected }: Props) {
  const allAdded = numOfFieldNotInDoc === 0;
  return (
    <button
      type="button"
      className={`flex gap-1 items-center rounded ${allAdded ? 'cursor-default' : 'hover:opacity-80'} ${
        isSelected ? 'ring-offset-1 ring-2' : ''
      } `}
      onMouseDown={e => {
        e.preventDefault();
        e.stopPropagation();
        if (numOfFieldNotInDoc > 0) {
          onClickTag();
        }
      }}
    >
      <TagBlock tag={tag} postfixElement={<span className="text-xs">({numOfFieldNotInDoc})</span>} />
    </button>
  );
}

export default FieldTag;
