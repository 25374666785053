import MultiDocsView from 'components/MultiDocsView/MultiDocsView';
import React, { useEffect, useMemo } from 'react';
import useDocumentStore from 'stores/document';
import useRecordingsStore from 'stores/recordings';
import { Document } from 'types/document';
import orderDocuments from 'utils/order-documents';

interface Props {
  className?: string;
  meetingParentId: string;
  currentMeeting: string;
  setCurrentMeeting: (id: string) => void;
  scrollContainerRef: React.RefObject<HTMLDivElement>;
}

function Meetings({ className, meetingParentId, currentMeeting, setCurrentMeeting, scrollContainerRef }: Props) {
  const documentStore = useDocumentStore();
  const recordingsStore = useRecordingsStore();
  useEffect(() => {
    const fetchContextPanelData = () => {
      if (meetingParentId) {
        recordingsStore.fetch(meetingParentId);
      }
    };
    fetchContextPanelData();
  }, [meetingParentId]);

  const meetings = useMemo(() => {
    // show meetings which have calendar event or have calls(not include superpanel voice record)
    const docs = documentStore.documents.filter(
      d =>
        (d.event || d.calls.filter(call => call.callType !== 'superpanel').length) && d.parent?.id === meetingParentId,
    );
    return orderDocuments(docs, []) as Document[];
  }, [documentStore.documents, meetingParentId, documentStore.entityDocuments]);

  const parentDoc = useMemo(
    () => documentStore.entityDocuments.find(doc => doc.id === meetingParentId),
    [documentStore.entityDocuments, meetingParentId],
  );

  return (
    <MultiDocsView
      className={className}
      docs={meetings}
      currentDoc={currentMeeting}
      parentDoc={parentDoc}
      setCurrentDoc={setCurrentMeeting}
      scrollContainerRef={scrollContainerRef}
    />
  );
}

Meetings.defaultProps = {
  className: '',
};

export default Meetings;
