import React, { useEffect, useRef, useState } from 'react';
import { CheckDocument } from 'api/documents/check_document_opportunity';
import { ClockIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import constants from 'utils/constants';
import CalendarIcon from './icons/CalendarIcon';
import { MeetingAttendee } from './MeetingAttendee';

interface Props {
  checkDocument: CheckDocument;
  setDocumentIds: (checkedDocumentIds: string[]) => void;
}

interface State {
  mainCheckboxState: 'checked' | 'indeterminate' | 'none';
  checkedDocumentIds: string[];
}

function OtherEventsToLink({ checkDocument, setDocumentIds }: Props) {
  const [state, setState] = useState<State>({
    mainCheckboxState: 'none',
    checkedDocumentIds: [],
  });
  const checkboxRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setDocumentIds(state.checkedDocumentIds);
  }, [state.checkedDocumentIds]);

  useEffect(() => {
    if (checkboxRef.current) {
      if (state.mainCheckboxState === 'checked') {
        checkboxRef.current.checked = true;
        checkboxRef.current.indeterminate = false;
      } else if (state.mainCheckboxState === 'indeterminate') {
        checkboxRef.current.checked = false;
        checkboxRef.current.indeterminate = true;
      } else {
        checkboxRef.current.checked = false;
        checkboxRef.current.indeterminate = false;
      }
    }
  }, [state.mainCheckboxState, checkboxRef]);

  useEffect(() => {
    if (state.checkedDocumentIds.length === 0) {
      setState(prevState => ({
        ...prevState,
        mainCheckboxState: 'none',
      }));
    } else if (state.checkedDocumentIds.length !== checkDocument.extraDocumentsToAssign.length) {
      if (state.mainCheckboxState !== 'indeterminate') {
        setState(prevState => ({
          ...prevState,
          mainCheckboxState: 'indeterminate',
        }));
      }
    } else if (state.checkedDocumentIds.length === checkDocument.extraDocumentsToAssign.length) {
      if (state.mainCheckboxState !== 'checked') {
        setState(prevState => ({
          ...prevState,
          mainCheckboxState: 'checked',
        }));
      }
    }
  }, [state.checkedDocumentIds]);

  const onMainCheckBoxClick = () => {
    // unselect all
    if (state.mainCheckboxState === 'checked') {
      setState(prevState => ({
        ...prevState,
        mainCheckboxState: 'none',
        checkedDocumentIds: [],
      }));
    }
    // select all
    else {
      setState(prevState => ({
        ...prevState,
        mainCheckboxState: 'checked',
        checkedDocumentIds: checkDocument.extraDocumentsToAssign.map(d => d.id),
      }));
    }
  };
  return (
    <div className="flex flex-col gap-2 border border-gray-300 rounded-lg px-2 pt-1 pb-2 mt-3">
      <div className="flex items-center gap-3 text font-medium  pl-2 border border-transparent">
        <input
          className="w-4 h-4"
          ref={checkboxRef}
          type="checkbox"
          onClick={() => {
            onMainCheckBoxClick();
          }}
        />
        <div className=""> Other events you might want to link</div>
      </div>
      <div className="flex flex-col gap-2">
        {checkDocument.extraDocumentsToAssign.map(d => (
          <div key={d.id} className="flex w-full items-start border border-gray-300  gap-3 px-2 py-1 rounded">
            <div className="w-[4%]">
              <input
                className="w-4 h-4"
                type="checkbox"
                checked={state.checkedDocumentIds.includes(d.id)}
                onChange={() => {
                  if (state.checkedDocumentIds.includes(d.id)) {
                    setState(prevState => ({
                      ...prevState,
                      checkedDocumentIds: state.checkedDocumentIds.filter(id => id !== d.id),
                    }));
                  } else {
                    setState(prevState => ({
                      ...prevState,
                      checkedDocumentIds: [...state.checkedDocumentIds, d.id],
                    }));
                  }
                }}
              />
            </div>
            <div className="flex w-[96%]">
              <div className="flex flex-col gap-1">
                {/* title */}
                <div className="flex items-start gap-1 text-gray-500">
                  <div className="flex pt-[0.15rem]">
                    <CalendarIcon className="w-4 h-4" />
                  </div>
                  <div className="text-sm font-medium text-gray-500">{d.event?.subject}</div>
                </div>
                {/* event time */}
                <div className="flex gap-1 items-center">
                  <ClockIcon className="w-3 h-3 text-gray-400" />
                  <div className="text-xs text-gray-400">
                    {moment(d.event?.startsAt).format(constants.DATETIME_FORMAT)}
                  </div>
                </div>
                {/* external attendees */}
                <div className="text-xs text-gray-400">External attendees</div>
                <div className="flex flex-col flex-grow-0">
                  {checkDocument.attendeesToAddToCRM.map(pureAttendee => {
                    const externalAttendee = d.event?.attendees.find(a => a.id === pureAttendee.id);

                    if (externalAttendee) {
                      return (
                        <div key={externalAttendee.id} className="">
                          <MeetingAttendee attendee={externalAttendee} inModal />
                        </div>
                      );
                    }

                    return null;
                  })}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default OtherEventsToLink;
