import React from 'react';
import { TeamMemberAssignee, TeamMemberRequestStatus } from '../../types/team-member-request';
import ContactIcon from '../../components/icons/ContactIcon';
import useUserStore from '../../stores/user';

interface Props {
  status: TeamMemberRequestStatus;
  assignees: TeamMemberAssignee[];
}

function AssignedTeamMember({ status, assignees }: Props) {
  const userStore = useUserStore();
  return (
    <div className="border rounded w-full px-10 py-5">
      <div className="bg-slate-100 w-full text-center py-2 font-medium rounded">Assigned Team Member</div>
      <div className="py-2" />
      {status === 'Pending' || assignees.length === 0 ? (
        <div className="text-center text-slate-500">
          {userStore.user?.role === 'Sales Engineer Manager'
            ? 'You are yet to assign a team member to this request'
            : 'Your request is still pending review. Once a team member is assigned it will show up here. You will be also be notified by email.'}
        </div>
      ) : (
        <div className="flex flex-col items-center gap-5">
          {assignees.map(a => {
            return (
              <div key={a.id}>
                <div className="flex flex-row gap-4 w-full">
                  <ContactIcon className="w-10 h-10 text-gray-500 bg-slate-100 rounded-full self-center" />
                  <div className="flex flex-col">
                    <div className="font-medium capitalize">
                      {a.firstName} {a.lastName}
                    </div>
                    <div className="text-xs text-gray-500">{a.role}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default AssignedTeamMember;
