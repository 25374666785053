import httpClient from '../http-client';

export interface SalesforceItem {
  id: string;
  name: string;
}

export interface Response {
  salesforceItems: SalesforceItem[];
}

export default async (object: string, name: string): Promise<Response> => {
  // TODO we may need to do url endode to handle object if there are special chars
  const { data } = await httpClient.get<Response>(`/salesforce/items/${object}?name=${name}`);
  return data;
};
