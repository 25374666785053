import React from 'react';

interface Props {
  className?: string;
}

function MicIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9996 3.00001C11.6904 2.99909 11.384 3.0592 11.0981 3.17688C10.8122 3.29457 10.5523 3.46752 10.3334 3.68583C10.1144 3.90414 9.94066 4.16351 9.82213 4.44909C9.70359 4.73466 9.64258 5.04082 9.64258 5.35001V11.6085C9.64258 12.2337 9.89093 12.8333 10.333 13.2753C10.7751 13.7174 11.3746 13.9658 11.9998 13.9658C12.625 13.9658 13.2246 13.7174 13.6667 13.2753C14.1087 12.8333 14.3571 12.2337 14.3571 11.6085V5.35001C14.3571 5.04077 14.296 4.73458 14.1775 4.44897C14.0589 4.16337 13.8851 3.90397 13.6661 3.68565C13.4471 3.46734 13.1872 3.29439 12.9012 3.17674C12.6152 3.05908 12.3088 2.99903 11.9996 3.00001V3.00001Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 10.0439V11.6089C17.5 13.0676 16.9205 14.4666 15.8891 15.498C14.8576 16.5295 13.4587 17.1089 12 17.1089C10.5413 17.1089 9.14236 16.5295 8.11091 15.498C7.07946 14.4666 6.5 13.0676 6.5 11.6089V10.0439"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 17.0869V20.2174"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.85742 20.2168H15.1429"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

MicIcon.defaultProps = {
  className: undefined,
};

export default MicIcon;
