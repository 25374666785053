import React, { useState } from 'react';
import errors from 'utils/errors';
import integrationToken from 'api/integration-api-token';
import useModalStore from 'stores/modal';

interface State {
  chorusToken: string;
  isSetChorusTokenLoading: boolean;
  isChorusTokenError: boolean;
}

function ChorusAPITokenModal({ nextStep }: { nextStep: () => void }) {
  const [state, setState] = useState<State>({
    chorusToken: '',
    isSetChorusTokenLoading: false,
    isChorusTokenError: false,
  });
  const modalStore = useModalStore();

  return (
    <div
      className="absolute left-1/2 bg-white rounded-lg p-3 pr-6 w-[1000px]"
      style={{
        top: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      {/* model header */}
      <div className="flex justify-between items-center px-4 pt-4 pb-2 font-medium text-2xl">Add Chorus API token</div>
      <div className="flex justify-between items-center p-4">
        You must be a Chorus admin and please get the token from Chorus &gt; Settings &gt; Personal Settings &gt; API
        Access.
      </div>
      <div className="flex gap-2 items-center pl-4 text-gray-500">
        Chorus Token
        <input
          type="password"
          className="flex border rounded focus:outline-none focus:border-indigo-300 h-10 w-96 p-2"
          onChange={e => {
            setState(prevState => ({ ...prevState, chorusToken: e.target.value }));
          }}
          maxLength={5000}
          value={state.chorusToken}
        />
        {state.isChorusTokenError && (
          <div className="text-red-500 text-sm">Error: Please check your Chorus token and try again.</div>
        )}
      </div>
      <div className="my-3 flex flex-row-reverse items-center gap-3">
        <button
          className={`px-8 py-2 rounded ${
            state.chorusToken.length === 0 || state.isSetChorusTokenLoading
              ? 'bg-gray-200 text-gray-500 cursor-default'
              : 'bg-blue-600 hover:bg-blue-500 text-white'
          }`}
          type="button"
          onClick={async () => {
            setState(prevState => ({ ...prevState, isSetChorusTokenLoading: true }));

            try {
              await integrationToken('chorus', state.chorusToken);
              setState(prevState => ({ ...prevState, isSetChorusTokenLoading: false }));
              modalStore.close();
              nextStep();
            } catch (e) {
              if (e instanceof errors.IntegrationError) {
                setState(prevState => ({
                  ...prevState,
                  isSetChorusTokenLoading: false,
                  isChorusTokenError: true,
                }));
              }
            }
          }}
          disabled={state.chorusToken.length === 0 || state.isSetChorusTokenLoading}
        >
          Add
        </button>
      </div>
    </div>
  );
}
export default ChorusAPITokenModal;
