import { EntityDocument } from 'types/entity';
import { Document } from 'types/document';
import httpClient from '../http-client';

export interface Response {
  entityDocuments: EntityDocument[];
  documents: Document[];
}

export default async (sfEntityId: string, sfEntityType: 'Opportunity' | 'Account'): Promise<Response> => {
  const { data } = await httpClient.post<Response>(`/entities`, {
    sfEntityId,
    sfEntityType,
  });
  return data;
};
