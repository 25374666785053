/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { openFormModal } from 'components/BaseModal';
import { cloneDeep } from 'lodash';
import FormField from 'components/Form/FormField';
import InputField from 'components/Form/InputField';
import { requiredInputValidate } from 'utils/validate';
import apiErrors from 'utils/errors';
import { ViewSetting, getEmptyViewSetting } from 'types/view-setting';
import updateViewSetting from 'api/view-setting/update-view-setting';
import createViewSetting from 'api/view-setting/create-view-setting';
import { ColumnConfig } from 'components/Table/types';

/**
 * open modal to create a new view setting or update an existing one
 * @param viewSetting if exist then update, otherwise create new
 * @returns new created/updated view setting
 */
const openNewViewSettingModal = (columns: ColumnConfig[], view?: ViewSetting): Promise<ViewSetting | undefined> => {
  const viewSetting: ViewSetting = view ? cloneDeep(view) : getEmptyViewSetting();
  if (!viewSetting.view.length) {
    viewSetting.view = columns;
  }
  return openFormModal<ViewSetting>(
    {
      formData: viewSetting,
      renderFormBody: ({ register, errors }) => (
        <FormField
          className="mb-3"
          label="View Name"
          labelClassName="w-[8rem]"
          error={errors.name ? errors.name.message || 'Name is required' : ''}
        >
          <InputField {...register('name', requiredInputValidate)} placeholder="Enter view name" />
        </FormField>
      ),
      handleApiError: (error, setError) => {
        if (error.code === apiErrors.DatabaseError.code) {
          setError('name', {
            message: 'View name already exist',
            type: 'api',
          });
        }
      },
      actions: [
        {
          label: 'Cancel',
          color: 'secondary',
        },
        {
          label: view ? 'Update' : 'Create',
          color: 'primary',
          type: 'submit',
          cb: fd => {
            if (fd.id) {
              return updateViewSetting(fd.id, fd.name.trim(), columns);
            }
            return createViewSetting(fd.name.trim(), columns, view?.viewType || 'analytic_report');
          },
        },
      ],
    },
    {
      className: 'w-[46rem]',
      title: view ? 'Update View' : 'Save Current Setting as a New View',
      bodyClassName: '!pr-0',
    },
    '',
    {
      disableRestoreFocus: true,
    },
  ).then(rsp => {
    return typeof rsp === 'object' ? (rsp as ViewSetting) : undefined;
  });
};

export default openNewViewSettingModal;
