import { Document } from 'types/document';
import httpClient from '../http-client';

export interface CreateMentionPayload {
  documentId: string;
  mentionTos: string[];
  content: string;
  mentionIds: string[];
}

export default async (payload: CreateMentionPayload): Promise<void> => {
  await httpClient.post<Document>(`/mention-event`, payload);
};
