import React, { useEffect } from 'react';
import useModalStore from '../stores/modal';
import SearchModal from './SearchModal';
import { isEmpty } from 'lodash';
import isHotkey from 'is-hotkey';

function KeyboardListener() {
  const modalStore = useModalStore();

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (isHotkey('mod+k', event)) {
        if (isEmpty(modalStore.modals)) {
          modalStore.setState({
            isOpen: true,
            element: <SearchModal />,
            verticalPosition: 'top',
          });
        }
      }
    };
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  return null;
}

export default KeyboardListener;
