export type MemberRole = 'Sales Engineer Manager' | 'Sales Engineer' | 'Account Executive' | 'Other';

export enum MemberStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  DELETED = 'deleted',
}
export interface Member {
  id: string;
  firstName: string | null;
  lastName: string | null;
  color: string;
  email: string;
  isAdmin: boolean;
  role: MemberRole;
  createdAt: string;
  status: MemberStatus;
}

export type InvitationStatus = 'pending' | 'expired';
export interface Invitation {
  id: string;
  email: string;
  isAdmin: boolean;
  role: MemberRole;
  createdAt: string;
  status: InvitationStatus;
}

export enum ActionType {
  DEACTIVATE = 'deactivate',
  DELETE = 'delete',
  REACTIVATE = 'reactivate',
  UPDATE = 'update',
  RESEND_INVITE = 'resend_invite',
  DELETE_INVITE = 'delete_invite',
}

export interface Invite {
  email: string;
  role: MemberRole;
  isAdmin: boolean;
}

export type UpdateMember = (actionType: ActionType, successMsg: string, transferEmail?: string) => void;
