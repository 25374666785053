import { Chat } from 'types/chat';
import httpClient from '../http-client';

export interface Response {
  chats: Chat[];
}

export default async (documentId: string, message: string): Promise<Response> => {
  const { data } = await httpClient.post<Response>(`/chats/${documentId}`, {
    message,
  });
  return data;
};
