import getField from 'api/fields/get-field';
import React, { useEffect, useState } from 'react';
import { ReactEditor, useSlate } from 'slate-react';
import { Field } from 'types/field-value';

import SpinLoader from 'components/icons/SpinLoader';
import errors from 'utils/errors';
import QuestionTitle from './FieldValueView/QuestionTitle';
import NoteEditor from './NoteEditor/NoteEditor';
import { DiscoveryQuestionElement } from './NoteEditor/types';

interface State {
  discoveryQuestion: Field | null | undefined;
  hidePill: boolean;
  isEditQuestion: boolean;
  isLoadingQuestion: boolean;
}

interface Props {
  discoveryQuestionId: string;
  element: DiscoveryQuestionElement;
  readOnly: boolean;
}

function FieldValueTemplateView({ discoveryQuestionId, element, readOnly }: Props) {
  // this contains local state of the discoveryQuestions
  // the local and global doesn't fully match
  // some places we use local, and some other global
  const [state, setState] = useState<State>({
    discoveryQuestion: null,
    hidePill: true,
    isEditQuestion: false,
    isLoadingQuestion: false,
  });
  const editor = useSlate();

  useEffect(() => {
    const fetch = async () => {
      try {
        setState(prevState => ({ ...prevState, isLoadingQuestion: true }));
        const dq = await getField(discoveryQuestionId);
        setState(prevState => ({ ...prevState, isLoadingQuestion: false, discoveryQuestion: dq }));
      } catch (e) {
        if (e instanceof errors.DatabaseError) {
          setState(prevState => ({ ...prevState, discoveryQuestion: undefined, isLoadingQuestion: false }));
        }
      }
    };

    if (discoveryQuestionId) {
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (state.isLoadingQuestion || state.discoveryQuestion === null) {
    return (
      <div className="w-full h-full flex justify-center items-center mb-10">
        <SpinLoader className="animate-spin w-6 h-6 text-orange-500" />
      </div>
    );
  }

  let removeFieldButton: JSX.Element | null = null;
  if (!readOnly) {
    removeFieldButton = (
      <button
        type="button"
        className={`flex ml-2 py-1 px-2 rounded-lg text-[10px] font-normal hover:cursor-pointer ${'text-blue-500 bg-blue-100 hover:bg-blue-200'}`}
        onClick={() => {
          const path = ReactEditor.findPath(editor, element);
          NoteEditor.sp_deleteNode(editor, path);
        }}
      >
        Remove
      </button>
    );
  }

  if (state.discoveryQuestion === undefined) {
    return (
      <div className="flex gap-4 w-full items-center">
        <div className="text-gray-400 border border-gray-100 px-2 rounded-lg my-2">
          This question has been deleted, please remove
        </div>
        <div className="animate-fade-in-down">{removeFieldButton}</div>
      </div>
    );
  }

  return (
    <div
      className="pt-1 relative pb-2"
      onMouseEnter={() => setState(prevState => ({ ...prevState, hidePill: false }))}
      onMouseLeave={() => setState(prevState => ({ ...prevState, hidePill: true }))}
    >
      <div className="flex gap-2 w-full">
        <div className="flex flex-wrap items-center">
          <QuestionTitle field={state.discoveryQuestion} />
          {state.discoveryQuestion.entity && (
            <div className="text-xs text-gray-400">• {state.discoveryQuestion.entity}</div>
          )}
          {!state.hidePill && !state.isEditQuestion && <div className="animate-fade-in-down">{removeFieldButton}</div>}
        </div>
      </div>
      <div className="">
        <div className="ml-[0.17rem] pl-[0.6rem] border-l-[0.2rem] border-solid border-gray-200 flex flex-col">
          <div className="flex items-center w-full h-6" />
        </div>
      </div>
    </div>
  );
}

export default FieldValueTemplateView;
