import Popover from 'components/Popover';
import typoStyles from 'components/typo.module.css';
import React from 'react';
import { concat } from 'utils/styling';
import LinkFormInput from './LinkFormInput';
import { LinkElement as LinkElementType } from '../../types';

interface Props {
  linkFormOpen: boolean;
  refElement: React.RefObject<HTMLDivElement>;
  element: LinkElementType;
  onUpdateLink: (newLink: string) => void;
}

function LinkForm({ linkFormOpen, refElement, element, onUpdateLink }: Props) {
  return (
    <Popover
      isOpen={linkFormOpen}
      onClose={() => {
        onUpdateLink(element.link);
      }}
      anchorReference="anchorEl"
      anchorEl={refElement.current}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      disableRestoreFocus
    >
      <div className="p-1 bg-gray-100">
        <div className={concat(typoStyles.xs, typoStyles['text-grey-500'])}>URL</div>
        <LinkFormInput element={element} onUpdateLink={onUpdateLink} />
      </div>
    </Popover>
  );
}

export default LinkForm;
