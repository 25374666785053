import React, { ReactNode } from 'react';

import { concat } from 'utils/styling';

import typoStyles from 'components/typo.module.css';
import styles from './SignupContainer.module.css';

interface Props {
  children: ReactNode;
  currentStepIndex: number;
  title?: string;
  totalStepNumber: number;
}

function SignupContainer({ children, currentStepIndex, title, totalStepNumber }: Props) {
  return (
    <div className={styles.root}>
      <div className={styles.layout}>
        <div className={styles.container}>
          <span className={concat(typoStyles.sm, typoStyles['semi-bold'])}>
            <span>Step {currentStepIndex}</span>
            &nbsp;
            <span className={typoStyles['text-grey-500']}> / {totalStepNumber}</span>
          </span>
          {title && <div className={concat(typoStyles['xl-3'], typoStyles.bold)}>{title}</div>}
          {children}
        </div>
      </div>
    </div>
  );
}

SignupContainer.defaultProps = {
  title: undefined,
};

export default SignupContainer;
