/* eslint-disable react/no-array-index-key, no-console */
import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { SalesforcePicklistValue } from 'types/field-value';
import TrashIcon from 'components/icons/TrashIcon';
import { PlusIcon } from '@heroicons/react/24/outline';
import BaseButton from '../BaseButton';

export interface ListOptionsProps {
  options: SalesforcePicklistValue[];
  onOptionsUpdate: (options: SalesforcePicklistValue[]) => Promise<void>;
  disabled?: boolean;
}

export function PickListOptions({ options, onOptionsUpdate, disabled }: ListOptionsProps) {
  const [rules, setRules] = useState<string[]>([]);

  useEffect(() => {
    let opts = options.map(i => i.value);
    if (opts.length <= 0) {
      opts = [''];
    }
    setRules(opts);
  }, [options, disabled]);

  const handleRulesContentUpdate = useCallback(
    debounce((newRules: string[]) => {
      onOptionsUpdate(
        newRules.map(i => ({
          defaultValue: false,
          active: true,
          label: i,
          value: i,
        })),
      ).catch(console.error);
    }, 50),
    [],
  );

  const handleUpdate = (i: number, txt: string) => {
    const newRules = [...rules];
    newRules[i] = txt;
    handleRulesContentUpdate(newRules);
    setRules(newRules);
  };

  const onDelete = (i: number) => {
    const newRules = [...rules];
    newRules.splice(i, 1);
    handleRulesContentUpdate(newRules);
    setRules(newRules);
  };
  const onAdd = () => {
    const newRules = [...rules];
    newRules.push('');
    handleRulesContentUpdate(newRules);
    setRules(newRules);
  };

  const hasDelete = (i: number) => rules.length > 1 && i >= 0 && !disabled;
  const hasAdd = (i: number) => i === rules.length - 1 && rules[i] && !disabled;
  const disableCls = disabled ? 'bg-gray-100' : '';
  return (
    <div className="w-full">
      {rules.map((t, i) => (
        <div
          key={i}
          className={`w-full flex flex-row items-center border-b-gray-200 mt-[1px] border-b rounded h-[32px] ${disableCls}`}
        >
          <input
            className={`text-xs pl-4 flex-1 pr-4 ${disableCls}`}
            type="text"
            readOnly={disabled}
            onChange={e => handleUpdate(i, e.target.value)}
            value={t}
          />
          <div className="ml-2 w-20 flex flex-row items-center justify-end">
            {hasDelete(i) && (
              <BaseButton size="small" color="secondary" disabled={disabled} onClick={() => onDelete(i)}>
                <TrashIcon className="w-4 h-4 text-gray-500" />
              </BaseButton>
            )}
            {hasAdd(i) && (
              <BaseButton size="small" color="secondary" disabled={disabled} onClick={() => onAdd()}>
                <PlusIcon className="w-4 h-4 text-gray-500" />
              </BaseButton>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

PickListOptions.defaultProps = {
  disabled: false,
};
