import { Person } from 'types/entity';
import httpClient from '../http-client';

export interface AvailableFilters {
  oppOwnerKeyById: { [key: string]: Person };
  accountOwnerKeyById: { [key: string]: Person };
  salesEngineers: string[];
  pipefySalesEngineers: string[];
  pipefySolutionArchitects: string[];
  redoxSolutionEngineers: { [key: string]: Person };
  walnutSalesEngineers: { [key: string]: Person };
  opportunityStages: string[];
  opportunityAmounts: number[];
}

export default async (): Promise<AvailableFilters> => {
  const { data } = await httpClient.get<AvailableFilters>(`/filters`);
  return data;
};
