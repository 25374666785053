import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import CaretIcon from 'components/icons/CaretIcon';

import useDocumentStore from 'stores/document';
import Loader from 'components/Loader';
import FilterMenu from './Filters/FilterMenu';
import { FieldSubHeader, ReportingFilter } from '../../types/reporting';
import FieldFilter from '../Table/FieldFilter';
import { ColumnFilter } from '../Table/types';
import useTemplateStore from '../../stores/templates';
import SelectField from 'components/Form/SelectField';

export type FiltersRef = {
  applyFilter: (currentDocumentId?: string) => void;
};

interface State {
  filters: ReportingFilter[];
  isEdited: boolean;
  showOpportunityFilters: boolean;
  showAccountFilters: boolean;
}

const Filters = forwardRef<FiltersRef>((_, ref) => {
  const documentStore = useDocumentStore();
  const templateStore = useTemplateStore();
  const [state, setState] = useState<State>({
    filters: [],
    isEdited: false,
    showOpportunityFilters: true,
    showAccountFilters: true,
  });

  useImperativeHandle(ref, () => ({
    applyFilter: currentDocumentId => {
      if (state.isEdited) {
        documentStore.setDocumentFilters(state.filters, documentStore.showOpportunityOnly, currentDocumentId);
        templateStore.fetch();
      }
    },
  }));

  useEffect(() => {
    documentStore.fetchFieldFilters();
  }, []);

  useEffect(() => {
    setState(prev => ({
      ...prev,
      filters: documentStore.documentFilters,
      showOpportunityOnly: documentStore.showOpportunityOnly,
    }));
  }, []);

  const accountCountSelectMenus = useMemo(() => [30, 50, 100].map(item => ({ label: `${item}`, value: item })), []);

  if (documentStore.isLoadingFieldFilters) {
    return (
      <div className="w-[25rem] flex items-center justify-center h-full">
        <Loader />
      </div>
    );
  }

  const onFilterClear = (entityName: 'Account' | 'Opportunity') => {
    setState(prev => ({
      ...prev,
      isEdited: true,
      filters: prev.filters.filter(f => f.groupId !== entityName),
      showOpportunityOnly: false,
    }));
  };

  const onFilterUpdate = (
    entityName: 'Account' | 'Opportunity',
    fieldSubHeader: FieldSubHeader,
    filter?: ColumnFilter,
  ) => {
    let reportingFilter: ReportingFilter | undefined;
    if (filter) {
      reportingFilter = {
        type: 'entity',
        groupId: entityName,
        columnId: fieldSubHeader.fieldId,
        dataEntryType: fieldSubHeader.type,
        filterType: filter.type,
        filterValue: filter.value,
      };
    }
    setState(prev => {
      const { filters } = prev;
      const index = filters.findIndex(df => df.columnId === fieldSubHeader.fieldId);

      if (index < 0 && reportingFilter) {
        filters.push(reportingFilter);
      } else if (index >= 0 && !reportingFilter) {
        filters.splice(index, 1);
      } else if (index >= 0 && reportingFilter) {
        filters.splice(index, 1, reportingFilter);
      }
      return {
        ...prev,
        filters,
        isEdited: true,
      };
    });
  };

  return (
    <div className="w-[23rem] p-3 divide-y">
      <div className="py-2">
        <div className="flex justify-between items-center py-2">
          <div className="text-sm font-medium text-gray-400">Accounts/Opportunities count to show</div>
          <SelectField
            size="small"
            value={documentStore.numberOfItemsToShow}
            onChange={newV => {
              if (typeof newV === 'number') {
                documentStore.setState({
                  numberOfItemsToShow: newV,
                });
              }
            }}
            className="w-[70px]"
            menus={accountCountSelectMenus}
          />
        </div>
      </div>
      <div className="py-2">
        <div className="flex justify-between items-center py-2">
          <div className="text-sm font-medium text-gray-400">Opportunity filters</div>
          <div className="flex gap-2">
            <button
              type="button"
              className="text-xs text-blue-500 hover:underline"
              onClick={() => onFilterClear('Opportunity')}
            >
              Clear all
            </button>
            <button
              type="button"
              className="w-5 h-5"
              onClick={() =>
                setState(prevState => ({ ...prevState, showOpportunityFilters: !prevState.showOpportunityFilters }))
              }
            >
              <CaretIcon className={`w-5 h-5 text-gray-400 ${!state.showOpportunityFilters && 'rotate-180'}`} />
            </button>
          </div>
        </div>
        {state.showOpportunityFilters && (
          <div className="flex flex-col">
            {documentStore.fieldFilters.opportunity.map(fieldSubHeader => {
              const reportingFilter = state.filters.find(f => f.columnId === fieldSubHeader.fieldId);
              return (
                <FilterMenu
                  key={fieldSubHeader.fieldId}
                  fieldType={fieldSubHeader.type}
                  title={fieldSubHeader.fieldLabel}
                  reportingFilter={reportingFilter}
                >
                  <div className="w-72">
                    <FieldFilter
                      columnDataType="ReportEntityField"
                      data={{
                        dataEntryType: fieldSubHeader.type,
                        picklistValues: fieldSubHeader.picklistValues,
                        referenceTo: fieldSubHeader.referenceTo,
                      }}
                      name={fieldSubHeader.fieldLabel}
                      onFilterUpdate={filter => onFilterUpdate('Opportunity', fieldSubHeader, filter)}
                      onClose={() => null}
                      type={reportingFilter?.filterType}
                      value={reportingFilter?.filterValue}
                    />
                  </div>
                </FilterMenu>
              );
            })}
          </div>
        )}
      </div>
      <div className="py-2">
        <div className="flex justify-between items-center py-2">
          <div className="text-sm font-medium text-gray-400">Account filters</div>
          <div className="flex gap-2">
            <button
              type="button"
              className="text-xs text-blue-500 hover:underline"
              onClick={() => onFilterClear('Account')}
            >
              Clear all
            </button>
            <button
              type="button"
              className="w-5 h-5"
              onClick={() =>
                setState(prevState => ({ ...prevState, showAccountFilters: !prevState.showAccountFilters }))
              }
            >
              <CaretIcon className={`w-5 h-5 text-gray-400 ${!state.showAccountFilters && 'rotate-180'}`} />
            </button>
          </div>
        </div>
        {state.showAccountFilters && (
          <div className="flex flex-col">
            {documentStore.fieldFilters.account.map(fieldSubHeader => {
              const reportingFilter = state.filters.find(f => f.columnId === fieldSubHeader.fieldId);
              return (
                <FilterMenu
                  key={fieldSubHeader.fieldId}
                  fieldType={fieldSubHeader.type}
                  title={fieldSubHeader.fieldLabel}
                  reportingFilter={reportingFilter}
                >
                  <div className="w-72">
                    <FieldFilter
                      columnDataType="ReportEntityField"
                      data={{
                        dataEntryType: fieldSubHeader.type,
                        picklistValues: fieldSubHeader.picklistValues,
                        referenceTo: fieldSubHeader.referenceTo,
                      }}
                      name={fieldSubHeader.fieldLabel}
                      onFilterUpdate={filter => onFilterUpdate('Account', fieldSubHeader, filter)}
                      onClose={() => null}
                      type={reportingFilter?.filterType}
                      value={reportingFilter?.filterValue}
                    />
                  </div>
                </FilterMenu>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
});

Filters.displayName = 'Filters';

export default Filters;
