/* eslint-disable no-nested-ternary */
import { TextFieldProps } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import BaseButton from 'components/BaseButton';
import moment from 'moment';
import React, { useCallback, useState } from 'react';

const DEFAULT_FMT = 'YYYY-MM-DD';

interface Props {
  className?: string;
  tooltip?: string;
  value?: string;
  onChange: (newV: string) => void;
  renderContent?: (v: string) => React.ReactNode;
  format?: string;
  placeholder?: string;
  loading?: boolean;
  clearable?: boolean;
  disabled?: boolean;
}

const DatePickerButton = React.forwardRef<HTMLDivElement, Props>(
  (
    { className, tooltip, value, onChange, renderContent, format, placeholder, loading, clearable, disabled }: Props,
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const renderBtnContent = useCallback(() => {
      return renderContent
        ? renderContent(value || '')
        : value
        ? moment(value).format(format || DEFAULT_FMT)
        : placeholder || 'Select date';
    }, [value, renderContent]);

    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          ref={ref}
          open={isOpen}
          onClose={() => setIsOpen(false)}
          value={value}
          onChange={newV => {
            onChange(newV ? moment(newV).toISOString() : '');
          }}
          disabled={disabled}
          componentsProps={
            clearable
              ? {
                  actionBar: {
                    actions: ['clear'],
                  },
                }
              : undefined
          }
          renderInput={(params: TextFieldProps) => {
            return (
              <BaseButton
                className={className}
                ref={params.inputRef}
                color="secondary"
                variant="text"
                tooltip={tooltip}
                onClick={() => setIsOpen(true)}
                loading={loading}
                disabled={disabled}
              >
                {renderBtnContent()}
              </BaseButton>
            );
          }}
        />
      </LocalizationProvider>
    );
  },
);

DatePickerButton.displayName = 'DatePickerButton';
DatePickerButton.defaultProps = {
  className: '',
  renderContent: undefined,
  value: undefined,
  format: '',
  placeholder: '',
  tooltip: '',
  loading: false,
  clearable: true,
  disabled: false,
};

export default DatePickerButton;
