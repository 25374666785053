import React from 'react';
import SpinLoader from './icons/SpinLoader';

interface Props {
  text: string;
}
function LoaderTransparentBackground({ text }: Props) {
  return (
    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full z-50 bg-gray-100 opacity-90">
      <div className="w-full h-full flex items-center justify-center">
        <div className="text-center">
          <SpinLoader className="animate-spin w-10 h-10 text-orange-500" />
          <div className="text-gray-600">{text}</div>
        </div>
      </div>
    </div>
  );
}

export default LoaderTransparentBackground;
