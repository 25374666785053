import { AIWorker } from './ai-worker';

export interface AIWorkerWBEvent {
  ai_worker_id: string;
  action: 'append' | 'finished' | 'context_update';
  text?: string;
  ai_worker?: AIWorker;
}

export interface VectorTask {
  entry_id: string;
  uid: string;
  type: string;
  team_id: string;

  document_id?: string;
  call_id?: string;
  field_value_id?: string;
  crm_note_id?: string;
  user_id?: string;
  crm_note_version_data?: string;
  file_id?: string;
}
export interface VectorEvent {
  action: 'finished' | 'started' | 'failed';
  task: VectorTask;
}

export const WB_EVENT_AI_WORKER = 'AI_WORKER';
export const WB_EVENT_VECTOR_TASK = 'VECTOR_TASK';

export type WBEventType = 'AI_WORKER' | 'VECTOR_TASK';
export type WBEventBody = AIWorkerWBEvent | VectorEvent;
export interface WBEvent {
  type: WBEventType;
  body: WBEventBody;
}
