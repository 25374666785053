import isHotkey from 'is-hotkey';
import { Editor, Node, Range, Transforms } from 'slate';
import NoteEditor from '../NoteEditor';
import { BlockElement, BlockType, CustomElement, CustomText, HotKeyIndent, TaskElement } from '../types';
import { SlatePlugin } from './types';
import useUserStore from 'stores/user';
import { createNode } from '../utils';

export const isMarkActive = (editor: Editor, format: keyof CustomText): boolean => {
  const marks = NoteEditor.marks(editor) as CustomText;
  return marks ? marks[format] === true : false;
};

function hotKeyPlugin(event: React.KeyboardEvent<HTMLDivElement>, editor: Editor, onlyApplyFormat?: boolean): boolean {
  const HOTKEYS_BOOLEAN: { [hotKey: string]: keyof CustomText } = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
    'mod+`': 'code',
  };

  Object.entries(HOTKEYS_BOOLEAN).forEach(([hotKey, format]) => {
    if (isHotkey(hotKey, event)) {
      event.preventDefault();
      const isActive = isMarkActive(editor, format);
      if (isActive) {
        NoteEditor.removeMark(editor, format);
      } else {
        NoteEditor.addMark(editor, format, true);
      }
    }
  });

  if (onlyApplyFormat) {
    return false;
  }

  const HOTKEYS_INDENT: { [hotKey: string]: HotKeyIndent } = {
    tab: 'indent',
    'shift+tab': 'outdent',
  };

  Object.entries(HOTKEYS_INDENT).forEach(([hotKey, format]) => {
    if (isHotkey(hotKey, event)) {
      event.preventDefault();
      const range = NoteEditor.sp_unhangRange(editor);
      if (range) {
        // get the 1st selected block id and the last block id
        const [firstBlock, lastBlock] = Editor.edges(editor, range);
        for (let blockIndex = firstBlock.path[0]; blockIndex <= lastBlock.path[0]; blockIndex += 1) {
          if ('indentLevel' in editor.children[blockIndex]) {
            if ('indentLevel' in editor.children[blockIndex] && format === 'indent') {
              // eslint-disable-next-line no-param-reassign
              const level = (editor.children[blockIndex] as CustomElement).indentLevel;
              if (level !== undefined && level < 6) {
                // eslint-disable-next-line no-param-reassign
                Transforms.setNodes(editor, { indentLevel: level + 1 }, { at: [blockIndex] });
              }
            }
            if ('indentLevel' in editor.children[blockIndex] && format === 'outdent') {
              // eslint-disable-next-line no-param-reassign
              const level = (editor.children[blockIndex] as CustomElement).indentLevel;
              if (level !== undefined && level > 0) {
                // eslint-disable-next-line no-param-reassign
                Transforms.setNodes(editor, { indentLevel: level - 1 }, { at: [blockIndex] });
              }
            }
          }
        }
      }
    }
  });

  if (isHotkey('space', event)) {
    const { selection } = editor;
    if (selection) {
      const [start] = Range.edges(selection);
      const rootPath = start.path.slice(0, 1);
      const rootNode = Node.has(editor, rootPath) ? (Node.get(editor, rootPath) as BlockElement) : null;
      if (start.offset === 2 && rootNode && (rootNode.type as BlockType) === BlockType.Paragraph) {
        const beforeText = Editor.string(editor, rootPath);
        if (beforeText === '[]') {
          event.preventDefault();
          event.stopPropagation();
          const task = {
            type: BlockType.Task,
            beingCreatedBy: useUserStore.getState().user?.id || '',
            children: [{ text: '' }],
            initContent: [createNode(BlockType.Paragraph)],
          } as TaskElement;
          // clear children node
          Transforms.select(editor, rootPath);
          Transforms.delete(editor, { hanging: true });
          // change to task node
          Transforms.setNodes(editor, { ...task, indentLevel: undefined }, { at: rootPath });
          return true;
        }
      }
    }
  }

  return false;
}

const HotKeyPlugin: SlatePlugin = {
  key: 'hotkey-plugin',
  onKeyDown: hotKeyPlugin,
};

export default HotKeyPlugin;
