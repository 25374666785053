/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-else-return */
import React, { useMemo } from 'react';
import { ColumnConfig } from '../types';
import BaseCheckbox from 'components/Form/BaseCheckbox';
import BaseButton from 'components/BaseButton';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { isProcessingColumn } from '../utils';
import { concat } from '../../../utils/styling';

interface Props {
  columnConfig: ColumnConfig;
  updateColumnConfig: (columnConfig: ColumnConfig) => void;
  expanded: boolean;
  toggleExpanded: () => void;
}
function SortableColumnConfig({ columnConfig, updateColumnConfig, expanded, toggleExpanded }: Props) {
  const isGroupConfig = 'group' in columnConfig;

  const isAllGroupColumnsVisible = useMemo(() => {
    if ('group' in columnConfig) {
      return columnConfig.columns.every(col => !col.isHidden)
        ? true
        : columnConfig.columns.every(col => !!col.isHidden)
        ? false
        : undefined;
    }
    return undefined;
  }, [(columnConfig as any).columns]);

  const isProcessing = isProcessingColumn(columnConfig);

  return (
    <div className="w-full">
      <div className="flex items-start">
        {isGroupConfig ? (
          <BaseCheckbox
            className="!ml-0 !items-start"
            labelClassName="block py-1.5"
            checked={isAllGroupColumnsVisible}
            indeterminate={isAllGroupColumnsVisible === undefined}
            disabled={isProcessing}
            onChange={checked => {
              const cols = columnConfig.columns.map(item => {
                let isHidden = !checked;
                if (isProcessingColumn(item)) {
                  isHidden = true;
                }
                return { ...item, isHidden };
              });
              updateColumnConfig({ ...columnConfig, columns: cols });
            }}
          />
        ) : (
          <BaseCheckbox
            className="!ml-0 w-full"
            label={isProcessing ? `${columnConfig.name} (Processing)` : columnConfig.name}
            labelClassName="text-sm font-medium "
            checked={!columnConfig.isHidden}
            disabled={isProcessing}
            onChange={checked => {
              updateColumnConfig({ ...columnConfig, isHidden: !checked });
            }}
          />
        )}
        {isGroupConfig && (
          <BaseButton
            color="secondary"
            variant="text"
            onClick={() => toggleExpanded()}
            className="flex-1 !h-auto !pl-1 !-ml-1 !text-black"
            disabled={isProcessing}
            contentClassName="!w-full !justify-between my-1 !items-start"
          >
            <div className={concat('text-sm font-medium text-left', isProcessing && 'text-stone-400')}>
              {columnConfig.group} {isProcessing && '(Processing)'}
            </div>
            {!isProcessing && (
              <ChevronDownIcon className={`min-w-[1.25rem] text-gray-400 w-5 h-5 ${expanded && 'rotate-180'}`} />
            )}
          </BaseButton>
        )}
      </div>
    </div>
  );
}

export default SortableColumnConfig;
