/* eslint-disable no-nested-ternary */
import React from 'react';
import { ArrowDownIcon, ArrowUpIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { ColumnDefinition, ColumnSort } from '../types';
import BaseButton from 'components/BaseButton';
import { Tooltip } from '@mui/material';
import { concat } from 'utils/styling';

interface Props {
  columnDefinition: ColumnDefinition;
  updateColumnDefinition: (newColumnDefinition: ColumnDefinition) => void;
  btnClassName?: string;
}

function SortFieldButton({ columnDefinition, updateColumnDefinition, btnClassName }: Props) {
  return (
    <div className="flex flex-nowrap items-center justify-between py-[1px] truncate">
      <Tooltip
        title={columnDefinition.sort ? (columnDefinition.sort === 'ASC' ? 'Sort ascending' : 'Sort descending') : ''}
        placement="top"
      >
        <button
          type="button"
          className={concat(
            'flex items-center justify-between text-left pl-1 pr-0.5 flex-1 hover:bg-gray-100 rounded truncate',
            btnClassName,
          )}
          onClick={() => {
            let sort: ColumnSort | undefined;
            if (columnDefinition.sort === 'ASC') {
              sort = 'DESC';
            } else if (columnDefinition.sort === undefined) {
              sort = 'ASC';
            }
            updateColumnDefinition({ ...columnDefinition, sort });
          }}
        >
          <span className="py-1 truncate">{columnDefinition.name}</span>
          {!!columnDefinition.sort &&
            (columnDefinition.sort === 'ASC' ? (
              <ArrowUpIcon className="w-4 h-4 text-green-500 flex-shrink-0 flex-grow-0" />
            ) : (
              <ArrowDownIcon className="w-4 h-4 text-green-500 flex-shrink-0 flex-grow-0" />
            ))}
        </button>
      </Tooltip>
      {!!columnDefinition.sort && (
        <BaseButton
          tooltip="Clear sort"
          color="secondary"
          variant="contained"
          iconBtn
          onClick={e => {
            e.stopPropagation();
            updateColumnDefinition({ ...columnDefinition, sort: undefined });
          }}
        >
          <XMarkIcon width={16} />
        </BaseButton>
      )}
    </div>
  );
}

SortFieldButton.defaultProps = {
  btnClassName: '',
};

export default SortFieldButton;
