/* eslint-disable import/no-extraneous-dependencies */
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { create } from 'zustand';
import getNoteTemplates, { Template } from 'api/note-templates/get-note-templates';
import updateTemplate, { templateData } from 'api/note-templates/update-template';
import deleteTemplate from 'api/note-templates/delete-template';
import createTemplate from 'api/note-templates/create-template';

interface State {
  templates: Template[];
  fetch: () => Promise<void>;
  fetching: boolean;
  update: (
    templateId: string,
    templateName: string,
    templateData: templateData,
    templateIcon: string | null,
    isMeta: boolean,
  ) => Promise<void>;
  delete: (templateId: string) => Promise<void>;
  create: (
    templateName: string,
    templateData: templateData,
    templateIcon: string | null,
    createVia: 'document' | 'template',
    isMeta: boolean,
  ) => Promise<void>;
}

const useTemplateStore = create<State>((set, get) => ({
  templates: [],
  setState: (newState: Partial<State>) => {
    set(state => {
      return { ...state, ...newState };
    });
    return get();
  },
  fetch: async (): Promise<void> => {
    try {
      set(state => {
        return { ...state, fetching: true };
      });
      const templates = await getNoteTemplates();
      set(state => {
        return { ...state, templates };
      });
    } finally {
      set(state => {
        return { ...state, fetching: false };
      });
    }
  },
  fetching: false,
  update: async (noteTemplateId, templateName, data, templateIcon, isMeta): Promise<void> => {
    await updateTemplate(noteTemplateId, templateName, data, templateIcon, isMeta);
    set(state => {
      return {
        ...state,
        templates: state.templates.map(template =>
          template.noteTemplateId === noteTemplateId ? { ...template, isMeta } : template,
        ),
      };
    });
  },
  delete: async (NotetemplateId): Promise<void> => {
    await deleteTemplate(NotetemplateId);
    set(state => {
      return {
        ...state,
        templates: state.templates.filter(template => template.noteTemplateId !== NotetemplateId),
      };
    });
  },
  create: async (templateName, data, templateIcon, createVia, isMeta): Promise<void> => {
    await createTemplate(templateName, data, templateIcon, createVia, isMeta);
    // need to add this refetch cuz from the test in dev env, the templates are not updated from the temp management popup, it works under local testing though
    await get().fetch();
  },
}));

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('templateStore', useTemplateStore);
}

export default useTemplateStore;
