/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useSortable } from '@dnd-kit/sortable';
import DragHandlerIcon from 'components/icons/DragHandlerIcon';
import { isEmpty, keys } from 'lodash';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Editor } from 'slate';
import { ReactEditor } from 'slate-react';
import useNoteEditorStore from 'stores/note-editor';
import { concat } from 'utils/styling';
import CommentRowButton from '../CustomElement/Comment/CommentRowButton';
import NoteEditor from '../NoteEditor';
import PlusIcon from '../icons/PlusIcon';
import { BlockElement, BlockType, COMMENTABLE_VOID_BLOCKS, CustomRenderElementProps } from '../types';
import BasicElement from './BasicElement';
import styles from './SortableElement.module.css';
import SortableElementMenu from './SortableElement/SortableElementMenu';

interface Props extends CustomRenderElementProps {
  editor: Editor;
  readOnly: boolean;
  enableComment: boolean;
  onAddNewBlock?: (action: string) => void;
  asideEdgeWidth?: number;
  scrollContainer?: HTMLDivElement;
}

function SortableElement({
  editor,
  attributes,
  element,
  children,
  blockPlaceholder,
  documentId,
  opportunityId,
  readOnly,
  enableComment,
  onAddNewBlock,
  asideEdgeWidth,
  scrollContainer,
}: Props) {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const menuButtonRef = useRef<HTMLButtonElement>(null);
  const {
    isOver: sortableIsOver,
    setNodeRef: sortableSetNodeRef,
    attributes: sortableAttributes,
    listeners: sortableListeners,
  } = useSortable({ id: element.uuid || '' });
  const { commentContext } = useNoteEditorStore();

  const deleteNode = useCallback(() => {
    const path = ReactEditor.findPath(editor, element);
    NoteEditor.sp_deleteNode(editor, path);
  }, [element]);

  const indentLevel = useMemo(() => element?.indentLevel, [element]);

  const marginLeftString = useMemo(() => {
    return `${(indentLevel ?? 0) * 2.2}rem`;
  }, [indentLevel]);

  const marginTopString = useMemo(() => {
    if (element.type === BlockType.H1) {
      return '1.25rem';
    }
    if (element.type === BlockType.H2) {
      return '0.5rem';
    }
    return '';
  }, [element]);

  const showComments = useMemo(() => {
    if (!enableComment) {
      return false;
    }
    let hideComments = true;
    if (!isEmpty(element.comments)) {
      const path = editor.children.findIndex(node => (node as BlockElement).uuid === element.uuid);
      const lastNodeComments = editor.children[path - 1]?.comments;
      hideComments = keys(element.comments).every(
        commentId =>
          keys(lastNodeComments).includes(commentId) || element.comments?.[commentId]?.[0]?.resolved === true,
      );
    }
    return !hideComments;
  }, [element, editor.children, enableComment]);

  const commentIds = useMemo(() => {
    const comments = element?.comments ?? {};
    return enableComment
      ? keys(comments).filter(commentId => comments[commentId].length && !comments[commentId][0].resolved)
      : [];
  }, [element, enableComment]);

  const commentIdClasses = useMemo(() => commentIds.map(item => `comment-${item}`), [commentIds]);
  const isCommentActive = useMemo(() => {
    return commentIds.some(commentId => !!commentContext?.comments[commentId]);
  }, [commentIds, commentContext?.comments]);
  const commentClasses = useMemo(
    () =>
      COMMENTABLE_VOID_BLOCKS.includes(element.type)
        ? concat(
            styles.comment,
            'comment',
            element.commentInit ? 'comment-init' : '',
            isCommentActive && styles.commentActive,
            styles[`comment-${commentIdClasses.length + (element.commentInit ? 1 : 0)}`],
            ...commentIdClasses,
          )
        : '',
    [element, commentIdClasses, isCommentActive],
  );

  const elementStyle = useMemo(
    () =>
      element.type === BlockType.Table && !showComments
        ? {
            marginRight: -(asideEdgeWidth || 0),
            marginLeft: -((asideEdgeWidth || 0) + 4),
            paddingLeft: (asideEdgeWidth || 0) + 68,
            overflow: 'auto hidden',
          }
        : undefined,
    [element.type, asideEdgeWidth, showComments],
  );

  const elementContentStyle = useMemo(
    () => ({
      marginLeft: marginLeftString,
      width: `calc(100% - ${marginLeftString})`,
    }),
    [marginLeftString],
  );

  const basicElementContent = useMemo(() => {
    return (
      <>
        {children}
        {enableComment &&
          COMMENTABLE_VOID_BLOCKS.includes(element.type) &&
          element.type !== BlockType.Task &&
          element.type !== BlockType.AIWorker &&
          element.type !== BlockType.ProductGap &&
          element.type !== BlockType.TableDialog &&
          element.type !== BlockType.Image &&
          !showComments && (
            <div className={styles['action-btns']} contentEditable={false}>
              <CommentRowButton
                editor={editor}
                comments={element.comments}
                commentForBlock
                darkStyle
                element={element}
              />
            </div>
          )}
      </>
    );
  }, [children, enableComment, showComments, element]);

  return (
    <div
      className={concat(
        styles['sortable-element'],
        sortableIsOver && styles['sortable-element-drag-over'],
        'sortable-element pl-[4rem] pr-[1rem]',
      )}
      ref={sortableSetNodeRef}
      style={elementStyle}
    >
      <div
        id={element.uuid}
        style={elementContentStyle}
        className={concat(styles['sortable-element-wrap'], styles[`sortable-element-${element.type}`])}
      >
        {!readOnly && (
          <div
            className={styles['sortable-menu']}
            style={{ display: isMenuOpen ? 'flex' : undefined, marginTop: marginTopString }}
          >
            <button
              type="button"
              id="SortableElement-Menu-AddNewBlock"
              className={styles['sortable-button']}
              onClick={() => {
                if (onAddNewBlock) {
                  NoteEditor.sp_addNewBlockIfCurrentNodeNotEmpty(editor, element);
                  onAddNewBlock('show-slash-menu');
                } else {
                  NoteEditor.sp_addEmptyBlock(editor, element);
                }
              }}
            >
              <PlusIcon />
            </button>
            <button
              id="SortableElement-Menu-DragHandler"
              ref={menuButtonRef}
              type="button"
              className={styles['sortable-button']}
              {...sortableAttributes}
              {...sortableListeners}
              onClick={e => {
                e.preventDefault();
                setIsMenuOpen(prev => !prev);
              }}
            >
              <DragHandlerIcon />
            </button>
          </div>
        )}
        <div
          className={concat(
            'basic-element-wrap',
            styles['basic-element-wrap'],
            styles[`basic-element-${element.type}`],
            showComments && 'has-comments',
            commentClasses,
          )}
        >
          <BasicElement
            editor={editor}
            attributes={attributes}
            element={element}
            blockPlaceholder={blockPlaceholder}
            documentId={documentId}
            opportunityId={opportunityId}
            readOnly={readOnly}
            scrollContainer={scrollContainer}
          >
            {basicElementContent}
          </BasicElement>
        </div>
        {isMenuOpen && (
          <SortableElementMenu
            isOpen={isMenuOpen}
            onClose={() => setIsMenuOpen(false)}
            anchorEl={menuButtonRef.current}
            deleteElement={deleteNode}
          />
        )}
        {showComments && (
          <CommentRowButton
            editor={editor}
            comments={element.comments}
            commentForBlock={false}
            darkStyle={false}
            element={element}
          />
        )}
      </div>
    </div>
  );
}

SortableElement.defaultProps = {
  onAddNewBlock: undefined,
  asideEdgeWidth: undefined,
  scrollContainer: undefined,
};

export default SortableElement;
