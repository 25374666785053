import React from 'react';
import { ProductFieldAdditionalData, ProductFieldType } from '../../types/product-gap';
import InputField from 'components/Form/InputField';
import NumberField from 'components/Form/NumberField';
import SelectField from 'components/Form/SelectField';
import DatePickerField from 'components/Form/DatePickerField';

interface Props {
  productFieldType: ProductFieldType;
  answer: string;
  additionalData: ProductFieldAdditionalData;
  onChange: (answer: string) => void;
  inputPlaceholder: string;
  selectPlaceholder: string;
  loading: boolean;
}
function ProductFieldAnswerView({
  productFieldType,
  answer,
  additionalData,
  onChange,
  inputPlaceholder,
  selectPlaceholder,
  loading,
}: Props) {
  switch (productFieldType) {
    case ProductFieldType.TEXT:
      return (
        <InputField
          size="small"
          placeholder={inputPlaceholder}
          value={answer}
          onChange={e => onChange(e.target.value)}
          loading={loading}
        />
      );
    case ProductFieldType.NUMBER:
      return (
        <NumberField
          size="small"
          value={answer}
          onChange={e => onChange(e.target.value)}
          placeholder={inputPlaceholder}
          loading={loading}
        />
      );
    case ProductFieldType.SELECT:
      return (
        <SelectField
          size="small"
          placeholder={selectPlaceholder}
          showClearBtn
          value={answer}
          onChange={newV => onChange(newV?.toString() || '')}
          menus={
            additionalData?.options.map(item => ({
              value: item,
              label: item,
            })) || []
          }
          loading={loading}
        />
      );
    case ProductFieldType.DATE:
      return (
        <DatePickerField
          size="small"
          placeholder={selectPlaceholder}
          value={answer}
          onChange={newV => onChange(newV?.toString() || '')}
          loading={loading}
        />
      );
    default:
      return <div>Unknown Field</div>;
  }
}

export default ProductFieldAnswerView;
