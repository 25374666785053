import { Descendant, Text } from 'slate';

/* eslint-disable import/prefer-default-export */
export const requiredInputValidate = {
  validate: (value: string | null | undefined) => {
    return !!(value ?? '').trim();
  },
};

export const dependentInputValidate = (checkValidation: boolean) => {
  return {
    validate: (value: string | null | undefined) => {
      if (checkValidation) {
        return !!(value ?? '').trim();
      }
      return true;
    },
  };
};

export const requiredSlateValidate = {
  validate: (value: Descendant[] | null | undefined) => {
    return (value ?? []).some(node => !Text.isText(node) || !!node.text?.trim());
  },
};
