import { DocumentEventPayload } from 'utils/event';
import { BlockTypeInline, CustomEditor, CustomElement, MentionElement as MentionElementType } from './types';
import { Editor, Element, Node } from 'slate';
import { elementToHtml } from 'utils/convert-element';
import createMention from 'api/documents/create-mention';
import useUserStore from 'stores/user';
import deleteMention from 'api/documents/delete-mention';
import { cloneDeep, groupBy, isEmpty, keys, noop, values } from 'lodash';
import NoteEditor from './NoteEditor';

export interface TrackingMentionItem {
  documentId: string;
  mentionTo: string;
  content: string;
  mentionId: string;
  updatedAt: number;
}

const trackingMentions: { [mentionId: string]: { item: TrackingMentionItem; editor: CustomEditor } } = {};
let trackingTimer: NodeJS.Timeout | null = null;
const trackingInterval = 5000;
const trackingExpiredIn = trackingInterval * 3;

const mentionContentStyle = {
  mention: 'opacity:0.6;',
  a: 'opacity:0.75;text-decoration:underline;',
};

const getMentionContent = (node: CustomElement): string => {
  return `<div style="background-color:rgb(243, 244, 246);padding:9px 0;">${elementToHtml(
    node,
    mentionContentStyle,
    true,
    true,
  ).trim()}</div>`;
};

const handleMentionSchedule = () => {
  // get new block content for all tracking items
  let mentionInfos: { item: TrackingMentionItem; editor: CustomEditor }[] = [];
  try {
    mentionInfos = keys(trackingMentions)
      .map(mentionId => {
        const mentionInfo = {
          item: cloneDeep(trackingMentions[mentionId].item),
          editor: trackingMentions[mentionId].editor,
        };
        const [mentionNodeEntry] = NoteEditor.nodes(mentionInfo.editor, {
          at: [],
          match: n => {
            return (
              Element.isElement(n) &&
              n.type === BlockTypeInline.Mention &&
              (n as MentionElementType).mentionId === mentionInfo.item.mentionId
            );
          },
          mode: 'lowest',
        });
        if (mentionNodeEntry) {
          const parentNode = Node.get(mentionInfo.editor, mentionNodeEntry[1].slice(0, -1));
          const newContent = getMentionContent(parentNode as CustomElement);
          if (newContent !== mentionInfo.item.content) {
            mentionInfo.item.content = newContent;
            mentionInfo.item.updatedAt = Date.now();
            return mentionInfo;
          }
        }
        return undefined;
      })
      .filter(item => !!item) as { item: TrackingMentionItem; editor: CustomEditor }[];
  } catch {
    noop();
  }

  // send api for all updated mention blocks
  values(groupBy(mentionInfos, item => `${item.item.documentId}:${item.item.content}`)).forEach(mentionGroups => {
    createMention({
      documentId: mentionGroups[0].item.documentId,
      mentionTos: mentionGroups.map(item => item.item.mentionTo),
      content: mentionGroups[0].item.content,
      mentionIds: mentionGroups.map(item => item.item.mentionId),
    });
  });
  // update trackingMentions
  mentionInfos.forEach(mentionInfo => {
    trackingMentions[mentionInfo.item.mentionId] = mentionInfo;
  });
  // remove expired tracking mentions
  const now = Date.now();
  keys(trackingMentions).forEach(mentionId => {
    const diff = now - trackingMentions[mentionId].item.updatedAt;
    if (diff > trackingExpiredIn) {
      delete trackingMentions[mentionId];
    }
  });
  // clear timer if there is no tracking mentions
  if (isEmpty(trackingMentions) && trackingTimer) {
    clearInterval(trackingTimer);
    trackingTimer = null;
  }
};

const filterMentions = (nodes: CustomElement[] | undefined | null, raisedByMe: boolean): MentionElementType[] => {
  return (nodes?.filter(item => {
    const node = item as MentionElementType;
    return (
      node.type === BlockTypeInline.Mention &&
      !!node.mentionUserId &&
      !!node.mentionByUserId &&
      (!raisedByMe || node.mentionByUserId === useUserStore.getState().user?.id)
    );
  }) ?? []) as MentionElementType[];
};

const handleMentionEvent = (event: DocumentEventPayload, editor: CustomEditor) => {
  if (event.type === 'MENTION_CREATE') {
    if (event.docSource === 'main-doc-comment' && event.commentContent) {
      const mentions = filterMentions(event.commentContent as CustomElement[], true);
      if (mentions.length) {
        createMention({
          documentId: event.documentId,
          mentionTos: mentions.map(item => item.mentionUserId),
          content: getMentionContent({ children: event.commentContent } as CustomElement),
          mentionIds: mentions.map(item => item.mentionId),
        });
      }
    }
    // if mention in main doc, we will schedule a timer to track mention's block content update
    if (event.docSource === 'main-doc' && event.sourceElement && !(event.sourceElement instanceof Array)) {
      const mentions = filterMentions([event.sourceElement], true);
      if (mentions.length) {
        mentions.forEach(mention => {
          trackingMentions[mention.mentionId] = {
            item: {
              documentId: event.documentId,
              mentionTo: mention.mentionUserId,
              content: '',
              mentionId: mention.mentionId,
              updatedAt: Date.now(),
            },
            editor,
          };
        });
        setTimeout(() => {
          handleMentionSchedule();
        });
        if (!trackingTimer) {
          trackingTimer = setInterval(handleMentionSchedule, trackingInterval);
        } else {
          clearInterval(trackingTimer);
          trackingTimer = setInterval(handleMentionSchedule, trackingInterval);
        }
      }
    }
  }
  if (event.type === 'MENTION_DELETE' && event.sourceElement instanceof Array) {
    const mentions = filterMentions(event.sourceElement, false);
    if (mentions.length) {
      mentions.forEach(mention => {
        if (trackingMentions[mention.mentionId]) {
          delete trackingMentions[mention.mentionId];
        }
      });
      // clear mention track timer if no mention in schedule
      if (isEmpty(trackingMentions) && trackingTimer) {
        clearInterval(trackingTimer);
        trackingTimer = null;
      }
      deleteMention(mentions.map(item => item.mentionId));
    }
  }
};
export default handleMentionEvent;
