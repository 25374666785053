import React, { useEffect, useState } from 'react';
import fetchTeams, { Team } from 'admin-src/api/fetch-teams';
import SpinLoader from 'components/icons/SpinLoader';
import { useNavigate } from 'react-router-dom';

interface State {
  isLoading: boolean;
  teams: Team[];
}

function AdminTeam() {
  const [state, setState] = useState<State>({
    isLoading: true,
    teams: [],
  });
  const navigate = useNavigate();

  useEffect(() => {
    const getTeams = async () => {
      setState(prevState => ({ ...prevState, isLoading: true, isAuthorized: true }));
      const teams = await fetchTeams();
      setState(prevState => ({ ...prevState, teams, isLoading: false }));
    };

    getTeams();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (state.isLoading) {
    return <SpinLoader className="w-4 h-4 animate-spin" />;
  }

  const teamRows = state.teams.map(team => {
    return (
      <tr
        key={team.id}
        className="hover:bg-gray-100 hover:cursor-pointer"
        onClick={() => {
          navigate(`/admin/teams/${team.id}`);
        }}
      >
        <td className="  text-gray-500 font-medium">{team.id}</td>
        <td className="  text-gray-500 font-medium">{team.adminUser}</td>
        <td className="  text-black font-medium">{team.crmProvider}</td>
        <td className="  text-black font-medium">{team.enableSharedCRM}</td>
      </tr>
    );
  });

  const teamTable: JSX.Element = (
    <table className="w-full" cellSpacing={0}>
      <thead>
        <tr className="text-gray-600 font-semibold bg-gray-100">
          <td className="w-[20%]  ">id</td>
          <td className="w-[20%]  ">admin</td>
          <td className="w-[20%]   text-gray-600">crm provider</td>
          <td className="w-[20%] ">shared crm integration among team</td>
        </tr>
      </thead>
      <tbody className="">{teamRows}</tbody>
    </table>
  );

  return (
    <div className="felx m-4">
      <div className="text-2xl">Team</div>
      <div className="flex">{teamTable}</div>
    </div>
  );
}

export default AdminTeam;
