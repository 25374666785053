import { SuggestedAnswer } from 'types/field-value';
import httpClient from '../http-client';

interface Response {
  isAiProcessing: boolean;
  suggestedAnswers: SuggestedAnswer[];
  valueAiSuggestion: string | null;
  acceptanceStatusAiSuggestion: 'accepted' | 'rejected' | null;
}

export default async (fieldValueId: string): Promise<Response> => {
  const { data } = await httpClient.get<Response>(`/field-values/${fieldValueId}/ai-status`);
  return data;
};
