import React from 'react';

interface Props {
  className?: string;
}

function PlayIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.25 20C16.5302 20 20 16.5302 20 12.25C20 7.96979 16.5302 4.5 12.25 4.5C7.96979 4.5 4.5 7.96979 4.5 12.25C4.5 16.5302 7.96979 20 12.25 20Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7002 9.15039L15.3502 12.2504L10.7002 15.3504V9.15039Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

PlayIcon.defaultProps = {
  className: undefined,
};
export default PlayIcon;
