import React from 'react';

interface Props {
  className?: string;
}

function EraserIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6893 7.59377L6.38462 3.28911M12.5341 12.5134H4.53976M6.34604 11.937L11.6768 6.60623C12.4074 5.87563 12.7727 5.51034 12.9096 5.0891C13.03 4.71858 13.03 4.31945 12.9096 3.94892C12.7727 3.52769 12.4074 3.16239 11.6768 2.4318L11.5466 2.30157C10.816 1.57097 10.4507 1.20568 10.0295 1.06881C9.65894 0.948418 9.25981 0.948418 8.88928 1.06881C8.46805 1.20568 8.10275 1.57097 7.37216 2.30157L2.32232 7.35141C1.59172 8.082 1.22643 8.4473 1.08956 8.86853C0.96917 9.23906 0.96917 9.63819 1.08956 10.0087C1.22643 10.4299 1.59172 10.7952 2.32232 11.5258L2.73349 11.937C2.94621 12.1497 3.05256 12.2561 3.17668 12.3321C3.28672 12.3996 3.40669 12.4493 3.53219 12.4794C3.67373 12.5134 3.82415 12.5134 4.12497 12.5134H4.95456C5.25538 12.5134 5.40579 12.5134 5.54734 12.4794C5.67283 12.4493 5.7928 12.3996 5.90285 12.3321C6.02696 12.2561 6.13332 12.1497 6.34604 11.937Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

EraserIcon.defaultProps = {
  className: undefined,
};

export default EraserIcon;
