import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/24/outline';

import SalesforceIcon from 'components/icons/SalesforceIcon';

import useUserStore from 'stores/user';

import getOauthLink from 'utils/get-oauth-link';
import useUser from 'hooks/userUser';

// interface State {
//   isLoading: boolean;
// }

function CrmIntegrationTeam() {
  //   const [state, setState] = useState<State>({
  //     isLoading: false,
  //   });

  // refresh user
  useUser(true);
  const navigate = useNavigate();
  const userStore = useUserStore();
  const skip = async () => {
    navigate('/');
  };

  return (
    <div className="h-full w-full">
      <div className="flex flex-col items-center m-20">
        <div className="flex flex-col gap-6 w-half min-w-[20rem] max-w-[50rem]">
          {' '}
          <p className="text-gray-500">
            You are setting up CRM integration for your team, your CRM token will be shared across your team member if
            they do not have it integrated. You should not set this up unless a Superpanel support person is with you.
          </p>
          {userStore.user?.hasNoCRMElement ? (
            <a
              className="flex items-center justify-between py-3 px-2 border rounded"
              href={getOauthLink('salesforce', 'team')}
            >
              <div className="flex items-center gap-4">
                <SalesforceIcon className="w-12 h-12" />
                <div className="flex flex-col gap-1">
                  <div className="text-lg font-semibold">Salesforce</div>
                  <div className="text-sm text-gray-400">Connect with Salesforce and share across your team</div>
                </div>
              </div>
              <ChevronRightIcon className="h-7 w-7 stroke-2" />
            </a>
          ) : (
            <div className="flex items-center justify-between py-3 px-2 border rounded">
              You are not allowed to use shared CRM account for your team, please contact us if you need it.
            </div>
          )}
          <div className="flex justify-end">
            <button className="p-3 text-gray-400 rounded hover:bg-gray-50" onClick={() => skip()} type="button">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CrmIntegrationTeam;
