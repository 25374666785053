import React from 'react';

interface Props {
  className?: string;
}

function Superpanel({ className }: Props) {
  return (
    <svg
      className={className}
      width="32"
      height="44"
      viewBox="0 0 32 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Superpanel Logo</title>
      <circle cx="23.6851" cy="7.69326" r="7.69326" fill="#FFC74D" />
      <circle cx="8.56826" cy="36.3067" r="7.69326" fill="#1DBC86" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8299 0.00616455C6.63863 0.22133 0.875 6.11861 0.875 13.3621C0.875 20.6055 6.63863 26.5028 13.8299 26.718V0.00616455Z"
        fill="#2F80ED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4227 17.2762C25.614 17.4913 31.3777 23.3886 31.3777 30.6321C31.3777 37.8756 25.614 43.7728 18.4227 43.988V17.2762Z"
        fill="#96B7F5"
      />
    </svg>
  );
}

Superpanel.defaultProps = {
  className: undefined,
};

export default Superpanel;
