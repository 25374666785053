import React from 'react';

interface Props {
  className?: string;
}

function EmptyResponseIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="170"
      height="131"
      viewBox="0 0 170 131"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="85.002" cy="69.002" r="61.791" fill="#F7F9FC" />
      <circle cx="20.1244" cy="22.6586" r="6.1791" fill="#F5F7FC" />
      <circle cx="152.97" cy="104.532" r="4.63433" fill="#F5F7FC" />
      <circle cx="19.3489" cy="113.801" r="7.72388" fill="#F5F7FC" />
      <circle cx="162.24" cy="36.5618" r="7.72388" fill="#F5F7FC" />
      <circle cx="147.563" cy="15.7073" r="5.40672" fill="#F5F7FC" />
      <g clipPath="url(#clip0_11210_228418)">
        <path
          d="M128.35 21.1401H39.7426C38.5471 21.1409 37.4009 21.6161 36.5556 22.4614C35.7103 23.3067 35.2351 24.4529 35.2344 25.6483V106.946C35.2351 108.142 35.7103 109.288 36.5556 110.133C37.4009 110.979 38.5471 111.454 39.7426 111.455H128.35C129.545 111.454 130.692 110.979 131.537 110.133C132.382 109.288 132.858 108.142 132.858 106.946V25.6483C132.858 24.4528 132.382 23.3065 131.537 22.4612C130.692 21.6159 129.545 21.1408 128.35 21.1401Z"
          fill="url(#paint0_linear_11210_228418)"
        />
        <path d="M61.9531 44.7312L76.5293 59.3078H61.9531V44.7312Z" fill="url(#paint1_linear_11210_228418)" />
        <path
          d="M139.692 63.5453L133.407 108.037C133.093 110.261 131.189 111.914 128.943 111.914H39.1511C36.9049 111.914 35.0013 110.261 34.6871 108.037L26.1935 47.918C25.8104 45.204 27.9165 42.7791 30.6575 42.7791H58.3872C60.6333 42.7791 62.5369 44.4321 62.8511 46.656L63.9642 54.529C64.2784 56.7533 66.182 58.4064 68.4282 58.4064H135.229C137.969 58.4064 140.076 60.8313 139.693 63.5453H139.692Z"
          fill="url(#paint2_linear_11210_228418)"
        />
        <rect
          x="90.4141"
          y="83.4524"
          width="9.5123"
          height="2.50324"
          transform="rotate(-45 90.4141 83.4524)"
          fill="#AFB9CC"
        />
        <rect
          width="9.5123"
          height="2.50324"
          transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 98.9141 83.4524)"
          fill="#AFB9CC"
        />
        <rect
          x="67.2422"
          y="83.4524"
          width="9.5123"
          height="2.50324"
          transform="rotate(-45 67.2422 83.4524)"
          fill="#AFB9CC"
        />
        <rect
          width="9.5123"
          height="2.50324"
          transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 75.7422 83.4524)"
          fill="#AFB9CC"
        />
        <rect x="79.1562" y="93.2781" width="8.26101" height="1.37684" fill="#AFB9CC" />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_11210_228418"
          x1="60.5687"
          y1="80.1975"
          x2="42.6347"
          y2="-26.3294"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0F4FD" />
          <stop offset="1" stopColor="#A9B8D8" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_11210_228418"
          x1="61.5013"
          y1="67.4968"
          x2="61.0895"
          y2="50.4413"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ADC6F8" />
          <stop offset="1" stopColor="#DFE5F0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_11210_228418"
          x1="55.6259"
          y1="87.9872"
          x2="30.1129"
          y2="156.153"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0F4FD" />
          <stop offset="1" stopColor="#A9B8D8" />
        </linearGradient>
        <clipPath id="clip0_11210_228418">
          <rect width="113.589" height="113.589" fill="white" transform="translate(26.1484 0.34082)" />
        </clipPath>
      </defs>
    </svg>
  );
}

EmptyResponseIcon.defaultProps = {
  className: undefined,
};

export default EmptyResponseIcon;
