/* eslint-disable no-else-return, no-param-reassign */
import React, { useEffect, useMemo, useState } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';

import { ColumnConfig, ColumnDefinition, GroupColumnDefinition } from './types';
import SortableColumnConfig from './CustomizeFieldMenu/SortableColumnConfig';
import InputField from 'components/Form/InputField';
import styles from './CustomizeFieldMenu.module.css';
import ReorderableList from 'components/ReorderableList';
import { findTableColIndex, getTableColUniqueId, swapTableColPostion } from './utils';

interface FlattenColumnConfig {
  id: string;
  groupName: string;
  groupId: string;
  col: ColumnConfig;
  expanded: boolean;
}

interface Props {
  columnConfigs: ColumnConfig[];
  updateColumnConfigs: (newColumnConfigs: ColumnConfig[]) => void;
}
function CustomizeFieldMenu({ columnConfigs, updateColumnConfigs }: Props) {
  const [searchValue, setSearchValue] = useState<string>('');
  const [localColumnConfigs, setLocalColumnConfigs] = useState<FlattenColumnConfig[]>([]);

  useEffect(() => {
    setLocalColumnConfigs(prev => {
      const cols: FlattenColumnConfig[] = [];
      const expandedCols: GroupColumnDefinition[] = [];
      prev.forEach(colDef => {
        if ('group' in colDef.col && colDef.expanded) {
          expandedCols.push(colDef.col);
        }
      });
      columnConfigs.forEach(col => {
        if ('group' in col) {
          const expanded = expandedCols.some(
            expandedCol =>
              expandedCol.id === col.id &&
              (col.columns.every(subCol => expandedCol.columns.find(item => item.id === subCol.id)) ||
                expandedCol.columns.every(subCol => col.columns.find(item => item.id === subCol.id))),
          );
          if (col.columns.some(item => item.name.toLowerCase().includes(searchValue.trim().toLowerCase()))) {
            cols.push({ groupName: '', groupId: '', id: getTableColUniqueId(col), col, expanded });
            col.columns.forEach(subCol => {
              if (subCol.name.toLowerCase().includes(searchValue.trim().toLowerCase())) {
                cols.push({
                  groupName: col.group,
                  groupId: col.id,
                  id: getTableColUniqueId(subCol),
                  col: subCol,
                  expanded,
                });
              }
            });
          }
        } else if (col.name.toLowerCase().includes(searchValue.trim().toLowerCase())) {
          cols.push({ groupName: '', groupId: '', id: getTableColUniqueId(col), col, expanded: true });
        }
      });
      return cols;
    });
  }, [columnConfigs, searchValue]);

  const allColumnsHide = useMemo(() => {
    const search = searchValue.trim().toLowerCase();
    return columnConfigs.every(columnConfig => {
      if ('group' in columnConfig) {
        return !columnConfig.columns.some(col => col.name.toLowerCase().includes(search));
      } else {
        return !columnConfig.name.toLowerCase().includes(search);
      }
    });
  }, [searchValue.trim().toLowerCase(), columnConfigs]);

  return (
    <div className={styles.container}>
      <div className={styles.searchInputWrap}>
        <InputField
          InputProps={{
            startAdornment: <MagnifyingGlassIcon className="w-4 h-4 text-gray-400 mx-2" />,
          }}
          size="small"
          placeholder="Search for a column"
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
        />
      </div>
      <div className="max-h-[40rem]">
        {allColumnsHide && <span className="block text-center text-gray-400 w-full py-2">No Columns Found</span>}

        <div className="flex flex-col pb-4">
          <ReorderableList<FlattenColumnConfig>
            list={localColumnConfigs}
            listItemClassName={item => (item.groupName ? 'pl-9' : '')}
            dragHandlerStaticPos
            useListItemId={false}
            filter={item => 'group' in item.col || item.expanded}
            renderListItem={columnConfig => (
              <SortableColumnConfig
                expanded={columnConfig.expanded}
                toggleExpanded={() => {
                  const expanded = !columnConfig.expanded;
                  columnConfig.expanded = !columnConfig.expanded;
                  (columnConfig.col as GroupColumnDefinition).columns?.forEach(subCol => {
                    const groupSubCol = localColumnConfigs.find(colDef => colDef.col === subCol);
                    if (groupSubCol) {
                      groupSubCol.expanded = expanded;
                    }
                  });
                  setLocalColumnConfigs([...localColumnConfigs]);
                }}
                columnConfig={columnConfig.col}
                updateColumnConfig={newColumnConfig => {
                  const [colIndex, subColIndex] = findTableColIndex(
                    columnConfigs,
                    getTableColUniqueId(newColumnConfig),
                    columnConfig.groupId,
                  );
                  if (colIndex >= 0) {
                    const newColumnConfigs = [...columnConfigs];
                    if (subColIndex >= 0) {
                      (newColumnConfigs[colIndex] as GroupColumnDefinition).columns = [
                        ...(newColumnConfigs[colIndex] as GroupColumnDefinition).columns,
                      ];
                      (newColumnConfigs[colIndex] as GroupColumnDefinition).columns[subColIndex] =
                        newColumnConfig as ColumnDefinition;
                    } else {
                      newColumnConfigs[colIndex] = newColumnConfig;
                    }
                    updateColumnConfigs(newColumnConfigs);
                  }
                }}
              />
            )}
            handleReorder={({ fromIndex, toIndex }) => {
              const filteredLocalColumnConfigs = localColumnConfigs.filter(
                item => 'group' in item.col || item.expanded,
              );
              const fromCol = filteredLocalColumnConfigs[fromIndex];
              const toCol = filteredLocalColumnConfigs[toIndex];
              const [fromColIndex, fromSubColIndex] = findTableColIndex(
                columnConfigs,
                getTableColUniqueId(fromCol.col),
                fromCol.groupId,
              );
              const [toColIndex, toSubColIndex] = findTableColIndex(
                columnConfigs,
                getTableColUniqueId(toCol.col),
                toCol.groupId,
              );
              const newCols = swapTableColPostion(
                columnConfigs,
                fromColIndex,
                fromSubColIndex >= 0 ? fromSubColIndex : undefined,
                toColIndex,
                toSubColIndex >= 0 ? toSubColIndex : undefined,
              );
              if (newCols) {
                updateColumnConfigs(newCols);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default CustomizeFieldMenu;
