/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useImperativeHandle, useMemo } from 'react';
import * as XLSX from 'xlsx';
import { Category, ColumnConfig, RowData } from './Table/types';
import CategorizedTable from './Table/CategorizedTable';
import { filterTableRows, getValue, sortData } from './Table/utils';
import SimpleTable from './Table/SimpleTable';

export type TableRef = {
  exportData: (name: string) => void;
};

interface Props {
  columnConfigs: ColumnConfig[];
  setColumnConfigs: (columnDefinitions: ColumnConfig[]) => void;
  rows: RowData[];
  category: Category | null;
  showSequence?: boolean;
  sequenceOffset?: number;
  notFilterData?: boolean;
}

const Table = React.forwardRef<TableRef, Props>(
  ({ columnConfigs, setColumnConfigs, rows, category, showSequence, sequenceOffset, notFilterData }: Props, ref) => {
    // Applying Filtering in the data
    const filteredSortedRows = useMemo(() => {
      const filteredRows = notFilterData ? rows : filterTableRows(rows, columnConfigs);

      // Applying Sort in the data
      columnConfigs
        .slice()
        .reverse()
        .forEach(columnConfig => {
          if ('group' in columnConfig) {
            columnConfig.columns
              .slice()
              .reverse()
              .forEach(column => {
                if (column.sort) {
                  filteredRows.sort(sortData(column.id, column.type, column.sort, columnConfig.id));
                }
              });
          } else if (columnConfig.sort) {
            filteredRows.sort(sortData(columnConfig.id, columnConfig.type, columnConfig.sort, null));
          }
        });
      return filteredRows;
    }, [rows, columnConfigs]);

    useImperativeHandle(ref, () => ({
      exportData: (name: string) => {
        const workbook = XLSX.utils.book_new();

        const excelRows: { [colName: string]: any }[] = [];
        filteredSortedRows.forEach(row => {
          const newRow: { [colName: string]: any } = {};
          columnConfigs.forEach(col => {
            if ('group' in col) {
              const groupData = row[col.id] || {};
              col.columns.forEach(groupCol => {
                newRow[`${col.group} - ${groupCol.name}`] = getValue(groupCol.type, groupData[groupCol.id]);
              });
              return;
            }
            newRow[`${col.name}`] = getValue(col.type, row[col.id]);
          });
          excelRows.push(newRow);
        });

        const worksheet = XLSX.utils.json_to_sheet(excelRows);
        XLSX.utils.book_append_sheet(workbook, worksheet, name);
        XLSX.writeFile(workbook, `${name}-${new Date().toISOString().split('T')[0]}.xlsx`);
      },
    }));
    if (category) {
      return (
        <CategorizedTable
          columnConfigs={columnConfigs}
          setColumnConfigs={setColumnConfigs}
          rows={filteredSortedRows}
          category={category}
          showSequence={showSequence}
          sequenceOffset={sequenceOffset}
        />
      );
    }

    return (
      <SimpleTable
        columnConfigs={columnConfigs}
        setColumnConfigs={setColumnConfigs}
        rows={filteredSortedRows}
        showSequence={showSequence}
        sequenceOffset={sequenceOffset}
      />
    );
  },
);

Table.displayName = 'Table';
Table.defaultProps = {
  showSequence: false,
  sequenceOffset: 0,
  notFilterData: undefined,
};
export default Table;
