import httpClient from 'api/http-client';

export interface Team {
  id: string;
  enableSharedCRM: boolean;
  crmProvider: string | null;
  adminUser: string;
}

export default async (): Promise<Team[]> => {
  const { data } = await httpClient.get<Team[]>('/admin/teams');
  return data;
};
