import React from 'react';
import { MenuItem } from '@mui/material';
import useReportingStore from 'stores/reporting';
import EditIcon from '../../../icons/EditIcon';
import useModalStore from '../../../../stores/modal';
import EditAnalyticColumnModal from './EditAnalyticColumnModal';
import { AnalyticColumnSubHeader } from '../../../../types/reporting';

interface Props {
  analyticColumnId: string;
  columnName: string;
  setMenuState: (menuState: 'MENU' | 'FILTER' | 'HIDE') => void;
}

function EditAnalyticColumnMenu({ analyticColumnId, columnName, setMenuState }: Props) {
  const modalStore = useModalStore();
  const reportingStore = useReportingStore();

  let analyticColumn: AnalyticColumnSubHeader | undefined;
  reportingStore.tableColumns.forEach(tc => {
    if (tc.type === 'field') {
      tc.columns.forEach(c => {
        if (c.id === analyticColumnId) {
          analyticColumn = c;
        }
      });
    }
  });

  if (analyticColumn === undefined) {
    return null;
  }

  return (
    <MenuItem
      onClick={async () => {
        setMenuState('HIDE');
        modalStore.setState({
          isOpen: true,
          element: <EditAnalyticColumnModal analyticColumnSubHeader={analyticColumn as AnalyticColumnSubHeader} />,
        });
      }}
    >
      <div className="flex items-center text-sm">
        <EditIcon className="w-4 h-4 mr-2" /> Edit column
      </div>
    </MenuItem>
  );
}

export default EditAnalyticColumnMenu;
