import React, { useState } from 'react';
import useUserStore from '../../stores/user';
import BaseButton from '../../components/BaseButton';
import { Checkbox, Select } from '@mui/material';
import usePopupMessageStore from '../../stores/popup-message';
import MenuItem from '@mui/material/MenuItem';
import { UserRole } from '../../types/user';

function UserSettingView() {
  const userStore = useUserStore();
  const popupMessageStore = usePopupMessageStore();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEdited, setIsEdited] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>(userStore.user?.firstName || '');
  const [lastName, setLastName] = useState<string>(userStore.user?.lastName || '');
  const [role, setRole] = useState<UserRole>(userStore.user?.role || 'Other');
  const [insertLinkInCalendar, setInsertLinkInCalendar] = useState<boolean>(
    userStore.user?.insertLinkInCalendar || false,
  );

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    await userStore.updateUser({
      firstName,
      lastName,
      insertLinkInCalendar,
      role,
    });
    popupMessageStore.success('User settings has been updated');
    setIsEdited(false);
    setIsLoading(false);
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="flex justify-between">
        <div>
          <div className="text-xl font-medium">User Settings</div>
          <div className="py-2" />
          <div className="text-sm text-slate-500">Add and manage information about yourself here.</div>
        </div>
        <div>
          <BaseButton
            type="submit"
            variant="contained"
            className="!py-5 !px-6"
            disabled={!isEdited}
            loading={isLoading}
          >
            Save
          </BaseButton>
        </div>
      </div>
      <div className="py-5" />
      <div className="flex items-center gap-10">
        <div className="w-full">
          <div className="text-slate-500 text-sm font-medium py-2">First Name</div>
          <input
            className="w-full border rounded p-3 shadow focus:ring-2 focus:ring-inset focus:ring-indigo-600"
            value={firstName}
            onChange={e => {
              setFirstName(e.target.value);
              setIsEdited(true);
            }}
          />
        </div>
        <div className="w-full">
          <div className="text-slate-500 text-sm font-medium py-2">Last Name</div>
          <input
            className="w-full border rounded p-3 shadow focus:ring-2 focus:ring-inset focus:ring-indigo-600"
            value={lastName}
            onChange={e => {
              setLastName(e.target.value);
              setIsEdited(true);
            }}
          />
        </div>
      </div>
      <div className="py-5" />
      <div className="flex items-center gap-10">
        <div className="w-full">
          <div className="text-slate-500 text-sm font-medium py-2">Email Address</div>
          <input
            className="w-full border rounded p-3 shadow disabled:cursor-not-allowed disabled:bg-slate-200 disabled:text-slate-500"
            value={userStore.user?.email || ''}
            disabled
          />
        </div>
        <div className="w-full">
          <div className="text-slate-500 text-sm font-medium py-2">Role</div>
          <Select
            className="w-full py-1"
            size="small"
            value={role}
            onChange={e => {
              setRole(e.target.value as UserRole);
              setIsEdited(true);
            }}
          >
            <MenuItem value="Sales Engineer Manager">Sales Engineer Manager</MenuItem>
            <MenuItem value="Sales Engineer">Sales Engineer</MenuItem>
            <MenuItem value="Account Executive">Account Executive</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
          </Select>
        </div>
      </div>
      <div className="py-5" />
      <div>
        <div className="text-xl font-medium">Additional Settings</div>
        <div className="flex items-center">
          <Checkbox
            size="small"
            onChange={e => {
              setInsertLinkInCalendar(e.target.checked);
              setIsEdited(true);
            }}
            checked={insertLinkInCalendar}
          />
          <div className="text-sm text-slate-500">
            Allow Superpanel to include a convenient link in your calendar events.
          </div>
        </div>
      </div>
    </form>
  );
}

export default UserSettingView;
