import { Popper } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Transforms } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';

import { BlockType, DiscoveryQuestionElement } from 'components/NoteEditor/types';
import SpinLoader from 'components/icons/SpinLoader';

import { Field } from 'types/field-value';
import NoteEditor from 'components/NoteEditor/NoteEditor';
import FieldPicker from 'components/FieldPicker';
import getFields from 'api/fields/get-fields';

interface State {
  isLoading: boolean;
  inputValue: string;
  isDropdownOpen: boolean;
  fields: Field[];
}

interface Props {
  element: DiscoveryQuestionElement;
}

function AddDiscoveryQuestionTemplate({ element }: Props) {
  const [state, setState] = useState<State>({
    isLoading: true,
    inputValue: '',
    isDropdownOpen: false,
    fields: [],
  });

  const ref = useRef<HTMLInputElement>(null);

  const editor = useSlate();
  const path = ReactEditor.findPath(editor, element);
  const fieldPickerRef = useRef<React.ElementRef<typeof FieldPicker>>(null);

  useEffect(() => {
    // Implementing hacky way to autofocus on the input element inside slate
    // only auto popup dropdown when create discovery question element
    if (ref.current && element.discoveryQuestionId === '') {
      setTimeout(() => {
        if (ref.current) {
          ReactEditor.focus(editor);
          ref.current.focus();
          setState(prevState => ({ ...prevState, isDropdownOpen: true }));
        }
      }, 200);
    }
    // set discoveryQuestionId to null which means it finished creating
    Transforms.setNodes(editor, { discoveryQuestionId: null }, { at: path });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  useEffect(() => {
    const fetch = async () => {
      setState(prevState => ({ ...prevState, isLoading: true }));
      const { accountFields, opportunityFields } = await getFields();
      setState(prevState => ({
        ...prevState,
        isLoading: false,
        fields: accountFields.concat(opportunityFields),
      }));
    };
    fetch();
  }, []);

  const addFields = async (newFields: Field[]) => {
    ref.current?.blur();
    const usedFieldIds = NoteEditor.sp_getQuestionIds(editor);
    newFields.forEach((field, idx) => {
      const isAlreadyInDoc = usedFieldIds.includes(field.id);
      if (!isAlreadyInDoc) {
        if (idx === 0) {
          Transforms.setNodes(editor, { discoveryQuestionId: field.id }, { at: path });
        } else {
          Transforms.insertNodes(editor, {
            type: BlockType.DiscoveryQuestion,
            discoveryQuestionId: field.id,
            children: [{ text: '' }],
          });
        }
      }
    });
  };

  return (
    <div>
      <div className="relative">
        <input
          data-lpignore="true"
          ref={ref}
          className="w-full rounded bg-slate-100 px-3 py-4"
          value={state.inputValue}
          onChange={event => setState(prevState => ({ ...prevState, inputValue: event.target.value }))}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              // add checked fields first
              const added = fieldPickerRef.current?.addCheckedFields();
              if (added) {
                return;
              }
              // get all displayed filtered out and not added fields
              const discoveryQuestions = fieldPickerRef.current?.getDisplayedNotAddedFields();
              if (discoveryQuestions?.length) {
                if (discoveryQuestions.length === 1) {
                  // if just one field filtered out, add it
                  addFields(discoveryQuestions);
                  return;
                }
                // if have one field having exact name with input string, add it
                const exactNameDiscoveryQuestion = discoveryQuestions.find(
                  field => field.label?.toLowerCase().trim() === state.inputValue.toLowerCase().trim(),
                );
                if (exactNameDiscoveryQuestion) {
                  addFields([exactNameDiscoveryQuestion]);
                  return;
                }
              }
              // if user gives nothing then remove the node
              if (!state.inputValue.trim()) {
                NoteEditor.sp_deleteNode(editor, path);
              }
            } else if (event.key === 'Escape') {
              NoteEditor.sp_deleteNode(editor, path);
            }
          }}
          onFocus={() => setState(prevState => ({ ...prevState, isDropdownOpen: true }))}
          onBlur={() => {
            setState(prevState => ({ ...prevState, isDropdownOpen: false }));
          }}
          placeholder="Search discovery field by start typing..."
        />
        {state.isLoading && (
          <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center z-10 backdrop-blur-sm">
            <SpinLoader className="animate-spin w-5 h-5 mr-2 text-orange-500" />
          </div>
        )}
      </div>
      <Popper anchorEl={ref.current} open={state.isDropdownOpen} style={{ zIndex: 1400 }} className="!my-1">
        <div style={{ width: `${ref.current?.getBoundingClientRect().width}px` }}>
          <FieldPicker
            inputValue={state.inputValue}
            addSearchField={false}
            fields={state.fields}
            usedFieldIds={NoteEditor.sp_getQuestionIds(editor)}
            onAddFields={addFields}
            ref={fieldPickerRef}
          />
        </div>
      </Popper>
    </div>
  );
}

export default AddDiscoveryQuestionTemplate;
