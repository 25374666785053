import React, { useMemo, useRef, useState } from 'react';

import { ColumnDefinition } from 'components/Table/types';
import { XMarkIcon } from '@heroicons/react/24/outline';
import FieldFilterPopover from './FieldFilterPopover';
import BaseButton from 'components/BaseButton';
import { Tooltip } from '@mui/material';
import { stringifyFilter } from './utils';
import FilterIcon from 'components/icons/FilterIcon';
import { concat } from 'utils/styling';

interface Props {
  columnDefinition: ColumnDefinition;
  updateColumnDefinition: (newColumnDefinition: ColumnDefinition) => void;
  btnClassName?: string;
}

function FieldFilterButton({ columnDefinition, updateColumnDefinition, btnClassName }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);
  const filterStr = useMemo(
    () => stringifyFilter(columnDefinition.filter, columnDefinition.data?.dataEntryType || columnDefinition.type),
    [columnDefinition.filter, columnDefinition.data?.dataEntryType],
  );

  return (
    <div className="flex flex-nowrap items-center justify-between py-[1px] truncate">
      <Tooltip
        title={
          filterStr ? (
            <div>
              <strong>Filter:&nbsp;</strong>
              {filterStr}
            </div>
          ) : undefined
        }
        placement="top"
      >
        <button
          ref={ref}
          type="button"
          className={concat(
            'flex items-center justify-between text-left pl-1 pr-0.5 flex-1 hover:bg-gray-100 rounded truncate',
            btnClassName,
          )}
          onClick={() => setIsOpen(true)}
        >
          <span className="py-1 truncate">{columnDefinition.name}</span>
          {!!columnDefinition.filter && (
            <div className="flex items-center truncate h-full">
              <FilterIcon className="w-4 h-4 text-green-500 flex-shrink-0 flex-grow-0 ml-1" />
              <span className="truncate text-xs text-gray-400 whitespace-nowrap">{filterStr}</span>
            </div>
          )}
        </button>
      </Tooltip>

      {!!columnDefinition.filter && (
        <BaseButton
          tooltip="Clear filter"
          color="secondary"
          variant="contained"
          iconBtn
          onClick={e => {
            e.stopPropagation();
            updateColumnDefinition({ ...columnDefinition, filter: undefined });
          }}
        >
          <XMarkIcon width={16} />
        </BaseButton>
      )}
      <FieldFilterPopover
        columnDataType={columnDefinition.type}
        name={columnDefinition.name}
        filter={columnDefinition.filter}
        onFilterUpdate={filter => {
          updateColumnDefinition({ ...columnDefinition, filter });
        }}
        onClose={() => setIsOpen(false)}
        data={columnDefinition.data}
        anchorEl={ref.current}
        isOpen={isOpen}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      />
    </div>
  );
}

FieldFilterButton.defaultProps = {
  btnClassName: '',
};

export default FieldFilterButton;
