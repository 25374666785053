import React, { ReactElement } from 'react';

import { Fade } from '@mui/material';

interface Props {
  children: ReactElement | null;
  isOpen: boolean;
}

function FadeIn({ children, isOpen }: Props) {
  return (
    <Fade in={isOpen} timeout={400}>
      {children || <div />}
    </Fade>
  );
}

export default FadeIn;
