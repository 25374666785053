import React from 'react';

interface Props {
  className?: string;
}

function UpArrowIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="6.33"
      height="3.64"
      viewBox="0 0 6.33 3.64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m.5.5l2.67,2.64L5.83.5" fill="none" stroke="#afb9cc" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

UpArrowIcon.defaultProps = {
  className: undefined,
};

export default UpArrowIcon;
