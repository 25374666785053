import moment from 'moment';
import { Event } from 'types/document';

export interface IEventBlock {
  event: Event;
  top: number;
  bottom: number;
  left: number;
  right: number;
}

function dateToMinutes(date: moment.Moment): number {
  return date.hours() * 60 + date.minutes();
}

function calculateLeftBlock(block: IEventBlock, blocks: IEventBlock[]): number {
  // Get list of overlapping blocks
  const overlappingBlocks: IEventBlock[] = [];
  for (let i = 0; i < blocks.length; i += 1) {
    const temp = blocks[i];
    if (temp.event.id !== block.event.id && temp.left >= 0) {
      if (
        (block.top >= temp.top && block.top < temp.bottom) ||
        (block.bottom > temp.top && block.bottom <= temp.bottom)
      ) {
        overlappingBlocks.push(temp);
      }
    }
  }

  let calculateLeft = true;
  let left = 0;
  while (calculateLeft) {
    const currentBlock = left;
    for (let i = 0; i < overlappingBlocks.length; i += 1) {
      if (overlappingBlocks[i].left === left) {
        left += 1;
      }
    }
    calculateLeft = currentBlock !== left;
  }

  return left;
}

function calculateRightBlock(block: IEventBlock, blocks: IEventBlock[]): number {
  // Get list of overlapping blocks
  const overlappingBlocks: IEventBlock[] = [];
  for (let i = 0; i < blocks.length; i += 1) {
    const temp = blocks[i];
    if (temp.event.id !== block.event.id && temp.left > block.left) {
      if (block.top < temp.bottom && block.bottom >= temp.top) {
        overlappingBlocks.push(temp);
      }
    }
  }

  // if there is overlapping box to the right, the minimum left should be the right of the block
  return overlappingBlocks.length > 0 ? Math.min(...overlappingBlocks.map(b => b.left)) : 0;
}

/*
  This is difficult process to determine calendar block arrangement especially
*/
function arrangeCalendarEventBlock(events: Event[], selectedDate: string): IEventBlock[] {
  const filterEvents = events
    .filter(
      event =>
        moment(event.startsAt).startOf('day').isSame(moment(selectedDate).startOf('day')) ||
        moment(event.endsAt).startOf('day').isSame(moment(selectedDate).startOf('day')),
    )
    .sort((a, b) => {
      // sort by start date
      return moment(a.startsAt).diff(moment(b.startsAt));
    });

  let eventBlocks: IEventBlock[] = filterEvents.map(event => {
    return {
      event,
      top: dateToMinutes(moment(event.startsAt)),
      bottom: dateToMinutes(moment(event.endsAt)),
      left: -1,
      right: -1,
    };
  });

  // Calculating Left of the Block
  for (let i = 0; i < eventBlocks.length; i += 1) {
    eventBlocks[i].left = calculateLeftBlock(eventBlocks[i], eventBlocks);
  }

  // Calculating Right of the Block
  for (let i = 0; i < eventBlocks.length; i += 1) {
    eventBlocks[i].right = calculateRightBlock(eventBlocks[i], eventBlocks);
  }

  const maxLeft = Math.max(...eventBlocks.map(e => e.left));
  const maxRight = Math.max(...eventBlocks.map(e => e.right));
  if (maxRight === 0) {
    eventBlocks = eventBlocks.map(e => ({ ...e, right: maxLeft + 1 }));
  } else {
    eventBlocks = eventBlocks.map(e => {
      if (e.right === 0) {
        return {
          ...e,
          right: maxLeft + 1,
        };
      }
      return e;
    });
  }

  return eventBlocks;
}

export default { arrangeCalendarEventBlock };
