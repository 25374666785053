/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo } from 'react';
import SalesforceIcon from 'components/icons/SalesforceIcon';
import ContextPanelView from 'components/ContextPanelView';
import EntityHeader from 'components/EntityHeader';
import useChatsStore from 'stores/chats';
import useFieldValuesStore from 'stores/field-values';
import useRecordingsStore from 'stores/recordings';
import { PanelContext } from 'stores/context-panel';
import styles from './UploadedFilePreview.module.css';
import { File } from 'types/file';
import PdfPreview from 'components/PdfPreview';
import HtmlFileContentPreview from 'components/HtmlFileContentPreview';
import { Document } from 'types/document';

interface Props {
  documentId: string;
  document: Document | undefined;
  file: null | File;
  onImportNode?: () => Promise<void>;
  isImportLoading?: boolean;
  fileContent: string;
}

function UploadedFilePreview({ documentId, document, file, onImportNode, isImportLoading, fileContent }: Props) {
  const chatsStore = useChatsStore();
  const fieldValuesStore = useFieldValuesStore();
  const recordingsStore = useRecordingsStore();

  useEffect(() => {
    if (documentId) {
      fieldValuesStore.fetch(documentId);
      recordingsStore.fetch(documentId);
      chatsStore.fetch(documentId);
    }
  }, [documentId]);

  const panelContexts: PanelContext[] = useMemo(() => ['recordings', 'chat', 'meetings'], []);
  return (
    <div className={styles.layout}>
      <EntityHeader
        entityType="uploaded-file"
        documentId={documentId}
        crmNoteId={null}
        panelContexts={panelContexts}
        menuItems={null}
      />
      <ContextPanelView viewKey="doc-view-context-panel" scrollable={false}>
        <div className="h-full">
          <div className="pt-6 pb-3 h-full flex flex-col max-w-[900px] mx-auto">
            <div className="mx-4 flex justify-between items-center text-sm border border-blue-600 bg-blue-50 rounded-lg px-5 py-3">
              <div className="text-blue-600">
                {`This is a read-only ${
                  file?.fileType?.endsWith('pdf') ? 'pdf' : file?.fileType?.includes('text/plain') ? 'text' : 'word'
                } document.${onImportNode ? ' Import this note into Superpanel to edit and save changes.' : ''}`}
              </div>
              {!!onImportNode && (
                <button
                  type="button"
                  className="flex items-center bg-blue-600 rounded px-5 py-2 mx-4 text-white hover:bg-blue-500"
                  disabled={isImportLoading}
                  onClick={onImportNode}
                >
                  <SalesforceIcon className="w-6 h-6 mr-2" />
                  <span>{isImportLoading ? 'Importing' : 'Import'}</span>
                </button>
              )}
            </div>
            <div className="text-3xl px-4 font-bold my-4">{document?.docTitle}</div>
            {file?.fileType === 'application/pdf' && !!file.url && (
              <PdfPreview url={file.url} className={styles.pdfPreview} />
            )}
            {(file?.fileType?.includes('word') || file?.fileType?.includes('text/plain')) && (
              <HtmlFileContentPreview fileContent={fileContent} className={styles.filePreview} />
            )}
          </div>
        </div>
      </ContextPanelView>
    </div>
  );
}

UploadedFilePreview.defaultProps = {
  onImportNode: undefined,
  isImportLoading: undefined,
};

export default UploadedFilePreview;
