import React, { memo, useCallback, useMemo, useRef } from 'react';
import { Plugin } from '../Plugins';
import { Editor } from 'slate';

export interface SlatePluginsChildrenProps {
  onKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  onDrop: (event: React.DragEvent<HTMLDivElement>) => void;
  onCopy: (event: React.ClipboardEvent<HTMLDivElement>) => void;
  onCut: (event: React.ClipboardEvent<HTMLDivElement>) => void;
  onPaste: (event: React.ClipboardEvent<HTMLDivElement>) => void;
  onAction: (action: string) => void;
  pluginElements: JSX.Element[];
}

interface Props {
  parentRef: HTMLDivElement | null;
  plugins: Plugin[];
  // fun props are defined as an object
  // otherwise <SlatePlugins>'s children take props as positional arguments, not as keyword arguments
  // this caused a bug detailed here https://github.com/orgs/faspo/projects/4/views/2?filterQuery=&pane=issue&itemId=37732017
  // hence, we changed the type of children to a function that takes props as an object, not as positional arguments
  children: (props: SlatePluginsChildrenProps) => JSX.Element | null;
  editor: Editor;
}

function SlatePlugins({ editor, parentRef, plugins, children }: Props) {
  const slatePluginsRef = useRef(plugins.map(plugin => plugin.initialize(parentRef)));

  const onKeyDown = useCallback((event: React.KeyboardEvent<HTMLDivElement>) => {
    slatePluginsRef.current.every(plugin => {
      if (plugin.onKeyDown) {
        // terminate plugin if the return is true
        if (plugin.onKeyDown(event, editor)) {
          return false;
        }
      }
      return true;
    });
  }, []);

  const onDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    slatePluginsRef.current.every(plugin => {
      if (plugin.onDrop) {
        // terminate plugin once onDrop is called
        if (plugin.onDrop(event, editor)) {
          return false;
        }
      }
      return true;
    });
  }, []);

  const onCopy = useCallback((event: React.ClipboardEvent<HTMLDivElement>) => {
    slatePluginsRef.current.every(plugin => {
      if (plugin.onCopy) {
        // terminate plugin once onCopy is called
        if (plugin.onCopy(event, editor)) {
          return false;
        }
      }
      return true;
    });
  }, []);

  const onCut = useCallback((event: React.ClipboardEvent<HTMLDivElement>) => {
    slatePluginsRef.current.every(plugin => {
      if (plugin.onCut) {
        // terminate plugin once onCut is called
        if (plugin.onCut(event, editor)) {
          return false;
        }
      }
      return true;
    });
  }, []);

  const onPaste = useCallback((event: React.ClipboardEvent<HTMLDivElement>) => {
    slatePluginsRef.current.every(plugin => {
      if (plugin.onPaste) {
        // terminate plugin once onPaste is called
        if (plugin.onPaste(event, editor)) {
          return false;
        }
      }
      return true;
    });
  }, []);

  const onAction = useCallback((action: string) => {
    slatePluginsRef.current.every(plugin => {
      if (plugin.onAction) {
        // terminate plugin if the return is true
        if (plugin.onAction(action)) {
          return false;
        }
      }
      return true;
    });
  }, []);

  const elements = useMemo<JSX.Element[]>(() => {
    const els: JSX.Element[] = [];
    slatePluginsRef.current.forEach(plugin => {
      if (plugin.element) {
        els.push(<div key={plugin.key}>{plugin.element}</div>);
      }
    });
    return els;
  }, []);

  return children({
    onKeyDown,
    onDrop,
    onCopy,
    onCut,
    onPaste,
    onAction,
    pluginElements: elements,
  });
}

export default memo(SlatePlugins);
