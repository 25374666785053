/* eslint-disable no-param-reassign, import/no-extraneous-dependencies */
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { create } from 'zustand';

export interface State {
  tableDraggingRowIndex: { [key: string]: number };
  tableFocusedRowIndex: { [key: string]: number };
  tableFocusedColIndex: { [key: string]: number };
  tableHoverRowIndex: { [key: string]: number };
  tableHoverColIndex: { [key: string]: number };
}

const useNoteEditorTableOperationStore = create<State>((set, get) => ({
  tableDraggingRowIndex: {},
  tableFocusedRowIndex: {},
  tableFocusedColIndex: {},
  tableHoverRowIndex: {},
  tableHoverColIndex: {},
  setState: (newState: Partial<State>) => {
    set(state => {
      return { ...state, ...newState };
    });
    return get();
  },
}));

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('NoteEditorTableOperation', useNoteEditorTableOperationStore);
}

export default useNoteEditorTableOperationStore;
