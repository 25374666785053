/* eslint-disable react/jsx-props-no-spreading, no-plusplus */
import React, { memo } from 'react';
import { Editor, Range } from 'slate';
import { ReactEditor } from 'slate-react';
import { concat } from 'utils/styling';
import DiscoveryQuestion from '../CustomElement/DiscoveryQuestion';
import DiscoveryQuestionTemplate from '../CustomElement/DiscoveryQuestionTemplate';
import NoteEditor from '../NoteEditor';
import {
  BlockType,
  CustomElement,
  CustomRenderElementProps,
  DiscoveryQuestionElement,
  ListElement,
  ProductGapElement,
  TableElement as TableElementType,
  TaskElement as TaskElementType,
  AIWorkerElement as AIWorkerElementType,
} from '../types';
import ImageElement from './ImageElement';
import styles from './BasicElement.module.css';
import ProductGapElementView from '../CustomElement/ProductGapElementView';
import TableDialogElement from './Table/TableDialogElement';
import TableElement from './Table/TableElement';
import TaskElement from '../CustomElement/Task/TaskElement';
import AIWorkerElement from '../CustomElement/AIWorker/AIWorkerElement';
import OrderedListItemElement from './OrderedListItemElement';

function isPointAtEmptyElement(editor: Editor, element: CustomElement, elementIndex: number) {
  return (
    NoteEditor.sp_isEmpty(editor, element) &&
    editor.selection &&
    Range.isCollapsed(editor.selection) &&
    editor.selection.anchor.path[0] === elementIndex
  );
}

interface Props extends CustomRenderElementProps {
  readOnly: boolean;
  scrollContainer?: HTMLDivElement;
}

function BasicElement({
  editor,
  attributes,
  children,
  element,
  blockPlaceholder,
  documentId,
  opportunityId,
  readOnly,
  scrollContainer,
}: Props) {
  switch (element.type) {
    case BlockType.H1: {
      const elementIndex = ReactEditor.findPath(editor, element)[0];
      let className = styles.h1;
      if (isPointAtEmptyElement(editor, element, elementIndex)) {
        className = concat(className, styles['empty-h1']);
      }
      return (
        <div {...attributes} className={className}>
          {children}
        </div>
      );
    }
    case BlockType.H2: {
      const elementIndex = ReactEditor.findPath(editor, element)[0];
      let className = styles.h2;
      if (isPointAtEmptyElement(editor, element, elementIndex)) {
        className = concat(className, styles['empty-h2']);
      }
      return (
        <div {...attributes} className={className}>
          {children}
        </div>
      );
    }

    case BlockType.OrderedList: {
      return (
        <OrderedListItemElement
          attributes={attributes}
          element={element as ListElement}
          documentId={documentId}
          opportunityId={opportunityId}
          editor={editor}
        >
          {children}
        </OrderedListItemElement>
      );
    }

    case BlockType.BulletedList: {
      let className = styles.list;
      if (NoteEditor.sp_isEmpty(editor, element)) {
        className = concat(className, styles['empty-bulleted-list']);
      }

      switch (element.indentLevel) {
        case 0:
          return (
            <div {...attributes} className={concat(className, styles['bulleted-list-solid-dot'])}>
              {children}
            </div>
          );
        case 1:
          return (
            <div {...attributes} className={concat(className, styles['bulleted-list-solid-square'])}>
              {children}
            </div>
          );
        case 2:
          return (
            <div {...attributes} className={concat(className, styles['bulleted-list-empty-square'])}>
              {children}
            </div>
          );
        default:
          return (
            <div {...attributes} className={concat(className, styles['bulleted-list-empty-dot'])}>
              {children}
            </div>
          );
      }
    }

    case BlockType.DiscoveryQuestion: {
      // template discovery question doesn't have these
      if (documentId == null && opportunityId == null) {
        return (
          <DiscoveryQuestionTemplate
            attributes={attributes}
            element={element as DiscoveryQuestionElement}
            readOnly={readOnly}
          >
            {children}
          </DiscoveryQuestionTemplate>
        );
      }
      return (
        <DiscoveryQuestion
          editor={editor}
          attributes={attributes}
          element={element as DiscoveryQuestionElement}
          documentId={documentId}
          opportunityId={opportunityId}
        >
          {children}
        </DiscoveryQuestion>
      );
    }
    case BlockType.ProductGap: {
      return (
        <ProductGapElementView
          editor={editor}
          attributes={attributes}
          element={element as ProductGapElement}
          documentId={documentId}
          opportunityId={opportunityId}
        >
          {children}
        </ProductGapElementView>
      );
    }
    case BlockType.Image: {
      return (
        <ImageElement attributes={attributes} element={element} documentId={null} opportunityId={null} editor={editor}>
          {children}
        </ImageElement>
      );
    }
    case BlockType.Table: {
      return (
        <TableElement attributes={attributes} element={element as TableElementType}>
          {children}
        </TableElement>
      );
    }
    case BlockType.TableDialog: {
      return (
        <TableDialogElement attributes={attributes} element={element} editor={editor}>
          {children}
        </TableDialogElement>
      );
    }
    case BlockType.Task: {
      // tasks in template
      if (documentId == null && opportunityId == null) {
        return (
          <TaskElement
            attributes={attributes}
            element={element as TaskElementType}
            documentId={documentId}
            opportunityId={opportunityId}
            editor={editor}
            comments={element.comments}
            scrollContainer={scrollContainer}
            isFromTemplate
            readOnly={readOnly}
          >
            {children}
          </TaskElement>
        );
      }
      return (
        <TaskElement
          attributes={attributes}
          element={element as TaskElementType}
          documentId={documentId}
          opportunityId={opportunityId}
          editor={editor}
          comments={element.comments}
          scrollContainer={scrollContainer}
        >
          {children}
        </TaskElement>
      );
    }
    case BlockType.AIWorker: {
      // ai workers in template
      if (documentId == null && opportunityId == null) {
        return (
          <AIWorkerElement
            attributes={attributes}
            element={element as AIWorkerElementType}
            documentId={documentId}
            editor={editor}
            isFromTemplate
            readOnly={readOnly}
          >
            {children}
          </AIWorkerElement>
        );
      }
      return (
        <AIWorkerElement
          attributes={attributes}
          element={element as AIWorkerElementType}
          documentId={documentId}
          editor={editor}
        >
          {children}
        </AIWorkerElement>
      );
    }
    default: {
      const elementIndex = ReactEditor.findPath(editor, element)[0];
      let className = styles.paragraph;
      // add default ::after text if user is pointing at an empty paragraph
      if (isPointAtEmptyElement(editor, element, elementIndex)) {
        className = concat(className, styles['empty-paragraph']);
      }
      return (
        <div
          {...attributes}
          className={className}
          style={
            {
              '--blockPlaceholder': `"${blockPlaceholder} "`,
            } as React.CSSProperties
          }
        >
          {children}
        </div>
      );
    }
  }
}

BasicElement.defaultProps = {
  scrollContainer: undefined,
};

export default memo(BasicElement);
