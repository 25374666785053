import React, { useEffect, useState } from 'react';

import SalesforceLinkIcon from 'components/icons/SalesforceLinkIcon';

import useDocumentStore from 'stores/document';

import { EntityDocument, EntityType } from 'types/entity';

interface Props {
  documentId: string;
  crmNoteId: string | null;
  type: EntityType;
}

interface State {
  data: EntityDocument | null;
}

function SFLink({ documentId, crmNoteId, type }: Props) {
  const documentStore = useDocumentStore();
  const [state, setState] = useState<State>({
    data: null,
  });

  useEffect(() => {
    const fetch = async () => {
      if (['account', 'opportunity'].includes(type)) {
        const res = await documentStore.getOrFetchEntityByDocId(documentId);
        if (res) {
          setState(prevState => ({ ...prevState, data: res }));
        }
      }
    };
    fetch();
  }, []);

  let link;

  if (type === 'account') {
    if (state.data) {
      if (state.data.crm) {
        link = `${documentStore.instanceUrl}/${state.data.crm.id}`;
      }
    }
  } else if (type === 'opportunity') {
    if (state.data) {
      if (state.data.crm) {
        link = `${documentStore.instanceUrl}/${state.data.crm.id}`;
      }
    }
  } else if (type === 'document') {
    const document = documentStore.documents.find(d => d.id === documentId);
    if (document) {
      if (document.parent && document.parent.crmId) {
        link = `${documentStore.instanceUrl}/${document.parent.crmId}`;
      }
    }
  } else if (type === 'crm-note' && crmNoteId) {
    const crmNote = documentStore.crmNotes.find(n => n.id === crmNoteId);
    if (crmNote) {
      link = `${documentStore.instanceUrl}/${crmNote.linkedEntity.id}`;
    }
  }
  return (
    <div className="flex items-center">
      {link && (
        <a id="crm-link-from-entity-title" className="w-5 h-5" href={link} target="_blank" rel="noreferrer">
          <SalesforceLinkIcon className="w-5 h-5" />
        </a>
      )}
    </div>
  );
}

export default SFLink;
