import React, { useEffect, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';

import Loader from 'components/Loader';

import getRecordings, { Recording } from 'api/recordings/get-recordings';

import useDocumentStore from 'stores/document';

import SpinLoader from 'components/icons/SpinLoader';
import CornerArrowIcon from 'components/icons/CornerArrow';
import searchRecordings from 'api/recordings/search-recordings';
import RecordingPageTable from './RecordingPage/RecordingPageTable';

interface State {
  isLoading: boolean;
  recordings: Recording[];
  searchedRecordings: null | Recording[];
  subView: 'allRecordings' | 'unlinkedRecordings';
  searchValue: string;
  searchValueFinal: string;
}

function RecordingPage() {
  const [state, setState] = useState<State>({
    isLoading: true,
    recordings: [],
    searchedRecordings: null,
    subView: 'allRecordings',
    searchValue: '',
    searchValueFinal: '',
  });

  const documentStore = useDocumentStore();

  const fetchRecordings = async () => {
    const { recordings } = await getRecordings();
    setState(oldState => {
      return { ...oldState, recordings };
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      setState(prevState => ({ ...prevState, isLoading: true }));
      await fetchRecordings();
      setState(prevState => ({ ...prevState, isLoading: false }));
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.searchValue.length === 0) {
      setState(prevState => ({ ...prevState, searchedRecordings: null }));
    }
  }, [state.searchValue]);

  const searchRecordingsByName = async (searchValue: string) => {
    setState(prevState => ({
      ...prevState,
      isLoading: true,
      searchedRecordings: null,
    }));
    const { recordings } = await searchRecordings(searchValue);
    setState(prevState => ({
      ...prevState,
      isLoading: false,
      searchedRecordings: recordings,
    }));
  };

  let recordingElement: null | JSX.Element = null;

  if (state.isLoading || documentStore.isLoading || documentStore.isLoading) {
    recordingElement = (
      <div className="flex grow items-center h-full justify-center">
        <Loader />
      </div>
    );
  } else {
    recordingElement = (
      <div className="w-full h-full flex flex-col">
        {/* 1st header */}
        <div className="text-xl p-6 pb-2 font-medium border-b border-gray-200">Recordings</div>
        {/* 2nd header */}
        <div className="flex justify-between items-center px-6 pt-2">
          <div className="flex gap-8">
            <button
              type="button"
              className="flex flex-col gap-1 group hover:cursor-pointer"
              onClick={() => {
                setState(oldState => ({
                  ...oldState,
                  subView: 'unlinkedRecordings',
                }));
              }}
            >
              <div
                className={`flex text-sm font-medium gap-[3px] items-center  ${
                  state.subView === 'unlinkedRecordings' ? 'text-blue-500' : 'text-gray-500'
                }`}
              >
                Unlinked Recordings
              </div>
              <div
                className={`h-1 ${
                  state.subView === 'unlinkedRecordings'
                    ? 'bg-blue-500 w-9'
                    : 'bg-transparent w-6 group-hover:bg-gray-300 transform group-hover:scale-x-150 transition-all'
                } rounded-sm self-center`}
              />
            </button>
            <button
              type="button"
              className="flex flex-col gap-1 group hover:cursor-pointer"
              onClick={() => {
                setState(oldState => ({
                  ...oldState,
                  subView: 'allRecordings',
                }));
              }}
            >
              <div
                className={`flex text-sm font-medium gap-[3px] items-center  ${
                  state.subView === 'allRecordings' ? 'text-blue-500' : 'text-gray-500'
                }`}
              >
                All Recordings
              </div>
              <div
                className={`h-1 ${
                  state.subView === 'allRecordings'
                    ? 'bg-blue-500 w-9'
                    : 'bg-transparent w-6 group-hover:bg-gray-300 transform group-hover:scale-x-150 transition-all'
                } rounded-sm self-center`}
              />
            </button>
          </div>
          <div className="py-1">
            <div className="flex items-center px-3 py-2 my-2 border rounded-lg text-gray-400">
              <div className="flex items-center gap-2">
                <MagnifyingGlassIcon className="w-4 h-4" />
                <input
                  className="text-sm placeholder:text-gray-400 placeholder:text-sm min-w-[17rem]"
                  placeholder="Search recordings..."
                  value={state.searchValue}
                  onChange={e => {
                    setState(prevState => ({ ...prevState, searchValue: e.target.value }));
                  }}
                  onKeyDown={event => {
                    if (event.key === 'Enter') {
                      if (state.searchValue.length > 0) {
                        searchRecordingsByName(state.searchValue);
                        setState(prevState => ({ ...prevState, searchValueFinal: prevState.searchValue }));
                      }
                    }
                  }}
                />
                {state.searchValue !== '' && (
                  <button
                    type="button"
                    className="mr-2"
                    onClick={() => setState(prevState => ({ ...prevState, searchValue: '' }))}
                  >
                    <XMarkIcon className="w-3 h-3" />
                  </button>
                )}
                <button
                  type="button"
                  className={`${
                    state.searchValue.length > 0 ? 'text-blue-600 bg-blue-100 border-blue-400' : 'text-gray-400'
                  } hover:bg-blue-200 flex items-center rounded px-1 py-0.5 border`}
                  onClick={() => {
                    if (state.searchValue.length > 0) {
                      searchRecordingsByName(state.searchValue);
                    }
                  }}
                  disabled={state.searchValue.length === 0 || state.isLoading}
                >
                  {state.isLoading ? (
                    <div>
                      <SpinLoader className="animate-spin w-3 h-3 text-orange-500" />
                    </div>
                  ) : (
                    <CornerArrowIcon className="w-4 h-4" />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* table */}
        <RecordingPageTable
          recordings={state.searchedRecordings !== null ? state.searchedRecordings : state.recordings}
          subView={state.subView}
          documents={documentStore.documents}
          onChange={fetchRecordings}
          loading={state.isLoading}
          searchStr={state.searchValueFinal}
          contentClassName="flex-1 h-1 overflow-auto"
        />
      </div>
    );
  }

  return recordingElement;
}

export default RecordingPage;
