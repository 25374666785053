import React, { useEffect, useState } from 'react';
import { LockClosedIcon } from '@heroicons/react/24/outline';
import { useNavigate, useParams } from 'react-router-dom';

import { EntityDocumentRich as ApiResponse } from 'api/entities/get-entity';
import errors from 'utils/errors';

import Loader from 'components/Loader';
import useFieldValuesStore from 'stores/field-values';
// import useDocumentStore from 'stores/document';
import useSuggestedAnswer from 'hooks/useSuggestedAnswer';

import useDocumentStore from 'stores/document';
import useRecording from 'hooks/useRecording';
import useTemplateStore from 'stores/templates';
import styles from './Opportunity.module.css';
import OpportunityView from './Opportunity/OpportunityView';
import useContextPanelStore from '../stores/context-panel';
import RightDrawerPanel from 'components/RightDrawerPanel';

interface State {
  isLoading: boolean;
  isAuthorized: boolean;
  apiResponse: null | ApiResponse;
}

function OpportunityC() {
  const { documentId } = useParams();
  useSuggestedAnswer();
  useRecording(documentId);

  const [state, setState] = useState<State>({
    isLoading: documentId !== undefined,
    isAuthorized: true,
    apiResponse: null,
  });

  const navigate = useNavigate();

  const documentStore = useDocumentStore();
  const templateStore = useTemplateStore();
  const fieldValuesStore = useFieldValuesStore();
  const contextPanelStore = useContextPanelStore();

  useEffect(() => {
    const fetchOpportunity = async (docId: string): Promise<void> => {
      setState(oldState => ({ ...oldState, isLoading: true, isAuthorized: true }));
      try {
        const [opportunity] = await Promise.all([documentStore.fetchEntityByDocId(docId), templateStore.fetch()]);
        setState(oldState => ({ ...oldState, apiResponse: opportunity }));
      } catch (e) {
        if (e instanceof errors.PermissionError) {
          setState(prevState => ({ ...prevState, isAuthorized: false }));
        }
      }
      setState(oldState => ({ ...oldState, isLoading: false }));
    };

    if (documentId !== undefined) {
      fetchOpportunity(documentId);
      documentStore.setState({ currentOppDocId: documentId });
    }
    return () => {
      fieldValuesStore.reset();
      contextPanelStore.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId]);

  if (documentId === undefined) {
    navigate('/');
    return null;
  }

  if (state.isLoading) {
    return (
      <div className={styles['opportunity-loader']}>
        <Loader />
      </div>
    );
  }

  if (!state.isAuthorized) {
    return (
      <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 text-gray-400">
        <div className="flex flex-col items-center justify-center">
          <div>
            <LockClosedIcon className="w-32 h-32" />
          </div>
          <div className="text-sm">You do not have the required access to view this opportunity.</div>
        </div>
      </div>
    );
  }
  if (state.apiResponse) {
    return (
      <div className={styles['opportunity-container']}>
        <OpportunityView
          apiResponse={state.apiResponse as ApiResponse}
          noteTemplates={templateStore.templates}
          documentId={documentId}
        />
        <RightDrawerPanel />
      </div>
    );
  }
  return null;
}

export default OpportunityC;
