import React from 'react';
import { currencyFormatter } from '../../../utils/number-formatter';

interface Props {
  cellValue: number | null;
}

function CurrencyCellValue({ cellValue }: Props) {
  if (!cellValue) return <div />;
  return (
    <div className="rounded bg-green-100 text-green-700 px-4 py-2 truncate w-full text-left">
      {currencyFormatter.format(cellValue)}
    </div>
  );
}

export default CurrencyCellValue;
