import React from 'react';

interface Props {
  className?: string;
}

function TaskIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.3333 26.6667C22.0403 26.6667 26.6667 22.0403 26.6667 16.3333C26.6667 10.6264 22.0403 6 16.3333 6C10.6264 6 6 10.6264 6 16.3333C6 22.0403 10.6264 26.6667 16.3333 26.6667Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3335 12.666L15.0476 19.3327L12.3808 16.666"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

TaskIcon.defaultProps = {
  className: undefined,
};

export default TaskIcon;
