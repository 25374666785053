/* eslint-disable no-nested-ternary */
import React, { useRef, useState } from 'react';
import { CheckIcon, ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';

import checkDocumentOpportunity from 'api/documents/check_document_opportunity';

import SalesforceIcon from 'components/icons/SalesforceIcon';
import Popover from 'components/Popover';
import SpinLoader from 'components/icons/SpinLoader';

import useModalStore from 'stores/modal';
import useDocumentStore from 'stores/document';

import OpportunityIcon from 'components/icons/OpportunityIcon';
import AccountIcon from 'components/icons/AccountIcon';
import CornerArrowIcon from 'components/icons/CornerArrow';
import { EntityDocument } from 'types/entity';
import searchObjects, { SearchNormalized } from 'utils/search-objects';
import getOrCreateNewSFEntity from 'utils/get-or-create-entity';
import moment from 'moment';
import useUserStore from '../stores/user';
import ChangeOpportunityWarningModal from '../pages/Document/DocumentView/ChangeOpportunityWarningModal';
import BaseButton from './BaseButton';

interface State {
  isMenuOpen: boolean;
  searchValue: string;
  // searched results may not have document created yet
  searchResultOpp: SearchNormalized[];
  searchResultAcct: SearchNormalized[];
  isLoading: boolean;
  isCheckEntityId: null | string;
}

interface Props {
  hideLabel?: boolean;
  documentId: string;
  entityDocumentId: string | null;
  setLoading: (isLoading: boolean) => void;
  onSelected?: (id: string | null) => void;
  onUpdatedDocFolderByApi?: () => void;
  className?: string;
}

function FolderSelect({
  hideLabel,
  documentId,
  entityDocumentId,
  setLoading,
  onSelected,
  onUpdatedDocFolderByApi,
  className,
}: Props) {
  const [state, setState] = useState<State>({
    isMenuOpen: false,
    searchValue: '',
    searchResultOpp: [],
    searchResultAcct: [],
    isLoading: false,
    isCheckEntityId: '',
  });

  const ref = useRef<HTMLButtonElement>(null);

  const documentStore = useDocumentStore();
  const modalStore = useModalStore();
  const userStore = useUserStore();

  // entityDocument should exist in the DocumentStore as upstream component will load it even under filtering
  const entityDocument = documentStore.entityDocuments.find(ed => ed.id === entityDocumentId);

  const getOrCreateNewSFEntityMain = async (selectedEntityDocument: SearchNormalized) => {
    setState(prevState => ({
      ...prevState,
      isLoading: true,
    }));
    const destinationEntity = await getOrCreateNewSFEntity(selectedEntityDocument, documentStore);
    setState(prevState => ({
      ...prevState,
      isLoading: false,
    }));
    return destinationEntity;
  };

  const checkSelectedEntity = async (selectedEntityDocument: EntityDocument | null) => {
    setState(prevState => ({
      ...prevState,
      isCheckEntityId: onSelected ? null : selectedEntityDocument?.crm?.id || null,
      isMenuOpen: onSelected ? false : prevState.isMenuOpen,
    }));
    if (onSelected) {
      onSelected(selectedEntityDocument?.id || null);
      return;
    }
    const checkDocument = await checkDocumentOpportunity(documentId);
    setState(prevState => ({
      ...prevState,
      isCheckEntityId: null,
    }));

    modalStore.setState({
      isOpen: true,
      element: (
        <ChangeOpportunityWarningModal
          checkDocument={checkDocument}
          entityDocument={selectedEntityDocument}
          documentId={documentId}
          setLoading={setLoading}
          onUpdatedDocFolderByApi={onUpdatedDocFolderByApi}
        />
      ),
    });
  };
  const checkIsDocumentEntity = (nd: SearchNormalized) => {
    if (!entityDocument) {
      return false;
    }
    if (entityDocument.id === nd.id) {
      return true;
    }
    return !!(entityDocument.crm?.id && nd.sfId && nd.sfId === entityDocument.crm?.id);
  };
  const searchObjectsByName = async () => {
    setState(prevState => ({
      ...prevState,
      isLoading: true,
      searchResultOpp: [],
      searchResultAcct: [],
    }));
    if (!userStore.user) {
      return;
    }
    const { searchOpps, searchAccts } = await searchObjects(
      userStore.user?.isIntegratedWithCrm,
      state.searchValue,
      documentStore.entityDocuments,
    );
    let searchOppsFinal = searchOpps;
    let searchAcctsFinal = searchAccts;
    if (entityDocument) {
      if (entityDocument.entity.name === 'Opportunity') {
        searchOppsFinal = searchOpps.filter(s => !checkIsDocumentEntity(s));
      } else if (entityDocument.entity.name === 'Account') {
        searchAcctsFinal = searchAccts.filter(s => !checkIsDocumentEntity(s));
      }
    }
    setState(prevState => ({
      ...prevState,
      isLoading: false,
      searchResultOpp: searchOppsFinal,
      searchResultAcct: searchAcctsFinal,
    }));
  };

  let label: JSX.Element = (
    <span className="flex items-center gap-2 w-full">
      {userStore.user?.isIntegratedWithCrm && !entityDocumentId && (
        <SalesforceIcon className="min-w-[1.25rem] w-5 h-5" />
      )}
      <span className="truncate">{hideLabel ? 'Not linked' : 'No Account/Opportunity Linked'}</span>
    </span>
  );
  if (entityDocument?.entity.name === 'Opportunity') {
    label = (
      <span className="flex items-center gap-2 w-full">
        <OpportunityIcon className="min-w-[1.25rem] w-5 h-5 flex-none text-orange-500 fill-orange-500" />
        <span className="truncate">{entityDocument.name}</span>
      </span>
    );
  } else if (entityDocument?.entity.name === 'Account') {
    label = (
      <span className="flex items-center gap-2 w-full">
        <AccountIcon className="min-w-[1.25rem] w-5 h-5" />
        <span className="truncate">{entityDocument.name}</span>
      </span>
    );
  }

  let currentEntity: JSX.Element | null = null;

  // remove selected entity as it will be placed at the top
  if (entityDocument?.entity.name === 'Opportunity') {
    currentEntity = (
      <div className="flex items-center justify-between px-2 w-full py-1 text-sm ">
        <div className="flex w-full justify-between items-center gap-2 hover:bg-slate-100 p-2 rounded-lg">
          <div className="flex items-center gap-1">
            <OpportunityIcon className="shrink-0 w-4 h-4 flex-none text-orange-500 fill-orange-500" />
            <span>{entityDocument.name}</span>
          </div>
          <CheckIcon className="shrink-0 w-4 h-4 text-gray-400" />
        </div>
      </div>
    );
  } else if (entityDocument?.entity.name === 'Account') {
    currentEntity = (
      <div className="flex items-center justify-between px-2 w-full py-1 text-sm  ">
        <div className="flex w-full justify-between items-center gap-2 hover:bg-slate-100 p-2 rounded-lg">
          <div className="flex items-center gap-1">
            <AccountIcon className="shrink-0 w-4 h-4" />
            <span>{entityDocument.name}</span>
          </div>
          <CheckIcon className="shrink-0 w-4 h-4 text-gray-400" />
        </div>
      </div>
    );
  }
  return (
    <div className={className}>
      {!hideLabel && <div className="text-sm w-44 flex-shrink-0 flex-grow-0 flex items-center">Folder</div>}
      <BaseButton
        ref={ref}
        color="secondary"
        variant="outlined"
        className="flex-1 !font-normal truncate"
        contentClassName="!justify-start w-full"
        onClick={e => {
          e.stopPropagation();
          setState(prevState => ({ ...prevState, isMenuOpen: true }));
        }}
        tooltip={
          hideLabel
            ? entityDocument
              ? `${entityDocument.entity.name}: ${entityDocument.name}`
              : 'No Account/Opportunity Linked'
            : ''
        }
        tooltipPlacement="bottom"
      >
        {label}
        {!hideLabel && <ChevronDownIcon className="w-3 h-3" />}
      </BaseButton>
      <Popover
        anchorEl={ref.current}
        onClose={() =>
          setState({
            isMenuOpen: false,
            searchValue: '',
            searchResultOpp: [],
            searchResultAcct: [],
            isLoading: false,
            isCheckEntityId: null,
          })
        }
        stopPropagationWhenClick
        isOpen={state.isMenuOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <div style={{ width: `${ref.current ? ref.current.getBoundingClientRect().width : '100%'}px`, minWidth: 420 }}>
          <div className="w-full p-3">
            <div className="w-full border rounded py-1 px-1 flex items-center">
              <MagnifyingGlassIcon className="w-4 h-4 mr-1 text-gray-400" />
              <input
                className="w-full text-sm placeholder:text-sm"
                value={state.searchValue}
                onChange={event => setState(prevState => ({ ...prevState, searchValue: event.target.value }))}
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    searchObjectsByName();
                  }
                }}
                placeholder="Search Account/Opportunity"
              />
              <button
                type="button"
                className={`${
                  state.searchValue.length > 0 ? 'text-blue-600 bg-blue-100 border-blue-400' : 'text-gray-400'
                } hover:bg-blue-200 flex items-center rounded px-1 py-0.5 border`}
                onClick={() => {
                  searchObjectsByName();
                }}
                disabled={state.searchValue.length === 0}
              >
                <CornerArrowIcon className="w-4 h-4" />
              </button>
            </div>
          </div>
          <div className="max-h-52 overflow-y-auto mb-3">
            {entityDocument && (
              <div className="w-full px-2 py-0.5 text-sm">
                <button
                  type="button"
                  className="relative flex justify-between items-center w-full text-left p-2 rounded-lg hover:bg-slate-100"
                  onClick={() => checkSelectedEntity(null)}
                >
                  <div>Unlink</div>
                </button>
              </div>
            )}
            {currentEntity}
            {state.isLoading && (
              <div className="flex items-center h-10 justify-center">
                <SpinLoader className="animate-spin w-5 h-5 text-orange-400" />
              </div>
            )}
            {!state.isLoading && (
              <>
                {state.searchResultOpp.map(oppItem => {
                  let oppInfo = null;
                  if (oppItem.stageName) {
                    oppInfo = <span className="text-xs text-gray-400">({oppItem.stageName})</span>;
                  } else if (oppItem.lastViewedDate) {
                    oppInfo = <span className="text-xs text-gray-400">{moment(oppItem.lastViewedDate).fromNow()}</span>;
                  } else {
                    oppInfo = <span className="text-xs text-gray-400">never viewed</span>;
                  }
                  return (
                    <div key={oppItem.id ? oppItem.id : oppItem.sfId} className="w-full px-2 py-0.5 text-sm">
                      <button
                        type="button"
                        className="relative flex justify-between items-center w-full text-left p-2 rounded-lg hover:bg-slate-100"
                        onClick={async () => {
                          const ed = await getOrCreateNewSFEntityMain(oppItem);
                          if (ed !== undefined) {
                            checkSelectedEntity(ed);
                          }
                        }}
                        disabled={state.isCheckEntityId !== null && state.isCheckEntityId === oppItem.sfId}
                      >
                        <span className="flex items-center gap-1">
                          <OpportunityIcon className="w-4 h-4 flex-none text-orange-500 fill-orange-500" />
                          <span>{oppItem.name}</span>
                        </span>
                        {/* This spin loader won't work for non-crm-integrated users */}
                        {state.isCheckEntityId && state.isCheckEntityId === oppItem.sfId && (
                          <SpinLoader className="absolute top-1 right-0 animate-spin w-6 h-6 text-orange-500" />
                        )}
                        {oppInfo}
                      </button>
                    </div>
                  );
                })}
              </>
            )}
            {!state.isLoading &&
              state.searchResultAcct.map(acctItem => {
                return (
                  <div key={acctItem.id ? acctItem.id : acctItem.sfId} className="w-full px-2 py-0.5 text-sm">
                    <button
                      type="button"
                      className={`relative flex justify-between items-center w-full text-left p-2 rounded-lg hover:bg-slate-100 ${
                        entityDocument?.entity.name === 'Opportunity' && 'text-gray-300 cursor-default'
                      }`}
                      onClick={async () => {
                        const ed = await getOrCreateNewSFEntityMain(acctItem);
                        if (ed !== undefined) {
                          checkSelectedEntity(ed);
                        }
                      }}
                      disabled={
                        (state.isCheckEntityId && state.isCheckEntityId === acctItem.sfId) ||
                        entityDocument?.entity.name === 'Opportunity'
                      }
                    >
                      <span className="flex items-center gap-2 w-full">
                        <AccountIcon className="flex-none w-4 h-4" />
                        <span>{acctItem.name}</span>
                      </span>
                      {state.isCheckEntityId && state.isCheckEntityId === acctItem.sfId && (
                        <SpinLoader className="absolute top-1 right-0 animate-spin w-6 h-6 text-orange-500" />
                      )}
                      {acctItem.lastViewedDate ? (
                        <span className="text-xs text-gray-400">{moment(acctItem.lastViewedDate).fromNow()}</span>
                      ) : (
                        <span className="text-xs text-gray-400">never viewed</span>
                      )}
                    </button>
                  </div>
                );
              })}
          </div>
        </div>
      </Popover>
    </div>
  );
}

FolderSelect.defaultProps = {
  hideLabel: undefined,
  onUpdatedDocFolderByApi: undefined,
  onSelected: undefined,
  className: undefined,
};

export default FolderSelect;
