import React, { CSSProperties } from 'react';

interface Props {
  style: CSSProperties;
}

function ChevronDown({ style }: Props) {
  return (
    <svg viewBox="0 0 30 30" fill="currentColor" style={style}>
      <polygon points="15,17.4 4.8,7 2,9.8 15,23 28,9.8 25.2,7 " />
    </svg>
  );
}

export default ChevronDown;
