import React from 'react';

interface Props {
  className?: string;
}

function SidebarIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.93 4.5H18.07C18.8598 4.5 19.5 5.14023 19.5 5.93V18.07C19.5 18.8598 18.8598 19.5 18.07 19.5H5.93C5.14023 19.5 4.5 18.8598 4.5 18.07V5.93C4.5 5.14023 5.14023 4.5 5.93 4.5Z"
        stroke="#AFB9CC"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="14" y="5" width="5" height="14" fill="#AFB9CC" />
      <path
        d="M11 9L8 12L11 15"
        stroke="#AFB9CC"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SidebarIcon.defaultProps = {
  className: undefined,
};

export default SidebarIcon;
