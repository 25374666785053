import httpClient from '../http-client';

export interface Response {
  isAiProcessing: boolean;
}

export default async (fieldValueId: string): Promise<Response> => {
  const { data } = await httpClient.post<Response>(`/discovery-question-suggested-answers`, {
    fieldValueId,
  });
  return data;
};
