import { MenuItem } from '@mui/material';
import React from 'react';
import { PlusIcon } from '@heroicons/react/24/outline';

import CreateAnalyticColumnModal from 'components/Table/Columns/GroupColumnMenus/CreateAnalyticColumnModal';
import useModalStore from 'stores/modal';

interface Props {
  fieldId: string;
  fieldLabel: string;
  setIsMenuOpen: (isOpen: boolean) => void;
}

function AddNewReportingColumnMenu({ fieldId, fieldLabel, setIsMenuOpen }: Props) {
  const modalStore = useModalStore();
  return (
    <MenuItem
      onClick={() => {
        modalStore.setState({
          isOpen: true,
          element: <CreateAnalyticColumnModal fieldId={fieldId} fieldLabel={fieldLabel} />,
        });
        setIsMenuOpen(false);
      }}
    >
      <div className="flex items-center text-sm">
        <PlusIcon className="w-4 h-4 mr-2" /> Add new column
      </div>
    </MenuItem>
  );
}

export default AddNewReportingColumnMenu;
