import httpClient from '../http-client';
import {
  ReportingCalculation,
  ReportingColumn,
  ReportingFilter,
  ReportingSort,
  TableCalculation,
  TableRow,
} from 'types/reporting';
import { SuggestedAnswer } from 'types/field-value';

interface Response {
  tableRows: TableRow[];
  tableCalculation: TableCalculation;
  totalPages: number;
  perPage: number;
  suggestionByFieldValueId: {
    [fieldValueId: string]: SuggestedAnswer;
  };
}

export default async (
  columns: ReportingColumn[],
  filters: ReportingFilter[],
  sorts: ReportingSort[],
  calculations: ReportingCalculation[],
  page: number,
  skipEntryJobCreation = false,
): Promise<Response> => {
  const { data } = await httpClient.post<Response>(`/reporting-rows`, {
    columns,
    filters,
    sorts,
    calculations,
    page,
    skipEntryJobCreation,
  });
  return data;
};
