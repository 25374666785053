import React, { useMemo, useState } from 'react';

import { SearchItem, SearchItemType } from 'api/search/search-items';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import useModalStore from '../../stores/modal';
import OpportunityIcon from '../icons/OpportunityIcon';
import useDocumentStore from '../../stores/document';
import AccountIcon from '../icons/AccountIcon';
import NoteIcon from '../icons/NoteIcon';
import constants from '../../utils/constants';
import CalendarIcon from '../icons/CalendarIcon';
import SpinLoader from '../icons/SpinLoader';
import SalesforceNoteIcon from '../icons/SalesforceNoteIcon';
import PdfIcon from 'components/icons/PdfIcon';
import WordIcon from 'components/icons/WordIcon';
import { concat } from 'utils/styling';
import TxtIcon from 'components/icons/TxtIcon';

export type SearchItemTypeProps = SearchItemType & 'crm-note';
export interface SearchItemProps extends SearchItem {
  type: SearchItemTypeProps;
}

interface Props {
  searchItem: SearchItemProps;
}

function OpportunitySearchItemView({ searchItem }: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const modalStore = useModalStore();
  const documentStore = useDocumentStore();

  return (
    <button
      type="button"
      className="w-full flex items-center gap-2 px-2 py-2 rounded hover:bg-slate-100 text-sm"
      onClick={async () => {
        if (searchItem.documentId && documentStore.isEntityExistInStore(searchItem.documentId)) {
          navigate(`/opportunity/${searchItem.documentId}`);
          modalStore.close();
        } else if (searchItem.crmId) {
          setIsLoading(true);
          const entityDocument = await documentStore.getOrCreateNewSFEntity(searchItem.crmId, 'Opportunity');
          setIsLoading(false);
          if (entityDocument) {
            navigate(`/opportunity/${entityDocument.id}`);
            modalStore.close();
          }
        }
      }}
    >
      <OpportunityIcon className="flex gap-2 w-4 h-4 text-orange-400 fill-orange-400" />
      <span className="truncate">{searchItem.title}</span>
      <span className="text-gray-400">({searchItem.oppStageName})</span>
      {isLoading && (
        <span>
          <SpinLoader className="animate-spin w-5 h-5 text-orange-500" />
        </span>
      )}
    </button>
  );
}

function AccountSearchItemView({ searchItem }: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const modalStore = useModalStore();
  const documentStore = useDocumentStore();

  return (
    <button
      type="button"
      className="w-full flex items-center gap-2 px-2 py-2 rounded hover:bg-slate-100 text-sm"
      onClick={async () => {
        if (searchItem.documentId && documentStore.isEntityExistInStore(searchItem.documentId)) {
          navigate(`/account/${searchItem.documentId}`);
          modalStore.close();
        } else if (searchItem.crmId) {
          setIsLoading(true);
          const entityDocument = await documentStore.getOrCreateNewSFEntity(searchItem.crmId, 'Account');
          setIsLoading(false);
          if (entityDocument) {
            navigate(`/account/${entityDocument.id}`);
            modalStore.close();
          }
        }
      }}
    >
      <AccountIcon className="w-4 h-4 text-orange-400 fill-orange-400" />
      <span className="truncate">{searchItem.title}</span>
      {isLoading && (
        <span>
          <SpinLoader className="animate-spin w-5 h-5 text-orange-500" />
        </span>
      )}
    </button>
  );
}

function DocumentSearchItemView({ searchItem }: Props) {
  const navigate = useNavigate();
  const modalStore = useModalStore();

  let title = <span>{searchItem.title}</span>;
  if (searchItem.parent) {
    const icon =
      searchItem.parent.type === 'Opportunity' ? (
        <OpportunityIcon className="pt-[3px] w-4 h-4 text-orange-400 fill-orange-400" />
      ) : (
        <AccountIcon className="pt-[3px] w-4 h-4 text-orange-400 fill-orange-400" />
      );
    title = (
      <span>
        {searchItem.title} ({icon} {searchItem.parent.name})
      </span>
    );
  }

  const docIcon = useMemo(() => {
    const iconClassName = 'shrink-0 w-4 h-4';
    if (searchItem.uploadedFileType) {
      if (searchItem.uploadedFileType.endsWith('pdf')) {
        return <PdfIcon className={iconClassName} />;
      }
      if (searchItem.uploadedFileType.includes('text/plain')) {
        return <TxtIcon className={iconClassName} />;
      }
      return <WordIcon className={iconClassName} />;
    }
    return <NoteIcon className={concat(iconClassName, 'text-gray-500')} />;
  }, [searchItem]);

  return (
    <button
      type="button"
      className="w-full flex items-center gap-2 px-2 py-2 rounded hover:bg-slate-100 text-sm"
      onClick={() => {
        navigate(`/${searchItem.uploadedFileType ? 'uploaded-file' : 'document'}/${searchItem.documentId}`);
        modalStore.close();
      }}
    >
      {docIcon}
      <span className="truncate">{title}</span>
    </button>
  );
}

function EventSearchItemView({ searchItem }: Props) {
  const navigate = useNavigate();
  const modalStore = useModalStore();

  return (
    <button
      type="button"
      className="w-full flex items-center gap-2 px-2 py-2 rounded hover:bg-slate-100 text-sm"
      onClick={() => {
        navigate(`/document/${searchItem.documentId}`);
        modalStore.close();
      }}
    >
      <CalendarIcon className="w-4 h-4 text-gray-500" />
      <span className="truncate">{`${searchItem.title} (${moment(searchItem.datetime).format(
        constants.DATETIME_FORMAT,
      )})`}</span>
    </button>
  );
}

function CrmNoteSearchItemView({ searchItem }: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const modalStore = useModalStore();
  const documentStore = useDocumentStore();

  return (
    <button
      type="button"
      className="w-full flex items-center gap-2 px-2 py-2 rounded hover:bg-slate-100 text-sm"
      onClick={async () => {
        if (searchItem.documentId && documentStore.isEntityExistInStore(searchItem.documentId)) {
          navigate(`/crm-note/${searchItem.crmId}?entity-document-id=${searchItem.documentId}`);
        }
        // parent id is type salesforce ID
        else if (searchItem.parent && searchItem.parent.id) {
          setIsLoading(true);
          const ed = await documentStore.getOrCreateNewSFEntity(searchItem.parent.id, searchItem.parent.type);
          setIsLoading(false);
          if (ed) {
            navigate(`/crm-note/${searchItem.crmId}?entity-document-id=${ed.id}`);
            modalStore.close();
          }
        }
      }}
    >
      <SalesforceNoteIcon className="w-4 h-4 text-gray-500" />
      <span className="truncate">{searchItem.title}</span>
      {isLoading && (
        <span>
          <SpinLoader className="animate-spin w-5 h-5 text-orange-500" />
        </span>
      )}
    </button>
  );
}

function SearchItemView({ searchItem }: Props) {
  if (searchItem.type === 'opportunity') {
    return <OpportunitySearchItemView searchItem={searchItem} />;
  }

  if (searchItem.type === 'account') {
    return <AccountSearchItemView searchItem={searchItem} />;
  }

  if (searchItem.type === 'document') {
    return <DocumentSearchItemView searchItem={searchItem} />;
  }

  if (searchItem.type === 'event') {
    return <EventSearchItemView searchItem={searchItem} />;
  }

  if (searchItem.type === 'crm-note') {
    return <CrmNoteSearchItemView searchItem={searchItem} />;
  }

  return <div>Unknown</div>;
}

export default SearchItemView;
