/* eslint-disable react/jsx-props-no-spreading, react/no-array-index-key */
/* eslint-disable react/destructuring-assignment, no-nested-ternary */
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { MenuItem, Select, SelectProps } from '@mui/material';
import { ListItem } from 'types/list-item';
import { concat } from 'utils/styling';
import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/outline';
import styles from './SelectField.module.css';
import SpinLoader from 'components/icons/SpinLoader';
import { isNil, omit } from 'lodash';
import InputField from './InputField';
import BaseButton from 'components/BaseButton';
import getPopoverInfo, { CustomPopoverInfo } from 'utils/popover';

function LoadingSpinner({ className }: { className?: string }) {
  return <SpinLoader className={concat('!w-6 !h-6 animate-spin !text-orange-500', styles.loadingSpinner, className)} />;
}

LoadingSpinner.defaultProps = {
  className: '',
};

export type SelectMenuValueType = string | number;

interface Props extends Omit<SelectProps, 'IconComponent' | 'onChange'> {
  menus:
    | ListItem<SelectMenuValueType>[]
    | ((search?: string) => Promise<ListItem<SelectMenuValueType>[]> | ListItem<SelectMenuValueType>[]);
  loading?: boolean;
  menuClassName?: string;
  valueClassName?: string;
  renderMenu?: (menu: ListItem<SelectMenuValueType>, isDisplayValue: boolean) => React.ReactNode;
  showClearBtn?: boolean;
  onChange?: (
    newV: SelectMenuValueType | undefined | null,
    selectedMenu: ListItem<SelectMenuValueType> | undefined | null,
  ) => void;
  searchAble?: boolean;
  localSearchAble?: boolean;
  // sometimes we not loaded value menu item in the list(for example, due to pagination),
  // we show this default menu whose value same as current select value
  defaultMenu?: ListItem<SelectMenuValueType>;
  popoverMinWidth?: number;
  isError?: boolean;
  clearValueLabel?: string;
  errorLabel?: string;
}

const mergeWithDefaultMenu = (
  menus?: ListItem<SelectMenuValueType>[],
  defaultMenu?: ListItem<SelectMenuValueType>,
): ListItem<SelectMenuValueType>[] | undefined => {
  const exist = !!menus?.find(menu => menu.value === defaultMenu?.value);
  return !defaultMenu || exist ? menus : [defaultMenu, ...(menus || [])];
};

const SelectField = React.forwardRef<HTMLInputElement, Props>((props: Props, ref) => {
  const [open, setOpen] = useState(false);
  const selectRef = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const inputValueRef = useRef('');
  const [loadingMenus, setLoadingMenus] = useState<boolean>();
  const [menuItems, setMenuItems] = useState<ListItem<SelectMenuValueType>[] | undefined>(
    mergeWithDefaultMenu(props.menus instanceof Array ? props.menus : undefined, props.defaultMenu),
  );
  const [selectedMenu, setSelectedMenu] = useState<ListItem<SelectMenuValueType>>();
  const [popoverInfo, setPopoverInfo] = useState<CustomPopoverInfo>({});

  const placeholderLabel = useMemo(
    // eslint-disable-next-line no-nested-ternary
    () =>
      loadingMenus || props.loading
        ? 'Loading...'
        : !menuItems?.length
        ? props.errorLabel || 'No Options'
        : props.placeholder,
    [props.placeholder, menuItems, loadingMenus, props.loading, props.errorLabel],
  );
  const menusLoadedRef = useRef(false);
  const [currentSearchStr, setCurrentSearchStr] = useState('');

  const loadMenus = (searchStr?: string) => {
    if (typeof props.menus === 'function') {
      const ret = props.menus(searchStr?.toLowerCase().trim());
      setCurrentSearchStr(searchStr?.trim() || '');
      if (ret instanceof Promise) {
        setLoadingMenus(true);
        ret.then(rsp => setMenuItems(rsp)).finally(() => setLoadingMenus(false));
      } else {
        setMenuItems(mergeWithDefaultMenu(ret, props.defaultMenu));
      }
    } else {
      setMenuItems(mergeWithDefaultMenu(props.menus, props.defaultMenu));
    }
  };

  useEffect(() => {
    // just load once when menus is function
    if (!menusLoadedRef.current || typeof props.menus !== 'function') {
      loadMenus();
    }
    menusLoadedRef.current = true;
  }, [props.menus]);

  useEffect(() => {
    if (props.disabled && open) {
      setOpen(false);
    }
  }, [props.disabled]);

  useEffect(() => {
    const item = menuItems?.find(it => it.value === props.value);
    if (item) {
      setSelectedMenu(item);
    } else {
      setSelectedMenu(
        !isNil(props.value) && props.value !== '' && props.defaultMenu?.value === props.value
          ? props.defaultMenu
          : undefined,
      );
    }
  }, [menuItems, props.value]);

  const allMenusHidden = useMemo(
    () =>
      props.localSearchAble &&
      !!menuItems &&
      menuItems.every(
        menu =>
          !menu.label.toLowerCase().includes(currentSearchStr) &&
          !menu.description?.toLowerCase().includes(currentSearchStr),
      ),
    [menuItems, currentSearchStr],
  );

  return (
    <Select
      inputRef={ref}
      ref={selectRef}
      {...omit(
        props,
        'loading',
        'showClearBtn',
        'menuClassName',
        'valueClassName',
        'renderMenu',
        'menus',
        'onChange',
        'searchAble',
        'localSearchAble',
        'researchAfterType',
        'defaultMenu',
        'popoverMinWidth',
        'isError',
        'clearValueLabel',
        'errorLabel',
        'multiline',
      )}
      value={
        (props.multiple ? !(props.value instanceof Array) || !props.value.length : isNil(props.value)) ||
        !menuItems?.length
          ? props.multiple
            ? []
            : ''
          : props.value
      }
      onChange={event =>
        props.onChange?.(
          event.target.value as SelectMenuValueType,
          menuItems?.find(menu => menu.value === event.target.value),
        )
      }
      disabled={props.disabled || props.loading || loadingMenus || !menuItems?.length}
      className={concat(
        styles.select,
        (props.multiple ? !(props.value instanceof Array) || !props.value.length : !props.value) && styles.noValue,
        props.isError && styles.error,
        props.multiline && styles.multiline,
        props.className,
      )}
      IconComponent={props.loading || loadingMenus ? LoadingSpinner : ChevronDownIcon}
      placeholder={placeholderLabel}
      onOpen={() => {
        setPopoverInfo(getPopoverInfo(selectRef.current, 'left', undefined, undefined, 16, props.popoverMinWidth));
        setTimeout(() => {
          if (inputRef.current) {
            inputRef.current.value = inputValueRef.current;
            inputRef.current.focus();
          }
        });
        setOpen(true);
      }}
      onClose={() => setOpen(false)}
      open={open}
      MenuProps={{
        className: `select-field-dropdown ${props.searchAble || props.localSearchAble ? 'with-input' : ''}`,
        ...popoverInfo,
      }}
      renderValue={value => {
        if (props.multiple) {
          const selectedMenus =
            props.value instanceof Array
              ? (props.value
                  .map(item => menuItems?.find(menu => menu.value === item))
                  .filter(menu => menu) as ListItem<SelectMenuValueType>[])
              : [];
          return (
            <div className={concat(styles.menu, props.menuClassName, props.valueClassName)}>
              {props.renderMenu ? (
                selectedMenus.map(menu => <Fragment key={menu.value}>{props.renderMenu?.(menu, true)}</Fragment>)
              ) : (
                <span className="truncate">{selectedMenus.map(menu => menu?.label).join(', ')}</span>
              )}
            </div>
          );
        }
        if (selectedMenu && selectedMenu.value === value) {
          return (
            <div className={concat(styles.menu, props.menuClassName, props.valueClassName)}>
              {props.renderMenu ? (
                props.renderMenu(selectedMenu, true)
              ) : (
                <>
                  {selectedMenu.icon}
                  <span className="truncate">{selectedMenu.label}</span>
                </>
              )}
            </div>
          );
        }
        return '';
      }}
    >
      {(props.searchAble || props.localSearchAble) && (
        <InputField
          placeholder="Type to search"
          disabled={loadingMenus || props.loading}
          onKeyDown={e => {
            e.stopPropagation();
            if (e.key === 'Enter' && !props.localSearchAble) {
              const { value } = e.target as HTMLInputElement;
              inputValueRef.current = value;
              loadMenus(value);
            }
          }}
          onChange={e => {
            if (props.localSearchAble) {
              const { value } = e.target as HTMLInputElement;
              inputValueRef.current = value;
              setCurrentSearchStr(value.trim().toLowerCase());
            }
          }}
          defaultValue=""
          ref={inputRef}
          InputProps={{
            endAdornment: loadingMenus ? <LoadingSpinner className="mr-2" /> : null,
          }}
          size="small"
        />
      )}
      {menuItems?.map((menu, index) => (
        <MenuItem
          key={index}
          value={menu.value}
          className={concat(
            '!pl-3',
            props.localSearchAble &&
              !menu.label.toLowerCase().includes(currentSearchStr) &&
              !menu.description?.toLowerCase().includes(currentSearchStr) &&
              '!hidden',
          )}
          disabled={menu.disabled}
        >
          <div className={concat(styles.menu, props.menuClassName)}>
            {props.renderMenu ? (
              props.renderMenu(menu, false)
            ) : (
              <>
                {menu.icon}
                <span className="truncate">{menu.label}</span>
              </>
            )}
          </div>
          {props.showClearBtn &&
            (props.multiple
              ? props.value instanceof Array && props.value.includes(menu.value)
              : props.value === menu.value) && (
              <BaseButton
                tooltip={props.clearValueLabel || 'Clear value'}
                iconBtn
                className={concat(styles.clearBtn, '!min-w-[1.25rem] !w-5 !h-5')}
                aria-label={props.clearValueLabel || 'Clear value'}
                color="primary"
                variant="contained"
                onClick={() => props.onChange?.(undefined, undefined)}
              >
                <XMarkIcon width={20} />
              </BaseButton>
            )}
        </MenuItem>
      ))}
      {(props.searchAble || props.localSearchAble) &&
        !!currentSearchStr &&
        (props.localSearchAble ? allMenusHidden : !menuItems?.length) &&
        !loadingMenus && (
          <div className="flex items-center justify-center px-4 py-8 text-center" role="alert">
            No results found for {currentSearchStr}
          </div>
        )}
    </Select>
  );
});

SelectField.displayName = 'SelectField';

SelectField.defaultProps = {
  menuClassName: undefined,
  valueClassName: undefined,
  loading: false,
  renderMenu: undefined,
  showClearBtn: false,
  searchAble: false,
  localSearchAble: false,
  onChange: undefined,
  defaultMenu: undefined,
  popoverMinWidth: undefined,
  isError: undefined,
  clearValueLabel: undefined,
  errorLabel: undefined,
};

export default SelectField;
