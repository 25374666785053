import { S3FileUploadObject } from 'types/file';
import httpClient from '../http-client';

export default async (fileName: string, fileType: string): Promise<S3FileUploadObject> => {
  const { data } = await httpClient.post<S3FileUploadObject>('/file/upload/start', {
    fileName,
    fileType,
  });
  return data;
};
