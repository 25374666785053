import { BaseButtonProps } from 'components/BaseButton';

export const ModalCloseAction = 'close';
export const ModalConfirmAction = 'confirm';
export const ModalIndeterminateAction = 'indeterminate';

export type ModalResponseAction = typeof ModalCloseAction | typeof ModalConfirmAction | typeof ModalIndeterminateAction;
export type ModalResponseType = ModalResponseAction | object;

export type ModalAction = Pick<BaseButtonProps, 'variant' | 'color' | 'type'> & {
  label: string;
  action: ModalResponseAction;
  cb?: () => void | Promise<unknown>;
  icon?: React.ReactNode;
  className?: string;
  disabled?: boolean;
};
