import { EntityName } from './entity';

export interface AIWorkerScope {
  Account: [string];
  Opportunity?: [string];
  Note: [string];
  CrmNote?: [string];
  File?: [string];
}

export interface AIWorker {
  id: string;
  text: string;
  scopeObj: AIWorkerScope;
  status: 'complete' | 'running' | 'failed' | 'new';
  failedReason?: string;
  result?: Record<string, object>;
  documentId: string;
  isDeleted: boolean;
  isTemplate: boolean;
}

export type AIWorkerScopeRowType = EntityName | 'Note' | 'CrmNote' | 'File';
export interface AIWorkerScopeRow {
  typeName: AIWorkerScopeRowType;
  id: string;
  label: string;
}

export interface SearchAndCompose {
  search_phrases: string[];
  writing_requirements: string[];
}
export interface WorkerContextMetadata {
  type: string;
}
export interface WorkerContextRow {
  id: string;
  text: string;
  metadata: WorkerContextMetadata;
}

export interface WorkerContext {
  searchTip: string;
  composeTip: string;
  searchAndCompose: SearchAndCompose;
  contexts: WorkerContextRow[];
}

export interface UpdateWorkerContextReq {
  rules: string[];
}

export const getDefaultScopeObj = (): AIWorkerScope => ({
  Account: ['all'],
  Opportunity: ['all'],
  Note: ['all'],
  CrmNote: ['all'],
  File: ['all'],
});

export const getEmptyAIWorker = (): AIWorker => {
  return {
    id: '',
    text: '',
    documentId: '',
    scopeObj: getDefaultScopeObj(),
    status: 'new',
    result: {},
    isDeleted: false,
    isTemplate: false,
  };
};
