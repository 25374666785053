import React from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';

import useRecordingsStore from 'stores/recordings';

import RecordingTableRow from './RecordingTableRow';
import Loader from 'components/Loader';

interface Props {
  loading: boolean;
  onClose: () => void;
  // eslint-disable-next-line react/require-default-props
}

function RecordingTable({ loading, onClose }: Props) {
  const recordingsStore = useRecordingsStore();

  const recordingRowsData = recordingsStore.recordings.map((recording, idx) => {
    return <RecordingTableRow key={recording.id} recording={recording} idx={idx} />;
  });

  const recordingTable: JSX.Element = (
    <table className="w-full" cellSpacing={0}>
      <thead>
        <tr className="text-gray-500 text-sm font-semibold bg-gray-100">
          {/* empty recording index */}
          <td className="w-1/12 py-3" />
          <td className="w-5/12 py-3 px-2 text-gray-600">Title</td>
          <td className="w-5/12 py-3 text-gray-600">
            <div className="flex justify-center">Details</div>
          </td>
          {/* <td className="w-1/12 py-3" /> */}
        </tr>
      </thead>
      <tbody className="w-full">{recordingRowsData}</tbody>
    </table>
  );

  return (
    <div className="p-4 w-full h-full flex flex-col overflow-y-auto">
      <div>
        <div className="flex justify-between items-center text-lg font-medium">
          <div>Recordings</div>
          <div>
            <button type="button" className="w-8 h-8 p-2 text-gray-400 bg-gray-100 rounded-lg" onClick={onClose}>
              <XMarkIcon />
            </button>
          </div>
        </div>
        {!loading && (
          <div className="my-2 text-gray-400 text-sm">
            All the meetings that have been recorded so far for this account
          </div>
        )}
      </div>
      {loading ? (
        <div className="w-full m-auto flex justify-center items-center flex-1">
          <Loader />
        </div>
      ) : (
        recordingTable
      )}
    </div>
  );
}

export default RecordingTable;
