import { Descendant } from 'slate';
import { Account, Opportunity } from './entity';
import { createNode } from 'components/NoteEditor/utils';
import { BlockType } from 'components/NoteEditor/types';

export interface Task {
  id: string;
  content: Descendant[];
  assignedToUserId: string;
  createdById: string;
  dueDate: string;
  done: boolean;
  createdAt: string;
  modifiedAt: string;
  documentId: string;
  account?: Account;
  opportunity?: Opportunity;
  isDeleted: boolean;
  isTemplateTask: boolean;
}

export const getEmptyTask = (): Task => {
  return {
    id: '',
    content: [createNode(BlockType.Paragraph)],
    assignedToUserId: '',
    createdById: '',
    dueDate: '',
    done: false,
    createdAt: '',
    modifiedAt: '',
    documentId: '',
    account: undefined,
    opportunity: undefined,
    isDeleted: false,
    isTemplateTask: false,
  };
};
