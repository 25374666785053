/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events, react/no-array-index-key */
import React, { useMemo } from 'react';
import UserProfileAvatar from 'components/UserProfileAvatar';
import colours from 'components/colours.module.css';
import styles from './MeetingAttendees.module.css';
import { concat } from 'utils/styling';
import Tooltip from 'components/Tooltip';
import { uniqBy } from 'lodash';

export const AVATAR_BG_COLORS = [
  colours['blue-400'],
  colours['yellow-400'],
  colours['neutrals-gray-alt'],
  colours['grey-300'],
];

interface Props {
  maxAttendee: number;
  meetingAttendees: { name: string; avatar: string; email: string }[];
  className?: string;
}

function MeetingAttendees({ maxAttendee, meetingAttendees, className }: Props) {
  const attendees = useMemo(() => {
    return uniqBy(meetingAttendees ?? [], 'email').map(attendee => {
      return {
        name: attendee.name,
        avatar: attendee.avatar,
        email: attendee.email,
      };
    });
  }, [meetingAttendees]);

  return attendees.length ? (
    <div className={concat(styles.attendeesList, className)}>
      {attendees.slice(0, maxAttendee).map((attendee, index) => (
        <UserProfileAvatar
          key={attendee.email + index}
          size={32}
          fullName={attendee.name}
          avatarUrl={attendee.avatar}
          bgColor={AVATAR_BG_COLORS[index % AVATAR_BG_COLORS.length]}
          className={styles.attendee}
        />
      ))}
      {attendees.length > maxAttendee && (
        <Tooltip
          title={
            <>
              {attendees.slice(maxAttendee).map(attendee => (
                <div key={attendee.email}>{attendee.name}</div>
              ))}
            </>
          }
          placement="bottom"
        >
          <div
            className={concat(styles.attendee, styles.extraAttendees)}
            style={{ backgroundColor: AVATAR_BG_COLORS[(attendees.length - 1) % AVATAR_BG_COLORS.length] }}
            onClick={e => e.stopPropagation()}
          >
            +{attendees.length - maxAttendee}
          </div>
        </Tooltip>
      )}
    </div>
  ) : null;
}

MeetingAttendees.defaultProps = {
  className: '',
};

export default MeetingAttendees;
