import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { LockClosedIcon } from '@heroicons/react/24/outline';
import { useParams } from 'react-router-dom';

import errors from 'utils/errors';

import Loader from 'components/Loader';

import useDocumentStore from 'stores/document';
import useFieldValuesStore from 'stores/field-values';
import useRecordingsStore from 'stores/recordings';
import useProductGapStore from 'stores/product-gap';
import useChatsStore from 'stores/chats';

import useSuggestedAnswer from 'hooks/useSuggestedAnswer';

import { Document, NoteService } from 'types/document';

import useRecording from 'hooks/useRecording';
import DocumentView from './Document/DocumentView';
import styles from './Document.module.css';
import RightDrawerPanel from 'components/RightDrawerPanel';

interface State {
  isLoading: boolean;
  isAuthorized: boolean;
  noteService: NoteService | null;
}

function DocumentC() {
  const { documentId } = useParams();
  useRecording(documentId);
  useSuggestedAnswer();

  const documentStore = useDocumentStore();
  const fieldValuesStore = useFieldValuesStore();
  const recordingsStore = useRecordingsStore();
  const productGapStore = useProductGapStore();
  const chatsStore = useChatsStore();

  const [state, setState] = useState<State>({
    isLoading: false,
    isAuthorized: true,
    noteService: null,
  });
  const fetchAdditionalData = useCallback((document: Document) => {
    fieldValuesStore.fetch(document.id);
    recordingsStore.fetch(document.id);
    productGapStore.getProductFields();
    if (document.parent) {
      chatsStore.fetch(document.id);
    }
  }, []);

  const fetchDocument = useCallback(async (docId: string) => {
    setState(prevState => ({ ...prevState, isLoading: true, isAuthorized: true }));
    try {
      const { noteService, document } = await documentStore.getDocument(docId);
      fetchAdditionalData(document);
      if (document.parent && document.parent.crmId) {
        // This logic is to fix the issue where the mismatch of entityStore happens
        // If the entityStore is missing, we ask to be fetched
        const { parent } = document;
        if (parent.crmId) {
          await documentStore.getOrCreateNewSFEntity(parent.crmId, parent.entityName);
        }
      }
      setState(prevState => ({ ...prevState, noteService }));
    } catch (e) {
      if (e instanceof errors.PermissionError) {
        setState(prevState => ({ ...prevState, isAuthorized: false }));
      }
    }

    setState(prevState => ({ ...prevState, isLoading: false }));
  }, []);

  useEffect(() => {
    if (documentId && !documentStore.isLoading) {
      fetchDocument(documentId);
    }
    return () => {
      fieldValuesStore.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId, documentStore.isLoading]);

  const document = useMemo(
    () => documentStore.documents.find(d => d.id === documentId),
    [documentStore.documents, documentId],
  );

  if (state.isLoading || !document || !state.noteService) {
    return (
      <div className={styles['document-loader']}>
        <Loader />
      </div>
    );
  }

  if (!state.isAuthorized) {
    return (
      <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 text-gray-400">
        <div className="flex flex-col items-center justify-center">
          <div>
            <LockClosedIcon className="w-32 h-32" />
          </div>
          <div className="text-sm">You do not have the required access to view this document.</div>
        </div>
      </div>
    );
  }

  const setLoading = async (isLoading: boolean) => {
    setState(prevState => ({ ...prevState, isLoading }));
  };

  return (
    <div className={styles['document-container']}>
      <DocumentView
        document={document}
        noteService={state.noteService}
        setLoading={setLoading}
        onUpdatedDocFolderByApi={() => documentId && fetchDocument(documentId)}
      />
      <RightDrawerPanel />
    </div>
  );
}

export default DocumentC;
