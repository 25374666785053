import React, { useRef, useState } from 'react';
import { Template as TemplateApi } from 'api/note-templates/get-note-templates';
import moment from 'moment';
import {
  ClockIcon,
  DocumentDuplicateIcon,
  EllipsisHorizontalIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { Menu, MenuItem, Tooltip } from '@mui/material';
import Grow from '@mui/material/Grow';
import { Emoji } from 'emoji-picker-react';
import SpinLoader from './icons/SpinLoader';
import UserAvatar from './UserAvatar';
import BaseCheckbox from './Form/BaseCheckbox';

interface Props {
  template: TemplateApi;
  showApply: boolean;
  disableApply: boolean;
  onEdit: (template: TemplateApi) => void;
  onPreview: (template: TemplateApi) => void;
  onApply: () => Promise<void>;
  onDelete: () => void;
  onDuplicate: () => void;
  onMetaToggle?: () => Promise<void>;
  isAdmin: boolean;
}
interface State {
  isMenuOpen: boolean;
  isConfirmDelete: boolean;
  isApplying: boolean;
  isMetaToggling: boolean;
}

function NoteTemplateElement({
  template,
  showApply,
  disableApply,
  onEdit,
  onPreview,
  onApply,
  onDelete,
  onDuplicate,
  onMetaToggle,
  isAdmin,
}: Props) {
  // create usestate for ismenuopen
  const [state, setState] = useState<State>({
    isMenuOpen: false,
    isConfirmDelete: false,
    isApplying: false,
    isMetaToggling: false,
  });
  const menuButtonRef = useRef<HTMLButtonElement>(null);
  let name = '';
  if (template.lastModifiedBy.firstName === '') {
    name = 'Superpanel';
  } else {
    name = `${template.lastModifiedBy.firstName} ${template.lastModifiedBy.lastName}`;
  }
  let fromNow = moment(template.lastModifiedAt).fromNow();
  //   if minutes or seconds included in fromNow
  if (fromNow.includes('minute') || fromNow.includes('second')) {
    fromNow = 'Just now';
  }

  let applicability: null | string = null;
  if (template.opportunityFieldCount > 0) {
    applicability = 'Opportunity Only';
  }

  return (
    <Grow in>
      <div className="flex flex-col border rounded-xl w-80">
        {/* header */}
        <div className="flex pt-3 px-3 justify-between items-center">
          {/* name */}
          <div className="flex gap-1 items-center">
            <UserAvatar user={template.lastModifiedBy} size="sm" />
            <span className="text-xs text-gray-400">{name}</span>
          </div>
          <Tooltip
            title={`${
              isAdmin
                ? 'Automatically load to new opportunities'
                : 'Only admin is allowed to set default template on opps'
            }`}
            placement="top"
          >
            <div>
              {!!onMetaToggle && (
                <BaseCheckbox
                  className={`${!isAdmin ? 'opacity-50 cursor-default' : ''} h-4 text-gray-400 text-xs`}
                  loading={state.isMetaToggling}
                  label="default on all opps"
                  checked={template.isMeta}
                  onChange={() => {
                    if (isAdmin) {
                      setState(prevState => ({ ...prevState, isMetaToggling: true }));
                      onMetaToggle().finally(() => {
                        setState(prevState => ({ ...prevState, isMetaToggling: false }));
                      });
                    }
                  }}
                />
              )}
            </div>
          </Tooltip>
        </div>
        {/* title */}
        <div className="flex items-start p-3 gap-1 flex-1">
          <span className="mr-1 mt-[1px] text-xl flex items-center">
            {template.icon ? <Emoji unified={template.icon} size={18} /> : <Emoji unified="1f5d2-fe0f" size={18} />}
          </span>
          <div className="flex gap-1 grow items-center">
            <span className="font-medium text-sm">{template.name}</span>
            {applicability && <span className="grow font-medium text-xs text-gray-400">({applicability})</span>}
          </div>
        </div>
        {/* footer */}
        <div className="flex justify-between p-3 text-xs border-t text-gray-400 group h-11">
          <div className="flex items-center gap-1 group-hover:hidden">
            <ClockIcon className="w-4 h-4" />
            {fromNow}
          </div>
          <div className="items-center gap-1 hidden group-hover:block">
            <div className="flex gap-2">
              <button
                type="button"
                className="py-1 px-2 text-blue-400 bg-blue-100 rounded h-6"
                onClick={() => {
                  onPreview(template);
                }}
              >
                Preview
              </button>
              {showApply && (
                <button
                  type="button"
                  className={`flex items-center gap-2 py-1 px-2 rounded ${
                    disableApply || state.isApplying
                      ? 'bg-gray-200 text-gray-500 cursor-default'
                      : 'bg-blue-500 text-white'
                  }`}
                  onClick={async () => {
                    setState(prevState => ({ ...prevState, isApplying: true }));
                    await onApply();
                    setState(prevState => ({ ...prevState, isApplying: false }));
                  }}
                  disabled={disableApply || state.isApplying}
                >
                  {state.isApplying && <SpinLoader className="animate-spin w-4 h-4" />}
                  Apply
                </button>
              )}
            </div>
          </div>
          <div className="flex">
            <button
              className="flex items-center px-1 text-sm rounde hover:bg-gray-100 rounded text-gray-400"
              type="button"
              onClick={() => setState({ ...state, isMenuOpen: true })}
              ref={menuButtonRef}
            >
              <EllipsisHorizontalIcon className="w-5 h-5" />
            </button>
            <Menu
              anchorEl={menuButtonRef.current}
              open={state.isMenuOpen}
              onClose={() =>
                setState({
                  ...state,
                  isMenuOpen: false,
                  isConfirmDelete: false,
                })
              }
            >
              <MenuItem
                onClick={() => {
                  onEdit(template);
                }}
              >
                <div className="w-36 flex items-center gap-2 hover:bg-gray-100 rounded">
                  <PencilSquareIcon className="w-4 h-4 text-gray-400" />
                  <div className="text-xs text-gray-500">Edit</div>
                </div>
              </MenuItem>
              <MenuItem className="flex items-center gap-2 hover:bg-gray-100 rounded" onClick={onDuplicate}>
                <DocumentDuplicateIcon className="w-4 h-4 text-gray-400" />
                <div className="text-xs text-gray-500">Duplicate</div>
              </MenuItem>
              {!state.isConfirmDelete && (
                <MenuItem
                  className="flex items-center gap-2 hover:bg-gray-100 rounded"
                  onClick={() => {
                    setState({
                      ...state,
                      isConfirmDelete: true,
                    });
                  }}
                >
                  <TrashIcon className="w-4 h-4 text-gray-400" />
                  <div className="text-xs text-gray-500">Delete</div>
                </MenuItem>
              )}
              {state.isConfirmDelete && (
                <div className="flex justify-between mx-4 my-2">
                  <button
                    type="button"
                    className="bg-gray-100 rounded text-xs px-2 py-1"
                    onClick={() => {
                      setState({
                        ...state,
                        isConfirmDelete: false,
                      });
                    }}
                  >
                    cancel
                  </button>
                  <button type="button" className="bg-blue-500 rounded text-xs px-2 py-1 text-white" onClick={onDelete}>
                    delete
                  </button>
                </div>
              )}
            </Menu>
          </div>
        </div>
      </div>
    </Grow>
  );
}

NoteTemplateElement.defaultProps = {
  onMetaToggle: undefined,
};

export default NoteTemplateElement;
