import React from 'react';

interface Props {
  className?: string;
}
function InfoIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.25 20C16.5302 20 20 16.5302 20 12.25C20 7.96979 16.5302 4.5 12.25 4.5C7.96979 4.5 4.5 7.96979 4.5 12.25C4.5 16.5302 7.96979 20 12.25 20Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 9.74639C13 10.1606 12.6642 10.4964 12.25 10.4964C11.8358 10.4964 11.5 10.1606 11.5 9.74639V9.15039C11.5 8.73618 11.8358 8.40039 12.25 8.40039C12.6642 8.40039 13 8.73618 13 9.15039V9.74639ZM13 15.8274C13 16.2417 12.6642 16.5774 12.25 16.5774C11.8358 16.5774 11.5 16.2417 11.5 15.8274V12.7274C11.5 12.3132 11.8358 11.9774 12.25 11.9774C12.6642 11.9774 13 12.3132 13 12.7274V15.8274Z"
        fill="currentColor"
      />
    </svg>
  );
}

InfoIcon.defaultProps = {
  className: undefined,
};

export default InfoIcon;
