/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps, import/no-cycle */
import NoteEditor from 'components/NoteEditor/NoteEditor';
import {
  BlockType,
  CustomElement,
  TableCellElement as TableCellElementType,
  TableRowElement as TableRowElementType,
} from 'components/NoteEditor/types';
import React, { memo, useCallback, useMemo, useRef } from 'react';
import { Editor, Transforms, Node } from 'slate';
import { ReactEditor, RenderElementProps } from 'slate-react';
import useNoteEditorTableOperationStore from 'stores/note-editor-table-operation';
import { concat } from 'utils/styling';
import styles from './Table.module.css';
import TableCellMenuBtn from './TableCellMenuBtn';
import useEditorSelectedFocused from 'hooks/useEditorSelectedFocused';

export const MIN_TABLE_CELL_WIDTH = 40;
export const DEFAULT_TABLE_CELL_WIDTH = 120;

interface Props extends RenderElementProps {
  element: TableCellElementType;
  editor: Editor;
}

function TableCellElement({ attributes, children, element: tableCellElement, editor }: Props) {
  const noteEditorStore = useNoteEditorTableOperationStore();
  const selected = useEditorSelectedFocused();
  const path = ReactEditor.findPath(editor, tableCellElement);
  const rowElement = useMemo(() => {
    const parentPath = path.slice(0, -1);
    return Node.has(editor, parentPath) ? (Node.get(editor, parentPath) as TableRowElementType) : null;
  }, [path]);

  const startDragX = useRef(0);
  const startDragCellWidth = useRef(0);

  const handleDragResize = useCallback(
    (e: MouseEvent) => {
      const targetWidth = startDragCellWidth.current + (e.clientX - startDragX.current);
      const colCells = NoteEditor.sp_table_GetColCellPaths(editor, path);
      const newProperties: Partial<TableCellElementType> = {
        width: Math.max(targetWidth, MIN_TABLE_CELL_WIDTH),
      };
      colCells.forEach(cellPath => Transforms.setNodes(editor, newProperties, { at: cellPath }));
    },
    [path],
  );

  const endDragResize = useCallback(() => {
    document.removeEventListener('mousemove', handleDragResize);
    document.removeEventListener('mouseup', endDragResize);
    useNoteEditorTableOperationStore.setState({ tableDraggingRowIndex: {} });
  }, [path]);

  const startDragResize = useCallback(
    (e: React.MouseEvent) => {
      startDragX.current = e.clientX;
      startDragCellWidth.current = tableCellElement.width || 0;
      useNoteEditorTableOperationStore.setState({
        tableDraggingRowIndex: { [path[path.length - 3]]: path[path.length - 1] },
      });
      document.addEventListener('mousemove', handleDragResize);
      document.addEventListener('mouseup', endDragResize);
    },
    [path],
  );

  return (
    <div
      {...attributes}
      className={concat(
        styles['table-cell'],
        noteEditorStore.tableDraggingRowIndex[path[path.length - 3]] === path[path.length - 1] ? styles.dragging : '',
        noteEditorStore.tableFocusedColIndex[path[path.length - 3]] === path[path.length - 1]
          ? styles['col-focused']
          : '',
        noteEditorStore.tableHoverColIndex[path[path.length - 3]] === path[path.length - 1]
          ? styles['col-hovered']
          : '',
        selected &&
          noteEditorStore.tableFocusedRowIndex[path[path.length - 3]] === undefined &&
          noteEditorStore.tableFocusedColIndex[path[path.length - 3]] === undefined
          ? styles.selected
          : '',
      )}
      onMouseEnter={() => {
        useNoteEditorTableOperationStore.setState({
          tableHoverRowIndex: { [path[path.length - 3]]: path[path.length - 2] },
          tableHoverColIndex: { [path[path.length - 3]]: path[path.length - 1] },
        });
      }}
      style={{ width: `${tableCellElement.width}px` }}
    >
      <div className="py-2 px-2">{children}</div>
      <button
        type="button"
        className={styles['cell-width-adjust-handler']}
        contentEditable={false}
        onMouseDown={startDragResize}
      />
      {path[path.length - 2] === 0 && (
        <div contentEditable={false} style={{ userSelect: 'none' }}>
          <div className={styles['float-icon-btn-wrap']}>
            <TableCellMenuBtn
              OnAdd={offset => {
                const currentPath = ReactEditor.findPath(editor, tableCellElement);
                const [currentNode] = NoteEditor.node(editor, currentPath);
                const colCells = NoteEditor.sp_table_GetColCellPaths(editor, currentPath);
                Editor.withoutNormalizing(editor, () => {
                  colCells.forEach(cellPath => {
                    const insertPath = [...cellPath];
                    insertPath[insertPath.length - 1] = insertPath[insertPath.length - 1] + offset;
                    Transforms.insertNodes(
                      editor,
                      {
                        type: (currentNode as TableCellElementType).type,
                        width: (currentNode as TableCellElementType).width,
                        children: [{ text: '' }],
                      } as TableCellElementType,
                      {
                        at: insertPath,
                      },
                    );
                  });
                });
                useNoteEditorTableOperationStore.setState({
                  tableFocusedRowIndex: {},
                  tableFocusedColIndex: {},
                });
              }}
              OnDelete={() => {
                // delete whole column
                const colCells = NoteEditor.sp_table_GetColCellPaths(editor, path);
                const nodeIdx = colCells[0][0];
                // delete cell from a column in batch
                // it won't work if we delete cell one by one
                // because withNormalizeNode will add the missing cell back to the table
                // this won't work: colCells.forEach(cellPath => Transforms.removeNodes(editor, { at: cellPath }));
                Transforms.removeNodes(editor, {
                  at: [nodeIdx],
                  match: (node, p) => {
                    if (
                      (node as CustomElement).type === BlockType.TableCell &&
                      colCells.find(cc => NoteEditor.sp_pathEquals(cc, p))
                    ) {
                      return true;
                    }
                    return false;
                  },
                });
                useNoteEditorTableOperationStore.setState({
                  tableFocusedRowIndex: {},
                  tableFocusedColIndex: {},
                });
              }}
              OnToggleVisible={visible =>
                useNoteEditorTableOperationStore.setState({
                  tableFocusedColIndex: visible ? { [path[path.length - 3]]: path[path.length - 1] } : {},
                  tableFocusedRowIndex: {},
                })
              }
              type="row"
              canDelete={!!rowElement?.children?.length && rowElement.children.length > 1}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default memo(TableCellElement);
