import React, { useEffect, useState } from 'react';
import { Checkbox, Tooltip } from '@mui/material';
import { SalesEngineerSummary } from '../../types/team-member-request';
import getSalesEngineerSummaries from 'api/team-member-requests/get-sales-engineer-summaries';
import LinkUserPopoverContent from './LinkUserPopoverContent';
import linkUserAsSalesEngineer from '../../api/team-member-requests/link-user-as-sales-engineer';
import { SalesforceFieldType } from '../../types/salesforce';
import SpinLoader from '../../components/icons/SpinLoader';
import useTeamMemberRequestStore from '../../stores/team-member-request';
import { Field, FieldValue } from '../../types/field-value';
import usePopupMessageStore from '../../stores/popup-message';
import ButtonPopover from '../../components/ButtonPopover';
import SettingIcon from '../../components/icons/SettingIcon';
import ConfigureFieldButtonPopover from './ConfigureFieldButtonPopover';
import LinkUsersToCRMButtonPopover from './LinkUsersToCRMButtonPopover';

interface Props {
  requestId: string;
  documentId: string;
  fieldValue: FieldValue;
  fields: Field[];
}

function AssignTeamMember({ requestId, documentId, fieldValue, fields }: Props) {
  const popupMessageStore = usePopupMessageStore();
  const teamMemberRequestStore = useTeamMemberRequestStore();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAssigning, setIsAssigning] = useState<boolean>(false);
  const [isConfigureMenuOpen, setIsConfigureMenuOpen] = useState<boolean>(false);
  const [salesEngineerSummaries, setSalesEngineerSummaries] = useState<SalesEngineerSummary[]>([]);

  useEffect(() => {
    getSalesEngineerSummaries(fieldValue.id).then(summaries => {
      setSalesEngineerSummaries(summaries);
      setIsLoading(false);
    });
  }, [fieldValue.id]);

  const linkCrmUser = async (userId: string, crmValue: string) => {
    if (fieldValue) {
      const salesEngineerSummary = await linkUserAsSalesEngineer(fieldValue.id, userId, crmValue);
      setSalesEngineerSummaries(prevState => {
        const index = prevState.findIndex(s => s.id === salesEngineerSummary.id);
        if (index === -1) {
          return prevState;
        }

        return [...prevState.slice(0, index), salesEngineerSummary, ...prevState.slice(index + 1)];
      });
      setIsConfigureMenuOpen(false);
    }
  };

  const onSelectTeamMember = (userId: string) => {
    const salesEngineerSummary = salesEngineerSummaries.find(s => s.id === userId);
    if (!salesEngineerSummary) return;

    const isMultiPicklist = fieldValue.field.dataType === SalesforceFieldType.MULTI_PICKLIST;
    setSalesEngineerSummaries(prevState => {
      return prevState.map(s => {
        if (s.id === userId) {
          return { ...s, isSelected: !s.isSelected };
        }
        if (!isMultiPicklist) return { ...s, isSelected: false };
        return s;
      });
    });
  };

  const assignTeamMember = async () => {
    setIsAssigning(true);
    const selectedSalesEngineers = salesEngineerSummaries.filter(s => s.isSelected);
    const selectedSalesEngineerIds = selectedSalesEngineers.map(s => s.id);
    await teamMemberRequestStore.assignRequestUsers(requestId, selectedSalesEngineerIds);
    setIsAssigning(false);
    popupMessageStore.success('You have successfully assigned the member(s)');
  };

  if (isLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <SpinLoader className="w-12 h-12 animate-spin mr-2" />
      </div>
    );
  }

  const selectedSalesEngineerIds = salesEngineerSummaries.filter(s => s.isSelected).map(s => s.id);

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="text-lg font-medium">Team Member Schedules</div>
        <div>
          <ButtonPopover
            isPopoverOpen={isConfigureMenuOpen}
            popoverToggle={show => setIsConfigureMenuOpen(show)}
            popoverContent={
              <div className="flex flex-col">
                <ConfigureFieldButtonPopover
                  btnClassName="!justify-start"
                  btnColor="secondary"
                  btnVariant="text"
                  documentId={documentId}
                  selectedId={fieldValue.field.id}
                  fields={fields}
                >
                  <div className="text-left">Configure Field</div>
                </ConfigureFieldButtonPopover>
                <LinkUsersToCRMButtonPopover
                  salesEngineerSummaries={salesEngineerSummaries}
                  onSelect={(v, s) => linkCrmUser(s.id, v)}
                />
              </div>
            }
          >
            <SettingIcon className="w-6 h-6 text-slate-500" />
          </ButtonPopover>
        </div>
      </div>
      <div className="py-2" />
      <div className="relative max-h-80 overflow-y-auto w-full">
        <table className="w-full divide-y border-collapse border rounded">
          <colgroup>
            <col className="w-1/12" />
            <col className="w-4/12" />
            <col className="w-2/12" />
            <col className="w-4/12" />
          </colgroup>
          <thead className="bg-slate-100 text-left text-gray-500 sticky top-0 z-10">
            <tr className="divide-x">
              <th scope="col" className="py-6">
                <span className="sr-only">Edit</span>
              </th>
              <th scope="col" className="px-3 font-medium">
                Sales Engineer
              </th>
              <th scope="col" className="px-3 font-medium">
                No. of Deals
              </th>
              <th scope="col" className="px-3 font-medium">
                <div>
                  No. of Meetings <span className="text-xs text-gray-400">(Next 2 weeks)</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y">
            {salesEngineerSummaries.map(s => {
              return (
                <tr key={s.id} className="divide-x">
                  <td className="py-2 px-2">
                    <Tooltip
                      title={s.deals === null ? 'You cannot assign without linking the user to CRM' : ''}
                      placement="top"
                    >
                      <span className={s.deals === null ? 'cursor-not-allowed' : ''}>
                        <Checkbox
                          disabled={s.deals === null}
                          size="small"
                          checked={selectedSalesEngineerIds.includes(s.id)}
                          onChange={() => onSelectTeamMember(s.id)}
                        />
                      </span>
                    </Tooltip>
                  </td>
                  <td className="px-3">
                    {s.firstName} {s.lastName}
                  </td>
                  <td className="px-3 text-right">
                    {s.deals === null ? (
                      <ButtonPopover
                        popoverContent={
                          <LinkUserPopoverContent
                            value=""
                            includedValues={
                              salesEngineerSummaries
                                .map(temp => temp.crmValue)
                                .filter(temp => temp !== null) as string[]
                            }
                            onSelect={crmValue => linkCrmUser(s.id, crmValue)}
                          />
                        }
                      >
                        Link user to CRM
                      </ButtonPopover>
                    ) : (
                      s.deals
                    )}
                  </td>
                  <td className="px-3 text-right">{s.meetings}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="py-4" />
      <div className="flex justify-end">
        <button
          type="button"
          className="flex items-center text-sm text-white px-3 py-2 rounded bg-blue-600 hover:bg-blue-500 disabled:bg-gray-400 disabled:cursor-not-allowed"
          disabled={selectedSalesEngineerIds.length === 0 || isAssigning}
          onClick={assignTeamMember}
        >
          <span>Assign Resource</span>
          {isAssigning && <SpinLoader className="ml-2 w-4 h-4 animate-spin" />}
        </button>
      </div>
    </>
  );
}

export default AssignTeamMember;
