import React from 'react';
import { ProductFieldAnswer } from 'types/product-gap';

interface Props {
  cellValue: ProductFieldAnswer | null;
}

function ProductCategoryCellValue({ cellValue }: Props) {
  if (!cellValue) return <div />;
  return (
    <div className="bg-slate-200 text-sm px-4 py-2 w-full h-full">
      <div className="truncate font-medium">{cellValue.title}</div>
    </div>
  );
}

export default ProductCategoryCellValue;
