import React from 'react';

import { PanelContext } from 'stores/context-panel';
import { EntityType } from 'types/entity';
import Breadcrumbs from './EntityHeader/Breadcrumbs';

import ContextPanelNavbar from './ContextPanelNavbar';
import SFLink from './EntityHeader/SFLink';

interface Props {
  entityType: EntityType;
  documentId: string;
  crmNoteId: string | null;
  panelContexts: PanelContext[];
  menuItems: React.ReactElement | null;
}

const EntityHeader = React.forwardRef<HTMLDivElement, Props>(
  ({ entityType, documentId, crmNoteId, panelContexts, menuItems }, ref) => {
    return (
      <div ref={ref} className="w-full border-b">
        <div className="px-5 pt-5">
          <div className="flex gap-3 items-center">
            <Breadcrumbs
              documentId={documentId}
              crmNoteId={crmNoteId}
              entityType={entityType}
              postfixElement={<SFLink type={entityType} documentId={documentId} crmNoteId={crmNoteId} />}
              className="pr-8"
            />
          </div>
          <div className="flex justify-between items-center">
            <ContextPanelNavbar contexts={panelContexts} />
            <div className="flex items-center gap-2 min-h-[36px]">{menuItems}</div>
          </div>
        </div>
      </div>
    );
  },
);

EntityHeader.displayName = 'EntityHeader';

export default EntityHeader;
