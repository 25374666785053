/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-classes-per-file, import/no-cycle */

import BackspacePlugin from './Plugins/BackspacePlugin';
import CodePlugin from './Plugins/CodePlugin';
import EmojiCommandPlugin from './Plugins/EmojiCommandPlugin';
import EnterKeyPlugin from './Plugins/EnterKeyPlugin';
import HotKeyPlugin from './Plugins/HotKeyPlugin';
import ItemListPlugin from './Plugins/ItemListPlugin';
import SlashCommandPlugin from './Plugins/SlashCommandPlugin';
import { SlashCommandMenu } from './Plugins/SlashCommnadPlugin/SlashCommandMenus';
import { SlatePlugin } from './Plugins/types';
import ImagePlugin from './Plugins/ImagePlugin';
import TabKeyPlugin from './Plugins/TabKeyPlugin';
import CopyPlugin from './Plugins/CopyPlugin';
import CutPlugin from './Plugins/CutPlugin';
import PastePlugin from './Plugins/PastePlugin';

export interface Plugin {
  initialize: (parentRef: HTMLDivElement | null) => SlatePlugin;
}

class BackSpace implements Plugin {
  key = 'back-space';

  initialize() {
    return BackspacePlugin;
  }
}

class Code implements Plugin {
  initialize() {
    return CodePlugin;
  }
}

class EmojiCommand implements Plugin {
  initialize(parentRef: HTMLDivElement | null) {
    return EmojiCommandPlugin(parentRef);
  }
}

class EnterKey implements Plugin {
  initialize() {
    return EnterKeyPlugin;
  }
}

class TabKey implements Plugin {
  initialize() {
    return TabKeyPlugin;
  }
}

class Copy implements Plugin {
  initialize() {
    return CopyPlugin;
  }
}

class Cut implements Plugin {
  initialize() {
    return CutPlugin;
  }
}

class Paste implements Plugin {
  initialize() {
    return PastePlugin;
  }
}

class HotKey implements Plugin {
  initialize() {
    return HotKeyPlugin;
  }
}

class ItemList implements Plugin {
  initialize() {
    return ItemListPlugin;
  }
}

class SlashCommand implements Plugin {
  menu: SlashCommandMenu[];

  constructor(menus: SlashCommandMenu[]) {
    this.menu = menus;
  }

  initialize(parentRef: HTMLDivElement | null) {
    return SlashCommandPlugin(parentRef, this.menu);
  }
}

class Image implements Plugin {
  initialize() {
    return ImagePlugin;
  }
}

export default {
  BackSpace,
  Code,
  EmojiCommand,
  EnterKey,
  TabKey,
  HotKey,
  ItemList,
  SlashCommand,
  Image,
  Copy,
  Cut,
  Paste,
};
