import React from 'react';
import { concat } from 'utils/styling';
import styles from './HtmlFileContentPreview.module.css';

interface Props {
  className?: string;
  fileContent: string;
}

function HtmlFileContentPreview({ className, fileContent }: Props) {
  // eslint-disable-next-line react/no-danger
  return <div className={concat(styles.docContainer, className)} dangerouslySetInnerHTML={{ __html: fileContent }} />;
}

HtmlFileContentPreview.defaultProps = {
  className: '',
};

export default HtmlFileContentPreview;
