import React from 'react';

interface Props {
  className?: string;
}

function UserIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5 19.5005V17.945C18.4991 17.5355 18.4175 17.1303 18.26 16.7523C18.1024 16.3744 17.872 16.0312 17.5818 15.7423C17.2916 15.4534 16.9474 15.2245 16.5687 15.0687C16.1901 14.9128 15.7845 14.8331 15.375 14.834H9.125C8.71554 14.8331 8.3099 14.9128 7.93126 15.0687C7.55261 15.2245 7.20837 15.4534 6.91819 15.7423C6.62801 16.0312 6.39756 16.3744 6.24002 16.7523C6.08248 17.1303 6.00092 17.5355 6 17.945V19.5005"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.25 11.722C13.9759 11.722 15.375 10.3292 15.375 8.611C15.375 6.89284 13.9759 5.5 12.25 5.5C10.5241 5.5 9.125 6.89284 9.125 8.611C9.125 10.3292 10.5241 11.722 12.25 11.722Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

UserIcon.defaultProps = {
  className: undefined,
};

export default UserIcon;
