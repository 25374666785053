import React, { useMemo } from 'react';
import { Field } from '../types';
import SalesforceIcon from '../../icons/SalesforceIcon';
import { Field as ApiField, isCRMField } from 'types/field-value';
import useUserStore from 'stores/user';
import Superpanel from '../../logos/Superpanel';
import AIIcon from '../../icons/AIIcon';
import FilterIcon from 'components/icons/FilterIcon';
import TeamMemberRequestIcon from 'components/icons/TeamMemberRequestIcon';
import Tooltip from '../../Tooltip';
import { concat } from 'utils/styling';
import { capitalizeName } from 'utils/string';
import { ShieldExclamationIcon } from '@heroicons/react/24/outline';

interface Props {
  field: Field;
  postfixElement: React.ReactNode;
}

function FieldView({ field, postfixElement }: Props) {
  const userStore = useUserStore();
  const itemClassName = useMemo(
    () => 'shrink-0 min-w-[24px] h-6 flex items-center justify-center gap-1 border rounded p-0.5 text-xs text-gray-500',
    [],
  );
  const fieldTypeLabel = useMemo(() => {
    return `${capitalizeName((field as ApiField).dataType || '')} field`;
  }, [field]);
  return (
    <div className="w-full flex">
      <div className="flex flex-wrap gap-x-2 gap-y-1 items-center">
        <div>{field.label}</div>
        {isCRMField(field as ApiField) && (
          <Tooltip title={userStore.user?.isIntegratedWithCrm ? 'Linked field' : 'Superpanel field'} placement="top">
            <div className={itemClassName}>
              {userStore.user?.isIntegratedWithCrm ? (
                <SalesforceIcon className="w-4 h-4 p-0.5" />
              ) : (
                <Superpanel className="w-4 h-4 p-0.5" />
              )}
              <span className="pr-1">{field.crmField.label}</span>
            </div>
          </Tooltip>
        )}
        {field.isAiEnabled && (
          <Tooltip title="Superpanel AI is assigned to this field" placement="top">
            <div className={itemClassName}>
              <AIIcon className="w-4 h-4" />
            </div>
          </Tooltip>
        )}
        {field.isUsedInMenuFilter && (
          <Tooltip title="Used in Menu Filter" placement="top">
            <div className={itemClassName}>
              <FilterIcon className="w-4 h-4" />
            </div>
          </Tooltip>
        )}
        {field.isUsedInTeamMemberRequest && (
          <Tooltip title="Used in Team Member Request Form" placement="top">
            <div className={itemClassName}>
              <TeamMemberRequestIcon className="w-4 h-4" />
            </div>
          </Tooltip>
        )}
        {field.isDefault && (
          <Tooltip title="Field is Mandatory" placement="top">
            <div className={itemClassName}>
              <ShieldExclamationIcon className="w-4 h-4" />
            </div>
          </Tooltip>
        )}
        <div className={concat(itemClassName, 'px-2.5')}>{fieldTypeLabel}</div>
      </div>
      {postfixElement}
    </div>
  );
}

export default FieldView;
