import React from 'react';

interface Props {
  className?: string;
}

function NoteIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8125 4.50001H7.5625C7.14978 4.49842 6.75332 4.66081 6.4603 4.95148C6.16729 5.24214 6.00172 5.63729 6 6.05001V18.45C6.00172 18.8627 6.16729 19.2579 6.4603 19.5485C6.75332 19.8392 7.14978 20.0016 7.5625 20H16.9375C17.3502 20.0016 17.7467 19.8392 18.0397 19.5485C18.3327 19.2579 18.4983 18.8627 18.5 18.45V9.15001L13.8125 4.50001Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8125 4.5V9.15H18.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.375 13.0254H9.125"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.375 16.125H9.125"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6875 9.9248H9.125"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

NoteIcon.defaultProps = {
  className: undefined,
};

export default NoteIcon;
