import React, { useEffect, useRef, useState } from 'react';
import { CheckIcon, ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';

import Popover from 'components/Popover';

import { SalesforcePicklistValue } from 'types/salesforce';
import SpinLoader from 'components/icons/SpinLoader';

interface Props {
  isEditable: boolean;
  isUpdating: boolean;
  value: string;
  picklistValues: SalesforcePicklistValue[];
  onChange: (value: string) => void;
}

function MultiPicklistField({ isEditable, isUpdating, value, picklistValues, onChange }: Props) {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [valueLists, setValueLists] = useState<string[]>([]);

  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    setValueLists(value ? value.split(';').sort() : []);
  }, [value]);

  const width = ref.current ? ref.current.getBoundingClientRect().width : 0;

  const close = () => {
    setIsMenuOpen(false);
    setSearchValue('');
  };

  const filteredPicklistValues = picklistValues.filter(p =>
    searchValue !== '' ? p.label.toLowerCase().includes(searchValue.toLowerCase()) : true,
  );

  return (
    <div className="w-full">
      <button
        ref={ref}
        type="button"
        className="flex gap-2 items-center"
        onClick={() => setIsMenuOpen(true)}
        disabled={!isEditable || isUpdating}
      >
        {valueLists.length > 0 ? (
          <div className="flex gap-2 flex-wrap">
            {valueLists.map(v => (
              <div key={v} className="px-2 rounded-full bg-gray-300">
                {v}
              </div>
            ))}
          </div>
        ) : (
          <div className="text-gray-400">Please select...</div>
        )}
        <ChevronDownIcon className="w-4 h-4 text-gray-400" />
      </button>
      <div className="flex items-center text-xs gap-1 mt-1">
        {isUpdating && (
          <>
            <SpinLoader className="w-5 h-5 p-1 animate-spin" />
            <span>Updating...</span>
          </>
        )}
      </div>
      <Popover
        isOpen={isMenuOpen}
        anchorEl={ref.current}
        onClose={() => {
          onChange(valueLists.join(';'));
          close();
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: -5, horizontal: 'left' }}
      >
        <div className="max-h-40 p-2" style={{ width: `${width > 200 ? width : 200}px` }}>
          <div className="w-full border rounded py-1 px-2 flex items-center">
            <MagnifyingGlassIcon className="w-4 h-4 mr-2 text-gray-400" />
            <input
              className="w-full text-sm placeholder:text-sm"
              placeholder="Search"
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
            />
          </div>
          <div className="flex flex-col mt-2">
            {filteredPicklistValues.length > 0 ? (
              filteredPicklistValues.map(picklistValue => (
                <button
                  type="button"
                  key={picklistValue.value}
                  className="flex items-center justify-between text-left text-sm hover:bg-blue-50 rounded px-2 py-1"
                  onClick={() => {
                    setValueLists(prev => {
                      let newValueList = [...prev, picklistValue.value];
                      if (prev.includes(picklistValue.value)) {
                        newValueList = prev.filter(v => v !== picklistValue.value);
                      }
                      newValueList.sort();
                      return newValueList;
                    });
                  }}
                >
                  {picklistValue.label}
                  {valueLists.includes(picklistValue.value) && (
                    <div>
                      <CheckIcon className="w-4 h-4 text-blue-500" />
                    </div>
                  )}
                </button>
              ))
            ) : (
              <div className="text-sm text-gray-400"> No search result for &quot;{searchValue}&quot; </div>
            )}
          </div>
        </div>
      </Popover>
    </div>
  );
}

export default MultiPicklistField;
