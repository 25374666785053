import React from 'react';
import { ProductCategory } from 'types/product-gap';
import moment from 'moment';
import constants from '../../../utils/constants';
import FieldEditorBlock from './FieldEditorBlock';

interface Props {
  productCategory: ProductCategory;
}

function ProductCategoryView({ productCategory }: Props) {
  if (!productCategory.integration)
    return (
      <div className="mt-4">
        <FieldEditorBlock className="mt-2" title="Name">
          <div className="w-full">{productCategory.title}</div>
        </FieldEditorBlock>
        <FieldEditorBlock className="mt-2" title="Description">
          <div className="w-full" dangerouslySetInnerHTML={{ __html: productCategory.description || '' }} />
        </FieldEditorBlock>
        <FieldEditorBlock className="mt-2" title="Status">
          <div className="w-full">{productCategory.status?.name || ''}</div>
        </FieldEditorBlock>
      </div>
    );

  return (
    <div className="mt-4">
      <FieldEditorBlock className="mt-2" title="Name">
        <div className="w-full">{productCategory.integration.name}</div>
      </FieldEditorBlock>
      <FieldEditorBlock className="mt-2" title="Description">
        <div className="w-full" dangerouslySetInnerHTML={{ __html: productCategory.integration.description || '' }} />
      </FieldEditorBlock>
      <FieldEditorBlock className="mt-2" title="Status">
        <div className="w-full">{productCategory.integration.status.name}</div>
      </FieldEditorBlock>
      <FieldEditorBlock className="mt-2" title="Start Date">
        <div className="w-full">
          {moment(productCategory.integration.timeframe.startDate).format(constants.DATE_FORMAT) || 'None'}
        </div>
      </FieldEditorBlock>
      <FieldEditorBlock className="mt-2" title="End Date">
        <div className="w-full">
          {moment(productCategory.integration.timeframe.endDate).format(constants.DATE_FORMAT) || 'None'}
        </div>
      </FieldEditorBlock>
      <FieldEditorBlock className="mt-2" title="Component">
        <div className="w-full">{productCategory.integration.parent.component?.name}</div>
      </FieldEditorBlock>
    </div>
  );
}

export default ProductCategoryView;
