import React from 'react';
import { concat } from 'utils/styling';

interface Props {
  className?: string;
  title: string | JSX.Element;
  children: JSX.Element;
  titleWidth?: number;
  truncate?: boolean;
}
function FieldEditorBlock({ className, title, children, titleWidth, truncate }: Props) {
  return (
    <div className={`flex w-full ${className}`}>
      <div className="text-gray-600 flex-shrink-0 flex-grow-0 py-0.5 px-2" style={{ width: `${titleWidth}rem` }}>
        {title}
      </div>
      <div className={concat('py-1 px-2 flex-1', truncate && 'truncate')}>{children}</div>
    </div>
  );
}

FieldEditorBlock.defaultProps = {
  className: '',
  titleWidth: 9,
  truncate: undefined,
};
export default FieldEditorBlock;
