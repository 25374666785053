/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/prefer-default-export */

import lodash from 'lodash';
import { ColumnConfig } from '../components/Table/types';

export const DEFAULT_FIELD_FEEDBACK_COLUMN: ColumnConfig = {
  id: 'field-Feedback',
  name: 'Feedback',
  width: 350,
  type: 'ProductFeedback',
  isHidden: false,
};

const DEFAULT_CREATED_AT_COLUMN: ColumnConfig = {
  id: 'createdAt',
  name: 'Created At',
  width: 130,
  type: 'Date',
  isHidden: false,
};

const DEFAULT_CREATED_BY_COLUMN: ColumnConfig = {
  id: 'createdBy',
  name: 'Created By',
  width: 130,
  type: 'Text',
  isHidden: false,
};

const DEFAULT_CATEGORY_COLUMN: ColumnConfig = {
  id: 'category',
  name: 'Category',
  width: 300,
  type: 'ProductCategory',
  isHidden: false,
};

export const DEFAULT_ACCOUNT_COLUMN: ColumnConfig = {
  id: 'account',
  group: 'Account',
  columns: [
    { id: 'name', name: 'Name', width: 150, type: 'Text', isHidden: false },
    {
      id: 'owner',
      name: 'Owner',
      width: 100,
      type: 'Text',
      isHidden: false,
    },
  ],
};

export const DEFAULT_OPPORTUNITY_COLUMN: ColumnConfig = {
  id: 'opportunity',
  group: 'Opportunity',
  columns: [
    { id: 'name', name: 'Name', width: 200, type: 'Text', isHidden: false },
    {
      id: 'owner',
      name: 'Owner',
      width: 100,
      type: 'Text',
      isHidden: false,
    },
    {
      id: 'amount',
      name: 'Amount',
      width: 140,
      type: 'Currency',
      isHidden: false,
    },
    { id: 'closeDate', name: 'Close Date', width: 120, type: 'Date', isHidden: false },
    { id: 'stageName', name: 'Stage', width: 150, type: 'Text', isHidden: false },
  ],
};

export const DEFAULT_PRODUCT_GAP_TABLE_CONFIG: ColumnConfig[] = [
  DEFAULT_FIELD_FEEDBACK_COLUMN,
  DEFAULT_CREATED_AT_COLUMN,
  DEFAULT_CREATED_BY_COLUMN,
  DEFAULT_CATEGORY_COLUMN,
  lodash.cloneDeep(DEFAULT_ACCOUNT_COLUMN),
  lodash.cloneDeep(DEFAULT_OPPORTUNITY_COLUMN),
];
