import { MenuItem } from '@mui/material';
import React from 'react';
import FilterIcon from 'components/icons/FilterIcon';
import { ColumnDefinition, ColumnFilter } from '../../types';
import { XMarkIcon } from '@heroicons/react/24/outline';

interface Props {
  columnDefinition: ColumnDefinition;
  setMenuState: (menuState: 'MENU' | 'FILTER' | 'HIDE') => void;
  onFilterUpdate: (filter?: ColumnFilter) => void;
}

function FilterColumnMenu({ columnDefinition, setMenuState, onFilterUpdate }: Props) {
  return (
    <>
      {!!columnDefinition.filter && (
        <MenuItem
          onClick={() => {
            onFilterUpdate();
            setMenuState('HIDE');
          }}
        >
          <div className="flex items-center text-sm">
            <XMarkIcon className="w-4 h-4 mr-2" />
            Clear filter
          </div>
        </MenuItem>
      )}
      <MenuItem onClick={() => setMenuState('FILTER')}>
        <div className="flex items-center text-sm">
          <FilterIcon className="w-4 h-4 mr-2" />
          {columnDefinition.filter ? 'Edit filter' : 'Apply filter'}
        </div>
      </MenuItem>
    </>
  );
}

export default FilterColumnMenu;
