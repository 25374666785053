import React, { useEffect, useState } from 'react';
import { wbSocketEventEmitter } from '../utils/event';
import { VectorEvent, VectorTask, WB_EVENT_VECTOR_TASK } from '../types/event';
import Loader from './Loader';

interface Props {
  entityId: string;
}

function EntityVectorizationStatus({ entityId }: Props) {
  const [tasks, setTasks] = useState<VectorTask[]>([]);
  const onWbEvent = (e: VectorEvent) => {
    if (e && e.task && e.task.entry_id === entityId) {
      if (e.action === 'started') {
        setTasks([...tasks, e.task]);
      } else if (e.action === 'finished' || e.action === 'failed') {
        const newTasks = [...tasks].filter(i => e.task.uid !== i.uid);
        setTasks(newTasks);
      }
    }
  };
  useEffect(() => {
    wbSocketEventEmitter.on(WB_EVENT_VECTOR_TASK, onWbEvent);
    return () => {
      wbSocketEventEmitter.removeListener(WB_EVENT_VECTOR_TASK, onWbEvent);
    };
  }, [tasks]);

  if (tasks.length <= 0) {
    return <div />;
  }
  return (
    <div className="absolute right-14 top-1 flex flex-row items-center">
      <span className="ml-1 text-xs">📚 Loading {tasks.length} context document(s)</span>
      <Loader className="w-10 h-10" />
    </div>
  );
}

export default EntityVectorizationStatus;
