import axios from 'axios';
import config from 'utils/config';

const client = axios.create({
  baseURL: config.SERVER_URL,
  timeout: 120000,
  validateStatus: () => true,
  withCredentials: true,
  headers: { version: config.BACKEND_API_VERSION },
});

export default client;
