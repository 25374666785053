import { User } from 'types/user';
import httpClient from './http-client';

export interface Response {
  user: User;
}

export default async (
  integrationName: 'zoom',
  zoomAccountId: string,
  zoomClientId: string,
  zoomClientSecret: string,
): Promise<Response> => {
  const { data } = await httpClient.post<Response>('/integration-token', {
    integrationName,
    zoomAccountId,
    zoomClientId,
    zoomClientSecret,
  });
  return data;
};
