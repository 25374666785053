import moment from 'moment';
import React, { useMemo, useState, useEffect } from 'react';
import { find, sortBy } from 'lodash';
import { Call } from 'types/document';
import constants from 'utils/constants';
import RecordingIcon from '../../../components/icons/RecordingIcon';
import SpinLoader from 'components/icons/SpinLoader';
import useRecordingsStore from '../../../stores/recordings';
import ButtonPopover from 'components/ButtonPopover';
import { concat } from 'utils/styling';
import useQueryParamState from 'components/Hooks/useQueryParamState';
import Tooltip from 'components/Tooltip';

interface Props {
  className?: string;
  calls: Call[];
}
function RecordingInformation({ className, calls: callsInput }: Props) {
  const [, setDrawer] = useQueryParamState(constants.RIGHT_DRAWER_QUERY_PARAM_KEY);
  const recordingsStore = useRecordingsStore();

  const calls = useMemo(() => sortBy(callsInput, call => -moment(call.callData.started).unix()), [callsInput]);
  const [callStatus, setCallStatus] = useState<boolean[]>(() => {
    return calls.map(() => false);
  });
  const [isBeingProcessed, setIsBeingProcessed] = useState<boolean>(false);

  useEffect(() => {
    const tempCallsStatus = calls.map(call => {
      const recording = find(recordingsStore.recordings, item => item.id === call.id);
      if (recording) {
        if (recording.status === 'pending' || recording.status === 'new') {
          return true;
        }
        return false;
      }
      return true;
    });
    setIsBeingProcessed(tempCallsStatus.some(status => status));
    setCallStatus(tempCallsStatus);
  }, [calls, recordingsStore.recordings]);

  const menus = useMemo(
    () =>
      calls.map((call, idx) => ({
        icon: callStatus[idx] ? (
          <SpinLoader className="animate-spin w-4 h-4 text-orange-500" />
        ) : (
          <RecordingIcon className="min-w-[20px] w-5 h-5 text-black" />
        ),
        label: call.callData.title
          ? `${call.callData.title} (${moment(call.callData.started).format(constants.DATE_FORMAT)})`
          : 'No Title',
        data: call,
        value: call.id,
      })),
    [callStatus, calls],
  );

  const tooltip = useMemo(() => (menus.length > 1 ? 'Recordings' : menus[0]?.data?.callData?.title), [menus]);

  if (calls.length === 0) {
    return null;
  }
  return (
    <ButtonPopover
      btnColor="secondary"
      btnVariant="outlined"
      btnClassName={concat('!w-auto !font-normal !text-sm !px-1', className)}
      closeWhenPopoverClick
      menus={menus}
      tooltip={tooltip}
      tooltipPlacement="bottom"
      handleMenuClick={menu => {
        setDrawer('recordings');
        recordingsStore.setState({ recordingId: menu.value });
      }}
      stopPropagation
      stopPropagationWhenClick
      onBtnClick={
        menus.length > 1
          ? undefined
          : () => {
              setDrawer('recordings');
              recordingsStore.setState({ recordingId: menus[0].value });
            }
      }
      horizontalOrigin="left"
    >
      <RecordingIcon className="min-w-[20px] w-5 h-5" />
      <span className="max-w-[96px] truncate">{menus.length > 1 ? 'Recordings' : menus[0]?.data?.callData?.title}</span>
      <span className="text-gray-500">{menus.length > 1 ? ` (${menus.length})` : ''}</span>
      {isBeingProcessed && <SpinLoader className="animate-spin w-4 h-4 text-orange-500" />}
    </ButtonPopover>
  );
}

RecordingInformation.defaultProps = {
  className: '',
};

export default RecordingInformation;
