import React, { useState } from 'react';

import StarIcon from 'components/icons/StarIcon';
import { Template } from 'api/note-templates/get-note-templates';
import useDocumentStore from 'stores/document';
import { useNavigate } from 'react-router-dom';
import { DocumentPlusIcon } from '@heroicons/react/24/outline';

interface State {
  isLoading: boolean;
}

interface Props {
  oppId: string;
  template: Template;
}

function TemplateLink({ oppId, template }: Props) {
  const [state, setState] = useState<State>({
    isLoading: false,
  });
  const documentStore = useDocumentStore();
  const navigate = useNavigate();

  // const docIcon: JSX.Element = <NoteIcon className="w-5 h-5" />;
  const docIcon: JSX.Element = <DocumentPlusIcon className="w-[18px] h-[18px] flex-grow-0 flex-shrink-0" />;

  return (
    <div className="w-full flex justify-between rounded group">
      <div className="w-full flex gap-2 items-center ">
        <div className="flex items-center text-gray-500 pl-[1px]">{docIcon}</div>
        <button
          type="button"
          className="flex items-center flex-grow gap-2"
          onClick={async () => {
            setState({
              ...state,
              isLoading: true,
            });
            try {
              const document = await documentStore.createDocumentFromTemplate(oppId, template.noteTemplateId);
              navigate(`/document/${document.id}`);
            } catch (e) {
              setState({
                ...state,
                isLoading: false,
              });
            }
            setState({
              ...state,
              isLoading: false,
            });
          }}
          disabled={state.isLoading}
        >
          <span className="text-gray-500 font-medium group-hover:underline group-hover:text-blue-600">
            {template.name}
          </span>
          <StarIcon className="w-5 h-5 text-yellow-400" />
        </button>
      </div>
    </div>
  );
}

export default TemplateLink;
