import httpClient from '../http-client';
import { Recording } from '../../types/recording';

export interface Response {
  recordings: Recording[];
}

export default async (recordingId: string): Promise<Response> => {
  const { data } = await httpClient.get<Response>(`/recording/${recordingId}`);
  return data;
};
