import { XMarkIcon } from '@heroicons/react/24/outline';
import AccountIcon from 'components/icons/AccountIcon';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useModalStore from '../../stores/modal';
import useDocumentStore from '../../stores/document';
import { EntityName } from '../../types/entity';
import OpportunityIcon from '../icons/OpportunityIcon';
import SpinLoader from '../icons/SpinLoader';

interface State {
  name: string;
  isLoading: boolean;
}

interface Props {
  entityName: EntityName;
  parentDocumentId: string | null;
}

function CreateNewEntityModal({ entityName, parentDocumentId }: Props) {
  const [state, setState] = useState<State>({
    name: '',
    isLoading: false,
  });

  const navigate = useNavigate();
  const documentStore = useDocumentStore();
  const modalStore = useModalStore();

  const onCreate = async () => {
    setState(prevState => ({ ...prevState, isLoading: true }));
    const entityDocument = await documentStore.createNewEntity(state.name, entityName, parentDocumentId);
    setState(prevState => ({ ...prevState, isLoading: false }));
    navigate(`/${entityName.toLowerCase()}/${entityDocument.id}`);
    modalStore.close();
  };

  return (
    <div className="w-[25rem] p-5">
      <div className="flex justify-between">
        <div className="flex items-center gap-2">
          {entityName === 'Account' ? (
            <AccountIcon className="w-6 h-6" />
          ) : (
            <OpportunityIcon className="w-6 h-6 text-orange-400 fill-orange-400" />
          )}
          <div className="text-lg font-medium">Create a new {entityName.toLowerCase()}</div>
        </div>
        <div>
          <button
            type="button"
            className="w-7 h-7 p-1 text-gray-800 hover:bg-gray-100 rounded"
            onClick={modalStore.close}
          >
            <XMarkIcon />
          </button>
        </div>
      </div>
      <div className="mt-8 text-xs font-medium">Enter Name for the {entityName}</div>
      <input
        className="w-full border rounded p-2 focus:border-blue-300 placeholder:text-xs"
        type="text"
        value={state.name}
        onChange={e => setState(prevState => ({ ...prevState, name: e.target.value }))}
        onKeyDown={event => {
          if (event.key === 'Enter' && state.name !== '') onCreate();
        }}
        placeholder={`Enter ${entityName} Name`}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
      />
      <div className="mt-8 flex justify-end">
        <button
          className="py-3 px-6 mr-2 bg-slate-200 hover:bg-slate-300 rounded text-gray-700"
          type="button"
          onClick={modalStore.close}
        >
          Cancel
        </button>
        <button
          className={`py-3 px-6 mr-2 ${
            state.isLoading ? 'bg-gray-400' : 'bg-blue-600 hover:bg-blue-500'
          } rounded text-white flex items-center gap-3`}
          type="button"
          disabled={state.isLoading || state.name === ''}
          onClick={() => onCreate()}
        >
          {state.isLoading && (
            <div>
              <SpinLoader className="animate-spin w-6 h-6 text-orange-500" />
            </div>
          )}
          <div>Create</div>
        </button>
      </div>
    </div>
  );
}

export default CreateNewEntityModal;
