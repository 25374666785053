import React from 'react';

interface Props {
  className?: string;
}
function EyeOffIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.25 18.6142C10.9467 18.6056 9.66738 18.2632 8.534 17.6197C7.50966 17.0386 6.58275 16.3004 5.787 15.4322C4.89217 14.4737 4.1236 13.4047 3.5 12.2512C4.48665 10.4051 5.85946 8.79317 7.525 7.52522L10.5635 10.5637C10.3285 10.782 10.1399 11.0455 10.009 11.3384C9.87817 11.6312 9.80767 11.9474 9.80176 12.2682C9.79585 12.5889 9.85466 12.9075 9.97467 13.205C10.0947 13.5024 10.2734 13.7727 10.5002 13.9995C10.727 14.2263 10.9972 14.4051 11.2947 14.5252C11.5921 14.6452 11.9107 14.7041 12.2314 14.6982C12.5522 14.6923 12.8684 14.6219 13.1613 14.4911C13.4541 14.3602 13.7176 14.1717 13.936 13.9367L16.9745 16.9747C15.617 18.0152 13.9602 18.5902 12.25 18.6142ZM19.282 14.7867L10.5795 6.07772C11.1214 5.95088 11.676 5.88679 12.2325 5.88672L12.25 5.88672C13.5534 5.89543 14.8329 6.23781 15.9665 6.88122C16.9907 7.46232 17.9176 8.20026 18.7135 9.06822C19.6082 10.0267 20.3766 11.0957 21 12.2492C20.5176 13.1526 19.9419 14.0031 19.2825 14.7867H19.282Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.5 3.5L21 21"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

EyeOffIcon.defaultProps = {
  className: undefined,
};
export default EyeOffIcon;
