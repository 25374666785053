import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';

import { Field, Tag } from 'types/field-value';
import FieldButton from './FieldPicker/FieldButton';
import FieldTag from './FieldPicker/FieldTag';
import getFieldName from './utils/GetFieldName';
import BaseButton from './BaseButton';

export type FieldPickerRef = {
  addCheckedFields: () => boolean;
  getDisplayedNotAddedFields: () => Field[];
};

interface State {
  selectList: string[];
  filteredTag: string[];
  filterValue: string;
}

interface Props {
  inputValue: string;
  addSearchField: boolean;
  fields: Field[];
  usedFieldIds: string[];
  onAddFields: (fields: Field[]) => void;
}

function getTags(fields: Field[]) {
  const tags: Tag[] = [];
  fields.forEach(field => {
    field.tags.forEach(tag => {
      if (tags.find(t => t.id === tag.id) === undefined) {
        tags.push(tag);
      }
    });
  });
  return tags;
}

const FieldPicker = forwardRef<FieldPickerRef, Props>(
  ({ inputValue, addSearchField, fields, usedFieldIds, onAddFields }: Props, ref) => {
    const [state, setState] = useState<State>({
      selectList: [],
      filteredTag: [],
      filterValue: inputValue,
    });

    useEffect(() => {
      setState(prevState => ({ ...prevState, filterValue: inputValue }));
    }, [inputValue]);

    const tags = useMemo(() => {
      return getTags(fields);
    }, [fields]);

    const filteredFields = useMemo(
      () =>
        fields.filter(field => {
          const label = getFieldName(field);
          // filter non parentId
          if (field.parentId !== null) {
            return false;
          }

          // filter by search input
          if (
            state.filterValue !== '' &&
            label &&
            !label.toLowerCase().includes(state.filterValue.toLowerCase().trim())
          ) {
            return false;
          }
          // filter by tag
          if (state.filteredTag.length > 0) {
            const isFieldHasTag = field.tags.find(tag => state.filteredTag.includes(tag.id)) !== undefined;
            if (!isFieldHasTag) {
              return false;
            }
          }
          return true;
        }),
      [fields, state.filteredTag, state.filterValue],
    );

    useImperativeHandle(ref, () => {
      return {
        addCheckedFields: (): boolean => {
          const checkedFields = fields.filter(f => state.selectList.includes(f.id));
          if (checkedFields.length) {
            onAddFields(checkedFields);
            return true;
          }
          return false;
        },
        getDisplayedNotAddedFields: () => filteredFields.filter(field => !usedFieldIds.includes(field.id)),
      };
    });

    return (
      // TODO: somehow clicking anywhere blank will close the picker
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        className="w-full border rounded bg-white pb-3"
        onMouseDown={e => {
          if (!addSearchField) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
      >
        {addSearchField && (
          <div className="p-2">
            <div className="flex items-center gap-2 p-2 border rounded-lg focus-within:border-blue-400">
              <MagnifyingGlassIcon className="w-4 h-4 text-gray-400" />
              <input
                className="w-full text-sm placeholder:text-sm"
                value={state.filterValue}
                onChange={e => setState(prevState => ({ ...prevState, filterValue: e.target.value }))}
                placeholder="Search"
              />
            </div>
          </div>
        )}
        {tags.length > 0 && (
          <>
            <div className="p-2 text-xs text-gray-500">Filter by tags</div>
            <div className="flex items-center flex-wrap gap-2 mx-2 mb-1">
              {tags.map(tag => {
                const fieldsPerTag = fields.filter(field => field.tags.find(t => t.id === tag.id) !== undefined);
                const fieldsPerTagNotInDoc = fieldsPerTag.filter(field => !usedFieldIds.includes(field.id));

                const onClickTag = () => {
                  setState(preState => {
                    if (preState.filteredTag.includes(tag.id)) {
                      return { ...preState, filteredTag: preState.filteredTag.filter(id => id !== tag.id) };
                    }
                    return { ...preState, filteredTag: [...preState.filteredTag, tag.id] };
                  });
                };

                return (
                  <FieldTag
                    key={tag.id}
                    tag={tag}
                    onClickTag={onClickTag}
                    isSelected={state.filteredTag.includes(tag.id)}
                    numOfFieldNotInDoc={fieldsPerTagNotInDoc.length}
                  />
                );
              })}
            </div>
          </>
        )}
        <div className="px-2 pt-2 text-xs text-gray-500">Existing fields ({filteredFields.length})</div>
        <div className="mt-2 max-h-52 overflow-y-auto">
          {filteredFields.length === 0 ? (
            <div className="w-full flex justify-center text-gray-500 text-sm italic">No discovery fields found</div>
          ) : (
            <>
              {filteredFields.map(field => {
                const isAlreadyInDoc = usedFieldIds.includes(field.id);
                return (
                  <FieldButton
                    key={field.id}
                    field={field}
                    isAlreadyInDoc={isAlreadyInDoc}
                    isSelected={state.selectList.includes(field.id)}
                    onClick={() => {
                      if (!isAlreadyInDoc) {
                        setState(() => {
                          if (state.selectList.includes(field.id)) {
                            return { ...state, selectList: state.selectList.filter(id => id !== field.id) };
                          }
                          return { ...state, selectList: [...state.selectList, field.id] };
                        });
                      }
                    }}
                  />
                );
              })}
            </>
          )}
        </div>
        <div className="flex justify-end mr-4 mt-1">
          <BaseButton
            color="primary"
            variant="contained"
            onMouseDown={() => {
              onAddFields(fields.filter(f => state.selectList.includes(f.id)));
            }}
            disabled={state.selectList.length === 0}
          >
            <span>Add</span>
            <span>({state.selectList.length})</span>
          </BaseButton>
        </div>
      </div>
    );
  },
);

FieldPicker.displayName = 'FieldPicker';

export default FieldPicker;
