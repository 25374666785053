import { create } from 'zustand';
import { ModalCloseAction, ModalResponseType } from 'types/modal';
import { v4 } from 'uuid';

export interface SingleModalState {
  isOpen: boolean;
  element: null | JSX.Element;
  verticalPosition: 'top' | 'center';
  disableEscapeKeyDown: boolean;
  closeCallback?: (modalResponse: ModalResponseType) => void;
  isHidden: boolean;
  hideBackdrop: boolean;
  disableRestoreFocus: boolean;
  className?: string;
  disableBackdropClose?: boolean;
}

const DEFAULT_SINGLE_MODAL_STATE: SingleModalState = {
  isOpen: false,
  element: null,
  verticalPosition: 'center',
  disableEscapeKeyDown: false,
  closeCallback: undefined,
  isHidden: false,
  hideBackdrop: false,
  disableRestoreFocus: false,
};

export type State = {
  modals: { [modalId: string]: SingleModalState };
  setState: (modalState: Partial<SingleModalState>, modalId?: string) => State;
  close: (modalResponse?: ModalResponseType, modalId?: string) => void;
  toggleModalVisible: (modalId: string, isHidden: boolean) => void;
};

const useModalStore = create<State>((set, get) => ({
  modals: {},
  /**
   * Open single modal
   * @param modalState single modal state
   * @param modalId modal id
   */
  setState: (modalState: Partial<SingleModalState>, modalId?: string) => {
    const id = modalId ?? v4();
    set(state => {
      return {
        ...state,
        modals: {
          ...state.modals,
          [id]: {
            ...DEFAULT_SINGLE_MODAL_STATE,
            ...state.modals[id],
            ...modalState,
          },
        },
      };
    });
    return get();
  },
  /**
   * Close modal
   * @param modalResponse modal response to caller
   * @param modalId modal id to close, if not provided, close all modals
   */
  close: (modalResponse?: ModalResponseType, modalId?: string) => {
    set(state => {
      const modalInstance = modalId ? state.modals[modalId] : undefined;
      modalInstance?.closeCallback?.(modalResponse || ModalCloseAction);
      const newState = { ...state, modals: modalId ? { ...state.modals } : {} };
      if (modalId) {
        delete newState.modals[modalId];
      }
      return newState;
    });
  },

  /**
   * Toggle modal visible
   * @param modalId modal id
   * @param isHidden is modal hidden
   */
  toggleModalVisible: (modalId: string, isHidden: boolean) => {
    set(state => {
      const modalInstance = state.modals[modalId];
      if (modalInstance) {
        const newState = {
          ...state,
          modals: {
            ...state.modals,
            [modalId]: {
              ...modalInstance,
              isHidden,
            },
          },
        };
        return newState;
      }
      return state;
    });
  },
}));

export default useModalStore;
