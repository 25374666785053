/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus, no-restricted-syntax */
import { cloneDeep, isEmpty, keys, max } from 'lodash';
import { Node, NodeEntry, Text, Transforms } from 'slate';
import { v4 } from 'uuid';
import NoteEditor from '../NoteEditor';
import {
  BlockType,
  BlockTypeInline,
  COMMENTABLE_VOID_BLOCKS,
  CustomEditor,
  CustomElement,
  INDENTABLE_TYPES,
  NON_TEXT_TYPES,
  ImageElement,
  MentionElement,
  TableCellElement,
  TableElement,
  TableRowElement,
} from '../types';
import { DEFAULT_TABLE_CELL_WIDTH } from '../Elements/Table/TableCellElement';
import { createNode } from '../utils';

const isFirstTimeNormalize: { [editorId: string]: boolean } = {};

export const withNormalizeInlineNode = (editor: CustomEditor) => {
  if (!editor.editor_uuid) {
    editor.editor_uuid = v4();
    isFirstTimeNormalize[editor.editor_uuid] = true;
  }
  const { normalizeNode } = editor;

  editor.normalizeNode = ([n, path]: NodeEntry) => {
    const node = n as CustomElement;
    if (!node || !path) {
      return;
    }
    if (node.type === BlockTypeInline.Link) {
      // unwrap link node if do not have text
      if (node.children.every(child => !child.text)) {
        Transforms.unwrapNodes(editor, { at: path });
        return;
      }
      const parentPath = path.slice(0, -1);
      const [parentNode] = NoteEditor.node(editor, parentPath);
      const lastIndex = path[path.length - 1];
      // if link is the last element of parent node, insert a empty text after it
      if ((parentNode as CustomElement).children.length - 1 === lastIndex) {
        Transforms.insertNodes(editor, { text: '' }, { at: [...parentPath, lastIndex + 1] });
        return;
      }
    }

    if (node.type === BlockTypeInline.Mention) {
      // unwrap mention node if do not have mentionUserId
      if (!(node as MentionElement).mentionUserId && !(node as MentionElement).beingEditedBy) {
        Transforms.setNodes(
          editor,
          {
            type: undefined,
            mentionUserId: undefined,
            mentionId: undefined,
          },
          { at: path },
        );
        Transforms.insertText(editor, '@', { at: path });
        Transforms.unwrapNodes(editor, { at: path });
        return;
      }
      const parentPath = path.slice(0, -1);
      const [parentNode] = NoteEditor.node(editor, parentPath);
      const lastIndex = path[path.length - 1];
      // if mention is the last element of parent node, insert a empty text after it
      if ((parentNode as CustomElement).children.length - 1 === lastIndex) {
        Transforms.insertNodes(editor, { text: '' }, { at: [...parentPath, lastIndex + 1] });
        return;
      }
      // always insure there is a text node before mention node
      const previousNodePath = [...parentPath, lastIndex - 1];
      const previousNode = Node.has(editor, previousNodePath) ? Node.get(editor, previousNodePath) : undefined;
      if (!previousNode || !Text.isText(previousNode)) {
        Transforms.insertNodes(editor, { text: '' }, { at: path });
        return;
      }
    }

    if (Text.isText(node) && node.comment) {
      // remove comment with empty text
      if (!node.text) {
        Transforms.setNodes(editor, { comment: undefined }, { at: path });
        return;
      }
      // if comment is the last element of parent node, insert a empty text after it
      const parentPath = path.slice(0, -1);
      const [parentNode] = NoteEditor.node(editor, parentPath);
      const lastIndex = path[path.length - 1];
      // if comment is the last element of parent node, insert a empty text after it
      if ((parentNode as CustomElement).children.length - 1 === lastIndex) {
        Transforms.insertNodes(editor, { text: '' }, { at: [...parentPath, lastIndex + 1] });
        return;
      }
    }

    // remove comment init tag when first time normalize
    if (node.commentInit && isFirstTimeNormalize[editor.editor_uuid]) {
      Transforms.setNodes(editor, { commentInit: undefined }, { at: path });
      return;
    }

    // slate normalize root node at final
    if (path.length === 0 && editor.children.length) {
      isFirstTimeNormalize[editor.editor_uuid] = false;
    }
    normalizeNode([n, path]);
  };

  return editor;
};

export const withNormalizeBlockNode = (editor: CustomEditor) => {
  if (!editor.editor_uuid) {
    editor.editor_uuid = v4();
    isFirstTimeNormalize[editor.editor_uuid] = true;
  }
  const { normalizeNode } = editor;

  editor.normalizeNode = ([n, path]: NodeEntry) => {
    const node = n as CustomElement;
    if (!node || !path) {
      return;
    }

    if (path.length === 1 && [...NON_TEXT_TYPES, ...INDENTABLE_TYPES].includes(node.type as BlockType) && !node.uuid) {
      Transforms.setNodes(editor, { uuid: v4() }, { at: path });
      return;
    }

    if ([BlockType.OrderedList, BlockType.BulletedList].includes(node.type as BlockType)) {
      // remove duplicated empty text node for list because it will impact enter plugin logic
      // if there are more than one empty text node, enter will not turn the empty list note into paragraph
      if (
        node.children.length > 1 &&
        node.children.every(child => 'text' in child && child?.text === '' && !('children' in child))
      ) {
        for (let i = 0; i < node.children.length; i++) {
          if (i !== node.children.length - 1) Transforms.removeNodes(editor, { at: [...path, i] });
        }
        return;
      }
    }

    if (path.length === 0) {
      const { children } = node as CustomElement;
      if (children?.length) {
        for (let i = 0; i < children.length; i++) {
          const child = children[i] as CustomElement;
          if (!child.type) {
            Transforms.removeNodes(editor, { at: [i] });
            return;
          }
        }
        // always add an empty paragraph when heading node is void, because current slate has a bug
        // that it will not correctly copy/cut when selected heading node is void
        if (
          NoteEditor.isVoid(editor, children[0] as CustomElement) ||
          (children[0] as CustomElement).type === BlockType.Table
        ) {
          Transforms.insertNodes<CustomElement>(editor, createNode(BlockType.Paragraph), { at: [0] });
          return;
        }
      }
    }

    if (path.length === 1) {
      // remove comment ids when children do not have these ids
      if (!isEmpty(node.comments)) {
        const comments = keys(node.comments);
        for (const comment of comments) {
          if (!COMMENTABLE_VOID_BLOCKS.includes(node.type)) {
            const [match] = NoteEditor.nodes(editor, {
              match: el => {
                if (Text.isText(el)) {
                  const nodeComments = (el.comment ?? '').split(' ');
                  return nodeComments.some(nodeComment => comment === nodeComment);
                }
                return false;
              },
              at: path,
              mode: 'lowest',
            });
            if (!match) {
              const newComments = cloneDeep(node.comments);
              keys(newComments).forEach(key => {
                if (key === comment) {
                  delete newComments[key];
                }
              });
              Transforms.setNodes(editor, { comments: isEmpty(newComments) ? undefined : newComments }, { at: path });
              return;
            }
          }
        }
      } else if (node.comments !== undefined) {
        Transforms.setNodes(editor, { comments: undefined }, { at: path });
        return;
      }
      // remove comment init tag when first time normalize
      if (node.commentInit && isFirstTimeNormalize[editor.editor_uuid]) {
        Transforms.setNodes(editor, { commentInit: undefined }, { at: path });
        return;
      }
      // remove table row and table cell element at root path
      if (node.type === BlockType.TableRow || node.type === BlockType.TableCell) {
        Transforms.removeNodes(editor, { at: path });
        return;
      }
    }

    if (INDENTABLE_TYPES.includes(node.type as BlockType)) {
      if (typeof node.indentLevel !== 'number' || node.indentLevel === 0) {
        Transforms.setNodes(editor, { indentLevel: 0 }, { at: path });
        return;
      }
    }

    if (node.type === BlockType.Image) {
      const imgNode = node as ImageElement;
      if (!imgNode.url && !imgNode.fileId) {
        Transforms.removeNodes(editor, { at: path });
        return;
      }
    }

    if (node.type === BlockType.Table) {
      const tableNode = node as TableElement;
      const rowCount = tableNode.children?.length ?? 0;
      if (!rowCount) {
        Transforms.removeNodes(editor, { at: path });
        return;
      }
      for (let i = 0; i < rowCount; i++) {
        const row = tableNode.children[i] as TableRowElement;
        if (row.type !== BlockType.TableRow) {
          Transforms.removeNodes(editor, { at: [...path, i] });
          return;
        }
      }
      // make table row has same count of cells
      const colCount = max(tableNode.children.map((row: TableRowElement) => row.children.length ?? 0)) as number;
      for (let i = 0; i < rowCount; i++) {
        const row = tableNode.children[i] as TableRowElement;
        const cellCount = row.children?.length ?? 0;
        if (cellCount < colCount) {
          Transforms.insertNodes(
            editor,
            {
              type: BlockType.TableCell,
              width:
                tableNode.children[i - 1]?.children[cellCount]?.width ??
                tableNode.children[i + 1]?.children[cellCount]?.width ??
                DEFAULT_TABLE_CELL_WIDTH,
              children: [{ text: '' }],
            } as TableCellElement,
            { at: [...path, i, cellCount] },
          );
          return;
        }
      }
    }

    if (node.type === BlockType.TableRow) {
      const rowNode = node as TableRowElement;
      const colCount = rowNode.children?.length ?? 0;
      if (!colCount) {
        Transforms.removeNodes(editor, { at: path });
        return;
      }
      for (let i = 0; i < colCount; i++) {
        const cell = rowNode.children[i] as TableCellElement;
        if (cell.type !== BlockType.TableCell) {
          Transforms.removeNodes(editor, { at: [...path, i] });
          return;
        }
      }
    }

    // slate normalize root node at final
    if (path.length === 0 && editor.children.length) {
      isFirstTimeNormalize[editor.editor_uuid] = false;
    }
    normalizeNode([n, path]);
  };

  return editor;
};
