import React, { useRef, useState } from 'react';
import { CheckIcon, ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';

import Popover from 'components/Popover';
import SpinLoader from 'components/icons/SpinLoader';

import { SalesforcePicklistValue } from 'types/salesforce';

export function PicklistFieldList({
  selectedValue,
  disableValues,
  width,
  picklistValues,
  onChange,
}: {
  selectedValue: string;
  disableValues: string[];
  width: number;
  picklistValues: SalesforcePicklistValue[];
  onChange: (value: string) => void;
}) {
  const [searchValue, setSearchValue] = useState<string>('');

  const filteredPicklistValues = picklistValues.filter(p =>
    searchValue !== '' ? p.label.toLowerCase().includes(searchValue.toLowerCase()) : true,
  );

  return (
    <div className="max-h-40 p-2" style={{ width: `${width > 200 ? width : 200}px` }}>
      <div className="w-full border rounded py-1 px-2 flex items-center">
        <MagnifyingGlassIcon className="w-4 h-4 mr-2 text-gray-400" />
        <input
          className="w-full text-sm placeholder:text-sm"
          placeholder="Search"
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
        />
      </div>
      <div className="flex flex-col mt-2">
        {filteredPicklistValues.length > 0 ? (
          filteredPicklistValues.map(picklistValue => (
            <button
              type="button"
              key={picklistValue.value}
              className="flex items-center justify-between text-left text-sm hover:bg-blue-50 rounded px-2 py-1"
              onClick={() => {
                onChange(picklistValue.value);
                setSearchValue('');
              }}
              disabled={disableValues.includes(picklistValue.value)}
            >
              {picklistValue.label}
              {picklistValue.value === selectedValue && (
                <div>
                  <CheckIcon className="w-4 h-4 text-blue-500" />
                </div>
              )}
            </button>
          ))
        ) : (
          <div className="text-sm text-gray-400"> No search result for &quot;{searchValue}&quot; </div>
        )}
      </div>
    </div>
  );
}

interface Props {
  isEditable: boolean;
  isUpdating: boolean;
  value: string;
  picklistValues: SalesforcePicklistValue[];
  onChange: (value: string) => void;
}

function PicklistField({ isEditable, isUpdating, value, picklistValues, onChange }: Props) {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const ref = useRef<HTMLButtonElement>(null);

  const width = ref.current ? ref.current.getBoundingClientRect().width : 0;

  const close = () => {
    setIsMenuOpen(false);
  };

  return (
    <div className="w-full">
      <button
        ref={ref}
        type="button"
        className="flex gap-2"
        onClick={() => setIsMenuOpen(true)}
        disabled={!isEditable || isUpdating}
      >
        {value ? <div className="text-gray-500">{value}</div> : <div className="text-gray-400">Please select...</div>}
        <ChevronDownIcon className="w-4 h-4 text-gray-400" />
      </button>
      <div className="flex items-center text-xs gap-1 mt-1">
        {isUpdating && (
          <>
            <SpinLoader className="w-5 h-5 p-1 animate-spin" />
            <span>Updating...</span>
          </>
        )}
      </div>
      <Popover
        isOpen={isMenuOpen}
        anchorEl={ref.current}
        onClose={close}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: -5, horizontal: 'left' }}
      >
        <PicklistFieldList
          selectedValue={value}
          disableValues={[]}
          width={width}
          picklistValues={picklistValues}
          onChange={v => {
            onChange(v);
            close();
          }}
        />
      </Popover>
    </div>
  );
}

export default PicklistField;
