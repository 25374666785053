// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from '@sentry/react';
import config from './config';
import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { User } from '../types/user';

const addUserDetail = (user: User | null | undefined) => {
  if (config.ENV === 'local') {
    return;
  }
  if (!user) {
    Sentry.setUser(null);
  } else {
    const { firstName, lastName, email } = user;
    Sentry.setUser({ userName: `${firstName} ${lastName}`, email });
  }
};

const initialize = () => {
  if (config.ENV === 'local') {
    return;
  }
  // Following guidance for https://docs.sentry.io/platforms/javascript/guides/react/?original_referrer=https%3A%2F%2Fwww.google.com%2F
  Sentry.init({
    dsn: 'https://2b5913540b414ae0b4de72e05f39df75@o1363364.ingest.sentry.io/4504135885651968',
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Sentry.BrowserTracing({
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new Sentry.Replay(),
    ],

    // Ignoring events for following routes
    ignoreTransactions: [
      '/admin/google-oauth',
      '/google-oauth',
      '/microsoft-oauth',
      '/salesforce-oauth',
      '/zoom-oauth',
      '/gong-oauth',
      '/slack-oauth',
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: config.ENV,
  });
};

export default {
  initialize,
  addUserDetail,
};
