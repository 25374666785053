import React from 'react';

interface Props {
  className?: string;
}

function RecordingIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.9996 8.07129L15.4316 11.9998L20.9996 15.9283V8.07129Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.132 6.5H4.8C4.08203 6.5 3.5 7.08203 3.5 7.8V16.2C3.5 16.918 4.08203 17.5 4.8 17.5H14.132C14.85 17.5 15.432 16.918 15.432 16.2V7.8C15.432 7.08203 14.85 6.5 14.132 6.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

RecordingIcon.defaultProps = {
  className: undefined,
};

export default RecordingIcon;
