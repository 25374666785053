import { Call } from 'types/document';
import httpClient from '../http-client';

export default async (blob: Blob, documentId: string, fileName: string, fileType: string): Promise<Call> => {
  const formData = new FormData();
  formData.append('file', blob);
  formData.append('document_id', documentId);
  formData.append('file_name', fileName);
  formData.append('file_type', fileType);
  const rsp = await httpClient.post<Call>(`/voice`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return rsp.data;
};
