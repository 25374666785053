import React from 'react';

interface Props {
  className?: string;
}

function MeetingIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="23"
      height="16"
      viewBox="0 0 23 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.7275 15.0007V13.4452C17.718 12.6107 17.3774 11.8142 16.7807 11.2308C16.184 10.6473 15.38 10.3248 14.5455 10.3342H8.182C7.34752 10.3248 6.5435 10.6473 5.94678 11.2308C5.35005 11.8142 5.00949 12.6107 5 13.4452L5 15.0007"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3646 7.222C13.122 7.222 14.5466 5.82916 14.5466 4.111C14.5466 2.39284 13.122 1 11.3646 1C9.60725 1 8.18262 2.39284 8.18262 4.111C8.18262 5.82916 9.60725 7.222 11.3646 7.222Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.5681 15.0001V13.4446C21.5623 12.7487 21.3244 12.0748 20.8921 11.5295C20.4598 10.9842 19.8578 10.5989 19.1816 10.4346"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.00022 15.0001V13.4446C1.00604 12.7487 1.24396 12.0748 1.67628 11.5295C2.10859 10.9842 2.71055 10.5989 3.38672 10.4346"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 1.10156C16.6779 1.26491 17.2817 1.65036 17.7153 2.19654C18.1489 2.74272 18.3872 3.41824 18.3925 4.11556C18.3872 4.81288 18.1489 5.4884 17.7153 6.03458C17.2817 6.58076 16.6779 6.96621 16 7.12956"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.56836 1.10156C5.89042 1.26491 5.28661 1.65036 4.85306 2.19654C4.4195 2.74272 4.18112 3.41824 4.17586 4.11556C4.18112 4.81288 4.4195 5.4884 4.85306 6.03458C5.28661 6.58076 5.89042 6.96621 6.56836 7.12956"
        stroke="#AFB9CC"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

MeetingIcon.defaultProps = {
  className: undefined,
};

export default MeetingIcon;
