import { Template } from 'api/note-templates/get-note-templates';
import { Document } from 'types/document';

const getTemplateToPopulate = (templateList: Template[], documents: Document[]): Template[] => {
  // get templates that have not being populated
  const templateIdsFromDocuments = documents
    .filter(document => {
      if (document.fromTemplateId) {
        return true;
      }
      return false;
    })
    .map(document => document.fromTemplateId);
  const outcaseTemplates = templateList.filter(
    template => !templateIdsFromDocuments.includes(template.id) && template.isMeta,
  );
  return outcaseTemplates;
};

export default getTemplateToPopulate;
