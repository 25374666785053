/* eslint-disable import/no-extraneous-dependencies */
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { create } from 'zustand';
import { TeamMemberRequest } from '../types/team-member-request';
import getTeamMemberRequests from '../api/team-member-requests/get-team-member-requests';
import createTeamMemberRequest from '../api/team-member-requests/create-team-member-request';
import getTeamMemberRequest from '../api/team-member-requests/get-team-member-request';
import updateTeamMemberRequest from '../api/team-member-requests/update-team-member-request';
import assignTeamMemberRequest from '../api/team-member-requests/assign-team-member-request';

interface State {
  teamMemberRequests: TeamMemberRequest[];
  isLoading: boolean;
  setState: (newState: Partial<State>) => State;
  fetch: () => Promise<void>;
  fetchById: (requestId: string) => Promise<TeamMemberRequest>;
  createRequest: (opportunityDocumentId: string) => Promise<TeamMemberRequest>;
  updateRequest: (requestId: string, opportunityDocumentId: string) => Promise<void>;
  assignRequestUsers: (requestId: string, userIds: string[]) => Promise<void>;
}

const useTeamMemberRequestStore = create<State>((set, get) => ({
  teamMemberRequests: [],
  isLoading: true,
  setState: (newState: Partial<State>) => {
    set(state => {
      return { ...state, ...newState };
    });
    return get();
  },
  fetch: async (): Promise<void> => {
    set(state => {
      return {
        ...state,
        contacts: [],
        isLoading: true,
      };
    });
    const teamMemberRequests = await getTeamMemberRequests();
    set(state => {
      return { ...state, teamMemberRequests, isLoading: false };
    });
  },
  fetchById: async (requestId: string): Promise<TeamMemberRequest> => {
    const request = get().teamMemberRequests.find(r => r.id === requestId);
    if (request) return request;

    set(state => {
      return {
        ...state,
        isLoading: true,
      };
    });
    const teamMemberRequest = await getTeamMemberRequest(requestId);
    set(state => {
      return { ...state, teamMemberRequests: [...state.teamMemberRequests, teamMemberRequest], isLoading: false };
    });
    return teamMemberRequest;
  },
  createRequest: async (opportunityDocumentId: string): Promise<TeamMemberRequest> => {
    const teamMemberRequest = await createTeamMemberRequest(opportunityDocumentId);
    set(state => {
      return { ...state, teamMemberRequests: [...state.teamMemberRequests, teamMemberRequest] };
    });
    return teamMemberRequest;
  },
  updateRequest: async (requestId: string, opportunityDocumentId: string): Promise<void> => {
    const teamMemberRequest = await updateTeamMemberRequest(requestId, opportunityDocumentId);
    set(state => {
      const findIndex = state.teamMemberRequests.findIndex(r => r.id === requestId);
      const teamMemberRequests = [...state.teamMemberRequests];
      if (findIndex > -1) {
        teamMemberRequests[findIndex] = teamMemberRequest;
      } else {
        teamMemberRequests.push(teamMemberRequest);
      }
      return { ...state, teamMemberRequests };
    });
  },
  assignRequestUsers: async (requestId: string, userIds: string[]): Promise<void> => {
    const teamMemberRequest = await assignTeamMemberRequest(requestId, userIds);
    set(state => {
      const findIndex = state.teamMemberRequests.findIndex(r => r.id === requestId);
      const teamMemberRequests = [...state.teamMemberRequests];
      if (findIndex > -1) {
        teamMemberRequests[findIndex] = teamMemberRequest;
      } else {
        teamMemberRequests.push(teamMemberRequest);
      }
      return { ...state, teamMemberRequests };
    });
  },
}));

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('TeamMemberRequests', useTeamMemberRequestStore);
}

export default useTeamMemberRequestStore;
