import React, { useCallback, useMemo, useState } from 'react';
import FieldManagement from 'components/FieldManagement';
import { useNavigate } from 'react-router-dom';
import Tabs from 'components/Tabs';
import styles from './CrmTaskPage.module.css';
import BaseButton from 'components/BaseButton';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { concat } from 'utils/styling';
import CrmDocumentationTask from 'components/CrmDocTaskManagement';
import useUserStore from 'stores/user';

function CrmTaskPage() {
  const navigate = useNavigate();
  const userStore = useUserStore();
  const next = useCallback(() => {
    navigate(-1);
  }, []);

  const tabs = useMemo(() => {
    if (userStore.isSuperpanelUser()) {
      return [
        { label: 'CRM Field Automation', value: 'field', icon: <div className={styles.squareBlock} /> },
        { label: 'Document Automation', value: 'doc', icon: <div className={styles.squareBlock} /> },
      ];
    }
    return [{ label: 'CRM Field Automation', value: 'field', icon: <div className={styles.squareBlock} /> }];
  }, []);
  const [currentTab, setCurrentTab] = useState(tabs[0].value);

  return (
    <div className={concat('w-screen h-screen', styles.pageContainer)}>
      <div className={styles.leftSection}>
        <div>
          {window.history.length > 1 && (
            <BaseButton color="secondary" variant="contained" onClick={next}>
              <ArrowLeftIcon className="w-4 h-4 text-gray-500" />
            </BaseButton>
          )}
          <Tabs
            tabs={tabs}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            orientation="vertical"
            containerClassName={styles.leftTabs}
          />
        </div>
      </div>
      <div className={styles.rightSection}>
        <div className={styles.rightSectionContent}>
          {currentTab === 'field' ? <FieldManagement cancelButtonText="" next={next} /> : <CrmDocumentationTask />}
        </div>
      </div>
    </div>
  );
}

export default CrmTaskPage;
