import React, { useRef, useState } from 'react';

import SpinLoader from 'components/icons/SpinLoader';
import Popover from 'components/Popover';
import ThreeVerticalDotsIcon from 'components/icons/ThreeVerticalDotsIcon';

import { FieldValue } from 'types/field-value';

import useFieldValuesStore from 'stores/field-values';

interface State {
  isOpen: boolean;
  isLoading: boolean;
}

interface Props {
  discoveryQuestionAnswer: FieldValue;
}

function QuestionSettingButton({ discoveryQuestionAnswer }: Props) {
  const [state, setState] = useState<State>({
    isOpen: false,
    isLoading: false,
  });

  const buttonRef = useRef<HTMLButtonElement>(null);

  const fieldValueStore = useFieldValuesStore();
  const removeQuestion = async () => {
    setState(prevState => ({ ...prevState, isLoading: true }));
    await fieldValueStore.updateFieldValues([{ id: discoveryQuestionAnswer.id, shownInDiscoveryDocument: false }]);
    setState(prevState => ({ ...prevState, isLoading: false, isOpen: false }));
  };

  return (
    <div className="mt-1">
      <button ref={buttonRef} type="button" onClick={() => setState(prevState => ({ ...prevState, isOpen: true }))}>
        <ThreeVerticalDotsIcon className="w-4 h-4" />
      </button>
      <Popover
        anchorEl={buttonRef.current}
        onClose={() => setState(prevState => ({ ...prevState, isOpen: false }))}
        isOpen={state.isOpen}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <div className="p-2">
          {state.isLoading ? (
            <div className="flex items-center justify-center">
              <SpinLoader className="w-4 h-4 animate-spin" />
            </div>
          ) : (
            <button type="button" className="w-full text-left text-sm" onClick={removeQuestion}>
              Remove
            </button>
          )}
        </div>
      </Popover>
    </div>
  );
}

export default QuestionSettingButton;
