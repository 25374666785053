import React from 'react';

interface Props {
  className?: string;
}
function Productboard({ className }: Props) {
  return (
    <svg className={className} height="20px" viewBox="0 0 162 108">
      <path fill="#0071E1" d="M108.001 0L162 54l-54.001 54-53.996-54L108 0z" />
      <path fill="#FFC600" d="M107.997 0L53.999 54 0 0h107.997z" />
      <path fill="#F84136" d="M53.999 54l53.998 54H0l53.999-54z" />
    </svg>
  );
}

Productboard.defaultProps = {
  className: undefined,
};

export default Productboard;
