import React, { useRef, useState } from 'react';
import { CalendarIcon, LockClosedIcon } from '@heroicons/react/24/outline';

import Popover from 'components/Popover';
import OpportunityIcon from 'components/icons/OpportunityIcon';
import NoteIcon from 'components/icons/NoteIcon';
import AccountIcon from 'components/icons/AccountIcon';

import { Document } from 'types/document';

import { Recording } from 'api/recordings/get-recordings';
import { EntityDocument } from 'types/entity';
import PopupList, { State as PopupListState } from './PopupButton/PopupListComponent';
import ConfirmView from './PopupButton/ConfirmView';
import { getEntityNames, getSourceType, SourceType } from './PopupButton/getSourceType';

export interface PopupButtonProps {
  recording: Recording;
  documents: Document[];
  sortedOpportunities: EntityDocument[];
  sortedAccounts: EntityDocument[];
  onChange: () => void;
}

export function acctOppOrDocEle(name: null | string, type: string) {
  if (type === 'acct') {
    return (
      <div className="flex items-start gap-1">
        <AccountIcon className="flex-none w-4 h-4 text-blue-500" />
        <span className="text-gray-500 text-left">{name}</span>
      </div>
    );
  }
  if (type === 'opp') {
    return (
      <div className="flex items-start gap-1">
        <OpportunityIcon className="flex-none w-4 h-4 text-orange-500 fill-orange-500" />
        <span className="text-gray-500 text-left">{name}</span>
      </div>
    );
  }
  if (type === 'doc-evt') {
    return (
      <div className="flex items-start gap-1">
        <CalendarIcon className="flex-none w-4 h-4 text-blue-500" />
        <span className="text-gray-500 text-left">{name}</span>
      </div>
    );
  }
  // if type===doc
  return (
    <div className="flex items-start gap-1">
      <NoteIcon className="flex-none w-4 h-4 text-blue-500" />
      <span className="text-gray-500 text-left">{name}</span>
    </div>
  );
}

interface State {
  popupStage: 'none' | 'list' | 'confirm';
  objType: 'opp' | 'doc' | 'acct' | null;
  objId: string | null;
}

interface PopupButtonDetailProps {
  sourceType: SourceType;
  recording: Recording;
  sourceRef: React.RefObject<HTMLButtonElement>;
  setState: React.Dispatch<React.SetStateAction<State>>;
}

function PopupButtonDetail({ sourceType, recording, sourceRef, setState }: PopupButtonDetailProps) {
  const names = getEntityNames(recording);
  let button;
  if (sourceType.type === 'doc') {
    button = acctOppOrDocEle(names.doc, 'doc');
  } else if (sourceType.type === 'doc-evt') {
    button = acctOppOrDocEle(names.doc, 'doc-evt');
  } else if (sourceType.type === 'opp') {
    button = (
      <div className="flex flex-col gap-1">
        {acctOppOrDocEle(names.acct, 'acct')}
        {acctOppOrDocEle(names.opp, 'opp')}
      </div>
    );
  } else if (sourceType.type === 'opp/doc-evt') {
    button = (
      <div className="flex flex-col gap-1">
        {acctOppOrDocEle(names.acct, 'acct')}
        {acctOppOrDocEle(names.opp, 'opp')}
        {acctOppOrDocEle(names.doc, 'doc-evt')}
      </div>
    );
  } else if (sourceType.type === 'opp/doc') {
    button = (
      <div className="flex flex-col gap-1">
        {acctOppOrDocEle(names.acct, 'acct')}
        {acctOppOrDocEle(names.opp, 'opp')}
        {acctOppOrDocEle(names.doc, 'doc')}
      </div>
    );
  } else if (sourceType.type === 'acct') {
    button = <div className="flex flex-col gap-1">{acctOppOrDocEle(names.acct, 'acct')}</div>;
  } else if (sourceType.type === 'acct/doc-evt') {
    button = (
      <div className="flex flex-col gap-1">
        {acctOppOrDocEle(names.acct, 'acct')}
        {acctOppOrDocEle(names.doc, 'doc-evt')}
      </div>
    );
  } else if (sourceType.type === 'acct/doc') {
    button = (
      <div className="flex flex-col gap-1">
        {acctOppOrDocEle(names.acct, 'acct')}
        {acctOppOrDocEle(names.doc, 'doc')}
      </div>
    );
  } else if (sourceType.type === null) {
    button = <div className=" hover:text-gray-600 text-gray-400">Link to Deal/Event</div>;
  } else if (sourceType.type === 'protected') {
    button = (
      <div className="flex items-center hover:text-gray-600 text-gray-400 gap-2">
        <LockClosedIcon className="w-3 h-3" />
        Protected Event
      </div>
    );
  }

  return (
    <button
      ref={sourceRef}
      onClick={() => setState(prevState => ({ ...prevState, popupStage: 'list' }))}
      type="button"
      className="w-[80%] rounded-lg bg-gray-100 px-5 py-2 "
      disabled={sourceType.type === 'protected'}
    >
      {button}
    </button>
  );
}

function PopupButton({ recording, documents, sortedOpportunities, sortedAccounts, onChange }: PopupButtonProps) {
  const [state, setState] = useState<State>({
    popupStage: 'none',
    objType: null,
    objId: null,
  });
  const [popupListState, setPopupListState] = useState<PopupListState>({
    searchItems: [],
    searchPhrase: '',
    finalSearchPhrase: '',
    isLoading: false,
  });
  const sourceRef = useRef<HTMLButtonElement>(null);
  const sourceType = getSourceType(recording);

  let popOver: JSX.Element | null = null;

  const updateData = (objType: 'opp' | 'doc' | 'acct' | null, objId: string | null) => {
    setState(prevState => ({
      ...prevState,
      popupStage: 'confirm',
      objType,
      objId,
    }));
  };

  if (state.popupStage === 'list') {
    popOver = (
      <PopupList
        state={popupListState}
        setState={setPopupListState}
        recording={recording}
        sortedOpportunities={sortedOpportunities}
        sortedAccounts={sortedAccounts}
        documents={documents}
        updateData={updateData}
      />
    );
  } else if (state.popupStage === 'confirm') {
    popOver = (
      <ConfirmView
        parentState={state}
        setParentState={setState}
        recording={recording}
        onChange={onChange}
        onClose={() => {
          setState(prevState => ({
            ...prevState,
            popupStage: 'none',
          }));
          setPopupListState(prevState => ({ ...prevState, finalSearchPhrase: '' }));
        }}
      />
    );
  }

  return (
    <div>
      <PopupButtonDetail setState={setState} recording={recording} sourceType={sourceType} sourceRef={sourceRef} />
      <Popover
        isOpen={state.popupStage !== 'none'}
        onClose={() => {
          setState(prevState => ({
            ...prevState,
            popupStage: 'none',
          }));
          setPopupListState(prevState => ({ ...prevState, finalSearchPhrase: '' }));
        }}
        anchorEl={sourceRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <div className="w-[600px] h-[500px]">{popOver}</div>
      </Popover>
    </div>
  );
}

export default PopupButton;
