import moment from 'moment';
import { User } from '../types/user';
import config from './config';

const DEFAULT_SCRIPT_ID = 'initialize-intercom-script';

const getIntercomScript = (intercomId: string, user: User) => `
  window.intercomSettings = {
    api_base: "https://api-iam.intercom.io",
    app_id: "${intercomId}", 
    name: "${user.firstName} ${user.lastName}", // Full name
    email: "${user.email}", // Email address
    created_at: "${moment(user.createdAt).unix()}" // Signup date as a Unix timestamp
  };
  (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/v60blhzp';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
`;

const initialize = (user: User | null | undefined) => {
  if (!user) {
    return;
  }

  const intercomId = config.INTERCOM_ID;
  if (intercomId === '' || intercomId === 'NONE') {
    return;
  }

  const hasScript = !!document.getElementById(DEFAULT_SCRIPT_ID);
  if (hasScript) {
    return;
  }
  const script = document.createElement('script');
  script.innerHTML = getIntercomScript(intercomId, user);
  script.id = DEFAULT_SCRIPT_ID;
  script.type = 'text/javascript';

  document.head.appendChild(script);
};

export default {
  initialize,
};
