import Filter2Icon from 'components/icons/Filter2Icon';
import React, { useMemo, useRef, useState } from 'react';
import Popover from '../../components/Popover';
import CustomizeFieldMenu from '../../components/Table/CustomizeFieldMenu';
import { ColumnConfig, GroupColumnDefinition } from '../../components/Table/types';
import { concat } from 'utils/styling';
import { flatten } from 'lodash';

interface Props {
  tableConfig: ColumnConfig[];
  updateTableConfig: (config: ColumnConfig[]) => void;
}

function CustomizeButton({ tableConfig, updateTableConfig }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);
  const numOfColShown = useMemo(() => {
    return flatten(
      tableConfig.map(col => ('group' in col ? col.columns.map(item => item.isHidden) : col.isHidden)),
    ).filter(hide => !hide).length;
  }, [tableConfig]);
  const totalCols = useMemo(() => {
    return flatten(tableConfig.map(col => ('group' in col ? col.columns : col))).length;
  }, [tableConfig]);

  return (
    <>
      <button
        ref={ref}
        type="button"
        className={concat(
          'flex items-center gap-1 text-gray-400 hover:bg-gray-100 rounded p-1',
          totalCols !== numOfColShown && 'text-green-500',
        )}
        onClick={() => setIsOpen(true)}
      >
        <Filter2Icon />
        Customize
        <span className="text-xs rounded-full text-center leading-4">({numOfColShown})</span>
      </button>
      <Popover
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        isOpen={isOpen}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <div className="w-96">
          <CustomizeFieldMenu
            columnConfigs={tableConfig}
            updateColumnConfigs={reportingTableConfigs => {
              updateTableConfig(reportingTableConfigs as GroupColumnDefinition[]);
            }}
          />
        </div>
      </Popover>
    </>
  );
}

export default CustomizeButton;
