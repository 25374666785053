import React, { useEffect, useMemo, useState } from 'react';

import useRecordingsStore from 'stores/recordings';
import { ArrowDownOnSquareIcon, CheckIcon } from '@heroicons/react/24/outline';
import CopyIcon from 'components/icons/CopyIcon';
import AIICon from 'components/icons/AIIcon';
import SpinLoader from 'components/icons/SpinLoader';
import useNoteEditorStore from 'stores/note-editor';
import PlayBack from 'components/FieldValueView/PlayBack';
import Tooltip from 'components/Tooltip';
import { concat } from 'utils/styling';

interface State {
  isShowing: boolean;
  isLoading: boolean;
  isCopied: boolean;
}

interface Props {
  className?: string;
  documentId: string;
}

function SummarySelect({ className, documentId }: Props) {
  const [state, setState] = useState<State>({
    isLoading: false,
    isShowing: false,
    isCopied: false,
  });

  const recordingsStore = useRecordingsStore();
  const noteEditorStore = useNoteEditorStore();

  useEffect(() => {
    const timer = setTimeout(() => {
      setState(prevState => ({ ...prevState, isCopied: false }));
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, [state.isCopied]);

  const matchedRecordings = useMemo(
    () =>
      recordingsStore.recordings.filter(
        recording =>
          recording.entityOrDocumentId &&
          documentId === recording.entityOrDocumentId &&
          (recording.summaryData || recording.isSummaryBeingProcessed),
      ),
    [recordingsStore.recordings, documentId],
  );

  if (recordingsStore.isLoading) {
    return null;
  }

  if (matchedRecordings && matchedRecordings.length > 0) {
    return (
      <div className={concat('my-2 p-2 bg-blue-50 rounded-lg mr-4', className)}>
        <div className="w-full justify-between gap-1 flex-shrink-0 flex-grow-0 align flex items-center">
          <div className="flex flex-row gap-2">
            <AIICon className="w-5 h-5 text-[#2D6CEA]" />
            <span className="text-gray-800 font-medium text-sm">Meeting Summary</span>
            {matchedRecordings.find(r => r.isSummaryBeingProcessed) !== undefined ? (
              <div className="w-5 h-5">
                <SpinLoader className="animate-spin w-5 h-5 text-orange-500" />
              </div>
            ) : (
              <span className="flex gap-1 items-center">
                <span className="rounded-xl px-2 bg-blue-100 text-blue-500 text-xs">Beta</span>
              </span>
            )}
          </div>
          <button
            type="button"
            className="w-12 text-xs bg-blue-800 py-1 px-2 rounded text-white hover:bg-blue-700"
            onClick={() => {
              setState(prevState => {
                return { ...prevState, isShowing: !state.isShowing };
              });
            }}
          >
            {state.isShowing ? 'Hide' : 'Show'}
          </button>
        </div>
        {state.isShowing &&
          matchedRecordings.map((recording, index) => {
            if (recording.summaryData) {
              const summaryStringObj = recordingsStore.stringfySummary(recording.summaryData);
              const summaryItems: JSX.Element[] = [
                <div key="overallSummaryTitle" className="flex text-sm grow-0 rounded font-medium">
                  Overall Summary
                </div>,
                <div key="overallSummary" className="whitespace-pre-line text-gray-600 text-sm">
                  {summaryStringObj.overallSummary}
                </div>,
              ];
              if (recording.summaryData.items) {
                recording.summaryData.items.forEach((item, idx) => {
                  if (item.label) {
                    summaryItems.push(
                      <div key={`${item.startTime}Title`} className="flex text-sm grow-0 rounded font-medium">
                        {idx + 1}. {item.label}
                      </div>,
                    );
                  }
                  summaryItems.push(
                    <div key={`${item.startTime}Summary`} className="whitespace-pre-line text-gray-600 text-sm">
                      {item.label === null && `${idx + 1}. `}
                      {item.summary}
                      {item.startTime !== null && (
                        <div className="inline-block">
                          <PlayBack
                            recordingId={recording.id}
                            startTime={item.startTime}
                            from="docEditor"
                            elementId=""
                          />
                        </div>
                      )}
                    </div>,
                  );
                });
              }
              return (
                <div
                  key={recording.id}
                  className={`flex justify-between items-center w-full p-2 pr-2 ${
                    matchedRecordings.length > 0 && index !== matchedRecordings.length - 1 ? 'border-b-2' : ''
                  }`}
                >
                  <div className="flex flex-col gap-2 ml-[20px]">{summaryItems}</div>
                  <div className="flex flex-col h-full">
                    <div className="flex space-x-4">
                      <button
                        type="button"
                        className="justify-center ml-3 text-gray-400 hover:text-gray-500"
                        onClick={() => {
                          if (recording.summaryData) {
                            navigator.clipboard.writeText(summaryStringObj.toCopy);
                          }
                          setState(prevState => ({ ...prevState, isCopied: true }));
                        }}
                      >
                        {!state.isCopied ? <CopyIcon className="w-4 h-4" /> : <CheckIcon className="w-5 h-5" />}
                      </button>

                      <Tooltip title={<div>Add to the document</div>} placement="top">
                        <button
                          type="button"
                          onClick={() => setState(prevState => ({ ...prevState, isInserted: true, isShowing: false }))}
                        >
                          <ArrowDownOnSquareIcon
                            onClick={() => {
                              noteEditorStore.setState({
                                callSummaryToAdd: { recordingId: recording.id, documentId },
                              });
                              setState(prevState => ({ ...prevState, isInserted: true }));
                            }}
                            className="w-5 h-5 text-gray-400 hover:text-gray-500"
                          />
                        </button>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              );
            }
            return '';
          })}
      </div>
    );
  }
  return null;
}

SummarySelect.defaultProps = {
  className: '',
};

export default SummarySelect;
