/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/no-extraneous-dependencies */
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { createJSONStorage, persist } from 'zustand/middleware';
import { create } from 'zustand';

import { ColumnConfig, GroupColumnDefinition } from 'components/Table/types';
import { ProductField, ProductFieldType } from '../types/product-gap';
import { DEFAULT_FIELD_FEEDBACK_COLUMN, DEFAULT_PRODUCT_GAP_TABLE_CONFIG } from '../types/table';

interface State {
  productGapTableConfigs: ColumnConfig[];
  reportingTableConfigs: GroupColumnDefinition[];
  generateProductGapTableConfigs: (productFields: ProductField[]) => ColumnConfig[];
  setState: (newState: Partial<State>) => State;
}

const useTableStore = create<State>()(
  persist(
    (set, get) => ({
      productGapTableConfigs: [...DEFAULT_PRODUCT_GAP_TABLE_CONFIG],
      reportingTableConfigs: [],
      generateProductGapTableConfigs: (productFields: ProductField[]) => {
        let configs = [...get().productGapTableConfigs];

        // Adds Missing Configs from Product Fields
        const columnFieldNames: string[] = [];
        configs.forEach(config => {
          if ('name' in config && config.id.includes('field-') && !columnFieldNames.includes(config.name)) {
            columnFieldNames.push(config.name);
          }
        });
        productFields.forEach(field => {
          if (!columnFieldNames.includes(field.title)) {
            let { fieldType } = field;
            if (fieldType === 'Select') fieldType = ProductFieldType.TEXT;
            if (field.title === 'Feedback') {
              configs.push({ ...DEFAULT_FIELD_FEEDBACK_COLUMN });
            } else if (field.title === 'Title' && configs.length > 1) {
              configs.splice(1, 0, {
                id: `field-${field.title}`,
                name: field.title,
                width: 300,
                type: fieldType,
                isHidden: false,
              });
            } else {
              configs.push({
                id: `field-${field.title}`,
                name: field.title,
                width: 300,
                type: fieldType,
                isHidden: false,
              });
            }
          }
        });

        // Removing Configs that are not in Product Fields
        const defaultFieldIds = DEFAULT_PRODUCT_GAP_TABLE_CONFIG.map(config => config.id);
        const productFieldTitles = productFields.map(productField => productField.title);
        configs = configs.filter(config => {
          // Check Default Fields
          if (defaultFieldIds.includes(config.id)) return true;

          // Check Product Fields
          return 'name' in config && productFieldTitles.includes(config.name);
        });

        set(state => ({ ...state, productGapTableConfigs: configs }));
        return configs;
      },
      setState: (newState: Partial<State>) => {
        set(state => {
          return { ...state, ...newState };
        });
        return get();
      },
    }),
    {
      name: 'table-store',
      storage: createJSONStorage(() => localStorage),
      version: 2,
      migrate: async (persistedState, version) => {
        const temp = { ...(persistedState as State) };
        if (version === 1) {
          // clear filter
          temp.reportingTableConfigs = [];
        }
        return temp;
      },
      partialize: state => ({
        reportingTableConfigs: state.reportingTableConfigs,
      }),
    },
  ),
);

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('Table', useTableStore);
}

export default useTableStore;
