import React, { useEffect, useState } from 'react';
import fetchTeamDetail, { TeamDetail } from 'admin-src/api/fetch-team-detail';
import SpinLoader from 'components/icons/SpinLoader';
import { useParams } from 'react-router-dom';

interface State {
  isLoading: boolean;
  teamDetail: TeamDetail;
}

function AdminUser() {
  const [state, setState] = useState<State>({
    isLoading: true,
    teamDetail: {
      id: '',
      enableSharedCRM: false,
      crmProvider: null,
      adminUser: '',
      opportunityInfo: {},
    },
  });
  const { teamId } = useParams();

  useEffect(() => {
    const getTeamDetail = async () => {
      setState(prevState => ({ ...prevState, isLoading: true, isAuthorized: true }));
      if (teamId) {
        const teamDetail = await fetchTeamDetail(teamId);
        setState(prevState => ({ ...prevState, teamDetail, isLoading: false }));
      }
    };
    if (teamId) {
      getTeamDetail();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId]);

  if (state.isLoading) {
    return <SpinLoader className="w-4 h-4 animate-spin" />;
  }

  return (
    <div className="h-full m-4">
      <div className="text-2xl">Team Detail</div>
      <div className="h-[600px] overflow-scroll py-5">
        <pre>{JSON.stringify(state.teamDetail, null, 2)}</pre>
      </div>
    </div>
  );
}

export default AdminUser;
