import React, { useState } from 'react';
import { CheckIcon } from '@heroicons/react/24/outline';
import moment from 'moment';

import AccountIcon from 'components/icons/AccountIcon';
import SpinLoader from 'components/icons/SpinLoader';
import useDocumentStore from 'stores/document';
import { SearchItem } from '../../../api/search/search-items';
import { Recording } from '../../../api/recordings/get-recordings';
import OpportunityIcon from '../../../components/icons/OpportunityIcon';
import CalendarIcon from '../../../components/icons/CalendarIcon';
import constants from '../../../utils/constants';

interface Props {
  searchItem: SearchItem;
  recording: Recording;
  updateData: (objType: 'opp' | 'doc' | 'acct', objId: string) => void;
}

function EventButtonFromSearch({ searchItem, recording, updateData }: Props) {
  const isLinked = recording.entityOrDocumentId === searchItem.documentId;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const documentStore = useDocumentStore();
  const { documentId } = searchItem;
  if (documentId === null) {
    return null;
  }

  return (
    <button
      type="button"
      className={`flex justify-between w-full min-h-8 items-center pr-3 p-2 hover:bg-slate-100 rounded-lg ${
        isLinked ? 'cursor-default' : 'hover:bg-gray-100 cursor-pointer'
      }`}
      onClick={async () => {
        setIsLoading(true);
        await documentStore.getDocument(documentId);
        updateData('doc', documentId);
        setIsLoading(false);
      }}
      disabled={isLinked || isLoading}
    >
      <div className="text-sm text-gray-500">
        <div className="flex gap-2 text-left">
          <CalendarIcon className="w-5 h-5 flex-none text-blue-500" />
          <div className="text-gray-500 text-left">
            <div className="">{searchItem.title}</div>
            <div>{moment(searchItem.datetime).format(constants.DATETIME_FORMAT)}</div>
          </div>
        </div>
        {searchItem.parent && (
          <div className="flex items-center pl-6 text-xs mt-1">
            ({' '}
            {searchItem.parent.type === 'Opportunity' ? (
              <OpportunityIcon className="mx-1 w-3.5 h-3.5 text-orange-500 fill-orange-500" />
            ) : (
              <AccountIcon className="mx-1 w-3 h-3" />
            )}{' '}
            {searchItem.parent.name} )
          </div>
        )}
        {isLoading && (
          <div>
            <SpinLoader className="animate-spin w-4 h-4 text-orange-500" />
          </div>
        )}
      </div>
      {isLinked && <CheckIcon className="w-5 h-5 flex-none text-blue-500" />}
    </button>
  );
}

export default EventButtonFromSearch;
