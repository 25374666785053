import React from 'react';

interface Props {
  className?: string;
}

function CornerArrowIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.9065 10.1875L6 14.0935L9.9065 18"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5 5.49926V10.9683C18.5 11.7971 18.1708 12.5919 17.5847 13.178C16.9987 13.764 16.2038 14.0933 15.375 14.0933H6"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

CornerArrowIcon.defaultProps = {
  className: undefined,
};

export default CornerArrowIcon;
