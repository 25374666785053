import { ReactEditor } from 'slate-react';
// eslint-disable-next-line import/no-extraneous-dependencies
import scrollIntoView from 'scroll-into-view-if-needed';
import { Range } from 'slate';
import { BlockElement, BlockType } from './types';
import AppDOMRange = globalThis.Range;

export { AppDOMRange };
export const scrollSelectionIntoView = (editor: ReactEditor, domRange: AppDOMRange) => {
  // This was affecting the selection of multiple blocks and dragging behavior,
  // so enabled only if the selection has been collapsed.
  if (
    domRange.getBoundingClientRect &&
    (!editor.selection || (editor.selection && Range.isCollapsed(editor.selection)))
  ) {
    const path = editor.selection?.focus.path;
    if (path) {
      const pathParent = [...path];
      if (pathParent.length > 1) {
        pathParent.pop();
      }

      const [node] = editor.node(pathParent);
      const ele = node as BlockElement;
      if (ele.type === BlockType.AIWorker) {
        return;
      }
    }
    const leafEl = domRange.startContainer.parentElement!;
    leafEl.getBoundingClientRect = domRange.getBoundingClientRect.bind(domRange);
    scrollIntoView(leafEl, {
      scrollMode: 'if-needed',
    });

    // @ts-expect-error an unorthodox delete D:
    delete leafEl.getBoundingClientRect;
  }
};
