/* eslint-disable import/no-cycle, react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { CommentContent, CommentRecord } from 'components/NoteEditor/types';
import getTimeAgo from 'utils/date';
import { ArrowPathIcon, CheckIcon, EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import ButtonPopover from 'components/ButtonPopover';
import BaseButton from 'components/BaseButton';
import { concat } from 'utils/styling';
import { Member } from 'types/member';
import { ListItem } from 'types/list-item';
import CommentUserAvatar from './CommentUserAvatar';
import styles from './PostedCommentRow.module.css';
import CommentEditor from './CommentEditor';

interface Props {
  comment: CommentRecord;
  members: { [userId: string]: Member };
  menus: ListItem<string>[];
  onAction: (action: 'resolve' | 'delete' | 'update', params?: CommentContent) => void;
  isFirst: boolean;
  contentPrefix: React.ReactNode;
  editorKey: string;
  documentId: string;
  opportunityId: string;
}

function PostedCommentRow({
  comment,
  members,
  menus,
  onAction,
  isFirst,
  contentPrefix,
  editorKey,
  documentId,
  opportunityId,
}: Props) {
  const member = useMemo(() => members[comment.userId], [comment, members]);
  const [time, setTime] = useState('');
  const [popoverShow, setPopoverShow] = useState(false);
  const [readOnly, setReadonly] = useState(true);

  useEffect(() => {
    const timer = setInterval(() => setTime(getTimeAgo(new Date(comment.updatedDate || comment.createdDate))), 30000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    setTime(getTimeAgo(new Date(comment.updatedDate || comment.createdDate)));
  }, [comment.updatedDate, comment.createdDate]);

  return (
    <div className={concat(styles.wrap, isFirst && styles.isFirst, popoverShow && styles.popoverShow)}>
      <div className={styles.topRow}>
        <CommentUserAvatar member={member} />
        <div className={styles.timeWrap}>
          {time}&nbsp;{comment.updatedDate ? '(edited)' : ''}
        </div>
        {readOnly && (
          <div className={styles.actions}>
            {isFirst && (
              <BaseButton
                tooltip={comment.resolved ? 'Re-Open' : 'Resolve'}
                color="secondary"
                variant="text"
                iconBtn
                onClick={() => onAction('resolve')}
              >
                {comment.resolved ? <ArrowPathIcon width={16} /> : <CheckIcon width={16} />}
              </BaseButton>
            )}
            <ButtonPopover
              btnColor="secondary"
              btnVariant="text"
              btnClassName="h-6"
              closeWhenPopoverClick
              iconBtn
              popoverToggle={setPopoverShow}
              menus={menus}
              handleMenuClick={menu => {
                if (menu.value === 'edit') {
                  setReadonly(false);
                } else {
                  onAction('delete');
                }
              }}
              horizontalOrigin="right"
              popoverClassName="w-[12rem]"
            >
              <EllipsisHorizontalIcon width={16} />
            </ButtonPopover>
          </div>
        )}
      </div>
      <div className={styles.content}>
        {contentPrefix}
        <CommentEditor
          content={comment.content}
          readOnly={readOnly}
          submitBtntype="check"
          onCancel={() => setReadonly(true)}
          onSubmit={newContent => {
            onAction('update', newContent);
            setReadonly(true);
          }}
          key={editorKey}
          documentId={documentId}
          opportunityId={opportunityId}
        />
      </div>
    </div>
  );
}

export default PostedCommentRow;
