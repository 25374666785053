/* eslint-disable import/no-extraneous-dependencies */
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { create } from 'zustand';

import getRecordingsByDocumentId from 'api/recordings/get-recordings-by-document-id';
import getRecordingById from 'api/recordings/get-recording-by-id';

import { Recording, RecordingSummary } from 'types/recording';
import getRecordingsByFieldValueId from '../api/recordings/get-recordings-by-field-value-id';

interface SummaryStringObj {
  toCopy: string;
  overallSummary: string;
}

interface State {
  isLoading: boolean;
  recordings: Recording[];
  playing: boolean;
  recordingId?: string;
  startTime?: number;
  elementId?: string;
  fromContext?: string;
  setState: (newState: Partial<State>) => State;
  fetch: (documentId: string, clearBeforeFetch?: boolean) => Promise<void>;
  fetchById: (recordingId: string) => Promise<void>;
  fetchByFieldValueId: (fieldValueId: string) => Promise<void>;
  checkDownloadStatus: (documentId: string) => Promise<boolean>;
  checkSummaryStatus: (documentId: string) => Promise<void>;
  checkProductFeedbackStatus: (documentId: string) => Promise<void>;
  stringfySummary: (summary: RecordingSummary) => SummaryStringObj;
}

const useRecordingsStore = create<State>((set, get) => ({
  isLoading: true,
  recordings: [],
  playing: false,
  recordingId: undefined,
  startTime: undefined,
  elementId: undefined,
  fromContext: undefined,
  setState: (newState: Partial<State>) => {
    set(state => {
      return { ...state, ...newState };
    });
    return get();
  },
  fetchById: async (recordingId: string) => {
    set(state => ({ ...state, isLoading: true, recordings: [] }));
    const { recordings } = await getRecordingById(recordingId);
    set(state => ({ ...state, isLoading: false, recordings }));
  },
  fetch: async (documentId: string, clearBeforeFetch = true) => {
    if (clearBeforeFetch) {
      set(state => ({ ...state, isLoading: true, recordings: [] }));
    } else {
      set(state => ({ ...state, isLoading: true }));
    }
    const { recordings } = await getRecordingsByDocumentId(documentId);
    set(state => ({ ...state, isLoading: false, recordings }));
  },
  fetchByFieldValueId: async (fieldValueId: string) => {
    set(state => ({ ...state, isLoading: true, recordings: [] }));
    const { recordings } = await getRecordingsByFieldValueId(fieldValueId);
    set(state => ({ ...state, isLoading: false, recordings }));
  },
  checkDownloadStatus: async documentId => {
    const { recordings } = await getRecordingsByDocumentId(documentId);
    const isSomeRecordingDone = get().recordings.some(
      recording =>
        recording.isRecordingBeingDownloaded &&
        recordings.find(r => r.id === recording.id)?.isRecordingBeingDownloaded === false,
    );
    const newRecordings = get().recordings.map(recording => {
      const newRec = recordings.find(r => r.id === recording.id);
      // update recording only when changed to prevent UI refresh
      return newRec && recording.isRecordingBeingDownloaded !== newRec?.isRecordingBeingDownloaded ? newRec : recording;
    });
    set(state => ({ ...state, recordings: newRecordings }));
    return isSomeRecordingDone;
  },
  checkSummaryStatus: async (documentId: string) => {
    const { recordings } = await getRecordingsByDocumentId(documentId);
    const newRecordings = get().recordings.map(recording => {
      const newRec = recordings.find(r => r.id === recording.id);
      // update recording only when changed to prevent UI refresh
      return newRec && recording.isSummaryBeingProcessed !== newRec?.isSummaryBeingProcessed ? newRec : recording;
    });
    set(state => ({ ...state, recordings: newRecordings }));
  },
  checkProductFeedbackStatus: async (documentId: string) => {
    const { recordings } = await getRecordingsByDocumentId(documentId);
    const newRecordings = get().recordings.map(recording => {
      const newRec = recordings.find(r => r.id === recording.id);
      // update recording only when changed to prevent UI refresh
      return newRec && recording.isProductFeedbackBeingProcessed !== newRec?.isProductFeedbackBeingProcessed
        ? newRec
        : recording;
    });
    set(state => ({ ...state, recordings: newRecordings }));
  },
  stringfySummary: (summary: RecordingSummary) => {
    let summaryStr = 'Overall Summary:\n';
    const overallSummary = summaryStr.concat(summary.overallSummary.replace(/<br\s*[/]?>/gi, '\n '));
    if (summary.items === null) {
      return { overallSummary: summary.overallSummary.replace(/<br\s*[/]?>/gi, '\n '), toCopy: overallSummary };
    }
    if (summary.items.length === 0) {
      return { overallSummary: summary.overallSummary.replace(/<br\s*[/]?>/gi, '\n '), toCopy: overallSummary };
    }
    summaryStr = summaryStr.concat(summary.overallSummary);
    summaryStr = summaryStr.concat('\n\n');
    summary.items.forEach(item => {
      if (item.label) {
        summaryStr = summaryStr.concat(`${item.label}:\n`);
      }
      summaryStr = summaryStr.concat(item.summary);
      summaryStr = summaryStr.concat('\n\n');
    });
    return {
      overallSummary: summary.overallSummary.replace(/<br\s*[/]?>/gi, '\n '),
      toCopy: summaryStr.replace(/<br\s*[/]?>/gi, '\n '),
    };
  },
}));

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('Recordings', useRecordingsStore);
}

export default useRecordingsStore;
