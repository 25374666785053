import { ChevronDownIcon, ChevronRightIcon, PlusIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MenuItem } from '@mui/material';

import AccountIcon from 'components/icons/AccountIcon';
import FadeIn from 'components/FadeIn';
import OpportunityIcon from 'components/icons/OpportunityIcon';
import NoteIcon from 'components/icons/NoteIcon';
import Popover from 'components/Popover';

import useDocumentStore from 'stores/document';
import useModalStore from 'stores/modal';
import useNavMenuStore from 'stores/nav-menu';
import useUserStore from 'stores/user';

import { EntityDocument } from 'types/entity';

import orderDocuments from 'utils/order-documents';

import useTemplateStore from 'stores/templates';
import CreateNewEntityModal from './CreateNewEntityModal';
import UploadDocumentModal from './UploadDocumentModal';
import Deal from './Deal';
import DocumentLink from './DocumentLink';
import CrmNoteLink from './CrmNoteLink';
import { useNewDocumentModal } from '../NewDocumentModal';
import { CrmNote } from 'types/document';

interface State {
  isNewOpen: boolean;
}

interface Props {
  account: EntityDocument;
  opportunities: EntityDocument[];
}

function AccountLink({ account, opportunities }: Props) {
  const [state, setState] = useState<State>({ isNewOpen: false });
  const newButtonRef = useRef<HTMLButtonElement>(null);
  const accountLinkRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const documentStore = useDocumentStore();
  const templateStore = useTemplateStore();
  const modalStore = useModalStore();
  const userStore = useUserStore();
  const navMenuStore = useNavMenuStore();
  const { createNewDocumentWithModal } = useNewDocumentModal();

  const isOpen = useMemo(() => navMenuStore.entitiesOpen.includes(account.id), [navMenuStore.entitiesOpen, account.id]);
  const path = `/account/${account.id}`;

  useEffect(() => {
    if (accountLinkRef.current && location.pathname.match(path)) {
      navMenuStore.setState({ activeEntityRef: accountLinkRef });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountLinkRef, location.pathname]);

  const accountDocuments = useMemo(
    () =>
      documentStore.documents.filter(d => d.parent && d.parent.entityName === 'Account' && d.parent.id === account.id),
    [documentStore.documents, account],
  );
  const accountCrmNotes = useMemo(
    () =>
      documentStore.crmNotes.filter(
        (c: CrmNote) => c.linkedEntity.name === 'Account' && c.linkedEntity.id === account.crm?.id,
      ),
    [documentStore.crmNotes, account],
  );
  const accountItems = useMemo(
    () => orderDocuments(accountDocuments, accountCrmNotes),
    [accountDocuments, accountCrmNotes],
  );
  const filterExist = useMemo(() => documentStore.documentFilters.length > 0, [documentStore.documentFilters]);

  return (
    <div ref={accountLinkRef} className="flex flex-col items-stretch mb-0.5 scroll-m-36">
      {/* TODO fix the sticky UX */}
      <Link
        className={`group flex items-center gap-0.5 p-0.5 rounded overflow-x-clip hover:bg-gray-200 hover:text-black sticky top-0 z-20 ${
          location.pathname.match(path)
            ? 'text-blue-600 bg-gray-100 font-semibold'
            : 'text-gray-500 bg-white font-medium'
        }`}
        to={path}
        style={{ textDecoration: 'none' }}
      >
        <button
          className="flex"
          onClick={event => {
            event.preventDefault();
            if (isOpen) {
              navMenuStore.removeEntityOpen(account.id);
            } else {
              navMenuStore.addEntityOpen([account.id]);
            }
          }}
          type="button"
        >
          {!isOpen ? (
            <ChevronRightIcon className="w-5 h-5 stroke-2 text-gray-400 font-medium hover:bg-gray-300 rounded p-1" />
          ) : (
            <ChevronDownIcon className="w-5 h-5 stroke-2 text-gray-400 font-medium hover:bg-gray-300 rounded p-1" />
          )}
          {!account.isFilter && filterExist && (
            <div className="absolute right-6 w-9 px-1 text-xs text-gray-400 bg-gray-100 border rounded-lg border-gray-400 line-through">
              filter
            </div>
          )}
        </button>

        <div className="flex items-center gap-2 flex-1 w-1">
          <AccountIcon className="min-w-[1.5rem] w-6 h-6 text-orange-400 fill-orange-400" />
          <div className="text-sm truncate">{account.name}</div>
          <div className="text-sm text-gray-400 font-light">({opportunities.length + accountDocuments.length})</div>
        </div>
        <div className="flex items-center">
          <button
            ref={newButtonRef}
            type="button"
            className={`w-6 h-6 text-gray-400 hover:bg-gray-300 rounded p-1 ${
              !state.isNewOpen && 'opacity-0'
            } group-hover:opacity-100`}
            onClick={event => {
              event.preventDefault();
              setState(prevState => ({ ...prevState, isNewOpen: !prevState.isNewOpen }));
            }}
          >
            <PlusIcon />
          </button>
        </div>
      </Link>
      <FadeIn isOpen={isOpen}>
        {isOpen ? (
          <div className="flex flex-col ml-2">
            {accountItems.map(element => {
              if ('docTitle' in element) {
                return <DocumentLink key={element.id} document={element} ml="ml-[20px]" addPaddingOffSet={false} />;
              }
              return <CrmNoteLink key={element.id} crmNote={element} ml="ml-[20px]" addPaddingOffSet={false} />;
            })}
            {opportunities.length > 0 &&
              opportunities.map(o => <Deal key={o.id} opportunity={o} noteTemplates={templateStore.templates} />)}
          </div>
        ) : null}
      </FadeIn>
      <Popover
        anchorEl={newButtonRef.current}
        onClose={() => setState(prevState => ({ ...prevState, isNewOpen: false }))}
        isOpen={state.isNewOpen}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <div>
          <MenuItem
            className="p-2 flex items-center gap-2"
            onClick={async () => {
              createNewDocumentWithModal({ parentId: account.id, title: null });
              setState(prevState => ({ ...prevState, isNewOpen: false }));
            }}
          >
            <NoteIcon className="w-5 h-5 text-gray-500" />
            <div className="text-sm text-gray-600">Create a new document</div>
          </MenuItem>
          <MenuItem
            className="p-2 flex items-center gap-2"
            onClick={() => {
              modalStore.setState({
                element: <UploadDocumentModal parentDocumentId={account.id} siblingDocuments={accountDocuments} />,
                isOpen: true,
              });
              setState(prevState => ({ ...prevState, isNewOpen: false }));
            }}
          >
            <NoteIcon className="w-5 h-5 text-gray-500" />
            <div className="text-sm text-gray-600">Upload document</div>
          </MenuItem>
          {userStore.user && !userStore.user.isIntegratedWithCrm && (
            <MenuItem
              className="p-2 flex items-center gap-2"
              onClick={async () => {
                modalStore.setState({
                  isOpen: true,
                  element: <CreateNewEntityModal entityName="Opportunity" parentDocumentId={account.id} />,
                });
                setState(prevState => ({ ...prevState, isNewOpen: false }));
              }}
            >
              <OpportunityIcon className="w-5 h-5 text-orange-400 fill-orange-400" />
              <div className="text-sm text-gray-600">Create a new opportunity</div>
            </MenuItem>
          )}
        </div>
      </Popover>
    </div>
  );
}

export default AccountLink;
