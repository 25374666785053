/* eslint-disable jsx-a11y/no-autofocus */
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { EntityDocument } from 'types/entity';
import { Document } from 'types/document';
import LinkBrokenIcon from 'components/icons/LinkBrokenIcon';
import { Recording } from 'api/recordings/get-recordings';
import CornerArrowIcon from 'components/icons/CornerArrow';
import SpinLoader from 'components/icons/SpinLoader';
import OpportunityButton from './OpportunityButton';
import AccountButton from './AccountButton';
import searchItems, { SearchItem } from '../../../api/search/search-items';
import AccountButtonFromSearch from './AccountButtonFromSearch';
import OpportunityButtonFromSearch from './OpportunityButtonFromSearch';
import DocumentButtonFromSearch from './DocumentButtonFromSearch';
import EventButtonFromSearch from './EventButtonFromSearch';
import Loader from '../../../components/Loader';

export interface State {
  searchItems: SearchItem[];
  searchPhrase: string;
  finalSearchPhrase: string;
  isLoading: boolean;
}

interface Props {
  state: State;
  setState: React.Dispatch<React.SetStateAction<State>>;
  recording: Recording;
  sortedOpportunities: EntityDocument[];
  sortedAccounts: EntityDocument[];
  documents: Document[];
  updateData: (objType: 'opp' | 'doc' | 'acct' | null, objId: string | null) => void;
}

function PopupList({ state, setState, recording, sortedOpportunities, sortedAccounts, documents, updateData }: Props) {
  const listView: JSX.Element[] = [];

  const searchObjectsByName = async () => {
    setState(prevState => ({
      ...prevState,
      isLoading: true,
      searchItems: [],
    }));
    const items = await searchItems(state.searchPhrase, true, true, true, true);
    setState(prevState => ({
      ...prevState,
      isLoading: false,
      searchItems: items,
    }));
  };

  listView.push(
    <div key="search-bar" className="flex items-center border border-blue-100 rounded-lg p-1 pl-3">
      <MagnifyingGlassIcon className="w-4 h-4 text-gray-400" />
      <input
        type="text"
        className="text-sm flex-grow pl-2"
        placeholder="Search for an account/deal/event"
        value={state.searchPhrase}
        onChange={e => {
          setState(prevState => ({
            ...prevState,
            searchPhrase: e.target.value,
          }));
        }}
        onKeyDown={event => {
          if (event.key === 'Enter') {
            setState(prevState => ({ ...prevState, finalSearchPhrase: prevState.searchPhrase }));
            searchObjectsByName();
          }
        }}
        autoFocus
      />
      <button
        type="button"
        className={`${
          state.finalSearchPhrase.length > 0 ? 'text-blue-600 bg-blue-100 border-blue-400' : 'text-gray-400'
        } hover:bg-blue-200 flex items-center rounded px-1 py-0.5 border`}
        onClick={() => {
          setState(prevState => ({ ...prevState, finalSearchPhrase: prevState.searchPhrase }));
          searchObjectsByName();
        }}
        disabled={state.searchPhrase.length === 0 || state.isLoading}
      >
        {state.isLoading ? (
          <div>
            <SpinLoader className="animate-spin w-3 h-3 text-orange-500" />
          </div>
        ) : (
          <CornerArrowIcon className="w-4 h-4" />
        )}
      </button>
    </div>,
  );
  if (recording.entityOrDocumentId !== null) {
    listView.push(
      <div key="current" className="flex items-center text-xs text-gray-500 mt-2">
        <div className="flex justify-between items-center pr-3 px-2 hover:bg-slate-100 rounded-lg group w-full">
          <button
            className="flex w-full items-center text-gray-400 text-sm font-medium py-1"
            type="button"
            onClick={() => {
              updateData(null, null);
            }}
          >
            <LinkBrokenIcon className="w-5 h-5 text-orange-500 stroke-2" />
            Unlink From Account/Deal/Event
          </button>
        </div>
      </div>,
    );
  }
  // load suggested elements
  if (state.finalSearchPhrase === '') {
    listView.push(
      <div key="suggested" className="text-xs text-gray-500 p-2">
        Suggested
      </div>,
    );
    const suggestedOpps: JSX.Element[] = [];
    const suggestedAccts: JSX.Element[] = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < sortedOpportunities.length; i++) {
      if (i > 6) {
        break;
      }
      suggestedOpps.push(
        <OpportunityButton
          key={sortedOpportunities[i].id}
          opportunity={sortedOpportunities[i]}
          recording={recording}
          documents={documents}
          mode="default"
          updateData={updateData}
        />,
      );
    }
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < sortedAccounts.length; i++) {
      if (i > 3) {
        break;
      }
      suggestedAccts.push(
        <AccountButton
          key={sortedAccounts[i].id}
          account={sortedAccounts[i]}
          recording={recording}
          updateData={updateData}
        />,
      );
    }
    listView.push(
      <div key="suggested-body" className="">
        <div key="opp-button" className="flex flex-col gap-1">
          {suggestedOpps}
        </div>
        <div key="account-button" className="flex flex-col gap-1">
          {suggestedAccts}
        </div>
      </div>,
    );
  } else if (state.isLoading) {
    listView.push(
      <div className="w-full h-full flex flex-col items-center justify-center font-medium">
        <Loader className="w-20 h-20 mt-4" />
        Searching...
      </div>,
    );
  } else {
    state.searchItems.forEach(searchItem => {
      if (searchItem.type === 'account') {
        listView.push(
          <AccountButtonFromSearch
            key={`${searchItem.documentId} + ${searchItem.crmId}`}
            searchItem={searchItem}
            recording={recording}
            updateData={updateData}
          />,
        );
      }

      if (searchItem.type === 'opportunity') {
        listView.push(
          <OpportunityButtonFromSearch
            key={`${searchItem.documentId} + ${searchItem.crmId}`}
            searchItem={searchItem}
            recording={recording}
            updateData={updateData}
          />,
        );
      }

      if (searchItem.type === 'document') {
        listView.push(
          <DocumentButtonFromSearch
            key={searchItem.documentId}
            searchItem={searchItem}
            recording={recording}
            updateData={updateData}
          />,
        );
      }

      if (searchItem.type === 'event') {
        listView.push(
          <EventButtonFromSearch
            key={searchItem.documentId}
            searchItem={searchItem}
            recording={recording}
            updateData={updateData}
          />,
        );
      }
    });
  }

  return <div className="p-4">{listView}</div>;
}

export default PopupList;
