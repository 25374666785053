import { AdminUser } from 'admin-src/stores/adminUser';
import httpClient from 'api/http-client';

export interface Response {
  adminUser: AdminUser;
}

export default async (code: string): Promise<Response> => {
  const { data } = await httpClient.post<Response>('/admin/login-oauth', { code });
  return data;
};
