import { State } from 'stores/document';
import { EntityDocument } from 'types/entity';
import { SearchNormalized } from './search-objects';

export default async function getOrCreateNewSFEntity(selectedEntityDocument: SearchNormalized, entityStore: State) {
  let destinationEntity: EntityDocument | undefined;
  if (selectedEntityDocument.id !== null) {
    destinationEntity = entityStore.entityDocuments.find(ed => ed.id === selectedEntityDocument.id);
  } else if (selectedEntityDocument?.sfId && selectedEntityDocument?.sfEntityType) {
    const existingEntity = entityStore.entityDocuments.find(ed => ed.crm?.id === selectedEntityDocument.sfId);
    if (existingEntity) {
      return existingEntity;
    }
    destinationEntity = await entityStore.getOrCreateNewSFEntity(
      selectedEntityDocument.sfId,
      selectedEntityDocument.sfEntityType,
    );
  }
  return destinationEntity;
}
