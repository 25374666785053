import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import integrationOauth from 'api/integration-oauth';

import Loader from 'components/Loader';
import typoStyles from 'components/typo.module.css';

import useUserStore from 'stores/user';

import { redirect } from 'utils/redirect-after-login';
import { concat } from 'utils/styling';
import { User } from 'types/user';
import registerOauth from 'api/register-oauth';
import loginOauth from '../api/login-oauth';

import styles from './MicrosoftOauth.module.css';

interface State {
  isLoading: boolean;
}

function MicrosoftOauth() {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const stateMS = searchParams.get('state');

  const [state, setState] = useState<State>({
    isLoading: true,
  });

  const navigate = useNavigate();

  const userStore = useUserStore();

  useEffect(() => {
    const oAuth = async (codeP: string): Promise<User> => {
      if (stateMS === 'onboarding') {
        const { user } = await integrationOauth('calendar', 'microsoft', codeP, null);
        return user;
      }
      if (stateMS === 'login') {
        const { user } = await loginOauth('microsoft', codeP);
        return user;
      }

      const token = stateMS === 'register' ? null : stateMS;
      const { user } = await registerOauth('microsoft', codeP, token);
      return user;
    };
    const authorize = async (codeP: string): Promise<void> => {
      try {
        const user = await oAuth(codeP);
        userStore.setState({ user });
        redirect(navigate, user);
      } catch {
        /* empty */
      }

      setState({ isLoading: false });
    };

    if (code) {
      authorize(code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  return (
    <div className={styles.layout}>
      <div className={styles.container}>
        <span className={concat(typoStyles.xl, typoStyles['semi-bold'])}>Microsoft Authorization</span>
        {state.isLoading ? <Loader /> : null}
      </div>
    </div>
  );
}

export default MicrosoftOauth;
