/* eslint-disable no-nested-ternary, no-plusplus, consistent-return, no-continue */
/* eslint-disable @typescript-eslint/no-explicit-any, no-lonely-if, no-param-reassign */

import React from 'react';
import moment from 'moment';
import lodash, { cloneDeep, groupBy, isArray, isEqual, isNil, values } from 'lodash';
import {
  BooleanFilterType,
  BooleanFilterValue,
  ColumnConfig,
  ColumnDataType,
  ColumnFilter,
  DateFilterType,
  GroupColumnDefinition,
  NumberFilterType,
  ReferenceFilterValue,
  RowData,
  TextFilterType,
} from './types';
import constants from '../../utils/constants';
import { currencyFormatter } from '../../utils/number-formatter';
import { ProductFieldAnswer } from '../../types/product-gap';
import { EntityRowData } from '../../types/reporting';
import typoStyles from 'components/typo.module.css';

export function isEmpty(value: any): boolean {
  return value === undefined || value === null || value === '';
}

function textFilter(filterType: TextFilterType, filterValue: string): (value: string | null | undefined) => boolean {
  switch (filterType) {
    case 'Is':
      return (value: string | null | undefined) => {
        return value === filterValue;
      };
    case 'Is not':
      return (value: string | null | undefined) => value !== filterValue;
    case 'Contains':
      return (value: string | null | undefined) => {
        return value ? value.toLowerCase().includes(filterValue.toLowerCase()) : false;
      };
    case 'Does not contain':
      return (value: string | null | undefined) => !value?.toLowerCase().includes(filterValue.toLowerCase());
    case 'Starts with':
      return (value: string | null | undefined) => !!value?.startsWith(filterValue);
    case 'Ends with':
      return (value: string | null | undefined) => !!value?.endsWith(filterValue);
    case 'Is empty':
      return (value: string | null | undefined) => isEmpty(value);
    case 'Is not empty':
      return (value: string | null | undefined) => !isEmpty(value);
    default:
      return () => true;
  }
}

function dateFilter(
  filterType: DateFilterType,
  filterValue: string | [string, string],
): (value: string | null | undefined) => boolean {
  switch (filterType) {
    case 'Is':
      return (value: string | null | undefined) => {
        return value
          ? moment(value).format(constants.DATE_FORMAT) === moment(filterValue).format(constants.DATE_FORMAT)
          : false;
      };
    case 'Is before':
      return (value: string | null | undefined) => {
        return value ? moment(value).isBefore(moment(filterValue)) : false;
      };
    case 'Is after':
      return (value: string | null | undefined) => {
        return value ? moment(value).isAfter(moment(filterValue)) : false;
      };
    case 'Is on or before':
      return (value: string | null | undefined) => {
        return value ? moment(value).isSameOrBefore(moment(filterValue)) : false;
      };
    case 'Is on or after':
      return (value: string | null | undefined) => {
        return value ? moment(value).isSameOrAfter(moment(filterValue)) : false;
      };
    case 'Is between':
      return (value: string | null | undefined) => {
        return value ? moment(value).isBetween(moment(filterValue[0]), moment(filterValue[1])) : false;
      };
    case 'Is empty':
      return (value: string | null | undefined) => isEmpty(value);
    case 'Is not empty':
      return (value: string | null | undefined) => !isEmpty(value);
    default:
      return () => true;
  }
}

function numberFilter(
  filterType: NumberFilterType,
  filterValue: string,
): (value: string | null | undefined) => boolean {
  switch (filterType) {
    case '=':
      return (value: string | null | undefined) => Number(value) === Number(filterValue);
    case '≠':
      return (value: string | null | undefined) => Number(value) !== Number(filterValue);
    case '>':
      return (value: string | null | undefined) => {
        return Number(value) > Number(filterValue);
      };
    case '<':
      return (value: string | null | undefined) => {
        return Number(value) < Number(filterValue);
      };
    case '≥':
      return (value: string | null | undefined) => {
        return Number(value) >= Number(filterValue);
      };
    case '≤':
      return (value: string | null | undefined) => {
        return Number(value) <= Number(filterValue);
      };
    case 'Is empty':
      return (value: string | null | undefined) => isEmpty(value);
    case 'Is not empty':
      return (value: string | null | undefined) => !isEmpty(value);
    default:
      return () => true;
  }
}

function booleanFilter(
  filterType: BooleanFilterType,
  filterValue: BooleanFilterValue,
): (value: boolean | null | undefined) => boolean {
  switch (filterType) {
    case 'Is':
      return (value: boolean | null | undefined) => {
        if (filterValue === 'True') return value === true;
        return value === false;
      };
    case 'Is empty':
      return (value: boolean | null | undefined) => isEmpty(value);
    case 'Is not empty':
      return (value: boolean | null | undefined) => !isEmpty(value);
    default:
      return () => true;
  }
}

export function calculateFooterDataFilter(type: ColumnDataType, rows: any[], data?: any): any[] {
  if (type === 'ReportFieldAnswer' || type === 'ReportEntityField' || type === 'ReportField') {
    const { entityName } = data;
    if (entityName === 'Account') {
      return lodash.uniqBy(rows, 'AccountDocumentId');
    }
    if (entityName === 'Opportunity') {
      return rows.filter(r => entityName in r);
    }
  }
  return rows;
}

export function filterFunction(columnDataType: ColumnDataType, filter: ColumnFilter): (value: any) => boolean {
  if (columnDataType === 'Text' || columnDataType === 'ProductFeedback' || columnDataType === 'ProductCategory')
    return textFilter(filter.type as TextFilterType, filter.value as string);
  if (columnDataType === 'Date') return dateFilter(filter.type as DateFilterType, filter.value as string);
  if (columnDataType === 'Number' || columnDataType === 'Currency')
    return numberFilter(filter.type as NumberFilterType, filter.value as string);
  if (columnDataType === 'Boolean')
    return booleanFilter(filter.type as BooleanFilterType, filter.value as BooleanFilterValue);

  return () => true;
}

export function getValue(type: ColumnDataType, cellValue: any): any {
  if (type === 'ProductCategory') {
    return (cellValue as ProductFieldAnswer)?.title || '';
  }
  if (type === 'ReportField') {
    return cellValue ? (cellValue as RowData).value : '';
  }
  if (type === 'ReportEntityField') {
    return cellValue ? (cellValue as EntityRowData).value : '';
  }

  return cellValue;
}

export function sortData(id: string, type: ColumnDataType, direction: 'ASC' | 'DESC', groupId: string | null) {
  return (a: RowData, b: RowData) => {
    let aValue: number | string = getValue(type, a[id]);
    let bValue: number | string = getValue(type, b[id]);
    if (typeof aValue === 'object' && !isNil(aValue)) {
      aValue = JSON.stringify(aValue);
    }
    if (typeof bValue === 'object' && !isNil(bValue)) {
      bValue = JSON.stringify(bValue);
    }
    if (groupId) {
      const groupA = a[groupId] || {};
      const groupB = b[groupId] || {};
      aValue = getValue(type, groupA[id]);
      bValue = getValue(type, groupB[id]);
    }

    if ((aValue === null || aValue === undefined) && (bValue === null || bValue === undefined)) return 0;
    if (aValue === null || aValue === undefined) return 1;
    if (bValue === null || bValue === undefined) return -1;

    if (type === 'Date') {
      aValue = new Date(aValue).getTime();
      bValue = new Date(bValue).getTime();
    }
    if (type === 'Number' || type === 'Currency') {
      aValue = Number(aValue);
      bValue = Number(bValue);
    }

    if (type === 'Text' || type === 'ProductCategory' || type === 'ProductFeedback') {
      if (!aValue) aValue = '';
      if (!bValue) bValue = '';
      return direction === 'ASC'
        ? (aValue as string).localeCompare(bValue as string)
        : (bValue as string).localeCompare(aValue as string);
    }
    return direction === 'ASC' ? (aValue as number) - (bValue as number) : (bValue as number) - (aValue as number);
  };
}

export function convertValueToDisplayValue(value: any, type: ColumnDataType) {
  if (isEmpty(value)) return value;
  let displayValue = value;
  if (type === 'Date') {
    if (value) displayValue = moment(value).format(constants.DATE_FORMAT);
  } else if (type === 'Currency' && value !== null && value !== undefined) {
    displayValue = currencyFormatter.format(value);
  } else if (type === 'Boolean') {
    displayValue = value ? 'True' : 'False';
  }
  return displayValue;
}

export function getTableSequenceCellWidth(sequence: number): number {
  const padding = 8;
  const widthEachChar = 10;
  return padding * 2 + `${sequence}`.length * widthEachChar;
}

export function stringifyFilter(filter: ColumnFilter | undefined, colType: string | undefined): React.ReactNode {
  if (!filter) {
    return null;
  }

  let value = filter.value as any;
  if (colType === 'reference') {
    value = (filter.value as ReferenceFilterValue[]).map(v => v.label).join(', ');
  } else if (colType?.toLowerCase() === 'date') {
    if (isArray(filter.value)) {
      value = (filter.value as string[]).map(v => moment(v).format(constants.DATE_FORMAT)).join(', ');
    } else if (filter.value) {
      value = moment(filter.value as string).format(constants.DATE_FORMAT);
    }
  } else if (isArray(filter.value)) {
    value = (filter.value as string[]).join(', ');
  }

  return (
    <span>
      <strong className={typoStyles['semi-bold']}>{filter.type}</strong>&nbsp;
      <span className="font-normal">{value}</span>
    </span>
  );
}

export function getTableColUniqueId(col: ColumnConfig): string {
  if ((col as GroupColumnDefinition).columns?.length) {
    return `${col.id}:${((col as GroupColumnDefinition).columns || []).map(item => item.id).join('_')}`;
  }
  return col.id;
}

export function swapTableColPostion(
  cols: ColumnConfig[],
  fromColIndex: number,
  fromSubColIndex: number | undefined,
  toColIndex: number,
  toSubColIndex: number | undefined,
  setPinnedSameAsToCol = true,
) {
  const isPinned = setPinnedSameAsToCol ? { isPinned: !!cols[toColIndex]?.isPinned } : {};
  // swap position of from column and to column
  if (fromColIndex >= 0 && toColIndex >= 0) {
    const dragLeft =
      fromColIndex > toColIndex || (fromColIndex === toColIndex && (fromSubColIndex || 0) > (toSubColIndex || 0));
    const newCols = cloneDeep(cols);
    if (fromSubColIndex !== undefined && fromSubColIndex >= 0) {
      const fromSubCol = (newCols[fromColIndex] as GroupColumnDefinition).columns.splice(fromSubColIndex, 1);
      if (toSubColIndex !== undefined && toSubColIndex >= 0) {
        const newToSubColIndex = toSubColIndex + (fromColIndex === toColIndex ? (dragLeft ? 0 : -1) : 0);
        const toSubCols = (newCols[toColIndex] as GroupColumnDefinition).columns.splice(
          newToSubColIndex + (dragLeft ? 0 : 1),
        );
        const fromCol = {
          ...(newCols[fromColIndex] as GroupColumnDefinition),
          columns: fromSubCol.map(subItem => ({ ...subItem, ...isPinned })),
          ...isPinned,
        };
        const toCols = {
          ...(newCols[toColIndex] as GroupColumnDefinition),
          columns: toSubCols,
        };
        if (fromColIndex === toColIndex) {
          if (dragLeft) {
            newCols.splice(toColIndex + 1, 0, fromCol);
            newCols.splice(toColIndex + 2, 0, toCols);
          } else {
            newCols.splice(toColIndex + 1, 0, fromCol, toCols);
          }
        } else {
          newCols.splice(toColIndex + 1, 0, fromCol, toCols);
        }
      } else {
        newCols.splice(toColIndex + (dragLeft ? 0 : 1), 0, {
          ...(newCols[fromColIndex] as GroupColumnDefinition),
          columns: fromSubCol,
          ...isPinned,
        });
      }
    } else {
      if (toSubColIndex !== undefined && toSubColIndex >= 0) {
        const toSubCols = (newCols[toColIndex] as GroupColumnDefinition).columns.splice(
          0,
          toSubColIndex + (dragLeft ? 0 : 1),
        );
        newCols.splice(toColIndex, 0, {
          ...(newCols[toColIndex] as GroupColumnDefinition),
          columns: cloneDeep(toSubCols),
        });
        newCols[toColIndex] = cloneDeep(newCols[toColIndex]);
        const newFromColIndex = dragLeft ? fromColIndex + 1 : fromColIndex;
        const newToColIndex = dragLeft ? toColIndex + 1 : toColIndex;
        const fromCols = newCols.splice(newFromColIndex, 1);
        if (setPinnedSameAsToCol) {
          (fromCols[0] as GroupColumnDefinition).columns?.forEach(subCol => {
            subCol.isPinned = isPinned.isPinned;
          });
        }
        newCols.splice(newToColIndex, 0, {
          ...fromCols[0],
          ...isPinned,
        });
      } else {
        const fromCols = newCols.splice(fromColIndex, 1);
        if (setPinnedSameAsToCol) {
          (fromCols[0] as GroupColumnDefinition).columns?.forEach(subCol => {
            subCol.isPinned = isPinned.isPinned;
          });
        }
        newCols.splice(toColIndex, 0, {
          ...fromCols[0],
          ...isPinned,
        });
      }
    }
    // merge splitted group col
    const filteredNewCols = newCols.filter(
      col => !(col as GroupColumnDefinition).group || (col as GroupColumnDefinition).columns.length,
    );
    const retCols = filteredNewCols
      .map((col, index) => {
        const groupCol = col as GroupColumnDefinition;
        const subsequenceCols = filteredNewCols.slice(index + 1);
        for (let subsequenceIndex = 0; subsequenceIndex < subsequenceCols.length; subsequenceIndex++) {
          const subsequenceCol = subsequenceCols[subsequenceIndex] as GroupColumnDefinition;
          if (
            groupCol.group &&
            groupCol.group === subsequenceCol?.group &&
            groupCol.id === subsequenceCol?.id &&
            !!groupCol.isPinned === !!subsequenceCol?.isPinned
          ) {
            groupCol.columns = [...groupCol.columns, ...subsequenceCol.columns];
            subsequenceCol.columns = [];
          } else if (subsequenceCol.columns && subsequenceCol.columns.every(subCol => subCol.isHidden)) {
            continue;
          } else {
            break;
          }
        }
        return col;
      })
      .filter(
        col => !!col && (!(col as GroupColumnDefinition).group || (col as GroupColumnDefinition).columns.length),
      ) as ColumnConfig[];
    return retCols;
  }
}

export function findTableColIndex(
  columnConfigs: ColumnConfig[],
  colUniqueId: string,
  groupId: string,
): [number, number] {
  const colIndex = columnConfigs.findIndex(col => !groupId && getTableColUniqueId(col) === colUniqueId);
  if (colIndex >= 0) {
    return [colIndex, -1];
  }
  let groupIndex = -1;
  let subColIndex = -1;
  for (let index = 0; index < columnConfigs.length; index++) {
    const col = columnConfigs[index];
    subColIndex =
      (col as GroupColumnDefinition).columns?.findIndex(
        item => (!groupId || col.id === groupId) && getTableColUniqueId(item) === colUniqueId,
      ) ?? -1;
    if (subColIndex >= 0) {
      groupIndex = index;
      break;
    }
  }
  return [groupIndex, subColIndex];
}

export function addGroupSequnceNumToColumnDefs(columnConfigs: ColumnConfig[]) {
  const cols = cloneDeep(columnConfigs);
  values(
    groupBy(
      cols.filter(col => 'group' in col && !col.columns.every(subCol => subCol.isHidden)),
      'group',
    ),
  ).forEach(groupCols => {
    groupCols.forEach((col, index) => {
      (col as GroupColumnDefinition).groupSequence = groupCols.length > 1 ? index : undefined;
    });
  });
  return isEqual(cols, columnConfigs) ? columnConfigs : cols;
}

export function filterTableRows(rows: RowData[], columns: ColumnConfig[]): RowData[] {
  return rows.filter(row => {
    const isFilters: boolean[] = [];
    columns.forEach(columnConfig => {
      if ('group' in columnConfig) {
        const groupData = row[columnConfig.id] || {};
        columnConfig.columns.forEach(column => {
          const value = getValue(column.type, groupData[column.id]);

          if (column.filter) {
            isFilters.push(filterFunction(column.type, column.filter)(value));
          }
        });
      } else {
        const value = getValue(columnConfig.type, row[columnConfig.id]);

        if (columnConfig.filter) {
          isFilters.push(filterFunction(columnConfig.type, columnConfig.filter)(value));
        }
      }
    });
    return isFilters.length > 0 ? isFilters.every(isFilter => isFilter) : true;
  });
}

export function isProcessingColumn(column: ColumnConfig): boolean {
  return (
    column?.data.key === 'createLoadingEntityFieldColumnDefinition' ||
    column?.data.key === 'createFieldGroupLoadingColumnDefinition'
  );
}
