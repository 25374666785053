import { FieldValue } from 'types/field-value';
import httpClient from '../http-client';

export default async (
  documentId: string,
  triggerAI: boolean,
  filterByTeamMemberRequest: boolean,
): Promise<FieldValue[]> => {
  const { data } = await httpClient.get<FieldValue[]>(
    `/field-values?documentId=${documentId}&triggerAI=${triggerAI}&filterByTeamMemberRequest=${filterByTeamMemberRequest}`,
  );
  return data;
};
