import React, { useMemo } from 'react';
import { ArrowUpRightIcon } from '@heroicons/react/24/outline';
import { Document } from 'types/document';
import useUserStore from '../../stores/user';
import FolderSelect from '../../components/FolderSelect';
import styles from './DocumentHeader.module.css';
import CalendarInformation from './DocumentView/CalendarInformation';
import BaseButton from 'components/BaseButton';
import { concat } from 'utils/styling';
import DocumentTitle from './DocumentView/DocumentTitle';
import EventActivitySelect from './DocumentView/EventActivitySelect';
import MeetingAttendees from './DocumentView/MeetingAttendees';
import ProdFeedbackSelect from './DocumentView/ProductFeedback';
import RecordingInformation from './DocumentView/RecordingInformation';
import SummarySelect from './DocumentView/SummarySelect';
import DocumentMoreActionBtn from './DocumentView/DocumentMoreActionBtn';

interface Props {
  className?: string;
  titleClassName?: string;
  document: Document;
  setLoading: (isLoading: boolean) => void;
  onUpdatedDocFolderByApi?: () => void;
  onTitleEnterPress?: () => void;
  onTitleUpdate?: (newTitle: string) => void;
  docAccordionMode?: boolean;
  onApplyTemplate?: () => void;
  onSaveAsTemplate?: () => void;
}

function DocumentHeader({
  className,
  titleClassName,
  document,
  setLoading,
  onUpdatedDocFolderByApi,
  onTitleEnterPress,
  onTitleUpdate,
  docAccordionMode,
  onApplyTemplate,
  onSaveAsTemplate,
}: Props) {
  const userStore = useUserStore();
  const meetingAttendees = useMemo(() => {
    const attendees = (document.event?.attendees ?? []).map(attendee => {
      return {
        name: attendee.name,
        avatar: attendee.profilePictureUrl,
        email: attendee.email,
      };
    });
    (document.calls ?? []).forEach(call => {
      call.callData.parties.forEach(part => {
        if (attendees.some(attendee => attendee.email === part.email)) {
          return;
        }
        attendees.push({
          name: part.name,
          avatar: '',
          email: part.email,
        });
      });
    });
    return attendees;
  }, [document.event, document.calls]);

  return (
    <div
      className={concat(
        className,
        'doc-header-container',
        styles.docHeaderContainer,
        docAccordionMode && styles.docAccordionMode,
      )}
    >
      <div className={styles.titleSection}>
        <DocumentTitle
          initTitle={document?.docTitle ?? ''}
          disabled={!!document?.event || docAccordionMode}
          placeholder="Untitled"
          className={titleClassName}
          onEnter={onTitleEnterPress}
          onUpdate={onTitleUpdate}
        />
        {docAccordionMode && <CalendarInformation event={document.event} className={styles.topSummaryItem} />}
      </div>
      <div className={styles.topSummary}>
        <FolderSelect
          hideLabel
          className={concat(styles.topSummaryItem, styles.folder)}
          documentId={document.id}
          entityDocumentId={document.parent ? document.parent.id : null}
          setLoading={setLoading}
          onUpdatedDocFolderByApi={onUpdatedDocFolderByApi}
        />
        <MeetingAttendees
          maxAttendee={docAccordionMode ? 2 : 3}
          meetingAttendees={meetingAttendees}
          className={styles.topSummaryItem}
        />
        {!docAccordionMode && (
          <>
            <CalendarInformation event={document.event} className={styles.topSummaryItem} />
            {!!document.event?.videoConferenceLink && (
              <BaseButton
                color="secondary"
                variant="outlined"
                tooltipPlacement="bottom"
                className="!w-8 !px-1 !-ml-3"
                role="link"
                onClick={e => {
                  e.stopPropagation();
                  window.open(document.event?.videoConferenceLink, '_blank', 'noreferrer');
                }}
                tooltip="Link to Video Conference"
              >
                <ArrowUpRightIcon className="w-4 h-4 text-blue-500" />
              </BaseButton>
            )}
          </>
        )}
        <RecordingInformation calls={document.calls} className={styles.topSummaryItem} />
        {userStore.user?.isIntegratedWithCrm && document.event !== null && document.parent !== null && (
          <EventActivitySelect className={concat(styles.topSummaryItem, styles.event)} documentId={document.id} />
        )}
        {docAccordionMode && (
          <DocumentMoreActionBtn
            document={document}
            showDelete={false}
            onApplyTemplate={onApplyTemplate}
            onSaveAsTemplate={onSaveAsTemplate}
          />
        )}
      </div>
      {!docAccordionMode && <SummarySelect documentId={document.id} />}
      {!docAccordionMode && !userStore.user?.needsToSetupProduct && <ProdFeedbackSelect documentId={document.id} />}
    </div>
  );
}

DocumentHeader.defaultProps = {
  className: '',
  titleClassName: '',
  onUpdatedDocFolderByApi: undefined,
  onTitleEnterPress: undefined,
  onTitleUpdate: undefined,
  docAccordionMode: undefined,
  onApplyTemplate: undefined,
  onSaveAsTemplate: undefined,
};

export default DocumentHeader;
