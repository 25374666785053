import httpClient from '../http-client';
import { FieldSubHeader } from '../../types/reporting';

interface Response {
  account: FieldSubHeader[];
  opportunity: FieldSubHeader[];
}

export default async (): Promise<Response> => {
  const { data } = await httpClient.get<Response>('/fields/filter');
  return data;
};
