import React, { useEffect, useState } from 'react';
import { DocumentDuplicateIcon, ArrowTopRightOnSquareIcon, CheckIcon } from '@heroicons/react/24/outline';

import Popover from 'components/Popover';

import { LinkElement as LinkElementType } from '../../types';

interface Props {
  linkBarOpen: boolean;
  refElement: React.RefObject<HTMLDivElement>;
  element: LinkElementType;
  setOpenLinkBar: (arg: boolean) => void;
  setOpenLinForm: (arg: boolean) => void;
  readOnly?: boolean;
}

function LinkBar({ linkBarOpen, refElement, element, setOpenLinkBar, setOpenLinForm, readOnly }: Props) {
  const [copyConfirm, setCopyConfirm] = useState<boolean>(false);
  // when copyConfirm changed to true, set it to false after 1 second
  useEffect(() => {
    if (copyConfirm) {
      setTimeout(() => {
        setCopyConfirm(false);
      }, 1500);
    }
  }, [copyConfirm]);

  return (
    <Popover
      isOpen={linkBarOpen}
      onClose={() => {
        setOpenLinkBar(false);
      }}
      anchorReference="anchorEl"
      anchorEl={refElement.current}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      disableRestoreFocus
    >
      <div className="px-2 bg-gray-100 flex gap-3 items-center h-8">
        <span className="text-gray-500 text-sm p-1">{element.link}</span>
        <button
          type="button"
          className="flex text-gray-400 hover:text-gray-500"
          onClick={() => {
            navigator.clipboard.writeText(element.link);
            setCopyConfirm(true);
          }}
        >
          {copyConfirm ? (
            <CheckIcon className="w-4 h-4 text-green-500" />
          ) : (
            <DocumentDuplicateIcon className="w-4 h-4" />
          )}
        </button>

        <button
          type="button"
          className="flex text-gray-400 hover:text-gray-500"
          onClick={() => {
            let url = element.link;
            if (!element.link.match(/^https?:\/\//i)) {
              url = `http://${url}`;
            }
            window.open(url, '_blank', 'noopener noreferrer');
          }}
        >
          <ArrowTopRightOnSquareIcon style={{ width: '1rem', height: '1rem' }} />
        </button>

        {!readOnly && (
          <button
            type="button"
            className="text-gray-400 hover:text-gray-500 text-sm"
            onClick={() => {
              setOpenLinkBar(false);
              setOpenLinForm(true);
            }}
          >
            edit
          </button>
        )}
      </div>
    </Popover>
  );
}

LinkBar.defaultProps = {
  readOnly: false,
};

export default LinkBar;
