import React from 'react';

interface Props {
  className?: string;
}

function DollarIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 14.4615C8 15.6511 9.04467 16.6154 10.3333 16.6154H12.5C13.8807 16.6154 15 15.5822 15 14.3077C15 13.0332 13.8807 12 12.5 12H10.5C9.11929 12 8 10.9668 8 9.69231C8 8.4178 9.11929 7.38462 10.5 7.38462H12.6667C13.9553 7.38462 15 8.34893 15 9.53846M11.5 6V7.38462M11.5 16.6154V18"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

DollarIcon.defaultProps = {
  className: undefined,
};

export default DollarIcon;
