import { sortBy } from 'lodash';
import moment from 'moment';
import { CrmNote, Document } from 'types/document';

const getDocSortValue = (doc: Document | CrmNote): number => {
  const { isFavourite } = doc as Document;
  const createdAt = (doc as Document).event?.startsAt || (doc as Document).createdAt || (doc as CrmNote).createdDate;
  let isMeeting = null;
  if ('linkedEntity' in doc) {
    isMeeting = false;
  } else {
    isMeeting = (doc as Document).event || (doc as Document).calls.length;
  }
  const isUplaodedFile = !!(doc as Document).uploadedFile;

  // sort by time, and put meeting at bottom, put favourite at top
  const createdAtNum = createdAt
    ? (isUplaodedFile ? -2e9 : 0) + (isMeeting ? -1e9 : 0) + (isFavourite ? 1e10 : 0) + moment(createdAt).unix()
    : 0;
  return -createdAtNum;
};

const orderDocuments = (documents: Document[], crmNotes: CrmNote[]): (Document | CrmNote)[] => {
  return sortBy([...documents, ...crmNotes], getDocSortValue);
};

export default orderDocuments;
