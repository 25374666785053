/* eslint-disable react/jsx-props-no-spreading */

import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';

import CalendarIcon from 'components/icons/CalendarIcon';
import SpinLoader from 'components/icons/SpinLoader';
import constants from 'utils/constants';

import styles from './DateField.module.css';

interface Props {
  isEditable: boolean;
  isUpdating: boolean;
  value: string;
  onChange: (value: string) => void;
}

function TimeField({ isEditable, isUpdating, value, onChange }: Props) {
  const initValue = value ? moment(value, 'HH:mm:ss').format('HH:mm:ss') : '';
  const [time, setTime] = useState<string>(initValue);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (time !== initValue) {
      setTime(initValue);
    }
  }, [initValue]);

  useEffect(() => {
    if (!isOpen && initValue !== time) {
      onChange(`${time}Z+00:00`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <div className="w-full">
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <TimePicker
          className={styles.date}
          open={isOpen}
          onClose={() => setIsOpen(false)}
          inputFormat={constants.TIME_FORMAT}
          disableMaskedInput
          value={time}
          onChange={(newValue: Moment | null) => {
            if (newValue !== null) {
              setTime(newValue.format('HH:mm:ss'));
            } else {
              setTime('');
            }
          }}
          renderInput={params => {
            return (
              <button
                type="button"
                className="bg-gray-100 px-2 py-1 flex items-center text-gray-500 text-sm"
                onClick={() => setIsOpen(true)}
                disabled={!isEditable}
              >
                <div ref={params.inputRef}>
                  {time !== '' ? moment(time, 'HH:mm:ss').format(constants.TIME_FORMAT) : 'Select a time'}
                </div>
                <CalendarIcon className="ml-1" />
              </button>
            );
          }}
        />
      </LocalizationProvider>
      <div className="flex items-center text-xs gap-1">
        {isUpdating && (
          <>
            <SpinLoader className="w-5 h-5 p-1 animate-spin" />
            <span>Updating...</span>
          </>
        )}
      </div>
    </div>
  );
}

export default TimeField;
