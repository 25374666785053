/* eslint-disable class-methods-use-this */
/* eslint-disable max-classes-per-file */

import {
  BlockType,
  CustomElement,
  DiscoveryQuestionElement,
  ImageElement as ImageElementType,
  ProductGapElement,
  TableDialogElement as TableDialogElementType,
  AIWorkerElement as AIWorkerElementType,
  TaskElement as TaskElementType,
} from 'components/NoteEditor/types';
import { createNode } from 'components/NoteEditor/utils';
import useUserStore from 'stores/user';
import { v4 as uuidv4 } from 'uuid';

export interface SlashCommandMenu {
  blockType: BlockType;
  text: string;
  description: string;
  imageUrl: string;
  node: CustomElement;

  onClick: (addNode: () => void) => Promise<void>;
}

const defaultOnClick = async (addNode: () => void) => {
  addNode();
};

class DefaultCommandMenu implements SlashCommandMenu {
  blockType = BlockType.Paragraph;

  text = 'Text';

  description = 'Start writing with plain text.';

  imageUrl = '/images/notes/paragraph.svg';

  node = {
    type: BlockType.Paragraph,
    children: [
      {
        text: '',
      },
    ],
    indentLevel: 0,
  } as CustomElement;

  onClick = defaultOnClick;
}

class Paragraph extends DefaultCommandMenu {}

class Heading1 extends DefaultCommandMenu {
  blockType = BlockType.H1;

  text = 'Heading 1';

  description = 'Big section heading.';

  imageUrl = '/images/notes/heading-1.svg';

  node = {
    type: BlockType.H1,
    children: [
      {
        text: '',
      },
    ],
  };
}

class Heading2 extends DefaultCommandMenu {
  blockType = BlockType.H2;

  text = 'Heading 2';

  description = 'Medium section heading.';

  imageUrl = '/images/notes/heading-2.svg';

  node = {
    type: BlockType.H2,
    children: [
      {
        text: '',
      },
    ],
  };
}

class BulletedList extends DefaultCommandMenu {
  blockType = BlockType.BulletedList;

  text = 'Bulleted List';

  imageUrl = '/images/notes/bulleted-list.svg';

  description = 'Create a simple bulleted list.';

  node = {
    type: BlockType.BulletedList,
    children: [
      {
        text: '',
      },
    ],
    indentLevel: 0,
  };

  onClick = defaultOnClick;
}

class OrderedList extends DefaultCommandMenu {
  blockType = BlockType.OrderedList;

  text = 'Numbered List';

  imageUrl = '/images/notes/ordered-list.svg';

  description = 'Create a list with numbering.';

  node = {
    type: BlockType.OrderedList,
    children: [
      {
        text: '',
      },
    ],
    indentLevel: 0,
  };
}

class DiscoveryQuestion extends DefaultCommandMenu {
  blockType = BlockType.DiscoveryQuestion;

  text = 'Discovery Question';

  description = 'Add a question from the master list.';

  imageUrl = '/images/notes/discovery-question.svg';

  node: DiscoveryQuestionElement;

  constructor() {
    super();
    this.node = {
      type: BlockType.DiscoveryQuestion,
      discoveryQuestionId: '',
      children: [],
    };
  }
}

class ProductGap extends DefaultCommandMenu {
  blockType = BlockType.ProductGap;

  text = 'Product Gap';

  description = 'Insert a form to report gaps in the product.';

  imageUrl = '/images/notes/product-gap.svg';

  node: ProductGapElement;

  constructor() {
    super();
    this.node = {
      type: BlockType.ProductGap,
      productGapId: null,
      children: [],
    };
  }
}

class ImageElement extends DefaultCommandMenu {
  blockType = BlockType.Image;

  text = 'Image';

  imageUrl = '/images/notes/image.svg';

  description = 'Upload or embed an image.';

  node: ImageElementType;

  constructor() {
    super();
    this.node = {
      fileId: uuidv4(),
      isInput: true,
      type: BlockType.Image,
      children: [{ text: '' }],
    };
  }
}

class TableElement extends DefaultCommandMenu {
  blockType = BlockType.Table;

  text = 'Table';

  imageUrl = '/images/notes/table.svg';

  description = 'Add simple tabular content.';

  node = {
    type: BlockType.TableDialog,
    tableDialogId: uuidv4(),
    children: [],
  } as TableDialogElementType;

  onClick = defaultOnClick;
}

class TaskElement extends DefaultCommandMenu {
  blockType = BlockType.Task;

  text = 'Task';

  imageUrl = '/images/notes/todo.svg';

  description = 'Track to do tasks.';

  node = {
    type: BlockType.Task,
    children: [{ text: '' }],
    initContent: [createNode(BlockType.Paragraph)],
  } as TaskElementType;

  onClick = async (addNode: () => void) => {
    this.node = { ...this.node, beingCreatedBy: useUserStore.getState().user?.id || '' };
    addNode();
  };
}

class AIWorkerElement extends DefaultCommandMenu {
  blockType = BlockType.AIWorker;

  text = 'AI Documentation';

  imageUrl = '/images/notes/ai-documentation.svg';

  description = 'Ask AI to document for you.';

  node = {
    type: BlockType.AIWorker,
    children: [{ text: '' }],
    initContent: [createNode(BlockType.Paragraph)],
  } as AIWorkerElementType;

  onClick = async (addNode: () => void) => {
    // beingCreatedBy is readonly, have to regenerate the node
    this.node = { ...this.node, beingCreatedBy: useUserStore.getState().user?.id || '' };
    addNode();
  };
}

export default {
  Paragraph,
  Heading1,
  Heading2,
  BulletedList,
  OrderedList,
  DiscoveryQuestion,
  ProductGap,
  ImageElement,
  TableElement,
  TaskElement,
  AIWorkerElement,
};
