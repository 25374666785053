/* eslint-disable import/no-cycle */
import React, { useMemo } from 'react';
import { Editor } from 'slate';
import { CustomText } from 'components/NoteEditor/types';
import { concat } from 'utils/styling';
import styles from './ToolbarButton.module.css';

function isFormatActive(editor: Editor, format: keyof Omit<CustomText, 'text'>) {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
}

function toggleFormat(editor: Editor, format: keyof Omit<CustomText, 'text'>) {
  const isActive = isFormatActive(editor, format);
  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
}

interface Props {
  format: keyof Omit<CustomText, 'text'>;
  isActive: boolean | undefined;
  children: JSX.Element;
  editor: Editor;
  onToggleFormat?: (format: keyof Omit<CustomText, 'text'>, on: boolean) => void;
  borderLeft?: boolean;
}

function ToolbarButton({ format, isActive, children, editor, onToggleFormat, borderLeft }: Props) {
  return (
    <button
      type="button"
      className={concat(styles.button, isActive && styles['button-active'], borderLeft && styles['border-left'])}
      onMouseDown={event => {
        event.preventDefault();
        toggleFormat(editor, format);
        onToggleFormat?.(format, !isActive);
      }}
    >
      {children}
    </button>
  );
}

ToolbarButton.defaultProps = {
  onToggleFormat: undefined,
  borderLeft: false,
};

export default ToolbarButton;
