/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useRef, useState } from 'react';

import useModalStore from 'stores/modal';
import { Member, MemberStatus } from 'types/member';
import TransferArrowIcon from 'components/icons/TransferArrow';
import DropdownIcon from 'components/icons/DropdownIcon';
import useUserStore from 'stores/user';
import Popover from 'components/Popover';
import TrashIcon from 'components/icons/TrashIcon';
import SpinLoader from 'components/icons/SpinLoader';

interface Props {
  member: Member;
  onDelete: (email: string | null) => Promise<void>;
}

const getDisplayName = (member: Member) => {
  let displayName = member.email;
  if (member.firstName && member.lastName) {
    displayName = `${member.firstName} ${member.lastName}`;
  }
  return displayName;
};

function DeleteMemberModal({ member, onDelete }: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [confirmedAction, setConfirmedAction] = useState<boolean>(false);
  const [transferUser, setTransferUser] = useState<Member | null>(null);
  const [showTransferDropdown, setShowTransferDropdown] = useState<boolean>(false);
  const transferButtonRef = useRef<HTMLButtonElement>(null);
  const modalStore = useModalStore();
  const userStore = useUserStore();
  const elgibleTransferUsers = userStore.members.filter(
    m => m.email !== member.email && m.status === MemberStatus.ACTIVE,
  );
  useEffect(() => {
    if (elgibleTransferUsers && elgibleTransferUsers.length >= 1) {
      setTransferUser(elgibleTransferUsers[0]);
    }
  }, [elgibleTransferUsers]);
  return (
    <div className="w-[32rem] p-5">
      <div className="flex items-center justify-between">
        <div className="w-14 h-14 bg-red-200 rounded-full flex justify-center items-center">
          <TrashIcon className="w-8 h-8 text-red-500" />
        </div>
        <button
          type="button"
          className="w-7 h-7 p-1 rounded-lg text-gray-400 hover:bg-gray-200"
          onClick={modalStore.close}
        >
          <XMarkIcon />
        </button>
      </div>
      {!confirmedAction && (
        <div>
          <div className="text-lg font-medium mt-5">Are you sure you want to delete this user?</div>
          <div className="text-sm text-gray-400 mt-3">
            This will remove this user from your team and reassign all of their private docs. This action cannot be
            undone.
          </div>
          <div className="flex justify-end items-center gap-2 mt-5">
            <button
              type="button"
              className="px-4 py-2 bg-gray-100 hover:bg-gray-200 text-gray-500 rounded"
              onClick={modalStore.close}
            >
              Cancel
            </button>
            <button
              type="button"
              className="flex items-center gap-2 px-4 py-2 bg-red-500 hover:bg-red-400 text-white rounded"
              onClick={() => setConfirmedAction(true)}
            >
              Continue
            </button>
          </div>
        </div>
      )}
      {confirmedAction && (
        <div>
          <div className="text-lg font-medium mt-5">
            To delete this user, select another user to transfer their private docs to
          </div>
          <div className="flex flex-col">
            <div className="flex flex-row items-center justify-center py-2">
              <button
                disabled
                type="button"
                className="inline-flex capitalize w-full justify-center gap-x-1.5 rounded-md bg-white px-4 py-3 text-sm font-regular text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 w-44 justify-between disabled:cursor-not-allowed disabled:opacity-50"
                id="role-menu-button"
                aria-expanded="true"
                aria-haspopup="true"
              >
                <div className="flex w-full justify-between">
                  <span>{member && getDisplayName(member)}</span>
                </div>
              </button>
              <TransferArrowIcon />
              <button
                ref={transferButtonRef}
                type="button"
                className="inline-flex capitalize w-full justify-center gap-x-1.5 rounded-md bg-white px-4 py-3 text-sm font-regular text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 w-44 justify-between disabled:cursor-not-allowed disabled:opacity-50"
                onClick={() => setShowTransferDropdown(!showTransferDropdown)}
              >
                <div className="flex w-full justify-between">
                  <span>{transferUser && getDisplayName(transferUser)}</span>
                  <DropdownIcon className="-mr-1 h-5 w-5 text-gray-400" />
                </div>
              </button>
              <Popover
                anchorEl={transferButtonRef.current}
                onClose={() => setShowTransferDropdown(false)}
                isOpen={showTransferDropdown}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              >
                <div className="w-44">
                  <div className="py-1" role="none">
                    {elgibleTransferUsers?.map((user, index) => {
                      return (
                        <button
                          key={index}
                          type="submit"
                          className="block w-full px-4 py-2 text-left text-sm hover:bg-gray-50"
                          onClick={() => {
                            setTransferUser(user);
                            setShowTransferDropdown(false);
                          }}
                        >
                          {getDisplayName(user)}
                        </button>
                      );
                    })}
                  </div>
                </div>
              </Popover>
            </div>
            <div className="flex flex-row justify-end items-center gap-2 mt-5">
              <button
                type="button"
                className="px-4 py-2 bg-gray-100 hover:bg-gray-200 text-gray-500 rounded"
                onClick={modalStore.close}
              >
                Cancel
              </button>
              <button
                disabled={isLoading}
                type="button"
                className="flex items-center gap-2 px-4 py-2 bg-red-500 hover:bg-red-400 text-white rounded"
                onClick={async () => {
                  setIsLoading(true);
                  await onDelete(transferUser ? transferUser.email : null);
                  setIsLoading(false);
                  modalStore.close();
                }}
              >
                {isLoading && <SpinLoader className="animate-spin w-4 h-4" />}
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DeleteMemberModal;
