import httpClient from '../http-client';
import { ProductGap } from '../../types/product-gap';

export default async (
  categoryId: string | null,
  documentId: string | null,
  productFieldAnswers: { [fieldId: string]: string },
  integrationId: string | null,
  suggestedProductFeedbackId?: string,
): Promise<ProductGap> => {
  const { data } = await httpClient.post<ProductGap>(`/product-gaps`, {
    categoryId,
    documentId,
    productFieldAnswers,
    integrationId,
    suggestedProductFeedbackId,
  });
  return data;
};
