/* eslint-disable @typescript-eslint/no-explicit-any */
import httpClient from '../http-client';

interface Response {
  id: string;
  fieldLabel: string;
  value: any;
  columnName: string;
  status: 'new' | 'pending' | 'complete' | 'failed' | null;
}

export default async (analyticColumnId: string, fieldValueId: string, value: any): Promise<Response> => {
  const { data } = await httpClient.post<Response>(`/analytic-table-entry`, {
    analyticColumnId,
    fieldValueId,
    value,
  });
  return data;
};
