import React, { useMemo, useState } from 'react';
import errors from 'utils/errors';
import integrationApiToken from 'api/integration-api-token';
import { XMarkIcon } from '@heroicons/react/24/outline';
import useUserStore from '../stores/user';
import useModalStore from '../stores/modal';
import { User } from '../types/user';
import TrashIcon from './icons/TrashIcon';
import SpinLoader from './icons/SpinLoader';

interface State {
  apiToken: string;
  isLoading: boolean;
  isTokenError: boolean;
}

function ProductboardAPIToken() {
  const [state, setState] = useState<State>({
    apiToken: '',
    isLoading: false,
    isTokenError: false,
  });

  const userStore = useUserStore();
  const modalStore = useModalStore();

  const user = userStore.user as User;
  const integrated = useMemo(() => !user.needsToSetupProduct && user.productProvider === 'ProductBoard', [user]);

  if (integrated) {
    return (
      <div className="w-96 p-5">
        <div className="flex items-center justify-between">
          <div className="w-14 h-14 bg-red-200 rounded-full flex justify-center items-center">
            <TrashIcon className="w-8 h-8 text-red-500" />
          </div>
          <button
            type="button"
            className="w-7 h-7 p-1 rounded-lg text-gray-400 hover:bg-gray-200"
            onClick={modalStore.close}
          >
            <XMarkIcon />
          </button>
        </div>
        <div className="text-lg text-center font-medium mt-5">
          Are you sure you want to disconnect integration with Productboard?
        </div>
        <div className="flex justify-end items-center gap-2 mt-5">
          <button
            type="button"
            className="px-4 py-2 bg-gray-100 hover:bg-gray-200 text-gray-500 rounded"
            onClick={modalStore.close}
          >
            Cancel
          </button>
          <button
            type="button"
            className="flex items-center gap-2 px-4 py-2 bg-red-500 hover:bg-red-400 text-white rounded"
            onClick={async () => {
              setState(prevState => ({ ...prevState, isLoading: true }));
              await userStore.deleteIntegration('productboard');
              setState(prevState => ({ ...prevState, isLoading: false }));
              modalStore.close();
            }}
          >
            {state.isLoading && <SpinLoader className="animate-spin w-4 h-4" />}
            Disconnect
          </button>
        </div>
      </div>
    );
  }

  return (
    <div
      className="absolute left-1/2 bg-white rounded-lg p-3 pr-6 w-[1000px]"
      style={{
        top: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      {/* model header */}
      <div className="flex justify-between items-center px-4 pt-4 pb-2 font-medium text-2xl">
        Add Productboard API token
      </div>
      <div className="flex justify-between items-center p-4">
        You must be a Productboard admin and please get the token from Productboard &gt; Settings &gt; Integrations &gt;
        Public API - Access token.
      </div>
      <div className="p-4 text-gray-500">
        <div>Productboard API Token</div>
        <div className="mt-2">
          <input
            type="password"
            className="flex border rounded focus:outline-none focus:border-indigo-300 h-10 w-full p-2"
            onChange={e => {
              setState(prevState => ({ ...prevState, apiToken: e.target.value }));
            }}
            maxLength={5000}
            value={state.apiToken}
          />
          {state.isTokenError && (
            <div className="text-red-500 text-sm">Error: Please check your Chorus token and try again.</div>
          )}
        </div>
      </div>
      <div className="my-3 flex flex-row-reverse items-center gap-3">
        <button
          className={`px-8 py-2 rounded ${
            state.apiToken.length === 0 || state.isLoading
              ? 'bg-gray-200 text-gray-500 cursor-default'
              : 'bg-blue-600 hover:bg-blue-500 text-white'
          }`}
          type="button"
          onClick={async () => {
            setState(prevState => ({ ...prevState, isLoading: true }));

            try {
              await integrationApiToken('productboard', state.apiToken);
              setState(prevState => ({ ...prevState, isLoading: false }));
              userStore.setState({ user: { ...user, needsToSetupProduct: false, productProvider: 'ProductBoard' } });
              modalStore.close();
            } catch (e) {
              if (e instanceof errors.IntegrationError) {
                setState(prevState => ({
                  ...prevState,
                  isLoading: false,
                  isTokenError: true,
                }));
              }
            }
          }}
          disabled={state.apiToken.length === 0 || state.isLoading}
        >
          Add
        </button>
      </div>
    </div>
  );
}
export default ProductboardAPIToken;
