import { ActionType } from 'types/member';
import httpClient from '../http-client';

export default async (
  email: string,
  actionType: ActionType,
  role: string | null,
  transferEmail: string | null,
  isAdmin: boolean | null,
): Promise<void> => {
  await httpClient.post(`/user/management`, {
    email,
    transferEmail,
    actionType,
    role,
    isAdmin,
  });
};
