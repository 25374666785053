import React from 'react';

interface Props {
  className?: string;
}
function TableIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.33203 12.4451L26.6654 12.4451M12.4431 5.33398L12.4431 26.6673M11.0209 5.33398H20.9765C22.9678 5.33398 23.9634 5.33398 24.724 5.72152C25.393 6.0624 25.9369 6.60633 26.2778 7.27535C26.6654 8.03593 26.6654 9.03158 26.6654 11.0229V20.9784C26.6654 22.9697 26.6654 23.9654 26.2778 24.726C25.9369 25.395 25.393 25.9389 24.724 26.2798C23.9634 26.6673 22.9678 26.6673 20.9765 26.6673H11.0209C9.02962 26.6673 8.03397 26.6673 7.2734 26.2798C6.60438 25.9389 6.06045 25.395 5.71956 24.726C5.33203 23.9654 5.33203 22.9697 5.33203 20.9784V11.0229C5.33203 9.03158 5.33203 8.03593 5.71956 7.27535C6.06045 6.60633 6.60438 6.0624 7.2734 5.72152C8.03397 5.33398 9.02962 5.33398 11.0209 5.33398Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>

    // <svg
    //   className={className}
    //   width="24"
    //   height="24"
    //   viewBox="0 0 24 24"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     d="M3.5 12.25C3.5 12.25 6.682 6 12.25 6C17.818 6 21 12.25 21 12.25C21 12.25 17.818 18.5 12.25 18.5C6.682 18.5 3.5 12.25 3.5 12.25Z"
    //     stroke="currentColor"
    //     strokeWidth="1.5"
    //     strokeMiterlimit="10"
    //     strokeLinecap="round"
    //     strokeLinejoin="round"
    //   />
    //   <path
    //     d="M12.2498 14.5943C13.5678 14.5943 14.6363 13.5448 14.6363 12.2503C14.6363 10.9557 13.5678 9.90625 12.2498 9.90625C10.9318 9.90625 9.86328 10.9557 9.86328 12.2503C9.86328 13.5448 10.9318 14.5943 12.2498 14.5943Z"
    //     stroke="currentColor"
    //     strokeWidth="1.5"
    //     strokeMiterlimit="10"
    //     strokeLinecap="round"
    //     strokeLinejoin="round"
    //   />
    // </svg>
  );
}

TableIcon.defaultProps = {
  className: undefined,
};

export default TableIcon;
